import React from "react";
import { Link } from "react-router-dom";
import PrivacyPolicyPage from "src/shared/pages/PrivacyPolicyPage";
import { ScrollerView } from "../components/views";

import "./Pages.scss";

const PrivacyPolicyScreen = () => {
  return (
    <ScrollerView>
      <PrivacyPolicyPage Link={Link} />
    </ScrollerView>
  );
};

export default PrivacyPolicyScreen;
