import React, { useState,  useEffect } from "react";
import { useSelector } from "react-redux";
import { copy } from "../../utils/CopyUtil";
import { useTranslation } from "react-i18next";

export const TOAST_TYPE = {
  error: "toast-error",
  notify: "toast-notify",
  warning: "toast-warning",
  success: "toast-success"
};

const Toaster = () => {
  const [state, setState] = useState({});
  const { t } = useTranslation();
  const messages = useSelector((state) => state.system.messages);

  useEffect(() => {
    const [firstMessage] = messages;
    if (firstMessage?.message) {
      let messageValue = firstMessage.message;
      if (messageValue.indexOf("@") === 0) {
        messageValue = t(messageValue.substr(1));
      }
      if (firstMessage && firstMessage.type === TOAST_TYPE.error) {
        error(messageValue);
      } else {
        notify(messageValue);
      }
    }
  }, [messages]);

  useEffect(() => {
    let timeout;
    const duration = 1000 + (state.message ? state.message.length * 75 : 0);
    if (state.show) {
      timeout = setTimeout(() => {
        setState({ ...state, show: false });
      }, duration);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [state]);

  const notify = (message) => {
    setState({ message, type: TOAST_TYPE.notify, show: true });
  };

  const success = (message) => {
    setState({ message, type: TOAST_TYPE.success, show: true });
  };

  const error = (message) => {
    setState({ message, type: TOAST_TYPE.error, show: true });
  };

  const warning = (message) => {
    setState({ message, type: TOAST_TYPE.warning, show: true });
  };

  const { message, show, type } = state;
  return (
    <span
      onClick={() => {
        copy(message);
      }}
      className={"toast-view " + (show ? "showView" : "hideView") + " " + type}
      dangerouslySetInnerHTML={{ __html: message }}></span>
  );
};

export default Toaster;
