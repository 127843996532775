import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actionActivate } from "../subscriptions.actions";

import { ChevronRightIcon } from "../../../assets/CommonIcons";
import { IconButton } from "../../views";
import { URLS } from "../../../shared/utils/url";

const EditSubsType = ({ type, history }) => {
  const dispatch = useDispatch();
  const allSubs = useSelector((state) => state.subscriptions.allSubs);
  const feeds = useSelector((state) => state.subscriptions.feeds);

  const isFeed = type === "news";
  
  const handleActivateClick = (e, item) => {
    e.stopPropagation();
    const { id, active } = item;
    dispatch(actionActivate({ id, activate: !active, isFeed }));
  };

  const renderItem = (item, isLast, isFeed) => {
    const { id, active } = item;
    let title, type;
    if (isFeed) {
      const { title: feedTitle } = item;
      title = feedTitle;
      type = "news";
    } else {
      const { title: bookTitle, book_type } = item.book;
      title = bookTitle;
      type = book_type;
    }
    let itemStyle = "subs-edit-item-mobile";
    if (!isLast) {
      itemStyle += " border";
    }
    const statusStyle = "status-text" + (active ? " on" : " off");
    return <div
      key={id}
      className={itemStyle}
      onClick={() => history.push(`${URLS.subscriptionEdit}/${type}/${id}`)}>
      <span className="title">{title}</span>
      <div className="subs-edit-switcher">
        <span 
          className={statusStyle}
          onClick={(e) => handleActivateClick(e, item)}>
          {active ? "on" : "off"}
        </span>
        <IconButton icon={ChevronRightIcon} />
      </div>
    </div>;
  };

  const items = isFeed ? feeds : allSubs.filter((item) => item.book.book_type === type);

  return (items || []).map((item, index) => renderItem(item, index === items.length - 1, isFeed));
};

EditSubsType.propTypes = {
  type: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(EditSubsType);
