import React from "react";
import { useTranslation } from "react-i18next";

import EgwWebFont from "src/assets/EgwWebFont";
import { IconButton } from "../views";
import {
  usePopupAllCollectionAudioBooksFilters,
  usePopupCategoryTreeFilters,
  usePopupTitleTreeFilters
} from "./PopupBooksFilters";
import { useHistory } from "react-router-dom";
import { URLS } from "../../shared/utils/url";

const _ButtonBooksFilters = (props) => {
  const { t } = useTranslation();

  return (
    <IconButton
      icon={EgwWebFont.filter}
      className="tpMenuIcon"
      title={t("filter")}
      {...props}
    />
  );
};

const ButtonCategoriesFilters = () => {
  const { isAnyFilterApplied, openPopupFilters } = usePopupCategoryTreeFilters();
  return <_ButtonBooksFilters onClick={openPopupFilters} active={isAnyFilterApplied} />;
};

const ButtonTitlesFilters = () => {
  const { isAnyFilterApplied, openPopupFilters } = usePopupTitleTreeFilters();
  return <_ButtonBooksFilters onClick={openPopupFilters} active={isAnyFilterApplied} />;
};

const ButtonAllCollectionAudioBooksFilters = () => {
  const { isAnyFilterApplied, openPopupFilters } = usePopupAllCollectionAudioBooksFilters();
  return <_ButtonBooksFilters onClick={openPopupFilters} active={isAnyFilterApplied} />;
};

const ButtonBooksFilters = () => {
  const history = useHistory();
  const pathname = history.location.pathname;

  if (pathname.includes(URLS.titles)) {
    return <ButtonTitlesFilters />;
  }

  if (pathname.includes(URLS.categories)) {
    return <ButtonCategoriesFilters />;
  }

  return <ButtonAllCollectionAudioBooksFilters />;
};

export default ButtonBooksFilters;
