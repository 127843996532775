import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { activeTheme, THEME_MODE } from "../../utils/ThemeUtils";
import { actionUpdateTheme } from "../../redux/actions";
import {
  ThemeDayIcon,
  ThemeDawnIcon,
  ThemeDuskIcon,
  ThemeDarkIcon,
  ThemeHumanWhiteIcon,
  ThemeHumanBlackIcon,
} from "../../assets/CommonIcons";
import { main } from "../../utils/RouterUtils";
import { menuItems } from "../../utils/MenuItems";
import { useNavigationData } from "../NavigationContext";
import classNames from "classnames";

import "./ThemeMode.scss";
import { useViewMode } from "../../hooks";
import { useHistory } from "react-router-dom";
import ViewMode from "../views/ViewMode";
import { URLS } from "../../shared/utils/url";

const ThemeMode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, themeMode } = useViewMode();
  
  const handleThemeClick = (theme) => {
    dispatch(actionUpdateTheme({
      theme,
      isMobile
    }));
    activeTheme(theme);
  };
  useNavigationData({ nodeList: [main, menuItems.themeMode] });

  useEffect(() => {
    if (!isMobile) {
      history.replace(URLS.settings);
    }
  }, [isMobile, history]);

  const renderThemeItem = (theme, Icon) => {
    return (
      <div 
        className={classNames("item-container", {
          "theme-active": theme.id === themeMode
        })} 
        onClick={() => handleThemeClick(theme.id)} 
      >
        <div className="item-inner">
          <span>{t(theme.label)}</span>
          <Icon />
        </div>
      </div>
    );
  };

  return (
    <div className="theme-mode-container">
      {renderThemeItem(THEME_MODE.dy, ThemeDayIcon)}
      {renderThemeItem(THEME_MODE.dw, ThemeDawnIcon)}
      {renderThemeItem(THEME_MODE.dk, ThemeDuskIcon)}
      {renderThemeItem(THEME_MODE.dm, ThemeDarkIcon)}
      {renderThemeItem(THEME_MODE.ady, ThemeHumanWhiteIcon)}
      {renderThemeItem(THEME_MODE.adm, ThemeHumanBlackIcon)}
      <ViewMode />
    </div>
  );
};

export default ThemeMode;
