import {
  SET_DRAGNDROP,
  RESET_DRAGNDROP,
} from "./constants";

const initialState = {
  id: null,
  title: null,
  overId: null,
  overTitle: null,
  isAddingPanel: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DRAGNDROP:
      return { ...state, ...action.data };
    case RESET_DRAGNDROP:
      return initialState;
    default:
      return state;
  }
};
