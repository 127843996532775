import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { IconButton, Scroll } from "../views";
import { getLinkValue, isExternalUrl, PanelTypes } from "../../utils/URLUtils";
import { useBookSelect, useLoader } from "../../redux/selectors";
import { getBookIdByLocation } from "../../utils/AdvSearchTypes";
import { useNavigationContext } from "../NavigationContext";
import { MinNavPanel } from "./MinNavPanel";
import { useAuth, useDebouncedEffect, useViewMode } from "../../hooks";
import { actionUpdateSetting, actionUpdateSettings } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { makeResourceUrl, URLS } from "../../shared/utils/url";
import useSubscription from "src/hooks/useSubscription";
import { Directory, EgwWritingsApps } from "../../shared/content/featured";
import EgwWebIcons from "../../assets/EgwWebFont";
import { FeaturedSectionContent } from "./FeaturedSectionContent";
import { SearchPanelTabs } from "./constants";
import {
  featuredSearchActions,
  LOADER_ID_FEATURED_SEARCH_SECTIONS,
} from "../featuredSearch/featuredSearch.actions";
import { Loading } from "../index";
import NoDataTemplate from "../NoDataTemplate/NoDataTemplate";
import SearchAccordion from "../views/Accordion/SearchAccordion";
import Badge from "../Badge";
import { CONTENT_CLASSES } from "../../shared/utils/content";
import { actionFullscreenId } from "../../redux/params.actions";
import { HASH_S_PLUS_OPENED_AND_SEARCHED } from "../search/search.utils";

// TODO: Make common styles of the Relevant search and S+ panel (featured and may be other tabs).
import "./featured-tab.scss";
import "../relevantSearch/relevantSearch.scss";

const featuredSectionMap = {
  dictionary: "dictionary",
  egwTopicalIndex: "egwTopicalIndex",
  bibleVersions: "versions",
  concordances: "concordances",
  bibleCommentaries: "commentaries",
  egwIndex: "egwIndex",
  aplIndex: "aplIndex",
  similarParagraphs: "similarParagraphs",
};

const renderLinkList = (list, t, hidePanel) =>
  list.map((item, id) => {
    if (item.action) {
      return (
        <button
          key={id}
          className="item"
          onClick={() => {
            if (hidePanel) {
              hidePanel();
            }
            item.action();
          }}>
          {t(item.title)}
        </button>
      );
    }
    // else
    const [target, rel] = isExternalUrl(item.url) ? ["_blank", "noopener noreferrer"] : ["", ""];
    return (
      <a
        key={id}
        className={"item" + (!item.url || item.url === "#" ? " passive" : "")}
        href={item.url}
        target={target}
        rel={rel}>
        {t(item.title)}
      </a>
    );
  });

const FeaturedTab = ({ isInsideSearchPlus, isTypeBible, mobileView, isFeaturedSearchResults }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { openId, activeId, isReader, panelIds } = useNavigationContext();
  const { showLoginDialog } = useAuth();
  const { isMobile } = useViewMode();
  const bookId = getBookIdByLocation(history.location.pathname, activeId);
  const book = useBookSelect(bookId);
  const { isCanSubscribe, isSubscribed, onClickSubscribe } = useSubscription(bookId);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const searchParams = useSelector((state) => state.search.searchParams);
  const currentSearchResultsQuery =
    useSelector((state) => state.search.search.query);
  const isLoadingFeaturedSearchSections = useLoader(LOADER_ID_FEATURED_SEARCH_SECTIONS);
  const syncReaderAndSearch = useSelector((state) => state.settings.syncReaderAndSearch);
  const featuredSearchSections = useSelector((state) => state.featuredSearch.sections);

  const isMobileSearchJustPerformed = history.location
    .hash.includes(HASH_S_PLUS_OPENED_AND_SEARCHED);
  const isReaderBasedFeatured = isMobile ? (isReader && !isMobileSearchJustPerformed) : isReader;
  const isCanSyncReader = isReaderBasedFeatured && syncReaderAndSearch;
  const isFeaturedSearchPossiblyShouldHasData = searchQuery || isCanSyncReader;

  const syncWithReader = useCallback(() => {
    if (activeId) {
      dispatch(featuredSearchActions.fetchForParagraph(activeId));
    }
  }, [activeId]);

  // NON-READER CASE: Fetch featured on mount and search params change.
  useEffect(() => {
    if (!isCanSyncReader) {
      if (searchQuery) {
        dispatch(featuredSearchActions.fetch({
          // e.g. for "aa faith": {currentSearchResultsQuery}="faith", {searchQuery}="aa faith"
          query: currentSearchResultsQuery || searchQuery,
          langs: searchParams.folders,
        }));
      }
    }
  }, [searchQuery, searchParams.folders]);

  // READER CASE: Fetch featured on mount and reader active para change.
  useDebouncedEffect(() => {
    if (isCanSyncReader) {
      if (activeId) {
        dispatch(featuredSearchActions.fetchForParagraph(activeId));
      }
    }
  }, 500, [isCanSyncReader, activeId]);

  const hideRightPanel = useCallback(() => {
    dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
  }, []);

  const extrasList = useMemo(() => {
    const SubscriptionsItem = {
      title: "subscriptions",
      action: () => {
        showLoginDialog(() => {
          history.push(URLS.subscription);
        });
      },
    };
    if (!book) {
      return [SubscriptionsItem];
    }
    const itemList = [];
    if (isCanSubscribe) {
      itemList.push({
        title: isSubscribed ? "subscribed" : "subscribe",
        action: onClickSubscribe,
      });
    }

    if (book.buy_link) {
      itemList.push({ title: "purchase_printed", url: book.buy_link });
    }
    if (book.files.epub) {
      itemList.push({ title: "purchase_ebook", url: makeResourceUrl(book.files.epub) });
    }
    if (book.files.mobi) {
      itemList.push({ title: "purchase_kindle", url: makeResourceUrl(book.files.mobi) });
    }
    itemList.push(SubscriptionsItem);
    return itemList;
  }, [book, isCanSubscribe, isSubscribed, onClickSubscribe]);

  const onBackToResults = () => {
    dispatch(actionUpdateSetting(Settings.rightTab.id, SearchPanelTabs.RESULTS));
  };

  const sectionLength = (section) => {
    if (!section.books.length) {
      return undefined;
    }

    return section.books.reduce((acc, item) => (acc += item.links.length), 0);
  };

  const openReader = useCallback((paraId) => {
    const featuredPanelIndex = panelIds.findIndex(
      (panelId) => getLinkValue(panelId).type === PanelTypes.FEATURED.id
    );
    dispatch(actionFullscreenId()); // [3511]
    openId(paraId, {
      className: CONTENT_CLASSES.PARAGRAPH,
      panelType: PanelTypes.FEATURED.id,
      replacePanelIndex: featuredPanelIndex === -1 ? undefined : featuredPanelIndex,
    });
  }, [openId, panelIds]);

  const goToFeaturedSearchMain = useCallback(
    (sectionCode) => {
      dispatch(
        actionUpdateSettings({
          [Settings.isShowRightPanel.id]: true,
          [Settings.rightTab.id]: SearchPanelTabs.FEATURED,
        }),
      );

      history.push(URLS.featuredSearch + "/" + sectionCode);
    },
    [history],
  );

  const accordionProps =
    mobileView || isInsideSearchPlus
      ? {
          className: "folder",
        }
      : {
          className: "relevant-search-content-item",
          isCanBeToggled: false,
          isOpenByDefault: true,
        };

  const elementTitle = <h3>{t(isTypeBible ? "bibleDidYouKnow" : "egwDidYouKnow")}</h3>;
  const elementAccordionExtras = isTypeBible ? (
    ""
  ) : (
    <SearchAccordion title={t("egwExtras")} {...accordionProps}>
      {renderLinkList(extrasList, t, isMobile ? hideRightPanel : undefined)}
    </SearchAccordion>
  );
  const elementAccordionEwgApps = (
    <SearchAccordion title={t("egwWritingsApps")} {...accordionProps}>
      {renderLinkList(EgwWritingsApps, t)}
    </SearchAccordion>
  );
  const elementAccordionDirectory = (
    <SearchAccordion title={t("directory")} {...accordionProps}>
      {renderLinkList(Directory, t)}
    </SearchAccordion>
  );

  const viewFeaturedSearchResults = useMemo(() => {
    if (isLoadingFeaturedSearchSections) {
      return (
        <Loading sizeSmall align="flex-center" type={Loading.types.FEATURED_SEARCH_SECTIONS} />
      );
    }

    if (featuredSearchSections?.length === 0 && isFeaturedSearchPossiblyShouldHasData) {
      return <NoDataTemplate />;
    }

    return featuredSearchSections?.map((section, index) => {
      if (!section.books.length) {
        return null;
      }

      const sectionBooks = isInsideSearchPlus ? section.books : section.books.slice(0, 3);

      const content = (
        <SearchAccordion
          key={section.code}
          isOpenByDefault={isInsideSearchPlus ? index === 0 : true} // make first item open for S+
          isCanBeToggled={isInsideSearchPlus} // can be toggled in the S+ only
          title={(elementChevron) => {
            return (
              <>
                <div>
                  {t(featuredSectionMap[section.code])}
                  {elementChevron}
                </div>
                <Badge number={sectionLength(section)} />
              </>
            );
          }}
          className={isInsideSearchPlus ? "folder" : "featured-search-content-inner"}>
          {sectionBooks.map((book, index) => {
            return (
              <FeaturedSectionContent
                key={book.book_id}
                index={index}
                book={book}
                openReader={openReader}
                withoutMore={!isInsideSearchPlus && isFeaturedSearchResults}
              />
            );
          })}
        </SearchAccordion>
      );

      if (isInsideSearchPlus) {
        return content;
      }

      return (
        <div key={section.code} className="featured-search-content">
          {content}
          <div
            className={classNames("featured-section__more with-chevron")}
            onClick={() => goToFeaturedSearchMain(section.code)}>
            <span>{t("More")}</span>
          </div>
        </div>
      );
    });
  }, [
    t,
    isLoadingFeaturedSearchSections,
    featuredSearchSections,
    isFeaturedSearchResults,
    isInsideSearchPlus,
    goToFeaturedSearchMain,
    isFeaturedSearchPossiblyShouldHasData,
    openReader,
  ]);

  if (isInsideSearchPlus) {
    return (
      <div className="tab-container">
        <div className="search-result-header featured-tab">
          <div className="back-button" onClick={onBackToResults}>
            <i className="icon icon-left-arrow" />
            &nbsp;{t("backToResults")}
          </div>
          <div className="featured-actions">
            {isReaderBasedFeatured && (
              <IconButton
                disabled={isLoadingFeaturedSearchSections}
                title={t("refreshResults")}
                icon={EgwWebIcons.restart}
                onClick={syncWithReader}
                className="featured-refresh"
                showIcon={true}
              />
            )}
            <MinNavPanel />
          </div>
        </div>
        <Scroll>
          <div
            className={classNames("search-plus-featured-wrapper", {
              "mobile-view": mobileView,
            })}>
            {viewFeaturedSearchResults}
            {elementTitle}
            {elementAccordionExtras}
            {elementAccordionEwgApps}
            {elementAccordionDirectory}
          </div>
        </Scroll>
      </div>
    );
  }

  return (
    <div className="featured-section relevant-search-box">
      {isFeaturedSearchResults && (
        <div
          className={classNames("featured-search-column", {
            "two-columns": featuredSearchSections?.length === 2,
          })}>
          {viewFeaturedSearchResults}
        </div>
      )}
      {elementTitle}
      <div className="search-relevant-featured-wrapper">
        {elementAccordionExtras}
        {elementAccordionEwgApps}
        {elementAccordionDirectory}
      </div>
    </div>
  );
};

FeaturedTab.defaultProps = {
  isInsideSearchPlus: true,
};

FeaturedTab.propTypes = {
  isTypeBible: PropTypes.bool,
  isInsideSearchPlus: PropTypes.bool,
  isFeaturedSearchResults: PropTypes.bool,
  mobileView: PropTypes.bool,
};

export default FeaturedTab;
