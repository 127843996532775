import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { useNavigationContext } from "src/components/NavigationContext";
import { IconButton } from "src/components/views";
import EgwWebFont from "src/assets/EgwWebFont";

import useReaderWorkspaces from "src/hooks/useReaderWorkspaces";
import { URLS } from "../../../shared/utils/url";

const WorkspaceTools = ({ item, currentLayout }) => {
  const { panelIds, pathname } = useNavigationContext();
  const isWorkspace = pathname.includes(URLS.workspaces);
  const {
    showDeleteWorkspaceDialog,
    showSaveWorkspaceDialog,
    showRenameWorkspaceDialog,
  } = useReaderWorkspaces({
    currentLayout,
    panelIds,
  });

  const handleClickSaveWS = useCallback(() => {
    showSaveWorkspaceDialog(item);
  }, [item, showSaveWorkspaceDialog]);

  const handleClickRenameWS = useCallback(() => {
    showRenameWorkspaceDialog(item);
  }, [item, showRenameWorkspaceDialog]);

  const handleClickDeleteWS = useCallback(() => {
    showDeleteWorkspaceDialog(item);
  }, [item, showDeleteWorkspaceDialog]);

  return (
    <div className="ws-tools">
      {!isWorkspace && <IconButton className="s-layouts-btn_save" icon={EgwWebFont.save} onClick={handleClickSaveWS} />}
      <IconButton className="s-layouts-btn_edit" icon={EgwWebFont.edit} onClick={handleClickRenameWS} />
      <IconButton className="s-layouts-btn_delete" icon={EgwWebFont.delete} onClick={handleClickDeleteWS} />
    </div>
  );
};

WorkspaceTools.propTypes = {
  item: PropTypes.object,
  currentLayout: PropTypes.string,
};

export default WorkspaceTools;
