import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "../views";

import "./View404.scss";

const View404 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404</h1>
      <h3 className="not-found-subtitle">{t("page404")}</h3>
      <Button className="not-found-button" onClick={() => history.push("/")}>
        {t("goToMainPage")}
      </Button>
    </div>
  );
};

export default View404;
