import React, { Component } from "react";
import PropTypes from "prop-types";
import { dispatch } from "./event_manager";
import { clearSelection } from "../../utils/Utils";
import Util from "./util";

// Minimum pixels moved before looking for new cards etc.
const minDragDistanceThreshold = 5;

/**
 * based on https://github.com/Forecast-it/react-virtualized-dnd
 */
class Draggable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startX: null,
      startY: null,
      isDragging: false,
      wasClicked: false,
      isTouch: true,
      xClickOffset: 0,
      yClickOffset: 0,
      didMoveMinDistanceDuringDrag: false,
      dragSensitivityX: 15,
      dragSensitivityY: 15
    };
    this.handleDragShortcuts = this.handleDragShortcuts.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onPointerCancel = this.onPointerCancel.bind(this);
    this.clickBlocker = this.clickBlocker.bind(this);
    this.dragAndDropGroup = Util.getDragEvents(this.props.dragAndDropGroup);
    this.elFromPointCounter = 0;
    this.latestUpdateX = null;
    this.latestUpdateY = null;
    this.draggableHoveringOver = null;
    this.droppableDraggedOver = null;
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleDragShortcuts);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleDragShortcuts);
    cancelAnimationFrame(this.frame);
    this.frame = null;
  }

  handleDragShortcuts(e) {
    if (e.key === "Escape") {
      this.onPointerCancel(e);
    }
  }

  /**
   * need for fix for EGWW-1460, prevent click for Firefox
   */
  clickBlocker(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  getBoundingClientRect() {
    if (this.draggable) {
      return this.draggable.getBoundingClientRect();
    }
    return {};
  }

  removeDragEventListeners() {
    document.removeEventListener("mousemove", this.onPointerMove);
    document.removeEventListener("mouseup", this.onPointerUp);
    document.removeEventListener("mousecancel", this.onPointerCancel);

    document.removeEventListener("touchmove", this.onPointerUp);
    document.removeEventListener("touchend", this.onPointerUp);
    document.removeEventListener("touchcancel", this.onPointerCancel);

    requestAnimationFrame(() => document.removeEventListener("click", this.clickBlocker, true));
    // document.querySelector('body').classList.remove('isDragging');

    // const scroll = document.querySelector('.virtualized-scrollbar-inner');
    // if (scroll && scroll.parentNode) {
    //   scroll.parentNode.style.overflow = 'scroll';
    // }
    // const mainApp = document.querySelector('#mainApp');
    // if (mainApp && mainApp.children[0]) {
    //   mainApp.children[0].style.overflow = "scroll";
    // }
  }

  onPointerDown(e, isTouchEvent) {
    const isMouse = e.buttons === 1;

    if (
      (!isTouchEvent && !isMouse) ||
      (e.target.className &&
        typeof e.target.className === "string" &&
        e.target.className.includes("no-drag")) ||
      this.props.disabled ||
      this.props.isSectionHeader
    ) {
      return;
    }

    if (!isTouchEvent) {
      e.preventDefault();
      document.addEventListener("mousemove", this.onPointerMove);
      document.addEventListener("mouseup", this.onPointerUp);
      document.addEventListener("mousecancel", this.onPointerCancel);
    } else {
      document.addEventListener("touchmove", this.onPointerMove, { passive: false });
      document.addEventListener("touchend", this.onPointerUp, { passive: false });
      document.addEventListener("touchcancel", this.onPointerCancel, { passive: false });
    }

    e.stopPropagation();
    const dragObject = { draggableId: this.props.draggableId, droppableId: this.props.droppableId };
    dispatch(this.dragAndDropGroup.moveEvent, dragObject, null, null, null, null);
    if (this.droppableDraggedOver !== null || this.draggableHoveringOver !== null) {
      this.droppableDraggedOver = null;
      this.draggableHoveringOver = null;
    }
    const x = isTouchEvent ? e.changedTouches[e.changedTouches.length - 1].clientX : e.clientX;
    const y = isTouchEvent ? e.changedTouches[e.changedTouches.length - 1].clientY : e.clientY;
    let cardWidth = this.draggable.offsetWidth;
    const cardTop = this.draggable.getBoundingClientRect().top;
    const cardLeft = this.draggable.getBoundingClientRect().left;

    this.timeOut = setTimeout(
      () => {
        clearSelection();
        this.timeOut = undefined;
        // document.querySelector('body').classList.add('isDragging');
        // if (isTouchEvent) {
        //   const scrollBar = document.querySelector('.virtualized-scrollbar-inner');

        //   if (scrollBar) {
        //     scrollBar.parentNode.style.overflow = 'hidden';
        //   }
        //   const mainApp = document.querySelector('#mainApp');
        //   if (mainApp && mainApp.children[0]) {
        //     mainApp.children[0].style.overflow = "";
        //   }
        // }

        this.setState({
          width: cardWidth,
          didMoveMinDistanceDuringDrag: false,
          minDragDistanceMoved: false,
          startX: x,
          startY: y,
          wasClicked: true,
          isTouch: isTouchEvent,
          isDragging: false,
          // +8 for margin for error
          xClickOffset: Math.abs(x - cardLeft) + 8,
          yClickOffset: Math.abs(y - cardTop) + 8
        });
      },
      isTouchEvent ? 500 : 50
    );
  }

  onPointerUp(e) {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = undefined;
    }
    if (this.props.disabled || this.props.isSectionHeader) {
      return;
    }
    //If object moved prevent default event
    if (this.state.didMoveMinDistanceDuringDrag) {
      dispatch(this.dragAndDropGroup.endEvent);
      e.preventDefault();
      e.stopPropagation();
    }
    dispatch(this.dragAndDropGroup.resetEvent);
    this.draggableHoveringOver = null;
    this.setState({
      isDragging: false,
      capturing: false,
      didMoveMinDistanceDuringDrag: false,
      minDragDistanceMoved: false,
      cardLeft: 0,
      cardTop: 0,
      top: null,
      left: null,
      wasClicked: false
    });
    this.removeDragEventListeners();
  }

  onPointerCancel() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = undefined;
    }
    dispatch(this.dragAndDropGroup.resetEvent);
    this.draggableHoveringOver = null;

    this.setState({
      isDragging: false,
      capturing: false,
      didMoveMinDistanceDuringDrag: false,
      minDragDistanceMoved: false,
      left: null,
      top: null,
      cardLeft: 0,
      cardTop: 0,
      wasClicked: false
    });
    this.removeDragEventListeners();
  }

  // Don't update what we're dragging over on every single drag
  shouldRefindDragElems(x, y) {
    if (!this.latestUpdateX || !this.latestUpdateY) {
      this.latestUpdateX = x;
      this.latestUpdateY = y;
      return true;
    }
    // Only update if we've moved some x + y distance that is larger than threshold
    const shouldUpdate =
      Math.abs(this.latestUpdateX - x) + Math.abs(this.latestUpdateY - y) >=
      minDragDistanceThreshold;
    if (shouldUpdate) {
      this.latestUpdateX = x;
      this.latestUpdateY = y;
      return true;
    }
    return false;
  }

  moveElement(x, y) {
    let hasDispatched = false;
    const shouldRefindDragElems = this.shouldRefindDragElems(x, y);
    let droppableDraggedOver =
      shouldRefindDragElems || this.droppableDraggedOver == null
        ? this.getDroppableElemUnderDrag(x, y)
        : this.droppableDraggedOver;
    let draggableHoveringOver =
      shouldRefindDragElems || this.draggableHoveringOver == null
        ? this.getDraggableElemUnderDrag(x, y)
        : this.draggableHoveringOver;
    const newLeft = x - this.state.xClickOffset;
    const newTop = y - this.state.yClickOffset;
    const minDistanceMoved =
      Math.abs(this.state.startX - x) > this.state.dragSensitivityX ||
      Math.abs(this.state.startY - y) > this.state.dragSensitivityY;
    if (minDistanceMoved && !this.state.didMoveMinDistanceDuringDrag) {
      this.setState({ didMoveMinDistanceDuringDrag: true });
    }
    if (!minDistanceMoved && !this.state.didMoveMinDistanceDuringDrag) {
      const dragObject = {
        draggableId: this.props.draggableId,
        droppableId: this.props.droppableId
      };
      dispatch(this.dragAndDropGroup.moveEvent, dragObject, null, null, null, null);
      hasDispatched = true;
      return;
    }
    if (!droppableDraggedOver) {
      dispatch(this.dragAndDropGroup.resetEvent);
      this.droppableDraggedOver = null;
      this.draggableHoveringOver = null;
    }
    const shouldRegisterAsDrag = this.state.didMoveMinDistanceDuringDrag || minDistanceMoved;
    if (shouldRegisterAsDrag && this.state.wasClicked && !this.state.isDragging) {
      const dragObject = {
        draggableId: this.props.draggableId,
        droppableId: this.props.droppableId,
        height: this.draggable ? this.draggable.clientHeight : null
      };
      dispatch(this.dragAndDropGroup.startEvent, dragObject, x, y);
      hasDispatched = true;
    }
    // We're hovering over a droppable and a draggable
    if (droppableDraggedOver && draggableHoveringOver && shouldRegisterAsDrag) {
      const draggableHoveredOverId = draggableHoveringOver.getAttribute("draggableid");
      const type = draggableHoveringOver.getAttribute("data-type");
      if (!draggableHoveredOverId.includes("placeholder")) {
        if (
          this.droppableDraggedOver !== droppableDraggedOver ||
          this.draggableHoveringOver !== draggableHoveringOver
        ) {
          const dragObject = {
            draggableId: this.props.draggableId,
            droppableId: this.props.droppableId,
            type
          };
          dispatch(
            this.dragAndDropGroup.moveEvent,
            dragObject,
            droppableDraggedOver,
            draggableHoveredOverId,
            x,
            y
          );
          hasDispatched = true;
          this.droppableDraggedOver = droppableDraggedOver;
          this.draggableHoveringOver = draggableHoveringOver;
        }
      }
    } else if (droppableDraggedOver && shouldRegisterAsDrag) {
      // We're hovering over a droppable, but no draggable
      this.droppableDraggedOver = droppableDraggedOver;
      this.draggableHoveringOver = null;
      const dragObject = {
        draggableId: this.props.draggableId,
        droppableId: this.props.droppableId
      };
      dispatch(this.dragAndDropGroup.moveEvent, dragObject, droppableDraggedOver, null, x, y, null);
      hasDispatched = true;
    }
    if (!hasDispatched) {
      // If nothing changed, we still wanna notify move for scrolling
      dispatch(this.dragAndDropGroup.moveEvent, null, null, null, x, y, null);
      hasDispatched = true;
    }
    this.setState({
      isDragging: shouldRegisterAsDrag,
      // We need to move more than the drag sensitivity before we consider it an intended drag
      minDragDistanceMoved: minDistanceMoved,
      left: newLeft,
      top: newTop
    });
  }

  onPointerMove(e) {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = undefined;
      this.onPointerCancel();
      return;
    }
    if (this.props.disabled || !this.state.wasClicked || this.props.isSectionHeader) {
      return;
    }
    const x = this.state.isTouch
      ? e.changedTouches[e.changedTouches.length - 1].clientX
      : e.clientX;
    const y = this.state.isTouch
      ? e.changedTouches[e.changedTouches.length - 1].clientY
      : e.clientY;
    const minDistanceMoved =
      Math.abs(this.state.startX - x) > this.state.dragSensitivityX ||
      Math.abs(this.state.startY - y) > this.state.dragSensitivityY;
    if (!minDistanceMoved && !this.state.didMoveMinDistanceDuringDrag) {
      return;
    }

    document.addEventListener("click", this.clickBlocker, true);
    e.preventDefault();
    e.stopPropagation();
    if (e.buttons === 1 || this.state.isTouch) {
      requestAnimationFrame(() => this.moveElement(x, y));
    } else {
      this.onPointerCancel();
    }
  }

  getDroppableElemUnderDrag(x, y) {
    let colUnder = null;
    let draggingElement = this.draggable;
    if (draggingElement) {
      // Disable pointer events to look through element
      draggingElement.style.pointerEvents = "none";
      // Get element under dragged  (look through)
      let elementUnder = document.elementFromPoint(x, y);
      // Reset dragged element's pointers
      draggingElement.style.pointerEvents = "all";
      colUnder = Util.getDroppableParentElement(elementUnder, this.props.dragAndDropGroup);
    }
    return colUnder;
  }

  getDraggableElemUnderDrag(x, y) {
    if (!this.state.wasClicked || !this.state.didMoveMinDistanceDuringDrag) {
      return undefined;
    }
    let cardUnder = null;
    // The Element we're dragging
    let draggingElement = this.draggable;
    if (draggingElement) {
      // Disable pointer events to look through element
      draggingElement.style.pointerEvents = "none";
      // Get element under dragged tasks (look through)
      let elementUnder = document.elementFromPoint(x, y);
      // Reset dragged element's pointers
      cardUnder = Util.getDraggableParentElement(elementUnder);
      draggingElement.style.pointerEvents = "all";
    }
    return cardUnder;
  }

  render() {
    const active = this.state.isDragging && this.state.wasClicked;
    const draggingStyle = {
      cursor: "move",
      position:
        this.state.didMoveMinDistanceDuringDrag || this.state.minDragDistanceMoved ? "fixed" : "",
      width: this.state.width,
      transition: "none",
      animation: "none",
      zIndex: 500,
      top:
        this.state.minDragDistanceMoved || this.state.didMoveMinDistanceDuringDrag
          ? this.state.top + "px"
          : 0,
      left:
        this.state.minDragDistanceMoved || this.state.didMoveMinDistanceDuringDrag
          ? this.state.left + "px"
          : 0
    };

    const commonStyle = {
      transform: "none",
      top: 0,
      left: 0,
      cursor:
        this.props.disabled || this.props.isSectionHeader
          ? "arrow"
          : this.state.wasClicked
          ? "move"
          : "grab",
      ...this.props.style
    };

    const propsObject = {
      "data-cy": "draggable-" + this.props.draggableId,
      className:
        (this.props.className || "") + " draggable " + (active ? this.props.dragActiveClass : ""),
      style: active ? { ...draggingStyle } : commonStyle,
      key: this.props.draggableId,
      draggableid: this.props.isSectionHeader
        ? "SECTION_HEADER_" +
          this.props.draggableId +
          (this.props.disableMove ? "_DISABLE_MOVE" : "")
        : this.props.draggableId,
      index: this.props.innerIndex,
      tabIndex: "0",
      ref: (div) => (this.draggable = div),
      onContextMenu: this.props.onContextMenu,
      onClick: this.props.onClick,
      "aria-grabbed": true,
      "aria-dropeffect": "move",
      "data-type": this.props.type,
      "data-disabled": this.props.disabled ? true : undefined,
      onTouchStart: (e) => this.onPointerDown(e, true),
      onMouseDown: (e) => this.onPointerDown(e, false)
    };

    return <div {...propsObject}>{this.props.children}</div>;
  }
}

Draggable.propTypes = {
  dragAndDropGroup: PropTypes.string.isRequired,
  draggableId: PropTypes.string.isRequired,
  droppableId: PropTypes.string,
  dragDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  isSectionHeader: PropTypes.bool,
  disableMove: PropTypes.bool,
  section: PropTypes.string,
  className: PropTypes.string,
  dragActiveClass: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  innerIndex: PropTypes.number
};

export default Draggable;
