import React from "react";
import PropTypes from "prop-types";
import { useDialogContext } from "../../dialog/DialogContext";
import { useBooksSelect } from "src/redux/selectors";
import { getBookIds } from "src/utils/URLUtils";
import WorkspaceDialog from "./WorkspaceDialog";
import useReaderWorkspaces from "src/hooks/useReaderWorkspaces";

const NewWorkspaceDialog = ({ panelIds, currentLayout, onConfirmCreateWS }) => {
  const { hideDialog } = useDialogContext();
  const books = useBooksSelect(getBookIds(panelIds));
  const { createWorkspace } = useReaderWorkspaces({ currentLayout, panelIds });
  const intiTitle = books.map((item) => item.code).join(" - ");
  const onConfirm = (title) => {
    if (title) {
      createWorkspace(title);
    }
    hideDialog();

    if (onConfirmCreateWS) {
      onConfirmCreateWS();
    }
  };

  return (
    <WorkspaceDialog
      titleDialog="addWorkspace"
      intiTitle={intiTitle}
      positiveText="add"
      onConfirm={onConfirm}
    />
  );
};

NewWorkspaceDialog.propTypes = {
  panelIds: PropTypes.string.isRequired,
  currentLayout: PropTypes.string.isRequired,
  onConfirmCreateWS: PropTypes.func,
};

export default NewWorkspaceDialog;
