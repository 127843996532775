import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const LongPressWrapper = ({
  children,
  onLongPress,
  onClick,
  onContextMenu,
  timeout = 1000,
  ...restProps
}) => {
  let timerRef = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const handlePress = useCallback(
    (e) => {
      e.persist();
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        if (typeof onLongPress === "function") {
          onLongPress(e);
        }

        timerRef.current = undefined;
      }, timeout);
    },
    [timeout, onLongPress],
  );

  const handleRelease = useCallback((e, onClick) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      if (onClick) {
        onClick(e);
      }
      return;
    }

    if (!e.defaultPrevented) {
      e.preventDefault();
    }
  }, []);

  return (
    <div
      {...restProps}
      onTouchStart={(e) => handlePress(e)}
      onTouchEnd={(e) => handleRelease(e)}
      onMouseDown={(e) => handlePress(e)}
      onClick={(e) => handleRelease(e, onClick)}
      onContextMenu={(e) => {
        e.preventDefault();
        handleRelease(e, onContextMenu);
      }}
      onMouseLeave={(e) => handleRelease(e)}>
      {children}
    </div>
  );
};

LongPressWrapper.propTypes = {
  children: PropTypes.node,
  onLongPress: PropTypes.func,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  timeout: PropTypes.number,
};

export default LongPressWrapper;
