import PropTypes from "prop-types";
import React from "react";

const IconView = (props) => {
  const { icon, className, ...allProps } = props;
  if (!icon) {
    return null;
  }
  if (typeof icon === "number") {
    if (allProps.fill) {
      allProps.style = { color: allProps.fill };
    }
    return (
      <i {...allProps} className={(className || "") + " egw-font-icon"}>
        {String.fromCodePoint(icon)}
      </i>
    );
  }

  const Icon = icon;
  return <Icon {...allProps} className={"icon-base " + className} />;
};

IconView.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
};

export default IconView;
