import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useDialogContext } from "src/components/dialog/DialogContext";
import { actionUpdateSubscriptions } from "src/components/subscriptions/subscriptions.actions";
import { formSubAction } from "src/components/subscriptions/SubscriptionsUtils";
import { useBookSelect } from "src/redux/selectors";
import { getBookId } from "src/shared/utils/content";
import { getSubscriptionRightBookType } from "src/utils/SubscriptionUtils";
import { useSubscriptionDialogs } from "./navigation.hooks";
import useAuth from "./useAuth";

const useSubscription = (bookId) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allSubs = useSelector((state) => state.subscriptions.allSubs);
  const subscription = !bookId
    ? undefined
    : (allSubs || []).find((sub) => getBookId(sub.book.book_id) === bookId);
  const subscriptionBooks = useSelector((state) => state.subscriptions.subscriptionBooks);
  const isCanSubscribe = !bookId ? false : subscriptionBooks.includes(bookId);

  const { isLogin, showLoginDialog } = useAuth(); 
  const { showConfirm } = useDialogContext();
  const { openAddSubscription } = useSubscriptionDialogs();
  const book = useBookSelect(bookId);

  const handleClickSubscribe = () => {
    if (!book) {
      return;
    }
    if (!isLogin) {
      showLoginDialog(() => {
        openAddSubscription({
          bookId: bookId,
          bookType: getSubscriptionRightBookType(book),
        });
      });
      return;
    }

    if (subscription) {
      showConfirm(t("deleteSub"), t("deleteSubWarn", { title: book.title }), () => {
        dispatch(actionUpdateSubscriptions(formSubAction(subscription, bookId)));
      });
    } else if (isCanSubscribe) {
      openAddSubscription({
        bookId: bookId,
        bookType: getSubscriptionRightBookType(book),
      });
    }
  };

  return { isCanSubscribe, isSubscribed: !!subscription, onClickSubscribe: handleClickSubscribe };
};

export default useSubscription;
