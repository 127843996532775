export const DBLCLICK_DELAY = 200;
export const TOUCH_CANCEL_DELAY = 800;

export const DEF_READER_FONT = "roboto_con";
export const DEF_READER_FONT_BLIND_MODE = "volkhov_regular";
export const DEF_READER_TEXT_ALIGN = "justify";

export const SCROLL_DIRECTIONS = {
  UNTOUCHED: "UNTOUCHED",
  UP: "up",
  DOWN: "down",
  LEFT: "left",
  RIGHT: "right"
};

export const COMPACT_READER_MODE_WIDTH = 500;

export const READER_PLAYER_BEHAVIOURS = {
  ASK: 1,
  HISTORY: 2,
  CURRENT_PARA: 3,
  CONTINUE: 4,
  FIRST_AVAILABLE: 5,
};
