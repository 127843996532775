export const SearchConstants = {
  ACTION_SEARCH: "ACTION_SEARCH",
  ACTION_CHANGE_SEARCH_LANG: "ACTION_CHANGE_SEARCH_LANG",
  ACTION_SEARCH_BY_RANGE: "ACTION_SEARCH_BY_RANGE",
  ACTION_SEARCH_BY_RANGE_SUCCESS: "ACTION_SEARCH_BY_RANGE_SUCCESS",
  ACTION_SEARCH_BY_RANGE_ERROR: "ACTION_SEARCH_BY_RANGE_ERROR",
  ACTION_SEARCH_SUGGESTION: "ACTION_SEARCH_SUGGESTION",
  ACTION_SEARCH_SUCCESS: "ACTION_SEARCH_SUCCESS",
  ACTION_SEARCH_QUERY: "ACTION_SEARCH_QUERY",
  ACTION_SEARCH_SUG_SUCCESS: "ACTION_SEARCH_SUG_SUCCESS",
  ACTION_SEARCH_HISTORY_DELETE: "ACTION_SEARCH_HISTORY_DELETE",
  ACTION_SEARCH_HISTORY: "ACTION_SEARCH_HISTORY",
  ACTION_SEARCH_HISTORY_SUCCESS: "ACTION_SEARCH_HISTORY_SUCCESS",
  ACTION_SEARCH_NEEDLE_CHANGE: "ACTION_SEARCH_NEEDLE_CHANGE",
  ACTION_SEARCH_TOPICS_SUCCESS: "ACTION_SEARCH_TOPICS_SUCCESS",
  ACTION_SEARCH_TYPE: "ACTION_SEARCH_TYPE",
  ACTION_SEARCH_BOOK_LIST_SUPPRESS: "ACTION_SEARCH_BOOK_LIST_SUPPRESS",
  FETCH_SUGGESTION: "FETCH_SUGGESTION",
  FETCH_SUGGESTION_SUCCESS: "FETCH_SUGGESTION_SUCCESS",
  FETCH_DETECT: "FETCH_DETECT",
  FETCH_DETECT_SUCCESS: "FETCH_DETECT_SUCCESS",
  FETCH_TRANSLATE: "FETCH_TRANSLATE",
  FETCH_POSSIBLE_TRANSLATE: "FETCH_POSSIBLE_TRANSLATE",
  FETCH_POSSIBLE_TRANSLATE_SUCCESS: "FETCH_POSSIBLE_TRANSLATE_SUCCESS",
  FETCH_TOPICS_SUCCESS: "FETCH_TOPICS_SUCCESS",
  FETCH_SHORTCUT_SUCCESS: "FETCH_SHORTCUT_SUCCESS",
  FETCH_LANGS_FOR_WORD_SUCCESS: "FETCH_LANGS_FOR_WORD_SUCCESS",
  FETCH_SEARCH_DICTIONARY: "FETCH_SEARCH_DICTIONARY",
  FETCH_SEARCH_DICTIONARY_SUCCESS: "FETCH_SEARCH_DICTIONARY_SUCCESS",
  ACTION_CLEAR_SEARCH: "ACTION_CLEAR_SEARCH",
  ACTION_UPDATE_SEARCH_PERIOD: "ACTION_UPDATE_SEARCH_PERIOD",
  SEARCH_WITH_CURRENT_TREE: "s/SEARCH_WITH_CURRENT_TREE",
  ACTION_GET_SHORTCUT_CONTENT: "ACTION_GET_SHORTCUT_CONTENT",
  ACTION_GET_SHORTCUT_CONTENT_SUCCESS: "ACTION_GET_SHORTCUT_CONTENT_SUCCESS",
  ACTION_IS_SHOW_FILTER_SEARCH: "ACTION_IS_SHOW_FILTER_SEARCH",
  ACTION_UPDATE_SEARCH_BIBLE: "ACTION_UPDATE_SEARCH_BIBLE",
  ACTION_CLEAR_LOCAL_SEARCH: "ACTION_CLEAR_LOCAL_SEARCH",
  ACTION_TOGGLE_SEARCH_MARK: "ACTION_TOGGLE_SEARCH_MARK",
  ACTION_CHANGE_RELATED_LANG: "ACTION_CHANGE_RELATED_LANG",
  SEARCH_BOOK_BY_NAME: "SEARCH_BOOK_BY_NAME",
  SEARCH_BOOK_BY_NAME_SUCCESS: "SEARCH_BOOK_BY_NAME_SUCCESS",
  SET_RIGHT_PANEL_POSITION: "SET_RIGHT_PANEL_POSITION",
  ACTION_UPDATE_DICTIONARY_FILTER: "ACTION_UPDATE_DICTIONARY_FILTER",
  UPDATE_SHOW_HEADER_SEARCH: "UPDATE_SHOW_HEADER_SEARCH",
};

export const SearchActions = {
  fetchSearch: (searchQueryParams, index, delay) => {
    return {
      type: SearchConstants.ACTION_SEARCH,
      data: {
        searchQueryParams,
        index,
        delay
      },
    };
  },
  fetchSearchByRange: (data) => ({
    type: SearchConstants.ACTION_SEARCH_BY_RANGE,
    data,
  }),
  fetchSearchByRangeSuccess: (data) => ({
    type: SearchConstants.ACTION_SEARCH_BY_RANGE_SUCCESS,
    data,
  }),
  fetchSearchByRangeError: (data) => ({
    type: SearchConstants.ACTION_SEARCH_BY_RANGE_ERROR,
    data,
  }),
  fetchSearchHistory: (data) => ({
    type: SearchConstants.ACTION_SEARCH_HISTORY,
    data,
  }),
  fetchSearchHistorySuccess: (data) => ({
    type: SearchConstants.ACTION_SEARCH_HISTORY_SUCCESS,
    data,
  }),
  fetchSearchSuccess: (content) => {
    return {
      type: SearchConstants.ACTION_SEARCH_SUCCESS,
      data: content,
    };
  },
  /**
   * This method and value looks unnessesary and make
   * @param {*} searchRequest
   * @returns
   */
  setSearchQuery: (searchQuery, lang) => ({
    type: SearchConstants.ACTION_SEARCH_QUERY,
    data: { searchQuery, lang },
  }),
  setSearchType: (data) => ({
    type: SearchConstants.ACTION_SEARCH_TYPE,
    data,
  }),
  fetchSearchSuggestions: (searchRequest) => ({
    type: SearchConstants.ACTION_SEARCH_SUGGESTION,
    data: searchRequest,
  }),
  fetchSearchSugSuccess: (content) => ({
    type: SearchConstants.ACTION_SEARCH_SUG_SUCCESS,
    data: content,
  }),
  fetchSearchTopicsSuccess: (content) => ({
    type: SearchConstants.ACTION_SEARCH_TOPICS_SUCCESS,
    data: content,
  }),
  setSearchNeedleChange: (content) => ({
    type: SearchConstants.ACTION_SEARCH_NEEDLE_CHANGE,
    data: content,
  }),
  setSearchBookListSuppress: (data) => ({
    type: SearchConstants.ACTION_SEARCH_BOOK_LIST_SUPPRESS,
    data,
  }),
  fetchSuggestion: (query, currentLang, withDetect) => ({
    type: SearchConstants.FETCH_SUGGESTION,
    data: { query, currentLang, withDetect },
  }),
  fetchDetectLang: (query, langs, currentLang) => ({
    type: SearchConstants.FETCH_DETECT,
    data: { query, langs, currentLang },
  }),
  fetchDetectSuccess: (detect, translate) => ({
    type: SearchConstants.FETCH_DETECT_SUCCESS,
    data: { detect, translate },
  }),
  fetchSuggestionSuccess: (allData = {}) => ({
    type: SearchConstants.FETCH_SUGGESTION_SUCCESS,
    data: allData,
  }),
  fetchTranslate: (fromLang, toLang, query) => ({
    type: SearchConstants.FETCH_TRANSLATE,
    data: { fromLang, toLang, query },
  }),
  fetchPossibleTranslate: (lang) => ({
    type: SearchConstants.FETCH_POSSIBLE_TRANSLATE,
    data: { lang },
  }),
  fetchPossibleTranslateSuccess: (translate) => ({
    type: SearchConstants.FETCH_POSSIBLE_TRANSLATE_SUCCESS,
    data: { translate },
  }),
  fetchTopicsSuccess: (topicQuery, topics) => ({
    type: SearchConstants.FETCH_TOPICS_SUCCESS,
    data: { topicQuery, topics },
  }),
  fetchShortcutSuccess: (data) => ({
    type: SearchConstants.FETCH_SHORTCUT_SUCCESS,
    data,
  }),
  fetchLangsForWordSuccess: (langs, query, correction) => ({
    type: SearchConstants.FETCH_LANGS_FOR_WORD_SUCCESS,
    data: { langs, query, correction },
  }),
  searchDictionary: (data) => ({
    type: SearchConstants.FETCH_SEARCH_DICTIONARY,
    data,
  }),
  searchDictionarySuccess: (data) => ({
    type: SearchConstants.FETCH_SEARCH_DICTIONARY_SUCCESS,
    data,
  }),
  clearSearch: (data) => ({
    type: SearchConstants.ACTION_CLEAR_SEARCH,
    data,
  }),
  updateSearchPeriod: (data) => ({
    type: SearchConstants.ACTION_UPDATE_SEARCH_PERIOD,
    data,
  }),
  searchWithCurrentTree: () => ({
    type: SearchConstants.SEARCH_WITH_CURRENT_TREE,
  }),
  fetchShotcutContent: (data) => ({
    type: SearchConstants.ACTION_GET_SHORTCUT_CONTENT,
    data,
  }),
  fetchShotcutContentSuccess: (data) => ({
    type: SearchConstants.ACTION_GET_SHORTCUT_CONTENT_SUCCESS,
    data,
  }),
  setShowFilterSearch: (data) => ({
    type: SearchConstants.ACTION_IS_SHOW_FILTER_SEARCH,
    data,
  }),
  updateSearchBible: (data) => ({
    type: SearchConstants.ACTION_UPDATE_SEARCH_BIBLE,
    data,
  }),
  toogleSearchMark: (data) => ({
    type: SearchConstants.ACTION_TOGGLE_SEARCH_MARK,
    data,
  }),
  clearLocalSearch: () => ({
    type: SearchConstants.ACTION_CLEAR_LOCAL_SEARCH,
  }),
  deleteSearchHistory: () => ({
    type: SearchConstants.ACTION_SEARCH_HISTORY_DELETE,
  }),
  changeRelatedLang: (data) => ({
    type: SearchConstants.ACTION_CHANGE_RELATED_LANG,
    data,
  }),
  searchBookByName: (data) => ({
    type: SearchConstants.SEARCH_BOOK_BY_NAME,
    data,
  }),
  searchBookByNameSuccess: (data) => ({
    type: SearchConstants.SEARCH_BOOK_BY_NAME_SUCCESS,
    data,
  }),
  setRightPanelPosition: (content) => ({
    type: SearchConstants.SET_RIGHT_PANEL_POSITION,
    data: content,
  }),
  updateDictionaryFilter: (data) => ({
    type: SearchConstants.ACTION_UPDATE_DICTIONARY_FILTER,
    data,
  }),
  updateShowHeaderSearch: (data) => ({
    type: SearchConstants.UPDATE_SHOW_HEADER_SEARCH,
    data,
  }),
};
