import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { decode } from "html-entities";

import IconView from "src/shared/components/IconView";
import EgwWebFont from "src/assets/EgwWebFont";
import { useAuth } from "src/hooks";
import { RootFolder, SubscriptionTopicFolder } from "./studyCenter.constants";
import { ChevronRightIcon, ChevronDownIcon } from "../../assets/CommonIcons";
import { Scroll } from "../views";

import "./ScTree.scss";

const ScTreeViewFolders = ({ onItemClick, current, treeList, expanded = [] }) => {
  const { t } = useTranslation();
  const { isUserEditor } = useAuth();

  const renderRow = (item, index) => {
    const { showExpand, isExpanded } = item;
    const ExpandIcon = isExpanded ? ChevronDownIcon : ChevronRightIcon;
    const paddingLeft = item.level ? item.level * 5 + 20 : 5;

    let itemTitle = item.title;
    if (item.id === RootFolder.id) {
      itemTitle = t(isUserEditor ? item.titleEditor : item.title);
    }
    if (item.id === SubscriptionTopicFolder.id) {
      itemTitle = t(item.title);
    }

    return (
      <div
        key={index}
        style={{ paddingLeft }}
        className={classNames("sc-drg sc-tree-row", {
          "active-row": expanded.includes(item.id) || item.isExpanded,
          active: current === item.id,
        })}
        onClick={() => onItemClick(item)}>
        <div className="sc-tree-title-part">
          {showExpand && <ExpandIcon className="sc-tree-expand" />}
          <IconView
            icon={isExpanded ? EgwWebFont["folder-open"] : EgwWebFont["folder-close"]}
            className={"sc-tree-icon"}
          />
          <span className={"sc-tree-title"}>{decode(itemTitle)}</span>
        </div>
      </div>
    );
  };

  return (
    <Scroll className="sc-tree-scroll dialog-scroll-wrapper" hasStaticPosition>
      {treeList.map(renderRow)}
    </Scroll>
  );
};

ScTreeViewFolders.propTypes = {
  treeList: PropTypes.array,
  current: PropTypes.string,
  onItemClick: PropTypes.func,
  expanded: PropTypes.array,
};

export default ScTreeViewFolders;
