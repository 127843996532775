import PropTypes from "prop-types";
import React from 'react';
import FacebookAuth from 'react-facebook-auth';
import { FacebookId } from "../../shared/utils/facebook";


const FacebookAuthButton = ({ onRenderComponent, onFbAuth }) => {
  return <FacebookAuth
    appId={FacebookId}
    reAuthenticate={true}
    callback={(response) => {
      if (response.accessToken) {
        let date = new Date();
        date.setTime(response.data_access_expiration_time * 1000);
        onFbAuth({
          facebook_id: response.userID,
          facebook_access_token: response.accessToken,
          facebook_expire_time: date.toISOString()
        });
      }
    }}
    component={({ onClick }) => onRenderComponent(onClick)} />;
};

FacebookAuthButton.propTypes = {
  onFbAuth: PropTypes.func,
  onRenderComponent: PropTypes.func
};

export default FacebookAuthButton;
