import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Badge from "../Badge"; 
import { useTranslation } from "react-i18next";
import BookCoverView from "src/shared/components/BookCoverView";
import useTreeContextMenu from "../sideMenu/TreeContextMenu";
import { CONTENT_CLASSES } from "../../shared/utils/content";

const getFullWidth = (element) => {
  const style = element.currentStyle || window.getComputedStyle(element),
    width = element.offsetWidth, // or use style.width
    margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
    padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight),
    border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);

  return parseInt(width + margin + padding + border, 10);
};

const MIN_SHOW_COUNT = 2;
const MAX_SHOW_COUNT = 5;

export const FeaturedSectionContent = ({ book, openReader, withoutMore }) => {
  const { t } = useTranslation();
  const contentRef = useRef();
  const hiddenContentRef = useRef();
  const [elementCount, setElementCount] = useState(0);
  const [isShowMore, setElementWidth] = useState(false);
  const [isMore, setIsMore] = useState(MIN_SHOW_COUNT);
  const openTreeContextMenu = useTreeContextMenu();

  useEffect(() => {
    if (contentRef.current && hiddenContentRef.current) {
      const containerWidth = contentRef.current.offsetWidth;
      const items = hiddenContentRef.current.querySelectorAll(".featured-section__link");
      const threeLinesWidth = containerWidth * isMore + (containerWidth / 2);

      let elementsWidth = 0;
      let elementsCount = 0;
      let isShowMore = false;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const width = getFullWidth(item);

        if (elementsWidth < threeLinesWidth) {
          elementsWidth += width;
          elementsCount += 1;
        } else {
          isShowMore = true;
        }
      }
      setElementWidth(isShowMore);
      setElementCount(elementsCount);
    }
  }, [book.links, isMore]);

  const links = useMemo(() => {
    if (elementCount === 0) {
      return book.links;
    }
    return book.links.slice(0, elementCount);
  }, [book.links, elementCount]);

  const toggleShow = () => {
    if (isMore === MIN_SHOW_COUNT) {
      setIsMore(MAX_SHOW_COUNT);
    } else {
      setIsMore(MIN_SHOW_COUNT);
    }
  };

  return (
    <div key={book.book_id} className="featured-section__item">
      <div className="featured-section__image">
        <BookCoverView book={book} />
      </div>
      <div className="featured-section__content">
        <div className="featured-section__title">
          <span className="featured-section__title-text">{book.title}</span>
          <Badge number={book.links.length} />
        </div>
        <ul className="featured-section__links hidden" ref={hiddenContentRef}>
          {book.links.map((link) => (
            <li key={link.para_id} className="featured-section__link">
              <span className="featured-section__link-text">
                {link.ref}
              </span>
            </li>
          ))}
        </ul>
        <ul className="featured-section__links" ref={contentRef}>
          {links.map((link, linkIndex) => {
            const isLastItem = links.length - 1 === linkIndex;
            const isActiveMore = isMore === MAX_SHOW_COUNT;

            return (
              <li
                key={link.para_id}
                className="featured-section__link"
                onContextMenu={(e) => openTreeContextMenu(e, {
                  ...link,
                  className: CONTENT_CLASSES.PARAGRAPH,
                  id: link.para_id,
                }, true)}
              >
                <span
                  className="featured-section__link-text"
                  onClick={() => openReader(link.para_id)}
                  dangerouslySetInnerHTML={{ __html: link.ref }}
                />
                {isLastItem && (isShowMore && !withoutMore) && (
                  <span
                    className="featured-section__toggle"
                    onClick={toggleShow}
                  >
                    <div
                      className={classNames(
                        "featured-section__more with-chevron-less-more", {
                          open: isActiveMore,
                        })}
                    >
                      <span>{t(isActiveMore ? "less" : "more")}</span>
                    </div>
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

FeaturedSectionContent.propTypes = {
  book: PropTypes.object,
  index: PropTypes.number,
  openReader: PropTypes.func,
  withoutMore: PropTypes.bool,
};
