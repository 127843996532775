//* eslint-disable no-console */
import { deviceType, osName, osVersion, browserVersion, browserName } from "react-device-detect";
import axios from "axios";
import { v4 as uuid } from "uuid";

import { getSessionToken, getStableToken, localStorageSafe } from "../utils/systemUtils";
import { getBaseHost, getSearchLogsUrl, getSearchLogsToken } from "../utils/url";
import { getSystemLang } from "../utils/user-agent";
import { isProduction, isStaging } from "../utils/assembly";

const userAgentString = osName + " " + osVersion + " " + browserName + " " + browserVersion;

const IS_DEV = false; // enable this if need to develop this module

// Runtime stores
const mapLogTypeToTimeoutId = {};
const currentlySendingLogTypes = new Set();

// Log types
const LOG_TYPE_SEARCH = "logSearch";
const LOG_TYPE_NETWORK = "logNetwork";
export const logTypes = [LOG_TYPE_SEARCH, LOG_TYPE_NETWORK];

// AWS logger flags
const isLogNetworkAWS = () => IS_DEV;
const isLogSearchAWS = () => IS_DEV || isProduction();
export const isCanLogAnythingToAWS = () => isLogNetworkAWS() || isLogSearchAWS();

// Tech logger flags
const isLogNetworkTech = () => IS_DEV || isStaging();
const isLogSearchTech = () => IS_DEV || isStaging() || isProduction();

// Common flags
const isLogNetworkNeeded = () => isLogNetworkAWS() || isLogNetworkTech();
const isLogSearchNeeded = () => isLogSearchAWS() || isLogSearchTech();

// Getters
const getSendTimeoutByLogType = (logType) => {
  if (logType === LOG_TYPE_SEARCH) {
    return 300_000; // 5 min
  }

  return 600_000; // 10 minutes
};
const getLogItemsCountMaxByLogType = (logType) => {
  if (logType === LOG_TYPE_SEARCH) {
    return 21;
  }

  return 60;
};
const getCanSendToAWSByLogType = (logType) => {
  if (logType === LOG_TYPE_SEARCH) {
    return isLogSearchAWS();
  }

  return isLogNetworkAWS();
};
const getCanSendToTechByLogType = (logType) => {
  if (logType === LOG_TYPE_SEARCH) {
    return isLogSearchTech();
  }

  return isLogNetworkTech();
};

const techLogsEndpointOrigin = "https://yo.pages.egwhite.net";
// const techLogsEndpointOrigin = "http://localhost:8080"; // local Tech server url
export const makeTechLogsEndpoint = (logType) => {
  const endPoint = logType === LOG_TYPE_NETWORK ? "network" : "search";
  return techLogsEndpointOrigin + "/api/v1/log/" + endPoint;
};

// Search
export const SearchLogEvents = {
  SEARCH: "search",
  SEARCH_SHORTCUT: "search_shortcut",
  SEARCH_CHANGE_LANG: "search_change_lang",
  SEARCH_LOAD_MORE: "search_load_more",
  SEARCH_CHANGE_POSITION: "search_change_position",
  SEARCH_CLICK: "search_click",
  SEARCH_CLICK_CORRECTION: "search_click_correction",
  SEARCH_CLICK_SUGGESTION: "search_click_suggestion",
  SEARCH_CLICK_HISTORY: "search_click_history",
};

export const logSearchEvent = (eventType, eventData) => {
  if (!isLogSearchNeeded()) {
    return;
  }
  // TODO add some data
  const webpage_url = window.location.origin + window.location.pathname;
  const fullEventData = {
    event_id: uuid(),
    event_type: eventType,
    params: eventData,
    user_id: window.userId || "",
    user_lang: window.userLang || getSystemLang(),
    date: new Date().toISOString(),
    cookie_id: getStableToken(),
    session_id: getSessionToken(),
    webpage_url,
    device_type: deviceType,
  };
  logEvent(LOG_TYPE_SEARCH, fullEventData);
};

// Network
export const logNetworkEvent = (eventData) => {
  if (!isLogNetworkNeeded()) {
    return;
  }
  // TODO add some data
  const fullEventData = {
    ...eventData,
    userId: window.userId || "",
    ua: userAgentString,
    appUrl: getBaseHost(),
    date: new Date().toISOString(),
  };
  logEvent(LOG_TYPE_NETWORK, fullEventData);
};

// Common
export const getStoredLogs = (logType) => {
  const defaultValue = [];
  const analiticDataString = localStorageSafe.getItem(logType);
  let analiticDataJson;
  if (analiticDataString) {
    try {
      analiticDataJson = JSON.parse(analiticDataString);
      if (!Array.isArray(analiticDataJson)) {
        analiticDataJson = defaultValue;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("background: black; color: white;[getStoredLogs] Error: ", analiticDataString);
    }
  } else {
    analiticDataJson = defaultValue;
  }
  return analiticDataJson;
};

const logEvent = (logType, eventData) => {
  clearTimeout(mapLogTypeToTimeoutId[logType]);
  const analiticDataJson = getStoredLogs(logType);
  analiticDataJson.push(eventData);
  localStorageSafe.setItem(logType, JSON.stringify(analiticDataJson));

  if (IS_DEV) {
    console.log(`%c${analiticDataJson.length}`,
      `background: ${logType === LOG_TYPE_NETWORK ? "red" : "blue"}; color: white;`
    );
  }

  const isSendImmediately = analiticDataJson.length > getLogItemsCountMaxByLogType(logType)
    && !currentlySendingLogTypes.has(logType);

  if (isSendImmediately) {
    if (IS_DEV) {
      console.log(`%cSend immediately ${logType}`, "background: gold; color: white;");
    }
    sendLogsByType(logType);
  } else {
    mapLogTypeToTimeoutId[logType] = setTimeout(() => {
      sendLogsByType(logType);
    }, getSendTimeoutByLogType(logType));
  }
};

export const flushStoredLogsByData = (logType, logData) => {
  const allData = getStoredLogs(logType);
  const simpleList = logData.map((item) => {
    return item.date;
  });
  const clearList = allData.filter((item) => !simpleList.includes(item.date));
  if (IS_DEV) {
    console.log(`flushLogData, new list: `, logType, clearList);
  }
  localStorageSafe.setItem(logType, JSON.stringify(clearList));
};

const mapSearchLogItemForAWSLogger = (searchLogItem) => {
  const out = {...searchLogItem};

  out.language = searchLogItem.user_lang;
  out.timestamp = searchLogItem.date;
  out["event_data"] = searchLogItem.params;

  delete out.user_lang;
  delete out.date;
  delete out.params;

  return out;
};

const sendLogsByType = async (logType) => {
  const logData = getStoredLogs(logType);
  if (IS_DEV) {
    console.groupCollapsed("Send logs group");
    console.log({ logType, logData });
  }
  if (logData.length) {
    let egwTechReqResponse, awsReqResponse, isEgwTechError, isAWSError;
    const isCanSendToEgwTechLogger = getCanSendToTechByLogType(logType);
    const isCanSendToAWSLogger = getCanSendToAWSByLogType(logType);

    currentlySendingLogTypes.add(logType);

    // EGW Tech logger
    if (isCanSendToEgwTechLogger) {
      try {
        egwTechReqResponse = await axios({
          method: "post",
          url: makeTechLogsEndpoint(logType),
          data: logData,
        });
      } catch (e) {
        isEgwTechError = true;
      }
    }

    // AWS logger
    if (isCanSendToAWSLogger) {
      try {
        awsReqResponse = await axios({
          method: "post",
          headers: {
            "x-api-key": getSearchLogsToken(),
          },
          url: getSearchLogsUrl(),
          data: {
            records: logData.map(mapSearchLogItemForAWSLogger),
          },
        });
      } catch (e) {
        isAWSError = true;
      }
    }

    // for now, ignore if some api failed to not overfill the storage or send the same data twice.
    const isCanFLush = (isCanSendToAWSLogger && !isAWSError)
      || (isCanSendToEgwTechLogger && !isEgwTechError);

    if (isCanFLush) {
      flushStoredLogsByData(logType, logData);
    }

    currentlySendingLogTypes.delete(logType);

    if (IS_DEV) {
      console.log("Flush " + (isCanFLush ? "✅" : "❌"));
      console.table({
        isCanSendToAWSLogger,
        isCanSendToEgwTechLogger,
        isAWSError,
        isEgwTechError,
      });
      console.log("Logs requests: ", { egwTechReqResponse, awsReqResponse, });
    }
  }
  if (IS_DEV) {
    console.groupEnd("Send logs method");
  }
};
