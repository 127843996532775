import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  featuredSearchActions,
  FeaturedSearchActionsConstants,
  LOADER_ID_FEATURED_SEARCH_SECTIONS
} from "./featuredSearch.actions";
import {
  fetchFeaturedForParagraph,
  fetchFeaturedSearchRequest
} from "../relevantSearch/RelevantSearchAPI";
import { actionLoading } from "../../redux/actions";

function* _getFeaturedSearchResultCached(cacheKey) {
  const featuredSearchCache = yield select((state) => state.featuredSearch.cache);
  return featuredSearchCache[cacheKey];
}

function* _handlerFetchFeaturedSearch(cacheKey, fetchFn) {
  let isLoaderWasEnabled = false;

  try {
    let data;

    const dataCached = yield _getFeaturedSearchResultCached(cacheKey);
    if (dataCached) {
      const sections = yield select((state) => state.featuredSearch.sections);

      if (sections === dataCached) {
        return;
      }

      data = dataCached;
    } else {
      yield put(actionLoading(LOADER_ID_FEATURED_SEARCH_SECTIONS));
      isLoaderWasEnabled = true;
      data = yield call(fetchFn);
    }

    yield put(featuredSearchActions.fetchSuccess(cacheKey, data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("%c[Featured Search] Error: ", "background: red; color: white;", error);
  } finally {
    if (isLoaderWasEnabled) {
      yield put(actionLoading(LOADER_ID_FEATURED_SEARCH_SECTIONS, true));
    }
  }
}

function* fetchFeaturedSearchWorker(action) {
  const { query, langs } = action.data;

  if (!query) {
    return;
  }

  const cacheKey = query + (langs || [] ).toString();

  const fetchFn = () => {
    return fetchFeaturedSearchRequest(query, langs);
  };

  yield call(_handlerFetchFeaturedSearch, cacheKey, fetchFn);
}

function* fetchFeaturedForParagraphWorker(action) {
  const paraId = action.data;

  const fetchFn = () => {
    return fetchFeaturedForParagraph(paraId);
  };

  yield call(_handlerFetchFeaturedSearch, paraId, fetchFn);
}

export default function* featuredSearchSaga () {
  yield all([
    takeLatest(FeaturedSearchActionsConstants.FETCH, fetchFeaturedSearchWorker),
    takeLatest(FeaturedSearchActionsConstants.FETCH_FOR_PARAGRAPH, fetchFeaturedForParagraphWorker),
  ]);
};
