import { useDispatch } from "react-redux";
import { useAuth } from "./index";
import { useCallback, useEffect } from "react";
import { actionUpdateSubsCount } from "../components/subscriptions/subscriptions.actions";
import useSetTimeoutWithPause from "./useSetTimeoutWithPause";

const TIMEOUT_UPDATE_SUBS_COUNT = 900_000;

const useAutoRefreshSubsCount = () => {
  const dispatch = useDispatch();
  const { isLogin } = useAuth();

  const refreshSubsCount = useCallback(() => {
    dispatch(actionUpdateSubsCount());
  }, []);

  const {
    pauseTimer,
    startOrResumeTimer,
    clearTimer,
    restartTimer,
    timestampStartRef,
  } = useSetTimeoutWithPause({
    timeout: TIMEOUT_UPDATE_SUBS_COUNT,
    isSetIntervalBehaviour: true,
    handler: refreshSubsCount,
  });

  // Update subs count each {TIMEOUT_UPDATE_SUBS_COUNT} if authorized, with pause on window blur.
  useEffect(() => {
    if (!isLogin) {
      return () => {};
    }

    const handleWindowFocus = () => {
      // If user back to the tab and it's passed more than {TIMEOUT_UPDATE_SUBS_COUNT}
      // from the last timer start.
      if (Date.now() - timestampStartRef.current >= TIMEOUT_UPDATE_SUBS_COUNT) {
        refreshSubsCount();
        restartTimer();
      } else {
        startOrResumeTimer();
      }
    };

    window.addEventListener("blur", pauseTimer);
    window.addEventListener("focus", handleWindowFocus);

    startOrResumeTimer();

    return () => {
      clearTimer();

      window.removeEventListener("blur", pauseTimer);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [
    restartTimer, startOrResumeTimer, pauseTimer, clearTimer,
    isLogin, timestampStartRef, refreshSubsCount,
  ]);
};

export default useAutoRefreshSubsCount;
