import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import SplitPane from "react-split-pane";
import * as classnames from "classnames";
import PlayerComponent from "../bookPlayer/Player/PlayerComponent";
import { Loading } from "../index";
import { getQueryByName, makeNavigationUrl } from "../../utils/URLUtils";
import ImageBlock from "./ImageBlock";
import DescriptionBlock from "./DescriptionBlock";
import BookTocBlock from "./BookTocBlock";
import BookHeader from "./BookHeader";
import { useResizeDimensions, useViewMode } from "../../hooks";
import { setPlayerParams, actionAddMessage, actionGetTranslations } from "../../redux/actions";
import { useNavigationContext } from "../NavigationContext";
import { Scroll } from "../views";
import { PLAYER_EVENTS, useAudioContext } from "../bookPlayer/AudioContext";
import { useHistory } from "react-router-dom";
import { useBookSelect } from "../../redux/selectors";
import { useAudioContextWithCurrentTime } from "../bookPlayer/AudioContext.hooks";
import { isSameBook } from "../../shared/utils/content";
import { useResizerContext } from "../resizer/Resizer";
import { useShopBook } from "src/hooks/useShop";
import View404 from "../view404/View404";
import { sessionStorageSafe } from "../../shared/utils/systemUtils";
import { ContentActions } from "src/redux/content.actions";

import "./BookDetails.scss";

const BookDetailsPage = ({ match, location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mainWidthInner: width } = useResizerContext();
  const { isMobile, isTablet, isMobileOrTablet } = useViewMode();

  /* Inits audio context and its additional hook to get curr time, with optimized configuration. */
  const waveformsDictionary = useSelector((state) => state.audioPlayer.waveformsDictionary);
  const audioContext = useAudioContext();
  const { chapterId, savePlayPosition } = audioContext;
  const waveform = waveformsDictionary[chapterId]?.waveform;
  const audioContextWithCurrentTime = useAudioContextWithCurrentTime(audioContext, {
    delayUpdateTime: !isMobile && waveform ? 65 : 1000,
  });
  const {
    getPlayerPosition,
    initPlay,
    subscribePlayerEvents,
    unsubscribePlayerEvents,
    isPlaying,
    audioChapters,
  } = audioContextWithCurrentTime;
  /* ======================== */

  const [selectedTrack] = audioChapters;
  const { isPlayer: isPlayerPage } = useNavigationContext();
  const { PANE_WIDTH_BOOK_DETAILS } = useResizeDimensions();
  const [isTocVisible, setIsTocVisible] = useState(false);
  const [tocInitialStateChosen, setTocInitialStateChosen] = useState(false);
  const [firstPaneWidth, setFirstPaneWidth] = useState(PANE_WIDTH_BOOK_DETAILS);
  const { bookId } = match.params;
  const isErrorBookLoad = useSelector((state) => state.system.errorBookLoad.includes(bookId));
  const book = useBookSelect(bookId);
  const {
    isAvailable,
    loading: loadingShop,
    shopBooks,
    shopBook,
    shopPackage,
    packageShopBookIds,
  } = useShopBook(book);

  const withHistory = getQueryByName("history", location.search) === "1";
  const locationChapterId = getQueryByName("listen", location.search);
  const locationPosition = location.hash ? parseInt(location.hash.substr(1)) : null;

  const toggleTocVisibility = () => setIsTocVisible(!isTocVisible);

  const updateLocationAudioPosition = useCallback(
    (position) => {
      if (selectedTrack) {
        history.replace(
          makeNavigationUrl(
            {
              ...selectedTrack,
              position: Math.round(position),
            },
            { isAudio: true },
          ),
        );
      }
    },
    [history, selectedTrack],
  );

  // Starts playing when book changed from the Breadcrumbs.
  useEffect(() => {
    if (chapterId && locationChapterId && !isSameBook(chapterId, locationChapterId)) {
      initPlay(locationChapterId, locationPosition || 0);
    }
  }, [locationChapterId]);

  // Inits player data from the location (e.g. player is opened with a link).
  useEffect(() => {
    if (isPlayerPage && !chapterId) {
      initPlay(locationChapterId, locationPosition || 0, false);
    }
  }, []);

  // Sets location audio position (e.g. listen audio, go Book Details, go Player).
  useEffect(() => {
    if (isPlayerPage) {
      if (isSameBook(locationChapterId, chapterId) && !locationPosition) {
        updateLocationAudioPosition(getPlayerPosition());
      }
    }
  }, [isPlayerPage, locationChapterId]);

  // Updates location player data on base player events.
  useEffect(() => {
    const listener = ({ event }) => {
      if (
        event === PLAYER_EVENTS.SKIP ||
        event === PLAYER_EVENTS.SEEK ||
        event === PLAYER_EVENTS.PAUSE ||
        event === PLAYER_EVENTS.PLAY
      ) {
        updateLocationAudioPosition(getPlayerPosition());
      }
    };

    if (isPlayerPage) {
      subscribePlayerEvents(listener);
    }

    return () => {
      if (isPlayerPage) {
        unsubscribePlayerEvents(listener);
      }
    };
  }, [isPlayerPage, subscribePlayerEvents, unsubscribePlayerEvents, updateLocationAudioPosition]);

  // Fix the book chapters or children had not fetched yet.
  useEffect(() => {
    if (!book || !book?.children || !book?.chapters || (isAvailable && book.children)) {
      dispatch(ContentActions.fetchBookDetailsRequest(bookId));
    }
    if (book?.lang) {
      dispatch(actionGetTranslations(book?.lang));
    }
  }, [bookId, book, isAvailable]);

  // Sync page's Book Content and player's Book Content open states.
  useEffect(() => {
    dispatch(
      setPlayerParams({
        showContent: isTocVisible,
      }),
    );
  }, [isTocVisible]);

  // show message if book has just been purchased
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const order = search.get("order");
    if (order) {
      // TODO parse the params and add to the message,
      // most probably there will be book ids of purchased
      if (order === "true") {
        dispatch(actionAddMessage("@congratsOnPurchase"));
        sessionStorageSafe.removeItem("cart");
      } else {
        dispatch(actionAddMessage("@notPurchased"));
      }
      history.replace({
        search: "",
      });
    }
  }, [location.search, history, dispatch]);

  // Hide small player on Book Details Audio page and show on unmount if audio is playing.
  useEffect(() => {
    if (isPlayerPage) {
      dispatch(
        setPlayerParams({
          isSmallPlayerActive: false,
        }),
      );
    }

    return () => {
      if (isPlayerPage) {
        dispatch(
          setPlayerParams({
            isSmallPlayerActive: isPlaying,
            showContent: false,
          }),
        );
      }
    };
  }, [isPlaying, isPlayerPage]);

  // Save listen history on player page close.
  useEffect(() => {
    return () => {
      if (isPlayerPage) {
        savePlayPosition();
      }
    };
  }, [isPlayerPage, savePlayPosition]);

  let isPlaceOnlyForOneColumn = firstPaneWidth < PANE_WIDTH_BOOK_DETAILS;
  let isNeedCompactViewMode = width < PANE_WIDTH_BOOK_DETAILS * 2;

  useEffect(() => {
    if (book && !book.isForSale && !tocInitialStateChosen && width) {
      if (!isPlaceOnlyForOneColumn && !isNeedCompactViewMode && !isMobileOrTablet) {
        setIsTocVisible(true);
      }
      setTocInitialStateChosen(true);
    }
  }, [tocInitialStateChosen, width, book]);

  if (isErrorBookLoad) {
    return <View404 />;
  }

  if (!book || (loadingShop && book?.isForSale)) {
    return <Loading type={Loading.types.BOOK_INFO} />;
  }

  const imageBlockElement = (
    <ImageBlock
      book={book}
      withHistory={withHistory}
      shopBook={shopBook}
      isAvailable={isAvailable}
      shopPackage={shopPackage}
    />
  );

  const descriptionBlockElement = (
    <DescriptionBlock
      book={book}
      isTocVisible={isTocVisible}
      disableContentBtn={!isAvailable}
      shopBooks={shopBooks}
      shopPackage={shopPackage}
      packageShopBookIds={packageShopBookIds}
      onButtonTocClick={toggleTocVisibility}
    />
  );

  const headerBlockElement = (
    <BookHeader
      book={book}
      isTocVisible={isTocVisible}
      disableContentBtn={!isAvailable}
      onButtonTocClick={toggleTocVisibility}
    />
  );

  const bookTocElement = (
    <div className="bookInfoContentContainer">
      <BookTocBlock
        book={book}
        mobileMode={isNeedCompactViewMode}
        onButtonHideClick={toggleTocVisibility}
      />
    </div>
  );

  const playerElement = (
    <PlayerComponent
      book={book}
      componentChapterId={locationChapterId}
      showBtnToc={isTocVisible}
      onClickBtnToc={toggleTocVisibility}
      audioContextWithCurrentTime={audioContextWithCurrentTime}
    />
  );

  let content, isTocShownOnly;

  if (isMobile) {
    let innerContent;

    if (isTocVisible) {
      // Show TOC only.
      innerContent = bookTocElement;
      isTocShownOnly = true;
    } else {
      if (isPlayerPage) {
        // Show player only.
        innerContent = playerElement;
      } else {
        // Show header block, book image, description.
        innerContent = (
          <>
            {headerBlockElement}
            {imageBlockElement}
            {descriptionBlockElement}
          </>
        );
      }
    }

    content = <div className="book-details-inner line-up-in-one-column">{innerContent}</div>;
  } else if (isTablet && isNeedCompactViewMode) {
    let innerContent;

    if (isPlayerPage) {
      // Show either TOC or player only.
      if (isTocVisible) {
        innerContent = bookTocElement;
        isTocShownOnly = true;
      } else {
        innerContent = playerElement;
      }
    } else {
      // Show book image plus either TOC or description.
      let secondaryContent;

      if (isTocVisible) {
        secondaryContent = bookTocElement;
      } else {
        secondaryContent = descriptionBlockElement;
      }

      innerContent = (
        <>
          {imageBlockElement}
          {secondaryContent}
        </>
      );
    }

    content = (
      <Scroll className="book-details-scroll">
        <div className="book-details-inner line-up-in-one-column">{innerContent}</div>
      </Scroll>
    );
  } else {
    if (isNeedCompactViewMode) {
      let innerContent;

      if (isTocVisible) {
        // Show TOC only.
        innerContent = bookTocElement;
        isTocShownOnly = true;
      } else {
        if (isPlayerPage) {
          // Show player.
          innerContent = playerElement;
        } else {
          // Show book image and description.
          innerContent = (
            <>
              {imageBlockElement}
              {descriptionBlockElement}
            </>
          );
        }
      }

      content = (
        <Scroll className="book-details-scroll">
          <div
            className={classnames("book-details-inner", {
              "line-up-in-one-column": isPlaceOnlyForOneColumn,
            })}>
            {innerContent}
          </div>
        </Scroll>
      );
    } else {
      const splitParams = {};

      if (isTocVisible) {
        splitParams.primary = "second";
        splitParams.minSize = PANE_WIDTH_BOOK_DETAILS;
        splitParams.maxSize = width - PANE_WIDTH_BOOK_DETAILS;
      } else {
        splitParams.primary = "first";
        splitParams.minSize = width;
      }

      let innerContent;

      if (isPlayerPage) {
        // Show player.
        innerContent = playerElement;
      } else {
        // Show book image and description.
        innerContent = (
          <Scroll>
            <div
              className={classnames("book-details-inner", {
                "line-up-in-one-column": isPlaceOnlyForOneColumn,
              })}>
              {imageBlockElement}
              {descriptionBlockElement}
            </div>
          </Scroll>
        );
      }

      content = (
        <SplitPane
          className="book-details-split-pane"
          style={{ height: "100%" }}
          onChange={(width) => {
            if (width !== undefined && firstPaneWidth !== width) {
              setFirstPaneWidth(width);
            }
          }}
          resizerStyle={{
            // Hide resizer line if the TOC is not visible.
            display: isTocVisible ? "" : "none",
          }}
          split="vertical"
          {...splitParams}>
          {innerContent}
          {isTocVisible ? bookTocElement : <div />}
        </SplitPane>
      );
    }
  }

  return (
    <div
      className={classnames("book-details", {
        "is-book-toc-visible": isTocVisible,
        "is-player-page": isPlayerPage,
        "is-toc-shown-only": isTocShownOnly,
      })}>
      {content}
    </div>
  );
};

BookDetailsPage.propTypes = {
  match: PropTypes.object,
  id: PropTypes.number,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default BookDetailsPage;
