import React, { useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TabBar, Button, Checkbox, Select } from "../../views";
import { useDialogContext } from "../../dialog/DialogContext";
import { generateParasHtml } from "../../../utils/Utils";
import { copy, COPY_FORMATS, transformSelectionWithOptions } from "../../../utils/CopyUtil";
import { printContent } from "../../../utils/ContentUtils";
import { actionAddMessage } from "../../../redux/actions";
import { TOAST_TYPE } from "../../popup/Toaster";
import { getBookId } from "../../../shared/utils/content";

const tabOptions = [
  {
    id: "copy",
    label: "copy"
  },
  {
    id: "print",
    label: "print"
  }
];

const PrintCopyDialog = ({ print, selection }) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogContext();
  const dispatch = useDispatch();
  const previewParagraphs = useSelector((state) => state.studyCenter.previewParagraphs);
  const entries = useSelector((state) => state.studyCenter.entries);
  const showRefCodes = useSelector((state) => state.settings.showRefCodes);
  const studyCenter = useSelector((state) => state.settings.studyCenter);
  const [printTab, setPrintTab] = useState(print);
  const parent = useRef();

  const allPreviewParagraphs = useMemo(() => {
    let list = [];
    Object.keys(previewParagraphs).forEach((item) => {
      list.push(...previewParagraphs[item]);
    });
    return list;
  }, [previewParagraphs]);

  const [refcodesCopy, setRefcodesCopy] = useState(showRefCodes);
  const [refcodesPrint, setRefcodesPrint] = useState(showRefCodes);

  const [formatCopy, setFormatCopy] = useState(studyCenter);
  const [formatPrint, setFormatPrint] = useState(studyCenter);

  const [fromIndexCopy, setFromIndexCopy] = useState(0);
  const [toIndexCopy, setToIndexCopy] = useState(allPreviewParagraphs.length - 1);

  const [fromIndexPrint, setFromIndexPrint] = useState(0);
  const [toIndexPrint, setToIndexPrint] = useState(allPreviewParagraphs.length - 1);

  const getParagraphsMappedForCopyPrint = () => {
    return allPreviewParagraphs.reduce((acc, item) => {
      if (!acc[getBookId(item.id)]) {
        acc[getBookId(item.id)] = [];
      }

      acc[getBookId(item.id)].push(item);
      return acc;
    }, {});
  };

  const handleCopy = (container) => {
    const copyObjFinal = transformSelectionWithOptions(container.outerHTML, {
      format: formatCopy,
      refs: refcodesCopy,
    }, getParagraphsMappedForCopyPrint(), entries);

    copy(copyObjFinal.html, {
      format: COPY_FORMATS.TEXT_HTML,
      textPlain: copyObjFinal.text,
      onSuccess: () => {
        dispatch(actionAddMessage("@textCopied"));
      },
      onError: () => {
        dispatch(actionAddMessage("@textNotCopied", TOAST_TYPE.error));
      },
    });

    hideDialog();
  };

  const handlePrint = (container) => {
    printContent(transformSelectionWithOptions(container.outerHTML, {
      format: formatPrint,
      refs: refcodesPrint,
    }, getParagraphsMappedForCopyPrint(), entries).html, {
      showFormat: formatPrint,
      showRefs: refcodesPrint,
      showPageBreaks: true,
      showHeaders: true,
    });
    hideDialog();
  };

  const handleClickSelection = () => {
    if (selection) {
      if (printTab) {
        handlePrint(selection);
      } else {
        handleCopy(selection);
      }
    }
  };

  const handleClickAll = (all) => {
    let content = [...allPreviewParagraphs];
    if (!all) {
      const fromIndex = !printTab ? fromIndexCopy : fromIndexPrint;
      const toIndex = !printTab ? toIndexCopy : toIndexPrint;
      const count = toIndex - fromIndex + 1;
      content = content.splice(fromIndex, count);
    }

    const { container } = generateParasHtml(
      content,
      { refs: printTab ? refcodesPrint : refcodesCopy },
    );
    if (printTab) {
      handlePrint(container);
    } else {
      handleCopy(container);
    }
  };

  const renderSelect = (label, index, setIndex, style) => {
    return (
      <div className="select-container select-margin-top select-flex-wrap">
        <span className="select-label">{t(label)}:</span>
        <Select
          dropDownStyle={style}
          options={allPreviewParagraphs}
          value={allPreviewParagraphs[index]}
          onRenderTitle={(title) => (
            <div className="pub-select-container-base pub-select-container-full">
              {title}
            </div>
          )}
          onGetTitle={(value) => (value ? value.refcode_short : "")}
          parentNode={parent}
          onChange={(_, index) => setIndex(index)}
        />
      </div>
    );
  };

  const renderTab = () => {
    const fromIndex = !printTab ? fromIndexCopy : fromIndexPrint;
    const toIndex = !printTab ? toIndexCopy : toIndexPrint;

    const setFromIndex = !printTab ? setFromIndexCopy : setFromIndexPrint;
    const setToIndex = !printTab ? setToIndexCopy : setToIndexPrint;

    return (
      <div className="pub-dialog-row">
        {renderSelect("from", fromIndex, setFromIndex, "sc-drop-down-from")}
        <div className="pub-row-space-24"></div>
        {renderSelect("to", toIndex, setToIndex, "sc-drop-down-to")}
      </div>
    );
  };

  const title = printTab ? t("Print") : t("Copy");

  const renderCheckboxes = () => {
    const refcodes = printTab ? refcodesPrint : refcodesCopy;
    const setRefcodes = printTab ? setRefcodesPrint : setRefcodesCopy;
    const format = printTab ? formatPrint : formatCopy;
    const setFormat = printTab ? setFormatPrint : setFormatCopy;

    return (
      <div className="pub-dialog-row">
        <Checkbox
          checked={refcodes}
          className="sct-check-text"
          onChange={(value) => setRefcodes(value)}
          title={t("actionRefcodes", { text: title })}
        />
        <div className="pub-row-space-24"></div>
        <Checkbox
          checked={format}
          className="sct-check-text"
          onChange={(value) => setFormat(value)}
          title={title + " " + t("format")}
        />
      </div>
    );
  };

  return (
    <div ref={parent} className="pub-dialog-container">
      <TabBar
        className="sc-tabs pub-tabs"
        options={tabOptions}
        current={printTab ? "print" : "copy"}
        onChange={(selectedTab) => setPrintTab(selectedTab === "print")}
      />
      <div className="sct-container">
        {renderCheckboxes()}
        <div className="pub-dialog-row pub-dialog-row-center">
          <Button
            disabled={!selection} 
            className="sc-btn pub-dialog-btn"
            onClick={handleClickSelection}>
            {t("actionSelection", { text: title })}
          </Button>
          <div className="pub-row-space"></div>
          <Button
            className="sc-btn pub-dialog-btn"
            onClick={() => handleClickAll(true)}>
            {t("actionChapter", { text: title })}
          </Button>
        </div>
        <div className="sct-divider" />
        <span className="sct-select-title">{t("selectRange")} </span>
        {renderTab()}        
        <Button
          className="button-base button-primary sc-btn pub-dialog-btn sct-center"
          onClick={() => handleClickAll(false)}>
          {t("actionRange", { text: title })}
        </Button>
      </div>
    </div>
  );
};

PrintCopyDialog.propTypes = {
  selection: PropTypes.any,
  print: PropTypes.bool
};

export default PrintCopyDialog;
