import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  DesktopIcon,
  MobileIcon,
  TextOnlyIcon,
  TabletIcon,
  FullScreenIcon
} from "../../assets/FooterIcons";
import { VIEW_MODE } from "../../utils/Utils";
import { useAuth, useViewMode, useFullScreen } from "../../hooks";
import { useDialogContext } from "../dialog/DialogContext";
import { actionUpdateSettings } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { MENU_STATE } from "../../utils/MenuItems"; 
import { getInitialMode } from "../../hooks/viewModeHooks";
import { isDesktop, ScreenType, ZoomValues } from "../../utils/ThemeUtils";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import IconButton from "./IconButton";

import "./ViewMode.scss";

const ViewMode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { textMode, viewMode, zoom, sizeMode } = useViewMode();
  const { onLogout, isLogin } = useAuth();
  const { showConfirm } = useDialogContext();
  const { fullscreen, toggle } = useFullScreen();

  const initialMode = getInitialMode(zoom, sizeMode);
  const mobileMode = initialMode === VIEW_MODE.MOBILE;
  const tabletMode = initialMode === VIEW_MODE.TABLET;
  const desktopMode = initialMode === VIEW_MODE.DESKTOP;

  const keyDownHandle = (event, mode) => {
    if (isEventKey(event, KeyCodes.enter)) {
      handleModeChange(mode, event.altKey);
    }
  };

  const handleModeChange = (mode) => {
    const settings = {
      [Settings.viewMode.id]: mode,
      [Settings.menuState.id]: MENU_STATE.COLLAPSE,
      [Settings.textMode.id]: false
    };

    if (mode === VIEW_MODE.DESKTOP) {
      settings[Settings.zoom.id] = ZoomValues.desktop[ScreenType.DESKTOP];
    }

    if (mode === VIEW_MODE.TEXT) {
      settings[Settings.textMode.id] = true;
    }

    dispatch(actionUpdateSettings(settings));
  };

  const textModeDialog = () => {
    showConfirm(
      "textModeConfirmTitle",
      "textModeConfirmContent",
      () => {
        onLogout();
        dispatch(
          actionUpdateSettings({
            [Settings.textMode.id]: true,
            [Settings.showBookshelf.id]: true,
            [Settings.isGrid.id]: true,
            [Settings.menuState.id]: MENU_STATE.COLLAPSE
          })
        );
      },
      { positiveBtn: "logout", negativeBtn: "cancel" }
    );
  };

  const handleTextMode = (skipLogout) => {
    if (isLogin && !textMode && !skipLogout) {
      textModeDialog();
    } else {
      dispatch(
        actionUpdateSettings({
          [Settings.textMode.id]: !textMode
        })
      );
    }
  };

  const modeIcons = [
    {
      icon: DesktopIcon,
      mode: VIEW_MODE.DESKTOP,
      ariaLabel: "desktop",
      isShow: desktopMode || isDesktop(sizeMode)
    },
    {
      icon: TabletIcon,
      mode: VIEW_MODE.TABLET,
      ariaLabel: "tablet",
      isShow: tabletMode || desktopMode || isDesktop(sizeMode)
    },
    {
      icon: MobileIcon,
      mode: VIEW_MODE.MOBILE,
      ariaLabel: "mobile",
      isShow: mobileMode || tabletMode || !isDesktop(sizeMode)
    },
    {
      icon: TextOnlyIcon,
      ariaLabel: "text",
      onClick: handleTextMode,
      isActive: textMode,
      isShow: true
    },
    {
      icon: FullScreenIcon,
      ariaLabel: "fullscreen",
      onClick: toggle,
      isActive: fullscreen,
      isShow: !mobileMode
    }
  ];

  return (
    <div className="view-mode-icons">
      {modeIcons.map(({ icon, mode, ariaLabel, onClick, isActive, isShow }, key) => {
        if (!isShow) {
          return null;
        }
        return (
          <button
            key={key}
            tabIndex={0}
            className="view-mode-icons-icon"
            aria-pressed={mode === viewMode}
            onKeyDown={(event) => {
              if (onClick) {
                onClick(event.altKey);
              } else {
                keyDownHandle(event, mode);
              }
            }}
            onClick={(e) => {
              if (onClick) {
                onClick(e.altKey);
              } else {
                handleModeChange(mode, e.altKey);
              }
            }}>
            <IconButton
              icon={icon}
              className={classNames({
                selected: isActive || (mode && viewMode === mode)
              })}
              title={t(ariaLabel)}
            />
          </button>
        );
      })}
    </div>
  );
};

export default ViewMode;
