import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./styles.scss";

const TextField = ({
  rootClass,
  className,
  backColor,
  textColor,
  label,
  error,
  value,
  autoFocus,
  disabled,
  helperText,
  multiline,
  rows,
  ...rest
}) => {
  const [focus, setFocus] = useState(autoFocus);
  const [hover, setHover] = useState(false);
  const isShowPlaceholder = !!label && !focus;

  const renderField = () => {
    if (multiline) {
      return (
        <textarea
          data-focus={autoFocus}
          className={classNames("field-input", className)}
          style={textColor ? { color: textColor } : null}
          rows={rows}
          value={value}
          disabled={disabled}
          {...rest}
        />
      );
    }

    return (
      <input
        data-focus={autoFocus}
        className={classNames("field-input", className)}
        style={textColor ? { color: textColor } : null}
        value={value}
        disabled={disabled}
        {...rest}
      />
    );
  };

  return (
    <div
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classNames("field-root", rootClass, {
        "disabled": disabled,
        "focus": focus,
        "active": value.length,
        "error": error && !(hover || focus),
        "hover": hover && !disabled,
        "multiline": multiline,
        "with-label": !!label
      })}
      style={backColor ? { backgroundColor: backColor } : null}
    >
      {!!label && (
        <label className="field-label" data-label={isShowPlaceholder}>
          {label}
        </label>
      )}
      <div className="field-container">
        {renderField()}
        <fieldset className="field-fieldset">
          <legend>
            <span>{label}</span>
          </legend>
        </fieldset>
      </div>
      {!!helperText && <p className="field-helper">{helperText}</p>}
    </div>
  );
};

TextField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  rootClass: PropTypes.string,
  backColor: PropTypes.string,
  textColor: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  error: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number
};

export default TextField;
