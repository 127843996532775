import {
  StudyCenterConstants,
} from "./studyCenter.actions";
import { ACTION_LOGOUT, ACTION_UPDATE_SETTING_SUCCESS } from "../../redux/constants";
import { STORAGE_KEY_LAST_SC_ACTIONS, UnderlineColor } from "./studyCenter.constants";
import { localStorageSafe } from "../../shared/utils/systemUtils";

const makeStorageKeyLastActionsSC = (userId) => STORAGE_KEY_LAST_SC_ACTIONS + userId;

const getLastActionsFromStorage = (userId) => {
  let list = [];
  try {
    const actionData = localStorageSafe.getItem(makeStorageKeyLastActionsSC(userId));
    if (actionData) {
      list = JSON.parse(actionData);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%c[Error parse last SC actions]", "background: black; color: white;", e);
  }
  return list;
};

const setLastActionsToStorage = (userId, actions, action) => {
  const newActionList = [...actions];
  const existActionIndex = newActionList.findIndex((item) => item.id === action.id);
  if (existActionIndex !== -1) {
    newActionList.splice(existActionIndex, 1);
  }
  newActionList.unshift(action);
  localStorageSafe.setItem(makeStorageKeyLastActionsSC(userId), JSON.stringify(newActionList));
  return newActionList;
};

const initPreviewState = {
  previewRequest: [],
  previewPageIds: [],
  previewParagraphs: {},
};

const initialState = {
  searchResults: {},
  searchQuery: undefined,
  entries: [],
  topics: [],
  checkedSCItem: [],
  searchText: "",
  validEntries: [],
  lastReaderLocation: undefined,
  colors: [],
  isColorsFetched: false,
  activeColorId: UnderlineColor,
  activeSelectedText: null,
  staticShare: [],
  checkedEntries: [],
  checkedTopics: [],
  lastActions: [],
  lastTopic: undefined,
  lastCreateEntry: undefined,
  previewStaticShare: {},
  nextPage: undefined,
  topicId: null,
  topicsItemsCounts: {},
  currentTopicId: undefined,
  currentEntryId: undefined,
  ...initPreviewState,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyCenterConstants.UPDATE_STUDY_CENTER_DUMP_SUCCESS: {
      const {
        topics,
        entries,
        colors,
        newEntry,
        nextPage,
        topicsItemsCounts,
      } = action.data;
      let newState = { ...state, nextPage: nextPage };
      if (topics) {
        newState.topics = topics;
      }
      if (entries) {
        newState.entries = entries;
      }
      if (colors) {
        newState.colors = colors;
      }
      if (newEntry) {
        newState.lastCreateEntry = newEntry;
      }
      if (topicsItemsCounts) {
        newState.topicsItemsCounts = {
          ...state.topicsItemsCounts,
          ...topicsItemsCounts,
        };
      }
      return newState;
    }
    case StudyCenterConstants.SET_LAST_CREATE_ENTITY:
      return { ...state, lastCreateEntry: action.data };
    case StudyCenterConstants.FETCH_STUDY_CENTER_COLORS_SUCCESS:
      return { ...state, colors: action.data };
    case StudyCenterConstants.FETCH_STUDY_CENTER_COLORS_FINALLY:
      return { ...state, isColorsFetched: true };
    case StudyCenterConstants.FETCH_STUDY_CENTER_TOPICS_SUCCESS:
      return { ...state, topics: action.data };
    case StudyCenterConstants.FETCH_STATIC_SHARE_SUCCESS:
      return { ...state, staticShare: action.data };
    case StudyCenterConstants.PREVIEW_STATIC_SHARE_SUCCESS:
      return { ...state, previewStaticShare: action.data };
    case StudyCenterConstants.SAVE_LAST_READING_BOOK:
      return { ...state, lastReaderLocation: action.data };
    case StudyCenterConstants.ADD_STUDY_CENTER_ACTION: {
      const lastActions = setLastActionsToStorage(
        action.data.userId, state.lastActions, action.data.action
      );
      const [first] = lastActions;
      let lastTopic;
      if (first) {
        lastTopic = first.topic;
      }
      return { ...state, lastActions, lastTopic };
    }
    case StudyCenterConstants.FETCH_STUDY_CENTER_SEARCH_SUCCESS: {
      return { ...state, searchResults: action.data };
    }
    case StudyCenterConstants.STUDY_CENTER_CLEAR_SEARCH: {
      return { ...state, searchResults: {} };
    }
    case StudyCenterConstants.FETCH_STUDY_CENTER_PREVIEW_SUCCESS: {
      if (!action.data) {
        return { ...state, ...initPreviewState };
      }

      const { previewRequest, previewParagraphs, entries, previewPageIds } = action.data;
      let outState = {
        ...state,
        previewRequest,
        previewPageIds,
        previewParagraphs,
      };
      if (entries) {
        outState.entries = entries;
      }
      return outState;
    }
    case StudyCenterConstants.ACTION_CHECK_CS_ITEMS: {
      const {
        checkedEntries = state.checkedEntries,
        checkedTopics = state.checkedTopics,
      } = action.data;

      return {
        ...state,
        checkedEntries: [...checkedEntries],
        checkedTopics: [...checkedTopics],
      };
    }
    case StudyCenterConstants.ACTION_SET_ACTIVE_COLOR: {
      return { ...state, activeColorId: action.data };
    }
    case StudyCenterConstants.ACTION_SET_ACTIVE_SELECTED_TEXT: {
      return { ...state, activeSelectedText: action.data };
    }
    case StudyCenterConstants.ACTION_UPDATE_TOPIC: {
      return { ...state, topicId: action.data };
    }
    case StudyCenterConstants.ACTION_SET_CURRENT_TOPIC: {
      const { topicId, entryId } = action.data;
      return { ...state, currentTopicId: topicId, currentEntryId: entryId };
    }
    case StudyCenterConstants.UPDATE_CHECKED_SC_ITEM: {
      return { ...state, checkedSCItem: action.data };
    }
    case StudyCenterConstants.SET_SC_SEARCH_TEXT_SUCCESS: {
      return { ...state, ...action.data };
    }
    case ACTION_UPDATE_SETTING_SUCCESS: {
      const userId = action.data?.userInfo?.id;

      if (userId) {
        return {
          ...state, lastActions: getLastActionsFromStorage(userId),
        };
      }
      return state;
    }
    case ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
