import React, { useCallback, useRef } from "react";
import { List } from "react-virtualized";
import PropTypes from "prop-types";

import Scroll  from "./Scroll";

const listStyle = {
  overflowX: false,
  overflowY: false,
};

// FIXME: "react-virtualized" has troubles with big height lists!
//  Issue: https://github.com/bvaughn/react-virtualized/issues/1086
//  {scrollToCell} method of virtualized fixes the trouble, but be aware,
//  {scrollToCell} doesn't always nails an item to the top,
//  So additional logic should be applied for this.
/**
 * @description Virtual list with custom scroll
 */
const ListView = React.forwardRef(({
  width,
  height,
  rowRenderer,
  rowCount,
  rowHeight,
  onScrollStop,
  listClassName,
  stopDelay,
  overscanRowCount,
  cache,
  onScroll,
  listRef,
}, ref) => {
  const listRefLocal = useRef();

  const handleScroll = useCallback(({ target }) => {
    if (target) {
      const { scrollTop } = target;
      const virtList = listRefLocal.current;
      if (onScroll) {
        onScroll(scrollTop);
      }
      if (virtList) {
        virtList.Grid.handleScrollEvent({ scrollTop });
      }
    }
  }, [onScroll]);

  const handleListRef = useCallback((instance) => {
    listRefLocal.current = instance;

    if (listRef) {
      if (typeof listRef === "function") {
        listRef(instance);
      } else if ("current" in listRef) {
        listRef.current = instance;
      }
    }
  }, [listRef]);

  return (
    <Scroll
      ref={ref}
      stopDelay={stopDelay}
      onScrollStop={onScrollStop}
      onScroll={handleScroll}
    >
      <List
        width={width}
        height={height}
        ref={handleListRef}
        overscanRowCount={overscanRowCount || 10}
        style={listStyle}
        className={listClassName}
        deferredMeasurementCache={cache}
        rowCount={rowCount}
        rowHeight={rowHeight}
        rowRenderer={rowRenderer}
      />
    </Scroll>
  );
});

ListView.propTypes = {
  rowRenderer: PropTypes.func,
  onScrollStop: PropTypes.func,
  onScroll: PropTypes.func,
  columnCount: PropTypes.number,
  scrollTop: PropTypes.number,
  columnWidth: PropTypes.number,
  height: PropTypes.number,
  rowCount: PropTypes.number,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  width: PropTypes.number,
  stopDelay: PropTypes.number,
  overscanRowCount: PropTypes.number,
  cache: PropTypes.object,
  listRef: PropTypes.object,
  listClassName: PropTypes.string,
};

export default ListView;
