import React, { useState, useRef } from "react";
import { Grid } from "react-virtualized";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ChevroneUpCircleIcon } from "../../assets/CommonIcons";
import { Scroll } from "../views";

const listStyle = {
  overflowX: false,
  overflowY: false
};


/**
 * @description component for virtual grid list with custom scrollbars
 */
const GridView = React.forwardRef(
  (
    {
      cellRenderer,
      columnCount,
      columnWidth,
      height,
      rowCount,
      rowHeight,
      width,
      ariaLabel,
      role,
      isHideScrollTo = false,
      scrollProps
    },
    ref
  ) => {
    const [showToTop, setShowToTop] = useState(false);
    const [showToBottom, setShowToBottom] = useState(false);
    const gridRef = useRef();

    const onScrollFrame = ({ scrollTop, scrollLeft, clientHeight, scrollHeight }) => {
      const grid = gridRef.current;
      setShowToTop(scrollTop !== 0);
      setShowToBottom(scrollHeight > clientHeight && scrollTop < scrollHeight - clientHeight * 2);

      if (grid) {
        grid.handleScrollEvent({ scrollTop, scrollLeft });
      }
    };

    const onScrollTop = () => {
      if (ref.current) {
        ref.current.scrollToTop();
      }
    };

    const onScrollBotton = () => {
      if (ref.current) {
        ref.current.scrollToBottom();
      }
    };

    return (
      <div className="grid-wrap">
        <Scroll
          autoHide
          className="grid-scrollbar"
          onScrollFrame={onScrollFrame}
          renderTrackHorizontal={() => <div className="hideView" />}
          renderThumbHorizontal={() => <div className="hideView" />}
          ref={ref}
          {...scrollProps}
        >
          <Grid
            role={role}
            aria-label={ariaLabel}
            ref={gridRef}
            className="grid-view"
            cellRenderer={cellRenderer}
            columnCount={columnCount}
            columnWidth={columnWidth}
            height={height}
            style={listStyle}
            rowCount={rowCount}
            rowHeight={rowHeight}
            width={width}
          />
        </Scroll>
        {!isHideScrollTo ? (
          <span className={classNames("scrollToWrapper", "--absolute")}>
            <ChevroneUpCircleIcon
              className={classNames("top-button", showToTop ? "showView" : "hideView")}
              onClick={onScrollTop}
            />
            <ChevroneUpCircleIcon
              className={classNames("bottom-button", showToBottom ? "showView" : "hideView")}
              onClick={onScrollBotton}
            />
          </span>
        ) : null}
      </div>
    );
  }
);

GridView.defaultProps = {
  role: "grid",
  scrollProps: {}
};

GridView.propTypes = {
  cellRenderer: PropTypes.func,
  columnCount: PropTypes.number,
  columnWidth: PropTypes.number,
  height: PropTypes.number,
  rowCount: PropTypes.number,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  width: PropTypes.number,
  onlyVertical: PropTypes.bool,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
  scrollProps: PropTypes.object,
  isHideScrollTo: PropTypes.bool
};

export default GridView;
