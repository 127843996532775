import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isTablet } from "react-device-detect";
import { withResizeDetector } from "react-resize-detector/build/withPolyfill";
import { useLocation } from "react-router-dom";

import HideIcon from "../components/views/HideIcon";
import LinkButton from "../components/views/LinkButton";

import appStoreImage from "../assets/app-store.png";
import googlePlayImage from "../assets/google-play.png";
import macOSImage from "../assets/imac.png";
import iOSTabletImage from "../assets/iPad.png";
import androidTabletImage from "../assets/androidTablet.png";
import androidMobileImage from "../assets/SamsungS9.png";
import iOSMobileImage from "../assets/iPhoneX.png";

import { useLocalStorage, useViewMode } from "../hooks";
import { COOKIE_KEY_APP_OPEN_NUMBER, detectOS } from "../utils/Utils";
import { getCookie } from "../shared/utils/storage";

import "./AppNotification.scss";

const osList = {
  MAC_OS: "MacOS",
  IOS: "iOS",
  ANDROID: "Android",
};

const availableOs = [osList.MAC_OS, osList.IOS, osList.ANDROID];

export const LS_KEY_CLOSED_COUNT = "appNotifyCC";
const LS_KEY_TIMESTAMP_NEXT_SHOW = "appNotifyNS";

const getDelayWithClosedCount = (closedCount) => {
  if (closedCount === 0) {
    return 0;
  }
  if (closedCount === 1) {
    return 604_800_000; // 7 days.
  }
  return 2_592_000_000; // 30 days.
};

const getTimestampNextShowWithClosedCount = (closedCount) => {
  return Date.now() + getDelayWithClosedCount(closedCount);
};

const AppNotification = ({ width, preventShow }) => {
  const { t } = useTranslation();
  const { zoom } = useViewMode();
  const location = useLocation();
  const [show, setShow] = useState(false);
  let [closedCount, setClosedCount] = useLocalStorage(LS_KEY_CLOSED_COUNT, 0);
  closedCount = parseInt(closedCount);
  let [timestampNextShow, setTimestampNextShow] = useLocalStorage(LS_KEY_TIMESTAMP_NEXT_SHOW, NaN);
  timestampNextShow = parseInt(timestampNextShow);
  const isTimestampNextShowValid = !isNaN(timestampNextShow);

  const platform = detectOS();
  const isShowImage = useMemo(() => !(zoom > 100 && width < 480), [width, zoom]);
  const isAvailableOs = availableOs.includes(platform);

  const { label, link, src } = useMemo(() => {
    let label, link, src;
    switch (platform) {
      case osList.ANDROID: {
        label = <img src={googlePlayImage} alt={t("googlePlayButton")} />;
        link = "https://play.google.com/store/apps/details?id=com.whiteestate.egwwritings";
        src = isTablet ? androidTabletImage : androidMobileImage;
        break;
      }
      case osList.MAC_OS: {
        label = <img src={appStoreImage} alt={t("appleStoreButton")} />;
        link = "https://media4.egwwritings.org/macos/4.0/egw-writings.pkg";
        src = macOSImage;
        break;
      }
      case osList.IOS: {
        label = <img src={appStoreImage} alt={t("appleStoreButton")} />;
        link = "https://apps.apple.com/us/app/egw-writings-2/id994076136";
        src = isTablet ? iOSTabletImage : iOSMobileImage;
        break;
      }
      default:
        label = "";
        link = "";
        src = "";
    }

    return { label, link, src };
  }, [t, platform]);

  useEffect(() => {
    if (!isTimestampNextShowValid) {
      setTimestampNextShow(getTimestampNextShowWithClosedCount(closedCount),);
    }
  }, [setTimestampNextShow, isTimestampNextShowValid, closedCount]);

  useEffect(() => {
    // === Return cases ===
    if (!isAvailableOs || show || !isTimestampNextShowValid) {
      return;
    }

    let appOpenNumber = parseInt(getCookie(COOKIE_KEY_APP_OPEN_NUMBER, document.cookie, 1));
    appOpenNumber = Math.max(1, isNaN(appOpenNumber) ? 1 : appOpenNumber);

    if (appOpenNumber < 3) {
      return;
    }
    // ===================

    if (timestampNextShow <= Date.now()) {
      setShow(true);
    }
  }, [isAvailableOs, show, timestampNextShow, location.pathname]);

  const onCloseClick = (event, preventDefault = true) => {
    if (preventDefault) {
      event.preventDefault();
    }
    const closedCountNew = closedCount + 1;
    setClosedCount(closedCountNew);
    setTimestampNextShow(getTimestampNextShowWithClosedCount(closedCountNew));
    setShow(false);
  };

  if (!show || !isAvailableOs || preventShow) {
    return null;
  }

  return (
    <div className="app-notification">
      <HideIcon onClick={(event) => onCloseClick(event)} />
      <div className="app-notification-wrapper">
        {isShowImage && (
          <div className="app-notification-column1">
            <img
              loading="eager"
              alt={t("typeDevice", { type: platform })}
              role="presentation"
              src={src}
            />
          </div>
        )}
        <div className="app-notification-column2">
          <div className="app-notification-content-title">{t("appNotificationTitle")}</div>
          <div
            className="app-notification-content-text"
            dangerouslySetInnerHTML={{ __html: t("appNotificationText") }}
          />
          <div className="app-notification-actions">
            <span onClick={(event) => onCloseClick(event, false)}>
              <LinkButton external link={link} label={label} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

AppNotification.defaultProps = {
  width: window.innerWidth,
};

AppNotification.propTypes = {
  width: PropTypes.number,
  preventShow: PropTypes.bool,
};

export default withResizeDetector(AppNotification);
