import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Drag.scss";

const Drag = ({ isPhantom, isDragging, isDragBetween, className, ...restProps }) => (
  <div
    {...restProps}
    className={classNames("base-drag-line", className, {
      "is-dragging": isDragging,
      "is-drag-between": isDragBetween,
      "is-phantom": isPhantom
    })}>
    <div className="base-drag-line__line">
      <svg  viewBox="0 0 37 14" className="base-drag-line__thumb">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9788 8.10581C12.321 11.3737 15.0846 13.9209 18.4431 13.9209C21.8016 13.9209 24.5652 11.3737 24.9075 8.10581H33.9365L30.9723 11.07C30.6794 11.3629 30.6794 11.8377 30.9723 12.1306C31.2652 12.4235 31.7401 12.4235 32.033 12.1306L36.2775 7.88614C36.5704 7.59325 36.5704 7.11837 36.2775 6.82548L32.033 2.581C31.7401 2.2881 31.2652 2.2881 30.9723 2.581C30.6794 2.87389 30.6794 3.34876 30.9723 3.64166L33.9365 6.60581H24.8925C24.4915 3.40068 21.7569 0.920898 18.4431 0.920898C15.1293 0.920898 12.3947 3.40068 11.9937 6.60581L2.93761 6.61719L5.90176 3.65304C6.19466 3.36014 6.19466 2.88527 5.90176 2.59238C5.60887 2.29948 5.134 2.29948 4.8411 2.59238L0.596622 6.83686C0.30373 7.12975 0.30373 7.60462 0.596622 7.89752L4.8411 12.142C5.134 12.4349 5.60887 12.4349 5.90176 12.142C6.19466 11.8491 6.19466 11.3742 5.90176 11.0813L2.93761 8.11719L11.9788 8.10581ZM21.8477 6.60581C21.4804 5.06593 20.0955 3.9209 18.4431 3.9209C16.7907 3.9209 15.4058 5.06593 15.0385 6.60581H17.197C17.6112 6.60581 17.947 6.94159 17.947 7.35581C17.947 7.77002 17.6112 8.10581 17.197 8.10581H15.0101C15.3285 9.71089 16.7445 10.9209 18.4431 10.9209C20.1417 10.9209 21.5577 9.71089 21.8761 8.10581H19.4939C19.0797 8.10581 18.7439 7.77002 18.7439 7.35581C18.7439 6.94159 19.0797 6.60581 19.4939 6.60581H21.8477Z"
        />
        <path
          d="M14.9434 7.4209C14.9434 5.4879 16.5104 3.9209 18.4434 3.9209V3.9209C20.3764 3.9209 21.9434 5.4879 21.9434 7.4209V7.4209C21.9434 9.35389 20.3764 10.9209 18.4434 10.9209V10.9209C16.5104 10.9209 14.9434 9.35389 14.9434 7.4209V7.4209Z"
          fill="var(--background)"
        />
      </svg>
    </div>
  </div>
);

export default Drag;

Drag.propTypes = {
  className: PropTypes.string,
  isDragging: PropTypes.bool,
  isDragBetween: PropTypes.bool,
  isPhantom: PropTypes.bool
};
