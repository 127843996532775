import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { subTypes } from "../SubscriptionsUtils";
import { actionGetSubscriptions, fetchFeeds } from "../subscriptions.actions";
import AddSubscription from "../AddSubscription";
import EditSubsType from "./EditSubsType";
import { main } from "../../../utils/RouterUtils";
import { useNavigationData } from "../../NavigationContext";
import { menuItems } from "../../../utils/MenuItems";
import { capitalize } from "../../../utils/Utils";
import { URLS } from "../../../shared/utils/url";

const getLabel = (pathname, feeds, allSubs, t) => {
  const isSubFeed = pathname.includes(URLS.subscriptionEdit + "/news");
  let items = isSubFeed ? feeds : allSubs;
  if (pathname.includes(URLS.subscriptionEdit)) {
    const pathParts = pathname.split("/");
    if (pathParts.length > 2) {
      const lastPart = pathParts[pathParts.length - 1];
      const subId = parseInt(lastPart);
      if (Number.isNaN(subId)) {
        return t("editSubTitle", { title: t(lastPart === "book" ? "egw" : lastPart) });
      }
      const sub = items.find((item) => item.id === subId);
      if (sub) {
        return t("editSubTitle", { title: sub.book ? sub.book.title : sub.title });
      }
    }
    return t("editSub");
  }
  return undefined;
};

const EditSubscriptionPage = ({ history, match, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allSubs = useSelector((state) => state.subscriptions.allSubs);
  const feeds = useSelector((state) => state.subscriptions.feeds);
  const { type, id } = match.params;
  const {pathname} = location;

  const backStack = location?.state?.backStack;

  const isFeed = type === "news" || type === "thought";

  const navigationParams = useMemo(() => {
    const nodeList = backStack ? [...backStack] : [main, menuItems.subscription];

    /* --- Subscription type item --- */
    nodeList.push({
      id: URLS.subscriptionEdit,
      label: "subscriptions_edit",
      children: subTypes.map(({ id, label }) => ({
        id: `${URLS.subscriptionEdit}/${id}`,
        label: capitalize(t(label)),
      }))
    });
    /* ======================== */

    /* --- Category name item --- */
    const currentCategory = subTypes.find((item) => item.id === type);
    const currCategoryItems = isFeed
      ? feeds
      : allSubs.filter((item) => item.book.book_type === type);
    if (currentCategory) {
      nodeList.push({
        id: `${URLS.subscriptionEdit}/${type}`,
        label: capitalize(t(currentCategory.label)),
        children: currCategoryItems?.length > 1 ? currCategoryItems : undefined
      });
    }
    /* ======================== */

    /* --- Book name item --- */
    const currentItem = currCategoryItems.find((item) => item.id.toString() === id);
    if (currentItem) {
      nodeList.push({
        id: `${URLS.subscriptionEdit}/${type}/${id}`,
        label: currentItem.book?.title,
      });
    }
    /* ======================== */

    return { nodeList, label: getLabel(pathname, feeds, allSubs, t), targetId: type};
  }, [isFeed, type, id, backStack, allSubs, feeds, t, pathname]);

  useNavigationData(navigationParams);

  useEffect(() => {
    if (allSubs.length === 0) {
      dispatch(actionGetSubscriptions());
    }
    dispatch(fetchFeeds());
  }, []);

  let content;
  if (!type && !id) {
    content = subTypes.map((item) => {
      const { id: type, label } = item;
      return (
        <div
          key={type}
          onClick={() => history.push(`${URLS.subscriptionEdit}/${type}`)}
          className="subs-edit-type-item">
          {t(label)}
        </div>
      );
    });
  } else if (type && !id) {
    content = <EditSubsType type={type} />;
  } else {
    const itemId = parseInt(id);
    const editItem = (isFeed ? feeds : allSubs).find((item) => item.id === itemId);
    if (editItem) {
      content = <AddSubscription editItem={editItem} editMode={true} isFeed={isFeed} />;
    }
  }

  return <div className="edit-sub-container">{content}</div>;
};

EditSubscriptionPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

export default EditSubscriptionPage;
