import {  SearchConstants } from "src/components/search/search.actions";
import { BooksFiltersStoreDefault, updateBooksFiltersStore } from "../../utils/FilterBooksUtils";
import { folderTreeActionConstants } from "./actions";
import { bookIdRegexStrict, getBookId, paraWithoutPrefixRegExp } from "../../shared/utils/content";

const getBookIdFromParaId = (id) => {
  return getBookId(id.split(".")[0]);
};

const isFolderIdParaId = (folderId) => paraWithoutPrefixRegExp.test(folderId);

// - Removes ids ids of the closed books.
const removeUnnecessaryIdsFromExpanded = (expanded) => {
  const booksIds = expanded.filter((folderId) => bookIdRegexStrict.test(folderId));

  return expanded.filter((folderId) => {
    if (isFolderIdParaId(folderId)) {
      return booksIds.includes(getBookIdFromParaId(folderId));
    }

    return true;
  });
};

const initialState = {
  expanded: [],
  checked: [],
  initialChecked: [],
  filters: BooksFiltersStoreDefault,
};

const folderTree = (state = initialState, action) => {
  switch (action.type) {
    case folderTreeActionConstants.UPDATE_EXPANDED:
      return { ...state, expanded: removeUnnecessaryIdsFromExpanded(action.data), };
    case folderTreeActionConstants.UPDATE_CHECKED:{
      const data = new Set(action.data || []);
      // prevent has duplicates in checked array
      return { ...state, checked: [...data] };
    }
    case folderTreeActionConstants.FETCH_PRESET_CHECKED_SUCCESS: {
      return {...state, initialChecked: action.data};
    }
    case folderTreeActionConstants.UPDATE_FILTERS: {
      return updateBooksFiltersStore(state, action.data);
    }
    case SearchConstants.ACTION_UPDATE_SEARCH_PERIOD: {
      // clear check in tree if search period has been updated
      return { ...state, checked: [] };
    }
    default:
      return state;
  }
};

export default folderTree;
