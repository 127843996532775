import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { getBookCover, wrongCoverUrls } from "../utils/url";
import { useEmToPx, useViewMode } from "../../hooks/viewModeHooks";

// universal book cover view show image of book or placeholder based on book category
const BookCoverView = ({ book, onClick, className, imageClassName = "", loading }) => {
  // {useState} guarantee a re-render after ref is available so computations gonna be OK
  const [rootEl, setRootEl] = useState(null);
  const emToPx = useEmToPx(rootEl);
  const { textMode } = useViewMode();
  const isUseCodeAsTitle = rootEl?.clientWidth < emToPx(4.5);
  const isHasCoverBig = rootEl?.clientWidth > 152;
  const coverUrl = getBookCover(book, isHasCoverBig);
  const bookTitle = book.title || book.book_title;
  const title = isUseCodeAsTitle ? book.code || bookTitle : bookTitle;
  const colorGroup = book.folder_color_group || book.group;

  return (
    <div
      ref={setRootEl}
      className={classNames("book-item-image-wrap", className, colorGroup, {
        "has-cover-big": isHasCoverBig,
      })}
      onClick={onClick}>
      <div
        className={classNames("text-over-image", {
          "is-centered": isUseCodeAsTitle,
        })}>
        {title}
      </div>
      {coverUrl && !textMode && (
        <img
          className={`book-item-image-img ${imageClassName}`}
          style={{ display: "block" }}
          onError={(e) => {
            e.target.style.display = "none";
            wrongCoverUrls.add(coverUrl);
          }}
          loading={loading || "lazy"}
          alt={bookTitle}
          effect="opacity"
          title={bookTitle}
          src={coverUrl}
        />
      )}
    </div>
  );
};

BookCoverView.propTypes = {
  book: PropTypes.shape({
    folder_color_group: PropTypes.string,
    group: PropTypes.string, // "property" for S+ featured sections.
    title: PropTypes.string,
    code: PropTypes.string,
    book_title: PropTypes.string, // property from sub item
  }),
  imageClassName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.oneOf(["lazy", "eager"]),
};

export default BookCoverView;
