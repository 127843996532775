import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Category from "../category/Category";
import Button from "../views/Button";
import { useDispatch } from "react-redux";
import { actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { useViewMode } from "../../hooks";
import classnames from "classnames";

import "./LibraryHint.scss";

const mockLanguages = {
  english: {
    id: "en",
    className: "language",
    label: "English",
    orderLabel: "primary",
    nbooks: 203
  },
  spanish: {
    id: "es",
    className: "language",
    label: "Spanish",
    orderLabel: "secondary",
    nbooks: 203
  },
  french: {
    id: "fr",
    className: "language",
    label: "French",
    orderLabel: "additional",
    nbooks: 203
  }
};

const LibraryHint = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { zoom, isMobile } = useViewMode();
  const [needCompactView, setNeedCompactView] = useState(false);

  useEffect(() => {
    const compactViewZoomBreakpoint = (window.innerWidth - 768) / 5.5 + 130;

    setNeedCompactView(isMobile || zoom > compactViewZoomBreakpoint);
  }, [zoom, isMobile]);

  const renderCategory = (item, needArrow) => {
    return (
      <div className="lib-hint-category-content">
        {needArrow && (
          <svg width="94" height="21" viewBox="0 0 94 21" fill="none" className="lib-hint__arrow">
            <path
              d="M92.8789 19.4011C61.3772 1.50005 21.967 4.03006 7.78705 4.95006M10.0029 1.50005C8.40269 2.3945 6.16246 5.18006 10.0029 9.16672C7.78724 8.52783 2.98674 7.02006 1.50966 6.10006C2.2482 4.95006 4.98079 2.42005 10.0029 1.50005Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        <Category item={item} showOrder={true} />
        <div className="lib-hint__category-text">{t(item.orderLabel)}</div>
      </div>
    );
  };

  const handleClick = () => {
    dispatch(actionUpdateSetting(Settings.libraryFirstOpen.id, false));
  };

  return (
    <div
      className={classnames("lib-hint__root", {
        "need-compact-view": needCompactView
      })}
      onClick={handleClick}>
      <span className="lib-hint__head">{t("selectLanguages")}</span>
      <div className="lib-hint__body">
        <span className="lib-hint__text">{t("libraryHint")}</span>
        {renderCategory(mockLanguages.english, true)}
        {renderCategory(mockLanguages.spanish)}
        {renderCategory(mockLanguages.french)}
        <div className="lib-hint__actions">
          <Button className="lib-hint__btn-ok" onClick={handleClick}>
            {t("ok")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LibraryHint;
