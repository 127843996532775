import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withResizeDetector } from "react-resize-detector/build/withPolyfill";

import BookListItem from "../book/BookListItem";
import useScrollArrows from "../../hooks/useScrollArrows";
import { IconButton, Scroll } from "../views";
import { ChevronUpIcon } from "../../assets/CommonIcons";
import { SCROLL_DIRECTIONS } from "../reader/constants";

import "./BookListWithArrows.scss";

const BookListWithArrows = ({books, width}) => {
  const [scrollRefInstance, setScrollRefInstance] = useState(null);

  const {
    showArrowOne, showArrowTwo,
    handleArrowClick, validateArrows,
  } = useScrollArrows(scrollRefInstance, true);

  useEffect(() => {
    validateArrows();
  }, [validateArrows, width, books]);

  useEffect(() => {
    if (scrollRefInstance) {
      validateArrows();
    }
  }, [scrollRefInstance, validateArrows]);

  const renderArrow = useCallback((isPrev) => {
    return (
      <IconButton
        icon={ChevronUpIcon}
        id={isPrev ? SCROLL_DIRECTIONS.LEFT : SCROLL_DIRECTIONS.RIGHT }
        onClick={handleArrowClick}
        className={classNames(
          isPrev ? "book-list-with-arrows_left" : "book-list-with-arrows_right",
          { invisible: isPrev ? !showArrowOne : !showArrowTwo }
        )}
      />
    );
  }, [handleArrowClick, showArrowOne, showArrowTwo]);

  return (
    <div className="book-list-with-arrows">
      {renderArrow(true)}
      <Scroll
        hasStaticPosition
        ref={setScrollRefInstance}
        renderViewClassName="book-list-with-arrows_scroll-view"
        onScroll={validateArrows}
        onScrollStop={validateArrows}
      >
        {books.map((book) => {
          return (
            <BookListItem
              key={book.id}
              isGrid
              isShowBookshelf
              isShowDescriptionCover={false}
              book={book}
            />
          );
        })}
      </Scroll>
      {renderArrow(false)}
    </div>
  );
};

BookListWithArrows.propTypes = {
  books: PropTypes.array.isRequired,
  width: PropTypes.number,
};

export default withResizeDetector(BookListWithArrows);
