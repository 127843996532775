import { makeLangImage } from "./URLUtils";
import {
  findNode,
  getChildrenIds,
  getNodeIds,
  joinArrays,
  removeFromArrayWithArray
} from "./Utils";
import { CONTENT_CLASSES } from "../shared/utils/content";
import { TREE_LABEL_HIDE_IN_TREE } from "./TreeUtils.constants";
import { isShowParaLevelByBookRealType } from "./BookUtils";

export const TreeMenuMode = {
  CATEGORIES: "categories",
  FOLDERS: "folders",
  TITLES: "titles",
};

export const TreeItemStateChecked = {
  none: "none",
  all: "all",
  part: "part",
};

export const isTreeItemCheckable = (item) => {
  const itemClassNameOrType = item.className || item.type;
  return itemClassNameOrType !== CONTENT_CLASSES.CHAPTER
    && itemClassNameOrType !== CONTENT_CLASSES.PARAGRAPH;
};

export const isTreeItemExpandable = (item, isShowParaLevel) => {
  const itemClassNameOrType = item.className || item.type;

  if (itemClassNameOrType === CONTENT_CLASSES.CHAPTER) {
    if (isShowParaLevel) {
      return true;
    }

    const itemChildren = item.children || [];
    // If the first child is a chapter, then the others too.
    // If the children are chapters, then the parent should be expandable.
    return itemChildren[0]?.className === CONTENT_CLASSES.CHAPTER;
  }

  return itemClassNameOrType !== CONTENT_CLASSES.CATEGORIES_LANGUAGE_FOLDER
    && itemClassNameOrType !== CONTENT_CLASSES.TITLES_BOOK_TRANSLATION
    && itemClassNameOrType !== CONTENT_CLASSES.PARAGRAPH;
};

export const makeLangsTree = (libraryLanguages, systemLang, bookIds, bookLang) => {
  const langs = new Set(libraryLanguages);
  if (langs.size === 0) {
    langs.add(systemLang);
  }
  let lang;
  bookIds.forEach((item) => {
    lang = bookLang[item];
    if (lang) {
      langs.add(lang);
    }
  });
  return [...langs];
};

/**
 * @description normalizes tree item to common object format
 * @param {object} item - fetched tree item
 * @param {string} parentClassName - ??
 * @param {number} index - index in main array
 * @param {boolean} isDisableDnd
 * @param {boolean} isShowParaLevel
 * @param {number[]} purchased
 * @returns {object}
 */
export const makeTreeItem = (
  item, parentClassName, index, isDisableDnd, isShowParaLevel, purchased
) => {
  const itemType = item.className || item.type;

  if (
    (itemType === CONTENT_CLASSES.PARAGRAPH || CONTENT_CLASSES.CHAPTER)
    && item.treeLabel === TREE_LABEL_HIDE_IN_TREE
  ) {
    return null;
  }

  const itemTitle = item.title || item.label;
  const itemClassMain = parentClassName || itemType;
  const itemId = item.id || item.book_id;

  const treeItem = {
    ...item,
    imageUrl: undefined,
    classes: [itemType],
    title: itemTitle,
    classMain: itemClassMain,
    id: itemId,
    showExpand: isTreeItemExpandable(item, isShowParaLevel),
    checkable: isTreeItemCheckable(item),
  };

  switch (itemType) {
    case CONTENT_CLASSES.LANGUAGE:
      if (index === 0) {
        treeItem.classes.push("current-lang");
      }
      treeItem.imageUrl = makeLangImage(itemId, true);
      break;
    case CONTENT_CLASSES.TITLES_BOOK_TRANSLATION:
    case CONTENT_CLASSES.CATEGORIES_BOOK_TRANSLATION:
      treeItem.showExpand = false;
      treeItem.imageUrl = makeLangImage(item.lang, true);
      break;
    case CONTENT_CLASSES.CATEGORIES_LANGUAGE_FOLDER:
    case CONTENT_CLASSES.TITLES_LANGUAGE_FOLDER:
      treeItem.type = itemType;
      treeItem.imageUrl = makeLangImage(item.lang, true);
      break;
    case CONTENT_CLASSES.BOOK:
      if (item.code && item.title) {
        treeItem.title = item.code + " " + item.title;
      }
      if (!isDisableDnd) {
        treeItem.classes.push("dndable");
      }
      if (item.realType) {
        treeItem.classes.push(item.realType);
      }
      if (item.isForSale && !purchased.includes(item.book_id)) {
        treeItem.showExpand = false;
      }
      break;
    case CONTENT_CLASSES.CHAPTER:
    case CONTENT_CLASSES.PARAGRAPH: {
      if (!isDisableDnd) {
        treeItem.classes.push("dndable");
      }
      if (item.treeLabel) {
        treeItem.title = item.treeLabel;
      }
      break;
    }
    case CONTENT_CLASSES.CATEGORIES_BOOK: {
      // forced added class name of the item, because it had a parent's class name only.
      treeItem.classMain += " " + CONTENT_CLASSES.CATEGORIES_BOOK;
      break;
    }
    default:
      break;
  }
  return treeItem;
};
/**
 * create expandable tree array
 * @param {{}[]} tree - tree array
 * @param {string[]} expanded - array of ids of expanded items
 * @param {string[]} checked - array of ids of checked items
 * @param {number[]} purchased - purchased books ids
 * @param {boolean} isDndDisabled
 * @param {number} _level - nesting level
 * @param {boolean} _isShowParaLevel
 * @param {string} _parentClassName
 * @param {Map} _cacheStateChecked
 * @returns {[]}
 */
export const makeTree = (
  tree = [],
  expanded, checked, purchased = [],
  isDndDisabled = false,
  // These params method uses itself only.
  // Get a consultation in "Developers Chat" if need to use these params outside the function.
  _level = 0, _isShowParaLevel, _parentClassName, _cacheStateChecked = new Map()
) => {
  const list = [];
  tree.forEach((item, index) => {
    const parentClassName = _level === 1 ? item.className : _parentClassName;
    const treeItem = makeTreeItem(
      item, parentClassName, index, isDndDisabled, _isShowParaLevel, purchased
    );
    if (!treeItem) {
      return;
    }

    const isExpanded = expanded.indexOf(treeItem.id) > -1;
    const stateChecked = getTreeItemStateChecked(treeItem, checked, _cacheStateChecked);
    list.push({
      ...item,
      ...treeItem,
      parentClassName,
      level: _level,
      classes: treeItem.classes.join(" "),
      checkType: stateChecked,
      isExpanded,
    });
    if (isExpanded && treeItem.showExpand) {
      if (treeItem.children) {
        const childList = makeTree(
          treeItem.children,
          expanded,
          checked,
          purchased,
          isDndDisabled,
          _level + 1,
          _isShowParaLevel || isShowParaLevelByBookRealType(treeItem.realType),
          parentClassName,
          _cacheStateChecked,
        );
        list.push(...childList);
      } else { // else push tree item "Loading..."
        list.push({
          parentClassName,
          level: _level + 1,
          classes: treeItem.classes.join(" "),
          loader: true,
        });
      }
    }
  });
  return list;
};

export const getTreeItemStateChecked = (item, checked = [], cache) => {
  const itemId = item.id;
  if (cache instanceof Map && cache.has(itemId)) {
    return cache.get(itemId);
  }

  let result;

  if (checked.includes(itemId)) {
    const itemChildren = item.children;

    // consider, that if the first child can be checked, then the others too.
    // if it is not so, update to check for each child.
    if (itemChildren?.length && isTreeItemCheckable(itemChildren[0])) {
      let countChildrenWithCheckedAll = 0;
      let countChildrenWithCheckedPartly = 0;

      let childStateChecked;
      for (let i = 0; i < itemChildren.length; i++) {
        childStateChecked = getTreeItemStateChecked(itemChildren[i], checked, cache);
        if (childStateChecked === TreeItemStateChecked.all) {
          countChildrenWithCheckedAll++;
        }
        if (childStateChecked === TreeItemStateChecked.part) {
          countChildrenWithCheckedPartly++;
        }
      }

      if (countChildrenWithCheckedAll === itemChildren.length) {
        result = TreeItemStateChecked.all;
      } else if (countChildrenWithCheckedAll || countChildrenWithCheckedPartly) {
        result = TreeItemStateChecked.part;
      } else {
        result = TreeItemStateChecked.none;
      }
    } else {
      result = TreeItemStateChecked.all;
    }
  } else {
    result = TreeItemStateChecked.none;
  }

  if (cache instanceof Map) {
    cache.set(itemId, result);
  }
  return result;
};

export const parseTreeWithChecked = (tree, checked) => {
  const checkedSpread = [...checked];
  const cacheStateChecked = new Map();
  const idsWithCheckedAll = [];
  const idsChildrenOfCheckedAll = [];
  const idsCheckedPartly = [];

  let checkedId, checkedNode;
  while (checkedSpread.length) {
    checkedId = checkedSpread.shift();

    checkedNode = findNode(checkedId, tree);
    if (checkedNode) {
      const checkedState = getTreeItemStateChecked(checkedNode, checked, cacheStateChecked);

      if (checkedState === TreeItemStateChecked.all) {
        idsWithCheckedAll.push(checkedId);

        if (checkedNode?.children?.length) {
          const checkedNodeChildrenIds = getNodeIds(checkedNode.children, checkedSpread);
          idsChildrenOfCheckedAll.push(...checkedNodeChildrenIds);

          removeFromArrayWithArray(idsWithCheckedAll, checkedNodeChildrenIds);
          removeFromArrayWithArray(checkedSpread, checkedNodeChildrenIds);
        }
      } else {
        idsCheckedPartly.push(checkedId);
      }
    }
  }

  return { idsWithCheckedAll, idsChildrenOfCheckedAll, idsCheckedPartly };
};

export const hasPath = (tree, path, id) => {
  if (!tree || !tree.length) {
    return false;
  }

  for (let i = 0; i < tree.length; i++) {
    const item = tree[i];
    path.push(item.id);

    if (item.id === id) {
      return true;
    }

    if (item.children) {
      if (hasPath(item.children, path, id)) {
        return true;
      }
    }

    path.pop();
  }

  return false;
};

export const findPath = (tree, id, withoutId = false) => {
  const path = [];

  if (hasPath(tree, path, id, withoutId)) {
    if (withoutId) {
      path.pop();
    }

    return path;
  }

  return path;
};

export const uncheckParents = (path, tree, updatedChecks) => {
  let end = false;

  if (!path.length) {
    return updatedChecks;
  }

  const lastId = path.pop();

  if (lastId) {
    const node = findNode(lastId, tree);

    if (node && node.children && node.children.length) {
      const oneOfChild = node.children.some((child) => updatedChecks.includes(child.id));

      if (!oneOfChild) {
        const index = updatedChecks.indexOf(node.id);
        updatedChecks.splice(index, 1);
      }
    }

    end = uncheckParents(path, tree, updatedChecks);
  }

  return end;
};

export const uncheckTree = (ids, tree, treeItemId, updatedChecks) => {
  ids.forEach((id) => {
    const index = updatedChecks.indexOf(id);

    if (index !== -1) {
      updatedChecks.splice(index, 1);
    }
  });

  const path = findPath(tree, treeItemId, true);

  return uncheckParents(path, tree, updatedChecks);
};

export const onReorderTree = (lang, defLang, tree, checked) => {
  let updatedChecks = [...new Set(checked)];

  const indexDefLang = updatedChecks.indexOf(defLang);
  if (indexDefLang > 0) {
    updatedChecks.splice(indexDefLang, 1);
    updatedChecks.unshift(defLang);
  }
  const index = updatedChecks.indexOf(lang);
  if (index > 0) {
    updatedChecks.splice(index, 1);
    updatedChecks.unshift(lang);
  }
  return updatedChecks.filter(Boolean);
};

export const onUpdateChecks = (treeItem, tree, checked, checkValue = "id") => {
  let updatedChecks = [...new Set(checked)];

  let itemId = treeItem[checkValue];

  const index = updatedChecks.indexOf(itemId);
  const { ids, parents } = getChildrenIds(itemId, tree);
  if (index === -1) {
    let parent = treeItem.parent;
    let parentId;
    while (parent) {
      parentId = parent[checkValue];
      if (parentId !== "home" && updatedChecks.indexOf(parentId) === -1) {
        updatedChecks.push(parentId);
      }
      parent = parent.parent;
    }

    updatedChecks = joinArrays([updatedChecks, ids, parents]);
  } else {
    updatedChecks = uncheckTree(ids, tree, itemId, updatedChecks);
  }

  return updatedChecks.filter(Boolean);
};
