import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useViewMode } from "../../hooks";
import { useNavigationContext } from "../NavigationContext";
import { useHistory } from "react-router-dom";
import { makeNavigationUrl } from "src/utils/URLUtils";
import { openNewTab } from "src/utils/RouterUtils";
import { usePopupContext } from "../popup/PopupContext";
import { MenuEvents } from "src/shared/constants";
import { CONTENT_CLASSES } from "../../shared/utils/content";

const TreeContextMenu = ({ item }) => {
  const { textMode } = useViewMode();
  const { t } = useTranslation();
  const { panelIds, openId, activeIndex, makeUpdateReaderPanels } = useNavigationContext();
  const history = useHistory();
  const { hidePopup } = usePopupContext();

  let listOfActions = [];

  if (item.className === CONTENT_CLASSES.BOOK) {
    listOfActions.push(MenuEvents.about);
  }

  if (!textMode) {
    if (panelIds.length === 0) {
      listOfActions.push(MenuEvents.openInWindow);
    } else {
      listOfActions.push(MenuEvents.openInWindow);
      listOfActions.push(MenuEvents.openInActiveSplit);
      listOfActions.push(MenuEvents.openInSplit);
    }
  }

  listOfActions.push(MenuEvents.openInTab);

  const handleClickContextItem = useCallback(
    (action) => {
      hidePopup();
      // workaround for prevent  open multiple time from one popup
      setTimeout(() => {
        switch (action) {
          case MenuEvents.about: {
            history.push(makeNavigationUrl(item));
            break;
          }
          case MenuEvents.openInWindow: {
            if (item.className === "book") {
              openId(item.id, {
                newWindow: true,
                className: CONTENT_CLASSES.CHAPTER,
                lang: item.lang,
                withHistory: true
              });
            } else {
              openId(item.id, { newWindow: true, className: item.className, lang: item.lang });
            }
            break;
          }
          case MenuEvents.openInSplit:
            openId(item.id, item);
            break;
          case MenuEvents.openInActiveSplit:
            openId(item.id, { ...item, replacePanelIndex: activeIndex, });
            break;
          case MenuEvents.openInTab:
            openNewTab(item);
            break;
          default:
            openId(item.id, { newWindow: true, className: item.className, lang: item.lang });
            break;
        }
      }, 25);
    },
    [hidePopup, openId, history, makeUpdateReaderPanels, activeIndex]
  );

  return (
    <React.Fragment>
      {listOfActions.map((action, index) => {
        return (
          <div
            className="context-menu-item"
            key={index}
            onClick={() => handleClickContextItem(action)}>
            {t(action)}
          </div>
        );
      })}
    </React.Fragment>
  );
};

TreeContextMenu.propTypes = {
  item: PropTypes.object
};

const useTreeContextMenu = () => {
  const { showPopup } = usePopupContext();

  const openTreeContextMenu = useCallback((e, item, alignToLeft) => {
    e.preventDefault();

    if ([
      CONTENT_CLASSES.BOOK, CONTENT_CLASSES.CHAPTER, CONTENT_CLASSES.PARAGRAPH
    ].indexOf(item.className) !== -1) {
      const component = <TreeContextMenu item={item} />;
      const position = { top: e.clientY };

      if (alignToLeft) {
        position.right = e.clientX;
      } else {
        position.left = e.clientX;
      }

      if (position.top === undefined) {
        const targetRect = e.target.getBoundingClientRect();
        position.top = targetRect.bottom;

        if (alignToLeft) {
          position.right = targetRect.left;
        } else {
          position.left = targetRect.right;
        }
      }

      showPopup(position, component, { hideOnClick: true, hideOnBackMouseUp: false, });
    }
  }, [showPopup]);

  return openTreeContextMenu;
};

export default useTreeContextMenu;
