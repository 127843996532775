/* eslint-disable no-console */
import {
  put,
  call,
  select,
  takeEvery,
  actionChannel,
  take,
  all,
  fork,
  delay,
} from "redux-saga/effects";
import deepEqual from "deep-equal";

import {
  COLLAPSE_TREE_ELEMENT,
  ACTION_CHECK_TREE,
  ACTION_CHANGE_LANG,
  ACTION_LANGUAGE,
  ACTION_INIT_APP_LOAD,
  RESTORE_DATA,
  ACTION_AUTH_USER,
  FETCH_TRANSLATION_BOOK,
  FETCH_SEGMENTS_TRANSLATION,
  FETCH_AVAILABLE_LANGUAGES,
} from "./constants";
import {
  actionLoading,
  actionAddMessage,
  actionInitAppLoadSuccess,
  actionUpdateSettingSuccess,
  onLoginAction,
  actionFetchGeoIpDataSuccess,
  onLoginSuccess,
  actionUpdateSetting,
  actionGetTranslations,
} from "./actions";
import {
  findNode,
  insertParasToBook,
  joinObjArrays,
  getLanguageDefault,
  collectAllNodes,
  joinArrays,
  arraymove,
  toggleInArray,
} from "../utils/Utils";
import { getBookIds, getQueryByName, PANELS } from "../utils/URLUtils";
import { onUpdateChecks } from "../utils/TreeUtils";
import { getContentByParaId, normalizeParaId } from "../components/reader/ReaderUtils";
import {
  actionUpdateSubsCountSuccess,
  fetchSubscriptionBooksSuccess,
  fetchSubscriptionsSuccess,
} from "../components/subscriptions/subscriptions.actions";
import {
  getFoldersRequest,
  getBooksByFolderRequest,
  getLanguagesRequest,
  getBookByIdRequest,
  getChapterContentRequest,
  getParagraphsRequest,
  getBiblesRequest,
  LoadDirection,
  getSubscriptionBooksRequest,
  getSettingsRequest,
  fetchBaseDataRequest,
  fetchBaseUserDataRequest,
  getUserInfo,
  fetchLibLangsRequest,
  getContentPreviewRequest,
} from "../api/API";
import { fetchDictionariesRequest } from "../api/SearchAPI";
import {
  actionGetTranslationsSuccess,
  actionGetSegmentsTranslationSuccess,
  actionGetAvailableLanguagesSuccess,
} from "./actions";
import { JTLBooks, makeChapterContent } from "../utils/ContentUtils";
import { TOAST_TYPE } from "../components/popup/Toaster";
import { actionStoreHistoryData, setLibLangs } from "./library.actions";
import { getTokens, setTokens } from "../shared/utils/systemUtils";
import { initFetchToken } from "../api/BaseAPI";
import {
  applySettings,
  makeTimeZoneCheck,
  restoreSavedData,
  Settings,
  validateSettings,
} from "../utils/Settings";
import {
  getAllSubscriptionsRequest,
  getUserTimeRequest,
} from "../components/subscriptions/SubscriptionsAPI";
import {
  CONTENT_CLASSES,
  getBookId,
  getBookOrigin,
  getParagraph,
  sortByArray,
} from "../shared/utils/content";
import { fetchRelevantSearchFacetAggregate } from "../components/relevantSearch/relevantSearch.actions";
import { DEFAULT_LANGUAGE } from "src/shared/utils/i18n";
import { joinHistoryData } from "../api/api.utils";
import { storeBooks } from "../api/CacheHolder";
import { SearchActions } from "src/components/search/search.actions";
import { isUserEditor } from "src/components/studyCenter/EditorCenterUtils";
import { getShopBooksRequest } from "src/shared/api/shopApi";
import { shopActions } from "./shop/actions";
import { folderTreeActionConstants, folderTreeActions } from "./folderTree/actions";
import {
  checkForObsoleteColorsInEntries,
  makeRichContent,
  updateColorOfEntries,
} from "src/components/studyCenter/StudyCenterUtils";
import {
  getAvailableLanguagesRequest,
  getBooksInTranslations,
  getSegmentsTranslationRequest,
} from "src/api/TranslateAPI";
import { isBookBibleByRealType } from "../utils/BookUtils";
import { AppState } from "src/shared/constants";
import { treesActions } from "./trees/actions";
import { Period } from "../utils/AdvSearchTypes";
import { loadBooksDataWorker } from "./saga.utils";
import { SearchType } from "../shared/utils/search";
import { URLS } from "../shared/utils/url";
import { ContentActions, ContentConstants } from "./content.actions";
import { makeTranslateBookLoader } from "src/api/translate.utils";

const TAG = "MAINSAGA";

export const DEBOUNCE_DELAY = 250;

// after 30 min unused book, remove it from memory
const MAX_CACHE_TIME = 1000 * 60 * 30;
const CACHE_ITEMS_LIMIT = 10;

// const SPECIAL_CHECKED_EN = ["en", 2, 4, 1227, 5, 8, 9, 10, 14, 253, 1257, 1242, 1277];
// const SPECIAL_EXPANDED_EN = ["en", 2];

export function* authUserMethod(action) {
  const { token, refreshToken, i18nLang, loadLang, isFetchInitLoad = false } = action.data;

  yield put(onLoginAction(token, refreshToken));

  const { isLogin } = yield select((state) => state.system);

  const storedData = restoreSavedData();
  let allSettings = { ...storedData };

  if (isLogin) {
    let baseUserData = {};
    try {
      baseUserData = yield call(fetchBaseUserDataRequest);
    } catch (error) {
      console.log("authUserMethod fetchBaseUserDataRequest", error);
    }

    const userInfo = yield call(getUserInfo);
    if (userInfo) {
      allSettings[Settings.userInfo.id] = userInfo;
    }
    allSettings[Settings.textMode.id] = false;
    let timeData = baseUserData.timeData;
    if (!timeData) {
      timeData = yield call(getUserTimeRequest);
    }
    if (timeData) {
      const actionTimeData = makeTimeZoneCheck(timeData);
      if (actionTimeData) {
        allSettings[Settings.timeZone.id] = actionTimeData.timeZone;
        allSettings[Settings.deliveryTime.id] = actionTimeData.deliveryTime;
      }
    }

    if (baseUserData.subsUnreadCount) {
      yield put(actionUpdateSubsCountSuccess(baseUserData.subsUnreadCount));
    }

    const allHistoryItems = joinHistoryData(
      baseUserData.reading,
      baseUserData.listen,
      baseUserData.ml,
      baseUserData.library,
    );
    yield put(actionStoreHistoryData(allHistoryItems));

    // search history can be only 1000 elements length
    if (baseUserData.searchHistory) {
      if (baseUserData.searchHistory.length > 1000) {
        const sliced = baseUserData.searchHistory.slice(0, 1000);
        yield put(SearchActions.fetchSearchHistorySuccess(sliced));
      } else {
        yield put(SearchActions.fetchSearchHistorySuccess(baseUserData.searchHistory));
      }
    }

    let userLang = i18nLang;
    let languages = baseUserData.languages;
    if (!languages) {
      languages = yield call(fetchLibLangsRequest);
    }
    if (languages?.length > 0) {
      userLang = languages[0];
      allSettings[Settings.libraryLanguages.id] = languages;
      allSettings[Settings.filterLibraryLanguages.id] = languages;
    } else {
      yield put(setLibLangs([i18nLang]));
    }
    window.userLang = userLang;

    try {
      const allSubs = yield call(getAllSubscriptionsRequest);
      if (allSubs) {
        yield put(fetchSubscriptionsSuccess(allSubs));
      }
    } catch (error) {
      console.log("function*getAllSubscriptionsRequest -> error: ", error);
    }

    try {
      const allShopBooks = yield call(getShopBooksRequest);
      if (allShopBooks?.books) {
        yield put(shopActions.fetchAllShopBooksSuccess(allShopBooks.books));
      }
    } catch (error) {
      console.log("function*getShopBooksRequest -> error: ", error);
    }

    try {
      let serverSettings = baseUserData.settings;
      if (!serverSettings) {
        serverSettings = yield call(getSettingsRequest);
      }
      if (serverSettings) {
        const settings = validateSettings(serverSettings, true);
        allSettings = {
          ...allSettings,
          ...settings,
        };
      }
    } catch (error) {
      console.log("function*getSettingsMethod -> error: ", error);
    }

    // Need for set default langs of user after login in app
    if (loadLang) {
      yield call(actionChangeLanguageMethod, {
        data: {
          lang: userLang,
          initCheck: true,
        },
      });
    }
  }
  applySettings(allSettings);
  yield put({ type: RESTORE_DATA, data: allSettings });
  yield put(actionUpdateSettingSuccess(allSettings));
  yield put(onLoginSuccess({ isFetchInitLoad }));
}

export function* fetchInitLoadMethod(action) {
  const { lang, searchParams } = action.data;
  const { isLogin } = yield select((state) => state.system);
  const { searchLang } = yield select((state) => state.search);
  let currentLang = (!isLogin ? searchLang : lang) || lang || DEFAULT_LANGUAGE;
  const { refreshToken } = getTokens();
  const tokens = yield call(initFetchToken, refreshToken);

  if (!tokens.token) {
    yield put(actionInitAppLoadSuccess(AppState.serverDown));
    return;
  }

  setTokens(tokens.token, tokens.refreshToken);

  try {
    const { libraryLanguages } = yield select((state) => state.settings);
    if (libraryLanguages?.length) {
      currentLang = getLanguageDefault(libraryLanguages, lang);
    }
    let baseData = {};
    try {
      baseData = yield call(fetchBaseDataRequest, currentLang);
    } catch (error) {
      console.log("fetchInitLoadMethod fetchBaseDataRequest", error);
    }

    if (baseData.subscriptions) {
      yield put(fetchSubscriptionBooksSuccess(baseData.subscriptions));
    } else {
      const subscriptions = yield call(getSubscriptionBooksRequest);
      if (subscriptions) {
        yield put(fetchSubscriptionBooksSuccess(subscriptions));
      }
    }

    const options = {};
    const dictionaries = yield call(fetchDictionariesRequest);
    if (dictionaries) {
      options.dictionaries = dictionaries;
    }

    let bibles = baseData.bibles;
    if (!bibles) {
      bibles = yield call(getBiblesRequest);
    }
    if (bibles) {
      options.bibles = bibles;
    }
    const chachedBooks = storeBooks(bibles);
    if (chachedBooks) {
      options.books = chachedBooks;
    }

    let mainTree = baseData.langs || [];
    if (baseData.langs && baseData.folders) {
      const langItem = mainTree.find((item) => item.id === currentLang);
      langItem.children = baseData.folders;
    }
    yield put(ContentActions.fetchFoldersToRootSuccess(mainTree, options));

    // this implemented on backend but keep logic for back compatibility
    if (baseData.presets) {
      yield put(folderTreeActions.fetchPresetCheckedSuccess(baseData.presets));
    }

    if (baseData.geoIpData) {
      yield put(actionFetchGeoIpDataSuccess(baseData.geoIpData));
    }

    yield call(authUserMethod, {
      data: {
        token: tokens.token,
        refreshToken: tokens.refreshToken,
        i18nLang: lang,
        isFetchInitLoad: true,
      },
    });

    yield call(actionChangeLanguageMethod, {
      data: {
        lang: currentLang,
        searchParams,
        initCheck: true,
      },
    });

    // this code below starts a search when the app opens with link with search parameters in URL
    if (searchParams) {
      //todo implement
      const extrasDefault = { type: SearchType.basic, period: Period.all, ...searchParams.extras };
      let langs = searchParams.langs || [];
      let searchLang = langs[0];

      yield put(SearchActions.setSearchQuery(searchParams.query, searchLang));

      if (searchParams.isRelated && searchParams.query) {
        yield put(SearchActions.fetchSuggestion(searchParams.query, searchLang));
        yield put(
          fetchRelevantSearchFacetAggregate(
            searchParams.lang,
            searchParams.query,
            searchParams.onlyText,
          ),
        );
      }
      yield put(
        SearchActions.fetchSearch({
          ...searchParams,
          langs,
          extras: extrasDefault,
          firstSearch: searchParams.firstSearch,
          // onlyText: type === "onlyText",
        }),
      );
    }

    yield put(actionInitAppLoadSuccess(AppState.loaded));
  } catch (e) {
    console.log("fetchInitLoadMethod error", e);
    yield put(actionInitAppLoadSuccess(AppState.serverDown));
  }
}

//TODO sync init load with book
export function* fetchFolderWorker(action) {
  try {
    const { data: lang, checkItems } = action;

    let { mainTree, bibles } = yield select((state) => state.mainTree);
    if (mainTree.length === 0) {
      mainTree = yield call(getLanguagesRequest);
    }
    if (bibles.length === 0) {
      bibles = yield call(getBiblesRequest);
      yield put(ContentActions.fetchBiblesSuccess(bibles));
    }
    const currentLang = findNode(lang, mainTree);

    if (currentLang && !currentLang?.children) {
      let langFolderList = yield call(getFoldersRequest, lang);
      currentLang.children = langFolderList;
    }
    yield put(ContentActions.fetchFoldersToRootSuccess(mainTree));

    if (checkItems && currentLang) {
      let { checked } = yield select((state) => state.folderTree);
      if (checked.indexOf(lang) !== -1) {
        const allNodes = [];
        collectAllNodes(currentLang, allNodes, ["folder"]);
        const ids = allNodes.map((item) => item.id);
        const newChecked = joinArrays([checked, ids]);
        yield put(treesActions.updateChecked(newChecked));
      }
    }
  } catch (error) {
    console.log(TAG, error);
    yield put(ContentActions.fetchFoldersRequestError(error));
  }
}

export function* actionChangeLanguageMethod(action) {
  const { lang, searchParams, initCheck } = action.data;
  yield fetchFolderWorker({ data: lang, searchParams, initCheck });
}

export function* fetchBooksWorker(action) {
  try {
    const { mainTree, bookLangMap } = yield select((state) => state.mainTree);
    const folderId = action.data;
    const { checkItems } = action;

    let booksNode = findNode(folderId, mainTree);
    if (booksNode.children) {
      const bookList = yield call(getBooksByFolderRequest, folderId);
      // save periodicals list for navigation
      if (bookList) {
        if (bookList[0].type === "periodical") {
          yield put(ContentActions.fetchPeriodicalsSuccess(bookList));
        }
        const booksForSale = [];
        bookList.forEach((book) => {
          if (book.isForSale) {
            booksForSale.push(book.book_id);
          }
          bookLangMap[book.id] = book.lang;
        });

        booksNode.children = bookList;
        yield put(
          ContentActions.fetchFoldersToRootSuccess(mainTree, { bookLangMap, books: bookList }),
        );
      }
      if (booksNode.lang) {
        yield put(actionGetTranslations(booksNode.lang));
      }
      if (checkItems && booksNode) {
        const { checked } = yield select((state) => state.folderTree);
        const index = checked.indexOf(booksNode.id);
        if (index !== -1) {
          const ids = booksNode.children.map((item) => item.id);
          const newChecked = joinArrays([checked, ids]);
          yield put(treesActions.updateChecked(newChecked));
        }
      }
    }
  } catch (error) {
    console.log(TAG, error);
  }
}

export function* fetchBooksByIdsWatcher() {
  const requestChan = yield actionChannel(ContentConstants.FETCH_BOOKS_BY_IDS);
  while (true) {
    const action = yield take(requestChan);
    const { data: bookIds } = action;

    yield call(loadBooksDataWorker, bookIds);
  }
}

export function* fetchFolderForBookWatcher() {
  const requestChan = yield actionChannel(ContentConstants.FETCH_FOLDERS_FOR_BOOK);
  while (true) {
    const action = yield take(requestChan);
    const { book } = action.data;
    let mainTree = yield select((state) => state.mainTree.mainTree);
    const { bookLangMap } = yield select((state) => state.mainTree);
    let langs = [...mainTree];
    let folder = findNode(book.folder_id, mainTree);

    if (!folder) {
      if (mainTree.length === 0) {
        langs = yield call(getLanguagesRequest);
        yield put(ContentActions.fetchFoldersToRootSuccess(langs));
      }
      const folders = yield call(getFoldersRequest, book.lang);

      if (folders) {
        langs.find((item) => item.id === book.lang).children = folders;
        folder = findNode(book.folder_id, langs);
      }
    }
    let books;
    if (folder) {
      const parentHolder = [];
      let bookFind = findNode(book.id, folder, "id", parentHolder);

      if (!bookFind) {
        books = yield call(getBooksByFolderRequest, book.folder_id);
        if (books) {
          // save periodicals list for navigation
          if (books[0].type === "periodical") {
            yield put(ContentActions.fetchPeriodicalsSuccess(books));
          }
          folder.children = books;
          books.forEach((item) => {
            bookLangMap[item.id] = item.lang;
          });

          bookFind = findNode(book.id, folder, "id", parentHolder);
        }
      }

      bookFind.realType = folder.className;
      bookFind.children = book.children;
      bookFind.chapters = book.chapters;

      /* ---- Spread the new book item to invoke Redux updates. ---- */
      const bookIndex = folder.children.findIndex((item) => item === bookFind);

      if (bookIndex !== -1) {
        folder.children[bookIndex] = { ...bookFind };
      }
    }

    yield put(ContentActions.fetchFoldersToRootSuccess(langs, { bookLangMap, books }));
  }
}

export function* fetchChapterContentMethod(action) {
  try {
    const { mainTree } = yield select((state) => state.mainTree);
    const { data: paraId } = action;

    let para = findNode(paraId, mainTree);
    //TODO implement check cached values
    if (para.className === CONTENT_CLASSES.PARAGRAPH) {
      return;
    }
    const bookId = getBookId(paraId);
    //LOAD BOOK
    let book = findNode(bookId, mainTree);
    if (!(book.children || []).length) {
      book = yield call(getBookByIdRequest, bookId);
      if (book) {
        yield put(ContentActions.fetchFolderForBook(book));
      } else {
        yield put(ContentActions.actionErrorBookLoad(bookId));
        return;
      }
    }

    // no need to fetch paragraphs for non-bible books.
    // only bible books have the requirement to show the paragraphs in the tree.
    if (!isBookBibleByRealType(book.realType)) {
      return;
    }

    const content = yield call(getChapterContentRequest, paraId, book.realType, book.type);
    if (content) {
      insertParasToBook(book, content);
    }
    yield put(ContentActions.fetchFoldersToRootSuccess(mainTree));
  } catch (error) {
    console.log(TAG, error);
  }
}

export function* fetchBooksTranslationProgressesWorker(action) {
  const language = action.data;
  try {
    const { translations, ellen4allToken } = yield select((state) => state.translate);
    if (!ellen4allToken) {
      return;
    }
    // Check for exist any book for current lang
    const transKeys = Object.keys(translations);
    let isExistLang = false;
    for (let index = 0; index < transKeys.length; index++) {
      const element = transKeys[index];
      if (translations[element].lang === language) {
        isExistLang = true;
        break;
      }
    }
    if (isExistLang) {
      return;
    }

    const content = yield call(getBooksInTranslations, language);
    if (content) {
      const bookMap = {};
      content?.results?.forEach((el) => {
        const bookId = getBookId(el.original.key);
        bookMap[bookId] = {
          ...el,
          lang: language,
          bookId: bookId,
        };
      });
      yield put(actionGetTranslationsSuccess(bookMap));
    }
  } catch (error) {
    console.log(TAG, error);
  }
}

export function* fetchSegmentsMethod(action) {
  try {
    const { segmentID, paraID, bookID } = action.data;
    const content = yield call(getSegmentsTranslationRequest, segmentID, paraID, bookID);
    yield put(actionGetSegmentsTranslationSuccess(content));
  } catch (error) {
    console.log(TAG, error);
  }
}

export function* fetchAvailableLanguagesMethod(action) {
  const bookId = action.data;
  try {
    const { languages } = yield select((state) => state.translate);
    if (languages[bookId] !== undefined) {
      return;
    }
    yield put(actionLoading(makeTranslateBookLoader(bookId)));
    const content = yield call(getAvailableLanguagesRequest, bookId);
    const { libraryLanguages } = yield select((state) => state.settings);
    const sortedLangs = sortByArray(content, libraryLanguages, "code");
    yield put(actionGetAvailableLanguagesSuccess(bookId, sortedLangs));
  } catch (error) {
    console.log(TAG, error);
  } finally {
    yield put(actionLoading(makeTranslateBookLoader(bookId), true));
  }
}

export function* cleanMarkParagraphsMethod() {
  const { paragraphs, booksCacheInfo } = yield select((store) => store.paragraphReducer);
  Object.keys(paragraphs).forEach((key) => {
    const bookContent = paragraphs[key];
    const bookData = booksCacheInfo[key];
    bookData.search = undefined;
    if (bookContent) {
      bookContent.forEach((item) => {
        item.content = item.content.replace(/<mark>|<\/mark>/g, "");
      });
    }
  });
  yield put(ContentActions.fetchParagraphsSuccess(paragraphs));
  yield put(ContentActions.updateBookInfo(booksCacheInfo));
}

export function* _storeParagraphs(bookId, content, search) {
  const { paragraphs, booksCacheInfo } = yield select((store) => store.paragraphReducer);
  let bookContent = paragraphs[bookId] || [];
  let contentArray = content;

  const bookData = booksCacheInfo[bookId] || {};
  if (!search || (search && search === bookData.search)) {
    contentArray = joinObjArrays([bookContent, content], "id");
    contentArray.sort((a1, a2) => a1.puborder - a2.puborder);
  }
  paragraphs[bookId] = contentArray;
  booksCacheInfo[bookId] = {
    lastUpdate: Date.now(),
    lastUse: Date.now(),
    search,
  };

  // logic for clear very old used books and not oppened in reader
  // need invistigate about get window location in saga
  if (window?.location?.search && window.location.pathname === URLS.read) {
    const panels = getQueryByName(PANELS, window?.location?.search);
    if (panels) {
      const openedBooks = getBookIds(panels.split(","));
      let bookList = Object.keys(booksCacheInfo).map((bookId) => {
        return {
          id: bookId,
          ...booksCacheInfo[bookId],
        };
      });
      bookList.sort((a, b) => {
        return b.lastUse - a.lastUse;
      });
      bookList.forEach((item, index) => {
        if (index > CACHE_ITEMS_LIMIT) {
          const lastUseDelta = Date.now() - item.lastUse;
          if (lastUseDelta > MAX_CACHE_TIME && !openedBooks.includes(item.id)) {
            paragraphs[item.id] = [];
            booksCacheInfo[item.id] = {};
          }
        }
      });
    }
  }

  // Double call because actionMakeRichContent can be long operation
  yield put(ContentActions.fetchParagraphsSuccess(paragraphs));
  yield put(ContentActions.updateBookInfo(booksCacheInfo));
  yield put(ContentActions.actionMakeRichContent(bookId));
}

/**
 * Channel for join SC entries with paragraph content.
 * Must be in background mode because add sc entries in dom node is no react way
 */

export function* makeRichContentWatcher() {
  const requestChan = yield actionChannel(ContentConstants.ACTION_MAKE_RICH_CONTENT);
  while (true) {
    const action = yield take(requestChan);
    const { bookId, hardUpdate } = action.data;
    const { entries, colors, currentEntryId } = yield select((state) => state.studyCenter);
    const { paragraphs, booksCacheInfo } = yield select((store) => store.paragraphReducer);
    const { isLogin } = yield select((state) => state.system);
    const { studyCenter, editorTempEntry } = yield select((state) => state.settings);

    let bookContent = paragraphs[bookId] || [];
    if (isLogin && studyCenter && bookContent.length > 0) {
      const isEditorMode = isUserEditor();
      const entryList = entries.filter((item) => item.bookId === bookId);
      const bookData = booksCacheInfo[bookId] || {};
      const entryIds = entryList.map((entry) => entry.id);
      const contentIds = bookContent.map((item) => item.id);
      entryIds.sort();
      contentIds.sort();

      if (entryList.length > 0 || bookData?.entryIds || isEditorMode) {
        if (
          !(deepEqual(entryIds, bookData.entryIds) && deepEqual(contentIds, bookData.contentIds)) ||
          (!!editorTempEntry?.hasNewData && isEditorMode) ||
          checkForObsoleteColorsInEntries(entryList, colors) ||
          hardUpdate
        ) {
          const newRichContent = makeRichContent(
            bookContent,
            updateColorOfEntries(entryList, colors, isEditorMode),
            editorTempEntry,
            currentEntryId,
          );
          paragraphs[bookId] = newRichContent;
          yield put(ContentActions.fetchParagraphsSuccess(paragraphs));
          if (editorTempEntry?.hasNewData) {
            yield put(
              actionUpdateSetting(Settings.editorTempEntry.id, {
                ...editorTempEntry,
                hasNewData: false,
              }),
            );
          }
        }
        booksCacheInfo[bookId] = {
          ...bookData,
          entryIds: entryIds,
          contentIds,
          lastUpdate: Date.now(),
          lastUse: Date.now(),
        };

        yield put(ContentActions.updateBookInfo(booksCacheInfo));
      }
    }
    yield delay(DEBOUNCE_DELAY);
  }
}
const LOAD_PARAGRAPHS_LIMIT = 25;
/**
 * Channel for load paragraphs step by step.
 * Need for avoid collisions of instant loading several folders.
 */
export function* fetchParagraphsWatcher() {
  const requestChan = yield actionChannel(ContentConstants.FETCH_PARAGRAPHS);
  while (true) {
    const action = yield take(requestChan);
    const { paraId, search, lastParaId, paraPreviewId } = action.data;
    const bookId = getBookId(paraId);
    try {
      const state = yield select();
      const { paragraphs, booksCacheInfo } = state.paragraphReducer;
      let { mainTree } = state.mainTree;

      //LOAD BOOK
      let book = findNode(bookId, mainTree);

      if (!(book.children || []).length) {
        book = yield call(getBookByIdRequest, bookId);
        if (book) {
          yield put(ContentActions.fetchFolderForBook(book));
        } else {
          yield put(ContentActions.actionErrorBookLoad(bookId));
          continue;
        }
      }

      let ParaOffset = LOAD_PARAGRAPHS_LIMIT;

      if (isBookBibleByRealType(book.realType)) {
        ParaOffset = LOAD_PARAGRAPHS_LIMIT * 2;

        const { mainTree } = yield select((state) => state.mainTree);
        let para = findNode(paraId, mainTree);
        if (!para) {
          const content = yield call(getChapterContentRequest, paraId, book.realType, book.type);
          if (content) {
            insertParasToBook(book, content);
            yield put(ContentActions.fetchFoldersToRootSuccess(mainTree));
          }
        }
      }

      let bookContent = paragraphs[bookId] || [];
      const bookData = booksCacheInfo[bookId] || {};

      let content = [];
      const normParaId = normalizeParaId(paraId, book.chapters);
      if (!search || (search && search === bookData.search)) {
        content = getContentByParaId(normParaId, bookContent, search);
      }

      let index = content.findIndex((item) => item.id === normParaId);

      let lastIndex = -1;
      let delta = 0;

      if (lastParaId) {
        lastIndex = content.findIndex((item) => item.id === lastParaId);
      }
      // load more content if reader can show a lot of elements
      if (lastIndex !== -1) {
        delta = lastIndex - index;
      }

      let loadId;
      let direction;
      let offset = Math.max(ParaOffset, delta);

      if (content.length === 0) {
        loadId = normParaId;
        direction = LoadDirection.both;
        offset = ParaOffset * 2;
      } else {
        if (index < ParaOffset / 2) {
          const [first] = content;
          if (first && first.id_prev) {
            loadId = first.id_prev;
            direction = LoadDirection.up;
          }
          // Need for special case with very long reader with big size of visible paragraphs
          if (!loadId && delta > ParaOffset / 2) {
            const lastValue = content[content.length - 1];
            if (lastValue.id_next) {
              loadId = lastValue.id_next;
              direction = LoadDirection.down;
            }
          }
        } else if (
          index > content.length - ParaOffset / 2 ||
          (lastIndex !== -1 && lastIndex > content.length - ParaOffset / 2)
        ) {
          const lastValue = content[content.length - 1];
          if (lastValue.id_next) {
            loadId = lastValue.id_next;
            direction = LoadDirection.down;
          }
        }
      }
      if (loadId) {
        yield put(actionLoading(bookId));
        const response = yield call(getParagraphsRequest, loadId, offset, direction, search);
        if (response) {
          const loadContent = makeChapterContent(response, book.realType, book.type);
          yield _storeParagraphs(bookId, loadContent, search);
        }
      } else {
        if (bookData) {
          bookData.lastUse = Date.now();
          yield put(ContentActions.updateBookInfo(booksCacheInfo));
        }
      }
      if (paraPreviewId) {
        const { paragraphs: paras } = yield select((state) => state.paragraphReducer);
        const paragraph = getParagraph(paraId, paras);
        if (paragraph) {
          yield put(
            ContentActions.fetchParagraphPreviewSuccess({ ...paragraph, previewId: paraId }),
          );
        }
      }
    } catch (error) {
      yield put(actionAddMessage());
      console.log(TAG, error);
    } finally {
      yield put(actionLoading(bookId, true));
    }
  }
}

export function* fetchParagraphPreviewWatcher() {
  const requestChan = yield actionChannel(ContentConstants.FETCH_PARAGRAPH_PREVIEW);
  while (true) {
    const action = yield take(requestChan);
    const { paraId, parentBookId } = action.data;
    let targetParaId = paraId;
    const bookId = getBookId(paraId);
    const { bibles } = yield select((state) => state.mainTree);
    const { baseBible, jtlBaseBible, libraryLanguages } = yield select((state) => state.settings);
    const targetBibleBook = JTLBooks.includes(parentBookId) ? jtlBaseBible : baseBible;
    const bibleBook = bibles.find((item) => item.id === bookId);
    if (bibleBook && targetBibleBook !== bookId) {
      const previewDataResponse = yield call(
        getContentPreviewRequest,
        targetParaId,
        libraryLanguages,
        "bible",
      );
      if (previewDataResponse) {
        const baseBiblePreview = previewDataResponse.find(
          (item) => item.bookId === targetBibleBook,
        );
        if (baseBiblePreview) {
          targetParaId = baseBiblePreview.paraId;
        }
      }
    }
    const { paragraphs } = yield select((state) => state.paragraphReducer);
    const paragraph = getParagraph(targetParaId, paragraphs);
    if (paragraph) {
      yield put(ContentActions.fetchParagraphPreviewSuccess({ ...paragraph, previewId: paraId }));
    } else {
      yield put(ContentActions.fetchParagraphs(targetParaId, undefined, undefined, paraId));
    }
  }
}

export function* fetchBookDetailsWorker(action) {
  const bookId = action.data;
  try {
    const { mainTree } = yield select((state) => state.mainTree);

    let book = findNode(bookId, mainTree);
    if (!book || (book && !book.children)) {
      book = yield call(getBookByIdRequest, bookId);
      if (book) {
        yield put(ContentActions.fetchFolderForBook(book));
      } else {
        yield put(ContentActions.actionErrorBookLoad(bookId));
      }
    }
  } catch (error) {
    yield put(ContentActions.actionErrorBookLoad(bookId));
    yield put(actionAddMessage("Can't load a book with id " + bookId, TOAST_TYPE.error));
    console.log(TAG + "_fetchBookDetailsWorker error", error);
  }
}

/*************************** LOGIC WITH TREE part **************************************/
export function* actionCheckTree(action) {
  if (action.options.drop) {
    yield put(folderTreeActions.updateChecked([]));
  }
  const item = action.data;
  const { libraryLanguages } = yield select((state) => state.settings);
  const { mainTree } = yield select((state) => state.mainTree);
  const { checked } = yield select((state) => state.folderTree);
  const defLang = getLanguageDefault(libraryLanguages);
  const checkedNew = onUpdateChecks(item, mainTree, checked);

  // TODO prevent call update search when user just scroll a reader each time with same data
  if (!deepEqual(checked.sort(), checkedNew.sort())) {
    const indexDefLang = checkedNew.indexOf(defLang);
    if (indexDefLang > 0) {
      arraymove(checkedNew, indexDefLang, 0);
    }
    yield put(folderTreeActions.updateChecked(checkedNew));
    yield put(SearchActions.searchWithCurrentTree());
  }
}

export function* actionChangeLangAsync(action) {
  const updatedChecks = action.data.checked;
  const state = yield select();
  const { checked } = state.folderTree;
  if (!deepEqual([...checked].sort(), [...updatedChecks].sort())) {
    const { searchParams } = yield select((state) => state.search);
    if (searchParams.query !== "") {
      yield put(SearchActions.fetchSearch({ ...searchParams, folders: updatedChecks }));
    }
  }
}

export function* invalidateFolderItem(action) {
  const { id, className, children, nbooks } = action.data;

  if (!children || children.length === 0) {
    if (nbooks > 0) {
      yield fetchBooksWorker({ data: id, checkItems: true });
    } else if (className === CONTENT_CLASSES.LANGUAGE) {
      yield fetchFolderWorker({ data: id, checkItems: true });
    } else if (className === CONTENT_CLASSES.BOOK) {
      yield fetchBookDetailsWorker({ data: id });
    } else if (className === CONTENT_CLASSES.CHAPTER) {
      yield fetchChapterContentMethod({ data: id });
    }
  }
}

export function* actionExpandTreeAsync(action) {
  const { drop } = action.options;
  const item = action.data;
  if (drop) {
    yield put(folderTreeActions.updateExpanded([]));
  }
  const { expanded } = yield select((state) => state.folderTree);

  const newExpandList = [...expanded];
  const index = toggleInArray(newExpandList, item.id);
  yield put(folderTreeActions.updateExpanded(newExpandList));

  if (index === -1) {
    yield put(folderTreeActions.invalidateItem(item));
  }
}
export function* actionCollapseElementAsync(action) {
  const { expanded } = yield select((state) => state.folderTree);
  let newExpandList = expanded.filter((e) => {
    return getBookOrigin(e) !== action.data;
  });
  yield put(folderTreeActions.updateExpanded([...newExpandList]));
}

export default function* contentSaga() {
  yield all([
    takeEvery(ACTION_AUTH_USER, authUserMethod),
    takeEvery(ACTION_INIT_APP_LOAD, fetchInitLoadMethod),
    takeEvery(ContentConstants.FETCH_FOLDERS, fetchFolderWorker),
    takeEvery(ContentConstants.FETCH_BOOKS, fetchBooksWorker),
    takeEvery(ContentConstants.FETCH_BOOKS_DETAILS, fetchBookDetailsWorker),
    takeEvery(ContentConstants.FETCH_CHAPTER_CONTENT, fetchChapterContentMethod),
    takeEvery(folderTreeActionConstants.EXPAND_TREE, actionExpandTreeAsync),
    takeEvery(folderTreeActionConstants.INVALIDATE_ITEM, invalidateFolderItem),
    takeEvery(FETCH_TRANSLATION_BOOK, fetchBooksTranslationProgressesWorker),
    takeEvery(FETCH_SEGMENTS_TRANSLATION, fetchSegmentsMethod),
    takeEvery(FETCH_AVAILABLE_LANGUAGES, fetchAvailableLanguagesMethod),
    takeEvery(COLLAPSE_TREE_ELEMENT, actionCollapseElementAsync),
    takeEvery(ACTION_CHECK_TREE, actionCheckTree),
    takeEvery(ACTION_CHANGE_LANG, actionChangeLangAsync),
    takeEvery(ContentConstants.CLEAN_PARAGRAPHS, cleanMarkParagraphsMethod),
    takeEvery(ACTION_LANGUAGE, actionChangeLanguageMethod),

    fork(fetchBooksByIdsWatcher),
    fork(fetchParagraphsWatcher),
    fork(makeRichContentWatcher),
    fork(fetchFolderForBookWatcher),
    fork(fetchParagraphPreviewWatcher),
  ]);
}
