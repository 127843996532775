import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ContentIcon } from "../../assets/CommonIcons";
import { useViewMode } from "../../hooks";
import { actionCopy } from "../../redux/actions";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { IconButton } from "../views";
import ShopBookPackage from "./ShopBookPackage";
import AccordionBookTranslations from "./AccordionBookTranslations";
import BookDetailsLabelSupremeInfo from "./BookDetailsLabelSupremeInfo";
import { useBookTranslationsBasedOnTitleTree } from "./BookDetails.hooks";

const DescriptionBlock = ({
  book,
  isTocVisible,
  disableContentBtn,
  onButtonTocClick,
  shopBooks,
  shopPackage,
  packageShopBookIds
}) => {
  const currentUrl = window.location.href;
  const nBookId = book.book_id;
  const shopBook = shopBooks[nBookId];

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile, textMode } = useViewMode();

  const { bookTranslations } = useBookTranslationsBasedOnTitleTree(nBookId);
  const handleBtnContentKeyDown = useCallback((event) => {
    if (isEventKey(event, KeyCodes.enter) && onButtonTocClick) {
      onButtonTocClick();
    }
  }, [onButtonTocClick]);

  const iconCopyToClipboard = (message) => (
    <i className="icon-copy book-copy-icon" onClick={() => dispatch(actionCopy(message))} />
  );

  const currentUrlLink = (
    <a href={currentUrl} className="bookOutlineBtn" target="_blank" rel="noopener noreferrer">
      {currentUrl}
    </a>
  );

  const elementBookPackage = shopBook && (
    <ShopBookPackage
      book={book}
      shopBooks={shopBooks}
      shopPackage={shopPackage}
      packageShopBookIds={packageShopBookIds}
    />);
  const elementBookCode = book.code && (
    <p className="bookInfoSmallMargin book-code">{`${t("book_code")}: ${book.code}`}</p>
  );

  if (isMobile) {
    return (
      <div className="bookDescriptionContainer">
        {elementBookPackage}
        <p className="bookInfoBigMargin" dangerouslySetInnerHTML={{ __html: book.description }} />
        {elementBookCode}
        <div className="bibliographyContainer">
          <div className="bookInfoTitle">{t("bibliography")}</div>

          {book.author && book.author.length > 0 && (
            <p>
              <strong>{t("author")}:</strong>
              <span>{book.author}</span>
            </p>
          )}
          {book.npages > 0 && (
            <p>
              <strong>{t("pages")}:</strong>
              <span>{book.npages}</span>
            </p>
          )}

          <p className="bibliographyContainer__lang">
            <strong>{t("language")}:</strong>
            <span>{book.lang}</span>
          </p>

          <p>
            <strong>{t("ISBN")}:</strong>
            <span>{book.isbn}</span>
          </p>

          {book.publisher && (
            <p>
              <strong>{t("publisher")}:</strong>
              <span dangerouslySetInnerHTML={{ __html: book.publisher }} />
            </p>
          )}

          {book.cite && (
            <p>
              <strong>{t("citation")}</strong>
              <span dangerouslySetInnerHTML={{ __html: book.cite }} />
              {iconCopyToClipboard(t("citation", { cite: book.cite }))}
            </p>
          )}

          <p>
            <strong>{t("retrievedFrom")}</strong>
            <span>
              {currentUrlLink}
              {iconCopyToClipboard(currentUrl)}
            </span>
          </p>
          {
            bookTranslations.length ? (
              <AccordionBookTranslations
                book={book}
                bookTranslations={bookTranslations}
              />
            ) : ""
          }
        </div>
      </div>
    );
  }

  return (
    <div className="bookDescriptionContainer">
      <div className={"bookInfoTitle " + book.realType}>
        {book.title}
        {!isTocVisible && (
          <IconButton
            icon={ContentIcon}
            ariaLabel={t("content")}
            onClick={onButtonTocClick}
            onKeyDown={handleBtnContentKeyDown}
            tabIndex={0}
            disabled={disableContentBtn}
            className={classNames("bookContentIcon", {
              "icon-button-base bordered": textMode
            })}
          />
        )}
      </div>

      {book.author.length > 0 && (
        <div className={"bookInfoAuthor " + book.realType}>{`By ${book.author}`}</div>
      )}
      {elementBookPackage}
      <p className="bookInfoDescription" dangerouslySetInnerHTML={{ __html: book.description }} />
      {elementBookCode}
      {book.publisher && (
        <p className="bookInfoBigMargin">
          {t("publishedBy", { publisher: "" })}
          <span dangerouslySetInnerHTML={{ __html: book.publisher }} />
        </p>
      )}
      {book.isbn && <p className="bookInfoSmallMargin">{`ISBN: ${book.isbn}`}</p>}
      {book.cite && (
        <p className="bookInfoBigMargin">
          {t("citation", { cite: "" })}
          <span dangerouslySetInnerHTML={{ __html: book.cite }} />
          {iconCopyToClipboard(t("citation", { cite: book.cite }))}
        </p>
      )}
      <p className="bookInfoSmallMargin">
        {t("retrievedFrom")} {currentUrlLink}
        {iconCopyToClipboard(currentUrl)}
      </p>
      {book.npages > 0 && (
        <BookDetailsLabelSupremeInfo className="bookInfoBigMargin">
          {t("pagesNum", { pages: book.npages })}
        </BookDetailsLabelSupremeInfo>
      )}
      {
        bookTranslations?.length ? (
          <AccordionBookTranslations
            book={book}
            bookTranslations={bookTranslations}
          />
        ) : (
          <BookDetailsLabelSupremeInfo className="bookInfoSmallMargin bookTextUppercase">
            {book.lang}
          </BookDetailsLabelSupremeInfo>
        )
      }
    </div>
  );
};

DescriptionBlock.propTypes = {
  book: PropTypes.object,
  isTocVisible: PropTypes.bool,
  disableContentBtn: PropTypes.bool,
  onButtonTocClick: PropTypes.func,
  shopBooks: PropTypes.object,
  shopPackage: PropTypes.object,
  packageShopBookIds: PropTypes.array
};

export default DescriptionBlock;
