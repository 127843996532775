import React from "react";
import classNames from "classnames";
import PropTypes, { oneOfType } from "prop-types";

import "./Price.scss";

export const Price = ({ discountPrice, fullPrice }) => {
  return (
    <div>
      <span
        className={classNames("price", {
          discounted: discountPrice
        })}>{`$${fullPrice}`}</span>
      {discountPrice && (
        <span className="price discount">&nbsp;${discountPrice}&nbsp;</span>
      )}
    </div>
  );
};

Price.propTypes = {
  discountPrice: oneOfType([PropTypes.string, PropTypes.number]),
  fullPrice: oneOfType([PropTypes.string, PropTypes.number])
};

export const PriceDescription = ({
  className, description, discountPrice, fullPrice, children
}) => {
  return (
    <div className={"price-description " + (className || "")}>
      {children ? <>{children}&nbsp;</> : ""}
      {
        description && (
          <span className="price-description_label">
            {description}:&nbsp;
          </span>
        )
      }
      <Price discountPrice={discountPrice} fullPrice={fullPrice} />
    </div>
  );
};
PriceDescription.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  discountPrice: oneOfType([PropTypes.string, PropTypes.number]),
  fullPrice: oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.object
};
