import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ChevronRightIcon } from "../../assets/CommonIcons";
import { getImageByClass } from "../../assets/CategoryIcons";
import { useTheme, useViewMode } from "../../hooks";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { CONTENT_CLASSES } from "../../shared/utils/content";
import { LongPressWrapper } from "..";
import { getCheckIcon } from "./FolderTreeUtils";
import IconView from "src/shared/components/IconView";
import { IdToIconId } from "src/utils/CategoryTreeUtils";
import { useTranslation } from "react-i18next";
import { MouseButtonsCodes } from "src/utils/Utils";
import { isTreeItemCheckable } from "../../utils/TreeUtils";
import { flagPlaceholder } from "../../shared/utils/url";
import { useRemToPx } from "../../hooks/viewModeHooks";

const TREE_LINK_CN = "tree-link";
const ICON_CHECK_CONTAINER_CN = "icon-check-container";

const isMouseEventOpenInNewSpit = (event, item) => {
  const itemClassName = item.className;

  return event.button === MouseButtonsCodes.middle
    && (itemClassName === CONTENT_CLASSES.PARAGRAPH
      || itemClassName === CONTENT_CLASSES.CHAPTER
      || itemClassName === CONTENT_CLASSES.BOOK);
};

const FolderTreeItem = ({
  item,
  isActive,
  style,
  onItemClick,
  onRightClick,
  onExpand,
  onCheck,
}) => {
  const {
    title,
    showExpand,
    isExpanded,
    imageUrl,
    classes,
    checkType,
    parentClassName,
    classMain,
    level,
    loader,
  } = item;
  const { isDark } = useTheme();
  const { isMobileOrTablet } = useViewMode();
  const remToPx = useRemToPx();
  const { t } = useTranslation();
  const isParagraph = item.className === CONTENT_CLASSES.PARAGRAPH;
  const isCheckable = isTreeItemCheckable(item);

  const onTreeItemKeyDown = useCallback(
    (event) => {
      const isSpaceKey = isEventKey(event, KeyCodes.space);
      const isEnterKey = isEventKey(event, KeyCodes.enter);
      if (isSpaceKey && !isParagraph) {
        onCheck(item);
      } else if (event.shiftKey && isEnterKey && showExpand) {
        event.preventDefault();
        onExpand(item);
      } else if (isEnterKey) {
        onItemClick(item, true);
      }
    },
    [item, onCheck, onExpand, onItemClick, showExpand, isParagraph],
  );

  const onCheckHandler = () => {
    if (onCheck) {
      onCheck(item);
    }
  };

  const handleTitleClick = useCallback(() => {
    onItemClick(item, true);
  }, [onItemClick, item]);

  const handleTitleMouseUp = useCallback((event) => {
    if (isMouseEventOpenInNewSpit(event, item)) {
      onItemClick(item, false);
    }
  }, [onItemClick, item]);

  const handleTitleMouseDown = useCallback((event) => {
    // prevent Windows behaviour of the scroll helping (try yourself to understand what it is)
    if (isMouseEventOpenInNewSpit(event, item)) {
      event.preventDefault();
    }
  }, [item]);

  const onRootClick = useCallback((event) => {
    if (
      showExpand &&
      !event.target.classList.contains(TREE_LINK_CN) &&
      !event.target.closest(`.${TREE_LINK_CN}`) &&
      !event.target.classList.contains(ICON_CHECK_CONTAINER_CN) &&
      !event.target.closest(`.${ICON_CHECK_CONTAINER_CN}`)
    ) {
      onExpand(item);
    }
  }, [showExpand, onExpand, item]);

  const handleContextMenu = useCallback((e) => {
    onRightClick(e, item);
  }, [onRightClick, item]);

  const handleLongPress = useCallback((e) => {
    if (isMobileOrTablet) {
      onRightClick(e, item);
    }
  }, [isMobileOrTablet, onRightClick, item]);

  const CheckIcon = getCheckIcon(checkType);
  let BookTypeIcon = getImageByClass(item.className, isDark, item.id);
  if (item.category_code) {
    BookTypeIcon = IdToIconId[item.category_code];
  }

  const paddingLeft = remToPx(1) + level * remToPx(0.22);

  if (loader) {
    return (
      <div style={{ ...style, paddingLeft }} className="tree-item tree-item-loader">
        &nbsp;&nbsp;{t("loading")}...
      </div>
    );
  }

  return (
    <LongPressWrapper
      className={classNames(CONTENT_CLASSES.TREE_ITEM, classMain, {
        "active-tree-position": isActive,
      })}
      tabIndex={0}
      onKeyDown={onTreeItemKeyDown}
      onClick={onRootClick}
      onContextMenu={handleContextMenu}
      // onLongPress looks unnessesary here
      onLongPress={handleLongPress}
      style={{ ...style, paddingLeft }}>
      <div className="tree-vertical-align">
        <ChevronRightIcon
          style={{visibility: showExpand ? "visible" : "hidden"}}
          className={classNames("icon-toggle", classMain, {
            "icon-toggle-with-checkbox": isCheckable,
            "is-expanded": isExpanded,
          })}
        />
        {isCheckable && (
          <CheckIcon
            onClick={onCheckHandler}
            className={classNames(ICON_CHECK_CONTAINER_CN, classMain)}
          />
        )}
        {imageUrl ? (
          <img
            onError={(e) => {
              if (e.target.src.indexOf("flags") > -1) {
                e.target.src = flagPlaceholder;
              }
            }}
            loading="lazy"
            className="tree-icon-flag"
            src={imageUrl}
            alt={item.id}
          />
        ) : (
          BookTypeIcon && (
            <IconView
              icon={BookTypeIcon}
              className={classNames("tree-icon", classMain, {
                "tree-icon-with-checkbox": isCheckable,
              })}
            />
          )
        )}
      </div>
      <span
        data-id={item.id}
        data-panel-title={title}
        data-first-id={item.first_para}
        className={classNames(TREE_LINK_CN, classes, parentClassName, {
          "current-folder": isActive,
        })}
        onMouseUp={handleTitleMouseUp}
        onMouseDown={handleTitleMouseDown}
        onClick={handleTitleClick}
      >
        {t(title, { defaultValue: title })}
      </span>
    </LongPressWrapper>
  );
};

FolderTreeItem.propTypes = {
  isActive: PropTypes.bool,
  item: PropTypes.object,
  style: PropTypes.object,
  onItemClick: PropTypes.func,
  onExpand: PropTypes.func,
  onCheck: PropTypes.func,
  onRightClick: PropTypes.func,
};

export default FolderTreeItem;
