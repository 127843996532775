import React from "react";
import { useDevMode } from "src/hooks";
import { useDialogContext } from "./dialog/DialogContext";

const DevModeView = () => {
  const { isDevMode, toggleDevMode } = useDevMode();
  const { showConfirm } = useDialogContext();
  if (!isDevMode) {
    return null;
  }
  return (
    <div
      className="dev-mode-view"
      onClick={() => {
        showConfirm(
          "This is a dev mode",
          "You can see some developer features. The application can work unstable.",
          () => {
            toggleDevMode();
          },
          { positiveBtn: "Exit dev mode" },
        );
      }}>
      DEV
    </div>
  );
};

export default DevModeView;
