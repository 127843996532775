import { useState, useCallback, useMemo } from "react";
import { SortDirection } from "react-virtualized";
import { ItemTypes } from "src/components/studyCenter/studyCenter.constants";
import { sortList } from "../components/studyCenter/StudyCenterUtils";

const useSortList = (list) => {
  const [sortDirection, setSortDirection] = useState(undefined);
  const [sortBy, setSortBy] = useState();

  const sortedList = useMemo(() => {
    const backItem = list.find((item) => item.type === ItemTypes.back);
    const sorted = sortList(list, sortBy, sortDirection);
    return backItem ? [backItem, ...sorted] : sorted;
  }, [list, sortDirection, sortBy]);

  const toggleSort = useCallback(
    (type, direction) => () => {
      if (direction === SortDirection.ASC) {
        setSortDirection(SortDirection.DESC);
      } else if (direction === SortDirection.DESC) {
        setSortDirection(undefined);
      } else {
        setSortDirection(SortDirection.ASC);
      }

      setSortBy(type);
    },
    []
  );

  return {
    sortedList,
    toggleSort,
    sortBy,
    sortDirection
  };
};

export default useSortList;
