import React from "react";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { EGWLogo } from "../../assets/CommonIcons";
import { useTranslation } from "react-i18next";
import "./Logotype.scss";

const Logotype = ({ logoWritingsLabelShown, logoLinksShown, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="logo-container">
      <Link
        to="/"
        className="logo-top-container"
        tabIndex={0}
        aria-label={t("logo")}
        onClick={onClick}>
        <span className="logo-content" tabIndex={-1}>
          <EGWLogo className="logo" alt="Ellen G. White Writings" />
          <span className="logo-content-text" tabIndex={-1}>
            { logoWritingsLabelShown ? (
              <span className="logo-content-app-name" tabIndex={-1}>
                writings
              </span>
            ) : null }
            <span className="logo-content-copyright" tabIndex={-1}>
              &reg;
            </span>
          </span>
        </span>
      </Link>
      {
        logoLinksShown
          ? (
            <span className="logo-container-links">
              <a
                href="https://whiteestate.org/"
                className="logo-link"
                target="_blank"
                rel="noopener noreferrer">whiteestate.org</a>
              {" "}|{" "}
              <a
                href="http://ellenwhite.org/"
                className="logo-link"
                target="_blank"
                rel="noopener noreferrer">ellenwhite.org</a>
            </span>
          ) : null
      }
    </div>
  );
};

Logotype.propTypes = {
  logoWritingsLabelShown: PropTypes.bool,
  logoLinksShown: PropTypes.bool,
  onClick: PropTypes.func
};

export default Logotype;
