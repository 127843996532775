import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { copy } from "../../../utils/CopyUtil";
import DialogButtons from "../../dialog/DialogButtons";
import { useDialogContext } from "../../dialog/DialogContext";
import { Button, TextField } from "../../views";
import { StudyCenterActions } from "../studyCenter.actions";
import { makeStaticShareUrl } from "../StudyCenterUtils";
import { useShareContext } from "../../shareHolder/ShareHolder";
import { getDialogBounds } from "../../dialog/BaseDialogs";
import { actionAddMessage } from "../../../redux/actions";
import { isEventKey, KeyCodes } from "../../../shared/utils/dom";
import IconView from "src/shared/components/IconView";
import EgwWebFont from "src/assets/EgwWebFont";

const StaticShareDialog = ({ topicId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { hideDialog } = useDialogContext();
  const { showShare } = useShareContext();
  const userInfo = useSelector((state) => state.settings.userInfo);
  const staticShare = useSelector((state) => state.studyCenter.staticShare);

  const sharedTopic = staticShare.find((item) => item.id === topicId);

  const [name, setName] = useState(sharedTopic ? sharedTopic.name : "");
  const [waitingForShare, setWaitingForShare] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (waitingForShare && sharedTopic) {
      if (waitingForShare.type === "copy") {
        handleCopy();
      } else {
        handleShare(waitingForShare.bounds, waitingForShare.parentBounds);
      }

      setWaitingForShare(null);
    }
  }, [waitingForShare, staticShare]);

  const makeShareUrl = () => {
    return `${window.location.origin}${makeStaticShareUrl(userInfo.id, topicId)}`;
  };

  const handlePreview = () => {
    history.push(makeStaticShareUrl(userInfo.id, topicId));
    hideDialog();
  };

  const shareUrl = makeShareUrl();

  const handleShare = (bounds, parentBounds) => {
    showShare({
      shareData: {
        url: shareUrl,
        title: name,
      },
      bounds,
      parentBounds,
      closeOnBack: false,
      onClose: () => {
        hideDialog();
      },
    });
  };

  const beginShare = (event, isCopy) => {
    if (!name) {
      dispatch(actionAddMessage(t("name_cannot_be_empty")));
      return;
    }

    const target = event.target.closest(".static-share-button-plus");
    let parentBounds;
    let bounds;

    if (target) {
      parentBounds = getDialogBounds(event);
      bounds = target.getBoundingClientRect();
    }

    if (!sharedTopic) {
      setWaitingForShare({ type: isCopy ? "copy" : "share", bounds, parentBounds });

      dispatch(
        StudyCenterActions.createStaticShare({
          id: topicId,
          name,
          elements: [topicId],
        }),
      );
    } else {
      if (isCopy) {
        handleCopy();
      } else {
        handleShare(bounds, parentBounds);
      }
    }
  };

  const handleCopy = () => {
    copy(shareUrl, {
      onSuccess: () => {
        dispatch(actionAddMessage(t("linkCopiedSuccess")));
        setCopied(true);
        hideDialog();
      },
    });
  };

  return (
    <React.Fragment>
      <div className="sc-title">{t("shareStudy")}</div>
      <div className="static-share-link-row">
        <TextField
          className="sc-line-input"
          value={name}
          autoFocus
          disabled={!!sharedTopic}
          error={name.length === 0}
          onKeyPress={(e) => {
            if (isEventKey(e, KeyCodes.enter)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (!sharedTopic) {
              setName(e.target.value);
            }
          }}
          label={t("studyName")}
        />
      </div>
      <div className="static-share-link-row">
        <TextField
          className="static-share-link-value"
          value={shareUrl}
          contentEditable={false}
          onFocus={(e) => {
            e.target.select();
          }}
          label={t("link")}
        />
        <Button
          className="static-share-button"
          disabled={copied}
          onClick={(e) => beginShare(e, true)}>
          <IconView icon={EgwWebFont.copy} />
        </Button>
        <Button
          className="static-share-button static-share-button-plus"
          onClick={(e) => beginShare(e, false)}>
          <IconView icon={EgwWebFont.plus} />
        </Button>
        {!!sharedTopic && (
          <Button
            className="static-share-button static-share-button-plus"
            onClick={handlePreview}>
            <IconView icon={EgwWebFont.view} />
          </Button>
        )}
      </div>

      <DialogButtons
        onClickNegative={hideDialog}
        disabled={name.length === 0}
        onClickPositive={() => {
          if (sharedTopic) {
            dispatch(StudyCenterActions.deleteStaticShare([topicId]));
          } else if (name && !sharedTopic) {
            dispatch(
              StudyCenterActions.createStaticShare({
                id: topicId,
                name,
                elements: [topicId],
              }),
            );
          }

          hideDialog();
        }}
        negativeText={t("cancel")}
        positiveText={sharedTopic ? t("delete") : t("share")}
      />
    </React.Fragment>
  );
};

StaticShareDialog.propTypes = {
  topicId: PropTypes.string,
};

export default StaticShareDialog;
