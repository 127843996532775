import { isNumberBetween } from "./number";
import { getQueryByName } from "../../utils/URLUtils";

const DateRangeLifetime = {
  start: 1845,
  end: 1917,
};

const isYearRangeLifetime = (startYear, endYear) => {
  return isNumberBetween(startYear, DateRangeLifetime.start, DateRangeLifetime.end)
    || isNumberBetween(endYear, DateRangeLifetime.start, DateRangeLifetime.end);
};

const yearCompilations = 1918;

const isYearRangeCompilations = (startYear, endYear) => {
  return startYear >= yearCompilations || endYear >= yearCompilations;
};

export const getDateRangeFilterBook = (books, dateRange) => {
  if (dateRange !== dateRangeSearchAll) {
    let checkFn;

    if (dateRange === Period.lifetime) {
      checkFn = isYearRangeLifetime;
    } else if (dateRange === Period.compilations) {
      checkFn = isYearRangeCompilations;
    }

    if (checkFn) {
      return books.filter((book) => {
        return checkFn(book.startYear, book.endYear);
      });
    }
  }
  return books;
};

export const Order = {
  wrel: "wrel",
  seq: "seq",
  rel: "rel",
  date: "date",
  minusDate: "-date"
};

export const SNIPPET = {
  REF: "ref",
  SHORT: "short",
  LONG: "long",
  FULL: "full"
};

export const QUERY_TYPE = {
  EXACT: "exact",
  PREFER_EXACT: "prefer_exact",
  STEMMED: "stemmed",
};

export const queryTypeOptions = [
  { key: QUERY_TYPE.PREFER_EXACT, label: "queryTypePreferExact" },
  { key: QUERY_TYPE.EXACT, label: "queryTypeExact" },
  { key: QUERY_TYPE.STEMMED, label: "queryTypeStemmed" },
];

export const orderOptions = [
  { key: Order.wrel, label: "weightedrelevancy" },
  { key: Order.seq, label: "booksequence" },
  { key: Order.rel, label: "relevancy" },
  { key: Order.date, label: "earliestfirst" },
  { key: Order.minusDate, label: "latestfirst" }
];

export const limitOptions = [
  { key: 10, label: "10", },
  { key: 25, label: "25", },
  { key: 50, label: "50", },
];

export const snippetOptions = [
  { key: SNIPPET.REF, label: "reference" },
  { key: SNIPPET.SHORT, label: "short" },
  { key: SNIPPET.LONG, label: "long" },
  { key: SNIPPET.FULL, label: "paragraph" }
];

export const searchGroups = {
  egwwritings: "egw",
  bible: "bible",
  apl: "apl",
  reference: "reference"
};

export const Period = {
  both: "both",
  all: "all",
  lifetime: "lifetime",
  compilations: "compilations",
  myBible: "mybible",
  bible: "bible",
  apl: "apl",
  dictionary: "dictionary",
};

export const PeriodDefault = Period.both;

export const periodOptions = [
  { key: Period.both, label: "egwCollection" },
  { key: Period.all, label: "allCollection" },
  { key: Period.lifetime, label: "lifetime" },
  { key: Period.compilations, label: "compilations" },
  { key: Period.apl, label: "adventistpioneerlibrary" },
  { key: Period.myBible, label: "myBible" },
  { key: Period.dictionary, label: "dictionary" }
];

export const getTopics = (topics) => {
  return Object.keys(topics).map((key) => {
    return { topic: key, count: topics[key] };
  });
};

export const RESTRICTION_SEARCH_VALUE = 99999;

export const aplFolders = [15];

export const LOADER_SUGGESTION_ID = "LOADER_SUGGESTION_ID";

export const SearchItemType = {
  SUGGESTION: "suggestion",
  TRANSLATIONS: "translations",
  CORRECTION: "correction",
  SYNONYM: "synonym",
  RECENT: "recent",
  WORD: "word",
  AUTOCOMPLETE: "autocomplete",
  RECENT_MORE: "recent-more",
};

export const dateRangeSearchAll = Period.both;

export const dateRangeInitial = [
  { id: Period.lifetime, label: "lifetime" },
  { id: Period.compilations, label: "compilations" },
  { id: Period.both, label: "search_all" }
];

export const monthNamesInitial = [
  "january", "february",
  "march", "april", "may",
  "june", "july", "august",
  "september", "october", "november",
  "december"
];

export const AdvSearchType = {
  book: "book",
  periodicals: "periodicals",
  ltms: "ltms",
  topical: "topical",
  scripture: "scripture",
  apl: "apl",
  reference: "reference",
  bible: "bible",
  commentary: "commentary",
  dictionary: "dictionary"
};

export const advSearchTypes = Object.values(AdvSearchType);

export const SearchType = {
  ...AdvSearchType,
  basic: "basic",
  bible: "bible",
  pubnr: "pubnr"
};

export const extrasByAdvSearchType = {
  [SearchType.basic]: ["period", "pubnr", "bookCode"],
  [SearchType.book]: [
    "lang", "pubnr", "bookCode", "bookpage", "paragraph", "daterange", "collections"
  ],
  [SearchType.periodicals]: [
    "start_year",
    "end_year",
    "daterange",
    "pubnr",
    "bookCode",
    "paragraph",
    "year",
    "month",
    "day"
  ],
  [SearchType.ltms]: ["subtype", "number", "start_year", "end_year", "year", "place", "addressee"],
  [SearchType.topical]: ["headings_only"],
  [SearchType.scripture]: ["book", "chapter"],
  [SearchType.apl]: [
    "subtype", "pubnr", "bookCode", "bookpage", "paragraph", "start_year", "end_year", "date"
  ],
  [SearchType.reference]: ["collection", "pubnr", "bookCode", "bookpage", "paragraph"],
  [SearchType.bible]: ["lang", "pubnr", "bookCode", "book", "chapter", "verse"],
  [SearchType.commentary]: ["pubnr", "bookCode", "book", "chapter", "verse"],
  [SearchType.dictionary]: []
};

export const minExtrasByAdvSearchType = {
  [SearchType.basic]: ["pubnr", "bookCode"],
  [SearchType.book]: [
    "pubnr", "type", "bookCode", "bookpage", "paragraph", "daterange", "collections"
  ],
  [SearchType.periodicals]: [
    "start_year",
    "end_year",
    "daterange",
    "pubnr",
    "bookCode",
    "paragraph",
    "year",
    "month",
    "day"
  ],
  [SearchType.ltms]: ["subtype", "number", "start_year", "end_year", "year", "place", "addressee"],
  [SearchType.topical]: ["headings_only"],
  [SearchType.scripture]: ["book", "chapter"],
  [SearchType.apl]: [
    "subtype", "pubnr", "bookCode", "bookpage", "paragraph", "start_year", "end_year", "date"
  ],
  [SearchType.reference]: ["collection", "pubnr", "bookCode", "bookpage", "paragraph"],
  [SearchType.bible]: ["pubnr", "bookCode", "book", "chapter", "verse"],
  [SearchType.commentary]: ["pubnr", "bookCode", "book", "chapter", "verse"],
  [SearchType.dictionary]: []
};

export const searchPageExtras = { order: Order.seq, snippet: SNIPPET.LONG, period: Period.all };

export const getSearchTypeByPeriod = (period, defaultType) => {
  if (period === Period.dictionary) {
    return SearchType.dictionary;
  }

  return defaultType || SearchType.basic;
};

export const getExtras = (paramsUrl) => {
  const type = getQueryByName("type", paramsUrl);
  const fields =
    type && advSearchTypes.includes(type)
      ? extrasByAdvSearchType[type]
      : extrasByAdvSearchType["basic"];

  const extrasList = fields.concat(Object.keys(searchPageExtras));
  const result = type ? { type: type } : {};
  for (const name of extrasList) {
    const value = getQueryByName(name, paramsUrl);
    if (value !== undefined) {
      result[name] = value;
    } else if (Object.keys(searchPageExtras).includes(name)) {
      // no param case
      result[name] = searchPageExtras[name];
    }
  }
  return result;
};

export const getMinExtras = (paramsUrl) => {
  const type = getQueryByName("type", paramsUrl);
  const fields =
    type && advSearchTypes.includes(type)
      ? minExtrasByAdvSearchType[type]
      : minExtrasByAdvSearchType[SearchType.basic];
  const result = {};
  for (const name of fields) {
    const value = getQueryByName(name, paramsUrl);
    if (value !== undefined) {
      result[name] = value;
    }
  }
  return result;
};
