import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { TreeMenuMode } from "src/utils/TreeUtils";
import { useViewMode } from "../../hooks";
import CategoryTree from "./CategoryTree";
import FolderTree from "./FolderTree";
import SearchForBooks from "./SearchForBooks";
import TitleTree from "./TitleTree";

const SideMenuTree = () => {
  const treeMenuMode = useSelector((state) => state.settings.treeMenuMode);

  const treeComponent = useMemo(() => {
    if (treeMenuMode === TreeMenuMode.CATEGORIES) {
      return <CategoryTree />;
    } else if (treeMenuMode === TreeMenuMode.TITLES) {
      return <TitleTree />;
    }
    
    return <FolderTree />;
  }, [treeMenuMode]);

  return (
    <MenuTreeWrap>
      <SearchForBooks />
      {treeComponent}
    </MenuTreeWrap>
  );
};

const MenuTreeWrap = ({ children }) => {
  const { zoom } = useViewMode();
  const treeFontSize = useSelector((state) => state.settings.treeFontSize);
  return (
    <div
      className="menuTreeWrap"
      style={{
        fontSize: treeFontSize * (zoom / 100),
      }}>
      {children}
    </div>
  );
};

MenuTreeWrap.propTypes = {
  children: PropTypes.node,
};

export default SideMenuTree;
