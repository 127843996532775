import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobileOnly as isRealMobileOnly, isTablet as isRealTablet } from "react-device-detect";
import { CONFIGURED_SIZES } from "../components/resizer/constants";
import { VIEW_MODE, getIsOldIOS } from "../utils/Utils";
import {
  getSizeValue,
  getScreenType,
  isDesktop,
  ScreenType,
  ZoomValues,
  makeZoomClass
} from "../utils/ThemeUtils";
import { actionSetScreenType, actionUpdateSetting } from "../redux/actions";
import { getStoredValue, Settings } from "../utils/Settings";
import { DefaultFontSize, getTheme } from "../shared/utils/theme";
import { VIEW_MODE_CLASSNAME_PREFIX } from "../shared/utils/viewmode";

export const useSetScreenType = () => {
  const dispatch = useDispatch();
  const screenType = useSelector((state) => state.settings.screenType);

  useEffect(() => {
    const handleOnResize = () => {
      const screenTypeUpdated = getScreenType();
      if (screenType !== screenTypeUpdated) {
        dispatch(actionSetScreenType(screenTypeUpdated));
      }
    };

    window.addEventListener("resize", handleOnResize);

    handleOnResize();

    return () => window.removeEventListener("resize", handleOnResize);
  }, [screenType]);
};

export const getInitialMode = (zoom, screenType) => {
  let mode;
  if (
    // this returns "desktop" for the screen that is "tablet" initially 
    // to display desktop view on big tablets
    (screenType === ScreenType.TABLET || isDesktop(screenType)) &&
    zoom < ZoomValues.tablet[ScreenType.DESKTOP] &&
    zoom < ZoomValues.mobile[ScreenType.DESKTOP]
  ) {
    mode = VIEW_MODE.DESKTOP;
  } else if (
    screenType === ScreenType.TABLET_SMALL ||
    (zoom >= ZoomValues.tablet[ScreenType.DESKTOP] &&
      zoom < ZoomValues.mobile[ScreenType.DESKTOP]) ||
    isRealTablet
  ) {
    mode = VIEW_MODE.TABLET;
  } else if (
    screenType === ScreenType.MOBILE ||
    screenType === ScreenType.MOBILE_SMALL ||
    zoom >= ZoomValues.mobile[ScreenType.DESKTOP] ||
    isRealMobileOnly
  ) {
    mode = VIEW_MODE.MOBILE;
  }
  return mode;
};

/**
 * @typedef {Object} ViewModeParams
 * @property {boolean} isMobile - is mobile size of app
 * @property {boolean} isTablet 
 * @property {boolean} isMobileOrTablet 
 * @property {boolean} blindMode 
 * @property {boolean} textMode 
 * @property {string} sizeMode -  mode of size of screen, no depends on zoom
 * @property {string} viewMode {@see VIEW_MODE}
 * @property {string} themeMode
 * @property {function} remToPx - calculate rem to px sizes
 * @property {number} zoom
 */

/**
 * @name useViewMode
 * @description returns a check of a device
 * @returns {ViewModeParams}
 */
export const useViewMode = () => {
  const zoom = useSelector((state) => state.settings.zoom);
  const viewMode = useSelector((state) => state.settings.viewMode);
  const screenType = useSelector((state) => state.settings.screenType);
  const themeMode = useSelector((state) => state.settings.themeMode);
  const textMode = useSelector((state) => state.settings.textMode);
  let mode = getInitialMode(zoom, screenType);
  if (
    (mode === VIEW_MODE.DESKTOP || mode === VIEW_MODE.TABLET) &&
    (viewMode === VIEW_MODE.MOBILE || viewMode === VIEW_MODE.TABLET)
  ) {
    mode = viewMode;
  }

  const isTablet = mode === VIEW_MODE.TABLET;
  const isMobile = mode === VIEW_MODE.MOBILE;

  const theme = getTheme(themeMode);

  return {
    viewMode: mode,
    zoom,
    textMode,
    themeMode,
    blindMode: theme.accessibility,
    sizeMode: screenType,
    isMobile,
    isTablet,
    isMobileOrTablet: isMobile || isTablet,
  };
};

export const useRemToPx = () => {
  const zoom = useSelector((state) => state.settings.zoom);

  const remToPx = useCallback((remValue) => {
    return (remValue * DefaultFontSize * zoom) / 100;
  }, [zoom]);

  return remToPx;
};

export const useEmToPx = (el) => {
  const emToPx = useCallback((remValue) => {
    if (el) {
      const {fontSize} = window.getComputedStyle(el);
      return remValue * parseFloat(fontSize);
    }

    return undefined;
  }, [el]);

  return emToPx;
};

function getCalculatedGridDimensions(screenType) {
  if (screenType === ScreenType.MOBILE || screenType === ScreenType.MOBILE_SMALL) {
    let GridWidthGap = 0.4;
    let GridHeight = 8.5;
    let ListWidthGap = 1.35;
    let ListHeight = 4.95;

    return {
      GRID_WIDTH_GAP: GridWidthGap,
      GRID_WIDTH: 5.2 + GridWidthGap,
      GRID_OFFSET: 5.2 + GridWidthGap,
      GRID_HEIGHT: GridHeight,
      GRID_BOOKSHELF_HEIGHT: GridHeight + 3.7,
      GRID_TEXT_MODE_HEIGHT: GridHeight,
      GRID_TEXT_MODE_BOOKSHELF_HEIGHT: GridHeight + 9.7,

      LIST_WIDTH_GAP: ListWidthGap,
      LIST_WIDTH: 19 + ListWidthGap,
      LIST_HEIGHT: ListHeight,
      LIST_TEXT_MODE_HEIGHT: ListHeight + 0.4,

      ALPHABET_WIDTH: 0.9
    };
  }

  if (screenType === ScreenType.TABLET || screenType === ScreenType.TABLET_SMALL) {
    let GridWidthGap = 1.2;
    let GridHeight = 9;
    let ListWidthGap = 0.6;
    let ListHeight = 5.2;

    return {
      GRID_WIDTH_GAP: GridWidthGap,
      GRID_WIDTH: 5.2 + GridWidthGap,
      GRID_OFFSET: 5.2 + (GridWidthGap / 2),
      GRID_HEIGHT: GridHeight,
      GRID_BOOKSHELF_HEIGHT: GridHeight + 3.2,
      GRID_TEXT_MODE_HEIGHT: GridHeight,
      GRID_TEXT_MODE_BOOKSHELF_HEIGHT: GridHeight + 8.8,

      LIST_WIDTH_GAP: ListWidthGap,
      LIST_WIDTH: 17 + ListWidthGap,
      LIST_HEIGHT: ListHeight,
      LIST_TEXT_MODE_HEIGHT: ListHeight + 0.4,

      ALPHABET_WIDTH: 0.9
    };
  }

  let GridWidthGap = 1.2;
  let GridHeight = 10.2;
  let ListWidthGap = 1.35;
  let ListHeight = 5.5;

  return {
    GRID_WIDTH_GAP: GridWidthGap,
    GRID_WIDTH: 6.2 + GridWidthGap,
    GRID_OFFSET: 6.2,
    GRID_HEIGHT: GridHeight,
    GRID_BOOKSHELF_HEIGHT: GridHeight + 3.2,
    GRID_TEXT_MODE_HEIGHT: GridHeight,
    GRID_TEXT_MODE_BOOKSHELF_HEIGHT: GridHeight + 8,

    LIST_WIDTH_GAP: ListWidthGap,
    LIST_WIDTH: 20.35 + ListWidthGap,
    LIST_HEIGHT: ListHeight,
    LIST_TEXT_MODE_HEIGHT: ListHeight + 0.4,

    ALPHABET_WIDTH: 0
  };
}

export const useGridDimensions = () => {
  const zoom = useSelector((state) => state.settings.zoom);
  const screenType = useSelector((state) => state.settings.screenType);
  let finalScreenType = screenType;
  
  // because screenType shows real initial screen type
  // but this method returns "desktop" for big tablets
  // and it turns out on big tablets it uses tablet's values while it should be using desktop's
  const mode = getInitialMode(zoom, screenType); 
  if (screenType === ScreenType.TABLET && mode === VIEW_MODE.DESKTOP) {
    finalScreenType = ScreenType.DESKTOP;
  }
  const sizes = getCalculatedGridDimensions(finalScreenType);

  const newSizes = useMemo(() => {
    // Note: Should be recreated to prevent origin obj modification.
    return Object.entries(sizes).reduce((acc, [key, value]) => {
      acc[key] = (zoom / 100) * value * DefaultFontSize;
      return acc;
    }, {});
  }, [sizes, zoom]);

  return newSizes;
};

const staticDimensions = ["MIN_PANEL_WIDTH", "MIN_PANEL_HEIGHT"];

export const useResizeDimensions = () => {
  const zoom = useSelector((state) => state.settings.zoom);
  const screenType = useSelector((state) => state.settings.screenType);
  let sizes = CONFIGURED_SIZES.DEFAULT;
  if (screenType === ScreenType.MOBILE || screenType === ScreenType.TABLET_SMALL) {
    sizes = CONFIGURED_SIZES.max768;
  } else if (screenType === ScreenType.TABLET) {
    sizes = CONFIGURED_SIZES.max1024;
  } else if (
    screenType === ScreenType.DESKTOP ||
    screenType === ScreenType.DESKTOP_SMALL ||
    screenType === ScreenType.DESKTOP_MEDIUM
  ) {
    sizes = CONFIGURED_SIZES.max1920;
  } else if (screenType === ScreenType.DESKTOP2K) {
    sizes = CONFIGURED_SIZES.max2560;
  } else if (screenType === ScreenType.DESKTOP4K) {
    sizes = CONFIGURED_SIZES.max3840;
  }

  const newSizes = useMemo(() => {
    // Note: Should be recreated to prevent origin obj modification.
    return Object.entries(sizes).reduce((acc, [key, value]) => {
      acc[key] = staticDimensions.includes(key) ? value : (zoom / 100) * value * DefaultFontSize;
      return acc;
    }, {});
  }, [sizes, zoom]);

  return newSizes;
};

const CLASSNAME_IOS_OLD = "only-old-ios";

export const useSetViewMode = () => {
  const dispatch = useDispatch();

  const { viewMode, textMode, blindMode, sizeMode, zoom, themeMode } = useViewMode();

  useEffect(() => {
    // for change theme in all tabs of website
    const storageHandler = () => {
      let themeModeStored = getStoredValue(Settings.themeMode.id);
      if (themeMode !== themeModeStored) {
        dispatch(actionUpdateSetting(Settings.themeMode.id, themeModeStored));
      }
    };
    window.addEventListener("storage", storageHandler);
    return () => {
      window.removeEventListener("storage", storageHandler);
    };
  }, [dispatch, themeMode]);

  useEffect(() => {
    const maxValue = getSizeValue(ZoomValues, "max", sizeMode);
    if (zoom > maxValue) {
      dispatch(actionUpdateSetting(Settings.zoom.id, maxValue));
    }
  }, [sizeMode, zoom, dispatch]);

  useEffect(() => {
    const classList = document.body.classList;

    // Remove view mode class names only
    const classListModeClassNames = [];
    let classListClassName;
    for (let i = 0; i < classList.length; i++) {
      classListClassName = classList[i];
      if (classListClassName.includes(VIEW_MODE_CLASSNAME_PREFIX)) {
        classListModeClassNames.push(classListClassName);
      }
    }

    classList.remove(...classListModeClassNames);
    // ========

    classList.add(viewMode);

    if (textMode) {
      classList.add(VIEW_MODE.TEXT_ONLY);
    }
    if (blindMode) {
      classList.add(VIEW_MODE.BLIND);
    }
    if (sizeMode === ScreenType.DESKTOP2K) {
      classList.add(VIEW_MODE.DESKTOP2K);
    }
    if (sizeMode === ScreenType.DESKTOP4K) {
      classList.add(VIEW_MODE.DESKTOP4K);
    }
    if (zoom >= 150) {
      classList.add(makeZoomClass(zoom));
    }

    if (getIsOldIOS()) {
      classList.add(CLASSNAME_IOS_OLD);
    } else {
      classList.remove(CLASSNAME_IOS_OLD);
    }
  }, [viewMode, textMode, blindMode, sizeMode, zoom]);
};

export const useTheme = () => {
  const themeMode = useSelector((state) => state.settings.themeMode);
  const isDark = getTheme(themeMode).dark;
  return { themeMode, isDark };
};
