import React from 'react';
import { layoutIds } from "./LayoutUtils";
import Layout2 from "./Layout2";
import PropTypes from "prop-types";
import ResizerWrap from "./ResizeWrap";

const Layout3 = ({ panelIds, id, onRender }) => {

  const [id0, id1, id2] = panelIds;
  switch (id) {
    case layoutIds.l3s12:
      return <ResizerWrap vertical>
        {onRender(id0)}
        <Layout2 panelIds={[id1, id2]} onRender={onRender} />
      </ResizerWrap>;

    case layoutIds.l3s21:
      return <ResizerWrap vertical>
        <Layout2 panelIds={[id0, id1]} onRender={onRender} />
        {onRender(id2)}
      </ResizerWrap>;

    case layoutIds.l3s12v:
      return <ResizerWrap >
        {onRender(id0)}
        <Layout2 vertical panelIds={[id1, id2]} onRender={onRender} />
      </ResizerWrap>;

    case layoutIds.l3s21v:
      return <ResizerWrap>
        <Layout2 vertical panelIds={[id0, id1]} onRender={onRender} />
        {onRender(id2)}
      </ResizerWrap>;

    case layoutIds.l3h:
      return <ResizerWrap weight={[1, 2]}>
        {onRender(id0)}
        <Layout2 panelIds={[id1, id2]} onRender={onRender} />
      </ResizerWrap>;

    case layoutIds.l3v:
    default:
      return <ResizerWrap vertical weight={[1, 2]}>
        {onRender(id0)}
        <Layout2 panelIds={[id1, id2]} vertical onRender={onRender} />
      </ResizerWrap>;
  }
};

Layout3.propTypes = {
  id: PropTypes.string,
  onRender: PropTypes.func,
  panelIds: PropTypes.array
};

export default Layout3;
