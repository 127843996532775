export const langListShort = [
  {
    url: "zh",
    label: "Chinese"
  },
  {
    url: "en",
    label: "English"
  },
  {
    url: "fr",
    label: "French"
  },
  {
    url: "de",
    label: "German"
  },
  {
    url: "it",
    label: "Italian"
  },
  {
    url: "pt",
    label: "Portuguese"
  },
  {
    url: "ro",
    label: "Romanian"
  },
  {
    url: "ru",
    label: "Russian"
  },
  {
    url: "es",
    label: "Spanish"
  }
];
