import React from "react";
import { URLS } from "../utils/url";

const CopyrightNoticePage = ({ Link }) => (
  <div className="long-content">
    <p>
      All information contained in our Websites and Apps are&nbsp;copyrighted by the Ellen G. White
      Estate®, a nonprofit corporation (“the White Estate, Inc.”). &nbsp;All rights are reserved.
      &nbsp;United States copyright law protects all materials contained on all our Ellen White
      Websites, White Estate Websites, EGW Writings Websites, and all our many EGW Writings apps on
      all OS platforms: Android, iOS, Kindle, macOS, and Windows. &nbsp;By using our websites or
      apps you acknowledge that the White Estate owns all intellectual property rights in and to the
      site or apps and its contents and that these rights are valid and protected in all forms.
      &nbsp;You may not modify, remove, delete, augment, add to, exploit, publish, transmit,
      participate in the transfer or sale of, and/or create derivative works from the content either
      in whole or in part on our Website or apps.
    </p>
    <p>
      These limitations do not preclude &quot;fair use&quot; of excerpts in harmony with U.S.
      Copyright Code, Title 17, Sec. 107, including brief quotations for use in sermons, classroom
      instruction, publications, research, or sharing through social media outlets.
    </p>
    <p>
      The White Estate Web site does not use “cookies.” &nbsp;A “cookie” is a test file that
      contains pieces of information that a Web site transfers to an individual’s hard disk for
      record-keeping purposes.
    </p>
    <p>
      The White Estate will not sell or distribute any information, including personal or
      identifying information, which is obtained through your use of this Web site. &nbsp;The White
      Estate is not gathering such information, but may obtain such information by your
      communication directly with the Web site’s Webmaster. &nbsp;Any names or information provided
      by users of the Web site shall only be used for the purpose such information is provided.
    </p>
    <p>
      The White Estate is provides our Websites and apps for your information purposes only.
      &nbsp;Certain organizations may choose to link this Website to their own Website. &nbsp;Such
      linkage is not meant to imply that the White Estate agrees with, supports, or concurs with the
      content such Websites or apps may contain.
    </p>
    <p>
      THE WHITE ESTATE PROVIDES&nbsp;ITS WEBSITES AND APPS AND ITS CONTENTS “AS IS” WITHOUT ANY
      OBLIGATIONS, LIABILITIES, OR WARRANTIES, EXPRESS OR IMPLIED, WHATSOEVER TO ANYONE WHO CHOOSES
      TO ACCESS THIS WEB SITE. &nbsp;EACH USER EXPRESSLY ACKNOWLEDGES THAT USE OF THIS WEB SITE AND
      ITS CONTENTS IS AT THE USER’S SOLE RISK. &nbsp;THE WHITE ESTATE DOES NOT WARRANT THAT THIS WEB
      SITE OR ANY OF ITS CONTENTS WILL BE CONTINUOUSLY AVAILABLE, ACCESSIBLE, NON-INFRINGING, OR
      FREE OF ERRORS OR VIRUSES OR ANYTHING ELSE MANIFESTING CONTAMINATING OR DESTRUCTIVE
      PROPERTIES, OR THAT THE INFORMATION CONTAINED IN IT IS ACCURATE OR RELIABLE. THE WHITE ESTATE
      WILL NOT BE LIABLE FOR ANY DAMAGES OR INJURY (INCLUDING INDIRECT, SPECIAL, AND CONSEQUENTIAL
      DAMAGES) THAT MAY RESULT FROM THE USE OF OR INABILITY TO USE ANY PART OF THE WEB SITE, OR FROM
      ANYTHING SUBMITTED OR POSTED BY USERS. &nbsp;THE TERMS OF THIS PARAGRAPH WILL SURVIVE ANY
      TERMINATION OF THIS NOTICE AND WEB SITE.
    </p>
    <p>
      Our Websites and apps are&nbsp;created and controlled under the laws of the State of Maryland,
      which shall be the law giving effect to and interpretation of all terms and conditions
      hereunder. &nbsp;Any action pertaining to this Website or any other of our Websites or Apps
      shall be in the State of Maryland with Montgomery County the venue for such action. &nbsp;Any
      person named a party to any action regarding this Web site agrees to submit to the exclusive
      jurisdiction of the courts of the State of Maryland and of the United States of America
      located in the State of Maryland.
    </p>
    <p>
      The White Estate® owns the{" "}
      <a href="http://www.adventist.org/copyright/trademark-and-logo-usage/" title="GC Trademarks">
        trademarks
      </a>{" "}
      for: “Ellen White Visionary for Kids, ” “E.G. WHITE,” “EGW,” “ELLEN G. WHITE,” and “WHITE
      ESTATE.’ &nbsp;The General Conference Corporation of Seventh-day Adventists® owns the
      trademarks for the names “General Conference of Seventh-day Adventists®”, “Seventh-day
      Adventist®” and “Adventist®”, as well as numerous other trademarks. &nbsp;In addition to
      registration with the United States Patent and Trademark Office, registrations are also
      current or pending in various other jurisdictions.
    </p>
    <p>
      For further information regarding this Web site or anything related to it, you may send email
      to the Webmaster or write to:
    </p>
    <p>
      The White Estate
      <br />
      Attention: &nbsp;Webmaster&nbsp;
      <br />
      12501 Old Columbia Pike
      <br />
      Silver Spring, MD &nbsp;20904
    </p>
    <p>
      <strong>Privacy Policy</strong>
    </p>
    <p>
      See Ellen G. White Estate <Link to={URLS.privacyPolicy}>Privacy Policy</Link>&nbsp;for
      information gathered for EGW Writings
    </p>
    <p>
      <strong>End User License Agreement</strong>
    </p>
    <p>
      See Ellen G. White Estate <Link to={URLS.userAgreement}>End User License Agreement</Link>
      &nbsp; (EULA)&nbsp;for use of all EGW Writings Apps and Ellen White Estate Websites
    </p>
  </div>
);

export default CopyrightNoticePage;
