import { getBookId } from "../utils/content";
import { makeRequest } from "./baseApi";

export const getShopBooksRequest = (bookIds = []) => {
  let url = "shop/books?limit=50";
  if (bookIds.length > 0) {
    url += "&" + bookIds.map((item) => "book_id=" + item).join("&");
  }
  return makeRequest({
    url,
    type: "get",
    parseResponse: (response) => {
      if (response.data) {
        const { books, total } = response.data;
        return {
          total,
          books: books.map((item) => ({
            ...item,
            id: getBookId(item.book_id),
          })),
        };
      }
      return undefined;
    },
  });
};

export const getPackagesRequest = (options = {}) => {
  const { limit, offset, packageIds } = options;
  const params = [];
  let url = "shop/packages";

  if (packageIds) {
    params.push("package_id=" + packageIds.join("&package_id="));
  }

  if (limit && offset) {
    params.push("limit=" + limit);
    params.push("offset=" + offset);
  }

  if (params.length) {
    url += "?";
    url += params.join("&");
  }

  const request = {
    url,
    type: "get",
    parseResponse: (response) => response.data,
  };
  return makeRequest(request);
};

export const createPayPalCheckoutRequest = (data) => {
  const request = {
    url: "paypal/checkout/create",
    type: "post",
    data,
    parseResponse: (response) => response.data,
  };
  return makeRequest(request);
};
