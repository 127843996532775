import React, { useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Tree from "./Tree";
import { orderLanguages} from "../../utils/Utils";
import { useNavigationContext } from "../NavigationContext";
import { makeTree } from "../../utils/TreeUtils";
import { LOADER_ID_SHOP, shopActions } from "../../redux/shop/actions";
import { useLoader } from "../../redux/selectors";
import { useViewMode } from "../../hooks";
import { actionCheckTree, actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { MENU_STATE } from "../../utils/MenuItems";
import { folderTreeActions } from "../../redux/folderTree/actions";
import { SearchActions } from "../search/search.actions";
import useTreeContextMenu from "./TreeContextMenu";
import { getSystemLang } from "../../shared/utils/user-agent";

import "./FolderTree.scss";

const FolderTree = () => {
  const dispatch = useDispatch();
  const { isMobile } = useViewMode();
  const openTreeContextMenu = useTreeContextMenu();
  const { openId, nodeList, currentFolder, bookIds } = useNavigationContext();

  const checked = useSelector((state) => state.folderTree.checked);
  const expanded = useSelector((state) => state.folderTree.expanded);
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const bookLangMap = useSelector((state) => state.mainTree.bookLangMap);
  const isLogin = useSelector((state) => state.system.isLogin);
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const languageUI = useSelector((state) => state.settings.languageUI);
  const shopBooks = useSelector((state) => state.shop.shopBooks);
  const isPackagesFetched = useSelector((state) => state.shop.isPackagesFetched);
  const isLoadingShop = useLoader(LOADER_ID_SHOP);

  // Loads paid books. Not purchased paid books don't have the expand btn in the Tree.
  useEffect(() => {
    if (!isPackagesFetched && !isLoadingShop) {
      dispatch(shopActions.fetchShopBooks());
    }
  }, [isLoadingShop, isPackagesFetched]);

  const shopBooksIdsPurchased = useMemo(() => {
    const out = [];

    const shopBooksValues = Object.values(shopBooks);

    let shopBook;
    for (let i = 0; i < shopBooksValues.length; i++) {
      shopBook = shopBooksValues[i];

      if (shopBook.is_purchased) {
        out.push(shopBook.book_id);
      }
    }

    return out;
  }, [shopBooks]);

  const folderTree = useMemo(() => {
    let langValue = getSystemLang(languageUI);
    let langs = [...libraryLanguages];
    if (libraryLanguages.length === 0 && langValue) {
      langs.push(langValue);
    }
    // temporary add lang to tree if user open folder from not ML language
    bookIds.forEach((item) => {
      langValue = bookLangMap[item];
      if (!langs.includes(langValue)) {
        langs.push(langValue);
      }
    });
    let clonedItems = [...mainTree];
    if (isLogin) {
      clonedItems = orderLanguages(clonedItems, langs);
    }

    return makeTree(clonedItems, expanded, checked, shopBooksIdsPurchased);
  }, [
    languageUI,
    shopBooksIdsPurchased, mainTree, libraryLanguages,
    isLogin, bookIds, bookLangMap, expanded, checked,
  ]);

  const handleItemClick = useCallback((item, newWindow) => {
    if (isMobile) {
      dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
    }
    openId(item.id, { newWindow, className: item.className, lang: item.lang });
    dispatch(folderTreeActions.expandTree(item));
  }, [isMobile, openId]);

  const handleItemExpand = useCallback((item) => {
    dispatch(folderTreeActions.expandTree(item));
  }, []);

  const handleItemCheck = useCallback((item) => {
    dispatch(actionCheckTree({ ...item, manual: true }));
    dispatch(SearchActions.setSearchBookListSuppress(true));
  }, []);

  const handleChangeExpanded = useCallback((expandedNew, currentFolder) => {
    dispatch(folderTreeActions.updateExpanded(expandedNew));
    dispatch(folderTreeActions.invalidateItem(currentFolder));
  }, []);

  return (
    <Tree
      isKeepOnlyOneBookExpanded

      className="main-tree"

      currentFolder={currentFolder}
      nodeList={nodeList}

      folderTree={folderTree}
      expanded={expanded}
      onChangeExpanded={handleChangeExpanded}

      onItemClick={handleItemClick}
      onRightClick={openTreeContextMenu}
      onItemCheck={handleItemCheck}
      onItemExpand={handleItemExpand}
    />
  );
};

FolderTree.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FolderTree;
