import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionResetDragndrop, actionUpdateDragndrop } from "../redux/params.actions";
import { clearSelection, getClientPositions, MouseButtonsCodes } from "../utils/Utils";

import { useNavigationContext } from "./NavigationContext";
import {
  getCanAddPanel,
  getLinkId,
  getOverWhatPanleId,
} from "../components/reader/layouts/LayoutUtils";
import { finishDragNDrop } from "../utils/RouterUtils";
import { useResizeDimensions, useViewMode } from "../hooks";

const DragnDrop = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [position, setPosition] = useState([]);
  const dragndrop = useSelector((state) => state.dragndrop);

  const { panelIds } = useNavigationContext();
  const { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT } = useResizeDimensions();
  const { isMobile, textMode } = useViewMode();

  const timeoutRef = useRef();

  // update dragndorp state on position change
  useEffect(() => {
    if (position.length && dragndrop.id) {
      const [x, y] = position;
      const { overId, overTitle } = getOverWhatPanleId(x, y);
      if (overId) {
        if (dragndrop.overId !== overId) {
          const updatedDragndrop = {
            overId,
            overTitle,
          };

          dispatch(actionUpdateDragndrop(updatedDragndrop));
        }
      } else {
        if (dragndrop.overId) {
          const updatedDragndrop = {
            overId: null,
            overTitle: null,
          };
          dispatch(actionUpdateDragndrop(updatedDragndrop));
        }
      }
    }
  }, [position, dragndrop]);

  const resetDragndrop = () => {
    clearTimeout(timeoutRef.current);
    dispatch(actionResetDragndrop());
    timeoutRef.current = undefined;
  };

  const createDragndrop = (e) => {
    if (isMobile || textMode) {
      return;
    }

    const element = e.target.closest(".dndable");
    if (element) {
      // treeLink is element from folder tree
      const isTreeLink = element.classList.contains("tree-link");
      const elementId = element.getAttribute("data-id");
      const firstParaId = element.getAttribute("data-first-id");
      let isAddingPanel = false,
        id = elementId;
      if (isTreeLink && panelIds) {
        const layoutWrap = document.getElementById("layout-wrap");
        const dimms = { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT };
        const canAddPanel = getCanAddPanel(layoutWrap, panelIds, dimms);

        if (canAddPanel) {
          isAddingPanel = true;
        } else {
          id = getLinkId(panelIds, elementId);
        }
      }
      if (id) {
        const event = { ...e };
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setPosition([event.clientX, event.clientY]);
          timeoutRef.current = undefined;
          const title = element.getAttribute("data-panel-title");
          const udpatedDropdown = {
            id,
            title,
            isAddingPanel,
            firstParaId,
          };
          dispatch(actionUpdateDragndrop(udpatedDropdown));
          clearSelection();
        }, 500);
      }
    }
  };

  const onTouchStart = (e) => createDragndrop(e);

  const onMouseDown = (event) => {
    if (event.button === MouseButtonsCodes.main) {
      createDragndrop(event);
    }
  };

  const onEventMove = (e) => {
    if (timeoutRef.current) {
      resetDragndrop();
      return;
    }
    if (dragndrop.id) {
      const { clientX, clientY } = getClientPositions(e);
      setPosition([clientX, clientY]);
    }
  };

  const onEventEnd = () => {
    if (timeoutRef.current) {
      resetDragndrop();
      return;
    }
    if (dragndrop.id) {
      const url = finishDragNDrop(dragndrop, panelIds);
      if (url) {
        history.replace(url);
      }

      dispatch(actionResetDragndrop());
    }
  };

  const onEventCancel = () => {
    if (dragndrop.id) {
      dispatch(actionResetDragndrop());
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  return (
    <div
      className="dnd-wrap"
      onTouchStart={onTouchStart}
      onTouchMove={onEventMove}
      onTouchEnd={onEventEnd}
      onTouchCancel={onEventCancel}
      onMouseDown={onMouseDown}
      onMouseMove={onEventMove}
      onMouseUp={onEventEnd}
      onMouseLeave={onEventCancel}>
      {dragndrop.id && (
        <div className="drag-overlay">
          <div className="drag-view" style={{ left: position[0], top: position[1] }}>
            <span>
              {dragndrop.title.length > 40
                ? dragndrop.title.substr(0, 40) + "..."
                : dragndrop.title}
            </span>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

DragnDrop.propTypes = {
  children: PropTypes.node,
};

export default DragnDrop;
