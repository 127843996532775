import asyncComponent from "../components/AsyncComponent";
export { default as CopyrightNoticeScreen } from "./CopyrightNoticeScreen";
export { default as PrivacyPolicyPage } from "./PrivacyPolicyPage";
export { default as UserAgreement } from "./UserAgreement";
export { default as PatchNotesPage } from "./PatchNotesPage";
export { default as AboutScreen } from "./AboutScreen";
export { default as Page404 } from "./Page404";
export { default as HistoryPage } from "./HistoryPage";
export { default as FolderPage } from "./FolderPage"; 
export { default as SystemPage } from "./SystemPage"; 
export { default as HomePage } from "./HomePage"; 
export { default as WorkspacesPage } from "./workspaces/WorkspacesPage"; 
 

export const SubsReaderPage = asyncComponent(() => {
  return import(
    "../components/subscriptions/SubsReaderPage" /* webpackChunkName: "SubsReaderPage" */
  );
});

export const SubscriptionsPage = asyncComponent(() => {
  return import(
    "../components/subscriptions/SubscriptionsPage" /* webpackChunkName: "SubscriptionsPage" */
  );
});

export const StudyCenterPage = asyncComponent(() => {
  return import(
    "../components/studyCenter/StudyCenterPage" /* webpackChunkName: "StudyCenterPage" */
  );
});

export const StaticSharePage = asyncComponent(() => {
  return import(
    "../components/studyCenter/StaticSharePage" /* webpackChunkName: "StaticSharePage" */
  );
});

export const SettingsPage = asyncComponent(() => {
  return import("../components/settings/SettingsPage" /* webpackChunkName: "SettingsPage" */);
});

export const AdvSearchPage = asyncComponent(() => {
  return import("../components/advSearch/AdvSearchPage" /* webpackChunkName: "AdvSearchPage" */);
});

export const RelatedSearchPage = asyncComponent(() => {
  return import(
    "../components/relevantSearch/RelatedSearchPage" /* webpackChunkName: "RelatedSearchPage" */
  );
});

export const HistorySearchPage = asyncComponent(() => {
  return import(
    "../components/search/HistorySearchPage" /* webpackChunkName: "HistorySearchPage" */
  );
});

export const FeaturedSearchPage = asyncComponent(() => {
  return import(
    "../components/featuredSearch/FeaturedSearchPage" /* webpackChunkName: "FeaturedSearchPage" */
  );
});

export const ContactScreen = asyncComponent(() => {
  return import("./ContactScreen" /* webpackChunkName: "ContactScreen" */);
});

export const BibliographyPage = asyncComponent(() => {
  return import("./BibliographyPage" /* webpackChunkName: "BibliographyPage" */);
});

export const CategoriesPage = asyncComponent(() => {
  return import("./CategoriesPage" /* webpackChunkName: "CategoriesPage" */);
});

export const TitlesPage = asyncComponent(() => {
  return import("./TitlesPage" /* webpackChunkName: "TitlesPage" */);
});

export const ReaderPage = asyncComponent(() => {
  return import("../components/reader/ReaderPage" /* webpackChunkName: "ReaderPage" */);
});
