import React from "react";
import Layout2 from "./Layout2";
import Layout3 from "./Layout3";
import { layoutIds } from "./LayoutUtils";
import ResizerWrap from "./ResizeWrap";

import PropTypes from "prop-types";

const Layout4 = ({ panelIds, id, onRender }) => {
  const [id0, id1, id2, id3] = panelIds;
  const ids0 = [id0, id1];
  const ids1 = [id2, id3];
  switch (id) {
    case layoutIds.l4h:
      return (
        <ResizerWrap>
          <Layout2 panelIds={ids0} onRender={onRender} />
          <Layout2 panelIds={ids1} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l4v:
      return (
        <ResizerWrap vertical>
          <Layout2 vertical panelIds={ids0} onRender={onRender} />
          <Layout2 vertical panelIds={ids1} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l4s112:
      return (
        <ResizerWrap vertical weight={[2, 1]}>
          <Layout2 vertical panelIds={ids0} onRender={onRender} />
          <Layout2 panelIds={ids1} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l4s112v:
      return (
        <ResizerWrap weight={[2, 1]}>
          <Layout2 panelIds={ids0} onRender={onRender} />
          <Layout2 vertical panelIds={ids1} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l4s211:
      return (
        <ResizerWrap weight={[1, 2]} vertical>
          <Layout2 panelIds={ids0} onRender={onRender} />
          <Layout2 vertical panelIds={ids1} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l4s211v:
      return (
        <ResizerWrap weight={[1, 2]}>
          <Layout2 vertical panelIds={ids0} onRender={onRender} />
          <Layout2 panelIds={ids1} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l4s121:
      return (
        <ResizerWrap weight={[2, 1]} vertical>
          <Layout3 vertical panelIds={[id0, id1, id2]} id={layoutIds.l3s12} onRender={onRender} />
          {onRender(id3)}
        </ResizerWrap>
      );

    case layoutIds.l4s121v:
      return (
        <ResizerWrap weight={[2, 1]}>
          <Layout3 vertical panelIds={[id0, id1, id2]} id={layoutIds.l3s12v} onRender={onRender} />
          {onRender(id3)}
        </ResizerWrap>
      );

    case layoutIds.l4s22:
    default:
      return (
        <ResizerWrap>
          <Layout2 vertical panelIds={ids0} onRender={onRender} />
          <Layout2 vertical panelIds={ids1} onRender={onRender} />
        </ResizerWrap>
      );
  }
};

Layout4.propTypes = {
  id: PropTypes.string,
  onRender: PropTypes.func,
  panelIds: PropTypes.array,
};

export default Layout4;
