import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSwipeable } from "react-swipeable";

import { MENU_STATE } from "../../utils/MenuItems";
import { useResizeDimensions, useViewMode } from "../../hooks";
import { useNavigationContext } from "../NavigationContext";
import { actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { getSelectionRange } from "../../utils/selectionRef";
import { getWindowSizes } from "../../utils/Utils";
import { READER_PANEL_TOOLTIP_CLASSNAME } from "../reader/panels/ReaderPanelTooltip.constants";
import { useResizerContext } from "../resizer/Resizer";

import "./SwipeableWrapper.scss";

/**
 * @param {import('react-swipeable').SwipeableProps} props
 * @return {JSX.Element}
 */
const Swipeable = (props) => {
  const {children, className, ...hookProps} = props;
  const handlers = useSwipeable(hookProps);
  return (<div { ...handlers } className={className}>{children}</div>);
};

Swipeable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const SwipeableWrapper = (props) => {
  const { isMobile, isTablet } = useViewMode();
  const { DELTA, MIN_LEFT_WIDTH, MIN_RIGHT_WIDTH, MIN_LEFT_TREE_WIDTH } = useResizeDimensions();
  const { isReader } = useNavigationContext();
  const {currentLeft, rightWidthInner} = useResizerContext();
  const dispatch = useDispatch();
  const timerRef = useRef(0);
  const menuState = useSelector((state) => state.settings.menuState);
  const isShowRightPanel = useSelector((state) => state.settings.isShowRightPanel);
  const isRightPanelPinned = useSelector((state) => state.settings.isRightPanelPinned);
  const isLeftPanelPinned = useSelector((state) => state.settings.isLeftPanelPinned);
  const [isIconSwipeLeftShown, setIsIconSwipeLeftShown] = useState(false);
  const [isIconSwipeRightShown, setIsIconSwipeRightShown] = useState(false);

  const isShowLeftPanel = menuState !== MENU_STATE.COLLAPSE;
  const isShowLeftPanelTree = menuState === MENU_STATE.TREE;

  const openRightPanel = useCallback(() => {
    if (!isShowRightPanel) {
      dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, true));
    }
  }, [isShowRightPanel]);

  const closeRightPanel = useCallback(() => {
    if (isShowRightPanel) {
      dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
    }
  }, [isShowRightPanel]);

  const openLeftPanel = useCallback(() => {
    if (menuState === MENU_STATE.COLLAPSE) {
      dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.TREE));
    }
  }, [menuState]);

  const closeLeftPanel = useCallback(() => {
    if (isShowLeftPanel) {
      dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
    }
  }, [isShowLeftPanel]);

  const getSwipeData = useCallback((eventData = {}) => {
    const { width } = getWindowSizes();
    const { dir, initial, event } = eventData;

    const isDirLeft = dir === "Left";
    const isDirRight = dir === "Right";
    const isDirAllowed = isDirLeft || isDirRight;
    let allowTargets = true;
    let noTextSelected = true;
    if (isReader) {
      const { isEmpty, range } = getSelectionRange();
      if (!isEmpty && range) {
        noTextSelected = false;
      }
    }

    const [x] = initial;
    // special indent for the mobile to avoid native browser's swipe events of  "prev/next history".
    const indent = isMobile ? 45 : DELTA;
    const isInitialXSuitableForSwipeRight = x <= indent;
    const isInitialXSuitableForSwipeLeft = x >= width - indent;

    let isCloseTabletLeftPanelAllowed = isInitialXSuitableForSwipeLeft;
    let isCloseTabletRightPanelAllowed = isInitialXSuitableForSwipeRight;

    if (isTablet) {
      const panelLeftWidth = isShowLeftPanelTree
        ? isLeftPanelPinned
          ? currentLeft
          : MIN_LEFT_TREE_WIDTH
        : MIN_LEFT_WIDTH;
      const panelRightX = width - (isRightPanelPinned ? rightWidthInner : MIN_RIGHT_WIDTH);

      isCloseTabletLeftPanelAllowed = x >= panelLeftWidth - indent && x <= panelLeftWidth;
      isCloseTabletRightPanelAllowed = x >= panelRightX && x <= panelRightX + indent;
    }

    // don't swipe over some particular targets e.g. the "progress-slider" of the "Reader"
    if (event?.target.closest("." + READER_PANEL_TOOLTIP_CLASSNAME)) {
      allowTargets = false;
    }

    return {
      openRightDrawer: isDirLeft && isInitialXSuitableForSwipeLeft && !isShowLeftPanel,
      closeLeftDrawer: isDirLeft && isCloseTabletLeftPanelAllowed && isShowLeftPanel,
      openLeftDrawer: isDirRight && isInitialXSuitableForSwipeRight && !isShowRightPanel,
      closeRightDrawer: isDirRight && isCloseTabletRightPanelAllowed && isShowRightPanel,
      isAllowed: isDirAllowed && allowTargets && noTextSelected
    };
  }, [
    DELTA, MIN_RIGHT_WIDTH, MIN_LEFT_TREE_WIDTH, MIN_LEFT_WIDTH,
    currentLeft, rightWidthInner,
    isMobile, isTablet, isReader,
    isShowLeftPanel, isShowRightPanel, isShowLeftPanelTree,
    isRightPanelPinned, isLeftPanelPinned,
  ]);

  const onSwiped = useCallback((eventData) => {
    const {
      openLeftDrawer,
      openRightDrawer,
      closeLeftDrawer,
      closeRightDrawer,
      isAllowed,
    } = getSwipeData(eventData);

    if (isAllowed) {
      if (openLeftDrawer) {
        openLeftPanel();
      } else if (closeLeftDrawer) {
        closeLeftPanel();
      } else if (openRightDrawer) {
        openRightPanel();
      } else if (closeRightDrawer) {
        closeRightPanel();
      }

      clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        setIsIconSwipeLeftShown(false);
        setIsIconSwipeRightShown(false);
      }, 450);
    }
  },
  [getSwipeData, openLeftPanel, closeLeftPanel, openRightPanel, closeRightPanel]);

  const onSwiping = useCallback((eventData) => {
    const {
      isAllowed,
      openLeftDrawer,
      openRightDrawer,
      closeLeftDrawer,
      closeRightDrawer,
    } = getSwipeData(eventData);
    if (isAllowed) {
      if (openLeftDrawer || closeRightDrawer) {
        setIsIconSwipeLeftShown(true);
      }

      if (openRightDrawer || closeLeftDrawer) {
        setIsIconSwipeRightShown(true);
      }
    } else {
      setIsIconSwipeLeftShown(false);
      setIsIconSwipeRightShown(false);
    }
  }, [getSwipeData]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <Swipeable
      className={classnames("swipeable-wrapper", {
        "show-icon-swiping-left": isIconSwipeLeftShown,
        "show-icon-swiping-right": isIconSwipeRightShown,
      })}
      onSwiped={onSwiped}
      onSwiping={onSwiping}
    >{props.children}</Swipeable>
  );
};

SwipeableWrapper.propTypes = {
  children: PropTypes.node,
};

export default SwipeableWrapper;
