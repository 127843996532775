export const scActions = {
  open: "open",
  navigate: "navigate",
  moveToFolder: "moveToFolder",
  copyToFolder: "copyToFolder",
  newFolder: "newFolder",
  navigateWithSearch: "navigateWithSearch",
  confirmDelete: "confirmDelete",
  edit: "edit",
  delete: "delete",
  updateFilter: "updateFilter",
  toggleExpand: "toggleExpand",
  toggleSearch: "toggleSearch",
  togglePreview: "togglePreview",
  openShareFolder: "openShareFolder",
  toggleFullscreen: "toggleFullscreen",
  moveDown: "moveDown",
  moveUp: "moveUp",
  moveToBottom: "moveToBottom",
  moveToTop: "moveToTop",
  backToReader: "backToReader",
  importToStudy: "importToStudy"
};

export const StudyCenterConstants = {
  FETCH_STUDY_CENTER: "FETCH_STUDY_CENTER",
  FETCH_STUDY_CENTER_COLORS: "FETCH_STUDY_CENTER_COLORS",
  FETCH_STUDY_CENTER_COLORS_SUCCESS: "FETCH_STUDY_CENTER_COLORS_SUCCESS",
  FETCH_STUDY_CENTER_COLORS_FINALLY: "FETCH_STUDY_CENTER_COLORS_FINALLY",
  DELETE_STUDY_CENTER_COLORS: "DELETE_STUDY_CENTER_COLORS",
  CREATE_STUDY_CENTER_COLOR: "CREATE_STUDY_CENTER_COLOR",
  UPDATE_STUDY_CENTER_COLOR: "UPDATE_STUDY_CENTER_COLOR",
  FETCH_STUDY_CENTER_TOPICS: "FETCH_STUDY_CENTER_TOPICS",
  FETCH_STUDY_CENTER_TOPICS_SUCCESS: "FETCH_STUDY_CENTER_TOPICS_SUCCESS",
  CREATE_STATIC_SHARE: "CREATE_STATIC_SHARE",
  DELETE_STATIC_SHARE: "DELETE_STATIC_SHARE",
  FETCH_STATIC_SHARE_SUCCESS: "FETCH_STATIC_SHARE_SUCCESS",
  PREVIEW_STATIC_SHARE: "PREVIEW_STATIC_SHARE",
  PREVIEW_STATIC_SHARE_SUCCESS: "PREVIEW_STATIC_SHARE_SUCCESS",
  DELETE_STUDY_CENTER_ELEMENTS: "DELETE_STUDY_CENTER_ELEMENTS",
  CREATE_STUDY_CENTER_ENTRY: "CREATE_STUDY_CENTER_ENTRY",
  UPDATE_STUDY_CENTER_ENTRY: "UPDATE_STUDY_CENTER_ENTRY",
  CREATE_TEST_SC_ELEMENTS: "CREATE_TEST_SC_ELEMENTS",
  UPDATE_STUDY_CENTER_DUMP: "UPDATE_STUDY_CENTER_DUMP",
  UPDATE_STUDY_CENTER_DUMP_SUCCESS: "UPDATE_STUDY_CENTER_DUMP_SUCCESS",
  CREATE_STUDY_CENTER_TOPIC: "CREATE_STUDY_CENTER_TOPIC",
  UPDATE_STUDY_CENTER_TOPIC: "UPDATE_STUDY_CENTER_TOPIC",
  ADD_STUDY_CENTER_ACTION: "ADD_STUDY_CENTER_ACTION",
  FETCH_STUDY_CENTER_SEARCH: "FETCH_STUDY_CENTER_SEARCH",
  FETCH_STUDY_CENTER_SEARCH_SUCCESS: "FETCH_STUDY_CENTER_SEARCH_SUCCESS",
  SAVE_LAST_READING_BOOK: "ACTION_SAVE_LAST_READING_BOOK",
  SET_LAST_CREATE_ENTITY: "SET_LAST_CREATE_ENTITY",
  FETCH_STUDY_CENTER_PREVIEW: "FETCH_STUDY_CENTER_PREVIEW",
  FETCH_STUDY_CENTER_PREVIEW_SUCCESS: "FETCH_STUDY_CENTER_PREVIEW_SUCCESS",
  STUDY_CENTER_CLEAR_SEARCH: "STUDY_CENTER_CLEAR_SEARCH",
  ACTION_CHECK_CS_ITEMS: "ACTION_CHECK_CS_ITEMS",
  ACTION_UPDATE_TOPIC: "ACTION_UPDATE_TOPIC",
  ACTION_SET_CURRENT_TOPIC: "ACTION_SET_CURRENT_TOPIC",
  ACTION_SET_ACTIVE_COLOR: "ACTION_SET_ACTIVE_COLOR",
  ACTION_SET_ACTIVE_SELECTED_TEXT: "ACTION_SET_ACTIVE_SELECTED_TEXT",
  UPDATE_CHECKED_SC_ITEM: "UPDATE_CHECKED_SC_ITEM",
  SET_SC_SEARCH_TEXT: "SET_SC_SEARCH_TEXT",
  SET_SC_SEARCH_TEXT_SUCCESS: "SET_SC_SEARCH_TEXT_SUCCESS",
  MOVE_TO_TOPIC_EDITOR_ACTION: "MOVE_TO_TOPIC_EDITOR_ACTION",
};

export const StudyCenterActions = {
  fetchStudyCenterPreview: (previewIds, start) => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_PREVIEW,
    data: { previewIds, start }
  }),
  fetchStudyCenterPreviewSuccess: (data) => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_PREVIEW_SUCCESS,
    data
  }),
  saveLastReadingBook: (data) => ({
    type: StudyCenterConstants.SAVE_LAST_READING_BOOK,
    data
  }),
  fetchStudyCenterSearch: (data) => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_SEARCH,
    data
  }),
  fetchStudyCenterSearchSuccess: (data) => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_SEARCH_SUCCESS,
    data
  }),
  clearSearchResults: () => ({
    type: StudyCenterConstants.STUDY_CENTER_CLEAR_SEARCH,
  }),
  fetchStudyCenter: (data) => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER,
    data
  }),
  setLastCreateEntry: (newEntry) => ({
    type: StudyCenterConstants.SET_LAST_CREATE_ENTITY,
    data: newEntry
  }),
  fetchSCTopics: () => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_TOPICS
  }),
  fetchSCTopicsSuccess: (data) => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_TOPICS_SUCCESS,
    data
  }),
  createSCElement: (data) => ({
    type: StudyCenterConstants.CREATE_STUDY_CENTER_ENTRY,
    data
  }),
  updateSCEntry: (data) => ({
    type: StudyCenterConstants.UPDATE_STUDY_CENTER_ENTRY,
    data
  }),
  updateStudyCenterDump: (data) => ({
    type: StudyCenterConstants.UPDATE_STUDY_CENTER_DUMP,
    data
  }),
  updateStudyCenterDumpSuccess: (data) => ({
    type: StudyCenterConstants.UPDATE_STUDY_CENTER_DUMP_SUCCESS,
    data
  }),
  fetchSCColors: () => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_COLORS
  }),
  fetchSCColorsSuccess: (data) => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_COLORS_SUCCESS,
    data
  }),
  fetchSCColorsFinally: (data) => ({
    type: StudyCenterConstants.FETCH_STUDY_CENTER_COLORS_FINALLY,
    data
  }),
  createSCTopic: (data) => ({
    type: StudyCenterConstants.CREATE_STUDY_CENTER_TOPIC,
    data
  }),
  updateSCTopic: (data) => ({
    type: StudyCenterConstants.UPDATE_STUDY_CENTER_TOPIC,
    data
  }),
  addLast: (userId, action) => ({
    type: StudyCenterConstants.ADD_STUDY_CENTER_ACTION,
    data: { userId, action, }
  }),
  deleteSCElements: (entries = [], topics = []) => ({
    type: StudyCenterConstants.DELETE_STUDY_CENTER_ELEMENTS,
    data: { entries, topics }
  }),
  createSCColor: (data) => ({
    type: StudyCenterConstants.CREATE_STUDY_CENTER_COLOR,
    data
  }),
  updateSCColor: (data) => ({
    type: StudyCenterConstants.UPDATE_STUDY_CENTER_COLOR,
    data
  }),
  deleteSCColors: (data) => ({
    type: StudyCenterConstants.DELETE_STUDY_CENTER_COLORS,
    data
  }),
  fetchStaticShareSuccess: (data) => ({
    type: StudyCenterConstants.FETCH_STATIC_SHARE_SUCCESS,
    data
  }),
  createStaticShare: (data) => ({
    type: StudyCenterConstants.CREATE_STATIC_SHARE,
    data
  }),
  deleteStaticShare: (data) => ({
    type: StudyCenterConstants.DELETE_STATIC_SHARE,
    data
  }),
  fetchPreviewStaticShare: (data) => ({
    type: StudyCenterConstants.PREVIEW_STATIC_SHARE,
    data
  }),
  fetchPreviewStaticShareSuccess: (data) => ({
    type: StudyCenterConstants.PREVIEW_STATIC_SHARE_SUCCESS,
    data
  }),
  сheckScItems: (data) => ({
    type: StudyCenterConstants.ACTION_CHECK_CS_ITEMS,
    data
  }),
  updateTopicId: (data) => ({
    type: StudyCenterConstants.ACTION_UPDATE_TOPIC,
    data
  }),
  createTestSCElements: (data) => ({
    type: StudyCenterConstants.CREATE_TEST_SC_ELEMENTS,
    data
  }),
  setCurrentSCElement: (entryId, topicId) => ({
    type: StudyCenterConstants.ACTION_SET_CURRENT_TOPIC,
    data: {
      entryId,
      topicId
    }
  }),
  setActiveColor: (data) => ({
    type: StudyCenterConstants.ACTION_SET_ACTIVE_COLOR,
    data
  }),
  setActiveSelectedText: (data) => ({
    type: StudyCenterConstants.ACTION_SET_ACTIVE_SELECTED_TEXT,
    data
  }),
  updateCheckedSCItem: (data) => ({
    type: StudyCenterConstants.UPDATE_CHECKED_SC_ITEM,
    data
  }),
  setSCSearchText: (data) => ({
    type: StudyCenterConstants.SET_SC_SEARCH_TEXT,
    data
  }),
  setSCSearchTextSuccess: (data) => ({
    type: StudyCenterConstants.SET_SC_SEARCH_TEXT_SUCCESS,
    data
  }),
  moveToTopicEditor: (data) => ({
    type: StudyCenterConstants.MOVE_TO_TOPIC_EDITOR_ACTION,
    data
  }),
};
