import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Scroll } from "../views";
import { MinNavPanel } from "./MinNavPanel";
import { useViewMode, useDebouncedEffect } from "../../hooks";
import RelatedEgwView from "../relevantSearch/RelatedEgwView";
import RelatedBibleView from "../relevantSearch/RelatedBibleView";
import {
  actionRelatedType,
  fetchRelevantSearchFacetAggregate,
  fetchRelevantSearchScheme,
} from "../relevantSearch/relevantSearch.actions";
import { useDefLang } from "../../redux/selectors";
import sassVariables from "../../styles/variables.module.scss";
import { actionUpdateSettings } from "src/redux/actions";
import { Settings } from "src/utils/Settings";
import { SearchPanelTabs } from "./constants";
import { RelatedTabType } from "../relevantSearch/RelevantSearch.utils";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { SearchActions } from "../search/search.actions";
import { makeSearchUrl } from "src/utils/SearchUtils";

import "./RelatedTab.scss";

const relevantSubtypeOptions = [
  { id: RelatedTabType.EGW, label: "ellen_white" },
  { id: RelatedTabType.BIBLE, label: "bible" },
];

const RelatedTab = ({ parentHeight }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useViewMode();
  const remToPx = useRemToPx();
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const searchParams = useSelector((state) => state.search.searchParams);
  const scheme = useSelector((state) => state.relevantSearch.scheme);
  const searchLang = useSelector((state) => state.search.searchLang);
  const relatedType = useSelector((state) => state.relevantSearch.relatedType);
  const defLang = useDefLang();
  const searchParamsQuery = searchParams.query;
  const isSearchAsText = searchParams?.onlyText;
  const currentLang = searchLang?.length > 0 ? searchLang : defLang;

  useEffect(() => {
    dispatch(fetchRelevantSearchScheme());
  }, []);

  const schemeFiltered = useMemo(
    () => scheme.filter((item) => item.language_code === currentLang),
    [currentLang, scheme],
  );

  useDebouncedEffect(
    () => {
      let query = "";
      if (searchQuery !== "") {
        query = searchQuery;
      } else if (searchParamsQuery !== "") {
        query = searchParamsQuery;
      }
      if (query !== "" && searchLang) {
        dispatch(fetchRelevantSearchFacetAggregate(searchLang, query, isSearchAsText));
      }
    },
    250,
    [searchQuery, searchParamsQuery, isSearchAsText, searchLang],
  );

  const handleOnNavigate = (params, filter) => {
    const newSettings = {
      [Settings.showShortcutsForShortcut.id]: false,
    };

    if (isMobile) {
      newSettings[Settings.rightTab.id] = SearchPanelTabs.RESULTS;
      dispatch(SearchActions.fetchSearch(params, 1));
    } else {
      newSettings[Settings.isShowRightPanel.id] = true;
      newSettings[Settings.rightTab.id] = SearchPanelTabs.FEATURED;
      history.push(makeSearchUrl(params, filter));
    }

    dispatch(actionUpdateSettings(newSettings));
  };

  const renderFacets = (type) => {
    if (type === RelatedTabType.EGW) {
      return (
        <RelatedEgwView
          lang={currentLang}
          mobileView
          schemeFiltered={schemeFiltered}
          rightPanel
          onNavigate={handleOnNavigate}
        />
      );
    }

    return (
      <RelatedBibleView
        lang={currentLang}
        mobileView
        schemeFiltered={schemeFiltered}
        rightPanel
        onNavigate={handleOnNavigate}
      />
    );
  };

  return searchLang.length !== 0 ? (
    <div className="tab-container">
      <div className="search-plus-related-wrapper">
        <div className={classNames("relevant-search-view-right-panel", "row-1")}>
          <div className="form-col">
            <div className={classNames("vertical-tabs-list", { "is-mobile": isMobile })}>
              {relevantSubtypeOptions.map((item) => (
                <span
                  key={item.id}
                  className={classNames("vertical-tabs-item-related", {
                    active: item.id === relatedType,
                  })}
                  onClick={() => {
                    dispatch(actionRelatedType(item.id));
                  }}>
                  {t(item.label)}
                </span>
              ))}
              <MinNavPanel />
            </div>

            <Scroll
              style={{
                // {-2}px is a fix of "Related" tab bottom border isn't visible.
                // such fix is applied because of no time to investigate.
                height: Math.floor(parentHeight - remToPx(sassVariables.panelHeaderHeight)) - 2,
              }}>
              {renderFacets(relatedType)}
            </Scroll>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="search-placeholder">{t("searchListRelatedTab")}</div>
  );
};

RelatedTab.propTypes = {
  parentHeight: PropTypes.number,
};

export default RelatedTab;
