export const categoryTreeActionConstants = {
  FETCH_CONVERT_SORT_TREE: "ct/FETCH_CONVERT_SORT_TREE",

  UPDATE_TREE_MAIN: "ct/UPDATE_TREE_MAIN",
  UPDATE_TREE_CONVERTED: "ct/UPDATE_TREE_CONVERTED",

  UPDATE_EXPANDED: "ct/UPDATE_EXPANDED",
  UPDATE_CHECKED: "ct/UPDATE_CHECKED",
  UPDATE_FILTERS: "ct/UPDATE_FILTERS",
  TOGGLE_ITEM_EXPAND: "ct/TOGGLE_ITEM_EXPAND",
  TOGGLE_ITEM_CHECK: "ct/TOGGLE_ITEM_CHECK",
};

export const categoryTreeActions = {
  fetchConvertSortTree: () => ({
    type: categoryTreeActionConstants.FETCH_CONVERT_SORT_TREE,
  }),
  updateTreeMain: (data) => ({
    type: categoryTreeActionConstants.UPDATE_TREE_MAIN,
    data,
  }),
  updateTreeConverted: (data) => ({
    type: categoryTreeActionConstants.UPDATE_TREE_CONVERTED,
    data,
  }),
  updateExpanded: (data) => ({
    type: categoryTreeActionConstants.UPDATE_EXPANDED,
    data,
  }),
  updateChecked: (data) => ({
    type: categoryTreeActionConstants.UPDATE_CHECKED,
    data,
  }),
  toggleItemExpand: (data) => ({
    type: categoryTreeActionConstants.TOGGLE_ITEM_EXPAND,
    data,
  }),
  toggleItemCheck: (data) => ({
    type: categoryTreeActionConstants.TOGGLE_ITEM_CHECK,
    data,
  }),
  updateFilters: (data) => ({
    type: categoryTreeActionConstants.UPDATE_FILTERS,
    data,
  }),
};
