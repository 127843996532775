import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import deepEqual from "deep-equal";

import {
  actionSetContentId,
  setPlayerParams
} from "../../redux/actions";
import { PlayCircleIcon, PauseCircleIcon } from "../../assets/PlayerIcons";
import { Scroll } from "../views";
import { findNode } from "../../utils/Utils";
import {
  findParaById,
  getReaderProgressAndChapterInfo,
  normalizeParaId
} from "../reader/ReaderUtils";
import Loading from "../Loading";
import { useParagraphSelector, useRTLBook } from "../../redux/selectors";
import { useAudioContext } from "../bookPlayer/AudioContext";
import { makeNavigationUrl } from "../../utils/URLUtils";
import { useNavigationContext } from "../NavigationContext";
import { isTreeItemExpandable } from "../../utils/TreeUtils";
import { isShowParaLevelByBookRealType } from "../../utils/BookUtils";
import { getAudioChapterForChapter } from "../bookPlayer/PlayerUtils";

import "./BookToc.scss";
import { ContentActions } from "src/redux/content.actions";

export const BOOK_TOC_ITEM_ACTIONS = {
  TOGGLE: "1",
  TOGGLE_PLAY: "2",
  OPEN_IN_READER: "3"
};

const BookToc = ({ bookId, paraId: _paraId, onItemTitleClick }) => {
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const book = useMemo(() => findNode(bookId, mainTree), [mainTree, bookId]);
  const paraId = _paraId ? normalizeParaId(_paraId, book.chapters) : undefined;

  const dispatch = useDispatch();
  const history = useHistory();
  const scrollRef = useRef();
  const [expanded, setExpanded] = useState([]);
  const paragraphs = useParagraphSelector(bookId || paraId);
  const { chapterId, isPlaying, toggleChapterPlaying, initPlay } = useAudioContext();
  const { isPlayer } = useNavigationContext();
  const isRTL = useRTLBook(bookId);
  const para = useMemo(() => {
    if (paraId) {
      return findParaById(paraId, mainTree, paragraphs);
    }

    return undefined;
  }, [mainTree, paragraphs, paraId]);

  const chapterIdReader = useMemo(() => {
    if (paraId) {
      return getReaderProgressAndChapterInfo(book, para, paraId).chapterId;
    }

    return undefined;
  }, [paraId, book, para]);

  const bookToc = book ? book.children : undefined;
  const isShowParaLevel = isShowParaLevelByBookRealType(book.realType);
  const currentChapterId = isShowParaLevel ? paraId : chapterIdReader;

  // Expand chapter if is the current one or if its child is the current one.
  useEffect(() => {
    let nodes = [];

    findNode(currentChapterId, { children: bookToc }, "id", nodes);

    if (nodes.length) {
      const newExpanded = [...expanded];

      nodes.forEach((item) => {
        if (!newExpanded.includes(item.id) && isTreeItemExpandable(item, isShowParaLevel)) {
          newExpanded.push(item.id);
        }
      });

      if (!deepEqual(newExpanded, expanded)) {
        setExpanded(newExpanded);
      }
    }
  }, [mainTree, bookToc, currentChapterId, isShowParaLevel]);

  // scroll to the current chapter
  useEffect(() => {
    if (scrollRef.current) {
      const { view } = scrollRef.current;
      const currentElement = view.querySelector(".is-current");
      if (currentElement) {
        scrollRef.current.scrollTop(currentElement.offsetTop - currentElement.clientHeight);
      }
    }
  }, [scrollRef.current, currentChapterId]);

  const handleAction = (action, item, itemAudioChapter) => {
    if (action === BOOK_TOC_ITEM_ACTIONS.TOGGLE) {
      toggleItemExpand(item);
    }

    if (action === BOOK_TOC_ITEM_ACTIONS.OPEN_IN_READER) {
      dispatch(actionSetContentId(undefined)); // Close Reader mobile book TOC.
      dispatch(
        setPlayerParams({
          isTocVisible: false,
          isSmallPlayerExpanded: false
        })
      );
      openItemInReader(item);
    }

    if (action === BOOK_TOC_ITEM_ACTIONS.TOGGLE_PLAY) {
      if (!itemAudioChapter) {
        // eslint-disable-next-line no-console
        console.log("%cSpecify audio chapter param!", "background: red; color: white;");
      }
      initOrTogglePlay(itemAudioChapter);
    }
  };

  const handleActions = (actions, item, itemAudioChapter) => {
    actions.forEach((action) => {
      handleAction(action, item, itemAudioChapter);
    });
  };

  const openItemInReader = ({ id }) => {
    history.push(makeNavigationUrl(id));
  };

  const initOrTogglePlay = (itemAudioChapter) => {
    if (chapterId) {
      toggleChapterPlaying(itemAudioChapter.id);
    } else {
      initPlay(itemAudioChapter.id); // This method will make a small player visible likewise.
    }
  };

  const toggleItemExpand = (item) => {
    const { children, id } = item;

    if (expanded.includes(id)) {
      setExpanded(
        expanded.filter((itemId) => {
          return itemId !== id;
        })
      );
    } else {
      setExpanded([...expanded, id]);

      if (children && children.length === 0) {
        dispatch(ContentActions.fetchChapterContentRequest(id));
      }
    }
  };

  const checkIsChildPlaying = (item, id) => {
    if (item.id === id) {
      return true;
    }
    const { children } = item;
    if (children && children.length) {
      let result = false;
      for (let i = 0; i < children.length; i++) {
        result = checkIsChildPlaying(children[i], id);
        if (result) {
          break;
        }
      }
      return result;
    }
    return false;
  };

  const renderContentList = (content) => {
    if (!content || !content.length) {
      return undefined;
    }

    const itemElements = content.map((item) => {
      const itemId = item.id;
      const itemDup = item.dup;
      const itemAudioChapter = getAudioChapterForChapter(book.chapters, itemId, itemDup)
        || item;
      const itemAudioId = itemAudioChapter.id;
      const canPlayAudio = !!itemAudioChapter.mp3;

      const isExpandable = isTreeItemExpandable(item, isShowParaLevel);
      const isExpanded = isExpandable && expanded.includes(itemId);

      const isInPlayer = itemAudioId === chapterId;
      const isItemPlaying = isInPlayer && isPlaying;

      const isSelected = currentChapterId === itemId;
      const PlayButtonIcon = isItemPlaying ? PauseCircleIcon : PlayCircleIcon;

      return (
        <div
          role="listitem"
          key={itemId}
          aria-selected={isSelected ? "true" : "false"}
          className={classNames("book-toc-list-item", "bookTocContentItem", {
            active: isInPlayer,
            "no-audio-track": !canPlayAudio,
            "is-child-playing": checkIsChildPlaying(item, chapterId),
            "is-expanded": isExpanded
          })}>
          <div className="book-toc-list-item__inner">
            {canPlayAudio && (
              <PlayButtonIcon
                className="book-toc-list-item-icon-play"
                onClick={() => {
                  handleAction(BOOK_TOC_ITEM_ACTIONS.TOGGLE_PLAY, item, itemAudioChapter);
                }}
              />
            )}
            {isExpandable && (
              <i className={classNames("icon-chevron-right book-toc-list-item-icon-expand", {
                "is-expanded": isExpanded
              })} 
              onClick={() => {
                handleAction(BOOK_TOC_ITEM_ACTIONS.TOGGLE, item);
              }}/>
            )}
            <button
              className={classNames("book-toc-list-item__btn", {
                "is-current": isSelected,
              })}
              onClick={() => {
                const actions = [];

                if (isPlayer) {
                  if (isExpandable) {
                    actions.push(BOOK_TOC_ITEM_ACTIONS.TOGGLE);

                    if (canPlayAudio && !isItemPlaying) {
                      actions.push(BOOK_TOC_ITEM_ACTIONS.TOGGLE_PLAY);
                    }
                  } else {
                    if (canPlayAudio) {
                      if (isItemPlaying) {
                        actions.push(BOOK_TOC_ITEM_ACTIONS.OPEN_IN_READER);
                      } else {
                        actions.push(BOOK_TOC_ITEM_ACTIONS.TOGGLE_PLAY);
                      }
                    } else {
                      actions.push(BOOK_TOC_ITEM_ACTIONS.OPEN_IN_READER);
                    }
                  }
                } else {
                  if (isExpandable) {
                    actions.push(BOOK_TOC_ITEM_ACTIONS.TOGGLE);
                  } else {
                    actions.push(BOOK_TOC_ITEM_ACTIONS.OPEN_IN_READER);
                  }
                }

                let isNativeActionPrevented = false;
                if (onItemTitleClick) {
                  isNativeActionPrevented = onItemTitleClick(actions, item, itemAudioChapter);
                }

                if (!isNativeActionPrevented) {
                  handleActions(actions, item, itemAudioChapter);
                }
              }}>
              <span className="book-toc-list-item__text">
                {item.title || item.refcode_short || item.label}
              </span>
            </button>
          </div>
          {isExpanded && renderContentList(item.children)}
        </div>
      );
    });

    return (
      <div
        role="list"
        className={classNames("book-toc__list", {
          "book-toc-rtl": isRTL
        })}>
        {itemElements}
      </div>
    );
  };

  if (!bookToc) {
    return <Loading type={Loading.types.BOOK_CONTENT} />;
  }

  return <Scroll ref={scrollRef}>{renderContentList(bookToc)}</Scroll>;
};

BookToc.defaultProps = {};

BookToc.propTypes = {
  bookId: PropTypes.string.isRequired,
  paraId: PropTypes.string,
  onItemTitleClick: PropTypes.func,
};

export default BookToc;
