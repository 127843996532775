export const LOADER_ID_FEATURED_SEARCH_SECTIONS = "fs/section";

export const FeaturedSearchActionsConstants = {
  FETCH: "fs/FETCH",
  FETCH_SUCCESS: "fs/FETCH_SUCCESS",
  FETCH_FOR_PARAGRAPH: "fs/FETCH_FOR_PARAGRAPH",
};

export const featuredSearchActions = {
  fetch: (data) => ({
    type: FeaturedSearchActionsConstants.FETCH,
    data,
  }),
  fetchSuccess: (cacheKey, data) => ({
    type: FeaturedSearchActionsConstants.FETCH_SUCCESS,
    data: {
      cacheKey,
      data,
    },
  }),
  fetchForParagraph: (data) => ({
    type: FeaturedSearchActionsConstants.FETCH_FOR_PARAGRAPH,
    data,
  }),
};
