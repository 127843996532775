export const VIEW_MODE_CLASSNAME_PREFIX = "mode-";

/**
 * @enum {string}
 */
export const VIEW_MODE = {
  DESKTOP: VIEW_MODE_CLASSNAME_PREFIX + "desktop",
  DESKTOP2K: VIEW_MODE_CLASSNAME_PREFIX + "2k",
  DESKTOP4K: VIEW_MODE_CLASSNAME_PREFIX + "4k",
  MOBILE: VIEW_MODE_CLASSNAME_PREFIX + "mobile",
  TABLET: VIEW_MODE_CLASSNAME_PREFIX + "tablet",
  FULLSCREEN: VIEW_MODE_CLASSNAME_PREFIX + "fullscreen",
  TEXT_ONLY: VIEW_MODE_CLASSNAME_PREFIX + "text",
  BLIND: VIEW_MODE_CLASSNAME_PREFIX + "blind",
  ZOOM: VIEW_MODE_CLASSNAME_PREFIX + "zoom"
};
