import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { IconButton } from "../views";
import { useViewMode } from "../../hooks";
import { Settings } from "../../utils/Settings";
import { actionUpdateSetting } from "../../redux/actions";
import EgwWebFont from "src/assets/EgwWebFont";
import { SearchPanelTabs } from "./constants";
import { makeSearchUrl, parseSearchUrl } from "src/utils/SearchUtils";

export const MinNavPanel = ({ hideBtnRelatedSearch, hideBtnFeaturedSearch }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewMode();
  const history = useHistory();
  const location = useLocation();
  const searchParams = useSelector((state) => state.search.searchParams);
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);

  if (isMobile) {
    return null;
  }

  const handleNavigationClick = (tab) => {
    dispatch(actionUpdateSetting(Settings.rightTab.id, SearchPanelTabs.RESULTS));
    const params = parseSearchUrl(location.search);
    const newSearchQuery = { ...searchParams, ...params };
    if (!newSearchQuery.query) {
      newSearchQuery.query = searchParams.query;
    }
    newSearchQuery.tab = tab;
    history.push(makeSearchUrl(newSearchQuery, collectionFilter, true));
  };

  return (
    <>
      {!hideBtnRelatedSearch && (
        <IconButton
          className="icon-margin"
          title={t("openRelatedSearch")}
          icon={EgwWebFont["search-relation"]}
          onClick={() => handleNavigationClick("related")}
          showIcon={true}
        />
      )}
      {
        !hideBtnFeaturedSearch && (
          <IconButton
            className="icon-margin"
            title={t("openFeaturedSearch")}
            icon={EgwWebFont["search-featured"]}
            onClick={() => handleNavigationClick("featured")}
            showIcon={true}
          />
        )
      }
    </>
  );
};

MinNavPanel.propTypes = {
  hideBtnRelatedSearch: PropTypes.bool,
  hideBtnFeaturedSearch: PropTypes.bool,
};

export default MinNavPanel;
