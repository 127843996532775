import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useViewMode } from "../../hooks";
import { DotsVerticalIcon } from "../../assets/CommonIcons";
import { IconButton } from "../views";
import { DefaultFontSize } from "../../shared/utils/theme";
import { useRemToPx } from "../../hooks/viewModeHooks";

const AlphabetView = ({ onFilter, filter, chars, maxHeight }) => {
  const { zoom, isMobileOrTablet } = useViewMode();
  const remToPx = useRemToPx();
  const [show, setShow] = useState(false);

  const list = chars.map((item, index) => {
    return (
      <span
        key={index}
        className={filter === item ? "char-selected" : null}
        onClick={() => {
          onFilter(item === filter ? null : item);
        }}
      >
        {item}
      </span>
    );
  });

  const toggleHiddenHandler = () => {
    setShow((prev) => !prev);
  };

  const charHeight = DefaultFontSize * zoom / 100;
  const threeDotsBtnHeight = remToPx(1.2);

  const renderAlphabetItems = () => {
    const { availableList, hiddenList } = list.reduce((acc, item) => {
      acc.listHeight += charHeight;

      if (acc.listHeight > maxHeight) {
        acc.hiddenList.push(item);
      } else {
        acc.availableList.push(item);
      }

      return acc;
    }, {
      availableList: [],
      hiddenList: [],
      listHeight: Math.min(charHeight, threeDotsBtnHeight)
    });

    return (
      <React.Fragment>
        {availableList}
        {!!hiddenList.length && (
          <div className="char-three-dots">
            <IconButton
              icon={DotsVerticalIcon}
              onClick={toggleHiddenHandler}
              active={show}
            />
            {show && (
              <React.Fragment>
                <div className="dropDownMenuBack" onClick={toggleHiddenHandler} />
                <div className={classNames("char-wrap-hidden", (show ? "showView" : "hideView"))}>
                  {hiddenList}
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="char-wrap">
      <div className="char-wrap__inner">
        {isMobileOrTablet ? renderAlphabetItems() : list}
      </div>
    </div>
  );
};

AlphabetView.propTypes = {
  chars: PropTypes.array,
  filter: PropTypes.string,
  maxHeight: PropTypes.number,
  onFilter: PropTypes.func
};

export default AlphabetView;
