import { useCallback, useEffect } from "react";
import { Settings } from "../utils/Settings";
import { MENU_STATE } from "../utils/MenuItems";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDimensions, useViewMode } from "./viewModeHooks";
import { TOAST_TYPE } from "../components/popup/Toaster";
import {
  actionAddMessage,
  actionUpdateSetting,
  actionUpdateSettings,
  setPlayerParams,
} from "../redux/actions";
import { useNavigationContext } from "../components/NavigationContext";
import useFullScreen from "./useFullScreen";

/**
 * Use this hook to get the OCCUPIED panel width in the RESIZER.
 * @returns {{
 * getRightPanelOccupiedWidth: (function(*, *=): (number|number)),
 * isEnoughWidthFor: (function({
 *  leftPanelState: *,
 *  rightPanelState: *,
 *  leftPanelPinned: *,
 *  rightPanelPinned: *
 * }): boolean),
 * getLeftPanelOccupiedWidth: (function(*, *=): (number|number))
 * }}
 */
const usePanelOccupiedWidth = () => {
  const { isTablet } = useViewMode();
  const {
    MIN_LEFT_WIDTH,
    MIN_RIGHT_WIDTH,
    MIN_MAIN_WIDTH,
    COLLAPSE_MENU_WIDTH,
    MIN_LEFT_TREE_WIDTH,
  } = useResizeDimensions();

  const getLeftPanelOccupiedWidth = useCallback(
    (state, pinned) => {
      if (isTablet && (state === MENU_STATE.COLLAPSE || state === MENU_STATE.MENU || !pinned)) {
        return 0;
      }
      if (state === MENU_STATE.TREE) {
        return MIN_LEFT_TREE_WIDTH;
      } else if (state === MENU_STATE.MENU) {
        return MIN_LEFT_WIDTH;
      }
      return COLLAPSE_MENU_WIDTH;
    },
    [isTablet, COLLAPSE_MENU_WIDTH, MIN_LEFT_WIDTH, MIN_LEFT_TREE_WIDTH],
  );

  const getRightPanelOccupiedWidth = useCallback(
    (state, pinned) => {
      if (state === false || (isTablet && !pinned)) {
        return 0;
      }
      return MIN_RIGHT_WIDTH;
    },
    [isTablet, MIN_RIGHT_WIDTH],
  );

  const isEnoughWidthFor = useCallback(
    ({ leftPanelState, rightPanelState, leftPanelPinned, rightPanelPinned }) => {
      return (
        getLeftPanelOccupiedWidth(leftPanelState, leftPanelPinned) +
          MIN_MAIN_WIDTH +
          getRightPanelOccupiedWidth(rightPanelState, rightPanelPinned) <=
        window.innerWidth
      );
    },
    [MIN_MAIN_WIDTH, getRightPanelOccupiedWidth, getLeftPanelOccupiedWidth],
  );

  return {
    getLeftPanelOccupiedWidth,
    getRightPanelOccupiedWidth,
    isEnoughWidthFor,
  };
};

/**
 * Use this hook to change panels visibility, pin state. It guarantees that UI
 * will be fit to the current application zoom and screen width.
 * @returns {{
 * expandSmallPlayer: function | expandSmallPlayer,
 * setMenuState: function | setMenuState,
 * setLeftPanelPinned: function | setLeftPanelPinned,
 * setRightPanelPinned: function | setRightPanelPinned,
 * setIsRightPanelVisible: function | setIsRightPanelVisible
 * }}
 */
export const usePanels = () => {
  const dispatch = useDispatch();
  const { isEnoughWidthFor } = usePanelOccupiedWidth();
  const menuState = useSelector((state) => state.settings.menuState);
  const isShowRightPanel = useSelector((state) => state.settings.isShowRightPanel);
  const isRightPanelPinned = useSelector((state) => state.settings.isRightPanelPinned);
  const isLeftPanelPinned = useSelector((state) => state.settings.isLeftPanelPinned);
  const isSmallPlayerExpanded = useSelector((state) => state.audioPlayer.isSmallPlayerExpanded);
  const { isMobileOrTablet, isTablet } = useViewMode();

  const _collapseSmallPlayerOnPanelOpen = () => {
    if (isMobileOrTablet && isSmallPlayerExpanded) {
      dispatch(setPlayerParams({ isSmallPlayerExpanded: false }));
    }
  };

  const expandSmallPlayer = () => {
    dispatch(setPlayerParams({ isSmallPlayerExpanded: true, isTocVisible: false }));
    // Close left and right panels. They are not visible under the expanded player.
    dispatch(
      actionUpdateSettings({
        [Settings.isShowRightPanel.id]: false,
        [Settings.menuState.id]: MENU_STATE.COLLAPSE,
      }),
    );
  };

  const setMenuState = (newState) => {
    const newStateIsOpen = newState !== MENU_STATE.COLLAPSE;

    if (newStateIsOpen) {
      _collapseSmallPlayerOnPanelOpen();
    }

    const updateSettings = {
      [Settings.menuState.id]: newState,
    };

    if (newStateIsOpen && isShowRightPanel) {
      if (
        (isTablet && !isRightPanelPinned) ||
        !isEnoughWidthFor({
          leftPanelState: newState,
          rightPanelState: isShowRightPanel,
          leftPanelPinned: isLeftPanelPinned,
          rightPanelPinned: isRightPanelPinned,
        })
      ) {
        updateSettings[Settings.isShowRightPanel.id] = false;
      }
    }

    dispatch(actionUpdateSettings(updateSettings));
  };

  const setIsRightPanelVisible = (newState) => {
    if (newState === true) {
      _collapseSmallPlayerOnPanelOpen();
    }

    const updateSettings = {
      [Settings.isShowRightPanel.id]: newState,
    };

    if (newState === true && menuState !== MENU_STATE.COLLAPSE) {
      if (
        (isTablet && !isLeftPanelPinned) ||
        !isEnoughWidthFor({
          leftPanelState: menuState,
          rightPanelState: newState,
          leftPanelPinned: isLeftPanelPinned,
          rightPanelPinned: isRightPanelPinned,
        })
      ) {
        updateSettings[Settings.menuState.id] = MENU_STATE.COLLAPSE;
      }
    }

    dispatch(actionUpdateSettings(updateSettings));
  };

  const setLeftPanelPinned = (state) => {
    if (state === true) {
      const isThereSpaceWithCurrentPanelStates = isEnoughWidthFor({
        leftPanelState: menuState,
        rightPanelState: isShowRightPanel,
        leftPanelPinned: true,
        rightPanelPinned: isRightPanelPinned,
      });
      if (isThereSpaceWithCurrentPanelStates) {
        dispatch(actionUpdateSetting(Settings.isLeftPanelPinned.id, true));
      } else {
        const isThereSpaceWithoutRightPanel = isEnoughWidthFor({
          leftPanelState: menuState,
          rightPanelState: false,
          leftPanelPinned: true,
          rightPanelPinned: isRightPanelPinned,
        });
        if (isThereSpaceWithoutRightPanel) {
          dispatch(
            actionUpdateSettings({
              [Settings.isLeftPanelPinned.id]: true,
              [Settings.isShowRightPanel.id]: false,
            }),
          );
        } else {
          dispatch(actionAddMessage("@canNotPinTree", TOAST_TYPE.notify));
        }
      }
    } else {
      dispatch(actionUpdateSetting(Settings.isLeftPanelPinned.id, false));
    }
  };

  const setRightPanelPinned = (state) => {
    if (state === true) {
      const isThereSpaceWithCurrentPanelStates = isEnoughWidthFor({
        leftPanelState: menuState,
        rightPanelState: isShowRightPanel,
        leftPanelPinned: isLeftPanelPinned,
        rightPanelPinned: true,
      });
      if (isThereSpaceWithCurrentPanelStates) {
        dispatch(actionUpdateSetting(Settings.isRightPanelPinned.id, true));
      } else {
        const isThereSpaceWithoutLeftPanel = isEnoughWidthFor({
          leftPanelState: MENU_STATE.COLLAPSE,
          rightPanelState: isShowRightPanel,
          leftPanelPinned: isLeftPanelPinned,
          rightPanelPinned: true,
        });
        if (isThereSpaceWithoutLeftPanel) {
          dispatch(
            actionUpdateSettings({
              [Settings.isRightPanelPinned.id]: true,
              [Settings.menuState.id]: MENU_STATE.COLLAPSE,
            }),
          );
        } else {
          dispatch(actionAddMessage("@canNotPinSearchPlus", TOAST_TYPE.notify));
        }
      }
    } else {
      dispatch(actionUpdateSetting(Settings.isRightPanelPinned.id, false));
    }
  };

  return {
    expandSmallPlayer,
    setMenuState,
    setLeftPanelPinned,
    setRightPanelPinned,
    setIsRightPanelVisible,
  };
};

// Hides panel or panels if not fit to the current application zoom or screen width.
export const useAutoHidePanels = () => {
  const dispatch = useDispatch();
  const { isRelatedSearch } = useNavigationContext();
  const { isTablet, isMobile } = useViewMode();
  const { fullscreen } = useFullScreen();
  const { isEnoughWidthFor } = usePanelOccupiedWidth();
  const { menuState, isShowRightPanel, isRightPanelPinned, isLeftPanelPinned } = useSelector(
    (state) => state.settings,
  );

  const _regulatePanelsVisibility = useCallback(() => {
    if (
      !isEnoughWidthFor({
        leftPanelPinned: isLeftPanelPinned,
        leftPanelState: menuState,
        rightPanelPinned: isRightPanelPinned,
        rightPanelState: isShowRightPanel,
      })
    ) {
      if (isTablet) {
        // Close the right panel in case the left and the right NOT PINNED panels are shown at once.
        if (
          menuState !== MENU_STATE.COLLAPSE &&
          isShowRightPanel &&
          !isLeftPanelPinned &&
          !isRightPanelPinned
        ) {
          dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
        }

        // Unpin one of the panels in case there is no enough space to show them at once.
        if (isRightPanelPinned) {
          dispatch(actionUpdateSetting(Settings.isRightPanelPinned.id, false));
        } else if (isLeftPanelPinned) {
          dispatch(actionUpdateSetting(Settings.isLeftPanelPinned.id, false));
        }
      } else {
        // Hide one of the panels in case there is no enough space to show them at once.
        if (isShowRightPanel) {
          dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
        } else if (menuState !== MENU_STATE.COLLAPSE) {
          dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
        }
      }
    }
  }, [
    isEnoughWidthFor,
    isLeftPanelPinned,
    isRightPanelPinned,
    isShowRightPanel,
    isTablet,
    menuState,
  ]);

  // Hide the right panel when full screen and right panel shown
  // (because it has broken markup and can't be closed).
  useEffect(() => {
    if (fullscreen && !isRelatedSearch) {
      dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
    }
  }, [fullscreen, isRelatedSearch]);

  useEffect(() => {
    if (!isMobile) {
      _regulatePanelsVisibility();
    }
  }, [_regulatePanelsVisibility, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener("resize", _regulatePanelsVisibility);
    }
    return () => {
      window.removeEventListener("resize", _regulatePanelsVisibility);
    };
  }, [isMobile, _regulatePanelsVisibility]);
};
