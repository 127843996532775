import { FETCH_ABBREVIATIONS_SUCCESS } from "./constants";

const initialState = {
  egw: [],
  bibles: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ABBREVIATIONS_SUCCESS:
      return { ...state, egw: action.data.egw, bibles: action.data.bible };
    default:
      return state;
  }
};
