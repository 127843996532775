import React, { useContext, useMemo, useCallback, useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import deepEqual from "deep-equal";
import { actionFullscreenId } from "../redux/params.actions";
import { useTranslation } from "react-i18next";
import { getTarget, getLabels, RouteType, getReaderNavigation } from "../utils/RouterUtils";
import {
  getParaIdsFromUrl,
  makeChapterUrl,
  updateReaderPanels,
  getQueryByName,
  PANELS,
  getBookIds,
  PARA_ID,
  firstPossiblePara,
  PanelTypes,
} from "../utils/URLUtils";
import { MENU_STATE } from "../utils/MenuItems";
import {
  useWhyDidYouUpdate,
  useViewMode,
  useResizeDimensions,
  useSessionStorage,
  useDevMode,
} from "../hooks";
import { actionUpdateSetting, actionNavigateById, actionUpdateSettings } from "../redux/actions";
import { useParagraphSelector } from "../redux/selectors";
import { Settings } from "../utils/Settings";
import { SearchActions } from "./search/search.actions";
import { CONTENT_CLASSES } from "../shared/utils/content";
import { URLS } from "../shared/utils/url";

const NavigationContext = React.createContext({});

const NavigationProvider = ({ children }) => {
  const history = useHistory();
  const { pathname, search: locationQuery } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [customParams, setCustomParams] = useState({});
  const [historyState, setHistoryRoute] = useState([]);
  const { isDevMode } = useDevMode();

  const isPlayer = !!getQueryByName("listen", locationQuery);
  const isAudio = pathname.includes(URLS.audioBooks) || isPlayer;
  const isAudioQuery = getQueryByName("audio", locationQuery) === "1";
  const isLibrary = pathname.includes(URLS.myLibrary);
  const isRelatedSearch =
    pathname.includes(URLS.relatedSearch) || pathname.includes(URLS.historySearch);

  const paraIds = getQueryByName(PANELS, locationQuery, true);

  const panelIds = useMemo(() => (paraIds ? paraIds.split(",") : []), [paraIds]);
  const bookIds = useMemo(() => getBookIds(panelIds), [panelIds]);

  const oldParaIds = getQueryByName(PARA_ID, locationQuery, true);

  const [hasNotSavedWSChanges, setHasNotSavedWSChanges] = useSessionStorage(
    "hasNotSavedWSChanges",
    false,
  );
  const [showPopupSaveWSonLogOut, setShowPopupSaveWSonLogOut] = useSessionStorage(
    "showPopupSaveWSonLogOut",
    false,
  );

  const { search, activeIndex } = useMemo(() => getParaIdsFromUrl(locationQuery), [locationQuery]);

  //for open push notification and show content instead of side panels
  const isFromPush = locationQuery && locationQuery.indexOf("fromPush=1") !== -1;

  const { isMobile } = useViewMode();
  const { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT } = useResizeDimensions();
  const isLogin = useSelector((state) => state.system.isLogin);
  const nextRoute = useSelector((state) => state.system.nextRoute);
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const libraryLanguages = useSelector((state) => state.settings[Settings.libraryLanguages.id]);

  const activeId = firstPossiblePara(panelIds, activeIndex);
  const bookContent = useParagraphSelector(activeId);

  const { targetId, currentFolder, nodeList, routeType, isReader, realType } = useMemo(() => {
    if (customParams.nodeList) {
      let currentFolder = customParams.currentFolder;
      const { nodeList } = customParams;
      if (!currentFolder && nodeList && nodeList.length > 0) {
        currentFolder = nodeList[nodeList.length - 1];
      }
      return {
        routeType: RouteType.panel,
        isReader: false,
        currentFolder,
        realType: currentFolder.realType,
        ...customParams,
      };
    }
    if (activeId) {
      return getReaderNavigation(activeId, mainTree, bookContent, libraryLanguages, isLogin);
    }
    return getTarget(mainTree, pathname, libraryLanguages, isLogin, isAudioQuery);
  }, [
    mainTree,
    pathname,
    libraryLanguages,
    isLogin,
    customParams,
    bookContent,
    activeId,
    isAudioQuery,
  ]);

  const label = customParams?.label || getLabels(t, currentFolder, pathname);

  useEffect(() => {
    // back compability for old url of reader
    if (oldParaIds) {
      const oldPanels = oldParaIds.split(",");
      history.replace(makeChapterUrl(oldPanels));
    }
  }, [oldParaIds]);

  useEffect(() => {
    if (isFromPush && isMobile) {
      dispatch(
        actionUpdateSettings({
          [Settings.isShowRightPanel.id]: false,
          [Settings.menuState.id]: MENU_STATE.COLLAPSE,
        }),
      );
    }
  }, []);


  useEffect(() => {
    if (nextRoute) {
      const { url, push } = nextRoute;
      if (isDevMode) {
        console.log("NavigationContext nextRoute", nextRoute);
      }
      const { pathname, search } = history.location;
      if (push || pathname + search !== url) {
        history.push(url);
      } else {
        history.replace(url);
      }
    }
  }, [nextRoute, history]);

  if (isDevMode) {
    console.log(
      "NavigationContext render",
      currentFolder,
      nodeList,
      panelIds,
      bookIds,
      routeType,
      activeId,
      activeIndex,
    );
  }
  useWhyDidYouUpdate("NavigationContext", {
    dispatch,
    history,
    bookIds,
    locationQuery,
    panelIds,
    search,
    pathname,
    activeId,
    label,
    activeIndex,
    currentFolder,
    nodeList,
    customParams,
    mainTree,
    libraryLanguages,
    isLogin,
    bookContent,
  });

  const makeUpdateReaderPanels = useCallback(
    (type, value, opts) => {
      const newIds = updateReaderPanels(panelIds, type, value, opts);
      if (newIds.length !== panelIds.length) {
        dispatch(actionFullscreenId());
      }
      if (type === PanelTypes.CONTENT.id) {
        // probably should be for any panel type
        history.push(makeChapterUrl(newIds));
      } else {
        history.replace(makeChapterUrl(newIds));
      }
    },
    [dispatch, history, panelIds],
  );
  /**
   *  @param {*} id
   *  @param {*} params
   */
  const openId = useCallback(
    (id, params = {}) => {
      if (!id) {
        return;
      }
      // special case. Sizes need for check sizes of open reader
      const lv = document.getElementsByClassName("layout-wrap")[0];
      if (lv) {
        params.readerWidth = lv.offsetWidth;
        params.readerHeight = lv.offsetHeight;
      }
      params.panelIds = panelIds;
      params.id = id;
      params.isMobile = isMobile;
      params.MIN_PANEL_WIDTH = MIN_PANEL_WIDTH;
      params.MIN_PANEL_HEIGHT = MIN_PANEL_HEIGHT;

      dispatch(actionNavigateById(params));

      if (isMobile) {
        dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
      }
    },
    [panelIds, isMobile],
  );

  const openGlobalSearchResult = useCallback(
    (paraId, openSidePanel, localSearch) => {
      if (openSidePanel) {
        dispatch(
          actionUpdateSettings({
            [Settings.isShowRightPanel.id]: true,
          }),
        );
      }
      openId(paraId, {
        className: CONTENT_CLASSES.PARAGRAPH,
        newWindow: true,
      });
      dispatch(SearchActions.setSearchType(localSearch));
      if (isMobile) {
        dispatch(SearchActions.updateShowHeaderSearch(false));
      }
    },
    [openId],
  );

  const setNavigation = (params) => {
    setCustomParams(params || {});
  };

  const formatUrl = (url) => {
    if (url) {
      if (getQueryByName("listen", url)) {
        return "isPlayer";
      }

      if (url.includes(URLS.pdf)) {
        return "isPdf";
      }

      if (url.includes(URLS.epub)) {
        return "isEpub";
      }

      if (url.includes(URLS.read)) {
        return "isReader";
      }
    }

    return url;
  };

  useEffect(() => {
    const prevUrl = historyState[0];
    const currentUrl = pathname + locationQuery;
    // WARNING: several history changes in the time will lead to several {pathname} changes,
    // but with THE SAME {history.action}.
    if (history?.action === "REPLACE" || formatUrl(prevUrl) === formatUrl(currentUrl)) {
      setHistoryRoute([currentUrl, ...historyState.slice(1)]);
    } else {
      setHistoryRoute([currentUrl, ...historyState]);
    }
  }, [pathname, locationQuery]);

  const hasPrevPage = useCallback(() => historyState.length - 1 > 0, [historyState]);

  const goPrevPage = useCallback(
    (goTo) => {
      if (goTo) {
        const targetUrl = historyState[goTo];
        if (targetUrl) {
          setHistoryRoute(historyState.slice(goTo));
          history.replace(targetUrl);
        }
        return;
      }
      const [currentUrl, prevUrl, ...historyUrls] = historyState;
      setHistoryRoute([...historyUrls]);
      history.push(prevUrl);
    },
    [historyState, history],
  );

  return (
    <NavigationContext.Provider
      value={{
        //methods
        makeUpdateReaderPanels,
        openId,
        openGlobalSearchResult,
        setNavigation,
        hasPrevPage,
        goPrevPage,

        //variables
        panelIds,
        bookIds,
        activeId,
        activeIndex,
        label,
        search,
        isAudio,
        isReader,
        isPlayer,
        isLibrary,
        isRelatedSearch,
        pathname,
        realType,
        //TODO need remove
        targetId: targetId,
        currentFolder: currentFolder || {},
        nodeList: nodeList || [],
        routeType,

        // workspaces
        hasNotSavedWSChanges,
        setHasNotSavedWSChanges,
        showPopupSaveWSonLogOut,
        setShowPopupSaveWSonLogOut,
      }}>
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.node,
};

export { NavigationContext, NavigationProvider };

/**
 * @typedef{Object} NavigationContextParams
 * @property {boolean} isAudio 
 * @property {boolean} isReader 
 * @property {boolean} isPlayer 
 * @property {boolean} isLibrary 
 * @property {boolean} isRelatedSearch
 * 
 * @property {function} makeUpdateReaderPanels 
 * @property {function} openId 
 * @property {function} openGlobalSearchResult 
 * @property {function} setNavigation 
 * @property {function} hasPrevPage 
 * @property {function} goPrevPage 
 * 
 * @property {string[]} panelIds 
 * @property {string[]} bookIds 
 * 
 * @property {?number} activeIndex 
 * @property {?string} activeId - id of paragraph, e.g. 127.5
 * @property {?string} label 
 * @property {?string} search 
 * @property {?string} pathname 
 * @property {string} realType 
 * @property {string} targetId 
 * @property {string} routeType 
 * 
 * @property {Object} currentFolder 
 * @property {Object[]} nodeList  
 * 
 * @property {function} hasNotSavedWSChanges 
 * @property {function} setHasNotSavedWSChanges 
 * @property {function} showPopupSaveWSonLogOut 
 * @property {function} setShowPopupSaveWSonLogOut 

 */

/**
 * Cant be used inside dialogs or popups
 * @returns {NavigationContextParams}
 */
export const useNavigationContext = () => useContext(NavigationContext);

/**
 * Hook provide set custom title and breadcrumbs. Can implement in
 * pages for make decentralization of content
 *
 * @param {Object} customParams - contains all posible params for navigation context
 * customParams can contains only nodeList array each item must have id and label
 * can have currentFolder for set special target (get last iemt of nodeList by default)
 * can have label for set special label (get from current folder by default)
 */
export const useNavigationData = (customParams) => {
  const { setNavigation } = useNavigationContext();
  const storedParams = useRef();

  useEffect(() => {
    return () => setNavigation();
  }, []);

  useEffect(() => {
    let putParams = customParams;
    if (deepEqual(storedParams.current, customParams)) {
      putParams = storedParams.current;
    } else {
      storedParams.current = customParams;
    }
    setNavigation(putParams);
  }, [customParams]);
};
