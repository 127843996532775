import React from "react";
import { getBoundingClientRect } from "../../../utils/Utils";
import { calculateTooltipView } from "./tooltip.utils";
import { containsUrl } from "../../../utils/URLUtils";
import TooltipMessage from "./TooltipMessage";
import classnames from "classnames";

const INITIAL_STATE = {
  left: -500,
  top: -500,
  isShown: false
};
const showOptionsDefault = { delayTimeout: 1000 };
let _resetListener;

class TooltipWrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.timerRef = undefined;
    this.hide = this.hide.bind(this);
    this.getResetAllListener = this.getResetAllListener.bind(this);
    this.show = this.show.bind(this);
  }

  // Pattern of this context saving.
  getResetAllListener() {
    if (_resetListener === undefined) {
      _resetListener = () => {
        this.hide();
      };
    }

    return _resetListener;
  }

  componentDidMount() {
    window.addEventListener("popstate", this.getResetAllListener());
  }

  componentWillUnmount() {
    const resetAllListener = this.getResetAllListener();
    window.removeEventListener("popstate", resetAllListener);
    resetAllListener();
  }

  show(message, e, parent, position, { delayTimeout } = showOptionsDefault) {
    //TODO optimize make position
    const currentTarget = e.target;
    const parentTarget = e.currentTarget;
    let timerId;

    e.persist && e.persist();

    let hideOnClick = false;
    let hideOnLeave = false;

    if (containsUrl(message)) {
      hideOnClick = true;
      hideOnLeave = true;
    } else {
      currentTarget.addEventListener(
        "mouseleave",
        () => {
          this.hide();
        },
        { once: true }
      );
      // parent target needed to hide tooltip when scroll event fired
      parentTarget.addEventListener(
        "scroll",
        () => {
          this.hide();
        },
        { once: true }
      );
    }

    const targetRect = getBoundingClientRect(e.target);
    const parentRect = parent ? getBoundingClientRect(parent) : undefined;
    const { left, top, pointerClass } = calculateTooltipView(
      message,
      targetRect,
      parentRect,
      position
    );

    if (delayTimeout) {
      const clearTimeoutListener = () => {
        clearTimeout(timerId);
      };

      timerId = setTimeout(() => {
        if (typeof this.setState === "function") {
          this.setState({
            isShown: true,
            message,
            left,
            top,
            pointerClass,
            hideOnClick,
            hideOnLeave
          });

          if (currentTarget) {
            currentTarget.removeEventListener("mouseleave", clearTimeoutListener, { once: true });
          }
          window.removeEventListener("popstate", clearTimeoutListener, { once: true });
        }
      }, delayTimeout);

      this.timerRef = timerId; // Is needed to clear a timer on browser tab change, unmount.

      currentTarget.addEventListener("mouseleave", clearTimeoutListener, { once: true });
      window.addEventListener("popstate", clearTimeoutListener, { once: true });
    } else {
      this.setState({ isShown: true, message, left, top, pointerClass, hideOnClick, hideOnLeave });
    }
  }

  hide(timerId = this.timerRef) {
    if (timerId) {
      clearTimeout(timerId);
    }
    this.setState(INITIAL_STATE);
  }

  render() {
    const { message, isShown, pointerClass, left, top, hideOnClick, hideOnLeave } = this.state;
    return (
      <div
        style={{ left, top }}
        className={classnames("tooltip-wrap", {
          hideView: !isShown
        })}>
        <div
          className="tooltip-view"
          onClick={hideOnClick ? () => this.hide() : undefined}
          onMouseLeave={hideOnLeave ? () => this.hide() : undefined}>
          <div className={pointerClass} />
          <TooltipMessage message={message} />
        </div>
      </div>
    );
  }
}

export default TooltipWrap;
