import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./View.scss";
import classNames from "classnames";

const HideIcon = ({ onClick, className, isMobile, title, textMode, ...rest }) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      className={classNames("hide-icon-container iconInActive", className)}
      {...rest}
    >

      {!textMode && <i className="icon-close" />}
      {!isMobile && <span>{title ? t(title) : t("hide")}</span>}
    </button>
  );
};

HideIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  title: PropTypes.string
};

export default HideIcon;
