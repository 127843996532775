import { isEventKey, KeyCodes } from "../shared/utils/dom";

export const selectorFocusableElements =
  "[tabindex]:not([tabindex=\"-1\"]), button:not([disabled]), [href], input, select, textarea, [data-focus='true']";

export const upDownNavigationByEvent = (
  event,
  elements,
  activeElement = document.activeElement,
) => {
  let useNavigation = false;
  const max = elements.length - 1;
  let currentElementIndex = 0;
  // const activeElement = (document && document.activeElement) || undefined;

  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    element.setAttribute("aria-selected", "false");
    if (element === activeElement) {
      currentElementIndex = i;
    }
  }

  if (isEventKey(event, KeyCodes.downArrow)) {
    if (currentElementIndex + 1 <= max) {
      useNavigation = true;
      currentElementIndex++;
    }
  } else if (isEventKey(event, KeyCodes.upArrow)) {
    if (currentElementIndex - 1 >= 0) {
      useNavigation = true;
      currentElementIndex--;
    }
  }

  if (useNavigation) {
    let element = elements[currentElementIndex];
    element.setAttribute("tab-index", 0);
    element.setAttribute("aria-selected", "true");
    element.focus();
  }
};

/**
 *
 * @param {NodeListOf<Element>} elements
 */
export const upDownNavigation = (elements = [], activeElement = document.activeElement) => {
  if (!elements || typeof elements !== "object") {
    return;
  }

  let useNavigation = false;
  const max = elements.length - 1;
  let currentElementIndex = 0;
  //  const activeElement = (document && document.activeElement) || undefined;

  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    element.setAttribute("aria-selected", "false");

    if (element === activeElement) {
      currentElementIndex = i;
    }

    element.onkeydown = (event) => {
      event.preventDefault();
      if (isEventKey(event, KeyCodes.downArrow)) {
        if (currentElementIndex + 1 <= max) {
          useNavigation = true;
          currentElementIndex++;
        }
      } else if (isEventKey(event, KeyCodes.upArrow)) {
        if (currentElementIndex - 1 >= 0) {
          useNavigation = true;
          currentElementIndex--;
        }
      } else {
        useNavigation = false;
      }

      if (useNavigation) {
        let element = elements[currentElementIndex];
        element.setAttribute("tab-index", 0);
        element.setAttribute("aria-selected", "true");
        element.focus();
      }
    };
  }
};

export const lastElementIsFocusable = (target = null, activeElement = document.activeElement) => {
  if (!target) {
    return false;
  }

  const elements = target.querySelectorAll(selectorFocusableElements);

  const filteredElements = elements && Array.from(elements).filter(filterVisibleElements);

  if (activeElement && filteredElements && filteredElements.length) {
    return activeElement === filteredElements[filteredElements.length - 1];
  }

  return false;
};

export const filterVisibleElements = (element) => {
  return element.offsetParent !== null && !element.closest(".hideView");
};

export const firstElementIsFocusable = (target = null, activeElement = document.activeElement) => {
  if (!target) {
    return false;
  }

  const elements = target.querySelectorAll(selectorFocusableElements);
  const filteredElements = elements && Array.from(elements).filter(filterVisibleElements);

  if (activeElement && filteredElements && filteredElements.length) {
    return activeElement === filteredElements[0];
  }

  return false;
};

/**
 *
 * @param {Event} event
 * @param {Element} target
 * @param {function} escCb
 */
export const commonPopupKeyDownEvent = (event = null, target = null, escCb = null) => {
  if (target && event) {
    if (escCb && isEventKey(event, KeyCodes.esc)) {
      // looking for a nested popup
      const nestedPopup = !!(target && target.querySelector(".showView:not(.scrollToWrapper)"));
      if (!nestedPopup) {
        escCb();
      }
    } else if (isEventKey(event, KeyCodes.tab)) {
      if (!event.shiftKey && lastElementIsFocusable(target)) {
        event.preventDefault();
      } else if (event.shiftKey && firstElementIsFocusable(target)) {
        event.preventDefault();
      }
    }
  }
};

/**
 *
 * @param {NodeList} focusableElements
 */
export const focusElement = (focusableElements = []) => {
  if (focusableElements && focusableElements.length) {
    let alreadyFocused = false;
    for (let i = 0; i < focusableElements.length; i++) {
      const element = focusableElements[i];
      if (element.getAttribute("aria-checked") === "true" || element.dataset?.focus === "true") {
        element.focus();
        alreadyFocused = true;
        break;
      }
    }
    if (!alreadyFocused) {
      focusableElements[0].focus();
    }
  }
};

/**
 * @param {string|HTMLElement} target
 */
export const focusElementWithinPopup = (target) => {
  let element = target;

  if (typeof target === "string") {
    element = document?.querySelector(target);
  }

  const closestElements = [".popup-background", ".showView"];

  for (let i = 0; i < closestElements.length; i++) {
    const popupElement = element && element.closest(closestElements[i]);
    if (popupElement) {
      focusElement(popupElement.querySelectorAll(selectorFocusableElements));
      break;
    }
  }
};
