import { combineReducers } from "redux";
import mainTree from "./mainTree";
import settings from "./settings";
import history from "./history.reducer";
import translate from "./manualTranslate/reducer";
import search from "../components/search/search.reducer";
import featuredSearch from "../components/featuredSearch/featuredSearch.reducer";
import system from "./system.reducer";
import dragndrop from "./dragndrop";
import paragraphReducer from "./paragraphs";
import studyCenter from "../components/studyCenter/studyCenter.reducer";
import relevantSearch from "../components/relevantSearch/relevantSearch.reducer";
import dialogContent from "./dialogContent";
import folderTree from "./folderTree/folderTree";
import subscriptions from "../components/subscriptions/subscriptions.reducer";
import audioPlayer from "./audioPlayer";
import contactForm from "./contactForm";
import abbreviations from "./abbreviations";
import cart from "./cart/cart";
import shop from "./shop/shop";
import categoryTree from "./categoryTree/reducer";
import titleTree from "./titleTree/reducer";

export default combineReducers({
  mainTree,
  settings,
  history,
  search,
  featuredSearch,
  system,
  translate,
  dragndrop,
  paragraphReducer,
  relevantSearch,
  studyCenter,
  dialogContent,
  subscriptions,
  audioPlayer,
  contactForm,
  abbreviations,
  cart,
  shop,
  folderTree,
  categoryTree,
  titleTree,
});
