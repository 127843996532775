import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import { usePortal } from "../../hooks";

/**
 * @example
 * <Portal id="modal" class="root-class">
 *   <p>Thinking with portals</p>
 * </Portal>
 */
const Portal = ({ id, children, rootClass = "" }) => {
  const target = usePortal(id, rootClass);
  return createPortal(children, target);
};

Portal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  rootClass: PropTypes.string
};

export default Portal;
