import React, { useRef } from 'react';
import SplitPane from "react-split-pane";

const SplitView = ({ panelOne, panelTwo, horizontal, defaultSize, style, minSize, ...props }) => {

  const panelRef = useRef();
  const isBoth = !!panelOne && !!panelTwo;


  return (
    <SplitPane
      ref={panelRef}
      minSize={minSize}
      maxSize={-minSize}
      pane1Style={!panelTwo ? { width: "100%", height: "100%" } : undefined}
      pane2Style={!panelOne ? { width: "100%", height: "100%" } : undefined}
      resizerStyle={isBoth ? undefined : { display: "none" }}
      defaultSize={isBoth ? defaultSize : "100%"}      
      split={horizontal ? "horizontal" : "vertical"}
      {...props}
    >
      {panelOne || <div />}
      {panelTwo || <div />}
    </SplitPane>
  );
};

export default SplitView;
