import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MENU_STATE, makeMenuState } from "../../utils/MenuItems";
import SearchHeader from "./SearchHeader";
import { useViewMode, useFullScreen, useHotKey } from "../../hooks";
import {
  actionUpdateSettings,
  setPlayerParams,
  toggleShowCart,
} from "../../redux/actions";
import ThemeMenu from "./ThemeMenu";
import ShareMenu from "./ShareMenu";
import LoginHeaderMenu from "./LoginHeaderMenu";
import { IconButton } from "../views";
import { PauseCircleIcon, PlayIconHeader } from "../../assets/PlayerIcons";
import SearchExampleView from "./SearchExampleView";
import { usePopupContext } from "../popup/PopupContext";
import { useNavigationContext } from "../NavigationContext";
import { getBoundingClientRect, VIEW_MODE } from "../../utils/Utils";
import { fullModeDialog } from "../dialog/BaseDialogs";
import { useDialogContext } from "../dialog/DialogContext";
import { analitics } from "../../utils/SystemUtils";
import { Settings } from "../../utils/Settings";
import classnames from "classnames";
import ThreeDotsView from "../views/ThreeDotsView";
import sassVariables from "./Header.variables.module.scss";
import Logotype from "./Logotype";
import { usePanels } from "../../hooks/usePanels";
import { useAudioContext } from "../bookPlayer/AudioContext";
import { useAdvSearch } from "../../hooks/navigation.hooks";
import Badge from "../views/Badge/Badge";
import { dispatchWindowEventPlayAudioInReader } from "../reader/ReaderUtils";
import EgwWebFont from "src/assets/EgwWebFont";
import { Hotkeys } from "src/shared/utils/dom";
import { SearchActions } from "../search/search.actions";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { URLS } from "../../shared/utils/url";
import { HASH_S_PLUS_OPENED } from "../search/search.utils";

import "./Header.scss";

const iconsPositions = {
  menu: { order: 0, hiddenOrder: 0, priority: 13 },
  tree: { order: 1, hiddenOrder: 1, priority: 12 },
  logo: { order: 2, hiddenOrder: 13, priority: 13 },
  searchForm: { order: 3, hiddenOrder: 3, priority: 14 },
  search: { order: 4, hiddenOrder: 4, priority: 7 },
  player: { order: 5, hiddenOrder: 5, priority: 10 },
  share: { order: 6, hiddenOrder: 6, priority: 9 },
  searchPlus: { order: 7, hiddenOrder: 7, priority: 8 },
  theme: { order: 8, hiddenOrder: 8, priority: 4 },
  oldEgw: { order: 9, hiddenOrder: 9, priority: 5 },
  filter: { order: 11, hiddenOrder: 11, priority: 3 },
  question: { order: 12, hiddenOrder: 12, priority: 2 },
  home: { order: 13, hiddenOrder: 14, priority: 1 },
};

const Header = () => {
  const dispatch = useDispatch();
  const [rootEl, setRootEl] = useState(null);
  const { closeAdvSearch, toggleAdvSearch } = useAdvSearch();
  const { pathname, isPlayer, isReader } = useNavigationContext();
  const { toggleChapterPlaying, chapterId, isPlaying } = useAudioContext();
  const { setIsRightPanelVisible, setMenuState } = usePanels();
  const menuState = useSelector((state) => state.settings.menuState);
  const isShowRightPanel = useSelector((state) => state.settings.isShowRightPanel);
  const isShowHeaderSearch = useSelector((state) => state.search.isShowHeaderSearch);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const showCartPanel = useSelector((state) => state.settings.showCartPanel);
  const cart = useSelector((state) => state.cart);
  const { t } = useTranslation();
  const { isMobile, isTablet, isMobileOrTablet, textMode } = useViewMode();
  const remToPx = useRemToPx();
  const { fullscreen } = useFullScreen();
  const isDesktop = !isMobileOrTablet;
  const { showPopup, hidePopup } = usePopupContext();
  const { showConfirm } = useDialogContext();
  const searchIconActive = pathname.includes(URLS.advSearch);
  const isSmallPlayerActive = useSelector((state) => state.audioPlayer.isSmallPlayerActive);
  const isTocVisible = useSelector((state) => state.audioPlayer.isTocVisible);
  const history = useHistory();

  /* Children visibility flags. */
  const isMobileSearchFormShown = isMobile && isShowHeaderSearch;
  const isLoginBtnShown = isDesktop;
  const isLogoLinksShown = isDesktop;
  // Client's request: Header play btn in the Reader should toggle the audio as well.
  const isPlayerBtnShown = (!isShowHeaderSearch && isMobile && isReader) || chapterId;
  /* ======= */

  /* Children widths with the zoom coefficient and with the view mode conditions. */
  const childrenGapFinal = isMobile
    ? remToPx(sassVariables.headerChildrenGapSmall)
    : remToPx(sassVariables.headerChildrenGapDefault);

  const iconMaxWidthFinal = textMode
    ? remToPx(sassVariables.headerIconMaxWidthTextMode)
    : remToPx(
        isMobile ? sassVariables.headerIconMaxWidthSmall : sassVariables.headerIconMaxWidthDefault,
      );
  const iconMaxWidthFinalWithMargin = iconMaxWidthFinal + childrenGapFinal;

  const logoMinWidthAndMargin = isLogoLinksShown
    ? remToPx(sassVariables.headerLogoMaxWidthBig) + childrenGapFinal
    : remToPx(sassVariables.headerLogoMaxWidthIcon) + childrenGapFinal;

  let searchMinWidthAndMargin = textMode
    ? remToPx(sassVariables.headerSearchMinWidthTextMode) + childrenGapFinal
    : isMobile
    ? remToPx(sassVariables.headerSearchMinWidthMobile) + childrenGapFinal
    : remToPx(sassVariables.headerSearchMinWidthDefault) + childrenGapFinal;

  const loginBtnWidthAndMargin = textMode
    ? iconMaxWidthFinalWithMargin
    : remToPx(sassVariables.headerLoginBtnMaxWidthDefault) + childrenGapFinal;
  /* =========== */

  const threeDotsAvailableWidth =
    window.innerWidth -
    remToPx(sassVariables.headerPaddingSide) * 2 -
    (isLoginBtnShown ? loginBtnWidthAndMargin : 0);

  let searchWidth;
  if (isMobileSearchFormShown) {
    searchWidth = Math.max(
      threeDotsAvailableWidth - iconMaxWidthFinalWithMargin - logoMinWidthAndMargin - 1,
      searchMinWidthAndMargin,
    ); // Stretch mobile search form to leave logo and form shown only.
    searchWidth = Math.min(searchWidth, threeDotsAvailableWidth - iconMaxWidthFinalWithMargin - 1); // Fix search form min width is tool big on small screens.
  } else {
    searchWidth = searchMinWidthAndMargin;
  }

  useHotKey(Hotkeys.toggleTree.keyCode, () => {
    onMenuControl(MENU_STATE.TREE);
    return true;
  });

  const onMenuControl = (event) => {
    analitics("user_event", {
      event: "onMenuControl",
    });
    setMenuState(makeMenuState(event, menuState));
  };

  const onToggleSearch = () => {
    setIsRightPanelVisible(!isShowRightPanel);
  };

  const handleShowSearchField = useCallback(
    () => {
      if (isMobile) {
        if (searchQuery) {
          if (!isShowHeaderSearch && isReader) {
            const newLocation = history.location.pathname
              + history.location.search
              + "#" + HASH_S_PLUS_OPENED;
            history.push(newLocation);
          }
          dispatch(
            actionUpdateSettings({
              [Settings.isShowRightPanel.id]: true,
            }),
          );
        }
        dispatch(SearchActions.updateShowHeaderSearch(!isShowHeaderSearch));
      }
    },
    [isMobile, isShowHeaderSearch],
  );

  const onLogoClick = (event) => {
    if (isMobile) {
      if (event && isShowHeaderSearch) {
        event.preventDefault();
      }
      dispatch(SearchActions.updateShowHeaderSearch(false));

      if (isReader) {
        // clear hash value for reader
        const newLocation = history.location.pathname + history.location.search;
        history.replace(newLocation);
      }  
      const newSettings = {
        [Settings.menuState.id]: MENU_STATE.COLLAPSE,
      };
      if (isShowRightPanel) {
        newSettings[Settings.isShowRightPanel.id] = false;
      }
     
  
      dispatch(actionUpdateSettings(newSettings));
    }
  };

  const onPlayerButtonClick = () => {
    const isSearchPanelCoversContent = isMobile && isShowRightPanel;
    if (isPlayer) {
      toggleChapterPlaying();
      return;
    }

    if (isReader && !isSearchPanelCoversContent) {
      dispatchWindowEventPlayAudioInReader();
      dispatch(
        setPlayerParams({
          isSmallPlayerActive: !isPlaying,
        }),
      );
      return;
    }

    dispatch(
      setPlayerParams({
        isSmallPlayerActive: !isSmallPlayerActive,
      }),
    );
  };
  const onTreeMenuClick = () => {
    if (textMode) {
      fullModeDialog(showConfirm, () => {
        if (isMobile) {
          dispatch(
            actionUpdateSettings({
              [Settings.viewMode.id]: VIEW_MODE.MOBILE,
              [Settings.textMode.id]: false,
            }),
          );
          dispatch(setPlayerParams({ isTocVisible: false }));
        } else {
          dispatch(
            actionUpdateSettings({
              [Settings.viewMode.id]: VIEW_MODE.DESKTOP,
              [Settings.textMode.id]: false,
            }),
          );
        }
      });
    } else {
      dispatch(setPlayerParams({ isTocVisible: false }));
      onMenuControl(MENU_STATE.TREE);
    }
  };

  const onMenuClick = () => {
    dispatch(
      setPlayerParams({
        isTocVisible: false,
      }),
    );
    onMenuControl(MENU_STATE.MENU);
  };



  const handleQuestionClick = ({ target }) => {
    const targetRect = getBoundingClientRect(target);

    // 3.8 - header heights.
    const bottom = remToPx(3.8);

    closeAdvSearch();
    onMenuControl(MENU_STATE.COLLAPSE);

    setTimeout(() => {
      showPopup(
        {
          left: isMobile ? 0 : targetRect.left,
          right: isMobile ? window.innerWidth : targetRect.right,
          top: bottom,
          bottom: bottom,
        },
        <SearchExampleView
          onClose={() => {
            hidePopup();
          }}
        />,
        {
          popupProps: {
            className: "search-example-view__popup",
          },
          fitSizes: true,
        },
      );
    }, 1);
  };

  const logoElement = (
    <Logotype
      onClick={onLogoClick}
      logoLinksShown={isLogoLinksShown}
      logoWritingsLabelShown={(isMobile && !isShowHeaderSearch) || isDesktop}
    />
  );

  const playerBtnElement = (
    <IconButton
      bordered
      icon={isPlaying ? PauseCircleIcon : PlayIconHeader}
      active={isSmallPlayerActive}
      title={t("player")}
      onClick={onPlayerButtonClick}
    />
  );

  const treeBtnElement = (
    <IconButton
      title={t("tree")}
      bordered
      icon={menuState === MENU_STATE.TREE ? EgwWebFont["folder-open"] : EgwWebFont["folder-close"]}
      className="menu-icon"
      active={!textMode && menuState === MENU_STATE.TREE}
      onClick={onTreeMenuClick}
    />
  );

  const menuBtnElement = (
    <IconButton
      title={t("menu")}
      icon={EgwWebFont.menu}
      bordered
      className="menu-icon"
      active={menuState === MENU_STATE.MENU}
      onClick={onMenuClick}
    />
  );

  const searchFormElement = <SearchHeader
    headerHeight={rootEl?.offsetHeight}
    handleShowForm={handleShowSearchField}
  />;

  const searchPlusBtnElement = (
    <IconButton
      bordered
      title={t("search+")}
      className="action-icon"
      icon={EgwWebFont["search-header"]}
      ariaLabel={t("searchPlus")}
      active={isShowRightPanel}
      onClick={onToggleSearch}
      showIcon={true}
    />
  );

  const searchBtnElement = (
    <IconButton
      bordered
      increasedTapZone
      className="action-icon"
      icon={EgwWebFont.search}
      title={t("search")}
      ariaLabel={t("search")}
      active={isShowHeaderSearch}
      onClick={handleShowSearchField}
    />
  );

  const egwOldBtnElement = (
    <a
      href="https://egwwritings.org/"
      target="_blank"
      rel="noopener noreferrer"
      className="header-egw-old-container">
      <IconButton title={t("oldEGWsite")} icon={EgwWebFont.old} noFocusable />
    </a>
  );

  const cartElement = (
    <Badge badgeContent={cart.cartIds.size} className="header-badge">
      <IconButton
        title={t("cart")}
        icon={EgwWebFont.cart}
        active={showCartPanel}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(toggleShowCart());
        }}
      />
    </Badge>
  );

  const filterBtnElement = (
    <IconButton
      bordered
      title={t("filter")}
      icon={EgwWebFont.filter}
      active={searchIconActive}
      onClick={() => {
        toggleAdvSearch();
      }}
    />
  );

  const questionBtnElement = (
    <IconButton
      bordered
      title={t("help")}
      icon={EgwWebFont.question}
      onClick={handleQuestionClick}
    />
  );

  const homeBtnElement = (
    <IconButton
      bordered
      title={t("home")}
      icon={EgwWebFont.home}
      onClick={() => {
        onLogoClick();
        history.push("/");
      }}
    />
  );

  const threeDotsMenuIcons = [
    {
      render: () => menuBtnElement,
      isShown: true,
      width: iconMaxWidthFinalWithMargin,
      key: "MenuIcon",
      ...iconsPositions.menu,
      priority: isMobileSearchFormShown ? 0 : iconsPositions.menu.priority,
    },
    {
      render: () => treeBtnElement,
      isShown: isDesktop || !textMode,
      width: iconMaxWidthFinalWithMargin,
      key: "FolderIcon",
      ...iconsPositions.tree,
      priority: isMobileSearchFormShown ? 0 : iconsPositions.tree.priority,
    },
    {
      render: () => logoElement,
      isShown: true,
      width: logoMinWidthAndMargin,
      key: "LogoLink",
      ...iconsPositions.logo,
    },
    {
      render: () => null,
      threeDotsRender: () => homeBtnElement,
      isShown: true,
      width: logoMinWidthAndMargin,
      key: "homeBtnElement",
      ...iconsPositions.home,
    },
    {
      render: () => searchFormElement,
      isShown: isMobileSearchFormShown || isTablet || isDesktop,
      width: searchWidth,
      key: "SearchField",
      ...iconsPositions.searchForm,
    },
    {
      render: () => playerBtnElement,
      isShown: isPlayerBtnShown,
      width: iconMaxWidthFinalWithMargin,
      key: "PlayIconHeader",
      ...iconsPositions.player,
    },
    {
      render: () => <ShareMenu />,
      isShown: isDesktop,
      width: iconMaxWidthFinalWithMargin,
      key: "ShareMenu",
      ...iconsPositions.share,
    },
    {
      render: () => searchPlusBtnElement,
      isShown: isTablet,
      width: iconMaxWidthFinalWithMargin,
      key: "SearchHeaderIcon",
      ...iconsPositions.searchPlus,
    },
    {
      render: () => searchBtnElement,
      isShown: isMobile && !isShowHeaderSearch,
      width: iconMaxWidthFinalWithMargin,
      key: "SearchFormIcon",
      ...iconsPositions.search,
    },
    {
      render: () =>
        isMobileOrTablet && (cart.cartIds.size || showCartPanel) ? cartElement : <ThemeMenu />,
      isShown: isMobile ? !isPlayerBtnShown : true,
      width: iconMaxWidthFinalWithMargin,
      key: "ThemeMenu",
      ...iconsPositions.theme,
    },
    {
      /* Shows S+ button instead of the old EGW button if fullscreen mode is enabled. */
      render: () =>
        cart.cartIds.size || showCartPanel
          ? cartElement
          : fullscreen
          ? searchPlusBtnElement
          : egwOldBtnElement,
      isShown: fullscreen ? isDesktop : isDesktop && !textMode,
      /* ================== */
      width: iconMaxWidthFinalWithMargin,
      key: "EGWOldIcon",
      ...iconsPositions.oldEgw,
    },
    {
      render: () => filterBtnElement,
      isShown: isMobileSearchFormShown,
      width: iconMaxWidthFinalWithMargin,
      key: "FilterIcon",
      ...iconsPositions.filter,
    },
    {
      render: () => questionBtnElement,
      isShown: isMobileSearchFormShown,
      width: iconMaxWidthFinalWithMargin,
      key: "QuestionIcon",
      ...iconsPositions.question,
    },
  ];

  return (
    <header
      ref={setRootEl}
      className={classnames("header-main", {
        "mobile-search-form-visible": isShowHeaderSearch,
      })}
      onClick={() => {
        if (isTocVisible) {
          dispatch(setPlayerParams({ isTocVisible: false }));
        }
        if (showCartPanel) {
          dispatch(toggleShowCart());
        }
      }}>
      <ThreeDotsView
        initialWidth={iconMaxWidthFinalWithMargin}
        threeDotsClassName="header-three-dots-button"
        isCollapsed={false}
        icons={threeDotsMenuIcons}
        parentWidth={threeDotsAvailableWidth}
        render={(
          visibleIcons,
          hiddenIcons,
          { renderButton, setShow, renderIcons, hiddenClassName, popupRef },
        ) => {
          const isThreeDotsAppropriate = hiddenIcons.length && (hiddenIcons.length > 1 || textMode);
          return (
            <React.Fragment>
              {renderIcons(visibleIcons)}
              {isThreeDotsAppropriate ? (
                <div
                  ref={popupRef}
                  className={classnames("header-three-dots-popup", hiddenClassName)}>
                  {hiddenIcons.map(({ threeDotsRender, render, key }) => {
                    const renderFn = threeDotsRender || render;

                    // doesn't show logo icon here because we have home icon
                    if (key === "LogoLink") {
                      return null;
                    }

                    return (
                      <div
                        key={key}
                        onClick={() => {
                          if (key !== "ThemeMenu") {
                            setShow(false);
                          }
                        }}>
                        {renderFn(key)}
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {isThreeDotsAppropriate ? renderButton() : renderIcons(hiddenIcons)}
            </React.Fragment>
          );
        }}
      />
      {isLoginBtnShown ? <LoginHeaderMenu /> : null}
    </header>
  );
};

Header.displayName = "Header";

export default Header;
