import React from "react";
import SplitPane from "react-split-pane";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { actionUpdateResize } from "../../../redux/params.actions";
import { useResizeDimensions } from "../../../hooks";

const ResizeWrap = ({ children, weight = [1, 1], defWeight, vertical }) => {
  const dispatch = useDispatch();
  const { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT } = useResizeDimensions();
  const [min, max] = weight;
  const minPanelSize = vertical ? MIN_PANEL_WIDTH : MIN_PANEL_HEIGHT;
  const defSize = defWeight ||  parseInt((min / (min + max)) * 100);

  let pane1Styles = undefined;
  let pane2Styles = undefined;

  if (children.length === 2) {
    if (!vertical) {
      pane2Styles = {
        height: defSize + "%"
      };
    } else {
      pane1Styles = {
        height: 100 + "%"
      };
      pane2Styles = {
        height: 100 + "%"
      };
    }
  }

  return (
    <SplitPane
      onDragStarted={() => dispatch(actionUpdateResize(true))}
      onDragFinished={() => dispatch(actionUpdateResize(false))}
      split={vertical ? "vertical" : "horizontal"}
      defaultSize={defSize + "%"}
      pane1Style={pane1Styles}
      pane2Style={pane2Styles}
      minSize={min * minPanelSize}
      maxSize={max * -1 * minPanelSize}>
      {children}
    </SplitPane>
  );
};

ResizeWrap.propTypes = {
  children: PropTypes.node,
  weight: PropTypes.array,
  vertical: PropTypes.bool,
  defWeight: PropTypes.number
};

export default ResizeWrap;
