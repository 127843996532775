import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconButton } from "../views";
import { actionUpdateSetting} from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { TreeMenuMode } from "src/utils/TreeUtils";
import EgwWebIcons from "../../assets/EgwWebFont";
import { treesActions } from "../../redux/trees/actions";

import "./SearchForBook.scss";

const treeMenuModeToBtnConfig = {
  [TreeMenuMode.FOLDERS]: {
    icon: EgwWebIcons.planet,
    mode: TreeMenuMode.CATEGORIES,
  },
  [TreeMenuMode.CATEGORIES]: {
    icon: EgwWebIcons.tree,
    mode: TreeMenuMode.TITLES,
  },
  [TreeMenuMode.TITLES]: {
    icon: EgwWebIcons.titles,
    mode: TreeMenuMode.FOLDERS,
  },
};

export const ToggleTreeModeButton = () => {
  const dispatch = useDispatch();
  const treeMenuMode = useSelector((state) => state.settings.treeMenuMode);

  const btnConfiguration = treeMenuModeToBtnConfig[treeMenuMode];

  return <IconButton
    className="tree-mode-icon"
    icon={btnConfiguration.icon}
    onClick={(e) => {
      e.stopPropagation();

      dispatch(actionUpdateSetting(Settings.treeMenuMode.id, btnConfiguration.mode));
      dispatch(treesActions.updateChecked([]));
    }}
  />;
};
