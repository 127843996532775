import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Scroll } from "../views";
import { whichEventKey } from "../../utils/Utils";
import { upDownNavigation } from "../../utils/AccessibilityUtils";
import { removeBR } from "../../shared/utils/content";
import { DATA_ATTR_READER_ELEMENT_ID } from "../reader/ReaderUtils";

const BreadCrumbMenu = ({
  menuData,
  menuItems,
  realType,
  current,
  onItemClick,
  vertical,
  show,
  onClickHead
}) => {
  const { t } = useTranslation();
  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      const { view } = scrollRef.current;
      const elements = view.querySelectorAll('[role="option"]');
      const element =
        (elements &&
          Array.from(elements).find(
            (el) => el.getAttribute(DATA_ATTR_READER_ELEMENT_ID) === "" + current)
        ) ||
        elements[0];

      if (element) {
        if (element.offsetTop > view.scrollTop) {
          view.scrollTop = element.offsetTop;
        }
        element.focus();
      }

      upDownNavigation(elements);
    }
  }, [current, menuItems, show]);

  const onKeyDownHandle = (event, subItem) => {
    event.preventDefault();
    const { enter } = whichEventKey(event);

    if (enter) {
      onItemClick(event, subItem);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={classNames("dropdown-menu-area", {
        "dropdown-menu-padding": vertical
      })}
      style={vertical ? { left: "100%", top: -10 } : undefined}>
      <div
        onClick={() => {
          onClickHead(menuData);
        }}
        className="bc-head"
      />
      <Scroll
        role="listbox"
        ref={scrollRef}
        className="dropdown-menu"
        autoHeight
        autoHide={false}
        autoHeightMax={"40vh"}>
        {menuItems.map((subItem, index) => {
          return (
            <p
              role="option"
              tabIndex={0}
              onKeyDown={(event) => onKeyDownHandle(event, subItem)}
              onClick={(event) => {
                event.stopPropagation();
                onItemClick(subItem);
              }}
              data-id={subItem.id}
              aria-selected={subItem.id === current ? "true" : "false"}
              className={classNames(
                realType,
                subItem.id === current ? "dropdown-text-selected" : undefined
              )}
              key={index}>
              {subItem.className ? subItem.label : removeBR(t(subItem.label))}
            </p>
          );
        })}
      </Scroll>
    </div>
  );
};

BreadCrumbMenu.displayName = "BreadCrumbMenu";

BreadCrumbMenu.propTypes = {
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuItems: PropTypes.array,
  menuData: PropTypes.object.isRequired,
  onItemClick: PropTypes.func,
  vertical: PropTypes.bool,
  realType: PropTypes.string,
  show: PropTypes.bool,
  onClickHead: PropTypes.func
};

export default BreadCrumbMenu;
