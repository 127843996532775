export const DefaultFontSize = 18;

export const COOKIE_KEY_THEME = "theme";
export const COOKIE_KEY_VIEWMODE = "viewmode";
export const COOKIE_KEY_FULLSCREEN = "fullScreen";
export const COOKIE_KEY_READER_FONT_SIZE = "rfs";
export const COOKIE_KEY_SEARCH_FONT_SIZE = "sfs";

export const Themes = {
  dy: {
    id: "dy",
    label: "dayMode",
    gifLoader: "loader-dy.gif"
  },
  dw: {
    id: "dw",
    label: "dawnMode",
    gifLoader: "loader-dw.gif"
  },
  dk: {
    id: "dk",
    label: "duskMode",
    dark: true,
    gifLoader: "loader-dk.gif"
  },
  dm: {
    id: "dm",
    label: "darkMode",
    dark: true,
    gifLoader: "loader-dm.gif"
  },
  adm: {
    id: "adm",
    label: "accessabilityDarkMode",
    dark: true,
    accessibility: true,
    gifLoader: "loader-adm.gif"
  },
  ady: {
    id: "ady",
    label: "accessabilityDayMode",
    accessibility: true,
    gifLoader: "loader-dy.gif"
  },
};

export const DefaultThemeId = Themes.dy.id;
export const TextappDefaultThemeId = Themes.dw.id;
export const EasyModeDefaultThemeId = Themes.dm.id;

export const getIsAccessibilityTheme = (theme) => {
  return Themes[theme] && Themes[theme].accessibility;
};

export const ReaderFontSizes = {
  min: 8,
  max: 72,
  factor: 2,
  default: 20,
  blindDefault: 36
};

export const getTheme = (themeMode) => {
  let theme = Themes[themeMode];
  if (theme) {
    return theme;
  }
  return Themes[DefaultThemeId];
};
