import React from "react";
import PropTypes from "prop-types";
import {
  commonPopupKeyDownEvent,
  focusElement,
  selectorFocusableElements
} from "../../../utils/AccessibilityUtils";

class CommonPopup extends React.Component {

  constructor({children}) {
    super();
    this.popupRef = React.createRef();
    this.defaultActiveElementRef = React.createRef();
    this.state = {
      children,
      isOpen: false,
      position: {},
      areaPosition: {},
      toRight: true,
      onClose: () => { },
      closeOnBack: true
    };
    this.focusOnOpen = this.focusOnOpen.bind(this);
    this.commonPopupKeyDown = this.commonPopupKeyDown.bind(this);
  }

  handleScroll = () => {
    this.setState({
      ...this.state,
      isOpen: false,
    });
  };

  componentDidMount() {
    document.addEventListener("blur", this.hide);

    if (this.popupRef.current) {
      this.setState({
        areaPosition: this.popupRef.current.getBoundingClientRect()
      });
    }
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("blur", this.hide);
    document.removeEventListener("scroll", this.handleScroll);
  }

  calculateOptimalPosition = (newState, bounds) => {
    const { width: areaWidth, height: areaHeight } = this.state.areaPosition;
    const { innerWidth, innerHeight } = window;
    const { toRight } = newState;
    const topDelta = toRight ? 10 : 5;
    let position = {
      top: bounds.bottom + topDelta,
      left: bounds.left
    };

    const sidesDelta = 32;
    const halfOfSidesDelta = sidesDelta / 2;
    const areaWithHalfOfSidesDelta = areaWidth + halfOfSidesDelta;
    let defLeft = (bounds.right - areaWithHalfOfSidesDelta) < 0 ? bounds.left : bounds.right - areaWithHalfOfSidesDelta;

    if (toRight) {
      position.left = defLeft;
    } else {
      let nearPosition = bounds.left;

      if (nearPosition + areaWithHalfOfSidesDelta > innerWidth - halfOfSidesDelta) {
        while (nearPosition + areaWithHalfOfSidesDelta > innerWidth - halfOfSidesDelta) {
          if (nearPosition <= sidesDelta) {
            nearPosition = halfOfSidesDelta;
            break;
          }

          nearPosition -= halfOfSidesDelta;
        }

        position.left = nearPosition;
      }
    }

    if (bounds.bottom + topDelta + areaHeight > innerHeight) {
      position.top = bounds.top - topDelta - areaHeight;
    }

    return position;
  }

  show = (newState, bounds) => {
    const position = this.calculateOptimalPosition(newState, bounds);
    this.setState({ isOpen: true, position, ...newState }, () => this.focusOnOpen());
  }

  hide = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false }, () => {
        setTimeout(() => {
          if (this.defaultActiveElementRef.current) {
            this.defaultActiveElementRef.current.focus();
            this.defaultActiveElementRef.current = null;
          }
        }, 0);
      });
    }
  }

  focusOnOpen() {
    setTimeout(() => {
      const currentActiveElement = document.activeElement;
      if (currentActiveElement && !currentActiveElement.closest(".common-popup") && !this.defaultActiveElementRef.current) {
        this.defaultActiveElementRef.current = currentActiveElement;
      }

      focusElement(this.popupRef.current?.querySelectorAll(selectorFocusableElements));
    }, 150);
  }

  commonPopupKeyDown(event) {
    commonPopupKeyDownEvent(
      event,
      event.target.closest(".common-popup"),
      () => this.hide()
    );
  }

  render() {
    const { isOpen, position } = this.state;
    const visible = isOpen ? "showView" : "hideView";
    return (
      <div
        ref={this.popupRef}
        className={`common-popup ${visible}`}
        style={{ ...position }}
        onKeyDown={this.commonPopupKeyDown}
      >
        { this.state.children }
        <div
          className={"dropDownMenuBack" + (isOpen ? " show" : " hide")}
          onClick={() => this.hide()} />
      </div>
    );
  }
}

CommonPopup.propTypes = {
  children: PropTypes.node,
};

export default CommonPopup;
