import { actionUpdateSetting } from "src/redux/actions";
import { getParagraph } from "src/shared/utils/content";
import { getTokens } from "src/shared/utils/systemUtils";
import { Settings } from "src/utils/Settings";
import { TreeItemStateChecked, getTreeItemStateChecked } from "src/utils/TreeUtils";
import { ItemTypes } from "./studyCenter.constants";

export const defEditorHighLightColor = {
  id: null,
  index: "defEditorHighLightColor",
  shared: false,
  name: "Yellow",
  color: "#FFF001",
};

export const getUserRole = () => {
  const tokens = getTokens();
  if (tokens.token) {
    const tokenPayload = atob(tokens.token.split(".")[1]);
    const tokenDetails = JSON.parse(tokenPayload);
    return Array.isArray(tokenDetails.role) ? tokenDetails.role : [tokenDetails.role];
  }
  return undefined;
};

export const isUserEditor = (roles) => {
  const userRoles = roles ? roles : getUserRole();
  if (!userRoles) {
    return false;
  }

  return (
    userRoles.includes(UserRoles.studyCenterEditor) ||
    userRoles.includes(UserRoles.StudyCenterEditorAdmin)
  );
};

export const UserRoles = {
  studyCenterEditor: "StudyCenterEditor",
  StudyCenterEditorAdmin: "StudyCenterEditorAdmin",
};

export const getTopicOfEditorEntry = (editorTempEntry) => {
  const topicParts = [];

  if (editorTempEntry?.book_code) {
    topicParts.push(editorTempEntry.book_code);
  }

  if (topicParts.length !== 0 && editorTempEntry?.note_topic) {
    topicParts.push(editorTempEntry.note_topic.trim());
  }

  return topicParts.length ? topicParts.join(" / ").toLowerCase() : null;
};

export const getTitleOfEditorEntry = (selectData, paragraphs) => {
  if (!selectData?.text) {
    return "";
  }

  const start = getParagraph(selectData.startId, paragraphs);
  if (selectData.startId === selectData.endId) {
    return start.refcode_short;
  }

  const end = getParagraph(selectData.endId, paragraphs);
  return start.refcode_short + " - " + end.refcode_short;
};

export const clearEditorTempAction = () =>
  actionUpdateSetting(Settings.editorTempEntry.id, {
    hasNewData: true,
    book_code: null,
    selectData: null,
    note_topic: "",
    note_subject: [],
    note_keywords: [],
  });

export const addTempEntry = (topics, entries, editorTempEntry, isUserEditor) => {
  const listTopics = [...topics];
  const treeEntries = [...entries];

  if (isUserEditor && editorTempEntry?.selectData) {
    treeEntries.push({
      order: -1,
      id: Settings.editorTempEntry.id,
      type: ItemTypes.editorTemp,
      color_id: editorTempEntry.colorId,
      text: editorTempEntry.selectData.text,
      title: editorTempEntry?.title || editorTempEntry?.book_code,
      start_para: editorTempEntry.selectData.startId,
      end_para: editorTempEntry.selectData.endId,
      topic: getTopicOfEditorEntry(editorTempEntry),
    });

    const tempBookCodeTopic = editorTempEntry.book_code.toLowerCase();
    const hasBookCodeTopic = listTopics.findIndex((topic) => topic.id === tempBookCodeTopic);
    if (hasBookCodeTopic === -1) {
      listTopics.push({
        id: tempBookCodeTopic,
        type: ItemTypes.folder,
        parent: null,
        topic: null,
        label: tempBookCodeTopic,
        title: tempBookCodeTopic,
        children: [],
        child_count: 0,
      });
    }

    if (editorTempEntry?.note_topic) {
      const noteTitleLower = editorTempEntry.note_topic.toLowerCase().trim();
      const tempNoteTopic = `${tempBookCodeTopic} / ${noteTitleLower}`;
      const hasNoteTopic = listTopics.findIndex((topic) => topic.id === tempNoteTopic);
      if (hasNoteTopic === -1) {
        const noteTopic = {
          id: tempNoteTopic,
          type: ItemTypes.folder,
          parent: tempBookCodeTopic,
          topic: tempBookCodeTopic,
          label: noteTitleLower,
          title: noteTitleLower,
          children: [],
          child_count: 1,
        };

        listTopics.push(noteTopic);
        const bookCodeTopic = listTopics.findIndex((topic) => topic.id === tempBookCodeTopic);
        listTopics[bookCodeTopic].children.push(noteTopic);
      }
    }

    const sortedTopic = listTopics.sort((left, right) => {
      if (left.id > right.id) {
        return 1;
      } else if (left.id < right.id) {
        return -1;
      }
      return 0;
    });

    return [sortedTopic, treeEntries];
  }

  return [listTopics, treeEntries];
};

const isInChecked = (listTopics, item, checked) => {
  if (checked?.length) {
    if (getTreeItemStateChecked(item, checked) === ItemTypes.folder) {
      return item.checkType === TreeItemStateChecked.all;
    }

    const parent = listTopics.find((topic) => topic.id === item.topic);

    if (parent) {
      return getTreeItemStateChecked(parent, checked) === TreeItemStateChecked.all;
    }

    return false;
  }

  return true;
};

const isValidTopicForFilter = (listTopics, item, searchText, checked) =>
  item.title.toLowerCase().includes(searchText) && isInChecked(listTopics, item, checked);

export const filterSCItem = (listTopics, treeEntries, searchText, validEntries, checked) => {
  if (searchText) {
    const searchTextLow = searchText.toLowerCase();

    const validTopics = listTopics
      .filter((item) => isValidTopicForFilter(listTopics, item, searchTextLow, checked))
      .map((item) => item.id);

    const filteredTopics = listTopics.filter((item) => {
      const isValidTopic = validTopics.includes(item.id);
      const isValidParent = item.topic ? validTopics.includes(item.topic) : false;
      if (isValidTopic || isValidParent) {
        return true;
      }

      const childrenTopic = listTopics.filter((topic) => topic.topic === item.id);
      const isSomeChildTopicValid = childrenTopic.some((topic) => validTopics.includes(topic.id));

      if (isSomeChildTopicValid) {
        return true;
      }

      const childrenEntry = validEntries.filter(
        (entry) =>
          entry.topic === item.id || childrenTopic.some((topic) => entry.topic === topic.id),
      );
      return !!childrenEntry.length;
    });

    const filteredEntries = treeEntries.filter((item) => {
      const isValidEntry = validEntries.findIndex((entry) => entry.id === item.id);
      return isValidEntry !== -1;
    });

    return [filteredTopics, filteredEntries];
  }

  return [listTopics, treeEntries];
};

export const topicHasChildren = (topicId, topics) => {
  const topic = topics.find((topic) => topic.id === topicId);
  return topic?.child_count && topic.child_count !== 0;
};
