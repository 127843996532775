import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Button, Checkbox, Select } from "../../views";
import { getBoundingClientRect, generateParasHtml, scOptions } from "../../../utils/Utils";
import { useShareContext } from "../../shareHolder/ShareHolder";
import { useViewMode } from "../../../hooks";
import { actionAddMessage, actionCopy } from "../../../redux/actions";
import { READER_ACTIONS } from "../../reader/ReaderUtils";
import { makeNavigationUrl } from "../../../utils/URLUtils";
import { TOAST_TYPE } from "../../popup/Toaster";
import { useDialogContext } from "../../dialog/DialogContext";
import IconView from "src/shared/components/IconView";
import EgwWebFont from "src/assets/EgwWebFont";

const ShareTab = ({
  shareTitle,
  shareUrl,
  mobileMode,
  chapterContent,
  selection,
  onToggle,
  checks,
  range,
  onRangeChange,
  hasAdvancedMode = false, // Advanced mode is in the regular Reader, in the epub - no.
}) => {
  const parent = useRef();
  const { t } = useTranslation();
  const { isMobile } = useViewMode();
  const { showShare } = useShareContext();
  const { hideDialog } = useDialogContext();
  const dispatch = useDispatch();
  const input = useRef();
  const [socialShare, setSocialShare] = useState({
    plus: false,
    selection: false,
    chapter: false,
    range: false,
    url: "",
    title: shareTitle,
    text: "",
  });
  const [isCopySelectionAllowed, setIsCopySelectionAllowed] = useState(!!selection);
  let from, to, refs, showAdvanced, isMobileMode;

  if (hasAdvancedMode) {
    from = range.from;
    to = range.to;
    refs = checks.refs;
    showAdvanced = checks.showAdvanced;
  }

  if (mobileMode === undefined) {
    isMobileMode = isMobile;
  } else {
    isMobileMode = mobileMode;
  }

  useEffect(() => {
    if (!hasAdvancedMode && input.current) {
      input.current.select();
    }
  }, [hasAdvancedMode]);

  useEffect(() => {
    const selectionChangeListener = () => {
      if (!selection) {
        setIsCopySelectionAllowed(false);
      }
    };

    document.addEventListener("selectionchange", selectionChangeListener);

    return () => {
      document.removeEventListener("selectionchange", selectionChangeListener);
    };
  }, []);

  const handleCopyToClipboard = () => {
    dispatch(
      actionCopy(shareUrl, {
        successMessage: t("linkCopied"),
        errorMessage: t("linkNotCopied"),
      }),
    );
  };

  const handlePlusClick = (e) => {
    const plus = e.target.closest(".sct-link-plus-container");
    const dialog =
      e.target.closest(".dialog-container") ||
      e.target.closest(".search-dialog-container") ||
      e.target.closest(".pub-dialog-container");

    if (dialog && plus) {
      const bounds = getBoundingClientRect(plus);
      const parentBounds = getBoundingClientRect(dialog);

      setSocialShare({ ...socialShare, plus: true });

      showShare({
        shareData: {
          url: shareUrl,
          title: socialShare.title,
        },
        bounds,
        parentBounds,
        onShareButtonClick: () => {
          hideDialog();
        },
      });
    }
  };

  const handleShareAction = (shareOption) => {
    let shareId = -1,
      popup,
      shareRange;
    switch (shareOption) {
      case scOptions.SELECTION:
        if (selection) {
          shareId = selection.getAttribute("data-id");
          shareRange = { container: selection };
          popup = "selection";
        } else {
          dispatch(actionAddMessage(t("No text selected"), TOAST_TYPE.error));
        }
        break;

      case scOptions.CHAPTER:
        shareId = chapterContent[0].para_id;
        shareRange = generateParasHtml(chapterContent);
        popup = "chapter";
        break;

      case scOptions.RANGE:
        shareId = chapterContent[from].para_id;
        shareRange = generateParasHtml(chapterContent.slice(from, to + 1));
        popup = "range";
        break;

      default:
        break;
    }
    if (shareId === -1) {
      return false;
    }
    const { container } = shareRange;
    const text = container ? container.textContent : "";

    socialShare[popup] = !socialShare[popup];
    socialShare.url = makeNavigationUrl(shareId);
    socialShare.text = text;
    socialShare.refcode = refs ? chapterContent[from]?.refcode_short : undefined;
    setSocialShare({ ...socialShare });
    return true;
  };

  const renderSelect = (isFrom) => {
    const label = isFrom ? t("from") : t("to");
    const range = isFrom ? chapterContent[from] : chapterContent[to];

    return (
      <Select
        parentNode={parent}
        label={label + ":"}
        containerStyle="select-margin-top select-flex-wrap"
        options={chapterContent}
        onRenderTitle={(title) => (
          <div className="pub-select-container-base pub-select-container-full">{title}</div>
        )}
        onGetTitle={(value) => (value ? value.refcode_short : "")}
        value={range}
        onChange={(value) =>
          onRangeChange(READER_ACTIONS.SHARE, chapterContent.indexOf(value), isFrom)
        }
      />
    );
  };

  const renderSocialsBtn = (btn, shareOption, btnText, disabled = false) => {
    const btnStyle = btn === "range" ? " sct-center" : "";
    return (
      <Button
        disabled={disabled}        
        className={"sc-btn pub-dialog-btn" + btnStyle}
        onClick={(e) => {
          const isShare = handleShareAction(shareOption);
          if (isShare) {
            const dialog = e.target.closest(".pub-dialog-container");
            const parentBounds = getBoundingClientRect(dialog);
            showShare({
              shareData: socialShare,
              bounds: getBoundingClientRect(e.target),
              parentBounds,
              onShareButtonClick: () => {
                hideDialog();
              },
            });
          }
        }}>
        {t(btnText, { text: t("share") })}
      </Button>
    );
  };

  const expandStyle = showAdvanced ? " expandChevronRotate" : "";

  const advancedContent = (
    <React.Fragment>
      <span
        className={"expandItem expandItemActive" + expandStyle}
        onClick={() => onToggle(READER_ACTIONS.SHARE, "showAdvanced")}>
        {t("advancedOptions")}
      </span>
      {showAdvanced && (
        <div className="sct-margin-top">
          <Checkbox
            checked={checks.refs}
            className="sct-check-text single-in-row"
            title={t("actionRefcodes", { text: t("share") })}
            onChange={() => onToggle(READER_ACTIONS.SHARE, "refs")}
          />
          <div className={"pub-dialog-row pub-dialog-row-center"}>
            {renderSocialsBtn(
              "selection",
              scOptions.SELECTION,
              "actionSelection",
              !isCopySelectionAllowed,
            )}
            <div className="pub-row-space" />
            {renderSocialsBtn("chapter", scOptions.CHAPTER, "actionChapter")}
          </div>
          <div className="sct-divider" />
          <span className="sct-select-title">{t("selectRange")}</span>
          <div className={"pub-dialog-row"}>
            {renderSelect(true)}
            <div className="pub-row-space-24" />
            {renderSelect(false)}
          </div>
          {renderSocialsBtn("range", scOptions.RANGE, "actionRange")}
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div className="sct-container" ref={parent}>
      <div className={classNames({ "select-margin-top": isMobileMode })}>
        <div
          className={classNames("sct-link-container", {
            "sct-link-container-m": isMobileMode,
          })}>
          <label>
            {t("link")}
            <input className="sct-input" type="text" value={shareUrl} readOnly ref={input} />
          </label>
          <Button className="sct-link-plus-container s-copy-link-btn" onClick={handleCopyToClipboard}>
            <IconView icon={EgwWebFont.copy} />
          </Button>
          <Button className="sct-link-plus-container" onClick={handlePlusClick}>
            <IconView icon={EgwWebFont.plus} />
          </Button>
        </div>
      </div>
      {hasAdvancedMode ? advancedContent : ""}
    </div>
  );
};

ShareTab.propTypes = {
  shareTitle: PropTypes.string,
  shareUrl: PropTypes.string,
  mobileMode: PropTypes.bool,
  hasAdvancedMode: PropTypes.bool,
  chapterContent: PropTypes.array,
  selection: PropTypes.object,
  onClose: PropTypes.func,
  onToggle: PropTypes.func,
  checks: PropTypes.object,
  range: PropTypes.object,
  onRangeChange: PropTypes.func,
};

export default ShareTab;
