import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  actionRefreshContent,
  actionGetSubscriptions,
  fetchFeeds,
  actionDeleteSubscriptions,
} from "../subscriptions.actions";
import { useDialogContext } from "../../dialog/DialogContext";
import { subTypes as tabs } from "../SubscriptionsUtils";
import { useViewMode, useSubscriptionDialogs } from "../../../hooks";
import { PlusIcon, MinusIcon } from "../../../assets/CommonIcons";
import AddSubscription from "../AddSubscription";
import { IconButton, TabBar, ScrollerView, Scroll } from "../../views";
import DialogButtons from "../../dialog/DialogButtons";

import "../../dialog/Dialog.scss";
import "../SubscriptionsDialog.scss";

const EditSubscriptionView = ({ date, bookId, fromTabId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openAddSubscription } = useSubscriptionDialogs();
  const { isTablet, zoom } = useViewMode();
  const { showConfirm, hideDialog } = useDialogContext();
  const [editItem, setEditItem] = useState(null);
  const subscriptions = useSelector((state) => state.subscriptions.allSubs);
  const allFeeds = useSelector((state) => state.subscriptions.feeds);
  const loading = useSelector((state) => state.subscriptions.loading);

  const fromNews = fromTabId === "thoughts" || fromTabId === "news";
  const initTab = fromNews ? tabs[3] : tabs[0];
  const [currentTab, setCurrentTab] = useState(initTab);
  const [isCreatedSub, setIsCreatedSub] = useState(false);

  useEffect(() => {
    if (bookId) {
      const subToEdit = subscriptions.find((item) => item.book.book_id === bookId);
      if (subToEdit) {
        setEditItem({ ...subToEdit, index: subscriptions.indexOf(subToEdit) });
        const tab = tabs.find((item) => item.id === subToEdit.book.book_type);
        if (tab) {
          setCurrentTab(tab);
        }
      }
    }
  }, [bookId]);

  const [allSubs, setAllSubs] = useState([]);
  const [feeds, setAllFeeds] = useState([]);
  const closeActionRef = useRef({ refreshContent: false, updateSubs: false });

  useEffect(() => {
    dispatch(fetchFeeds());

    return () => {
      if (closeActionRef.current?.refreshContent) {
        dispatch(actionRefreshContent(date));
      }
      if (closeActionRef.current?.updateSubs) {
        dispatch(actionGetSubscriptions());
      }
    };
  }, []);

  useEffect(() => {
    if (allFeeds.length > 0) {
      setAllFeeds(allFeeds);
    }
  }, [allFeeds]);

  useEffect(() => {
    setAllSubs(subscriptions);
    if (!bookId && !loading) {
      setInitialItem(null, subscriptions);
    }
  }, [subscriptions, loading]);

  useEffect(() => {
    if (fromNews && items.length && feeds.length) {
      if (fromTabId === "news") {
        setEditItem({ ...items[0], index: feeds.indexOf(items[0]) });
      } else {
        setEditItem({ ...items[1], index: feeds.indexOf(items[1]) });
      }
    }
  }, [fromNews, items, feeds]);

  // create a new subscription
  useEffect(() => {
    if (!isCreatedSub) {
      return;
    }
    const createdSub = subscriptions[subscriptions.length - 1];
    if (createdSub) {
      handleTabClick(createdSub.book.book_type);
      setEditItem({ ...createdSub, index: subscriptions.indexOf(createdSub) });
    }
  }, [subscriptions]);

  const setInitialItem = (newTab, subscriptions) => {
    const selectedTab = newTab || currentTab;
    const subs = subscriptions || allSubs;
    if (subs.length === 0) {
      return;
    }

    const isNews = selectedTab.id === "news";
    const items = isNews
      ? feeds
      : (subs || []).filter((sub) => sub.book.book_type === selectedTab.id);
    let firstItem = null;
    if (items.length > 0) {
      firstItem = { ...items[0], index: (isNews ? feeds : subs).indexOf(items[0]) };
    }
    setEditItem(firstItem);
  };

  const handleTabClick = (tabId, withInitial) => {
    if (currentTab.id === tabId) {
      return;
    }
    const newTab = tabs.find((tab) => tab.id === tabId);
    if (newTab) {
      setCurrentTab(newTab);
      if (withInitial) {
        setInitialItem(newTab);
      }
    }
  };

  const handleSubCreate = (isCreateSub) => setIsCreatedSub(isCreateSub);

  const handleSubUpdate = (updatedSub) => {
    const isNews = currentTab.id === "news";
    const items = isNews ? feeds : allSubs;
    items[editItem.index] = updatedSub;
    isNews ? setAllFeeds([...feeds]) : setAllSubs([...allSubs]);
    confirmRefresh();
    setEditItem({ ...updatedSub, index: items.indexOf(updatedSub) });
  };

  const filterSubs = () => (allSubs || []).filter((sub) => sub.book.book_type === currentTab.id);

  const handleSubDelete = () => {
    if (!editItem) {
      return;
    }
    const handleConfirm = () => {
      dispatch(actionDeleteSubscriptions({ id: editItem.id }));
      confirmRefresh(true);
    };

    showConfirm(t("deleteSub"), t("deleteSubWarn", { title: editItem.book.title }), handleConfirm);
  };

  const confirmRefresh = (withSubs) => {
    if (!closeActionRef.current.refreshContent) {
      closeActionRef.current.refreshContent = true;
    }
    if (withSubs && !closeActionRef.current.updateSubs) {
      closeActionRef.current.updateSubs = true;
    }
  };

  const openAddSubDialog = (isFeedTab) => {
    openAddSubscription({
      bookType: isFeedTab ? null : currentTab.id.toUpperCase(),
      onCreate: handleSubCreate,
    });
  };

  const renderSubscriptionsList = (items, isNews) => {
    if (items.length === 0) {
      return <span className="subs-edit-def-text">{isNews ? t("noNews") : t("noSubs")}</span>;
    }

    return items.map((item) => {
      const itemStyle = item.id === (editItem || {}).id ? " subs-edit-item-selected" : "";

      return (
        <div
          key={item.id}
          className={"subs-edit-item " + itemStyle}
          onClick={() => setEditItem({ ...item, index: (isNews ? feeds : allSubs).indexOf(item) })}>
          {isNews ? item.title : item.book.title}
        </div>
      );
    });
  };

  const renderNoItemBlock = (isFeedTab) => {
    return (
      <div id="add-sub-no-item" className={isTablet ? "tablet" : ""}>
        <span className="subs-edit-def-text">
          {isFeedTab ? t("noNewsSelected") : t("noSubSelected")}
        </span>
        <DialogButtons
          positiveText={t("add")}
          negativeText={t("cancel")}
          disabled={isFeedTab}
          onClickPositive={() => openAddSubDialog(isFeedTab)}
          onClickNegative={hideDialog}
        />
      </div>
    );
  };

  const isFeedTab = currentTab.id === "news";
  const items = isFeedTab ? feeds : filterSubs();

  return (
    <div
      id="subs-edit-container"
      className={classNames("subs-edit-container", {
        "zoom-mode": zoom > 110,
      })}>
      <div className="dialog-title">
        {t("editSub")}
        <IconButton icon={PlusIcon} onClick={() => openAddSubDialog(isFeedTab)} />
        <IconButton icon={MinusIcon} disabled={isFeedTab || !editItem} onClick={handleSubDelete} />
      </div>
      <Scroll autoHeight autoHeightMax="80vh" style={{ width: "100%" }}>
        <div className="subs-edit-inner">
          <div className="subs-edit-tabs-container">
            <TabBar
              current={currentTab.id}
              options={tabs}
              onChange={(tab) => handleTabClick(tab, true)}
              className="subs-edit-tabs"
            />
            <ScrollerView className="subs-edit-scroll">
              {renderSubscriptionsList(items, isFeedTab)}
            </ScrollerView>
          </div>
          {(!isFeedTab && items.length > 0) || (isFeedTab && editItem) ? (
            <AddSubscription
              editItem={editItem}
              editMode={true}
              isFeed={isFeedTab}
              onUpdate={handleSubUpdate}
              bookId={bookId}
            />
          ) : (
            renderNoItemBlock(isFeedTab)
          )}
        </div>
      </Scroll>
    </div>
  );
};

EditSubscriptionView.propTypes = {
  date: PropTypes.string,
  bookId: PropTypes.number,
  fromTabId: PropTypes.string,
};

export default EditSubscriptionView;
