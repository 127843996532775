import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useDialogContext } from "../dialog/DialogContext";
import { Button } from "../views";
import { useViewMode } from "../../hooks";
import { actionAddMessage } from "../../redux/actions";
import { createPrintWindow } from "../../utils/PrintUtils";
import { SearchActions } from "./search.actions";

const PrintTab = ({ isLocal }) => {
  const { t } = useTranslation();
  const { isMobile } = useViewMode();
  const dispatch = useDispatch();
  const { hideDialog } = useDialogContext();
  const search = useSelector((state) => state.search.search);
  const localSearch = useSelector((state) => state.search.localSearch);
  const localSearchParams = useSelector((state) => state.search.localSearchParams);
  const searchParams = useSelector((state) => state.search.searchParams);
  const print = useSelector((state) => state.search.print);

  const total = isLocal ? localSearch.total : search.total;
  const params = isLocal ? localSearchParams : searchParams;

  const [range, setRange] = useState({ from: 1, to: total >= 100 ? 100 : total });
  const [success, setSuccess] = useState(false);

  const { from, to } = range;

  useEffect(() => {
    if (!print.length || !success) {
      return;
    }

    createPrintWindow(print);
    dispatch(SearchActions.fetchSearchByRangeSuccess({ data: [], last: -1 }));
    hideDialog();
  }, [print, hideDialog, success]);

  const checkRange = () => {
    if (Math.abs(from - to) > 100) {
      dispatch(actionAddMessage(t("outOfRange", { count: 100 })));
      return false;
    }

    if (from > total || to > total) {
      dispatch(actionAddMessage(t("limitExceeded")));
      return false;
    }

    if (from > to) {
      dispatch(actionAddMessage(t("fromMoreThanTo")));
      return false;
    }

    return true;
  };

  const handlePrint = () => {
    const isValid = checkRange();

    if (!isValid) {
      return;
    }

    dispatch(SearchActions.fetchSearchByRange({ ...params, start: from, end: to - 1 }));
    setSuccess(true);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setRange((prev) => {
      const fromMoreThanTo = name === "from" && value > prev.to;

      if (fromMoreThanTo) {
        return { from: +value, to: +value };
      }
      let newValue = value;
      if (name === "to" && newValue >= total) {
        newValue = total;
      }

      return { ...prev, [name]: newValue };
    });
  };

  return (
    <div className="search-print">
      <span className="search-print-title">{t("selectRange")}</span>
      <div className={classNames("search-print-row", { "search-print-row-m": isMobile })}>
        <div className="search-print-column">
          <label className="search-print-label">{t("from_number") + ":"}</label>
          <input className="search-print-input" value={from} name="from" onChange={handleChange} />
        </div>
        <div className="pub-row-space-24" />
        <div className="search-print-column">
          <label className="search-print-label">{t("to_number") + ":"}</label>
          <input className="search-print-input" value={to} name="to" onChange={handleChange} />
        </div>
      </div>
      <Button className="sc-btn pub-dialog-btn sct-center" onClick={handlePrint}>
        {t("actionRange", { text: t("Print") })}
      </Button>
    </div>
  );
};

PrintTab.propTypes = {
  isLocal: PropTypes.bool,
};

export default PrintTab;
