import React from "react";
import AboutUsPage from "src/shared/pages/AboutUsPage";
import { ScrollerView } from "../components/views";
import { useViewMode } from "../hooks";

import "./Pages.scss";

const AboutScreen = () => {
  const { isMobile } = useViewMode();
  const WrapperComponent = isMobile ? "div" : ScrollerView;

  return (
    <WrapperComponent>
      <AboutUsPage />
    </WrapperComponent>
  );
};

export default AboutScreen;
