import { ACTION_LOGIN, ACTION_LOGOUT } from "../constants";

import { shopActionConstants } from "./actions";

const initialState = {
  pendingIds: new Set(),
  loadingPackages: false,
  isPackagesFetched: false,
  shopIds: [],
  shopBooks: {},
  packageIds: [],
  shopPackages: {},
  allShopBooks: [],
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case shopActionConstants.FETCH_ALL_SHOP_BOOKS_SUCCESS: {
      return { ...state, allShopBooks: action.data };
    }
    case shopActionConstants.FETCH_SHOP_BOOKS_SUCCESS: {
      const shopBooks = action.data;
      const updatedPendingIds = new Set(state.pendingIds);
      const updatedShopIds = [...state.shopIds];
      const updatedShopBooks = { ...state.shopBooks };
      shopBooks.books.forEach((shopBook) => {
        updatedShopIds.push(shopBook.book_id);
        updatedShopBooks[shopBook.book_id] = shopBook;
        updatedPendingIds.delete(shopBook.book_id);
      });
      return {
        ...state,
        pendingIds: updatedPendingIds,
        shopIds: [...new Set(updatedShopIds)],
        shopBooks: updatedShopBooks,
      };
    }
    case shopActionConstants.FETCH_SHOP_BOOKS_PENDING: {
      const updatedPendingIds = new Set(state.pendingIds);
      action.data.forEach((bookId) => updatedPendingIds.add(bookId));
      return { ...state, pendingIds: updatedPendingIds };
    }
    case shopActionConstants.FETCH_PACKAGES_PENDING: {
      return { ...state, loadingPackages: true };
    }
    case shopActionConstants.FETCH_PACKAGES_SUCCESS: {
      const updatedPackageIds = [...state.packageIds];
      const updatedShopPackages = { ...state.shopPackages };
      action.data.forEach((shopPackage) => {
        updatedPackageIds.push(shopPackage.id);
        updatedShopPackages[shopPackage.id] = shopPackage;
      });
      return {
        ...state,
        shopPackages: updatedShopPackages,
        packageIds: updatedPackageIds,
        loadingPackages: false,
        isPackagesFetched: true,
      };
    }
    case shopActionConstants.RESTORE_SHOP: {
      const { shopPackages, shopBooks } = action.data;
      const updatedPackageIds = [...state.packageIds];
      const updatedShopIds = [...state.shopIds];
      const updatedShopBooks = { ...state.shopBooks };
      const updatedShopPackages = { ...state.shopPackages };
      shopPackages.forEach((shopPackage) => {
        updatedPackageIds.push(shopPackage.id);
        updatedShopPackages[shopPackage.id] = shopPackage;
      });
      shopBooks.forEach((shopBook) => {
        updatedShopIds.push(shopBook.book_id);
        updatedShopBooks[shopBook.book_id] = shopBook;
      });
      return {
        ...state,
        shopIds: [...new Set(updatedShopIds)],
        shopBooks: updatedShopBooks,
        shopPackages: updatedShopPackages,
        packageIds: [...new Set(updatedPackageIds)],
      };
    }
    case ACTION_LOGOUT:
    case ACTION_LOGIN:
    case shopActionConstants.RESET_SHOP:
      return initialState;
    default:
      return state;
  }
};

export default shopReducer;
