import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { getBoundingClientRect, getClientPositions } from "../../../utils/Utils";
import { calculateTooltipView } from "./tooltip.utils";
import TooltipMessage from "./TooltipMessage";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { ContentActions } from "src/redux/content.actions";
import { useTranslation } from "react-i18next";

const INITIAL_STATE = { left: -500, top: -500, isShown: false, timerId: undefined };

const getParagraphPreviewContent = (t, paraId, paragraph) => {
  if (paragraph && paragraph?.previewId === paraId) {
    return `${paragraph.content} <span class="refCode">(${paragraph.refcode_short})</span>`;
  }
  return t("loading");
};
/**
 * Show tooltip of content by paraId
 */
const ReaderTooltipWrap = forwardRef((props, ref) => {
  const [state, setState] = useState(INITIAL_STATE);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paragraphPreview = useSelector((state) => state.paragraphReducer.paragraphPreview);

  const {
    paraId,
    isShown,
    pointerClass,
    left,
    top,
    hideOnClick,
    hideOnLeave,
    parentBookId,
  } = state;

  let message = getParagraphPreviewContent(t, paraId, paragraphPreview);

  useEffect(() => {
    window.addEventListener("popstate", hide);
    return () => {
      window.removeEventListener("popstate", hide);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    showTooltip(paraid, e, parent, parentBookId) {
      show(paraid, e, parent, parentBookId);
    },
    hideTooltip() {
      hide();
    },
  }));

  useEffect(() => {
    if (paraId && paragraphPreview?.previewId !== paraId) {
      dispatch(ContentActions.fetchParagraphPreview(paraId, parentBookId));
    }
  }, [paraId, paragraphPreview, parentBookId]);

  const recalculatePosition = (message) => {
    const { targetRect, parentRect } = state;

    const { left, top, pointerClass } = calculateTooltipView(message, targetRect, parentRect);
    setState({ ...state, left, top, pointerClass });
  };

  useEffect(() => {
    if (paraId && message) {
      recalculatePosition(message);
    }
  }, [message]);

  const show = (paraId, event, parent, parentBookId) => {
    let message = getParagraphPreviewContent(t, paraId, paragraphPreview);

    //TODO optimize make position
    event.persist && event.persist();

    event.target.onmouseleave = () => hide();
    // parent target needed to hide tooltip when scroll event fired
    if (event.currentTarget) {
      event.currentTarget.onscroll = () => hide();
    }

    const parentRect = parent ? getBoundingClientRect(parent) : undefined;
    const { clientX, clientY } = getClientPositions(event);
    const targetRect = {
      x: clientX,
      y: clientY,
      height: 10,
    };

    const { left, top, pointerClass } = calculateTooltipView(message, targetRect, parentRect);

    setState({
      isShown: true,
      paraId,
      targetRect,
      parentRect,
      left,
      top,
      pointerClass,
      hideOnClick,
      hideOnLeave,
      parentBookId,
    });
  };

  const hide = () => {
    setState(INITIAL_STATE);
  };

  return (
    <div
      style={{ left, top }}
      className={classnames("tooltip-wrap", {
        hideView: !isShown,
      })}>
      <div
        className={classnames("tooltip-view", {
          content: !!paraId,
        })}
        onClick={hide}
        onMouseLeave={hide}>
        <div className={pointerClass} />
        <TooltipMessage message={message || t("loading")} />
      </div>
    </div>
  );
});

export default ReaderTooltipWrap;
