import { makeRequest } from "src/shared/api/baseApi";
import { getBookId } from "src/shared/utils/content"; 

const BASE_SUB_BOOKS_URL = "subscriptions/books";
const BASE_SUBSCRIPTIONS_URL = "subscriptions/subscriptions";
const BASE_SUB_CONTENT_URL = "subscriptions/content";
const BASE_FEEDS_URL = "subscriptions/feeds";
const BASE_THOUGHT_URL = "subscriptions/thought";
const BASE_SETTINGS_URL = "subscriptions/settings";

/*Books*/
export const getSubBooks = (language, bookType) => {
  const url = `${BASE_SUB_BOOKS_URL}?book_type=${bookType}&language=${language}`;

  const request = {
    url,
    type: "get",
    data: { language: language },
    parseResponse: (response) => {
      return response.data.map((book) => {
        return {
          id: book.book_id,
          ...book,
        };
      });
    },
  };
  return makeRequest(request);
};

export const getSubBookToc = (bookId) => {
  const request = {
    url: `${BASE_SUB_BOOKS_URL}/${bookId}/toc`,
    type: "get",
    parseResponse: (response) => {
      return response.data.map((para) => {
        return {
          id: para.order,
          ...para,
        };
      });
    },
  };
  return makeRequest(request);
};
/*Books*/

/*Subscriptions*/
export const getAllSubscriptionsRequest = () => {
  return makeRequest({
    url: BASE_SUBSCRIPTIONS_URL,
    type: "get",
    parseResponse: (response) => {
      return response.data.map((item) => {
        return {
          ...item,
          book: { ...item.book, id: getBookId(item.book.book_id) },
        };
      });
    },
  });
};

export const createSubscription = (params) => {
  const request = {
    url: `${BASE_SUBSCRIPTIONS_URL}`,
    type: "post",
    data: params,
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const updateSubscription = ({ id, params }) => {
  const request = {
    url: `${BASE_SUBSCRIPTIONS_URL}/${id}`,
    type: "put",
    data: params,
    parseResponse: (response) => response.data,
  };
  return makeRequest(request);
};

export const pauseSubscription = (id, dateUntil) => {
  const data = {};
  if (dateUntil) {
    data.until = dateUntil;
  }
  const request = {
    url: `${BASE_SUBSCRIPTIONS_URL}/${id}/stop`,
    type: "post",
    data,
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const startSubscription = (id) => {
  const request = {
    url: `${BASE_SUBSCRIPTIONS_URL}/${id}/start`,
    type: "post",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const deleteSubscription = (id) => {
  const request = {
    url: `${BASE_SUBSCRIPTIONS_URL}/${id}`,
    type: "delete",
    parseResponse: (response) => {
      return response.status;
    },
  };
  return makeRequest(request);
};
/*Subscriptions*/

/*Content*/
export const getSubsContentForDate = (date) => {
  const request = {
    url: `${BASE_SUB_CONTENT_URL}/${date}`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const getCalendarForMonth = (year, month) => {
  const request = {
    url: `${BASE_SUB_CONTENT_URL}/month/${year}/${month}`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const markAsRead = (id) => {
  const request = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "*/*",
    },
    url: `${BASE_SUB_CONTENT_URL}/read`,
    type: "post",
    data: `id=${id}`,
    parseResponse: (response) => {
      return response.status;
    },
  };
  return makeRequest(request);
};

export const markAllAsRead = (ids) => {
  let args = "";
  ids.forEach((id, index) => {
    args += "book_id=" + id;
    if (index !== ids.length - 1) {
      args += "&";
    }
  });
  const request = {
    url: `${BASE_SUB_CONTENT_URL}/unread?${args}`,
    type: "delete",
    parseResponse: (response) => {
      return response.status;
    },
  };
  return makeRequest(request);
};

export const fetchUnreadCountRequest = () => {
  const request = {
    url: `${BASE_SUB_CONTENT_URL}/unread`,
    type: "get",
    parseResponse: (response) => {
      return response.data.unread;
    },
  };
  return makeRequest(request);
};
/*Content*/

/*Feeds*/
export const getAllFeeds = () => {
  const request = {
    url: `${BASE_FEEDS_URL}`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const updateFeed = ({ id, params }) => {
  const request = {
    url: `${BASE_FEEDS_URL}/${id}`,
    type: "put",
    data: params,
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const activateFeed = (id, activate) => {
  const startOrStop = activate ? "start" : "stop";
  const request = {
    url: `${BASE_FEEDS_URL}/${id}/${startOrStop}`,
    type: "post",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};
/*Feeds*/

/*Thought*/
export const getThoughtsByDate = (date) => {
  const request = {
    url: `${BASE_THOUGHT_URL}?date=${date}`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};
/*Thought*/

/*User settings*/
export const getDeliveryMethods = () => {
  const request = {
    url: `subscriptions/delivery_methods`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const getSocialsData = async () => {
  const fb = await getFacebookCreds();
  const twitter = await getTwitterUserName();
  return { fb, twitter };
};

export const getFacebookCreds = () => {
  const request = {
    url: `${BASE_SETTINGS_URL}/social/facebook`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const setFacebookCreds = (data) => {
  let request;
  if (data) {
    request = {
      url: `${BASE_SETTINGS_URL}/social/facebook`,
      type: "put",
      data,
      parseResponse: (response) => {
        return response.status;
      },
    };
  } else {
    request = {
      url: `${BASE_SETTINGS_URL}/social/facebook`,
      type: "delete",
      parseResponse: (response) => {
        return response.status;
      },
    };
  }

  return makeRequest(request);
};

export const getTwitterUserName = () => {
  const request = {
    url: `${BASE_SETTINGS_URL}/social/twitter`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const setTwitterUserName = (userName) => {
  let request;
  if (userName) {
    request = {
      url: `${BASE_SETTINGS_URL}/social/twitter`,
      type: "put",
      data: { twitter_username: userName },
      parseResponse: (response) => {
        return response.status;
      },
    };
  } else {
    request = {
      url: `${BASE_SETTINGS_URL}/social/twitter`,
      type: "delete",
      parseResponse: (response) => {
        return response.status;
      },
    };
  }
  return makeRequest(request);
};

export const getTimeZones = () => {
  const request = {
    url: `${BASE_SETTINGS_URL}/timezones`,
    type: "get",
    parseResponse: (response) => {
      return response.data.timezones;
    },
  };
  return makeRequest(request);
};

export const getUserTimeRequest = () => {
  const request = {
    url: `${BASE_SETTINGS_URL}/time`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const setUserTime = (timeZone, deliveryTime) => {
  const request = {
    url: `${BASE_SETTINGS_URL}/time`,
    type: "put",
    data: {
      time_zone: timeZone,
      delivery_time: deliveryTime,
    },
    parseResponse: (response) => {
      return response.status;
    },
  };
  return makeRequest(request);
};
/*User settings*/
