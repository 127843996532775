import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { TabBar } from "../views";
import { READER_ACTIONS } from "../reader/ReaderUtils";
import { useViewMode } from "../../hooks";
import PrintTab from "./PrintTab";
import { makeSearchUrl } from "../../utils/SearchUtils";
import ShareTab from "../epub/PrintShareDialog/ShareTab";

import "./PrintShareDialog.scss";

const tabOptions = [
  {
    id: READER_ACTIONS.PRINT,
    label: READER_ACTIONS.PRINT
  },
  {
    id: READER_ACTIONS.SHARE,
    label: READER_ACTIONS.SHARE
  }
];

const PrintShareDialog = ({ activeTab, isLocal }) => {
  const [currentTab, setCurrentTab] = useState(activeTab);
  const { isMobile } = useViewMode();
  const searchParams = useSelector((state) => state.search.searchParams);
  const localSearchParams = useSelector((state) => state.search.localSearchParams);
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);
  const searchUrlData = makeSearchUrl(
    isLocal ? localSearchParams :  searchParams, collectionFilter, isLocal
  );
  const searchUrl = `${window.location.origin}${searchUrlData}`;

  const handleChangeTab = (selectedTab) => {
    setCurrentTab(selectedTab);
  };

  const renderTab = () => {
    switch (currentTab) {
      case READER_ACTIONS.SHARE:
        return <ShareTab shareTitle={searchParams.query} shareUrl={searchUrl} />;
      case READER_ACTIONS.PRINT:
      default:
        return <PrintTab isLocal={isLocal} />;
    }
  };

  return (
    <div
      className={classNames("search-dialog-container", { "search-dialog-container-m": isMobile })}>
      <TabBar
        options={tabOptions}
        current={currentTab}
        className="search-tabs"
        onChange={handleChangeTab}
      />
      {renderTab()}
    </div>
  );
};

PrintShareDialog.propTypes = {
  activeTab: PropTypes.string,
  isLocal: PropTypes.bool
};

export default PrintShareDialog;
