import { all, put, takeEvery } from "redux-saga/effects";

import { folderTreeActions } from "../folderTree/actions";
import { categoryTreeActions } from "../categoryTree/actions";
import { titlesActions } from "../titleTree/actions";
import { treesActionConstants } from "./actions";

function* updateChecked(action) {
  const { data } = action;
  yield put(folderTreeActions.updateChecked(data));
  yield put(categoryTreeActions.updateChecked(data));
  yield put(titlesActions.updateChecked(data));
}

export default function* treesSaga() {
  yield all([
    takeEvery(treesActionConstants.UPDATE_CHECKED, updateChecked),
  ]);
}
