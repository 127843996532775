import { all } from "redux-saga/effects";

import contentSaga from "./content.saga";
import systemSaga from "./system.saga";
import studyCenterSaga from "../components/studyCenter/studyCenter.saga";
import relevantSearchSaga from "../components/relevantSearch/relevantSearch.saga";
import searchSaga from "../components/search/search.saga";
import featuredSearchSaga from "../components/featuredSearch/featuredSearch.saga";
import librarySaga from "./library.saga";
import subscriptionSaga from "../components/subscriptions/subscriptions.saga";
import supportSaga from "./support.saga";
import categoriesSaga from "./categoryTree/saga";
import titlesSaga from "./titleTree/saga";
import shopSaga from "./shop/saga";
import treesSaga from "./trees/saga";

export default function* rootSaga() {
  yield all([
    contentSaga(),
    studyCenterSaga(),
    relevantSearchSaga(),
    searchSaga(),
    featuredSearchSaga(),
    librarySaga(),
    subscriptionSaga(),
    systemSaga(),
    supportSaga(),
    categoriesSaga(),
    titlesSaga(),
    shopSaga(),
    treesSaga(),
  ]);
}
