export const getEvnVariable = (key, envValue, defaultValue) => {
  let value = envValue;
  if (typeof window !== "undefined" && window.env && window.env[key]) {
    value = window.env[key];
  }
  return value || defaultValue;
};

export const getEnvironment = () => getEvnVariable(
  "REACT_APP_ENVIRONMENT",
  process.env.REACT_APP_ENVIRONMENT,
  "development"
);

export const isStaging = () => getEnvironment() === "staging";
export const isProduction = () => getEnvironment() === "production";
export const isTest = () => getEnvironment() === "test";
export const isDevelopment = () =>  (
  process.env.NODE_ENV !== "production" && !isStaging() && !isProduction() && !isTest()
) || false;
