import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ContentIcon } from "../../assets/CommonIcons";
import { IconButton } from "../views";
import classNames from "classnames";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { useViewMode } from "src/hooks";


const BookHeader = ({ book, isTocVisible, disableContentBtn, onButtonTocClick }) => {
  const { t } = useTranslation();
  const { textMode } = useViewMode();

  const onKeyDownHandle = (event) => {
    if (isEventKey(event, KeyCodes.enter) && onButtonTocClick) {
      onButtonTocClick();
    }
  };

  return (
    <div className="bookDescriptionHeader">
      <div className="bookInfoTitle">{book.title}</div>
      <div className="bookDescriptionHeader__content">
        {book.author.length > 0 && (
          <div className="bookDescriptionHeaderRightPanel__author">{`${t("By")} ${
            book.author
          }`}</div>
        )}
        <div className="bookDescriptionHeader__pagesLang">
          {!isTocVisible &&
            <IconButton
              icon={ContentIcon}
              ariaLabel={t("content")}
              onClick={onButtonTocClick}
              onKeyDown={onKeyDownHandle}
              tabIndex={0}
              disabled={disableContentBtn}
              className={classNames("bookContentIcon", {
                "icon-button-base bordered": textMode,
              })}
            />}
          {book.npages > 0 && (
            <span className="bookDescriptionHeader__pages">
              {t("pagesNum", { pages: book.npages })}
            </span>
          )}
          <span className="bookDescriptionHeader__lang bookTextUppercase">{book.lang}</span>
        </div>
      </div>
    </div>
  );
};

BookHeader.propTypes = {
  book: PropTypes.object,
  isTocVisible: PropTypes.bool,
  disableContentBtn: PropTypes.bool,
  onButtonTocClick: PropTypes.func
};

export default BookHeader;
