/**
 * @description Returns "value" if is in boundaries of "min" and "max"
 * Returns "max" if "value" more than "max"
 * Returns "min" if "value" less than "min"
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const clutch = (value, min, max) => {
  if (min > max) {
    max = min;
    min = max;
  }

  if (isNaN(value)) {
    return max;
  }

  if (value > max) {
    return max;
  } else if (value < min) {
    return min;
  }

  return value;
};

/**
 * @description Returns whether a given number is acceptable for calculations,
 * so if it's a regular digit number.
 * @param {number} value
 * @returns {boolean}
 */
export const isNumberCalculable = (value) => !isNaN(value) && typeof value === "number";

/**
 * @description Calculates direct or inverse proportion.
 * @param {number} value
 * @param {number} base
 * @param {number} baseNew
 * @returns {number}
 */
export const calcProportion = (value, base, baseNew) => {
  return value * baseNew / base;
};

export const isDeltaEquals = (num1, num2, delta) => Math.abs(num1 - num2) <= delta;
