import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import LinkButton from "../components/views/LinkButton";
import HideIcon from "../components/views/HideIcon";
import { EXTERNAL_URLS, makePublicUrl } from "../shared/utils/url";
import { useNavigationContext } from "../components/NavigationContext";
import { useViewMode } from "../hooks";
import EgwWebFont from "src/assets/EgwWebFont";
import IconView from "src/shared/components/IconView";
import { useDonateNotification } from "../shared/hooks/useDonateNotification";
import { useLocation } from "react-router-dom";

import "./DonateNotification.scss";

const DonateNotification = ({onShowChange, preventShow}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isReader } = useNavigationContext();
  const { zoom } = useViewMode();

  const { show, onCloseClick, onDonateClick, } = useDonateNotification({
    isReader, pathname: location.pathname,
  });

  useEffect(() => {
    onShowChange(show);
  }, [onShowChange, show]);

  if (!show || preventShow) {
    return null;
  }

  const withZoom = zoom > 100;

  return (
    <div className="donate-notification">
      <HideIcon onClick={onCloseClick} />
      <div className={classNames("donate-notification-wrapper", { "with-zoom": withZoom })}>
        <div
          className="donate-notification-icon-wrapper"
          style={{
            backgroundImage: "url(" + makePublicUrl("/images/donate.png") + ")",
          }}>
          <IconView icon={EgwWebFont.donate} className="donate-icon" />
        </div>
        <div className="donate-notification-content">
          <div className="donate-notification-content-title">{t("donateNotificationTitle")}</div>
          <div className="donate-notification-content-text">{t("donateNotificationText")}</div>
          <div className="donate-notification-actions">
            <span onClick={onDonateClick}>
              <LinkButton label={t("donate")} link={EXTERNAL_URLS.donate} external />
            </span>
            <span className="already-donated" onClick={onDonateClick}>
              {t("alreadyDonated")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

DonateNotification.propTypes = {
  preventShow: PropTypes.bool,
  onShowChange: PropTypes.func,
};

export default DonateNotification;
