import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeResourceUrl } from "../../shared/utils/url";
import { usePopupContext } from "../popup/PopupContext";
import { useViewMode } from "../../hooks";
import BookDownloadLinksView from "./BookDownloadLinksView";
import { useTooltipContext } from "../popup/TooltipContext";
import IconButton from "../views/IconButton";
import EgwWebFont from "src/assets/EgwWebFont";

const DOWNLOAD_POPUP_ID = "download-popup";

export const ImageButton = ({ className, link, icon, title }) => {
  const { hideTooltip, showTooltip } = useTooltipContext();
  const { textMode } = useViewMode();

  useEffect(() => {
    return () => hideTooltip();
  }, []);

  const classNameFinal =  "image-button " + className;

  if (textMode) {
    return (
      <a
        className={classNameFinal}
        href={makeResourceUrl(link)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <span>{title}</span>
      </a>
    );
  }

  return (
    <a
      className={classNameFinal}
      onMouseEnter={(e) => title ? showTooltip(title, e) : undefined}
      onMouseLeave={hideTooltip}
      onClick={hideTooltip}
      href={makeResourceUrl(link)}
      rel="noopener noreferrer"
      target="_blank"
    >
      <IconButton icon={icon} />
    </a>
  );
};

ImageButton.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
};

const DownloadButton = ({ book }) => {
  const { t } = useTranslation();
  const { epub, mobi, mp3, pdf } = book && book.files ? book.files : {};
  const { buy_link } = book ? book : {};
  const showIcon = epub || mobi || mp3 || pdf || buy_link;
  const { textMode } = useViewMode();
  const [showDownload, setShowDownload] = useState({});
  const { showPopup } = usePopupContext();

  useEffect(() => {
    let timer;

    if (showDownload.left) {
      timer = setTimeout(() => {
        const rect = showDownload;
        const component = <BookDownloadLinksView book={book} className="bookDownloadPopup" />;
        const popupPosition = {
          left: rect.left,
          top: rect.top,
          right: rect.right
        };
        showPopup(popupPosition, component, { popupId: DOWNLOAD_POPUP_ID, hideOnMouseLeave: true });
        setShowDownload({});
      }, 500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showDownload]);

  if (!showIcon) {
    return null;
  }

  const handleShowDownload = (e) => {
    const node = e.target.closest(".book-row");
    if (node) {
      const parentNodeRect = node.getBoundingClientRect();
      const alignRight =
        window.innerWidth - parentNodeRect.right < 150 ||
        (parentNodeRect.width > 150 && !textMode);
      const rect = e.target.getBoundingClientRect();
      const popupPosition = { left: rect.left, top: rect.bottom };
      if (alignRight) {
        popupPosition.right = rect.right;
      }
      setShowDownload(popupPosition);
    }
  };

  return (
    <IconButton
      ariaLabel={t("download")}
      title={t("download")}
      icon={EgwWebFont.download}
      className={"bookDownloadIcon booklist-fill-icon"}
      onMouseOver={handleShowDownload}
      onMouseLeave={() => setShowDownload({})}
    />
  );
};

DownloadButton.propTypes = {
  book: PropTypes.object
};

export default DownloadButton;
