import { findNode, getBoundingClientRect, inRect, removeDuplicates } from "../../utils/Utils";
import { DEF_READER_FONT, DEF_READER_TEXT_ALIGN, READER_PLAYER_BEHAVIOURS } from "./constants";
import { getBookIds, getLinkValue, makeLinkValue, PanelTypes } from "../../utils/URLUtils";
import { DefaultFontSize } from "../../shared/utils/theme";
import { getBibleContentsRequest } from "../../api/API";
import { CONTENT_CLASSES, getBookId, isSameBook } from "../../shared/utils/content";

import { makeFnGetFirstVisible } from "../../utils/DOMUtils";
import EgwWebIcons from "../../assets/EgwWebFont";
import { BIBLE_TYPE_CONCORDANCES, BIBLE_TYPE_DICTIONARIES, JTLBooks, NoSyncBooks } from "src/utils/ContentUtils";
import { isNumberBetween } from "../../shared/utils/number";

export const READER_ACTIONS = {
  OPEN_BIBLE: "open_bible",
  GOTO: "goto",
  GO_TO_BIBLE: "goToBible",
  CHANGE_BOOK: "changeBook",
  CHANGE_CHAPTER: "change_chapter",
  DICTIONARY: "dictionary",
  SEARCH: "search",
  EDITOR: "editor",
  OPEN_BOOK_CONTENT: "open_book_content",
  COPY: "copy",
  SUBSCRIBE: "subscribe",
  TYPO: "type",
  SHARE: "share",
  PRINT: "print",
  BIBLIOGRAPHY: "BIBLIOGRAPHY",
  CLICK_SC: "click_sc",
  TOOLTIP_SC_ENTRY: "TOOLTIP_SC_ENTRY",
  ADD_TO_ML: "ADD_TO_ML",
  PLAY_AUDIO: "PLAY_AUDIO",
  FULLSCREEN: "FULLSCREEN",
  CLOSE_AND_CLEAR: "CLOSE_AND_CLEAR",
  OPEN_TREE_CONTEXT_MENU: "OPEN_TREE_CONTEXT_MENU",
  HELP_TRANSLATE: "HELP_TRANSLATE",
};

export const EL_CN_SYNC_LINKS_AREA = "reader-panel_sync-links-area";

export const DATA_ATTR_READER_ELEMENT_ID = "data-id";

const DELTA_SIZE = 18;

export const getReaderFirstVisibleId = (parent, deltaSize = DELTA_SIZE) => {
  let firstVisibleDataId;

  makeFnGetFirstVisible(parent, false, false, (elemStart, wrapStart, elemEnd, wrapEnd, elem) => {
    if (
      elemStart >= wrapStart || // WS ES WE
      elemEnd - deltaSize >= wrapStart || //  WS EE WE
      (isNumberBetween(wrapStart, elemStart, elemEnd) &&
        isNumberBetween(wrapEnd, elemStart, elemEnd)) // ES WS WE EE
    ) {
      firstVisibleDataId = elem.getAttribute(DATA_ATTR_READER_ELEMENT_ID);
      if (firstVisibleDataId) {
        return true;
      }
    }

    return false;
  });

  return firstVisibleDataId;
};

export const getReaderLastVisible = (parent) => {
  let firstVisibleDataId;

  makeFnGetFirstVisible(
    parent,
    true,
    false,
    undefined,
    (elemStart, wrapStart, elemEnd, wrapEnd, elem) => {
      if (
        elemStart <= wrapEnd // WS ES WE 
      ) {
        firstVisibleDataId = elem.getAttribute(DATA_ATTR_READER_ELEMENT_ID);
        if (firstVisibleDataId) {
          return true;
        }
      }

      return false;
    },
  );

  return firstVisibleDataId;
};

/**
 * @description Returns first chapter id(if exists) if {paraId} ends with "1"
 */
export const normalizeParaId = (paraId, chapters) => {
  //FIXME Works wrong EGWW-1789
  const [, para] = paraId?.split(".");
  if (para === "1") {
    const firstId = chapters?.[0]?.id;

    if (firstId) {
      return firstId;
    }
  }
  return paraId;
};

export const changeChapter = (mainTree, paragraphs, paraId, isNext, isPara) => {
  let nextId;
  const bookId = getBookId(paraId);
  const bookContent = paragraphs[bookId] || [];
  const para = findParaById(paraId, mainTree, bookContent);
  if (isPara) {
    if (para) {
      if (isNext && para.id_next) {
        nextId = para.id_next;
      } else if (!isNext && para.id_prev) {
        nextId = para.id_prev;
      }
    }
  } else {
    const book = findNode(getBookId(paraId), mainTree);
    const chapters = book.chapters;
    const paraPuborder = para.puborder;

    if (chapters) {
      const currentChapterIndex = chapters.findIndex(({ puborder }, index) => {
        const nextChapter = chapters[index + 1];
        const nextChapterPuborder = nextChapter && nextChapter.puborder;

        /**
         * If chapter publication order less or equal paragraph
         * publication order.
         * And next chapter is absent or its publication order more than
         * paragraph publication order.
         */
        return puborder <= paraPuborder && (!nextChapter || nextChapterPuborder > paraPuborder);
      });

      const newChapterIndex = isNext ? currentChapterIndex + 1 : currentChapterIndex - 1;

      const chapter = chapters[newChapterIndex];
      if (chapter) {
        nextId = chapter.id;
      }
    }
  }
  return nextId;
};

export const getContentByParaId = (paraId, bookContent = [], limit) => {
  let content = [];
  let baseIndex = bookContent.findIndex((item) => item.id === paraId);

  if (baseIndex !== -1) {
    let index = baseIndex;
    let paraBase = bookContent[baseIndex];
    content.push(paraBase);
    let prevPara = paraBase;
    //get items before current
    do {
      index--;
      paraBase = bookContent[index];
      if (paraBase && paraBase.id === prevPara.id_prev) {
        content.unshift(paraBase);
        prevPara = paraBase;
      } else {
        paraBase = undefined;
      }
    } while (paraBase);

    paraBase = bookContent[baseIndex];
    prevPara = paraBase;
    index = baseIndex;
    // get items after current
    do {
      index++;
      paraBase = bookContent[index];
      if (paraBase && paraBase.id === prevPara.id_next) {
        content.push(paraBase);
        prevPara = paraBase;
      } else {
        paraBase = undefined;
      }
    } while (paraBase);
  }
  // splice limit value?
  if (limit && content.length > limit) {
    const index = content.findIndex((item) => item.id === paraId);
    let start = index - limit / 2;
    if (start < 0) {
      start = 0;
    }
    let end = start + limit;
    if (end > content.length - 1) {
      end = content.length - 1;
    }
    content = content.splice(start, end);
  }

  return content;
};

/**
 * generate content list based on current paragraph id
 * @param {*} paraId
 * @param {*} mainTree
 * @param {*} bookContent
 */
export const getReaderProgressAndChapterInfo = (book, para, paraId) => {
  let progress = 0;
  let chapterIndex = 0;
  let chapterId = paraId;
  if (book && book.chapters && para) {
    const index = book.chapters.findIndex((item) => item.para_id === paraId);

    if (index > -1) {
      chapterIndex = index;
      progress = (index / book.chapters.length) * 100;
    } else {
      const index = book.chapters.findIndex((item) => item.puborder > para.puborder);
      if (index > -1) {
        chapterIndex = Math.max(0, index - 1);
        progress = (chapterIndex / book.chapters.length) * 100;
      } else {
        const lastItem = book.chapters[book.chapters.length - 1];

        if (lastItem && lastItem.puborder <= para.puborder) {
          chapterIndex = book.chapters.length - 1;
          progress = 100;
        }
      }
    }

    chapterId = chapterIndex === -1 ? para.parent : book.chapters[chapterIndex].id;
  }

  return {
    progress,
    chapterIndex,
    chapterId,
  };
};

export const findParaById = (paraId, mainTree, bookContent) => {
  if (bookContent) {
    let para = bookContent.find((item) => item.id === paraId);
    if (!para) {
      para = bookContent[0];
    }
    return para;
  }

  const para = findNode(paraId, mainTree);
  if (para && para.className === CONTENT_CLASSES.PARAGRAPH) {
    return para;
  }
  return undefined;
};

export const fontOptions = [
  {
    label: "Roboto Condensed",
    id: "roboto_con",
    value: "Roboto Condensed",
    style: { fontFamily: "Roboto Condensed" },
  },
  {
    label: "Roboto",
    id: "roboto",
    value: "Roboto",
    style: { fontFamily: "Roboto" },
  },
  {
    label: "Roboto Slab",
    id: "roboto_slab",
    value: "Roboto Slab",
    style: { fontFamily: "Roboto Slab" },
  },
  {
    label: "Merriweather",
    id: "merriweather",
    value: "Merriweather",
    style: { fontFamily: "Merriweather", fontStyle: "italic" },
  },
  {
    label: "Noto Sans",
    id: "noto_sans",
    value: "Noto Sans",
    style: { fontFamily: "Noto Sans" },
  },
  {
    label: "Noto Sans Condensed",
    id: "noto_sans_cond",
    value: "Noto Sans Condensed",
    style: { fontFamily: "Noto Sans Condensed" },
  },
  {
    label: "Noto Serif",
    id: "noto_serif",
    value: "Noto Serif",
    style: { fontFamily: "Noto Serif" },
  },
  {
    label: "Volkhov Regular",
    id: "volkhov_regular",
    value: "Volkhov Regular",
    style: { fontFamily: "Volkhov" },
  },
  {
    label: "Volkhov Italic",
    id: "volkhov_italic",
    value: "Volkhov Italic",
    style: { fontFamily: "Volkhov", fontStyle: "italic" },
  },
  {
    label: "Volkhov Bold",
    id: "volkhov_bold",
    value: "Volkhov Bold",
    style: { fontFamily: "Volkhov", fontWeight: "bold" },
  },
];

export const textAlignOptions = [
  {
    id: "left",
    icon: EgwWebIcons["text-left"],
  },
  {
    id: "center",
    icon: EgwWebIcons["text-center"],
  },
  {
    id: "right",
    icon: EgwWebIcons["text-right"],
  },
  {
    id: "justify",
    icon: EgwWebIcons["text-justify"],
  },
];

export const getReaderFont = (readerFont) => {
  const font = readerFont || DEF_READER_FONT;
  let fontValue = fontOptions.find((item) => item.id === font);
  if (!fontValue) {
    fontValue = fontOptions[0];
  }

  return fontValue;
};

export const getParAmountAndFirstParId = (selection) => {
  let selectedParasAmount;
  let firstParaId;
  let selectedParaIds = [];
  if (selection?.className?.includes("para")) {
    selectedParasAmount = 1;
    firstParaId = selection.dataset?.id;
    selectedParaIds = selection.dataset?.id ? [selection.dataset.id] : [];
  } else {
    const selectedParas = selection?.getElementsByClassName("para");
    selectedParasAmount = selectedParas?.length;
    if (selectedParas?.length) {
      firstParaId = selectedParas[0].dataset.id;
      selectedParaIds = Array.from(selectedParas).map((para) => para.dataset.id);
    }
  }
  return { selectedParasAmount, firstParaId, selectedParaIds };
};

export const getReaderTextAlign = (align) => {
  const textAlign = align || DEF_READER_TEXT_ALIGN;
  return textAlignOptions.find((item) => item.id === textAlign)?.id || DEF_READER_TEXT_ALIGN;
};

export const selectedDataIsValid = (selection) => {
  const { text, startId, endId, startOffset, endOffset } = selection;

  if (
    text === "" ||
    typeof startId === "undefined" ||
    typeof endId === "undefined" ||
    startOffset === endOffset
  ) {
    return false;
  }

  if (text.length === 1) {
    const code = text.charCodeAt(0);
    if (code === 10 || isNaN(code)) {
      return false;
    }
  }

  return true;
};

export const getAudioChapterByChapterId = (book, chapterId) => {
  if (!book || !chapterId) {
    return null;
  }

  const nodeToSearchIn = [];

  if (book.chapters?.length) {
    const bookTitleChapter = book.chapters[0]; // Title chapter is cut off from the {book.children}.
    nodeToSearchIn.push(bookTitleChapter);
  }

  if (book.children?.length) {
    nodeToSearchIn.push(...book.children);
  }

  const parentNodesHolder = [];

  // Case 1 - search for {chapterId} with 'mp3'
  const currentChapter = findNode(chapterId, nodeToSearchIn, "id", parentNodesHolder);

  if (currentChapter?.mp3) {
    return currentChapter;
  }
  // ===================================

  // Case 2 - search for a chapter with {dup} = {chapterId}
  let currentChapterDup = findNode(chapterId, nodeToSearchIn, "dup", parentNodesHolder);

  while (currentChapterDup) {
    if (currentChapterDup.mp3) {
      return currentChapterDup;
    }

    currentChapterDup = findNode(currentChapterDup.id, nodeToSearchIn, "dup", parentNodesHolder);
  }

  // Case 3 - search for a chapter with {id} = {chapter.dup}
  if (currentChapter.dup) {
    currentChapterDup = findNode(currentChapter.dup, nodeToSearchIn, "id", parentNodesHolder);

    while (currentChapterDup) {
      if (currentChapterDup.mp3) {
        return currentChapterDup;
      }

      if (!currentChapterDup.dup) {
        break;
      }

      currentChapterDup = findNode(currentChapterDup.dup, nodeToSearchIn, "id", parentNodesHolder);
    }
  }

  // Case 4 - search for the nearest PARENT with 'mp3'
  let parent = parentNodesHolder.pop();

  while (parent) {
    if (parent.mp3) {
      return parent;
    }

    parent = parentNodesHolder.pop();
  }
  // ===================================

  return null;
};

export const getFirstAvailableAudioChapter = (book) => {
  return book?.chapters?.find((item) => item.mp3);
};

/**
 * remove panel by index and return object with
 *   newPanelIds
 *   newActiveId
 *   newActiveIndex
 * @param {number} index for remove item from panels
 * @param {string[]} panels array of panel ids
 * @param {number} activeIndex current active index
 */
export const removeFromPanelByIndex = (index, panels, activeIndex) => {
  const panelId = panels[index];
  let newPanels = [...panels];
  newPanels.splice(index, 1);
  const linkValue = getLinkValue(panelId);
  if (linkValue.type === PanelTypes.PARA.id) {
    // if close main book, search for dupicate of this book and make type from r to p
    const dummyIndex = newPanels.findIndex((item) => {
      const { type, value } = getLinkValue(item);
      return type === PanelTypes.READ.id && isSameBook(value, linkValue.value);
    });
    if (dummyIndex !== -1) {
      const dummyValue = getLinkValue(newPanels[dummyIndex]);
      newPanels[dummyIndex] = makeLinkValue(
        PanelTypes.PARA.id,
        dummyValue.value,
        dummyValue.activeValue,
      );
    }
  }

  let newActiveIndex;
  if (activeIndex < index) {
    newActiveIndex = activeIndex;
  } else if (activeIndex > index) {
    newActiveIndex = activeIndex - 1;
  } else {
    newActiveIndex = index - 1;
  }
  if (newActiveIndex < 0) {
    newActiveIndex = 0;
  }
  if (newActiveIndex > newPanels.length - 1) {
    newActiveIndex = newPanels.length - 1;
  }

  return {
    newPanels,
    newActiveIndex,
  };
};

export const getLinksInPanel = (panelIndex, onlyOtherBook, activeParaId) => {
  const panel = document.querySelector(`[data-panel-index='${panelIndex}']`);

  let links = [];
  if (panel) {
    const egwlinks = panel.getElementsByClassName(CONTENT_CLASSES.EGW_LINK);
    //TODO get scroll view of content Very important change it if reader panel changed
    const parentRect = getBoundingClientRect(panel);
    let elem,
      elementId,
      elemRect,
      parentPara,
      prevParaId,
      paraId,
      activePara,
      index = 1;
    for (let i = 0; i < egwlinks.length; i++) {
      elem = egwlinks[i];
      activePara = false;
      elemRect = getBoundingClientRect(elem);
      parentPara = elem.closest(".para");

      if (parentPara) {
        paraId = parentPara.getAttribute(DATA_ATTR_READER_ELEMENT_ID);
        if (activeParaId && paraId === activeParaId) {
          activePara = true;
        }
      }
      elementId = elem.getAttribute("data-link");
      if (onlyOtherBook && isSameBook(paraId, elementId)) {
        continue;
      }
      if (prevParaId === paraId) {
        index++;
      } else {
        index = 1;
      }

      prevParaId = paraId;
      links.push({
        elem,
        id: elementId,
        activePara,
        classList: [...elem.classList],
        title: elem.innerText,
        visible: inRect(parentRect, elemRect),
        index,
        paraId,
      });
    }
  }
  return { panel, links };
};

const allowedContentClass = [
  CONTENT_CLASSES.EGW_LINK,
  CONTENT_CLASSES.EGW_LINK_BIBLE,
  CONTENT_CLASSES.EGW_LINK_BOOK,
];

export const makeActiveLinkSelector = (item) => {
  const { linkType, id, paraId, index } = item;
  let selectors = [];
  if (paraId) {
    selectors.push(`.para[data-id='${paraId}']:not(.tree-link)`);
  }
  if (allowedContentClass.includes(linkType)) {
    if (index !== undefined) {
      selectors.push(`.${linkType}`);
    } else {
      selectors.push(`.${linkType}[data-link='${id}']`);
    }
    return selectors.join(" ");
  }
  return undefined;
};

export const checkRefCodePart = (value) => {
  if (value && value !== "0") {
    return value;
  }
  return "";
};

export const checkRefCodeByType = (refCode, type) => {
  if (type === "year") {
    return checkRefCodeByYear(refCode);
  }
  if (type === "month") {
    return checkRefCodeByMonth(refCode);
  }
  if (type === "day") {
    return checkRefCodeByDay(refCode);
  }
  if (type === "paragraph") {
    return checkRefCodeByParagraph(refCode);
  }

  return "";
};

export const checkRefCodeByYear = (refCode) => {
  const yearRegexp = /\d{4}/i;
  const matched = refCode.match(yearRegexp);
  return matched?.length ? matched[0] : "";
};

export const checkRefCodeByMonth = (refCode) => {
  const matched = refCode.match(
    /(January|February|March|April|May|June|July|August|September|October|November|December)/gi,
  );
  return matched?.length ? matched[0] : "";
};

export const checkRefCodeByDay = (refCode) => {
  const matched = refCode.match(/([12]\d|3[01]|0?[1-9])/i);
  return matched?.length ? matched[0] : "";
};

export const checkRefCodeByParagraph = (refCode) => {
  const matched = refCode.match(/par. (\d+)/i);
  return matched?.length ? matched[1] : "";
};

export const makeChapterTitle = (book, targetId, content, width, zoom = 100, addIconSize = 0) => {
  if (!book) {
    return undefined;
  }
  let titleAddOn = "";

  let defaultIconSize = DefaultFontSize * (1.2 + 0.5);
  if (zoom) {
    defaultIconSize *= zoom / 100;
  }
  //icons of panel
  let iconsWidth = defaultIconSize * (3 + addIconSize);
  const currentPara = content.find((item) => item.id === targetId);
  if (currentPara) {
    iconsWidth = defaultIconSize * 6;
    if (book.type === "bible") {
      const part1 = checkRefCodePart(currentPara.refcode_2);
      if (part1) {
        titleAddOn = ", " + part1;
      }
      const part2 = checkRefCodePart(currentPara.refcode_3);
      if (part2) {
        titleAddOn += " " + part2;
      }
      const part3 = checkRefCodePart(currentPara.refcode_4);
      if (part3) {
        titleAddOn += ":" + part3;
      }
    } else {
      titleAddOn = ` p.${currentPara.refcode_2.toUpperCase()}.${currentPara.refcode_3}`;
    }
  }
  const titleWithAddOn = book.title + titleAddOn;
  let titleDimensions = (titleWithAddOn.length * 10 * zoom) / 100;
  if (titleDimensions + iconsWidth > width) {
    return book.code + titleAddOn;
  }
  return titleWithAddOn;
};

export const getElementParagraphId = (element) => {
  return element?.dataset?.id;
};

/**
 *
 * @param {*} linkId
 * @param {*} panelIds - []
 */
export const navigateToBible = async (linkId, panelIds, activeIndex) => {
  const [bookId, paraId] = linkId.split(".");
  const bookIds = getBookIds(panelIds);

  const bibleContent = await getBibleContentsRequest(bookId, bookIds, [paraId]);

  if (bibleContent) {
    let newParaIds = [];
    panelIds.forEach((item, index) => {
      if (index !== activeIndex) {
        const { type, value } = getLinkValue(item);
        const bookId = getBookId(value);
        const newPara = bibleContent.find((elem) => elem.bookId === bookId);
        if (newPara) {
          newParaIds.push(makeLinkValue(type, newPara.para_id));
        } else {
          newParaIds.push(item);
        }
      } else {
        newParaIds.push(item);
      }
    });
    return newParaIds;
  }
  return panelIds;
};

export const makeSyncPanels = async (
  currentParaIds, currentActiveId, activeIndex, linkId, isBasedOnLinksArea
) => {
  let finalParaIds = [...currentParaIds];

  let { panel, links } = getLinksInPanel(activeIndex, true, currentActiveId, isBasedOnLinksArea);
  let activeLinkValue;

  // Case 3 - Sync with a link in the "links area"
  let linkToSync;
  if (isBasedOnLinksArea) {
    const linksArea = panel.querySelector("." + EL_CN_SYNC_LINKS_AREA);

    if (linksArea) {
      const areaRect = linksArea.getBoundingClientRect();
      const areaX = areaRect.x;
      const areaY = areaRect.y;
      const areaXWidth = areaX + areaRect.width;
      const areaYHeight = areaY + areaRect.height;

      linkToSync = links.find((link) => {
        const rect = link.elem.getBoundingClientRect();
        const rectX = rect.x;
        const rectY = rect.y;

        return rectX >= areaX && rectY >= areaY
          && (rectX + rect.width) <= areaXWidth
          && (rectY + rect.height) <= areaYHeight;
      });
    }

    if (!linkToSync) {
      return [finalParaIds];
    }
  }
  // ==============================

  // Case 2 - Sync with specific link id
  if (linkId) {
    // TODO (YO comment) may be need to implement search by targetId and paraId of target id
    linkToSync = links.find((item) => item.id === linkId);
  }
  // =============================

  // Return block for Cases 2 and 3.
  if (linkToSync) {
    const { classList, paraId, index, id } = linkToSync;
    if (classList.includes(CONTENT_CLASSES.EGW_LINK_BIBLE)) {
      finalParaIds = await navigateToBible(id, currentParaIds, activeIndex);
      activeLinkValue = {
        linkType: CONTENT_CLASSES.EGW_LINK_BIBLE,
        id,
        paraId,
        index,
      };
    } else if (classList.includes(CONTENT_CLASSES.EGW_LINK)) {
      activeLinkValue = {
        linkType: CONTENT_CLASSES.EGW_LINK,
        id,
        paraId,
        index,
      };
    } else if (classList.includes(CONTENT_CLASSES.EGW_LINK_BOOK)) {
      activeLinkValue = {
        linkType: CONTENT_CLASSES.EGW_LINK_BOOK,
        id,
        paraId,
        index,
      };
    }
    return [finalParaIds, activeLinkValue];
  }
  // ==========================

  // Case 1 - Common. Find (first) link of the active para and sync with it.
  let checkLinks = links;
  const linksFromActivePara = links.filter((item) => {
    return item.activePara;
  });

  if (linksFromActivePara.length > 0) {
    checkLinks = linksFromActivePara;
  }

  for (let i = 0; i < checkLinks.length; i++) {
    const { classList, id, visible, paraId, index } = checkLinks[i];

    if (visible) {
      const isLink = classList.includes(CONTENT_CLASSES.EGW_LINK);
      const isLinkBook = classList.includes(CONTENT_CLASSES.EGW_LINK_BOOK);
      if (classList.includes(CONTENT_CLASSES.EGW_LINK_BIBLE)) {
        finalParaIds = await navigateToBible(id, currentParaIds);
        activeLinkValue = { linkType: CONTENT_CLASSES.EGW_LINK_BIBLE, id, paraId, index };
        break;
      } else if (isLink || isLinkBook) {
        const openedBookIndex = currentParaIds.findIndex((panel) => {
          const linkData = getLinkValue(panel);
          return isSameBook(getBookId(linkData.value), getBookId(id));
        });
        if (openedBookIndex !== -1) {
          const currentBookData = getLinkValue(currentParaIds[openedBookIndex]);
          activeLinkValue = {
            linkType: isLink ? CONTENT_CLASSES.EGW_LINK : CONTENT_CLASSES.EGW_LINK_BOOK,
            id,
            paraId,
            index,
          };
          finalParaIds[openedBookIndex] = makeLinkValue(currentBookData.type, id);
          break;
        }
      }
    }
  }

  return [finalParaIds, activeLinkValue];
  // ========================
};

export const getPlayerBehaviorLabel = (settingValue) => {
  if (settingValue === READER_PLAYER_BEHAVIOURS.ASK) {
    return "ask";
  }
  if (settingValue === READER_PLAYER_BEHAVIOURS.HISTORY) {
    return "fromHistory";
  }
  if (settingValue === READER_PLAYER_BEHAVIOURS.CURRENT_PARA) {
    return "currentParagraph";
  }
  if (settingValue === READER_PLAYER_BEHAVIOURS.CONTINUE) {
    return "continuePlaying";
  }
  if (settingValue === READER_PLAYER_BEHAVIOURS.FIRST_AVAILABLE) {
    return "firstAvailable";
  }

  return "";
};

export const getBookWaveformLoaderId = (chapterId) => {
  return "FBW_" + chapterId;
};

const getTargetNodes = (target) => {
  const nodes = [];
  let hlTarget = target;

  do {
    const { classList, parentElement } = hlTarget;
    if (classList.contains("hh") || classList.contains(CONTENT_CLASSES.EGW_LINK)) {
      nodes.push(hlTarget);

      const { childNodes } = hlTarget;
      if (childNodes && childNodes.length > 0) {
        childNodes.forEach((child) => {
          if (child.classList?.contains(CONTENT_CLASSES.SC_MARK)) {
            nodes.push(child);
          }
        });
      }
    }
    hlTarget = parentElement;
  } while (
    hlTarget.classList &&
    (hlTarget.classList.contains("hh") || hlTarget.classList.contains(CONTENT_CLASSES.EGW_LINK))
  );
  return nodes;
};

export const getAreaEntriesAndLinks = (docFragment, target) => {
  let allNodes;
  if (docFragment) {
    const docNodes = docFragment.querySelectorAll(".hh, .sc-mark, .egwlink");
    allNodes = docNodes && docNodes.length === 0 ? getTargetNodes(target) : Array.from(docNodes);
    if (target.classList.contains("hh")) {
      allNodes.unshift(target);
    }
  } else {
    allNodes = getTargetNodes(target);
  }
  const selectedEntries = [];
  const selectedLinks = [];

  if (allNodes) {
    allNodes.forEach((item) => {
      if (item.hasAttribute("data-link")) {
        selectedLinks.push({
          id: item.getAttribute("data-link"),
          name: item.innerText,
          isBible: item.classList.contains("egwlink_bible"),
          type: "link",
        });
      } else {
        selectedEntries.push(
          item.getAttribute(item.hasAttribute("data-hid") ? "data-hid" : "id").trim(),
        );
      }
    });
  }
  return [[...new Set(selectedEntries)], [...new Set(selectedLinks)]];
};

export const getParaOptionsFromChapters = (chapters, dialogContent) =>
  chapters.reduce((previousOptions, chapter) => {
    if (dialogContent[chapter.id]) {
      return removeDuplicates([...previousOptions, ...dialogContent[chapter.id]]);
    }
    return previousOptions;
  }, []);

export const dispatchWindowEventPlayAudioInReader = () => {
  window.dispatchEvent(new CustomEvent("handlePlayAudioInReader"));
};

// some books must skip sync for other books, e.g. bible concordances
export const isSkipSyncForBook = (book) => {
  if (book) {
    return (
      book.realType === BIBLE_TYPE_CONCORDANCES ||
      book.realType === BIBLE_TYPE_DICTIONARIES ||
      JTLBooks.includes(book.id) ||
      NoSyncBooks.includes(book.id)
    );
  }
  return false;
};

export const makeJTLClickLinkSync = async (panels, fromLink, id, baseBible, isMobile) => {
  const newPanels = [...panels];
  let activeLinkId;
  const jtlBookPanelIndex = newPanels.findIndex((item) => {
    return item.indexOf(PanelTypes.JTL_BOOK.id) === 0;
  });

  const jtlBiblePanelIndex = newPanels.findIndex((item) => {
    return item.indexOf(PanelTypes.JTL_BIBLE.id) === 0;
  });

  if (fromLink === CONTENT_CLASSES.EGW_LINK) {
    const bookPanelValue = makeLinkValue(PanelTypes.JTL_BOOK.id, id);
    if (jtlBookPanelIndex !== -1) {
      newPanels[jtlBookPanelIndex] = bookPanelValue;
    } else {
      if (isMobile && jtlBiblePanelIndex !== -1) {
        newPanels[jtlBiblePanelIndex] = bookPanelValue;
      } else {
        newPanels.push(bookPanelValue);
      }
    }
  } else if (fromLink === CONTENT_CLASSES.EGW_LINK_BIBLE) {
    let newBiblePanelValue = makeLinkValue(PanelTypes.JTL_BIBLE.id, id);
    let targetBibleId = baseBible;

    if (jtlBiblePanelIndex !== -1) {
      const { value } = getLinkValue(newPanels[jtlBiblePanelIndex]);
      targetBibleId = value;
    }
    const bibleId = getBookId(id);
    if (bibleId !== baseBible) {
      const bibleLink = await getBibleContentsRequest(getBookId(id), [targetBibleId], [id]);
      if (bibleLink) {
        newBiblePanelValue = makeLinkValue(PanelTypes.JTL_BIBLE.id, bibleLink[0].paraId);
      }
    }

    if (jtlBiblePanelIndex !== -1) {
      newPanels[jtlBiblePanelIndex] = newBiblePanelValue;
    } else {
      if (isMobile && jtlBookPanelIndex !== -1) {
        newPanels[jtlBookPanelIndex] = newBiblePanelValue;
      } else {
        newPanels.push(newBiblePanelValue);
      }
    }
  }
  return { panels: newPanels, activeLinkId };
};
