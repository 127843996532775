import { langRegex } from "src/shared/utils/content";
import { arraymove } from "src/utils/Utils";
import { monthNamesInitial } from "../shared/utils/search";
import { localStorageSafe } from "../shared/utils/systemUtils";

export const withEtagSaving = (callback, eTagCacheName) => (response) => {
  const responseETag = response.headers.etag;
  const storedETag = localStorageSafe.getItem(eTagCacheName);
  if (responseETag && responseETag !== storedETag) {
    localStorageSafe.setItem(eTagCacheName, responseETag);
  }
  let value = null;
  if (response.data) {
    value = response.data;
    if (callback) {
      value = callback(response);
    }
  }
  return { status: response.status, value };
};

export const getOrderNumberOfMonth = (month) => {
  const matched = monthNamesInitial.findIndex((item) => item.includes(month.toLowerCase()));
  return matched !== -1 ? matched + 1 : "1";
};

export const getBookIdByCode = (periodicals = [], code, bookId) => {
  const cloned = [...periodicals] || [];
  const lowerCode = code.toLowerCase();

  // first of all we are looking up in the same book
  const filteredByBookId = cloned.filter(
    (item) => item.book_id === bookId && item.code.toLowerCase() === lowerCode,
  );

  if (filteredByBookId.length) {
    return filteredByBookId[0].book_id;
  }

  const filteredByCode = cloned.filter((item) => item.code.toLowerCase() === lowerCode);

  if (filteredByCode.length) {
    return filteredByCode[0].book_id;
  }

  return null;
};

export const removeLangFromFolders = (folders = []) => {
  return folders.filter((item) => !langRegex.test(item) && item !== "all");
};

export const joinHistoryData = (readData = [], listenData = [], mlData = [], libraryData = []) => {
  const allHistoryItems = {};

  readData.forEach((item) => {
    if (item.id) {
      allHistoryItems[item.id] = {
        ...item,
        paraId: item.para_id,
        luRead: item.lu,
      };
    }
  });

  let element;
  listenData.forEach((item) => {
    if (item.id) {
      if (!allHistoryItems[item.id]) {
        allHistoryItems[item.id] = {};
      }
      element = allHistoryItems[item.id];
      element.luListen = item.lu;
      element.lang = item.lang;
      element.position = item.position;
      element.listenChapter = item.chapter;
    }
  });

  mlData.forEach((item) => {
    if (item.id) {
      if (!allHistoryItems[item.id]) {
        allHistoryItems[item.id] = {};
      }
      element = allHistoryItems[item.id];
      element.mlRead = item.paraId;
      element.mlListen = item.position;
      element.mlLu = item.lu;
    }
  });

  libraryData.forEach((libraryItem) => {
    if (libraryItem.id) {
      if (!allHistoryItems[libraryItem.id]) {
        allHistoryItems[libraryItem.id] = {};
      }
      element = allHistoryItems[libraryItem.id];
      element.inLibrary = true;
      element.luLibrary = libraryItem.lu;
    }
  });
  return allHistoryItems;
};

export const makeHistoryUrl = (historyType, withDelete, start, end) => {
  const params = [`deleted=${withDelete}`];
  if (start) {
    params.push(`start=${start}`);
  }
  if (end) {
    params.push(`end=${end}`);
  }
  return `settings/${historyType}/sync?${params.join("&")}`;
};

export const makeAggregateUrl = (oUrls) => {
  let elements = Object.entries(oUrls).map(([key, value]) => {
    let urlValue = value;
    if (urlValue.indexOf("/") !== 0) {
      urlValue = "/" + urlValue;
    }
    return key + "=" + encodeURIComponent(urlValue);
  });

  return "/aggregate/?" + elements.join("&");
};

export const normilizeLangs = (langList, lang) => {
  let languages = langList.map((item) => ({
    ...item,
    label: item.name,
    id: item.code,
    className: "language",
  }));
  if (lang) {
    let index = languages.findIndex((item) => item.id === lang);
    if (index > -1) {
      arraymove(languages, index, 0);
    }
  }
  return languages;
};

