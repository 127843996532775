export const cartActionConstants = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  RESET_CART: "RESET_CART",
  RESTORE_CART: "RESTORE_CART",
  ACTION_TOGGLE_SHOW_CART: "ACTION_TOGGLE_SHOW_CART"
};

export const cartActions = {
  addToCart: (shopBook) => ({
    type: cartActionConstants.ADD_TO_CART,
    data: shopBook
  }),
  removeFromCart: (bookId) => ({
    type: cartActionConstants.REMOVE_FROM_CART,
    data: bookId
  }),
  resetCart: () => ({
    type: cartActionConstants.RESET_CART,
  }),
  restoreCart: (cartIds) => ({
    type: cartActionConstants.RESTORE_CART,
    data: cartIds
  }),
};
