import React, { useCallback, useState } from "react";
import { useViewMode } from "./viewModeHooks";
import AddSubscription from "../components/subscriptions/AddSubscription";
import { useDialogContext } from "../components/dialog/DialogContext";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import EditSubscriptionView from "../components/subscriptions/edit/EditSubscriptionView";
import { useNavigationContext } from "../components/NavigationContext";
import { actionUpdateSettings } from "../redux/actions";
import { Settings } from "../utils/Settings";
import { MENU_STATE } from "../utils/MenuItems";
import { useDispatch } from "react-redux";
import { URLS } from "../shared/utils/url";

export const useSubscriptionDialogs = () => {
  const { isMobile, isRealTablet } = useViewMode();
  const { showDialog } = useDialogContext();
  const { t } = useTranslation();
  const history = useHistory();
  const { nodeList } = useNavigationContext();

  const openEditSubscription = (params) => {
    const { date, id, type, subscription } = params;
    if (isMobile && !isRealTablet) {
      let url = URLS.subscriptionEdit;
      if (type && subscription) {
        url += `/${type}/${subscription}`;
      }
      history.push(url, {
        backStack: nodeList?.map((item) => {
          return {
            className: item.className,
            lang: item.lang,
            id: item.id,
            position: item.position,
            chapterId: item.chapterId,
            page: item.page,
            label: item.label
          };
        })
      });
    } else {
      showDialog(<EditSubscriptionView date={date} fromTabId={id} />);
    }
  };

  const openAddSubscription = (params) => {
    const { tabId, editMode, bookId, bookType, onCreate } = params;
    if (isMobile && !isRealTablet) {
      let url = URLS.subscriptionAdd;
      if (bookType && bookId) {
        url += `/${bookType}/${bookId}`;
      }

      history.push(url, {
        backStack: nodeList?.map((item) => {
          return {
            className: item.className,
            lang: item.lang,
            id: item.id,
            position: item.position,
            chapterId: item.chapterId,
            page: item.page,
            label: item.label
          };
        })
      });
    } else {
      showDialog(
        <AddSubscription
          onCreate={onCreate}
          editMode={editMode}
          fromTabId={tabId}
          type={bookType}
          bookId={bookId}
        />,
        {
          title: t("subscriptions_add")
        }
      );
    }
  };
  return { openAddSubscription, openEditSubscription };
};

/**
 * @return {{
 *    isAdvSearch: boolean,
 *    openAdvSearch: (function(): void),
 *    toggleAdvSearch: (function(): void),
 *    closeAdvSearch: (function(): void)
 * }}
 */
export const useAdvSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [openLocation, setOpenLocation] = useState("");

  const isAdvSearch = pathname.includes(URLS.advSearch);

  const openAdvSearch = useCallback(() => {
    setOpenLocation(history.location.pathname);

    history.push(URLS.advSearch);

    dispatch(actionUpdateSettings({
      [Settings.isShowRightPanel.id]: false,
      [Settings.menuState.id]: MENU_STATE.COLLAPSE,
    }));
  }, [dispatch, history]);

  const closeAdvSearch = useCallback(() => {
    if (isAdvSearch) {
      if (openLocation) {
        history.goBack();
      } else {
        history.push(URLS.home);
      }
    }
  }, [history, openLocation, isAdvSearch]);

  const toggleAdvSearch = useCallback(() => {
    if (isAdvSearch) {
      closeAdvSearch();
    } else {
      openAdvSearch();
    }
  }, [isAdvSearch, closeAdvSearch, openAdvSearch]);

  return {
    isAdvSearch,
    toggleAdvSearch,
    closeAdvSearch,
    openAdvSearch,
  };
};
