import React from "react";
import PropTypes from "prop-types";
import { useDialogContext } from "../../dialog/DialogContext";
import WorkspaceDialog from "./WorkspaceDialog";
import useReaderWorkspaces from "src/hooks/useReaderWorkspaces";

const EditWorkspaceDialog = ({ item }) => {
  const { hideDialog } = useDialogContext();
  const { renameWorkspace } = useReaderWorkspaces({});
  const onConfirm = (title) => {
    if (title) {
      renameWorkspace(item, title);
      hideDialog();
    }
  };

  return (
    <WorkspaceDialog
      titleDialog="editWorkspaceName"
      intiTitle={item.title}
      positiveText="save"
      onConfirm={onConfirm}
    />
  );
};

EditWorkspaceDialog.propTypes = {
  item: PropTypes.object,
};

export default EditWorkspaceDialog;
