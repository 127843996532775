import React from "react";
import PropTypes from "prop-types";
import { useLocation, Link } from "react-router-dom";
import { containsUrl, getParaIdsFromUrl, makeChapterUrl } from "../../../utils/URLUtils";

const TooltipMessage = ({ message }) => {
  const { search } = useLocation();

  const renderMessage = () => {
    if (containsUrl(message)) {
      let parts = message.split(/(https?:\/\/[^\s]+)/g);

      for (let i = 0; i < parts.length; i++) {
        if (parts[i].indexOf("http") > -1) {
          const url = parts[i];
          const { panelIds } = getParaIdsFromUrl(url);

          // creates a reader split link if there are any paraIds listed in url
          if (panelIds.length) {
            const { panelIds: panelIdsFromUrl, activeIndex } = getParaIdsFromUrl(search);
            const link = makeChapterUrl([...panelIdsFromUrl, panelIds], activeIndex);
            parts[i] = (
              <Link key={i} to={link}>
                <span dangerouslySetInnerHTML={{ __html: url }} />
              </Link>
            );
          } else {
            parts[i] = (
              <a
                key={i}
                target="_blank"
                rel="noopener noreferrer"
                href={url}
                dangerouslySetInnerHTML={{ __html: url }}
              />
            );
          }
        }
      }

      return <div className="tooltip-text">{parts}</div>;
    }

    return <div className="tooltip-text" dangerouslySetInnerHTML={{ __html: message }} />;
  };

  return renderMessage();
};

TooltipMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default TooltipMessage;
