// https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
export const TIMEOUT_MAX = 2147483647; // 2^32 (24.85 days)

export const AppState = {
  none: "none",
  loading: "loading",
  loaded: "loaded",
  serverDown: "serverDown",
};

export const cookieConfig = {
  maxAge: 1000 * 60 * 60 * 24 * 30, // would expire after 30 days
};

export const READER_TOP_PANEL_PORTAL_ID = "reader-top-panel-portal";

export const NOT_ALLOWED_OPTION = "NOT_ALLOWED_OPTION";

export const MenuEvents = {
  openInWindow: "openInWindow",
  openInActiveSplit: "openInActiveSplit",
  openInTab: "openInTab",
  openInSplit: "openInSplit",
  about: "about",
};

export const SearchExamples = {
  goToBibleRef: {
    title: "goToBibleRef",
    keys: ["revelation612", "rev612"],
  },
  goToBookPage: {
    title: "goToBookPage",
    keys: ["education", "education57", "ed57"],
  },
  wordsWithinBookEd: {
    title: "wordsWithinBookEd",
    keys: ["edGreatestWant", "educationGreatestWant"],
  },
  phraseInBook: {
    title: "phraseInBook",
    keys: ["edPhrase", "educationPhrase"],
  },
  search_topic: {
    title: "search_topic",
    keys: ["greatestWantQuotes"],
  },
  wildCardSearch: {
    title: "wildCardSearch",
    keys: ["just"],
  },
  proximityMatch: {
    title: "proximityMatch",
    keys: ["faithSight"],
  },
  minimumMatching: {
    title: "minimumMatching",
    keys: ["loveJoy"],
  },
  orderedSearch: {
    title: "orderedSearch",
    keys: ["godStr"],
  },
};
