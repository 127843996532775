import React, {
  createContext,
  useRef,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  setPlayerParams,
  setBrokenAudios,
  fetchAudioTrack,
  actionAddMessage
} from "../../redux/actions";
import { useBookSelect } from "../../redux/selectors";
import { Settings, storeSetting } from "../../utils/Settings";
import AudioPlayer from "./PlayerSmall/AudioPlayer";
import { getBookCover, makeResourceUrl } from "../../shared/utils/url";
import { getIsIOSDevice } from "../../utils/Utils";
import { actionSaveListen } from "../../redux/library.actions";
import { getAudioChapters, makeJsonUrl } from "./PlayerUtils";
import { useObserverPattern } from "../../hooks/useObserverPattern";
import { isSameBook } from "../../shared/utils/content";
import { clutch } from "../../utils/NumberUtils";

export const PLAYER_EVENTS = {
  POSITION_SET: 1,
  END: 2,
  CAN_PLAY_THROUGH: 3,
  PAUSE: 4,
  PLAY: 5,
  SKIP: 6,
  SEEK: 7,
  CALL_INIT_PLAY: 8,
};

const playerID = Date.now();

const AudioContext = createContext({});

const TAG = "AudioContext";
const silenceSrc = "/audio/silence.mp3";

export const AudioProvider = ({ children }) => {
  const dispatch = useDispatch();
  const audioRef = useRef();
  const [playerState, _setPlayerState] = useState({
    chapterId: "",
    volume: 1,
    duration: 0,
    isPlaying: false,
    isMute: false,
    playbackRate: 1.0
  });
  const audioStateRef = useRef(playerState);
  const { chapterId, isPlaying, volume, isMute, playbackRate, duration } = playerState;

  const brokenAudios = useSelector((state) => state.audioPlayer.brokenAudios);
  const waveformsDictionary = useSelector((state) => state.audioPlayer.waveformsDictionary);
  const playerId = useSelector((state) => state.settings.playerState);
  const { addObserverListener, removeObserverListener,
    notifyObserverListeners } = useObserverPattern();
  const book = useBookSelect(chapterId);
  const chapters = book?.chapters;

  const audioChapters = useMemo(
    () => getAudioChapters(chapters, chapterId),
    [chapters, chapterId]
  );
  const [currentTrack, currentIndex, audioChaptersList] = audioChapters;
  const src = currentTrack ? makeResourceUrl(currentTrack.mp3) : undefined;
  const cover = book ? getBookCover(book, true) : undefined;
  const [suitableTrack, audioChaptersArray] = audioChapters;
  const selectedTrack = suitableTrack || audioChaptersArray?.[0];
  const trackTitle = selectedTrack ? selectedTrack.title : "";

  const getPlayer = useCallback(() => audioRef.current?.audioEl, []);

  const setPlayerPosition = useCallback((position) => {
    const player = getPlayer();

    if (player && typeof position === "number") {
      player.currentTime = position;
    }
  }, [getPlayer]);
  const _setPlayerPlayback = useCallback((playbackRate) => {
    const player = getPlayer();
    if (player) {
      player.playbackRate = playbackRate;
    }
  }, [getPlayer]);

  const updatePlayerState = useCallback((newState) => {
    _setPlayerState((oldState) => ({
      ...oldState,
      ...newState,
    }));
  }, []);

  const _playerPlay = useCallback((position) => {
    const player = getPlayer();
    if (player) {
      _setPlayerPlayback(playbackRate);
      setPlayerPosition(position);

      player.play()
        .then(() => {
          updatePlayerState({ isPlaying: true });
        })
        .catch(() => {});
    }
  }, [setPlayerPosition, getPlayer, updatePlayerState, playbackRate]);

  const dispatchPlayerEvent = (event, data) => {
    let prevented = false;

    notifyObserverListeners({
      ...data,
      event,
      preventEvent: () => {
        prevented = true;
      },
    });

    return prevented;
  };

  const _playerPause = useCallback(() => {
    const player = getPlayer();
    if (player) {
      player.pause();
      updatePlayerState({ isPlaying: false, });
    }
  }, [updatePlayerState, getPlayer]);

  const toggleChapterPlaying = (playChapterId, position) => {
    if (playChapterId === undefined || playChapterId === chapterId) {
      if (isPlaying) {
        savePlayPosition();
        _playerPause();
      } else {
        _playerPlay(position);
      }
    } else {
      storeSetting(Settings.playerState.id, playerID);

      updatePlayerState({
        chapterId: playChapterId
      });

      getPlayer().addEventListener("canplaythrough", () => {
        _playerPlay(position);
      }, { once: true });
    }
  };

  const getPlayerPosition = useCallback(() => {
    const player = getPlayer();
    if (player) {
      return player.currentTime;
    }
    return 0;
  }, []);

  const savePlayPosition = useCallback(() => {
    const chapter = audioStateRef.current.chapterId;

    if (chapter) {
      dispatch(actionSaveListen(chapter, getPlayerPosition()));
    }
  }, [getPlayerPosition]);

  const skipPlay = (skipValue) => {
    const player = getPlayer();

    if (player) {
      setPlayerPosition(clutch(getPlayerPosition() + skipValue, 0, duration));

      dispatchPlayerEvent(PLAYER_EVENTS.SKIP);
    }
  };

  const seekPlay = (forvard) => {
    if (currentIndex !== undefined && audioChaptersList) {
      const newIndex = forvard ? currentIndex + 1 : currentIndex - 1;

      if (newIndex < 0 || newIndex > audioChaptersList.length - 1) {
        _playerPause();
      } else {
        const newChapter = audioChaptersList[newIndex];

        setPlayerPosition(0);

        updatePlayerState({
          chapterId: newChapter.id
        });

        getPlayer().addEventListener("canplaythrough", () => {
          _playerPlay(0);
        }, { once: true });
      }

      dispatchPlayerEvent(PLAYER_EVENTS.SEEK);
    }
  };

  const initPlayIfNotTheSameBook = (newChapterId, position, autoplay = true) => {
    // [EGWW-1687] Prevent restart player if the same book.
    if (chapterId && isSameBook(newChapterId, chapterId)) {
      if (!isPlaying) {
        _playerPlay(position);
      }
    } else {
      initPlay(newChapterId, position, autoplay);
    }
  };

  const initPlay = (newChapterId, position = 0, autoplay = true) => {
    dispatchPlayerEvent(PLAYER_EVENTS.CALL_INIT_PLAY, {position});

    storeSetting(Settings.playerState.id, playerID);
    updatePlayerState({
      chapterId: newChapterId,
      volume: 1,
    });
    dispatch(setPlayerParams({
      isSmallPlayerActive: true
    }));

    if (newChapterId === chapterId) {
      if (autoplay) {
        _playerPlay(position);
      } else {
        setPlayerPosition(position);
      }
    } else {
      getPlayer().addEventListener("canplaythrough", () => {
        if (autoplay) {
          _playerPlay(position);
        } else {
          setPlayerPosition(position);
        }
      }, { once: true });
    }
  };

  const stopPlayer = () => {
    savePlayPosition();

    updatePlayerState({
      chapterId: "",
    });

    dispatch(setPlayerParams({
      isSmallPlayerActive: false
    }));

    const player = getPlayer();
    if (player) {
      if (!player.paused) {
        _playerPause();
      }
    }
  };

  const endedHandler = () => {
    const prevented = dispatchPlayerEvent(PLAYER_EVENTS.END);

    if (!prevented) {
      seekPlay(true);
    }
  };

  const loadedMetaDataHandler = (event) => {
    updatePlayerState({ duration: event.target.duration });
  };

  const playHandler = () => {
    dispatchPlayerEvent(PLAYER_EVENTS.PLAY);

    if (brokenAudios.includes(chapterId)) { // Remove from the broken audios array.
      const audioIndex = brokenAudios.findIndex(item => item === chapterId);
      if (audioIndex !== -1) {
        brokenAudios.splice(audioIndex, 1);
        dispatch(setBrokenAudios([...brokenAudios]));
      }
    }
  };

  const pauseHandler = () => {
    dispatchPlayerEvent(PLAYER_EVENTS.PAUSE);
  };

  const updatePlayerMediaData = () => {
    if (!window.MediaMetadata) {
      return;
    }
    if ("mediaSession" in navigator && cover) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: book.title,
        album: trackTitle,
        artwork: [
          { src: cover, type: "image/png" },
        ]
      });
      navigator.mediaSession.setActionHandler("play", () => {
        _playerPlay();
      });
      navigator.mediaSession.setActionHandler("pause", () => {
        _playerPause();
      });
      navigator.mediaSession.setActionHandler("seekbackward", () => {
        skipPlay(-10);
      });
      navigator.mediaSession.setActionHandler("seekforward", () => {
        skipPlay(10);
      });
      navigator.mediaSession.setActionHandler("previoustrack", () => {
        seekPlay(false);
      });
      navigator.mediaSession.setActionHandler("nexttrack", () => {
        seekPlay(true);
      });
    }
  };

  const errorHandler = () => {
    updatePlayerState({
      chapterId,
      volume: 1
    });
    _playerPause();

    if (!brokenAudios.includes(chapterId)) { // Add to the broken audios array.
      dispatch(setBrokenAudios([...brokenAudios, chapterId]));
    }

    dispatch(actionAddMessage("@audioNotAvailable"));
  };

  // Fetch paragraphs audio positions (and chapter audio wave form as there is the single API).
  // Waveforms data is needed to draw audio wave, detect para position in the track or vice-versa.
  // It is needed in the Reader and Big Audio Player only, so its call is optional.
  const downloadWaveformsData = useCallback((audioTrack = currentTrack) => {
    if (audioTrack && !waveformsDictionary[audioTrack.id]) {
      dispatch(fetchAudioTrack(makeJsonUrl(audioTrack.mp3), audioTrack.id));
    }
  }, [waveformsDictionary, currentTrack]);

  // Fixes the IOS can't play the audio before the interaction with the page.
  // Reproduce: use Iphone, go to Book List with shown icons under the items, refresh the page,
  // click on the play icon, see thrown error in the console and audio hadn't started.
  // (answered Nov 9 at 11:20, by luky) https://stackoverflow.com/questions/31776548/why-cant-javascript-play-audio-files-on-iphone-safari
  useEffect(() => {
    if (getIsIOSDevice()) {
      const player = getPlayer();
      if (player) {
        player.src = silenceSrc;
        player.play().catch(() => {});
        player.pause();
        player.currentTime = 0;
        document.addEventListener("touchstart", () => {
          if (player.src.includes(silenceSrc)) {
            player.play().then(() => {
              player.pause();
            }).catch(() => {});
          }
        }, {once: true});
      }
    }
  }, [audioRef]);

  // Fix audio is not loading and "canplaythrough" event is not fired when src is set on the IOS.
  // (answered Apr 12 '18 at 10:19, by Gabriele Petrioli) https://stackoverflow.com/questions/49792768/js-html5-audio-why-is-canplaythrough-not-fired-on-ios-safari
  useEffect(() => {
    if (src) {
      getPlayer().load();
    }
  }, [src]);

  // Sets first possible audio chapter if para is "1" (usually "1" is set when para is not known).
  useEffect(() => {
    if (chapterId && !currentTrack && audioChaptersList?.length) {
      const [, para] = chapterId.split(".");
      if (para === "1") {
        updatePlayerState({ chapterId: audioChaptersList[0].id });
      }
    }
  }, [chapterId, currentTrack, audioChaptersList]);

  // Update playback rate when is updated in state.
  useEffect(() => {
    _setPlayerPlayback(playbackRate);
  }, [_setPlayerPlayback, playbackRate]);

  // stop playing when player 'on another TAB' isPlaying
  useEffect(() => {
    const handler = () => {
      if (playerId && playerID !== playerId) {
        _playerPause();
      }
    };

    if (isPlaying) {
      window.addEventListener("storage", handler);
    }

    return () => {
      window.removeEventListener("storage", handler);
    };
  }, [_playerPause, isPlaying]);

  // Updates value in the player state ref.
  // "useMemo" for the ref be updated exactly the same time "state" is.
  useMemo(() => {
    audioStateRef.current = playerState;
  }, [playerState]);

  useEffect(() => {
    updatePlayerMediaData();
  }, [trackTitle]);

  return (
    <AudioContext.Provider
      value={{
        savePlayPosition,
        getPlayerPosition,
        initPlay,
        initPlayIfNotTheSameBook,
        toggleChapterPlaying,
        setPlayerPosition,
        updatePlayerState,
        seekPlay,
        skipPlay,
        stopPlayer,
        getPlayer,

        downloadWaveformsData,
        subscribePlayerEvents: addObserverListener,
        unsubscribePlayerEvents: removeObserverListener,

        chapterId,
        volume,
        duration,
        isPlaying,
        isMute,
        playbackRate,

        audioChapters,
      }}>
      {children}
      <AudioPlayer
        ref={audioRef}
        src={src}
        volume={parseFloat(volume) || 1}
        muted={isMute}
        onPlay={playHandler}
        onPause={pauseHandler}
        onLoadedMetadata={loadedMetaDataHandler}
        onCanPlayThrough={() => {
          dispatchPlayerEvent(PLAYER_EVENTS.CAN_PLAY_THROUGH);
        }}
        onEnded={endedHandler}
        onError={errorHandler}
      />
    </AudioContext.Provider>
  );
};

AudioProvider.displayName = TAG;

AudioProvider.propTypes = {
  children: PropTypes.node
};

/**
 * @returns {{
    initPlay: function,
    initPlayIfNotTheSameBook: function,
    toggleChapterPlaying: function,
    seekPlay: function,
    skipPlay: function,
    stopPlayer: function,

    getPlayer: function,
    getPlayerPosition: function,
    setPlayerPosition: function,
    updatePlayerState: function,
    downloadWaveformsData: function,
    savePlayPosition: function,

    subscribePlayerEvents: function,
    unsubscribePlayerEvents: function,

    chapterId: string,
    volume: number,
    duration: number,
    isPlaying: boolean,
    stopTimeout: number,
    isMute: boolean,
    playbackRate: number,

    audioChapters: [],
 * }}
 */
export const useAudioContext = () => useContext(AudioContext);
