import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import deepEqual from "deep-equal";

import LanguageSelectorView from "./LanguageSelectorView";
import { searchLanguagesSelector, useLoader } from "../../redux/selectors";
import componentsId from "../constants";
import { SearchActions } from "../search/search.actions";
import { treesActions } from "../../redux/trees/actions";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { Period } from "src/utils/AdvSearchTypes";

const renderSlotRightSide = (item) => item.langCount;

const SearchLanguageSelector = () => {
  const dispatch = useDispatch();
  const remToPx = useRemToPx();
  const searchParams = useSelector((state) => state.search.searchParams);
  const searchLang = useSelector((state) => state.search.searchLang);
  const checked = useSelector((state) => state.folderTree.checked);
  const languages = useSelector(searchLanguagesSelector, (left, right) => {
    return deepEqual(left, right);
  });
  const isLoading = useLoader(componentsId.SEARCH_ID);

  const [initOpen, setInitOpen] = useState(false);

  const newLangs = languages.filter((l) => l.checked).map((item) => item.id);

  const [languagesTemp, setLanguagesTemp] = useState(newLangs);

  useEffect(() => {
    const newLangs = languages.filter((l) => l.checked).map((item) => item.id);
    newLangs.sort();
    languagesTemp.sort();
    if (!deepEqual(languagesTemp, newLangs)) {
      setLanguagesTemp(newLangs);
    }
  }, [languages]); // not needed to update on languagesTemp updates

  useEffect(() => {
    // need prevent init make search on open
    if (!initOpen) {
      setInitOpen(true);
      return false;
    }
    let timeout = setTimeout(() => {
      // keep only checked languagesTemp and clear other checks
      const updatedChecks = checked.filter(
        (item) => typeof item === "string" && languagesTemp.includes(item),
      );

      dispatch(treesActions.updateChecked(updatedChecks));
      const newSearchParams = {
        ...searchParams,
        context: "",
        folders: languagesTemp,
        firstSearch: false,
      };
      if (languagesTemp.length > 0) {
        // reset period to custom if some lang is selected
        newSearchParams.extras.period = Period.custom;
      }

      dispatch(
        SearchActions.fetchSearch(newSearchParams, 1, 1), //remove delay 1 sec
      );
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [languagesTemp]);

  const handleChange = useCallback((event, item) => {
    const itemId = item.id;

    setLanguagesTemp((languagesTempOld) => {
      const languagesTempNew = [...languagesTempOld];

      const itemIndex = languagesTempOld.indexOf(itemId);
      if (itemIndex === -1) {
        languagesTempNew.push(itemId);
      } else {
        languagesTempNew.splice(itemIndex, 1);
      }

      return languagesTempNew;
    });
  }, []);

  return (
    <LanguageSelectorView
      isDisabled={isLoading}
      items={languages}
      itemsChecked={languagesTemp}
      itemsHighlighted={[searchLang]}
      onClickItem={handleChange}
      defaultHeight={languages.length < 16 ? languages.length * remToPx(1) : 285}
      renderSlotRightSide={renderSlotRightSide}
    />
  );
};

export default SearchLanguageSelector;
