import { useEffect, useRef, useCallback } from "react";
import { isEventKeys } from "../utils/Utils";
import { selectorFocusableElements } from "../utils/AccessibilityUtils";
import { useDispatch, useSelector } from "react-redux";
import { actionAddMessage, actionUpdateSetting } from "../redux/actions";
import { Settings } from "../utils/Settings";
import {
  updateSizeValue,
  TreeFontSizes,
  SearchFontSizes,
  UpdateSize,
  ZoomValues,
} from "../utils/ThemeUtils";
import { useTranslation } from "react-i18next";
import { useViewMode } from "./viewModeHooks";
import { isEventKey, isFocusTextInput, KeyCodes } from "../shared/utils/dom";
import { ReaderFontSizes } from "../shared/utils/theme";

const SECTIONS = {
  SEARCH_PANEL: {
    name: "SEARCH_PANEL",
    selector: ".search-controlled-list",
    sizeModel: SearchFontSizes,
    settingsKey: Settings.searchFontSize.id,
    hint: "set_search_font_size",
  },
  READER_PANEL: {
    name: "READER_PANEL",
    selector: ".reader-content-wrap",
    sizeModel: ReaderFontSizes,
    settingsKey: Settings.readerFontSize.id,
    hint: "set_reader_font_size",
  },
  HEADER: { name: "HEADER", selector: "header" },
  MENU: { name: "MENU", selector: ".sideMenuMain" },
  TREE: {
    name: "TREE",
    selector: ".menuTreeWrap",
    sizeModel: TreeFontSizes,
    settingsKey: Settings.treeFontSize.id,
    hint: "set_tree_font_size",
  },
  TOP_BAR: { name: "TOP_BAR", selector: ".topBar" },
  MAIN: { name: "MAIN", selector: ".mainContent" },
  RIGHT_PANEL: { name: "RIGHT_PANEL", selector: ".right-panel" },
  FOOTER: { name: "FOOTER", selector: "footer" },
};

const updateKeyValue = (event) => {
  if (isEventKeys(event, [KeyCodes.plus, KeyCodes.numPlus])) {
    return UpdateSize.increase;
  } else if (isEventKeys(event, [KeyCodes.minus, KeyCodes.numMinus])) {
    return UpdateSize.decrease;
  } else if (isEventKeys(event, [KeyCodes.zero, KeyCodes.keyZ])) {
    return UpdateSize.default;
  }
  return undefined;
};

const useKeyboard = () => {
  const zoom = useSelector((state) => state.settings.zoom);
  const readerFontSize = useSelector((state) => state.settings.readerFontSize);
  const treeFontSize = useSelector((state) => state.settings.treeFontSize);
  const searchFontSize = useSelector((state) => state.settings.searchFontSize);
  const { sizeMode } = useViewMode();
  const dispatch = useDispatch();
  const positionRef = useRef(0);
  const hoveredRef = useRef(0);
  const timerRef = useRef(0);
  const blinkerTimerRef = useRef(0);
  const { t } = useTranslation();

  const updateSectionSize = useCallback(
    (currentValue, keyValue, section) => {
      let newSizeValue = updateSizeValue(currentValue, keyValue, section.sizeModel, sizeMode);
      if (newSizeValue !== currentValue) {
        const valueDisplayedInUI = Math.ceil(newSizeValue * (zoom / 100));
        dispatch(actionAddMessage(t(section.hint, { value: valueDisplayedInUI })));
        dispatch(actionUpdateSetting(section.settingsKey, newSizeValue));
        return true;
      }
      return false;
    },
    [dispatch, t],
  );

  useEffect(() => {
    const onDocumentKeyDown = (event) => {
      const upArrow = isEventKey(event, KeyCodes.upArrow);
      const downArrow = isEventKey(event, KeyCodes.downArrow);
      const useNavigation = event.shiftKey && (upArrow || downArrow);
      const useFullscreenKey = isEventKey(event, KeyCodes.f11);

      if (useFullscreenKey) {
        event.preventDefault();
      }

      if (event.ctrlKey || event.metaKey) {
        // checking if active element is actually input
        // because we don't need to block default [ctrl + z]
        if (isFocusTextInput()) {
          return;
        }

        // TODO update and use constant keys
        let keyValue = updateKeyValue(event);

        if (hoveredRef.current && keyValue !== undefined) {
          const { name } = hoveredRef.current;

          if (name === SECTIONS.SEARCH_PANEL.name) {
            updateSectionSize(searchFontSize, keyValue, SECTIONS.SEARCH_PANEL);
            event.preventDefault();
            return;
          }
          if (name === SECTIONS.TREE.name) {
            updateSectionSize(treeFontSize, keyValue, SECTIONS.TREE);
            event.preventDefault();
            return;
          }
          if (name === SECTIONS.READER_PANEL.name) {
            updateSectionSize(readerFontSize, keyValue, SECTIONS.READER_PANEL);
            event.preventDefault();
            return;
          }
        }
        let zoomValue = updateSizeValue(zoom, keyValue, ZoomValues, sizeMode);
        if (zoomValue && zoomValue !== zoom) {
          dispatch(actionAddMessage(t("set_zoom_level", { value: zoomValue })));
          dispatch(actionUpdateSetting(Settings.zoom.id, zoomValue));
          event.preventDefault();
          return;
        }
      }

      const popups = document.querySelectorAll(".dialog-background");
      if (popups.length) {
        return;
      }

      if (useNavigation) {
        const sections = getPresentSections();
        const max = sections.length - 1;
        let shouldUpdate = false;

        if (upArrow) {
          if (positionRef.current - 1 >= 0) {
            positionRef.current--;
            shouldUpdate = true;
          }
        } else if (downArrow) {
          if (positionRef.current + 1 <= max) {
            positionRef.current++;
            shouldUpdate = true;
          }
        }

        if (shouldUpdate) {
          const possibleSection = sections[positionRef.current];
          let section = document.querySelector(possibleSection.selector);

          if (section) {
            // find a possible focusable html element
            const focusableSectionElement = section.querySelector(selectorFocusableElements);
            if (focusableSectionElement) {
              // set blinker animation to draw attention to a focused section
              section.classList.add("temporary-focused");
              // removes blinker animation class in 0.6s
              blinkerTimerRef.current = setTimeout(() => {
                section.classList.remove("temporary-focused");
              }, 250);
              focusableSectionElement.focus();
            }
          }
        }
      }
    };

    const onMouseWheel = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.deltaY !== 0) {
        const updateSize = event.deltaY < 0 ? UpdateSize.increase : UpdateSize.decrease;
        if (event.target) {
          const readerPanel = event.target.closest(SECTIONS.READER_PANEL.selector);
          if (readerPanel) {
            updateSectionSize(readerFontSize, updateSize, SECTIONS.READER_PANEL);
            event.preventDefault();
            return false;
          }
          const folderTree = event.target.closest(SECTIONS.TREE.selector);
          if (folderTree) {
            updateSectionSize(treeFontSize, updateSize, SECTIONS.TREE);
            event.preventDefault();
            return false;
          }
          const searchList = event.target.closest(SECTIONS.SEARCH_PANEL.selector);
          if (searchList) {
            updateSectionSize(searchFontSize, updateSize, SECTIONS.SEARCH_PANEL);
            event.preventDefault();
            return false;
          }
        }

        let zoomValue = updateSizeValue(zoom, updateSize, ZoomValues, sizeMode);
        if (zoom !== zoomValue) {
          dispatch(actionAddMessage(t("set_zoom_level", { value: zoomValue })));
          dispatch(actionUpdateSetting(Settings.zoom.id, zoomValue));
        }

        event.preventDefault();
        return false;
      }
      return true;
    };

    const onMouseOver = (event) => {
      hoveredRef.current = getClosestSection(event.target);
    };

    document.addEventListener("keydown", onDocumentKeyDown);
    document.addEventListener("wheel", onMouseWheel, { passive: false });
    document.addEventListener("mouseover", onMouseOver, { passive: false });

    return () => {
      document.removeEventListener("keydown", onDocumentKeyDown);
      document.removeEventListener("wheel", onMouseWheel);
      document.removeEventListener("mouseover", onMouseOver);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (blinkerTimerRef.current) {
        clearTimeout(blinkerTimerRef.current);
      }
    };
  }, [zoom, readerFontSize, treeFontSize, dispatch, searchFontSize, updateSectionSize]);

  // disable default browser zoom
  useEffect(() => {
    const onDocumentKeyDown = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (isEventKeys(event, [KeyCodes.plus, KeyCodes.numPlus]) ||
          isEventKeys(event, [KeyCodes.minus, KeyCodes.numMinus]))
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", onDocumentKeyDown);
    return () => {
      document.removeEventListener("keydown", onDocumentKeyDown);
    };
  }, []);
};

export default useKeyboard;

export const useHotKey = (hotkeyData, action) => {
  useEffect(() => {
    const onDocumentKeyDown = (event) => {
      const isShift = hotkeyData.shift === undefined || (hotkeyData.shift && event.shiftKey);
      const isAlt = hotkeyData.alt === undefined || (hotkeyData.alt && event.altKey);
      const isCtrl =
        hotkeyData.ctrl === undefined || (hotkeyData.ctrl && (event.ctrlKey || event.metaKey));
      if (isShift && isAlt && isCtrl && isEventKeys(event, hotkeyData.keys)) {
        const preventDefault = action();
        if (preventDefault) {
          event.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", onDocumentKeyDown);
    return () => {
      document.removeEventListener("keydown", onDocumentKeyDown);
    };
  }, [hotkeyData, action]);
};

const getPresentSections = () => {
  let sections = [];
  Object.keys(SECTIONS).forEach((item) => {
    if (document.querySelector(SECTIONS[item].selector)) {
      sections.push(SECTIONS[item]);
    }
  });
  return sections;
};

const getClosestSection = (element) => {
  if (!element) {
    return undefined;
  }
  let targetSection;
  let section;
  const sectionKeys = Object.keys(SECTIONS);
  for (let i = 0; i < sectionKeys.length; i++) {
    section = SECTIONS[sectionKeys[i]];
    targetSection = element.closest(section.selector);
    if (targetSection) {
      break;
    }
  }
  return section;
};
