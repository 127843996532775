import { useEffect, useState } from "react";
import {
  WINDOW_EVENT_IS_LAST_POINTER_MOUSE,
  WINDOW_KEY_IS_LAST_POINTER_MOUSE
} from "./what-input.utils";

export const useWhatInputIsLastPointerMouse = () => {
  const [
    isLastInputMouse, setIsLastInputMouse
  ] = useState(window[WINDOW_KEY_IS_LAST_POINTER_MOUSE]);

  useEffect(() => {
    const listener = (e) => { setIsLastInputMouse(e.detail); };
    window.addEventListener(WINDOW_EVENT_IS_LAST_POINTER_MOUSE, listener);
    return () => {
      window.removeEventListener(WINDOW_EVENT_IS_LAST_POINTER_MOUSE, listener);
    };
  }, []);

  return isLastInputMouse;
};

// Lite hook to return a value only.
// For using inside {IconButton}. If troubles, may be need to implement {MainProvider}
// and store there this and {isMobile, isTablet, ...} too.
export const useWhatInputValueIsLastPointerMouse = () => {
  return window[WINDOW_KEY_IS_LAST_POINTER_MOUSE];
};
