/**
 * Includes type of SC elements and special items of list of sc elements
 */
export const ItemTypes = {
  folder: "folder", // TODO maybe better change to topic
  highlight: "highlight",
  editorHighlight: "editor_highlight",
  editorTemp: "editor_temp",
  note: "note",
  bookmark: "bookmark",
  back: "back",
};

export const PanelState = {
  none: "n",
  search: "s",
  preview: "p",
  previewFullscreen: "pf",
};

export const STUDY_CENTER_MIN_HEIGHT_LIST_ROW = 50;
export const STUDY_CENTER_TREE_ITEM_HEIGHT = 2;

export const RootFolder = {
  title: "studyCenter",
  titleEditor: "editorCenter",
  type: "folder",
  id: "root",
};

export const SubscriptionTopicId = "6d6f3cc9-4466-4542-8787-3a6d43c0a4bc";

export const SubscriptionTopicFolder = {
  title: "favoriteSubscription",
  label: "favoriteSubscription",
  type: "folder",
  id: SubscriptionTopicId,
};

export const SystemFolderIds = [RootFolder.id, SubscriptionTopicFolder.id];

export const UnderlineColor = "00000000-0000-0000-0000-000000000000";

export const Types = {
  folder: 0,
  highlight: 1,
  note: 2,
  bookmark: 3,
};

export const SortBy = {
  lu: "lu",
  title: "title",
  text: "text",
  tag: "tag",
};

export const STORAGE_KEY_LAST_SC_ACTIONS = "lastScActions";
