export const LOADER_ID_SHOP = "shop/loader";

export const shopActionConstants = {
  FETCH_ALL_SHOP_BOOKS_SUCCESS: "FETCH_ALL_SHOP_BOOKS_SUCCESS",
  FETCH_SHOP_BOOKS_SUCCESS: "FETCH_SHOP_BOOKS_SUCCESS",
  FETCH_SHOP_BOOKS_FAILED: "FETCH_SHOP_BOOKS_FAILED",
  FETCH_SHOP_BOOKS_PENDING: "FETCH_SHOP_BOOKS_PENDING",
  FETCH_SHOP_BOOKS: "FETCH_SHOP_BOOKS",
  FETCH_PACKAGES_SUCCESS: "FETCH_PACKAGES_SUCCESS",
  FETCH_PACKAGES_PENDING: "FETCH_PACKAGES_PENDING",
  FETCH_PACKAGES: "FETCH_PACKAGES",
  RESET_SHOP: "RESET_SHOP",
  RESTORE_SHOP: "RESTORE_SHOP"
};

export const shopActions = {
  fetchShopBooksSuccess: (data) => ({
    type: shopActionConstants.FETCH_SHOP_BOOKS_SUCCESS,
    data
  }),  
  fetchAllShopBooksSuccess: (data) => ({
    type: shopActionConstants.FETCH_ALL_SHOP_BOOKS_SUCCESS,
    data
  }),
  fetchShopBooksFailed: (error) => ({
    type: shopActionConstants.FETCH_SHOP_BOOKS_FAILED,
    data: error
  }),
  fetchShopBooksPending: (data) => ({
    type: shopActionConstants.FETCH_SHOP_BOOKS_PENDING,
    data
  }),
  fetchShopBooks: (data) => ({
    type: shopActionConstants.FETCH_SHOP_BOOKS,
    data
  }),
  fetchPackagesPending: (data) => ({
    type: shopActionConstants.FETCH_PACKAGES_PENDING,
    data
  }),
  fetchPackagesSuccess: (data) => ({
    type: shopActionConstants.FETCH_PACKAGES_SUCCESS,
    data
  }),
  fetchPackages: () => ({
    type: shopActionConstants.FETCH_PACKAGES
  }),
  resetShop: () => ({
    type: shopActionConstants.RESET_SHOP
  }),
  restoreShop: (shopItems) => ({
    type: shopActionConstants.RESTORE_SHOP,
    data: shopItems
  })
};
