export const STORAGE_KEY_RESIZER_SIZES = "resizer";

export const resizeIds = {
  LEFT_MAIN_DRAG: "leftMainDrag",
  RIGHT_MAIN_DRAG: "rightMainDrag",
  RIGHT_MIDDLE_DRAG: "rightMiddleDrag",
  LEFT_MIDDLE_DRAG: "leftMiddleDrag",
};

export const isDragId = (id) => Object.values(resizeIds).indexOf(id) > -1;

/**
 * TODO cover by tests
 * @param {string} phantomDragId 
 * @param {number} phantomDragPosition 
 * @param {number} left 
 * @param {number} leftNoPanel 
 * @param {number} right 
 * @param {number} rightNoPanel 
 * @param {number} minMainWidth 
 * @returns {{
 *  left,
 *  leftNoPanel,
 *  right,
 *  rightNoPanel,
 * }} 
 */
export const calculateDragEnd = (
  phantomDragId,
  phantomDragPosition,
  left,
  leftNoPanel,
  right,
  rightNoPanel,
  minMainWidth,
) => {
  const newSizes = {};
  switch (phantomDragId) {
    case resizeIds.LEFT_MIDDLE_DRAG: {
      newSizes.left = phantomDragPosition;
      // Prevent shrink main part less than min main part width.
      const distanceLeftToRight = right - phantomDragPosition;
      const distanceLeftToRightNoPanel = rightNoPanel - phantomDragPosition;
      if (distanceLeftToRight < minMainWidth) {
        newSizes.right = right + (minMainWidth - distanceLeftToRight);
      }
      if (distanceLeftToRightNoPanel < minMainWidth) {
        newSizes.rightNoPanel = rightNoPanel + (minMainWidth - distanceLeftToRightNoPanel);
      }
      break;
    }
    case resizeIds.LEFT_MAIN_DRAG: {
      newSizes.leftNoPanel = phantomDragPosition;
      // Prevent shrink main part less than min main part width.
      const distanceLeftToRight = right - phantomDragPosition;
      const distanceLeftToRightNoPanel = rightNoPanel - phantomDragPosition;
      if (distanceLeftToRight < minMainWidth) {
        newSizes.right = right + (minMainWidth - distanceLeftToRight);
      }
      if (distanceLeftToRightNoPanel < minMainWidth) {
        newSizes.rightNoPanel = rightNoPanel + (minMainWidth - distanceLeftToRightNoPanel);
      }
      break;
    }
    case resizeIds.RIGHT_MAIN_DRAG: {
      newSizes.rightNoPanel = phantomDragPosition;
      // Prevent shrink main part less than min main part width.
      const distanceLeftToRight = phantomDragPosition - left;
      const distanceLeftToRightNoPanel = phantomDragPosition - leftNoPanel;
      if (distanceLeftToRight < minMainWidth) {
        newSizes.left = left - (minMainWidth - distanceLeftToRight);
      }
      if (distanceLeftToRightNoPanel < minMainWidth) {
        newSizes.leftNoPanel = leftNoPanel - (minMainWidth - distanceLeftToRightNoPanel);
      }
      break;
    }
    case resizeIds.RIGHT_MIDDLE_DRAG: {
      newSizes.right = phantomDragPosition;
      // Prevent shrink main part less than min main part width.
      const distanceLeftToRight = phantomDragPosition - left;
      const distanceLeftToRightNoPanel = phantomDragPosition - leftNoPanel;
      if (distanceLeftToRight < minMainWidth) {
        newSizes.left = left - (minMainWidth - distanceLeftToRight);
      }
      if (distanceLeftToRightNoPanel < minMainWidth) {
        newSizes.leftNoPanel = leftNoPanel - (minMainWidth - distanceLeftToRightNoPanel);
      }
      break;
    }
    default:
      break;
  }

  return newSizes;
};
