/* eslint-disable no-console */
import { put, call, select, takeEvery, takeLatest, all, takeLeading } from "redux-saga/effects";

import {
  FETCH_DIALOG_CONTENTS,
  ACTION_FETCH_AUDIO_WAVE,
  FETCH_GEO_IP_DATA,
  ACTION_CF_TYPO,
  ACTION_FETCH_CF,
  ACTION_SEND_CF,
  FETCH_ABBREVIATIONS,
} from "./constants";
import {
  actionLoading,
  actionFetchCFSuccess,
  actionSendCFStatus,
  actionAddMessage,
  fetchAbbreviationsSuccess,
  fetchDialogContentsSuccess,
} from "./actions";
import { CFStatus } from "../utils/Utils";
import { getBookWaveformLoaderId } from "../components/reader/ReaderUtils";
import {
  getWaveformRequest,
  getGeoIpData,
  sendCFTypo,
  getContactUsRequest,
  sendContact,
  getAbbreviationsRequest,
  getChapterContentRequest,
} from "../api/API";
import { putAudioTrack, actionFetchGeoIpDataSuccess } from "./actions";
import componentsId from "../components/constants";
import { TOAST_TYPE } from "../components/popup/Toaster";
import { getBookOrigin } from "../shared/utils/content";

const TAG = "SUPPORTSAGA";

function* actionFetchWaveformAsync(action) {
  const { url, chapterId } = action.data;
  if (url && chapterId) {
    let loader = getBookWaveformLoaderId(chapterId);
    const bookId = getBookOrigin(chapterId);
    try {
      const { waveformsDictionary } = yield select((state) => state.audioPlayer);
      if (!waveformsDictionary[chapterId]) {
        yield put(actionLoading(loader));
        const { waveform, paragraphsAudioPositions } = yield call(getWaveformRequest, url, bookId);
        yield put(
          putAudioTrack({
            waveform,
            paragraphsAudioPositions,
            chapterId,
          }),
        );
      }
    } catch (error) {
      console.log(TAG, "An error while fetching waveform", error);
    } finally {
      yield put(actionLoading(loader, true));
    }
  }
}

function* fetchGeoIpData() {
  try {
    const { geoIpData } = yield select((state) => state.settings);
    if (Object.keys(geoIpData).length === 0) {
      const data = yield call(getGeoIpData);
      if (data) {
        yield put(actionFetchGeoIpDataSuccess(data));
      }
    }
  } catch (error) {
    console.log(TAG, "An error while getting geo data", error);
  }
}

function* sendCFTypoAsync(action) {
  try {
    yield put(actionLoading(componentsId.CF_TYPO_ID));
    const response = yield call(sendCFTypo, action.data);

    if (response.status === 202 || response.status === 204) {
      yield put(actionAddMessage("@cfTypoFieldSuccess", TOAST_TYPE.success));
    } else {
      yield put(actionAddMessage("@cfTypoFieldError", TOAST_TYPE.error));
    }
  } catch (error) {
    console.log(TAG, "function*sendCFTypoAsync -> error", error);
    yield put(actionAddMessage("Typeo error " + error, TOAST_TYPE.error));
  } finally {
    yield put(actionLoading(componentsId.CF_TYPO_ID));
  }
}

function* getContactAsync() {
  try {
    const response = yield call(getContactUsRequest);
    const contatTypes = response.data[0].contacts.map((item) => ({
      ...item,
      id: item.tag,
    }));
    yield put(actionFetchCFSuccess(contatTypes));
  } catch (error) {
    console.log("function*getContactAsync -> error: ", error);
  }
}

function* sendContactAsync(action) {
  try {
    yield put(actionLoading(componentsId.CF_SEND_ID));
    const response = yield call(sendContact, action.data);

    if (response.status === 202) {
      yield put(actionSendCFStatus(CFStatus.SUCCESS));
    } else {
      yield put(actionSendCFStatus(CFStatus.ERROR));
    }
  } catch (error) {
    yield put(actionAddMessage("Can't send contact data", TOAST_TYPE.error));
  } finally {
    yield put(actionLoading(componentsId.CF_SEND_ID));
  }
}

function* fetchAbbreviations() {
  try {
    const abbreviations = yield call(getAbbreviationsRequest);
    yield put(fetchAbbreviationsSuccess(abbreviations));
  } catch (error) {
    console.log("function*fetchAbbreviations => error: ".error);
  }
}

// FIXME
function* fetchDialogContentsAsync(action) {
  const { chapterIds, bookRealType, bookType } = action.data;
  const dialogContent = {};
  for (const chapterId of chapterIds) {
    dialogContent[chapterId] = yield call(getChapterContentRequest, chapterId, bookRealType, bookType);
  }
  yield put(fetchDialogContentsSuccess(dialogContent));
}

export default function* contentSaga() {
  yield all([
    takeLeading(ACTION_FETCH_AUDIO_WAVE, actionFetchWaveformAsync),
    takeLatest(FETCH_GEO_IP_DATA, fetchGeoIpData),
    takeLatest(ACTION_CF_TYPO, sendCFTypoAsync),
    takeLatest(ACTION_FETCH_CF, getContactAsync),
    takeLatest(ACTION_SEND_CF, sendContactAsync),
    takeEvery(FETCH_DIALOG_CONTENTS, fetchDialogContentsAsync),
    takeLatest(FETCH_ABBREVIATIONS, fetchAbbreviations),
  ]);
}
