import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { HideIcon } from "../views";
import { searchNavProcess } from "../../api/SearchAPI";
import { actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { SearchExamples } from "src/shared/constants"; 

import "./searchExample.scss";
import { SearchActions } from "../search/search.actions";
import { useSearchContext } from "../search/SearchContext";
import { URLS } from "../../shared/utils/url";

const removeFirstWord = (value) => {
  return value.substr(value.indexOf(" ") + 1);
};

export const SearchExampleView = ({ onClose }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const baseBible = useSelector((state) => state.settings.baseBible);
  const searchParams = useSelector((state) => state.search.searchParams);
  const languageUI = useSelector((state) => state.settings.languageUI);

  const { makeSearch } = useSearchContext();

  const handleHide = (event) => {
    if (onClose) {
      onClose();
    }
    event.preventDefault();
  };

  const onSearchExample = (query) => {
    if (!query) {
      return;
    }
    const extras = { ...searchParams.extras, type: "basic" };
    const searchValue = { query, extras };

    searchNavProcess(searchValue, baseBible).then((shortCutData) => {
      dispatch(SearchActions.setSearchQuery(query, languageUI));
      makeSearch({
        query,
        shortCutData,
      });
    });
  };

  const handleClickSearchExample = (event) => {
    const query = event.target?.innerText;

    if (query) {
      onSearchExample(query);
    }
    onClose();
  };

  const handleAbbreviationsClick = (e) => {
    const id = e.target.id;
    if (id) {
      dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
      history.push(id);
      onClose();
    }
  };

  const renderBlock = (blockValues) => {
    return (
      <div className="search-item-box">
        {blockValues.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className="searchString title">{t(item.title)}</div>
              {item.keys.map((key, keyIndex) => {
                return (
                  <div
                    key={keyIndex}
                    className="searchExample clickable"
                    onClick={handleClickSearchExample}>
                    {t(key)}
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <div className="search-example-view">
      <div className="search-example-view__title">{t("search_syntax_examples")}</div>
      <HideIcon onClick={handleHide} />
      <div className="flex-container">
        <div className="search-item-box full-width">
          <div className="searchString title">
            {t("bibliography")}:{" "}
            <span
              className="searchExample clickable"
              id={URLS.bibliographyEgw}
              onClick={handleAbbreviationsClick}>
              {t("EGW")}
            </span>
            <span className="delimiter" />
            <span
              className="searchExample clickable"
              id={URLS.bibliographyBible}
              onClick={handleAbbreviationsClick}>
              {t("bible")}
            </span>
          </div>
        </div>
        {renderBlock([SearchExamples.goToBookPage])}
        {renderBlock([SearchExamples.goToBibleRef])}
        {renderBlock([SearchExamples.wordsWithinBookEd])}
        {renderBlock([SearchExamples.phraseInBook])}

        <div className="search-item-box">
          <div className="searchString title">{t("Boolean operators")}:</div>
          <div className="search-box">
            {t("AND")}
            <div className="searchExample clickable" onClick={handleClickSearchExample}>
              {removeFirstWord(t("boolAnd"))}
            </div>
          </div>

          <div className="search-box">
            {t("OR")}
            <div className="searchExample clickable" onClick={handleClickSearchExample}>
              {removeFirstWord(t("boolOr"))}
            </div>
          </div>
          <div className="search-box">
            {t("NOT")}
            <div className="searchExample clickable" onClick={handleClickSearchExample}>
              {removeFirstWord(t("boolNot"))}
            </div>
          </div>
        </div>
        {renderBlock([SearchExamples.search_topic, SearchExamples.wildCardSearch])}
        {renderBlock([SearchExamples.proximityMatch])}
        {renderBlock([SearchExamples.minimumMatching])}
        {renderBlock([SearchExamples.orderedSearch])}
      </div>
    </div>
  );
};

SearchExampleView.propTypes = {
  elWidth: PropTypes.number,
  elLeft: PropTypes.number,
  onSearch: PropTypes.func,
  onClose: PropTypes.func,
  onOpenGlobal: PropTypes.func,
};

export default SearchExampleView;
