import { useEffect } from "react";

const useOutsideEvent = ({ eventType = "click", ref, callback, specialCondition = true }) => {
  const handleEvent = (event) => {
    if (ref.current && !ref.current.contains(event.target) && specialCondition) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener(eventType, handleEvent);

    return () => {
      document.removeEventListener(eventType, handleEvent);
    };
  });
};

export default useOutsideEvent;
