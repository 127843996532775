import { useCallback } from "react";

import { getLinkValue } from "src/utils/URLUtils";
import { cartActions } from "../redux/cart/actions";
import { actionAddMessage } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "./index";

const useBuyBook = () => {
  const dispatch = useDispatch();
  const { isLogin } = useAuth();
  const shopBooks = useSelector((state) => state.shop.shopBooks);

  return useCallback((bookId) => {
    const nBookId = bookId === "number" ? bookId : bookId.replace("b", "");

    if (isLogin) {
      const shopBook = shopBooks[nBookId];

      if (shopBook) {
        dispatch(cartActions.addToCart(shopBook));
        dispatch(actionAddMessage("@addedToCart"));
      }
    } else {
      dispatch(actionAddMessage("@pleaseLogin"));
    }
  }, [isLogin, shopBooks]);
};

export default useBuyBook;
