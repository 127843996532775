import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Select } from "./index";
import { getLabelById } from "../../utils/Utils";
import { NOT_ALLOWED_OPTION } from "../../shared/constants";

/**
 * @param options - [{id, label}]
 * @param value - id
 * @param onChange - f(id)
 * @param className
 */
const SelectAutocomplete = ({
  options: initialOptions,
  value: initialValue,
  onChange,
  onInputKeyUp,
  filterOptions,
}) => {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isKeyUpEvent, setKeyUpEvent] = useState(false);

  useEffect(() => {
    if (initialValue !== NOT_ALLOWED_OPTION && !isKeyUpEvent) {
      setValue(getLabelById(initialValue, initialOptions));
    }

    setOptions(initialOptions);
  }, [initialValue, initialOptions]);

  const handleChange = (item) => {
    setValue(item.label);
    onChange(item.id, item.code);
  };

  const handleInputKeyUp = (event) => {
    setKeyUpEvent(false);

    const inputValue = event.target.value;
    if (onInputKeyUp) {
      onInputKeyUp(inputValue);
      return;
    }

    if (filterOptions) {
      if (inputValue) {
        const filteredOptions = options.filter((item) => filterOptions(item, inputValue));

        if (filteredOptions.length === 1) {
          setKeyUpEvent(true);
          onChange(filteredOptions[0].id, filteredOptions[0].code);
          return;
        }

        onChange(NOT_ALLOWED_OPTION);
      } else {
        onChange("");
      }
    }
  };

  const handleInputChange = (event) => {
    const val = event.target.value;

    if (val.length >= 1) {
      setOptions(
        initialOptions.filter((item) => {
          return (
            item.label.toLowerCase().includes(val.toLowerCase()) ||
            (filterOptions && filterOptions(item, val))
          );
        }),
      );
    } else {
      setOptions(initialOptions);
    }
    setValue(val);
  };

  return (
    <Select
      options={options}
      value={{ value, options }}
      onChange={(value) => handleChange(value)}
      onRenderTitle={() => (
        <input
          className="inputField"
          type="text"
          value={value || ""}
          onChange={handleInputChange}
          onKeyUp={handleInputKeyUp}
        />
      )}
      onGetTitle={(item) => (item?.label ? item.label : "")}
    />
  );
};

SelectAutocomplete.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onInputKeyUp: PropTypes.func,
  filterOptions: PropTypes.func,
};

export default SelectAutocomplete;
