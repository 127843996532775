import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import deepEqual from "deep-equal";
import classnames from "classnames";

import ReactResizeDetector from "react-resize-detector/build/withPolyfill";
import { ListView } from "../views";
import { bookIdRegexStrict, CONTENT_CLASSES, } from "../../shared/utils/content";
import { useSelector } from "react-redux";
import { useViewMode } from "../../hooks";
import FolderTreeItem from "./FolderTreeItem";

import "./Tree.scss";

const getListItemRowSizeAndPosition = (listInstance, itemIndex) => {
  return listInstance.Grid.state.instanceProps.rowSizeAndPositionManager.getSizeAndPositionOfCell(
    itemIndex
  );
};

const getListScrollTop = (listInstance) => {
  return listInstance.Grid.state.scrollTop;
};

// [1445] Regulates containing the only ONE BOOK EXPANDED at the time.
const getExpandedWithOnlyLastBookExpanded = (expanded) => {
  const expandedNew = [];
  const bookIdsRemoved = [];

  let expandedId, idBookLast;
  for (let i = expanded.length - 1; i >= 0; i--) {
    expandedId = expanded[i];

    if (bookIdRegexStrict.test(expandedId)) {
      if (idBookLast) {
        if (idBookLast !== expandedId) {
          bookIdsRemoved.push(expandedId);
          continue;
        }
      } else {
        idBookLast = expandedId;
      }
    }

    expandedNew.push(expandedId);
  }

  expandedNew.reverse();

  return {
    expandedNew,
    bookIdsRemoved,
    idBookLast,
  };
};

const Tree = React.memo(({
  className, isKeepOnlyOneBookExpanded,
  folderTree, expanded, onChangeExpanded,
  currentFolder, nodeList,
  onItemExpand, onItemCheck, onItemClick, onRightClick
}) => {
  const isTreeFetched = !!folderTree.length;

  const scrollRef = useRef();
  const listRef = useRef();

  const { zoom } = useViewMode();
  const treeFontSize = useSelector((state) => state.settings.treeFontSize);

  const [scrollToId, onChangeScrollToId] = useState();
  const [preserveScrollData, setPreserveScrollData] = useState({
    id: undefined,
    offset: undefined,
    size: undefined,
    listScrollTop: undefined,
  });

  const currentFolderId = currentFolder?.id;

  // Expands the "current folder" item(s) (book, chapter or subchapter).
  useEffect(() => {
    if (!currentFolderId) {
      return;
    }

    const expandedNewSet = new Set(expanded);

    nodeList.forEach((item) => {
      if (item.className !== CONTENT_CLASSES.PARAGRAPH) {
        expandedNewSet.add(item.id);
      }
    });

    const expandedNew = isKeepOnlyOneBookExpanded
      ? getExpandedWithOnlyLastBookExpanded(Array.from(expandedNewSet)).expandedNew
      : Array.from(expandedNewSet);

    if (!deepEqual(expandedNew, expanded)) {
      onChangeExpanded(expandedNew, currentFolder);
    }

    onChangeScrollToId(currentFolderId);
  }, [isTreeFetched, currentFolderId, onChangeExpanded, isKeepOnlyOneBookExpanded]);

  // VALIDATES {expanded} and helps to preserve a folder position if some items above the folder
  // were removed (e.g. book A had been collapsed after book B expanded)
  useEffect(() => {
    if (!isKeepOnlyOneBookExpanded) {
      return;
    }

    const {
      expandedNew, bookIdsRemoved, idBookLast,
    } = getExpandedWithOnlyLastBookExpanded(expanded);

    if (!deepEqual(expandedNew, expanded)) {
      onChangeExpanded(expandedNew, currentFolder);
    }

    if (bookIdsRemoved.length) {
      const indexLonelyBook = folderTree.findIndex((item) => item.id === idBookLast);

      const isSomeBooksWereAboveLonelyBook = bookIdsRemoved.some((bookIdRemoved) => {
        return folderTree.findIndex((item) => item.id === bookIdRemoved) < indexLonelyBook;
      });

      if (isSomeBooksWereAboveLonelyBook) {
        setPreserveScrollData({
          id: idBookLast,
          listScrollTop: getListScrollTop(listRef.current),
          ...getListItemRowSizeAndPosition(listRef.current, indexLonelyBook),
        });
      }
    }
  }, [expanded, onChangeExpanded, isKeepOnlyOneBookExpanded]);

  // Preserves a folder position if some items above the folder were removed.
  useMemo(() => {
    if (!listRef.current || !scrollRef.current || !preserveScrollData.id) {
      return;
    }

    const { id } = preserveScrollData;
    const index = folderTree.findIndex((folderItem) => folderItem.id === id);

    if (index === -1) {
      return;
    }

    const offset = preserveScrollData.size * index;
    const listScrollTop = getListScrollTop(listRef.current);

    scrollRef.current.scrollTop(
      listScrollTop
      - (listScrollTop - offset)
      - (preserveScrollData.offset - preserveScrollData.listScrollTop)
    );
  }, [preserveScrollData]);

  // Preserves a folder position if some items above the folder were removed.
  useEffect(() => {
    if (!listRef.current || !scrollToId) {
      return;
    }

    const rowIndex = folderTree.findIndex(
      (folderItem) => folderItem.id === scrollToId
    );

    if (rowIndex === -1) {
      return;
    }

    const scrollTop = scrollRef.current.getScrollTop();
    const scrollBottom = scrollTop + scrollRef.current.getClientHeight();
    const { offset, size } = getListItemRowSizeAndPosition(listRef.current, rowIndex);

    if (offset < scrollTop || (offset + size) > scrollBottom) {
      scrollRef.current.scrollTop(
        listRef.current.Grid.getOffsetForCell({
          rowIndex,
          alignment: "center",
        }).scrollTop
      );
    }
  }, [scrollToId]);

  const rowRenderer = useCallback((params) => {
    const { key, style, index } = params;
    const item = folderTree[index];

    if (index % 2 === 0) { // If the row is even.
      style.backgroundColor = "var(--tree-row-odd)";
    } else {
      style.backgroundColor = "var(--tree-row-even)";
    }

    return (
      <FolderTreeItem
        key={key}
        style={style}
        isActive={currentFolderId
          ? (currentFolderId === item.id || currentFolderId === item.first_para)
          : false}
        item={item}
        onExpand={onItemExpand}
        onCheck={onItemCheck}
        onItemClick={onItemClick}
        onRightClick={onRightClick}
      />
    );
  }, [currentFolderId, folderTree, onItemExpand, onItemCheck, onItemClick, onRightClick]);

  const rowHeight = treeFontSize * (zoom / 100) + 10;

  const render = useCallback(({ width, height }) => {
    return (
      <ListView
        ref={scrollRef}
        listRef={listRef}
        width={width || 500}
        height={height || 500}
        overscanRowCount={50}
        rowCount={folderTree.length}
        rowHeight={rowHeight}
        rowRenderer={rowRenderer}
      />
    );
  }, [folderTree.length, rowHeight, rowRenderer]);

  return (
    <div className={classnames("tree-list", className)}>
      <ReactResizeDetector handleWidth handleHeight render={render} />
    </div>
  );
});

Tree.propTypes = {
  className: PropTypes.string,

  isKeepOnlyOneBookExpanded: PropTypes.bool,

  currentFolder: PropTypes.object,
  nodeList: PropTypes.array,

  onItemExpand: PropTypes.func.isRequired,
  onItemCheck: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onRightClick: PropTypes.func.isRequired,

  folderTree: PropTypes.array.isRequired,

  expanded: PropTypes.array.isRequired,
  onChangeExpanded: PropTypes.func.isRequired,
};

export default Tree;
