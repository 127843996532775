import { useEffect, useRef, useState } from "react";
import { useViewMode } from "./viewModeHooks";
import { KeyCodes } from "../shared/utils/dom";

const useTextEditor = () => {
  const [textTransform, setTextTransform] = useState({
    bold: false,
    italic: false,
    underline: false
  });
  const [correctionText, setCorrectionText] = useState("");
  const shouldUpdateRef = useRef(false);
  const { isMobile } = useViewMode();
  const input = useRef(null);
  const selectedText = useRef(null);

  // tarnsform text with css instead of html tags
  useEffect(() => {
    document.execCommand("styleWithCSS", null, true);
  }, []);

  const toggleTextTransform = (e) => {
    const id = e.target.id;
    const cmd = e.target.getAttribute("data-cmd");
    document.execCommand(cmd, false, null);
    const selection = window.getSelection();
    // workaround for the underline command bug in Safari - the queryCommandState returns true untill you start typing
    if (cmd === "underline" && selection.type !== "Range") {
      const style = selection.anchorNode.parentNode.getAttribute("style");
      if (style && style.indexOf("text-decoration: underline;") !== -1) {
        setTextTransform((prev) => ({ ...prev, [id]: false }));
        shouldUpdateRef.current = false;
      } else {
        setTextTransform((prev) => ({ ...prev, [id]: document.queryCommandState(cmd) }));
      }
    } else {
      setTextTransform((prev) => ({ ...prev, [id]: document.queryCommandState(cmd) }));
    }
    input.current.focus();
  };
  // reset all commands if the input is empty
  useEffect(() => {
    if (correctionText === "<br>") {
      selectedText.current.focus();
      input.current.focus();
    }
  }, [correctionText]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === KeyCodes.enter && isMobile) {
        e.preventDefault();
      };
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isMobile]);

  useEffect(() => {
    const editableNode = input.current;
    const updateTextTransformState = () => {
      if (document.queryCommandState) {
        setTextTransform((prevState) => {
          let newState = { ...prevState };
          for (let cmd in prevState) {
            // workaround for the underline command bug in Safari - the queryCommandState returns true untill you start typing
            if (cmd === "underline") {
              if (shouldUpdateRef.current === true) {
                newState[cmd] = document.queryCommandState(cmd);
              } else {
                newState[cmd] = false;
                shouldUpdateRef.current = true;
              }
            } else {
              newState[cmd] = document.queryCommandState(cmd);
            }
          }
          return newState;
        });
      }
    };
    const handleInputChange = () => {
      setCorrectionText(input.current.innerHTML);
      updateTextTransformState();
    };
    const handlePaste = (e) => {
      const clipboardData = e.clipboardData || window.clipboardData;
      const paste = clipboardData.getData("text");
      const regExp = new RegExp(/(https?:\/\/[^\s]+)/g);
      const isUrl = regExp.test(paste);
      const selection = window.getSelection();
      if (!selection.rangeCount) {
        return;
      };
      selection.deleteFromDocument();
      if (!isUrl) {
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      } else {
        const div = document.createElement("div");
        div.innerHTML = "<br>";
        const link = document.createElement("div");
        link.innerHTML = paste;
        link.classList.add("td-input-share-link");
        link.classList.add("wrap");
        selection.getRangeAt(0).insertNode(div);
        selection.getRangeAt(0).insertNode(link);
      }
      setCorrectionText(input.current.innerHTML);
      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener("input", handleInputChange);
    document.addEventListener("paste", handlePaste);
    editableNode.addEventListener("click", updateTextTransformState);
    return () => {
      document.removeEventListener("input", handleInputChange);
      document.removeEventListener("paste", handlePaste);
      editableNode.removeEventListener("click", updateTextTransformState);
    };
  }, []);

  return { input, selectedText, textTransform, correctionText, toggleTextTransform };
};
export default useTextEditor;
