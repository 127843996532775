import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { decode } from "html-entities";
import { TextField } from "../../views";
import DialogButtons from "../../dialog/DialogButtons";
import { useDialogContext } from "../../dialog/DialogContext";
import { StudyCenterActions } from "../studyCenter.actions";
import { isEventKey, KeyCodes } from "../../../shared/utils/dom";
import { useAuth } from "src/hooks";
import { moveToTopic } from "src/components/studyCenter/StudyCenterUtils";

const NewFolderDialog = ({ entry, parent, onNewFolder, afterSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isUserEditor } = useAuth();
  const oldName = entry ? decode(entry.name) : "";
  const [name, setName] = useState(oldName);
  const [showError, setShowError] = useState(false);
  const { hideDialog, showConfirm } = useDialogContext();
  const topics = useSelector((state) => state.studyCenter.topics);
  const entries = useSelector((state) => state.studyCenter.entries);

  const [waitForUpdate, setWaitForUpdate] = useState();

  useEffect(() => {
    if (waitForUpdate && onNewFolder) {
      const newTopic = topics.find((item) => item.id === waitForUpdate);
      if (newTopic) {
        onNewFolder(newTopic, parent);
        hideDialog();
      }
    }
  }, [waitForUpdate, parent, topics]);

  const onConfirm = () => {
    if (name) {
      if (entry) {
        if (isUserEditor) {
          const children = topics.filter((topic) => topic.topic === entry.topic);
          const nameLow = name.toLowerCase();
          const dupIndex = children.findIndex((topic) => topic.name.toLowerCase() === nameLow);
          if (dupIndex !== -1) {
            showConfirm(
              t("mergeFolder"),
              t("topicNameExist", {
                from: oldName,
                to: name,
              }),
              () => {
                const targetFolder = children[dupIndex];
                const folderId = targetFolder.id;
                const fromFolderId = entry.id;

                hideDialog();
                moveToTopic(
                  dispatch,
                  folderId,
                  targetFolder,
                  topics,
                  {
                    selectedTopics: [],
                    selectedEntries: entries.filter((entry) => entry.topic === fromFolderId),
                  },
                  isUserEditor,
                );
              },
            );
            return;
          }
        }

        dispatch(
          StudyCenterActions.updateSCTopic({
            ...entry,
            name,
            parent: parent || entry.parent,
          }),
        );
        hideDialog();
      } else {
        const newId = uuid();
        if (onNewFolder) {
          setWaitForUpdate(newId);
        } else {
          hideDialog();
        }
        if (afterSubmit) {
          afterSubmit(name);
        }
        if (isUserEditor) {
          return;
        }
        dispatch(
          StudyCenterActions.createSCTopic({
            id: newId,
            parent,
            name,
          }),
        );
      }
    }
  };

  return (
    <div>
      <div className="sc-title sc-title-margin">{t(entry ? "editFolder" : "createFolder")}</div>
      <TextField
        label={t("title")}
        className="new-folder-input"
        value={name}
        autoFocus
        error={showError ? name.length === 0 : false}
        onKeyPress={(e) => {
          if (onConfirm && isEventKey(e, KeyCodes.enter)) {
            onConfirm();
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          setShowError(true);
          setName(e.target.value);
        }}
      />
      <DialogButtons
        onClickNegative={hideDialog}
        disabled={name.length === 0}
        onClickPositive={() => onConfirm()}
        negativeText={t("cancel")}
        positiveText={t(entry ? "save" : "create")}
      />
    </div>
  );
};

NewFolderDialog.propTypes = {
  entry: PropTypes.object,
  onNewFolder: PropTypes.func,
  afterSubmit: PropTypes.func,
  parent: PropTypes.string,
};

export default NewFolderDialog;
