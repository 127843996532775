/* eslint-disable no-console */
import { put, call, select } from "redux-saga/effects";
import { getBooksInfoRequest } from "src/api/API";
import { getBookOrigin } from "src/shared/utils/content";
import { findNode } from "src/utils/Utils"; 
import { ContentActions } from "./content.actions";

/**
 * Method loads books by id if it not exists in the cache or redux
 * 
 * @param {String[]} bookIds
 * @returns {Object[]} array of books
 */
export function* loadBooksDataWorker(bookIds = []) {
  const { mainTree, books } = yield select((state) => state.mainTree);
  const allBooksInfo = [];
  const booksToFetch = [];
  bookIds.forEach((bookId) => {
    let book = books.find((item) => item.id === bookId);
    if (!book) {
      book = findNode(bookId, mainTree);
    }
    if (book) {
      allBooksInfo.push(book);
    } else {
      booksToFetch.push(getBookOrigin(bookId));
    }
  });

  // Try to remove logic for remove loading all books
  if (booksToFetch.length > 0) {
    try {
      const fetchedBooks = yield call(getBooksInfoRequest, booksToFetch);
      allBooksInfo.push(...fetchedBooks);
      yield put(ContentActions.actionPutBooks(allBooksInfo));
    } catch (error) {
      console.log("loadBooksDataWorker_error", error);
    }
  }
  return allBooksInfo;
}
