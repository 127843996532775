import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionToggleBookInMLById } from "../redux/library.actions";
import ToggleInLibraryDialog from "../components/myLibrary/ToggleInLibraryDialog";
import { useDialogContext } from "../components/dialog/DialogContext";
import { TOGGLE_IN_LIBRARY_BEHAVIOURS } from "../components/myLibrary/constants";
import { actionUpdateSetting } from "../redux/actions";
import { Settings } from "../utils/Settings";
import useAuth from "./useAuth";

const useToggleInLibrary = () => {
  const toggleInLibraryBehaviour = useSelector((state) => state.settings.toggleInLibraryBehaviour);
  const dispatch = useDispatch();
  const { showDialog } = useDialogContext();
  const { showLoginDialog, isLogin } = useAuth();

  const showToggleInLibraryDialog = useCallback((inLibrary, bookId) => {
    const message = inLibrary ? "removeFromML?" : "addToML?";
    const onConfirm = (doNotAskAgain) => {
      if (doNotAskAgain) {
        dispatch(
          actionUpdateSetting(
            Settings.toggleInLibraryBehaviour.id,
            TOGGLE_IN_LIBRARY_BEHAVIOURS.DO_NOT_ASK
          )
        );
      }
      dispatch(actionToggleBookInMLById(bookId));
    };
    const component = <ToggleInLibraryDialog message={message} onConfirm={onConfirm} />;
    showDialog(component);
  }, [showDialog]);
  const toggleInLibrary = useCallback((inLibrary, bookId) => {
    if (isLogin) {
      if (toggleInLibraryBehaviour === TOGGLE_IN_LIBRARY_BEHAVIOURS.ASK) {
        showToggleInLibraryDialog(inLibrary, bookId);
      } else {
        dispatch(actionToggleBookInMLById(bookId));
      }
    } else {
      showLoginDialog();
    }
  }, [showLoginDialog, isLogin, showToggleInLibraryDialog, toggleInLibraryBehaviour]);
  return { toggleInLibrary };
};
export default useToggleInLibrary;
