import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import LanguageSelectorView  from "./LanguageSelectorView";

const FiltersLanguageSelector = ({ itemsDisabled, itemsChecked, onClickItem, }) => {
  const mainTree = useSelector((state) => state.mainTree.mainTree);

  return (
    <LanguageSelectorView
      items={mainTree}
      itemsChecked={itemsChecked}
      itemsDisabled={itemsDisabled}
      onClickItem={onClickItem}
    />
  );
};

FiltersLanguageSelector.propTypes = {
  itemsChecked: PropTypes.array.isRequired,
  itemsDisabled: PropTypes.array,
  onClickItem: PropTypes.func.isRequired,
};

export default FiltersLanguageSelector;
