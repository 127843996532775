import { sortByArray } from "../../shared/utils/content";

export const normalizeFeaturedSearchSections = (sections) => {
  return sortByArray(sections, [
    "bibleCommentaries",
    "concordances",
    "dictionary",
    "bibleVersions",
    "similarParagraphs",
    "egwIndex",
    "aplIndex",
  ], "code");
};


export const RelatedTabType = {
  EGW: "egw",
  BIBLE: "bible"
};
