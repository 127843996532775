export const treesActionConstants = {
  UPDATE_CHECKED: "trees/UPDATE_CHECKED",
};

export const treesActions = {
  updateChecked: (data) => ({
    type: treesActionConstants.UPDATE_CHECKED,
    data,
  }),
};
