import { all, call, put, select, takeEvery, takeLeading } from "redux-saga/effects";

import { CONTENT_CLASSES } from "src/shared/utils/content";
import { onUpdateChecks } from "src/utils/TreeUtils";
import { titlesActionConstants, titlesActions } from "./actions";
import { getTitlesRequest } from "../../api/TitlesAPI";
import { menuItems } from "../../utils/MenuItems";
import { convertTitleTree, sortTitleTree } from "../../utils/TitleTreeUtils";
import { SearchActions } from "src/components/search/search.actions";
import { ACTION_LOGOUT, ACTION_UPDATE_SETTING_SUCCESS } from "../constants";
import { getLanguageDefault } from "../../utils/Utils";

export function* toggleTreeItemExpandWorker(action) {
  const expanded = yield select((state) => state.titleTree.expanded);

  const id = action.data.id;

  const expandedNew = expanded.includes(id)
    ? expanded.filter((expandedId) => expandedId !== id)
    : [...expanded, id];

  yield put(titlesActions.updateExpanded(expandedNew));
}

export function* toggleTreeItemCheckWorker(action) {
  const { checked, tree } = yield select((state) => state.titleTree);

  const checkItem = action.data;

  const updatedChecks = onUpdateChecks(checkItem, tree, checked);

  yield put(titlesActions.updateChecked(updatedChecks));
  yield put(SearchActions.searchWithCurrentTree());
}

export function* fetchAndConvertTitleTreeWorker() {
  // Prevent fetching and converting if tree already exists
  const titleTreeTreeConverted = yield select((state) => state.titleTree.treeConverted);

  if (titleTreeTreeConverted.length) {
    return;
  }
  // =====================

  const treeTitles = yield call(getTitlesRequest);
  const mainTree = yield select((state) => state.mainTree.mainTree);

  const treeConverted = convertTitleTree(treeTitles, mainTree);

  yield put(titlesActions.updateTreeConverted(treeConverted));
}

export function* _sortTreeWorker() {
  // If tree is not converted yet - fetch, convert and sort
  const treeConverted = yield select((state) => state.titleTree.treeConverted);
  if (!treeConverted.length) {
    yield put(titlesActions.fetchConvertSortTree());
    return;
  }
  // =====================

  // Prevent sorting if the tree is already properly sorted.
  const libraryLanguages = yield select((state) => state.settings.libraryLanguages);
  const titleTree = yield select((state) => state.titleTree.tree);

  const treeLibraryLanguages = titleTree?.[0]?.libraryLanguages;
  const isTreeAndUserLangsDifferent =
    !treeLibraryLanguages
    || treeLibraryLanguages.length !== libraryLanguages.length
    || libraryLanguages.some((libLang, index) => libLang !== treeLibraryLanguages[index]);

  if (!isTreeAndUserLangsDifferent) {
    return;
  }
  // ======================================================

  yield put(titlesActions.updateTreeMain([{
    ...menuItems.titles,
    className: CONTENT_CLASSES.TITLES_ROOT,
    type: CONTENT_CLASSES.TITLES_ROOT,
    title: menuItems.titles.label,
    children: sortTitleTree(treeConverted, libraryLanguages),
    libraryLanguages: [...libraryLanguages],
  }]));
}

export function* fetchConvertSortTreeWorker() {
  yield fetchAndConvertTitleTreeWorker();
  yield _sortTreeWorker();
}

export function* sortTreeIfLibLangsChangedWorker(action) {
  if (action.data.libraryLanguages) {
    const { tree } = yield select((state) => state.titleTree);
    // no need to re-covert tree when there's no tree, to not fetch it, bcz its size's big
    // (tree fetch is inside "_sortTreeWorker")
    if (!tree.length) {
      return;
    }

    yield _sortTreeWorker();
  }
}

export default function* categoriesSaga() {
  yield all([
    takeEvery(titlesActionConstants.TOGGLE_ITEM_EXPAND, toggleTreeItemExpandWorker),
    takeLeading(titlesActionConstants.TOGGLE_ITEM_CHECK, toggleTreeItemCheckWorker),

    takeLeading(titlesActionConstants.FETCH_CONVERT_SORT_TREE, fetchConvertSortTreeWorker),

    takeLeading(ACTION_UPDATE_SETTING_SUCCESS, sortTreeIfLibLangsChangedWorker),
    takeLeading(ACTION_LOGOUT, _sortTreeWorker),
  ]);
}
