import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import IconView from "../../shared/components/IconView";
import EgwWebFont from "../../assets/EgwWebFont";

import "./BlockProvidedBy.scss";

const PUBLISHER_REVIEW_AND_HERALD = "Review and Herald Publishing Association";

const BlockProvidedBy = ({publisher}) => {
  const { t } = useTranslation();

  let elementProvidedBy;

  if (publisher === PUBLISHER_REVIEW_AND_HERALD) {
    elementProvidedBy = (
      <div className="provided-by-rah">
        <div className="provided-by-rah_logo">
          <IconView icon={EgwWebFont["logo-review-and-herald"]}/>
        </div>
        <div className="provided-by-rah_text">
          <div className="provided-by-rah_title">Review&Herald</div>
          <div className="provided-by-rah_subtitle">{t("publishingAssociation")}</div>
        </div>
      </div>
    );
  }

  if (!elementProvidedBy) {
    return null;
  }

  return (
    <div className="provided-by">
      <div className="provided-by_title">{t("providedBy")}:&nbsp;</div>
      {elementProvidedBy}
    </div>
  );
};

BlockProvidedBy.propTypes = {
  publisher: PropTypes.string,
};

export default BlockProvidedBy;
