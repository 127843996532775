import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { version } from "../../package.json";
import { parseISO, format } from "date-fns"; 
import { URLS } from "../shared/utils/url";
import { isProduction } from "../shared/utils/assembly";

/**
 * Debug, show last build time
 */
const VersionView = ({ link }) => {
  const info = useSelector((state) => state.system.info);

  let versionValue = `Version: ${version}`;
  const isProductionValue = isProduction();

  if (info?.lastCommitTime) {
    const dateVal = parseISO(info.lastCommitTime);
    const formatDate = isProductionValue ? "dd.MM.yyyy" : "dd.MM.yyyy hh:mm";
    versionValue += ` - ${format(dateVal, formatDate)}`;
  }  
 
  if (link && !isProductionValue) {
    return (
      <span className="footer-build-time-link">
        <Link to={URLS.patchNotes}>{versionValue}</Link>
      </span>
    );
  }
  return <span className="footer-build-time-link">{versionValue}</span>;
};

export default VersionView;
