import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import Button from "../components/views/Button";

import { useDialogContext } from "src/components/dialog/DialogContext";
import { getTextAppHost, URLS } from "src/shared/utils/url";
import { useDevMode } from "src/hooks";

import "./CookiesNotification.scss";
import PropTypes from "prop-types";

const CookiesNotification = ({onShowChange}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { location: { pathname }, } = history;

  const { showConfirm } = useDialogContext();
  const isInEu = useSelector((state) => state.settings.geoIpData.is_in_eu);
  const [cookie, setCookie] = useCookies(["cookiesNotify"]);
  const { cookiesNotify } = cookie;
  const [show, setShow] = useState(true);
  const { isDevMode } = useDevMode();
  const showCookiesNotification =
    !cookiesNotify && (isInEu || isDevMode) && show && !pathname.includes(URLS.privacyPolicy);

  const onCloseClick = (event) => {
    event && event.preventDefault();
    setCookie("cookiesNotify", "1", {
      maxAge: 31104000, // 1 year
      path: "/",
    });
    setShow(false);
  };

  const onClickDecline = () => {
    showConfirm(t("decline_cookie_title"), t("decline_cookie_message"), onCloseClick, {
      positiveBtn: t("accept"),
      negativeBtn: t("decline_cookie_negative"),
      onCancel: () => document.location.replace(getTextAppHost()),
      noCancelable: true,
    });
  };

  useEffect(() => {
    onShowChange(show);
  }, [onShowChange, show]);

  if (!showCookiesNotification) {
    return null;
  }

  return (
    <div className="cookies-notification">
      <div className="cookies-notification-wrapper">
        <div className="cookies-notification-content">
          <div className="cookies-notification-content-title">{t("cookiesNotificationTitle")}</div>
          <div className="cookies-notification-content-text">
            {t("cookiesNotificationText")}{" "}
            <Link to={URLS.privacyPolicy}>{t("privacyPolicyText")}</Link>
            {"."}
          </div>
        </div>
        <div className="cookies-notification-actions">
          <Button type="secondary" className={"s-cookie-decline-btn"} onClick={onClickDecline}>
            {t("decline")}
          </Button>
          <Button className={"s-cookie-accept-btn"} onClick={onCloseClick}>
            {t("accept")}
          </Button>
        </div>
      </div>
    </div>
  );
};

CookiesNotification.propTypes = {
  onShowChange: PropTypes.func,
};

export default CookiesNotification;
