import { ACTION_LOGOUT } from "./constants";
import { ContentConstants } from "./content.actions";

/**
 * [bookId]: {
 *  content: [],
 *  lastUsed: Date.now(),
 *  lastUpdate: Date.now(),
 *  search: String
 * }
 */
const initialState = {
  paragraphs: {},
  booksCacheInfo: {},
  paragraphPreview: undefined, // { previewId, ...otherParaData}
  chapters: {}, // map chapter by id { "127.5": [] }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ContentConstants.FETCH_PARAGRAPHS_SUCCESS:
      return { ...state, paragraphs: { ...action.data } };
    case ContentConstants.UPDATE_BOOK_INFO:
      return { ...state, booksCacheInfo: { ...action.data } };
    case ContentConstants.FETCH_PARAGRAPH_PREVIEW_SUCCESS:
      return { ...state, paragraphPreview: action.data };
    case ACTION_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};
