/* eslint-disable no-console */
import packageJson from "../../package.json";
import {
  clearLocalStorage,
  deleteAllCookies,
  getTokens, sessionStorageSafe,
  setTokens,
  localStorageSafe
} from "../shared/utils/systemUtils";
import {
  COOKIE_KEY_DONATE_CLOSED_COUNT,
  COOKIE_KEY_DONATE_NEXT_SHOW
} from "../shared/utils/donate";

export const checkVersion = (version) => {
  try {
    if (version !== packageJson.version && process.env.NODE_ENV !== "development") {
      if (localStorageSafe) {
        clearLocalStorage(true);
      }   
      const { refreshToken } = getTokens();
      // EGWW-1226 save login and donateNotify on change version
      deleteAllCookies([COOKIE_KEY_DONATE_NEXT_SHOW, COOKIE_KEY_DONATE_CLOSED_COUNT]);
      unregisterServiceWorker();
      setTokens(undefined, refreshToken);
      let refreshCounts = parseInt(sessionStorageSafe.getItem("refreshCount") || 0);
      if (refreshCounts < 3) {
        sessionStorageSafe.setItem("refreshCount", refreshCounts + 1);
        console.log(
          "%c%s",
          "color: white; font-weight:bold; background: red; font-size: 18px;",
          "The server version(" +
            version +
            ") and the application version(" +
            packageJson.version +
            ")  are  mismatch, the window will be reloaded in 3 seconds",
        );

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        // TODO maybe add clear all data
        console.error("The app tries to refresh 3 times, something wrong, the app can work wrong");
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const logout = () => {
  setTokens();
  if (localStorageSafe) {
    clearLocalStorage();
    deleteAllCookies();
  }
};

export const unregisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }
};

export const analitics = (event, content) => {
  if (window.firebase && window.firebase.analitics) {
    const fbAnalitic = window.firebase.analitics();
    fbAnalitic.logEvent(event, content);
  }
};
