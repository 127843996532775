import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { LinkButton } from "../views";
import { useViewMode } from "../../hooks";
import { ImageButton } from "./BookDownloadView";
import { EpubFileIcon, MobiFileIcon, Mp3FileIcon, PdfFileIcon } from "../../assets/CommonIcons";
import { BookFormats } from "../../utils/FilterBooksUtils";

const mapBookFormatToIcon = {
  mp3: Mp3FileIcon,
  pdf: PdfFileIcon,
  epub: EpubFileIcon,
  mobi: MobiFileIcon,
};

export const ButtonDownloadBook = ({ book, format, ...restProps }) => {
  const { t } = useTranslation();
  const { textMode } = useViewMode();

  const link = book.files?.[format];

  if (link) {
    return (
      <ImageButton
        link={link}
        icon={mapBookFormatToIcon[format]}
        title={textMode ? format : `${t("download")} ${format}`}
        {...restProps}
      />
    );
  }

  return null;
};
ButtonDownloadBook.propTypes = {
  book: PropTypes.object.isRequired,
  format: PropTypes.oneOf(Object.values(BookFormats)),
  className: PropTypes.string,
};

const LinkButtonBuyBook = ({book}) => {
  const { t } = useTranslation();
  const link = book.buy_link;

  if (link) {
    return (
      <LinkButton
        key='buy_link'
        link={link}
        external
        className="btn-download"
        label={t("buyPrinted")}
      />
    );
  }

  return null;
};
LinkButtonBuyBook.propTypes = {
  book: PropTypes.object.isRequired,
};

const BookDownloadLinksView = ({ book, className }) => {
  const buttons = useMemo(() => {
    return (
      <>
        <ButtonDownloadBook book={book} format='mp3' />
        <ButtonDownloadBook book={book} format='pdf' />
        <ButtonDownloadBook book={book} format='epub' />
        <ButtonDownloadBook book={book} format='mobi' />
        <LinkButtonBuyBook book={book} />
      </>
    );
  }, [book]);

  if (buttons.props.children.length) { // if at least one icon would be shown
    return (
      <div className={classNames("book-download-links", {
        [className]: !!className,
        "with-buy-link": book.buy_link,
      })}>
        {buttons}
      </div>
    );
  }

  return null;
};

BookDownloadLinksView.propTypes = {
  book: PropTypes.object,
  className: PropTypes.string
};

export default BookDownloadLinksView;
