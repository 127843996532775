export const ContentConstants = {
  FETCH_PARAGRAPHS: "FETCH_PARAGRAPHS",
  FETCH_PARAGRAPH_PREVIEW: "FETCH_PARAGRAPH_PREVIEW",
  FETCH_PARAGRAPH_PREVIEW_SUCCESS: "FETCH_PARAGRAPH_PREVIEW_SUCCESS",
  CLEAN_PARAGRAPHS: "CLEAN_PARAGRAPHS",
  FETCH_PARAGRAPHS_SUCCESS: "FETCH_PARAGRAPHS_SUCCESS",
  FETCH_FOLDERS: "FETCH_FOLDERS",
  FETCH_FOLDERS_SUCCESS: "FETCH_FOLDERS_SUCCESS",
  FETCH_FOLDERS_ERROR: "FETCH_FOLDERS_ERROR",
  FETCH_FOLDERS_TO_ROOT_SUCCESS: "FETCH_FOLDERS_TO_ROOT_SUCCESS",
  FETCH_BIBLES_SUCCESS: "FETCH_BIBLES_SUCCESS",
  FETCH_DICTIONARIES: "FETCH_DICTIONARIES",
  FETCH_DICTIONARIES_SUCCESS: "FETCH_DICTIONARIES_SUCCESS",
  FETCH_BOOKS: "FETCH_BOOKS",
  FETCH_BOOKS_SUCCESS: "FETCH_BOOKS_SUCCESS",
  FETCH_BOOKS_ERROR: "FETCH_BOOKS_ERROR",
  FETCH_BOOKS_DETAILS: "FETCH_BOOKS_DETAILS",
  FETCH_BOOKS_DETAILS_SUCCESS: "FETCH_BOOKS_DETAILS_SUCCESS",
  FETCH_CHAPTER_CONTENT: "FETCH_CHAPTER_CONTENT",
  FETCH_PERIODICALS_SUCCESS: "FETCH_PERIODICALS_SUCCESS",
  ACTION_PUT_BOOKS: "ACTION_PUT_BOOKS",
  FETCH_BOOKS_BY_IDS: "c/FETCH_BOOKS_BY_IDS",
  FETCH_FOLDERS_FOR_BOOK: "FETCH_FOLDERS_FOR_BOOK",
  ACTION_ERROR_BOOK_LOAD: "ACTION_ERROR_BOOK_LOAD",
  ACTION_MAKE_RICH_CONTENT: "ACTION_MAKE_RICH_CONTENT",
  UPDATE_BOOK_INFO: "UPDATE_BOOK_INFO",
};

export const ContentActions = {
  fetchFoldersToRootSuccess: (data, options = {}) => ({
    type: ContentConstants.FETCH_FOLDERS_TO_ROOT_SUCCESS,
    data: {
      mainTree: data,
      options,
    },
  }),
  actionPutBooks: (data) => ({
    type: ContentConstants.ACTION_PUT_BOOKS,
    data,
  }),
  fetchFoldersRequest: (lang) => ({
    type: ContentConstants.FETCH_FOLDERS,
    data: lang,
  }),
  fetchFoldersRequestError: (folders) => ({
    type: ContentConstants.FETCH_FOLDERS_ERROR,
    folders,
  }),
  fetchBooksRequest: (data) => ({
    type: ContentConstants.FETCH_BOOKS,
    data,
  }),
  fetchBookDetailsRequest: (data) => ({
    type: ContentConstants.FETCH_BOOKS_DETAILS,
    data,
  }),
  fetchChapterContentRequest: (data) => ({
    type: ContentConstants.FETCH_CHAPTER_CONTENT,
    data,
  }),
  fetchParagraphs: (paraId, search, lastParaId, paraPreviewId) => ({
    type: ContentConstants.FETCH_PARAGRAPHS,
    data: { paraId, search, lastParaId, paraPreviewId },
  }),
  fetchParagraphPreview: (paraId, parentBookId) => ({
    type: ContentConstants.FETCH_PARAGRAPH_PREVIEW,
    data: {paraId, parentBookId},
  }),
  fetchParagraphPreviewSuccess: (data) => ({
    type: ContentConstants.FETCH_PARAGRAPH_PREVIEW_SUCCESS,
    data,
  }),
  cleanParagraphs: () => ({
    type: ContentConstants.CLEAN_PARAGRAPHS,
  }),
  fetchParagraphsSuccess: (data) => ({
    type: ContentConstants.FETCH_PARAGRAPHS_SUCCESS,
    data,
  }),
  fetchBooksByIds: (data) => ({
    type: ContentConstants.FETCH_BOOKS_BY_IDS,
    data,
  }),
  fetchFolderForBook: (book) => ({
    type: ContentConstants.FETCH_FOLDERS_FOR_BOOK,
    data: { book },
  }),
  fetchBiblesSuccess: (data) => ({
    type: ContentConstants.FETCH_BIBLES_SUCCESS,
    data,
  }),
  actionErrorBookLoad: (data) => ({
    type: ContentConstants.ACTION_ERROR_BOOK_LOAD,
    data,
  }),
  actionMakeRichContent: (bookId, hardUpdate) => ({
    type: ContentConstants.ACTION_MAKE_RICH_CONTENT,
    data: { bookId, hardUpdate },
  }),
  updateBookInfo: (data) => ({
    type: ContentConstants.UPDATE_BOOK_INFO,
    data,
  }),
  actionFetchDictionariesSuccess: (data) => ({
    type: ContentConstants.FETCH_DICTIONARIES_SUCCESS,
    data,
  }),
  actionFetchDictionaries: () => ({
    type: ContentConstants.FETCH_DICTIONARIES,
  }),
  fetchPeriodicalsSuccess: (data) => ({
    type: ContentConstants.FETCH_PERIODICALS_SUCCESS,
    data,
  }),
};
