import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { getImageByClass } from "../../assets/CategoryIcons";
import { makeLangImage } from "../../utils/URLUtils";
import { usePopupContext } from "../popup/PopupContext";
import { CheckHalfIcon } from "../../assets/CommonIcons";
import UpdateLangPopup from "../popup/UpdateLangPopup";
import { LongPressWrapper } from "..";
import { useViewMode, useTheme } from "../../hooks";
import Checkbox from "../views/Checkbox";
import classnames from "classnames";
import { removeBR } from "../../shared/utils/content";

import "./Category.scss";
import IconView from "src/shared/components/IconView";
import { flagPlaceholder, URLS } from "../../shared/utils/url";

const SPECIAL_CLASSES = ["apl", "periodicals"];
const SPECIAL_NAMES = [
  "Adventist Pioneer Library",
  "Pioneer Authors",
  "Periodicals",
  "Misc Titles",
  "Recent Authors",
];
const isShowIcon = (className, label) =>
  !(SPECIAL_CLASSES.indexOf(className) !== -1 && SPECIAL_NAMES.indexOf(label) === -1);

const CategoryImage = ({ item }) => {
  const { isDark } = useTheme();
  const { icon, className, id, label } = item;
  if (className === "language") {
    return (
      <img
        className="category-image"
        alt={label}
        src={makeLangImage(id)}
        loading="lazy"
        onError={(e) => {
          if (e.target.src.indexOf("flags") > -1) {
            e.target.src = flagPlaceholder;
          }
        }}
      />
    );
  }
  let IconValue = icon || getImageByClass(className, isDark, id);
  if (id === URLS.aboutEW && isDark && item.secondIcon) {
    IconValue = item.secondIcon;
  }
  if (IconValue) {
    return <IconView icon={IconValue} className={"category-icon"} />;
  }
  return null;
};

CategoryImage.propTypes = {
  item: PropTypes.object,
  dark: PropTypes.bool,
};

const Category = ({
  onClick,
  item,
  isLogin,
  route,
  home,
  isAudio,
  showOrder,
  isDefault,
  inLibrary,
  booksCountByLang,
  onOrderClick,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { isMobile, textMode } = useViewMode();

  const { label, className } = item;
  let iconMode = isShowIcon(className, label);
  const { showPopup } = usePopupContext();

  const handleContextMenu = (e) => {
    if (!showOrder || (showOrder && isDefault)) {
      return;
    }
    e.preventDefault();

    const component = <UpdateLangPopup langItem={item || ""} />;
    showPopup(
      {
        left: e.clientX,
        top: e.clientY,
      },
      component,
      { hideOnClick: true },
    );
  };

  const mustBeGray = !isLogin && item.onlyAuth;
  const titleStyle = "category-title" + (iconMode ? "" : " titleNoIcon");
  let count = 0;
  if (isAudio) {
    count = item.audiobook_count || item.totalAudioCount || item.nAudioBooks || 0;
  } else {
    if (booksCountByLang) {
      count = booksCountByLang[item.id] || 0;
    } else {
      count = item.book_count
        ? item.book_count
        : item.totalCount === 0
        ? item.nbooks
        : item.totalCount;
    }
  }

  let languageCheckContent;
  if (showOrder) {
    if (isDefault) {
      languageCheckContent = (
        <div className="langCheckContainer">
          <span className="lang-check-text">{t("default")}</span>
          <CheckHalfIcon
            className="lang-check-icon"
            onClick={(e) => onOrderClick(e, item.id, isDefault)}
          />
        </div>
      );
    } else {
      languageCheckContent = (
        <Checkbox
          className="langCheckContainer lang-check-icon"
          checked={inLibrary}
          onChange={(value, e) => onOrderClick(e, item.id)}
        />
      );
    }
  }
  let labelText = t(label);

  if (labelText.length > 40) {
    labelText = labelText.substr(0, 40) + "...";
  }

  let itemContent;
  if (textMode) {
    itemContent = (
      <div className="text-category-item" tabIndex={0}>
        <div
          className="category-title"
          title={t(label)}
          dangerouslySetInnerHTML={{ __html: labelText }}
        />
        {!home && count > 0 && <div className="category-books">{t("books_count", { count })}</div>}
      </div>
    );
  } else if (isMobile) {
    itemContent = (
      <div className="categoryListItem" tabIndex={0}>
        {showOrder && languageCheckContent}
        <span className="imageAndTextContainer">
          <CategoryImage item={item} />
        </span>
        <span className="categoryTitleWrapper">
          {!home && count > 0 && (
            <span className="category-books">{t("books_count", { count })}</span>
          )}
          <span className={titleStyle}>{removeBR(t(label))}</span>
        </span>
      </div>
    );
  } else {
    itemContent = (
      <div className="categoryListItem changeBooksCountToTop" tabIndex={0}>
        {showOrder && languageCheckContent}
        <span className="imageAndTextContainer">
          <CategoryImage item={item} />
          {!home && count > 0 && (
            <span className="category-books">{t("books_count", { count })}</span>
          )}
        </span>
        <span
          dangerouslySetInnerHTML={{ __html: labelText }}
          className={classNames("category-title", {
            titleNoIcon: !iconMode,
          })}
        />
      </div>
    );
  }

  if (showOrder && !textMode) {
    itemContent = (
      <LongPressWrapper
        className="category__long-press-wrapper"
        onLongPress={(e) => handleContextMenu(e)}
        onContextMenu={(e) => handleContextMenu(e)}>
        {itemContent}
      </LongPressWrapper>
    );
  }

  if (isAudio && count === 0) {
    return null;
  }

  const rootClassName = classnames("categorySpaceItemWrap", {
    "has-checkbox": showOrder,
    notLoggedInTitle: mustBeGray,
  });

  //TODO need to change order. Main wrap must be a simple div element with tabIndex={-1}
  // itemContent must be Link o a
  return item.url ? (
    <a
      tabIndex={-1}
      className={rootClassName}
      href={item.url}
      target="_blank"
      rel="noopener noreferrer"
      {...restProps}>
      {itemContent}
    </a>
  ) : (
    <Link
      tabIndex={-1}
      className={rootClassName}
      to={mustBeGray || (showOrder && !inLibrary) ? "#" : route}
      onClick={onClick}
      {...restProps}>
      {itemContent}
    </Link>
  );
};

Category.propTypes = {
  home: PropTypes.bool,
  isLogin: PropTypes.bool,
  item: PropTypes.object,
  onClick: PropTypes.func,
  route: PropTypes.string,
  isAudio: PropTypes.bool,
  showOrder: PropTypes.bool,
  isDefault: PropTypes.bool,
  inLibrary: PropTypes.bool,
  booksCountByLang: PropTypes.object,
  onOrderClick: PropTypes.func,
};

export default Category;
