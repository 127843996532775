import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Scroll, IconButton } from "../components/views"; 
import { findNode } from "../utils/Utils";
import { useViewMode } from "../hooks";

import "./SystemPage.scss";
import EgwWebFont from "src/assets/EgwWebFont";
import { ContentActions } from "src/redux/content.actions";

const reduceLengthLabel = (contentLength) => {
  let contentString = "" + contentLength;
  if (contentLength > 10000) {
    contentString = Math.round(contentLength / 1000) + "K";
  } else if (contentLength > 10000000) {
    contentString = Math.round(contentLength / 1000000) + "M";
  } else if (contentLength > 10000000000) {
    contentString = Math.round(contentLength / 1000000000) + "B";
  }

  return contentString;
};

const SystemPage = () => {
  const paragraphs = useSelector((state) => state.paragraphReducer.paragraphs);
  const booksCacheInfo = useSelector((state) => state.paragraphReducer.booksCacheInfo);
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const dispatch = useDispatch();
  const { isMobile } = useViewMode();

  const [dataList, fullsize] = useMemo(() => {
    let list = [];
    let fullsize = 0;
    Object.keys(paragraphs).forEach((bookId) => {
      const content = paragraphs[bookId];
      const bookData = booksCacheInfo[bookId];
      const book = findNode(bookId, mainTree);
      const contentLength = content.reduce((accum, contentItem) => {
        let value = contentItem.content.length;
        if (contentItem.richContent) {
          value += contentItem.richContent.length;
        }
        return accum + value;
      }, 0);
      fullsize += contentLength;

      list.push({
        id: bookId,
        name: book.label,
        contentLength,
        ...bookData,
        paragraphCount: content.length
      });
    });

    return [list, fullsize];
  }, [paragraphs, booksCacheInfo, mainTree]);

  const list = dataList.map((item, index) => {
    let data = new Date(item.lastUpdate);
    return (
      <div className="system-table-row" key={index}>
        <div className="system-name system-column">{item.name}</div>
        <div className="system-count system-column">{item.paragraphCount}</div>
        <div className="system-size system-column">{reduceLengthLabel(item.contentLength)}</div>
        <div className="system-lu system-column">{data.toLocaleString()}</div>
        <div className="system-action">
          <IconButton
            icon={EgwWebFont.delete}
            onClick={() => {
              delete paragraphs[item.id];
              dispatch(ContentActions.fetchParagraphsSuccess({ ...paragraphs }));
            }}
          />
        </div>
      </div>
    );
  });
  return (
    <div className="system-wrap">
      <Button
        className="system-page__btn-clear"
        onClick={() => {
          dispatch(ContentActions.fetchParagraphsSuccess({}));
        }}>
        Clear All ({reduceLengthLabel(fullsize)})
      </Button>
      <div className="system-table-container">
        <div className="system-table-header system-table-row">
          <div className="system-name system-column">Name</div>
          <div className="system-count system-column">Count</div>
          <div className="system-size system-column">Length</div>
          <div className="system-lu system-column">LU</div>
          <div className="system-action system-column">Action</div>
        </div>
        {isMobile ? list : <Scroll>{list}</Scroll>}
      </div>
    </div>
  );
};

export default SystemPage;
