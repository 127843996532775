import classNames from "classnames";
import PropTypes, { oneOfType } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actionAddMessage, toggleShowCart } from "src/redux/actions";
import { cartActions } from "src/redux/cart/actions";
import { useBooksSelect } from "src/redux/selectors";
import { shopActions } from "src/redux/shop/actions";
import { createPayPalCheckoutRequest } from "src/shared/api/shopApi";
import { getBookId } from "src/shared/utils/content";
import { Button, Scroll } from "../views";
import Backdrop from "../views/Backdrop/Backdrop";
import CartList from "./CartList";
import { sessionStorageSafe } from "../../shared/utils/systemUtils";
import "./CartSidePanel.scss";

const sortShopIds = (shopBooks, shopPackages, cartIds) => {
  const shopPackageIds = [];
  const bookIds = new Set();
  cartIds.forEach((cartId) => {
    if (shopBooks[cartId]) {
      bookIds.add(getBookId(cartId));
    }
    const shopPackage = shopPackages[cartId];
    if (shopPackage) {
      shopPackageIds.push(cartId);
      shopPackage.items.forEach((shopBook) => {
        bookIds.add(getBookId(shopBook.book_id));
      });
    }
  });
  return { shopPackageIds, bookIds: [...bookIds] };
};

const convertBooks = (books) => {
  const booksObj = {};
  books.forEach((book) => (booksObj[book.book_id] = book));
  return booksObj;
};

const CartSidePanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.system.isLogin);
  const showCartPanel = useSelector((state) => state.settings.showCartPanel);
  const cartIds = useSelector((state) => state.cart.cartIds);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const shopBooks = useSelector((state) => state.shop.shopBooks);
  const shopPackages = useSelector((state) => state.shop.shopPackages);
  const { bookIds } = sortShopIds(shopBooks, shopPackages, cartIds);
  const books = convertBooks(useBooksSelect(bookIds));

  const [loading, setLoading] = useState(false);

  // save to session storage
  useEffect(() => {
    if (cartIds.size) {
      const shopPackagesToSave = [];
      const shopBooksToSave = [];
      cartIds.forEach((cartId) => {
        const shopBook = shopBooks[cartId];
        if (shopBook) {
          shopBooksToSave.push(shopBook);
          return;
        }
        const shopPackage = shopPackages[cartId];
        if (shopPackage) {
          shopPackagesToSave.push(shopPackage);
        }
      });
      const saveJson = JSON.stringify({
        shopPackages: shopPackagesToSave,
        shopBooks: shopBooksToSave,
        cartIds: [...cartIds],
      });
      sessionStorageSafe.setItem("cart", saveJson);
    }
  }, [cartIds, shopBooks, shopPackages]);

  // restore from session storage
  useEffect(() => {
    const restoredCartJson = sessionStorageSafe.getItem("cart");
    if (restoredCartJson) {
      const restoredCart = JSON.parse(restoredCartJson);
      dispatch(shopActions.restoreShop(restoredCart));
      dispatch(cartActions.restoreCart(restoredCart));
    }
  }, []);

  const createCheckout = async () => {
    if (isLogin) {
      setLoading(true);
      const packages = [];
      const books = [];
      cartIds.forEach((cartId) => {
        const shopBook = shopBooks[cartId];
        if (shopBook) {
          books.push(shopBook);
        }
        const shopPackage = shopPackages[cartId];
        if (shopPackage) {
          packages.push({ package_id: cartId, price: shopPackage.price });
        }
      });
      const firstBook = books[0] || shopPackages[packages[0].package_id].items[0];
      const data = {
        return_url: `${window.location.origin}/book/b${firstBook.book_id}?order=true`,
        cancel_url: `${window.location.origin}/book/b${firstBook.book_id}?order=false`,
        total_price: totalPrice,
        description: "Ellen G. White books",
        books,
        packages,
      };
      try {
        const paypalUrl = await createPayPalCheckoutRequest(data);
        window.location.replace(paypalUrl.redirect_url);
      } catch (err) {
        dispatch(actionAddMessage("@somethingWentWrong"));
        setLoading(false);
      }
    } else {
      dispatch(actionAddMessage("@pleaseLogin"));
    }
  };
  return (
    <>
      {showCartPanel && (
        <Backdrop
          className="cart-side-panel__backdrop"
          onClick={() => dispatch(toggleShowCart())}
        />
      )}
      <div
        className={classNames("cart-side-panel", {
          visible: showCartPanel,
        })}>
        <span className="cart-side-panel__title">{t("shoppingCart")}</span>
        <Scroll>
          <CartList
            books={books}
            shopPackages={shopPackages}
            shopBooks={shopBooks}
            cartIds={cartIds}
          />
        </Scroll>
        <CartCheckout
          totalPrice={totalPrice}
          disabled={loading || !cartIds.size}
          onClick={createCheckout}
        />
      </div>
    </>
  );
};

export default CartSidePanel;

const CartCheckout = ({ totalPrice, disabled, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="cart-side-panel__checkout">
      <div className="cart-side-panel__total-price">
        <span className="cart-side-panel__subtitle">{t("total")}:</span>
        <span className="cart-side-panel__price">$&nbsp;{totalPrice}</span>
      </div>
      <Button disabled={disabled} className="cart-side-panel__pay-btn" onClick={onClick}>
        {t("pay")}
      </Button>
    </div>
  );
};

CartCheckout.propTypes = {
  totalPrice: oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
