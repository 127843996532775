import React, { useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useResizerContext } from "../resizer/Resizer";
import { useTranslation } from "react-i18next";
import { useViewMode } from "../../hooks";
import { IconButton, TabBar } from "../../components/views";
import AdvanceSearchRightPanel from "../advSearch/AdvanceSearchRightPanel";
import RelatedTab from "./RelatedTab";
import FeaturedTab from "./FeaturedTab";
import StudyCenterTree from "../studyCenter/StudyCenterTree";
import LockedTab, { MIN_PANEL_WIDTH } from "./LockedTab";
import { SEARH_PANEL_TAB_ITEMS, SearchPanelTabs } from "./constants";
import { actionAddMessage, actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { usePanels } from "../../hooks/usePanels";
import sassVariables from "../../styles/variables.module.scss";
import EgwWebIcons from "../../assets/EgwWebFont";
import { SearchActions } from "../search/search.actions";
import { useRemToPx } from "../../hooks/viewModeHooks";

import "./RightPanel.scss";

const RightPanel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { textMode } = useViewMode();

  const { rightWidthInner: width, mainWrapperHeight: height } = useResizerContext();
  const rightTab = useSelector((state) => state.settings.rightTab);
  const lockedTabs = useSelector((state) => state.settings.lockedTabs);
  const { isTablet } = useViewMode();
  const remToPx = useRemToPx();
  const { setRightPanelPinned } = usePanels();
  const isRightPanelPinned = useSelector((state) => state.settings.isRightPanelPinned);
  const parentHeight = useMemo(() => {
    return Math.floor(Math.floor(height) - remToPx(sassVariables.panelHeaderHeight));
  }, [height, remToPx]);

  const handleSearchPin = () => {
    setRightPanelPinned(!isRightPanelPinned);
  };

  useEffect(() => {
    if (lockedTabs.length > 0 && width < MIN_PANEL_WIDTH * lockedTabs.length) {
      dispatch(actionAddMessage(t("lockedTabMsg")));
    }
  }, [lockedTabs]);

  const tabs = useMemo(() => {
    const tabsFitPanel = [];
    SEARH_PANEL_TAB_ITEMS.forEach((item) => {
      const lockedTabIndex = lockedTabs.findIndex((lockItem) => lockItem === item.id);
      if (lockedTabIndex !== -1 && width >= (lockedTabIndex + 1) * MIN_PANEL_WIDTH) {
        tabsFitPanel.push(item.id);
      }
    });
    return SEARH_PANEL_TAB_ITEMS.map((item) => {
      const lockedTabIndex = lockedTabs.findIndex((lockItem) => lockItem === item.id);
      let isActive;
      const isLocked = lockedTabIndex !== -1;
      if (isLocked) {
        if (width < MIN_PANEL_WIDTH * 2) {
          isActive = item.id === rightTab;
        } else {
          const isTabFitPanel = width >= (lockedTabIndex + 1) * MIN_PANEL_WIDTH;
          isActive =
            (isTabFitPanel && tabsFitPanel.includes(rightTab)) ||
            (!isTabFitPanel && item.id === rightTab);
        }
      }
      return { ...item, isLocked, isActive };
    });
  }, [lockedTabs, width, rightTab]);

  const handleTabClick = (selectedTab) => {
    if (selectedTab === rightTab) {
      return;
    }
    dispatch(actionUpdateSetting(Settings.rightTab.id, selectedTab));
  };

  const handleDoubleTabClick = (selectedTab) => {
    if (textMode) {
      return;
    }

    const tabIndex = lockedTabs.findIndex((item) => selectedTab === item);
    if (tabIndex === -1) {
      lockedTabs.push(selectedTab);
    } else {
      lockedTabs.splice(tabIndex, 1);
    }
    dispatch(actionUpdateSetting(Settings.lockedTabs.id, [...lockedTabs]));
  };

  const getTabsToRender = () => {
    let tabsToRender = [];
    lockedTabs.forEach((tab, index) => {
      if (width >= MIN_PANEL_WIDTH * (index + 1)) {
        tabsToRender.push(tab);
      }
    });
    if (!tabsToRender.includes(rightTab)) {
      tabsToRender = [rightTab];
    }
    return tabsToRender;
  };

  const renderTab = useCallback(() => {
    switch (rightTab) {
      case SearchPanelTabs.RESULTS:
        return <AdvanceSearchRightPanel parentHeight={parentHeight} parentWidth={width} />;
      case SearchPanelTabs.RELATED:
        return <RelatedTab parentHeight={parentHeight} />;
      case SearchPanelTabs.FEATURED:
        return <FeaturedTab isInsideSearchPlus />;
      case SearchPanelTabs.STUDY:
        return <StudyCenterTree parentHeight={parentHeight} parentWidth={width} />;
      default:
        return <span className="search-placeholder">Here will be {rightTab} content.</span>;
    }
  }, [parentHeight, rightTab, width]);

  return (
    <div
      className="right-panel-inner"
      onClick={() => dispatch(SearchActions.setSearchBookListSuppress(true))}>
      {isTablet && (
        <IconButton
          icon={EgwWebIcons.pin}
          className="pin-icon"
          active={isRightPanelPinned}
          onClick={handleSearchPin}
        />
      )}
      <TabBar
        onChange={handleTabClick}
        onDoubleClick={handleDoubleTabClick}
        current={rightTab}
        className="tabs-right-panel"
        options={tabs}
      />
      <div className="right-panel-content">
        {lockedTabs.length > 1 && lockedTabs.includes(rightTab) && width >= MIN_PANEL_WIDTH * 2 ? (
          <LockedTab tabs={getTabsToRender()} parentWidth={width} parentHeight={parentHeight} />
        ) : (
          renderTab()
        )}
      </div>
    </div>
  );
};

export default RightPanel;
