import { titlesActionConstants } from "./actions";
import { BooksFiltersStoreDefault, updateBooksFiltersStore } from "../../utils/FilterBooksUtils";
import { URLS } from "../../shared/utils/url";

const initialState = {
  treeConverted: [],
  tree: [],
  expanded: [URLS.titles],
  checked: [],
  filters: BooksFiltersStoreDefault,
};

const titleTreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case titlesActionConstants.UPDATE_TREE_MAIN:
      return { ...state, tree: action.data };
    case titlesActionConstants.UPDATE_TREE_CONVERTED:
      return { ...state, treeConverted: action.data };
    case titlesActionConstants.UPDATE_EXPANDED:
      return { ...state, expanded: action.data };
    case titlesActionConstants.UPDATE_CHECKED:
      return { ...state, checked: action.data };
    case titlesActionConstants.UPDATE_FILTERS: {
      return updateBooksFiltersStore(state, action.data);
    }
    default:
      return state;
  }
};

export default titleTreeReducer;
