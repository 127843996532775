import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeResourceUrl } from "../../../shared/utils/url";
import { makeNavigationUrl } from "../../../utils/URLUtils";
import { IconButton } from "../../views";
import CommonPopup from "./commonPopup";
import { downloadFile } from "../../../utils/RouterUtils";

import EgwWebFont from "src/assets/EgwWebFont";

import "./commonPopup.scss";

class PdfPopup extends Component {
  constructor(props) {
    super();
    const { history, book } = props;
    this.popupRef = React.createRef();
    this.state = {
      book,
      history
    };
  }

  show = (newState, bounds) => {
    this.setState({ book: newState.book });
    this.popupRef.current.show(newState, bounds);
  };

  handleReaderClick = () => {
    const pdfReaderLink = makeNavigationUrl({
      className: "pdf",
      id: this.state.book.id
    });
    this.state.history.push(pdfReaderLink);
  };

  handleDownloadClick = () => {
    const downloadUrl = makeResourceUrl(this.state.book.files.pdf);
    downloadFile(downloadUrl);
    // openNewTab(downloadUrl);
  };

  render() {
    if (!this.state.book.children) {
      return null;
    }

    return (
      <CommonPopup ref={this.popupRef}>
        <IconButton icon={EgwWebFont.read} onClick={this.handleReaderClick} />
        <IconButton icon={EgwWebFont.download} onClick={() => this.handleDownloadClick()} />
      </CommonPopup>
    );
  }
}

PdfPopup.propTypes = {
  history: PropTypes.object,
  book: PropTypes.object
};

export default PdfPopup;
