import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { usePopupContext } from "../popup/PopupContext";
import { makeLangImage} from "../../utils/URLUtils";
import { actionChangeLang } from "../../redux/params.actions";
import { actionUpdateSetting } from "../../redux/actions";
import { SearchField } from "../views";
import { usePopupId, useUiLanguagesTranslated } from "../../hooks";
import { Settings } from "../../utils/Settings";
import Scroll from "../views/Scroll";
import { flagPlaceholder } from "../../shared/utils/url";
import { getCurrentLang } from "../../utils/Utils";

import "./Settings.scss";

const LANG_POPUP_ID = "lang-popup";

const UiLangsSelector = ({ currentLang }) => {
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const { hidePopup } = usePopupContext();
  const dispatch = useDispatch();
  const uiLanguagesTranslated = useUiLanguagesTranslated();
  const searchParams = useSelector((state) => state.search.searchParams);

  const handleLangChange = (lang) => {
    if (currentLang !== lang) {
      i18n.changeLanguage(lang);
      dispatch(actionChangeLang({ lang, searchParams }));
      dispatch(actionUpdateSetting(Settings.languageUI.id,  lang));
      hidePopup();
    }
  };

  const renderItem = (lang) => {
    const isActive = lang.id === currentLang;
    return <button
      key={lang.id}
      className={"radio-item" + (isActive ? " active" : " inactive")}
      onClick={() => handleLangChange(lang.id)}>
      <img
        alt={lang.label}
        role="presentation"
        loading="lazy"
        src={makeLangImage(lang.id, true)}
        onError={(e) => {
          if (e.target.src.indexOf("flags") > -1) {
            e.target.src = flagPlaceholder;
          }
        }} />
      <span>{lang.label}</span>
    </button>;
  };

  let filteredLangs = uiLanguagesTranslated;
  if (searchValue !== "") {
    filteredLangs = uiLanguagesTranslated.filter((item) =>
      item.label.toLowerCase().includes(searchValue.toLowerCase()));
  }

  return <div className="time-zone-container">
    <SearchField
      value={searchValue}
      isIconSearchEnabled={false}
      isIconClearVisible={!!searchValue}
      isIconSearchVisible={!searchValue}
      onChange={(searchValue) => setSearchValue(searchValue)}
      onInputClick={(event) => event.stopPropagation()}
      placeholder={t("search")}
      onClear={() => setSearchValue("")}
    />
    <Scroll style={{ marginTop: "0.5rem", height: "calc(100% - 2rem)" }} noHidePopups={true}>
      {filteredLangs.map((item) => renderItem(item))}
    </Scroll>
  </div>;
};

UiLangsSelector.propTypes = {
  currentLang: PropTypes.string,
  popupRefOuter: PropTypes.object,
};

export const usePopupUiLangsSelector = () => {
  const { i18n } = useTranslation();
  const { showPopup } = usePopupContext();
  const isShownLangPopup = usePopupId(LANG_POPUP_ID);
  const languageUI = useSelector((state) => state.settings.languageUI);
  const currentLang = getCurrentLang(i18n, languageUI);

  const openPopupUiLangs = useCallback((e) => {
    const rect = e.target.getBoundingClientRect();
    const component = <UiLangsSelector currentLang={currentLang} />;
    const popupPosition = {
      left: rect.left,
      top: rect.bottom,
      right: rect.right,
    };
    showPopup(popupPosition, component, { popupId: LANG_POPUP_ID, hideOnClick: true });
  }, [showPopup, currentLang]);

  return { openPopupUiLangs, isShownLangPopup, currentLang };
};

export default usePopupUiLangsSelector;
