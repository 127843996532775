import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "./Button";

const LinkButton = ({
  external,
  link,
  label,
  className,
  primary,
  secondary,
  outline,
  disabled,
  tabIndex,
  newTab,
  ...rest
}) => {
  const props = { className, primary, secondary, outline, disabled, tabIndex, ...rest };
  const addProps = newTab ? { target: "_blank", rel: "noopener noreferrer" } : {};

  return (
    <Button {...props}>
      {external ? (
        <a href={link} className="button-link" {...addProps}>
          {label}
        </a>
      ) : (
        <Link to={link} className="button-link">
          {label}
        </Link>
      )}
    </Button>
  );
};

LinkButton.defaultProps = {
  newTab: true
};

LinkButton.propTypes = {
  external: PropTypes.bool,
  link: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  newTab: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default LinkButton;
