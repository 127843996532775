import React from "react";

import "./BookDetailsLabelSupremeInfo.scss";

const BookDetailsLabelSupremeInfo = (props) => {
  return (
    <div {...props} className={"book-details_label-supreme-info " + props.className} />
  );
};

export default BookDetailsLabelSupremeInfo;
