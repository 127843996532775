/* eslint-disable no-console */
import { useEffect, useState } from "react";

import { subscribeToPush, unsubscribeFromPush, sendPush, sendPushTo } from "./PushAPI";

import { getVapidKey } from "./PushAPI";
import { useAuth } from "../hooks";

const StartTimeOut = 5 * 1000;

window.testPush = () => sendPush();

export const checkPushSupported = () => {
  return "serviceWorker" in navigator;
};

const registerServiceWorker = () => {
  return navigator.serviceWorker.register("/ServiceWorker.js");
};

const getPushManager = async () => {
  const sw = await navigator.serviceWorker.ready;
  sw.update();
  if (sw.pushManager) {
    return sw.pushManager;
  } else if (window.safari && window.safari.pushNotification) {
    console.log("SAFARI", window.safari.pushNotification);
  }

  return undefined;
};

const getNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (!permission) {
      return new Promise((resolve) => {
        Notification.requestPermission((perm) => {
          resolve(perm);
        });
      });
    }
    return permission;
  } catch (e) {
    return undefined;
  }
};

const usePush = () => {
  const [ready, setReady] = useState(false);
  const { isLogin } = useAuth();

  useEffect(() => {
    if (checkPushSupported()) {
      registerServiceWorker();
    }
  }, []);

  useEffect(() => {
    let timeOut;
    if (checkPushSupported() && isLogin && !ready) {
      timeOut = setTimeout(() => {
        registerServiceWorker().then((registration) => {
          if (registration.active) {
            getNotificationPermission().then((permission) => {
              if (permission === "granted") {
                setReady(true);
              }
            });
          }
        });
      }, StartTimeOut);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [isLogin, ready]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    getPushSubscription().then(async (pushSubscription) => {
      if (pushSubscription) {
        // renew subscription if we're within 5 days of expiration
        if (
          pushSubscription.expirationTime &&
          Date.now() > pushSubscription.expirationTime - 432e6
        ) {
          const deleteStatus = await removeSubscription(pushSubscription);
          if (deleteStatus !== 204) {
            return;
          }
        }
      }
      const subscription = await createNotificationSubscription();
      if (subscription) {
        subscribeToPush(subscription);
      }
    });
  }, [ready]);

  const removeSubscription = async (subscription) => {
    if (!checkPushSupported() || !ready) {
      return 0;
    }
    const pushSubscription = (await getPushSubscription()) || subscription;
    if (!pushSubscription) {
      return 0;
    }
    const removed = await pushSubscription.unsubscribe();
    if (removed) {
      return await unsubscribeFromPush(pushSubscription);
    }
    return 0;
  };

  const sendTestPush = () => sendPush();

  const sendTestPushTo = (receiver) => sendPushTo(receiver);

  const getPushSubscription = async () => {
    const pushManager = await getPushManager();

    if (pushManager) {
      return await pushManager.getSubscription();
    } else if (window.safari && window.safari.pushNotification) {
      console.log("SAFARI", window.safari.pushNotification);
    }
    return undefined;
  };

  return {
    sendTestPush,
    removeSubscription,
    sendTestPushTo
  };
};

const urlB64ToUint8Array = (base64String) => {
  if (!base64String) {
    return undefined;
  }
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export const createNotificationSubscription = async () => {
  const pushManager = await getPushManager();
  if (!pushManager) {
    return undefined;
  }
  const vapidKey = await getVapidKey();
  if (vapidKey) {
    return await pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(vapidKey)
    });
  }
  return undefined;
};

export default usePush;
