import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { TextField } from "../../views";
import DialogButtons from "../../dialog/DialogButtons";
import { useDialogContext } from "../../dialog/DialogContext";
import { isEventKey, KeyCodes } from "../../../shared/utils/dom";

const WorkspaceDialog = ({ titleDialog, intiTitle, positiveText, onConfirm }) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const { hideDialog } = useDialogContext();
  const [title, setTitle] = useState(intiTitle || "");

  return (
    <div>
      <div className="sc-title sc-title-margin">
        {t(titleDialog)}
      </div>
      <TextField
        label={t("title")}
        className="new-folder-input"
        value={title}
        autoFocus
        error={showError ? title.length === 0 : false}
        onKeyPress={(e) => {
          if (onConfirm && isEventKey(e, KeyCodes.enter)) {
            onConfirm(title);
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          setShowError(true);
          setTitle(e.target.value);
        }}
      />
      <DialogButtons
        onClickNegative={hideDialog}
        disabled={title.length === 0}
        onClickPositive={() => onConfirm(title)}
        negativeText={t("cancel")}
        positiveText={t(positiveText)}
      />
    </div>
  );
};

WorkspaceDialog.propTypes = {
  item: PropTypes.object,
  titleDialog: PropTypes.string,
  intiTitle: PropTypes.string,
  positiveText: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default WorkspaceDialog;
