import uiLanguagesJson from "./uiLanguages.json";

export const translateSelectOptions = (options, t) => {
  return options.map(item => {
    return { ...item, label: t(item.label) };
  });
};

export const DEFAULT_LANGUAGE = "en";

export const uiLanguages = [...uiLanguagesJson];
