import React from "react";
import PropTypes from "prop-types";
import ResizerWrap from "./ResizeWrap";

const Layout2 = ({ panelIds, vertical, onRender, defWeight }) => {
  return (
    <ResizerWrap vertical={vertical} defWeight={defWeight}  >
      {panelIds.map((item) => onRender(item))}
    </ResizerWrap>
  );
};

Layout2.propTypes = {
  panelIds: PropTypes.array,
  weight: PropTypes.array,
  vertical: PropTypes.bool,
  onRender: PropTypes.func,
  defWeight: PropTypes.number
};

export default Layout2;
