import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AdvanceSearchRightPanel from "../advSearch/AdvanceSearchRightPanel";
import SplitPane from "react-split-pane";
import { SearchPanelTabs } from "./constants";
import RelatedTab from "./RelatedTab";
import FeaturedTab from "./FeaturedTab";
import StudyCenterTree from "../studyCenter/StudyCenterTree";

export const MIN_PANEL_WIDTH = 310;

const BaseLayout = ({ tabs, onRender, size, onDragFinished }) => {
  return (
    <SplitPane
      split={"vertical"}
      defaultSize={"50%"}
      minSize={MIN_PANEL_WIDTH}
      maxSize={-MIN_PANEL_WIDTH}
      size={size}
      onDragFinished={(width) => {
        if (onDragFinished) {
          onDragFinished(width);
        }
      }}>
      {tabs.map((item, index) => onRender(item, index))}
    </SplitPane>
  );
};

const Layout2 = ({ parentWidth, tabs, onRender }) => {
  const columnSize = Math.round(parentWidth / 2);
  const [containerSize, setContainerSize] = useState(undefined);

  useEffect(() => {
    setContainerSize(undefined);
    setContainerSize(columnSize);
  }, [parentWidth]);

  return <BaseLayout tabs={tabs} size={containerSize} onRender={onRender} />;
};

const Layout3 = ({ parentWidth, tabs, onRender }) => {
  const columnSize = Math.round(parentWidth / 3);
  const [containerSize, setContainerSize] = useState(undefined);
  const [leftPaneSize, setLeftPaneSize] = useState(undefined);

  useEffect(() => {
    setContainerSize(undefined);
    setContainerSize(columnSize);
  }, [parentWidth]);

  return (
    <SplitPane
      split={"vertical"}
      primary="second"
      defaultSize={columnSize}
      size={containerSize}
      minSize={MIN_PANEL_WIDTH}
      maxSize={-((leftPaneSize || columnSize) + MIN_PANEL_WIDTH)}>
      <BaseLayout
        tabs={[tabs[0], tabs[1]]}
        onRender={onRender}
        size={containerSize}
        onDragFinished={(width) => setLeftPaneSize(width)}
      />
      {onRender(tabs[2], 2)}
    </SplitPane>
  );
};

const Layout4 = ({ parentWidth, tabs, onRender }) => {
  const paneSize = Math.round(parentWidth / 2);
  const columnSize = Math.round(parentWidth / 4);
  const [containerSize, setContainerSize] = useState(undefined);
  const [leftPaneSize, setLeftPaneSize] = useState(undefined);
  const [rightPaneSize, setRightPaneSize] = useState(undefined);
  const [pane1Width, setPane1Width] = useState(undefined);
  const [pane2Width, setPane2Width] = useState(undefined);

  useEffect(() => {
    setContainerSize(undefined);
    setLeftPaneSize(undefined);
    setRightPaneSize(undefined);
    setContainerSize(paneSize);
    setRightPaneSize(columnSize);
    setLeftPaneSize(columnSize);
  }, [parentWidth]);

  return (
    <SplitPane
      defaultSize={paneSize}
      primary="second"
      size={containerSize}
      minSize={MIN_PANEL_WIDTH * 2}
      maxSize={pane1Width ? -(pane1Width + MIN_PANEL_WIDTH) : -paneSize}
      onDragFinished={(width) => {
        const offset = parentWidth - (parentWidth - width) - pane2Width - MIN_PANEL_WIDTH;
        if (offset < 0) {
          setRightPaneSize(pane2Width - Math.abs(offset));
        } else {
          if (rightPaneSize !== undefined) {
            setRightPaneSize(undefined);
          }
        }
      }}>
      <BaseLayout
        tabs={[tabs[0], tabs[1]]}
        onRender={onRender}
        onDragFinished={(width) => setPane1Width(width)}
        size={leftPaneSize}
      />
      <BaseLayout
        tabs={[tabs[2], tabs[3]]}
        onRender={onRender}
        onDragFinished={(width) => setPane2Width(width)}
        size={rightPaneSize}
      />
    </SplitPane>
  );
};

const LockedTab = ({ tabs, parentWidth, parentHeight }) => {
  const onRender = useCallback(
    (tab, index) => {
      const key = index.toString();
      switch (tab) {
        case SearchPanelTabs.RESULTS:
          return (
            <AdvanceSearchRightPanel
              key={key}
              parentHeight={parentHeight}
              parentWidth={parentWidth}
            />
          );
        case SearchPanelTabs.RELATED:
          return <RelatedTab key={key} parentHeight={parentHeight} />;
        case SearchPanelTabs.FEATURED:
          return <FeaturedTab key={key} isInsideSearchPlus />;
        case SearchPanelTabs.STUDY:
          return <StudyCenterTree parentHeight={parentHeight} key={key} lockedTab />;
        default:
          return (
            <span className="search-placeholder" key={key}>
              Here will be content.
            </span>
          );
      }
    },
    [parentHeight, tabs],
  );

  if (tabs.length === 1) {
    return onRender(tabs[0], 0);
  } else if (tabs.length === 2) {
    return <Layout2 tabs={tabs} parentWidth={parentWidth} onRender={onRender} />;
  } else if (tabs.length === 3) {
    return <Layout3 tabs={tabs} parentWidth={parentWidth} onRender={onRender} />;
  } else if (tabs.length === 4) {
    return <Layout4 tabs={tabs} parentWidth={parentWidth} onRender={onRender} />;
  }
  return null;
};

LockedTab.propTypes = {
  tabs: PropTypes.array,
  parentWidth: PropTypes.number,
  parentHeight: PropTypes.number,
};

BaseLayout.propTypes = {
  tabs: PropTypes.array,
  onRender: PropTypes.func,
  onDragFinished: PropTypes.func,
  size: PropTypes.number,
};

Layout2.propTypes = {
  parentWidth: PropTypes.number,
  tabs: PropTypes.array,
  onRender: PropTypes.func,
};

Layout3.propTypes = {
  parentWidth: PropTypes.number,
  tabs: PropTypes.array,
  onRender: PropTypes.func,
};

Layout4.propTypes = {
  parentWidth: PropTypes.number,
  tabs: PropTypes.array,
  onRender: PropTypes.func,
};

export default LockedTab;
