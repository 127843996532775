import PropTypes from "prop-types";
import React, { useContext, createContext, useMemo, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { isIOS } from "react-device-detect";
import { useViewMode } from "src/hooks";
import { useDefLang, useParagraphSelector } from "src/redux/selectors";
import { makeSearchUrl, parseSearchUrl } from "src/utils/SearchUtils";
import { useNavigationContext } from "../NavigationContext";

import { actionUpdateSetting, actionUpdateSettings } from "src/redux/actions";
import { logSearchEvent, SearchLogEvents } from "src/shared/api/analitics";
import { CONTENT_CLASSES } from "src/shared/utils/content";
import { MENU_STATE } from "src/utils/MenuItems";
import { Settings } from "src/utils/Settings";
import { ScreenType } from "src/utils/ThemeUtils";
import { SearchPanelTabs } from "../rightPanel/constants";
import { Period } from "src/utils/AdvSearchTypes";
import { SearchActions } from "./search.actions";
import { featuredSearchActions } from "../featuredSearch/featuredSearch.actions";
import { isUserEditor } from "../studyCenter/EditorCenterUtils";
import {
  getSearchTypeByPeriod,
  PeriodDefault, QUERY_TYPE,
  SearchType,
  SNIPPET
} from "../../shared/utils/search";
import { actionRelatedType } from "../relevantSearch/relevantSearch.actions";
import { RelatedTabType } from "../relevantSearch/RelevantSearch.utils";
import { URLS } from "../../shared/utils/url";
import { HASH_S_PLUS_OPENED_AND_SEARCHED } from "./search.utils";

const SearchContext = createContext();

// For better UX
// e.g. replaces "DBY — Genesis 1:1" with "Genesis 1:1",
// Bcz ref code with a "DBY —" part is not so good for text search.
const enhanceRefCodeForTextSearch = (refCodeShort) => {
  if (typeof refCodeShort !== "string") {
    return undefined;
  }

  if (refCodeShort.includes("—")) {
    return refCodeShort.split("—").pop();
  }

  return refCodeShort;
};

const SearchProvider = ({ children }) => {
  const detect = useSelector((state) => state.search.searchFilter.detect);
  const searchLang = useSelector((state) => state.search.searchLang);
  const dispatch = useDispatch();
  const history = useHistory();
  const { openGlobalSearchResult, activeId, openId, isReader } = useNavigationContext();
  const shortcuts = useSelector((state) => state.search.searchFilter.shortcuts);
  const { isMobile, isMobileOrTablet, isTablet, blindMode, sizeMode } = useViewMode();
  const isShowRightPanel = useSelector((state) => state.settings.isShowRightPanel);
  const isRightPanelPinned = useSelector((state) => state.settings.isRightPanelPinned);
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);
  const searchParams = useSelector((state) => state.search.searchParams);
  const bibles = useSelector((state) => state.mainTree.bibles);
  const isShowHeaderSearch = useSelector((state) => state.search.isShowHeaderSearch);
  const syncReaderAndSearch = useSelector((state) => state.settings.syncReaderAndSearch);
  const shortcutContent = useSelector((state) => state.search.shortcutContent.results);
  const showShortcutsForShortcut = useSelector((state) => state.settings.showShortcutsForShortcut);

  const isCurrentQueryHasShortcuts = shortcutContent.length > 0;
  const isShortcutsAvailableAndShouldBeShown =
    isCurrentQueryHasShortcuts && showShortcutsForShortcut;

  const { location } = history;
  const pathname = location.pathname;

  const defLang = useDefLang();

  const activeReaderIdParas = useParagraphSelector(activeId);
  const syncWithReaderPara = useMemo(() => {
    if (!activeReaderIdParas?.length || !syncReaderAndSearch || !activeId) {
      return undefined;
    }

    return activeReaderIdParas?.find((para) => para.id === activeId);
  }, [activeReaderIdParas, activeId, syncReaderAndSearch]);

  const syncWithReaderParaRefcodeShort = enhanceRefCodeForTextSearch(
    syncWithReaderPara?.refcode_short
  );

  const syncReaderParaAndSearchShortcuts = useCallback(() => {
    if (activeId && syncWithReaderParaRefcodeShort) {
      dispatch(SearchActions.fetchShotcutContent({
        paraId: activeId,
        queryAnalogue: syncWithReaderParaRefcodeShort,
      }));
    }
  }, [activeId, syncWithReaderParaRefcodeShort]);

  useEffect(() => {
    const unregCallback = history.listen((location, action) => {
      if (action === "POP"  && isReader && isShowHeaderSearch && !location.hash) {
        dispatch(SearchActions.updateShowHeaderSearch(false));
        dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
      }
    });

    return unregCallback;
  }, [isReader, isShowHeaderSearch]);

  const currentSearchLang = useMemo(() => {
    if (detect.length > 0) {
      if (detect.includes(searchLang)) {
        return searchLang;
      }
      return detect[0];
    }
    return defLang;
  }, [detect, searchLang, defLang]);

  const updateSearchURL = ({ lang, query }) => {
    const params = parseSearchUrl(location.search);
    if (lang) {
      params.langs = [lang];
    }
    if (query) {
      params.query = query;
    }
    const newRelatedUrl = makeSearchUrl(params, collectionFilter, true);
    if (pathname === URLS.relatedSearch) {
      history.replace(newRelatedUrl);
    } else {
      history.push(newRelatedUrl);
    }
  };

  const openSearchHistory = (searchValue) => {
    dispatch(SearchActions.setSearchQuery(searchValue));
    handleMakeSearch({
      query: searchValue,
    });
  };

  const handleMakeSearch = (searchData) => {
    const { query, type, isSuggest, newLang } = searchData;
    if (!query?.length) {
      return;
    }
    const resultLang = newLang || currentSearchLang || defLang;

    if (type !== "translate" && type !== "init") {
      // EGWW-3224 6. new search should every time set to default language on enter
      dispatch(SearchActions.changeRelatedLang(resultLang));
    }
    let shortCutData = {};
    if (type !== "onlyText" && shortcuts?.length > 0) {
      let langShortcut = shortcuts.find((item) => item.lang === resultLang);
      // try again only if no match previous find
      if (!langShortcut) {
        langShortcut = shortcuts.find((item) => item.lang === defLang);
      }
      if (langShortcut) {
        shortCutData = langShortcut;
      }
      if (!langShortcut && shortcuts[0]?.cutQuery) {
        shortCutData = shortcuts[0];
      }
    }

    const period = collectionFilter || PeriodDefault;
    const baseExtras = {
      snippet: SNIPPET.SHORT,
      period,
      type: getSearchTypeByPeriod(period),
      queryType: QUERY_TYPE.PREFER_EXACT,
    };
    let books;
    // if some book open in reader on mobile - put it id to search values
    if (activeId && isMobile) {
      baseExtras.period = Period.custom;
      baseExtras.type = SearchType.basic;
      baseExtras.bookfirst = activeId;
    }

    const isMainSearch = pathname === URLS.search;
    const newSettings = {
      [Settings.isShowRightPanel.id]: true,
      [Settings.rightTab.id]: SearchPanelTabs.RESULTS,
    };

    if (isMobileOrTablet) {
      newSettings[Settings.menuState.id] = MENU_STATE.COLLAPSE;
    }

    const { shortCutType, paraId, bookId, hits } = shortCutData;

    if (bookId && !(shortCutType === "search" && hits === 0 && paraId)) {
      dispatch(SearchActions.setSearchNeedleChange(query));
      logSearchEvent(SearchLogEvents.SEARCH_SHORTCUT, {
        query,
        lang: resultLang,
        bookId,
      });
      if (shortCutType === "search") {
        if (hits === 0) {
          openId(bookId, { className: CONTENT_CLASSES.BOOK });
        } else {
          // OPEN search results page for search word in book e.g. aa hope
          const params = {
            query: query,
            start: 1,
            langs: [],
            extras: {
              ...baseExtras,
              period: Period.custom,
              type: SearchType.basic,
            },
            rightPanel: !isMainSearch,
            isSuggest,
          };

          if (paraId) {
            params.chapters = [paraId];
          } else {
            params.books = [bookId];
          }

          if (isMobileOrTablet) {
            newSettings[Settings.menuState.id] = MENU_STATE.COLLAPSE;
          } else {
            newSettings[Settings.rightTab.id] = SearchPanelTabs.RELATED;
          }
          // for mobile search opens a S+ panel instead of open a SearchPage
          if (isMobile) {
            dispatch(
              SearchActions.fetchSearch({
                ...params,
                firstSearch: searchParams.firstSearch,
                onlyText: type === "onlyText",
              }),
            );
            dispatch(SearchActions.setShowFilterSearch(false));
          } else {
            history.push(makeSearchUrl(params, collectionFilter));
          }
        }
      } else if (paraId) {
        // OPEN reader by shortcut, for  open simple book or chapter
        // 16.07.21 YO open side panel and start search looks buggy for navigation to book
        // dispatch(SearchActions.clearSearch({ resetSearch: true }));
        openGlobalSearchResult(paraId, false);

        const bibleBook = bibles.find((item) => item.id === bookId);
        if (shortCutType === "ref" && !bibleBook) {
          newSettings[Settings.rightTab.id] = SearchPanelTabs.FEATURED;
          if (isUserEditor()) {
            newSettings[Settings.rightTab.id] = SearchPanelTabs.STUDY;
          }
        } else {
          dispatch(actionRelatedType(bibleBook ? RelatedTabType.BIBLE : RelatedTabType.EGW));
          newSettings[Settings.rightTab.id] = SearchPanelTabs.RESULTS;
        }

        if (isMobile) {
          newSettings[Settings.isShowRightPanel.id] = false;
          dispatch(SearchActions.setShowFilterSearch(false));
          dispatch(SearchActions.updateShowHeaderSearch(false));
        }

        newSettings[Settings.showShortcutsForShortcut.id] = true;
        // fetch shortcut content itself
        dispatch(
          SearchActions.fetchShotcutContent({
            shortcut: shortCutData,
            paraId,
            queryAnalogue: query,
          }),
        );
        // fetch featured search content for paragraph
        dispatch(featuredSearchActions.fetchForParagraph(paraId));
      }
    } else {
      if (isTablet) {
        if (blindMode) {
          if (
            sizeMode === ScreenType.DESKTOP ||
            sizeMode === ScreenType.DESKTOP2K ||
            sizeMode === ScreenType.DESKTOP4K ||
            sizeMode === ScreenType.DESKTOP8K ||
            (sizeMode === ScreenType.DESKTOP_SMALL && isIOS)
          ) {
            // If we have desktop with screen > 1680x1050 and do search.
            // or if it is Ipad with desktop screen size.
            newSettings[Settings.isRightPanelPinned.id] = true;
          } else if (
            sizeMode === ScreenType.DESKTOP_SMALL ||
            sizeMode === ScreenType.DESKTOP_MEDIUM
          ) {
            // if we have screen smaller < 1680 and do search we show unpinned.
            newSettings[Settings.isRightPanelPinned.id] = false;
          }
          newSettings[Settings.isShowRightPanel.id] = true;
        }

        // Closes not pinned right panel after searched on tablet screens.
        if (sizeMode === ScreenType.TABLET && isShowRightPanel && !isRightPanelPinned) {
          newSettings[Settings.isShowRightPanel.id] = false;
        }
      }

      // Start simple search process
      const params = {
        query,
        start: 1,
        langs: [resultLang],
        books,
        extras: baseExtras,
        rightPanel: !isMainSearch,
        isSuggest,
        firstSearch: searchParams.firstSearch,
        onlyText: type === "onlyText",
      };
      if (type === "translate") {
        params.extras.firstLang = currentSearchLang;
      }
      dispatch(SearchActions.fetchSearch(params));

      // not needed update url on mobile, because mobile show only side panel without change url
      if (isMobile) {
        newSettings[Settings.rightTab.id] = SearchPanelTabs.RESULTS;
      } else {
        updateSearchURL({
          lang: resultLang,
          query,
        });
      }

      if (isMobile && isReader) {
        const newLocation = history.location.pathname
          + history.location.search
          + "#" + HASH_S_PLUS_OPENED_AND_SEARCHED;
        history.push(newLocation);
      }
    }
    dispatch(actionUpdateSettings(newSettings));
  };

  return (
    <SearchContext.Provider
      value={{
        makeSearch: handleMakeSearch,
        updateSearchURL,
        openSearchHistory,
        syncReaderParaAndSearchShortcuts,
        isCurrentQueryHasShortcuts,
        isShortcutsAvailableAndShouldBeShown,
      }}>
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * @typedef {Object} SearchContextParams
 * @property {boolean} isCurrentQueryHasShortcuts
 * @property {boolean} isShortcutsAvailableAndShouldBeShown
 * @property {function} syncReaderParaAndSearchShortcuts
 * @property {function} makeSearch
 * @property {function} updateSearchURL
 * @property {function} openSearchHistory
 */

/* @returns {SearchContextParams} */
export const useSearchContext = () => useContext(SearchContext);

export default SearchProvider;
