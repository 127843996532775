import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { DotsVerticalIcon, ChevronRightIcon } from "../../assets/CommonIcons";
import { scActions, StudyCenterActions } from "./studyCenter.actions";
import { TagIcon, subsFolderOrItem } from "./StudyCenterUtils";
import {
  ItemTypes,
  STUDY_CENTER_TREE_ITEM_HEIGHT,
  RootFolder,
  SubscriptionTopicFolder,
} from "./studyCenter.constants";
import { IconButton, SearchField } from "../views";
import { Draggable, Droppable, DragDropContext } from "../../components/dndList";
import { useAuth, useDeviceDetect, useViewMode } from "../../hooks";
import { fillContrastColor } from "../../utils/ThemeUtils";
import EgwWebFont from "src/assets/EgwWebFont";
import { getCheckIcon } from "../sideMenu/FolderTreeUtils";
import { defEditorHighLightColor } from "./EditorCenterUtils";
import { useRemToPx } from "../../hooks/viewModeHooks";

import "./ScTree.scss";

const ScTreeView = ({
  onItemClick,
  activeId,
  treeList,
  count,
  parentWidth,
  parentHeight,
  showIcons,
  onOverChange,
  onDragEnd,
  onContextMenu,
  showContextMenu,
  onScrollStop,
  lockedTab,
  onCheck,
  expanded = [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isRealMobileOrTablet } = useDeviceDetect();
  const { themeMode } = useViewMode();
  const remToPx = useRemToPx();
  const scrollerRef = useRef();
  const { showLoginDialog, isUserEditor, isLogin } = useAuth();
  const colors = useSelector((state) => state.studyCenter.colors);
  const currentTopicId = useSelector((state) => state.studyCenter.currentTopicId);
  const currentEntryId = useSelector((state) => state.studyCenter.currentEntryId);
  const lastCreateEntry = useSelector((state) => state.studyCenter.lastCreateEntry);
  const searchText = useSelector((state) => state.studyCenter.searchText);
  const editorTempEntry = useSelector((state) => state.settings.editorTempEntry);

  const [needle, setNeedle] = useState("");

  const scItemHeight = remToPx(STUDY_CENTER_TREE_ITEM_HEIGHT);

  const handleClickPleaseLogin = () => {
    showLoginDialog();
  };

  const handleSearch = (query) => {
    dispatch(StudyCenterActions.setSCSearchText(query));
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (scrollerRef.current && (lastCreateEntry?.id || currentTopicId)) {
        let scrollPos;
        const index = treeList.findIndex(
          (item) => item.id === (lastCreateEntry?.id || currentTopicId),
        );
        if (index === treeList.length - 1) {
          scrollPos = treeList.length * scItemHeight;
        } else {
          scrollPos = index * scItemHeight;
        }

        const scrollTop = scrollerRef.current.getScrollTop() + scItemHeight;
        const scrollBottom = scrollTop + scrollerRef.current.getClientHeight() - scItemHeight * 2;
        if (index !== -1 && (scrollPos < scrollTop || scrollPos > scrollBottom)) {
          scrollerRef.current.scrollTop(scrollPos);
        }
      }
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [lastCreateEntry, treeList, scItemHeight, currentTopicId, expanded]);

  const renderIcons = (item) => {
    if (!showIcons) {
      return null;
    }
    const isFolder = item.type === "folder";
    const isTempEntry = item.type === ItemTypes.editorTemp;
    const isRootItem = item.id === RootFolder.id;
    const isSubsFolderOrItem = subsFolderOrItem(item);

    const content = isRootItem ? (
      <div className="sc-tree-menu-top">
        {!isUserEditor && (
          <>
            <IconButton
              title={t("createFolder")}
              icon={EgwWebFont.plus}
              onClick={(e) => {
                if (e?.stopPropagation) {
                  e.stopPropagation();
                }
                onItemClick(item, scActions.newFolder);
              }}
            />
            <IconButton
              title={t("search")}
              icon={EgwWebFont.search}
              onClick={(e) => {
                if (e?.stopPropagation) {
                  e.stopPropagation();
                }
                onItemClick(item, scActions.navigateWithSearch);
              }}
            />
          </>
        )}
        <IconButton
          title={t("view")}
          icon={EgwWebFont.view}
          onClick={(e) => {
            if (e?.stopPropagation) {
              e.stopPropagation();
            }
            onItemClick(item, scActions.navigate);
          }}
        />
      </div>
    ) : (
      <div className={"sc-tree-menu"}>
        {!isSubsFolderOrItem && (
          <>
            {!isUserEditor && (
              <IconButton
                title={t("createFolder")}
                icon={EgwWebFont.plus}
                onClick={(e) => {
                  if (e?.stopPropagation) {
                    e.stopPropagation();
                  }
                  onItemClick(item, scActions.newFolder);
                }}
              />
            )}
            {!(isUserEditor && !item.topic) && (
              <IconButton
                title={t("edit")}
                icon={EgwWebFont.edit}
                onClick={(e) => {
                  if (e?.stopPropagation) {
                    e.stopPropagation();
                  }
                  onItemClick(item, scActions.edit);
                }}
              />
            )}
          </>
        )}
        {!(isUserEditor && isFolder) && (
          <IconButton
            title={t("delete")}
            icon={EgwWebFont.delete}
            onClick={(e) => {
              if (e?.stopPropagation) {
                e.stopPropagation();
              }
              onItemClick(item, scActions.delete);
            }}
          />
        )}
        {!isTempEntry && (
          <IconButton
            title={t("view")}
            icon={EgwWebFont.view}
            onClick={(e) => {
              if (e?.stopPropagation) {
                e.stopPropagation();
              }
              onItemClick(item, scActions.navigate);
            }}
          />
        )}
      </div>
    );

    if (!isRootItem && isRealMobileOrTablet) {
      return (
        <IconButton
          className="sc-dots-menu-icon"
          icon={DotsVerticalIcon}
          ariaLabel={t("options")}
          onClick={(e) => {
            if (e?.stopPropagation) {
              e.stopPropagation();
            }
            showContextMenu(item, e);
          }}
        />
      );
    }

    return content;
  };

  const onCheckHandler = (event, checkItem) => {
    event.stopPropagation();
    if (onCheck) {
      onCheck(checkItem);
    }
  };

  const treeListWithUpdatedColors = useMemo(() => {
    const defColor = isUserEditor ? defEditorHighLightColor.color : "#000000";
    return treeList.map((item) => {
      const isDefColor = item.color === "#000000";
      const colorIndex = colors.findIndex((colorItem) => colorItem.id === item.color_id);
      const color = colorIndex !== -1 && !isDefColor ? colors[colorIndex].color : defColor;

      return {
        ...item,
        color,
      };
    });
  }, [isUserEditor, colors, treeList]);

  const renderRow = (item, index) => {
    const { showExpand, isExpanded } = item;
    const ExpandFolderIcon = isExpanded ? EgwWebFont["folder-open"] : EgwWebFont["folder-close"];
    let ItemIcon = TagIcon[item.type];

    let fill,
      itemTitle = item.title;
    let className = [];

    if (item.type === ItemTypes.highlight || item.type === ItemTypes.editorHighlight) {
      fill = fillContrastColor(item.color, themeMode);
      className.push("entry");
    } else if (item.type === ItemTypes.bookmark || item.type === ItemTypes.note) {
      fill = "var(--sc-icon)";
      className.push("entry");
    } else if (item.type === ItemTypes.folder) {
      className.push(ItemTypes.folder);
      ItemIcon = ExpandFolderIcon;
      if (item.id === RootFolder.id) {
        itemTitle = t(isUserEditor ? item.titleEditor : item.title);
      }
      if (item.id === SubscriptionTopicFolder.id) {
        itemTitle = t(item.title);
      }
    }
    const paddingLeft = item.level ? item.level * 5 + 20 : 5;
    if (lastCreateEntry?.id === item.id) {
      className.push("active");
    }
    if (currentTopicId === item.id || currentEntryId === item.id) {
      className.push("active-nav");
    }

    if (!lastCreateEntry?.id && item.start_para && item.end_para) {
      if (activeId === item.chapter || activeId === item.start_para || activeId === item.end_para) {
        className.push("active");
      }
    }

    const parentClassName = classNames("sc-drg sc-tree-row", className, {
      "active-row": expanded.includes(item.id) || item.isExpanded,
    });

    const isSubsFolderOrItem = subsFolderOrItem(item);
    const isEditorTempEntry = item.type === ItemTypes.editorTemp;
    const CheckIcon = getCheckIcon(item.checkType);

    return (
      <Draggable
        disabled={item.type === ItemTypes.folder || isSubsFolderOrItem || isUserEditor}
        key={index}
        className={parentClassName}
        disableMove={item.type === ItemTypes.folder}
        type={item.type === ItemTypes.folder ? ItemTypes.folder : "entry"}
        dragActiveClass="draggable-active"
        dragAndDropGroup={"scTree"}
        style={{ paddingLeft }}
        onContextMenu={(e) => {
          if (onContextMenu) {
            onContextMenu(item, e);
          }
        }}
        onClick={() => onItemClick(item)}
        draggableId={item.id}>
        <div className={classNames("sc-tree-title-part", { isEditor: isUserEditor })}>
          {showExpand && (
            <ChevronRightIcon
              className={classNames("sc-tree-expand", {
                "is-expanded": isExpanded,
              })}
              onClick={(e) => {
                e.stopPropagation();
                onItemClick(item, scActions.toggleExpand);
              }}
            />
          )}
          {isUserEditor && item.type === ItemTypes.folder && (
            <CheckIcon onClick={(event) => onCheckHandler(event, item)} className="sc-check-icon" />
          )}
          <IconButton icon={ItemIcon} fill={fill} className={"sc-tree-icon"} />

          <span className={"sc-tree-title"} dangerouslySetInnerHTML={{ __html: itemTitle }} />
          <span className={classNames("sc-tree-text", { "locked-tab": lockedTab })}>
            {item.text}
          </span>
        </div>
        {renderIcons(item)}
      </Draggable>
    );
  };

  if (!isLogin) {
    return (
      <div>
        {renderRow(RootFolder, 0)}
        <span onClick={handleClickPleaseLogin} className="study-center-please-login">
          {t("studyCenterPlaceholder")}
        </span>
      </div>
    );
  } else if (isLogin && count === 0 && !(isUserEditor && editorTempEntry?.selectData)) {
    return (
      <div>
        {renderRow(RootFolder, 0)}
        <div className="study-center-placeholder">{t("scNoElements")}</div>
      </div>
    );
  }

  return (
    <DragDropContext
      style={{ width: parentWidth }}
      dragAndDropGroup={"scTree"}
      outerScrollBar={false}
      onOverChange={onOverChange}
      autoScrollThreshold={scItemHeight * 2}
      onDragEnd={(source, _, placeholderId) => {
        if (onDragEnd) {
          onDragEnd(source.draggableId, placeholderId);
        }
      }}>
      {isUserEditor && (
        <SearchField
          className="searchMenu"
          value={needle}
          onSearch={handleSearch}
          onChange={(search) => {
            if (search === "" && searchText !== "") {
              dispatch(StudyCenterActions.setSCSearchText(""));
            }
          }}
          onClear={() => {
            setNeedle("");
            handleSearch("");
          }}
          placeholder={t("titleSearch")}
        />
      )}
      <Droppable
        containerHeight={parentHeight}
        dragAndDropGroup={"scTree"}
        droppableId={"scTree"}
        elemHeight={scItemHeight}
        ref={scrollerRef}
        scrollProps={{
          onScrollStop: (params) => {
            if (onScrollStop) {
              onScrollStop(params);
            }
          },
        }}
        placeholderStyle={{
          backgroundColor: "var(--row-highlight)",
        }}>
        {treeListWithUpdatedColors.map(renderRow)}
      </Droppable>
    </DragDropContext>
  );
};

ScTreeView.defaultProps = {};

ScTreeView.propTypes = {
  treeList: PropTypes.array,
  current: PropTypes.string,
  activeId: PropTypes.string,
  onItemClick: PropTypes.func,
  count: PropTypes.number,
  parentHeight: PropTypes.number,
  parentWidth: PropTypes.number,
  showIcons: PropTypes.bool,
  onOverChange: PropTypes.func,
  onContextMenu: PropTypes.func,
  showContextMenu: PropTypes.func,
  onScrollStop: PropTypes.func,
  onCheck: PropTypes.func,
  onDragEnd: PropTypes.func,
  lockedTab: PropTypes.bool,
  expanded: PropTypes.array,
};

export default ScTreeView;
