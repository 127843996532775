import { localStorageSafe } from "src/shared/utils/systemUtils";
import {
  FETCH_TRANSLATION_BOOK_SUCESS,
  FETCH_SEGMENTS_TRANSLATION_SUCCESS,
  FETCH_AVAILABLE_LANGUAGES_SUCCESS,
  ACTION_SET_ELLEN4ALL_TOKEN,
} from "../constants";
import { ELLEN4ALL_TOKEN_KEY } from "../../api/translate.utils";

const initialState = {
  translations: {},
  segments: [],
  languages: {},
  ellen4AllToken: localStorageSafe.getItem(ELLEN4ALL_TOKEN_KEY),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSLATION_BOOK_SUCESS:
      return {
        ...state,
        translations: { ...state.translations, ...action.data },
      };
    case FETCH_SEGMENTS_TRANSLATION_SUCCESS:
      return { ...state, segments: action.data };
    case FETCH_AVAILABLE_LANGUAGES_SUCCESS: {
      const { bookId, content } = action.data;

      return { ...state, languages: { ...state.languages, [bookId]: content } };
    }
    case ACTION_SET_ELLEN4ALL_TOKEN: {
      const token = action.data;
      if (token) {
        localStorageSafe.setItem(ELLEN4ALL_TOKEN_KEY, token);
      } else {
        localStorageSafe.removeItem(ELLEN4ALL_TOKEN_KEY);
      }

      return { ...state, ellen4AllToken: token };
    }
    default:
      return state;
  }
};
