import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { HideIcon, IconButton, Scroll } from "../views";
import { usePopupId, useViewMode } from "../../hooks";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { useNavigationContext } from "../NavigationContext";
import { SearchActions } from "../search/search.actions";
import { useLangs } from "../../redux/selectors";
import EgwWebFont from "src/assets/EgwWebFont";
import { SearchItemType } from "src/shared/utils/search";
import { usePopupContext } from "../popup/PopupContext";
import AdvanceSearchLanguageSelector from "../languageSelector/AdvanceSearchLanguageSelector";
import { getBoundingClientRect, isEventKeys } from "src/utils/Utils";
import { logSearchEvent, SearchLogEvents } from "src/shared/api/analitics";
import { CONTENT_CLASSES } from "../../shared/utils/content";
import { useRemToPx } from "../../hooks/viewModeHooks";

const POPUP_ID = "advSearchLang";

const MAX_ROWS = 6;

const MARGIN_LEFT = 7;

const FilterBlockIndex = {
  [SearchItemType.CORRECTION]: {
    index: 10,
    oneLine: true,
  },
  [SearchItemType.TRANSLATIONS]: {
    index: 20,
    oneLine: true,
  },
  [SearchItemType.WORD]: {
    index: 30,
    singleColumn: true,
    oneLine: true,
  },
  [SearchItemType.AUTOCOMPLETE]: {
    index: 40,
  },
  [SearchItemType.SYNONYM]: {
    index: 50,
  },
  [SearchItemType.SUGGESTION]: {
    index: 60,
  },
  [SearchItemType.RECENT]: {
    index: 70,
  },
  [SearchItemType.RECENT_MORE]: {
    index: 80,
    singleColumn: true,
    oneLine: true,
  },
};

// get array of only single column items
const SingleColumnItems = Object.keys(FilterBlockIndex)
  .filter((key) => {
    return FilterBlockIndex[key].singleColumn;
  })
  .map((item) => FilterBlockIndex[item].index);

//only for synonym. Replace last word from searched phrase to synonym
function getSynonymValue(item, search) {
  let searchValue;
  let words = search.trim().split(" ");
  let lastword = "";
  if (words && words.length > 0) {
    lastword = words[words.length - 1];
    if (lastword.length > 0) {
      lastword = item;

      words.splice(-1, 1);
      searchValue = words.join(" ") + " " + lastword;
      searchValue = searchValue.trim();
    }
  }
  return searchValue;
}

function makeArrayOfIndex(baseValue, size) {
  const list = [];
  const normalBaseValue = Math.round(baseValue);
  for (let i = 0; i < size; i++) {
    list.push(normalBaseValue + i);
  }
  return list;
}

function renderItemIcon(type) {
  switch (type) {
    case SearchItemType.SUGGESTION:
      return EgwWebFont["search-phrase"];
    case SearchItemType.CORRECTION:
      return EgwWebFont["search-corrections"];
    case SearchItemType.SYNONYM:
      return EgwWebFont["search-suggestions"];
    case SearchItemType.RECENT:
      return EgwWebFont["search-history"];
    case SearchItemType.AUTOCOMPLETE:
    default:
      return EgwWebFont.search;
  }
}

const SearchFilterPopup = ({
  parentWidth,
  headerHeight,
  search = "",
  onFilterSearch,
  setSearchInputValue,
  hideFilter,
  onChangeLang,
  setInputFocused,
  detectedLang,
  onChangeDetectedLang,
  inputFocused,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const langList = useLangs();

  const { isMobile, isTablet } = useViewMode();
  const remToPx = useRemToPx();
  const searchHistory = useSelector((state) => state.search.searchHistory);
  const isLogin = useSelector((state) => state.system.isLogin);
  const [expand, setExpand] = useState(false);
  const { openId } = useNavigationContext();
  const { showPopup, hidePopup } = usePopupContext();
  const isShowPopup = usePopupId(POPUP_ID);
  const [[activeX, activeY], setActiveXY] = useState([0, 0]);

  const [isShowMoreLangs, setIsShowMoreLangs] = useState(false);
  const [isShowMoreCorrections, setIsShowMoreCorrections] = useState(false);

  const {
    searchedQuery,
    detect,
    translate,
    autocomplete,
    suggestions,
    corrections,
    synonyms,
    suggestionLoaded,
  } = useSelector((state) => state.search.searchFilter);

  const onOpenLangPopup = (event) => {
    const targetRect = getBoundingClientRect(event.target);
    showPopup(
      {
        right: targetRect.right,
        top: targetRect.bottom,
      },
      <AdvanceSearchLanguageSelector
        languages={langList}
        selectedLanguage={detectedLang}
        onChange={(_, item) => {
          onChangeLang(item.id);
          hidePopup();
        }}
      />,
      {
        targetElement: event.target,
        popupId: POPUP_ID,
      },
    );
  };

  const handleChangeDetectLang = (lang) => {
    logSearchEvent(SearchLogEvents.SEARCH_CHANGE_LANG, { lang });
    dispatch(SearchActions.fetchTranslate(detectedLang, lang, search));
  };

  const maxLangsTranslateCount = useMemo(() => {
    const oneElement = remToPx(2);

    if (isMobile) {
      const maxWidth = parentWidth - remToPx(6);
      let maxElementCount = Math.round(maxWidth / oneElement);
      return isShowMoreLangs ? maxElementCount : 2;
    }

    if (isShowMoreLangs) {
      return detect.length + translate.length;
    }

    // approx value for avoid labels translate to more MARGIN_LEFT
    // correctionsCount
    // 2 - paddings, 6 - translatons, 6 - more + to
    let countAdd = 2 + 6 + 6 + 2;
    if (correctionsCount === 0) {
      countAdd += MARGIN_LEFT;
    }
    const maxWidth = parentWidth - remToPx(countAdd);
    // set min size of langs is 1
    let maxElementCount = Math.max(1, Math.round(maxWidth / oneElement)) / 2;

    return maxElementCount;
  }, [
    parentWidth,
    remToPx,
    isShowMoreLangs,
    isMobile,
    detect.length,
    translate.length,
    correctionsCount,
  ]);

  const langsCount = useMemo(() => {
    let maxDetectCount = Math.min(detect.length, maxLangsTranslateCount);
    let maxLangCount = Math.min(translate.length, maxLangsTranslateCount);
    return maxDetectCount + maxLangCount;
  }, [maxLangsTranslateCount, detect, translate]);

  const maxRowCount = expand ? MAX_ROWS : 1;

  let rowWidth = 230;
  let columnsCount = 2;
  let elWidth = parentWidth;

  if (isMobile || isTablet) {
    columnsCount = 2;
    if (isMobile) {
      elWidth = window.innerWidth - 40;
      rowWidth = 140;
    }
  } else {
    columnsCount = Math.floor((elWidth - 28) / rowWidth);
  }

  const possibleLangRowCounts = useMemo(() => {
    if (langsCount === 0) {
      return 0;
    }
    if (isMobile) {
      return isShowMoreLangs ? 2 : 1;
    }
    let baseRowCount = isShowMoreLangs ? 2 : 1;

    if (isMobile) {
      return baseRowCount;
    }

    if (isShowMoreLangs) {
      let possibleRows = 0;
      if (detect.length > 0) {
        // +4 for translate label
        const possibleDetectRows = Math.round(
          (detect.length * remToPx(2) + remToPx(4)) / parentWidth + 0.5,
        );
        possibleRows += possibleDetectRows;
      }

      if (translate.length > 0) {
        const possibleTranslateRows = Math.round(
          (translate.length * remToPx(2)) / parentWidth + 0.5,
        );
        possibleRows += possibleTranslateRows;
      }

      baseRowCount = possibleRows;
    }

    return baseRowCount;
  }, [
    isMobile,
    isShowMoreLangs,
    langsCount,
    parentWidth,
    remToPx,
    detect.length,
    translate.length,
  ]);

  const { autocompleteElements, rowsCount, isRenderMore } = useMemo(() => {
    const getMinCount = (array) => {
      const minCount = Math.round(array.length / columnsCount + 0.5);
      return Math.min(minCount, maxRowCount);
    };

    const makeArrayByType = (searchItemType, array) => {
      const maxRowValue = Math.min(maxRowCount, array.length / columnsCount);
      return makeArrayOfIndex(FilterBlockIndex[searchItemType].index, maxRowValue);
    };
    let possibleRowCount = 0;
    let list = [];

    if (corrections.length > 0) {
      list.push(FilterBlockIndex[SearchItemType.CORRECTION].index);
      possibleRowCount++;
    }

    if (possibleLangRowCounts > 0) {
      list.push(FilterBlockIndex[SearchItemType.TRANSLATIONS].index);
      list.push(FilterBlockIndex[SearchItemType.WORD].index);
      // +1 is for add row for "Search text:" row
      possibleRowCount++;
      // Rows of langs have less size and calculation is in filterHeight useMemo
      // possibleRowCount += possibleLangRowCounts + 1;
    }
    if (!isMobile) {
      if (autocomplete.length > 0) {
        list.push(...makeArrayByType(SearchItemType.AUTOCOMPLETE, autocomplete));
        possibleRowCount += getMinCount(autocomplete);
      } else {
        possibleRowCount++;
      }
      if (synonyms.length > 0) {
        list.push(...makeArrayByType(SearchItemType.SYNONYM, synonyms));
        possibleRowCount += getMinCount(synonyms);
      } else {
        possibleRowCount++;
      }
    }

    if (suggestions.length > 0) {
      list.push(...makeArrayByType(SearchItemType.SUGGESTION, suggestions));
      possibleRowCount += getMinCount(suggestions);
    } else {
      possibleRowCount++;
    }
    if (searchHistory.length > 0) {
      list.push(...makeArrayByType(SearchItemType.RECENT, searchHistory));
      possibleRowCount += getMinCount(searchHistory);
    }
    let isRenderMore = false;
    const maxLen = maxRowCount * columnsCount;
    if (
      expand ||
      searchHistory.length > maxLen ||
      suggestions.length > maxLen ||
      corrections.length > maxLen ||
      synonyms.length > maxLen ||
      autocomplete.length > maxLen
    ) {
      isRenderMore = true;
      list.push(FilterBlockIndex[SearchItemType.RECENT_MORE].index);
      possibleRowCount++;
    }

    return { autocompleteElements: list, rowsCount: possibleRowCount, isRenderMore };
  }, [
    expand,
    isMobile,
    corrections,
    autocomplete,
    synonyms,
    suggestions,
    searchHistory,
    maxRowCount,
    columnsCount,
    possibleLangRowCounts,
  ]);

  const correctionsCount = useMemo(() => {
    if (corrections.length > 1) {
      return isShowMoreCorrections ? corrections.length + 1 : 2;
    }
    return corrections.length;
  }, [isShowMoreCorrections, corrections]);

  /**
   * handle keyboard arrow events and update position
   */
  const handleUpdateKeyPosition = useCallback(
    (direction) => {
      let newX = activeX;
      let newY = activeY;
      if (direction === KeyCodes.leftArrow) {
        newX = activeX - 1;
      } else if (direction === KeyCodes.rightArrow) {
        newX = activeX + 1;
      }
      if (newY === FilterBlockIndex[SearchItemType.CORRECTION].index) {
        if (correctionsCount === 1) {
          newX = 0;
        } else {
          if (newX > correctionsCount - 1) {
            newX = 0;
          }
          if (newX < 0) {
            newX = correctionsCount - 1;
          }
        }
      } else if (newY === FilterBlockIndex[SearchItemType.TRANSLATIONS].index) {
        if (newX > langsCount) {
          newX = 0;
        }
        if (newX < 0) {
          // not -1 because more button can be focusable
          newX = langsCount;
        }
      } else {
        if (newX >= columnsCount) {
          newX = 0;
        }
        if (newX < 0) {
          newX = columnsCount - 1;
        }
      }

      if (direction === KeyCodes.upArrow) {
        newY = activeY - 1;

        let upListY = autocompleteElements.filter((v) => newY >= v);
        let posY = autocompleteElements.indexOf(newY);
        if (posY < 0 && newY >= 0 && upListY.length > 0) {
          newY = upListY[upListY.length - 1];
        } else if (upListY.length === 0) {
          setInputFocused(true);
          setSearchInputValue(searchedQuery);
          setActiveXY([0, 0]);
        }
      } else if (direction === KeyCodes.downArrow) {
        newY = activeY + 1;
        let downListY = autocompleteElements.filter((v) => newY <= v);
        let posY = autocompleteElements.indexOf(newY);
        if (posY < 0 && newY >= 0 && downListY.length > 0) {
          newY = downListY[0];
        } else if (downListY.length < 1) {
          newY = autocompleteElements[0];
        }
      }
      // RESET to zero for single column items
      if (SingleColumnItems.includes(newY)) {
        newX = 0;
      }
      if (newY < 0) {
        newY = autocompleteElements[0];
      }
      setActiveXY([newX, newY]);
      handleUpdateSeletedValue(newX, newY);
    },
    [
      activeX,
      activeY,
      autocompleteElements,
      columnsCount,
      searchedQuery,
      langsCount,
      correctionsCount,
    ],
  );

  const handleUpdateSeletedValue = (newX, newY) => {
    const element = document.querySelector(`[data-xy="${newX + "_" + newY}"]`);
    if (element) {
      const valueNode = element.querySelector(".search-content");
      if (valueNode) {
        const searchText = valueNode.innerText;
        const isSynonyms = element.classList.contains(SearchItemType.SYNONYM);
        if (isSynonyms) {
          const synonym = getSynonymValue(searchText, search);
          setSearchInputValue(synonym);
        } else {
          setSearchInputValue(searchText);
        }
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && isEventKey(event, KeyCodes.downArrow)) {
        setExpand(true);
        event.preventDefault();
      }
      if ((event.ctrlKey || event.metaKey) && isEventKey(event, KeyCodes.upArrow)) {
        setExpand(false);
        event.preventDefault();
      }

      if (inputFocused) {
        return;
      }
      if (
        isEventKeys(event, [
          KeyCodes.leftArrow,
          KeyCodes.rightArrow,
          KeyCodes.downArrow,
          KeyCodes.upArrow,
        ])
      ) {
        handleUpdateKeyPosition(event.keyCode);
        event.preventDefault();
      }

      if (isEventKey(event, KeyCodes.enter)) {
        const element = document.querySelector(`[data-xy="${activeX + "_" + activeY}"]`);
        if (element) {
          const value = element.innerText.trim().toLowerCase();
          if (element.classList.contains("translate-lang")) {
            handleChangeDetectLang(value);
            event.preventDefault();
          } else if (element.classList.contains("detected-lang")) {
            onChangeDetectedLang(value);
            event.preventDefault();
          } else if (element.classList.contains("recent-more")) {
            setExpand((oldValue) => !oldValue);
            event.preventDefault();
          } else if (element.classList.contains("lang-more")) {
            setIsShowMoreLangs((oldValue) => !oldValue);
            event.preventDefault();
          } else if (element.classList.contains("search-text-value")) {
            onFilterSearch(undefined, "onlyText");
            hideFilter();
            event.preventDefault();
          } else if (element.classList.contains("correction-more")) {
            setIsShowMoreCorrections((oldValue) => !oldValue);
            event.preventDefault();
          } else if (element.classList.contains("correct-item")) {
            handleClickCorrection(corrections[activeX]);
            event.preventDefault();
          } else {
            onFilterSearch(undefined, "onlyText");
            hideFilter();
            event.preventDefault();
          }
        }
      }
      if (isEventKey(event, KeyCodes.esc)) {
        hideFilter();
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [
    inputFocused,
    handleUpdateKeyPosition,
    search,
    handleClickCorrection,
    corrections,
    activeX,
    activeY,
  ]);

  useEffect(() => {
    setInputFocused(true);
    setActiveXY([0, 0]);
  }, [search]);

  const renderHeaderByType = (data, type) => {
    let textNoSearch = "";
    let textLoading = "";
    switch (type) {
      case SearchItemType.SUGGESTION:
        textNoSearch = "no_search_suggestions";
        textLoading = "loading_suggestions";
        break;
      case SearchItemType.SYNONYM:
        textNoSearch = "no_search_synonyms";
        textLoading = "loading_synonyms";
        break;
      case SearchItemType.AUTOCOMPLETE:
        textNoSearch = "no_search_autocomplete";
        textLoading = "loading_autocomplete";
        break;
    }
    if (isMobile) {
      if (type === SearchItemType.SUGGESTION) {
        if (!suggestionLoaded && search.trim() !== "") {
          return (
            <div className="header-search-box-placeholder">
              <span>{t(textLoading)}</span>
            </div>
          );
        } else if ((suggestionLoaded && data.length === 0) || !suggestionLoaded) {
          return (
            <div className="header-search-box-placeholder">
              <span>{t(textNoSearch)}</span>
            </div>
          );
        }
      } else if (type === SearchItemType.RECENT) {
        if (data === undefined || data.length === 0) {
          return (
            <div className="header-search-box-placeholder">
              <span>{t("no_search_history")}</span>
            </div>
          );
        }
      }
    } else {
      if (
        type === SearchItemType.SUGGESTION ||
        type === SearchItemType.SYNONYM ||
        type === SearchItemType.AUTOCOMPLETE
      ) {
        if ((suggestionLoaded && data.length === 0) || !suggestionLoaded || search.trim() === "") {
          return (
            <div className="header-search-box-placeholder">
              <span>{t(textNoSearch)}</span>
            </div>
          );
        }
      } else if (type === SearchItemType.RECENT) {
        if (data === undefined || data.length === 0) {
          return (
            <div className="header-search-box-placeholder">
              <span>{t("no_search_history")}</span>
            </div>
          );
        }
      }
    }

    let max = maxRowCount * columnsCount;
    let freeRowWeight = columnsCount;
    let percent = 100 / columnsCount;

    return (data || []).slice(0, max).map((item, key) => {
      let lenItem = rowWidth;
      if (item.query) {
        lenItem = item.query.length * remToPx(0.6);
      } else {
        lenItem = item.length * remToPx(0.6);
      }
      let weight = 1;
      if (lenItem > rowWidth) {
        weight = Math.ceil(lenItem / rowWidth);
        if (weight > freeRowWeight) {
          weight = freeRowWeight;
        }
      }

      let lastInString = 0 === data.length - (key + 1) && (weight > 1 || weight < freeRowWeight);
      if (weight > 1 && !lastInString) {
        weight = 1;
      }
      if (lastInString && weight === columnsCount) {
        lastInString = false;
      }
      freeRowWeight -= weight;
      let style = {
        flexBasis: percent * weight + "%",
        flexShrink: 0,
        flexGrow: 0,
      };

      if (freeRowWeight < 1) {
        freeRowWeight = columnsCount;
      }

      return renderItemContent(item, key, type, style);
    });
  };

  const renderTranslateLangContent = () => {
    let translationYRow = FilterBlockIndex[SearchItemType.TRANSLATIONS].index;

    const translateLang = translate.filter((item) => item !== detectedLang);
    let indexes = 0;

    const detectArray = detect.slice(0, maxLangsTranslateCount);
    const detectLangContent = detectArray.map((item, key) => {
      const activeDetectLang = detectedLang && item === detectedLang;

      let posXY = indexes + "_" + translationYRow;
      const focused = indexes === activeX && translationYRow === activeY;
      indexes++;
      return (
        <div
          key={"detect" + key}
          data-xy={posXY}
          className={classNames("detected-lang", "focusable", {
            focused,
            active: activeDetectLang,
            clickable: !activeDetectLang,
            last: key === detectArray.length - 1,
          })}
          onClick={() => {
            if (!activeDetectLang) {
              onChangeDetectedLang(item);
            }
          }}>
          {item}
        </div>
      );
    });
    const transList = translateLang.slice(0, maxLangsTranslateCount);
    const translateLangContent = transList.map((item, index) => {
      let posXY = indexes + "_" + translationYRow;
      const focused = translationYRow === activeY && indexes === activeX;
      indexes++;
      return (
        <div
          key={"translate" + index}
          data-xy={posXY}
          className={classNames("translate-lang", "focusable", "clickable", {
            focused,
            last: index === transList.length - 1,
          })}
          onClick={() => {
            handleChangeDetectLang(item);
          }}>
          {item}
        </div>
      );
    });

    const moreLangButton =
      translate.length + detect.length >= maxLangsTranslateCount ? (
        <div
          data-xy={indexes + "_" + translationYRow}
          className={classNames(
            "search-box-more-btn lang-more with-chevron-less-more",
            "focusable",
            {
              open: isShowMoreLangs,
              focused: translationYRow === activeY && indexes === activeX,
            },
          )}
          onClick={() => setIsShowMoreLangs((oldValue) => !oldValue)}>
          <span>{t(isShowMoreLangs ? "less" : "more")}</span>
        </div>
      ) : null;
    let translateContent = (
      <React.Fragment>
        {detectLangContent.length > 0 && (
          <React.Fragment>
            <div className="translate-label">
              {t(isMobile ? "translateFromMobile" : "translateFrom") + ":"}
            </div>
            {detectLangContent}
          </React.Fragment>
        )}
        {translateLangContent.length > 0 && (
          <React.Fragment>
            <div className="translate-label">{t("translateTo") + ":"}</div>
            {translateLangContent}
          </React.Fragment>
        )}
        {moreLangButton}
      </React.Fragment>
    );
    if (isShowMoreLangs) {
      translateContent = (
        <div>
          {detectLangContent.length > 0 && (
            <div className="lang-row-wrap">
              <div className="translate-label">
                {t(isMobile ? "translateFromMobile" : "translateFrom") + ":"}
              </div>
              {detectLangContent}
            </div>
          )}
          {translateLangContent.length > 0 && (
            <div className="lang-row-wrap">
              <div className="translate-label">{t("translateTo") + ":"}</div>
              {translateLangContent}
              {moreLangButton}
            </div>
          )}
        </div>
      );
    }
    return (
      <div
        className={classNames("translations-content", {
          open: isShowMoreLangs,
        })}>
        {translateContent}
      </div>
    );
  };

  const renderSearchLabel = () => {
    let posYSearch = FilterBlockIndex[SearchItemType.WORD].index;
    return (
      <div
        className={classNames("header-search-box", "focusable", "search-text-value", {
          focused: posYSearch === activeY && 0 === activeX,
        })}
        data-xy={"0_" + posYSearch}>
        <span>{t("searchText")}:&nbsp;</span>
        <span
          className="search-detect-main-text clickable"
          onClick={() => {
            onFilterSearch(search, "onlyText");
            hideFilter(true);
          }}>
          {search}
        </span>
      </div>
    );
  };

  const handleClickCorrection = useCallback(
    (item = corrections[0]) => {
      logSearchEvent(SearchLogEvents.SEARCH_CLICK_CORRECTION, { item });
      if (item.type === "search") {
        onFilterSearch(item.book_code + " " + item.cutQuery, SearchItemType.CORRECTION);
      } else if (item.paraId) {
        openId(item.paraId, { className: CONTENT_CLASSES.PARAGRAPH, newWindow: true });
        hideFilter(true);
      } else {
        onFilterSearch(item.title, SearchItemType.CORRECTION);
      }
    },
    [corrections],
  );

  const renderCorrectionsBlock = () => {
    let posY = FilterBlockIndex[SearchItemType.CORRECTION].index;

    const renderList = isShowMoreCorrections ? corrections : [corrections[0]];
    return (
      <div className="correction-content">
        <div className="correct-label">{t("didyoumean")}:&nbsp;</div>
        <div className={"correct-wrap"}>
          {renderList.map((correct, index) => {
            let x = index;
            const posXY = x + "_" + posY;
            let nameClass = classNames("correct-item", "focusable", SearchItemType.CORRECTION, {
              focused: x === activeX && posY === activeY,
              // cuted: isShowMoreCorrections,
              clickable: true,
            });
            return (
              <div
                className={nameClass}
                data-xy={posXY}
                onClick={() => {
                  handleClickCorrection(correct);
                }}
                key={index}>
                {correct.title}
              </div>
            );
          })}
          {correctionsCount > 1 && (
            <div
              className={classNames(
                "search-box-more-btn",
                "correction-more",
                "with-chevron-less-more",
                "focusable",
                {
                  open: isShowMoreCorrections,
                  focused: correctionsCount - 1 === activeX && activeY === posY,
                },
              )}
              data-xy={correctionsCount - 1 + "_" + posY}
              onClick={() => {
                setIsShowMoreCorrections(!isShowMoreCorrections);
              }}>
              {t(isShowMoreCorrections ? "less" : "more")}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderItemContent = (item, key, renderType, suggestionStyle) => {
    // dangerous
    item = typeof item === "string" ? item : item.query;

    let x = key % columnsCount;
    let y = FilterBlockIndex[renderType].index + Math.floor(key / columnsCount);
    const posXY = x + "_" + y;
    let nameClass = classNames("header-search-box", "focusable", renderType, {
      focused: x === activeX && y === activeY,
      clickable: renderType !== SearchItemType.CORRECTION,
    });

    let action;
    if (renderType === SearchItemType.RECENT) {
      action = () => {
        logSearchEvent(SearchLogEvents.SEARCH_CLICK_HISTORY, { item });
        onFilterSearch(item, SearchItemType.RECENT);
        hideFilter(true);
      };
    }
    if (renderType === SearchItemType.SUGGESTION || renderType === SearchItemType.AUTOCOMPLETE) {
      action = () => {
        hideFilter(true);
        logSearchEvent(SearchLogEvents.SEARCH_CLICK_SUGGESTION, { item });
        onFilterSearch(item, SearchItemType.SUGGESTION);
      };
    }
    if (renderType === SearchItemType.SYNONYM) {
      const synonym = getSynonymValue(item, search);
      action = () => {
        hideFilter(true);
        logSearchEvent(SearchLogEvents.SEARCH_CLICK_SUGGESTION, { item });
        onFilterSearch(synonym, SearchItemType.SUGGESTION);
      };
    }

    let style = {};
    if (isMobile && item.length > 23) {
      style = { minWidth: "100%" };
    }
    return (
      <div
        key={renderType + key}
        className={nameClass}
        data-xy={posXY}
        style={{ ...suggestionStyle, ...style }}
        onClick={action}>
        {item !== "" && (
          <IconButton icon={renderItemIcon(renderType)} className="categories-icons" />
        )}
        &nbsp;
        <div className={classNames("search-content", "ellipsis")}>{item}</div>
      </div>
    );
  };

  const renderMoreButton = (key, suggestionStyle) => {
    if (!isRenderMore || isMobile) {
      return null;
    }

    let posY = FilterBlockIndex[SearchItemType.RECENT_MORE].index;

    return (
      <div
        className={classNames(
          "header-search-box",
          "recent-more",
          "search-box-more-btn",
          "with-chevron-less-more",
          "focusable",
          {
            open: expand,
            focused: activeY === posY,
          },
        )}
        data-xy={"0_" + posY}
        key={key}
        style={suggestionStyle}
        onClick={() => {
          setExpand(!expand);
        }}>
        {t(expand ? "less" : "more")}
      </div>
    );
  };

  const dataContent = [
    {
      renderContent: renderCorrectionsBlock,
      type: SearchItemType.CORRECTION,
      isShow: corrections.length > 0,
    },
    {
      renderContent: renderTranslateLangContent,
      type: SearchItemType.TRANSLATIONS,
      isShow: detect.length + translate.length > 0,
    },
    {
      renderContent: renderSearchLabel,
      type: SearchItemType.WORD,
      isShow: search.length > 1,
    },
    {
      data: autocomplete,
      type: SearchItemType.AUTOCOMPLETE,
      className: "autocomplete-content",
      isShow: !isMobile,
    },
    {
      data: synonyms,
      type: SearchItemType.SYNONYM,
      className: "synonym-content",
      isShow: !isMobile,
    },
    {
      data: suggestions,
      type: SearchItemType.SUGGESTION,
      className: "suggestion-content",
      advClass: { "is-login": isLogin },
      isShow: true,
    },
    {
      data: searchHistory,
      type: SearchItemType.RECENT,
      className: "recent-content",
      isShow: isLogin,
    },
    {
      renderContent: renderMoreButton,
      type: SearchItemType.RECENT_MORE,
      isShow: isRenderMore && !isMobile,
    },
  ];
  const renderViewStyle = useMemo(
    () => ({maxHeight: window.innerHeight - parseInt(headerHeight)}),
    [headerHeight]
  );

  return (
    <div className="header-search-view">
      {isMobile ? null : (
        <div className="search-popup-actions">
          <span
            className={classNames("search-lang-container", { active: isShowPopup })}
            onClick={onOpenLangPopup}>
            <i className="icon-planet" />
            &nbsp;
            {detectedLang.toUpperCase()}
          </span>
          <HideIcon onClick={() => hideFilter(true)} className="show-desktop" />
        </div>
      )}
      <Scroll fixScrollbarCoversContent hasStaticPosition renderViewStyle={renderViewStyle}>
        <HideIcon onClick={() => hideFilter(true)} className="hidden-desktop" isMobile={isMobile} />
        <div
          className={classNames("flex-container-vertical", "form-col", {
            expanded: expand,
          })}>
          {dataContent
            .filter((item) => item.isShow)
            .map(({ data, className, type, advClass, renderContent }, index) => {
              return (
                <div
                  key={type}
                  className={classNames("horizontal-data-row", {
                    "first-row": index === 0,
                  })}>
                  {renderContent ? (
                    renderContent()
                  ) : (
                    <div className={classNames("horizontal-data-content", className, advClass)}>
                      {renderHeaderByType(data, type)}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </Scroll>
    </div>
  );
};

SearchFilterPopup.propTypes = {
  onChangeDetectedLang: PropTypes.func,
  detectedLang: PropTypes.any,
  hideFilter: PropTypes.func,
  inputFocused: PropTypes.bool,
  onChangeLang: PropTypes.func,
  onFilterSearch: PropTypes.func,
  parentWidth: PropTypes.number,
  headerHeight: PropTypes.number,
  search: PropTypes.string,
  setInputFocused: PropTypes.func,
  setSearchInputValue: PropTypes.func,
};

export default SearchFilterPopup;
