import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { IconButton, SearchField, Scroll } from "../views";
import { MENU_STATE } from "../../utils/MenuItems";
import { useDevMode, useViewMode, useDebouncedEffect } from "../../hooks";
import { useNavigationContext } from "../NavigationContext";
import { searchNavProcess } from "../../api/SearchAPI";
import { actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { usePanels } from "../../hooks/usePanels";
import { CONTENT_CLASSES, getBookId } from "../../shared/utils/content";

import EgwWebIcons from "../../assets/EgwWebFont";
import BookCoverView from "src/shared/components/BookCoverView";
import { ToggleTreeModeButton } from "./ToggleTreeModeButton";

import { SearchActions } from "../search/search.actions";
import { useShortcut } from "src/redux/selectors";

import "./SearchForBook.scss";


const minNeedleLength = 2;
const BOOKS_COUNT_MAX_COLLAPSED = 4;

const SearchForBooks = () => {
  const { toggleDevMode } = useDevMode();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setLeftPanelPinned } = usePanels();
  const { openId, openGlobalSearchResult, isReader } = useNavigationContext();
  const searchNeedle = useSelector((state) => state.search.searchNeedle);
  const booksSearch = useSelector((state) => state.search.booksSearch);
  const searchBookListSuppressed = useSelector((state) => state.search.searchBookListSuppressed);
  const { isMobile, isTablet, zoom } = useViewMode();
  const [isExpanded, setIsExpanded] = useState(false);
  const [needleInternal, setNeedleInternal] = useState("");
  const isLeftPanelPinned = useSelector((state) => state.settings.isLeftPanelPinned);
  const baseBible = useSelector((state) => state.settings.baseBible);
  const treeFontSize = useSelector((state) => state.settings.treeFontSize);

  const shortcuts = useShortcut(needleInternal);

  const searchResultsCount = booksSearch.total;

  const handleMenuTreePin = () => {
    setLeftPanelPinned(!isLeftPanelPinned);
  };

  const onBookClick = (item) => {
    openId(getBookId(item.book_id), {
      newWindow: true,
      className: CONTENT_CLASSES.BOOK,
      lang: item.lang,
    });
    if (isMobile) {
      dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
    }
  };

  // isReader *change* handler
  useEffect(() => {
    if (isReader) {
      dispatch(SearchActions.setSearchBookListSuppress(true));
    }
  }, [isReader]);

  useEffect(() => {
    if (searchResultsCount <= BOOKS_COUNT_MAX_COLLAPSED) {
      setIsExpanded(false);
    }
  }, [searchResultsCount]);

  const resetFilteredList = () => {
    dispatch(SearchActions.setSearchNeedleChange());
  };

  // searchNeedle handle
  useEffect(() => {
    const needleTextCurrent = needleInternal.trim();
    const needleText = searchNeedle.trim();
    if (needleText.length < minNeedleLength || needleTextCurrent === needleText) {
      return;
    }
    setNeedleInternal(needleText);
  }, [searchNeedle]);

  // needle handle
  useDebouncedEffect(
    () => {
      const needleText = needleInternal.trim();
      if (needleText.length < minNeedleLength) {
        resetFilteredList();
        return;
      }
      dispatch(SearchActions.searchBookByName(needleText));
    },
    500,
    [needleInternal],
  );

  const onClickHandle = (item) => {
    dispatch(SearchActions.setSearchBookListSuppress(true));
    onBookClick(item);
  };

  const handleSearch = (query) => {
    if (query === "dev-mode") {
      toggleDevMode();
      return;
    }
    if (shortcuts && shortcuts[0]?.paraId) {
      openGlobalSearchResult(shortcuts[0]?.paraId, false);
      return;
    }
    // this component became "title search"
    // no search in text here anymore
    const searchValue = { query, extras: { type: "basic" } };
    searchNavProcess(searchValue, baseBible).then(({ paraId }) => {
      if (paraId) {
        openGlobalSearchResult(paraId, false);
      }
    });
  };

  const handleOnInputFocus = () => {
    dispatch(SearchActions.setSearchBookListSuppress(false));
  };

  const renderSearchField = () => {
    const searchField = (
      <SearchField
        isWithDebounce
        className="searchMenu"
        value={needleInternal}
        onSearch={handleSearch}
        onClear={() => {
          setNeedleInternal("");
          resetFilteredList();
        }}
        placeholder={t("titleSearch")}
        onChange={setNeedleInternal}
        onInputFocus={handleOnInputFocus}
      />
    );

    return (
      <div className="searchMenuWrapper">
        {searchField}
        <ToggleTreeModeButton />
        {isTablet && (
          <IconButton
            icon={EgwWebIcons.pin}
            className="pin-icon"
            active={isLeftPanelPinned}
            onClick={handleMenuTreePin}
          />
        )}
      </div>
    );
  };

  const canBeExpanded = searchResultsCount > BOOKS_COUNT_MAX_COLLAPSED;

  useEffect(() => {
    if (!canBeExpanded) {
      setIsExpanded(false);
    }
  }, [canBeExpanded]);

  const itemHeight = treeFontSize * (zoom / 100) * 6 + 1; //1px border
  const listHeight = isExpanded
    ? undefined
    : itemHeight * (canBeExpanded ? BOOKS_COUNT_MAX_COLLAPSED : searchResultsCount);

  return (
    <div
      className={classnames("search-for-books", {
        "has-height-full": isExpanded && !searchBookListSuppressed,
      })}>
      {renderSearchField()}
      <button
        aria-pressed={searchBookListSuppressed}
        disabled={!searchResultsCount}
        className={classnames("search-for-books-header panel wide", {
          inactive: !searchResultsCount,
        })}
        style={{ minHeight: treeFontSize * (zoom / 100) }}
        onClick={() => {
          dispatch(SearchActions.setSearchBookListSuppress(!searchBookListSuppressed));
        }}>
        <div className={"panel-child"}>
          <div
            className={classnames("with-chevron", {
              open: !(searchBookListSuppressed || !searchResultsCount),
            })}
          />
          &nbsp;
          <span className="title">{t("search_for_books")}</span>
          {searchResultsCount ? <div className="counter">{searchResultsCount}</div> : ""}
        </div>
      </button>

      {searchResultsCount && !searchBookListSuppressed ? (
        <React.Fragment>
          <Scroll
            style={{ height: listHeight, maxHeight: "100%" }}
            aria-live="assertive"
            className="search-for-books_book-list">
            {booksSearch.books.map((item) => {
              return (
                <button
                  style={{ height: itemHeight, maxHeight: "100%" }}
                  key={item.id}
                  className={`item wide ${item.folder_type}`}
                  onClick={() => onClickHandle(item)}>
                  <BookCoverView className="search-for-books_cover" book={item} />
                  <div className="text-holder">
                    <div className="title">{item.title}</div>
                    <span dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                </button>
              );
            })}
          </Scroll>
          {canBeExpanded && (
            <div className="panel-bottom">
              <button
                className={classnames("panel-bottom-child wide with-chevron-less-more", {
                  open: isExpanded,
                })}
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}>
                {isExpanded ? t("see_less") : t("see_more")}
              </button>
            </div>
          )}
        </React.Fragment>
      ) : null}
    </div>
  );
};

SearchForBooks.propTypes = {
  onExpand: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SearchForBooks;
