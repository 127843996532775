import {
  compareDesc,
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
  isAfter,
  parse,
  parseISO,
} from "date-fns";
import { isIOS, isMacOs, isMobile, isTablet, osVersion } from "react-device-detect";
import { isEventKey, KeyCodes } from "../shared/utils/dom";
import { CONTENT_CLASSES, sortByArray } from "../shared/utils/content";
import { VIEW_MODE as VIEW_MODE_SHARED } from "../shared/utils/viewmode";
import { getIsIos } from "../shared/utils/device";
import { DEFAULT_LANGUAGE } from "../shared/utils/i18n";
import { clutch } from "./NumberUtils";
import { getSystemLang } from "../shared/utils/user-agent";

export const COOKIE_KEY_APP_OPEN_NUMBER = "openNumber";

// Delay between open last read book of ML
export const DelayOpenML = 1000 * 60 * 60;

export const SearchQueryLimit = 500;

export const MouseButtonsCodes = {
  main: 0, // Main button, usually the left button.
  middle: 1, //  Auxiliary button, usually the wheel button or the middle button (if present).
  secondary: 2, // Secondary button, usually the right button.
};

export const VIEW_MODE = VIEW_MODE_SHARED;

/**
 * @readonly
 * @enum {number}
 */
export const scOptions = {
  SELECTION: 1,
  CHAPTER: 2,
  RANGE: 3,
};

/**
 * @readonly
 * @enum {string}
 */
export const HistoryType = {
  READING: "read",
  LISTEN: "audio",
  LIBRARY: "download_history",
  ML_HISTORY: "library",
  SEARCH: "search_history",
};

export const CFStatus = {
  PENDING: "PENDING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

export const DATA_ACQUISITION_STATUS = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

export const Position = {
  TOP: "TOP",
  RIGHT: "RIGHT",
  LEFT: "LEFT",
  BOTTOM: "BOTTOM",
};

/**
 * Method for search object by id in json tree.
 * Search work only by id value.
 *
 * @param {string|number} value  - id or other value for search
 * @param {object|array} currentNode - json tree
 * @param {string} field - property name to search
 * @param {*[]} nodeList - holder of all parent for found value
 */
export const findNode = (value, currentNode, field = "id", nodeList = []) => {
  let i, currentChild, result, children;

  if (value == currentNode[field]) {
    return currentNode;
  }
  if (currentNode.length) {
    children = currentNode;
  } else {
    children = currentNode.children;
  }
  if (children) {
    for (i = 0; i < children.length; i += 1) {
      currentChild = children[i];
      result = findNode(value, currentChild, field, nodeList);
      if (result !== false) {
        nodeList.push(currentChild);
        return result;
      }
    }
  }
  return false;
};

export const getNodeIds = (node, array, ids = []) => {
  if (node?.length) {
    for (let i = 0; i < node.length; i++) {
      ids.push(node[i].id);
      getNodeIds(node[i].children, array, ids);
    }
  }

  return ids;
};

export const getNodePathIds = (node, id) => {
  const path = [];
  findNode(id, node, "id", path);
  return path.map((child) => child.id);
};

export const insertParasToBook = (book, paras) => {
  if (!paras.length || !book?.children?.length) {
    return;
  }

  const bookChildren = book.children;
  const mapPuborderMin = bookChildren[0].puborder;

  let para;
  for (let i = 0; i < paras.length; i++) {
    para = paras[i];

    // Lower {puborder} than {mapPuborderMin} means there is no holder for {para}.
    // (e.g. paragraph of the book title, title chapters are cut off from the "children",
    // because the title chapter would be the same as the book title in the "Tree").
    if (para.puborder < mapPuborderMin) {
      continue;
    }

    insertParaToNodeByPuborder(book, paras[i]);
  }
};

const insertParaToNodeByPuborder = (node, para) => {
  if (node.className === CONTENT_CLASSES.PARAGRAPH) {
    // eslint-disable-next-line no-console
    console.log(
      "%c Paragraph is not allowed as the node! Use book or chapter!",
      "background: black; color: white;",
    );
  }

  const paraPuborder = para.puborder;
  const paraParentChildrenSpread = [...node.children];
  const paraParentChildrenSpreadLength = paraParentChildrenSpread.length;

  if (paraParentChildrenSpreadLength) {
    if (paraParentChildrenSpread[0].className === CONTENT_CLASSES.PARAGRAPH) {
      insertParaToChapterByPuborder(node, para);
    } else {
      let childCurr, childNext, childCurrPuborder, childNextPuborder;
      for (let i = 0; i < paraParentChildrenSpreadLength; i++) {
        childCurr = paraParentChildrenSpread[i];
        childNext = paraParentChildrenSpread[i + 1];
        childCurrPuborder = childCurr.puborder;
        childNextPuborder = childNext?.puborder;

        // Skip push if {para} is already in the book as either a paragraph or a chapter.
        // The priority has a chapter as it has the same {content} as a para.
        if (paraPuborder === childCurrPuborder || paraPuborder === childNextPuborder) {
          return;
        }

        if (
          childCurrPuborder < paraPuborder &&
          (!childNextPuborder || childNextPuborder > paraPuborder)
        ) {
          insertParaToNodeByPuborder(childCurr, para);
        }
      }
    }
  } else {
    node.children = [para];
  }
};

const insertParaToChapterByPuborder = (chapter, para) => {
  const paraPuborder = para.puborder;
  const childrenSpread = [...chapter.children];
  const childrenSpreadLength = childrenSpread.length;

  if (childrenSpreadLength) {
    let childNextPuborder;

    for (let i = 0; i < childrenSpreadLength; i++) {
      const puborderDelta = paraPuborder - childrenSpread[i].puborder;
      childNextPuborder = childrenSpread[i + 1]?.puborder;

      // Skip push if {para} is already in the book as either a paragraph or a chapter.
      // The priority has a chapter as it has the same {content} as a para.
      if (puborderDelta === 0 || (childNextPuborder && childNextPuborder === paraPuborder)) {
        return;
      }

      if (puborderDelta === -1) {
        // push before the item
        chapter.children = [];
        if (i > 0) {
          chapter.children.push(...childrenSpread.slice(0, i));
        }
        chapter.children.push(para);
        chapter.children.push(...childrenSpread.slice(i));
        break;
      }

      if (puborderDelta === 1) {
        // push after the item
        chapter.children = [];
        chapter.children.push(...childrenSpread.slice(0, i + 1));
        chapter.children.push(para);
        if (i < childrenSpreadLength) {
          chapter.children.push(...childrenSpread.slice(i + 1));
        }
        break;
      }
    }
  } else {
    chapter.children = [para];
  }
};

export const trimBr = (html) => html.replace(/^( |<br\s*[/]?>)*(.*?)( |<br\s*[/]?>)*$/, "$2");

export const arraymove = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export const toggleInArray = (arr, value) => {
  const index = arr.indexOf(value);
  if (index === -1) {
    arr.push(value);
  } else {
    arr.splice(index, 1);
  }
  return index;
};

export const removeFromArrayWithArray = (array, removeIds) => {
  let removeId, removeIdIndex;
  for (let i = 0; i < removeIds.length; i++) {
    removeId = removeIds[i];

    removeIdIndex = array.findIndex((element) => element === removeId);
    if (removeIdIndex !== -1) {
      array.splice(removeIdIndex, 1);
    }
  }
};

export const generateParasHtml = (readerItems, { refs, headers, pageBreaks } = {}) => {
  let container = document.createElement("div");
  let firstPara, lastPara;
  let lastPageNumber;

  readerItems.forEach((item, index) => {
    const type = item.element_type;
    const isParagraphItem = type === "p";

    const element = document.createElement(type);
    let formedContent = item.content;

    if (isParagraphItem) {
      element.className = CONTENT_CLASSES.PARAGRAPH;

      if (refs) {
        formedContent += `<span class="${CONTENT_CLASSES.REF_CODE}">${item.refcode_short}</span>`;
      }
    }

    element.innerHTML = `${formedContent} `;
    element.dataset.id = item.id;

    if (index === 0) {
      firstPara = element;
    }
    if (index === readerItems.length - 1) {
      lastPara = element;
    }

    if (headers && isParagraphItem) {
      container.insertAdjacentHTML(
        "beforeend",
        `<div class="${CONTENT_CLASSES.READER_HEADER_TEXT}">${item.refcode_long}</div>`,
      );
    }

    container.appendChild(element);

    if (pageBreaks && isParagraphItem) {
      if (lastPageNumber !== item.refcode_2) {
        lastPageNumber = item.refcode_2;
        container.insertAdjacentHTML(
          "beforeend",
          `<div class="${CONTENT_CLASSES.PAGE_NUMBER}">${item.refcode_2}</div>`,
        );
      }
    }
  });

  return { container, firstPara, lastPara };
};

/**
 * dont use very offen, long time calculation
 * @param {*} text
 * @param {*} classes
 */
export const calculateTextDimension = (text, classes = []) => {
  let dimensions = { width: 0, height: 0 };
  classes.push("textDimensionCalculation");
  const div = document.createElement("div");
  div.setAttribute("class", classes.join(" "));
  div.innerHTML = text;

  document.body.appendChild(div);

  dimensions.width = div.clientWidth;
  dimensions.height = div.clientHeight;
  div.parentNode.removeChild(div);
  return dimensions;
};

export const getIdFromElement = (element) => {
  const { parentElement, id } = element;
  if (id) {
    return id;
  }
  return parentElement ? getIdFromElement(parentElement) : null;
};

export const removeDuplicates = (options) => {
  let noDupOptions = options.filter((item) => item.refcode_short !== "");
  let duplicates = {};

  noDupOptions.forEach((item) => {
    const refcode = item.refcode_short;
    if (!duplicates[refcode]) {
      duplicates[refcode] = [];
    }
    duplicates[refcode].push(item);
  });

  Object.keys(duplicates).forEach((item) => {
    const indicies = duplicates[item];
    if (indicies.length >= 2) {
      indicies.forEach((option) => noDupOptions.splice(noDupOptions.indexOf(option), 1));
    }
  });
  return noDupOptions;
};

export const collectAllNodes = (node, holder, classes = []) => {
  if (node.children) {
    node.children.forEach((item) => {
      if (classes.indexOf(item.className) === -1 || classes.length === 0) {
        holder.push(item);
      }
      collectAllNodes(item, holder, classes);
    });
  }
};

/**
 * Joins arrays of object check by one key
 *
 * @param {*} arrays - arrays for join e.g. [
 * [{id: 0, label: "a"},{id: 1, label: "b"}],
 * [{id: 1, label: "b"},{id: 2, label: "c"}]
 * ] array with bigger index has less priority
 * @param {*} key - key for compare. id by default
 */
export const joinObjArrays = (arrays, key = "id") => {
  let obj = {};

  arrays.forEach((array) => {
    array.forEach((item) => {
      if (!obj[item[key]]) {
        obj[item[key]] = item;
      }
    });
  });

  let finalList = [];

  Object.keys(obj).forEach((objKey) => {
    finalList.push(obj[objKey]);
  });
  return finalList;
};

export const joinArrays = (arrays) => {
  const arrayFull = [];
  arrays.forEach((arr) => {
    arrayFull.push(...arr);
  });
  return [...new Set(arrayFull)];
};

export const isArraysContentsEquals = (pathIds, array) => {
  return array.length === pathIds.length && array.every((element) => pathIds.includes(element));
};

export const arrayToObject = (aArray, key, value) => {
  let obj = {};
  aArray.forEach((item) => {
    obj[item[key]] = item[value];
  });
  return obj;
};

/**
 * @description Returns x coordinate of the received event
 * or the last x coordinate of the touches of the received event.
 * @param {MouseEvent|TouchEvent} e
 * @returns {number|undefined}
 */
export const getCoordinateX = (e) => {
  const { x, touches, changedTouches } = e;

  if (x !== undefined) {
    return x;
  }

  if (touches) {
    const lastTouch = touches[touches.length - 1];

    if (lastTouch) {
      return lastTouch.clientX;
    }
  }

  if (changedTouches) {
    const lastTouch = changedTouches[changedTouches.length - 1];

    if (lastTouch) {
      return lastTouch.clientX;
    }
  }

  return undefined;
};

export const getChildrenIds = (id, mainTree) => {
  let ids = [id];
  let allNodes = [];
  const getId = (item) => item.id;
  const node = findNode(id, mainTree, "id", allNodes);
  const parent = allNodes[1];
  let siblings = [];
  let parentId;
  if (parent && parent.children) {
    parentId = parent.id;
    siblings = parent.children.map(getId);
  }
  const parents = allNodes.map(getId);
  if (node && node.children) {
    let holder = [];
    collectAllNodes(node, holder, ["para", "chapter"]);
    ids = [id, ...holder.map(getId)];
  }
  return { ids, siblings, parents, parentId };
};

export const contains = (rect, x, y) => {
  const { width, height, left, top } = rect;
  const newRect = { width, height, x: rect.x || left, y: rect.y || top };

  return (
    newRect.x <= x &&
    x <= newRect.x + newRect.width &&
    newRect.y <= y &&
    y <= newRect.y + newRect.height
  );
};

export const findTargetId = (para, bookChapters, bookId) => {
  if (!para || !bookChapters) {
    return undefined;
  }
  let targetId;
  let index = (bookChapters || []).findIndex((item) => item.puborder > para.puborder);
  if (index > 0) {
    index--;
    const chapter = bookChapters[index];
    if (chapter) {
      targetId = chapter.level === 0 ? bookId || bookChapters[0].id : chapter.id;
    }
  } else if (para.puborder >= (bookChapters[bookChapters.length - 1] || {}).puborder) {
    targetId = bookChapters[bookChapters.length - 1].id;
  } else if (index === -1 && bookChapters.length === 1) {
    targetId = bookChapters[0].id;
  }
  return targetId;
};

export const getDateLatest = (allDates) => {
  let dates = [];
  allDates.forEach((item) => {
    if (item) {
      dates.push(parseISO(item));
    }
  });
  dates.sort(compareDesc);
  return dates[0];
};

export const detectIfLastRead = (luRead, luListen) => {
  if (luRead && luListen) {
    return isAfter(parseISO(luRead), parseISO(luListen));
  } else if (luRead && !luListen) {
    return true;
  } else if (!luRead && luListen) {
    return false;
  }
  return true;
};

export const getSortDate = (luRead, luListen, showReadingHistory, showListenHistory) => {
  if (showReadingHistory && showListenHistory) {
    let readDate = luRead ? parseISO(luRead) : undefined;
    let listenDate = luListen ? parseISO(luListen) : undefined;
    if (readDate && listenDate) {
      return isAfter(readDate, listenDate) ? readDate : listenDate;
    } else if (readDate) {
      return readDate;
    } else if (listenDate) {
      return listenDate;
    }
  } else if (showReadingHistory && !showListenHistory && luRead) {
    return parseISO(luRead);
  } else if (!showReadingHistory && showListenHistory && luListen) {
    return parseISO(luListen);
  }
  return undefined;
};


export const getLabelById = (value, options = []) => {
  const filtered = options.find((item) => item.id === value);
  return filtered ? filtered.label : "";
};

export const convertToTime = (position, withHours = true) => {
  const MINUTES_PER_HOUR = 60;
  const SECONDS_PER_HOUR = 3600;
  var hours = Math.floor(position / SECONDS_PER_HOUR);
  var minutes = Math.floor((position - hours * SECONDS_PER_HOUR) / MINUTES_PER_HOUR);
  var seconds = position - hours * SECONDS_PER_HOUR - minutes * MINUTES_PER_HOUR;

  const time = [hours, minutes, seconds].map((item) => {
    if (item < 10) {
      return "0" + item;
    }
    return item;
  });
  return withHours ? time[0] + ":" + time[1] + ":" + time[2] : time[1] + ":" + time[2];
};

export const makeTreeFromList = (data, options = {}) => {
  var ID_KEY = options.id || "id";
  var PARENT_KEY = options.parent || "topic";
  var CHILDREN_KEY = "children";

  var tree = [],
    childrenOf = {};
  var item, id, parentId;

  for (var i = 0, length = data.length; i < length; i++) {
    item = data[i];
    id = item[ID_KEY];
    parentId = item[PARENT_KEY] || 0;
    // every item may have children
    childrenOf[id] = childrenOf[id] || [];
    // init its children
    item[CHILDREN_KEY] = childrenOf[id];
    if (parentId != 0) {
      // init its parent's children object
      childrenOf[parentId] = childrenOf[parentId] || [];
      // push it into its parent's children object
      childrenOf[parentId].push(item);
    } else {
      tree.push(item);
    }
  }

  return tree;
};

/**
 * development
 * staging
 * production
 */

export const orderLanguages = (languages = [], addedLanguages = []) => {
  let resultItems = languages
    .filter((item) => addedLanguages.includes(item.id))
    .sort((prev, next) => {
      if (prev.id < next.id) {
        return -1;
      }
      if (prev.id > next.id) {
        return 1;
      }
      return 0;
    });

  resultItems = sortByArray(resultItems, addedLanguages, "id");
  return resultItems;
};

export const orderLanguagesDetect = (languages, addedLanguages) => {
  let resultItems = (languages || []).filter((item) => addedLanguages.includes(item.id));
  const defLang = addedLanguages[0];
  const defLangIndex = resultItems.findIndex((item) => item.id === defLang);
  if (defLangIndex !== -1) {
    arraymove(resultItems, defLangIndex, 0);
  }
  return resultItems;
};

export const parseTimeString = (time) => {
  return formatDateTo(time, "HH:mm:ss", "hh-mm-a").split("-");
};

export const formatTimeString = (time) => {
  const timeValues = parseTimeString(time);
  if (timeValues.length > 0) {
    const [hour, minute, period] = timeValues;
    return `${hour}:${minute} ${period}`;
  }
  return format(new Date(), "h:mm a");
};

export const fromNow = (date) => {
  if (!date) {
    return "";
  }

  const dateType = typeof date;

  if (dateType === "number") {
    date = new Date(date);
  } else if (dateType === "string") {
    date = parseISO(date);
  } else if (!(date instanceof Date)) {
    return "";
  }

  const timeDifferenceFromNow = differenceInSeconds(new Date(), date);

  if (timeDifferenceFromNow < 44) {
    return "a few seconds ago";
  } else if (timeDifferenceFromNow < 90) {
    return "44 seconds ago";
  }

  return formatDistanceToNowStrict(date, {
    addSuffix: true,
    roundingMethod: "floor",
  });
};

export const clearSelection = () => {
  if (typeof window !== "undefined" && window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
};

/**
 * @description Returns window or document sizes (width and height). Specific
 * window object can be passed.
 * @returns {{width: number, height: number}}
 */
export const getWindowSizes = (windowObject = window) => {
  let width, height;

  // Fix for devices, that don't have innerWidth/Height properties. (e.g. old IOS)
  if (windowObject?.innerWidth === undefined) {
    width = windowObject.document.documentElement.clientWidth;
    height = windowObject.document.documentElement.clientHeight;
  } else {
    width = windowObject.innerWidth;
    height = windowObject.innerHeight;
  }

  return { width, height };
};

export const inRect = (parentRect, rect) => {
  return (
    parentRect.left < rect.left &&
    parentRect.left + parentRect.width > rect.left &&
    parentRect.top < rect.top &&
    parentRect.top + parentRect.height > rect.top
  );
};

export const checkWordsNumber = (str) => !str || str.trim().split(" ").length > 1;

export const getClientPositions = (event) => {
  const { clientY, clientX } = event;
  let top = clientY,
    left = clientX;

  if (top === undefined && event.changedTouches?.[0]) {
    const { pageX, pageY } = event.changedTouches[0];
    left = pageX;
    top = pageY;
  }

  return {
    clientX: left,
    clientY: top,
  };
};

export const getBoundingClientRect = (target, returnOriginalRect = false) => {
  if (!target) {
    return { height: 0, width: 0, x: 0, y: 0, top: 0, left: 0, right: 0, bottom: 0 };
  }

  if (returnOriginalRect) {
    return target.getBoundingClientRect();
  }

  const { height, width, x, y, top, left, right, bottom } = target.getBoundingClientRect();

  return {
    height,
    width,
    left: left || x,
    top: top || y,
    x: left || x,
    y: top || y,
    right,
    bottom,
  };
};

export const int3DigitFormat = (n) => {
  const list = (Number.isInteger(n) ? n : n | 0).toString().split("");
  const l = list.length;
  for (let i = l - 3; i > 0; i -= 3) {
    list.splice(i, 0, ",");
  }
  return list.join("");
};

/**
 * Check what button is clicked
 * @param {*} event
 */
export const buttonPressedCode = (event) =>
  event.buttons !== undefined ? event.buttons : event.nativeEvent.which;

export const formatDateTo = (date, oldFormat, newFormat = oldFormat, currentTime = new Date()) => {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    return format(parse(date, oldFormat, currentTime), newFormat);
  }

  return format(date, newFormat);
};

export const isEventKeys = (e = {}, codes = [], strict = false) => {
  return strict
    ? codes.every((code) => isEventKey(e, code))
    : codes.some((code) => isEventKey(e, code));
};

export const findItemById = (list, id) => list.find((item) => item.id === id);

export const whichEventKey = (e = {}) => ({
  leftArrow: isEventKey(e, KeyCodes.leftArrow),
  rightArrow: isEventKey(e, KeyCodes.rightArrow),
  upArrow: isEventKey(e, KeyCodes.upArrow),
  downArrow: isEventKey(e, KeyCodes.downArrow),
  enter: isEventKey(e, KeyCodes.enter),
  esc: isEventKey(e, KeyCodes.esc),
  verticalNavigation: isEventKey(e, KeyCodes.upArrow) || isEventKey(e, KeyCodes.downArrow),
});

export const getLanguageDefault = (languages = [], fallback = DEFAULT_LANGUAGE) => {
  return languages.length ? languages[0] : getSystemLang(fallback);
};

export const getSelectionAnchorClosest = (selection, selector) => {
  return selection?.anchorNode?.parentElement?.closest(selector) || null;
};

/**
 * @description Dispatches "name" mouse event on "target" in "touch" position.
 * @param {string} name
 * @param {Object} touch
 * @param {HTMLElement} target
 */
export const dispatchMouseEvent = (name, touch, target) => {
  const e = document.createEvent("MouseEvent");
  e.initMouseEvent(
    name,
    true,
    true,
    touch.view,
    1,
    touch.screenX,
    touch.screenY,
    touch.clientX,
    touch.clientY,
    false,
    false,
    false,
    false,
    0,
    null,
  );
  target.dispatchEvent(e);
};

/**
 * @description Returns touches array of event
 * @param {TouchEvent} e
 * @returns {Object}
 */
export const getTouches = (e) => {
  if (e.originalEvent) {
    if (e.originalEvent.touches && e.originalEvent.touches.length) {
      return e.originalEvent.touches;
    } else if (e.originalEvent.changedTouches && e.originalEvent.changedTouches.length) {
      return e.originalEvent.changedTouches;
    }
  }

  return e.changedTouches || e.touches;
};

export const getIsOldIOS = (data = { isIOS, osVersion }) => {
  const { isIOS, osVersion } = data;
  let [major] = osVersion.split(".");
  major = Number(major);
  return isIOS && major < 11;
};

export const getIsIOSDevice = () => {
  // Note: Ipad is detected as a computer if desktop browser mode is enabled in the settings.
  const isAppleDevice = navigator.vendor.includes("Apple Computer") || isMacOs || isIOS;
  const isTouchableDevice = navigator.maxTouchPoints > 0;
  const isIOSDevice = (isAppleDevice && isTouchableDevice) || isIOS;
  return isIOSDevice;
};

export const detectOS = () => {
  if (typeof navigator !== "undefined") {
    const platform = navigator.platform.toLowerCase();

    if (getIsIos()) {
      return "iOS";
    }
    if (platform.includes("mac")) {
      return "MacOS";
    }
    if (/android/.test(navigator.userAgent.toLowerCase())) {
      return "Android";
    }
    if (platform.includes("win")) {
      return "Windows";
    }
    if (/linux/.test(platform)) {
      return "Linux";
    }
  }

  return "unknown";
};

export const currentOS = detectOS();
export const isAppleDevice = currentOS === "MacOS" || currentOS === "iOS";
export const isAndroid = currentOS === "Android";

export const getCurrentLang = (i18n, language) =>
  language || (i18n.language && i18n.language.split("-")[0]) || DEFAULT_LANGUAGE;

export const scrollToElement = (
  selector,
  options = {
    behavior: "smooth",
    block: "nearest",
    inline: "nearest",
  },
) => {
  const element = document.querySelector(selector);

  if (element) {
    element.scrollIntoView(options);
  }
};

/**
 * Calculates the optimal position styles relate to the rect, that should not be covered
 * ("avoidRectangleRect") and boundaries that should not be crossed
 * ("boundaryRect").
 *
 * @param {Object} boundaryRect - The boundaries that should not be crossed.
 * @param {Object} avoidRectangleRect - The rect, that should not be covered.
 * @param {number} blockWidth - window width.
 * @param {number} blockHeight - window height.
 *
 * @returns {{top: number, left: number}}
 */
export const calculateFitPosition = (boundaryRect, avoidRectangleRect, blockWidth, blockHeight) => {
  let top, left;

  const topDelta = avoidRectangleRect.top - boundaryRect.top;
  const bottomDelta = boundaryRect.bottom - avoidRectangleRect.bottom;
  const leftDelta = avoidRectangleRect.right - boundaryRect.left;
  const rightDelta = boundaryRect.right - avoidRectangleRect.left;

  if (topDelta > bottomDelta) {
    top = avoidRectangleRect.top - blockHeight;
    top = clutch(top, 0, window.innerHeight);
  } else {
    top = avoidRectangleRect.bottom;
    top = clutch(top, 0, window.innerHeight - blockHeight);
  }

  if (leftDelta > rightDelta) {
    left = avoidRectangleRect.right - blockWidth;
    left = clutch(left, 0, window.innerWidth);
  } else {
    left = avoidRectangleRect.left;
    left = clutch(avoidRectangleRect.left, 0, window.innerWidth - blockWidth);
  }

  return { top, left };
};

/**
 * Calculates the optimal sizes styles relate to the rect, that should not be
 * covered ("avoidRectangleRect") and boundaries that should not be crossed
 * ("boundaryRect").
 *
 * @param {Object} boundaryRect - The boundaries that should not be crossed.
 * @param {Object} avoidRectangleRect - The rect, that should not be covered.
 *
 * @returns {{maxWidth: number, maxHeight: number}}
 */
export const calculateMaxSizes = (boundaryRect, avoidRectangleRect) => {
  let maxWidth, maxHeight;

  const topDelta = avoidRectangleRect.top - boundaryRect.top;
  const leftDelta = avoidRectangleRect.left - boundaryRect.left;
  const rightDelta = boundaryRect.right - avoidRectangleRect.right;
  const bottomDelta = boundaryRect.bottom - avoidRectangleRect.bottom;

  if (topDelta && bottomDelta) {
    if (topDelta > bottomDelta) {
      maxHeight = topDelta;
    } else {
      maxHeight = bottomDelta;
    }
  }

  if (leftDelta && rightDelta) {
    if (leftDelta > rightDelta) {
      maxWidth = leftDelta;
    } else {
      maxWidth = rightDelta;
    }
  }

  return { maxWidth, maxHeight };
};

export const getExtendedClass = (index, isExpand, selected) => {
  let addClass = "";

  if (!isExpand) {
    addClass = "sideMenuItemCollapsed";
    if (selected !== -1 && index === selected) {
      addClass += " miSel";
    }
  } else if (selected !== -1 && index === selected) {
    addClass = "sideMenuSelected";
  } else if (selected !== -1 && Math.abs(selected - index) === 1 && isExpand) {
    addClass = "sideMenuBigger";
  } else {
    addClass = "sideMenuItemDefault";
  }

  return addClass;
};

/**
 * Returns new DOM element with "tag" name and copied "element" attributes.
 * @param {HTMLElement} element
 * @param {string} tag
 * @returns {HTMLElement}
 */
export const changeElementTag = (element, tag) => {
  const newElement = document.createElement(tag);
  const { attributes } = element;

  for (let i = 0, length = attributes.length; i < length; i++) {
    const { nodeName, nodeValue } = attributes.item(i);
    newElement.setAttribute(nodeName, nodeValue);
  }

  newElement.innerHTML = element.innerHTML;

  return newElement;
};

/**
 * @description Returns "true" if element is one from the note elements,
 * else "false" will be returned.
 * @param {HTMLElement | EventTarget} element - Element to check
 * @returns {boolean}
 */
export const isNoteElement = (element) => {
  if (!element) {
    return false;
  }
  const { classList } = element;
  const { FOOT_NOTE, CHAPTER_END_NOTE, BOOK_END_NOTE } = CONTENT_CLASSES;
  return (
    classList.contains(FOOT_NOTE) ||
    classList.contains(CHAPTER_END_NOTE) ||
    classList.contains(BOOK_END_NOTE)
  );
};

export const SCREEN_ORIENTATION = {
  PORTRAIT: "PORTRAIT",
  LANDSCAPE: "LANDSCAPE",
};

/**
 * Returns current screen orientation.
 * @param windowObject - for the test.
 * @param isTabletFlag - for the test.
 * @param isMobileFlag - for the test.
 * @returns {string}
 */
export const getScreenOrientation = (
  windowObject = window,
  isTabletFlag = isTablet,
  isMobileFlag = isMobile,
) => {
  let isLandscape = false;

  if (isMobileFlag || isTabletFlag) {
    if (windowObject.matchMedia("(min-aspect-ratio: 13/9)").matches) {
      isLandscape = true;
    }
  } else if (windowObject.innerWidth > windowObject.innerHeight) {
    isLandscape = true;
  }

  if (isLandscape) {
    return SCREEN_ORIENTATION.LANDSCAPE;
  }

  return SCREEN_ORIENTATION.PORTRAIT;
};

export const swapObjectsProperty = (obj1, obj2, keys) => {
  if (typeof keys === "string") {
    const temp = obj1[keys];
    obj1[keys] = obj2[keys];
    obj2[keys] = temp;
  } else if (keys instanceof Array) {
    for (let key of keys) {
      swapObjectsProperty(obj1, obj2, key);
    }
  }
};

export const getIsMatchesCssMediaHover = () => {
  return (
    typeof window !== "undefined" &&
    window.matchMedia &&
    window.matchMedia("(hover: hover) and (pointer: fine)").matches
  );
};

export const findBible = (bibles, id) => bibles.length > 1 && bibles.find((b) => b.id === id);

export const capitalize = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const trottleCheck = (trottle, lastAction = Date.now()) => {
  return trottle === 0 || (trottle > 0 && Date.now() - lastAction > trottle);
};

export const getObjectKeysNotNullable = (obj) => Object.keys(obj).filter((key) => !!obj[key]);

export const removeDuplicatesFromPrimitives = (options) => [...new Set(options)];

export const isFunction = (func) => typeof func === "function";
