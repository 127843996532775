import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTooltipContext } from "../popup/TooltipContext";
import { useBookSelect, useParagraphSelector } from "../../redux/selectors";
import { useAudioContext } from "../bookPlayer/AudioContext";

const TocButton = ({ titleFallback, isVisible, onToggleVisible }) => {
  const { t } = useTranslation();
  const { hideTooltip, showTooltip } = useTooltipContext();
  const { chapterId } = useAudioContext();
  const book = useBookSelect(chapterId);
  const paragraphs = useParagraphSelector(chapterId);
  const isBible = book && book.realType === "bible";
  const tooltipTitle = t("content");

  useEffect(() => {
    return () => {
      hideTooltip();
    };
  }, []);

  const tocTitle = useMemo(() => {
    let currentPara;
    if (paragraphs) {
      currentPara = paragraphs.find((item) => item.id === chapterId);
    }  

    if (!currentPara) {
      return titleFallback;
    }

    const { refcode_1: rc1, refcode_2: rc2, refcode_3: rc3, refcode_4: rc4 } = currentPara;

    if (isBible) {
      return `${rc2 || 0} ${rc3 || 0}.${rc4 || 0}`;
    }

    return `${rc1 || 0} ${rc2 || 0}.${rc3 || rc4 || 0}`;
  }, [isBible, chapterId, paragraphs]);

  const onHandleToggle = () => {
    onToggleVisible();
    hideTooltip();
  };

  return (
    <button
      className={classNames("book-toc-button with-chevron", { "open": isVisible })}
      onClick={onHandleToggle}
      onMouseOver={(e) => {
        if (tooltipTitle) {
          showTooltip(tooltipTitle, e);
        }
      }}
    >
      <span className="book-toc-button__text">{tocTitle}</span>
    </button>
  );
};

TocButton.propTypes = {
  isVisible: PropTypes.bool,
  onToggleVisible: PropTypes.func,
  titleFallback: PropTypes.string,
};

export default TocButton;
