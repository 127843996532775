import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TextField } from "../../views";
import ScFolder from "./ScFolder";
import { useDialogContext } from "../../dialog/DialogContext";
import { actionScElement } from "../StudyCenterUtils";
import DialogButtons from "../../dialog/DialogButtons";
import { ItemTypes } from "../studyCenter.constants";

import "./SCDialog.scss";

//TODO Implement better logic for split note and BM
const NoteDialog = ({ isNote = true, entry, selectionData, topic }) => {
  const [text, setText] = useState(entry ? entry.text : "");
  const [textRows, setTextRows] = useState(12);
  const [folderId, setFolderId] = useState(entry ? entry.topic : undefined);
  const userInfo = useSelector((state) => state.settings.userInfo);
  const userId = userInfo?.id;
  const { hideDialog } = useDialogContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (entry) {
      setText(entry.text);
      setFolderId(entry.topic);
    } else {
      setFolderId(topic);
    }
  }, [entry, topic]);

  const onConfirm = () => {
    const id = entry ? entry.id : undefined;
    const type = isNote ? ItemTypes.note  : ItemTypes.bookmark;
    if (!isNote || text.length > 0) {
      hideDialog();
      actionScElement(dispatch, userId, {
        id, text, isNote, entry, selectionData, type, topic: folderId
      });
    }
  };

  const placeholder = isNote ? t("placeholderCreateNote") : t("placeholderCreateBookmark");
  return (
    <div className="sc-dialog" style={textRows === 3 ? { marginBottom: 60 } : {}}>
      <span className="sc-title sc-title-margin"> {t(isNote ? "Note" : "Bookmark")}</span>
      <TextField
        label={placeholder}
        onFocus={() => {
          //FIXME need to fix
          if (navigator.userAgent.indexOf('Mobile/') !== -1 && navigator.userAgent.indexOf('Safari/6')) {
            setTextRows(3);
          } else if (document.querySelector('html').scrollTop !== 0) {
            setTextRows(3);
          }
        }}
        onBlur={() => setTextRows(12)}
        autoFocus
        multiline={isNote}
        value={text}
        onKeyPress={(e) => {
          //FIXME make more universal
          if (e.key === "Enter" && (!isNote || (isNote && e.shiftKey))) {
            onConfirm();
            e.preventDefault();
          }
        }}
        error={isNote && text.length === 0}
        rows={isNote ? textRows : 12}
        className="note-textarea"
        onChange={(e) => setText(e.target.value)}
      />
      <ScFolder
        folderId={folderId}
        onChange={(topicId) => {
          setFolderId(topicId);
        }}
      />
      <DialogButtons
        onClickNegative={hideDialog}
        disabled={isNote && text.length === 0}
        onClickPositive={() => {
          onConfirm();
        }}
        negativeText={t("cancel")}
        positiveText={t("save")}
      />
    </div>
  );
};

NoteDialog.propTypes = {
  isNote: PropTypes.bool,
  onAction: PropTypes.func,
  initValue: PropTypes.string,
  id: PropTypes.string,
  topic: PropTypes.string,
  entry: PropTypes.object,
  selectionData: PropTypes.object,
  onClose: PropTypes.func
};

export default NoteDialog;
