import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setLibLangs } from "../../redux/library.actions";
import { actionChangeLang } from "../../redux/params.actions";

const UpdateLangPopup = ({ langItem }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);

  const setDefaultLanguage = () => {
    if (!libraryLanguages.includes(langItem.id)) {
      return;
    }
    const [defLang] = libraryLanguages.splice(libraryLanguages.indexOf(langItem.id), 1);
    libraryLanguages.splice(0, 0, defLang);
    dispatch(actionChangeLang({ lang: libraryLanguages[0], initCheck: true }));
    dispatch(setLibLangs(libraryLanguages));
  };

  return (
    <div className="context-menu-item" onClick={() => setDefaultLanguage()}>
      {t("setAsDefault")}
    </div>
  );
};

UpdateLangPopup.propTypes = {
  langItem: PropTypes.object,
};

export default UpdateLangPopup;
