import { all, call, put, select, takeEvery, takeLeading } from "redux-saga/effects";

import { CONTENT_CLASSES } from "src/shared/utils/content";
import { onUpdateChecks } from "src/utils/TreeUtils";
import { menuItems } from "../../utils/MenuItems";
import { SearchActions } from "src/components/search/search.actions";
import { ACTION_LOGOUT, ACTION_UPDATE_SETTING_SUCCESS } from "../constants";
import { categoryTreeActionConstants, categoryTreeActions } from "./actions";
import { convertCategoryTree, sortCategoryTree } from "../../utils/CategoryTreeUtils";
import { getCategoriesRequest } from "../../api/CategoryAPI";

export function* toggleItemExpandWorker(action) {
  const expanded = yield select((state) => state.categoryTree.expanded);

  const id = action.data.id;

  const expandedNew = expanded.includes(id)
    ? expanded.filter((expandedId) => expandedId !== id)
    : [...expanded, id];

  yield put(categoryTreeActions.updateExpanded(expandedNew));
}

export function* toggleItemCheckWorker(action) {
  const { checked, tree } = yield select((state) => state.categoryTree);

  const updatedChecks = onUpdateChecks(action.data, tree, checked);

  yield put(categoryTreeActions.updateChecked(updatedChecks));
  yield put(SearchActions.searchWithCurrentTree());
}

export function* fetchAndConvertWorker() {
  // Prevent fetching and converting if tree already exists
  const titleTreeTreeConverted = yield select((state) => state.categoryTree.treeConverted);

  if (titleTreeTreeConverted.length) {
    return;
  }
  // =====================

  const tree = yield call(getCategoriesRequest);
  const mainTree = yield select((state) => state.mainTree.mainTree);

  const treeConverted = convertCategoryTree(tree, mainTree);

  yield put(categoryTreeActions.updateTreeConverted(treeConverted));
}

export function* _sortTreeWorker() {
  // If tree is not converted yet - fetch, convert and sort
  const treeConverted = yield select((state) => state.categoryTree.treeConverted);
  if (!treeConverted.length) {
    yield put(categoryTreeActions.fetchConvertSortTree());
    return;
  }
  // =====================

  // Prevent sorting if tree is already sorted properly
  const libraryLanguages = yield select((state) => state.settings.libraryLanguages);
  const categoryTree = yield select((state) => state.categoryTree.tree);

  const treeLangs = categoryTree?.[0]?.libraryLanguages;
  const isTreeAndLibraryLangsDifferent =
    !treeLangs
    || treeLangs.length !== libraryLanguages.length
    || libraryLanguages.some((libLang, index) => libLang !== treeLangs[index]);

  if (!isTreeAndLibraryLangsDifferent) {
    return;
  }
  // =====================

  yield put(categoryTreeActions.updateTreeMain([{
    ...menuItems.categories,
    className: CONTENT_CLASSES.CATEGORIES_ROOT,
    type: CONTENT_CLASSES.CATEGORIES_ROOT,
    title: menuItems.categories.label,
    children: sortCategoryTree(treeConverted, libraryLanguages),
    libraryLanguages: [...libraryLanguages],
  }]));
}

export function* fetchConvertSortTreeWorker() {
  yield fetchAndConvertWorker();
  yield _sortTreeWorker();
}

export function* sortTreeIfLibLangsChangedWorker(action) {
  if (action.data.libraryLanguages) {
    const { tree } = yield select((state) => state.categoryTree);
    // no need to re-covert tree when there's no tree, to not fetch it, bcz its size's big
    // (tree fetch is inside "_sortTreeWorker")
    if (!tree.length) {
      return;
    }

    yield _sortTreeWorker();
  }
}

export default function* categoriesSaga() {
  yield all([
    takeEvery(categoryTreeActionConstants.TOGGLE_ITEM_EXPAND, toggleItemExpandWorker),
    takeLeading(categoryTreeActionConstants.TOGGLE_ITEM_CHECK, toggleItemCheckWorker),

    takeLeading(categoryTreeActionConstants.FETCH_CONVERT_SORT_TREE, fetchConvertSortTreeWorker),

    takeLeading(ACTION_UPDATE_SETTING_SUCCESS, sortTreeIfLibLangsChangedWorker),
    takeLeading(ACTION_LOGOUT, _sortTreeWorker),
  ]);
}
