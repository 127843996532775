import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconButton } from "../views";
import { scActions } from "./studyCenter.actions";
import { subsFolderOrItem } from "./StudyCenterUtils";
import EgwWebFont from "src/assets/EgwWebFont";

const ScTreeMenu = ({ onAction, entry }) => {
  const { t } = useTranslation();
  const staticShare = useSelector((state) => state.studyCenter.staticShare);

  const isFolder = entry.type === "folder";
  const isShared = staticShare.findIndex((folder) => entry.id === folder.id) !== -1;
  const isSubsFolderOrItem = subsFolderOrItem(entry);

  return (
    <div className="sc-tree-menu-wrap">
      <div className="sc-tree-menu-icon-row">
        {!isSubsFolderOrItem && (
          <IconButton
            icon={EgwWebFont.edit}
            className="rcm-icon"
            title={t("edit")}
            onClick={() => {
              onAction(scActions.edit);
            }}
          />
        )}
        <IconButton
          icon={EgwWebFont.delete}
          className="rcm-icon"
          title={t("delete")}
          onClick={() => {
            onAction(scActions.delete);
          }}
        />
        <IconButton
          icon={EgwWebFont["go-to-reader"]}
          className="rcm-icon"
          title={t("view")}
          onClick={() => {
            onAction(scActions.open);
          }}
        />
        {!isSubsFolderOrItem && (
          <IconButton
            icon={EgwWebFont["folder-add"]}
            className="rcm-icon"
            title={t("createFolder")}
            onClick={() => {
              onAction(scActions.newFolder);
            }}
          />
        )}
      </div>
      {!isSubsFolderOrItem && isFolder && (
        <div className="sc-tree-menu-icon-row">
          <IconButton
            icon={isShared ? EgwWebFont.unshare : EgwWebFont.share}
            className="rcm-icon"
            title={t("share")}
            onClick={() => {
              onAction(scActions.openShareFolder, entry.id);
            }}
          />
        </div>
      )}
      <div className="context-menu-item-wrap">
        {!isSubsFolderOrItem && (
          <div className="context-menu-item" onClick={() => onAction(scActions.moveToTop)}>
            {t("moveToTop")}
          </div>
        )}
        {!isSubsFolderOrItem && (
          <div className="context-menu-item" onClick={() => onAction(scActions.moveToBottom)}>
            {t("moveToBottom")}
          </div>
        )}
        {!isSubsFolderOrItem && (
          <div
            className="context-menu-item"
            onClick={() => {
              onAction(scActions.moveToFolder);
            }}>
            {t("moveToFolder")}
          </div>
        )}
        {!isSubsFolderOrItem && !isFolder && (
          <div
            className="context-menu-item"
            onClick={() => {
              onAction(scActions.copyToFolder);
            }}>
            {t("copyToFolder")}
          </div>
        )}
        {!isSubsFolderOrItem && (
          <div className="context-menu-item" onClick={() => onAction(scActions.moveUp)}>
            {t("moveOneLevelUp")}
          </div>
        )}
        {!isSubsFolderOrItem && (
          <div className="context-menu-item" onClick={() => onAction(scActions.moveDown)}>
            {t("moveOneLevelDown")}
          </div>
        )}
      </div>
    </div>
  );
};

ScTreeMenu.propTypes = {
  entry: PropTypes.object,
  onAction: PropTypes.func,
  folderId: PropTypes.string,
};

export default ScTreeMenu;
