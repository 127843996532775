import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { makePublicUrl } from "../shared/utils/url";

i18n.use(HttpApi).use(LanguageDetector).init({
  // lng:"en",
  fallbackLng: "en",
  debug: false,
  // have a common namespace used around the full app
  ns: ["common", "translate"],
  defaultNS: "common",
  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },
  detection: {
    order: ["cookie", "navigator", "path", "localStorage", "subdomain", "queryString", "htmlTag"],
    lookupFromPathIndex: 0,
    caches: ["cookie"],
    cookieMinutes: 10000000,
    lookupQuerystring: "i18next"
  },
  backend: {
    loadPath: makePublicUrl("/locales/{{lng}}/{{ns}}.json"),
  },
  react: {
    useSuspense: false
  }
});

export default i18n;
