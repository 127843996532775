 
import { makeOAuthRequest } from "src/shared/api/baseApi";
import { getBoundingClientRect } from "../../utils/Utils";


export const loginDialog = (showConfirm, onConfirm, onCancel) => {
  showConfirm(
    "pleaseSignIn",
    "enterToUse",
    () => {
      makeOAuthRequest();
      if (onConfirm) {
        onConfirm();
      }
    },
    { positiveText: "signIn", onCancel });
};

export const fullModeDialog = (showConfirm, callback) => {
  showConfirm(
    "fullModeDialogTitle",
    "fullModeDialogContent",
    callback ? callback : () => { },
    { positiveText: "ok" }
  );
};

export const getDialogBounds = (event) => {
  const target = event.target.closest(".dialog-container");
  if (target) {
    return getBoundingClientRect(target);
  }
  return undefined;
};
