export const SearchPanelTabs = {
  RESULTS: "RESULTS",
  RELATED: "RELATED",
  FEATURED: "FEATURED",
  STUDY: "STUDY",
};

export const SEARH_PANEL_TAB_ITEMS = [
  {
    id: SearchPanelTabs.RESULTS,
    label: "results",
  },
  {
    id: SearchPanelTabs.RELATED,
    label: "related",
  },
  {
    id: SearchPanelTabs.FEATURED,
    label: "featured",
  },
  {
    id: SearchPanelTabs.STUDY,
    label: "study",
  },
];
