import { useCallback, useState } from "react";

/**
 * @returns {{
 * notifyObserverListeners: (function(...[*]): void),
 * removeObserverListener: (function(*=): void),
 * addObserverListener: (function(*): void)
 * }}
 */
export const useObserverPattern = () => {
  const [listeners, setListeners] = useState([]);

  const addListener = useCallback((listener) => {
    setListeners((oldListeners) => [...oldListeners, listener]);
  }, []);

  const removeListener = useCallback((listener) => {
    setListeners((oldListeners) => {
      return [
        ...oldListeners.filter((oldListener) => oldListener !== listener)
      ];
    });
  }, []);

  const notifyListeners = useCallback(
    (...args) => {
      for (let listener of listeners) {
        if (typeof listener === "function") {
          listener(...args);
        } else {
          removeListener(listener);
        }
      }
    },
    [removeListener, listeners]
  );

  return {
    addObserverListener: addListener,
    removeObserverListener: removeListener,
    notifyObserverListeners: notifyListeners
  };
};
