import { findNode, HistoryType } from "../utils/Utils";
import { getJsonValue } from "../utils/Settings";
import { localStorageSafe } from "../shared/utils/systemUtils";
import {
  CACHE_READING_HISTORY,
  CACHE_LISTEN_HISTORY,
  CACHE_LU_READING_HISTORY,
  CACHE_LU_LISTEN_HISTORY,
} from "./CacheHolder";

import { getBookId } from "../shared/utils/content";

const defaultHistoryObject = {
  date: "",
  items: [],
  deleted: [],
};

const getStoredHistory = (historyCache) => getJsonValue(historyCache, defaultHistoryObject);

export const saveBookHistory = (paraOrChapter, mainTree, historyType, position) => {
  const isReading = historyType === HistoryType.READING;
  const cache = isReading ? CACHE_READING_HISTORY : CACHE_LISTEN_HISTORY;
  const bookHistory = getStoredHistory(cache);
  const [listenBookId, listenChapterId] = paraOrChapter.id.split(".");
  const bookId = getBookId(isReading ? paraOrChapter.id : listenBookId);

  let record = bookHistory.items.find((history) => history.id === bookId);
  if (!record) {
    record = {};
    bookHistory.items.push(record);
  }
  record.id = bookId;
  const update = new Date().toISOString();
  if (isReading) {
    record.luRead = update;
    record.refcode_long = paraOrChapter.refcode_long;
    record.refcode_short = paraOrChapter.refcode_short;
    record.paraId = paraOrChapter.id;
    record.puborder = paraOrChapter.puborder;
  } else {
    record.book = parseInt(listenBookId);
    record.luListen = update;
    record.listenChapter = parseInt(listenChapterId);
    record.position = position;
  }

  if (!record.title) {
    const book = findNode(bookId, mainTree);
    if (book) {
      record.title = book.title;
      record.book = book.book_id;
    }
  }
  localStorageSafe.setItem(cache, JSON.stringify(bookHistory));
  return bookHistory;
};

export const readHistoryConfig = {
  withDelete: true,
  cache: CACHE_READING_HISTORY,
  luCache: CACHE_LU_READING_HISTORY,
  mapToServer: (localItem) => {
    return {
      para_id: localItem.paraId,
      position: 0,
      lu: localItem.luRead,
    };
  },
  mapToCache: (syncItem) => {
    return {
      id: getBookId(syncItem.para_id),
      book: syncItem.book,
      paraId: syncItem.para_id,
      luRead: syncItem.lu,
      refcode_short: syncItem.refcode_short,
    };
  },
};

export const listenHistoryConfig = {
  withDelete: true,
  cache: CACHE_LISTEN_HISTORY,
  luCache: CACHE_LU_LISTEN_HISTORY,
  mapToServer: (localItem) => {
    return {
      para_id: localItem.book + "." + localItem.listenChapter,
      book: localItem.book,
      chapter: localItem.listenChapter,
      lu: localItem.luListen,
      position: localItem.position,
    };
  },
  mapToCache: (syncItem) => {
    return {
      id: `b${syncItem.book}`,
      book: syncItem.book,
      listenChapter: syncItem.chapter,
      position: syncItem.position,
      luListen: syncItem.lu,
    };
  },
};
