import EgwWebFont from "src/assets/EgwWebFont";
import { CONTENT_CLASSES, getBookId, sortByArray } from "../shared/utils/content";
import { getLanguageDefault } from "./Utils";
import { makeMLLangDefaultFirst } from "./ContentUtils";

export const IdToIconId = {
  christian_lifestyle: EgwWebFont["christian-lifestyle"],
  christ_s_life_and_ministry: EgwWebFont["christ-s-life-and-ministry"],
  church_history: EgwWebFont["church-history"],
  church_life_and_ministry: EgwWebFont["church-life-and-ministry"],
  conflict_of_the_ages_series: EgwWebFont["conflict-of-the-ages-series"],
  devotional_readings: EgwWebFont["devotional-readings"],
  education: EgwWebFont["education"],
  egw_biography: EgwWebFont["egw-biography"],
  evangelism_and_witnessing: EgwWebFont["evangelism-and-witnessing"],
  health_and_wellness: EgwWebFont["health-and-wellness"],
  last_day_events: EgwWebFont["last-day-events"],
  leadership: EgwWebFont["leadership"],
  lessons_from_the_bible: EgwWebFont["lessons-from-the-bible"],
  parenting: EgwWebFont["parenting"],
  publishing: EgwWebFont["publishing"],
  relationships_and_marriage: EgwWebFont["relationships-and-marriage"],
  testimonies_for_the_church: EgwWebFont["testimonies-for-the-church"],
  youth_and_modern_english: EgwWebFont["youth-and-modern-english"],
};

const makeTreeChildrenBookTranslations = (mainTree, bookOriginal, bookOriginalTranslations) => {
  const langToBookTranslationsMap = {}; // creates { [lang]: bookTranslation[] }

  let bookTranslation;
  for (let i = 0; i < bookOriginalTranslations.length; i++) {
    bookTranslation = bookOriginalTranslations[i];

    const translationLanguage = bookTranslation.lang;
    const booksWithTranslationLanguage = langToBookTranslationsMap[translationLanguage];

    langToBookTranslationsMap[translationLanguage] = booksWithTranslationLanguage
      ? [...booksWithTranslationLanguage, bookTranslation]
      : [bookTranslation];
  }
  // ==========

  // If one book translation of lang "A", creates tree item, that redirects to the book.
  // If plenty of book translations of lang "A", creates an expandable item with book translations.
  return Object.values(langToBookTranslationsMap).map((bookTranslationsOfLang) => {
    const firstBook = bookTranslationsOfLang[0];
    const firstBookLang = firstBook.lang;
    const languageName = mainTree.find((lang) => lang.id === firstBookLang)?.name || firstBookLang;
    const isPlentyTranslationsOfLang = bookTranslationsOfLang.length > 1;

    if (isPlentyTranslationsOfLang) {
      return {
        id: getBookId(firstBook.book_id) + "_" + firstBookLang,
        lang: firstBookLang,
        label: languageName,
        title: languageName,
        className: CONTENT_CLASSES.CATEGORIES_LANGUAGE_FOLDER,
        type: CONTENT_CLASSES.CATEGORIES_LANGUAGE_FOLDER,

        children: bookTranslationsOfLang.map((book) => {
          return {
            ...book,
            id: getBookId(book.book_id),
            label: book.title,
            type: CONTENT_CLASSES.CATEGORIES_BOOK_TRANSLATION,
            children: null,
          };
        })
      };
    }

    return {
      ...firstBook,
      id: getBookId(firstBook.book_id),
      label: languageName,
      title: languageName,
      type: CONTENT_CLASSES.CATEGORIES_BOOK_TRANSLATION,
      children: null,
    };
  });
};

export const convertCategoryTree = (tree, mainTree) => {
  return tree.map((category) => {
    const categoryId = category.category_code;
    const categoryName = category.category_name;
    const categoryBooks = category.books;

    const booksOriginals = [];
    const mapBookIdEnToBooks = {};

    let categoryBook;
    for (let i = 0; i < categoryBooks.length; i++) {
      categoryBook = categoryBooks[i];

      const categoryBookIdEn = categoryBook.book_id_en;

      if (mapBookIdEnToBooks[categoryBookIdEn]) {
        mapBookIdEnToBooks[categoryBookIdEn].push(categoryBook);
      } else {
        mapBookIdEnToBooks[categoryBookIdEn] = [categoryBook];
      }

      if (categoryBook.book_id === categoryBookIdEn) {
        booksOriginals.push(categoryBook);
      }
    }

    const categoryItem = {
      ...category,
      id: categoryId,
      categoryId,
      className: CONTENT_CLASSES.CATEGORIES_CATEGORY,
      type: CONTENT_CLASSES.CATEGORIES_CATEGORY,
      label: categoryName,
      icon: IdToIconId[categoryId],
      children: booksOriginals.map((bookOriginal) => {
        const booksTranslations = mapBookIdEnToBooks[bookOriginal.book_id_en];

        return {
          ...bookOriginal,
          titleOriginal: bookOriginal.title, // for using in Breadcrumbs
          title: bookOriginal.code + " " + bookOriginal.title,
          // without "b" prefix to prevent highlight the item when a book is open as {BookDetails}.
          // Parsing to string to be the same type as location match parameter.
          id: bookOriginal.book_id.toString(),
          categoryId, // is needed for each child to make an url on the click in the tree
          className: CONTENT_CLASSES.CATEGORIES_BOOK,
          type: CONTENT_CLASSES.CATEGORIES_BOOK,
          children: makeTreeChildrenBookTranslations(mainTree, bookOriginal, booksTranslations),
        };
      }),
    };

    delete categoryItem.books;

    return categoryItem;
  });
};

export const sortCategoryTree = (titlesConverted, libraryLanguages) => {
  const mlLangDefault = getLanguageDefault(libraryLanguages);

  return titlesConverted.map((category) => {
    const categoryBooks = category.children;
    const categoryBooksFilteredSorted = [];

    let categoryBook;
    for (let i = 0; i < categoryBooks.length; i++) {
      categoryBook = categoryBooks[i];

      // Removes non-library languages from a book
      const categoryBookChildrenFiltered = libraryLanguages.length
        ? categoryBook.children.filter((categoryChildChild) => {
          return libraryLanguages.includes(categoryChildChild.lang);
        })
        : categoryBook.children;
      // ===============

      // Skips a book without children
      if (!categoryBookChildrenFiltered.length) {
        continue;
      }
      // ============

      // Sort by lib langs or make default lang first.
      let categoryBookChildrenFilteredSorted;
      if (libraryLanguages.length) {
        categoryBookChildrenFilteredSorted
          = sortByArray(categoryBookChildrenFiltered, libraryLanguages, "lang");
      } else {
        categoryBookChildrenFilteredSorted = makeMLLangDefaultFirst(
          categoryBookChildrenFiltered, mlLangDefault
        );
      }
      // ===================================

      categoryBooksFilteredSorted.push({
        ...categoryBook,
        children: categoryBookChildrenFilteredSorted,
      });
    }

    return {
      ...category,
      children: categoryBooksFilteredSorted,
    };
  });
};
