import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Badge from "../Badge";
import FeaturedTab from "../rightPanel/FeaturedTab";
import SearchAccordion from "../views/Accordion/SearchAccordion";
import { Period } from "src/utils/AdvSearchTypes";

const RelatedBibleView = ({ lang, mobileView, schemeFiltered, rightPanel, onNavigate }) => {
  const { t } = useTranslation();
  const facets = useSelector((state) => state.relevantSearch.facets);
  const lastSearchQuery = useSelector((state) => state.relevantSearch.lastSearchQuery);
  const searchParams = useSelector((state) => state.search.searchParams);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);

  const renderItem = (item, position) => {
    return (
      <div
        key={item.code}
        className={classNames("relevant-search-block", {
          "mobile-view": mobileView,
        })}>
        {renderRelevantFormContent(item, position)}
      </div>
    );
  };

  const handleSearch = (item, section) => {
    const query = lastSearchQuery || searchQuery || searchParams.query;
    if (query !== "") {
      onNavigate(
        {
          query,
          start: 1,
          context: item.code,
          extras: { section, type: "related", lang, period: Period.custom },
        },
        collectionFilter,
      );
    }
  };

  const renderRelevantForm = (data, position, placeholder = "") => {
    if ((data === undefined || data.length === 0) && !rightPanel) {
      return <div className="filter-search-box-placeholder">{t(placeholder)}</div>;
    }
    return (data || []).map((item) => {
      let left = ["bible_version", "bible_concordance", "bible_dictionary"];
      // let middle = ['bible_dictionary', 'bible_commentary'];
      let right = ["bible_directory", "bible_commentary"];
      if (item.code.indexOf("bible_") === 0) {
        if (position === "left" && left.includes(item.code)) {
          return renderItem(item, position);
          // } else if (position === 'middle' && middle.includes(item.code) ) {
          //   return renderItem(item);
        } else if (position === "right" && right.includes(item.code)) {
          return renderItem(item, position);
        }
        return false;
      }
      return false;
    });
  };

  const renderRelevantFormContent = (data, position) => {
    if (data.endpoints.length > 1) {
      if (mobileView) {
        return (
          <SearchAccordion title={data.title} className="folder" isOpenByDefault>
            <div className="cols">
              {(data.endpoints || []).map((item) => {
                let count = facets?.[data.code]?.[item.code] || 0;
                return renderRelevantFormContentItem(item, count, data.code);
              })}
            </div>
          </SearchAccordion>
        );
      }
      if (!mobileView && position === "left") {
        return (
          <React.Fragment>
            <div className="title">{data.title}</div>
            {(data.endpoints || []).map((item) => {
              let count = facets?.[data.code]?.[item.code] || 0;
              return renderRelevantFormContentItem(item, count, data.code);
            })}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <div className="title">{data.title}</div>
          <div className="cols">
            {(data.endpoints || []).map((item) => {
              let count = facets?.[data.code]?.[item.code] || 0;
              return renderRelevantFormContentItem(item, count, data.code);
            })}
          </div>
        </React.Fragment>
      );
    }
    let count = facets?.[data.code]?.[data.code] || 0;
    return (
      <div
        className={classNames("title with-badge-wrap", count > 0 ? "clickable" : "")}
        onClick={() => (count > 0 ? handleSearch(data, data.code) : false)}>
        {data.title}
        <Badge number={count} />
      </div>
    );
  };

  const renderRelevantFormContentItem = (item, count, section) => {
    return (
      <div
        key={item.code}
        className={classNames("relevant-search-content-item", count > 0 ? "clickable" : "")}
        onClick={() => (count > 0 ? handleSearch(item, section) : false)}>
        <div className="item-title">{item.title}</div>
        <Badge number={count} />
      </div>
    );
  };

  if (rightPanel) {
    return (
      <div className="relevant-search-box">
        <div className="relevant-search-box-left">{renderRelevantForm(schemeFiltered, "left")}</div>
        <div className="relevant-search-box-right">
          {renderRelevantForm(schemeFiltered, "right")}
        </div>
      </div>
    );
  }
  return (
    <div className="relevant-search-box">
      {schemeFiltered?.length !== 0 && (
        <div className="relevant-search-box-left">
          {renderRelevantForm(schemeFiltered, "left", "noSearch")}
        </div>
      )}
      <div className="relevant-search-box-right">
        {renderRelevantForm(schemeFiltered, "right", "reSearch")}
        <FeaturedTab isTypeBible isInsideSearchPlus={false} mobileView={mobileView} />
      </div>
    </div>
  );
};

RelatedBibleView.propTypes = {
  lang: PropTypes.string,
  onNavigate: PropTypes.func,
  mobileView: PropTypes.bool,
  schemeFiltered: PropTypes.array,
  rightPanel: PropTypes.bool,
};

export default RelatedBibleView;
