import React, { useState, useRef, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import DropDown from "./DropDown";
import { IconButton, Portal } from "../views";
import { getBoundingClientRect, Position } from "../../utils/Utils";
import { useViewMode } from "../../hooks";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { getOptionKey } from "./DropDown.utils";

export const DropdownIcon = ({
  icon,
  iconTitle,
  className,
  dropDownClassName,
  containerClassName,
  options,
  value,
  onChange,
  onDropDownClose,
  closeOnMouseLeave,
  maxHeight,
  tooltipPosition,
  regulateMaxHeight,
  withRadio = false,
  doNotOpenOnHover,
  id,
}) => {
  const isValueArray = Array.isArray(value);
  const { t } = useTranslation();
  const rootRef = useRef(null);
  const dropDownRef = useRef(null);
  const { isMobileOrTablet } = useViewMode();
  const remToPx = useRemToPx();
  const [expanded, setExpanded] = useState(false);
  const [position, setPosition] = useState({});
  const [defaultTooltipPosition, setDefaultTooltipPosition] = useState();
  const [isBackground, setIsBackground] = useState(true);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState(maxHeight);
  const [canBeToggled, setCanBeToggled] = useState(true);
  const isDisabled = !options.length;

  const updateFontsDropdownMaxHeight = useCallback(() => {
    const rootRefCurrent = rootRef.current;

    if (rootRefCurrent) {
      const { bottom } = getBoundingClientRect(rootRefCurrent);
      const dropdownGapBottom = 10;
      const maxHeightAvailable = window.innerHeight - bottom - dropdownGapBottom; 
      if (maxHeight) {
        setDropdownMaxHeight(Math.min(maxHeight, maxHeightAvailable));
      } else {
        setDropdownMaxHeight(maxHeightAvailable);
      }
    }
  }, [rootRef.current, maxHeight]);

  const handleSetPosition = () => {
    if (rootRef.current && dropDownRef.current) {
      const iconMargin = remToPx(0.3);
      const iconRect = getBoundingClientRect(rootRef.current);
      const dropDownRect = getBoundingClientRect(dropDownRef.current);
      const dropDownShadowX = 12;

      const newPosition = {};
      newPosition.left = iconRect.left + iconMargin;

      if (newPosition.left + dropDownRect.width > window.innerWidth) {
        newPosition.left = window.innerWidth - dropDownRect.width - dropDownShadowX;

        if (newPosition.left < 0) {
          newPosition.left = 0;
        }
      }

      if (iconRect.bottom + dropDownRect.height >= window.innerHeight) {
        newPosition.bottom = iconRect.height;
        setDefaultTooltipPosition(Position.BOTTOM);
      } else {
        newPosition.top = iconRect.top + iconRect.height;
        setDefaultTooltipPosition(Position.TOP);
      }

      setPosition(newPosition);
    }
  };

  useEffect(() => {
    if (regulateMaxHeight) {
      window.addEventListener("resize", updateFontsDropdownMaxHeight);
    }

    return () => {
      return window.removeEventListener("resize", updateFontsDropdownMaxHeight);
    };
  }, [regulateMaxHeight, updateFontsDropdownMaxHeight]);

  useEffect(() => {
    let toggleDelayTimerId;

    if (expanded) {
      setCanBeToggled(false);

      toggleDelayTimerId = setTimeout(() => {
        setCanBeToggled(true);
      }, 750);
    } else {
      setCanBeToggled(true);
    }

    handleSetPosition();

    return () => {
      if (toggleDelayTimerId) {
        clearTimeout(toggleDelayTimerId);
      }
    };
  }, [expanded]);

  useEffect(() => {
    if (closeOnMouseLeave && !isMobileOrTablet) {
      setIsBackground(false);
    }
  }, [closeOnMouseLeave]);

  useEffect(() => {
    updateFontsDropdownMaxHeight();
  }, [updateFontsDropdownMaxHeight, rootRef]);

  useEffect(() => {
    window.addEventListener("resize", handleSetPosition);

    return () => window.removeEventListener("resize", handleSetPosition);
  }, []);

  const handleChange = (valueNew) => {
    if (onChange && valueNew) {
      const valueNewNormalized = (Array.isArray(valueNew) ? valueNew : [valueNew])
        .map((option) => getOptionKey(option));
      // if value was an array then return as an array of keys.
      // if value was a primitive then return as a primitive key.
      onChange(isValueArray ? valueNewNormalized : valueNewNormalized[0]);
    }
  };

  const open = useCallback(() => {
    if (!isDisabled) {
      setExpanded(true);
    }
  }, [isDisabled]);

  const hide = useCallback(() => {
    setExpanded(false);
  }, []);

  const toggle = useCallback(() => {
    if (expanded) {
      hide();
    } else {
      open();
    }
  }, [expanded, open, hide]);

  const handleDropDownClose = useCallback(() => {
    toggle();
    if (onDropDownClose) {
      onDropDownClose();
    }
  }, [toggle, onDropDownClose]);

  const onHandleMouseEnter = useCallback(() => {
    if (isMobileOrTablet) {
      return;
    }
    open();
  }, [isMobileOrTablet, open]);

  const onHandleMouseLeave = useCallback(() => {
    if (!closeOnMouseLeave && isMobileOrTablet) {
      return;
    }
    hide();
  }, [closeOnMouseLeave, hide, isMobileOrTablet]);

  const handleClick = useCallback(() => {
    if (canBeToggled) {
      toggle();
    }
  }, [canBeToggled, toggle]);

  const handleGetTitle = useCallback((item) => (item && item.label ? t(item.label) : ""), [t]);

  return (
    <div
      className={classNames("default-select", containerClassName)}
      ref={rootRef}
      onClick={handleClick}
      onMouseEnter={doNotOpenOnHover ? undefined : onHandleMouseEnter}
      onMouseLeave={onHandleMouseLeave}>
      <IconButton
        id={id}
        active={expanded}
        title={iconTitle}
        icon={icon}
        className={className}
        tooltipPosition={tooltipPosition || defaultTooltipPosition}
        onClick={handleClick}
        disabled={isDisabled}
      />
      <Portal id="dropdown">
        <DropDown
          ref={dropDownRef}
          isBackground={isBackground}
          expanded={expanded}
          options={options}
          dropDownClassName={dropDownClassName}
          innerClass="dd-margin-top"
          position={position}
          maxHeight={dropdownMaxHeight}
          withRadio={withRadio}
          value={value}
          onGetTitle={handleGetTitle}
          onSelect={handleChange}
          onDropDownClose={handleDropDownClose}
        />
      </Portal>
    </div>
  );
};

DropdownIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  iconTitle: PropTypes.string,
  dropDownClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onDropDownClose: PropTypes.func,
  closeOnMouseLeave: PropTypes.bool,
  maxHeight: PropTypes.number,
  tooltipPosition: PropTypes.string,
  withRadio: PropTypes.bool,
  doNotOpenOnHover: PropTypes.bool,
  regulateMaxHeight: PropTypes.bool,
  id: PropTypes.string,
};
