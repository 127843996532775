import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "next-i18next";
import classNames from "classnames";

import { useViewMode } from "../../../hooks";
import { Position } from "src/utils/Utils";
import DropDown from "src/components/dropDown/DropDown";
import DropDownPortal from "src/components/dropDown/DropDownPortal";

import "./NestedDropdown.scss";

const NestedDropdown = ({ items, parent, ...restProps }) => {
  const { t } = useTranslation();
  const { textMode } = useViewMode();
  const [activeItemIndex, setActiveItemIndex] = useState(-1);
  const [selectedLabelReact, setSelectedLabelReact] = useState({});

  const DropDownElement = parent ? DropDownPortal : DropDown;

  const activeItem = activeItemIndex !== -1 ? items[activeItemIndex] : {};

  const closeNestedDD = useCallback(() => {
    setSelectedLabelReact({});
    setActiveItemIndex(-1);
  }, []);

  const openNestedDD = useCallback((event, index) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setSelectedLabelReact(rect);
    setActiveItemIndex(index);
  }, []);

  const handleClickItem = useCallback((event, index) => {
    if (activeItemIndex === index) {
      closeNestedDD();
    } else {
      openNestedDD(event, index);
    }
  }, [activeItemIndex, openNestedDD, closeNestedDD]);

  const children = (
    <ul className="nested-dd">
      {
        items?.length ? items.map((item, index) => (
          <li key={item.id} className="nested-dd__li" id={item.id}>
            <span
              onClick={(event) => handleClickItem(event, index)}
              className={classNames("nested-dd__title", {
                "with-chevron": item.children || item.component,
              })}
            >{t(item.title, item.translarionKeys)}</span>
          </li>
        )) : items
      }
    </ul>
  );

  return (
    <>
      <DropDownElement isPopup expanded slotOptionsAfter={children} {...restProps} />
      {activeItemIndex !== -1 && (
        <NestedDropdown
          labelRect={selectedLabelReact}
          positionHorizontal={textMode ? Position.LEFT : Position.RIGHT}
          dropDownClassName={classNames("nested-dd__dd", restProps.dropDownStyle)}
          onDropDownClose={closeNestedDD}
          items={activeItem?.children}
          maxHeight={window.innerHeight}
          parent={activeItem}
        />
      )}
    </>
  );
};

NestedDropdown.propTypes = {
  className: PropTypes.string,
  parent: PropTypes.object,
  labelRect: PropTypes.object,
  items: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  onChange: PropTypes.func,
};

export default NestedDropdown;
