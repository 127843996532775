import { getBaseHost } from "./url";
import EgwWebFont from "src/assets/EgwWebFont";

export const addThisUrl = "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d405fe9b17ed1fd&async=1";

export const makeMessageText = (maxLength, url = "", refcode = "", text = "") => {
  const refCodeAndUrlString = ` ${refcode} ${url}`;
  const textMaxAllowedLength = maxLength - refCodeAndUrlString.length;

  if (text.length > textMaxAllowedLength) {
    text = text.substr(0, textMaxAllowedLength - 1) + "…";
  }

  let finalText = text + refCodeAndUrlString;
  return finalText.trim().length ? encodeURIComponent(finalText) : "";
};

export const ShareActions = [
  {
    icon: EgwWebFont["telegram-circle"],
    title: "Telegram",
    service: "telegram_share",
    action: (shareData) => {
      const { url, text, refcode } = shareData;
      const content = makeMessageText(200, "", refcode, text);
      const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(normilizeUrl(url))}&text=${content}`;
      window.open(shareUrl, "_blank");
    }
  },
  {
    icon: EgwWebFont["facebook-circle"],
    title: "Facebook",
    service: "facebook",
    action: (shareData) => {
      const { url, text, refcode } = shareData;
      const content = makeMessageText(400, "", refcode, text);
      let quote = "";

      if (content) {
        quote = `&quote=${content}`;
      }

      /*let sharerUrl =
        `https://www.facebook.com/dialog/share?app_id=${FacebookId}` +
        `&display=page&href=${encodeURIComponent(normilizeUrl(url))}${quote}`;*/
      const sharerUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(normilizeUrl(url))}&quote=${quote}`;
      window.open(sharerUrl, "_blank");
    }
  },
  {
    icon: EgwWebFont["twitter-circle"],
    title: "Twitter",
    service: "twitter",
    action: (shareData) => {
      const { url, text, refcode } = shareData;
      const shareUrl = normilizeUrl(url);
      const maxLength = 280 - (shareUrl || "").length;
      let content = makeMessageText(maxLength, "", refcode, text);
      let sharerUrl = `https://twitter.com/share?text=${content}&url=${encodeURIComponent(shareUrl)}`;
      window.open(sharerUrl, "_blank");
    }
  },
  {
    icon: EgwWebFont["whatsapp-circle"],
    title: "WhatsApp",
    service: "whatsapp",
    action: (shareData) => {
      const { url, text, refcode } = shareData;
      let content = makeMessageText(400, normilizeUrl(url), refcode, text);
      let sharerUrl = `https://api.whatsapp.com/send?text=${content}`;
      window.open(sharerUrl, "_blank");
    }
  },
  {
    icon: EgwWebFont["email-circle"],
    title: "Email",
    service: "email",
    action: (shareData) => {
      const { url, title = "", text, refcode } = shareData;
      const shareText = makeMessageText(1300 - url.length, "", refcode, text);
      const content = `${shareText}\n${encodeURIComponent(url)}`;
      const sharerUrl = `mailto:?subject=${title}&body=${content}`;
      window.open(sharerUrl, "_blank");
    }
  },
  {
    icon: EgwWebFont["copy-link"],
    title: "Link",
    service: "link",
    justCopy: true,
  },
  {
    icon: EgwWebFont["print-circle"],
    title: "Print",
    service: "print",
    action: () => {
      window.print();
    }
  }
];

export const normilizeUrl = (url) => {
  if (url.indexOf("http") !== 0) {
    if (process.env.NODE_ENV === "development") {
      return getBaseHost() + url;
    }
    return window.location.origin + url;
  }
  return url;
};
