import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { getBoundingClientRect, Position } from "../../utils/Utils";
import { Portal } from "../views";
import DropDown, { DEFAULT_DROPDOWN_MAXHEIGHT } from "./DropDown";

export const DropDownPortal = ({ labelRect, expanded, positionHorizontal, ...restProps }) => {
  const [position, setPosition] = useState({});
  const [maxHeight, setMaxHeight] = useState(DEFAULT_DROPDOWN_MAXHEIGHT);
  const dropDownRef = useRef(null);

  const handleSetPosition = () => {
    if (dropDownRef.current) {
      const dropDownRect = getBoundingClientRect(dropDownRef.current);
      const dropDownShadowX = 12;

      const newPosition = {};
      switch (positionHorizontal) {
        case Position.RIGHT:
          newPosition.left = labelRect.right + dropDownShadowX;
          if (newPosition.left + dropDownRect.width + dropDownShadowX > window.innerWidth) {
            newPosition.left = window.innerWidth - dropDownRect.width - dropDownShadowX;

            if (newPosition.left < 0) {
              newPosition.left = 0;
            }
          }
          break;

        case Position.LEFT:
          newPosition.left = labelRect.left - dropDownRect.width - dropDownShadowX;
          if (newPosition.left < 0) {
            newPosition.left = 0;
          }
          break;
      }

      setMaxHeight(window.innerHeight - labelRect.bottom - dropDownShadowX);

      if (labelRect.bottom + dropDownRect.height >= window.innerHeight) {
        newPosition.top = labelRect.bottom;
      } else {
        newPosition.top = labelRect.top + labelRect.height;
      }

      setPosition(newPosition);
    }
  };

  useEffect(() => {
    if (expanded) {
      handleSetPosition();
    }
  }, [expanded, labelRect]);

  useEffect(() => {
    window.addEventListener("resize", handleSetPosition);

    return () => window.removeEventListener("resize", handleSetPosition);
  }, []);

  return (
    <Portal id="dropDownPortal">
      <DropDown
        {...restProps}
        expanded={expanded}
        ref={dropDownRef}
        maxHeight={maxHeight}
        position={position}
      />
    </Portal>
  );
};

DropDownPortal.propTypes = {
  expanded: PropTypes.bool,
  positionHorizontal: PropTypes.string,
  labelRect: PropTypes.object,
};

export default DropDownPortal;
