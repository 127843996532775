import {
  ACTION_FETCH_CF_SUCCESS,
  ACTION_SEND_CF_STATUS
} from "./constants";
import { CFStatus } from "../utils/Utils";

const initialState = {
  mainGeneralQuestions: [],
  formSentStatus: CFStatus.PENDING
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_FETCH_CF_SUCCESS: {
      return { ...state, mainGeneralQuestions: action.data };
    }
    case ACTION_SEND_CF_STATUS: {
      return { ...state, formSentStatus: action.data };
    }
    default:
      return state;
  }
};
