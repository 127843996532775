import scssVariables from "../../styles/variables.module.scss";

const DefaultSizes = {
  COLLAPSE_MENU_WIDTH: scssVariables.sideMenuItemHeight,
  MIN_PANEL_WIDTH: 320,
  MIN_PANEL_HEIGHT: 150,
  DELTA_TILE_GAPS: 2,
  DELTA: 2,
  MIN_LEFT_TREE_WIDTH: 15,
  MIN_MAIN_WIDTH: 18,
  MIN_LEFT_WIDTH: 12.5,
  MIN_TILE_WIDTH: 13,
  MIN_TILE_HEIGHT: 9,
  PANE_WIDTH_BOOK_DETAILS: 19.5,
  MIN_RIGHT_WIDTH: 20,
  SEARCH_PLUS_BUTTON_WIDTH: 6.1
};

export const CONFIGURED_SIZES = {
  DEFAULT: DefaultSizes,
  max3840: {
    ...DefaultSizes,
    DELTA_TILE_GAPS: 0.8,
    DELTA: 1.45,
    MIN_LEFT_TREE_WIDTH: 24
  },
  max2560: {
    ...DefaultSizes,
    DELTA_TILE_GAPS: 0.8,
    DELTA: 1.45,
    MIN_LEFT_TREE_WIDTH: 19
  },
  max1920: {
    ...DefaultSizes,
    DELTA_TILE_GAPS: 0.8,
    DELTA: 1.45
  },
  max1024: {
    ...DefaultSizes,
    DELTA_TILE_GAPS: 0.8,
    DELTA: 0.9,
    MIN_MAIN_WIDTH: 17.6
  },
  max768: {
    ...DefaultSizes,
    DELTA_TILE_GAPS: 0.8,
    DELTA: 0.9,
    MIN_MAIN_WIDTH: 17.6
  }
};
