export const BookListType = {
  all: "all",
  allWithHistory: "allWithHistory",
  read: "read",
  readWithHistory: "readWithHistory",
  listen: "listen",
  listenWithHistory: "listenWithHistory",
};

export const makeBookListType = (withHistory, showReadingHistory, showListenHistory) => {
  if (showReadingHistory && showListenHistory) {
    return withHistory ? BookListType.allWithHistory : BookListType.all;
  } else if (!showReadingHistory && showListenHistory) {
    return withHistory ? BookListType.listenWithHistory : BookListType.listen;
  } else if (showReadingHistory && !showListenHistory) {
    return withHistory ? BookListType.readWithHistory : BookListType.read;
  }
  return BookListType.all;
};

export const getIsBookListTypeWithHistory = (bookListType) => {
  return (
    bookListType === BookListType.allWithHistory ||
    bookListType === BookListType.readWithHistory ||
    bookListType === BookListType.listenWithHistory
  );
};

export const getIsBookListTypeListen = (bookListType) => {
  return (
    bookListType === BookListType.listen
    || bookListType === BookListType.listenWithHistory
  );
};

const ALPHABET_LENGTH_LIMIT = 35;

export const getBooksFirstChars = (books) => {
  let chars = [];

  books.forEach((book) => {
    const bookData = book.bookInfo || book;
    if (bookData) {
      if (chars.indexOf(bookData.firstChar) < 0) {
        chars.push(bookData.firstChar);
      }
    }
  });

  if (chars.length >= ALPHABET_LENGTH_LIMIT) {
    chars = chars.filter((char, index) => (index % 2 > 0));
  }

  chars.sort((a, b) => a.localeCompare(b));

  return chars;
};
