import { PeriodDefault, QUERY_TYPE, SearchType, SNIPPET } from "src/shared/utils/search";
import { RESTORE_DATA } from "../../redux/constants";
import { SearchConstants } from "./search.actions";
import { updateStatePropertyAndItsCache } from "../../utils/StoreUtils";

const SHORTCUT_CONTENT_CACHE_LIMIT = 12;

const makeShortcutContent = (queryAnalogue, results) => ({ queryAnalogue, results });

export const initialSearchState = {
  search: {
    total: -1,
    results: [],
    languageFacets: {},
  },
  searchParams: {
    query: "",
    folders: [],
    books: [],
    context: "",
    bookCode: "",
    extras: {
      type: SearchType.basic,
      snippet: SNIPPET.LONG,
      period: PeriodDefault,
      queryType: QUERY_TYPE.EXACT,
    },
  },
  searchQueryLangs: {},
  searchQueryLangsCache: {},
  searchQuery: "",
  isShowHeaderSearch: false,
};

const initSearchFilter = {
  searchedQuery: undefined,
  translate: [],
  detect: [],
  autocomplete: [],
  suggestions: [],
  corrections: [],
  synonyms: [],
  autocompleteLoaded: false,
  suggestionLoaded: true,
  shortcuts: [],
};

const initLocalSearchResults = {
  total: -1,
  results: [],
};

const initLocalSearchParams = {
  query: "",
  books: [],
  isLocal: true,
  extras: {
    order: "rel",
    type: "basic",
    snippet: "long",
  },
};

const initialState = {
  ...initialSearchState,
  topicQuery: undefined,
  topics: [],
  searchHistory: [],
  searchBible: "",
  dictionaryFilter: "all",
  searchQuery: "",
  searchNeedle: "",
  searchLang: "",
  rightPanelPosition: 1,
  isLastLocal: false,
  lastPosition: 1,
  localSearch: initLocalSearchResults,
  localSearchParams: initLocalSearchParams,
  searchClearMarkBookId: [],
  print: [],
  searchFilter: initSearchFilter,
  shortcuts: {},
  searchDictionary: [],
  isShowFilterSearch: false,
  searchBookListSuppressed: false,
  shortcutContent: makeShortcutContent("", []),
  shortcutContentCache: {},
  queryCorrectionsCache: {},
  booksSearch: {
    total: 0,
    books: [],
    query: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SearchConstants.ACTION_SEARCH_TYPE:
      return { ...state, isLastLocal: action.data };
    case SearchConstants.ACTION_SEARCH_SUCCESS: {
      const {
        search,
        searchParams,
        localSearchParams,
        localSearch,
        lastPosition,
        rightPanelPosition,
      } = action.data;
      const newState = { ...state };
      if (searchParams && search) {
        newState.search = { ...search };
        newState.searchParams = { ...searchParams };
        // not needed to set checked current langs after search. keep selection empty
      }
      if (localSearchParams) {
        newState.localSearchParams = localSearchParams;
      }
      if (localSearch) {
        newState.localSearch = localSearch;
      }

      if (lastPosition !== undefined) {
        newState.lastPosition = lastPosition;
      }
      if (rightPanelPosition !== undefined) {
        newState.rightPanelPosition = rightPanelPosition;
      }

      // Clear shortcut content results if queries are different
      if (!localSearchParams && searchParams) {
        const shortcutQueryAnalogue = state.shortcutContent.queryAnalogue;
        if (state.shortcutContent.results.length && (
          !shortcutQueryAnalogue || shortcutQueryAnalogue !== searchParams.query
        )) {
          newState.shortcutContent = initialState.shortcutContent;
        }
      }
      // =======================================================

      return newState;
    }
    case SearchConstants.FETCH_TOPICS_SUCCESS:
      return { ...state, topics: action.data.topics, topicQuery: action.data.topicQuery };
    case SearchConstants.ACTION_SEARCH_HISTORY_SUCCESS:
      return { ...state, searchHistory: action.data };
    case SearchConstants.FETCH_SHORTCUT_SUCCESS:
      return { ...state, shortcuts: action.data };
    case SearchConstants.ACTION_SEARCH_QUERY: {
      const { searchQuery, lang } = action.data;
      const newState = { ...state, searchQuery: searchQuery };
      if (lang) {
        newState.searchLang = lang;
      }
      return newState;
    }
    case SearchConstants.ACTION_CHANGE_RELATED_LANG:
      return { ...state, searchLang: action.data };
    case SearchConstants.ACTION_SEARCH_TOPICS_SUCCESS:
      return {
        ...state,
        topics: action.data,
        topicsTotal: action.data.reduce((sum, item) => sum + item.count, 0),
      };
    case SearchConstants.ACTION_SEARCH_NEEDLE_CHANGE: {
      const newState = { ...state };
      if (action.data === undefined) {
        newState.searchNeedle = "";
        newState.booksSearch = {
          total: 0,
          books: [],
          query: "",
        };
      } else {
        newState.searchNeedle = action.data;
      }
      return newState;
    }

    case SearchConstants.SET_RIGHT_PANEL_POSITION:
      return { ...state, rightPanelPosition: action.data };
    case RESTORE_DATA: {
      const params = { ...state.searchParams };
      const { searchSnippet, searchSort, collectionFilter } = action.data;
      if (searchSnippet) {
        params.extras.snippet = searchSnippet;
      }
      if (searchSort) {
        params.extras.order = searchSort;
      }
      if (collectionFilter) {
        params.extras.period = collectionFilter;
      }
      return { ...state, searchParams: params };
    }
    case SearchConstants.ACTION_TOGGLE_SEARCH_MARK: {
      const panelBookId = action.data;
      let clearBookIds = [];
      if (panelBookId !== "all") {
        clearBookIds = [...state.searchClearMarkBookId];
        const bookIndex = clearBookIds.indexOf(panelBookId);
        if (bookIndex === -1) {
          clearBookIds.push(panelBookId);
        } else {
          clearBookIds.splice(bookIndex, 1);
        }
      }

      return { ...state, searchClearMarkBookId: clearBookIds };
    }

    case SearchConstants.ACTION_SEARCH_BY_RANGE_SUCCESS: {
      return { ...state, print: action.data };
    }
    case SearchConstants.ACTION_SEARCH_BY_RANGE_ERROR: {
      return { ...state, printError: action.data };
    }
    case SearchConstants.ACTION_UPDATE_SEARCH_PERIOD: {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          extras: { ...state.searchParams.extras, period: action.data },
        },
      };
    }
    case SearchConstants.FETCH_POSSIBLE_TRANSLATE_SUCCESS: {
      const { translate } = action.data;
      let newState = { ...state };
      newState.searchFilter = { ...newState.searchFilter, translate: translate || [] };
      return newState;
    }
    case SearchConstants.FETCH_SUGGESTION: {
      return { ...state };
    }
    case SearchConstants.FETCH_DETECT_SUCCESS: {
      const { detect, translate } = action.data;
      const newSearchFilter = { ...state.searchFilter };
      newSearchFilter.detect = detect || [];
      if (translate !== undefined) {
        newSearchFilter.translate = translate;
      }
      return { ...state, searchFilter: newSearchFilter };
    }
    case SearchConstants.FETCH_SUGGESTION_SUCCESS: {
      const {
        searchedQuery,
        suggestions,
        corrections,
        synonyms,
        autocomplete,
        shortcuts,
      } = action.data;
      const newSearchFilter = { ...state.searchFilter };
      if (!searchedQuery) {
        newSearchFilter.detect = [];
        newSearchFilter.translate = [];
      }
      newSearchFilter.searchedQuery = searchedQuery || "";
      newSearchFilter.suggestions = suggestions || [];
      newSearchFilter.corrections = corrections || [];
      newSearchFilter.synonyms = synonyms || [];
      newSearchFilter.autocomplete = autocomplete || [];
      newSearchFilter.shortcuts = shortcuts || [];
      return { ...state, searchFilter: newSearchFilter };
    }
    case SearchConstants.ACTION_UPDATE_SEARCH_BIBLE:
      return { ...state, searchBible: action.data };
    case SearchConstants.ACTION_CLEAR_LOCAL_SEARCH: {
      return {
        ...state,
        localSearch: initLocalSearchResults,
        localSearchParams: initLocalSearchParams,
      };
    }
    case SearchConstants.FETCH_SEARCH_DICTIONARY_SUCCESS: {
      return { ...state, searchDictionary: action.data };
    }
    case SearchConstants.ACTION_UPDATE_DICTIONARY_FILTER: {
      return { ...state, dictionaryFilter: action.data };
    }
    case SearchConstants.ACTION_IS_SHOW_FILTER_SEARCH: {
      return { ...state, isShowFilterSearch: action.data };
    }
    case SearchConstants.FETCH_LANGS_FOR_WORD_SUCCESS: {
      const { langs, query, corrections } = action.data;
      const searchQueryLangsCache = { ...state.searchQueryLangsCache };
      searchQueryLangsCache[query] = langs;
      const newQueryCorrections = { ...state.queryCorrectionsCache };
      if (corrections) {
        newQueryCorrections[query] = corrections;
      }
      return {
        ...state,
        searchQueryLangs: langs,
        searchQueryLangsCache,
        queryCorrectionsCache: newQueryCorrections,
      };
    }
    case SearchConstants.ACTION_SEARCH_BOOK_LIST_SUPPRESS: {
      return { ...state, searchBookListSuppressed: action.data };
    }
    case SearchConstants.ACTION_GET_SHORTCUT_CONTENT_SUCCESS: {
      const shortcutQueryAnalogue = action.data.queryAnalogue;
      const searchResultsQuery = state.search.query;

      let newState;
      // Clear search results if queries are different
      if (state.search.results.length && (
        !searchResultsQuery || shortcutQueryAnalogue !== searchResultsQuery
      )) {
        newState = {
          ...state,
          search: {...initialSearchState.search},
          searchParams: {...initialSearchState.searchParams},
        };
      } else {
        newState = state;
      }
      // =======================================================

      return updateStatePropertyAndItsCache(
        SHORTCUT_CONTENT_CACHE_LIMIT,
        newState,
        "shortcutContentCache",
        "shortcutContent",
        action.data.paraId,
        makeShortcutContent(shortcutQueryAnalogue, action.data.data),
      );
    }
    case SearchConstants.ACTION_CLEAR_SEARCH: {
      const { lang, resetSearch } = action.data;
      if (resetSearch) {
        return { ...state, ...initialSearchState };
      }
      return {
        ...state,
        ...initialSearchState,
        searchFilter: { ...initSearchFilter },
        searchLang: lang,
        isShowHeaderSearch: false,
        searchHistory: [],
      };
    }
    case SearchConstants.SEARCH_BOOK_BY_NAME_SUCCESS: {
      return { ...state, booksSearch: action.data };
    }
    case SearchConstants.UPDATE_SHOW_HEADER_SEARCH: {
      return { ...state, isShowHeaderSearch: action.data };
    }
    default:
      return state;
  }
};
