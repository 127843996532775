export const EgwWritingsApps = [
  {
    title: "androidApp",
    url: "https://play.google.com/store/apps/details?id=com.whiteestate.egwwritings"
  },
  { title: "iosApp", url: "https://itunes.apple.com/us/app/egw-writings-2/id994076136?mt=8" },
  { title: "macosApp", url: "https://apps.apple.com/us/app/egw-writings/id1458108029" },
  {
    title: "windowsApp",
    url:
      "https://apps.microsoft.com/windows/en-us/app/egw-writings/aac653fc-1f3b-40cf-9ae4-a8ccc7568178"
  },
  { title: "legacyEgwwritings", url: "https://egwwritings.org" },
  { title: "legacyMobile", url: "https://m.egwwritings.org" },
  { title: "legacyText", url: "https://text.egwwritings.org" },
  { title: "playPitcairn", url: "https://honor.ellenwhite.org/" }
];

export const Directory = [
  { title: "Ellenwhite.org", url: "https://ellenwhite.org" },
  { title: "Whiteestate.org", url: "https://whiteestate.org" },
  { title: "egwResearchCenters", url: "#" },
  { title: "localSdaChurches", url: "https://www.adventistdirectory.org/" },
  { title: "adventistdigitallibrary.org", url: "https://adventistdigitallibrary.org" },
  { title: "adventist.org", url: "https://adventist.org" },
  { title: "bibleschools.com", url: "https://bibleschools.com" }
];

export const SubscriptionsItemDefault = {
  title: "subscriptions",
  url: "#"
};
