import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { actionUpdateSetting } from "../redux/actions";
import { Settings } from "../utils/Settings";
import { TreeMenuMode } from "../utils/TreeUtils";
import { URLS } from "../shared/utils/url";

const useChangeTreeBasedOnRoute = () => {
  const pathnamePrevRef = useRef(undefined);
  const history = useHistory();
  const dispatch = useDispatch();
  const treeMenuMode = useSelector((state) => state.settings.treeMenuMode);

  const { pathname } = history.location;

  useEffect(() => {
    const pathnameRouteFirst = pathname.split("/")[1];
    const pathnamePrevRouteFirst = (pathnamePrevRef.current || "").split("/")[1];

    if (
      typeof pathnamePrevRef.current !== undefined
      && pathnameRouteFirst === pathnamePrevRouteFirst
    ) {
      return;
    }

    let treeModeNew;

    if (pathname.includes(URLS.titles)) {
      treeModeNew = TreeMenuMode.TITLES;
    }

    if (pathname.includes(URLS.categories)) {
      treeModeNew = TreeMenuMode.CATEGORIES;
    }

    if (
      pathname === "/"
      || pathname.includes(URLS.myLibrary)
      || pathname.includes(URLS.history)
      || pathname.includes(URLS.allCollection)
      || pathname.includes(URLS.audioBooks)
    ) {
      treeModeNew = TreeMenuMode.FOLDERS;
    }

    if (treeModeNew && treeModeNew !== treeMenuMode) {
      dispatch(actionUpdateSetting(Settings.treeMenuMode.id, treeModeNew));
    }
  }, [pathname]); // missing deps to change tree view only on pathname upd.
};

export default useChangeTreeBasedOnRoute;
