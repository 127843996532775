import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { decode } from "html-entities";

import BreadCrumbs from "./BreadCrumbs";
import { IconButton, SearchPlusButton } from "../views";
import TopPanelTools from "./TopPanelTools";
import { useViewMode, useAuth, useFullScreen } from "../../hooks";
import { useNavigationContext } from "../NavigationContext"; 
import TextButton from "../views/TextButton";
import BaseLanguageSelector from "../languageSelector/BaseLanguageSelector";
import { usePopupContext } from "../popup/PopupContext";
import { actionUpdateSetting, changeTopPanelVisibility } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { removeBR } from "../../shared/utils/content";
import { SCROLL_DIRECTIONS } from "../reader/constants";
import EgwWebFont from "src/assets/EgwWebFont";
import ButtonBooksFilters from "../categories/ButtonBooksFilters";
import IconView from "src/shared/components/IconView";
import { READER_TOP_PANEL_PORTAL_ID } from "src/shared/constants";

import "./TopPanel.scss";
import { URLS } from "../../shared/utils/url";

const TopPanel = ({style}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isReader,
    targetId,
    currentFolder,
    label,
    pathname,
    realType,
    hasPrevPage,
    goPrevPage,
  } = useNavigationContext();
  const { t } = useTranslation();
  const { showPopup } = usePopupContext();
  const isGrid = useSelector((state) => state.settings.isGrid);
  const isShowAZ = useSelector((state) => state.settings.isShowAZ);
  const showReadingHistory = useSelector((state) => state.settings.showReadingHistory);
  const showListenHistory = useSelector((state) => state.settings.showListenHistory);
  const showBookshelf = useSelector((state) => state.settings.showBookshelf);
  const isTopPanelVisible = useSelector((state) => state.settings.isTopPanelVisible);
  const sortByRecent = useSelector((state) => state.settings.sortByRecent);
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const scrollDirection = useSelector((state) => state.settings.scrollDirection);
  const { isLogin } = useAuth();

  const { isMobile, isTablet, isMobileOrTablet } = useViewMode();
  const { fullscreen } = useFullScreen();

  const isHome = pathname === URLS.home;
  const isExternalReader = pathname.includes("epub") || pathname.includes("pdf");

  const isLibrary = pathname.includes(URLS.myLibrary);
  const isHistory = pathname.includes(URLS.history);
  const isShowBtnFiltersBookType = isHistory || currentFolder.showBtnFiltersBookType;
  const isWorkspace = pathname.includes(URLS.workspaces);
  const isSubEditType =
    pathname.endsWith(URLS.subscriptionEdit + "/book") ||
    pathname.endsWith(URLS.subscriptionEdit + "/bible") ||
    pathname.endsWith(URLS.subscriptionEdit + "/devotional");
  const isAbbr = pathname.includes(URLS.bibliography);
  const isTitles = pathname.includes(URLS.titles);

  let onPlusClick = null;

  if (isLibrary || (pathname === URLS.allCollection && isLogin)) {
    onPlusClick = (e) => {
      const rect = e.target.getBoundingClientRect();
      showPopup(
        {
          left: rect.left,
          top: rect.top,
        },
        <BaseLanguageSelector />,
        {
          parent: e.target,
          hideOnClick: false,
        },
      );
    };
  } else if (isSubEditType) {
    onPlusClick = () => {
      history.push(`${URLS.subscriptionAdd}/${targetId}`);
    };
  }
  let showIconsForHistory = false;
  if (isHistory) {
    const parts = pathname.split("/");
    if (parts[2]) {
      showIconsForHistory = true;
    }
  }

  const showListIcons =
    currentFolder.nbooks > 0 ||
    showIconsForHistory ||
    isWorkspace ||
    (isLibrary && (!pathname.endsWith(URLS.myLibrary) || libraryLanguages.length === 1));

  useEffect(() => {
    if (!isTopPanelVisible && isReader && isTablet && scrollDirection === SCROLL_DIRECTIONS.DOWN) {
      dispatch(changeTopPanelVisibility(false));
    } else if (isTopPanelVisible) {
      dispatch(changeTopPanelVisibility(true));
    }
  }, [isTopPanelVisible, isReader, scrollDirection, isTablet]);

  const handleAZ = () => {
    dispatch(actionUpdateSetting(Settings.isShowAZ.id, !isShowAZ));
  };

  const handleListChange = (isGrid) => {
    dispatch(actionUpdateSetting(Settings.isGrid.id, isGrid));
  };

  const handleBookshelfChange = () => {
    dispatch(actionUpdateSetting(Settings.showBookshelf.id, !showBookshelf));
  };

  const handleHistoryFilter = (isReading) => {
    if (
      (isReading && showReadingHistory && !showListenHistory) ||
      (!isReading && !showReadingHistory && showListenHistory)
    ) {
      return;
    }
    if (isReading) {
      dispatch(actionUpdateSetting(Settings.showReadingHistory.id, !showReadingHistory));
    } else {
      dispatch(actionUpdateSetting(Settings.showListenHistory.id, !showListenHistory));
    }
  };

  const handleBackLink = (e) => {
    if (hasPrevPage && isMobileOrTablet) {
      /* TODO implement go to back on nested paragraphs      
      let lastNode = nodeList[nodeList.length - 2];
      let lastItem = nodeList[nodeList.length - 1];
      if (isReader && lastItem.className === "book") {
        lastNode = lastItem;
      } 
      history.push(makeNavigationUrl(lastNode, { isAudio }));*/

      goPrevPage();
      e.preventDefault();
    }
  };

  let topTitle = label;
  if (isHistory) {
    if (showReadingHistory && showListenHistory) {
      topTitle = t("history");
    } else if (showReadingHistory && !showListenHistory) {
      topTitle = t("readingHistory");
    } else {
      topTitle = t("listenHistory");
    }
  }

  if (isAbbr) {
    topTitle = t("bibliography");
  }

  if (isTitles) {
    topTitle = t(topTitle);
  }

  const renderMainHeader = () => {
    if (!topTitle) {
      return null;
    }

    return (
      <div className={classNames("main-header", realType)}>
        <button onClick={handleBackLink} className="main-header-inner">
          <h1 className={"main-header-title"}>
            {isMobileOrTablet && !isHome && hasPrevPage && (
              <i className="back-icon icon-chevron-left"></i>
            )}
            <span>{decode(removeBR(topTitle))}</span>
          </h1>
        </button>
      </div>
    );
  };

  if (isMobileOrTablet) {
    let topPanelTools = (
      <TopPanelTools
        isLibrary={isLibrary}
        isWorkspace={isWorkspace}
        showListIcons={showListIcons}
        isShowBtnFiltersBookType={isShowBtnFiltersBookType}
        onPlusClick={onPlusClick}
      />
    );

    if (isAbbr) {
      topPanelTools = (
        <div className="panel-tools">
          <IconButton
            icon={EgwWebFont.AZ}
            title={t("A/Z")}
            className="tpMenuIcon"
            onClick={handleAZ}
            active={isShowAZ}
          />
        </div>
      );
    }

    if (isMobile) {
      // Render for mobile view, without breadcrumbs
      const showOnMobile = isExternalReader ? isTopPanelVisible : !isHome;
      return (
        <div style={style} className={classNames("topBar", { "--empty": !showOnMobile })}>
          <div
            id={READER_TOP_PANEL_PORTAL_ID}
            className={classNames("topPanelWrap", { hide: !showOnMobile })}>
            {renderMainHeader()}
            {topPanelTools}
          </div>
        </div>
      );
    } else if (isTablet) {
      return (
        <div style={style} className={classNames("topBar", { "--empty": !isTopPanelVisible })}>
          <div
            id={READER_TOP_PANEL_PORTAL_ID}
            className={classNames("topPanelWrap", { hide: !isTopPanelVisible })}>
            {renderMainHeader()}
            {topPanelTools}
          </div>
          {topTitle && (
            <div className={classNames("tp-main", { hide: !isTopPanelVisible })}>
              <BreadCrumbs />
            </div>
          )}
        </div>
      );
    }
  }

  return (
    <div style={style} className={classNames("topBar", { "--empty": !isTopPanelVisible })}>
      <div
        id={READER_TOP_PANEL_PORTAL_ID}
        className={classNames("topPanelWrap", { hide: !isTopPanelVisible })}>
        {renderMainHeader()}
        <div className="tp-main">
          {fullscreen ? null : topTitle ? <BreadCrumbs /> : <span />}
          <span className="tpMenuWrap">
            {onPlusClick && (
              <TextButton className="add-language-button" onClick={(e) => onPlusClick(e)}>
                <IconView icon={EgwWebFont.plus}  className="tpMenuIcon"/>
                <span className={classNames("", { "add-language-margin": showListIcons })}>
                  {t("language")}
                </span>
              </TextButton>
            )}
            {isShowBtnFiltersBookType && (
              <>
                <IconButton
                  icon={EgwWebFont["reading-history"]}
                  title={t(currentFolder.showBtnBooksFilter ? "read" : "readingHistory")}
                  className="tpMenuIcon"
                  onClick={() => handleHistoryFilter(true)}
                  active={showReadingHistory}
                />
                <IconButton
                  icon={EgwWebFont.volume}
                  title={t(
                    currentFolder.showBtnBooksFilter ? "audiobooks" : "listenHistory",
                  )}
                  className="tpMenuIcon"
                  onClick={() => handleHistoryFilter()}
                  active={showListenHistory}
                />
              </>
            )}
            {currentFolder.showBtnBooksFilter && <ButtonBooksFilters />}
            {((!showListIcons && isAbbr) || currentFolder.showAZicon) && (
              <IconButton
                icon={EgwWebFont.AZ}
                className="tpMenuIcon"
                title={t("A/Z")}
                onClick={handleAZ}
                active={isShowAZ}
              />
            )}
            {showListIcons && isLibrary && (
              <IconButton
                icon={sortByRecent ? EgwWebFont["sort-by-recent"] : EgwWebFont.AZ}
                className="tpMenuIcon"
                title={sortByRecent ? t("sortByRecent") : t("A/Z")}
                onClick={() =>
                  dispatch(actionUpdateSetting(Settings.sortByRecent.id, !sortByRecent))
                }
                active={true}
              />
            )}
            {(currentFolder.showBtnChangeBookshelf ?? showListIcons) && (
              <IconButton
                icon={EgwWebFont.bookshelf}
                className="tpMenuIcon"
                title={t("bookShelf")}
                onClick={() => handleBookshelfChange()}
                active={showBookshelf}
              />
            )}
            {showListIcons && (
              <IconButton
                icon={EgwWebFont.grid}
                className="tpMenuIcon"
                title={t("grid")}
                onClick={() => handleListChange(true)}
                active={isGrid}
              />
            )}
            {showListIcons && !isWorkspace && (
              <IconButton
                icon={EgwWebFont.list}
                className="tpMenuIcon"
                title={t("list")}
                onClick={() => handleListChange(false)}
                active={!isGrid}
              />
            )}
            <SearchPlusButton />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopPanel;
