import React  from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { I18nextProvider } from "react-i18next";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import MainApp from "./MainApp";
import i18n from "./i18n/i18n";
import rootReducer from "./redux";
import rootSaga from "./redux/rootSaga";
import UnsupportedPage from "./react-pages/UnsupportedPage";
import TextModePage from "./react-pages/TextModePage";
import InitPage from "./react-pages/InitPage";

import { PopupProvider } from "./components/popup/PopupContext";
import { DialogProvider } from "./components/dialog/DialogContext";
import { ShareProvider } from "./components/shareHolder/ShareHolder";
import { TooltipProvider } from "./components/popup/TooltipContext";
import { NavigationProvider } from "./components/NavigationContext";
import { AudioProvider } from "./components/bookPlayer/AudioContext";
import SearchProvider from "./components/search/SearchContext";
import ModalWrap from "./components/popup/ModalWrap";
import Toaster from "./components/popup/Toaster";
import { isProduction } from "./shared/utils/assembly";

import "src/what-input/what-input";

export let store;
let sagaMiddleware;

if (!isProduction()) {
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) || compose;

  sagaMiddleware = createSagaMiddleware({ trace: true });
  store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
  window.store = store;
} else {
  sagaMiddleware = createSagaMiddleware();
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
}

sagaMiddleware.run(rootSaga);

const App = () => {
  return (
    <BrowserRouter>
      <CookiesProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <React.Fragment>
              <Toaster />
              <UnsupportedPage>
                <InitPage>
                  <TooltipProvider>
                    <ShareProvider>
                      <NavigationProvider>
                        <SearchProvider>
                          <DialogProvider>
                            <PopupProvider>
                              <AudioProvider>
                                <MainApp />
                                <ModalWrap />
                              </AudioProvider>
                            </PopupProvider>
                          </DialogProvider>
                        </SearchProvider>
                      </NavigationProvider>
                    </ShareProvider>
                  </TooltipProvider>
                </InitPage>
                <TextModePage />
              </UnsupportedPage>
            </React.Fragment>
          </I18nextProvider>
        </Provider>
      </CookiesProvider>
    </BrowserRouter>
  );
};

export default App;
