import React from "react";

const AboutUsPage = () => (
  <div className="long-content">
    <h2>
      <strong>About The Ellen G. White® Estate, Incorporated.</strong>
    </h2>
    <p>
      The Ellen G. White® Estate, Incorporated is an organization created by the last will and
      testament of Ellen G. White to act as her agent in the custody of her writings, handling her
      properties, &quot;conducting the business thereof,&quot; &quot;securing the printing of new
      translations,&quot; and the &quot;printing of compilations from my manuscripts.&quot; Her
      will, dated Feb. 9, 1912 (printed in its entirety as Appendix N in Messenger of the Lord, by
      Herbert E. Douglass) named five church leaders to serve as a board of trustees: Arthur G.
      Daniells, president of the General Conference; William C. White, her son; Clarence C. Crisler,
      a secretary; Charles H. Jones, manager of the Pacific Press; and Francis M. Wilcox, editor of
      the Review and Herald. Four of the five were members of the Executive Committee of the General
      Conference.
    </p>
    <p>
      Appointment of the trustees was for life, Ellen White providing that &quot;if a vacancy shall
      occur for any reason among said trustees, or their successors, a majority of the surviving or
      remaining trustees are hereby empowered and directed to fill such vacancy by the appointment
      of some other fit person&quot;; or if this provision were to fail, the General Conference
      Executive Committee should appoint someone to fill such a vacancy. The will dedicated the
      major portion of the existing and potential royalty incomes from her books to the work of the
      trustees. (For additional information, see Appendix B, &quot;The Settlement of Ellen G.
      White`s Estate,&quot; in volume 6 of A. L. White`s biography of Ellen White, Ellen G. White:
      The Later Elmshaven Years.)
    </p>
    <p>
      At the death of Ellen White, July 16, 1915, this self-perpetuating board began to function. It
      soon sold Ellen White&apos;s real estate, consisting mainly of Elmshaven, her home property
      near St. Helena, California, then began the continued care of her literary properties. Under
      the terms of the will, such responsibilities fell into three areas: (1) possession of the
      copyrights to her writings and the care and promotion of her books in the English language;
      (2) preparation of manuscripts for, and the promotion of the translation and publication of
      her writings in other languages; and (3) custody of the files of manuscripts and other files,
      and the selection of matter from the Ellen G. White manuscript files for publication. The
      board now carries a fourth responsibility, which has developed naturally through the
      years--acquainting Seventh-day Adventists and others with Mrs. White and her work.
    </p>
    <h2>Organization of the Estate</h2>
    <h3>
      <strong>The Original Board</strong>
    </h3>
    <p>
      When the board was organized in 1915, A. G. Daniells served temporarily as president, followed
      by F. M. Wilcox from 1915-1922. A. G. Daniells again served as president from 1922-1935. The
      secretaryship, after being held for a short time by C. C. Crisler, passed to W. C. White, the
      only member of the board devoting full time to the work of the trustees. He filled this office
      until his death in 1937. From 1915 to 1937 the work was carried on at Elmshaven in a rented
      office building with a vault that was used to house the Ellen G. White materials.
    </p>
    <p>
      During the 19 years they worked together, the original members, in addition to routine tasks,
      (1) published 10 posthumous compilations (for an annotated bibliography of the Ellen G. White
      books, see White, Ellen G., Writings of, and Appendix D in the Comprehensive Index to the
      Writings of Ellen G. White); (2) produced an 865-page Comprehensive Index to the Writings of
      Ellen G. White, published in 1926; (3) carried forward the thorough indexing of the Ellen G.
      White manuscript files; and (4) in counsel with the leading officers of the General Conference
      in 1933 and 1934, laid the foundation for continuing the trusteeship in perpetuity. The steps
      taken to ensure the perpetuation of the trusteeship were: (a) in 1933 the trustees, as the
      constituency, formed a corporation under the laws of the state of California &quot;to carry
      out and perform the provisions of the charitable trust created by the last will and testament
      of Ellen G. White deceased&quot;; (b) the General Conference agreed to provide adequate
      financial support for the work of the trustees in the form of an annual budget; the trustees,
      in turn, assigned to the General Conference all royalty incomes produced by the Ellen G. White
      books; (c) it was agreed to move the property and work of the trustees at some appropriate
      future time to Washington, D.C., thus placing it close to the world headquarters of the
      church.
    </p>
    <h3>
      <strong>Period of Transition</strong>
    </h3>
    <p>
      When three of the original trustees died--one in 1935 and two in 1936--the vacancies were
      filled in harmony with the provisions of the will and the bylaws of the 1933 corporation. The
      full-time secretary, W. C. White, died on Sept. 1, 1937. He was replaced by his son, Arthur L.
      White, who for nine years had served as his secretary and for four years as assistant
      secretary of the White Estate. The work of the White Estate was moved to the General
      Conference, Washington, D.C., in January, 1938.
    </p>
    <h3>
      <strong>Present Organization</strong>
    </h3>
    <p>
      With the demands upon them increasing steadily with the growth of the church and numerous
      constituencies to be represented, in 1950 the trustees increased the board&apos;s membership
      from five to seven, and in 1958 amended the bylaws of the corporation to provide for a
      constituency and board of nine, seven to be life members and two to be elected for a term
      corresponding to that of General Conference elected personnel (originally four years, but now
      five). In 1970 the board was increased to 11; in 1980, to 13; and, in 1985, to 15. The number
      of life members is currently five. At quinquennial meetings the board also elects the
      secretary and associate secretaries, as well as officers of the corporation, as provided for
      in the bylaws.
    </p>
    <h3>
      <strong>Relationship to General Conference</strong>
    </h3>
    <p>
      Through the years a close working relationship has obtained between the White Estate trustees
      and the General Conference. Most of the trustees are members of the General Conference
      Executive Committee. Various matters, such as promoting the overseas publication of the Ellen
      G. White material, appropriation of funds to assist in the foreign language publication of
      Ellen G. White books, and overall planning of Spirit of Prophecy promotion, including
      preparation of materials for the annual Spirit of Prophecy Sabbath, although intimately
      related to the work of the White trustees, are beyond the sphere of their direct
      responsibility. These are handled by the General Conference Committee through a sub-committee
      known as the Spirit of Prophecy Committee. This committee includes several of the White
      trustees. The duties of this subcommittee and the working relationship between the General
      Conference Committee and the White Estate are currently set forth in a joint agreement adopted
      by the General Conference Committee and the White Estate trustees on Oct. 10, 1957. There is
      an interlocking and at times overlapping of responsibilities; nevertheless, a smooth and
      efficient working relationship between the two organizations is maintained.
    </p>
    <h3>
      <strong>The Work of the White Estate.</strong>
    </h3>
    <p>The paid staff members:</p>
    <ol>
      <li>
        safeguard and maintain the records in the custody of the trustees, and the indexes thereto,
        in such a manner as to serve the church;
      </li>
      <li>handle the copyrights to the Ellen G. White works;</li>
      <li>
        conduct such research in these works and the related historical materials as may be called
        for;
      </li>
      <li>
        respond to questions that may be directed to the White Estate in personal interviews and in
        a worldwide correspondence;
      </li>
      <li>
        assemble, when authorized by the trustees, materials for compilations from Ellen G.
        White&apos;s writings;
      </li>
      <li>
        foster, in conjunction with the Spirit of Prophecy Committee, the ever-widening publication
        of these writings in various languages and at times make selections or abridgments as called
        for and authorized;
      </li>
      <li>
        fill assignments in church, institutional, and field visitation as the needs and best
        interests of the advancing work of the church require;
      </li>
      <li>
        conduct tours of historical sites of denominational interest, especially in the New England
        states; and
      </li>
      <li>prepare articles, correspondence lessons, and text materials.</li>
    </ol>
    <p>
      Productions of special value to the church include the four-volume{" "}
      <span className="publication_reference">
        Comprehensive Index to the Writings of Ellen G. White
      </span>{" "}
      (1962, 1992); the six-volume facsimile reprints of the Ellen G. White{" "}
      <span className="publication_reference">Present Truth</span> and{" "}
      <span className="publication_reference">Review and Herald</span> articles; the four-volume
      Ellen G. White <span className="publication_reference">Signs of the Times</span> articles; the
      Ellen G. White <span className="publication_reference">Youth`s Instructor</span> articles; the
      Periodical Resource Collection volumes; the six-volume biography of Ellen G. White, by A. L.
      White;{" "}
      <span className="publication_reference">
        and The Published Writings of Ellen G. White on Compact Disc
      </span>{" "}
      (CD-ROM), a tool of inestimable value to users of computers.
    </p>
    <h3>
      <strong>Ellen G. White Estate Branch Offices and Research Centers</strong>
    </h3>
    <p>
      The White Estate maintains three branch office research centers--at Andrews University,
      Berrien Springs, Michigan, at Loma Linda University, Loma Linda, California, and at Oakwood
      College, Huntsville, Alabama. These offices contain duplicates of the Ellen G. White documents
      and other historical materials housed in the main office at General Conference headquarters.
      Beginning in 1974, the White Estate also has set up Ellen G. White-SDA Research Centers on the
      campuses of 19 Seventh-day Adventist colleges and universities outside North America, in the
      countries of Argentina, Australia, Brazil, Costa Rica, England, France, India, Jamaica, Kenya,
      Korea, Mexico, Nigeria, Philippines, Peru, Puerto Rico, Russia, and South Africa.
    </p>
    <h3>
      <strong>Use of Ellen G. White Manuscript Materials</strong>
    </h3>
    <p>
      During the later years of her life, Ellen G. White often drew upon her unique 50,000-page
      manuscript file in the preparation of published works. The White trustees have continued to
      draw upon this for the compilations made since her death. These manuscripts constitute an
      invaluable basic file of historical records and of counsel to the church. The copyright of
      these manuscripts resides solely with the White trustees.
    </p>
    <p>
      While all of Ellen White&apos;s writings are available for research, the unpublished letters,
      manuscripts, and other materials in the Ellen G. White files do not constitute a public
      archive. The sacred nature of the files generally and the confidential nature of many of the
      communications in the files require that they be cared for and used responsibly. Even
      manuscripts whose primary value is historical in nature must not be used in a solely secular
      manner. &quot;Spiritual things are spiritually discerned&quot; (The Desire of Ages, p. 55; see
      also 1 Cor. 2:14). Because of this, during the first few decades following Ellen White`s
      death, careful policies governing the use and release of unpublished materials were set up,
      ultimately resulting in the publication of 21 volumes known as Manuscript Releases. In recent
      years the earlier restrictive policies have been adapted to accommodate the needs of increased
      research.
    </p>
    <h3>
      <strong>Officers of the White Estate Board</strong>
    </h3>
    <p>
      The two chief officers of the board are the chair and the secretary. The secretary serves not
      only as secretary of the board but as president of the organization, and is responsible for
      the day-to-day operations of the office and staff. Beginning in 1915, when the terms of Ellen
      G. White&apos;s will went into effect, the White Estate has had 11 chairs and six secretaries.
      Chairs: A. G. Daniells, 1915; F. M. Wilcox, 1915-1922; A. G. Daniells, 1922-1935; J. E.
      Fulton, 1935-1936; J. L. Shaw, 1936-1937; F. M. Wilcox, 1938-1944; M. E. Kern, 1944-1951; D.
      E. Rebok, 1952; A. V. Olson, 1952-1963; F. D. Nichol 1963-1966; W. P. Bradley, 1966-1980;
      Kenneth H. Wood, 1980-2008; Don C. Schneider, 2008-. Secretaries: Clarence C. Crisler,
      1915-1917; William C. White, 1917-1937; Arthur L. White, 1937-1978; Robert W. Olson,
      1978-1990; Paul A. Gordon, 1990-1995; Juan Carlos Viera, 1995-2000; James R. Nix, 2000- .
    </p>
    <p>
      [Material adapted slightly from the Seventh-day Adventist Encyclopedia, second revised edition
      (Hagerstown, Md.: Review and Herald Publishing Association, 1996), vol. A-L, pp. 503-506.]
    </p>
    <h3>
      <strong>Ellen G. White Estate Contact Information and Address</strong>
    </h3>
    <h3>
      <strong>Address</strong>
    </h3>
    <p>
      Ellen G. White Estate
      <br />
      General Conference of Seventh-day Adventists
      <br />
      12501 Old Columbia Pike
      <br />
      Silver Spring Maryland 20904
      <br />
      United States of America
    </p>
    <h3>
      <strong>Telephone</strong>
    </h3>
    <p>+1 301-680-6540</p>
    <h3>
      <strong>Email</strong>
    </h3>
    <p>
      For general inquiries, <a href="mailto:mail@whiteestate.org">mail@whiteestate.org</a>
      .&nbsp;Before sending an email, please check our{" "}
      <a target="_blank" rel="noopener noreferrer" href="http://ellenwhite.org/answers">
        FAQ
      </a>{" "}
      section, as your question may already be answered there.
    </p>
  </div>
);

export default AboutUsPage;
