import { calculateTextDimension,  Position } from "../../../utils/Utils";


export const calculateTooltipView = (message, targetRect, parentRect, position) => {
  const {
    width: tooltipWidth,
    height: tooltipHeight
  } = calculateTextDimension(message, ["tooltip-view"]);
  const { devicePixelRatio,  innerWidth, innerHeight } = window;
  const browserZoomLevel = Math.round(devicePixelRatio * 100);
  const horizontalTooltipOffset = 5;
  const topTooltipOffset = 15;
  const defTopPosition = targetRect.y + targetRect.height + topTooltipOffset;
  let left = targetRect.x - horizontalTooltipOffset;
  let top = defTopPosition;
  let pointerClass = "pointer-left-top";

  const rectX = parentRect?.x || targetRect.x;
  const tooltipOnTop = defTopPosition + tooltipHeight > innerHeight;
  const alignRight = rectX + tooltipWidth > innerWidth;
  const isAlignRight = alignRight || position === Position.RIGHT;

  if (isAlignRight) {
    left = rectX - tooltipWidth + horizontalTooltipOffset;
  }

  if (tooltipOnTop || position === Position.TOP) {
    top = targetRect.y - topTooltipOffset - tooltipHeight;
  }

  if (browserZoomLevel <= 50 || innerWidth >= 2500) {
    top += topTooltipOffset * 2.5;
  }

  if (parentRect && !isAlignRight) {
    // const parentRect = getBoundingClientRect(parent);
    left = parentRect.left > left ? parentRect.left : left;
    left = parentRect.right < left + tooltipWidth ? parentRect.right - tooltipWidth : left;

    const tooltipOnTop = defTopPosition + tooltipHeight > parentRect.bottom;

    if (tooltipOnTop || position === Position.TOP) {
      top = targetRect.y - topTooltipOffset - tooltipHeight;
    }
  }

  if (left < 0) {
    left = 0;
  }

  const pointerAtLeft = left + tooltipWidth / 2 > targetRect.x - horizontalTooltipOffset;  
  if (top + tooltipHeight > targetRect.y + targetRect.height) {
    pointerClass = pointerAtLeft ? "pointer-left-top" : "pointer-right-top";
  } else {
    pointerClass = pointerAtLeft ? "pointer-left-bottom" : "pointer-right-bottom";
  }

  return { left, top, pointerClass };
};
