import { useMemo } from "react";
import { createSelector } from "reselect";
import { getActiveLangs } from "../utils/AdvSearchTypes";
import { findNode, getLanguageDefault } from "../utils/Utils";
import { useSelector } from "react-redux";
import { getBookId } from "../shared/utils/content";
import {
  findParaById,
  getContentByParaId,
  getReaderProgressAndChapterInfo,
  normalizeParaId,
} from "src/components/reader/ReaderUtils";
import { TreeMenuMode } from "../utils/TreeUtils";
import { sortBookByLabel, sortBooksByDateMyLibrary } from "src/utils/FilterBooksUtils";

export const activeLangsSelector = createSelector(
  (state) => state.folderTree.checked,
  (state) => state.mainTree.mainTree,
  (state) => state.mainTree.bookLangMap,
  (checked, mainTree, bookLangMap) => getActiveLangs(checked, mainTree, bookLangMap),
);

export const bookInLibrarySelector = createSelector(
  (state) => state.history.allHistory,
  (_, id) => id,
  (allHistory, id) => {
    const bookId = getBookId(id);
    const book = allHistory.find((item) => item.id === bookId);
    if (book) {
      return book.inLibrary;
    }
    return false;
  },
);

export const libraryBooksSelector = createSelector(
  (state) => state.history.allHistory,
  (state) => state.settings.sortByRecent,
  (allHistory, sortByRecent) => {
    const inLibraryBooks = allHistory.filter((item) => item.inLibrary);
    if (sortByRecent) {
      sortBooksByDateMyLibrary(inLibraryBooks);
    } else {
      sortBookByLabel(inLibraryBooks);
    }
    return inLibraryBooks;
  },
);

export const getLoaderByIdSelector = createSelector(
  (state) => state.system.loaders,
  (_, loadingId) => loadingId,
  (loaders, loadingId) => loaders.some((id) => id === loadingId),
);

export const useLoader = (loadingId) => {
  return useSelector((state) => getLoaderByIdSelector(state, loadingId));
};

export const useBookContentAndOtherInfo = (_paraId) => {
  const bookId = getBookId(_paraId);
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const book = useMemo(() => {
    return findNode(bookId, mainTree);
  }, [mainTree, bookId]);
  const paraId = normalizeParaId(_paraId, book.chapters);
  const paragraphs = useParagraphSelector(paraId);

  const para = useMemo(() => {
    return findParaById(paraId, mainTree, paragraphs);
  }, [mainTree, paragraphs, paraId]);

  const content = useMemo(() => {
    return getContentByParaId(paraId, paragraphs);
  }, [paragraphs, paraId]);

  const readerProgressAndChapterInfo = useMemo(() => {
    return getReaderProgressAndChapterInfo(book, para, paraId);
  }, [paraId, book, para]);

  return {
    content,
    book,
    ...readerProgressAndChapterInfo,
  };
};

/**
 * // TODO: Optimize.
 * @description Selects books by ids from store {mainTree} or {books}
 */
export const useBooksSelect = (bookIds) => {
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const books = useSelector((state) => state.mainTree.books);

  return useMemo(() => {
    const bookList = [];

    let book, bookId;
    for (let i = 0; i < bookIds.length; i++) {
      bookId = getBookId(bookIds[i]);
      book = books.find((book) => book.id === bookId);

      if (!books) {
        book = findNode(bookId, mainTree);
      }

      if (book) {
        bookList.push(book);
      }
    }

    return bookList;
  }, [bookIds, mainTree, books]);
};

/**
 * hook for get book by id (book or chapter/paragraph)
 */
export const useBookSelect = (id) => {
  const bookId = getBookId(id);

  const books = useSelector((state) => state.mainTree.books);
  const mainTree = useSelector((state) => state.mainTree.mainTree);

  const bookFromBooks = useMemo(() => {
    return bookId ? books.find((book) => book.id === bookId) : undefined;
  }, [books, bookId]);

  return useMemo(() => {
    if (bookFromBooks) {
      const bookOut = bookFromBooks;

      // TODO impl get from bookToc reducer for each book
      const book = findNode(bookId, mainTree);

      if (book.children) {
        bookOut.children = book.children;
        bookOut.chapters = book.chapters;
      }

      return bookOut;
    }

    return undefined;
  }, [bookId, mainTree, bookFromBooks]);
};

/**
 *
 * @param {(string|number)} id
 * @returns boolean
 */
export const useRTLBook = (id) => {
  const book = useBookSelect(id);
  const mainTree = useSelector((state) => state.mainTree.mainTree);

  return useMemo(() => {
    let isRtl = false;
    if (book) {
      const lang = mainTree.find((lang) => lang.id === book.lang);
      if (lang) {
        isRtl = lang.direction === "rtl";
      }
    }
    return isRtl;
  }, [mainTree, book]);
};

/**
 * hook for get bookcontent by id (book or chapter/paragraph)
 */
export const useParagraphSelector = (id) => {
  return useSelector((state) => state.paragraphReducer.paragraphs[getBookId(id)]);
};

export const useDefLang = () => {
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  return getLanguageDefault(libraryLanguages);
};

export const searchLanguagesSelector = createSelector(
  (state) => state.search.searchQueryLangs,
  (state) => state.search.searchParams,
  (state) => state.mainTree.mainTree,
  (searchQueryLangs, searchParams, mainTree) => {
    if (Object.keys(searchQueryLangs).length === 0) {
      return [];
    }

    let useChecked = true;
    const { folders } = searchParams;
    // when perform simple search without
    // any checked langs - list includes parameter all at the bottom
    // if this parametr includes - dont show any lang as checked
    if (folders.includes("all")) {
      useChecked = false;
    }
    return mainTree
      .filter((item) => searchQueryLangs[item.code] !== undefined)
      .map((item) => ({
        id: item.id,
        label: item.label,
        langCount: searchQueryLangs[item.code],
        checked: useChecked && folders.includes(item.code),
      }))
      .sort((a, b) => b.langCount - a.langCount);
  },
);

export const currentTreeReducerSelector = createSelector(
  (state) => state.settings.treeMenuMode,
  (state) => state.folderTree,
  (state) => state.categoryTree,
  (state) => state.titleTree,
  (treeMenuMode, folderTree, categoryTree, titleTree) => {
    const isTreeFolders = treeMenuMode === TreeMenuMode.FOLDERS;

    if (isTreeFolders) {
      return folderTree;
    }

    if (treeMenuMode === TreeMenuMode.CATEGORIES) {
      return categoryTree;
    }

    return titleTree;
  },
);

export const useLangs = () => {
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const isLogin = useSelector((state) => state.system.isLogin);

  const langList = useMemo(() => {
    return isLogin ? mainTree.filter((item) => libraryLanguages.includes(item.code)) : mainTree;
  }, [isLogin, mainTree, libraryLanguages]);

  return langList;
};

/**
 *
 * @param {string} query
 * @param {boolean} onlyMyBible
 * @returns {ShortcutParams[]}
 */
export const useShortcut = (query, onlyMyBible = true) => {
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const trimQuery = (query || searchQuery).trim();
  const shortcuts = useSelector((state) => state.search.shortcuts);
  const baseBible = useSelector((state) => state.settings.baseBible);
  const shortcut = shortcuts[trimQuery];
  if (onlyMyBible && shortcut) {
    const bibleBook = shortcut.find((item) => item.bookId === baseBible);
    if (bibleBook) {
      return [bibleBook];
    }
  }
  return shortcut;
};
