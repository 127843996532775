import React from "react";
import PropTypes from "prop-types";
import { Button } from "../views";
import classnames from "classnames";

const DialogButtons = ({
  onClickNegative,
  onClickPositive,
  negativeText,
  positiveText,
  disabled,
  className,
}) => {
  return (
    <div className={classnames("dialog-btn-wrap", className)}>
      {onClickNegative && (
        <Button onClick={onClickNegative} type="secondary" className="dialog-btn">
          {negativeText}
        </Button>
      )}
      <div className="pub-row-space"></div>
      {onClickPositive && (
        <Button onClick={onClickPositive} disabled={disabled} className="dialog-btn">
          {positiveText}
        </Button>
      )}
    </div>
  );
};

DialogButtons.propTypes = {
  negativeText: PropTypes.string,
  positiveText: PropTypes.string,
  onClickNegative: PropTypes.func,
  onClickPositive: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default DialogButtons;
