import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import "./Backdrop.scss";

const Backdrop = ({ children, className, onClick }) => {
  return (
    <div className={classNames("backdrop", className)} onClick={onClick}>
      {children}
    </div>
  );
};

Backdrop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Backdrop;
