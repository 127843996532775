import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { getBoundingClientRect } from "../../utils/Utils";
import DropDown from "../dropDown/DropDown";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { useRemToPx } from "../../hooks/viewModeHooks";

import "./View.scss";

const Select = ({
  dropDownStyle,
  containerStyle,
  options,
  value,
  onGetTitle,
  onRenderTitle,
  disabled,
  onChange,
  onDropDownClose,
  parentNode,
  label,
}) => {
  const select = useRef(null);
  const dropDownRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [startNavigation, setStartNavigation] = useState(false);
  const [position, setPosition] = useState({});
  const remToPx = useRemToPx();

  const maxHeight = remToPx(11.1);
  
  useEffect(() => {
    if (!expanded) {
      return;
    }
    const { bottom, height } = select.current.getBoundingClientRect();
    let parentRect = { left: 0, top: 0, right: window.innerWidth, bottom: window.innerHeight };

    if (parentNode && parentNode.current) {
      parentRect = getBoundingClientRect(parentNode.current);
    }
    let currentPosition = {};

    if (bottom + maxHeight >= parentRect.bottom) {
      currentPosition.bottom = height;
    } else {
      currentPosition.top = height;
    }
    setPosition(currentPosition);
  }, [expanded, parentNode]);

  const handleChange = (value, index) => {
    setExpanded(!expanded);
    if (value) {
      onChange(value, index);
    }
  };

  const handleDropDownClose = () => {
    setExpanded(!expanded);
    if (onDropDownClose) {
      onDropDownClose();
    }
  };

  const renderComponent = () => {
    if (onRenderTitle) {
      return onRenderTitle(onGetTitle(value), value);
    }
    return <span>{onGetTitle(value)}</span>;
  };

  const renderLabel = () => {
    return <span className="select-label">{label}</span>;
  };

  const onKeyHandler = (event) => {
    if (isEventKey(event, KeyCodes.enter)) {
      handleChange();
      return;
    }
    if (isEventKey(event, KeyCodes.esc)) {
      if (expanded) {
        event.nativeEvent.stopImmediatePropagation();
        setExpanded(false);
      }

      return;
    }
    if (dropDownRef.current) {
      if (isEventKey(event, KeyCodes.upArrow) || isEventKey(event, KeyCodes.downArrow)) {
        event.preventDefault();
        setExpanded(true);
        setStartNavigation(true);
        return;
      }
    }

    setStartNavigation(false);

    if (!expanded) {
      setExpanded(true);
    }
  };

  return (
    <div
      tabIndex={disabled ? -1 : 0}
      className={classNames("select-container", containerStyle, expanded ? "active" : "")}
      ref={select}
      onClick={() => handleChange()}
      onKeyDown={onKeyHandler}>
      {label && renderLabel()}
      {renderComponent()}
      <DropDown
        ref={dropDownRef}
        focusOnExpand={false}
        focusFirstOption={startNavigation}
        expanded={expanded}
        options={options}
        dropDownClassName={dropDownStyle}
        maxHeight={maxHeight}
        position={position}
        value={value}
        onGetTitle={onGetTitle}
        onSelect={handleChange}
        onDropDownClose={handleDropDownClose}
      />
    </div>
  );
};

Select.defaultProps = {
  onGetTitle: (value) => value,
};

Select.propTypes = {
  dropDownStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  onGetTitle: PropTypes.func,
  onRenderTitle: PropTypes.func,
  onChange: PropTypes.func,
  onDropDownClose: PropTypes.func,
  parentNode: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;
