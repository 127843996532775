import {
  ACTION_FULLSCREEN_BOOK,
  ACTION_RESIZE,
  ACTION_LANGUAGE,
  ACTION_DELETE_LIBRARY_LANGUAGE,
  ACTION_SCROLL_DIRECTION_UPDATE,
  ACTION_UPDATE_SHOW_TEXT_MODE_POPUP,
  READER_SEARCH_FOCUS,
  READER_LAYOUT_WRAP_HEIGHT,
  RESET_DRAGNDROP,
  SET_DRAGNDROP,
} from "./constants";

export const actionChangeLang = (data) => ({
  type: ACTION_LANGUAGE, data
});

export const actionSetReaderSearchFocus = (data) => ({
  type: READER_SEARCH_FOCUS, data
});

export const actionSetReaderLayoutWrapHeight = (data) => ({
  type: READER_LAYOUT_WRAP_HEIGHT, data
});

export const actionUpdateDragndrop = (data) => ({
  type: SET_DRAGNDROP, data
});

export const actionResetDragndrop = () => ({
  type: RESET_DRAGNDROP,
});

export const actionUpdateResize = (data) => ({
  type: ACTION_RESIZE, data
});

export const actionFullscreenId = (data) => ({
  type: ACTION_FULLSCREEN_BOOK, data
});




export const actionDeleteLibraryLanguage = (data) => ({
  type: ACTION_DELETE_LIBRARY_LANGUAGE, data
});

export const actionScrollDirectionUpdate = (data) => ({
  type: ACTION_SCROLL_DIRECTION_UPDATE, data
});

export const actionUpdateShowTextModePopup = (data) => ({
  type: ACTION_UPDATE_SHOW_TEXT_MODE_POPUP, data
});
