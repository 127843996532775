import React, { useState, useEffect } from "react";
import { default as LibDatePicker } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { format, isAfter, getYear, getMonth, parseISO, isSameDay } from "date-fns";
import PropTypes from "prop-types";
import { fetchMonthCalendar } from "../subscriptions/subscriptions.actions";
import { ChevronLeftIcon, ChevronRightIcon } from "../../assets/CommonIcons";
import { IconButton } from "../views";

import "react-datepicker/dist/react-datepicker.css";
import "./View.scss";
import "../dialog/Dialog.scss";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";

const picker = {
  container: ".react-datepicker",
  days: ".react-datepicker__day:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--disabled)",
  selectedDays: ".react-datepicker__day--selected"
};

const DatePicker = ({ 
  date, 
  minDate, 
  withMaxDate, 
  withMarks, 
  onDateChange, 
}) => {
  const dispatch = useDispatch();
  const [innerDate, setInnerDate] = useState(date || minDate);
  const [yearDate, setYearDate] = useState(date);
  const [updateDays, setUpdateDays] = useState(false);

  const calendar = useSelector((state) => state.subscriptions.calendar);

  useEffect(() => {
    fetchMonthData(date);
  }, []);

  useEffect(() => {
    handlerForAccessibility();
  }, [updateDays]);

  const handlerForAccessibility = () => {
    const datePicker = document.querySelector(picker.container);
    const days = datePicker?.querySelectorAll(picker.days);

    days?.forEach((item) => {
      // set tabIndex for all elements;
      item.setAttribute("tabindex", "0");

      item.addEventListener("keydown", (event) => {
        if (isEventKey(event, KeyCodes.enter)) {
          if (item.childNodes.length && !item.classList.contains(picker.selectedDays)) {
            item.childNodes[0].click();
          }
        }
      });
    });
  };

  const fetchMonthData = (date) => {
    if (!withMarks) {
      return;
    }
    const year = getYear(date);
    const month = getMonth(date) + 1;
    if (!(year in calendar) || !(month in calendar[year])) {
      dispatch(fetchMonthCalendar({ year, month }));
    }
  };

  const handleDateChange = (newDate) => {
    if (onDateChange) {
      onDateChange(newDate);
    }
    setInnerDate(newDate);
  };

  const handleMonthChange = (newDate) => {
    const prevYear = yearDate.getFullYear();
    const nextYear = newDate.getFullYear();

    if (prevYear !== nextYear) {
      setYearDate(newDate);
    }
    fetchMonthData(newDate);
    setInnerDate(newDate);
    onUpdateDays();
  };

  const onUpdateDays = () => {
    setUpdateDays(true);

    setTimeout(() => {
      setUpdateDays(false);
    }, 100);
  };

  const renderHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled }) => {

    const prevStyle = prevMonthButtonDisabled ? "datepicker-header-disabled" : "";
    const nextStyle = nextMonthButtonDisabled ? "datepicker-header-disabled" : "";

    return <div className="datepicker-header-container">
      <IconButton icon={ChevronLeftIcon} className={prevStyle} onClick={decreaseMonth} />
      <span className="datepicker-header-title">{format(date, "MMMM yyyy")}</span>
      <IconButton icon={ChevronRightIcon} className={nextStyle} onClick={increaseMonth} />
    </div>;
  };

  const handleDayClick = (date) => {
    if (withMarks) {
      handleDateChange(date);
    }
  };
  
  const renderDay = (day, date) => {
    const calendarDate = format(date, "yyy-MM-dd");
    const currentYear = getYear(innerDate);
    const currentMonth = getMonth(innerDate) + 1;

    let markStyle;
    if (withMarks && calendar[currentYear]) {
      const monthDates = calendar[currentYear][currentMonth];
      if (monthDates) {
        const dateInfo = monthDates.find((dateItem) => dateItem.date === calendarDate);

        if (dateInfo && !isAfter(parseISO(dateInfo?.date), new Date())) {
          const { unread, total } = dateInfo;
          if (isSameDay(date, innerDate)) {
            markStyle = total === 0 ? "" : " mark-selected";
          } else {
            markStyle = total === 0 ? "" : unread === 0 ? " mark-read" : " mark-unread";
          }
        }
      }
    }

    return <div className="datepicker-day-container" onClick={() => handleDayClick(date)}>
      {day}
      {markStyle && <div className={"datepicker-mark" + markStyle} />}
    </div>;
  };

  const props = {};
  if (withMaxDate) {
    props.maxDate = new Date();
  }

  return <React.Fragment>
    <div className="date-picker-container">
      <div className="date-picker-content">
        <LibDatePicker
          {...props}
          inline
          selected={innerDate}
          minDate={minDate}
          renderCustomHeader={(params) => renderHeader(params)}
          renderDayContents={renderDay}
          onChange={(date) => handleDateChange(date)}
          onMonthChange={(date) => handleMonthChange(date)} />
      </div>
    </div>
  </React.Fragment>;
};

DatePicker.propTypes = {
  date: PropTypes.object,
  minDate: PropTypes.object,
  onDateChange: PropTypes.func,
  withMaxDate: PropTypes.bool,
  withMarks: PropTypes.bool,
};

export default DatePicker;

