import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StudyCenterActions } from "../studyCenter.actions";
import { useDialogContext } from "../../dialog/DialogContext";
import ScFoldersDialog from "./ScFoldersDialog";
import { useAuth } from "src/hooks";
import { RootFolder } from "../studyCenter.constants";

const ScFolder = ({ folderId, onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isUserEditor } = useAuth();
  const { showDialog } = useDialogContext();
  const topics = useSelector((state) => state.studyCenter.topics);
  const [titleWidth, setTitleWidth] = useState(0);
  const titleRef = useRef();

  useEffect(() => {
    dispatch(StudyCenterActions.fetchSCTopics());
  }, []);

  useEffect(() => {
    if (!titleRef.current) {
      return;
    }

    setTitleWidth(titleRef.current.offsetWidth || 0);
  }, []);

  const renderFolderNames = () => {
    const folder = topics.find((item) => item.id === folderId);
    let title = t(isUserEditor ? RootFolder.titleEditor : RootFolder.title);
    if (folder) {
      title = folder.title;
    }
    return <span className="sc-folder-text with-chevron">{title}</span>;
  };

  return (
    <div
      className="sc-folder-wrap"
      onClick={() => {
        showDialog(
          <ScFoldersDialog
            topics={topics}
            current={folderId}
            onChange={(topic) => onChange(topic)}
          />
        );
      }}>
      <span className="sc-title" ref={titleRef}>
        {t("saveTo")}
      </span>
      <div className="sc-folder-value" style={{ maxWidth: `calc(100% - ${titleWidth}px - 15.5px` }}>

        <i className="icon-folder-close" />
        {renderFolderNames()}
      </div>
    </div>
  );
};

ScFolder.propTypes = {
  topics: PropTypes.array,
  folderId: PropTypes.string,
  onChange: PropTypes.func
};

export default ScFolder;
