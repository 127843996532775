import { all, call, put, select, takeLeading } from "redux-saga/effects";
import { LOADER_ID_SHOP, shopActionConstants, shopActions } from "./actions";
import { actionLoading } from "../actions";
import { getPackagesRequest, getShopBooksRequest } from "../../shared/api/shopApi";

function* fetchShopBooks(action) {
  const { packageIds } = yield select((state) => state.shop);
  const booksToFetch = new Set(action.data);

  if (!packageIds.length) {
    yield put(actionLoading(LOADER_ID_SHOP));
    yield put(shopActions.fetchPackagesPending());
    const packagesData = yield call(getPackagesRequest, {});
    const packages = packagesData?.packages || [];
    yield put(shopActions.fetchPackagesSuccess(packages));
    packages.forEach((shopPackage) => {
      shopPackage.items.forEach((shopBook) => {
        booksToFetch.add(shopBook.book_id);
      });
    });
  }

  if (booksToFetch.size) {
    yield put(shopActions.fetchShopBooksPending([...booksToFetch]));
    const shopData = yield call(getShopBooksRequest, [...booksToFetch]);
    yield put(shopActions.fetchShopBooksSuccess(shopData));
  }

  yield put(actionLoading(LOADER_ID_SHOP, true));
}

export default function* shopSaga() {
  yield all([
    takeLeading(shopActionConstants.FETCH_SHOP_BOOKS, fetchShopBooks),
  ]);
}
