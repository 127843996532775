import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shopActions } from "src/redux/shop/actions";

/* eslint-disable-next-line */
const findPackageForBook = (shopPackages, packageIds, book_id) => {
  for (let i = 0; i < packageIds.length; i++) {
    const shopPackage = shopPackages[packageIds[i]];
    /* eslint-disable-next-line */
    const found = shopPackage.items.find((shopBook) => shopBook.book_id === book_id);
    if (found) {
      return {
        shopPackage,
        packageShopBookIds: shopPackage.items.map((shopBook) => shopBook.book_id),
      };
    }
  }
  return { shopPackage: null, packageShopBookIds: [] };
};

export const useShopBook = (book) => {
  const dispatch = useDispatch();
  const shopBooks = useSelector((state) => state.shop.shopBooks);
  const shopIds = useSelector((state) => state.shop.shopIds);
  const pendingIds = useSelector((state) => state.shop.pendingIds);
  const packageIds = useSelector((state) => state.shop.packageIds);
  const loadingPackages = useSelector((state) => state.shop.loadingPackages);
  const shopPackages = useSelector((state) => state.shop.shopPackages);
  const bookId = book?.book_id;
  const shopBook = shopBooks[bookId];
  const isForSaleAndPurchased = book?.isForSale && shopBook?.is_purchased;
  const isAvailable = !book?.isForSale || isForSaleAndPurchased;
  const { shopPackage, packageShopBookIds } = findPackageForBook(
    shopPackages,
    packageIds,
    book?.book_id,
  );
  const loading = pendingIds.has(bookId) || loadingPackages;

  useEffect(() => {
    if (!loading && bookId && book?.isForSale && !shopBook) {
      const booksToFetch = [bookId];
      dispatch(shopActions.fetchShopBooks(booksToFetch));
    }
  }, [shopIds, loading, bookId, book, shopBook, dispatch]);

  return {
    shopBook,
    shopBooks,
    isAvailable,
    shopPackage,
    packageShopBookIds,
    packageIds,
    loading,
    isForSaleAndPurchased
  };
};
export const useShopBooks = (books) => {
  const dispatch = useDispatch();
  const shopBooks = useSelector((state) => state.shop.shopBooks);
  const shopIds = useSelector((state) => state.shop.shopIds);
  const pendingIds = useSelector((state) => state.shop.pendingIds);
  const packageIds = useSelector((state) => state.shop.packageIds);
  const shopPackages = useSelector((state) => state.shop.shopPackages);
  const loadingPackages = useSelector((state) => state.shop.loadingPackages);
  const booksToFetch = useMemo(
    () =>
      books.reduce((acc, book) => {
        const bookInfo = book?.bookInfo || book;
        if (bookInfo.isForSale && !shopBooks[bookInfo.book_id]) {
          acc.push(bookInfo.book_id);
        }
        return acc;
      }, []),
    [books, shopBooks],
  );
  const loadingShopBooks = !![...pendingIds].find((book_id) => booksToFetch.includes(book_id));
  const loading = loadingShopBooks || loadingPackages;
  useEffect(() => {
    if (!loading && booksToFetch.length) {
      dispatch(shopActions.fetchShopBooks(booksToFetch));
    }
  }, [shopIds, loading, booksToFetch, dispatch]);

  return { shopBooks, loading, packageIds, shopPackages, shopIds };
};
