import { makeRequest } from "src/shared/api/baseApi";

const BASE_PUSH_URL = "subscriptions/webpush";
const DEBUG_PUSH_URL = "subscriptions/management/devel";

export const getVapidKey = () => {
  const request = {
    url: `${BASE_PUSH_URL}/key`,
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const subscribeToPush = (subscription) => {
  const request = {
    url: `${BASE_PUSH_URL}/subscribe`,
    type: "post",
    data: { subscription },
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const unsubscribeFromPush = (subscription) => {
  const request = {
    url: `${BASE_PUSH_URL}/unsubscribe`,
    type: "post",
    data: { subscription },
    parseResponse: (response) => {
      return response.status;
    },
  };
  return makeRequest(request);
};

/*for debug only*/
export const sendPush = () => {
  const request = {
    url: `${DEBUG_PUSH_URL}/send`,
    type: "post",
    data: { title: "Test", body: "Test push notification" },
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};

export const sendPushTo = (receiver, isFeed) => {
  const request = {
    url: `${DEBUG_PUSH_URL}/enqueue`,
    type: "post",
    data: {
      method: receiver,
      isFeed: isFeed,
    },
    parseResponse: (response) => {
      return response.data;
    },
  };
  return makeRequest(request);
};
/*for debug only*/
