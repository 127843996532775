import React, { useState, useEffect } from "react";
import { isIE } from "react-device-detect";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { HideIcon, Scroll } from "../components/views";
import Chrome from "../assets/browsers/chrome.png";
import Safari from "../assets/browsers/safari.png";
import Edge from "../assets/browsers/edge.png";
import Firefox from "../assets/browsers/firefox.png";

import "./UnsupportedPage.scss";
import "../styles/unsuported-polyfill.scss";


const UnsupportedPage = ({ children }) => {
  const [supportShow, setSupportShow] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const agent = navigator.userAgent;
    const isAndroid = agent.toLowerCase().indexOf("android") !== -1;
    let isSupportedBrowser = true;

    if (isIE) {
      isSupportedBrowser = false;
    } else if (agent.indexOf("YaBrowser") !== -1) {
      isSupportedBrowser = false;
    } else if (agent.indexOf("Safari") !== -1 && agent.indexOf("Version/9.1") !== -1) {
      isSupportedBrowser = false;
    } else if (agent.indexOf("Safari") !== -1 && agent.indexOf("Version/8") !== -1) {
      isSupportedBrowser = false;
    } else if (agent.indexOf("Safari") !== -1 && agent.indexOf("Version/7") !== -1) {
      isSupportedBrowser = false;
    } else if (agent.indexOf("UCBrowser") !== -1) {
      isSupportedBrowser = false;
    } else if (agent.indexOf("SamsungBrowser") !== -1) {
      isSupportedBrowser = false;
    } else if (/Firefox\/[1-6][1-7]/gim.test(agent) && !isAndroid) {
      isSupportedBrowser = false;
    } else if (agent.indexOf("MiuiBrowser") !== -1) {
      isSupportedBrowser = false;
    }

    if (!isSupportedBrowser) {
      setSupportShow(true);
    }

    setIsReady(true);
  }, []);

  if (!isReady) {
    return null;
  }

  return  (isIE || supportShow)
    ? (
      <div className="unsupported-page">
        {!isIE && <HideIcon onClick={() => setSupportShow(false)} title="close" showSimpleIcon />}
        <Scroll autoHide>
          <div className="container">
            <h2>{t("browserSupportOne")}</h2>
            <h4>{t("browserSupportTwo")}</h4>
            <h6>{t("browserSupportThree")}</h6>
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/chrome/?brand=CHBD&gclid=EAIaIQobChMIpt_EwbWS5wIVRouyCh35TwMBEAAYASAAEgKVivD_BwE&gclsrc=aw.ds">
                  <div>
                    <img src={Chrome} alt={t("nameBrowser", { name: "Google Chrome"})} />
                  </div>
                  <span>Chrome</span>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/en-us/edge?form=MY01BQ&OCID=MY01BKQ">
                  <div>
                    <img src={Edge} alt={t("nameBrowser", { name: "Edge"})} />
                  </div>
                  <span>Edge Chromium</span>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://www.mozilla.org/en-US/firefox/download/thanks/">
                  <div>
                    <img src={Firefox} alt={t("nameBrowser", { name: "Mozilla Firefox"})} />
                  </div>
                  <span>Mozilla Firefox</span>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://support.apple.com/downloads/safari">
                  <div>
                    <img src={Safari} alt={t("nameBrowser", { name: "Safari"})} />
                  </div>
                  <span>Safari</span>
                </a>
              </li>
            </ul>
            {!isIE && <button className="button-base button-primary" onClick={() => setSupportShow(false)}>{t("skip")}</button>}
          </div>
        </Scroll>
      </div>
    ) : children;
};

UnsupportedPage.propTypes = {
  children: PropTypes.node.isRequired
};

export default UnsupportedPage;
