import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getLayoutsData } from "src/components/reader/layouts/LayoutUtils";
import WorkspacesGrid from "src/components/reader/workspaces/WorkspacesGrid";
import { Scroll } from "src/components/views";
import { useResizeDimensions } from "src/hooks";
import { withLogin } from "src/utils/HOC";

import "./Workspace.scss";

/**
 * item {
 *  layoutId: string,
 *  created: date,
 *  ids: string
 * }
 */
const WorkspacesPage = () => {
  const { t } = useTranslation();
  const layouts = useSelector((state) => state.settings.layouts);
  const workspaces = useSelector((state) => state.settings.workspaces);
  const showBookshelf = useSelector((state) => state.settings.showBookshelf);
  const { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT } = useResizeDimensions();
  const layoutsData = getLayoutsData({ MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT });

  if (!workspaces.length) {
    return <div className="ws-not-found">{t("workspaceNotFound")}</div>;
  }

  return (
    <Scroll
      renderView={(props) => (
        <div
          {...props}
          className={classNames("ws-grid ws-grip-page", { "with-shelf": !showBookshelf })}></div>
      )}>
      <WorkspacesGrid
        currentLayout={layouts}
        layoutsData={layoutsData}
        showDateCreated={true}
        showShelf={!showBookshelf}
      />
    </Scroll>
  );
};

export default withLogin(WorkspacesPage);
