import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "../dialog/DialogContext";
import { Button, Checkbox } from "../views";
import PropTypes from "prop-types";

import "./ToggleInLibraryDialog.scss";

const ToggleInLibraryDialog = ({ message, onConfirm }) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialogContext();

  const [doNotAskAgain, setDoNotAskAgain] = useState(false);
  return (
    <div className="dialog-toggle-in-library">
      <span className="dialog-title">{t("confirmation")}</span>
      <div data-focus={true} tabIndex={1} className="dialog-message">{t(message)}</div>
      <Checkbox
        className="dialog-toggle-in-library__checkbox"
        title={t("doNotAskAgain")}
        checked={doNotAskAgain}
        onChange={() => {
          setDoNotAskAgain(!doNotAskAgain);
        }}
      />
      <div className="dialog-toggle-in-library__buttons">
        <Button
          type="secondary"
          onClick={() => {
            hideDialog();
          }}
          className="dialog-btn">
          {t("cancel")}
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            onConfirm(doNotAskAgain);
            hideDialog();
          }}
          className="dialog-btn">
          {t("ok")}
        </Button>
      </div>
    </div>
  );
};

ToggleInLibraryDialog.propTypes = {
  message: PropTypes.string,
  onConfirm: PropTypes.func
};

export default ToggleInLibraryDialog;
