import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useViewMode } from "../../hooks";
import { IconButton } from "../views";
import {
  DotsVerticalIcon,
  ContentIcon,
} from "../../assets/CommonIcons";
import { useNavigationContext } from "../NavigationContext";
import { actionSetContentId, actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import EgwWebFont from "src/assets/EgwWebFont";
import ButtonBooksFilters from "../categories/ButtonBooksFilters";

import "./TopPanelTools.scss";

// TODO move all to TopPanel.js
export const TopPanelToolsComponent = ({ items, children }) => {
  const [show, setShow] = useState(false);
  const isReady = items.length > 0 || children;
  const { isMobile } = useViewMode();

  if (!isReady) {
    return null;
  }

  return (
    <div className="panel-tools">
      {items.map((tool, index) => (
        <span className={isMobile ? "tpMenuIcon" : "panel-tools-icon"} key={index}>
          {tool}
        </span>
      ))}
      {children && (
        <React.Fragment>
          <IconButton active={show} icon={DotsVerticalIcon} onClick={() => setShow(!show)} />
          {show && (
            <React.Fragment>
              <div className="dropDownMenuBack" onClick={() => setShow(false)} />
              <div className={"panel-popup " + (show ? "showView" : "hideView")}>{children}</div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const TopPanelTools = ({
  isLibrary,
  isWorkspace,
  showListIcons,
  isShowBtnFiltersBookType,
  onPlusClick,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isGrid = useSelector((state) => state.settings.isGrid);
  const isShowAZ = useSelector((state) => state.settings.isShowAZ);
  const showReadingHistory = useSelector((state) => state.settings.showReadingHistory);
  const showListenHistory = useSelector((state) => state.settings.showListenHistory);
  const showBookshelf = useSelector((state) => state.settings.showBookshelf);
  const sortByRecent = useSelector((state) => state.settings.sortByRecent);
  const { isMobile, textMode } = useViewMode();
  const { targetId, isReader, currentFolder } = useNavigationContext();
  const hiddenItems = [];
  const items = [];

  const handleBookshelfChange = () => {
    dispatch(actionUpdateSetting(Settings.showBookshelf.id, !showBookshelf));
  };

  const handleListChange = (isGrid) => {
    dispatch(actionUpdateSetting(Settings.isGrid.id, isGrid));
  };

  const handleAZ = () => {
    dispatch(actionUpdateSetting(Settings.isShowAZ.id, !isShowAZ));
  };

  const handleHistoryFilter = (isReading) => {
    if (
      (isReading && showReadingHistory && !showListenHistory) ||
      (!isReading && !showReadingHistory && showListenHistory)
    ) {
      return;
    }

    if (isReading) {
      dispatch(actionUpdateSetting(Settings.showReadingHistory.id, !showReadingHistory));
    } else {
      dispatch(actionUpdateSetting(Settings.showListenHistory.id, !showListenHistory));
    }
  };

  if (currentFolder.showBtnBooksFilter) {
    hiddenItems.push(<ButtonBooksFilters />);
  }

  if (showListIcons) {
    hiddenItems.push(
      <IconButton
        icon={EgwWebFont.bookshelf}
        title={t("bookShelf")}
        className="tpMenuIcon"
        onClick={() => handleBookshelfChange()}
        active={showBookshelf}
      />,
    );

    hiddenItems.push(
      <IconButton
        icon={EgwWebFont.grid}
        title={t("grid")}
        className="tpMenuIcon"
        onClick={() => handleListChange(true)}
        active={isGrid}
      />,
    );

    if (!isWorkspace) {
      hiddenItems.push(
        <IconButton
          icon={EgwWebFont.list}
          title={t("list")}
          className="tpMenuIcon"
          onClick={() => handleListChange(false)}
          active={!isGrid}
        />,
      );
    }

    if (isLibrary) {
      hiddenItems.push(
        <IconButton
          icon={sortByRecent ? EgwWebFont["sort-by-recent"] : EgwWebFont.AZ}
          className="tpMenuIcon"
          title={sortByRecent ? t("sortByRecent") : t("A/Z")}
          onClick={() => dispatch(actionUpdateSetting(Settings.sortByRecent.id, !sortByRecent))}
          active={true}
        />,
      );
    } else {
      if (!isWorkspace) {
        hiddenItems.push(
          <IconButton
            icon={EgwWebFont.AZ}
            title={t("A/Z")}
            className="tpMenuIcon"
            onClick={handleAZ}
            active={isShowAZ}
          />,
        );
      }
    }
  }

  if (onPlusClick) {
    items.push(
      <IconButton
        icon={EgwWebFont.plus}
        title={t("+")}
        className="tpMenuIcon"
        onClick={(e) => onPlusClick(e)}
      />,
    );
  }

  if (isMobile && isReader) {
    items.push(
      <IconButton
        className={textMode ? "bordered" : "tp-menu-reader-icon"}
        icon={ContentIcon}
        title={t("content")}
        onClick={() => {
          dispatch(actionSetContentId(targetId));
        }}
      />,
    );
  }

  if (isShowBtnFiltersBookType) {
    const arrToPush = isMobile && textMode ? hiddenItems : items;
    arrToPush.push(
      <IconButton
        icon={EgwWebFont["reading-history"]}
        title={t(currentFolder.showBtnBooksFilter ? "read" : "readingHistory")}
        className="tpMenuIcon"
        onClick={() => handleHistoryFilter(true)}
        active={showReadingHistory}
      />,
      <IconButton
        icon={EgwWebFont.volume}
        title={t(currentFolder.showBtnBooksFilter ? "audiobooks" : "listenHistory")}
        className="tpMenuIcon"
        onClick={() => handleHistoryFilter()}
        active={showListenHistory}
      />,
    );
  }

  const isReady = hiddenItems.length > 0 || items.length > 0;

  if (!isReady) {
    return null;
  }

  return (
    <TopPanelToolsComponent items={items}>
      {hiddenItems.length > 0 &&
        hiddenItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>)}
    </TopPanelToolsComponent>
  );
};

TopPanelTools.propTypes = {
  isLibrary: PropTypes.bool,
  isWorkspace: PropTypes.bool,
  showListIcons: PropTypes.bool,
  isShowBtnFiltersBookType: PropTypes.bool,
  onPlusClick: PropTypes.func,
};

TopPanelToolsComponent.defaultProps = {
  items: [],
};

TopPanelToolsComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
};

export default TopPanelTools;
