import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { useBookSelect } from "src/redux/selectors"; 
import { getBookId } from "src/shared/utils/content";
import { ContentActions } from "src/redux/content.actions";

const BookCodePane = ({ panelId }) => {
  const dispatch = useDispatch();
  const paraId = panelId.substring(1);
  const bookId = getBookId(paraId);
  const bookInfo = useBookSelect(bookId);
  const bookCode = bookInfo ? bookInfo.code : "";

  useEffect(() => {
    if (!bookInfo) {
      dispatch(ContentActions.fetchBookDetailsRequest(bookId));
    }
  }, [bookId]);

  return (
    <div className="ws-pane-item" title={bookCode}>
      <span>{bookCode}</span>
    </div>
  );
};

BookCodePane.propTypes = {
  panelId: PropTypes.string,
};

export default BookCodePane;
