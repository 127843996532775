import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./View.scss";

const Button = ({
  children,
  type,
  singleLine,
  onClick,
  className,
  outline,
  disabled,
  ...props
}) => {
  let advStyle = "";
  if (disabled) {
    advStyle += outline ? "button-disabled-outline" : "button-disabled";
  } else if (type === "primary") {
    advStyle += outline ? "button-primary-outline" : "button-primary";
  } else if (type === "secondary") {
    advStyle += outline ? "button-secondary-outline" : "button-secondary";
  } else if (type === "tertiary") {
    advStyle += outline ? "button-tertiary-outline" : "button-tertiary";
  } else if (type === "quaternary") {
    advStyle += outline ? "button-quaternary-outline" : "button-quaternary";
  } else {
    //set primary if no other variants
    advStyle += outline ? "button-primary-outline" : "button-primary";
  }

  if (singleLine) {
    advStyle += " single-line";
  }

  return (
    <button
      tabIndex={disabled ? -1 : 0}
      onClick={disabled ? undefined : onClick}
      className={classNames("button-base", advStyle, className)}
      {...props}>
      {children}
    </button>
  );
};

Button.defaultTypes = {
  type: "primary",
};

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary"]),
  outline: PropTypes.bool,
  singleLine: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
