import { CONTENT_CLASSES, getBookId, getBookOrigin, isSameBook } from "../shared/utils/content";
import { URLS } from "../shared/utils/url";

export const PARA_ID = "paraId";
export const PANELS = "panels";
export const NEW_PANEL_ID = "newPanel";

/**
 * @typedef PanelType
 * @property{string} id
 * @property{boolean} canActive
 * @property{boolean} book
 * @property{boolean} bracket
 * @property{boolean} encode
 * @property{number} maxCount
 */

/**
 * @enum{PanelType}
 */
export const PanelTypes = {
  BOOK: {
    // panel for book info
    id: "b",
    book: true,
  },
  PARA: {
    // panel with content, can make sync
    id: "p",
    canActive: true,
    book: true,
  },
  READ: {
    // panel with content, dont make sync, use for duplicate book in reader
    id: "r",
    canActive: true,
    book: true,
  },
  CONTENT: {
    // panel with book toc
    id: "c",
  },
  JTL_BOOK: {
    // panel for jtl link book
    id: "j",
    canActive: true,
    book: true,
  },
  JTL_BIBLE: {
    // panel for jtl link bible
    id: "v",
    canActive: true,
    book: true,
  },
  FEATURED: {
    // panel for content, opened from S+ featured
    id: "f",
    canActive: true,
    book: true,
  },
  TRANSLATE: {
    // panel for translation logic for ellen4All
    id: "t",
  },
  NEW: {
    // panel for new dnd field
    id: "n",
  },
  DICTIONARY: {
    // panel with dictionary search and content
    id: "d",
    bracket: true,
    encode: true,
  },
  SEARCH: {
    // panel with search inside reader
    id: "s",
    bracket: true,
    encode: true,
  },
  EDITOR: {
    // panel with SC editor
    id: "e",
    bracket: true,
    encode: true,
  },
};

export const panelPrefix = Object.values(PanelTypes).map((item) => item.id);

export const possibleActivePanels = Object.values(PanelTypes)
  .filter((item) => item.canActive)
  .map((item) => item.id);

export const possibleBookPanels = Object.values(PanelTypes)
  .filter((item) => item.book)
  .map((item) => item.id);

const LinkValueRegExp = /^(\w)(\d*\.\d*|\(.*\)|\d*)(\(\d*\.\d*\))?$/;

const removeBracket = (str) => str.replace(/[()]/g, "");
const addBracket = (str) => "(" + str + ")";

/**
 * @typedef LinkParams
 * @property {string} type
 * @property {string} value
 * @property {!string} activeValue
 *
 */

/**
 *
 * b{id} - book b1
 * p{id} - paragraph p1.2
 * d({value}) - dictionary d(bible)
 * c{id} - content c1.2
 * s({value}) - search s(bible)
 * @returns {LinkParams}
 */
export const getLinkValue = (id) => {
  if (!id) {
    return {};
  }

  let result = {};
  let matchValues = id.match(LinkValueRegExp);
  if (!matchValues) {
    return {};
  }
  let [, typeId, value, activeValue] = matchValues;
  if (panelPrefix.includes(typeId)) {
    const fullType = Object.values(PanelTypes).find((item) => item.id === typeId);

    if (typeId) {
      result.type = typeId;
    }
    if (value) {
      result.value = decodeURIComponent(removeBracket(value));
    }

    if (fullType && fullType.canActive) {
      if (activeValue) {
        result.activeValue = removeBracket(activeValue);
      } else {
        result.activeValue = value;
      }
    }
  }
  return result;
};

export const makeLinkValue = (type, value, activeValue) => {
  let outValue;
  if (type === PanelTypes.BOOK.id) {
    return value;
  }
  const params = Object.values(PanelTypes).find((item) => item.id === type);
  if (params) {
    outValue = type;
    let finalValue = params.encode ? encodeURIComponent(value) : value;
    outValue += params.bracket ? addBracket(finalValue) : finalValue;

    if (params.canActive && activeValue && activeValue !== value) {
      outValue += addBracket(activeValue);
    }
  }
  return outValue;
};

const isValidActivePanel = (panelId = "") => {
  const { type } = getLinkValue(panelId);
  return possibleActivePanels.includes(type);
};

/**
 * Returns index of panel with current book or -1 if book is not exist
 * @param {string[]} panels [p123.1, p23.12]
 * @param {string} paraId
 */
export const findBookIndex = (panels = [], paraId) => {
  // const { value } = getLinkValue(id);
  const index = panels.findIndex((item) => {
    const itemValue = getLinkValue(item);
    // TODO add check  by possibleBookPanels if it needed
    return (
      (itemValue.type === PanelTypes.PARA.id || itemValue.type === PanelTypes.READ.id) &&
      isSameBook(itemValue.value, paraId)
    );
  });
  return index;
};

/**
 *
 * @param {string[]} panels
 * @returns {string[]} only uniq book ids
 */
export const getBookIds = (panels) => {
  let set = new Set();
  panels.forEach((item) => {
    const { type, value } = getLinkValue(item);
    if (possibleBookPanels.includes(type)) {
      set.add(getBookId(value));
    }
  });

  return [...set];
};

export const firstPossiblePara = (panels = [], activeIndex = 0) => {
  let id;
  if (panels.length === 0) {
    return id;
  }

  const { type, activeValue } = getLinkValue(panels[activeIndex]);

  if (isValidActivePanel(type)) {
    id = activeValue;
  } else {
    let index = 0;
    while (index < panels.length) {
      const { type, activeValue } = getLinkValue(panels[index]);
      if (isValidActivePanel(type)) {
        id = activeValue;
        break;
      }
      index++;
    }
  }

  return id;
};

//TODO make simple
export const updateReaderPanels = (panels, newPanelType, newPanelValue, options = {}) => {
  const { onlyUpdate, translation, index, toStart, onlySync } = options;
  let newPanels = [...panels];
  const newLinkValue = makeLinkValue(newPanelType, newPanelValue);

  let existPosition = -1;
  let itemTrans;
  newPanels.forEach((item, panelIndex) => {
    const { type, value } = getLinkValue(item);
    const isSameBookValue = isSameBook(value, newPanelValue);

    // Update content panel for currrent book
    if (isSameBookValue && type === PanelTypes.CONTENT.id) {
      newPanels[panelIndex] = makeLinkValue(PanelTypes.CONTENT.id, newPanelValue);
    } else if (type === PanelTypes.EDITOR.id && newPanelType === PanelTypes.EDITOR.id) {
      newPanels[panelIndex] = makeLinkValue(PanelTypes.EDITOR.id, newPanelValue);
    } else if (!onlySync && index !== undefined && index === panelIndex) {
      newPanels[panelIndex] = newLinkValue;
    } else if (
      !onlySync &&
      index === undefined &&
      newPanelType === type &&
      (isSameBookValue ||
        newPanelType === PanelTypes.DICTIONARY.id ||
        newPanelType === PanelTypes.SEARCH.id)
    ) {
      existPosition = panelIndex;
      newPanels[panelIndex] = newLinkValue;
    } else if (translation && type === PanelTypes.PARA.id && newPanelType === PanelTypes.PARA.id) {
      // make sync based on translations for current para
      itemTrans = translation.find((trans) => isSameBook(trans.para_id, value));
      if (itemTrans) {
        newPanels[panelIndex] = makeLinkValue(PanelTypes.PARA.id, itemTrans.para_id);
      }
    }
  });

  if (existPosition === -1 && !onlyUpdate) {
    if (toStart) {
      newPanels.unshift(newLinkValue);
    } else {
      newPanels.push(newLinkValue);
    }
  }

  return newPanels;
};

export const getQueryByName = (name, url, noDecode) => {
  if (!url) {
    return undefined;
  }
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) {
    return undefined;
  }
  if (!results[2]) {
    return "";
  }
  const result = results[2].replace(/\+/g, " ");
  if (noDecode) {
    return result;
  }
  return decodeURIComponent(result);
};

export const isExternalUrl = (url) => url.slice(0, 4) === "http";

export const makeSingleChapterUrl = (paraId) => {
  let paras = [makeLinkValue(PanelTypes.PARA.id, paraId)];
  return makeChapterUrl(paras);
};

/**
 *
 * @param {string[]} panels
 * @param {number} activeIndex
 * @returns
 */
export const makeChapterUrl = (panels, activeIndex) => {
  if (!panels) {
    return "";
  }

  let index = activeIndex || 0;

  let validIndexes = [];

  // Check is valid active id;
  panels.forEach((id, index) => {
    if (isValidActivePanel(id)) {
      validIndexes.push(index);
    }
  });

  if (validIndexes.indexOf(index) === -1 && validIndexes.length > 0) {
    index = validIndexes[0];
  }

  return URLS.read + `?${PANELS}=${panels.join(",")}&index=${index}`;
};

export const getParaIdsFromUrl = (location) => {
  const paraId = getQueryByName(PANELS, location);
  if (!paraId) {
    return {
      panelIds: [],
      bookIds: [],
    };
  }
  let paraIds = paraId.split(",");
  const index = parseInt(getQueryByName("index", location));
  let searchItem = paraIds.find((item) => item.indexOf(PanelTypes.SEARCH.id) === 0);
  let search;
  if (searchItem) {
    const { value } = getLinkValue(searchItem);
    search = value;
  }

  let bookIds = getBookIds(paraIds);
  return {
    panelIds: paraIds,
    bookIds,
    search,
    activeIndex: index,
  };
};

export const makeLangImage = (code, small = false) => {
  const folder = small ? "flags" : "flags_big";
  return `/images/${folder}/${code}.png`;
};

export const makeNavigationUrl = (item, params = {}) => {
  const { isAudio, isLibrary, isHistory } = params;
  if (!item) {
    return "";
  }
  let link = "";
  const { className, lang, id, position, chapterId, page } = item;
  if (!id) {
    link = makeSingleChapterUrl(item);
  } else if (!className) {
    link = id;
  } else if (className === CONTENT_CLASSES.BOOK || className === CONTENT_CLASSES.CATEGORIES_BOOK) {
    let audioUrlPart = "";

    if (isAudio) {
      audioUrlPart = `?listen=${getBookOrigin(id)}.1&audio=1`;
    }

    link = URLS.book + "/" + id + audioUrlPart;
  } else if (className === "epub") {
    link = chapterId ? `/epub/${id}/${chapterId}` : `/epub/${id}`;
  } else if (className === "pdf") {
    link = URLS.pdf + "/" + id;
    if (page) {
      link += "/" + page;
    }
  } else if (
    !isAudio &&
    (className === CONTENT_CLASSES.CHAPTER || className === CONTENT_CLASSES.PARAGRAPH)
  ) {
    link = makeSingleChapterUrl(id);
  } else if (className === CONTENT_CLASSES.LANGUAGE) {
    if (isHistory) {
      link = `${URLS.history}/${id}`;
    } else if (isLibrary) {
      link = `${URLS.myLibrary}/${id}`;
    } else {
      link = isAudio ? `${URLS.audioBooks}/${id}` : `${URLS.allCollection}/${id}`;
    }
  } else if (lang) {
    link = isAudio ? `${URLS.audioBooks}/${lang}/${id}` : `${URLS.allCollection}/${lang}/${id}`;
  } else if (isAudio) {
    link =
      URLS.book +
      `/${getBookId(id)}?listen=${id}&audio=1` +
      (typeof position === "number" && !isNaN(position) ? "#" + position : "");
  }
  return link;
};

export const containsUrl = (text = "") => text && text.indexOf("http") > -1;

export const urlPattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
