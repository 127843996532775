import React from "react";
import Layout4 from "./Layout4";
import { layoutIds } from "./LayoutUtils";
import PropTypes from "prop-types";
import ResizerWrap from "./ResizeWrap";

const Layout8 = ({ panelIds, id, onRender }) => {
  const [id0, id1, id2, id3, id4, id5, id6, id7] = panelIds;

  if (id === layoutIds.l8h) {
    return (
      <ResizerWrap vertical>
        <Layout4 panelIds={[id0, id1, id2, id3]} id={layoutIds.l4s22} onRender={onRender} />
        <Layout4 panelIds={[id4, id5, id6, id7]} id={layoutIds.l4s22} onRender={onRender} />
      </ResizerWrap>
    );
  }

  //layoutIds.l8v
  return (
    <ResizerWrap>
      <Layout4 panelIds={[id0, id1, id2, id3]} id={layoutIds.l4s22} onRender={onRender} />
      <Layout4 panelIds={[id4, id5, id6, id7]} id={layoutIds.l4s22} onRender={onRender} />
    </ResizerWrap>
  );
};

Layout8.propTypes = {
  id: PropTypes.string,
  onRender: PropTypes.func,
  panelIds: PropTypes.array,
};

export default Layout8;
