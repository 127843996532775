import PropTypes from "prop-types";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, LinkButton } from "src/components/views";
import useDialogHelpTranslate from "./useDialogHelpTranslate";
import { useDevMode } from "src/hooks";

const BookTranslateStat = ({ statistics, big, paraId }) => {
  const { t } = useTranslation();
  const handleBookInteractionTranslate = useDialogHelpTranslate();
  const { isDevMode } = useDevMode();

  const segments = statistics?.segments || 0;
  const preTranslated = statistics?.pretranslated || 0;
  const translated = statistics?.translationDone || 0;
  const reviewed = statistics?.reviewDone || 0;
  const approved = statistics?.trusteeDone || 0;

  if (!isDevMode) {
    return null;
  }

  const renderProgressItem = (label, value, total, isGreen) => {
    const percentProgress = Math.round((value / total) * 100);
    if (total === 0) {
      return null;
    }

    return (
      <div className={classnames("translate-wrapper-item", { big })}>
        <div className="translate-stat-title">
          <span>{t(label)}:</span>
        </div>
        <div className="translate-stat-value">
          <span className={isGreen ? "reviewed" : "translated"}>{value}</span>
          <span>/{total}</span>
        </div>
        <div className="translate-stat-value">
          <span className={isGreen ? "reviewed" : "translated"}>
            {isNaN(percentProgress) ? "0" : percentProgress} %
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={classnames("translate-wrapper", { big })}>
      {renderProgressItem(t("translate:pre_translated"), preTranslated, segments)}
      {renderProgressItem(t("translate:translated"), translated, segments)}
      {renderProgressItem(t("translate:reviewed"), reviewed, segments, true)}
      {renderProgressItem(t("translate:approved"), approved, segments, true)}

      <div className="transalte-button-wrap">
        <Button
          onClick={() => handleBookInteractionTranslate(paraId)}
          className="height-tiny"
          singleLine
          type="quaternary">
          {t("translate:start_translating")}
        </Button>

        <LinkButton
          link="https://training.ellen4all.org/"
          external
          singleLine
          label={t("translate:start_training")}
        />
      </div>
    </div>
  );
};

BookTranslateStat.propTypes = {
  big: PropTypes.bool,
  paraId: PropTypes.string,
  statistics: PropTypes.shape({
    pretranslated: PropTypes.number,
    reviewDone: PropTypes.number,
    segments: PropTypes.number,
    translationDone: PropTypes.number,
    trusteeDone: PropTypes.number,
  }),
};

export default BookTranslateStat;
