import React, { useState } from "react";
import AppNotification from "../../react-pages/AppNotification";
import CookiesNotification from "../../react-pages/CookiesNotification";
import DonateNotification from "../../react-pages/DonateNotification";
import HelpNotification from "../../react-pages/HelpNotification";

const ModalWrap = () => {
  const [isCookieNotificationShown, setIsCookieNotificationShown] = useState(false);
  const [isDonateNotificationShown, setIsDonateNotificationShown] = useState(false);
  return (
    <React.Fragment>
      <CookiesNotification onShowChange={setIsCookieNotificationShown} />
      <DonateNotification
        onShowChange={setIsDonateNotificationShown}
        preventShow={isCookieNotificationShown}
      />
      <AppNotification
        preventShow={isCookieNotificationShown || isDonateNotificationShown}
      />
      <HelpNotification />
    </React.Fragment>
  );
};

export default ModalWrap;
