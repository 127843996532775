import { URLS } from "../../shared/utils/url";

export const weekDays = [
  {
    value: 7,
    label: "SU"
  },
  {
    value: 1,
    label: "MO"
  },
  {
    value: 2,
    label: "TU"
  },
  {
    value: 3,
    label: "WE"
  },
  {
    value: 4,
    label: "TH"
  },
  {
    value: 5,
    label: "FR"
  },
  {
    value: 6,
    label: "SA"
  },
];

export const bookType = {
  BOOK: {
    id: "book",
    label: "book"
  },
  BIBLE: {
    id: "bible",
    label: "bible"
  },
  DEVOTIONAL: {
    id: "devotional",
    label: "devotional"
  }
};

export const subTypes = [
  {
    id: "book",
    label: "egw"
  }, {
    id: "bible",
    label: "bible"
  }, {
    id: "devotional",
    label: "devotionals"
  }, {
    id: "news",
    label: "news"
  }
];

export const validateDate = (date) => {
  try {
    return date.toISOString().split("T")[0];
  } catch (e) {
    return undefined;
  }
};

export const getDate = (date) => date ? new Date(date) : new Date();

/**
 * Make url for subscription read. This url must be same as formed in push notification 
 * @see public/ServiceWorker.js
 * @param {*} date 
 * @param {*} bookId 
 */
export const makeReadUrl = (date, bookId) => {
  let url = URLS.subscription + "/read/" + date + "/";
  if (bookId) {
    url += bookId;
  }
  return url;
};

export const formSubAction = (subscription, bookId, fetchContent = false) => {
  const action = { fetchContent };
  if (fetchContent) {
    action.date = validateDate(new Date());
  }
  if (subscription) {
    action.subId = subscription.id || subscription;
  } else {
    action.params = {
      start_date: new Date().toISOString(),
      start_order: 0,
      book: bookId,
      weekdays: weekDays.map((day) => day.value).sort().join(""),
      items_per_day: 1,
      delivery_time: "00:00",
      delivery_methods: [],
    };
  }
  return action;
};

export const setSocialsData = (fbData, twData, setFbFun, setTwFun) => {
  const { facebook_access_token: fbToken } = fbData;
  const { twitter_username: twName } = twData;
  if (fbToken) {
    setFbFun(fbData);
  }
  if (twName) {
    setTwFun(twName);
  }
};
