import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { makeNavigationUrl, makeSingleChapterUrl } from "../../../utils/URLUtils";
import {
  setPlayerParams,
  actionSetContentId,
  actionUpdateSetting, actionAddMessage
} from "../../../redux/actions";
import { PLAYER_EVENTS } from "../AudioContext";
import { findWaveformsDictionaryParaByPosition } from "../PlayerUtils";
import {
  PlaybackControls, PlayControls,
  TimerControls, VolumeControls,
  TrackCover, ProgressControls
} from "../PlayerUIKit/PlayerUIKit";
import IconButton from "../../views/IconButton";
import Scroll from "../../views/Scroll";
import ReactResizeDetector from "react-resize-detector/build/withPolyfill";
import { useBookHistory, useViewMode } from "../../../hooks";
import { isEventKey, isFocusTextInput, KeyCodes } from "../../../shared/utils/dom";
import { Settings } from "../../../utils/Settings";
import { getBookCover } from "../../../shared/utils/url";
import EgwWebFont from "../../../assets/EgwWebFont";
import useToggleInLibrary from "../../../hooks/useToggleInLibrary";
import { useShopBook } from "../../../hooks/useShop";

import "./PlayerComponent.scss";

const PlayerComponent = ({
  audioContextWithCurrentTime,
  book,
  showBtnToc,
  showBtnClose,
  showBtnCollapse,
  onClickBtnToc,
  onClickBtnClose,
  onClickBtnCollapse,
}) => {
  const { t } = useTranslation();
  const prevBookIdRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, isTablet, zoom } = useViewMode();
  const waveformsDictionary = useSelector((state) => state.audioPlayer.waveformsDictionary);
  const brokenAudios = useSelector((state) => state.audioPlayer.brokenAudios);
  const { toggleInLibrary } = useToggleInLibrary();
  const bookId = book?.id;
  const { isAvailable } = useShopBook(book);
  const bookDataHistory = useBookHistory(bookId);
  const isBookInLibrary = !!bookDataHistory?.inLibrary;
  const {
    chapterId, duration, volume, isMute, playbackRate,
    audioChapters, downloadWaveformsData, updatePlayerState,
    timePosition, getPlayerPosition, setPlayerPosition,
    isPlaying, toggleChapterPlaying, skipPlay, seekPlay,
    subscribePlayerEvents, unsubscribePlayerEvents,
  } = audioContextWithCurrentTime;

  const [stopTimeout, setStopTimeout] = useState(0);

  const [suitableTrack, selectedChapterIndex, audioChaptersArray, fileDuration] = audioChapters;
  const selectedTrack = suitableTrack || audioChaptersArray?.[0];
  const waveform = waveformsDictionary[selectedTrack?.id]?.waveform;
  const trackDuration = fileDuration || duration;
  const disabled = !selectedTrack || brokenAudios.includes(selectedTrack.id);

  // Set playback to default when book is changed.
  useEffect(() => {
    if (prevBookIdRef.current && prevBookIdRef.current !== bookId) {
      updatePlayerState({
        playbackRate: 1.0
      });
    }

    prevBookIdRef.current = bookId;
  }, [updatePlayerState, bookId]);

  // Fetch chapter waveform and paragraphs positions in the chapter.
  useEffect(() => {
    downloadWaveformsData(selectedTrack);
  }, [selectedTrack]);

  // [2251] Stops audio when timeout value equals the track duration.
  useEffect(() => {
    const listener = ({event, preventEvent}) => {
      if (event === PLAYER_EVENTS.END) {
        if (stopTimeout !== 0) {
          const time = getPlayerPosition();
          const shouldStop = stopTimeout - time <= 1;

          if (shouldStop && isPlaying) {
            preventEvent();

            toggleChapterPlaying();
          }
        }
      }
    };

    subscribePlayerEvents(listener);

    return () => {
      unsubscribePlayerEvents(listener);
    };
  }, [subscribePlayerEvents, unsubscribePlayerEvents, stopTimeout]);

  // Keyboard press handling.
  useEffect(() => {
    const hanleArrows = (next, alt) => {
      if (alt) {
        seekPlay(next);
      } else {
        skipPlay(next ? 10 : -10);
      }
    };
    const keyEventListener = (event) => {
      if (isFocusTextInput()) {
        return;
      }
      if (isEventKey(event, KeyCodes.space)) {
        toggleChapterPlaying();
        event.preventDefault();
      } else if (event.shiftKey && isEventKey(event, KeyCodes.rightArrow)) {
        hanleArrows(true, event.altKey);
        event.preventDefault();
      } else if (event.shiftKey && isEventKey(event, KeyCodes.leftArrow)) {
        hanleArrows(false, event.altKey);
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", keyEventListener);

    return () => {
      window.removeEventListener("keydown", keyEventListener);
    };
  }, [skipPlay, seekPlay, selectedTrack, timePosition]);

  // Stop timeout logic.
  useEffect(() => {
    if (isPlaying) {
      if (stopTimeout !== 0 && timePosition > stopTimeout) {
        setStopTimeout(0);
        toggleChapterPlaying();
      }
    }
  }, [isPlaying, timePosition]);

  // Reset the timeout value on the chapter id update.
  useEffect(() => {
    setStopTimeout(0);
  }, [chapterId]);

  const handleLike = useCallback(() => {
    if (isAvailable) {
      toggleInLibrary(isBookInLibrary, bookId);
    } else {
      dispatch(actionAddMessage("@pleaseBuyThisBook"));
    }
  }, [toggleInLibrary, isAvailable, isBookInLibrary, bookId]);

  const handleIconTimerClick = () => {
    setStopTimeout(stopTimeout ? 0 : trackDuration);
  };
  const handleIconVolumeClick = () => {
    let newVolume = volume;
    if (newVolume === 0 && isMute) {
      newVolume = 0.5;
    }
    updatePlayerState({
      isMute: !isMute,
      volume: newVolume
    });
  };
  const handleProgressSliderChange = (time) => {
    setPlayerPosition(time);
    if (stopTimeout !== 0 && time > stopTimeout) {
      setStopTimeout(0);
    }
  };
  const handlePlaybackChange = (value) => {
    updatePlayerState({
      playbackRate: value
    });
  };

  if (!selectedTrack) {
    return null;
  }

  const elBtnBookDetails = (
    <IconButton
      bordered
      icon={EgwWebFont["info"]}
      title={t("book info")}
      onClick={() => {
        dispatch(setPlayerParams({ isSmallPlayerExpanded: false }));
        history.replace(makeNavigationUrl(book));
      }}
    />
  );

  const elPlaybackControls = (
    <PlaybackControls
      disabled={disabled}
      playbackRate={playbackRate}
      onChange={handlePlaybackChange}
    />
  );

  const elBtnLike = (
    <IconButton
      bordered
      icon={EgwWebFont["heart-outline"]}
      trottle={500}
      onClick={handleLike}
      disabled={!isAvailable}
      className={classNames("player-component_btn-like", {
        active: isBookInLibrary,
      })}
      title={isBookInLibrary ? t("removeFromLibrary") : t("addToLibrary")}
    />
  );

  const elBtnContent = (
    !showBtnToc && <IconButton
      bordered
      icon={EgwWebFont["content"]}
      title={t("content")}
      onClick={onClickBtnToc}
    />
  );

  const elBtnReader = (
    <IconButton
      bordered
      disabled={(book?.children || []).length === 0}
      icon={EgwWebFont["read"]}
      title={t("reader")}
      onClick={() => {
        dispatch(setPlayerParams({ isSmallPlayerExpanded: false }));
        dispatch(actionSetContentId(undefined));

        if (isPlaying) {
          dispatch(actionUpdateSetting([Settings.syncPlayerAndReader.id], true));
        }

        history.push(makeSingleChapterUrl(findWaveformsDictionaryParaByPosition(
          waveformsDictionary, chapterId, getPlayerPosition()
        )?.id || chapterId));
      }}
    />
  );

  const elBtnCollapse = (
    showBtnCollapse && <IconButton
      bordered
      icon={EgwWebFont["fullscreen-exit"]}
      onClick={onClickBtnCollapse}
      title={t("minimize")}
    />
  );

  const elBtnClose = (
    showBtnClose && <IconButton
      bordered
      className="small-player__btn-close"
      icon={EgwWebFont["close"]}
      onClick={onClickBtnClose}
      title={t("close")}
    />
  );

  const elHeaderChildren = isMobile ? (
    <>
      {elBtnBookDetails}
      {elBtnContent}
      {elBtnReader}
      <div className="player-component__header__block-empty"/>
      {elBtnLike}
      {elBtnCollapse}
      {elBtnClose}
      {elPlaybackControls}
    </>
  ) : (
    <>
      {elBtnBookDetails}
      {elPlaybackControls}
      <div className="player-component__header__block-empty"/>
      {elBtnLike}
      {elBtnContent}
      {elBtnReader}
      {elBtnCollapse}
      {elBtnClose}
    </>
  );

  return (
    <ReactResizeDetector handleWidth render={({ width = window.innerWidth }) => {
      // "mode-mobile", "mode-tablet" can't be used because the component is used on Audio book page
      // and Small Player as the part of Split views, so it can have small width even on the desktop
      const hasSmallSizeUI = width <= 465 || isMobile;
      const isLayoutCompact = width <= 700;
      const hasMediumSizeUI = !hasSmallSizeUI && (width <= 840 || isTablet);
      return (
        <Scroll
          autoHide
          hasStaticPosition
          className={classNames("player-component", {
            "has-small-size-ui": hasSmallSizeUI,
            "has-medium-size-ui": hasMediumSizeUI,
            "has-layout-compact": isLayoutCompact,
          })}
        >
          <div className="player-component__header">{elHeaderChildren}</div>
          <div className="player-component__body">
            <TrackCover
              coverSrc={getBookCover(book, true)}
              bookTitle={book.title}
              trackTitle={selectedTrack ? selectedTrack.title : ""}
            />
            <TimerControls
              iconOnRightSide
              disabled={disabled}
              timePosition={timePosition}
              trackDuration={trackDuration - 1}
              stopTimeout={stopTimeout}
              onChange={setStopTimeout}
              onIconClick={handleIconTimerClick}
            />
            <PlayControls
              isAudioPlaying={isPlaying}
              isSeekBackDisabled={selectedChapterIndex === 0}
              isSeekForwardDisabled={selectedChapterIndex + 1 === audioChaptersArray.length}
              disabled={disabled}
              showSkipButtons={!(zoom > 140 && hasSmallSizeUI)}
              onSeekPrevClick={() => { seekPlay(false); }}
              onSkipPrevClick={() => { skipPlay(-10); }}
              onTogglePlayClick={() => { toggleChapterPlaying(); }}
              onSkipNextClick={() => { skipPlay(10); }}
              onSeekNextClick={() => { seekPlay(true); }}
            />
            <VolumeControls
              disabled={disabled}
              isMute={isMute}
              volume={volume}
              onIconClick={handleIconVolumeClick}
              onChange={(value) => {
                updatePlayerState({
                  volume: value,
                  isMute: value === 0
                });
              }}
            />
            <ProgressControls
              disabled={disabled}
              stopTimeout={stopTimeout}
              timePosition={timePosition}
              trackDuration={trackDuration}
              waveform={!hasSmallSizeUI && !isMobile && waveform}
              onChange={handleProgressSliderChange}
              onChangeCommitted={(value) => {
                skipPlay(value - getPlayerPosition());
              }}
            />
          </div>
        </Scroll>
      );
    }}/>
  );
};

PlayerComponent.propTypes = {
  componentChapterId: PropTypes.string,
  onClickBtnToc: PropTypes.func,
  onClickBtnClose: PropTypes.func,
  onClickBtnCollapse: PropTypes.func,
  showBtnToc: PropTypes.bool,
  showBtnClose: PropTypes.bool,
  showBtnCollapse: PropTypes.bool,
  book: PropTypes.object,
  waveformsDictionary: PropTypes.any,
  audioContextWithCurrentTime: PropTypes.object,
};

export default PlayerComponent;
