import React from "react";
import Layout3 from "./Layout3";
import Layout4 from "./Layout4";
import ResizerWrap from "./ResizeWrap";
import { layoutIds } from "./LayoutUtils";
import PropTypes from "prop-types";

const Layout7 = ({ panelIds, id, onRender }) => {
  const [id0, id1, id2, id3, id4, id5, id6] = panelIds;

  switch (id) {
    case layoutIds.l7s2122:
      return (
        <ResizerWrap vertical>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s21} onRender={onRender} />
          <Layout4 panelIds={[id3, id4, id5, id6]} id={layoutIds.l4s22} onRender={onRender} />
        </ResizerWrap>
      );
    case layoutIds.l7s2212:
      return (
        <ResizerWrap vertical>
          <Layout4 panelIds={[id0, id1, id2, id3]} id={layoutIds.l4s22} onRender={onRender} />
          <Layout3 panelIds={[id4, id5, id6]} id={layoutIds.l3s12} onRender={onRender} />
        </ResizerWrap>
      );
    case layoutIds.l7s2221:
      return (
        <ResizerWrap vertical>
          <Layout4 panelIds={[id0, id1, id2, id3]} id={layoutIds.l4s22} onRender={onRender} />
          <Layout3 panelIds={[id4, id5, id6]} id={layoutIds.l3s21} onRender={onRender} />
        </ResizerWrap>
      );
    case layoutIds.l7s2221v:
      return (
        <ResizerWrap>
          <Layout4 panelIds={[id0, id1, id2, id3]} id={layoutIds.l4s22} onRender={onRender} />
          <Layout3 panelIds={[id4, id5, id6]} id={layoutIds.l3s21v} onRender={onRender} />
        </ResizerWrap>
      );
    case layoutIds.l7s2212v:
      return (
        <ResizerWrap>
          <Layout4 panelIds={[id0, id1, id2, id3]} id={layoutIds.l4s22} onRender={onRender} />
          <Layout3 panelIds={[id4, id5, id6]} id={layoutIds.l3s12v} onRender={onRender} />
        </ResizerWrap>
      );
    case layoutIds.l7s2122v:
      return (
        <ResizerWrap>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s21v} onRender={onRender} />
          <Layout4 panelIds={[id3, id4, id5, id6]} id={layoutIds.l4s22} onRender={onRender} />
        </ResizerWrap>
      );
    case layoutIds.l7s1222v:
      return (
        <ResizerWrap>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s12v} onRender={onRender} />
          <Layout4 panelIds={[id3, id4, id5, id6]} id={layoutIds.l4s22} onRender={onRender} />
        </ResizerWrap>
      );
    case layoutIds.l7s1222:
    default:
      return (
        <ResizerWrap vertical>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s12} onRender={onRender} />
          <Layout4 panelIds={[id3, id4, id5, id6]} id={layoutIds.l4s22} onRender={onRender} />
        </ResizerWrap>
      );
  }
};

Layout7.propTypes = {
  id: PropTypes.string,
  onRender: PropTypes.func,
  panelIds: PropTypes.array,
};

export default Layout7;
