import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CheckYesIcon, CheckNoIcon } from "../../assets/CommonIcons";

import { useTranslation } from "react-i18next";
import { useDialogContext } from "../dialog/DialogContext";
import { IconButton } from "../views";
import {
  setFacebookCreds,
  setTwitterUserName,
  getSocialsData,
  getDeliveryMethods
} from "./SubscriptionsAPI";
import { setSocialsData } from "./SubscriptionsUtils";

import TwitterDialog from "./TwitterDialog";
import FacebookAuthButton from "./FacebookAuthButton";
import { useDispatch } from "react-redux";
import { actionAddMessage } from "../../redux/actions";
import { TOAST_TYPE } from "../popup/Toaster";
import classnames from "classnames";

export const deliveryMethods = [
  {
    id: "inApp",
    label: "In app"
  },
  {
    id: "email",
    label: "Email"
  },
  {
    id: "facebook",
    label: "Facebook"
  },
  {
    id: "twitter",
    label: "Twitter"
  }
];

const DeliveryView = ({ allDisabled, selectedMethods, setSelectedMethods, editMode }) => {
  const { t } = useTranslation();
  const { showDialog } = useDialogContext();
  const [facebookData, setFacebookData] = useState();
  const [twitterData, setTwitterData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getSocialsData().then(({ fb, twitter }) => {
      setSocialsData(fb, twitter, setFacebookData, setTwitterData);
    });
    if (!editMode) {
      getDeliveryMethods().then((methods) => {
        let newSelected = [...selectedMethods];
        if (methods && methods.length > 0) {
          methods.forEach((item) => {
            if (item.enabled && !newSelected.includes(item.code)) {
              newSelected.push(item.code);
            }
          });
        }
        setSelectedMethods(newSelected);
      });
    }
  }, []);

  const addDeliveryMethod = (methodId) => {
    selectedMethods.push(methodId);
    setSelectedMethods([...selectedMethods]);
  };

  const handleConnectClick = (methodId, onFacebookClick) => {
    if (selectedMethods.includes(methodId)) {
      if (methodId === "facebook") {
        setFacebookData();
      } else if (methodId === "twitter") {
        setTwitterData();
      }
      selectedMethods.splice(selectedMethods.indexOf(methodId), 1);
      setSelectedMethods([...selectedMethods]);
    } else {
      if (methodId === "facebook") {
        if (facebookData) {
          addDeliveryMethod(methodId);
          return;
        }
        onFacebookClick();
      } else if (methodId === "twitter") {
        if (twitterData) {
          addDeliveryMethod(methodId);
          return;
        }
        showDialog(
          <TwitterDialog
            onConfirm={(userName) => {
              setTwitterUserName(userName).then((status) => {
                if (status === 200) {
                  setTwitterData(userName);
                  addDeliveryMethod(methodId);
                } else {
                  dispatch(
                    actionAddMessage(t("socialsError", { social: "Twitter" }), TOAST_TYPE.error)
                  );
                }
              });
            }}
          />,
          { title: "twitterName" }
        );
      } else {
        addDeliveryMethod(methodId);
      }
    }
  };

  let onFacebookClick;
  const renderFacebookConnect = (text, textStyle) => {
    return (
      <FacebookAuthButton
        onRenderComponent={(onClick) => {
          onFacebookClick = onClick;
          return (
            <span className={textStyle} onClick={() => handleConnectClick("facebook", onClick)}>
              {text}
            </span>
          );
        }}
        onFbAuth={(fbCreds) => {
          setFacebookCreds(fbCreds).then((status) => {
            if (status === 200) {
              setFacebookData(fbCreds);
              addDeliveryMethod("facebook");
            } else {
              dispatch(
                actionAddMessage(t("socialsError", { social: "Facebook" }), TOAST_TYPE.error)
              );
            }
          });
        }}
      />
    );
  };

  return (
    <div className={classnames("add-sub-options-block delivery-methods", {
      "subs-disabled": allDisabled
    })}>
      <span className="add-sub-block-title">{t("deliveryMethods")}</span>
      {deliveryMethods.map((method) => {
        const { id, label } = method;
        const isDefault = id === deliveryMethods[0].id;
        const isSelected = selectedMethods.includes(id);
        const icon = isDefault || isSelected ? CheckYesIcon : CheckNoIcon;
        
        const textStyle = isDefault
          ? "add-sub-delivery-default"
          : "add-sub-connect" + (isSelected ? " add-sub-option-text" : "");
        const textKey = isSelected ? "connected" : "connect";
        const checkStyle = isDefault ? " add-sub-delivery-default-icon" : " add-sub-checkbox-icon";

        return (
          <div key={id} className="add-sub-option-container">
            <span
              className="add-sub-delivery-checkbox add-sub-option-label"
              onClick={
                isDefault
                  ? null
                  : () => handleConnectClick(id, id === "facebook" ? onFacebookClick : undefined)
              }>
              <IconButton icon={icon} className={"add-sub-delivery-icon" + checkStyle} />
              {label}
            </span>
            {id === "facebook" ? (
              renderFacebookConnect(t(textKey), textStyle)
            ) : (
              <span className={textStyle} onClick={isDefault ? null : () => handleConnectClick(id)}>
                {t(textKey)}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

DeliveryView.propTypes = {
  allDisabled: PropTypes.bool,
  selectedMethods: PropTypes.array,
  setSelectedMethods: PropTypes.func,
  editMode: PropTypes.bool
};

export default DeliveryView;
