import { BookFilterFormatIds } from "../../utils/FilterBooksUtils";

export const BookInteractTypes = {
  open: "open",
  read: "read",
  listen: "listen",
  buy: "buy",
  pdf: "pdf",
  epub: "epub",
  mobi: "mobi",
  translate: "translate",
};

export const mapFilterFormatToBtnInteractType = {
  [BookFilterFormatIds.pdf]: BookInteractTypes.pdf,
  [BookFilterFormatIds.ePub]: BookInteractTypes.epub,
  [BookFilterFormatIds.mobi]: BookInteractTypes.mobi,
  [BookFilterFormatIds.mp3]: BookInteractTypes.listen,
};
