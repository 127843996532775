/* eslint-disable max-len */
import React from "react";

export const ReadIcon = (props) => (
  <svg width="20" height="21" viewBox="0 0 20 21" {...props}>
    <path d="M19.6548 1.1581C19.3697 1.1581 19.1022 1.16166 18.8342 1.17031V0.03125H18.5357C12.7364 0.03125 10.7923 1.76733 10.1653 2.70434C9.53827 1.76733 7.59418 0.03125 1.79486 0.03125H1.49639V1.16981C1.22883 1.16064 0.961269 1.1581 0.676629 1.1581H0V17.6231H0.676629C7.83125 17.6231 9.29145 19.6768 9.49964 20.058H10.8423C11.0688 19.659 12.5575 17.6231 19.6552 17.6231H20.3314V1.1581H19.6548ZM9.718 16.8806C8.71892 15.9583 6.62316 14.8885 2.24296 14.8172V0.972327C8.37776 1.07107 9.56267 3.17462 9.718 3.51715V16.8806ZM18.0884 14.8172C13.7082 14.8885 11.6125 15.9573 10.6134 16.8806V3.52174C10.7826 3.14663 12.0008 1.07158 18.0884 0.973345V14.8172Z" />
  </svg>
);

export const SkipBackIcon = (props) => (
  <svg width="43" height="45" viewBox="0 0 43 45" fill="none" {...props}>
    <path d="M23.8339 43.1751H27.5412V21.2437H24.6874C23.7806 22.3267 21.4868 23.3014 20.5 23.6534V26.4422C20.9534 26.2256 23.4072 25.657 23.8339 25.3863V43.1751Z" />
    <path d="M37.3723 43.5C41.133 43.5 43 40.8736 43 37.1372V27.417C43 23.5993 41.2397 21 37.3723 21C33.4517 21 31.7447 23.5722 31.7447 27.417V37.1372C31.7447 40.8736 33.585 43.5 37.3723 43.5ZM37.3723 40.278C35.7721 40.278 35.4787 38.491 35.4787 37.083V27.4711C35.4787 25.982 35.6654 24.222 37.3723 24.222C39.0526 24.222 39.266 25.982 39.266 27.4711V37.083C39.266 38.491 38.9993 40.278 37.3723 40.278Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.84782 11.3301L18.8173 0.5V9.58329C22.2391 9.74286 25.5388 10.9014 28.3072 12.9151C30.9815 14.8604 33.0464 17.5211 34.2656 20.5865C33.036 20.2053 31.729 20 30.374 20C30.1349 20 29.8973 20.0064 29.6614 20.019C28.7559 18.5179 27.5637 17.1985 26.1439 16.1403C24.0155 14.5541 21.4687 13.6241 18.8173 13.465V22.1602L5.84782 11.3301ZM19.5334 40.5264C20.413 41.8121 21.5152 42.9334 22.7847 43.8346C22.2398 43.9892 21.6861 44.1177 21.1254 44.2193C17.7549 44.8298 14.2789 44.4395 11.1285 43.0968C7.97813 41.7541 5.29179 39.518 3.40257 36.6657C1.51335 33.8134 0.504322 30.4704 0.500591 27.0512C0.500591 26.5364 0.705452 26.0427 1.07011 25.6787C1.43476 25.3148 1.92934 25.1103 2.44504 25.1103C2.96074 25.1103 3.45531 25.3148 3.81997 25.6787C4.18462 26.0427 4.38948 26.5364 4.38948 27.0512C4.39427 29.7025 5.17614 32.2944 6.63872 34.5076C8.1013 36.7208 10.1807 38.4585 12.6206 39.5064C14.8011 40.443 17.1844 40.7921 19.5334 40.5264Z"
    />
  </svg>
);

export const SkipForwardIcon = (props) => (
  <svg width="44" height="45" viewBox="0 0 44 45" fill="none" {...props}>
    <path d="M3.83392 43.1751H7.54124V21.2437H4.6874C3.78058 22.3267 1.48684 23.3014 0.5 23.6534V26.4422C0.953413 26.2256 3.40718 25.657 3.83392 25.3863V43.1751Z" />
    <path d="M17.3723 43.5C21.133 43.5 23 40.8736 23 37.1372V27.417C23 23.5993 21.2397 21 17.3723 21C13.4517 21 11.7447 23.5722 11.7447 27.417V37.1372C11.7447 40.8736 13.585 43.5 17.3723 43.5ZM17.3723 40.278C15.7721 40.278 15.4787 38.491 15.4787 37.083V27.4711C15.4787 25.982 15.6654 24.222 17.3723 24.222C19.0526 24.222 19.266 25.982 19.266 27.4711V37.083C19.266 38.491 18.9993 40.278 17.3723 40.278Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.1541 11.3301L25.1847 0.5V9.58329C21.7628 9.74286 18.4632 10.9014 15.6947 12.9151C13.0205 14.8604 10.9556 17.5211 9.73633 20.5865C10.966 20.2053 12.273 20 13.6279 20C13.867 20 14.1046 20.0064 14.3406 20.019C15.246 18.5179 16.4382 17.1985 17.8581 16.1403C19.9864 14.5541 22.5333 13.6241 25.1847 13.465V22.1602L38.1541 11.3301ZM24.4685 40.5264C23.589 41.8121 22.4867 42.9334 21.2172 43.8346C21.7621 43.9892 22.3158 44.1177 22.8765 44.2193C26.2471 44.8298 29.723 44.4395 32.8734 43.0968C36.0238 41.7541 38.7102 39.518 40.5994 36.6657C42.4886 33.8134 43.4976 30.4704 43.5014 27.0512C43.5014 26.5364 43.2965 26.0427 42.9318 25.6787C42.5672 25.3148 42.0726 25.1103 41.5569 25.1103C41.0412 25.1103 40.5466 25.3148 40.182 25.6787C39.8173 26.0427 39.6125 26.5364 39.6125 27.0512C39.6077 29.7025 38.8258 32.2944 37.3632 34.5076C35.9007 36.7208 33.8213 38.4585 31.3814 39.5064C29.2009 40.443 26.8176 40.7921 24.4685 40.5264Z"
    />
  </svg>
);

export const PauseIcon = (props) => (
  <svg width="10" height="16" viewBox="0 0 10 16" {...props}>
    <path d="M1.63132 0.697266C0.730179 0.697266 0 1.42875 0 2.33077V14.0838C0 14.9865 0.730179 15.7173 1.63132 15.7173C2.53246 15.7173 3.26264 14.9862 3.26264 14.0838V2.33077C3.26264 1.42842 2.53279 0.697266 1.63132 0.697266Z" />
    <path d="M7.50437 0.697266C6.60323 0.697266 5.87305 1.42875 5.87305 2.33077V14.0838C5.87305 14.9862 6.60323 15.7173 7.50437 15.7173C8.40551 15.7173 9.13569 14.9862 9.13569 14.0838V2.33077C9.13569 1.42875 8.40551 0.697266 7.50437 0.697266Z" />
  </svg>
);

export const PlayIcon = (props) => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" {...props}>
    <path d="M1.31261 0.138764C1.47233 0.0463261 1.6511 0 1.83013 0C2.00868 0 2.18739 0.0463261 2.34781 0.138764L14.6862 9.10372C15.0059 9.28843 15.2039 9.63041 15.2039 10.0002C15.2039 10.3699 15.0065 10.7118 14.6862 10.8963L2.34781 19.8616C2.0276 20.0461 1.63287 20.0461 1.31287 19.8616C0.992612 19.6765 0.794922 19.3344 0.794922 18.9649V1.0351C0.794922 0.665562 0.99229 0.323639 1.31261 0.138764Z" />
  </svg>
);

export const PlayCircleIcon = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.3477C12.4183 16.3477 16 12.7659 16 8.34766C16 3.92938 12.4183 0.347656 8 0.347656C3.58172 0.347656 0 3.92938 0 8.34766C0 12.7659 3.58172 16.3477 8 16.3477ZM6.71422 3.87371L12.1435 7.82039C12.3132 7.94394 12.4159 8.13797 12.415 8.34853C12.415 8.55561 12.3149 8.75051 12.1435 8.87667L6.71422 12.8199C6.59937 12.9043 6.46364 12.9469 6.32965 12.9469C6.22698 12.9469 6.12518 12.9234 6.03208 12.8764C5.81195 12.765 5.67448 12.5397 5.67448 12.2926V4.40185C5.67448 4.15736 5.81195 3.93114 6.03208 3.81977C6.25047 3.70753 6.51498 3.72928 6.71422 3.87371Z"
    />
  </svg>
);

export const PauseCircleIcon = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8.34766C16 12.7659 12.4183 16.3477 8 16.3477C3.58172 16.3477 0 12.7659 0 8.34766C0 3.92938 3.58172 0.347656 8 0.347656C12.4183 0.347656 16 3.92938 16 8.34766ZM5.86667 3.54766C5.27744 3.54766 4.8 4.01518 4.8 4.5917V12.1036C4.8 12.6806 5.27744 13.1477 5.86667 13.1477C6.45589 13.1477 6.93333 12.6803 6.93333 12.1036V4.5917C6.93333 4.01497 6.45611 3.54766 5.86667 3.54766ZM10.1333 3.54766C9.54411 3.54766 9.06667 4.01518 9.06667 4.5917V12.1036C9.06667 12.6803 9.54411 13.1477 10.1333 13.1477C10.7226 13.1477 11.2 12.6803 11.2 12.1036V4.5917C11.2 4.01518 10.7226 3.54766 10.1333 3.54766Z"
    />
  </svg>
);

export const PlayIconHeader = (props) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 10.1484C19 15.119 14.9706 19.1484 10 19.1484C5.02944 19.1484 1 15.119 1 10.1484C1 5.17787 5.02944 1.14844 10 1.14844C14.9706 1.14844 19 5.17787 19 10.1484ZM20 10.1484C20 15.6713 15.5228 20.1484 10 20.1484C4.47715 20.1484 0 15.6713 0 10.1484C0 4.62559 4.47715 0.148438 10 0.148438C15.5228 0.148438 20 4.62559 20 10.1484ZM8.35641 4.31192L15.4393 9.4606C15.6606 9.62178 15.7945 9.8749 15.7934 10.1496C15.7934 10.4197 15.6629 10.674 15.4393 10.8386L8.35641 15.9827C8.20658 16.0928 8.02951 16.1484 7.85471 16.1484C7.72077 16.1484 7.58797 16.1178 7.46651 16.0565C7.17934 15.9112 7 15.6172 7 15.2949V5.0009C7 4.68195 7.17934 4.38683 7.46651 4.24154C7.75142 4.09512 8.09648 4.12349 8.35641 4.31192Z"
    />
  </svg>
);

export const TimerIcon = (props) => (
  <svg width="80" height="50" viewBox="0 0 80 50" fill="none" {...props}>
    <mask id="path-1-inside-1">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 3H30V5H50V3ZM27 3V5H3C1.34315 5 0 6.34315 0 8V43C0 44.6569 1.34315 46 3 46H9.60001V48C9.60001 49.1046 10.4954 50 11.6 50H23.6C24.7046 50 25.6 49.1046 25.6 48V46H54.3999V48C54.3999 49.1046 55.2953 50 56.3999 50H68.3999C69.5045 50 70.3999 49.1046 70.3999 48V46H77C78.6569 46 80 44.6569 80 43V8C80 6.34315 78.6569 5 77 5H53V3C53 1.34315 51.6569 0 50 0H30C28.3431 0 27 1.34315 27 3ZM30 8H50H77V43H3L3 8H30Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 3H30V5H50V3ZM27 3V5H3C1.34315 5 0 6.34315 0 8V43C0 44.6569 1.34315 46 3 46H9.60001V48C9.60001 49.1046 10.4954 50 11.6 50H23.6C24.7046 50 25.6 49.1046 25.6 48V46H54.3999V48C54.3999 49.1046 55.2953 50 56.3999 50H68.3999C69.5045 50 70.3999 49.1046 70.3999 48V46H77C78.6569 46 80 44.6569 80 43V8C80 6.34315 78.6569 5 77 5H53V3C53 1.34315 51.6569 0 50 0H30C28.3431 0 27 1.34315 27 3ZM30 8H50H77V43H3L3 8H30Z"
    />
    <path
      d="M30 3V1H28V3H30ZM50 3H52V1H50V3ZM30 5H28V7H30V5ZM50 5V7H52V5H50ZM27 5V7H29V5H27ZM9.60001 46H11.6V44H9.60001V46ZM25.6 46V44H23.6V46H25.6ZM54.3999 46H56.3999V44H54.3999V46ZM70.3999 46V44H68.3999V46H70.3999ZM53 5H51V7H53V5ZM77 8H79V6H77V8ZM77 43V45H79V43H77ZM3 43H1V45H3V43ZM3 8V6H1V8H3ZM30 5H50V1H30V5ZM32 5V3H28V5H32ZM50 3H30V7H50V3ZM48 3V5H52V3H48ZM29 5V3H25V5H29ZM3 7H27V3H3V7ZM2 8C2 7.44772 2.44771 7 3 7V3C0.238577 3 -2 5.23858 -2 8H2ZM2 43V8H-2V43H2ZM3 44C2.44772 44 2 43.5523 2 43H-2C-2 45.7614 0.238579 48 3 48V44ZM9.60001 44H3V48H9.60001V44ZM11.6 48V46H7.60001V48H11.6ZM11.6 48H7.60001C7.60001 50.2091 9.39087 52 11.6 52V48ZM23.6 48H11.6V52H23.6V48ZM23.6 48V52C25.8091 52 27.6 50.2091 27.6 48H23.6ZM23.6 46V48H27.6V46H23.6ZM54.3999 44H25.6V48H54.3999V44ZM56.3999 48V46H52.3999V48H56.3999ZM56.3999 48H52.3999C52.3999 50.2091 54.1908 52 56.3999 52V48ZM68.3999 48H56.3999V52H68.3999V48ZM68.3999 48V52C70.609 52 72.3999 50.2091 72.3999 48H68.3999ZM68.3999 46V48H72.3999V46H68.3999ZM77 44H70.3999V48H77V44ZM78 43C78 43.5523 77.5523 44 77 44V48C79.7614 48 82 45.7614 82 43H78ZM78 8V43H82V8H78ZM77 7C77.5523 7 78 7.44772 78 8H82C82 5.23858 79.7614 3 77 3V7ZM53 7H77V3H53V7ZM51 3V5H55V3H51ZM50 2C50.5523 2 51 2.44772 51 3H55C55 0.238576 52.7614 -2 50 -2V2ZM30 2H50V-2H30V2ZM29 3C29 2.44772 29.4477 2 30 2V-2C27.2386 -2 25 0.238577 25 3H29ZM50 6H30V10H50V6ZM77 6H50V10H77V6ZM79 43V8H75V43H79ZM3 45H77V41H3V45ZM1 8L1 43H5V8H1ZM30 6H3V10H30V6Z"
      mask="url(#path-1-inside-1)"
    />
  </svg>
);

export const TimerDisabledIcon = (props) => (
  <svg width="80" height="50" viewBox="0 0 80 50" fill="none" {...props}>
    <mask id="path-1-inside-1">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 3H30V5H50V3ZM27 3V5H3C1.34315 5 0 6.34315 0 8V43C0 44.6569 1.34315 46 3 46H9.60001V48C9.60001 49.1046 10.4954 50 11.6 50H23.6C24.7046 50 25.6 49.1046 25.6 48V46H54.3999V48C54.3999 49.1046 55.2953 50 56.3999 50H68.3999C69.5045 50 70.3999 49.1046 70.3999 48V46H77C78.6569 46 80 44.6569 80 43V8C80 6.34315 78.6569 5 77 5H53V3C53 1.34315 51.6569 0 50 0H30C28.3431 0 27 1.34315 27 3ZM30 8H50H77V43H3L3 8H30Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 3H30V5H50V3ZM27 3V5H3C1.34315 5 0 6.34315 0 8V43C0 44.6569 1.34315 46 3 46H9.60001V48C9.60001 49.1046 10.4954 50 11.6 50H23.6C24.7046 50 25.6 49.1046 25.6 48V46H54.3999V48C54.3999 49.1046 55.2953 50 56.3999 50H68.3999C69.5045 50 70.3999 49.1046 70.3999 48V46H77C78.6569 46 80 44.6569 80 43V8C80 6.34315 78.6569 5 77 5H53V3C53 1.34315 51.6569 0 50 0H30C28.3431 0 27 1.34315 27 3ZM30 8H50H77V43H3L3 8H30Z"
    />
    <path
      d="M30 3V1H28V3H30ZM50 3H52V1H50V3ZM30 5H28V7H30V5ZM50 5V7H52V5H50ZM27 5V7H29V5H27ZM9.60001 46H11.6V44H9.60001V46ZM25.6 46V44H23.6V46H25.6ZM54.3999 46H56.3999V44H54.3999V46ZM70.3999 46V44H68.3999V46H70.3999ZM53 5H51V7H53V5ZM77 8H79V6H77V8ZM77 43V45H79V43H77ZM3 43H1V45H3V43ZM3 8V6H1V8H3ZM30 5H50V1H30V5ZM32 5V3H28V5H32ZM50 3H30V7H50V3ZM48 3V5H52V3H48ZM29 5V3H25V5H29ZM3 7H27V3H3V7ZM2 8C2 7.44772 2.44771 7 3 7V3C0.238577 3 -2 5.23858 -2 8H2ZM2 43V8H-2V43H2ZM3 44C2.44772 44 2 43.5523 2 43H-2C-2 45.7614 0.238579 48 3 48V44ZM9.60001 44H3V48H9.60001V44ZM11.6 48V46H7.60001V48H11.6ZM11.6 48H7.60001C7.60001 50.2091 9.39087 52 11.6 52V48ZM23.6 48H11.6V52H23.6V48ZM23.6 48V52C25.8091 52 27.6 50.2091 27.6 48H23.6ZM23.6 46V48H27.6V46H23.6ZM54.3999 44H25.6V48H54.3999V44ZM56.3999 48V46H52.3999V48H56.3999ZM56.3999 48H52.3999C52.3999 50.2091 54.1908 52 56.3999 52V48ZM68.3999 48H56.3999V52H68.3999V48ZM68.3999 48V52C70.609 52 72.3999 50.2091 72.3999 48H68.3999ZM68.3999 46V48H72.3999V46H68.3999ZM77 44H70.3999V48H77V44ZM78 43C78 43.5523 77.5523 44 77 44V48C79.7614 48 82 45.7614 82 43H78ZM78 8V43H82V8H78ZM77 7C77.5523 7 78 7.44772 78 8H82C82 5.23858 79.7614 3 77 3V7ZM53 7H77V3H53V7ZM51 3V5H55V3H51ZM50 2C50.5523 2 51 2.44772 51 3H55C55 0.238576 52.7614 -2 50 -2V2ZM30 2H50V-2H30V2ZM29 3C29 2.44772 29.4477 2 30 2V-2C27.2386 -2 25 0.238577 25 3H29ZM50 6H30V10H50V6ZM77 6H50V10H77V6ZM79 43V8H75V43H79ZM3 45H77V41H3V45ZM1 8L1 43H5V8H1ZM30 6H3V10H30V6Z"
      mask="url(#path-1-inside-1)"
    />
    <rect
      fillRule="evenodd"
      clipRule="evenodd"
      rx="2"
      x="3"
      y="8"
      width="4"
      height="81"
      transform="rotate(-55 0.744141 2.86719)"
    />
  </svg>
);

export const PlaybackRateIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path d="M7.96639 5.44538L14.0168 9.98319L7.96639 14.521V5.44538ZM2.01681 10.9916H0C0.201681 12.874 0.907563 14.6555 2.08403 16.1345L3.52941 14.6891C2.72269 13.6134 2.18487 12.3361 2.01681 10.9916ZM20.0336 9.98319C20.0336 4.84034 16.1345 0.537815 10.9916 0V2.01681C15.395 2.58824 18.4874 6.58824 17.9496 10.9916C17.479 14.6218 14.6218 17.479 10.9916 17.9496V19.9664C16.1345 19.4622 20.0336 15.1261 20.0336 9.98319ZM4.87395 16.1681L3.46219 17.5798C5.0084 18.9244 6.95798 19.7647 9.0084 19.9664V17.9496C7.46218 17.7815 6.05042 17.1429 4.87395 16.1681ZM3.4958 5.27731L2.05042 3.83193C0.907563 5.31092 0.201681 7.12605 0 8.97479H2.01681C2.18487 7.63025 2.68908 6.35294 3.4958 5.27731ZM8.97479 2.05042V0C6.92437 0.201681 5.0084 1.04202 3.42857 2.38655L4.84034 3.79832C6.05042 2.82353 7.46219 2.21849 8.97479 2.05042Z" />
  </svg>
);


export const VolumeIconMuted = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.1569 24.8902C42.1569 32.2668 38.1615 38.9335 31.9716 41.8556C31.7412 41.9668 31.4961 42.0213 31.2583 42.0213C30.5601 42.0213 29.8909 41.5724 29.5961 40.8282C29.2074 39.8253 29.6314 38.6646 30.5397 38.2298C35.4047 35.9248 38.5505 30.6875 38.5505 24.8902C38.5505 19.0607 35.3973 13.8072 30.5027 11.5083C29.589 11.0817 29.165 9.91284 29.5593 8.90992C29.948 7.90699 31.0078 7.44183 31.9218 7.87455C38.1412 10.7985 42.1569 17.4732 42.1569 24.8902ZM34.8975 0.161795C44.0745 4.43173 50 14.1932 50 25.0232C50 35.8189 44.0895 45.558 34.948 49.842C34.7074 49.9535 34.4598 50 34.2194 50C33.513 50 32.8441 49.5588 32.5461 48.8059C32.1453 47.8024 32.5757 46.641 33.5002 46.2059C41.3093 42.5551 46.3534 34.2386 46.3534 25.0232C46.3534 15.7737 41.287 7.44263 33.4554 3.79822C32.5311 3.36338 32.1027 2.20168 32.5032 1.19796C32.8946 0.194508 33.9733 -0.270917 34.8975 0.161795ZM34.3137 25.0139C34.3137 28.8657 32.1686 32.3394 28.8532 33.8777C28.6179 33.9865 28.3601 34.0426 28.1174 34.0426C27.4044 34.0426 26.7287 33.6027 26.4275 32.8577C26.023 31.8615 26.4502 30.7088 27.3912 30.2772C29.3517 29.3673 30.6238 27.295 30.6238 25.0139C30.6238 22.7067 29.3385 20.6282 27.3458 19.7346C26.4127 19.311 25.9723 18.1583 26.3748 17.1543C26.7718 16.1562 27.8539 15.6942 28.7873 16.1099C32.1461 17.632 34.3137 21.1304 34.3137 25.0139ZM23.3172 3.49104C24.0012 3.79822 24.4446 4.51737 24.4522 5.32163V44.8019C24.4522 45.6 24.0086 46.3173 23.3248 46.6263C23.0993 46.7295 22.8588 46.7761 22.6257 46.7761C22.1525 46.7761 21.6865 46.5779 21.3363 46.192L10.2721 34.1716H1.82083C0.816176 34.1716 0 33.2867 0 32.1974V17.928C0 16.8309 0.816176 15.9461 1.82083 15.9461H10.3076L21.3287 3.92535C21.8544 3.3578 22.639 3.19211 23.3172 3.49104Z"
    />
    <rect
      x="0.167969"
      y="3.38281"
      width="4.54545"
      height="65.843"
      rx="2.27273"
      transform="rotate(-45 0.167969 3.38281)"
    />
  </svg>
);

export const VolumeIcon = (props) => (
  <svg width="50" height="50" viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.1569 24.8902C42.1569 32.2668 38.1615 38.9335 31.9716 41.8556C31.7412 41.9668 31.4961 42.0213 31.2583 42.0213C30.5601 42.0213 29.8909 41.5724 29.5961 40.8282C29.2074 39.8253 29.6314 38.6646 30.5397 38.2298C35.4047 35.9248 38.5505 30.6875 38.5505 24.8902C38.5505 19.0607 35.3973 13.8072 30.5027 11.5083C29.589 11.0817 29.165 9.91284 29.5593 8.90992C29.948 7.90699 31.0078 7.44183 31.9218 7.87455C38.1412 10.7985 42.1569 17.4732 42.1569 24.8902ZM34.8975 0.161795C44.0745 4.43173 50 14.1932 50 25.0232C50 35.8189 44.0895 45.558 34.948 49.842C34.7074 49.9535 34.4598 50 34.2194 50C33.513 50 32.8441 49.5588 32.5461 48.8059C32.1453 47.8024 32.5757 46.641 33.5002 46.2059C41.3093 42.5551 46.3534 34.2386 46.3534 25.0232C46.3534 15.7737 41.287 7.44263 33.4554 3.79822C32.5311 3.36338 32.1027 2.20168 32.5032 1.19796C32.8946 0.194508 33.9733 -0.270917 34.8975 0.161795ZM34.3137 25.0139C34.3137 28.8657 32.1686 32.3394 28.8532 33.8777C28.6179 33.9865 28.3601 34.0426 28.1174 34.0426C27.4044 34.0426 26.7287 33.6027 26.4275 32.8577C26.023 31.8615 26.4502 30.7088 27.3912 30.2772C29.3517 29.3673 30.6238 27.295 30.6238 25.0139C30.6238 22.7067 29.3385 20.6282 27.3458 19.7346C26.4127 19.311 25.9723 18.1583 26.3748 17.1543C26.7718 16.1562 27.8539 15.6942 28.7873 16.1099C32.1461 17.632 34.3137 21.1304 34.3137 25.0139ZM23.3172 3.49104C24.0012 3.79822 24.4446 4.51737 24.4522 5.32163V44.8019C24.4522 45.6 24.0086 46.3173 23.3248 46.6263C23.0993 46.7295 22.8588 46.7761 22.6257 46.7761C22.1525 46.7761 21.6865 46.5779 21.3363 46.192L10.2721 34.1716H1.82083C0.816176 34.1716 0 33.2867 0 32.1974V17.928C0 16.8309 0.816176 15.9461 1.82083 15.9461H10.3076L21.3287 3.92535C21.8544 3.3578 22.639 3.19211 23.3172 3.49104Z"
    />
  </svg>
);
