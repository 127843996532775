import React from "react";
import PropTypes from "prop-types";
import "./timerFlag.scss";

const TimerFlag = ({ children, duration, timerValue, isEnabled }) => {
  let flagPosition = (timerValue * 100) / duration;
  if (flagPosition > 100) {
    flagPosition = 100;
  }
  return (
    <div className="timer-flag-wrap">
      {isEnabled && <div className="timer-flag" style={{ left: `${flagPosition}%` }} />}
      {children}
    </div>
  );
};

TimerFlag.propTypes = {
  children: PropTypes.node,
  duration: PropTypes.number,
  timerValue: PropTypes.number,
  isEnabled: PropTypes.bool
};

export default TimerFlag;
