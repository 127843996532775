import { HistoryType } from "../utils/Utils";
//TODO finish move all actions in this file
export const ACTION_SAVE_READ = "ACTION_SAVE_READ";
export const ACTION_SAVE_LISTEN = "ACTION_SAVE_LISTEN";
export const TOGGLE_BOOK_IN_ML_BY_ID = "TOGGLE_BOOK_IN_ML_BY_ID";
export const FETCH_ALL_BOOKS_DATA = "FETCH_ALL_BOOKS_DATA";
export const FETCH_BOOKS_INFO = "FETCH_BOOKS_INFO";
export const FETCH_SEARCH_HISTORY = "FETCH_SEARCH_HISTORY";
export const FETCH_LIB_LANGS = "FETCH_LIB_LANGS";
export const SET_LIB_LANGS = "SET_LIB_LANGS";
export const FORM_BOOK_DATA_SUCCESS = "FORM_BOOK_DATA_SUCCESS";
export const ACTION_LAST_TIME_OPEN = "ACTION_LAST_TIME_OPEN";

export const ACTION_STORE_HISTORY_DATA = "ACTION_STORE_HISTORY_DATA";

export const actionStoreHistoryData = (data) => ({
  type: ACTION_STORE_HISTORY_DATA,
  data
});

export const fetchAllBooksData = (historyTypes = []) => ({
  type: FETCH_ALL_BOOKS_DATA,
  data: historyTypes
});

export const actionSaveListen = (chapterId, position) => ({
  type: ACTION_SAVE_LISTEN,
  data: { paraId: chapterId, position: position }
});

export const actionToggleBookInMLById = (bookId) => ({
  type: TOGGLE_BOOK_IN_ML_BY_ID,
  data: { bookId }
});

export const actionSaveRead = (para) => ({
  type: ACTION_SAVE_READ,
  data: { paraId: para, justSync: !para }
});
 
export const fetchSearchHistory = () => ({
  type: FETCH_SEARCH_HISTORY,
  data: { historyType: HistoryType.SEARCH }
});

export const fetchBooksInfo = (data) => ({
  type: FETCH_BOOKS_INFO, data
});

export const fetchLibLangs = (data) => ({
  type: FETCH_LIB_LANGS, data
});

export const setLibLangs = (data) => ({
  type: SET_LIB_LANGS, data
});

export const formBookDataSuccess = (data) => ({
  type: FORM_BOOK_DATA_SUCCESS, data
});

export const actionLastOpenML = data => ({ type: ACTION_LAST_TIME_OPEN, data });
