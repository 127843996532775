import { put, call, select, takeLatest, all } from "redux-saga/effects";

import {
  FETCH_CONTENT_BY_DATE,
  ACTION_REFRESH_CONTENT,
  FETCH_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTIONS,
  FETCH_THOUGHTS,
  FETCH_MONTH_CALENDAR,
  ACTION_MARK_AS_READ,
  ACTION_SET_USER_TIME,
  ACTION_MARK_ALL_READ,
  ACTION_UPDATE_SUBS_COUNT,
  ACTION_FETCH_FEEDS,
  ACTION_FETCH_NEWS_BY_DATE,
  ACTION_ACTIVATE,
  ACTION_UPDATE_FEED,
  ACTION_UPDATE_SUBSCRIPTION,
  fetchContentByDateSuccess,
  fetchSubscriptionsSuccess,
  fetchThoughtsSuccess,
  actionClear,
  fetchContentByDate,
  actionRefreshContent,
  fetchMonthCalendarSuccess,
  actionMarkAsReadSuccess,
  actionUpdateSubsCount,
  actionGetSubscriptions,
  actionUpdateSubsCountSuccess,
  actionFetchNewsByDateSuccess,
  fetchFeedsSuccess,
  actionFeedsLoading,
  fetchFeeds,
  actionUpdateFeedSuccess,
  actionUpdateSubscriptionSuccess,
} from "./subscriptions.actions";

import {
  getAllSubscriptionsRequest,
  getSubsContentForDate,
  getThoughtsByDate,
  getCalendarForMonth,
  createSubscription,
  deleteSubscription,
  markAsRead,
  setUserTime,
  markAllAsRead,
  fetchUnreadCountRequest,
  getAllFeeds,
  pauseSubscription,
  startSubscription,
  activateFeed,
  updateFeed,
  updateSubscription,
} from "./SubscriptionsAPI";
import { normalizeSubsContent, cutOutHeaders } from "../../utils/ContentUtils";
import { actionAddMessage, actionUpdateSettings } from "../../redux/actions";
import { Settings } from "../../utils/Settings";

export function* fetchSubsMethod() {
  const { isLogin } = yield select((state) => state.system);
  if (isLogin) {
    const subs = yield call(getAllSubscriptionsRequest);
    if (subs) {
      yield put(fetchSubscriptionsSuccess(subs));
    }
  }
}

export function* fetchContentByDateMethod(action) {
  const date = action.data;
  const dateContent = yield call(getSubsContentForDate, date);

  if (dateContent) {
    yield put(
      fetchContentByDateSuccess({
        date,
        dateContent: cutOutHeaders(normalizeSubsContent(dateContent.subscriptions)),
      }),
    );
  }
}

export function* fetchNewsByDateAsync(action) {
  const date = action.data;

  const content = yield call(getSubsContentForDate, date);

  if (content) {
    yield put(actionFetchNewsByDateSuccess({ date, dateNews: content.feeds }));
  }
}

export function* updateSubsMethod(action) {
  const { allSubs } = yield select((state) => state.subscriptions);
  let updatedSubs = [...allSubs];
  const { subId, params, fetchContent, date, bookType } = action.data;

  let response;
  if (params) {
    response = yield call(createSubscription, params);
  } else {
    response = yield call(deleteSubscription, subId);
    yield put(actionUpdateSubsCount());
  }
  if (params && response) {
    // stop previous subscription (can be only one active book with this type)
    if (bookType === "devotional") {
      const [activeDevotional] = updatedSubs.filter((item) => {
        return item.book?.book_type === "devotional" && item.active;
      });

      if (activeDevotional) {
        const deactivatedBook = yield call(pauseSubscription, activeDevotional.id);

        if (deactivatedBook) {
          const index = updatedSubs.findIndex((item) => item.id === deactivatedBook.id);
          if (index !== -1) {
            updatedSubs.splice(index, 1, deactivatedBook);
          }
        }
      }
    }

    // start subscription for the new one
    if (response.book?.book_type === "devotional" && !response.active) {
      const activatedBook = yield call(startSubscription, response.id);
      if (activatedBook) {
        response = activatedBook;
      }
    }

    updatedSubs.push(response);
    yield put(actionAddMessage("@subAdded"));
  } else if (!params && response === 204) {
    const deleteIndex = updatedSubs.findIndex((sub) => sub.id === subId);
    if (deleteIndex !== -1) {
      updatedSubs.splice(deleteIndex, 1);
      yield put(actionAddMessage("@subRemoved"));
    }
  }

  if (fetchContent) {
    yield put(actionRefreshContent(date));
  } else {
    yield put(actionClear());
  }
  yield put(fetchSubscriptionsSuccess(updatedSubs));
}

export function* deleteSubsAsync(action) {
  try {
    const { id } = action.data;
    const { isLogin } = yield select((state) => state.system);
    const { allSubs } = yield select((state) => state.subscriptions);

    if (isLogin) {
      const status = yield call(deleteSubscription, id);
      if (status !== 204) {
        return;
      }

      const updatedSubs = allSubs.filter((item) => id !== item.id);
      yield put(actionUpdateSubsCount());
      yield put(fetchSubscriptionsSuccess(updatedSubs));
    }
  } catch (error) {
    console.log("function*deleteSubsAsync -> error ->", error);
  }
}

export function* refreshContentMethod(action) {
  yield put(actionClear());
  yield put(fetchContentByDate(action.data));
}

export function* fetchThoughtsAsync(action) {
  const date = action.data;
  const dateThoughts = yield call(getThoughtsByDate, date);
  if (dateThoughts) {
    yield put(fetchThoughtsSuccess({ date, dateThoughts }));
  }
}

export function* fetchMonthCalendarAsync(action) {
  const { year, month } = action.data;
  const dates = yield call(getCalendarForMonth, year, month);
  if (dates) {
    yield put(fetchMonthCalendarSuccess({ year, month, dates }));
  }
}

export function* markAsReadAsync(action) {
  const { id, date } = action.data;
  const content = yield select((state) => state.subscriptions.content);
  const status = yield call(markAsRead, id);
  if (status === 204) {
    const contentIndex = content[date].findIndex((item) => item.baseId === id);
    if (contentIndex !== -1) {
      content[date][contentIndex].read = true;
      yield put(actionMarkAsReadSuccess(content));
      yield put(actionUpdateSubsCount());
    }
  }
}

export function* setUserTimeAsync(action) {
  const { timeZone, deliveryTime } = action.data;
  const status = yield call(setUserTime, timeZone, deliveryTime);
  if (status === 200) {
    yield put(
      actionUpdateSettings({
        [Settings.timeZone.id]: timeZone,
        [Settings.deliveryTime.id]: deliveryTime,
      }),
    );
  }
}

export function* markAllReadAsync(action) {
  const status = yield call(markAllAsRead, action.data);
  if (status === 204) {
    yield put(actionClear());
    yield put(actionUpdateSubsCountSuccess(0));
  }
}

export function* updateSubsCountAsync() {
  const { isLogin } = yield select((state) => state.system);
  const { subsCount } = yield select((state) => state.subscriptions);
  if (isLogin) {
    const count = yield call(fetchUnreadCountRequest);
    if (count || count === 0) {
      yield put(actionUpdateSubsCountSuccess(count));
    }
  } else if (subsCount !== 0) {
    yield put(actionUpdateSubsCountSuccess(0));
  }
}

export function* fetchFeedsAsync() {
  try {
    yield put(actionFeedsLoading(true));
    const feeds = yield call(getAllFeeds);
    yield put(fetchFeedsSuccess(feeds));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actionFeedsLoading(false));
  }
}

export function* activateAsync(action) {
  const { id, activate, isFeed, isTypeNews } = action.data;
  let updateFun;
  if (isFeed) {
    updateFun = activateFeed;
  } else {
    updateFun = activate ? startSubscription : pauseSubscription;
  }
  const updated = yield call(updateFun, id, activate);
  if (updated) {
    if (isFeed) {
      yield put(fetchFeeds());
    } else {
      yield put(actionGetSubscriptions());
    }
    yield put(actionAddMessage(isTypeNews ? "@newsAddedToSubs" : "@thoughtsAddedToSubs"));
  }
}

export function* updateFeedSubsAsync(action) {
  try {
    const isFeed = action.type === ACTION_UPDATE_FEED;
    let fn = isFeed ? updateFeed : updateSubscription;
    let fnSuccess = isFeed ? actionUpdateFeedSuccess : actionUpdateSubscriptionSuccess;

    const updated = yield call(fn, action.data);
    if (updated) {
      const { feeds, allSubs } = yield select((state) => state.subscriptions);

      let data = isFeed ? feeds : allSubs;

      const subscriptionIndex = data.findIndex((item) => item.id === updated.id);
      const updatedData = [...data];
      updatedData[subscriptionIndex] = updated;

      yield put(fnSuccess(updatedData));
    }
  } catch (error) {
    console.log(`function* updateFeedSubsAsync ${action.type} => : ${error}`);
  }
}

export default function* subscriptions() {
  yield all([
    takeLatest(FETCH_CONTENT_BY_DATE, fetchContentByDateMethod),
    takeLatest(ACTION_REFRESH_CONTENT, refreshContentMethod),
    takeLatest(FETCH_SUBSCRIPTIONS, fetchSubsMethod),
    takeLatest(UPDATE_SUBSCRIPTIONS, updateSubsMethod),
    takeLatest(DELETE_SUBSCRIPTIONS, deleteSubsAsync),
    takeLatest(FETCH_THOUGHTS, fetchThoughtsAsync),
    takeLatest(FETCH_MONTH_CALENDAR, fetchMonthCalendarAsync),
    takeLatest(ACTION_MARK_AS_READ, markAsReadAsync),
    takeLatest(ACTION_SET_USER_TIME, setUserTimeAsync),
    takeLatest(ACTION_MARK_ALL_READ, markAllReadAsync),
    takeLatest(ACTION_UPDATE_SUBS_COUNT, updateSubsCountAsync),
    takeLatest(ACTION_FETCH_FEEDS, fetchFeedsAsync),
    takeLatest(ACTION_ACTIVATE, activateAsync),
    takeLatest(ACTION_FETCH_NEWS_BY_DATE, fetchNewsByDateAsync),
    takeLatest(ACTION_UPDATE_FEED, updateFeedSubsAsync),
    takeLatest(ACTION_UPDATE_SUBSCRIPTION, updateFeedSubsAsync),
  ]);
}
