export function getCookie(name, cookie, defaultValue) {
  if (!cookie) {
    return defaultValue;
  }
  const value = `; ${cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
  return defaultValue;
}

export function setCookie(name, value, maxAge = 100000000) {
  document.cookie = `${name}=${value};path=/;max-age=${maxAge}`;
}

export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
