import React, { useEffect, useState, useRef, useMemo } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { StudyCenterActions } from "../studyCenter.actions";
import { useDialogContext } from "../../dialog/DialogContext";
import { IconButton, Scroll } from "../../views";
import asyncComponent from "../../AsyncComponent";
import { getContrastColor } from "../../../utils/ThemeUtils";
import ScFolder from "./ScFolder";
import { actionScElement } from "../StudyCenterUtils";
import DialogButtons from "../../dialog/DialogButtons";
import PopupWrap from "../../popup/PopupWrap";
import ColorEditPopup from "../../popup/ColorEditPopup";
import { actionUpdateSetting } from "../../../redux/actions";
import { Settings } from "../../../utils/Settings";
import { useViewMode, useAuth } from "../../../hooks";
import { ItemTypes } from "../studyCenter.constants";
import EgwWebFont from "src/assets/EgwWebFont";
import { defEditorHighLightColor } from "../EditorCenterUtils";

import "./SCDialog.scss";

const ColorPicker = asyncComponent(() => {
  return import("./ColorPicker" /* webpackChunkName: "ColorPicker" */);
});

const HL_LIST_TYPES = {
  LIST: "l",
  GRID_CIRCLE: "g_c",
  GRID: "g",
};

const HighlightDialog = ({ entry, selectionData, topic, isPanel = false }) => {
  const { isUserEditor } = useAuth();
  const { showDialog, showConfirm, hideDialog } = useDialogContext();
  const menuWrap = useRef();
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { zoom } = useViewMode();
  const hlListType = useSelector((state) => state.settings.hlListType);
  const lastActiveColor = useSelector((state) => state.settings.lastActiveColor);
  const [color, setColor] = useState(entry ? entry.color_id : lastActiveColor);
  const [folderId, setFolderId] = useState(entry ? entry.topic : undefined);
  const [scrollToElement, setScrollToElement] = useState(false);
  const editorTempEntry = useSelector((state) => state.settings.editorTempEntry);
  const currentEntryId = useSelector((state) => state.studyCenter.currentEntryId);
  const userInfo = useSelector((state) => state.settings.userInfo);
  const userId = userInfo?.id;
  const scColors = useSelector((state) => state.studyCenter.colors);
  const isColorsFetched = useSelector((state) => state.studyCenter.isColorsFetched);
  const colors = useMemo(() => {
    if (isUserEditor) {
      return [defEditorHighLightColor, ...scColors];
    }
    return [...scColors];
  }, [isUserEditor, scColors]);

  const isHlListTypeList = hlListType === HL_LIST_TYPES.LIST;
  const isHlListTypeGrid = hlListType === HL_LIST_TYPES.GRID;
  const isHlListTypeGridCircle = hlListType === HL_LIST_TYPES.GRID_CIRCLE;

  const [defColors, customColors] = useMemo(() => {
    const defColors = colors.filter((item) => item.shared);
    const customColors = colors.filter((item) => !item.shared);
    customColors.sort((prev, next) => next.lu - prev.lu);
    return [defColors, customColors];
  }, [colors]);

  useEffect(() => {
    dispatch(StudyCenterActions.fetchSCColors());
  }, []);

  useEffect(() => {
    if (isColorsFetched) {
      if (entry) {
        setFolderId(entry.topic);
      } else {
        setFolderId(topic);
      }

      if (!customColors.length) {
        const firstDefColor = isUserEditor ? defEditorHighLightColor.id : defColors[0].id;
        setColor(firstDefColor);
      } else {
        const activeEntry =
          currentEntryId === Settings.editorTempEntry.id ? editorTempEntry : entry;
        const activeColor = activeEntry ? activeEntry.color_id || activeEntry.colorId : color;
        const activeColorIndex = colors.findIndex((item) => item.id === activeColor);
        setColor(activeColorIndex === -1 ? customColors[0].id : activeColor);
      }
    }
  }, [entry, topic, customColors, isUserEditor, isColorsFetched]);

  useEffect(() => {
    if (scrollToElement && customColors.length) {
      const { view } = scrollRef.current;
      const firstCustomColor = customColors[isUserEditor ? 1 : 0].id;
      const elem = view.querySelector("[data-id='" + firstCustomColor + "']");
      setColor(firstCustomColor);
      if (elem) {
        view.scrollTop = elem.offsetTop;
      }

      setScrollToElement(false);
    }
  }, [customColors.length]);

  useEffect(() => {
    dispatch(StudyCenterActions.setActiveColor(color));
    dispatch(actionUpdateSetting(Settings.lastActiveColor.id, color));
    if (currentEntryId === Settings.editorTempEntry.id) {
      const activeColor = colors.find((item) => item.id === color);
      dispatch(
        actionUpdateSetting(Settings.editorTempEntry.id, {
          ...editorTempEntry,
          color: activeColor ? activeColor.color : defEditorHighLightColor.color,
          colorId: color,
          hasNewData: true,
        }),
      );
    }
  }, [color, colors]);

  const handleColorDelete = (selectedColor) => {
    const deleteColor = selectedColor ? selectedColor.id : color;
    if (!deleteColor) {
      return;
    }
    const colorValue = customColors.find((item) => item.id === deleteColor);
    if (colorValue) {
      showConfirm(t("deleteColor"), t("deleteColorMessage", { name: colorValue.name }), () =>
        dispatch(StudyCenterActions.deleteSCColors([deleteColor.id || deleteColor])),
      );
    }
  };

  const handleContextMenu = (e, selectedColor) => {
    e.preventDefault();
    if (color !== selectedColor.id) {
      setColor(selectedColor.id);
    }

    if (isPanel) {
      return;
    }

    menuWrap.current.showPopup(
      {
        left: e.clientX,
        top: e.clientY,
      },
      <ColorEditPopup
        onEdit={() => showDialog(<ColorPicker editColor={selectedColor} />)}
        onDelete={() => handleColorDelete(selectedColor)}
      />,
    );
  };

  const renderListType = (icon, action) => (
    <IconButton
      icon={icon}
      className="hlListIcon"
      active={action === hlListType}
      onClick={() => {
        dispatch(actionUpdateSetting(Settings.hlListType.id, action));
      }}
    />
  );

  const renderListItem = (item, index) => {
    const classAdd =
      item.id === color
        ? isHlListTypeGridCircle
          ? "scColorListItemSelectedCircle"
          : "scColorListItemSelected"
        : "";
    const textColor = getContrastColor(item.color);
    let className = "sc-color-list-item";
    let colorName = item.name;
    if (isHlListTypeGridCircle) {
      className = "sc-color-circle-item";
      colorName = item.name.substr(0, 2);
    } else if (isHlListTypeGrid) {
      className = "sc-color-grid-item";
      colorName = item.name.substr(0, 8);
      if (item.name.length > 8) {
        colorName += "...";
      }
    }

    let timer;
    const handleClick = (event) => {
      clearTimeout(timer);
      if (event.detail === 1) {
        timer = setTimeout(() => setColor(item.id), 200);
      } else if (event.detail === 2) {
        if (item.id !== null && isPanel) {
          showDialog(<ColorPicker editColor={item} />);
          setColor(item.id);
        }
      }
    };

    return (
      <button
        data-id={item.id}
        className={"sc-color-item " + className + " " + classAdd}
        onClick={handleClick}
        onContextMenu={!item.shared ? (e) => handleContextMenu(e, item) : undefined}
        key={index}
        style={{ backgroundColor: item.color, color: textColor }}>
        <span className="sc-color-item-text">{colorName}</span>
      </button>
    );
  };

  const onConfirm = () => {
    if (color) {
      const colorItem = colors.find((item) => item.id === color);
      actionScElement(dispatch, userId, {
        colorId: colorItem.id,
        color: colorItem.color,
        name: colorItem.name,
        topic: folderId,
        entry,
        selectionData,
        type: ItemTypes.highlight,
      });
      hideDialog();
    }
  };

  let hlColorRowTypeClassName;
  if (isHlListTypeList) {
    hlColorRowTypeClassName = "sc-color-row-list-type";
  } else if (isHlListTypeGrid) {
    hlColorRowTypeClassName = "sc-color-row-grid-type";
  } else if (isHlListTypeGridCircle) {
    hlColorRowTypeClassName = "sc-color-row-grid-circle-type";
  }

  let scrollStyles = {};

  if (!isPanel) {
    scrollStyles = {
      height: zoom >= 300 ? 600 : 300,
      minWidth: zoom >= 300 ? "13rem" : "none",
    };
  } else {
    scrollStyles = {
      height: "calc(100% - 3rem)",
    };
  }

  const highlightPanel = (
    <>
      <div className="sc-dialog-title">
        <span className="sc-title"> {t("highlight")}</span>
        {renderListType(EgwWebFont.list, HL_LIST_TYPES.LIST)}
        {renderListType(EgwWebFont["grid-2"], HL_LIST_TYPES.GRID)}
        {renderListType(EgwWebFont.grid, HL_LIST_TYPES.GRID_CIRCLE)}
      </div>
      <Scroll ref={scrollRef} className="sc-scroll-wrap" style={scrollStyles}>
        <div className="sc-dialog-container">
          {!!defColors.length && (
            <>
              <div className="hl-color-title">{t(isUserEditor ? "shared" : "default")}</div>
              <div className={`hl-color-row ${hlColorRowTypeClassName}`}>
                {defColors.map((item, index) => renderListItem(item, index))}
              </div>
            </>
          )}
          <div className="hl-color-title">
            {t("Custom")}
            <IconButton
              icon={EgwWebFont.plus}
              className="hl-control-btn"
              onClick={() => {
                const colorItem = colors.find((item) => item.id === color);
                let initColor = colorItem ? colorItem.color : undefined;
                showDialog(
                  <ColorPicker initColor={initColor} onSave={() => setScrollToElement(true)} />,
                  {
                    rootClassAdv: "color-picker-dialog",
                  },
                );
              }}
            />
            <IconButton icon={EgwWebFont.minus} onClick={() => handleColorDelete()} />
          </div>
          <div className={classNames("hl-color-row", hlColorRowTypeClassName)}>
            {customColors.map((item, index) => renderListItem(item, index))}
          </div>
        </div>
      </Scroll>
    </>
  );

  if (isPanel) {
    return <div className={"highlight-panel"}>{highlightPanel}</div>;
  }

  return (
    <PopupWrap ref={menuWrap}>
      <div
        className="sc-dialog"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onConfirm();
            e.preventDefault();
          }
        }}>
        {highlightPanel}
        <ScFolder
          folderId={folderId}
          onChange={(folderId) => {
            setFolderId(folderId);
          }}
        />
        <DialogButtons
          onClickNegative={hideDialog}
          disabled={!color}
          onClickPositive={() => onConfirm()}
          negativeText={t("cancel")}
          positiveText={t("save")}
        />
      </div>
    </PopupWrap>
  );
};

HighlightDialog.propTypes = {
  onClose: PropTypes.func,
  entry: PropTypes.object,
  selectionData: PropTypes.object,
  topic: PropTypes.string,
  isPanel: PropTypes.bool,
};

export default HighlightDialog;
