import { useEffect } from "react";
import { isSafari, browserVersion } from "react-device-detect";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "../components/dialog/DialogContext";
import { useDeviceDetect } from "../hooks";

/**
 * @name useSafariCheck (hook)
 * @description check if a browser is Safari and do some stuff for a particular version of one.
 * @return {undefined}
 */
export const useSafariCheck = () => {
  const { t } = useTranslation();
  const { showConfirm, hideDialog } = useDialogContext();
  const [cookie, setCookie] = useCookies();
  const version = browserVersion || "";
  const { isRealMobileOrTablet } = useDeviceDetect();
  const showVersion10 = !cookie["safari10Notify"] && isSafari && version.slice(0, 2) === "10";

  // disable zooming on mobile devices if a meta tag used for that was not applied,
  // for example: iOS Safari
  useEffect(() => {
    const onDocumentTouchmove = (e) => {
      const event = e.originalEvent || e || {};

      if (event.scale !== undefined && event.scale !== 1) {
        event.preventDefault();
      }
    };

    if (isSafari && isRealMobileOrTablet) {
      document.addEventListener("touchmove", onDocumentTouchmove);
    }

    return () => document.removeEventListener("touchmove", onDocumentTouchmove);
  }, []);

  useEffect(() => {
    const onCancelHandle = (cookieName, maxAge = 86400) => {
      hideDialog();
      setCookie(cookieName, "1", {
        maxAge,
        path: "/"
      });
    };

    if (showVersion10) {
      showConfirm(t("safari10NotifyTitle"), t("safari10NotifyText"), null, {
        noButtons: true,
        onCancel: () => onCancelHandle("safari10Notify")
      });
    }
  }, [showVersion10]);
};
