import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatDateTo} from "../../utils/Utils";
import { IconButton } from "./";
import { ChevronRightIcon } from "../../assets/CommonIcons";
import { format } from "date-fns";

import "./View.scss";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";

const MAX_HOUR = 12;
const MAX_MINUTE = 59;
const DEF_HOUR = "12";
const DEF_MINUTE = "00";
const AM = "AM";
const PM = "PM";

const unit = {
  HOUR: 0,
  MINUTE: 1,
  PERIOD: 2
};

const TIME_FORMAT_INPUT_OUTPUT = "HH:mm:ss";

const TimePicker = ({ time, onClose }) => {
  const [hour, setHour] = useState(DEF_HOUR);
  const [minute, setMinute] = useState(DEF_MINUTE);
  const [dayPeriod, setDayPeriod] = useState(PM);

  useEffect(() => {
    const timeValues = formatDateTo(time, TIME_FORMAT_INPUT_OUTPUT, "hh:mm:a").split(":");
    if (timeValues.length > 0) {
      const [hour, minute, period] = timeValues;
      setHour(hour);
      setMinute(minute);
      setDayPeriod(period);
    }
  }, [time]);

  const handleTimeUnitChange = (timeUnit, setUnitFunc, maxValue, type, increase) => {
    if (type === unit.PERIOD) {
      setUnitFunc(timeUnit === AM ? PM : AM);
      return;
    }
    let timeUnitValue = Number(timeUnit);
    let updatedValue = increase ? ++timeUnitValue : --timeUnitValue;
    if (type === unit.HOUR && updatedValue === 0) {
      return;
    }
    if (updatedValue > maxValue || updatedValue < 0) {
      return;
    }
    setUnitFunc(
      updatedValue.toString().length === 1 ? `0${updatedValue}` : updatedValue.toString(),
    );
  };

  const handleBlur = (e, setUnitFunc, type) => {
    const isHour = type === unit.HOUR;
    const value = e.target.value;
    if (isHour && (value === "0" || value === "00")) {
      setUnitFunc(DEF_HOUR);
    }
    if (value === "") {
      setUnitFunc(isHour ? DEF_HOUR : DEF_MINUTE);
    }
  };

  const handleChange = (e, setUnitFunc, maxValue) => {
    const strValue = e.target.value;
    if (strValue.length > 2 || strValue.includes(".") || strValue === " ") {
      return;
    }
    const changeValue = Number(strValue);
    if (isNaN(changeValue)) {
      return;
    }
    if (changeValue < 0 || changeValue > maxValue) {
      return;
    }
    setUnitFunc(strValue);
  };

  // TODO: Bring time format to the common form when is just open and after some value changed.
  const renderTimeUnitSelector = (timeUnit, setUnitFunc, maxValue, type) => {
    let value = timeUnit;

    return <div className="time-picker-unit-container">
      <IconButton
        icon={ChevronRightIcon}
        className="time-picker-arrow time-picker-increaseArrow"
        onClick={() => handleTimeUnitChange(timeUnit, setUnitFunc, maxValue, type, true)} />
      {type === unit.PERIOD ?
        <div className="time-picker-text">{value}</div>
        : <input
          className="time-picker-input time-picker-text"
          value={value}
          onBlur={(e) => handleBlur(e, setUnitFunc, type)}
          onChange={(e) => handleChange(e, setUnitFunc, maxValue)} />}
      <IconButton
        icon={ChevronRightIcon}
        className="time-picker-arrow time-picker-decreaseArrow"
        onClick={() => handleTimeUnitChange(timeUnit, setUnitFunc, maxValue, type)} />
    </div>;
  };

  const handleClose = () => {
    if (onClose) {
      let hoursParsed = Number(hour);
      let hoursIn24Format;

      // Parse hours to be 12:00 AM = 00:00 and 12:00 PM = 12:00.
      if (dayPeriod === AM) {
        if (hoursParsed === 12) {
          hoursIn24Format = 24;
        } else {
          hoursIn24Format = hoursParsed;
        }
      } else {
        hoursIn24Format = hoursParsed + 12;

        if (hoursIn24Format === 24) {
          hoursIn24Format = 12;
        }
      }

      onClose(format(new Date(0, 0, 0, hoursIn24Format, Number(minute)),
        TIME_FORMAT_INPUT_OUTPUT));
    }
  };

  const onContainerKeyDown = (event) => {
    if (isEventKey(event, KeyCodes.esc)) {
      // listen ESC here (fix closing few popup together)
      event.nativeEvent.stopImmediatePropagation();
      handleClose();
    }
  };

  return <React.Fragment>
    <div onKeyDown={onContainerKeyDown} className="time-picker-container">
      {renderTimeUnitSelector(hour, setHour, MAX_HOUR, unit.HOUR)}
      <span className="time-picker-text time-picker-semicolon">:</span>
      {renderTimeUnitSelector(minute, setMinute, MAX_MINUTE, unit.MINUTE)}
      {renderTimeUnitSelector(dayPeriod, setDayPeriod, null, unit.PERIOD)}
    </div>
    <div className="popup-wrap-background" onClick={() => handleClose()} />
  </React.Fragment>;
};

TimePicker.propTypes = {
  time: PropTypes.string,
  onClose: PropTypes.func
};

export default TimePicker;
