import {
  ACTION_LOGOUT,
} from "./constants";

import {
  FORM_BOOK_DATA_SUCCESS,
  ACTION_LAST_TIME_OPEN,
} from "./library.actions";

const initialState = {
  allHistory: [], // TODO change to object?
  lastTimeOpenML: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_LOGOUT:
      return initialState;
    case FORM_BOOK_DATA_SUCCESS:
      return { ...state, allHistory: [...action.data] };
    case ACTION_LAST_TIME_OPEN: 
      return  { ...state, lastTimeOpenML: action.data };
    default:
      return state;
  }
};
