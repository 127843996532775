import {
  FETCH_RELEVANT_SEARCH_SCHEME_SUCCESS,
  FETCH_RELEVANT_SEARCH_FACET_SUCCESS,
  FETCH_RELEVANT_SEARCH_FACET_AGGREGATE_SUCCESS,
  ACTION_RELATED_TYPE,
} from "./relevantSearch.actions";
import { RelatedTabType } from "./RelevantSearch.utils";

const initialState = {
  scheme: [],
  relatedLangs: [],
  facets: [],
  lastSearchQuery: undefined,
  lastSearchLang: undefined,
  query: "",
  relatedType: RelatedTabType.EGW,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_RELATED_TYPE: {
      return { ...state, relatedType: action.data };
    }
    case FETCH_RELEVANT_SEARCH_SCHEME_SUCCESS: {
      const { sections, relatedLangs } = action.data;
      let newState = { ...state };
      if (sections) {
        newState.scheme = sections;
      }
      if (relatedLangs) {
        newState.relatedLangs = relatedLangs;
      }
      return newState;
    }
    case FETCH_RELEVANT_SEARCH_FACET_SUCCESS: {
      const { code, results } = action.data;
      let newState = { ...state };
      newState.facets[code] = results.facets;
      // newState.facets['time'] = Date.now() ;

      return newState;
    }
    case FETCH_RELEVANT_SEARCH_FACET_AGGREGATE_SUCCESS: {
      const { results, lang, query } = action.data;
      const newState = { ...state };
      const newFacets = { ...state.facets };
      Object.keys(results).forEach((code) => {
        const result = results[code];
        newFacets[code] = result?.facets || [];
      });
      newState.facets = newFacets;
      if (lang) {
        newState.lastSearchLang = lang;
      }
      if (query) {
        newState.lastSearchQuery = query;
      }
      return newState;
    }

    default:
      return state;
  }
};
