/* eslint-disable no-console */
import { makeParamsString } from "../utils/SearchUtils";
import {
  advSearchAutocompleteTypes,
  advSearchInfoLangTypes,
  getBiblePubnr,
  parseFolders,
  Period,
  resultsLimit,
  virtualPeriodValues,
} from "../utils/AdvSearchTypes";
import { makeExternalRequest } from "./SearchExternalAPI";
import { bookPermission, getBookId, getBookOrigin } from "../shared/utils/content";
import { advSearchTypes, aplFolders } from "../shared/utils/search";
import { getBookIdByCode, getOrderNumberOfMonth, removeLangFromFolders } from "./api.utils";
import { makeRequest } from "src/shared/api/baseApi";
import { logSearchEvent, SearchLogEvents } from "src/shared/api/analitics";

const advSearchInfoExpireTime = 60 * 60 * 1000; // 1 hour

export const advSearchPeriodicalsInfoByPubnr = (pubnr) => {
  const url = `/search/advanced/periodicals/${pubnr}/info/`;
  return makeRequest({
    url,
    storageCacheName: url,
    storageCacheTime: advSearchInfoExpireTime,
    parseResponse: (response) => response.data,
  });
};

export const advSearchAplInfoByPubnr = (pubnr) => {
  const url = `/search/advanced/apl/${pubnr}/info/`;
  return makeRequest({
    url,
    storageCacheName: url,
    storageCacheTime: advSearchInfoExpireTime,
    parseResponse: (response) => response.data,
  });
};

export const getBibleLanguagesRequest = () => {
  const url = "content/books/biblelang";
  return makeRequest({
    url,
    storageCacheName: url,
    storageCacheTime: advSearchInfoExpireTime,
    parseResponse: (response) => response.data,
  });
};

export const getScripturePubnrs = () => {
  const url = "/search/advanced/scripture/books";
  return makeRequest({
    url,
    storageCacheName: url,
    storageCacheTime: advSearchInfoExpireTime,
    parseResponse: (response) => response.data,
  });
};

export const getBibliography = (lang, page, order, extras) => {
  //const [bookId, chapterId] = paraId.split(".");
  if (extras["type"]) {
    delete extras["type"];
  }
  const paramsString = makeParamsString(extras, "isApi");
  const searchExtras = paramsString ? `?${paramsString}&` : "?";
  let args =
    Object.entries(order).length !== 0
      ? "sort=" + order.sort + "&order=" + order.order + "&page=" + page
      : "sort=default&order=asc&page=" + page;

  const request = {
    url: `content/languages/${lang}/bibliography/${searchExtras}${args}`,
    type: "get",
    parseResponse: (response) => response.data,
  };
  return makeRequest(request);
};

export const searchTopicsRequest = (searchValue) => {
  return makeRequest({
    url: `search/related/topics?query=${encodeURIComponent(searchValue)}&lang=en`,
    type: "get",
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

// make default count 100 for avoid multiple call
export const searchExternalSuggestionsRequest = (searchValue, lang, count = 100) => {
  return makeRequest({
    url: `/suggestions/popup/${lang}?query=${encodeURIComponent(searchValue)}&count=${count}`,
    type: "get",
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

export const searchExternalAutocomplete = (searchValue) => {
  return makeExternalRequest({
    url: `/autocomplete?word=${encodeURIComponent(searchValue)}`,
    type: "get",
    parseResponse: (response) => response.data,
  });
};

export const getDetectSuggestionsRequest = (text, language) => {
  return makeRequest({
    url:
      "/suggestions/detect?" +
      makeParamsString(
        {
          text,
          language,
        },
        "isApi",
      ),
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

export const getPosibleTranslateLangRequest = (lang) => {
  return makeRequest({
    url: "/translations/languages/" + lang,
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

export const getTranslateRequest = (langs, query) => {
  const { fromLang, toLang } = langs;
  return makeRequest({
    url: `/translations/translate?from=${fromLang}&to=${toLang}&text=${query}`,
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

export const advSearchAutocomplete = (extras) => {
  const type = extras["type"];
  if (!advSearchAutocompleteTypes.includes(type)) {
    return null;
  }
  if (extras["type"]) {
    delete extras["type"];
  }
  if (extras["subtype"]) {
    if (extras["subtype"] !== "all") {
      extras["type"] = extras["subtype"];
    }
    delete extras["subtype"];
  }
  const path = `/search/advanced/${type}/autocomplete`;
  const paramsString = makeParamsString(extras, "isApi");
  const url = path + (paramsString ? `?${paramsString}` : "");
  return makeRequest({
    url,
    memoryCache: true,
    parseResponse: (response) => {
      return response.data;
    },
  });
};

export const advSearchInfo = (extras) => {
  const type = extras["type"];
  if (!advSearchTypes.includes(type)) {
    return null;
  }
  if (extras["type"]) {
    delete extras["type"];
  }

  let path = "";
  if (!advSearchInfoLangTypes.includes(type)) {
    // common case
    path = `/search/advanced/${type}/info`;
  } else {
    // info(lang) case
    if (!extras["lang"]) {
      return null;
    }
    const lang = extras["lang"];
    if (lang.length < 2) {
      return null;
    }
    path = `/search/advanced/${type}/${lang}/info`;
    delete extras["lang"];
  }
  const paramsString = makeParamsString(extras, "isApi");

  const url = path + (paramsString ? `?${paramsString}` : "");

  return makeRequest({
    url,
    storageCacheName: url,
    storageCacheTime: advSearchInfoExpireTime,
    parseResponse: (response) => {
      return response.data;
    },
  });
};

export const AdvSearchNavigationRequest = (extras) => {
  const type = extras["type"];
  if (!advSearchTypes.includes(type)) {
    return null;
  }
  if (extras["type"]) {
    delete extras["type"];
  }
  if (extras["bookpage"]) {
    extras["page"] = extras["bookpage"];
    delete extras["bookpage"];
  }

  const path = `/search/advanced/${type}/navigate`;
  const paramsString = makeParamsString(extras, "isApi");

  const url = path + (paramsString ? `?${paramsString}` : "");

  return makeRequest({
    url,
    memoryCache: true,
    parseResponse: (response) => {
      return response.data;
    },
  });
};

const relevantSearchRequest = (query, context, extras, limit, offset) => {
  console.log(":api.relevantSearch()");

  const lang = extras["lang"] || "en";
  const section = extras["section"];
  const order = extras.order ? "&order=" + extras.order : "";

  const url =
    "related/search/" +
    lang +
    "/" +
    section +
    "/" +
    context +
    "?query=" +
    encodeURIComponent(query) +
    order +
    "&limit=" +
    limit +
    "&offset=" +
    offset;
  const request = {
    url,
    type: "get",
    parseResponse: (response) => {
      const { total } = response.data;
      console.log("relevantSearch:response", response);
      const results = response.data.results.map((item, k) => {
        if (item.index) {
          return {
            query,
            ...item,
            isForSale: item.action_required === bookPermission.purchased,
            id: getBookId(item.para_id),
            className: "book",
          };
        }
        return {
          query,
          index: k,
          ...item,
          isForSale: item.action_required === bookPermission.purchased,
          id: getBookId(item.para_id),
        };
      });
      return { total, results, languageFacets: {} };
    },
  };
  return makeRequest(request);
};

const advSearch = (query, extrasOriginal, limit, offset, folders = []) => {
  const extras = { ...extrasOriginal };
  console.log(":api.advSearch()");
  const type = extras["type"];
  const path = advSearchTypes.includes(type) ? `/search/advanced/${type}` : "/search";
  if (extras["type"]) {
    delete extras["type"];
  }
  if (extras["subtype"]) {
    extras["type"] = extras["subtype"];
    delete extras["subtype"];
  }
  if (extras["bookpage"]) {
    extras["page"] = extras["bookpage"];
    delete extras["bookpage"];
  }

  // datarange process
  switch (type) {
    case "bible":
      if (folders.length && !extras.lang) {
        extras["lang"] = folders[0];
      }
      break;
    case "apl":
      if (extras["start_year"]) {
        extras["start_date"] = `${extras["start_year"]}-01-01`;
        delete extras["start_year"];
      }
      if (extras["end_year"]) {
        extras["end_date"] = `${extras["end_year"]}-12-31`;
        delete extras["end_year"];
      }
      break;

    case "book":
      if (extras.daterange) {
        switch (extras.daterange) {
          case "all":
            break;
          case "lifetime":
            extras["end_date"] = "1917-12-31";
            break;
          case "compilations":
            extras["start_date"] = "1918-01-01";
            break;
        }
        delete extras["daterange"];
      }
      break;

    case "ltms":
      if (extras["number"]) {
        if (isNaN(parseInt(extras["number"]))) {
          delete extras["number"];
        }
      }

      if (extras["start_year"]) {
        extras["start_date"] = `${extras["start_year"]}-01-01`;
        delete extras["start_year"];
      }
      if (extras["end_year"]) {
        extras["end_date"] = `${extras["end_year"]}-12-31`;
        delete extras["end_year"];
      }
      if (extras["year"]) {
        const year = extras["year"];
        extras["start_date"] = `${year}-01-01`;
        extras["end_date"] = `${year}-12-31`;
        delete extras["year"];
      }

      break;
    case "periodicals":
      if (extras["year"] && extras["month"] && extras["day"]) {
        const year = extras["year"];
        const month = extras["month"];
        const day = extras["day"];
        extras["start_date"] = `${year}-${month}-${day}`;
        extras["end_date"] = `${year}-${month}-${day}`;
        delete extras["year"];
        delete extras["month"];
        delete extras["day"];
      } else if (extras["year"] && extras["month"]) {
        const year = extras["year"];
        const month = extras["month"];
        extras["start_date"] = `${year}-${month}-01`;
        extras["end_date"] = `${year}-${month}-31`;
        delete extras["year"];
        delete extras["month"];
      } else if (extras["year"]) {
        const year = extras["year"];
        extras["start_date"] = `${year}-01-01`;
        extras["end_date"] = `${year}-12-31`;
        delete extras["year"];
      } else {
        // combine start_date-end_date with daterange('lifetime', 'compilations', 'all')
        if (extras["start_year"]) {
          extras["start_date"] = `${extras["start_year"]}-01-01`;
          delete extras["start_year"];
        }
        if (extras["end_year"]) {
          extras["end_date"] = `${extras["end_year"]}-12-31`;
          delete extras["end_year"];
        }
        if (extras["daterange"]) {
          const daterange = extras["daterange"];
          switch (daterange) {
            case "lifetime":
              if (!extras["end_date"] || "1917-12-31" < extras["end_date"]) {
                extras["end_date"] = "1917-12-31";
              }
              break;
            case "compilations":
              if (!extras["start_date"] || "1918-01-01" > extras["start_date"]) {
                extras["start_date"] = "1918-01-01";
              }
              break;
          }
          delete extras["daterange"];
        }
      }
      break;
    case "dictionary": {
      if (extras["period"]) {
        extras["period"] = "all";
      }
      const foldersWithoutLang = removeLangFromFolders(folders);
      if (foldersWithoutLang.length !== 0) {
        extras["pubnr"] = [foldersWithoutLang];
      }
      break;
    }
  }

  if (extras["collections"]) {
    let seq;
    if (typeof extras["collections"] === "string") {
      seq = decodeURIComponent(extras["collections"]).split(",");
    } else {
      seq = extras["collections"];
    }
    if (seq.length) {
      extras["folder"] = seq;
    }
    delete extras["collections"];
  }
  const paramsStr = makeParamsString(extras, "isApi");
  const searchParams = [`query=${encodeURIComponent(query)}`, paramsStr];
  if (limit) {
    searchParams.push(`limit=${limit}`);
  }
  if (offset) {
    searchParams.push(`offset=${offset}`);
  }
  const fullUrl = path + "?" + searchParams.join("&");

  console.log("advSearchUrl:", fullUrl);
  const request = {
    url: fullUrl,
    type: "get",
    parseResponse: (response) => {
      const { total } = response.data;
      const results = response.data.results.map((item) => {
        // isForSale = isAvailable for search items,
        // because search items do not have "action_required" property if available
        const isForSale = item.action_required === bookPermission.purchased;
        return {
          query,
          ...item,
          isForSale,
        };
      });
      return { total, results };
    },
  };
  return makeRequest(request);
};

export const processCode = (query) => {
  const trimmed = query.trim();
  const rx = /^([0-9]*[a-z]+)([0-9]+[0-9.:]*)$/i;
  if (!rx.test(trimmed)) {
    return [query];
  }
  // else
  // extended code
  const res = trimmed.match(rx);
  return [`${res[1]} ${res[2]}`, `${res[1]}${res[2]}`];
};

/**
 *
 * @param {object} searchValue
 * @param {string} baseBible
 * @returns
 */
export const searchNavProcess = async (searchValue, baseBible) => {
  const { query, extras, langs, onlyText } = searchValue;
  const type = extras?.type ? extras.type : "";
  console.log(`search type: ${type}`);
  const returnData = {
    navigation: false,
  };

  if (type !== "basic" || onlyText) {
    return returnData;
  }
  // basic type only
  const [query1] = processCode(query);
  const bibleId = getBiblePubnr(baseBible);

  const searchShortcutQuery1 = await searchShortcutRequest({
    query: query1,
    langs,
    bibleId,
  });
  if (searchShortcutQuery1) {
    return searchShortcutQuery1;
  }
  return returnData;
};

/**
 * Post method for possible make complicate request with a lot of book ids for example
 * @param {*} param0
 * @returns
 */
export function basicSearchRequest({
  query,
  offset,
  folders,
  books,
  chapters,
  extras,
  limit,
  context,
  useChecked = true,
}) {
  const defLang = extras?.defLang || "en";
  const newLimit = limit ?? resultsLimit;
  let url = "search";
  let postData = {
    query: query,
    limit: newLimit,
    offset: offset,
    facets: true,
    include_language_facets: true,
  };

  const apiExtras = extras ? { ...extras } : {};

  if (apiExtras.queryType) {
    apiExtras.query_type = apiExtras.queryType;
    delete apiExtras.queryType;
  }

  if (extras?.period) {
    const period = extras.period || Period.all;
    apiExtras.period = virtualPeriodValues.includes(period) ? Period.all : period;
  }

  delete apiExtras["type"];
  if (useChecked) {
    delete apiExtras["firstLang"];
  } else {
    postData.lang = apiExtras["firstLang"];
    delete apiExtras["firstLang"];
  }

  if (extras?.period === Period.myBible && !chapters?.length) {
    postData.pubnr = [getBiblePubnr(apiExtras["pubnr"] || books?.[0])];
  } else if (extras?.period === Period.apl) {
    postData.folder = aplFolders;
  } else {
    if (chapters?.length) {
      postData.chapter = chapters;
    }
    // other periods case
    if (folders && folders.length === 1 && folders[0] === "all" && useChecked) {
      delete apiExtras["defLang"];
      postData.lang = [defLang, "all"];
    } else if (folders && folders.length && useChecked) {
      const { lang, folder, pubnr } = parseFolders(folders);
      postData.folder = folder;
      postData.pubnr = pubnr;
      postData.lang = lang;
      if (apiExtras["lang"] === undefined) {
        delete apiExtras["lang"];
      }
    }
    if (books && books.length) {
      const { pubnr } = parseFolders(books.map(getBookId));
      postData.pubnr = pubnr;
    }
    if (context) {
      postData.context = context;
    }
  }

  if (apiExtras) {
    postData = {
      ...apiExtras,
      ...postData,
    };
  }

  if (typeof postData.lang === "string") {
    postData.lang = [postData.lang];
  }

  if (!postData.snippet) {
    postData.snippet = "short";
  }

  console.log("api.search(): url", postData);
  logSearchEvent(SearchLogEvents.SEARCH, postData);

  const cacheName = url + JSON.stringify(postData);

  return makeRequest({
    url,
    type: "post",
    memoryCache: true,
    memoryCacheName: cacheName,
    data: postData,
    parseResponse: (response) => {
      const { total, language_facets: languageFacets = {}, suggested_query } = response.data;
      const results = response.data.results.map((item) => {
        return {
          query,
          ...item,
          isForSale: item.action_required === bookPermission.purchased,
          id: getBookId(item.para_id),
          className: "book",
        };
      });
      return { total, languageFacets, results, suggested_query };
    },
  });
}

const getOffset = (start) => {
  const value = parseInt(start, 10);
  return value !== value ? 0 : Math.max(0, value - 1);
};

/**
 * Workaround for allow to load in slpit books, not optimized
 * @param {*} param0 
 * @returns 
 */
async function splitSearchRequest({ query, offset, folders, books, extras }) {
  const newExtras = { ...extras };
  const bookfirst = getBookOrigin(extras?.bookfirst);

  const bookFirstResponse = await basicSearchRequest({
    query,
    offset,
    books: [getBookId(bookfirst)],
    extras: newExtras,
  });

  let total = bookFirstResponse.total;
  let newOffset = 0;
  let totalResult = [];
  if (offset > total) {
    newOffset = offset - total;
  } else {
    totalResult.push(...bookFirstResponse.results);
  }

  const otherResponse = await basicSearchRequest({
    query,
    folders,
    books,
    extras: {
      ...newExtras,
      excluded_pubnr: [bookfirst],
    },
    offset: newOffset,
  });

  const updatedIndex = otherResponse.results.map((item) => {
    return {
      ...item,
      index: item.index + total,
    };
  });

  total += otherResponse.total;

  totalResult.push(...updatedIndex);

  const summaryResult = {
    total,
    results: totalResult,
    suggested_query: bookFirstResponse.suggested_query,
  };

  console.log(`splitSearch`, { bookFirstResponse, otherResponse, summaryResult });

  return summaryResult;
}

export const searchRequest = ({
  query,
  start,
  folders,
  books,
  context,
  extras,
  limit,
  useChecked,
  chapters,
}) => {
  const offset = getOffset(start);
  if (advSearchTypes.includes(extras?.type)) {
    return advSearch(query, extras, limit || resultsLimit, offset, folders);
  }
  if (extras?.type === "related" && extras?.lang?.length > 0) {
    return relevantSearchRequest(query, context, extras, limit || resultsLimit, offset);
  } else if (extras?.type === "related") {
    return false;
  }

  if (extras?.bookfirst) {
    return splitSearchRequest({ query, offset, folders, books, extras });
  }

  return basicSearchRequest({
    query,
    offset,
    folders,
    context,
    books,
    chapters,
    extras,
    limit,
    useChecked,
  });
};

export const searchSuggestionsRequest = (searchValue) => {
  const { query, langs } = searchValue;
  const langsString = langs && langs.length ? "&" + makeParamsString({ lang: langs }, "isApi") : "";
  return makeRequest({
    url: `search/suggestions?query=${encodeURIComponent(query)}${langsString}`,
    type: "get",
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

/**
 *
 * @typedef{Object} ShortcutParams
 * @property {string} query
 * @property {string} cutQuery
 * @property {string} bookId
 * @property {?string} paraId
 * @property {string} shortCutType
 * @property {number} hits
 * @property {string} title
 */

/**
 *
 * @param {*} data
 * @param {*} query
 * @returns {ShortcutParams[]}
 */
const normilizeShortcut = (data, query) => {
  const outData = {
    ...data,
    query,
  };

  outData.shortCutType = data.type;

  if (data.type === "search") {
    outData.cutQuery = data.params.query;
  } else {
    outData.cutQuery = "";
  }
  if (data.params?.pubnr) {
    outData.bookId = getBookId(data.params.pubnr);
  }
  if (data.para_id) {
    outData.bookId = getBookId(data.para_id);
    outData.paraId = data.para_id;
  }
  if (data.params?.chapter) {
    outData.bookId = getBookId(data.params?.chapter);
    outData.paraId = data.params?.chapter;
  }

  return outData;
};

/**
 *
 * @param {string} searchValue
 * @returns {Promise.<ShortcutParams, Error>}
 */
export const searchShortcutRequest = (searchValue) => {
  const { query, langs, bibleId } = searchValue;
  const params = [`query=${encodeURIComponent(query)}`, `bible_id=${bibleId}`];
  if (langs && langs.length) {
    params.push(makeParamsString({ lang: langs }, "isApi"));
  }
  return makeRequest({
    url: `search/suggestions/shortcut?${params.join("&")}`,
    type: "get",
    memoryCache: true,
    parseResponse: (response) => {
      const data = response.data;
      if (!data) {
        return undefined;
      }
      return normilizeShortcut(data, query);
    },
  });
};

/**
 *
 * @param {string} searchValue
 * @returns {Promise.<ShortcutParams[], Error>}
 */
export const getAllShortcutsRequest = (query, empty) => {
  return makeRequest({
    url: `search/suggestions?query=${encodeURIComponent(query)}&empty=${empty}`,
    type: "get",
    memoryCache: true,
    parseResponse: (response) => {
      const data = response.data;
      return data.map((item) => normilizeShortcut(item, query));
    },
  });
};

export const fetchDictionariesRequest = () => {
  const request = {
    // There was no dictionaries for non-english languages.
    url: "content/books?type=dictionary&lang=en",
    type: "get",
    parseResponse: (response) => {
      const defaultOption = { key: "all", label: "All Dictionaries" };
      const options = response.data.results.map((item) => ({
        key: item.book_id,
        label: `${item.title} (${item.code})`,
        isForSale: item.action_required === bookPermission.purchased,
        id: getBookId(item.para_id),
        className: "book",
      }));

      return [defaultOption, ...options];
    },
  };
  return makeRequest(request);
};

export const searchDictionaryContentRequest = ({ value, key }) => {
  return makeRequest({
    url: `/search/advanced/dictionary/content?chapter=${value}&pubnr=${key}`,
    memoryCache: true,
    parseResponse: (response) => {
      return response.data.map((item) => ({
        id: item.para_id,
        ...item,
        isForSale: item.action_required === bookPermission.purchased,
        className: "book",
      }));
    },
    parseError: (error) => error.data,
  });
};

export const searchDictionaryRequest = ({ value, key }) => {
  const pubnr = key && key !== "all" ? `&pubnr=${key}` : "";
  return makeRequest({
    url: `/search/advanced/dictionary?headings_only=false&query=${encodeURIComponent(
      value,
    )}${pubnr}`,
    memoryCache: true,
    parseResponse: (response) => {
      return response.data.results.map((item) => ({
        id: item.para_id,
        type: "dictionary",
        ...item,
        isForSale: item.action_required === bookPermission.purchased,
        className: "book",
      }));
    },
    parseError: (error) => error.data,
  });
};

export const searchDictionaryAutocompleteRequest = (query) => {
  return makeRequest({
    url: `/search/advanced/dictionary/autocomplete?q=${query}`,
    memoryCache: true,
    parseResponse: (response) => {
      console.log("dictionaryAutocompete", response.data);
      return response.data.options;
    },
  });
};

export const searchBookByNameRequest = (query, langs, limit = 100) => {
  const params = [`query=${encodeURIComponent(query)}`];
  if (langs) {
    params.push(makeParamsString({ lang: langs }, "isApi"));
  }
  if (limit) {
    params.push(`limit=${limit}`);
  }

  return makeRequest({
    url: `search/book/name?${params.join("&")}`,
    memoryCache: true,
    parseResponse: (response) => {
      const { total, results } = response.data;
      return {
        total,
        results: results.map((item) => ({
          ...item,
          id: getBookId(item.book_id),
          coverSmall: item.cover,
          folder_color_group: item.folder_type,
        })),
      };
    },
  });
};

export const getParaByPeriodRefCode = async (refs, periodicals, book) => {
  const [pubnr, year, month, day, paragraph] = refs;

  const defaultExtras = { type: "periodicals", daterange: "all" };
  const extras = { ...defaultExtras };
  const bookId = getBookIdByCode(periodicals, pubnr, book.book_id);

  if (!year) {
    if (!bookId) {
      return { status: "error", message: "codeNotFound" };
    }

    extras.pubnr = bookId;

    return await AdvSearchNavigationRequest(extras);
  }

  extras.date = `${year}-${getOrderNumberOfMonth(month)}-${day || "1"}`;
  if (bookId) {
    extras.pubnr = bookId;
  }
  extras.paragraph = paragraph || "1";

  const data = await AdvSearchNavigationRequest(extras);

  if (!data) {
    return { status: "error", message: "dateNotFound" };
  }

  return data;
};

export const resolveParaByRefCode = async (refs, periodicals, book, langs, baseBible) => {
  const isBible = book.realType === "bible";
  const isPeriodical = book.realType === "periodicals";

  if (isPeriodical) {
    return getParaByPeriodRefCode(refs, periodicals, book);
  }

  const [ref1, ref2, ref3] = refs;
  const params = { query: "", langs, bibleId: isBible ? getBiblePubnr(baseBible) : book.book_id };
  const delimiter = isBible ? ":" : ".";
  const postfix = _createPostfix(ref2, ref3, delimiter);
  params.query = postfix ? `${ref1} ${postfix}` : ref1;

  // if chapter is empty - change chapter and paragraph to "1"
  if (!ref2) {
    refs[1] = "1";
    refs[2] = "1";
  }

  return getParaByRefCodeRequest(refs, params, book.book_id, book.lang, isBible);
};

const _createPostfix = (ref2, ref3, delimiter) => {
  if (!ref2 || !ref3) {
    return [1, 1].join(delimiter);
  }

  return [ref2, ref3].filter((value) => value).join(delimiter);
};

export const getParaByRefCodeRequest = async (refs, params, bookId, bookLang, isBible = false) => {
  const [ref1, ref2, ref3] = refs;

  let res = await searchShortcutRequest(params);
  if (res?.para_id) {
    return res;
  }

  res = await getParaByRefCode({ ref1, ref2, ref3, bookId, isBible, lang: bookLang });
  if (res?.para_id) {
    return res;
  }

  res = await getParaByRefCode({ ref1, ref3: "1", bookId, isBible, lang: bookLang });
  if (res?.para_id) {
    return res;
  }

  res = await getParaByRefCode({ ref1, ref3: "1", bookId, isBible: false });
  return res;
};

export const getParaByRefCode = ({ ref1, ref2, ref3, bookId, isBible, lang, code }) => {
  const params = [];
  const type = isBible ? "bible" : "book";
  params.push(`type=${type}`);
  if (ref1) {
    params.push(isBible ? `bible_code=${ref1}` : `code=${ref1}`);
  }
  if (ref2) {
    params.push(isBible ? `chapter=${ref2}` : `page=${ref2}`);
  }
  if (ref3) {
    params.push(isBible ? `verse=${ref3}` : `paragraph=${ref3}`);
  }
  if (lang) {
    params.push(`lang=${lang}`);
  }
  if (code && isBible) {
    params.push(`code=${code}`);
  }
  if (bookId && isBible) {
    params.push(`book_id=${bookId}`);
  }

  return makeRequest({
    url: `content/utilities/paraid?${params.join("&")}`,
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

export const AdvSearchNavigation = (extras) => {
  return AdvSearchNavigationRequest(extras).then((data) => {
    if (data && data.pubnr && data.para) {
      const paraId = `${data.pubnr}.${data.para}`;
      return { paraId, lang: data.lang };
    }

    return {};
  });
};
