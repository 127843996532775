import axios from "axios";
import { localStorageSafe } from "../shared/utils/systemUtils";

const cacheExpire = 1000 * 60 * 60 * 24; //cache for one day
const IsCacheEnable = false;
const serverUrl = "https://search-api.staging.egwwritings.org/api";

export const makeExternalRequest = (request) => {
  return axios({
    method: request.type,
    url: serverUrl + request.url,
    headers: {
      ...request.headers
    },
    data: request.data
  })
    .then((response) => {
      return request.parseResponse(response);
    })
    .catch((response) => {
      if (response.response) {
        return undefined;
      }
      return undefined;
    });
};

export const makeExternalCacheRequest = async (request, cacheName) => {
  let cache = localStorageSafe.getItem(cacheName);
  if (cache && IsCacheEnable) {
    try {
      let jsonCache = JSON.parse(cache);
      if (jsonCache.data && Date.now() < jsonCache.timestamp + cacheExpire) {
        return jsonCache.data;
      }
    } catch (e) {
      console.log("makeExternalCacheRequest error", e);
    }
  }

  return makeExternalRequest(request).then((data) => {
    storeCache(cacheName, data);
    return data;
  });
};

const storeCache = (name, data) => {
  let json = {
    timestamp: Date.now(),
    data
  };
  localStorageSafe.setItem(name, JSON.stringify(json));
};
