import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Footer, WaterMark } from "../";
import Drag from "./Drag";
import { getClientPositions, getIdFromElement } from "../../utils/Utils";
import { MENU_STATE } from "../../utils/MenuItems";
import { actionUpdateResize } from "../../redux/params.actions";
import { resizeIds, isDragId, STORAGE_KEY_RESIZER_SIZES, calculateDragEnd } from "./ResizerUtils";
import {
  useViewMode,
  useResizeDimensions,
  useGridDimensions,
  useFullScreen,
  useLocalStorage,
} from "../../hooks";
import { usePopupContext } from "../popup/PopupContext";
import { useNavigationContext } from "../NavigationContext";
import { actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import { ScrollerView } from "../views";
import Scroll from "../views/Scroll";
import CartSidePanel from "../cart/CartSidePanel";
import SwipeableWrapper from "../swipeable/SwipeableWrapper";
import { withResizeDetector } from "react-resize-detector/build/withPolyfill";
import { calcProportion, clutch, isNumberCalculable } from "../../utils/NumberUtils";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { useAdvSearch } from "src/hooks/navigation.hooks";

import "./Resizer.scss";
import { URLS } from "../../shared/utils/url";

const { LEFT_MIDDLE_DRAG, LEFT_MAIN_DRAG, RIGHT_MAIN_DRAG, RIGHT_MIDDLE_DRAG } = resizeIds;

const ResizerContext = createContext({});

// Default value case (e.g. when broken cookies).
// Specific default value see [EGWW-2411].
// {dragValueWhenDragOk} - e.g. for left drag when no panel - {leftNoPanel}.
// {deltaWhenDragOk} - is {DELTA} in time {dragValueWhenDragOk} was given.
const getDefaultSpecificValue = (remToPx, delta, dragValueWhenDragOk, deltaWhenDragOk) => {
  return remToPx(dragValueWhenDragOk) + (remToPx(deltaWhenDragOk) - delta) / 2;
};

const Resizer = ({ TopPanel, LeftPanel, RightPanel, children, width, height }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollComponentRef = useRef(null);
  const mainWrapperRef = useRef(null);
  const mainPartRef = useRef(null);
  const { hidePopup } = usePopupContext();
  const { isRelatedSearch, isReader, label } = useNavigationContext();
  const { isAdvSearch } = useAdvSearch();
  const { textMode, blindMode, isTablet, isMobileOrTablet, isMobile } = useViewMode();
  const remToPx = useRemToPx();
  const { fullscreen } = useFullScreen();
  const isDesktop = !isMobileOrTablet;
  const {
    DELTA,
    MIN_LEFT_WIDTH,
    MIN_RIGHT_WIDTH,
    MIN_MAIN_WIDTH,
    COLLAPSE_MENU_WIDTH,
    MIN_LEFT_TREE_WIDTH,
    SEARCH_PLUS_BUTTON_WIDTH,
  } = useResizeDimensions();
  const { LIST_WIDTH } = useGridDimensions();
  const [[phantomDragId, phantomDragPosition], setPhantomDragHolder] = useState([]);
  const zoom = useSelector((state) => state.settings.zoom);
  const menuState = useSelector((state) => state.settings.menuState);
  const isShowRightPanel = useSelector((state) => state.settings.isShowRightPanel);
  const isRightPanelPinned = useSelector((state) => state.settings.isRightPanelPinned);
  const isLeftPanelPinned = useSelector((state) => state.settings.isLeftPanelPinned);
  const readerMinWidth = useSelector((state) => state.settings.readerMinWidth);

  const gapBetweenParts = isMobile ? 0 : DELTA / 2;

  const showTree = !textMode && menuState === MENU_STATE.TREE;
  const showMenu = menuState === MENU_STATE.MENU;
  const isLeftPanelShown = showTree || showMenu;
  const isRightPanelSliding = isMobile || (isTablet && !isRightPanelPinned);

  let isLeftResizeAllowed, isRightResizeAllowed, isLeftAffectsPanelSizes, isRightAffectsPanelSizes;
  if (isTablet) {
    isLeftAffectsPanelSizes = showTree && isLeftPanelPinned;
    isRightAffectsPanelSizes = isShowRightPanel && isRightPanelPinned;

    isLeftResizeAllowed = !fullscreen && !textMode && isLeftAffectsPanelSizes;
    isRightResizeAllowed = !fullscreen && !textMode && isRightAffectsPanelSizes;
  } else if (isDesktop) {
    isLeftResizeAllowed = !fullscreen && !textMode;
    isRightResizeAllowed = !fullscreen && !textMode;
    isLeftAffectsPanelSizes = isLeftPanelShown;
    isRightAffectsPanelSizes = isShowRightPanel;
  }

  let left, leftNoPanel, right, rightNoPanel, zoomCached, widthCached;

  const [sizesCached, setSizesCached] = useLocalStorage(STORAGE_KEY_RESIZER_SIZES, {});

  if (isLeftResizeAllowed || isRightResizeAllowed) {
    if (sizesCached) {
      left = sizesCached.left;
      leftNoPanel = sizesCached.leftNoPanel;
      right = sizesCached.right;
      rightNoPanel = sizesCached.rightNoPanel;
      zoomCached = sizesCached.zoom;
      widthCached = sizesCached.width;

      // Calc new proportional sizes if the new {zoom}.
      if (zoomCached && zoom !== zoomCached) {
        left = calcProportion(left, zoomCached, zoom);
        leftNoPanel = calcProportion(leftNoPanel, zoomCached, zoom);
        right = calcProportion(right, zoomCached, zoom);
        rightNoPanel = calcProportion(rightNoPanel, zoomCached, zoom);
      }

      // Calc new proportional sizes if the new {width}.
      if (widthCached && width !== widthCached) {
        left = calcProportion(left, widthCached, width);
        leftNoPanel = calcProportion(leftNoPanel, widthCached, width);
        right = calcProportion(right, widthCached, width);
        rightNoPanel = calcProportion(rightNoPanel, widthCached, width);
      }
    }
  }

  let minMainWidth = MIN_MAIN_WIDTH < LIST_WIDTH ? LIST_WIDTH : MIN_MAIN_WIDTH;
  // Prevent the main part width is less than needed for several opened Readers.
  if (isReader && readerMinWidth) {
    if (minMainWidth < readerMinWidth) {
      minMainWidth = readerMinWidth;
    }
  }

  const clutchLeft = (value) => {
    const max = width - minMainWidth - MIN_RIGHT_WIDTH;
    if (!isNumberCalculable(value)) {
      value = MIN_LEFT_TREE_WIDTH; // Default value case (e.g. when broken cookies).
    }
    return clutch(value, MIN_LEFT_TREE_WIDTH, max);
  };

  const clutchLeftNoPanel = (value) => {
    const max = width - minMainWidth - MIN_RIGHT_WIDTH;

    if (!isNumberCalculable(value)) {
      value = getDefaultSpecificValue(remToPx, DELTA, 5.55, 1.45);
    }

    return clutch(value, COLLAPSE_MENU_WIDTH, max);
  };

  const clutchRight = (value) => {
    const max = width - MIN_RIGHT_WIDTH;
    const min =
      (isLeftAffectsPanelSizes
        ? isLeftPanelShown
          ? MIN_LEFT_TREE_WIDTH
          : COLLAPSE_MENU_WIDTH
        : 0) + minMainWidth;

    if (!isNumberCalculable(value)) {
      value = max; // Default value case (e.g. when broken cookies).
    }

    return clutch(value, min, max);
  };

  const clutchRightNoPanel = (value) => {
    const min = (isLeftPanelShown ? MIN_LEFT_WIDTH : COLLAPSE_MENU_WIDTH) + minMainWidth;
    // {gapBetweenParts} to prevent right border too go to far so it'd shown partly
    let max = width - gapBetweenParts;
    if (isRelatedSearch && isDesktop) {
      // prevents covering S+ button by main part in the related search.
      max -= SEARCH_PLUS_BUTTON_WIDTH;
    }
    if (!isNumberCalculable(value)) {
      value = max; // Default value case (e.g. when broken cookies).
    }
    return clutch(value, min, max);
  };

  let mainWidth, rightWidth, currentLeft, currentRight;

  if (isLeftResizeAllowed) {
    left = clutchLeft(left);
    leftNoPanel = clutchLeftNoPanel(leftNoPanel);
  } else {
    // Else default sizes.
    if (fullscreen) {
      left = MIN_LEFT_TREE_WIDTH;
      leftNoPanel = 0;
    } else if (isTablet) {
      left = showTree && isLeftPanelPinned ? MIN_LEFT_TREE_WIDTH : 0;
      leftNoPanel = 0;
    } else if (textMode) {
      left = MIN_LEFT_TREE_WIDTH;
      leftNoPanel = getDefaultSpecificValue(remToPx, DELTA, 7.55, 1.45);
    }
  }

  if (isRightResizeAllowed) {
    right = clutchRight(right);
    rightNoPanel = clutchRightNoPanel(rightNoPanel);
  } else {
    // Else default sizes.
    if (fullscreen) {
      right = width - MIN_RIGHT_WIDTH;
      rightNoPanel = width;
    } else if (isTablet) {
      right = isShowRightPanel && isRightPanelPinned ? clutchRight(right) : width;
      rightNoPanel = width;
    } else if (textMode) {
      right = width - MIN_RIGHT_WIDTH;
      rightNoPanel = width;
    }
  }

  currentLeft = isLeftAffectsPanelSizes ? left : leftNoPanel;
  currentRight = isRightAffectsPanelSizes ? right : rightNoPanel;
  mainWidth = currentRight - currentLeft;
  rightWidth = width - currentLeft - mainWidth;

  const updateSizesInStore = useCallback(
    (newSizes) => {
      setSizesCached({
        left,
        leftNoPanel,
        right,
        rightNoPanel,
        zoom,
        width,
        ...newSizes,
      });
    },
    [left, leftNoPanel, right, rightNoPanel, width, zoom, setSizesCached],
  );

  const setPhantomLocation = (phantomDragId, clientX) => {
    let newPosition;

    switch (phantomDragId) {
      case LEFT_MIDDLE_DRAG: {
        newPosition = clutchLeft(clientX);
        break;
      }
      case LEFT_MAIN_DRAG: {
        newPosition = clutchLeftNoPanel(clientX);
        break;
      }
      case RIGHT_MAIN_DRAG: {
        newPosition = clutchRightNoPanel(clientX);
        break;
      }
      case RIGHT_MIDDLE_DRAG: {
        newPosition = clutchRight(clientX);
        break;
      }
    }

    setPhantomDragHolder([phantomDragId, newPosition]);
  };

  const onDragEnd = () => {
    if (phantomDragId) {
      const newSizes = calculateDragEnd(
        phantomDragId,
        phantomDragPosition,
        left,
        leftNoPanel,
        right,
        rightNoPanel,
        minMainWidth,
      );
      updateSizesInStore(newSizes);
      setPhantomDragHolder([]);
    }
  };

  const renderRightBorder = () => {
    if (isRightResizeAllowed) {
      let rightDrag = currentRight;

      if (!isShowRightPanel) {
        if (isReader) {
          rightDrag -= gapBetweenParts; // Directly sticky to the Reader.
        }
      }

      return (
        <Drag
          id={isShowRightPanel ? RIGHT_MIDDLE_DRAG : RIGHT_MAIN_DRAG}
          style={{ left: rightDrag }}
          isDragBetween={isShowRightPanel}
          isDragging={!!phantomDragId}
        />
      );
    }

    return null;
  };

  const renderLeftBorder = () => {
    if (isLeftResizeAllowed) {
      let leftDrag = currentLeft;

      if (!isLeftPanelShown) {
        // Calculate drag line position directly at the start of the content.
        leftDrag += gapBetweenParts;
      }

      return (
        <Drag
          id={isLeftPanelShown ? LEFT_MIDDLE_DRAG : LEFT_MAIN_DRAG}
          style={{ left: leftDrag }}
          isDragBetween={showTree}
          isDragging={!!phantomDragId}
        />
      );
    }

    return null;
  };

  const renderLeftPanel = () => {
    if (fullscreen && menuState === MENU_STATE.COLLAPSE) {
      return null;
    }

    if (isMobile) {
      return (
        <div
          className="left-menu left-slide-menu"
          style={{
            transform: `translate(${isLeftPanelShown ? "0%" : "-110%"}, 0)`,
          }}>
          <LeftPanel />
        </div>
      );
    }

    let styles;

    if (isDesktop || (isTablet && showTree && isLeftPanelPinned)) {
      styles = {
        boxSizing: "border-box",
        width: currentLeft,
        paddingRight: gapBetweenParts,
      };

      if (isAdvSearch) {
        styles.transform = `translate(${isLeftPanelShown ? "0%" : "-110%"}, 0)`;
      }
    }

    return (
      <div
        className={classNames("left-menu", { "topmost": isLeftPanelShown && isAdvSearch })}
        style={styles}>
        {textMode && menuState === MENU_STATE.COLLAPSE ? "" : <LeftPanel />}
      </div>
    );
  };

  const renderRightPanel = () => {
    let style;

    if (isRightPanelSliding) {
      style = { width: isMobile ? "100%" : MIN_RIGHT_WIDTH };
    } else {
      if (isShowRightPanel) {
        style = {
          boxSizing: "border-box",
          width: rightWidth,
          paddingLeft: gapBetweenParts,
          paddingRight: gapBetweenParts,
        };
      }
    }

    return (
      <React.Fragment>
        <aside
          className={classNames("right-panel", {
            pinned: isTablet && isRightPanelPinned,
            sliding: isRightPanelSliding,
            shown: isShowRightPanel,
          })}
          style={style}>
          {isShowRightPanel && <RightPanel />}
        </aside>
        {isRightPanelSliding && (
          <div
            className="right-panel__backdrop"
            onClick={(e) => {
              if (e.target.classList.contains("right-panel__backdrop")) {
                dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
              }
            }}
          />
        )}
      </React.Fragment>
    );
  };
  const isFullscreenReader = fullscreen && location.pathname === URLS.read;

  const renderTopPanel = useCallback(() => {
    if (!(isRelatedSearch && isTablet) && !isFullscreenReader) {
      return (
        <TopPanel
          style={{
            boxSizing: "border-box",
            paddingLeft: gapBetweenParts,
            paddingRight: gapBetweenParts,
          }}
        />
      );
    }

    return null;
  }, [isFullscreenReader, gapBetweenParts, isRelatedSearch, isTablet]);

  const onEventStart = (e) => {
    const id = getIdFromElement(e.target);
    const { clientX } = getClientPositions(e);
    if (isDragId(id)) {
      hidePopup();
      dispatch(actionUpdateResize(true));
      setPhantomLocation(id, clientX);
    }
  };

  const onEventEnd = () => {
    if (phantomDragId) {
      onDragEnd();
      dispatch(actionUpdateResize(false));
    }
  };

  const onEventCancel = (e) => {
    const { classList } = e.target;
    if (
      phantomDragId &&
      (classList.contains("resizerWrap") ||
        classList.contains("phantomWrap") ||
        classList.contains("base-drag-line") ||
        classList.contains("base-drag-line__line"))
    ) {
      onDragEnd();
      dispatch(actionUpdateResize(false));
    }
  };

  const onEventMove = (e) => {
    if (phantomDragId) {
      const { clientX } = getClientPositions(e);
      setPhantomLocation(phantomDragId, clientX);
    }
  };

  const isRouteWithScrollView = useMemo(() => {
    return (
      location.pathname === URLS.home ||
      location.pathname === URLS.allCollection ||
      location.pathname === URLS.audioBooks
    );
  }, [location]);

  // Resets scroll on page change. May be needs to be updated.
  useEffect(() => {
    if (isRouteWithScrollView && typeof scrollComponentRef.current?.scrollToTop === "function") {
      scrollComponentRef.current.scrollToTop();
    }
  }, [scrollComponentRef, location, isRouteWithScrollView]);

  const ScrollComponent = isMobile ? (isRouteWithScrollView ? ScrollerView : Scroll) : "main";
  let scrollComponentProps;

  if (isMobile) {
    if (isRouteWithScrollView) {
      scrollComponentProps = { ref: scrollComponentRef, tagName: "main" };
    }
  } else {
    scrollComponentProps = {
      style: {
        boxSizing: "border-box",
        width: mainWidth,
        paddingLeft: gapBetweenParts,
        paddingRight: gapBetweenParts,
        paddingTop: fullscreen ? gapBetweenParts : 0,
      },
    };
  }
  const rightPanelWidth = isRightPanelSliding ? MIN_RIGHT_WIDTH : rightWidth - gapBetweenParts * 2;

  return (
    <ResizerContext.Provider
      value={{
        gapBetweenParts,
        currentLeft,
        resizerWidth: width,
        mainWidthInner: isMobile ? width : mainWidth - gapBetweenParts * 2,
        rightWidthInner: isMobile ? width : rightPanelWidth,
        mainPartHeight: isMobile ? height : mainPartRef?.current?.offsetHeight,
        mainWrapperHeight: isMobile
          ? height
          // {getBoundingClientRect} to return NOT rounded value
          : mainWrapperRef?.current?.getBoundingClientRect()?.height,
      }}>
      <SwipeableWrapper>
        <div
          className="resizerWrap"
          onTouchStart={onEventStart}
          onTouchMove={onEventMove}
          onTouchEnd={onEventEnd}
          onTouchCancel={onEventCancel}
          onMouseDown={onEventStart}
          onMouseUp={onEventEnd}
          onMouseLeave={onEventCancel}
          onMouseMove={onEventMove}>
          {!isMobile && !isReader && !isRelatedSearch && !blindMode && <WaterMark title={label} />}
          {phantomDragId && <div className="phantomWrap" />}
          {phantomDragId && <Drag isPhantom={true} style={{ left: phantomDragPosition }} />}
          {renderLeftBorder()}
          {renderRightBorder()}
          {renderLeftPanel()}

          <div className="mainPart">
            {renderTopPanel()}
            <div className="mainWrapper" ref={mainWrapperRef}>
              <ScrollComponent ref={mainPartRef} className="mainContent" {...scrollComponentProps}>
                {children}
                {isMobile ? <Footer /> : null}
              </ScrollComponent>

              {renderRightPanel()}
            </div>
            <CartSidePanel />
          </div>
        </div>
      </SwipeableWrapper>
    </ResizerContext.Provider>
  );
};

Resizer.defaultProps = {
  width: window.innerWidth,
  height: window.innerHeight,
};

Resizer.propTypes = {
  children: PropTypes.node,
  LeftPanel: PropTypes.func,
  RightPanel: PropTypes.func,
  TopPanel: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};
/**
 * 
 * @returns {{ gapBetweenParts,
      currentLeft: number,
      resizerWidth: number, 
      mainWidthInner: number: number,
      rightWidthInner: number,
      mainPartHeight: number,
      mainWrapperHeight: number
  }}
 */
export const useResizerContext = () => useContext(ResizerContext);

export default withResizeDetector(Resizer);
