import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchField, Scroll } from "../components/views";
import VersionView from "../components/VersionView";
import { isProduction } from "../shared/utils/assembly";

import "./Pages.scss";

const PatchNotesPage = () => {
  const [pathNotes, setPatchNotes] = useState([]);
  const [filter, setFilter] = useState("");
  const [author, setAuthor] = useState("");
  const info = useSelector((state) => state.system.info);

  useEffect(() => {
    fetch(window.location.origin + "/patchNotes.json")
      .then((res) => res.json())
      .then((result) => {
        let dateValue = new Date();
        setPatchNotes(
          result.map((item) => {
            dateValue = new Date(item.date);
            return { ...item, date: dateValue };
          })
        );
      })
      .catch((e) => {
        console.log("NoPatchnotes", e);
      });
  }, []);

  const list = pathNotes.filter((item) => {
    if (author && author !== item.email) {
      return false;
    }
    if (!filter) {
      return true;
    }

    return (
      item.message.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
      (item.body || "").toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  });

  return (
    <div className="patch-note">
      <div className="patch-note-head">
        <SearchField
          isWithDebounce
          placeholder="Filter"
          className="patch-note-search"
          onChange={setFilter}
          onClear={() => setFilter()}
        />
        {!isProduction()  && info?.authors && (
          <select
            value={author}
            className="patch-note-select"
            onChange={(e) => {
              setAuthor(e.target.value);
            }}>
            <option value="">ALL</option>
            {info.authors.map((item, index) => {
              return (
                <option key={index} value={item.email}>
                  {item.name}
                </option>
              );
            })}
          </select>
        )}
        <VersionView />
      </div>
      {/* TODO change to virtual list */}
      <Scroll>
        {list.length !== 0 ? (
          list.map((item, index) => {
            let author = info?.authors?.[item.email]?.name || item.email;

            return (
              <div key={index} className="patch-row">
                <span className="message">{item.message}</span>
                {item.body && <div className="patch-description">{item.body} </div>}
                <div className="second-row">
                  {!isProduction() && <span
                    onClick={() => {
                      setAuthor(item.email);
                    }}
                    // href={"mailto:" + item.email + "?subject=" + item.message}
                    // target="_blanc"
                    className="author">
                    {author}
                  </span>}             
                  <span style={{ color: "gray" }}>
                    {item.date.toLocaleString() + "   "}
                  </span>
                  {item.tasks &&
                    item.tasks.map((item, index) => {
                      if (item === "FIX") {
                        return null;
                      }
                      return (
                        <a
                          key={index}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={"https://rollncode.atlassian.net/browse/" + item}>
                          {"[" + item + "]"}
                        </a>
                      );
                    })}
                </div>
              </div>
            );
          })
        ) : (
          <div>No results found</div>
        )}
      </Scroll>
    </div>
  );
};

export default PatchNotesPage;
