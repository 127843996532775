import React, { useState, useEffect, useRef, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getDay, addDays, isFuture, isSameDay, parseISO } from "date-fns";

import { useDialogContext } from "../dialog/DialogContext";
import { weekDays, bookType, validateDate, formSubAction } from "./SubscriptionsUtils";
import {
  getSubBooks,
  getSubBookToc,
  pauseSubscription,
  startSubscription,
} from "./SubscriptionsAPI";
import { formatTimeString, formatDateTo, findItemById } from "../../utils/Utils";
import {
  actionUpdateSubscriptions,
  fetchFeeds,
  actionActivate,
  actionUpdateFeed,
  actionUpdateSubscription,
  actionGetSubscriptions,
} from "./subscriptions.actions";
import { useViewMode } from "../../hooks";
import { actionAddMessage } from "../../redux/actions";
import { getLinkValue} from "../../utils/URLUtils";
import Scroll from "../views/Scroll";
import {
  PlusIcon,
  MinusIcon,
  CheckYesIcon,
  CheckNoIcon,
} from "../../assets/CommonIcons";
import { useBookSelect } from "../../redux/selectors";
import DialogButtons from "../dialog/DialogButtons";
import { IconButton, DatePicker, TimePicker } from "../views";
import DropDown from "../dropDown/DropDown";
import PopupWrap from "../popup/PopupWrap";
import DeliveryView, { deliveryMethods } from "./DeliveryView";
import { useNavigationContext } from "../NavigationContext";
import { useRemToPx } from "../../hooks/viewModeHooks";
import EgwWebFont from "src/assets/EgwWebFont";

import sassVariables from "./SubscriptionsDialog.variables.module.scss";
import "./SubscriptionsDialog.scss";
import "./Subscriptions.scss";
import { URLS } from "../../shared/utils/url";

const AddSubscription = ({
  editItem,
  editMode,
  isFeed,
  fromTabId,
  onUpdate,
  onCreate,
  bookId,
  type,
}) => {
  const { t } = useTranslation();
  const popupRef = useRef();
  const containerRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(true);
  const { isMobile, isTablet, isRealTablet, zoom } = useViewMode();
  const remToPx = useRemToPx();
  const { showDialog, hideDialog, showConfirm } = useDialogContext();
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const deliveryTime = useSelector((state) => state.settings.deliveryTime);
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const subscriptions = useSelector((state) => state.subscriptions.allSubs);
  const book = useBookSelect(bookId);

  const alreadySubscribedBookIds = useMemo(() => {
    return subscriptions.map((subscription) => subscription.book.book_id);
  }, [subscriptions]);

  const defaultType = book
    ? bookType[book.subtype.toUpperCase()] || bookType[book.type.toUpperCase()]
    : bookType.BOOK;

  const [selectedDays, setSelectedDays] = useState(Object.values(weekDays).map((day) => day.value));
  const [pageCount, setPageCount] = useState(1);
  const [selectedMethods, setSelectedMethods] = useState(editItem ? [] : [deliveryMethods[0].id]);
  const [languages, setLanguages] = useState([]);
  const [books, setBooks] = useState([]);
  const [paras, setParas] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedBookType, setSelectedBookType] = useState(bookType[type] || defaultType);
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedPara, setSelectedPara] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pauseUntil, setPauseUntil] = useState(null);
  const [time, setTime] = useState(deliveryTime);
  const [booksDisabled, setBooksDisabled] = useState(true);
  const [allDisabled, setAllDisabled] = useState(true);
  const [active, setActive] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [editFeed, setEditFeed] = useState(null);
  const feeds = useSelector((state) => state.subscriptions.feeds);

  const { goPrevPage } = useNavigationContext();

  const [expandOptions, setExpandOptions] = useState({
    language: {
      label: "language",
      expanded: false,
    },
    bookType: {
      label: "bookType",
      expanded: false,
    },
    book: {
      label: "book",
      expanded: false,
    },
    para: {
      label: "startFrom",
      expanded: false,
    },
    time: {
      label: "deliveryTime",
      expanded: false,
    },
  });

  useEffect(() => {
    const initTab = fromTabId === "thoughts" || fromTabId === "news";
    setCurrentTab(!initTab);
  }, [fromTabId]);

  useEffect(() => {
    if (feeds.length === 0) {
      dispatch(fetchFeeds());
    } else {
      setEditFeed(fromTabId === "news" ? feeds[0] : feeds[1]);
    }
  }, [feeds, currentTab]);

  useEffect(() => {
    if (editMode || !mainTree.length || !libraryLanguages.length) {
      return;
    }

    const langs = mainTree.map(({ id, label }) => ({ id, label }));

    if (book) {
      setSelectedLanguage(findItemById(langs, book.lang));
    } else {
      setSelectedLanguage(findItemById(langs, libraryLanguages[0]));
    }

    setLanguages(langs);
  }, [mainTree, book, editMode, libraryLanguages]);

  useEffect(() => {
    let disabled = true;
    if (editMode && editItem) {
      const {
        active: isActive,
        start_date,
        end_date,
        delivery_time,
        items_per_day,
        weekdays,
        paused_until,
        delivery_methods,
      } = editItem;

      setActive(isActive);
      if (!isFeed && editItem.feed_type !== "news") {
        setStartDate(start_date ? formatDateTo(start_date, "yyyy-MM-dd") : null);
        setEndDate(end_date ? formatDateTo(end_date, "yyyy-MM-dd") : null);
        setSelectedDays(weekdays.split("").map((item) => parseInt(item)));
        setTime(delivery_time);
        setPageCount(items_per_day);
        setPauseUntil(paused_until ? formatDateTo(paused_until, "yyyy-MM-dd") : null);
        setSelectedMethods([deliveryMethods[0].id, ...delivery_methods]);
      }
      disabled = false;
    }
    if (disabled !== allDisabled) {
      setAllDisabled(disabled);
    }
  }, [editItem]);

  useEffect(() => {
    if (selectedLanguage && languages.length > 0) {
      if (!booksDisabled) {
        setBooksDisabled(true);
        setAllDisabled(true);
      }
      getSubBooks(selectedLanguage.id, selectedBookType.label).then((books) => {
        let booksFiltered;

        /**
         * If book is represented then gets it from the books array.
         * If there is no book but its id is in the url then gets it from
         * the books array.
         * Else gets all not subscribed books.
         */
        const bookId = book?.book_id || Number(getLinkValue(bookId).value);
        if (bookId) {
          booksFiltered = books.filter((elem) => bookId === elem.id);
        } else {
          booksFiltered = books.filter((book) => !alreadySubscribedBookIds.includes(book.book_id));
        }

        if (booksFiltered.length > 0) {
          setSelectedBook(booksFiltered[0]);
          setBooks([...booksFiltered]);
          setBooksDisabled(false);
        }
      });
    }
  }, [selectedLanguage, selectedBookType]);

  useEffect(() => {
    if (selectedBook) {
      if (!allDisabled) {
        setAllDisabled(true);
      }
      getSubBookToc(selectedBook.id).then((paras) => {
        if (paras) {
          setSelectedPara(paras[0]);
          setParas(paras);
          setAllDisabled(false);
        }
      });
    }
  }, [selectedBook]);

  useEffect(() => {
    if ((!editMode && !selectedPara) || selectedDays.length === 0) {
      return;
    }
    if (editMode && (!editItem || isFeed || editItem.feed_type === "news")) {
      return;
    }

    const chunksAmount = editMode ? editItem.book.chunks : selectedBook.chunks - selectedPara.order;
    let daysAmount = Math.round(chunksAmount / pageCount);
    let finishDate = new Date(startDate);

    if (daysAmount <= 0) {
      return;
    }
    while (daysAmount > 0) {
      finishDate = addDays(finishDate, 1);
      const dayOfWeek = getDay(finishDate);
      const finishDayOfWeek = dayOfWeek === 0 ? weekDays[0].value : dayOfWeek;
      if (selectedDays.includes(finishDayOfWeek)) {
        daysAmount--;
      }
    }
    setEndDate(formatDateTo(finishDate, "yyyy-MM-dd"));
  }, [selectedPara, pageCount, selectedDays, time, startDate]);

  useEffect(() => {
    const nonClickable = allDisabled || selectedDays.length === 0;
    let disabled;
    if (editMode) {
      if (isFeed) {
        disabled = editItem.active === active;
      } else {
        if (!editItem) {
          disabled = true;
        } else {
          const { weekdays, delivery_time, items_per_day, delivery_methods } = editItem;
          disabled =
            nonClickable ||
            (weekdays === selectedDays.join("") &&
              delivery_time === time &&
              items_per_day === pageCount &&
              delivery_methods.length === selectedMethods.length - 1);
        }
      }
    } else {
      if (!currentTab && editFeed) {
        disabled = editFeed.active === active;
      } else {
        disabled = nonClickable;
      }
    }
    if (disabled !== btnDisabled) {
      setBtnDisabled(disabled);
    }
  }, [
    selectedDays,
    allDisabled,
    active,
    selectedDays,
    time,
    pageCount,
    selectedMethods,
    currentTab,
    editFeed,
    editItem,
  ]);

  const handleSubscriptionCreate = () => {
    if (!currentTab && editFeed) {
      dispatch(
        actionActivate({
          id: editFeed.id,
          activate: active,
          isFeed: true,
          isTypeNews: editFeed.feed_type === "news",
        }),
      );
      handleBack();
      return;
    }

    const params = {
      start_date: startDate.toISOString(),
      start_order: selectedPara.order,
      book: selectedBook.book_id,
      weekdays: selectedDays.sort().join(""),
      items_per_day: pageCount,
      delivery_time: time,
      delivery_methods: selectedMethods.slice(1),
    };

    dispatch(
      actionUpdateSubscriptions({
        params,
        fetchContent: true,
        bookType: selectedBookType.id,
        date: validateDate(new Date()),
      }),
    );

    // trigger for edit subscription popup
    if (onCreate) {
      onCreate(true);
    }

    dispatch(actionAddMessage(t("subAdded")));
    handleBack();
  };

  const handleBack = () => {
    if (isMobile && !isRealTablet) {
      // skip current route e.g. edit subs page after deleting/adding
      goPrevPage(1);
    } else {
      hideDialog();
    }
  };

  const handleUpdate = () => {
    let params = { delivery_methods: selectedMethods.slice(1) };
    if (isFeed) {
      params = {
        ...params,
        active,
      };
    } else {
      params = {
        ...params,
        weekdays: selectedDays.sort().join(""),
        items_per_day: pageCount,
        delivery_time: time,
      };
    }

    setAllDisabled(true);

    if (isFeed) {
      dispatch(actionUpdateFeed({ id: editItem.id, params }));
    } else {
      dispatch(actionUpdateSubscription({ id: editItem.id, params }));
    }

    setAllDisabled(false);
    if (editItem && onUpdate) {
      onUpdate(editItem);
    }

    handleBack();
  };

  const handleSubDelete = () => {
    showConfirm(t("deleteSub"), t("deleteSubWarn", { title: editItem.book.title }), () => {
      dispatch(actionUpdateSubscriptions(formSubAction(editItem, null, true)));
      handleBack();
    });
  };

  const handleSubscriptionActivate = (activate) => {
    const updateFun = activate ? startSubscription : pauseSubscription;
    setAllDisabled(true);
    updateFun(editItem.id, pauseUntil).then((updatedSub) => {
      setAllDisabled(false);
      dispatch(actionGetSubscriptions());
      if (updatedSub) {
        setActive(updatedSub.active);
      }
      if (updatedSub && onUpdate) {
        onUpdate(updatedSub);
      }
    });
  };

  const handleChangeTab = (activeTab) => () => {
    if (activeTab) {
      setEditFeed(null);
    }
    setCurrentTab(activeTab);
  };

  const renderDropDownOption = (expandKey, selected, options, setSelected, titleKey) => {
    const { expanded, label } = expandOptions[expandKey];
    const isTime = expandKey === "time";

    let containerStyle = "add-sub-option-container";
    if (isMobile && !isTime) {
      containerStyle += " mobile book";
    }
    if (isMobile && isTime) {
      containerStyle += " mobile delivery";
    }
    if (expandKey === "book") {
      containerStyle += booksDisabled ? " subs-disabled" : "";
    } else if (expandKey === "para") {
      containerStyle += allDisabled ? " subs-disabled" : "";
    }

    let selectedLabel;
    if (isTime) {
      selectedLabel = formatTimeString(time);
    } else {
      if (!selected || (allDisabled && (expandKey === "book" || expandKey === "para"))) {
        selectedLabel = "";
      } else {
        selectedLabel = selected[titleKey]
          ? selected[titleKey]
          : expandKey === "bookType"
          ? selected
          : "";
      }
    }

    const handleClose = () => {
      expandOptions[expandKey].expanded = false;
      setExpandOptions({ ...expandOptions });
    };

    const handleTimeClose = (newTime) => {
      handleClose();
      popupRef.current.hide();
      if (newTime !== time) {
        setTime(newTime);
      }
    };

    const handleSelect = (value) => {
      setSelected(value);
      handleClose();
    };

    const handleOpen = (e) => {
      if (expandKey === "time") {
        const rect = e.currentTarget.getBoundingClientRect();
        const TimePickerWidth = 160;
        const component = <TimePicker onClose={handleTimeClose} time={time} />;
        popupRef.current.showPopup(
          {
            left: rect.right - TimePickerWidth,
            top: rect.top,
          },
          component,
          rect,
        );
      }
      expandOptions[expandKey].expanded = true;
      setExpandOptions({ ...expandOptions });
    };

    return (
      <div className={containerStyle}>
        <span className="add-sub-option-label">{t(label) + ":"}</span>
        <div
          className={classNames("add-sub-arrow-text-container with-chevron", { open: expanded })}
          onClick={handleOpen}>
          <span className="add-sub-option-text ">{selectedLabel}</span>
        </div>
        {expanded && selected && (
          <DropDown
            containerRef={containerRef}
            expanded={true}
            options={options}
            onGetTitle={(item) => item[titleKey] || item}
            value={selected}
            position={{ top: remToPx(sassVariables.subOptionHeight), right: 0 }}
            maxHeight={200}
            onSelect={(value) => handleSelect(value)}
            onDropDownClose={() => handleClose()}
          />
        )}
      </div>
    );
  };

  const renderBookOptions = () => {
    return (
      <div className="add-sub-options-block book-options">
        <div>
          <span
            className={`add-sub-block-title tab-title ${currentTab ? "active" : ""}`}
            onClick={handleChangeTab(true)}>
            Subscriptions
          </span>
          <span
            className={`add-sub-block-title tab-title ${!currentTab ? "active" : ""}`}
            onClick={handleChangeTab(false)}>
            News
          </span>
        </div>
        {currentTab && (
          <div>
            {renderDropDownOption(
              "language",
              selectedLanguage,
              languages,
              setSelectedLanguage,
              "label",
            )}
            {renderDropDownOption(
              "bookType",
              selectedBookType,
              Object.values(bookType),
              setSelectedBookType,
              "label",
            )}
            {renderDropDownOption("book", selectedBook, books, setSelectedBook, "title")}
            {renderDropDownOption("para", selectedPara, paras, setSelectedPara, "title")}
          </div>
        )}
        {!currentTab && (
          <div>
            {feeds.map((elem) => {
              return (
                <div
                  onClick={() => setEditFeed(elem)}
                  key={elem.id}
                  className={"add-sub-option-container"}>
                  <div
                    className={`add-sub-option-label can-click ${
                      editFeed && editFeed.id === elem.id ? "active" : ""
                    }`}>
                    {elem.title}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderDeliveryOptions = () => {
    const pauseMinDate = addDays(new Date(), 1);
    let content;
    if (isFeed || !currentTab) {
      content = (
        <div
          className={classNames("subs-edit-active", {
            mobile: isMobile,
            "subs-disabled": !editMode,
          })}>
          <IconButton
            icon={active ? CheckYesIcon : CheckNoIcon}
            className={classNames(
              "add-sub-delivery-icon",
              !editMode ? "subs-disabled" : "add-sub-checkbox-icon",
            )}
            onClick={() => setActive(!active)}
          />
          {t("active")}
        </div>
      );
    } else {
      const containerStyle = classNames("add-sub-option-container", {
        delivery: isTablet || isMobile,
      });
      let isDisabled = false;
      if (endDate) {
        const parsedEndDate = parseISO(endDate);
        isDisabled =
          (!isSameDay(parsedEndDate, new Date()) && !isFuture(parsedEndDate)) ||
          (editItem && !!editItem.paused_until);
      }
      content = (
        <React.Fragment>
          <div className={containerStyle + " weekdays"}>
            {t("weekDays") + ":"}
            {renderWeekDays()}
          </div>
          {renderDropDownOption("time")}
          <div className={containerStyle}>
            {t("pagesPerDay") + ":"}
            {renderPagesCounter()}
          </div>
          <div className={classNames(containerStyle, { tablet: isTablet })}>
            {t("startReading") + ":"}
            {renderDateSelection(startDate, setStartDate, editMode)}
          </div>
          <div className={classNames(containerStyle, { tablet: isTablet })}>
            {t("finishReading") + ":"}
            {renderDateSelection(endDate, setEndDate, true)}
          </div>
          {editMode && (
            <div className="subs-vacation">
              <span className="add-sub-block-title">{t("vacationSettings")}</span>
              <div
                className={classNames(containerStyle, {
                  tablet: isTablet,
                  "subs-disabled": !active,
                })}>
                {t("pauseUntil") + ":"}
                {renderDateSelection(pauseUntil, setPauseUntil, !active, pauseMinDate)}
              </div>
              <div className="subs-start-stop">
                <IconButton
                  icon={active ? EgwWebFont.stop : EgwWebFont.restart}
                  disabled={isDisabled}
                  className="add-sub-state-icon"
                  onClick={() => handleSubscriptionActivate(!active)}
                />
                <span className={isDisabled ? "subs-disabled" : ""}>
                  {active ? t("stopSub") : t("startSub")}
                </span>
              </div>
            </div>
          )}
        </React.Fragment>
      );
    }

    return (
      <div
        className={classNames("add-sub-options-block delivery-options", {
          "subs-disabled": allDisabled,
        })}>
        <span className="add-sub-block-title">{t("delivery")}</span>
        {content}
      </div>
    );
  };

  const renderWeekDays = () => {
    const dayItems = weekDays.map((day) => {
      const { value, label } = day;
      const daySelected = selectedDays.includes(value);

      const handleDayClick = () => {
        if (daySelected) {
          selectedDays.splice(selectedDays.indexOf(value), 1);
        } else {
          selectedDays.push(value);
        }
        setSelectedDays([...selectedDays]);
      };

      return (
        <span
          key={label}
          className={classNames("add-sub-week-day", {
            "add-sub-week-day-selected": daySelected,
          })}
          onClick={() => handleDayClick()}>
          {label}
        </span>
      );
    });

    let containerStyle = "weekdays-container";
    if (isMobile || isTablet) {
      containerStyle += " mobile";
    }

    return <div className={containerStyle}>{dayItems}</div>;
  };

  const renderPagesCounter = () => {
    const handleCounterChange = (add) => {
      let counter = add ? pageCount + 1 : pageCount - 1;
      if (counter < 1) {
        counter = 1;
      }
      setPageCount(counter);
    };

    return (
      <div className="add-sub-pages-container">
        <span onClick={() => handleCounterChange()} className="add-sub-pages-btn">
          <IconButton icon={MinusIcon} onClick={() => handleCounterChange()} />
        </span>
        <span className="add-sub-pages-label">{pageCount}</span>
        <span onClick={() => handleCounterChange(true)} className="add-sub-pages-btn">
          <IconButton icon={PlusIcon} onClick={() => handleCounterChange(true)} />
        </span>
      </div>
    );
  };

  const renderDateSelection = (date, onDateChange, disabled, minDate) => {
    return (
      <div
        className={classNames("add-sub-date-container", {
          tablet: isTablet,
        })}>
        <span
          className={classNames("add-sub-date-value", {
            "add-sub-text-disabled": disabled,
            tablet: isTablet,
          })}>
          {formatDateTo(date, "yyyy-MM-dd", "dd.MM.yyyy")}
        </span>
        <IconButton
          icon={EgwWebFont.calendar}
          className={classNames({
            "add-sub-icon-disabled": disabled,
          })}
          onClick={() => {
            showDialog(
              <DatePicker
                date={date || new Date()}
                minDate={minDate || new Date()}
                onDateChange={onDateChange}
              />,
              {
                noMinWidth: true,
                noBorderRadius: true,
                rootClassAdv: "root-date-picker",
              },
            );
          }}
        />
      </div>
    );
  };
  const components = (
    <>
      {renderDeliveryOptions()}
      <DeliveryView
        allDisabled={allDisabled}
        selectedMethods={selectedMethods}
        setSelectedMethods={setSelectedMethods}
        editMode={editMode}
      />
    </>
  );
  const content = (
    <div className="add-sub-container" ref={containerRef}>
      <div
        className={classNames("add-sub-container-content-wrap", {
          "add-mode": !editMode,
          "zoom-mode": zoom > 120,
        })}>
        {!editMode && renderBookOptions()}
        {isTablet && !editMode ? (
          <div className="add-sub-container border-left">{components}</div>
        ) : (
          components
        )}
      </div>
      <DialogButtons
        className="add-sub-action-buttons-wrap"
        positiveText={t(editMode ? "save" : "start")}
        negativeText={t("cancel")}
        disabled={btnDisabled}
        onClickPositive={editMode ? handleUpdate : handleSubscriptionCreate}
        onClickNegative={handleBack}
      />
    </div>
  );

  const addSubsMobilePage = history.location.pathname === URLS.subscriptionAdd;

  return (
    <PopupWrap ref={popupRef}>
      {editMode ? (
        content
      ) : addSubsMobilePage ? (
        content
      ) : (
        <Scroll noHidePopups autoHeight autoHeightMax={"70vh"}>
          {content}
        </Scroll>
      )}

      {isMobile && editMode && !isFeed && (
        <div className="add-sub-options-block add-sub-options-block__delete">
          <span className="add-sub-block-title">{t("delete")}</span>
          <span className="subs-button-delete-sub" onClick={handleSubDelete}>
            {t("deleteSub")}
          </span>
        </div>
      )}
    </PopupWrap>
  );
};

AddSubscription.propTypes = {
  editItem: PropTypes.object,
  editMode: PropTypes.bool,
  onUpdate: PropTypes.func,
  isFeed: PropTypes.bool,
  onCreate: PropTypes.func,
  fromTabId: PropTypes.string,
  bookId: PropTypes.string,
  routeParams: PropTypes.object,
  type: PropTypes.string,
};

export default AddSubscription;
