import React from 'react';
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";

import "./View.scss";

/**
 * 
 * @param options  array - [{id, label}] 
 * @param value array - [id,...]
 * @param onChange - f([id,...])
 * @param className
 */

const CheckboxGroup = ({ options, value, onChange, className }) => {

  const getNewValue = (key, flag) => {
    let a = value.slice();
    if (flag) {
      // include key
      if (!value.includes(key)) {
        a.push(key);
      }
    }else {
      // exclude key
      const index = a.indexOf(key);
      if (index > -1) {
        a.splice(index, 1);
      }
    }
    return a;
  };

  return <div className={"CheckboxGroup" + (className ? ` ${className}` : '')}>
    {(options || []).map(item => {
      return <Checkbox key={item.id} title={item.label} checked={value.includes(item.id)} onChange={(isChecked) => {
        onChange(getNewValue(item.id, isChecked));
      }
      }/>;
    })}
  </div>;

};

CheckboxGroup.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default CheckboxGroup;
