import React, { useEffect, useMemo } from "react";
import { isAfter } from "date-fns";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BookList from "../components/book/BookList";
import { FETCH_ALL_BOOKS_DATA, fetchAllBooksData } from "../redux/library.actions";
import { getSortDate } from "../utils/Utils";
import { withLogin } from "../utils/HOC";
import { makeNavigationUrl} from "../utils/URLUtils";
import { makeBookListType } from "../components/book/BooksUtils";
import { Category, Loading } from "../components";
import { ScrollerView } from "../components/views";
import { useNavigationData } from "../components/NavigationContext";
import { main, RouteType } from "../utils/RouterUtils";
import { menuItems } from "../utils/MenuItems";
import { useLoader } from "../redux/selectors";
import { URLS } from "../shared/utils/url";

const HistoryPage = ({ history }) => {
  const dispatch = useDispatch();
  const { lang } = useParams();
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const allHistory = useSelector((state) => state.history.allHistory);
  const showReadingHistory = useSelector((state) => state.settings.showReadingHistory);
  const showListenHistory = useSelector((state) => state.settings.showListenHistory);
  const isBooksDataLoading = useLoader(FETCH_ALL_BOOKS_DATA);

  useEffect(() => {
    dispatch(fetchAllBooksData());
  }, [lang]); // fetch new data for sync with other devices

  const [books, langList] = useMemo(() => {
    let bookList = [...allHistory.filter((item) => item.luRead || item.luListen)];
    let finalList = [];
    const langsObj = {};
    bookList.forEach((item) => {
      const { luRead, luListen } = item;
      const sortDate = getSortDate(luRead, luListen, showReadingHistory, showListenHistory);
      if (sortDate) {
        finalList.push({
          ...item,
          sortDate: sortDate,
        });
        if (item.lang) {
          if (!langsObj[item.lang]) {
            langsObj[item.lang] = 0;
          }
          langsObj[item.lang]++;
        }
      }
    });

    finalList.sort((prev, next) => (isAfter(prev.sortDate, next.sortDate) ? -1 : 1));

    const langList = [];
    Object.keys(langsObj).forEach((item) => {
      const langItem = mainTree.find((langItem) => langItem.id === item);
      langList.push({
        id: item,
        isHistory: true,
        label: langItem ? langItem.label : item,
        className: "language",
        totalCount: langsObj[item],
      });
    });

    return [finalList, langList];
  }, [allHistory, mainTree, showListenHistory, showReadingHistory]);

  useEffect(() => {
    if (langList.length === 1 && langList[0].id) {
      const langUrl = URLS.history + "/" + langList[0].id;
      if (langUrl !== history.location.pathname) {
        // prevent several different {pathname} changes in the time,
        // but with the same {history.action}
        setTimeout(() => history.replace(langUrl));
      }
    }
  }, [langList]);

  const navigationData = () => {
    const nodeList = [main, { ...menuItems.history, children: langList }];
    let routeType = RouteType.folders;
    if (lang) {
      routeType = RouteType.books;
      const currentLang = langList.find((item) => item.id === lang);
      if (currentLang) {
        nodeList.push(currentLang);
      }
    }

    return { nodeList, routeType };
  };

  useNavigationData(navigationData());

  if (isBooksDataLoading) {
    return <Loading align="flex-center" type={Loading.types.HISTORY} />;
  }

  if (lang || langList.length === 1) {
    const currentLang = lang || langList[0].id;
    const bookList = books.filter((item) => item.lang === currentLang);
    return (
      <BookList
        bookListType={makeBookListType(true, showReadingHistory, showListenHistory)}
        books={bookList}
      />
    );
  }

  return (
    <ScrollerView>
      {langList.map((item, index) => {
        return (
          <Category key={index} item={item} route={makeNavigationUrl(item, { isHistory: true })} />
        );
      })}
    </ScrollerView>
  );
};

HistoryPage.propTypes = {
  history: PropTypes.object,
};

export default withLogin(HistoryPage);
