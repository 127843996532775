import { panelPrefix, PanelTypes } from "src/utils/URLUtils";

export const SAVE_WORKSPACE_BEHAVIOURS = {
  ASK: 1,
  SAVE: 2,
  CREATE_NEW: 3,
  DO_NOT_SAVE: 4,
};

export const SAVE_WORKSPACE_BEHAVIOURS_LABELS = {
  [SAVE_WORKSPACE_BEHAVIOURS.ASK]: "ask",
  [SAVE_WORKSPACE_BEHAVIOURS.SAVE]: "saveToCurrent",
  [SAVE_WORKSPACE_BEHAVIOURS.CREATE_NEW]: "createNew",
  [SAVE_WORKSPACE_BEHAVIOURS.DO_NOT_SAVE]: "doNotSave",
};

export const getSaveWSBehaviorLabel = (settingValue) => {
  if (settingValue in SAVE_WORKSPACE_BEHAVIOURS_LABELS) {
    return SAVE_WORKSPACE_BEHAVIOURS_LABELS[settingValue];
  }

  return "";
};

// fix WS from macOS app [EGWW-3268]
export const checkPrefix = (panelIds) => {
  const newPanelIds = [];
  panelIds.split(",").forEach((panelId) => {
    if (panelPrefix.some((prefix) => panelId.includes(prefix))) {
      newPanelIds.push(panelId);
    } else {
      newPanelIds.push(`${PanelTypes.PARA.id}${panelId}`);
    }
  });

  return newPanelIds.join(",");
};
