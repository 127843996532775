import React, { useState, useEffect, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { StudyCenterActions, scActions } from "./studyCenter.actions";
import {
  makeSCTree,
  moveToEnd,
  moveOneLevel,
  moveToTopic,
  findChildTopics,
  findParentTopics,
} from "./StudyCenterUtils";
import { useDialogContext } from "../dialog/DialogContext";
import {
  HighlightDialog,
  NoteDialog,
  NewFolderDialog,
  ScFoldersDialog,
  StaticShareDialog,
} from "./index";
import ScTreeView from "./ScTreeView";
import { useNavigationContext } from "../NavigationContext";
import { usePopupContext } from "../popup/PopupContext";
import ScTreeMenu from "./ScTreeMenu";
import {
  findNode,
  getBoundingClientRect,
  getClientPositions,
  makeTreeFromList,
} from "../../utils/Utils";
import { useAuth, useOrientationChange, useViewMode } from "../../hooks";
import { actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import {
  ItemTypes,
  SubscriptionTopicId,
  RootFolder,
  SubscriptionTopicFolder,
} from "./studyCenter.constants";
import {
  addTempEntry,
  clearEditorTempAction,
  filterSCItem,
  getTopicOfEditorEntry,
} from "./EditorCenterUtils";
import { onUpdateChecks } from "src/utils/TreeUtils";
import { CONTENT_CLASSES } from "../../shared/utils/content";
import { URLS } from "../../shared/utils/url";

const StudyCenterTree = ({ parentHeight, parentWidth, lockedTab }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { isUserEditor } = useAuth();
  const { openId, activeId } = useNavigationContext();
  const { addOrientationChangeListener, removeOrientationChangeListener } = useOrientationChange();

  const { showPopup, hidePopup } = usePopupContext();

  const entries = useSelector((state) => state.studyCenter.entries);
  const lastCreateEntry = useSelector((state) => state.studyCenter.lastCreateEntry);
  const topics = useSelector((state) => state.studyCenter.topics);
  const nextPage = useSelector((state) => state.studyCenter.nextPage);
  const checkedSCItem = useSelector((state) => state.studyCenter.checkedSCItem);
  const searchText = useSelector((state) => state.studyCenter.searchText);
  const validEntries = useSelector((state) => state.studyCenter.validEntries);
  const editorTempEntry = useSelector((state) => state.settings.editorTempEntry);
  const currentTopicId = useSelector((state) => state.studyCenter.currentTopicId);
  const currentEntryId = useSelector((state) => state.studyCenter.currentEntryId);
  const { isMobile } = useViewMode();
  const [expanded, setExpanded] = useState([RootFolder.id]);
  const { showConfirm, showDialog } = useDialogContext();
  const { showLoginDialog, isLogin } = useAuth();

  const [over, setOver] = useState();
  const treeWrapper = useRef(null);

  // Fixes need to close context menu after the device rotate.
  // (Some mobile devices do rerender of the component after the rotate
  // and "useOrientationChange" listener (is below [EGWW-1964]) doesn't hide the context menu).
  useEffect(() => {
    return () => {
      if (isMobile) {
        hidePopup();
      }
    };
  }, [isMobile]);

  useEffect(() => {
    if (isUserEditor && editorTempEntry?.selectData) {
      const tempTopicId = getTopicOfEditorEntry(editorTempEntry);
      const tempTopic = findNode(tempTopicId, treeList);
      if (tempTopic && expanded.indexOf(tempTopic.id) === -1) {
        handleExpand(tempTopic);
      }
    }
  }, [editorTempEntry]);

  useEffect(() => {
    if (currentTopicId) {
      const topic = findNode(currentTopicId, treeList);
      const topicIndex = expanded.findIndex((id) => topic.id === id);
      if (topicIndex === -1) {
        handleExpand(topic);
      }
    }
  }, [currentTopicId, currentEntryId]);

  const treeList = useMemo(() => {
    topics.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      }
      return 0;
    });

    const [listTopics, treeEntries] = addTempEntry(topics, entries, editorTempEntry, isUserEditor);

    const [filteredTopics, filteredEntries] = filterSCItem(
      listTopics,
      treeEntries,
      searchText,
      validEntries,
      checkedSCItem,
    );

    const rootTopics = makeTreeFromList(filteredTopics);

    const subsEntries = entries.filter((item) => item.topic === SubscriptionTopicFolder.id);
    subsEntries.sort((a, b) => a.lu - b.lu);
    if (subsEntries.length > 0) {
      rootTopics.unshift({
        child_count: subsEntries.length,
        lu: subsEntries[0].lu,
        ...SubscriptionTopicFolder,
      });
    }
    const tree = [
      {
        ...RootFolder,
        children: rootTopics,
      },
    ];

    const checked = isUserEditor ? checkedSCItem : null;

    return makeSCTree(tree, filteredEntries, expanded, 0, checked);
  }, [topics, entries, expanded, editorTempEntry, checkedSCItem, searchText]);

  useEffect(() => {
    if (lastCreateEntry) {
      let parentList = [];
      let parentId = lastCreateEntry.topic;
      while (parentId) {
        const parentTopic = topics.find((item) => item.id === parentId);
        if (parentTopic) {
          parentList.push(parentId);
          parentId = parentTopic.topic;
        } else {
          break;
        }
      }
      let needUpdate = false;
      parentList.forEach((item) => {
        if (!expanded.includes(item)) {
          needUpdate = true;
          expanded.push(item);
        }
      });
      if (needUpdate) {
        setExpanded([...expanded]);
      }
    }
  }, [lastCreateEntry, expanded, topics]);

  const handleExpand = (item) => {
    let newExpanded = [...expanded];
    const index = newExpanded.indexOf(item.id);

    if (index === -1) {
      const toPush = findParentTopics(treeList, item);
      newExpanded.push(...toPush.filter((el) => newExpanded.indexOf(el) < 0));

      dispatch(
        StudyCenterActions.fetchStudyCenter({
          topic: item.id,
          loadRecursive: true,
        }),
      );
    } else {
      if (lastCreateEntry && lastCreateEntry.topic === item.id) {
        dispatch(StudyCenterActions.setLastCreateEntry());
      }

      const toRemove = findChildTopics(treeList, item.id);
      newExpanded = newExpanded.filter((el) => toRemove.indexOf(el) < 0);
    }
    setExpanded(newExpanded);
  };

  useEffect(() => {
    let timeout;
    if (over) {
      if (expanded.indexOf(over) === -1) {
        timeout = setTimeout(() => {
          if (over) {
            dispatch(
              StudyCenterActions.fetchStudyCenter({
                topic: over,
                loadRecursive: true,
              }),
            );
          }
          expanded.push(over);
          setExpanded([...expanded]);
        }, 1000);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [over, expanded]);

  useEffect(() => {
    if (isLogin) {
      dispatch(StudyCenterActions.fetchSCTopics());
      dispatch(
        StudyCenterActions.fetchStudyCenter({
          topic: "null",
        }),
      );
    }
  }, [isLogin]);

  const handleView = (item, isOpenSearch = false) => {
    showLoginDialog(() => {
      if (isMobile) {
        dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, false));
      }

      const center = isUserEditor ? URLS.editorCenter : URLS.studyCenter;
      let pathname = center;
      let targetId;
      if (item.type === "folder") {
        pathname = item.id === RootFolder.id ? center : center + "/" + encodeURIComponent(item.id);
      } else {
        pathname = item.topic ? center + "/" + encodeURIComponent(item.topic) : center;
        targetId = item.id;
      }
      history.push({
        pathname,
        state: {
          isOpenSearch,
          targetId,
        },
      });
    });
  };

  const handleOpenLink = (item) => {
    if (item.start_para) {
      openId(item.start_para, {
        className: CONTENT_CLASSES.CHAPTER,
        newWindow: true,
        fromSC: true,
      });
      let topicId = item.topic === null ? RootFolder.id : item.topic;
      // dispatch(setLastCreateEntry(item));
      dispatch(StudyCenterActions.setCurrentSCElement(item.id, topicId));
    }
  };

  const handleDelete = (item) => {
    let message = "";
    let deleteTopic = [],
      deleteEntry = [];
    if (item.type === "folder") {
      deleteTopic.push(item.id);
      message = t("deleteFolderMessage", { title: item.title });
    } else {
      deleteEntry.push(item.id);
      message = t("deleteEntryMessage", { title: item.title });
    }
    showConfirm(
      "Delete",
      message,
      () => {
        if (item.type === ItemTypes.editorTemp) {
          dispatch(clearEditorTempAction());
          return;
        }

        dispatch(StudyCenterActions.deleteSCElements(deleteEntry, deleteTopic));
      },
      { positiveBtn: "delete", negativeBtn: "cancel", noTranslation: true },
    );
  };

  const handleEdit = (entry) => {
    if (entry.type === ItemTypes.note) {
      showDialog(<NoteDialog isNote entry={entry} />, { rootClassAdv: "note-dialog" });
    } else if (entry.type === ItemTypes.bookmark) {
      showDialog(<NoteDialog isNote={false} entry={entry} />, { rootClassAdv: "note-dialog" });
    } else if (entry.type === ItemTypes.highlight) {
      showDialog(<HighlightDialog entry={entry} />, { rootClassAdv: "highlight-dialog" });
    } else if (entry.type === ItemTypes.folder) {
      showDialog(<NewFolderDialog entry={entry} />);
    } else if (entry.type === ItemTypes.editorHighlight || entry.type === ItemTypes.editorTemp) {
      handleOpenLink(entry);
    }
  };

  const handleDragEnd = (movedId, overId) => {
    const draggableItem = entries.find((item) => item.id === movedId);
    const topic = topics.find((item) => item.id === overId);

    if (!draggableItem) {
      return;
    }

    if (topic) {
      dispatch(
        StudyCenterActions.updateSCEntry({
          ...draggableItem,
          order: 0,
          parent: topic.id,
          topic: topic.id,
        }),
      );
    } else {
      const placeHolderIndex = entries.findIndex((item) => item.id === overId);
      if (placeHolderIndex === -1) {
        return;
      }
      const placeHolderItem = entries[placeHolderIndex];

      if (placeHolderItem.topic === SubscriptionTopicId) {
        return;
      }

      const prevPlaceHolderItem = entries[placeHolderIndex - 1];
      let nextOrder = placeHolderItem.order - 0.1;
      if (prevPlaceHolderItem) {
        const prevOrder = prevPlaceHolderItem.order;
        nextOrder = prevOrder + (placeHolderItem.order - prevOrder) / 2;
        if (nextOrder === prevOrder) {
          nextOrder = prevOrder + 0.1;
        }
      }
      dispatch(
        StudyCenterActions.updateSCEntry({
          ...draggableItem,
          order: nextOrder,
          parent: placeHolderItem.topic,
          topic: placeHolderItem.topic,
        }),
      );
    }
    setOver();
  };

  const handleCopyToFolder = (topic, item) => {
    if (topic === "") {
      return;
    }

    if (item.type === "folder") {
      if (item.id !== topic) {
        dispatch(
          StudyCenterActions.createSCTopic({
            parent: topic,
            name: item.name,
          }),
        );
      }
    } else {
      dispatch(
        StudyCenterActions.createSCElement({
          ...item,
          id: uuid(),
          topic,
        }),
      );
    }
  };

  const handleNewFolder = (newFolder) => {
    handleExpand(newFolder);
  };

  const setCurrentSCElement = (item) => {
    let firstEntry;
    const entry = entries.find((entry) => entry.topic === item.id);
    if (entry) {
      firstEntry = entry.id;
    }
    dispatch(StudyCenterActions.setCurrentSCElement(firstEntry, item.id));
  };

  const handleItemClick = (item, action) => {
    if (!action) {
      if (item.type === "folder") {
        handleExpand(item);
        setCurrentSCElement(item);
      } else {
        handleOpenLink(item);
      }
    } else {
      let parent = null;
      const itemsArray = item.type === ItemTypes.folder ? topics : entries;
      switch (action) {
        case scActions.toggleExpand:
          handleExpand(item);
          if (expanded.indexOf(item.id) === -1) {
            setCurrentSCElement(item);
          }
          break;
        case scActions.open:
          handleOpenLink(item);
          break;
        case scActions.edit:
          handleEdit(item);
          break;
        case scActions.navigate:
          hidePopup();
          handleView(item);
          break;
        case scActions.navigateWithSearch:
          hidePopup();
          handleView(item, true);
          break;
        case scActions.delete:
          handleDelete(item);
          break;
        case scActions.newFolder:
          showLoginDialog(() => {
            if (item.id !== RootFolder.id) {
              parent = item.type === "folder" ? item.id : item.topic;
            }
            showDialog(<NewFolderDialog parent={parent} onNewFolder={handleNewFolder} />);
          });
          break;
        case scActions.moveDown:
          moveOneLevel(dispatch, itemsArray, item, false);
          break;
        case scActions.moveUp:
          moveOneLevel(dispatch, itemsArray, item, true);
          break;
        case scActions.moveToBottom:
          moveToEnd(dispatch, itemsArray, item, false);
          break;
        case scActions.moveToTop:
          moveToEnd(dispatch, itemsArray, item, true);
          break;
        case scActions.moveToFolder: {
          showDialog(
            <ScFoldersDialog
              topics={topics}
              onChange={(_, topic) => {
                const folderId = topic ? topic.id : null;
                let data = {};
                if (item.type === ItemTypes.folder) {
                  data.selectedTopics = [item];
                } else {
                  data.selectedEntries = [item];
                }
                moveToTopic(dispatch, folderId, topic, topics, data);
              }}
              title="moveTo"
            />,
          );
          break;
        }
        case scActions.copyToFolder: {
          showDialog(
            <ScFoldersDialog
              topics={topics}
              onChange={(topic) => handleCopyToFolder(topic, item)}
              title="copyTo"
            />,
          );
          break;
        }
        case scActions.openShareFolder: {
          showDialog(<StaticShareDialog topicId={item.id} />);
        }
      }
    }
  };

  const showContextMenu = (item, e) => {
    if (isUserEditor) {
      return;
    }

    if (item.id !== RootFolder.id) {
      const parentRect = getBoundingClientRect(treeWrapper.current);
      const { clientX, clientY } = getClientPositions(e);

      if (clientX && clientY) {
        showPopup(
          {
            left: clientX,
            top: clientY,
          },
          <ScTreeMenu
            onAction={(action) => {
              handleItemClick(item, action);
            }}
            entry={item}
          />,
          {
            hideOnClick: true,
            parentRect,
            onShow: () => {
              addOrientationChangeListener(hidePopup);
            },
            onHide: () => {
              removeOrientationChangeListener(hidePopup);
            },
          },
        );
      }
    }
  };

  const handleCheck = (checkItem) => {
    const updatedChecks = onUpdateChecks(checkItem, treeList, checkedSCItem);
    dispatch(StudyCenterActions.updateCheckedSCItem(updatedChecks));
  };

  return (
    <div className="sc-tree-wrap" ref={treeWrapper}>
      <ScTreeView
        onCheck={handleCheck}
        activeId={activeId}
        expanded={expanded}
        lockedTab={lockedTab}
        showContextMenu={showContextMenu}
        onContextMenu={(item, e) => {
          e.preventDefault();

          if ("ontouchstart" in window) {
            return;
          }

          showContextMenu(item, e);
        }}
        onOverChange={(overItem) => setOver(overItem.placeholder)}
        treeList={treeList}
        onDragEnd={handleDragEnd}
        count={entries.length + topics.length}
        onItemClick={handleItemClick}
        showIcons={true}
        onScrollStop={({ scrollTop, scrollHeight, clientHeight }) => {
          if (scrollHeight - scrollTop < clientHeight * 1.5) {
            if (nextPage) {
              dispatch(StudyCenterActions.fetchStudyCenter(nextPage));
            }
          }
        }}
        parentHeight={parentHeight}
        parentWidth={parentWidth}
      />
    </div>
  );
};

StudyCenterTree.propTypes = {
  parentHeight: PropTypes.number,
  parentWidth: PropTypes.number,
  lockedTab: PropTypes.bool,
};

export default StudyCenterTree;
