import { useState, useEffect, useCallback } from "react";
import {  getScreenOrientation} from "../utils/Utils";
import { useObserverPattern } from "./useObserverPattern";

const useOrientationChange = () => {
  const [previousOrientation, setPreviousOrientation] = useState(getScreenOrientation());
  const { addObserverListener, removeObserverListener,
    notifyObserverListeners } = useObserverPattern();

  const _notifyListeners = useCallback(
    (newOrientation, e) => {
      notifyObserverListeners({
        originalEvent: e,
        previousOrientation,
        newOrientation
      });
    },
    [notifyObserverListeners, previousOrientation]
  );

  useEffect(() => {
    const resizeListener = (e) => {
      const newOrientation = getScreenOrientation();
      if (previousOrientation !== newOrientation) {
        _notifyListeners(newOrientation, e);
        setPreviousOrientation(newOrientation);
      }
    };

    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [_notifyListeners, previousOrientation]);

  return {
    addOrientationChangeListener: addObserverListener,
    removeOrientationChangeListener: removeObserverListener
  };
};

export default useOrientationChange;
