import React, { useContext, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import TooltipWrap from "./tooltip/TooltipWrap";
import ReaderTooltipWrap from "./tooltip/ReaderTooltipWrap";
  

const TooltipContext = React.createContext({});

const TooltipProvider = ({ children }) => {
  const rootRef = useRef(null);
  const readerRef = useRef(null);

  const showTooltip = useCallback((...args) => {
    if (rootRef?.current) {
      rootRef.current.show(...args);
    }
  }, []);
  /**
   * 
   * @param  {...any} args 
   */
  const showReaderTooltip = useCallback((paraid, event, parent, parentBookId) => {
    if (readerRef?.current) {
      readerRef.current.showTooltip(paraid, event, parent, parentBookId);
    }
  },[]);

  const hideTooltip = useCallback(() => {
    if (rootRef?.current) {
      rootRef.current.hide();
    }
    if (readerRef?.current) {
      readerRef.current.hideTooltip();
    }
  },[]);

  return (
    <TooltipContext.Provider value={{ showTooltip, hideTooltip, showReaderTooltip }}>
      <TooltipWrap ref={rootRef} />
      <ReaderTooltipWrap ref={readerRef} />
      {children}
    </TooltipContext.Provider>
  );
};

TooltipProvider.propTypes = {
  children: PropTypes.node
};

export { TooltipContext, TooltipProvider };
/**
 * @returns {{
 *   showTooltip, hideTooltip, showReaderTooltip
 * }}
 */
export const useTooltipContext = () => useContext(TooltipContext);
