import { decode } from "html-entities";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Select } from "./index";
import { useDebouncedEffect } from "src/hooks";

/**
 *
 * @param value - string
 * @param onChange - f(string)
 * @param autocompleteRequest - async f(string): [string,...]
 * @param className
 */
const InputAutocomplete = ({
  value,
  onChange,
  onKeyPress,
  autocompleteRequest,
  className,
  updateOnInputChange,
  setIsOptionValid,
  parentNode,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [options, setOptions] = useState([]);

  const handleChange = (item) => {
    setInputValue(item.label);
    setOptions([]);
    onChange(item.label);

    if (setIsOptionValid) {
      setIsOptionValid(true);
    }
  };

  const handleInputChange = (event) => {
    const val = event.target.value;
    setInputValue(val);

    if (updateOnInputChange) {
      onChange(val);
    }

    if (onKeyPress) {
      onKeyPress(event);
    }

    if (!val.length) {
      setOptions([]);
      setInputValue(val);
      return;
    }
  };

  useDebouncedEffect(
    () => {
      if (!inputValue) {
        return;
      }
      autocompleteRequest(inputValue).then((data) => {
        let opt = [];
        if (data?.options) {
          opt = data.options.map((item, index) => ({ id: index, label: item }));
        }
        setOptions(opt);

        if (setIsOptionValid) {
          const valLowCase = inputValue.trim().toLowerCase();
          const isOptionExist = opt.findIndex(
            (option) => option.label.toLowerCase() === valLowCase,
          );
          setIsOptionValid(isOptionExist !== -1);
        }
      });
    },
    250,
    [inputValue],
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Select
      options={options}
      value={{ value: inputValue, options }}
      parentNode={parentNode}
      onChange={handleChange}
      onRenderTitle={() => (
        <input
          className={classNames("input-autocomplete", className)}
          type="text"
          value={decode(inputValue) || ""}
          onChange={handleInputChange}
        />
      )}
      onGetTitle={(item) => (item && item.label ? item.label : "")}
    />
  );
};

InputAutocomplete.defaultProps = {
  InputAutocomplete: false,
};

InputAutocomplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  autocompleteRequest: PropTypes.func,
  className: PropTypes.string,
  updateOnInputChange: PropTypes.bool,
  setIsOptionValid: PropTypes.func,
  parentNode: PropTypes.any,
};

export default InputAutocomplete;
