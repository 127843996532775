import { storeBooks } from "src/api/CacheHolder";
import { ContentConstants, } from "./content.actions";

const initialState = {
  mainTree: [],
  bibles: [],
  langMap: {},
  bookLangMap: {},
  dictionaries: [],
  periodicals: [],
  books: [], // array of all books
  bookTocs: [], // array of all bookTocs {children - treeView, chapters - listview }
  chapters: [], 
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ContentConstants.FETCH_FOLDERS_TO_ROOT_SUCCESS: {
      const { mainTree, options } = action.data;
      const langMap = {};
      mainTree.forEach((item) => {
        langMap[item.id] = item.label;
      });
      const newState = {
        ...state,
        langMap,
        mainTree: [...mainTree],
      };
      
      const { bookLangMap, bibles, dictionaries, books } = options;
    

      if (bibles) {
        newState.bibles = bibles;
      }

      if (dictionaries) {
        newState.dictionaries = dictionaries;
      }

      if (books) {
        newState.books = storeBooks(books);
      }

      if (bookLangMap) {
        newState.bookLangMap = {...newState.bookLangMap,  ...bookLangMap };
      }

      return newState;
    }
    case ContentConstants.FETCH_BIBLES_SUCCESS:
      return { ...state, bibles: action.data };
    case ContentConstants.FETCH_DICTIONARIES_SUCCESS:
      return { ...state, dictionaries: action.data };
    case ContentConstants.FETCH_PERIODICALS_SUCCESS:
      return { ...state, periodicals: action.data };

    case ContentConstants.ACTION_PUT_BOOKS:{
      const normBooks = storeBooks(action.data);

      return { ...state, books: normBooks };
    }
    
    default:
      return state;
  }
};
