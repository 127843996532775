import React from "react";
import { useTranslation } from "react-i18next";
import { HideIcon } from "../views";
import BookToc from "../bookToc/BookToc";
import PropTypes from "prop-types";

const BookTocBlock = ({  book, mobileMode, onButtonHideClick }) => {
  const { t } = useTranslation();
  const titleContainerStyle = mobileMode ? " bookInfoTitleContainerMin" : "";
  const titleStyle = mobileMode ? "bookInfoContentTitleMin" : "bookInfoContentTitle";
  const contentStr = mobileMode ? t("contents") : ` - ${t("contents")}`;

  return <React.Fragment>
    <HideIcon onClick={() => onButtonHideClick()} isMobile={mobileMode} />
    <div className={"bookInfoTitleContainer" + titleContainerStyle}>
      <span className={titleStyle}>{book.title}</span>
      <span className="bookInfoGrayText">{contentStr}</span>
    </div>
    {book?.children?.length &&
      <BookToc
        bookId={book.id}
        isMobile={mobileMode} />}
  </React.Fragment>;
};

BookTocBlock.propTypes = {
  book: PropTypes.object,
  mobileMode: PropTypes.bool,
  onButtonHideClick: PropTypes.func
};

export default BookTocBlock;
