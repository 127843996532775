/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { EGWLogo } from "../assets/CommonIcons";
import { getVersionRequest } from "../api/BaseAPI";
import { checkVersion } from "../utils/SystemUtils";
import { actionPutInfo, actionInitAppLoad } from "../redux/actions";
import { parseSearchUrl } from "../utils/SearchUtils";
import LoadingPage from "./LoadingPage";
import { getCurrentLang } from "../utils/Utils";
import { actionUpdateShowTextModePopup } from "../redux/params.actions";

import "./Pages.scss";
import { AppState } from "src/shared/constants";
import { getBaseHost, URLS } from "../shared/utils/url";

const VersionCheckTime = 1000 * 60 * 60;

//Main init load page for show loader or error message. Check version on server and refresh page
class InitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidMount() {
    const initLoadTime = Date.now() - window.initAppTime || 0;
    let loaderDelay = 10000 - initLoadTime;
    if (loaderDelay > 0) {
      this.loadTimeout = setTimeout(() => {
        dispatch(actionUpdateShowTextModePopup(true));
      }, loaderDelay);
    }

    const versionCheck = () => {
      // TODO make wrapper component for load page
      getVersionRequest().then((serverVersion) => {
        if (serverVersion) {
          checkVersion(serverVersion);
        }
      });
    };

    versionCheck();

    setInterval(() => {
      versionCheck();
    }, VersionCheckTime);

    const { dispatch, i18n } = this.props;

    /**
     * Wait i18n init instead use suspend component. Now only 2 loaders instead 3
     */

    const onI18nInit = () => {
      const lang = getCurrentLang(i18n);
      let searchParams;
      // get search parameters from ulr if the app opens with search url
      const isRelated = window.location.pathname === URLS.relatedSearch;
      if (isRelated || window.location.pathname === URLS.search) {
        searchParams = parseSearchUrl(window.location.search, isRelated);
        searchParams.isRelated = isRelated;
        if (!searchParams.query) {
          searchParams.query = "";
        }
      }
      dispatch(actionInitAppLoad({ lang, searchParams }));
    };

    if (i18n.isInitialized) {
      onI18nInit();
    } else {
      i18n.on("initialized", onI18nInit);
    }

    fetch(window.location.origin + "/info.json")
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          dispatch(actionPutInfo(result));
        }
      })
      .catch(() => {
        console.log("no info file");
      });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.log("componentDidCatch", error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }

  render() {
    const { hasError } = this.state;
    const { appState } = this.props;
    if (appState === AppState.loading) {
      return <LoadingPage />;
    } else if (appState === AppState.serverDown) {
      clearTimeout(this.loadTimeout);
      return (
        <div className="initLoad">
          <EGWLogo className="logo-page" />
          <span className="errorMessage">
            EGW Writings is currently down for maintenance
            <br />
            Try later, please
          </span>
        </div>
      );
    } else if (hasError) {
      clearTimeout(this.loadTimeout);
      return (
        <div className="initLoad">
          <EGWLogo className="logo-page" />
          <span className="errorMessage">
            Sorry, the application crashes.
            <br />
            Report sent to developers.
            <br />
            Please refresh page or go to <a href="/">Main page</a>
          </span>
        </div>
      );
    }
    clearTimeout(this.loadTimeout);
    return this.props.children;
  }
}

InitPage.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  i18n: PropTypes.object,
  appState: PropTypes.string,
};

const mapStateToProps = (state) => ({
  appState: state.system.appState,
});

export default connect(mapStateToProps)(withTranslation()(InitPage));
