import React from "react";
import { DefaultFontSize } from "../shared/utils/theme";

const ICONS_SORTED_BY = {
  ORDER: "ORDER",
  PRIORITY: "PRIORITY",
  HIDDEN_ORDER: "HIDDEN_ORDER"
};


export const renderIcons = (icons) => {
  return icons.map(({ render, key }) => {
    return <React.Fragment key={key}>{render(key)}</React.Fragment>;
  });
};

export const renderColumnIcons = (icons, maxRows = 8) => {
  const iconsArray = [];
  let currentIteration = 1;
  let totalIteration = 1;
  let currentArray = [];

  icons.forEach((icon) => {
    currentArray.push(icon);

    if (currentIteration > maxRows || icons.length === totalIteration) {
      iconsArray.push(currentArray);
      currentArray = [];
      currentIteration = 0;
    }

    currentIteration++;
    totalIteration++;
  });

  return iconsArray.map((icons, key) => {
    return (
      <div className="icons-column" key={key}>
        {renderIcons(icons)}
      </div>
    );
  });
};

export const getIconsSorted = (icons, sortedBy = ICONS_SORTED_BY.ORDER) => {
  let sortable;
  const clonedIcons = [...icons];

  if (sortedBy === ICONS_SORTED_BY.PRIORITY) {
    sortable = clonedIcons.sort((a, b) => a["priority"] - b["priority"]);
  } else if (sortedBy === ICONS_SORTED_BY.HIDDEN_ORDER) {
    sortable = clonedIcons.sort((a, b) => a["hiddenOrder"] - b["hiddenOrder"]);
  } else {
    sortable = clonedIcons.sort((a, b) => a["order"] - b["order"]);
  }

  return sortable.filter((icon) => icon.isShown);
};

export const getControlPanelIcons = (icons, { isCollapsed, parentWidth, initialWidth, zoom }) => {
  /**
   *  width of icon from .icon-button-base 1.2rem
   *  margin of .panel-base svg 0.25 * 2
   *  */

  let defaultIconSize = DefaultFontSize * (1.2 + 0.5);
  if (zoom) {
    defaultIconSize *= zoom / 100;
  }

  let currentWidth = initialWidth || defaultIconSize * 2;
  let hiddenIcons = [];
  let visibleIcons = [];

  if (isCollapsed) {
    hiddenIcons = [...getIconsSorted(icons, ICONS_SORTED_BY.HIDDEN_ORDER)];
  } else {
    const sortedIcons = getIconsSorted(icons);
    const sortedIconsByPriority = getIconsSorted(icons, ICONS_SORTED_BY.PRIORITY);
    const clonedSortedIconsByPriority = [...sortedIconsByPriority];

    for (let i = 0; i < sortedIcons.length; i++) {
      const icon = sortedIcons[i];
      currentWidth += icon.width || defaultIconSize;

      if (currentWidth >= parentWidth) {
        for (let j = 0; j < sortedIconsByPriority.length; j++) {
          const shiftedIcon = clonedSortedIconsByPriority.shift() || {};
          currentWidth = currentWidth - (shiftedIcon.width || defaultIconSize);
          hiddenIcons.push(shiftedIcon);

          if (currentWidth < parentWidth) {
            break;
          }
        }
      }
    }

    if (clonedSortedIconsByPriority.length > 0) {
      visibleIcons = [...getIconsSorted(clonedSortedIconsByPriority)];
    }

    if (hiddenIcons.length > 0) {
      hiddenIcons = [...getIconsSorted(hiddenIcons, ICONS_SORTED_BY.HIDDEN_ORDER)];
    }
  }

  return {
    hiddenIcons,
    visibleIcons,
    showHiddenIcons: hiddenIcons.length > 0,
    showVisibleIcons: visibleIcons.length > 0,
    widthOccupied: currentWidth
  };
};

