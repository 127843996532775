import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { int3DigitFormat } from "../../utils/Utils";
import { getResultsCount } from "../../utils/SearchUtils";

const HistoryResultsCount = ({ position, count }) => {
  const { t } = useTranslation();

  return (
    <div className="results-count results-history-count">
      {t("resultsCountSearchHistory", {
        loaded: int3DigitFormat(position),
        count: getResultsCount(count)
      })}
    </div>
  );
};

HistoryResultsCount.propTypes = {
  position: PropTypes.number,
  count: PropTypes.number
};

export default HistoryResultsCount;
