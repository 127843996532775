import React from "react";

import "./components.scss";

const Badge = ({ number }) => {
  let realNumber = number;
  // WORKAROUND need valid fix of api EGWW-2627
  if (number && typeof number === "object" && number?.count > -1) {
    realNumber = number.count;
  }
  if (isNaN(number)) {
    realNumber = 0;
  }
  return (
    <div className="badge-wrap">
      <div className="badge-text">{realNumber}</div>
    </div>
  );
};

export default Badge;
