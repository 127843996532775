import React, { useState } from "react";
import { useSelector } from "react-redux";

import { HistoryResultsCount } from "./index";
import SearchHistoryList from "../search/SearchHistoryList";
import SearchTopPanel from "../search/SearchTopPanel";

const HistoryPanel = () => {
  const searchHistory = useSelector((state) => state.search.searchHistory);

  const [activeIndex, setActiveIndex] = useState(1);
  const [isScroll, setIsScroll] = useState(false);

  const onChangeStartPosition = (value) => updateActiveIndex(value, true);
  const onScrollList = (row) => updateActiveIndex(row);

  const updateActiveIndex = (value, isScroll = false) => {
    setIsScroll(isScroll);
    setActiveIndex(value);
  };
 

  return (
    <div className="tab-container">
      <div className="search-result-header">
        <HistoryResultsCount position={activeIndex} count={searchHistory.length} />
        <SearchTopPanel
          total={searchHistory.length}
          loaded={searchHistory}
          start={activeIndex}
          isRightPanel
          isHistorySearch
          onChangePosition={onChangeStartPosition}
        />
      </div>
      <SearchHistoryList
        isScroll={isScroll}
        activeIndex={activeIndex}
        onScrollList={onScrollList}
        isList
      />
    </div>
  );
};

export default HistoryPanel;
