import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import LanguageSelectorView from "./LanguageSelectorView";
import { useRemToPx } from "../../hooks/viewModeHooks";

const AdvanceSearchLanguageSelector = ({ languages, selectedLanguage, onChange }) => {
  const { t } = useTranslation();
  const remToPx = useRemToPx();

  return (
    <LanguageSelectorView
      isHideCheckbox
      title={t("language")}
      items={languages}
      itemsChecked={[selectedLanguage]}
      onClickItem={onChange}
      defaultHeight={languages.length < 16 ? languages.length * remToPx(1) : 285}
    />
  );
};

AdvanceSearchLanguageSelector.propTypes = {
  languages: PropTypes.array,
  onChange: PropTypes.func,
  selectedLanguage: PropTypes.string
};

export default AdvanceSearchLanguageSelector;
