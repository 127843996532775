//DEFINE WHERE IS APP HOSTED
import { getEvnVariable } from "./assembly";

export const URL_ORIGIN_TEXT_APP = process.env.REACT_APP_BASE_TEXT || "";

//DEFINE WHAT API APPLICATION USES
export const getAPIUrl = () =>
  getEvnVariable(
    "REACT_APP_SERVER_URL",
    process.env.REACT_APP_SERVER_URL,
    "https://a.staging.egwwritings.org",
  );

export const getCPanelUrl = () => getEvnVariable("REACT_APP_CPANEL", process.env.REACT_APP_CPANEL);

export const getBaseHost = () =>
  getEvnVariable("REACT_APP_BASE_HOST", process.env.REACT_APP_BASE_HOST);

export const getTextAppHost = () =>
  getEvnVariable("REACT_APP_TEXT_APP_HOST", process.env.REACT_APP_TEXT_APP_HOST);

export const getNextAppHost = () =>
  getEvnVariable("REACT_APP_NEXT_APP_HOST", process.env.REACT_APP_NEXT_APP_HOST);

export const getSearchLogsUrl = () =>
  getEvnVariable("REACT_APP_SEARCH_LOGS_URL", process.env.REACT_APP_SEARCH_LOGS_URL);

export const getSearchLogsToken = () =>
  getEvnVariable("REACT_APP_SEARCH_LOGS_TOKEN", process.env.REACT_APP_SEARCH_LOGS_TOKEN);

//FIXME workaround before CD/CI update
const nextDomain = "next.";
const textDomain = "text.";
export const getMainAppHost = () => {
  let realMainApp = getEvnVariable("REACT_APP_MAIN_APP_HOST", process.env.REACT_APP_MAIN_APP_HOST);
  let link = realMainApp || getBaseHost();
  if (link.indexOf(nextDomain) !== -1) {
    link = link.split(nextDomain).join("");
  }
  if (link.indexOf(textDomain) !== -1) {
    link = link.split(textDomain).join("");
  }
  return link;
};

export const getClientId = () =>
  getEvnVariable("REACT_APP_CLIENT_ID_AUTHORIZED", process.env.REACT_APP_CLIENT_ID_AUTHORIZED);

export const makeResourceUrl = (url) => {
  let resUrl = getEvnVariable(
    "REACT_APP_RESOURCE_URL",
    process.env.REACT_APP_RESOURCE_URL,
    "https://egwwritings-a.akamaihd.net",
  );
  return resUrl + url;
};

export const makeImgFeedsUrl = (url) => getBaseHost() + "/" + url;

export const getControlPanelProfile = () => getCPanelUrl() + "/accounts/profile";
export const getSubsAddUrl = () => getCPanelUrl() + "/subscriptions/add";
export const getSubsEditUrl = () => getCPanelUrl() + "/subscriptions";
export const getBackupsUrl = () => getCPanelUrl() + "/studycenter/backups";

export const EXTERNAL_URLS = {
  donate: "https://partner.egwwritings.org/",
  about: "https://whiteestate.org/about/egwbio/",
  oldVersion: "https://egwwritings.org/",
  subscription: getCPanelUrl() + "/accounts/login/?next=/accounts/subscription/",
  faq: "https://ellenwhite.org/faq", //at this point before we moved to prod link will stay this staging link, but later it also will change
};

/**
 * add process.env.PUBLIC_URL to url.
 * @param {*} url
 */
export const makePublicUrl = (url) => (process.env.PUBLIC_URL || "") + url;

export const placeHolderImage = makePublicUrl("/images/book-placeholder.png");
export const placeHolderImageBig = makePublicUrl("/images/book-placeholder-big.png");
export const folderPlaceholder = makePublicUrl("/images/folder.svg");
export const flagPlaceholder = makePublicUrl("/images/no-flag.svg");
export const avatarPlaceholder = makePublicUrl("/images/avatar.svg");

const categories = ["base", "bible", "apl", "reference"];

export const getPlaceholderImage = (isBig, category = "base") => {
  let validCategory = categories.includes(category) ? category : "base";
  let fileName = validCategory + "" + (isBig ? "-big" : "") + ".png";

  return makePublicUrl("/images/placeholder/" + fileName);
};

export const makeImageUrl = (bookId, big, category) => {
  if (bookId) {
    const coverLink = big ? "large" : "small";

    // const def =
    //   "&default=" +
    //   encodeURIComponent(
    //     makeImgFeedsUrl(getPlaceholderImage(big, category)),
    //     // (big ? "/covers/book-placeholder-big.png" : "/covers/book-placeholder.png")
    //   );
    // return `${getAPIUrl()}/covers/${bookId}?type=${coverLink}${def}`;
    return `${getAPIUrl()}/covers/${bookId}?type=${coverLink}`;
  } else if (category) {
    return makeImgFeedsUrl(getPlaceholderImage(big, category));
  }
  return big ? placeHolderImageBig : placeHolderImage;
};

export const wrongCoverUrls = new Set();

export const getBookCover = (book, isBig, withDefault) => {
  let coverUrl;
  if (book) {
    let cover = isBig ? book.coverLarge : book.coverSmall;
    if (cover) {
      coverUrl = makeResourceUrl(cover);
    } else if (book.book_id) {
      coverUrl = makeImageUrl(book.book_id, isBig, book.folder_color_group || book.realType);
    }
  }
  if (wrongCoverUrls.has(coverUrl)) {
    return withDefault ? (isBig ? placeHolderImageBig : placeHolderImage) : undefined;
  }

  return coverUrl;
};

//TODO make kebab-case for urls
const routeBibliography = "/bibliography";
export const URLS = {
  home: "/",
  theme: "/getTheme",
  read: "/read",
  myLibrary: "/myLibrary",
  allCollection: "/allCollection",
  adventistBeliefs: "/allCollection/en/1340",
  categories: "/categories",
  titles: "/titles",
  login: "/login",
  logout: "/logout",
  studyCenter: "/studyCenter",
  editorCenter: "/editorCenter",
  subscription: "/subscription",
  subscriptionAdd: "/subscriptionAdd",
  subscriptionEdit: "/subscriptionEdit",
  audioBooks: "/audio",
  history: "/history",
  about: "/about",
  settings: "/settings",
  advSearch: "/advsearch",
  relatedSearch: "/relatedsearch",
  historySearch: "/search-history",
  featuredSearch: "/featured-search",
  information: "/information",
  languages: "/languages",
  donate: "/donate",
  contactus: "/contactus",
  lessons: "/lessons",
  helpingGuides: "/helping-guides",
  moreAboutUs: "/about",
  book: "/book",
  pdf: "/pdf",
  epub: "/epub",
  aboutEW: "/aboutEW",
  search: "/search",
  searchFilters: "/search-filters", // Textapp only.
  privacyPolicy: "/privacyPolicy",
  copirightNotice: "/legal-copyright-notice",
  userAgreement: "/userAgreement",
  dictionary: "/dictionary",
  themeMode: "/themeMode",
  faq: "/faq",
  patchNotes: "/patchNotes",
  staticShare: "/static-share",
  loading: "/loading",
  system: "/system",
  bibliography: routeBibliography,
  bibliographyEgw: routeBibliography + "/egw",
  bibliographyBible: routeBibliography + "/bible",
  workspaces: "/workspaces"
};

export const AMP_ROUTE = "/amp";

export const URLS_AMP = {
  read: AMP_ROUTE + URLS.read
};
