import { ACTION_LOGIN, ACTION_LOGOUT } from "../constants";
import { cartActionConstants } from "./actions";
import { sessionStorageSafe } from "../../shared/utils/systemUtils";

const initialState = {
  cartIds: new Set(),
  totalPrice: 0.0
};

const add = (totalPrice, price) =>
  parseFloat(parseFloat(totalPrice) + parseFloat(price)).toFixed(2);

const subtract = (totalPrice, price) =>
  parseFloat(parseFloat(totalPrice) - parseFloat(price)).toFixed(2);

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartActionConstants.ADD_TO_CART: {
      const shopItem = action.data;
      const shopItemId = shopItem.book_id || shopItem.id;
      const found = state.cartIds.has(shopItemId);
      if (!found) {
        const updatedCartIds = new Set(state.cartIds);
        updatedCartIds.add(shopItemId);
        const updatedTotalPrice = add(state.totalPrice, shopItem.price);
        return { ...state, cartIds: updatedCartIds, totalPrice: updatedTotalPrice };
      }
      return state;
    }
    case cartActionConstants.RESTORE_CART: {
      const { cartIds, shopBooks, shopPackages } = action.data;
      const updatedCartIds = new Set([...state.cartIds, ...cartIds]);
      let updatedTotalPrice = state.totalPrice;
      cartIds.forEach((cartId) => {
        const shopItem =
          shopBooks.find((shopBook) => shopBook.book_id === cartId) ||
          shopPackages.find((shopPackage) => shopPackage.id === cartId);
        updatedTotalPrice = add(updatedTotalPrice, shopItem.price);
      });
      return { ...state, cartIds: updatedCartIds, totalPrice: updatedTotalPrice };
    }
    case cartActionConstants.REMOVE_FROM_CART: {
      const shopItem = action.data;
      const shopItemId = shopItem.book_id || shopItem.id;
      const updatedCartIds = new Set(state.cartIds);
      updatedCartIds.delete(shopItemId);
      if (!updatedCartIds.size) {
        sessionStorageSafe.removeItem("cart");
      }
      const updatedTotalPrice = subtract(state.totalPrice, shopItem.price);
      return { ...state, cartIds: updatedCartIds, totalPrice: updatedTotalPrice };
    }
    case cartActionConstants.RESET_CART:
    case ACTION_LOGOUT:
      sessionStorageSafe.removeItem("cart");
      return initialState;
    case ACTION_LOGIN:
      return initialState;
    default:
      return state;
  }
};

export default cartReducer;
