import { SortDirection } from "react-virtualized";

import { StudyCenterActions } from "./studyCenter.actions";
import { clearSelection, findNode } from "../../utils/Utils";
import HighlightUtils from "./HighlightUtils";
import { CONTENT_CLASSES } from "../../shared/utils/content";
import { getContrastTextColor } from "src/utils/ThemeUtils";
import {
  RootFolder,
  ItemTypes,
  SortBy,
  Types,
  UnderlineColor,
  SubscriptionTopicFolder,
  SystemFolderIds,
} from "./studyCenter.constants";
import EgwWebFont from "src/assets/EgwWebFont";
import { TreeItemStateChecked, getTreeItemStateChecked } from "src/utils/TreeUtils";
import { defEditorHighLightColor } from "./EditorCenterUtils";
import { Settings } from "src/utils/Settings";
import { URLS } from "../../shared/utils/url";

export const sortByType = (a, b, asc) => {
  return asc ? Types[a] - Types[b] : Types[b] - Types[a];
};

export const sortByText = (a, b, asc) => {
  if (asc) {
    return a < b ? -1 : 1;
  }
  return a > b ? -1 : 1;
};

export const sortByField = (field, sortDirection, isTypes = false) => (a, b) => {
  if (isTypes) {
    return sortByType(a[field], b[field], sortDirection === SortDirection.ASC);
  }

  return sortByText(a[field], b[field], sortDirection === SortDirection.ASC);
};

export const sortList = (list, sortBy, sortDirection) => {
  if (!list.length) {
    return list;
  }

  const folderList = list.filter((item) => item.type === ItemTypes.folder);
  const itemsList = list.filter(
    (item) => item.type !== ItemTypes.folder && item.type !== ItemTypes.back,
  );

  if (!sortBy || !sortDirection) {
    return [
      ...folderList.sort(sortByField(SortBy.lu, SortDirection.DESC)),
      ...itemsList.sort(sortByField(SortBy.lu, SortDirection.ASC)),
    ];
  }

  if (sortBy === SortBy.tag) {
    return [...folderList, ...itemsList].sort(sortByField("type", sortDirection, true));
  }

  return [
    ...folderList.sort(sortByField(sortBy, sortDirection)),
    ...itemsList.sort(sortByField(sortBy, sortDirection)),
  ];
};

export const TagIcon = {
  highlight: EgwWebFont.highlight,
  editor_highlight: EgwWebFont.highlight,
  editor_temp: EgwWebFont["search-history"],
  bookmark: EgwWebFont.bookmark,
  note: EgwWebFont.note,
  folder: EgwWebFont["folder-close"],
  folderShared: EgwWebFont["folder-shared"],
  back: EgwWebFont["folder-up"],
};

export const makeTopicPath = (topics, topicId) => {
  if (!topics) {
    return [];
  }
  if (topicId === SubscriptionTopicFolder.id) {
    return [SubscriptionTopicFolder];
  }
  const currentTopic = topics.find((item) => item.id === topicId);
  const list = [];
  if (currentTopic) {
    let parent = currentTopic;
    do {
      list.push({
        ...parent,
        children: topics.filter((item) => item.parent === parent.id),
      });
      parent = topics.find((item) => item.id === parent.parent);
    } while (parent);
    list.reverse();
  }
  return list;
};

export const isHexColor = (colorStr) => /^#[0-9A-F]{6}$/i.test(colorStr);

export const actionFolder = (value, dispatch) => {
  const { name, parent, entry } = value;
  if (entry) {
    dispatch(
      StudyCenterActions.updateSCTopic({
        ...entry,
        name,
        parent,
      }),
    );
  } else {
    dispatch(
      StudyCenterActions.createSCTopic({
        parent,
        name,
      }),
    );
  }
};

export const actionScElement = (dispatch, userId, value) => {
  const { type, color, colorId, name, skipAdd, selectionData, entry, text, isNote, topic } = value;
  if (entry) {
    if (type === ItemTypes.highlight) {
      dispatch(
        StudyCenterActions.updateSCEntry({
          ...entry,
          name,
          topic,
          color_id: colorId,
        }),
      );
    } else {
      dispatch(
        StudyCenterActions.updateSCEntry({
          ...entry,
          topic,
          text,
        }),
      );
    }
  } else if (selectionData) {
    const { startId, endId, startOffset, endOffset, textWithoutRefcodes } = selectionData;

    if (type === ItemTypes.highlight) {
      clearSelection();
      dispatch(
        StudyCenterActions.createSCElement({
          type: ItemTypes.highlight,
          color_id: colorId === UnderlineColor ? undefined : colorId,
          name,
          start_para: startId,
          end_para: endId,
          topic,
          start_pos: startOffset,
          end_pos: endOffset,
          text: textWithoutRefcodes,
        }),
      );
      if (!skipAdd) {
        dispatch(
          StudyCenterActions.addLast(userId, {
            type: ItemTypes.highlight,
            topic,
            color,
            id: colorId,
            name,
          }),
        );
      }
    } else {
      const type = isNote ? ItemTypes.note : ItemTypes.bookmark;
      let params;
      if (isNote) {
        params = {
          end_pos: endOffset,
          end_para: endId,
          start_pos: endOffset + 1,
          start_para: endId,
        };
      } else {
        params = {
          start_pos: startOffset,
          start_para: startId,
          end_pos: startOffset + 1,
          end_para: startId,
        };
      }

      dispatch(
        StudyCenterActions.createSCElement({
          ...params,
          type,
          topic,
          text,
        }),
      );

      // dispatch(
      //   createSCElement({
      //     type,
      //     start_para: startId,
      //     end_para: endId,
      //     topic,
      //     start_pos: startOffset,
      //     end_pos: endOffset,
      //     text
      //   })
      // );
      dispatch(
        StudyCenterActions.addLast(userId, {
          type,
          topic,
          id: type,
        }),
      );
    }
  }
};

export const collectAllTopics = (topicList, ids) => {
  const items = [];
  ids.forEach((id) => {
    const topicIds = topicList.filter((topic) => topic.parent === id).map((item) => item.id);

    const childIds = collectAllTopics(topicList, topicIds);
    items.push(id);
    items.push(...childIds);
  });

  return items;
};

export const makeSCTree = (topics, entries, expanded, level = 0, checked) => {
  let list = [];
  const cacheStateChecked = new Map();
  topics.forEach((item) => {
    const isExpanded = expanded.indexOf(item.id) !== -1;
    const hasCh = checked
      ? getTreeItemStateChecked(item, checked, cacheStateChecked)
      : TreeItemStateChecked.none;
    list.push({
      ...item,
      level,
      isExpanded,
      checkType: hasCh,
      showExpand: item.type === ItemTypes.folder,
    });
    if (isExpanded) {
      if (item.children) {
        list = [...list, ...makeSCTree(item.children, entries, expanded, level + 1, checked)];
      }
      if (entries) {
        const children = entries.filter(
          (child) => child.topic === item.id || (item.id === RootFolder.id && !child.topic),
        );
        children.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else if (a.order < b.order) {
            return -1;
          }
          return 0;
        });

        children.forEach((child) => {
          list.push({
            ...child,
            level: level + 1,
          });
        });
      }
    }
  });

  return list;
};

export const moveOneLevel = (dispatch, array, item, top) => {
  const filtered = array
    .filter((elem) => elem.topic === item.topic)
    .sort((prev, next) => prev.order - next.order);
  const currentItemIndex = filtered.findIndex((elem) => elem.id === item.id);
  const currentItem = { ...item };
  const otherItemIndex = top ? currentItemIndex - 1 : currentItemIndex + 1;
  const otherItem = { ...filtered[otherItemIndex] };

  if (otherItem) {
    filtered[currentItemIndex] = otherItem;
    filtered[otherItemIndex] = currentItem;
    filtered.forEach((elem, i) => {
      if (elem.order !== i) {
        elem.order = i;
        elem.lu = Date.now();
      }
    });
    if (item.type === ItemTypes.folder) {
      dispatch(StudyCenterActions.updateStudyCenterDump({ topics: filtered }));
    } else {
      dispatch(StudyCenterActions.updateStudyCenterDump({ entries: filtered }));
    }
  }
};

export const moveToEnd = (dispatch, array, item, top) => {
  const filtered = array
    .filter((elem) => elem.topic === item.topic)
    .sort((prev, next) => prev.order - next.order);
  const currentItem = { ...item };
  const currentItemIndex = filtered.findIndex((elem) => elem.id === item.id);
  filtered.splice(currentItemIndex, 1);
  if (top) {
    filtered.unshift(currentItem);
  } else {
    filtered.push(currentItem);
  }

  filtered.forEach((elem, i) => {
    if (elem.order !== i) {
      elem.order = i;
      elem.lu = Date.now();
    }
  });
  if (item.type === ItemTypes.folder) {
    dispatch(StudyCenterActions.updateStudyCenterDump({ topics: filtered }));
  } else {
    dispatch(StudyCenterActions.updateStudyCenterDump({ entries: filtered }));
  }
};

export const moveToTopic = (dispatch, folderId, targetFolder, topics, items, isUserEditor) => {
  if (targetFolder) {
    const isExist = topics.find((item) => item.id === targetFolder.id);
    if (!isExist) {
      topics.push(targetFolder);
    }
  }

  let updatedEntries = [];
  let updatedTopics = (items.selectedTopics || []).map((elem) => ({
    ...elem,
    topic: folderId,
    parent: folderId,
    lu: Date.now(),
  }));

  let oldTopics = {};
  (items.selectedEntries || []).forEach((item) => {
    if (!oldTopics[item.topic]) {
      oldTopics[item.topic] = 0;
    }
    oldTopics[item.topic]--;
    updatedEntries.push({
      ...item,
      topic: folderId,
      parent: folderId,
      lu: Date.now(),
    });
  });

  if (folderId) {
    oldTopics[folderId] = updatedEntries.length;
  }

  for (let topicId in oldTopics) {
    const topic = topics.find((item) => item.id === topicId);
    if (topic) {
      updatedTopics.push({
        ...topic,
        child_count: (topic.child_count || 0) + oldTopics[topic.id],
      });
    }
  }

  if (isUserEditor) {
    dispatch(
      StudyCenterActions.moveToTopicEditor({
        updatedTopics,
        updatedEntries,
        selectedEntries: items.selectedEntries,
        targetFolder,
      }),
    );
    return;
  }

  dispatch(
    StudyCenterActions.updateStudyCenterDump({ topics: updatedTopics, entries: updatedEntries }),
  );
};

/**
 * Method adds study center elements to node
 * @param {HTMLNode} wrapper
 * @param {Array} entries
 */
export const addSCEntries = (wrapperElement, entries, editorTempEntry, currentEntryId) => {
  if (wrapperElement) {
    const hlUtils = new HighlightUtils({ wrapperElement });
    if (entries.length) {
      entries.sort((a, b) => sortByType(a.type, b.type, false));
      entries.forEach((item) => {
        if (item.type === ItemTypes.highlight || item.type === ItemTypes.editorHighlight) {
          const element = wrapperElement.querySelector(`.hh[data-hid="${item.id}"]`);
          if (!element) {
            hlUtils.addHL({
              ...item,
              isActive: currentEntryId === item.id,
            });
          }
        } else if (item.type === ItemTypes.bookmark || item.type === ItemTypes.note) {
          const element = wrapperElement.querySelector(`[id="${item.id}"]`);
          if (!element) {
            hlUtils.addMark(item);
          }
        }
      });
    }

    if (editorTempEntry?.selectData) {
      const id = Settings.editorTempEntry.id;
      const { startId, endId, startOffset, endOffset } = editorTempEntry?.selectData;
      const element = wrapperElement.querySelector(`.hh[data-hid="${id}"]`);
      if (!element) {
        hlUtils.addHL({
          id,
          start_para: startId,
          end_para: endId,
          start_pos: startOffset,
          end_pos: endOffset,
          color: editorTempEntry.color,
          isActive: currentEntryId === id,
        });
      }
    }
  }
};

export const removeAllEntries = (wrapperElement) => {
  if (wrapperElement) {
    const hlUtils = new HighlightUtils({ wrapperElement });
    wrapperElement.querySelectorAll(".hh").forEach((hh) => {
      hlUtils.removeHighlight(hh.dataset.hid);
    });

    const iconNodes = wrapperElement.querySelectorAll(
      `.${CONTENT_CLASSES.SC_NOTE}, .${CONTENT_CLASSES.SC_BOOKMARK}`,
    );
    Array.from(iconNodes).forEach((node) => node.remove());
  }
};

/**
 * method generate richcontent in background based on paragraphs and entries
 * @param {Object[]} paragraphs
 * @param {Object[]} entries
 */
export const makeRichContent = (paragraphs, entries, editorTempEntry, currentEntryId) => {
  let wrapper = document.createElement("div");
  wrapper.classList.add("reader-content-holder");
  //convert all paragraphs to string
  let divContent = "";
  paragraphs.forEach((item) => {
    divContent += `<div data-id="${item.id}" id="${item.id}">${item.content}</div>`;
  });
  // add all content as html content
  wrapper.innerHTML = divContent;
  // add sc elements to virtual div
  addSCEntries(wrapper, entries, editorTempEntry, currentEntryId);
  // extract highlighted content from virtual list and put as richContent
  const newRichContent = [];
  for (let i = 0; i < wrapper.childElementCount; i++) {
    newRichContent.push({
      ...paragraphs[i],
      richContent: wrapper.children[i].innerHTML,
    });
  }
  if (wrapper.remove) {
    wrapper.remove();
  }
  // clear object
  wrapper = undefined;

  return newRichContent;
};

export const makeStaticShareUrl = (userId, shareId, topicId) => {
  return `${URLS.staticShare}/${userId}/${shareId}/${topicId || ""}`;
};

export const subsFolderOrItem = (item) =>
  item.id === SubscriptionTopicFolder.id || item.topic === SubscriptionTopicFolder.id;

export const isSystemFolder = (id) => SystemFolderIds.includes(id);

export const onDragEndHandler = (dispatch, allList, topics, overId, movedId) => {
  const placeHolderIndex = allList.findIndex((item) => item.id === overId);
  const draggableItem = allList.find((item) => item.id === movedId);
  let topic = topics.find((item) => item.id === overId);
  if (topic) {
    dispatch(
      StudyCenterActions.updateSCEntry({
        ...draggableItem,
        order: 0,
        parent: topic.id,
        topic: topic.id,
      }),
    );
  } else {
    if (placeHolderIndex === -1) {
      return;
    }
    const placeHolderItem = allList[placeHolderIndex];
    const prevPlaceHolderItem = allList[placeHolderIndex - 1];
    let nextOrder = placeHolderItem.order - 0.1;
    if (prevPlaceHolderItem) {
      const prevOrder = prevPlaceHolderItem.order;
      nextOrder = prevOrder + (placeHolderItem.order - prevOrder) / 2;
      if (nextOrder === prevOrder) {
        nextOrder = prevOrder + 0.1;
      }
    }
    dispatch(
      StudyCenterActions.updateSCEntry({
        ...draggableItem,
        order: nextOrder,
        parent: placeHolderItem.topic,
        topic: placeHolderItem.topic,
      }),
    );
  }
};

export const makeSCEntryPreview = (entry) => {
  let content = entry.type + " " + entry.text;
  if (entry.type === ItemTypes.folder) {
    content = `${entry.title}(${entry.child_count || 0})`;
  } else if (entry.type === ItemTypes.highlight) {
    const color = getContrastTextColor(entry.color);
    content = `<div style="background-color: ${entry.color}; color: ${color}">${entry.text}</div>`;
  }
  return content;
};

export const findParentTopics = (treeList, item) => {
  const path = [item.id];
  let current = item.topic;
  let parentTopic = null;

  do {
    if (current == null || current == RootFolder.id) {
      path.push(RootFolder.id);
      break;
    }

    parentTopic = findNode(current, treeList);
    if (parentTopic) {
      path.push(parentTopic.id);
      current = parentTopic?.topic;
    }
  } while (parentTopic);

  return path;
};

export const findChildTopics = (treeList, id) => {
  const stack = [],
    found = [];
  treeList.forEach((child) => {
    if (child.id === id) {
      found.push(child.id);
      stack.push(child);
    }
  });
  if (id === RootFolder.id) {
    found.push(id);
    stack.push(RootFolder);
  }
  while (stack.length > 0) {
    const current = stack.pop();
    treeList.forEach((child) => {
      if (child.topic === current.id || (child.topic === null && current.id === RootFolder.id)) {
        stack.push(child);
        found.push(child.id);
      }
    });
  }
  return found;
};

export const checkForObsoleteColorsInEntries = (entries, colors) => {
  return entries.some((entry) => {
    const foundColor = colors.find((color) => entry.color_id === color.id);
    return !(foundColor && foundColor.color === entry.color);
  });
};

export const updateColorOfEntries = (entries, colors, isEditorMode) => {
  const defColor = isEditorMode ? defEditorHighLightColor.color : "#000000";

  return entries.map((entry) => {
    const foundColor = colors.find((color) => entry.color_id === color.id);

    return {
      ...entry,
      color: foundColor?.color || defColor,
    };
  });
};
