import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isEventKeys, trottleCheck } from "../../utils/Utils";

import { KeyCodes } from "../../shared/utils/dom";
import { useTooltipContext } from "../popup/TooltipContext";
import { useViewMode } from "src/hooks";
import IconView from "src/shared/components/IconView";
import { useWhatInputValueIsLastPointerMouse } from "../../what-input/useWhatInput";

import "./View.scss";

const IconButton = ({
  icon,
  showIcon,
  active,
  increasedTapZone,
  disabled = false,
  onClick,
  onDisableClick,
  className,
  title,
  ariaLabel,
  role = "button",
  tooltipPosition,
  ariaPressed = undefined,
  bordered,
  noFocusable,
  trottle = 0,
  ...otherProps
}) => {
  const classes = ["icon-button-base"];
  const trottleRef = useRef(Date.now());

  const isLastPointerMouse = useWhatInputValueIsLastPointerMouse();

  if (active) {
    classes.push("icon-button-active");
  } else if (disabled) {
    classes.push("icon-button-disabled");
  }
  if (bordered) {
    classes.push("bordered");
  }
  if (className) {
    classes.push(className);
  }

  const { hideTooltip, showTooltip } = useTooltipContext();
  const { textMode } = useViewMode();

  useEffect(() => {
    return () => {
      hideTooltip();
    };
  }, []);

  const label = ariaLabel || title;
  let onFinalClick = undefined;

  if (onClick && !disabled) {
    onFinalClick = (e) => {
      if (trottleCheck(trottle, trottleRef.current)) {
        hideTooltip();
        trottleRef.current = Date.now();
        onClick(e);
      }
    };
  } else if (onDisableClick && disabled) {
    onFinalClick = (e) => {
      if (trottleCheck(trottle, trottleRef.current)) {
        hideTooltip();
        onDisableClick(e);
      }
    };
  }

  const onKeyDownHandle = (event) => {
    if (isEventKeys(event, [KeyCodes.enter, KeyCodes.space]) && onFinalClick) {
      onFinalClick(event);
    }
  };

  if (textMode && title && !showIcon) {
    classes.push("text-only");
    return (
      <span
        {...otherProps}
        onClick={onFinalClick}
        aria-label={label}
        role={role}
        className={classes.join(" ")}>
        {label}
      </span>
    );
  }

  if (increasedTapZone && (!textMode || showIcon)) {
    classes.push("has-increased-tap-zone");
  }

  const allProps = {
    tabIndex: noFocusable ? -1 : disabled ? undefined : 0,
    // className: classes.join(" "),
    onKeyDown: onKeyDownHandle,
    "aria-label": label,
    role,
    "aria-pressed": active ?? ariaPressed,
    onMouseEnter: isLastPointerMouse
      ? (e) => {
          if (title) {
            showTooltip(title, e, null, tooltipPosition);
          }
        }
      : undefined,

    onClick: onFinalClick,
    ...otherProps,
  };

  return <IconView  icon={icon} className={classes.join(" ")}  {...allProps}/>;
};

IconButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.number]).isRequired,
  increasedTapZone: PropTypes.bool,
  active: PropTypes.bool,
  bordered: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  tooltipPosition: PropTypes.string,
  showIcon: PropTypes.bool,
  ariaPressed: PropTypes.bool,
  noFocusable: PropTypes.bool,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
  onDisableClick: PropTypes.func,
  trottle: PropTypes.number,
};

export default IconButton;
