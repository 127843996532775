import { EXTERNAL_URLS, URLS } from "../shared/utils/url";
import { MENU_ITEMS_LOCATIONS } from "../shared/utils/menu";

import EgwWebFont from "src/assets/EgwWebFont";

export const MENU_STATE = {
  COLLAPSE: "0",
  MENU: "1",
  TREE: "2",
};

export const menuItems = {
  allCollection: {
    icon: EgwWebFont["all-collections"],
    label: "allCollection",
    id: URLS.allCollection,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  categories: {
    icon: EgwWebFont["tree"],
    label: "categories",
    id: URLS.categories,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  titles: {
    icon: EgwWebFont["titles"],
    label: "titles",
    id: URLS.titles,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  audioBooks: {
    icon: EgwWebFont["audio-books"],
    label: "audiobooks",
    id: URLS.audioBooks,
    isAudio: true,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  myLibrary: {
    icon: EgwWebFont["my-library"],
    label: "myLibrary",
    id: URLS.myLibrary,
    onlyAuth: true,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  workspaces: {
    icon: EgwWebFont.layouts,
    label: "workspaces",
    id: URLS.workspaces,
    onlyAuth: true,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME
    ],
  },
  studyCenter: {
    icon: EgwWebFont["study-center"],
    label: "studyCenter",
    id: URLS.studyCenter,
    onlyAuth: true,
    hideForEditorUser: true,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  editorCenter: {
    icon: EgwWebFont["study-center-plus"],
    label: "editorCenter",
    id: URLS.editorCenter,
    onlyAuth: true,
    onlyEditorUser: true,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  history: {
    icon: EgwWebFont.history,
    label: "history",
    id: URLS.history,
    onlyAuth: true,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  subscription: {
    icon: EgwWebFont.subscription,
    label: "subscriptions",
    id: URLS.subscription,
    onlyAuth: true,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  about: {
    icon: EgwWebFont["ellen-light"],
    secondIcon: EgwWebFont["ellen-dark"],
    label: "aboutEW",
    id: URLS.aboutEW,
    url: EXTERNAL_URLS.about,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  moreAboutUs: {
    icon: EgwWebFont["helping-guides"],
    label: "whatIsEGW",
    id: URLS.about,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  faq: {
    icon: EgwWebFont.faq,
    label: "faq",
    shortLabel: "shortFaq",
    id: URLS.faq,
    url: EXTERNAL_URLS.faq,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  bibliography: {
    icon: EgwWebFont["ABC"],
    label: "bibliography",
    id: URLS.bibliography,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  adventistBeliefs: {
    icon: EgwWebFont["adventist-beliefs"],
    label: "adventistBeliefs",
    id: URLS.adventistBeliefs,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  donate: {
    icon: EgwWebFont.donate,
    label: "supportOur",
    id: URLS.donate,
    url: EXTERNAL_URLS.donate,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  information: {
    icon: EgwWebFont.information,
    label: "information",
    id: URLS.information,
  },
  helpingGuides: {
    icon: EgwWebFont["helping-guides"],
    label: "helpingGuides",
    id: URLS.helpingGuides,
  },
  contactus: {
    icon: EgwWebFont["contact-us"],
    label: "contactUs",
    id: URLS.contactus,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME
    ],
  },
  settings: {
    icon: EgwWebFont.settings,
    label: "settings",
    id: URLS.settings,
    onlyAuth: true,
    locations: [
      MENU_ITEMS_LOCATIONS.BREADCRUMBS,
      MENU_ITEMS_LOCATIONS.MENU,
      MENU_ITEMS_LOCATIONS.HOME,
    ],
  },
  languages: {
    icon: EgwWebFont.languages,
    label: "egwlanguages",
    id: URLS.languages,
  },
  search: {
    icon: EgwWebFont["search-book"],
    label: "search",
    id: URLS.search,
  },
  advsearch: {
    icon: EgwWebFont["search-book"],
    label: "advsearch",
    id: URLS.advsearch,
  },
  relatedSearch: {
    icon: EgwWebFont["search-book"],
    label: "relatedsearch",
    id: URLS.relatedSearch,
  },
  historySearch: {
    icon: EgwWebFont["search-history"],
    label: "historySearch",
    id: URLS.historySearch,
  },
  featuredSearch: {
    icon: EgwWebFont["search-history"],
    label: "search",
    id: URLS.featuredSearch,
  },
  themeMode: {
    icon: EgwWebFont["theme-select"],
    label: "colorMode",
    id: URLS.themeMode,
    onlyMobile: true,
    locations: [MENU_ITEMS_LOCATIONS.MENU],
  },
  privacyPolicy: {
    label: "privacyPolicy",
    id: URLS.privacyPolicy,
  },
  copirightNotice: {
    label: "copirightNotice",
    id: URLS.copirightNotice,
  },
  lessons: {
    icon: EgwWebFont.lessons,
    label: "lessons",
    id: URLS.lessons,
  },
  userAgreement: {
    label: "userAgreement",
    id: URLS.userAgreement,
  },
  dictionary: {
    label: "dictionary",
    id: URLS.dictionary,
  },
  oldVersion: {
    icon: EgwWebFont.old,
    label: "oldVersionOfSite",
    url: EXTERNAL_URLS.oldVersion,
    locations: [MENU_ITEMS_LOCATIONS.MENU],
    onlyMobileOrTablet: true,
  },
  login: {
    icon: EgwWebFont.profile,
    label: "login",
    id: URLS.login,
    onlyMobileOrTablet: true,
    onlyNoAuth: true,
    locations: [MENU_ITEMS_LOCATIONS.MENU],
  },
  logout: {
    icon: EgwWebFont.profile,
    label: "logout",
    id: URLS.logout,
    onlyMobileOrTablet: true,
    onlyAuth: true,
    locations: [MENU_ITEMS_LOCATIONS.MENU],
  },
  page404: {
    label: "page404",
    id: "",
  },
  loading: {
    label: "loading",
    id: "/loading",
  },
  system: {
    label: "system",
    id: "/system",
  },
  patchNotes: {
    label: "patchNotes",
    id: URLS.patchNotes,
  },
};

// TODO: does it have a sense to implement an additional filter based on some options?
export const getMenuItemsAsList = (
  menuItemsLocation = null,
  useStrictCheckLocations = false,
  devMode,
  isUserEditor,
) => {
  let list = [];
  for (let key in menuItems) {
    const menuItem = menuItems[key];
    if (
      (menuItem.hideForEditorUser && isUserEditor) ||
      (menuItem.onlyEditorUser && !isUserEditor)
    ) {
      continue;
    }

    list.push(menuItem);
  }

  if (menuItemsLocation) {
    let menuItemsLocations;

    if (menuItemsLocation.constructor === Array) {
      menuItemsLocations = [...menuItemsLocation];
    } else {
      menuItemsLocations = [menuItemsLocation];
    }

    list = list.filter(({ locations, onlyDevMode }) => {
      if (onlyDevMode && !devMode) {
        return false;
      }
      if (!locations) {
        return false;
      }
      if (useStrictCheckLocations) {
        const containsAll = locations.every((location) => menuItemsLocations.includes(location));
        return containsAll && locations.length === menuItemsLocations.length;
      }
      return locations.some((location) => menuItemsLocations.includes(location));
    });
  }

  return list;
};

export const makeMenuState = (newState, current) => {
  let newMenuState = MENU_STATE.COLLAPSE;
  if (newState === MENU_STATE.TREE && current !== MENU_STATE.TREE) {
    newMenuState = MENU_STATE.TREE;
  } else if (newState === MENU_STATE.MENU && current !== MENU_STATE.MENU) {
    newMenuState = MENU_STATE.MENU;
  }
  return newMenuState;
};
