import {
  FETCH_DIALOG_CONTENTS_SUCCESS
} from "./constants";

const initialState = {
  dialogContent: {}
};

export default (state = initialState, action) => {
  switch (action.type) {  
    case FETCH_DIALOG_CONTENTS_SUCCESS: {
      return { ...state, dialogContent: action.data };
    }
    default:
      return state;
  }
};
