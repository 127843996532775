import React, { useState, useEffect, useRef, forwardRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ProfileIcon } from "../../assets/CommonIcons";
import { getControlPanelProfile, getBackupsUrl, URLS, EXTERNAL_URLS } from "../../shared/utils/url";
import { IconButton } from "../views/";
import { useViewMode, useAuth, useUiLanguagesTranslated, useDevMode } from "../../hooks";
import { getGravatarImage } from "../../utils/Gravatar.utils";
import { getBoundingClientRect } from "src/utils/Utils";
import { makeOAuthRequest } from "src/shared/api/baseApi";
import { openNewTab } from "../../utils/RouterUtils";
import { makeLangImage } from "../../utils/URLUtils";
import usePopupUiLangs from "../settings/UiLangsSelector";
import PopupWrap from "../popup/PopupWrap";
import { getEventPath } from "../../shared/utils/dom";
import { useWhatInputValueIsLastPointerMouse } from "../../what-input/useWhatInput";

import "./LoginHeaderMenu.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionSetEllen4AllToken } from "src/redux/actions";
import useDialogHelpTranslate from "../book/translate/useDialogHelpTranslate";

const POPUP_ID = "HeaderMenu";

const popupRefValueDefault = { showPopup: () => {}, hide: () => {} };

const LoginHeaderMenu = () => {
  const { t } = useTranslation();
  const { isLogin, userInfo } = useAuth();
  const popupRef = useRef();
  const rootButtonRef = useRef();
  const { showPopup, hide } = popupRef.current || popupRefValueDefault;
  const [isPopupShown, setIsPopupShown] = useState(false);
  const isLastPointerMouse = useWhatInputValueIsLastPointerMouse();

  // Hide menu popup when user became authorized.
  useEffect(() => {
    if (isLogin) {
      hide();
    }
    // eslint-disable-next-line
  }, [isLogin]); // intentionally missing dependency.

  const showHeaderMenuPopup = useCallback(() => {
    const targetRect = getBoundingClientRect(rootButtonRef.current);
    showPopup(targetRect, <PopupMenu hideMenuPopup={hide} />, {
      withHoverZone: true,
      fitPosition: true,
      hideOnOutsideClick: isLastPointerMouse,
      hideOnBlur: false,
      hideOnClick: false,
      popupId: POPUP_ID,
      targetElement: rootButtonRef.current,
      onShow: () => {
        setIsPopupShown(true);
      },
      onHide: () => {
        setIsPopupShown(false);
      },
    });
  }, [isLastPointerMouse, showPopup, hide]);

  const togglePopup = useCallback(() => {
    if (isPopupShown) {
      hide();
    } else {
      showHeaderMenuPopup();
    }
  }, [isPopupShown, hide, showHeaderMenuPopup]);

  return (
    <div>
      <div
        className={classNames("login-header-menu with-chevron", {
          open: isPopupShown,
        })}
        onKeyDown={togglePopup}
        onClick={togglePopup}
        role="button">
        <ProfileButton
          ref={rootButtonRef}
          userInfo={userInfo}
          isLogin={isLogin}
          title={t(isLogin ? "profile" : "login")}
          active={isPopupShown}
        />
      </div>
      <PopupWrap ref={popupRef} />
    </div>
  );
};

export default LoginHeaderMenu;

const ProfileButton = forwardRef(({ userInfo, isLogin, title, active }, ref) => {
  const { t } = useTranslation();
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const { textMode } = useViewMode();

  useEffect(() => {
    setShowPlaceholder(false);
  }, [userInfo]);

  if (textMode) {
    return (
      <div ref={ref}>
        <IconButton bordered active={active} ariaLabel={title} title={title} />
      </div>
    );
  }
  return (
    <div ref={ref} className="headerProfileContainer">
      {isLogin ? (
        userInfo?.email && !showPlaceholder ? (
          <img
            className="headerLoginImage"
            alt="avatar image"
            onError={() => {
              setShowPlaceholder(true);
            }}
            src={getGravatarImage(userInfo.email)}
          />
        ) : (
          <ProfileIcon className="headerLoginImage" />
        )
      ) : (
        <span className={"headerLoginText"}>
          {t("logIn")}
          <hr />
          {t("register")}
        </span>
      )}
    </div>
  );
});

ProfileButton.propTypes = {
  isLogin: PropTypes.bool,
  active: PropTypes.bool,
  title: PropTypes.string,
  userInfo: PropTypes.object,
};

const PopupMenu = ({ hideMenuPopup }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const btnLanguagesRef = useRef();
  const { isLogin, userInfo, showLoginDialog, showLogoutDialog } = useAuth();
  const uiLanguagesTranslated = useUiLanguagesTranslated();
  const { isShownLangPopup, openPopupUiLangs, currentLang } = usePopupUiLangs();
  const ellen4AllToken = useSelector((state) => state.translate.ellen4AllToken);
  const handleEllen4AllLogin = useDialogHelpTranslate();

  const { isDevMode } = useDevMode();

  const currentLangTitle = useMemo(() => {
    const lang = uiLanguagesTranslated.find((item) => item.id === currentLang);
    return lang ? lang.label : "";
  }, [uiLanguagesTranslated, currentLang]);

  const handleAuthorizationClick = useCallback(() => {
    if (isLogin) {
      showLogoutDialog();
    } else {
      makeOAuthRequest();
    }
  }, [isLogin, showLogoutDialog]);

  const handleSubscriptionClick = useCallback(() => {
    showLoginDialog(() => {
      history.push(URLS.subscription);
    });
  }, [showLoginDialog, history]);

  const handleBackupsClick = useCallback(() => {
    showLoginDialog(() => {
      openNewTab(getBackupsUrl());
    });
  }, [showLoginDialog]);

  const handleClickRoot = useCallback(
    (e) => {
      const btnLanguages = btnLanguagesRef.current;

      // Prevent close menu popup on languages btn click.
      if (btnLanguages && (e.target === btnLanguages || getEventPath(e).includes(btnLanguages))) {
        return;
      }

      hideMenuPopup();
    },
    [hideMenuPopup],
  );

  return (
    <div
      onClick={handleClickRoot}
      className="headerPopupMenu"
      tabIndex={0}
      aria-expanded="true"
      aria-live="assertive">
      {isLogin && (
        <a
          href={getControlPanelProfile()}
          className="dropDownText s-header-menu_btn-user-info"
          target="_blank"
          rel="noopener noreferrer">
          {userInfo?.name ? userInfo.name : t("myProfile")}
        </a>
      )}
      <button
        ref={btnLanguagesRef}
        className={classNames("header-menu_btn-languages dropDownText with-chevron", {
          open: isShownLangPopup,
        })}
        onClick={openPopupUiLangs}>
        <img src={makeLangImage(currentLang, true)} alt={currentLang} />
        &nbsp;
        {currentLangTitle}
      </button>
      <Link tabIndex={0} to={URLS.system} className="dropDownText">
        {t("clearMyHistory")}
      </Link>
      <button tabIndex={0} onClick={handleBackupsClick} className="dropDownText">
        {t("backups")}
      </button>
      {isLogin && (
        <Link tabIndex={0} to={`${URLS.settings}/mylibrary`} replace className="dropDownText">
          {t("myLanguages")}
        </Link>
      )}
      <button tabIndex={0} onClick={handleSubscriptionClick} className="dropDownText">
        {t("subscriptions")}
      </button>
      <a
        tabIndex={0}
        href={EXTERNAL_URLS.donate}
        className="dropDownText"
        target="_blank"
        rel="noopener noreferrer">
        {t("support")}
      </a>
      <button
        tabIndex={0}
        className="dropDownText s-header-menu_btn-login-logout"
        onClick={handleAuthorizationClick}>
        {t(isLogin ? "logout" : "login")}
      </button>
      {isDevMode && (
        <button
          tabIndex={0}
          className="dropDownText s-header-menu_btn-login-logout"
          onClick={() => {
            if (ellen4AllToken) {
              dispatch(actionSetEllen4AllToken());
            } else {
              handleEllen4AllLogin();
            }
          }}>
          {"Ellen4All " + t(ellen4AllToken ? "logout" : "login")}
        </button>
      )}
    </div>
  );
};

PopupMenu.propTypes = {
  hideMenuPopup: PropTypes.func,
};
