import React from "react";
import { URLS } from "../utils/url";

const PrivacyPolicyPage = ({ Link }) => (
  <div className="long-content">
    <p>
      <strong>EGW Writings Applications</strong> are owned and operated by the Ellen G. White
      Estate, Inc. All the information contained within the Websites and Applications (apps)
      are&nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://ellenwhite.org/legal-copyright-notice">
        copyrighted
      </a>
      &nbsp; by the Ellen G. White® Estate, a nonprofit corporation (“the White Estate,
      Inc.”).&nbsp; All rights are reserved. The White Estate respects the privacy of every
      individual that uses our services. The following statement is for both your&nbsp;and our
      protection.
    </p>
    <p>
      <strong>What Personal Information We Collect and Why</strong>
      <br />
      The White Estate uses several Third-party services to provide an enhanced user experience for
      all our Websites and Apps.
    </p>
    <p>
      <strong>CDN:</strong>&nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.cloudflare.com/security-policy/">
        Cloudflare
      </a>
      &nbsp; tracking cookies are&nbsp;used to deliver your content requests from&nbsp;EGW Writings
      Websites to you&nbsp;via the closest geographically located EGW Writings cloud server. This
      also enables us to deliver your content&nbsp;requests using&nbsp;the fastest route possible to
      your location over Cloudflares&nbsp;global edge networks. These session cookies are not
      permanently stored.
    </p>
    <p>
      <strong>Websites</strong>:&nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.google.com/analytics/answer/6004245?hl=en">
        Google Analytics
      </a>
      &nbsp; is used for all our Websites to help us build&nbsp;better websites for our users.
    </p>   
    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/legal/FB_Work_Privacy">
        Facebook ID
      </a>
      &nbsp;is for users who have subscribed to EGW Writings subscription service and requested to
      receive notification of their daily EGW subscriptions their Facebook account.
    </p>
    <p>
      <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/en/privacy">
        Twitter
      </a>
      &nbsp;is for users who have subscribed to EGW Writings subscription service and requested to
      receive notification of their daily EGW subscriptions via their Twitter feed.
    </p>
    <p>
      <a target="_blank" rel="noopener noreferrer" href="https://www.smtp2go.com/privacy/">
        SMTP2Go
      </a>
      &nbsp; is&nbsp;for users who have subscribed to EGW Writings subscription service and
      requested to receive email delivery of their daily EGW subscriptions.
    </p>    
    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
        PayPal
      </a>
      &nbsp; is a credit card payment gateway that is used for donor contributions to our EGW
      Writings apps and Websites. We do not have access to your financial information at anytime
      during the transaction since this is handled completly by PayPal. We are given by PayPal a log
      of your transaction ID, time stamp, donation amount, name and email address.
    </p>
    <p>
      <a target="_blank" rel="noopener noreferrer" href="https://sentry.io/privacy/">
        Sentry
      </a>
      &nbsp; provides self-hosted and cloud-based error monitoring that helps all software teams
      discover, triage, and prioritize errors in real-time.
    </p>
    <p>
      <a target="_blank" rel="noopener noreferrer" href="https://firebase.google.com/support/privacy">
        &nbsp;Firebase
      </a>
      &nbsp; gives you functionality like analytics, databases, messaging and crash reporting so you
      can move quickly and focus on your users.
    </p>
    <p>
      <strong>Disclosure: </strong>The White Estate does not sell or distribute any information,
      including personal or identifying information, which is obtained through your use of our
      websites or applications.&nbsp; Nor is the White Estate gathering such information to share
      with non-affiliated organizations.
    </p>
    <p>
      <strong>Your location</strong>
      <br />
      When you use the EGW Writings Website we log your IP, OS, browser version, and language. This
      stores a browser&nbsp;cookie to help with your experience when using EGW Writiings
      Websites.&nbsp; Setting your language, screen resolution for layout on your device, login
      information, and preferences while your&apos;re using EGW Writings Websites. This information
      is not shared with any third parties and it is stored for only one week before it is
      automatically deleted.
    </p>
    <p>
      <strong>Content delivered retention policy</strong>
      <br />
      The content that you search and read on our websites is held in memcache on our servers for
      two days to speed up your access to the EGW Writings. We do not permanently store or share any
      of this information. Cloudflare also caches this content on their servers for 24 hours to
      speed up delivery of EGW Writings content to users.
    </p>
    <p>
      <strong>User communications policy</strong>
      <br />
      Personal information or requests that you submit via email to&nbsp;
      <a target="_blank" rel="noopener noreferrer" href="mailto:android@ellenwhite.org">
        android@ellenwhite.org
      </a>
      ,{" "}
      <a target="_blank" rel="noopener noreferrer" href="mailto:egwwritings@ellenwhite.org">
        egwwritings@ellenwhite.org
      </a>
      ,{" "}
      <a target="_blank" rel="noopener noreferrer" href="mailto:ios@ellenwhite.org">
        ios@ellenwhite.org
      </a>
      ,{" "}
      <a target="_blank" rel="noopener noreferrer" href="mailto:macos@elllenwhite.org">
        macos@elllenwhite.org
      </a>
      ,{" "}
      <a target="_blank" rel="noopener noreferrer" href="mailto:windows@ellenwhite.org">
        windows@ellenwhite.org
      </a>
      ,{" "}
      <a target="_blank" rel="noopener noreferrer" href="mailto:egwhelp@whiteestate.org">
        egwhelp@whiteestate.org
      </a>
      ,{" "}
      <a target="_blank" rel="noopener noreferrer" href="mailto:estate@ellenwhite.org">
        estate@ellenwhite.org
      </a>
      ,{" "}
      <a target="_blank" rel="noopener noreferrer" href="mailto:visit@ellenwhite.org">
        visit@ellenwhite.org
      </a>
      , and&nbsp;
      <a target="_blank" rel="noopener noreferrer" href="mailto:mail@whiteestate.org">
        mail@whiteestate.org
      </a>
      , will be sent to a White Estate employee to answer. The White Estate employee assigned to
      answer questions&nbsp;from any of our email addresses will have access to the information you
      wrote in your email. When you send an email or other communication to the Ellen G. White
      Estate we retain those communications to process your inquiries, respond to your requests and
      improve our services. We may add your email to ellenwhite.org or our egwwritings.org support
      knowledgebase to help other users with similar questions or problems. Your name or email
      address will not be displayed.
    </p>
    <p>
      <strong>How do we protect your information?</strong>
      <br />
      Ellen G. White Estate does not share your personal information with other organization outside
      of the Ellen G. White Estate without your explicit consent. The White Estate may share your
      personal information only if it has a good faith belief that access, use, preservation or
      disclosure of such information is reasonably necessary to (a) satisfy any applicable law,
      regulation, legal process or enforceable governmental request, (b) enforce applicable Terms Of
      Use, including investigation of potential violations thereof, (c) detect, prevent, or
      otherwise address fraud, security or technical issues, or (d) protect against imminent harm to
      the rights, property or safety of the Ellen G. White Estate, its users or the public as
      required or permitted by law. The White Estate takes appropriate security measures to protect
      against unauthorized access to or unauthorized alteration, disclosure or destruction of data.
      Including monitoring of our servers, storage and processing practices and security measures,
      as well as physical security measures to guard against unauthorized access to our systems
      where we store your personal data. Unless explicit permission is obtained from you, the White
      Estate restricts access to personal information by its employees and contractors who need to
      know that information to operate, develop or improve our services. These individuals are bound
      by confidentiality agreements and may be subject to discipline, including termination and
      criminal prosecution, if they fail to meet these obligations.
    </p>
    <p>
      <strong>How to Delete My Personal Account and Information</strong>
      <br />
      Your right to privacy is our top concern. If you desire to suspend using our EGW Writings
      services at any time and wish to have all your personal data deleted from our servers, please
      visit our customer panel&nbsp;
      <a href="https://cpanel.egwwritings.org">cpanel.egwwritings.org</a> to initiate the delete my
      account process.
    </p>
    <p>
      <strong>Enforcement</strong>
      <br />
      The White Estate periodically reviews its compliance with this Privacy Policy. When we receive
      formal written complaints regarding privacy issues, it is our policy to contact the
      complaining user regarding his or her concerns. We will cooperate with the appropriate
      regulatory authorities, including local data protection authorities, to resolve any complaints
      regarding the transfer of personal data that cannot be resolved between Ellen G. White Estate,
      Inc., and an individual.
    </p>
    <p>
      <strong>Questions</strong>
      <br />
      Any questions about the privacy policy can be directed to:&nbsp;
      <a target="_blank" rel="noopener noreferrer" href="mailto:egwwritings@ellenwhite.org">
        egwwritings@ellenwhite.org
      </a>
    </p>
    <p>
      <strong>End User License Agreement</strong>&nbsp;(EULA)
      <br />
      To read about your rights and correct usage of this software, please read the&nbsp;
      <Link to={URLS.userAgreement}>EULA.</Link>
    </p>
    <p>
      <strong>Copyright Notice</strong>
      <br />
      See Ellen G. White Estate&nbsp;<Link to={URLS.copirightNotice}>Copyright Notice</Link>
      &nbsp;for EGW Writings Apps and Ellen White Estate Websites
    </p>
  </div>
);

export default PrivacyPolicyPage;
