import React from "react";
import { useParams } from "react-router-dom";

import { main } from "../../utils/RouterUtils";
import { menuItems } from "../../utils/MenuItems";
import { useNavigationData } from "../NavigationContext";
import AddSubscription from "./AddSubscription";

import "./SubscriptionsDialog.scss";
import "./Subscriptions.scss";
import { URLS } from "../../shared/utils/url";

const AddSubscriptionPage = ({ location }) => {
  let { id, type } = useParams();

  if (type) {
    type = type.toUpperCase();
  }
  const backStack = location?.state?.backStack;

  const nodeList = backStack ? [...backStack] : [main, menuItems.subscription];

  nodeList.push({ id: URLS.subscriptionAdd, label: "subscriptions_add" });

  useNavigationData({ nodeList });

  return <AddSubscription bookId={id} type={type} />;
};

export default AddSubscriptionPage;
