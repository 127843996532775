import PropTypes from "prop-types";
import React from "react";
import { Category } from "../components/index";
import { ScrollerView } from "../components/views";
import { makeNavigationUrl} from "../utils/URLUtils";
import { getMenuItemsAsList } from "../utils/MenuItems";
import { useNavigationContext } from "../components/NavigationContext";
import { useAuth, useDevMode, useViewMode } from "../hooks";
import { MENU_ITEMS_LOCATIONS } from "../shared/utils/menu";
import { useSelector } from "react-redux";

import "./HomePage.scss";
import { URLS } from "../shared/utils/url";

const HomePage = ({ history }) => {
  const { openId } = useNavigationContext();
  const { isLogin, showLoginDialog, isUserEditor } = useAuth();
  const { isMobile } = useViewMode();
  const { isDevMode } = useDevMode();
  const homeFolders = getMenuItemsAsList(MENU_ITEMS_LOCATIONS.HOME, false, isDevMode, isUserEditor);
  const settings = useSelector((state) => state.settings);

  const WrapperComponent = isMobile ? "div" : ScrollerView;

  const rowItemStyle = isMobile ? { height: 100 / homeFolders.length + "%" } : null;

  return (
    <WrapperComponent className="home-page__folder-list folder-list">
      {homeFolders.map((item) => {
        if (item.checkVisibility) {
          const isVisible = item.checkVisibility(settings);
          if (!isVisible) {
            return null;
          }
        }
        return (
          <Category
            key={item.id}
            item={item}
            isLogin={isLogin}
            style={rowItemStyle}
            home
            onClick={(e) => {
              if (item.onlyAuth && !isLogin) {
                showLoginDialog(() => {
                  history.push(makeNavigationUrl(item, { isAudio: item.isAudio }));
                });
                e.preventDefault();
              } else {
                if (item.id === URLS.myLibrary || item.id === URLS.allCollection) {
                  openId(item.id);
                  e.preventDefault();
                }
              }
            }}
            route={makeNavigationUrl(item, item.isAudio)}
          />
        );
      })}
    </WrapperComponent>
  );
};

HomePage.propTypes = {
  history: PropTypes.object,
  height: PropTypes.number,
};

export default HomePage;
