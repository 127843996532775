import React, { useEffect, useRef, forwardRef, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Checkbox, Scroll, SearchField } from "../views";
import PopupWrap from "../popup/PopupWrap";
import { focusElementWithinPopup } from "../../utils/AccessibilityUtils";
import { useViewMode } from "../../hooks";
import { makeLangImage } from "../../utils/URLUtils";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { flagPlaceholder } from "../../shared/utils/url";

import "./LanguageSelector.scss";

const LanguageSelectorView = forwardRef(({
  title, defaultHeight, isHideCheckbox, isDisabled,
  renderSlotRightSide, onClickItem, onContextMenu,
  items, itemsHighlighted, itemsDisabled, itemsChecked, itemsDefault,
}, ref) => {
  const timerRef = useRef(0);
  const scrollerRef = useRef(null);
  const { zoom } = useViewMode();
  const [searchValue, setSearchValue] = useState("");
  const [height, setHeight] = useState(window.innerHeight);

  const itemsFiltered = useMemo(() => {
    if (!searchValue) {
      return items;
    }

    const searchValueLowerCase = searchValue.toLowerCase();
    return items.filter((language) => {
      // "?" before {label} can prevent a crash. Better not remove it.
      return language.label?.toLowerCase().includes(searchValueLowerCase);
    });
  }, [items, searchValue]);

  const handleKeyUp = useCallback((event, item, flags) => {
    if (isEventKey(event, KeyCodes.enter)) {
      onClickItem(event, item, flags);
    }
  }, [onClickItem]);

  useEffect(() => {
    if (scrollerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        const { view } = scrollerRef.current;
        focusElementWithinPopup(view);
      }, 150);
    }

    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    const onWindowResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", onWindowResize);

    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  const scrollHeight = height > defaultHeight ? defaultHeight * zoom / 100 : height - 100;

  const renderItem = useCallback((item) => {
    const itemId = item.id;

    const imageUrl = makeLangImage(itemId, true);
    const isChecked = itemsChecked.includes(itemId);
    const isDefault = itemsDefault?.includes(itemId);
    const isDisabled = itemsDisabled?.includes(itemId);
    const isHighlighted = itemsHighlighted?.includes(itemId);

    const handleChange = (e) => onClickItem(e, item, {
      isDisabled,
      isChecked,
      isDefault,
      isHighlighted,
    });

    return (
      <div
        key={item.id}
        tabIndex={0}
        className={classNames("lang-sel_item", {
          "is-default": isDefault,
          "is-checked": isChecked,
          "is-highlighted": isHighlighted,
          "is-disabled": isDisabled,
        })}
        onClick={handleChange}
        onContextMenu={(e) => onContextMenu(e, item, {
          isDisabled,
          isChecked,
          isDefault,
          isHighlighted,
        })}
        onKeyUp={(e) => handleKeyUp(e, item, {
          isDisabled,
          isChecked,
          isDefault,
          isHighlighted,
        })}
      >
        {
          !isHideCheckbox && (
            <Checkbox
              iconClassName="lang-sel-check-icon"
              disabled={isDisabled}
              checked={isChecked}
              partCheck={isDefault}
              onChange={handleChange}
            />
          )
        }
        <img
          alt={item.code}
          role="presentation"
          className="lang-sel-img"
          src={imageUrl}
          onError={(e) => {
            if (e.target.src.indexOf("flags") > -1) {
              e.target.src = flagPlaceholder;
            }
          }}
        />
        <span
          className="lang-sel-text">{item.label}</span>
        {renderSlotRightSide && <span className="lang-sel-count">{renderSlotRightSide(item)}</span>}
      </div>
    );
  }, [
    isHideCheckbox,
    itemsDisabled, itemsChecked, itemsHighlighted, itemsDefault,
    handleKeyUp, onClickItem, onContextMenu, renderSlotRightSide,
  ]);

  return (
    <PopupWrap ref={ref}>
      <div className={classNames("lang-sel-container", {
        "is-disabled": isDisabled,
      })}>
        {title && <span className="lang-sel-title">{title}</span>}
        <SearchField
          isWithDebounce
          className="lang-sel-search"
          value={searchValue}
          isIconSearchEnabled={false}
          isIconClearVisible={!!searchValue}
          isIconSearchVisible={!searchValue}
          onChange={setSearchValue}
          onClear={() => setSearchValue("")}
        />
        <Scroll
          className="lang-sel_scroll"
          ref={scrollerRef}
          noHidePopups
          style={{ height: scrollHeight, marginTop: "0.5rem" }}
        >
          {itemsFiltered.map(renderItem)}
        </Scroll>
      </div>
    </PopupWrap>
  );
});

LanguageSelectorView.defaultProps = {
  defaultHeight: 400,
};

LanguageSelectorView.propTypes = {
  isDisabled: PropTypes.bool,
  isHideCheckbox: PropTypes.bool,
  title: PropTypes.string,
  defaultHeight: PropTypes.number,
  items: PropTypes.array.isRequired,
  itemsChecked: PropTypes.array.isRequired,
  itemsDisabled: PropTypes.array,
  itemsDefault: PropTypes.array,
  itemsHighlighted: PropTypes.array,
  onClickItem: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func,
  renderSlotRightSide: PropTypes.func,
};

export default LanguageSelectorView;
