import React from "react";
import { useTranslation } from "react-i18next";
import { makeOAuthRequest } from "src/shared/api/baseApi";
import { SignInIcon } from "../assets/CommonIcons";
import { Button, Scroll } from "../components/views"; 

import "./PleaseSignInPage.scss";

const PleaseSignInPage = () => {
  const { t } = useTranslation();

  return (
    <Scroll autoHeight autoHeightMax="80vh">
      <div className="please-sign-in-page">
        <div className="please-sign-in-page-header">
          <SignInIcon className="sign-in-icon" />
        </div>
        <div className="please-sign-in-page-content">
          <p className="__text">{t("pleaseSignInText")}</p>

          <Button onClick={() => {
            makeOAuthRequest();
          }} >{t("signIn")}</Button>
        </div>
      </div>
    </Scroll>
  );
};

export default PleaseSignInPage;
