import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserManager, WebStorageStateStore } from "oidc-client";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { useDialogContext } from "../../dialog/DialogContext";
import DialogButtons from "../../dialog/DialogButtons";
import { useNavigationContext } from "../../NavigationContext";
import { makeChapterUrl, makeLinkValue, PanelTypes } from "../../../utils/URLUtils";

import { getBaseHost } from "src/shared/utils/url";
import { actionSetEllen4AllToken } from "src/redux/actions";
import { isDevelopment } from "../../../shared/utils/assembly";


import "../../reader/BibliographyDialog.scss";

const HelpTranslateDialog = ({ onSelect, message }) => {
  const { hideDialog } = useDialogContext();
  const dispatch = useDispatch();
  const ellen4AllToken = useSelector((state) => state.translate.ellen4AllToken);

  const handleClose = useCallback(() => {
    hideDialog();
  }, [hideDialog]);

  const joinIn = useCallback(() => {
    if (ellen4AllToken) {
      onSelect();
      handleClose();
      return;
    }
    const userStore = new WebStorageStateStore({ store: window.localStorage });
    const oidcClient = new UserManager({
      userStore,
      authority: "https://login.authentickated.com",
      client_id: "ea260c9e36f2848c3bbda357bea502ffa821d342",
      redirect_uri: `${getBaseHost()}/api/oidc/auth/cb`,
      response_type: "id_token token",
      scope: "openid offline_access profile email phone address social",
      silent_redirect_uri: `${getBaseHost()}/api/oidc/auth/cb`,
      accessTokenExpiringNotificationTime: 10,
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: true,
      popupWindowTarget: "window.open",
      startSilentRenew: true,
      popupWindowFeatures: "location=no,toolbar=no,width=500,height=800,left=100,top=100",
    });

    oidcClient.getUser().then((user) => {
      if (!user || user.expired) {
        dispatch(actionSetEllen4AllToken());

        oidcClient.signinPopup().then((user) => {
          dispatch(actionSetEllen4AllToken(user.access_token));
          onSelect();
          handleClose();
        });
      } else {
        onSelect();
        handleClose();
      }
    });
  }, [handleClose, onSelect, ellen4AllToken]);

  useEffect(() => {
    const handleToken = (message) => {
      if (message?.data?.type === "oidc-message") {
        const urlParts = message.data.url.split("#");
        if (urlParts[1]) {
          const paramParts = urlParts[1].split("&");
          const accessToken = paramParts.find((item) => item.indexOf("access_token=") === 0);
          if (accessToken) {
            dispatch(actionSetEllen4AllToken(accessToken.split("=")[1]));
            onSelect();
            handleClose();
          }
        }
      }
    };

    if (isDevelopment()) {
      window.addEventListener("message", handleToken);
    }

    return () => {
      window.removeEventListener("message", handleToken);
    };
  }, []);

  return (
    <div className="bibliography-container">
      <div data-focus={true} tabIndex={1} className="dialog-message">
        {message}
      </div>
      <DialogButtons
        onClickNegative={handleClose}
        onClickPositive={joinIn}
        negativeText={"Close"}
        positiveText={"Join in"}
      />
    </div>
  );
};

HelpTranslateDialog.propTypes = {
  message: PropTypes.string,
  onSelect: PropTypes.func,
};

const useDialogHelpTranslate = () => {
  const { t } = useTranslation();
  const { showDialog } = useDialogContext();
  const history = useHistory();
  const { makeUpdateReaderPanels, panelIds } = useNavigationContext(); 
  const ellen4AllToken = useSelector((state) => state.translate.ellen4AllToken);

  const makeNav = useCallback(
    (paraId) => {
      if (paraId) {
        if (panelIds.length > 0) {
          makeUpdateReaderPanels(PanelTypes.TRANSLATE.id, paraId);
        } else {          
          const newPanels = [
            makeLinkValue(PanelTypes.PARA.id, paraId),
            makeLinkValue(PanelTypes.TRANSLATE.id, paraId),
          ];
          history.push(makeChapterUrl(newPanels));
        }
      }
    },
    [panelIds, makeUpdateReaderPanels],
  );

  return useCallback(
    (paraId) => {
      if (ellen4AllToken) {
        makeNav(paraId);
      } else {
        showDialog(
          <HelpTranslateDialog
            message={t("translate:help_us")}
            onSelect={() => {
              makeNav(paraId);
            }}
          />,
          {
            title: t("translate:help_translate"),
            noTranslation: true,
          },
        );
      }
    },
    [t, showDialog, makeNav, ellen4AllToken],
  );
};

export default useDialogHelpTranslate;
