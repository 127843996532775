import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { withResizeDetector } from "react-resize-detector/build/withPolyfill";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { decode } from "html-entities";

import { makeReadUrl } from "../subscriptions/SubscriptionsUtils";
import BreadCrumbMenu from "./BreadCrumbMenu";
import { useNavigationContext } from "../NavigationContext";
import { calculateTextDimension, whichEventKey } from "../../utils/Utils";
import { upDownNavigation } from "../../utils/AccessibilityUtils";
import { useAuth } from "../../hooks";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { CONTENT_CLASSES, removeBR } from "../../shared/utils/content";
import { useResizerContext } from "../resizer/Resizer";
import { isSystemFolder } from "../studyCenter/StudyCenterUtils";

import "./BreadCrumbs.scss";
import { URLS } from "../../shared/utils/url";

const BreadCrumbs = ({ width }) => {
  const { t } = useTranslation();
  const { isLogin, showLoginDialog } = useAuth();
  const { openId, nodeList, isAudio, realType, isLibrary } = useNavigationContext(); 
  const history = useHistory();
  const resizeContext = useResizerContext();
  const { mainWidthInner } = resizeContext;

  const [showId, setShowId] = useState();
  const [showMainID, setShowMainID] = useState();
  const keyDownTimerIDRef = useRef(0);
  const maxBCWidth = Math.min(width, mainWidthInner);

  const folders = useMemo(() => {
    // Aproximate calculation of size of all breadcrumbs
    const folderList = [];
    let accWidth = 0;
    let itemSize;
    for (let i = nodeList.length - 1; i >= 0; i--) {
      itemSize = calculateTextDimension(nodeList[i].label + " > ", ["expandItem"]);
      accWidth += itemSize.width;
      folderList.push({
        isLine: nodeList.length - 1 === i || accWidth < maxBCWidth,
        index: i,
        ...nodeList[i],
      });
    }
    folderList.reverse();

    return folderList;
  }, [nodeList, maxBCWidth]);

  useEffect(() => {
    if (showMainID) {
      setTimeout(() => {
        const breadcrumbItems = document.querySelectorAll(".bc-popup-modal .breadcrumb-item");
        if (breadcrumbItems) {
          breadcrumbItems[0].focus();
          upDownNavigation(breadcrumbItems);
        }
      }, 0);
    }
  }, [showMainID]);

  useEffect(() => {
    const resetBreadCrumbs = () => {
      setTimeout(() => {
        const breadcrumbItem = document.querySelector(
          `.breadcrumb-item[data-brc-path="${showId || showMainID}"]`,
        );
        if (breadcrumbItem) {
          breadcrumbItem.focus();
        }
      });

      if (showId) {
        setShowId();
      } else if (showMainID) {
        setShowMainID();
      }
    };

    const documentKeyDownHandle = (event) => {
      if (keyDownTimerIDRef.current) {
        clearTimeout(keyDownTimerIDRef.current);
      }

      keyDownTimerIDRef.current = setTimeout(() => {
        if (isEventKey(event, KeyCodes.esc)) {
          resetBreadCrumbs(event);
        }
      }, 0);
    };

    document.addEventListener("keydown", documentKeyDownHandle);

    return () => {
      document.removeEventListener("keydown", documentKeyDownHandle);
      if (keyDownTimerIDRef.current) {
        clearTimeout(keyDownTimerIDRef.current);
      }
    };
  }, [showId, showMainID]);

  const onKeyDownHandle = useCallback(
    (event, item) => {
      const { downArrow, rightArrow, enter } = whichEventKey(event);

      if (showMainID) {
        event.preventDefault();
      }

      if (downArrow && !showMainID) {
        event.preventDefault();
        setShowId(item.id);
      } else if (rightArrow && showMainID) {
        setShowId(item.id);
      } else if (enter) {
        handleClickItem(item);
      }
    },
    [showMainID, isLogin],
  );

  const handleOpen = useCallback(
    (item) => {
      if (item.url) {
        window.open(item.url, "_blank");
      } else if (item.className === "subscription") {
        history.push(makeReadUrl(item.date, item.book_id));
      } else if (item.type === CONTENT_CLASSES.TITLES_CATEGORY) {
        history.push([URLS.titles, item.categoryId].join("/"));
      } else if (item.type === CONTENT_CLASSES.TITLES_BOOK) {
        history.push([URLS.titles, item.categoryId, item.id].join("/"));
      } else if (item.type === CONTENT_CLASSES.CATEGORIES_CATEGORY) {
        history.push([URLS.categories, item.categoryId].join("/"));
      } else if (item.type === CONTENT_CLASSES.CATEGORIES_BOOK) {
        history.push([URLS.categories, item.categoryId, item.id].join("/"));
      } else {
        openId(item.id, { ...item, newWindow: true, fromTop: true, isAudio, isLibrary });
      }
    },
    [isAudio, isLibrary, openId],
  );

  const handleClickItem = useCallback(
    (item) => {
      const mustShowLoginDialog = !isLogin && item.onlyAuth;
      if (mustShowLoginDialog) {
        showLoginDialog(() => {
          handleOpen(item);
        });
      } else {
        handleOpen(item);
      }
    },
    [handleOpen, isLogin, showLoginDialog],
  );

  const onMainKeyDownHandle = (event, id) => {
    if (isEventKey(event, KeyCodes.downArrow)) {
      event.preventDefault();
      setShowMainID(id);
    }
  };

  const renderBcItem = useCallback(
    (item, isLast, parent) => {
      const { id, label, title, isLine } = item;

      // EGWW-1851 prevent show titles of bible in content
      const menuItems = parent?.children?.filter((subItem) => {
        return !(
          subItem.className === "para" &&
          (subItem.element_type === "h1" || subItem.element_type === "h2")
        );
      });
      let finalLabel = isSystemFolder(id)
        ? t(title, { defaultValue: title })
        : decode(removeBR(t(label, { defaultValue: label })));

      return (
        <div
          key={id}
          className={classNames("bc-item-wrap with-chevron", {
            [`expandItemActive ${realType}`]: isLast,
            "bc-title-vertical": !isLine,
            collapsable: menuItems,
            open: isLine && showId == id,
          })}
          onMouseOver={() => setShowId(id)}
          onMouseLeave={() => setShowId()}>
          <span
            className={classNames("breadcrumb-item")}
            data-brc-path={id}
            onKeyDown={(event) => onKeyDownHandle(event, item)}
            tabIndex={0}
            onClick={() => handleClickItem(item)}>
            {finalLabel}
            {menuItems && (
              <BreadCrumbMenu
                menuData={item}
                menuItems={menuItems}
                vertical={!isLine}
                realType={realType}
                onClickHead={handleClickItem}
                show={showId == item.id}
                current={id}
                onItemClick={handleClickItem}
              />
            )}
          </span>
        </div>
      );
    },
    [handleClickItem, onKeyDownHandle, realType, showId, t],
  );

  const allCrumbs = useMemo(() => {
    let inMenuItems = folders.filter((item) => !item.isLine);
    let inLineItems = folders.filter((item) => item.isLine);

    let items = [];
    let item, parent;
    if (inMenuItems.length) {
      const first = folders[0];
      parent = first;
      let menuItems = [];

      for (let i = 1; i < inMenuItems.length; i++) {
        item = inMenuItems[i];
        menuItems.push(renderBcItem(item, false, parent));
        parent = item;
      }
      items.push(
        <div key={0} className={classNames("bc-popup-wrap", { active: showMainID })}>
          <div
            className={classNames("bc-item-wrap with-chevron collapsable", {
              collapsed: showMainID,
              open: showMainID,
            })}>
            <span
              className="breadcrumb-item"
              data-brc-path={first.id}
              onKeyDown={(event) => onMainKeyDownHandle(event, first.id)}
              tabIndex={0}
              onClick={() => handleClickItem(first)}>
              {t(first.label, { defaultValue: first.label })}
            </span>
          </div>
          {menuItems.length > 0 && (
            <React.Fragment>
              ...&nbsp;
              <div className="bc-popup-modal">{menuItems}</div>
            </React.Fragment>
          )}
        </div>,
      );
    }
    inLineItems.forEach((item, index) => {
      items.push(renderBcItem(item, index === inLineItems.length - 1, parent));
      parent = item;
    });

    return items;
  }, [folders, handleClickItem, renderBcItem, showMainID, t]);

  return <div className="bc-wrap">{allCrumbs}</div>;
};

BreadCrumbs.propTypes = {
  width: PropTypes.number,
};

export default withResizeDetector(BreadCrumbs);
