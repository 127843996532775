import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as classnames from "classnames";

import { convertToTime, getIsIOSDevice } from "../../../utils/Utils";
import { TimerIcon, TimerDisabledIcon, } from "../../../assets/PlayerIcons";
import { useViewMode } from "../../../hooks";
import { EGWSlider } from "../../views";
import IconButton from "../../views/IconButton";
import WaveformProgress from "../waveformProgress/WaveformProgress";
import TimerFlag from "../timerFlag/timerFlag";
import { MinusIcon, PlusIcon, } from "../../../assets/CommonIcons";
import { clutch } from "../../../utils/NumberUtils";
import EgwWebFont from "../../../assets/EgwWebFont";
import { handleReactSlot } from "../../../shared/utils/dom";

import "./PlayerUIKit.scss";

export const IconHoverSlider = ({
  className,
  min,
  max,
  value,
  step,
  disabled,
  slideDirectionLeft = false,
  onIconClick,
  onSliderChange,
  renderIcon,
  renderSliderLabelRight,
  ...restProps
}) => {
  const { isMobile } = useViewMode();
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoverTimeout, setTimerHoverTimeout] = useState(null);
  const isHoverBehaviourAllowed = !isMobile;

  useEffect(() => {
    if (!isHoverBehaviourAllowed) {
      if (!isExpanded) {
        setIsExpanded(true);
      }
    }
  }, [isExpanded, isHoverBehaviourAllowed]);

  const startHoverTimeout = () => {
    setTimerHoverTimeout(setTimeout(() => handleHover(false), 1000));
  };

  useEffect(() => {
    return () => {
      if (typeof hoverTimeout === "number") {
        clearTimeout(hoverTimeout);
      }
    };
  }, [hoverTimeout]);

  const handleHover = (value) => {
    setIsExpanded(value);
    if (typeof hoverTimeout === "number") {
      clearInterval(hoverTimeout);
    }
  };

  const handleIconClick = () => {
    if (!disabled) {
      setIsExpanded(!isExpanded);
      onIconClick();
    }
  };

  return (
    <div
      className={classnames("icon-hover-slider", className, {
        "is-expanded": isExpanded,
        "is-disabled": disabled,
        "has-slide-direction-left": slideDirectionLeft,
      })}
      onMouseEnter={() => {
        if (isHoverBehaviourAllowed) {
          handleHover(true);
        }
      }}
      onMouseLeave={() => {
        if (isHoverBehaviourAllowed) {
          startHoverTimeout();
        }
      }}
      {...restProps}>
      <div className="icon-hover-slider__icon-wrapper" onClick={handleIconClick}>
        {handleReactSlot(renderIcon)}
      </div>
      <div className="icon-hover-slider__slider-wrapper">
        <div className="icon-hover-slider__slider-wrapper__inner">
          <EGWSlider
            className="icon-hover-slider__slider"
            max={max}
            min={min}
            step={step}
            value={value}
            onChange={(_e, value) => {
              onSliderChange(value);
              if (typeof hoverTimeout === "number") {
                clearInterval(hoverTimeout);
              }
            }}
          />
          <span className="icon-hover-slider__slider-label-right">
            {handleReactSlot(renderSliderLabelRight)}
          </span>
        </div>
      </div>
    </div>
  );
};

IconHoverSlider.defaultProps = {
  onIconClick: () => {},
  onSliderChange: () => {},
  renderIcon: "",
  renderSliderLabelRight: "",
};

IconHoverSlider.propTypes = {
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  slideDirectionLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  onIconClick: PropTypes.func,
  onSliderChange: PropTypes.func,
  renderIcon: PropTypes.any,
  renderSliderLabelRight: PropTypes.any,
};

const TextControl = ({
  className, value, delta, min, max, onChange, disabled, label, labelValue, onIconClick
}) => {
  const { t } = useTranslation();
  return (
    <div className={classnames("text-controls", className, {
      "value-is-not-zero": !!value,
    })}>
      <span>
        <span className={classnames("text-controls__text", {
          "clickable": !!onIconClick,
        })} onClick={onIconClick}>
          {t(label)}:&nbsp;
        </span>
        {value ? (
          <span className="text-controls__text-current">
            {labelValue ? handleReactSlot(labelValue) : value.toFixed(1)}
          </span>
        ) : (
          ""
        )}
      </span>
      <span className="text-controls__controls">
        <IconButton
          disabled={disabled}
          icon={PlusIcon}
          onClick={() => {
            onChange(clutch(value + delta, min, max));
          }}
        />
        <IconButton
          disabled={disabled}
          icon={MinusIcon}
          onClick={() => {
            onChange(clutch(value - delta, min, max));
          }}
        />
      </span>
    </div>
  );
};

TextControl.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  delta: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelValue: PropTypes.string,
  className: PropTypes.string,
  onIconClick: PropTypes.func,
};

export const TimerControls = ({
  iconOnRightSide,
  timePosition,
  trackDuration,
  stopTimeout,
  onIconClick,
  onChange,
  disabled,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { textMode } = useViewMode();

  if (textMode) {
    return (
      <TextControl
        className="timer-controls"
        disabled={disabled}
        onChange={onChange}
        onIconClick={onIconClick}
        value={stopTimeout}
        min={0}
        max={trackDuration}
        delta={15}
        label={"timer"}
        labelValue={convertToTime(Math.floor(stopTimeout), false) + " Ch"}
      />
    );
  }

  return (
    <IconHoverSlider
      disabled={disabled}
      className={classnames("timer-controls", {
        "has-timeout-value": stopTimeout,
      })}
      slideDirectionLeft={iconOnRightSide}
      value={stopTimeout}
      max={trackDuration}
      min={timePosition}
      onIconClick={onIconClick}
      onSliderChange={onChange}
      renderIcon={
        <button className="timer-controls-icon">
          <div className="timer-controls-icon__label">
            {convertToTime(Math.floor(stopTimeout), false)}
          </div>
          <IconButton
            disabled={disabled}
            // icon={stopTimeout ? EgwWebFont["timer"] : EgwWebFont["timer-disabled"]}
            icon={stopTimeout ? TimerIcon : TimerDisabledIcon}
            className="timer-controls-icon__svg"
            title={t("timer")}
          />
        </button>
      }
      renderSliderLabelRight={"Ch"}
      {...restProps}
    />
  );
};

TimerControls.propTypes = {
  disabled: PropTypes.bool,
  iconOnRightSide: PropTypes.bool,
  trackDuration: PropTypes.number,
  timePosition: PropTypes.number,
  stopTimeout: PropTypes.number,
  onIconClick: PropTypes.func,
  onChange: PropTypes.func,
};

export const VolumeControls = ({
  onIconClick,
  onChange,
  volume,
  isMute,
  disabled,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { textMode } = useViewMode();
  const isIOSDevice = getIsIOSDevice(); // [EGWW-2860]
  const volumeWithMute = Number((volume * 100 * !isMute).toFixed()); // Prevent "55.0000000001".

  const handleChange = useCallback((value) => {
    onChange(value / 100);
  }, [onChange]);

  if (textMode) {
    return (
      <TextControl
        className="volume-controls"
        disabled={disabled}
        onIconClick={onIconClick}
        onChange={onChange}
        value={volumeWithMute / 100}
        min={0}
        max={1}
        delta={isIOSDevice ? 1 : 0.1}
        label={"volume"}
      />
    );
  }

  return (
    <IconHoverSlider
      className="volume-controls"
      value={volumeWithMute}
      max={100}
      min={0}
      step={isIOSDevice ? 100 : 1}
      disabled={disabled}
      onIconClick={onIconClick}
      onSliderChange={handleChange}
      renderIcon={
        <IconButton
          disabled={disabled}
          icon={isMute ? EgwWebFont["volume-muted"] : EgwWebFont["volume"]}
          title={t("volume")}
        />
      }
      renderSliderLabelRight={volumeWithMute}
      {...restProps}
    />
  );
};

VolumeControls.propTypes = {
  volume: PropTypes.number,
  isMute: PropTypes.bool,
  disabled: PropTypes.bool,
  onIconClick: PropTypes.func,
  onChange: PropTypes.func,
};

const playbackMin = 0.1;
const playbackMax = 2;
const playbackStep = 0.1;

export const PlaybackControls = ({ onChange, playbackRate, disabled, ...restProps }) => {
  const { textMode } = useViewMode();
  const playbackFixed = playbackRate.toFixed(1);
  const nPlaybackFixed = Number(playbackFixed); // Prevent "55.0000000001".

  if (textMode) {
    return (
      <TextControl
        className="playback-controls"
        disabled={disabled}
        onChange={onChange}
        value={nPlaybackFixed}
        min={playbackMin}
        max={playbackMax}
        delta={playbackStep}
        label={"playback"}
      />
    );
  }
  return (
    <IconHoverSlider
      className="playback-controls"
      value={playbackRate * 10}
      max={playbackMax * 10}
      min={playbackMin * 10}
      step={playbackStep * 10}
      disabled={disabled}
      onSliderChange={(value) => {
        onChange(value / 10);
      }}
      renderIcon={
        <IconButton
          icon={EgwWebFont["playback-rate"]}
          className="playback-controls__icon"
        />
      }
      renderSliderLabelRight={playbackFixed}
      {...restProps}
    />
  );
};

PlaybackControls.propTypes = {
  disabled: PropTypes.bool,
  playbackRate: PropTypes.number,
  onChange: PropTypes.func,
};

export const PlayControls = ({
  disabled,
  isAudioPlaying,
  onSkipPrevClick,
  onSkipNextClick,
  onSeekPrevClick,
  onSeekNextClick,
  onTogglePlayClick,
  isSeekBackDisabled = false,
  isSeekForwardDisabled = false,
  isSkipForwardDisabled = false,
  isSkipBackDisabled = false,
  isTogglePlayDisabled = false,
  showSkipButtons = true,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classnames("player-controls", className, {
        "skip-buttons-shown": showSkipButtons,
      })}>
      <IconButton
        showIcon
        icon={EgwWebFont["chevron-double-left"]}
        onClick={onSeekPrevClick}
        title={t("seekBack")}
        disabled={disabled || isSeekBackDisabled}
      />
      {showSkipButtons && (
        <IconButton
          showIcon
          icon={EgwWebFont["skip-back"]}
          onClick={onSkipPrevClick}
          title={t("skipBack")}
          disabled={disabled || isSkipForwardDisabled}
        />
      )}
      <IconButton
        showIcon
        icon={isAudioPlaying ? EgwWebFont["pause"] : EgwWebFont["play"]}
        onClick={onTogglePlayClick}
        title={t("isPlaying")}
        disabled={disabled || isTogglePlayDisabled}
      />
      {showSkipButtons && (
        <IconButton
          showIcon
          icon={EgwWebFont["skip-forward"]}
          onClick={onSkipNextClick}
          title={t("skipForward")}
          disabled={disabled || isSkipBackDisabled}
        />
      )}
      <IconButton
        showIcon
        icon={EgwWebFont["chevron-double-right"]}
        onClick={onSeekNextClick}
        title={t("seekForward")}
        disabled={disabled || isSeekForwardDisabled}
      />
    </div>
  );
};

PlayControls.propTypes = {
  className: PropTypes.string,
  isAudioPlaying: PropTypes.bool,
  isSeekBackDisabled: PropTypes.bool,
  isSeekForwardDisabled: PropTypes.bool,
  isSkipForwardDisabled: PropTypes.bool,
  isSkipBackDisabled: PropTypes.bool,
  isTogglePlayDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  showSkipButtons: PropTypes.bool,
  onSkipPrevClick: PropTypes.func,
  onSkipNextClick: PropTypes.func,
  onSeekPrevClick: PropTypes.func,
  onSeekNextClick: PropTypes.func,
  onTogglePlayClick: PropTypes.func,
};

export const ProgressControls = ({
  stopTimeout,
  waveform,
  timePosition,
  trackDuration,
  onChange,
  onChangeCommitted,
  className,
  disabled,
  uiChangesInTextMode = true,
}) => {
  const { t } = useTranslation();
  const { textMode } = useViewMode();
  const needApplyTextModeUi = uiChangesInTextMode && textMode;
  return (
    <div
      className={classnames("progress-controls", className, {
        "with-slider": !needApplyTextModeUi,
      })}>
      {!needApplyTextModeUi && (
        <TimerFlag timerValue={stopTimeout} duration={trackDuration} isEnabled={!!stopTimeout}>
          {waveform ? (
            <WaveformProgress
              wave={waveform}
              min={0}
              max={trackDuration}
              value={timePosition}
              tooltip={t("timeline")}
              onChange={(_e, value) => {
                onChange(value);
              }}
              onChangeCommitted={(_e, value) => {
                onChangeCommitted(value);
              }}
            />
          ) : (
            <EGWSlider
              disabled={disabled}
              className="player-content__time-slider"
              max={trackDuration}
              min={0}
              value={timePosition}
              onChange={(e, value) => {
                onChange(value);
              }}
              onChangeCommitted={(e, value) => {
                onChangeCommitted(value);
              }}
            />
          )}
        </TimerFlag>
      )}
      <div className="progress-controls__time-wrapper">
        <div>
          {needApplyTextModeUi && <span>{t("playing")}: </span>}
          <span className="progress-controls__time-wrapper__current">
            {convertToTime(Math.round(timePosition), false)}
          </span>
        </div>
        <div>
          {needApplyTextModeUi && <span>{t("total")}: </span>}
          <span>{convertToTime(Math.round(trackDuration), false)}</span>
        </div>
      </div>
    </div>
  );
};

ProgressControls.propTypes = {
  className: PropTypes.string,
  timePosition: PropTypes.number,
  trackDuration: PropTypes.number,
  stopTimeout: PropTypes.number,
  waveform: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  uiChangesInTextMode: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const TrackCover = ({ coverSrc, bookTitle, trackTitle }) => {
  const { textMode } = useViewMode();
  return (
    <div className="track-cover">
      {!textMode && <img className="track-cover__img" src={coverSrc} alt={bookTitle} />}
      <div className="track-cover__text-wrapper">
        <span className="track-cover__text-wrapper__text">{bookTitle}</span>{" "}
        <span className="track-cover__text-wrapper__text track-cover__text-wrapper__text-track">
          {trackTitle}
        </span>
      </div>
    </div>
  );
};

TrackCover.propTypes = {
  coverSrc: PropTypes.string,
  bookTitle: PropTypes.string,
  trackTitle: PropTypes.string,
};
