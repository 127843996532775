import { CONTENT_CLASSES, DEFAULT_BIBLES, getBookId, sortByArray } from "../shared/utils/content";
import { getLanguageDefault } from "./Utils";
import { makeMLLangDefaultFirst } from "./ContentUtils";

const makeTreeChildrenBookTranslations = (mainTree, bookOriginal, bookOriginalTranslations) => {
  // creates { [lang]: bookTranslation[] }
  const langToBookTranslationsMap = {
    [bookOriginal.lang]: [bookOriginal],
  };

  let bookTranslation;
  for (let i = 0; i < bookOriginalTranslations.length; i++) {
    bookTranslation = bookOriginalTranslations[i];

    const translationLanguage = bookTranslation.lang;
    const booksWithTranslationLanguage = langToBookTranslationsMap[translationLanguage];

    langToBookTranslationsMap[translationLanguage] = booksWithTranslationLanguage
      ? [...booksWithTranslationLanguage, bookTranslation]
      : [bookTranslation];
  }
  // ==========

  // If one book translation of lang "A", creates tree item, that redirects to the book.
  // If plenty of book translations of lang "A", creates an expandable item with book translations.
  return Object.values(langToBookTranslationsMap).map((bookTranslationsOfLang) => {
    const firstBook = bookTranslationsOfLang[0];
    const firstBookLang = firstBook.lang;
    const languageName = mainTree.find((lang) => lang.id === firstBookLang)?.name || firstBookLang;
    const isPlentyTranslationsOfLang = bookTranslationsOfLang.length > 1;

    if (isPlentyTranslationsOfLang) {
      return {
        id: getBookId(firstBook.book_id) + "_" + firstBookLang,
        lang: firstBookLang,
        label: languageName,
        title: languageName,
        bookTitle: firstBook.title,
        className: CONTENT_CLASSES.TITLES_LANGUAGE_FOLDER,
        type: CONTENT_CLASSES.TITLES_LANGUAGE_FOLDER,

        children: bookTranslationsOfLang.map((book) => {
          return {
            ...book,
            id: getBookId(book.book_id),
            label: book.title,
            type: CONTENT_CLASSES.TITLES_BOOK_TRANSLATION,
            children: null,
          };
        })
      };
    }

    return {
      ...firstBook,
      id: getBookId(firstBook.book_id),
      label: languageName,
      title: languageName,
      bookTitle: firstBook.title,
      type: CONTENT_CLASSES.TITLES_BOOK_TRANSLATION,
      children: null,
    };
  });
};

export const convertTitleTree = (titleTree, mainTree) => {
  const nDefaultBibleEn = parseInt(DEFAULT_BIBLES.en);
  return titleTree.map((item) => {
    const categoryId = item.category;

    const categoryItem = {
      ...item,
      id: categoryId,
      categoryId,
      className: categoryId,
      type: CONTENT_CLASSES.TITLES_CATEGORY,
      label: categoryId,
      children: item.books.map((book) => {
        const bookOriginal = book.original;

        return {
          ...bookOriginal,
          // [3332] Special title for the English default Bible book.
          title: bookOriginal.book_id === nDefaultBibleEn
            ? "bibleVersions"
            : bookOriginal.title,
          // without "b" prefix to prevent highlight the item when a book is open as {BookDetails}.
          // Parsing to string to be the same type as {location.match} parameter.
          id: bookOriginal.book_id.toString(),
          categoryId, // is needed in each child to make a url on click {TitleTree.js}
          className: CONTENT_CLASSES.TITLES_BOOK,
          type: CONTENT_CLASSES.TITLES_BOOK,
          children: makeTreeChildrenBookTranslations(mainTree, bookOriginal, book.translations),
        };
      }),
    };

    delete categoryItem.books;

    return categoryItem;
  });
};

export const sortTitleTree = (titlesConverted, libraryLanguages) => {
  const mlLangDefault = getLanguageDefault(libraryLanguages);

  return titlesConverted.map((category) => {
    const categoryBooks = category.children;
    const categoryBooksFilteredSorted = [];

    let categoryBook, categoryBookChildren;
    for (let i = 0; i < categoryBooks.length; i++) {
      categoryBook = categoryBooks[i];
      categoryBookChildren = categoryBook.children;

      if (!categoryBookChildren.length) { // Skip a book without children
        continue;
      }

      // Sort by lib langs or make default lang first.
      let categoryBookChildrenFiltered;
      if (libraryLanguages.length) {
        categoryBookChildrenFiltered = sortByArray(categoryBookChildren, libraryLanguages, "lang");
      } else {
        categoryBookChildrenFiltered = makeMLLangDefaultFirst(categoryBookChildren, mlLangDefault);
      }
      // ===================================

      categoryBooksFilteredSorted.push({
        ...categoryBook,
        children: categoryBookChildrenFiltered,
      });
    }

    return {
      ...category,
      children: categoryBooksFilteredSorted,
    };
  });
};
