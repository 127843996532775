/* eslint-disable no-console */
import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  FETCH_RELEVANT_SEARCH_SCHEME,
  FETCH_RELEVANT_SEARCH_FACET_AGGREGATE,
  actionRelatedType,
} from "./relevantSearch.actions";

import {
  relevantSearchSchemeRequest,
  relevantSearchFacetAggregateRequest,
} from "../relevantSearch/RelevantSearchAPI";
import {
  fetchRelevantSearchSchemeSuccess,
  fetchRelevantSearchFacetAggregateSuccess,
} from "../relevantSearch/relevantSearch.actions";
import { getAllShortcutsRequest, searchExternalSuggestionsRequest } from "../../api/SearchAPI";
import { RelatedTabType } from "./RelevantSearch.utils";
import { arrayToObject } from "src/utils/Utils";

const TAG = "RelevantSearchSaga_";

function* fetchRelevantSearchSchemeWorker() {
  try {
    const { scheme } = yield select((state) => state.relevantSearch);

    if (scheme.length === 0) {
      const data = yield call(relevantSearchSchemeRequest);

      if (data) {
        const languages = data.sections.map((v) => v.language_code);
        const unicLanguages = [...new Set(languages)];

        yield put(fetchRelevantSearchSchemeSuccess(data.sections, unicLanguages));
      }
    }
  } catch (error) {
    console.log(TAG + "fetchRelevantSearchSchemeAsync", error);
  }
}

function* fetchRelevantSearchFacetAggregateWorker(action) {
  try {
    const { lang, query, onlyText } = action.data;

    if (lang && query) {
      let trimedQuery = query.trim();

      const { facets, lastSearchQuery, lastSearchLang } = yield select(
        (state) => state.relevantSearch,
      );
      // dont make search if the last search is same as the current one
      if (lastSearchQuery === trimedQuery && lastSearchLang === lang && facets.length !== 0) {
        return;
      }

      if (!onlyText) {
        const { baseBible } = yield select((state) => state.settings);
        const { bibles } = yield select((state) => state.mainTree);
        const shortcutResponse = yield call(getAllShortcutsRequest, trimedQuery, true);
        if (shortcutResponse) {
          const currentShortcode = shortcutResponse.filter((item) => item.lang === lang);
          const bibleIds = arrayToObject(bibles, "id", "lang");
          let isBible = false;
          if (currentShortcode.length === 1 && currentShortcode[0].cutQuery) {
            trimedQuery = currentShortcode[0].cutQuery;
            if (bibleIds[currentShortcode[0].bookId]) {
              isBible = true;
            }
          }

          const bible = shortcutResponse.find((item) => item.bookId === baseBible);
          if (bible) {
            isBible = true;
            if (bible.cutQuery) {
              trimedQuery = bible.cutQuery;
            }
          }

          yield put(actionRelatedType(isBible ? RelatedTabType.BIBLE : RelatedTabType.EGW));
        }
      }

      let results = yield call(relevantSearchFacetAggregateRequest, { lang, query: trimedQuery });
      const resultsSuggestions = yield call(searchExternalSuggestionsRequest, trimedQuery, lang);
      if (results) {
        if (resultsSuggestions.suggestions.length > 0) {
          results = {
            ...results,
            "egw_search:009": { facets: { suggestions: resultsSuggestions.suggestions } },
          };
        }
        yield put(fetchRelevantSearchFacetAggregateSuccess(results, lang, trimedQuery));
      }
    }
  } catch (error) {
    console.log(TAG + "fetchRelevantSearchFacetAggregateAsync", error);
  }
}

export default function* relevantSearch() {
  yield takeLatest(FETCH_RELEVANT_SEARCH_SCHEME, fetchRelevantSearchSchemeWorker);
  yield takeLatest(FETCH_RELEVANT_SEARCH_FACET_AGGREGATE, fetchRelevantSearchFacetAggregateWorker);
}
