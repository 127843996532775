import {
  FETCH_CONTENT_BY_DATE_SUCCESS,
  ACTION_CLEAR,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_THOUGHTS_SUCCESS,
  FETCH_MONTH_CALENDAR_SUCCESS,
  ACTION_MARK_AS_READ_SUCCESS,
  ACTION_UPDATE_SUBS_COUNT_SUCCESS,
  ACTION_FETCH_FEEDS_SUCCESS,
  ACTION_SUBSCRIPTIONS_BOOKS_SUCCESS,
  ACTION_FETCH_NEWS_BY_DATE_SUCCESS,
  ACTION_UPDATE_FEED_SUCCESS,
  ACTION_UPDATE_SUBSCRIPTION_SUCCESS,
  ACTION_FEEDS_LOADING
} from "./subscriptions.actions";
import { ACTION_LOGOUT } from "../../redux/constants";

const initialState = {
  allSubs: [],
  feeds: [],
  content: {},
  thoughts: {},
  calendar: {},
  subsCount: 0,
  news: {},
  subscriptionBooks: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_LOGOUT: {
      return initialState;
    }

    case ACTION_SUBSCRIPTIONS_BOOKS_SUCCESS: {
      return {...state,  subscriptionBooks: action.data };
    }

    case FETCH_SUBSCRIPTIONS_SUCCESS: {
      return { ...state, allSubs: action.data };
    }

    case FETCH_CONTENT_BY_DATE_SUCCESS: {
      const { date, dateContent } = action.data;
      const updatedSubs = {
        ...state.content,
        [date]: dateContent
      };
      return { ...state, content: updatedSubs };
    }

    case ACTION_CLEAR: {
      return { ...state, content: {}, calendar: {} };
    }

    case FETCH_THOUGHTS_SUCCESS: {
      const { date, dateThoughts } = action.data;
      const updatedThoughts = {
        ...state.thoughts,
        [date]: dateThoughts
      };
      return { ...state, thoughts: updatedThoughts };
    }

    case FETCH_MONTH_CALENDAR_SUCCESS: {
      const { year, month, dates } = action.data;
      const updatedCalendar = { ...state.calendar };
      if (!(year in updatedCalendar)) {
        updatedCalendar[year] = {};
      }
      updatedCalendar[year][month] = dates;
      return { ...state, calendar: updatedCalendar };
    }

    case ACTION_MARK_AS_READ_SUCCESS: {
    
      return { ...state, content: action.data, calendar: {} };
    }

    case ACTION_UPDATE_SUBS_COUNT_SUCCESS: {
      return { ...state, subsCount: action.data };
    }

    case ACTION_FETCH_FEEDS_SUCCESS: {
      return { ...state, feeds: action.data };
    }

    case ACTION_FETCH_NEWS_BY_DATE_SUCCESS: {
      const { date, dateNews } = action.data;

      const updatedNews = {
        ...state.news,
        [date]: dateNews.filter((item) => item.channel === 1)
      };

      return { ...state, news: updatedNews };
    }

    case ACTION_UPDATE_FEED_SUCCESS: {
      return { ...state, feeds: action.data };
    }

    case ACTION_UPDATE_SUBSCRIPTION_SUCCESS: {
      return { ...state, allSubs: action.data };
    }

    case ACTION_FEEDS_LOADING: {
      return { ...state, loading: action.data };
    }

    default:
      return state;
  }
};

