import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { cutAreaFromExtras, getBiblePubnr, Period } from "../../utils/AdvSearchTypes";
import { searchShortcutRequest } from "../../api/SearchAPI";
import { activeLangsSelector, useLoader, useShortcut } from "../../redux/selectors";
import SearchList from "./SearchList";
import SearchTopPanel from "./SearchTopPanel";
import { actionUpdateSetting, actionUpdateSettings } from "../../redux/actions";
import { ResultsCount } from "../views";
import { useNavigationContext } from "../NavigationContext";
import { useViewMode } from "../../hooks";
import { SearchPanelTabs } from "../rightPanel/constants";
import { updateSearchPeriod, makeSearchUrl, parseSearchUrl } from "../../utils/SearchUtils";
import { MinNavPanel } from "../rightPanel/MinNavPanel";
import componentsId from "../constants";
import { Settings } from "../../utils/Settings";
import { Order, PeriodDefault, SearchType } from "../../shared/utils/search";
import { CONTENT_CLASSES, getBookId } from "../../shared/utils/content";
import { logSearchEvent, SearchLogEvents } from "src/shared/api/analitics";
import { SearchListType } from "./search.utils";
import { SearchActions } from "./search.actions";
import ShortcutListResult from "./ShortcutListResult";
import ShortcutPanelButtons from "./ShortcutPanelButtons";
import { treesActions } from "../../redux/trees/actions";
import { URLS } from "../../shared/utils/url";
import { useSearchContext } from "./SearchContext";

import "./Search.scss";

const omskData = ["egw_search:003", "egw_dictionary:002"];
const bookData = ["egw_search:002"];
const phraseData = ["egw_phrase:000"];
const suggestionData = ["egw_search:009"];
/**
 *
 * @param {*} extras
 * @returns
 */
const getSearchListType = (extras = {}) => {
  const isRelatedType = extras.type === "related";
  if (omskData.includes(extras.section) && isRelatedType) {
    return SearchListType.OMSK_SEARCH;
  } else if (phraseData.includes(extras.section) && isRelatedType) {
    return SearchListType.PHRASE_SEARCH;
  } else if (bookData.includes(extras.section) && isRelatedType) {
    return SearchListType.BOOK_SEARCH;
  } else if (suggestionData.includes(extras.section) && isRelatedType) {
    return SearchListType.SUGGESTION_SEARCH;
  }
  return SearchListType.DEFAULT;
};

const SearchPanel = ({
  start,
  searchList,
  onChangePosition,
  onSearchFromScratch,
  searchListClassName,
  rightPanel,
  parentHeight,
  parentWidth,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useViewMode();
  const {
    isShortcutsAvailableAndShouldBeShown, syncReaderParaAndSearchShortcuts,
  } = useSearchContext();
  const { openGlobalSearchResult, openId, panelIds, isReader } = useNavigationContext();
  const search = useSelector((state) => state.search.search);
  const searchParams = useSelector((state) => state.search.searchParams);
  const activeLangs = useSelector(activeLangsSelector);
  const rightTab = useSelector((state) => state.settings.rightTab);
  const isShowRightPanel = useSelector((state) => state.settings.isShowRightPanel);
  const isRightPanelPinned = useSelector((state) => state.settings.isRightPanelPinned);
  const baseBible = useSelector((state) => state.settings.baseBible);
  const shortcutContent = useSelector((state) => state.search.shortcutContent.results);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);
  const syncReaderAndSearch = useSelector((state) => state.settings.syncReaderAndSearch);
  const isSearchPage = history.location.pathname === URLS.search;
  const loaderId = rightPanel ? componentsId.RIGHT_PANEL_SEARCH_ID : componentsId.SEARCH_ID;
  const searchLoading = useLoader(loaderId);

  const { query, context, extras, externalList } = searchParams;
  const { total: searchTotal } = search;

  const shortcutList = useShortcut();

  const resultCount = isShortcutsAvailableAndShouldBeShown ? shortcutContent.length : searchTotal;

  const skipLoader =
    searchList.length > 0 &&
    (searchList[0].query === query || searchList[0].query === searchQuery) &&
    shortcutContent.length > 0;

  const isLoading =
    !skipLoader &&
    ((externalList ? false : searchLoading && start === 1) ||
      (searchList.length === 0 && searchTotal > 0));

  const searchType = useMemo(() => getSearchListType(extras), [extras]);

  const onUpdateExtras = (updatedExtras, targetKey, skipBooks) => {
    const params = onUpdateParams(updatedExtras, targetKey, skipBooks);
    if (isSearchPage) {
      history.push(makeSearchUrl({ ...params, rightPanel }, collectionFilter));
    } else {
      dispatch(SearchActions.fetchSearch({ ...params, rightPanel: rightPanel }));
    }
  };

  const processPanelsOnItemClick = () => {
    let newSettings = {};
    if (isMobile) {
      // Mobile case
      if (isShowRightPanel) {
        newSettings[Settings.isShowRightPanel.id] = false;
      }
      dispatch(SearchActions.setShowFilterSearch(false));
    } else {
      // Tablet Or Desktop case
      if (isTablet && rightPanel && !isRightPanelPinned) {
        if (isShowRightPanel) {
          newSettings[Settings.isShowRightPanel.id] = false;
        }
      } else {
        if (!isShowRightPanel) {
          newSettings[Settings.isShowRightPanel.id] = true;
        }
      }
    }

    // switch to earchPanelTabs.RESULTS
    if (SearchPanelTabs.RESULTS !== rightTab) {
      newSettings[Settings.rightTab.id] = SearchPanelTabs.RESULTS;
    }
    dispatch(actionUpdateSettings(newSettings));
  };

  const onUpdateParams = (updatedExtras, targetKey, skipBooks) => {
    let params = {
      ...searchParams,
      extras: { ...extras, ...updatedExtras, type: updatedExtras?.type || SearchType.basic },
    };

    if (skipBooks) {
      params.books = [];
      params.extras.pubnr = undefined; // [3528]
    }
    if (updatedExtras?.period === Period.myBible || updatedExtras?.type === SearchType.bible) {
      const bBible = targetKey || baseBible;

      params = { ...params, books: [bBible], folders: [] };
      // remove other books for my bible period
    } else if (updatedExtras?.period === Period.dictionary) {
      params = {
        ...params,
        books: [],
        folders: targetKey && targetKey !== "all" ? [targetKey] : [],
        extras: { type: Period.dictionary, period: Period.dictionary },
      };
    }

    return params;
  };

  const onChangeOrder = (value) => {
    if (extras.order === value) {
      return;
    }

    const params = { order: value };

    if (value === Order.wrel) {
      params.period = Period.both;
    } else if (value === Order.rel) {
      params.period = Period.all;
    }

    dispatch(actionUpdateSetting(Settings.searchSort.id, value));
    onUpdateExtras(params);
  };

  const onChangeQueryType = (value) => {
    if (extras.queryType === value) {
      return;
    }

    dispatch(actionUpdateSetting(Settings.searchQueryType.id, value));
    onUpdateExtras({ queryType: value });
  };

  const onChangeSnippet = (value) => {
    if (extras.snippet === value) {
      return;
    }

    dispatch(actionUpdateSetting(Settings.searchSnippet.id, value));
    onUpdateExtras({ snippet: value });
  };

  const onChangePeriod = (value) => {
    if (extras.period === value) {
      return;
    }

    dispatch(treesActions.updateChecked([]));
    onUpdateExtras(updateSearchPeriod(value), undefined, true);
  };

  const onChangeDictionaryPeriod = (targetDictionary) => {
    onUpdateExtras({ period: extras.period }, targetDictionary);
  };

  const onChangeTargetBible = (targetBible) => {
    dispatch(SearchActions.updateSearchBible(targetBible.id));
    dispatch(treesActions.updateChecked([]));
    onUpdateExtras(
      {
        type: SearchType.bible,
        period: targetBible.id === baseBible ? Period.myBible : Period.custom,
        pubnr: getBiblePubnr(targetBible.id),
        bookCode: targetBible.code,
        lang: targetBible.lang,
      },
      targetBible.id,
    );
  };

  const onChangeCode = (value) => {
    const parsedUrl = parseSearchUrl(history.location.search);

    if (!value.trim().length) {
      onSearchFromScratch(parsedUrl?.type);
      return;
    }

    // else
    // non-empty bookCode case
    searchShortcutRequest({
      query: value,
      langs: activeLangs,
      bibleId: getBiblePubnr(baseBible),
    }).then((res) => {
      if (!res || res.type !== "ref") {
        return;
      }
      // Clear both trees for search in one book
      dispatch(treesActions.updateChecked([]));
      // else
      // code found case
      const newExtras = cutAreaFromExtras(extras);
      const newSearchParams = {
        query,
        books: [res.bookId],
        rightPanel,
        extras: { ...newExtras, period: Period.custom, type: SearchType.pubnr },
      };
      if (rightPanel) {
        dispatch(SearchActions.fetchSearch(newSearchParams, 1));
      } else {
        history.push(makeSearchUrl(newSearchParams, collectionFilter));
      }
    });
  };

  const onItemClick = (item) => {
    switch (searchType) {
      case SearchListType.OMSK_SEARCH:
      case SearchListType.SUGGESTION_SEARCH:
        {
          const newSearchParams = {
            query: item.result,
            start: 1,
            context: "",
            extras: { section: "", type: "basic" },
          };
          if (isMobile) {
            dispatch(SearchActions.fetchSearch(newSearchParams, 1, 1));
          } else {
            dispatch(
              actionUpdateSettings({
                [Settings.isShowRightPanel.id]: true,
                [Settings.rightTab.id]: SearchPanelTabs.FEATURED,
              }),
            );

            history.push(makeSearchUrl(newSearchParams, collectionFilter));
          }
        }
        break;

      case SearchListType.BOOK_SEARCH:
        openId(getBookId(item.book_id), {
          newWindow: true,
          className: CONTENT_CLASSES.BOOK,
          lang: item.lang,
        });
        break;
      case SearchListType.PHRASE_SEARCH: {
        // Search for phrases
        // set local search because need start a new local search for phrase
        let isLocalSearch = false;
        if (item.inner_hits) {
          let hintValue = item.inner_hits[0].trim();
          const lastIndex = hintValue.length - 1;
          if (hintValue[lastIndex] === "'") {
            hintValue[lastIndex] = "*";
          }
          isLocalSearch = true;
          const newSearchParams = {
            query: `"${hintValue}"`,
            isLocal: true,
            extras: { period: Period.custom, type: SearchType.pubnr },
          };
          dispatch(SearchActions.fetchSearch(newSearchParams, 1, 1));
        }

        openGlobalSearchResult(item.para_id, true, isLocalSearch);
        break;
      }
      case SearchListType.DEFAULT:
        logSearchEvent(SearchLogEvents.SEARCH_CLICK, {
          ...item,
          searchType,
        });
        processPanelsOnItemClick();
        openGlobalSearchResult(item.para_id, true, false);
        break;
    }
  };

  // Syncs Reader para and Search shortcuts.
  useEffect(() => {
    if (isShortcutsAvailableAndShouldBeShown && syncReaderAndSearch && isReader) {
      syncReaderParaAndSearchShortcuts();
    }
  }, [
    isShortcutsAvailableAndShouldBeShown,
    syncReaderAndSearch, isReader,
    syncReaderParaAndSearchShortcuts,
  ]);

  let searchListView;

  let searchTopPanel = (
    <SearchTopPanel
      total={searchTotal}
      loaded={searchList}
      start={start}
      isRightPanel={rightPanel}
      onChangePosition={onChangePosition}
      onChangeTargetBible={onChangeTargetBible}
      onChangeOrder={onChangeOrder}
      onChangeQueryType={onChangeQueryType}
      onChangeSnippet={onChangeSnippet}
      onChangePeriod={onChangePeriod}
      onChangeDictionaryPeriod={onChangeDictionaryPeriod}
      onChangeCode={onChangeCode}
      onSearchFromScratch={onSearchFromScratch}
    />
  );

  let shortcutPanel = null;
  let resultsCount = (
    <ResultsCount
      position={start}
      loaded={searchList.length}
      count={resultCount}
      searchQuery={searchParams.query}
    />
  );

  if (isShortcutsAvailableAndShouldBeShown) {
    if (rightPanel) {
      searchTopPanel = null;
    } else {
      searchTopPanel = (
        <SearchTopPanel total={searchTotal} loaded={searchList} />
      );
    }
    resultsCount = null;
    searchListView = <ShortcutListResult rightPanel={rightPanel} />;
    shortcutPanel = <ShortcutPanelButtons rightPanel={rightPanel} />;
  } else {
    searchListView = (
      <SearchList
        shortcutList={shortcutList}
        width={parentWidth}
        height={parentHeight}
        onCorrectionClick={(correction) => {
          logSearchEvent(SearchLogEvents.SEARCH_CLICK_CORRECTION, {
            correction,
          });
          // TODO impl open by chapter for type search
          if (correction.paraId) {
            openId(correction.paraId, {
              className: CONTENT_CLASSES.PARAGRAPH,
              replacePanelIndex: panelIds.length ? panelIds.length - 1 : 0,
            });
          } else {
            const title =
              correction.type === "search"
                ? correction.book_code + " " + correction.cutQuery
                : correction.title;

            dispatch(SearchActions.setSearchQuery(title));
            dispatch(
              SearchActions.fetchSearch({
                ...searchParams,
                query: title,
                rightPanel,
                // Probably, set of the {period} is a surplus.
                extras: { ...extras, period: PeriodDefault },
              }),
            );
          }
        }}
        listClassName={searchListClassName}
        searchList={searchList}
        total={searchTotal}
        start={start}
        onChangePosition={onChangePosition}
        snippet={extras.snippet}
        isLoading={isLoading}
        onChangePeriod={onChangePeriod}
        listType={searchType}
        onItemClick={onItemClick}
      />
    );
  }

  if (rightPanel) {
    return (
      <div className="tab-container">
        {resultCount !== -1 && (
          <div className="search-result-header">
            {shortcutPanel}
            {resultsCount}
            {searchTopPanel}
          </div>
        )}
        {searchListView}
      </div>
    );
  }

  return (
    <div className="search-wrapper">
      {searchTopPanel}
      <div className="search-result-header" data-search-result-header={true}>
        {resultsCount}
        <div className="results-related">
          {" "}
          <MinNavPanel />
        </div>
        {context && <div className="results-context">({t(context)})</div>}
      </div>
      {searchListView}
    </div>
  );
};

SearchPanel.defaultProps = {
  parentHeight: window.innerHeight,
  parentWidth: window.innerWidth,
};

SearchPanel.propTypes = {
  rightPanel: PropTypes.bool,
  searchListClassName: PropTypes.string,
  searchData: PropTypes.object,
  onSearchFromScratch: PropTypes.func,
  onChangePosition: PropTypes.func,
  start: PropTypes.number,
  searchList: PropTypes.array,
  parentHeight: PropTypes.number,
  parentWidth: PropTypes.number,
};

export default SearchPanel;
