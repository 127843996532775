export const FETCH_RELEVANT_SEARCH_SCHEME = "FETCH_RELEVANT_SEARCH_SCHEME";
export const FETCH_RELEVANT_SEARCH_SCHEME_SUCCESS = "FETCH_RELEVANT_SEARCH_SCHEME_SUCCESS";
export const FETCH_RELEVANT_SEARCH_FACET = "FETCH_RELEVANT_SEARCH_FACET";
export const FETCH_RELEVANT_SEARCH_FACET_AGGREGATE = "FETCH_RELEVANT_SEARCH_FACET_AGGREGATE";
export const FETCH_RELEVANT_SEARCH_FACET_AGGREGATE_SUCCESS =
  "FETCH_RELEVANT_SEARCH_FACET_AGGREGATE_SUCCESS";
export const FETCH_RELEVANT_SEARCH_FACET_SUCCESS = "FETCH_RELEVANT_SEARCH_FACET_SUCCESS";
export const ACTION_RELATED_TYPE = "ACTION_RELATED_TYPE";

export const fetchRelevantSearchScheme = () => ({
  type: FETCH_RELEVANT_SEARCH_SCHEME,
});

export const actionRelatedType = (data) => {
  return {
    type: ACTION_RELATED_TYPE,
    data,
  };
};

export const fetchRelevantSearchSchemeSuccess = (sections, relatedLangs) => ({
  type: FETCH_RELEVANT_SEARCH_SCHEME_SUCCESS,
  data: { sections, relatedLangs },
});

export const fetchRelevantSearchFacetAggregate = (lang, query, onlyText) => ({
  type: FETCH_RELEVANT_SEARCH_FACET_AGGREGATE,
  data: { lang, query, onlyText },
});

export const fetchRelevantSearchFacetSuccess = (code, results) => ({
  type: FETCH_RELEVANT_SEARCH_FACET_SUCCESS,
  data: { code, results },
});
export const fetchRelevantSearchFacetAggregateSuccess = (results, lang, query) => ({
  type: FETCH_RELEVANT_SEARCH_FACET_AGGREGATE_SUCCESS,
  data: { results, lang, query },
});

