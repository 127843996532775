export const FETCH_CONTENT_BY_DATE = "FETCH_CONTENT_BY_DATE";
export const FETCH_CONTENT_BY_DATE_SUCCESS = "FETCH_CONTENT_BY_DATE_SUCCESS";

export const ACTION_REFRESH_CONTENT = "ACTION_REFRESH_SUBSCRIPTIONS";
export const ACTION_CLEAR = "ACTION_CLEAR";

export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const UPDATE_SUBSCRIPTIONS = "UPDATE_SUBSCRIPTIONS";
export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";

export const FETCH_THOUGHTS = "FETCH_THOUGHTS";
export const FETCH_THOUGHTS_SUCCESS = "FETCH_THOUGHTS_SUCCESS";

export const FETCH_MONTH_CALENDAR = "FETCH_MONTH_CALENDAR";
export const FETCH_MONTH_CALENDAR_SUCCESS = "FETCH_THOUGHTS_SUCCESS_SUCCESS";

export const ACTION_MARK_AS_READ = "ACTION_MARK_AS_READ";
export const ACTION_MARK_AS_READ_SUCCESS = "ACTION_MARK_AS_READ_SUCCESS";

export const ACTION_SET_USER_TIME = "ACTION_SET_USER_TIME";

export const ACTION_MARK_ALL_READ = "ACTION_MARK_ALL_READ";
export const ACTION_UPDATE_SUBS_COUNT = "ACTION_UPDATE_SUBS_COUNT";
export const ACTION_UPDATE_SUBS_COUNT_SUCCESS = "ACTION_UPDATE_SUBS_COUNT_SUCCESS";

export const ACTION_FETCH_FEEDS = "ACTION_FETCH_FEEDS";
export const ACTION_FEEDS_LOADING = "ACTION_FETCH_LOADING";
export const ACTION_FETCH_FEEDS_SUCCESS = "ACTION_FETCH_FEEDS_SUCCESS";

export const ACTION_SUBSCRIPTIONS_BOOKS_SUCCESS = "ACTION_SUBSCRIPTIONS_BOOKS_SUCCESS";

export const ACTION_ACTIVATE = "ACTION_ACTIVATE";

export const ACTION_FETCH_NEWS_BY_DATE = "ACTION_FETCH_NEWS_BY_DATE";
export const ACTION_FETCH_NEWS_BY_DATE_SUCCESS = "ACTION_FETCH_NEWS_BY_DATE_SUCCESS";

export const ACTION_UPDATE_FEED = "ACTION_UPDATE_FEED";
export const ACTION_UPDATE_FEED_SUCCESS = "ACTION_UPDATE_FEED_SUCCESS";

export const ACTION_UPDATE_SUBSCRIPTION = "ACTION_UPDATE_SUBSCRIPTION";
export const ACTION_UPDATE_SUBSCRIPTION_SUCCESS = "ACTION_UPDATE_SUBSCRIPTION_SUCCESS";

export const DELETE_SUBSCRIPTIONS = "DELETE_SUBSCRIPTIONS";

export const fetchContentByDate = (data) => ({
  type: FETCH_CONTENT_BY_DATE,
  data
});

export const fetchContentByDateSuccess = (data) => ({
  type: FETCH_CONTENT_BY_DATE_SUCCESS,
  data
});

export const actionRefreshContent = (data) => ({
  type: ACTION_REFRESH_CONTENT,
  data
});

export const actionClear = (data) => ({
  type: ACTION_CLEAR,
  data
});

export const actionGetSubscriptions = () => ({
  type: FETCH_SUBSCRIPTIONS
});

export const actionUpdateSubscriptions = (data) => ({
  type: UPDATE_SUBSCRIPTIONS,
  data
});

export const fetchSubscriptionsSuccess = (data) => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  data
});

export const actionDeleteSubscriptions = (data) => ({
  type: DELETE_SUBSCRIPTIONS,
  data
});

export const fetchThoughts = (data) => ({
  type: FETCH_THOUGHTS,
  data
});

export const fetchThoughtsSuccess = (data) => ({
  type: FETCH_THOUGHTS_SUCCESS,
  data
});

export const fetchMonthCalendar = (data) => ({
  type: FETCH_MONTH_CALENDAR,
  data
});

export const fetchMonthCalendarSuccess = (data) => ({
  type: FETCH_MONTH_CALENDAR_SUCCESS,
  data
});

export const actionMarkAsRead = (data) => ({
  type: ACTION_MARK_AS_READ,
  data
});

export const actionMarkAsReadSuccess = (data) => ({
  type: ACTION_MARK_AS_READ_SUCCESS,
  data
});

export const actionSetUserTime = (data) => ({
  type: ACTION_SET_USER_TIME,
  data
});

export const actionMarkAllRead = (data) => ({
  type: ACTION_MARK_ALL_READ,
  data
});

export const actionUpdateSubsCount = (data) => ({
  type: ACTION_UPDATE_SUBS_COUNT,
  data
});

export const actionUpdateSubsCountSuccess = (data) => ({
  type: ACTION_UPDATE_SUBS_COUNT_SUCCESS,
  data
});

export const fetchFeeds = (data) => ({
  type: ACTION_FETCH_FEEDS,
  data
});

export const fetchFeedsSuccess = (data) => ({
  type: ACTION_FETCH_FEEDS_SUCCESS,
  data
});

export const actionFeedsLoading = (data) => ({
  type: ACTION_FEEDS_LOADING,
  data
});

export const actionActivate = (data) => ({
  type: ACTION_ACTIVATE,
  data
});

export const fetchSubscriptionBooksSuccess = (data) => ({
  type: ACTION_SUBSCRIPTIONS_BOOKS_SUCCESS,
  data
});

export const actionFetchNewsByDate = (data) => ({
  type: ACTION_FETCH_NEWS_BY_DATE,
  data
});

export const actionFetchNewsByDateSuccess = (data) => ({
  type: ACTION_FETCH_NEWS_BY_DATE_SUCCESS,
  data
});

export const actionUpdateFeed = (data) => ({
  type: ACTION_UPDATE_FEED,
  data
});

export const actionUpdateFeedSuccess = (data) => ({
  type: ACTION_UPDATE_FEED_SUCCESS,
  data
});

export const actionUpdateSubscription = (data) => ({
  type: ACTION_UPDATE_SUBSCRIPTION,
  data
});

export const actionUpdateSubscriptionSuccess = (data) => ({
  type: ACTION_UPDATE_SUBSCRIPTION_SUCCESS,
  data
});
