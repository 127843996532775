import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useViewMode } from "src/hooks";
import { actionUpdateSetting } from "src/redux/actions";
import { bookIdRegexStrict, CONTENT_CLASSES } from "src/shared/utils/content";
import { MENU_STATE } from "src/utils/MenuItems";
import { Settings } from "src/utils/Settings";
import { makeTree } from "src/utils/TreeUtils";
import { useNavigationContext } from "../NavigationContext";
import { titlesActions } from "../../redux/titleTree/actions";
import Tree from "./Tree";
import { findNode } from "../../utils/Utils";

import "./TitleTree.scss";
import { URLS } from "../../shared/utils/url";

const handleRightClickMockFn = () => {};

const TitleTree = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openId, currentFolder } = useNavigationContext();
  const currentFolderId = currentFolder.id;
  const { isMobile } = useViewMode();

  const tree = useSelector((state) => state.titleTree.tree);
  const expanded = useSelector((state) => state.titleTree.expanded);
  const checked = useSelector((state) => state.titleTree.checked);

  const handleItemToggleExpand = useCallback((item) => {
    dispatch(titlesActions.toggleItemExpand(item));
  }, []);

  const handleItemCheck = useCallback((item) => {
    dispatch(titlesActions.toggleItemCheck(item));
  }, []);

  const handleItemClick = useCallback((item, newWindow) => {
    if (isMobile) {
      dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
    }

    if (
      item.type === CONTENT_CLASSES.TITLES_ROOT
      || item.type === CONTENT_CLASSES.TITLES_CATEGORY
      || item.type === CONTENT_CLASSES.TITLES_BOOK
    ) {
      const pathUris = [URLS.titles];

      if (
        item.type === CONTENT_CLASSES.TITLES_CATEGORY
        || item.type === CONTENT_CLASSES.TITLES_BOOK
      ) {
        pathUris.push(item.categoryId);
      }

      if (item.type === CONTENT_CLASSES.TITLES_BOOK) {
        pathUris.push(item.id);
      }

      const pathnameNew = pathUris.join("/");
      if (history.location.pathname !== pathnameNew) {
        history.push(pathnameNew);
      }
    } else {
      if (bookIdRegexStrict.test(item.id)) {
        openId(item.id, { newWindow, className: CONTENT_CLASSES.BOOK, lang: item.lang });
      }
    }

    handleItemToggleExpand(item);
  }, [history, isMobile, handleItemToggleExpand, openId]);

  useEffect(() => {
    if (!tree.length) {
      dispatch(titlesActions.fetchConvertSortTree());
    }
  }, [tree.length]);

  const treeNormalized = useMemo(() => {
    return makeTree(tree, expanded, checked, [], true);
  }, [tree, expanded, checked]);

  const handleChangeExpanded = useCallback((expandedNew) => {
    dispatch(titlesActions.updateExpanded(expandedNew));
  }, []);

  const nodeList = useMemo(() => {
    const nodeList = [];
    findNode(currentFolderId, tree, "id", nodeList);
    return nodeList;
  }, [tree, currentFolderId]);

  return (
    <Tree
      currentFolder={currentFolder}
      nodeList={nodeList}

      className="tree-titles"

      folderTree={treeNormalized}

      expanded={expanded}
      onChangeExpanded={handleChangeExpanded}

      onItemClick={handleItemClick}
      onRightClick={handleRightClickMockFn}
      onItemCheck={handleItemCheck}
      onItemExpand={handleItemToggleExpand}
    />
  );
};

export default TitleTree;
