import { withEtagCachingRequest } from "./BaseAPI";

export const getTitlesRequest = () => {
  let url = "/content/export/titles";

  const request = {
    url,
    type: "get",
    parseResponse: (response) => response.data.categories,
  };

  return withEtagCachingRequest(request);
};
