import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ThemeMenuView from "./ThemeMenuView";
import { IconButton } from "../views";
import { getBoundingClientRect } from "../../utils/Utils";
import { useViewMode, useFullScreen, usePopupId } from "../../hooks";
import sassVariables from "../../styles/variables.module.scss";
import { usePopupContext } from "../popup/PopupContext";
import EgwWebFont from "src/assets/EgwWebFont";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { useWhatInputValueIsLastPointerMouse } from "../../what-input/useWhatInput";

import "./ThemeMenu.scss";

const POPUP_ID = "ThemeMenu";

const ThemeMenu = React.memo(() => {
  const { t } = useTranslation();
  const btnIconRef = useRef();
  const { zoom, textMode } = useViewMode();
  const remToPx = useRemToPx();
  const { fullscreen, toggle: toggleFullScreen } = useFullScreen();
  const { showPopup, hidePopup } = usePopupContext();
  const isPopupShown = usePopupId(POPUP_ID);
  const [compactLayout, setCompactLayout] = useState(false);
  const isLastPointerMouse = useWhatInputValueIsLastPointerMouse();

  const menuPopupNotCompactHeight = remToPx(sassVariables.themePopupHeight);

  // Regulate compact layout.
  useEffect(() => {
    const regulateCompactLayout = () => {
      if (btnIconRef.current) {
        const { bottom } = getBoundingClientRect(btnIconRef.current);

        if (
          window.innerWidth > window.innerHeight && // To perform only for landscapes.
          window.innerHeight <= menuPopupNotCompactHeight + bottom
        ) {
          setCompactLayout(true);
        } else {
          setCompactLayout(false);
        }
      }
    };

    window.addEventListener("resize", regulateCompactLayout);

    regulateCompactLayout();

    return () => {
      window.removeEventListener("resize", regulateCompactLayout);
    };
  }, [zoom, btnIconRef, textMode, menuPopupNotCompactHeight]);

  useEffect(() => {
    if (fullscreen) {
      hidePopup();
    }
  }, [fullscreen]);

  const show = () => {
    if (!fullscreen && !isPopupShown) {
      const targetRect = getBoundingClientRect(btnIconRef.current);

      showPopup(
        targetRect,
        <div className="theme-menu-popup">
          <ThemeMenuView compactLayout={compactLayout} />
        </div>,
        {
          withHoverZone: true,
          fitPosition: true,
          hideOnMouseLeave: false,
          hideOnOutsideClick: isLastPointerMouse,
          popupId: POPUP_ID,
          targetElement: btnIconRef.current,
        },
      );
    }
  };

  const handleClickTheme = () => {
    if (fullscreen) {
      toggleFullScreen();
    } else {
      if (isPopupShown) {
        hidePopup();
      } else {
        show();
      }
    }
  };

  return (
    <span
      ref={btnIconRef}
      className={classNames("theme-menu-wrap", { active: isPopupShown })}
      onKeyDown={handleClickTheme}
      onClick={handleClickTheme}>
      <IconButton
        increasedTapZone
        bordered
        className="themeIcon"
        icon={fullscreen ? EgwWebFont["fullscreen-exit"] : EgwWebFont["theme-select"]}
        title={t(fullscreen ? "minimize" : "theme")}
      />
    </span>
  );
});

ThemeMenu.propTypes = {
  textMode: PropTypes.bool,
};

export default ThemeMenu;
