import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useDialogContext } from "../dialog/DialogContext";
import { TextField } from "../views";
import DialogButtons from "../dialog/DialogButtons";

import "./SubscriptionsDialog.scss";

const TwitterDialog = ({ onConfirm }) => {
  const { hideDialog } = useDialogContext();
  const { t } = useTranslation();
  const [twitterUserName, setTwitterUserName] = useState("");
  const url = `https://twitter.com/intent/follow?original_referer=${window.location}
  &ref_src=twsrc%5Etfw&region=follow_link&screen_name=ellenwhite&tw_p=followbutton`;

  return <div className="twitter-container">
    <TextField
      className="twitter-input"
      value={twitterUserName}
      autoFocus
      error={twitterUserName.length === 0}
      onChange={(e) => setTwitterUserName(e.target.value)}
      label={t("username")}
    />
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}>
      {t("subOnTwitter")}
    </a>
    <DialogButtons
      onClickNegative={hideDialog}
      disabled={twitterUserName.length === 0}
      onClickPositive={() => {
        onConfirm(twitterUserName);
        hideDialog();
      }}
      negativeText={t("cancel")}
      positiveText={t("confirm")}
    />
  </div>;
};

TwitterDialog.propTypes = {
  onConfirm: PropTypes.func,
};

export default TwitterDialog;
