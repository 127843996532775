import React from "react";
import PropTypes from "prop-types";
import CartListItem from "./CartListItem";
import { getBookCover } from "src/shared/utils/url";
import { makeNavigationUrl } from "src/utils/URLUtils";
import { useDispatch } from "react-redux";
import { cartActions } from "src/redux/cart/actions";

import "./CartList.scss";
import EgwWebFont from "src/assets/EgwWebFont";

const CartList = React.memo(({ books, shopPackages, shopBooks, cartIds }) => {
  const isImageBigSize = false;
  const dispatch = useDispatch();
  return (
    <ul className="cart-list">
      {[...cartIds].map((cartId, index) => {
        const shopPackage = shopPackages[cartId];
        const shopBook = shopBooks[cartId] || shopPackage.items[0];
        const book = books[cartId] || books[shopBook.book_id];
        let title = book?.title,
          price = shopBook.price,
          fullPrice = shopBook.full_price,
          icon = EgwWebFont["book-close"];
        if (shopPackage) {
          title = shopPackage.title;
          price = shopPackage.price;
          fullPrice = shopPackage.full_price;
          icon = EgwWebFont["book-stack"];
        }
        return (
          <CartListItem
            key={cartId}
            index={index + 1}
            icon={icon}
            imgSrc={getBookCover(book, isImageBigSize)}
            navUrl={makeNavigationUrl(book)}
            title={title}
            price={price}
            discountPercent={shopPackage && shopPackage.discount_percent}
            bookCount={shopPackage && shopPackage.items.length}
            fullPrice={fullPrice}
            onRemove={() => {
              dispatch(cartActions.removeFromCart(shopPackage || shopBook));
            }}
          />
        );
      })}
      <hr className="cart-list__border" />
    </ul>
  );
});

CartList.propTypes = {
  books: PropTypes.object,
  shopBooks: PropTypes.object,
  shopPackages: PropTypes.object,
  cartIds: PropTypes.object
};

export default CartList;
