import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { int3DigitFormat } from "../../utils/Utils";
import { getResultsCount } from "../../utils/SearchUtils";

const ResultsCount = ({ position, loaded, searchQuery, count, children }) => {
  const { t } = useTranslation();
  const showCount = getResultsCount(count);

  if (count === -1) {
    return <div className="results-count">{t("resultsCountInitial")}</div>;
  }
  // else
  if (count > 0) {
    if (loaded !== undefined) {
      return (
        <div className="results-count">
          {t("resultsCountExtended", {
            loaded: int3DigitFormat(position),
            count: showCount,
          })}
          <span className="result-value">{searchQuery}</span>
          {children}
        </div>
      );
    }
    // else
    // loaded === undefined case
    return (
      <div className="results-count">
        {t("resultsCount", { count: showCount })}
        <span className="result-value">{searchQuery}</span>
      </div>
    );
  }

  return (
    <div className="results-count empty-search">
      <span className="empty-search-text">{t("resultsZeroCount")}</span>
      <span className="result-value">{searchQuery}</span>
    </div>
  );
};

ResultsCount.propTypes = {
  loaded: PropTypes.number,
  count: PropTypes.number,
  searchQuery: PropTypes.string,
  position: PropTypes.number,
  children: PropTypes.node,
};

export default ResultsCount;
