import React from "react";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { removeBR } from "src/shared/utils/content";
import { getColorByTheme } from "../utils/ThemeUtils";
import { useNavigationContext } from "./NavigationContext";

const MetaHeader = () => {
  const { label, pathname } = useNavigationContext();
  const themeMode = useSelector((state) => state.settings.themeMode);

  let titleName = removeBR(label);
  if (pathname === "/") {
    titleName = "The Complete Published Books of Ellen G. White";
  }

  const color = getColorByTheme(themeMode, "--primary");

  return (
    <Helmet>
      <meta name="theme-color" content={color} />
      <title>{"EGW Writings®: " + titleName}</title>
    </Helmet>
  );
};

export default MetaHeader;
