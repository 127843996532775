import { isTablet as isRealTablet } from "react-device-detect";

import dy from "./dy.json";
import dw from "./dw.json";
import dm from "./dm.json";
import dk from "./dk.json";
import ady from "./ady.json";
import adm from "./adm.json";
import { getIsIOSDevice, VIEW_MODE } from "./Utils";
import { COOKIE_KEY_THEME, DefaultFontSize, getTheme, Themes } from "../shared/utils/theme";
import { setCookie } from "../shared/utils/storage";

/**
 * @enum {string}
 */
export const ScreenType = {
  MOBILE_SMALL: "mobileSmall",
  MOBILE: "mobile",
  TABLET_SMALL: "tabletSmall",
  TABLET: "tablet",
  DESKTOP_SMALL: "desktopSmall",
  DESKTOP_MEDIUM: "desktopMedium",
  DESKTOP: "desktop",
  DESKTOP2K: "desktop2k",
  DESKTOP4K: "desktop4k",
  DESKTOP8K: "desktop8k",
};

export const THEME_MODE = Themes;

// Need to split theme files to separate file for avoid obsolete data for textapp
export const ThemeFiles = {
  dy: dy,
  dw: dw,
  dk: dk,
  dm: dm,
  ady: ady,
  adm: adm,
};

/**
 *  tablet: {
    [ScreenType.DESKTOP]: 170,
    [ScreenType.DESKTOP2K]: 230,
    } means - enable tablet view mode for screen size ScreenType.DESKTOP on zoom 170
 */
export const ZoomValues = {
  min: 80,
  factor: 10,
  max: {
    [ScreenType.MOBILE_SMALL]: 160,
    [ScreenType.MOBILE]: 160,
    [ScreenType.TABLET]: 200,
    [ScreenType.DESKTOP]: 200,
    [ScreenType.DESKTOP2K]: 400,
    [ScreenType.DESKTOP4K]: 600,
    [ScreenType.DESKTOP8K]: 600,
  },
  mobile: {
    [ScreenType.TABLET]: 200,
    [ScreenType.DESKTOP_SMALL]: 220,
    [ScreenType.DESKTOP_MEDIUM]: 230,
    [ScreenType.DESKTOP]: 250,
    [ScreenType.DESKTOP2K]: 330,
    [ScreenType.DESKTOP4K]: 400,
    [ScreenType.DESKTOP8K]: 500,
  },
  tablet: {
    [ScreenType.DESKTOP_SMALL]: 130,
    [ScreenType.DESKTOP_MEDIUM]: 150,
    [ScreenType.DESKTOP]: 170,
    [ScreenType.DESKTOP2K]: 230,
    [ScreenType.DESKTOP4K]: 290,
  },
  desktop: {
    [ScreenType.DESKTOP]: 100,
    [ScreenType.DESKTOP2K]: 110,
    [ScreenType.DESKTOP4K]: 120,
    [ScreenType.DESKTOP8K]: 120,
  },
  blindDefault: {
    [ScreenType.MOBILE_SMALL]: 110,
    [ScreenType.MOBILE]: 130,
    [ScreenType.TABLET_SMALL]: 120,
    [ScreenType.TABLET]: 130,
    [ScreenType.DESKTOP_SMALL]: 110,
    [ScreenType.DESKTOP_MEDIUM]: 110,
    [ScreenType.DESKTOP]: 130,
    [ScreenType.DESKTOP2K]: 150,
    [ScreenType.DESKTOP4K]: 180,
    [ScreenType.DESKTOP8K]: 180,
  },
};

export const TreeFontSizes = {
  min: 10,
  max: 40,
  factor: 2,
  default: 14,
  blindDefault: {
    [ScreenType.DESKTOP]: 20,
    [ScreenType.DESKTOP2K]: 24,
    [ScreenType.DESKTOP4K]: 28,
  },
};

export const SearchFontSizes = {
  min: 10,
  max: 64,
  factor: 2,
  default: 16,
  blindDefault: 20,
};

export const UpdateSize = {
  default: 0,
  increase: 1,
  decrease: -1,
};

export const getScreenType = () => {
  const { innerWidth: width, devicePixelRatio: ratio } = window;

  if (width <= 360) {
    return ScreenType.MOBILE_SMALL;
  } else if (width > 360 && width < 768) {
    if (isRealTablet) {
      return ScreenType.TABLET_SMALL;
    }
    if (ratio === 2) {
      return ScreenType.MOBILE_SMALL;
    }
    return ScreenType.MOBILE;
  } else if (width >= 768 && width < 1024) {
    return ScreenType.TABLET_SMALL;
  } else if (width >= 1024 && width <= 1366) {
    if (isRealTablet || getIsIOSDevice()) {
      return width > 1024 ? ScreenType.TABLET : ScreenType.TABLET_SMALL;
    }
    return ScreenType.DESKTOP_SMALL;
  } else if (width > 1366 && width <= 1440) {
    return ScreenType.DESKTOP_SMALL;
  } else if (width > 1440 && width <= 1680) {
    return ScreenType.DESKTOP_MEDIUM;
  } else if (width > 1920 && width <= 2560) {
    return ScreenType.DESKTOP2K;
  } else if (width > 2560 && width <= 5120) {
    return ScreenType.DESKTOP4K;
  } else if (width > 5120) {
    return ScreenType.DESKTOP8K;
  }
  return ScreenType.DESKTOP;
};

export const isDesktop = (screenType) => {
  return (
    screenType === ScreenType.DESKTOP_SMALL ||
    screenType === ScreenType.DESKTOP_MEDIUM ||
    screenType === ScreenType.DESKTOP ||
    screenType === ScreenType.DESKTOP2K ||
    screenType === ScreenType.DESKTOP4K ||
    screenType === ScreenType.DESKTOP8K
  );
};

export const getSizeValue = (model, key, mode) => {
  if (!model[key]) {
    return undefined;
  }
  if (typeof model[key] !== "number") {
    if (model[key][mode]) {
      return model[key][mode];
    }
    return model[key][ScreenType.DESKTOP];
  }
  return model[key];
};

export const getDefaultSize = (model) => {
  if (typeof model.default === "number") {
    return model.default;
  }
  const screenType = getScreenType();
  return model.desktop[screenType] || model.desktop[ScreenType.DESKTOP];
};

export const updateSizeValue = (current, updateSize, model, sizeMode) => {
  const defaultSize = getDefaultSize(model);

  let newValue = current || defaultSize;
  if (updateSize === UpdateSize.increase) {
    newValue = newValue + model.factor;
    const maxValue = getSizeValue(model, "max", sizeMode);
    if (newValue > maxValue) {
      newValue = maxValue;
    }
  } else if (updateSize === UpdateSize.decrease) {
    newValue = newValue - model.factor;
    if (newValue < model.min) {
      newValue = model.min;
    }
  } else if (updateSize === UpdateSize.default) {
    newValue = defaultSize;
  }
  return newValue;
};

export const makeZoomClass = (zoom) => {
  const zoomVal = zoom % 50 === 0 ? zoom : zoom - (zoom % 50);
  return `${VIEW_MODE.ZOOM}-${zoomVal}`;
};

export const getColorByTheme = (themeMode, name) => {
  const themeObj = getTheme(themeMode);
  const fileColors = ThemeFiles[themeObj.id];
  return fileColors[name];
};

export const getJsonAsCssString = (theme = THEME_MODE.dy.id) => {
  const themeObj = getTheme(theme);
  const fileColors = ThemeFiles[themeObj.id];

  let colorArr = [];
  for (const k in fileColors) {
    colorArr.push(`${k}: ${fileColors[k]};`);
  }

  return ":root {\n" + colorArr.join("\n") + "}";
};

export const activeTheme = (themeMode) => {
  const theme = getTheme(themeMode);
  const colors = ThemeFiles[theme.id];
  const rootElement = document.querySelector(":root");
  for (const k in colors) {
    rootElement.style.setProperty(k, colors[k]);
  }
  //set theme in cookies for send it to server
  setCookie(COOKIE_KEY_THEME, theme.id);
};

export const activeZoom = (zoom) => {
  const z = zoom || getDefaultSize(ZoomValues);

  const rootElement = document.querySelector("html");
  rootElement.style.fontSize = (DefaultFontSize * z) / 100 + "px";
};

export const hexToRgb = (hex) => {
  if (hex.slice(0, 1) === "#") {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((hex) => hex + hex)
      .join("");
  }

  const red = parseInt(hex.substr(0, 2), 16);
  const green = parseInt(hex.substr(2, 2), 16);
  const blue = parseInt(hex.substr(4, 2), 16);

  return [red, green, blue];
};

export const checkColorDark = (hex) => {
  const [red, green, blue] = hexToRgb(hex);
  return 1 - (0.299 * red + 0.587 * green + 0.114 * blue) / 255 > 0.5;
};

export const getContrastColor = (color) => {
  return checkColorDark(color) ? dy["--hl-light"] : dy["--hl-dark"];
};

export const checkColorLight = (hex) => {
  const [red, green, blue] = hexToRgb(hex);
  return red > 240 && green > 240 && blue > 240;
};

export const fillContrastColor = (color, themeMode) => {
  const backgroundColor = getColorByTheme(themeMode, "--tree-row-odd");

  const lightAndDark = color === "#000000" || checkColorLight(color);

  if (lightAndDark) {
    return getContrastColor(backgroundColor);
  }

  return color;
};

/**
 * Return contrast text color for hightlight
 * @param {String} hexcolor
 * @returns css variable for color
 */
export const getContrastTextColor = (hexcolor) => {
  return checkColorDark(hexcolor) ? "var(--hl-light)" : "var(--hl-dark)";
};
