import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { actionUpdateSubsCount } from "./components/subscriptions/subscriptions.actions";
import { actionAddMessage, actionAuthUser, actionUpdateSettings } from "./redux/actions";
import { TopPanel, RightPanel, SideMenu, Footer, Header } from "./components";
import DragnDrop from "./components/DragAndDrop";
import { Resizer } from "./components/resizer";
import Routes from "./Routes";
import MetaHeader from "./components/MetaHeader";
import usePush from "./pushes/usePush";
import { Scroll } from "./components/views";
import {
  useKeyboard,
  useAuth,
  useOrientationCheck,
  useSafariCheck,
  useSetViewMode,
  useViewMode,
  useSetScreenType,
} from "./hooks";
import PlayerSmall from "./components/bookPlayer/PlayerSmall/PlayerSmall";
import { useAutoHidePanels } from "./hooks/usePanels";
import { useScrollToTop } from "./hooks/useScroll";
import { useDefLang } from "./redux/selectors";
import { setTokens } from "./shared/utils/systemUtils";
import DevModeView from "./components/DevModeView";
import { makeChapterUrl } from "./utils/URLUtils";
import { Settings } from "./utils/Settings";
import { SearchPanelTabs } from "./components/rightPanel/constants";
import { checkPrefix } from "./components/reader/workspaces/WorkspaceUtils";
import { useDialogContext } from "./components/dialog/DialogContext";
import useAutoRefreshSubsCount from "./hooks/useAutoRefreshSubsCount";
import useChangeTreeBasedOnRoute from "./hooks/useChangeTreeBasedOnRoute";
import { getCookie, setCookie } from "./shared/utils/storage";
import { COOKIE_KEY_APP_OPEN_NUMBER } from "./utils/Utils";

import "./MainApp.scss";

/**
 * Part of app after loading and with important contexts
 * @returns
 */
const MainApp = () => {
  const { showConfirm } = useDialogContext();
  const { isLogin, isUserEditor } = useAuth();
  const { isMobile } = useViewMode();
  const [smallPlayerHeight, setSmallPlayerHeight] = useState();
  const history = useHistory();
  const isLoginByUserAction = useSelector((state) => state.system.isLoginByUserAction);
  const languageUI = useSelector((state) => state.settings.languageUI);
  const activeWorkspace = useSelector((state) => state.settings.activeWorkspace);
  const lastEditWorkspace = useSelector((state) => state.settings.lastEditWorkspace);
  const workspaces = useSelector((state) => state.settings.workspaces);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const scrollMainAppRef = useRef();
  const isSmallPlayerActive = useSelector((state) => state.audioPlayer.isSmallPlayerActive);
  const defLang = useDefLang();

  useSafariCheck();
  useOrientationCheck();
  useScrollToTop(scrollMainAppRef);
  usePush();
  useKeyboard();
  useSetScreenType();
  useSetViewMode();
  useAutoHidePanels();
  useAutoRefreshSubsCount();
  useChangeTreeBasedOnRoute();

  // Counts number of app's opens.
  useEffect(() => {
    let appOpenNumber = parseInt(getCookie(COOKIE_KEY_APP_OPEN_NUMBER, document.cookie, 1));
    appOpenNumber = Math.max(1, isNaN(appOpenNumber) ? 1 : appOpenNumber);
    setCookie(COOKIE_KEY_APP_OPEN_NUMBER, appOpenNumber + 1);
  }, []);

  useEffect(() => {
    if (languageUI) {
      i18n.changeLanguage(languageUI);
    }
  }, [i18n, isLogin, languageUI]);

  useEffect(() => {
    if (isLogin && isLoginByUserAction) {
      if (isUserEditor) {
        dispatch(
          actionUpdateSettings({
            [Settings.isShowRightPanel.id]: true,
            [Settings.rightTab.id]: SearchPanelTabs.STUDY,
          }),
        );
      } else {
        const openIdWS = activeWorkspace || lastEditWorkspace;
        const activeWS = workspaces.find((ws) => ws.id === openIdWS);
        if (activeWS) {
          showConfirm(
            t("loadWSTitle"),
            t("loadWSMessage", { loadWSTitle: activeWS.title }),
            () => {
              dispatch(
                actionUpdateSettings({
                  [Settings.activeWorkspace.id]: openIdWS,
                  [Settings.layout.id]: activeWS.layoutId,
                }),
              );
              const panelIds = checkPrefix(activeWS.ids).split(",");
              const url = makeChapterUrl(panelIds);
              history.push(url);
            },
            {
              negativeBtn: "loadWSStay",
              positiveBtn: "loadWSLoad",
              noTranslation: true,
            },
          );
        }
      }
    }
  }, [isLogin, isLoginByUserAction]);

  useEffect(() => {
    // START APP load init data and settings
    const sw = navigator.serviceWorker;

    if (sw) {
      sw.onmessage = (message) => {
        if (message.data === "PUSH_RECEIVED") {
          dispatch(actionUpdateSubsCount());
        }
      };
    }

    const handleAuthToken = (message) => {
      if (message?.data?.error) {
        dispatch(actionAddMessage(message.error));
      } else if (message?.data?.access_token) {
        let tokenData = message.data;
        const { access_token, refresh_token } = tokenData;
        /*eslint-disable-next-line*/
        if (access_token) {
          console.log("handleAuthToken", message);
          setTokens(access_token, refresh_token);
          /*eslint-disable-next-line*/
          if (refresh_token) {
            dispatch(
              actionAuthUser({
                token: access_token,
                refreshToken: refresh_token,
                i18nLang: defLang,
                loadLang: true,
              }),
            );
          }
        }
      }
    };

    window.addEventListener("message", handleAuthToken);

    return () => {
      window.removeEventListener("message", handleAuthToken);
      const sw = navigator.serviceWorker;

      if (sw) {
        sw.onmessage = null;
      }
    };
  }, [dispatch, i18n]);

  const ContentWrapper = isMobile ? "div" : Scroll;

  return (
    <React.Fragment>
      <MetaHeader />
      <Header />
      <ContentWrapper ref={scrollMainAppRef} id="mainApp" className="main-application" style={{
        paddingBottom: (isMobile && isSmallPlayerActive) ? smallPlayerHeight : 0,
      }}>
        <DragnDrop>
          <Resizer TopPanel={TopPanel} LeftPanel={SideMenu} RightPanel={RightPanel}>
            <Routes />
          </Resizer>
        </DragnDrop>

        {isMobile ? null : <Footer />}
        {isSmallPlayerActive && <PlayerSmall onMinimizedViewHeightChange={setSmallPlayerHeight} />}
      </ContentWrapper>
      <DevModeView />
    </React.Fragment>
  );
};

export default MainApp;
