export const ACTION_AUTH_USER = "ACTION_AUTH_USER";

export const ACTION_INIT_APP_LOAD = "ACTION_INIT_APP_LOAD";
export const ACTION_INIT_APP_LOAD_SUCCESS = "ACTION_INIT_APP_LOAD_SUCCESS";

export const FETCH_BOOKS = "FETCH_BOOKS";
export const FETCH_BOOKS_SUCCESS = "FETCH_BOOKS_SUCCESS";
export const FETCH_BOOKS_ERROR = "FETCH_BOOKS_ERROR";
 
export const FETCH_BOOKS_DETAILS_SUCCESS = "FETCH_BOOKS_DETAILS_SUCCESS";

export const ACTION_EXPAND_TREE = "ACTION_EXPAND_TREE";
export const FETCH_TRANSLATION_BOOK = "FETCH_TRANSLATION_BOOK";
export const FETCH_TRANSLATION_BOOK_SUCESS = "FETCH_TRANSLATION_BOOK_SUCESS";

export const FETCH_SEGMENTS_TRANSLATION = "FETCH_SEGMENTS_TRANSLATION";
export const FETCH_SEGMENTS_TRANSLATION_SUCCESS = "FETCH_SEGMENTS_TRANSLATION_SUCCESS";

export const FETCH_AVAILABLE_LANGUAGES = "FETCH_AVAILABLE_LANGUAGES";
export const FETCH_AVAILABLE_LANGUAGES_SUCCESS = "FETCH_AVAILABLE_LANGUAGES_SUCCESS";
export const ACTION_SET_ELLEN4ALL_TOKEN = "ACTION_SET_ELLEN4ALL_TOKEN";

export const COLLAPSE_TREE_ELEMENT = "COLLAPSE_TREE_ELEMENT";
export const ACTION_CHECK_TREE = "ACTION_CHECK_TREE";

export const ACTION_CHANGE_LANG = "ACTION_CHANGE_LANG";

export const ACTION_SAVE_MY_LIBRARY = "ACTION_SAVE_MY_LIBRARY";

export const ACTION_LOADING = "ACTION_LOADING";

export const ACTION_LANGUAGE = "ACTION_LANGUAGE";

export const READER_SEARCH_FOCUS = "READER_SEARCH_FOCUS";
export const READER_LAYOUT_WRAP_HEIGHT = "LAYOUT_WRAP_HEIGHT";
export const ACTION_FULLSCREEN_BOOK = "ACTION_FULLSCREEN_BOOK";

export const ACTION_LOGIN = "ACTION_LOGIN";
export const ACTION_LOGOUT = "ACTION_LOGOUT";
export const ACTION_LOGIN_SUCCESS = "ACTION_LOGIN_SUCCESS";

export const ACTION_FETCH_CF = "ACTION_FETCH_CF";
export const ACTION_FETCH_CF_SUCCESS = "ACTION_FETCH_CF_SUCCESS";
export const ACTION_SEND_CF = "ACTION_SEND_CF";
export const ACTION_SEND_CF_STATUS = "ACTION_SEND_CF_STATUS";

export const SET_DRAGNDROP = "SET_DRAGNDROP";
export const RESET_DRAGNDROP = "RESET_DRAGNDROP";
export const ACTION_RESIZE = "ACTION_RESIZE";

export const ACTION_DELETE_LIBRARY_LANGUAGE = "ACTION_DELETE_LIBRARY_LANGUAGE";

export const FETCH_DIALOG_CONTENTS = "FETCH_DIALOG_CONTENTS";
export const FETCH_DIALOG_CONTENTS_SUCCESS = "FETCH_DIALOG_CONTENTS_SUCCESS";

export const ACTION_SET_AUDIO_PLAYER_DATA = "ACTION_SET_AUDIO_PLAYER_DATA";
export const ACTION_FETCH_AUDIO_WAVE = "ACTION_FETCH_AUDIO_WAVE";
export const ACTION_PUT_AUDIO_WAVE = "ACTION_PUT_AUDIO_WAVE";
export const ACTION_SET_BROKEN_AUDIOS = "ACTION_SET_BROKEN_AUDIOS";

export const ACTION_SCROLL_DIRECTION_UPDATE = "ACTION_SCROLL_DIRECTION_UPDATE";

export const ACTION_SET_TOP_PANEL_VISIBILITY = "ACTION_SET_TOP_PANEL_VISIBILITY";
export const ACTION_SET_SCREEN_TYPE = "ACTION_SET_SCREEN_TYPE";

export const ACTION_UPDATE_DATA_ACQUISITION_STATUS = "ACTION_UPDATE_DATA_ACQUISITION_STATUS";
export const ACTION_UPDATE_SHOW_TEXT_MODE_POPUP = "ACTION_UPDATE_SHOW_TEXT_MODE_POPUP";

export const RESTORE_DATA = "RESTORE_DATA";

export const FETCH_GEO_IP_DATA = "FETCH_GEO_IP_DATA";
export const FETCH_GEO_IP_DATA_SUCCESS = "FETCH_GEO_IP_DATA_SUCCESS";

export const ACTION_SWIPING = "ACTION_SWIPING";

export const ACTION_CF_TYPO = "ACTION_CF_TYPO";

export const ACTION_ADD_MESSAGE = "ACTION_ADD_MESSAGE";

export const ACTION_PUT_INFO = "ACTION_PUT_INFO";

export const ACTION_COPY = "ACTION_COPY";

export const ACTION_UPDATE_THEME = "ACTION_UPDATE_THEME";
export const ACTION_UPDATE_SETTING = "ACTION_UPDATE_SETTING";
export const ACTION_UPDATE_SETTINGS = "ACTION_UPDATE_SETTINGS";
export const ACTION_UPDATE_SETTING_SUCCESS = "ACTION_UPDATE_SETTING_SUCCESS";

export const ACTION_NAVIGATE_BY_ID = "ACTION_NAVIGATE_BY_ID";

export const ACTION_SET_CONTENT_ID = "ACTION_SET_CONTENT_ID";

export const FETCH_ABBREVIATIONS = "FETCH_ABBREVIATIONS";
export const FETCH_ABBREVIATIONS_SUCCESS = "FETCH_ABBREVIATIONS_SUCCESS";
 
export const ACTION_SET_NEXT_ROUTE = "ACTION_SET_NEXT_ROUTE";

export const ACTION_TOGGLE_SHOW_CART = "ACTION_TOGGLE_SHOW_CART";

export const ACTION_SET_POPUP_ID = "ACTION_SET_POPUP_ID";

export const ACTION_CREATE_WORKSPACE = "ACTION_CREATE_WORKSPACE";
export const ACTION_UPDATE_WORKSPACE = "ACTION_UPDATE_WORKSPACE";
export const ACTION_DELETE_WORKSPACE = "ACTION_DELETE_WORKSPACE";
