import PropTypes from "prop-types";
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Rebound from "rebound";

class VirtualizedScrollBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elemHeight: this.props.staticElemHeight ? this.props.staticElemHeight : 50,
      scrollOffset: 0,
      elemOverScan: this.props.overScan ? this.props.overScan : 3,
      topSpacerHeight: 0,
      unrenderedBelow: 0,
      unrenderedAbove: 0,
    };
    this.stickyElems = null;
  }

  componentDidMount() {
    this.springSystem = new Rebound.SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.setOvershootClampingEnabled(true);
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate.bind(this) });
  }

  // update element height when it was changed
  componentDidUpdate(prevProps, prevState) {
    if (this.props.staticElemHeight !== prevProps.staticElemHeight) {
      this.setState({ elemHeight: this.props.staticElemHeight });
    }
  }

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
  }

  handleSpringUpdate(spring) {
    const val = spring.getCurrentValue();
    this.scrollBars.scrollTop(val);
  }

  // Find the first element to render,
  // and render (containersize + overScan / index * height) elems after the first.
  getListToRender(list) {
    let listToRender = [];
    this.stickyElems = [];
    const elemHeight = this.state.elemHeight;
    const containerHeight = this.props.containerHeight;
    const maxVisibleElems = Math.floor(containerHeight / elemHeight);
    if (!containerHeight || this.state.scrollOffset == null) {
      return list;
    }

    let smallestIndexVisible = null;
    if (
      this.state.scrollOffset === 0 &&
      this.props.stickyElems &&
      this.props.stickyElems.length === 0
    ) {
      smallestIndexVisible = 0;
    } else {
      for (let index = 0; index < list.length; index++) {
        const child = list[index];
        // Maintain elements that have the alwaysRender flag set.
        // This is used to keep a dragged element rendered,
        // even if its scroll parent would normally unmount it.
        if (this.props.stickyElems.find((id) => id === child.props.draggableId)) {
          this.stickyElems.push(child);
        } else {
          const ySmallerThanList = (index + 1) * elemHeight < this.state.scrollOffset;

          if (ySmallerThanList) {
            // Keep overwriting to obtain the last element that is not smaller
            smallestIndexVisible = index;
          }
        }
      }
    }
    const start = Math.max(
      0,
      (smallestIndexVisible != null ? smallestIndexVisible : 0) - this.state.elemOverScan,
    );
    // start plus number of visible elements plus overscan
    const end = smallestIndexVisible + maxVisibleElems + this.state.elemOverScan;
    // +1 because Array.slice isn't inclusive
    listToRender = list.slice(start, end + 1);
    // Remove any element from the list, if it was included in the stickied list
    if (this.stickyElems && this.stickyElems.length > 0) {
      listToRender = listToRender.filter(
        (elem) => !this.stickyElems.find((e) => e.props.draggableId === elem.props.draggableId),
      );
    }
    return listToRender;
  }

  // Save scroll position in state for virtualization
  handleScroll(e) {
    const scrollOffset = this.scrollBars ? this.scrollBars.getScrollTop() : 0;
    const scrollDiff = Math.abs(scrollOffset - this.state.scrollOffset);
    // As to not update exactly on breakpoint, but instead 5px or 10% within an element being scrolled past
    const leniency = Math.max(5, this.state.elemHeight * 0.1);
    if (!this.state.scrollOffset || scrollDiff >= this.state.elemHeight - leniency) {
      this.setState({ scrollOffset: scrollOffset });
    }
    if (this.props.onScroll) {
      this.props.onScroll(e);
    }
  }

  // Animated scroll to top
  animateScrollTop(top) {
    const scrollTop = this.scrollBars.getScrollTop();
    this.spring.setCurrentValue(scrollTop).setAtRest();
    this.spring.setEndValue(top);
  }

  // Get height of virtualized scroll container
  getScrollHeight() {
    return this.scrollBars.getScrollHeight();
  }
  getClientHeight() {
    return this.scrollBars.getClientHeight();
  }

  // Set scroll offset of virtualized scroll container
  scrollTop(val) {
    this.scrollBars.scrollTop(val);
  }
  // Get scroll offset of virtualized scroll container
  getScrollTop() {
    return this.scrollBars.getScrollTop();
  }

  handleScrollStop() {
    if (!this.props.scrollProps) {
      return;
    }
    const { onScrollStop } = this.props.scrollProps;
    if (onScrollStop) {
      const clientHeight = this.scrollBars.getClientHeight();
      const scrollTop = this.getScrollTop();
      const scrollHeight = this.getScrollHeight();
      onScrollStop({ scrollTop, scrollHeight, clientHeight });
    }
  }

  render() {
    const { customScrollbars, children, className } = this.props;
    const UseScrollbars = customScrollbars || Scrollbars;
    const rowCount = children.length;
    const elemHeight = this.state.elemHeight;

    const height = rowCount * this.state.elemHeight;
    let childrenWithProps = React.Children.map(children, (child, index) =>
      React.cloneElement(child, { originalindex: index }),
    );
    this.numChildren = childrenWithProps.length;

    const hasScrolled = this.state.scrollOffset > 0;

    const listToRender = this.getListToRender(childrenWithProps);

    const unrenderedBelow = hasScrolled
      ? (listToRender && listToRender.length > 0 ? listToRender[0].props.originalindex : 0) -
        (this.stickyElems ? this.stickyElems.length : 0)
      : 0;
    const unrenderedAbove =
      listToRender && listToRender.length > 0
        ? childrenWithProps.length - (listToRender[listToRender.length - 1].props.originalindex + 1)
        : 0;
    const belowSpacerStyle = this.props.disableVirtualization
      ? { width: "100%", height: 0 }
      : { width: "100%", height: unrenderedBelow ? unrenderedBelow * elemHeight : 0 };

    const aboveSpacerStyle = this.props.disableVirtualization
      ? { width: "100%", height: 0 }
      : { width: "100%", height: unrenderedAbove ? unrenderedAbove * elemHeight : 0 };

    if (this.stickyElems && this.stickyElems.length > 0) {
      listToRender.push(this.stickyElems[0]);
    }

    const innerStyle = {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexGrow: "1",
    };
    if (!this.props.disableVirtualization) {
      innerStyle.minHeight = height;
      innerStyle.height = height;
      innerStyle.maxHeight = height;
    }

    return (
      <UseScrollbars
        onScroll={this.handleScroll.bind(this)}
        ref={(div) => (this.scrollBars = div)}
        className={className}
        {...this.props.scrollProps}
        onScrollStop={this.handleScrollStop.bind(this)}>
        <div
          className={"virtualized-scrollbar-inner"}
          style={{ ...innerStyle }}
          ref={(div) => (this._test = div)}>
          <div style={belowSpacerStyle} className={"below-spacer"} />
          {listToRender}
          <div style={aboveSpacerStyle} className={"above-spacer"} />
        </div>
      </UseScrollbars>
    );
  }
}

VirtualizedScrollBar.propTypes = {
  children: PropTypes.shape({
    length: PropTypes.any,
  }),
  className: PropTypes.any,
  containerHeight: PropTypes.any,
  customScrollbars: PropTypes.any,
  disableVirtualization: PropTypes.any,
  onScroll: PropTypes.func,
  overScan: PropTypes.any,
  scrollProps: PropTypes.shape({
    onScrollStop: PropTypes.func,
  }),
  staticElemHeight: PropTypes.any,
  stickyElems: PropTypes.shape({
    find: PropTypes.func,
    length: PropTypes.number,
  }),
};

export default VirtualizedScrollBar;
