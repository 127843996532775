import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withResizeDetector } from "react-resize-detector/build/withPolyfill";

import { draw, normalizeData } from "./Utils";
import { useViewMode } from "../../../hooks";
import { calculateTextDimension, getBoundingClientRect, getTouches } from "../../../utils/Utils";
import "./WaveformProgress.scss";

const calculateProgressPercent = (val, min, max) => {
  const result = (val * 100) / (max - min);
  if (result < 0) {
    return 0;
  }
  return result <= 100 ? result : 100;
};

const calculateProgressWidth = (value, width, max) => {
  if (!max) {
    return 0;
  }
  return (width * value) / max;
};

const colorActive = getComputedStyle(document.documentElement).getPropertyValue(
  "--slider-active"
);
const colorInactive = getComputedStyle(document.documentElement).getPropertyValue(
  "--slider-inactive"
);

const WaveformProgress = ({
  width,
  wave,
  min,
  max,
  value,
  onChange,
  onChangeCommitted,
  tooltip
}) => {
  const [tooltipStyle, setTooltipStyle] = useState({});

  const themeMode = useSelector((state) => state.settings.themeMode);

  const canvasRef = useRef();

  const { isMobileOrTablet } = useViewMode();
  const [isMouseDown, setMouseDown] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  const [progressPercent, setProgressPercent] = useState(calculateProgressPercent(value, min, max));

  const normalizedData = useMemo(() => {
    return normalizeData(wave);
  }, [wave]);

  useEffect(() => {
    if (canvasRef.current) {
      draw(normalizedData, colorActive, colorInactive, canvasRef.current, progressWidth);
    }
  }, [normalizedData, progressWidth, width, themeMode]);

  useEffect(() => {
    if (canvasRef.current && !isMouseDown) {
      setProgressWidth(
        calculateProgressWidth(value, canvasRef.current.getBoundingClientRect().width, max)
      );
    }
  }, [value, max]);

  useEffect(() => {
    setProgressWidth(calculateProgressWidth(value, width, max));
  }, [width]);

  const handleProgressOver = (e) => {
    const topTooltipOffset = 20;
    const { width: tooltipWidth } = calculateTextDimension(tooltip, ["tooltip-view"]);
    const targetRect = getBoundingClientRect(e.target);
    const top = targetRect.y + targetRect.height + topTooltipOffset;
    const halfTooltipWidth = tooltipWidth / 2;
    const halfRectWidth = targetRect.width / 2;
    const left = targetRect.x + halfRectWidth - halfTooltipWidth;

    setTooltipStyle({ show: true, left, top });
  };

  const handleHideTooltip = () => {
    setTooltipStyle({});
  };

  const seekProgress = (e) => {
    const canvasRect = e.currentTarget.getBoundingClientRect();
    const touches = getTouches(e);
    const clientX = (touches) ? touches[0].clientX : e.clientX;
    const x = clientX - canvasRect.left;
    const percent = calculateProgressPercent(x, 0, canvasRect.width);

    setProgressWidth(x);
    setProgressPercent(percent);
  };

  const handleMouseDown = (e) => {
    setMouseDown(true);
    seekProgress(e);
  };

  const handleMouseUp = (e) => {
    seekProgress(e);
    setMouseDown(false);
    onChangeCommitted(e, Math.ceil((max * progressPercent) / 100));
  };

  const handleMouseMove = (e) => {
    handleProgressOver(e);

    if (isMouseDown) {
      seekProgress(e);
      onChange(e, Math.ceil((max * progressPercent) / 100));
    }
  };

  const handleMouseLeave = (e) => {
    handleHideTooltip();

    if (isMouseDown) {
      handleMouseUp(e);
    }
  };

  return (
    <div className="waveform-control">
      <canvas
        ref={canvasRef}
        className="waveform-control__canvas"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseEnter={handleProgressOver}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
      />
      {!isMobileOrTablet && tooltipStyle.show && (
        <div className="tooltip-wrap" style={tooltipStyle}>
          <div className={classNames("tooltip-view")}>
            <div className="tooltip-text" dangerouslySetInnerHTML={{ __html: tooltip }} />
          </div>
        </div>
      )}
    </div>
  );
};

WaveformProgress.propTypes = {
  width: PropTypes.number,
  wave: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  tooltip: PropTypes.string
};

export default withResizeDetector(WaveformProgress);
