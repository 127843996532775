import whatInput from "what-input";
import {
  dispatchCustomEventLastInputWasMouseChange,
  WINDOW_KEY_IS_LAST_POINTER_MOUSE
} from "./what-input.utils";
import { getIsMatchesCssMediaHover } from "../utils/Utils";

window[WINDOW_KEY_IS_LAST_POINTER_MOUSE] = getIsMatchesCssMediaHover();
const handleWhatInputChange = (type) => {
  let valueNew;

  if (type === "mouse" || type === "pointer") {
    valueNew = true;
  } else if (type === "touch") {
    valueNew = false;
  }

  if (valueNew !== undefined) {
    window[WINDOW_KEY_IS_LAST_POINTER_MOUSE] = valueNew;
    dispatchCustomEventLastInputWasMouseChange(valueNew);
  }
};

whatInput.registerOnChange(handleWhatInputChange, "intent");
whatInput.registerOnChange(handleWhatInputChange, "input");
