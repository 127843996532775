/* eslint-disable no-console */
import { put, call, select, takeEvery, all, takeLatest, delay } from "redux-saga/effects";
import { saveBookHistory, readHistoryConfig, listenHistoryConfig } from "../api/SyncService";

import {
  ACTION_SAVE_READ,
  ACTION_SAVE_LISTEN,
  TOGGLE_BOOK_IN_ML_BY_ID,
  FETCH_ALL_BOOKS_DATA,
  FETCH_LIB_LANGS,
  SET_LIB_LANGS,
  ACTION_STORE_HISTORY_DATA,
  formBookDataSuccess,
  fetchAllBooksData,
} from "./library.actions";
import { HistoryType, findNode } from "../utils/Utils";
import {
  fetchLibLangsRequest,
  setLibLangsRequest,
  putHistoryRequest,
  getBookByIdRequest,
  getAllHistoryRequest,
  addBooksToLibraryRequest,
  deleteBooksFromLibraryRequest,
} from "../api/API";
import { actionAddMessage, actionLoading, actionUpdateSettings } from "./actions";
import { ACTION_DELETE_LIBRARY_LANGUAGE } from "./constants";
import { findParaById, getAudioChapterByChapterId } from "../components/reader/ReaderUtils";
import { Settings } from "../utils/Settings";
import { getBookId, getBookOrigin } from "../shared/utils/content";
import { joinHistoryData, makeHistoryUrl } from "../api/api.utils";
import { loadBooksDataWorker } from "./saga.utils";

const SYNC_HISTORY_TYPE_DEBOUNCE_MS = 3000;

function* fetchAllBooksDataMethod(action) {
  const { isLogin } = yield select((state) => state.system);
  if (!isLogin) {
    return;
  }
  const historyTypes = action.data;
  if (historyTypes) {
    console.log("fetchAllBooksDataAsync selective", historyTypes);
  }
  yield put(actionLoading(FETCH_ALL_BOOKS_DATA));
  const requests = {
    reading: makeHistoryUrl(HistoryType.READING, true),
    listen: makeHistoryUrl(HistoryType.LISTEN, true),
    ml: makeHistoryUrl(HistoryType.ML_HISTORY, true),
    library: makeHistoryUrl(HistoryType.LIBRARY, false),
  };
  const { reading, listen, ml, library } = yield call(getAllHistoryRequest, requests);

  const allHistoryItems = joinHistoryData(reading, listen, ml, library);

  yield call(storeHistoryDataMethod, { data: allHistoryItems });
  yield put(actionLoading(FETCH_ALL_BOOKS_DATA, true));
}

function* storeHistoryDataMethod(action) {
  const allHistoryItems = action.data;
  const bookIds = Object.keys(allHistoryItems);

  const allBooksInfo = yield call(loadBooksDataWorker, bookIds);

  // avoid join book data and history here and move to selector
  const formedBooksData = allBooksInfo.map((item) => {
    const bookHistory = allHistoryItems[item.id];
    const bookData = {
      id: item.id,
      bookInfo: item,
      ...bookHistory,
    };
    if (item.lang) {
      bookData.lang = item.lang;
    }
    return bookData;
  });
  yield put(formBookDataSuccess(formedBooksData));
}

function* toggleBookInMLById(action) {
  const { bookId } = action.data;

  const { allHistory } = yield select((state) => state.history);
  const { mainTree, books } = yield select((state) => state.mainTree);

  const allHistorySpread = [...allHistory];

  const bookIndex = allHistorySpread.findIndex((item) => item.id === bookId);
  let isAdded;
  if (bookIndex === -1) {
    let bookInfo = books.find((book) => book.id === bookId);
    if (!bookInfo) {
      bookInfo = findNode(bookId, mainTree);
    }
    if (!bookInfo) {
      bookInfo = yield call(getBookByIdRequest, bookId);
    }
    if (bookInfo) {
      allHistorySpread.push({
        id: bookId,
        lang: bookInfo?.lang,
        bookInfo,
        inLibrary: true,
        luLibrary: new Date().toISOString(),
      });
      isAdded = true;
    }
  } else {
    allHistorySpread[bookIndex].inLibrary = !allHistorySpread[bookIndex].inLibrary;
    allHistorySpread[bookIndex].luLibrary = new Date().toISOString();
    isAdded = allHistorySpread[bookIndex].inLibrary;
  }
  yield put(formBookDataSuccess(allHistorySpread));
  const bookOriginId = getBookOrigin(bookId);
  if (isAdded) {
    yield call(addBooksToLibraryRequest, [bookOriginId]);
    yield put(actionAddMessage("@toastAddToLibrary"));
  } else {
    yield call(deleteBooksFromLibraryRequest, [bookOriginId]);
    yield put(actionAddMessage("@toastRemoveFromLibrary"));
  }
}

function* saveReadHistoryMethod(action) {
  const { paraId, position } = action.data;

  yield delay(SYNC_HISTORY_TYPE_DEBOUNCE_MS);

  yield saveHistoryMethod(HistoryType.READING, paraId, position);
}

function* saveListenHistoryMethod(action) {
  const { paraId, position: positionNotParsed } = action.data;
  const position = Number.parseInt(positionNotParsed, 10);

  yield delay(SYNC_HISTORY_TYPE_DEBOUNCE_MS);

  yield saveHistoryMethod(HistoryType.LISTEN, paraId, position);
}

function* saveHistoryMethod(historyType, paraOrChapterId, position) {
  const bookId = getBookId(paraOrChapterId);
  let allHistory = yield select((state) => state.history.allHistory);
  const bookData = allHistory.find((item) => item.id === bookId);
  const isInLibrary = bookData && bookData.inLibrary;

  const { mainTree } = yield select((state) => state.mainTree);

  let paraOrChapter;

  if (historyType === HistoryType.LISTEN) {
    const book = findNode(bookId, mainTree);
    paraOrChapter = getAudioChapterByChapterId(book, paraOrChapterId);
  } else {
    const { paragraphs } = yield select((state) => state.paragraphReducer);
    const bookContent = paragraphs[bookId] || [];
    paraOrChapter = findParaById(paraOrChapterId, mainTree, bookContent);
  }

  if (!paraOrChapter) {
    return;
  }
  // TODO remove save history to localStorage,
  // just put it in allHistory and make sync after some time
  const { items } = saveBookHistory(paraOrChapter, mainTree, historyType, position);
  let mlHistory;
  if (isInLibrary) {
    mlHistory = {
      mlLu: new Date().toISOString(),
      mlRead: paraOrChapterId,
    };
    if (position) {
      mlHistory.mlListen = position;
    }
  }

  const historyItem = items.find((item) => item.id === bookId);
  const allHistoryReselected = yield select((state) => state.history.allHistory);
  const allHistoryReselectedCopy = [...allHistoryReselected];

  const itemIndexInAllHistory = allHistoryReselectedCopy.findIndex((item) => item.id === bookId);

  if (historyItem) {
    if (itemIndexInAllHistory !== -1) {
      const item = allHistoryReselectedCopy[itemIndexInAllHistory];
      allHistoryReselectedCopy[itemIndexInAllHistory] = {
        ...(item || {}),
        ...historyItem,
        ...(mlHistory || {}),
      };
    } else {
      allHistoryReselectedCopy.push({
        ...(mlHistory || {}),
        ...historyItem,
      });
    }

    const { isLogin } = yield select((state) => state.system);

    //TODO optimize
    if (isLogin) {
      let itemToSave = {};

      if (historyType === HistoryType.READING) {
        // eslint-disable-next-line no-console
        console.log(
          "%cSAVE Read " + paraOrChapterId + " position: " + position,
          "background: red; color: white;",
        );
        itemToSave = readHistoryConfig.mapToServer(historyItem);
      } else if (historyType === HistoryType.LISTEN) {
        // eslint-disable-next-line no-console
        console.log(
          "%cSAVE Listen " + paraOrChapterId + " position: " + position,
          "background: blue; color: white;",
        );
        itemToSave = listenHistoryConfig.mapToServer(historyItem);
      }

      yield call(putHistoryRequest, historyType, {
        items: [itemToSave],
      });

      if (isInLibrary) {
        yield call(putHistoryRequest, HistoryType.ML_HISTORY, {
          items: [itemToSave],
        });
      }
    }

    yield put(formBookDataSuccess(allHistoryReselectedCopy));
  }
}

function* deleteLibraryLanguageMethod(action) {
  const books = action.data;
  const { isLogin } = yield select((state) => state.system);
  if (isLogin) {
    const bookIds = books.map((item) => getBookOrigin(item.id));
    yield call(deleteBooksFromLibraryRequest, bookIds);
    yield put(fetchAllBooksData());
  }
}

function* updateLibLangs(languages) {
  yield put(
    actionUpdateSettings({
      [Settings.libraryLanguages.id]: languages,
      [Settings.filterLibraryLanguages.id]: languages,
    }),
  );
}

function* fetchLibLangsAsync() {
  const languages = yield call(fetchLibLangsRequest);
  if (languages) {
    yield call(updateLibLangs, languages);
  }
}

function* setLibLangsAsync(action) {
  const langs = action.data;
  const { isLogin } = yield select((state) => state.system);

  if (isLogin) {
    yield call(setLibLangsRequest, langs);
  }

  yield call(updateLibLangs, [...langs]);
}

export default function* librarySaga() {
  yield all([
    takeLatest(ACTION_SAVE_READ, saveReadHistoryMethod),
    takeLatest(ACTION_SAVE_LISTEN, saveListenHistoryMethod),
    takeEvery(TOGGLE_BOOK_IN_ML_BY_ID, toggleBookInMLById),
    takeEvery(ACTION_DELETE_LIBRARY_LANGUAGE, deleteLibraryLanguageMethod),
    takeEvery(FETCH_ALL_BOOKS_DATA, fetchAllBooksDataMethod),
    takeEvery(FETCH_LIB_LANGS, fetchLibLangsAsync),
    takeEvery(SET_LIB_LANGS, setLibLangsAsync),
    takeEvery(ACTION_STORE_HISTORY_DATA, storeHistoryDataMethod),
  ]);
}
