import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useViewMode } from "src/hooks";
import { actionUpdateSetting } from "src/redux/actions";
import { categoryTreeActions } from "src/redux/categoryTree/actions";
import { bookIdRegexStrict, CONTENT_CLASSES } from "src/shared/utils/content";
import { MENU_STATE } from "src/utils/MenuItems";
import { Settings } from "src/utils/Settings";
import { makeTree } from "src/utils/TreeUtils";
import { useNavigationContext } from "../NavigationContext";
import { findNode } from "../../utils/Utils";
import Tree from "./Tree";

import "./CategoryTree.scss";
import { URLS } from "../../shared/utils/url";

const handleRightClickMockFn = () => {};

const CategoryTree = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openId, currentFolder } = useNavigationContext();
  const currentFolderId = currentFolder.id;
  const { isMobile } = useViewMode();

  const tree = useSelector((state) => state.categoryTree.tree);
  const expanded = useSelector((state) => state.categoryTree.expanded);
  const checked = useSelector((state) => state.categoryTree.checked);

  const handleItemToggleExpand = useCallback((item) => {
    dispatch(categoryTreeActions.toggleItemExpand(item));
  }, []);

  const handleItemCheck = useCallback((item) => {
    dispatch(categoryTreeActions.toggleItemCheck(item));
  }, []);

  const handleItemClick = useCallback((item, newWindow) => {
    if (isMobile) {
      dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
    }

    if (
      item.type === CONTENT_CLASSES.CATEGORIES_ROOT
      || item.type === CONTENT_CLASSES.CATEGORIES_CATEGORY
      || item.type === CONTENT_CLASSES.CATEGORIES_BOOK
    ) {
      const pathUris = [URLS.categories];

      if (
        item.type === CONTENT_CLASSES.CATEGORIES_CATEGORY
        || item.type === CONTENT_CLASSES.CATEGORIES_BOOK
      ) {
        pathUris.push(item.categoryId);
      }

      if (item.type === CONTENT_CLASSES.CATEGORIES_BOOK) {
        pathUris.push(item.id);
      }

      const pathnameNew = pathUris.join("/");
      if (history.location.pathname !== pathnameNew) {
        history.push(pathnameNew);
      }
    } else {
      if (bookIdRegexStrict.test(item.id)) {
        openId(item.id, { newWindow, className: CONTENT_CLASSES.BOOK, lang: item.lang });
      }
    }

    handleItemToggleExpand(item);
  }, [history, isMobile, handleItemToggleExpand, openId]);

  useEffect(() => {
    dispatch(categoryTreeActions.fetchConvertSortTree());
  }, [dispatch]);

  const treeNormalized = useMemo(() => {
    return makeTree(tree, expanded, checked, [], true);
  }, [tree, expanded, checked]);

  const handleChangeExpanded = useCallback((expandedNew) => {
    dispatch(categoryTreeActions.updateExpanded(expandedNew));
  }, []);

  const nodeList = useMemo(() => {
    const nodeList = [];
    findNode(currentFolderId, tree, "id", nodeList);
    return nodeList;
  }, [tree, currentFolderId]);

  return (
    <Tree
      currentFolder={currentFolder}
      nodeList={nodeList}

      className="tree-categories"

      folderTree={treeNormalized}
      expanded={expanded}
      onChangeExpanded={handleChangeExpanded}

      onItemClick={handleItemClick}
      onRightClick={handleRightClickMockFn}
      onItemCheck={handleItemCheck}
      onItemExpand={handleItemToggleExpand}
    />
  );
};

export default CategoryTree;
