import { bookType } from "../components/subscriptions/SubscriptionsUtils";

/**
 * @description The reason we need this is that the subscriptions has
 * the "devotional" type, that is "subtype" for the book instance.
 * Nevertheless, other types as "book", "bible" are "type" for
 * the book instance.
 * @param book
 * @returns {string}
 */
export const getSubscriptionRightBookType = (book) => {
  const { subtype } = book;

  if (subtype === bookType.DEVOTIONAL.label) {
    return subtype;
  }

  return book.type;
};
