import { useSelector } from "react-redux";
import {
  getLinkValue,
  findBookIndex,
  NEW_PANEL_ID,
  PanelTypes,
  makeChapterUrl,
  makeLinkValue,
  makeNavigationUrl,
  urlPattern,
  makeSingleChapterUrl,
} from "./URLUtils";
import { getMenuItemsAsList, menuItems } from "./MenuItems";
import { findNode, findTargetId } from "./Utils";
import { getBookId } from "../shared/utils/content";
import { MENU_ITEMS_LOCATIONS } from "../shared/utils/menu";
import { BooksFiltersItemsIds } from "./FilterBooksUtils";
import { isShowParaLevelByBookRealType } from "./BookUtils";
import { URLS } from "../shared/utils/url";

const ReaderType = {
  egwwritings: "egwwritings",
  bible: "bible",
  apl: "apl",
  reference: "reference",
};

const mobileLabels = {
  [URLS.themeMode]: "colorMode",
  [URLS.subscriptionAdd]: "subscriptions_add",
  [URLS.subscriptionAdd + "/book"]: "subscriptions_add",
  [URLS.subscriptionAdd + "/devotional"]: "subscriptions_add",
  [URLS.subscriptionAdd + "/bible"]: "subscriptions_add",
};

export const RouteType = {
  folders: "folders",
  books: "books",
  panel: "panel",
};

export const getRealType = (list, fieldCheck = "className") => {
  let realType = "";

  list.forEach((item) => {
    const itemType = item[fieldCheck];

    if (itemType && itemType === ReaderType[itemType]) {
      realType = itemType;
    }
  });

  return realType;
};

const menuList = getMenuItemsAsList(MENU_ITEMS_LOCATIONS.BREADCRUMBS);
const menuListAll = getMenuItemsAsList();

export const main = {
  label: "main",
  id: URLS.home,
  children: [...menuList],
};

export const openNewTab = (id) => {
  if (urlPattern.test(id)) {
    window.open(id, "_blank");
  } else {
    window.open(makeNavigationUrl(id), "_blank");
  }
};

export const downloadFile = (uri) => {
  const fileName = uri.substr(uri.lastIndexOf("/") + 1);
  let link = document.createElement("a");
  link.download = fileName;
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const createPanelId = (id, panelIds, firstParaId) => {
  const bookIndex = findBookIndex(panelIds, id);
  const { type, value } = getLinkValue(id);
  if (!type) {
    const linkType = bookIndex !== -1 ? PanelTypes.READ.id : PanelTypes.PARA.id;
    return makeLinkValue(linkType, id);
  } else if (type === PanelTypes.BOOK.id) {
    // TODO avoid using ".1"
    const paraId = firstParaId || value + ".1";
    const linkType =
      findBookIndex(panelIds, paraId) !== -1 ? PanelTypes.READ.id : PanelTypes.PARA.id;
    return makeLinkValue(linkType, paraId);
  }
  return id;
};

/**
 * @description finish dragndrop and make a navigation url
 * with updated params that is layout is based on
 * @param {String} dragndropId - panel id that is currently dragged
 * @param {String} overId - panel id that is being dragged over
 * @param {Array} panelIds current panel ids from the navigation params
 * @returns {String} new url with updated params
 */
export const finishDragNDrop = (dragndrop, panelIds) => {
  const { id: dragndropId, overId, isAddingPanel, firstParaId } = dragndrop;
  const newPanelId = createPanelId(dragndropId, panelIds, firstParaId);
  const updatedPanelIds = [...panelIds];
  if (!overId) {
    if (panelIds.length === 0) {
      if (firstParaId) {
        return makeSingleChapterUrl(firstParaId);
      }
      return makeChapterUrl([newPanelId]);
    }
    return undefined;
  }
  if (isAddingPanel) {
    updatedPanelIds.unshift(NEW_PANEL_ID);
    const addingPanelIndex = updatedPanelIds.indexOf(NEW_PANEL_ID);
    const overIdIndex = updatedPanelIds.indexOf(overId);
    // put overIdIndex into addingPanelIndex
    updatedPanelIds[addingPanelIndex] = updatedPanelIds[overIdIndex];
    // put newPanelId into overIdIndex
    updatedPanelIds[overIdIndex] = newPanelId;
  } else {
    // swap
    const dragndropIndex = updatedPanelIds.indexOf(dragndropId);
    const overIdIndex = updatedPanelIds.indexOf(overId);
    const temp = updatedPanelIds[overIdIndex];
    updatedPanelIds[overIdIndex] = newPanelId;
    // might be -1 if adding new book, but there is no space for new panel to add
    if (dragndropIndex !== -1) {
      updatedPanelIds[dragndropIndex] = temp;
    }
  }
  return makeChapterUrl(updatedPanelIds);
};

export const filterAudioContent = (nodeList) => {
  const audioNodes = [];
  nodeList.forEach((item) => {
    audioNodes.push({
      ...item,
      children: (item.children || []).filter((child) => {
        return (
          !child.className ||
          child.totalAudioCount ||
          child.nAudioBooks ||
          child.audiobook_count ||
          child.is_audiobook
        );
      }),
    });
  });
  return audioNodes;
};

export const getTarget = (mainTree, pathname, libraryLanguages, isLogin, isAudioQuery) => {
  let currentFolder = menuItems.page404;
  let nodeList = [];
  let parts = pathname.split("/");
  let targetId = parts[parts.length - 1];
  let isAudioBooks = false;

  const firstPart = "/" + parts[1];
  let itemMenu = menuListAll.find((item) => firstPart === item.id);
  if (itemMenu) {
    if (itemMenu.id === pathname) {
      targetId = itemMenu.id;
    }
    currentFolder = { ...itemMenu };
  } else {
    currentFolder = menuItems.page404;
  }
  if (firstPart === URLS.book) {
    return getBookNavigation(targetId, isLogin, isAudioQuery, mainTree, libraryLanguages);
  } else if (pathname === URLS.home) {
    currentFolder = main;
  } else if (currentFolder) {
    const targetParse = parseInt(targetId, 10);
    if (!isNaN(targetParse)) {
      targetId = targetParse;
    }
    if (currentFolder.id === URLS.allCollection) {
      if (isLogin) {
        currentFolder.children = mainTree.filter((elem) => libraryLanguages.includes(elem.code));
      } else {
        currentFolder.children = mainTree;
      }
      if (targetId === currentFolder.id) {
        nodeList.push(main);
        nodeList.push(currentFolder);
      } else {
        const parent = currentFolder;
        // "nodeList" array gets trace nodes during "findNode".
        currentFolder = findNode(targetId, mainTree, "id", nodeList);
        nodeList.push(parent);
        nodeList.push(main);
        nodeList.reverse();
      }
    } else if (currentFolder.id === URLS.audioBooks) {
      currentFolder.children = mainTree;
      if (targetId === currentFolder.id) {
        nodeList.push(main);
        nodeList.push(currentFolder);
      } else {
        const parent = currentFolder;
        // "nodeList" array gets trace nodes during "findNode".
        currentFolder = findNode(targetId, currentFolder, "id", nodeList);
        nodeList.push(parent);
        nodeList.push(main);
        nodeList.reverse();
        nodeList = filterAudioContent(nodeList);
      }

      isAudioBooks = true;
    } else {
      nodeList.push(main);
      nodeList.push(currentFolder);
    }
  }

  let routeType = RouteType.panel;

  if (currentFolder) {
    const folderId = currentFolder.id;
    if (
      (folderId && (folderId === URLS.allCollection || folderId === URLS.audioBooks)) ||
      pathname === "/" ||
      currentFolder.className === "language" ||
      currentFolder.nbooks === 0
    ) {
      routeType = RouteType.folders;
    } else if (currentFolder.nbooks > 0 || folderId === URLS.history) {
      routeType = RouteType.books;
      // fixme: Need to think how to put this in the {Books.js}
      //  as it's done for categories and titles.
      currentFolder.showBtnBooksFilter = true;
      currentFolder.idsHiddenFilters = [BooksFiltersItemsIds.languages, BooksFiltersItemsIds.years];

      if (isAudioBooks) {
        currentFolder.idsHiddenFilters.push(BooksFiltersItemsIds.format);
      }
    }
  }

  const realType = getRealType(nodeList);

  return {
    targetId,
    currentFolder,
    nodeList,
    realType,
    routeType,
  };
};

export const getLabels = (t, currentFolder, pathname) => {
  let label = t("main");
  if (currentFolder) {
    if (currentFolder.label) {
      label = t(currentFolder.label);
    }
    if (currentFolder.className) {
      label = currentFolder.label;
    }
    if (!currentFolder.id) {
      label = "";
    }
  }

  const mobileLabel = mobileLabels[pathname];
  if (mobileLabel) {
    label = t(mobileLabel);
  }

  return label;
};

const getBookNavigation = (bookId, isLogin, isAudio, mainTree, libraryLanguages) => {
  let nodeList = [];
  const currentFolder = findNode(bookId, mainTree, "id", nodeList);
  let tree = mainTree;
  if (isLogin && !isAudio) {
    tree = mainTree.filter((elem) => libraryLanguages.includes(elem.code));
  }

  const parent = isAudio ? menuItems.audioBooks : menuItems.allCollection;
  nodeList.push({
    ...parent,
    children: tree,
  });
  nodeList.push(main);
  nodeList.reverse();

  if (isAudio) {
    nodeList = filterAudioContent(nodeList);
  }

  const realType = getRealType(nodeList);

  return { currentFolder, nodeList, realType, targetId: bookId };
};

export const useBookNavigation = (bookId, isAudio) => {
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const isLogin = useSelector((state) => state.system.isLogin);
  return getBookNavigation(bookId, isLogin, isAudio, mainTree, libraryLanguages);
};

export const getReaderNavigation = (
  paraId,
  mainTree,
  bookContent = [],
  libraryLanguages,
  isLogin,
) => {
  let targetId,
    book,
    nodeList = [];

  const para = findNode(paraId, mainTree);
  if (!para) {
    const bookId = getBookId(paraId);
    book = findNode(bookId, mainTree, "id", nodeList);
    const [, paraValue] = paraId.split(".");
    if (paraValue === "1" && book.children) {
      targetId = book.children[0].id;
    } else {
      const findPara = bookContent.find((item) => item.id === paraId);
      if (findPara && book.chapters) {
        targetId = findTargetId(findPara, book.chapters, bookId);
      }
    }
  } else {
    targetId = para.id;
  }

  let currentFolder = menuItems.loading;
  if (targetId) {
    nodeList = [];
    currentFolder = findNode(targetId, mainTree, "id", nodeList);
    const nodeBook = nodeList.find((item) => item.className === "book");

    if (
      !isShowParaLevelByBookRealType(nodeBook?.realType) &&
      currentFolder.className === "para" &&
      nodeList[1].className === "chapter"
    ) {
      // prevent para be inside {nodeList} and be {currentFolder}
      nodeList.splice(0, 1);
      currentFolder = nodeList[0];
    }
  } else if (book) {
    currentFolder = book;
  }

  let tree = mainTree;
  if (isLogin) {
    tree = mainTree.filter((elem) => libraryLanguages.includes(elem.code));
  }
  nodeList.push({
    ...menuItems.allCollection,
    children: tree,
  });
  nodeList.push(main);
  nodeList.reverse();

  const realType = getRealType(nodeList);

  return {
    nodeList,
    isReader: true,
    targetId,
    realType,
    currentFolder,
  };
};
