import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { findNode } from "../../utils/Utils";
import { titlesActions } from "../../redux/titleTree/actions";

export const useBookTranslationsBasedOnTitleTree = (nBookId) => {
  const dispatch = useDispatch();
  const titleTreeConverted = useSelector((state) => state.titleTree.treeConverted);

  useEffect(() => {
    if (!titleTreeConverted.length) {
      dispatch(titlesActions.fetchConvertSortTree());
    }
  }, [titleTreeConverted.length]);

  const [titleTreeBookOriginal, bookLang] = useMemo(() => {
    const nodePath = [];
    const bookLang = findNode(nBookId, titleTreeConverted, "book_id", nodePath)?.lang;

    let book = nodePath.shift();
    while (book) {
      if (book.book_id && book.book_id_en && book.book_id === book.book_id_en) {
        break;
      }

      book = nodePath.shift();
    }

    return [book, bookLang];
  }, [titleTreeConverted, nBookId]);

  const bookTranslations = useMemo(() => {
    const titleTreeBookOriginalChildren = titleTreeBookOriginal?.children || [];
    let titleBooks = [];

    let child;
    for (let i = 0; i < titleTreeBookOriginalChildren.length; i++) {
      child = titleTreeBookOriginalChildren[i];

      if (child.lang === bookLang) {
        if (child.children?.length > 1) {
          titleBooks.push(...child.children.filter((book) => book.book_id !== nBookId));
        }
      } else {
        if (child.children?.length) {
          titleBooks.push(...child.children);
        } else {
          titleBooks.push({ ...child, title: child.bookTitle });
        }
      }
    }

    return titleBooks;
  }, [nBookId, bookLang, titleTreeBookOriginal?.children]);


  return { titleTreeBookOriginal, bookTranslations };
};
