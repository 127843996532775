import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EGWLogo } from "../assets/CommonIcons";
import { HideIcon, Scroll, LinkButton } from "../components/views";
import { useViewMode } from "../hooks";
import { actionUpdateShowTextModePopup } from "../redux/params.actions";
import { DATA_ACQUISITION_STATUS } from "../utils/Utils";
import { getTextAppHost, makePublicUrl } from "../shared/utils/url";
import "./textModePage.scss";

const googlePlaySrc = makePublicUrl("/images/google-play.png");
const appStoreSrc = makePublicUrl("/images/app-store.png");

const TextModePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataAcquisitionStatus = useSelector((state) => state.system.dataAcquisitionStatus);
  const showTextModePopup = useSelector((state) => state.settings.showTextModePopup);
  const { textMode } = useViewMode();
  const [isShowTextModePage, setIsShowTextModePage] = useState(false);

  useEffect(() => {
    if (dataAcquisitionStatus === DATA_ACQUISITION_STATUS.LOW && !textMode) {
      setIsShowTextModePage(true);
    }
  }, [dataAcquisitionStatus, textMode]);

  if (!(isShowTextModePage || showTextModePopup)) {
    return null;
  }

  return (
    <div className="text-mode-page">
      <div className="header-page">
        <HideIcon
          onClick={() => {
            setIsShowTextModePage(false);
            dispatch(actionUpdateShowTextModePopup(false));
          }}
        />
      </div>
      <div className="container">
        <div className="logo-container">
          <Link to="/" className="logo-top-container">
            <span className="logo-content">
              <EGWLogo className="logo" />
              <span className="logo-content-text">
                <span>writings</span>
                <span className="logo-content-copyright">&reg;</span>
              </span>
            </span>
          </Link>
          <span className="logo-container-links">
            <a
              href="https://whiteestate.org/"
              className="logo-link"
              target="_blank"
              rel="noopener noreferrer">
              whiteestate.org
            </a>
            {" | "}
            <a
              href="http://ellenwhite.org/"
              className="logo-link"
              target="_blank"
              rel="noopener noreferrer">
              ellenwhite.org
            </a>
          </span>
        </div>

        <Scroll autoHeight autoHeightMax="65vh">
          <div className="content-container">
            <div className="title">{t("textModePageTitle")}</div>
            <p>{t("try_switching_to_text_mode")}</p>
            <LinkButton
              link={getTextAppHost()}
              external
              newTab={false}
              className="text-mode-button"
              label={t("switch_to_text_mode")}
            />
            <div className="footer-container">
              <p dangerouslySetInnerHTML={{ __html: t("or_download_our_apps") }} />
              <div className="btn-group">
                <a
                  className="app-sore-button"
                  href="https://apps.apple.com/us/app/egw-writings-2/id994076136"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={appStoreSrc} alt={t("appleStoreButton")} />
                </a>
                <a
                  className="google-play-button"
                  href="https://play.google.com/store/apps/details?id=com.whiteestate.egwwritings"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={googlePlaySrc} alt={t("googlePlayButton")} />
                </a>
              </div>
            </div>
          </div>
        </Scroll>
      </div>
    </div>
  );
};

TextModePage.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default TextModePage;
