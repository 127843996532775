import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "../views";
import { ShareIcon } from "../../assets/CommonIcons";
import { useShareContext } from "../shareHolder/ShareHolder";

const SHARE_ID = "header-share";

const ShareMenu = () => {
  const { t } = useTranslation();
  const { showShare, sharerId } = useShareContext();

  return (
    <IconButton
      title={t("share")}
      active={SHARE_ID === sharerId}
      bordered
      className='header-share'
      icon={ShareIcon}
      onClick={(e) => {
        showShare({
          sharerId: SHARE_ID,
          shareData: {
            title: "EGW Writings",
            url: ""
          },
          bounds: e.target.getBoundingClientRect()
        });
      }} />
  );
};

export default ShareMenu;
