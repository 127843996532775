/* eslint-disable no-console */
import { put, call, select, takeLatest, all, delay } from "redux-saga/effects";
import { actionLastOpenML } from "./library.actions";
import {
  findBookIndex,
  getLinkValue,
  makeChapterUrl,
  makeLinkValue,
  makeNavigationUrl,
  PanelTypes,

} from "../utils/URLUtils";
import {
  ACTION_NAVIGATE_BY_ID,
  ACTION_UPDATE_SETTING,
  ACTION_UPDATE_SETTINGS,
  ACTION_CREATE_WORKSPACE,
  ACTION_DELETE_WORKSPACE,
  ACTION_UPDATE_THEME,
  ACTION_COPY,
  ACTION_LOGOUT,
  ACTION_UPDATE_WORKSPACE,
} from "./constants";
import { isAfter, parseISO } from "date-fns";
import { getLayouts } from "../components/reader/layouts/LayoutUtils";
import { DelayOpenML, findNode} from "../utils/Utils";
import {
  getDefaultSize,
  getScreenType,
  getSizeValue,
  SearchFontSizes,
  TreeFontSizes,
  ZoomValues,
} from "../utils/ThemeUtils";
import {
  actionAddMessage,
  actionUpdateSettings,
  actionUpdateSettingSuccess,
  actionSetNextRoute,
} from "./actions";
import { actionChangeLang } from "./params.actions";
import { Settings, storeSetting } from "../utils/Settings";
import {
  getChapterContentRequest,
  getContentPreviewRequest,
  saveSettingsRequest,
} from "../api/API";
import { copyText, COPY_FORMATS, transformSelectionWithOptions } from "../utils/CopyUtil";
import { TOAST_TYPE } from "../components/popup/Toaster";
import { logout } from "../utils/SystemUtils";
import { DEF_READER_FONT, DEF_READER_FONT_BLIND_MODE } from "../components/reader/constants";
import { bookIdRegexStrict, CONTENT_CLASSES, getBookId } from "../shared/utils/content";
import { getTheme, ReaderFontSizes } from "../shared/utils/theme";
import { DEFAULT_LANGUAGE } from "../shared/utils/i18n";
import { StudyCenterActions } from "src/components/studyCenter/studyCenter.actions";
import { SearchActions } from "src/components/search/search.actions";
import { isUserEditor } from "src/components/studyCenter/EditorCenterUtils";
import { makeChapterContent, JTLBooks } from "src/utils/ContentUtils";
import { URLS } from "../shared/utils/url";
import { getSystemLang } from "../shared/utils/user-agent";

const TAG = "SystemSaga_";

function* _getBookData(bookId, withHistory) {
  const { allHistory } = yield select((state) => state.history);
  const { mainTree } = yield select((state) => state.mainTree);
  const { value } = getLinkValue(bookId);
  let targetParaId;
  if (withHistory) {
    const historyItem = allHistory.find((item) => item.id === bookId);
    if (historyItem) {
      targetParaId = historyItem.paraId;
    }
  }
  if (!targetParaId) {
    const book = findNode(bookId, mainTree);
    if (book?.chapters) {
      const firstChapter = book?.chapters?.[0];
      if (firstChapter) {
        targetParaId = firstChapter.id;
      }
    } else if (book?.first_para) {
      targetParaId = book.first_para;
    } else {
      //TODO impl load chapters. May be not needed cuz first_para always must exist

      targetParaId = value + ".1";
    }
  }

  return targetParaId;
}

function* _getLinksForBooks(paraId) {
  const paraIds = [makeLinkValue(PanelTypes.PARA.id, paraId)];
  try {
    let linkBible, linkBook;
    let count = 0;
    let nextParaId = paraId;
    let paragraph;
    while ((!(linkBible && linkBook) && nextParaId) || count < 5) {
      const chapterResponse = yield call(getChapterContentRequest, nextParaId);
      if (chapterResponse) {
        const content = makeChapterContent(chapterResponse);
        for (let i = 0; i < content.length; i++) {
          paragraph = content[i];
          const firstLink = paragraph.links?.[0];
          if (firstLink) {
            if (!linkBible && firstLink.type === CONTENT_CLASSES.EGW_LINK_BIBLE) {
              linkBible = firstLink.link;
            }
            if (!linkBook && firstLink.type === CONTENT_CLASSES.EGW_LINK) {
              linkBook = firstLink.link;
            }
          }
          if (linkBible && linkBook) {
            break;
          }
        }
        nextParaId = content[content.length - 1]?.id_next;
      }
      count++;
    }

    if (linkBible) {
      let bibleId = linkBible;
      const { baseBible, jtlBaseBible } = yield select((state) => state.settings);
      const targetBibleId = jtlBaseBible || baseBible;
      const bibleLinkId = getBookId(linkBible);
      if (bibleLinkId !== targetBibleId) {
        const bibleTranslate = yield call(getContentPreviewRequest, linkBible, [], "bible");
        if (bibleTranslate) {
          const baseBiblePara = bibleTranslate.find((item) => item.bookId === targetBibleId);
          if (baseBiblePara) {
            bibleId = baseBiblePara.paraId;
          }
        }
      }
      paraIds.push(makeLinkValue(PanelTypes.JTL_BIBLE.id, bibleId));
    }

    if (linkBook) {
      paraIds.push(makeLinkValue(PanelTypes.JTL_BOOK.id, linkBook));
    }
  } catch (error) {
    console.log(TAG + "*_getLinksForBooks", error);
  }

  return paraIds;
}

/**
 * need for make navigation with async event e.g loading data of book, history, etc.
 *
 *
 *  */
function* navigateByIdMethod(action) {
  yield delay(100);
  const {
    id,
    className,
    newWindow,
    panelType,
    lang,
    fromTop,
    fromSC,
    panelIds,
    readerWidth,
    readerHeight,
    MIN_PANEL_WIDTH,
    MIN_PANEL_HEIGHT,
    isAudio,
    isLibrary,
    isHistory,
    withHistory,
    isMobile,
    allowReplace,
    replacePanelIndex,
  } = action.data;

  const { allHistory, lastTimeOpenML } = yield select((state) => state.history);
  const { libraryLanguages, openLastBook } = yield select((state) => state.settings);
  const { currentEntryId } = yield select((state) => state.studyCenter);
  const { allShopBooks } = yield select((state) => state.shop);
  if (!fromSC) {
    yield put(StudyCenterActions.setCurrentSCElement());
  }
  if (id === URLS.allCollection) {
    let newUrl = URLS.allCollection;
    if (libraryLanguages.length === 1) {
      newUrl = URLS.allCollection + "/" + libraryLanguages[0];
    }
    yield put(actionSetNextRoute(newUrl));
  } else if (id === URLS.myLibrary && !fromTop) {
    if (openLastBook) {
      if (lastTimeOpenML) {
        if (Date.now() - lastTimeOpenML > DelayOpenML) {
          yield put(actionLastOpenML(Date.now()));
        } else {
          yield put(actionSetNextRoute(id));
          return;
        }
      } else {
        yield put(actionLastOpenML(Date.now()));
      }
      const library = allHistory.filter((item) => item.mlRead);
      library.sort((a, b) => (isAfter(parseISO(a.luRead), parseISO(b.luRead)) ? -1 : 1));
      const latestBook = library[0];
      //TODO check panelIds for open ML if reader on screen
      if (latestBook && panelIds.length === 0) {
        yield put(actionSetNextRoute(makeNavigationUrl(latestBook.paraId)));
      }
    } else {
      yield put(actionSetNextRoute(id));
    }
  } else if (newWindow) {
    let newUrl;
    if ([CONTENT_CLASSES.CHAPTER, CONTENT_CLASSES.PARAGRAPH].indexOf(className) !== -1) {
      let paraId;

      if (bookIdRegexStrict.test(id)) {
        const bookParaId = yield call(_getBookData, id, withHistory);
        paraId = makeLinkValue(PanelTypes.PARA.id, bookParaId);
      } else {
        paraId = makeLinkValue(PanelTypes.PARA.id, id);
      }

      const bookId = getBookId(id);

      if (JTLBooks.includes(bookId)) {
        const panels = yield call(_getLinksForBooks, id);
        newUrl = makeChapterUrl(panels);
      } else {
        // Check is purchased book or not. if not - open book info
        const shopBook = allShopBooks.find((item) => bookId === item.id);
        if (shopBook && !shopBook.is_purchased) {
          newUrl = makeNavigationUrl({ id: bookId, className: CONTENT_CLASSES.BOOK });
        } else {
          if (isUserEditor()) {
            const editorPanel = makeLinkValue(PanelTypes.EDITOR.id, currentEntryId || "");
            newUrl = makeChapterUrl([paraId, editorPanel]);
          } else {
            newUrl = makeChapterUrl([paraId]);
          }
        }
      }
    } else {
      newUrl = makeNavigationUrl({ id, className, lang }, { isAudio, isHistory, isLibrary });
    }
    yield put(actionSetNextRoute(newUrl));
  } else if (className) {
    let targetParaId = id;
    if (className === CONTENT_CLASSES.BOOK) {
      targetParaId = yield call(_getBookData, id, withHistory);
    }
    const existBookIndex = findBookIndex(panelIds, targetParaId);

    let type = panelType ?? (existBookIndex !== -1 ? PanelTypes.READ.id : PanelTypes.PARA.id);
    let newActiveId = makeLinkValue(type, targetParaId);

    let newParaIds = [...panelIds];

    if (replacePanelIndex !== undefined) {
      newActiveId = makeLinkValue(type, targetParaId, targetParaId);
      newParaIds[replacePanelIndex] = newActiveId;
    } else if (allowReplace && existBookIndex !== -1) {
      const { type } = getLinkValue(newParaIds[existBookIndex]);
      panelIds[existBookIndex] = makeLinkValue(type, targetParaId);
    } else {
      let isCanAddMoreSplit;
      if (isMobile && panelIds.length >= 2) {
        isCanAddMoreSplit = false;
      } else if (readerWidth && readerHeight) {
        isCanAddMoreSplit =
          getLayouts(
            { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT },
            panelIds.length + 1,
            readerWidth,
            readerHeight,
          ).length > 0;
      }
      if (panelIds.length === 0) {
        newParaIds = [newActiveId];
      } else if (!isCanAddMoreSplit) {
        newParaIds = [...panelIds];
        newParaIds[panelIds.length - 1] = newActiveId;
      } else {
        newParaIds = [...panelIds, makeLinkValue(type, targetParaId)];
      }
    }

    let newActiveIndex = newParaIds.indexOf(newActiveId);
    const newUrl = makeChapterUrl(newParaIds, newActiveIndex);
    yield put(actionSetNextRoute(newUrl, {
      push: true,
    }));
  }
}

function* updateThemeMethod(action) {
  const {
    data: { theme, isMobile },
  } = action;
  const currentTheme = yield select((state) => state.settings.themeMode);
  const themeObj = getTheme(theme);
  const currentThemeObj = getTheme(currentTheme);
  const isAccessibilityMode = themeObj.accessibility;
  const needResetZoom = !isAccessibilityMode && currentThemeObj.accessibility;
  const settings = {};
  if (needResetZoom) {
    //TODO implement get window size outsize saga
    settings.zoom = getDefaultSize(ZoomValues);
    settings.searchFontSize = SearchFontSizes.default;
    settings.treeFontSize = TreeFontSizes.default;
    settings.readerFontSize = ReaderFontSizes.default;
    settings.readerFontFamily = DEF_READER_FONT;
  } else if (isAccessibilityMode) {
    const screenType = getScreenType();
    settings.zoom = getSizeValue(ZoomValues, "blindDefault", screenType);
    settings.searchFontSize = getSizeValue(SearchFontSizes, "blindDefault", screenType);
    settings.treeFontSize = getSizeValue(TreeFontSizes, "blindDefault", screenType);
    settings.readerFontSize = getSizeValue(ReaderFontSizes, "blindDefault", screenType);
    settings.readerFontFamily = DEF_READER_FONT_BLIND_MODE;
  }

  if (!isMobile) {
    // TODO make better logic
    const newSettings = {};
    if (settings.zoom) {
      newSettings[Settings.zoom.id] = settings.zoom;
    }
    if (settings.treeFontSize) {
      newSettings[Settings.treeFontSize.id] = settings.treeFontSize;
    }
    if (settings.searchFontSize) {
      newSettings[Settings.searchFontSize.id] = settings.searchFontSize;
    }
    if (settings.readerFontSize) {
      newSettings[Settings.readerFontSize.id] = settings.readerFontSize;
    }
    if (settings.readerFontFamily) {
      newSettings[Settings.readerFontFamily.id] = settings.readerFontFamily;
    }
    yield put(
      actionUpdateSettings({
        [Settings.themeMode.id]: theme,
        ...newSettings,
      }),
    );
  } else {
    yield put(
      actionUpdateSettings({
        [Settings.themeMode.id]: theme,
        [Settings.zoom.id]: settings.zoom || 100,
      }),
    );
  }
}

function* updateSettingsMethod(action) {
  try {
    const { data } = action;
    const { isLogin } = yield select((state) => state.system);

    const settingsList = {};
    const serverSettingsList = {};

    for (let key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const value = data[key];
        const setting = storeSetting(key, value);
        if (setting) {
          if (!settingsList[key]) {
            settingsList[key] = value;
          }
          if (setting.action) {
            setting.action(value);
          }
          if (isLogin && setting.server) {
            serverSettingsList[key] = value;
          }
        }
      }
    }
    yield put(actionUpdateSettingSuccess(settingsList));

    if (Object.keys(serverSettingsList).length) {
      yield call(saveSettingsRequest, serverSettingsList);
    }
  } catch (error) {
    console.log("function*updateSettingsMethod -> error: ", error);
  }
}

function* createWorkspaceMethod(action) {
  const { workspaces } = yield select((state) => state.settings);
  const { title, id, ids, layoutId } = action.data;
  workspaces.push({
    title: title || "",
    id,
    ids,
    layoutId,
    created: new Date().toISOString(),
  });

  yield put(
    actionUpdateSettings({
      [Settings.workspaces.id]: [...workspaces],
      [Settings.activeWorkspace.id]: id,
    }),
  );
}

function* updateWorkspaceMethod(action) {
  const { workspaces } = yield select((state) => state.settings);
  const data = action.data;
  const newWorkspaces = [...workspaces];
  const index = newWorkspaces.findIndex((item) => item.id === data.id);

  newWorkspaces[index] = { ...newWorkspaces[index], ...data };
  yield put(actionUpdateSettings({ [Settings.workspaces.id]: [...newWorkspaces] }));
}

function* deleteWorkspaceMethod(action) {
  const { workspaces, activeWorkspace, lastEditWorkspace } = yield select(
    (state) => state.settings,
  );
  const id = action.data;
  const newWorkspaces = [...workspaces];
  const index = newWorkspaces.findIndex((item) => item.id === id);

  newWorkspaces.splice(index, 1);
  yield put(actionUpdateSettings({ [Settings.workspaces.id]: [...newWorkspaces] }));

  if (activeWorkspace === id) {
    yield put(actionUpdateSettings({ [Settings.activeWorkspace.id]: "" }));
  }

  if (lastEditWorkspace === id) {
    yield put(actionUpdateSettings({ [Settings.lastEditWorkspace.id]: "" }));
  }
}

function* copyWorker(action) {
  const { text, options } = action.data;
  const { entries } = yield select((state) => state.studyCenter);
  const { paragraphs } = yield select((state) => state.paragraphReducer);
  const copyObjFinal = transformSelectionWithOptions(text, options, paragraphs, entries);

  const result = yield call(copyText, copyObjFinal.html, {
    format: COPY_FORMATS.TEXT_HTML,
    textPlain: copyObjFinal.text,
  });

  if (result) {
    yield put(actionAddMessage(options?.successMessage || "@textCopied"));
  } else {
    yield put(actionAddMessage(options?.errorMessage || "@textNotCopied", TOAST_TYPE.error));
  }
}

function* logoutMethod() {
  logout();
  // WORKAROUND WAITING FOR CLEAR SETTINGS
  yield delay(200);
  const systemLang = getSystemLang(DEFAULT_LANGUAGE);
  yield put(SearchActions.changeRelatedLang(systemLang));
  yield put(actionChangeLang({ lang: systemLang, initCheck: true }));
}

export default function* systemSaga() {
  yield all([
    takeLatest(ACTION_NAVIGATE_BY_ID, navigateByIdMethod),
    takeLatest(ACTION_UPDATE_THEME, updateThemeMethod),
    takeLatest(ACTION_UPDATE_SETTING, updateSettingsMethod),
    takeLatest(ACTION_UPDATE_SETTINGS, updateSettingsMethod),
    takeLatest(ACTION_CREATE_WORKSPACE, createWorkspaceMethod),
    takeLatest(ACTION_UPDATE_WORKSPACE, updateWorkspaceMethod),
    takeLatest(ACTION_DELETE_WORKSPACE, deleteWorkspaceMethod),
    takeLatest(ACTION_COPY, copyWorker),
    takeLatest(ACTION_LOGOUT, logoutMethod),
  ]);
}
