import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { parseSearchUrl } from "../../utils/SearchUtils";
import { useNavigationData } from "../NavigationContext";
import { main } from "../../utils/RouterUtils";
import { menuItems } from "../../utils/MenuItems";
import { useDefLang } from "../../redux/selectors";
import { actionUpdateSetting } from "../../redux/actions";
import { useSearchList, useAuth, useViewMode } from "../../hooks";
import SearchPanel from "./SearchPanel";
import { Settings } from "../../utils/Settings";
import { useResizerContext } from "../resizer/Resizer";
import { logSearchEvent, SearchLogEvents } from "src/shared/api/analitics";
import { SearchActions } from "./search.actions";
import { getSearchTypeByPeriod, PeriodDefault } from "../../shared/utils/search";

import "./Search.scss";

const SearchPage = ({ location }) => {
  const { t } = useTranslation();
  const searchUrlQueryParams = useMemo(() => parseSearchUrl(location.search || ""), [
    location.search,
  ]);
  const dispatch = useDispatch();
  const defLang = useDefLang();
  const { isLogin } = useAuth();
  const { mainWidthInner, mainPartHeight } = useResizerContext();
  const { isMobile } = useViewMode();
  const searchParams = useSelector((state) => state.search.searchParams);
  const searchHistory = useSelector((state) => state.search.searchHistory);
  const searchBible = useSelector((state) => state.search.searchBible);
  const dictionaryFilter = useSelector((state) => state.search.dictionaryFilter);
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);

  const position = searchUrlQueryParams.start || 1;

  const { searchList, onSearchFromScratch, onSaveUrlPosition } = useSearchList({
    initPosition: position,
    currentSearchParams: searchParams,
  });

  // we should never see the /search page without rightPanel on mobile
  useEffect(() => {
    if (isMobile) {
      dispatch(actionUpdateSetting(Settings.isShowRightPanel.id, true));
    }
  }, [isMobile]);

  useEffect(() => {
    const searchUrlQueryParams = parseSearchUrl(location.search || "");
    if (searchUrlQueryParams && !isMobile) {
      const {
        query,
        books,
        chapters,
        folders,
        context,
        langs,
        extras,
        section,
        start,
        period,
        type,
        snippet,
        order,
        lang
      } = searchUrlQueryParams;
      let queryValue = query;
      const [lastSearch] = searchHistory;

      if (!query && isLogin && lastSearch) {
        queryValue = lastSearch?.query;
      }

      if (queryValue) {
        const periodValue = period || collectionFilter || PeriodDefault;
        const newSearchParams = {
          query: queryValue,
          extras: {
            ...extras,
            snippet,
            order,
            period: periodValue,
            type: getSearchTypeByPeriod(periodValue, type),
            langs,
            lang,
            section,
          },
        };
        if (context) {
          newSearchParams.context = context;
        }
        if (books) {
          newSearchParams.books = books;
        }
        if (chapters) {
          newSearchParams.chapters = chapters;
        }
        const folderList = folders || langs;
        if (folderList) {
          newSearchParams.folders = folderList;
        }

        dispatch(SearchActions.fetchSearch(newSearchParams, start));
      }
    }
  }, [searchBible, defLang, searchUrlQueryParams, dictionaryFilter, collectionFilter]);

  useNavigationData({
    label: t("searchResults"),
    nodeList: [main, menuItems.search],
  });

  return (
    <SearchPanel
      searchList={searchList}
      onChangePosition={(newPosition) => {
        if (position !== newPosition) {
          onSaveUrlPosition(newPosition);
          logSearchEvent(SearchLogEvents.SEARCH_LOAD_MORE, {
            index: newPosition,
          });
        }
      }}
      parentWidth={mainWidthInner}
      parentHeight={mainPartHeight}
      onSearchFromScratch={onSearchFromScratch}
      start={position}
    />
  );
};

SearchPage.propTypes = {
  location: PropTypes.object,
};

export default SearchPage;
