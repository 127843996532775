import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import BookListWithArrows from "./BookListWithArrows";
import { findNode } from "../../utils/Utils";
import { useViewMode } from "../../hooks";
import BookDetailsLabelSupremeInfo from "./BookDetailsLabelSupremeInfo";
import Accordion from "../views/Accordion/Accordion";

import "./AccordionBookTranslations.scss";

const AccordionBookTranslations = ({ book, bookTranslations }) => {
  const bookLang = book.lang;

  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const { isMobileOrTablet } = useViewMode();
  const isOpenInitially = !isMobileOrTablet;
  const [isOpen, setIsOpen] = useState(isOpenInitially);

  const mainTreeBookLang = useMemo(() => {
    return findNode(bookLang, mainTree);
  }, [mainTree, bookLang]);

  // Return to initial value on book change.
  useEffect(() => {
    setIsOpen(isOpenInitially);
  }, [book]);

  return (
    <Accordion
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isChevronSecondary
      className="accordion-book-translations"
      title={(
        <BookDetailsLabelSupremeInfo style={{ display: "inline-block", }}>
          <span className="accordion-book-translations_text-lang">
            {bookLang}
          </span>&nbsp;
          ({mainTreeBookLang?.label})
        </BookDetailsLabelSupremeInfo>
      )}
    >
      <div className="book-package">
        <BookListWithArrows books={bookTranslations} />
      </div>
    </Accordion>
  );
};

AccordionBookTranslations.propTypes = {
  book: PropTypes.object.isRequired,
  bookTranslations: PropTypes.array.isRequired,
};

export default AccordionBookTranslations;
