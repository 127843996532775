import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { DATA_ACQUISITION_STATUS } from "../utils/Utils";
import { actionUpdateDataAcquisitionStatus } from "../redux/actions";
import { isMobile } from "react-device-detect";
import { useViewMode } from "../hooks";
import { PreloadIcon } from "./views";

const minLoadingTimeInMilliseconds = {
  CONTACT_EMAIL: 10000,
  INIT: 10000,
  BOOK: 10000,
  BOOK_INFO: 10000,
  BOOK_CONTENT: 10000,
  FOLDER_LIST: 10000,
  BOOKS_LIST: 10000,
  CURRENT_FOLDER: 10000,
  SUBSCRIPTION_CONTENT: 10000,
  THOUGHT_CONTENT: 10000,
  NEWS_CONTENT: 10000,
  SEARCH_FILTER_CONTENT: 10000,
  HISTORY: 10000,
  SC_PREVIEW: 10000,
};

const LOADING_TYPES = {
  INIT: "INIT",
  BOOK: "BOOK",
  BOOK_INFO: "BOOK_INFO",
  BOOK_CONTENT: "BOOK_CONTENT",
  BOOK_DATA: "BOOK_DATA",
  FOLDER_LIST: "FOLDER_LIST",
  BOOKS_LIST: "BOOKS_LIST",
  BOOKS_IN_TRANSLATIONS: "BOOKS_IN_TRANSLATIONS",
  CURRENT_FOLDER: "CURRENT_FOLDER",
  SUBSCRIPTION_CONTENT: "SUBSCRIPTION_CONTENT",
  THOUGHT_CONTENT: "THOUGHT_CONTENT",
  NEWS_CONTENT: "NEWS_CONTENT",
  CONTACT_EMAIL: "CONTACT_EMAIL",
  SEARCH_FILTER_CONTENT: "SEARCH_FILTER_CONTENT",
  FEATURED_SEARCH_SECTIONS: "FEATURED_SEARCH_SECTIONS",
  HISTORY: "HISTORY",
  SC_PREVIEW: "SC_PREVIEW",
};

const Loading = ({ type, sizeSmall, align }) => {
  const dispatch = useDispatch();
  const { textMode } = useViewMode();

  useEffect(() => {
    const startTime = Date.now();

    return () => {
      const endTime = Date.now() - startTime;
      const minTime = minLoadingTimeInMilliseconds[type] || undefined;    
      if (minTime && !textMode) {
        if (endTime > minTime) {
          dispatch(actionUpdateDataAcquisitionStatus(DATA_ACQUISITION_STATUS.LOW));
        }
      }
    };
  }, []);

  const className = "loadingWrap" + (align ? " " + align : "");

  let size;

  if (sizeSmall) {
    size = 50;
  } else {
    if (isMobile) {
      size = 63;
    } else {
      size = 100;
    }
  }

  return (
    <div className={className}>
      <PreloadIcon size={size} />
    </div>
  );
};

Loading.propTypes = {
  type: PropTypes.string,
  sizeSmall: PropTypes.bool,
  align: PropTypes.oneOf(["flex-center"])
};

Loading.types = LOADING_TYPES;

export default Loading;
