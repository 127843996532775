import { arraymove, trimBr } from "./Utils";
import { getJsonAsCssString } from "./ThemeUtils";
import {
  bookPermission,
  CONTENT_CLASSES,
  convertAbbreviation as convertAbbreviationShared,
  convertAbbreviations as convertAbbreviationsShared,
  getBookId,
  makeChapterTree as makeChapterTreeShared,
  SubsPreffix,
} from "../shared/utils/content";
import { getBaseHost } from "src/shared/utils/url";
import { TREE_LABEL_HIDE_IN_TREE } from "./TreeUtils.constants";
import { isBookBibleByRealType } from "./BookUtils";

const bookTypes = ["bible", "apl"];

// JTL books must open with additional books in reader
export const JTLBooks = ["b14345", "b14347"];

export const NoSyncBooks = [
  "b14175", // KJVSD King James Bible With Strongs Dictionary
];

export const BIBLE_TYPE_CONCORDANCES = "bible-concordances";
export const BIBLE_TYPE_DICTIONARIES = "bible-dictionaries";

export const BibleTypes = [
  "bible-versions",
  BIBLE_TYPE_CONCORDANCES,
  BIBLE_TYPE_DICTIONARIES,
  "bible-commentaries",
  "bible-sdasi",
];

export const convertAbbreviations = convertAbbreviationsShared;

export const convertAbbreviation = convertAbbreviationShared;

export const convertBooks = (books) => {
  return books.map(convertBook);
};

const FirstCharRegEx = /^(the\s|a\s|an\s|from\s|"|\()(.*)/;

// TODO make fix for get only book char for first char
export const getFirstTitleChar = (value) => {
  let title = value.toLowerCase();
  let match = title.match(FirstCharRegEx);
  if (match && match[2]) {
    title = match[2];
  }
  let firstChar = title.trim().substr(0, 1);
  return firstChar;
};

export const convertBook = (item) => {
  let firstChar = getFirstTitleChar(item.title);
  let realType = item.folder_color_group || item.subtype || item.type;
  if (bookTypes.indexOf(item.type) !== -1) {
    realType = item.type;
  }

  const isForSale = item.permission_required === bookPermission.purchased;
  const { cover, ...otherBookProps } = item;
  const bookData = {
    ...otherBookProps,
    realType,
    label: item.title,
    className: "book",
    id: getBookId(item.book_id),
    pubYear: parseInt(otherBookProps.pub_year) || 0,
    firstChar,
    isForSale,
  };
  if (cover) {
    if (cover.small) {
      bookData.coverSmall = cover.small;
    }
    if (cover.large) {
      bookData.coverLarge = cover.large;
    }
  }

  return bookData;
};

export const convertFoldersToTree = (folders, lang) => {
  if (!folders) {
    return { totalCount: 0, totalAudioCount: 0, outList: [] };
  }
  let totalCount = 0;
  let totalAudioCount = 0;
  let outList = [];
  folders.forEach((item) => {
    let outChildren = convertFoldersToTree(item.children, lang);
    totalCount += item.nbooks;
    totalCount += outChildren.totalCount;
    totalAudioCount += item.naudiobooks;
    totalAudioCount += outChildren.totalAudioCount;
    outList.push({
      nbooks: item.nbooks,
      nAudioBooks: item.naudiobooks,
      value: item.folder_id,
      id: item.folder_id,
      label: item.name,
      className: item.add_class,
      totalCount: outChildren.totalCount,
      totalAudioCount: outChildren.totalAudioCount,
      children: outChildren.outList,
      lang,
    });
  });

  return { totalCount, totalAudioCount, outList };
};

export const makeChapterTree = makeChapterTreeShared;

const regExpHtmlElementStrong = /(<strong\b[^>]*>)[^<>]*(<\/strong>)/i;

const externalRefRE = /href\s*=\s*['"]https?:\/\/[^\s]+['"]/gis;

const addBlankTarget = (string) => {
  const matches = string.match(externalRefRE);

  if (matches) {
    return string.replace(externalRefRE, `target="_blank" ${matches[0]}'`);
  }

  return string;
};

const DataLinkRegex = /data-link="(.*?)"/gm;

export const getAllDataLink = (paragraphs) => {
  let links = [];
  let resItem;
  paragraphs.forEach((item) => {
    const result = item.content.match(DataLinkRegex);
    result.forEach((matchResult) => {
      resItem = matchResult.split('"')[1];
      if (!links.includes(resItem)) {
        links.push(resItem);
      }
    });
  });
  return links;
};

/**
 *
 * @param {Object[]} paras
 * @param {String} bookRealType
 * @param {Object} langMap
 * @param {*} bookType
 * @returns
 */
export const makeChapterContent = (paras, bookRealType, bookType) => {
  const paraIdParent = paras[0].para_id;
  const htmlElementDiv = document.createElement("div");

  const paragraphsParsed = paras.map((item, index) => {
    const outPara = {
      ...item,
      id: item.para_id,
      parent: paraIdParent, // maybe not used
      bookType: bookRealType,
      className: CONTENT_CLASSES.PARAGRAPH,
    };

    let firstLinkIndex = outPara.content.indexOf(CONTENT_CLASSES.EGW_LINK);

    outPara.content = addBlankTarget(outPara.content);
    // not needed parse again if make data from cache
    if (firstLinkIndex > -1 && !outPara.links) {
      const linkClassName = CONTENT_CLASSES.ICON_BIBLE + " icon-bible";
      htmlElementDiv.innerHTML = outPara.content;
      const allLinks = htmlElementDiv.getElementsByClassName(CONTENT_CLASSES.EGW_LINK);
      let links = [];
      let icon;
      Array.from(allLinks).forEach((link) => {
        if (link.classList.contains(CONTENT_CLASSES.EGW_LINK_BIBLE)) {
          icon = `<i class="${linkClassName}" data-link="${link.dataset.link}"></i>`;
          if (!link.innerText) {
            link.classList.add("icon-link-empty");
            link.innerHTML = `<i class="icon-arrow-right "></i>`;
          }
          link.insertAdjacentHTML("afterend", icon);
          link.removeAttribute("title");
          links.push({ type: CONTENT_CLASSES.EGW_LINK_BIBLE, link: link.dataset.link });
        } else {
          links.push({ type: CONTENT_CLASSES.EGW_LINK, link: link.dataset.link });
        }
      });
      outPara.content = htmlElementDiv.innerHTML;
      outPara.links = links;
    }

    /* === Bible has para level, thus "Tree" and "BC" labels should be parsed differently. === */
    let bcLabel = outPara.refcode_4 || outPara.refcode_short || index; // BC label

    if (isBookBibleByRealType(bookRealType)) {
      // [3360] e.g. replace "3,4,5" to "3-5" for "b2778"
      if (bookType === "scriptindex") {
        outPara.refcode_4 = outPara.refcode_4.replace(
          /([\d]+),.*,\s([\d]+)/g,
          (fullMatch, from, to) => {
            return from + " - " + to;
          },
        );
      }
      // ======================================

      // "BC" label
      if (outPara.refcode_2 && outPara.refcode_3 && outPara.refcode_4) {
        if (outPara.refcode_4 === "0") {
          bcLabel = outPara.refcode_2 + " " + outPara.refcode_3;
        } else {
          bcLabel = outPara.refcode_2 + " " + outPara.refcode_3 + ":" + outPara.refcode_4;
        }
      } else {
        bcLabel = outPara.refcode_long || outPara.refcode_1;
      }
      // ======================================

      // "Tree" label
      if (outPara.refcode_3 && outPara.refcode_4) {
        if (outPara.refcode_4 === "0") {
          outPara.treeLabel = outPara.refcode_3;
        } else {
          outPara.treeLabel = outPara.refcode_3 + ":" + outPara.refcode_4;
        }
      } else {
        outPara.treeLabel = outPara.refcode_short || outPara.refcode_1;
      }
      // ======================================

      // [3360][3365] e.g. remove unnecessary "h4" for "b2778"
      if (bookType === "scriptindex" && outPara.element_type === "h4") {
        outPara.treeLabel = TREE_LABEL_HIDE_IN_TREE;
        bcLabel = "";
        outPara.content = "";
      }
      // =================================================

      outPara.content = outPara.content.replace(regExpHtmlElementStrong, "");
    }
    /* ======================================================================================= */
    outPara.label = bcLabel;
    outPara.content = trimBr(outPara.content);

    return outPara;
  });

  if (htmlElementDiv.remove) {
    htmlElementDiv.remove();
  }

  return paragraphsParsed;
};

export const cutOutHeaders = (dateContent) => {
  dateContent.forEach((item) => {
    item.content = item.content.filter((tag) => !tag.includes("</h1>"));
  });
  return dateContent;
};

export const normalizeSubsContent = (content) => {
  return content.map((item) => ({
    ...item,
    baseId: item.id,
    id: SubsPreffix + item.id, //make special if for avoid endless tree rerender
    className: "subscription",
    label: item.book_title,
  }));
};

const getStyles = ({ showPageBreaks, showRefs, showHeaders }) => {
  // TODO can work wrong need check correct link
  const baseHost = getBaseHost();
  let content = `
    <link href="${baseHost}/fonts/egw-web-font.css" crossorigin="anonymous" rel="stylesheet">
    <style>
      body {
        font-size: 16px;
        padding: 1em;
        font-family: sans-serif;
      }

      .${CONTENT_CLASSES.PARAGRAPH} {
        font-style: normal;
        text-align: justify;
        font-weight: normal;
        line-height: 1.5em;
        margin-top: 0;
        color: var(--text-default);
        box-sizing: border-box;
        text-indent: 1em;
        padding-bottom: 0.5em;
        transition: 0.25s;
        position: relative;
      }

      .${CONTENT_CLASSES.EGW_LINK} {
        color: var(--text-link);
        cursor: pointer;
        text-decoration: underline;
      }

      h1, h2, h3, h4 {
        text-indent: 1em;
        color: var(--primary);
      }

      .${CONTENT_CLASSES.EGW_LINK_BIBLE} {
        color: var(--category-bible);
      }

      .sc-mark {
        font-size: 0.75em;
        user-select: none;
        cursor: pointer;
        color: var(--sc-icon);
      }
      
      .egw-bible-icon, .reader-translate-icon {
        display: none;
      }

      .${CONTENT_CLASSES.PAGE_NUMBER} {
        display: block;
        text-align: center;
        user-select: none;
        font-size: 0.75em;
        color: var(--primary);
        font-weight: bold;
        text-transform: uppercase;
        text-indent: 0;
      }
    </style>`;

  if (showPageBreaks) {
    content += `
      <style>
        .${CONTENT_CLASSES.PAGE_BREAK} {
          display: inline;
        }
        .${CONTENT_CLASSES.PAGE_BREAK} svg {
          width: 0.85em;
          height: 0.85em;
          fill: var(--icon-active);
        }
      </style>
    `;
  } else {
    content += `
     <style>
        .${CONTENT_CLASSES.PAGE_BREAK} { display: none; }
      </style>
    `;
  }

  if (showRefs) {
    content += `
      <style>
        .${CONTENT_CLASSES.REF_CODE} {
          color: var(--secondary);
          font-size: 14px;
          margin-left: 4px;
          display: inline;
        }
      </style>
    `;
  } else {
    content += `
      <style>
        .${CONTENT_CLASSES.REF_CODE} { display: none; }
      </style>
    `;
  }

  if (showHeaders) {
    content += `
      <style>
        .${CONTENT_CLASSES.READER_HEADER_TEXT} {
          display: block;
          text-indent: 1em;
          user-select: none;
          font-weight: bold !important;
          color: var(--text-paragraph) !important;
          line-height: 1.2em;
        }
      </style>
    `;
  } else {
    content += `
      <style>
        .${CONTENT_CLASSES.READER_HEADER_TEXT} { display: none; }
      </style>
    `;
  }

  return content;
};

export const printContent = (htmlContent, options = {}) => {
  const win = window.open();
  if (win) {
    // Variable can be absent if browser has blocked the "window.open()" action.
    win.document.write("<title>Print</title>");
    win.document.write(`<style>${getJsonAsCssString()}</style>`);
    win.document.write(getStyles(options));
    win.document.write(htmlContent);
    win.document.close(); // Avoid new window infinite loading.
    win.focus();
    win.print();
  }
};

export const makeMLLangDefaultFirst = (books, languageDefault) => {
  const defLangIndex = books.findIndex((book) => book.lang === languageDefault);
  if (defLangIndex > 0) {
    arraymove(books, defLangIndex, 0);
  }
  return books;
};
