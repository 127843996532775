import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import SearchPanel from "../search/SearchPanel";
import { useAuth, useSearchList, useViewMode } from "../../hooks"; 
import { isEventKey, isFocusTextInput, KeyCodes } from "../../shared/utils/dom";
import { HistoryPanel } from "../historySearch";
import { SearchActions } from "../search/search.actions";

import "../search/Search.scss";
import { URLS } from "../../shared/utils/url";

const AdvanceSearchRightPanel = ({ parentWidth, parentHeight }) => {
  const { isMobile } = useViewMode();
  const { isLogin } = useAuth(); 
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = history;
  const searchParams = useSelector((state) => state.search.searchParams);
  const shortcutContent = useSelector((state) => state.search.shortcutContent.results);
  const searchHistory = useSelector((state) => state.search.searchHistory);
  const rightPanelPosition = useSelector((state) => state.search.rightPanelPosition);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const localSearchParams = useSelector((state) => state.search.localSearchParams);
  

  const {
    searchList,
    onChangePosition,
    onSearchFromScratch, 
    onNavWithKeyboard,
  } = useSearchList({
    initPosition: rightPanelPosition,
    onUpdatePosition: SearchActions.setRightPanelPosition,
    isRightPanel: true,
    currentSearchParams: searchParams,
  });
 

  const { query } = searchParams;
  const isReader = location.pathname === URLS.read;
  const isNotSearch = location.pathname !== URLS.search;

  const isHistorySearch =
    isLogin &&
    !searchHistory.length !== 0 &&
    !searchQuery &&
    !localSearchParams.query &&
    isNotSearch &&
    !(isReader && query) && shortcutContent.length === 0;

  useEffect(() => {
    const onKeyupHandler = (event) => {
      if (isFocusTextInput()) {
        return;
      }
      if (!event.altKey && !(event.ctrlKey || event.metaKey)) {
        if (isEventKey(event, KeyCodes.plus)) {
          onNavWithKeyboard(1);
        } else if (isEventKey(event, KeyCodes.minus)) {
          onNavWithKeyboard(-1);
        }
      }
    };

    window.addEventListener("keyup", onKeyupHandler);

    return () => {
      window.removeEventListener("keyup", onKeyupHandler);
    };
  }, [onNavWithKeyboard]);
 

  if (isHistorySearch) {
    return <HistoryPanel />;
  }

  return (
    <SearchPanel
      rightPanel
      parentWidth={parentWidth}
      parentHeight={parentHeight}
      searchListClassName="right-panel-search-list"
      searchList={searchList}
      start={rightPanelPosition}
      onChangePosition={onChangePosition}
      onSearchFromScratch={onSearchFromScratch}
    />
  );
};

AdvanceSearchRightPanel.propTypes = {
  parentHeight: PropTypes.number,
  parentWidth: PropTypes.number,
};

export default AdvanceSearchRightPanel;
