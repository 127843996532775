import { isNumberBetween } from "../shared/utils/number";

export const makeFnGetFirstVisible = (
  scrollableView, isReverse, isHorizontal, checkFn, checkFnReverse
) => {
  if (!scrollableView) {
    return undefined;
  }
  const { children } = scrollableView;
  if (!children) {
    return undefined;
  }

  const keyOffsetElem = isHorizontal ? "offsetLeft" : "offsetTop";
  const keySize = isHorizontal ? "offsetWidth" : "offsetHeight";

  const wrapStart = Math.round(isHorizontal ? scrollableView.scrollLeft : scrollableView.scrollTop);
  const wrapEnd = wrapStart + scrollableView[keySize];

  let elem, elemStart;

  if (isReverse) {
    for (let i = children.length - 1; i >= 0; i--) {
      elem = children[i];
      elemStart = elem[keyOffsetElem];

      if (checkFnReverse(elemStart, wrapStart, elemStart + elem[keySize], wrapEnd, elem)) {
        return elem;
      }
    }
  } else {
    for (let i = 0; i < children.length; i++) {
      elem = children[i];
      elemStart = elem[keyOffsetElem];

      if (checkFn(elemStart, wrapStart, elemStart + elem[keySize], wrapEnd, elem)) {
        return elem;
      }
    }
  }


  return undefined;
};

export const getFirstVisible = (parent, isReverse, isHorizontal) => {
  return makeFnGetFirstVisible(parent, isReverse, isHorizontal,
    (elemStart, wrapStart, elemEnd) => {
      return isNumberBetween(wrapStart, elemStart, elemEnd);
    },
    (elemStart, wrapStart, elemEnd, wrapEnd) => {
      return isNumberBetween(wrapEnd, elemStart, elemEnd);
    },
  );
};
