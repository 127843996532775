/* eslint-disable no-console */
import { localStorageSafe } from "src/shared/utils/systemUtils";
import { joinObjArrays } from "src/utils/Utils";
import { isDevelopment } from "../shared/utils/assembly";

export const CACHE_LANG = "langCache";
export const CACHE_FOLDERS = "folderCache";
export const CACHE_READING_HISTORY = "readingHistory";
export const CACHE_LISTEN_HISTORY = "listenHistory";
export const CACHE_SEARCH_HISTORY = "searchHistory";
export const CACHE_LU_READING_HISTORY = "luReadingHistory";
export const CACHE_LU_LISTEN_HISTORY = "luListenHistory";
export const CACHE_SUBSCRIPTION_BOOKS = "subscriptionBooks";
export const CACHE_RELEVANT_FORMS = "relevantSearchForms";
export const CACHE_RELEVANT_OMSK_FORMS = "relevantSearchOmskForms";

export const makeFolderCacheName = (folderId) => "folder_" + folderId;
export const makeFolderCustomCacheName = (folderId) => "folderCustom_" + folderId;
export const makeDumpCacheName = (lang) => "dump_" + lang;

const IS_DEBUG = false;
const isDebugCache = () => isDevelopment() && IS_DEBUG;

export const storeBooks = (books = []) => {
  const isDebugCacheValue = isDebugCache();

  let bookList = [];
  const t1 = Date.now();
  if (isDebugCacheValue) {
    console.log("storeBooks", books);
  }
  const cacheValue = localStorageSafe.getItem("books_cache");
  try {
    if (cacheValue) {
      bookList = JSON.parse(cacheValue);
    }
    if (!bookList) {
      bookList = [];
    }
    const normilizeBooks = books.map((book) => {
      // eslint-disable-next-line no-unused-vars
      const { children, chapters, ...otherBookProps } = book;
      return { ...otherBookProps };
    });

    bookList = joinObjArrays([normilizeBooks, bookList]);
    const allStringValue = JSON.stringify(bookList);
    if (isDebugCacheValue) {
      console.log("allStringValue", allStringValue.length);
    }
    localStorageSafe.setItem("books_cache", allStringValue);
  } catch (error) {
    console.log("storeBooks error", error);
  }
  if (isDebugCacheValue) {
    console.log("storeBooks time=", Date.now() - t1, "count = " + bookList.length);
  }
  return bookList;
};
