/* eslint-disable no-console */
import {
  put,
  call,
  select,
  takeLatest,
  all,
  takeLeading,
  actionChannel,
  take,
  fork,
} from "redux-saga/effects";
import { StudyCenterConstants, StudyCenterActions } from "./studyCenter.actions";

import {
  fetchColorsRequest,
  createEntryRequest,
  createTopicRequest,
  fetchTopicsRequest,
  fetchSCEntriesRequest,
  deleteSCEntriesRequest,
  deleteSCTopicsRequest,
  updateEntryRequest,
  updateStudyCenterDumpRequest,
  deleteColorsRequest,
  createColorRequest,
  updateColorRequest,
  updateTopicRequest,
  fetchStudyCenterSearchRequest,
  fetchPreviewTopicsRequest,
  fetchStaticShareRequest,
  createStaticShareRequest,
  deleteStaticShareRequest,
  fetchPreviewStaticShareRequest,
  moveToTopicRequest,
} from "./StudyCenterAPI";

import { joinObjArrays } from "../../utils/Utils";
import { collectAllTopics } from "./StudyCenterUtils";
import deepEqual from "deep-equal";
import {
  actionAddMessage,
  actionLoading,
} from "../../redux/actions";
import { TOAST_TYPE } from "../popup/Toaster";
import { getBookId } from "../../shared/utils/content";
import { v4 as uuid } from "uuid";
import { getQueryByName } from "src/utils/URLUtils";
import { SubscriptionTopicId } from "./studyCenter.constants";
import { clearEditorTempAction, isUserEditor } from "./EditorCenterUtils"; 
import { ContentActions } from "src/redux/content.actions";
const TAG = "StudyCenterSaga_";

//********************** TOPICS *********************************
export const topicsSort = (a, b) => a.order - b.order;

function* fetchSCTopicsRequest() {
  const loadTopics = yield call(fetchTopicsRequest);
  if (loadTopics) {
    loadTopics.sort(topicsSort);
    yield put(StudyCenterActions.fetchSCTopicsSuccess(loadTopics));
  }

  return loadTopics;
}

export function* fetchSCTopicsAsync() {
  try {
    const { topics, staticShare } = yield select((state) => state.studyCenter);
    let topicList = topics;
    if (topics.length === 0) {
      const loadTopics = yield call(fetchSCTopicsRequest);
      if (loadTopics) {
        topicList = loadTopics;
      }
    }
    if (staticShare.length === 0 && !isUserEditor()) {
      const staticShareLoad = yield call(fetchStaticShareRequest);
      if (staticShareLoad) {
        const topicIds = topicList.map((item) => item.id);
        const absentShares = [];
        const presentShares = [];
        staticShareLoad.forEach((item) => {
          if (topicIds.indexOf(item.id) === -1) {
            absentShares.push(item.id);
          } else {
            presentShares.push(item);
          }
        });
        if (absentShares.length > 0) {
          yield call(deleteStaticShareRequest, absentShares);
        }
        yield put(StudyCenterActions.fetchStaticShareSuccess(presentShares));
      }
    }
  } catch (error) {
    console.log(TAG + "fetchSCTopicsAsync", error);
  }
}

export function* updateSCTopicAsync(action) {
  try {
    const state = yield select();
    const { topics } = state.studyCenter;
    const updatedTopic = yield updateTopicRequest(action.data);
    if (updatedTopic) {
      const index = topics.findIndex((item) => item.id === updatedTopic.id);
      if (index !== -1) {
        topics[index] = { ...updatedTopic, child_count: action.data.child_count || 0 };
        yield put(StudyCenterActions.fetchSCTopicsSuccess([...topics]));
      }

      if (isUserEditor()) {
        const { entries } = yield select((state) => state.studyCenter);
        const newEntries = entries.map((entry) => {
          if (entry.topic === action.data.id) {
            return {
              ...entry,
              topic: updatedTopic.id,
              note_topic: updatedTopic.name,
            };
          }

          return entry;
        });

        yield put(
          StudyCenterActions.updateStudyCenterDumpSuccess({
            entries: newEntries,
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createSCTopicAsync(action) {
  try {
    const state = yield select();
    const { topics } = state.studyCenter;
    let newTopicValue = action.data;

    const list = topics.filter(
      (topic) => topic.parent === newTopicValue.parent || (!newTopicValue.parent && !topic.parent),
    );
    list.sort(topicsSort);
    const minOrder = list[0] ? list[0].order : 1;
    newTopicValue.order = minOrder - 1;

    const newTopic = yield call(createTopicRequest, action.data);
    if (newTopic) {
      if (!newTopic.child_count) {
        newTopic.child_count = 0;
      }
      yield put(StudyCenterActions.fetchSCTopicsSuccess([newTopic, ...topics]));
    }
  } catch (error) {
    console.log(error);
  }
}

//********************** COLORS *********************************

export function* fetchStudyCenterColorsAsync() {
  try {
    //TODO add check exists colors
    // const state = yield select();
    const colors = yield call(fetchColorsRequest);
    if (colors) {
      yield put(StudyCenterActions.fetchSCColorsSuccess(colors));
    }
  } catch (error) {
    console.log(TAG + "fetchStudyCenterColorsAsync", error);
  } finally {
    yield put(StudyCenterActions.fetchSCColorsFinally());
  }
}

export function* updateSCColorAsync(action) {
  try {
    const { colors } = yield select((state) => state.studyCenter);
    const localColors = [...colors];
    const updatedColor = yield call(updateColorRequest, action.data);
    if (updatedColor) {
      const updColor = localColors.findIndex((item) => item.id === updatedColor.id);
      if (updColor !== -1) {
        localColors[updColor] = updatedColor;
        yield put(StudyCenterActions.fetchSCColorsSuccess(localColors));
      }
    }
  } catch (error) {
    console.log(TAG + "updateSCColorAsync", error);
  }
}

export function* createSCColorAsync(action) {
  try {
    const { name, color } = action.data;
    const { colors } = yield select((state) => state.studyCenter);
    const localColors = [...colors];
    const newColor = yield call(createColorRequest, name, color);
    if (newColor) {
      localColors.unshift(newColor);
      yield put(StudyCenterActions.fetchSCColorsSuccess(localColors));
    }
  } catch (error) {
    console.log(TAG + "createSCColorAsync", error);
  }
}

export function* deleteSCColorsAsync(action) {
  try {
    const ids = action.data;
    const state = yield select();
    const { colors } = state.studyCenter;
    const localColors = [...colors];
    const result = yield call(deleteColorsRequest, ids);
    if (result.deleted) {
      ids.forEach((colorId) => {
        const index = localColors.findIndex((item) => item.id === colorId);
        if (index !== -1) {
          localColors.splice(index, 1);
        }
      });
      yield put(StudyCenterActions.fetchSCColorsSuccess(localColors));
    }
  } catch (error) {
    console.log(TAG + "deleteSCColorsAsync", error);
  }
}

//********************** ENTRIES *********************************

export function* fetchStudyCenterWatcher() {
  const requestChan = yield actionChannel(StudyCenterConstants.FETCH_STUDY_CENTER);
  while (true) {
    const action = yield take(requestChan);

    try {
      const { colors } = yield select((state) => state.studyCenter);
      if (colors && colors.length === 0) {
        const colorList = yield call(fetchColorsRequest);
        if (colorList) {
          yield put(StudyCenterActions.fetchSCColorsSuccess(colorList));
        }
      }
    } catch (error) {
      console.log(TAG + "fetchStudyCenter_colors", error);
    }
    try {
      const { loadRecursive, page, topic } = action.data;
      const allLoadEntries = [];
      const isRootTopic = topic && topic === "null";

      let loadEntries = {};
      let topicsItemsCounts = {};
      let currentPage = page || 0;
      do {
        loadEntries = yield call(fetchSCEntriesRequest, { ...action.data, page: currentPage });
        topicsItemsCounts[isRootTopic ? undefined : topic] = loadEntries.count;
        allLoadEntries.push(...loadEntries.entries);
        currentPage++;
      } while (loadEntries.nextPage && loadRecursive);

      if (isRootTopic) {
        const subsEntries = yield call(fetchSCEntriesRequest, { topic: SubscriptionTopicId });
        topicsItemsCounts[SubscriptionTopicId] = subsEntries.count;
        if (subsEntries.entries) {
          allLoadEntries.push(...subsEntries.entries);
        }
      }

      if (allLoadEntries) {
        const { colors, entries } = yield select((state) => state.studyCenter);
        let allEntries = [...entries];
        allLoadEntries.forEach((item) => {
          const color = colors.find((color) => color.id === item.color_id);
          if (color) {
            item.name = color.name;
          }
          const findIndex = allEntries.findIndex((entry) => item.id === entry.id);
          if (findIndex === -1) {
            allEntries.push(item);
          }
        });
        let nextPageValue;
        if (loadEntries.nextPage && !loadRecursive) {
          nextPageValue = {
            ...action.data,
            page: currentPage,
          };
        }

        // collect data about books from SC entries
        let uniqueBookIds = new Set();
        allEntries.forEach((entry) => {
          uniqueBookIds.add(entry.bookId);
        });
        uniqueBookIds = [...uniqueBookIds];
        // loads all unique book
        yield put(ContentActions.fetchBooksByIds([...uniqueBookIds]));

        yield put(
          StudyCenterActions.updateStudyCenterDumpSuccess({
            entries: allEntries,
            nextPage: nextPageValue,
            topicsItemsCounts,
          }),
        );
      }
    } catch (error) {
      console.log(TAG + "fetchStudyCenter", error);
    }
  }
}

export function* createSCEntryAsync(action) {
  try {
    const isEditorMode = isUserEditor();
    const { colors, entries, topics } = yield select((state) => state.studyCenter);
    let newEntryValue = action.data;
    let maxOrder = 0;
    entries.forEach((entry) => {
      if (entry.topic === newEntryValue.topic || (!entry.topic && !newEntryValue.topic)) {
        if (entry.order > maxOrder) {
          maxOrder = entry.order;
        }
      }
    });
    newEntryValue.order = maxOrder + 1;

    const newEntry = yield call(createEntryRequest, newEntryValue);
    if (newEntry) {
      const newState = {};
      let topicList = topics;
      let topicIndex = topicList.findIndex((item) => item.id === newEntry.topic);
      if (topicIndex === -1 && isEditorMode) {
        const loadTopics = yield call(fetchSCTopicsRequest);
        if (loadTopics) {
          newState.topics = [...loadTopics];
        }
      }
      if (topicIndex !== -1) {
        topicList[topicIndex].child_count = (topicList[topicIndex].child_count || 0) + 1;
        newState.topics = [...topicList];
      }
      const color = colors.find((color) => color.id === newEntry.color_id);
      if (color) {
        newEntry.name = color.name;
      }
      newState.entries = [...entries, newEntry];
      newState.newEntry = newEntry;

      if (isEditorMode) {
        yield put(StudyCenterActions.setCurrentSCElement(newEntry.id, newEntry.topic));
        yield put(clearEditorTempAction());
      }

      yield put(StudyCenterActions.updateStudyCenterDumpSuccess(newState));
    }
  } catch (error) {
    yield put(actionAddMessage("Error: can't create entry"));
    console.log(TAG + "createSCEntryAsync", error);
  }
}

export function* updateSCEntryAsync(action) {
  try {
    const state = yield select();
    const isEditorMode = isUserEditor();
    const { entries, topics } = state.studyCenter;
    const oldEntry = entries.find((item) => item.id === action.data.id);
    const updatedEntry = yield call(updateEntryRequest, action.data);
    if (updatedEntry) {
      const newState = {};
      const entryIndex = entries.findIndex((item) => item.id === updatedEntry.id);
      if (entryIndex !== -1) {
        updatedEntry.name = action.data.name;
        entries[entryIndex] = updatedEntry;
        newState.entries = [...entries];
      }
      let needTopicUpdate = false;
      let oldTopicIndex;
      if (oldEntry) {
        const { topic: oldTopic } = oldEntry;
        oldTopicIndex = topics.findIndex((item) => item.id === oldTopic);
        if (oldTopicIndex !== -1) {
          needTopicUpdate = true;
          topics[oldTopicIndex].child_count--;
        }
      }
      const topicIndex = topics.findIndex((item) => item.id === updatedEntry.topic);
      if (topicIndex !== -1) {
        topics[topicIndex].child_count = (topics[topicIndex].child_count || 0) + 1;
        needTopicUpdate = true;
      }

      if (needTopicUpdate) {
        newState.topics = [...topics];
      }

      if (isEditorMode) {
        if (
          topicIndex === -1 ||
          (oldTopicIndex !== -1 && topics[oldTopicIndex].child_count === 0)
        ) {
          const loadTopics = yield call(fetchSCTopicsRequest);
          if (loadTopics) {
            newState.topics = loadTopics;
          }
        }
      }

      yield put(StudyCenterActions.updateStudyCenterDumpSuccess(newState));
      if (isEditorMode) {
        yield put(StudyCenterActions.setCurrentSCElement(updatedEntry.id, updatedEntry.topic));
      }
      yield put(ContentActions.actionMakeRichContent(updatedEntry.bookId, true));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateSCDumpAsync(action) {
  try {
    const SCDumpTypes = ["topics", "entries", "colors"];
    const { updateContentBook, topics, entries, colors } = action.data;

    const res = yield call(updateStudyCenterDumpRequest, { topics, entries, colors });

    if (res.status === 204) {
      const { topics, entries, colors } = yield select((state) => state.studyCenter);
      const newState = { topics, entries, colors };

      SCDumpTypes.forEach((key) => {
        if (action.data[key]) {
          newState[key] = joinObjArrays([action.data[key], newState[key]], "id");
        }
      });

      yield put(StudyCenterActions.updateStudyCenterDumpSuccess(newState));
      if (updateContentBook) {
        yield put(ContentActions.actionMakeRichContent(updateContentBook));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteSCElementsAsync(action) {
  try {
    const { entries, topics } = action.data;
    const studyCenter = yield select((state) => state.studyCenter);

    let localEntries = [...studyCenter.entries];
    let localTopics = [...studyCenter.topics];

    const { staticShare } = studyCenter;

    if (topics.length) {
      const deleteTopics = yield call(deleteSCTopicsRequest, topics);
      if (deleteTopics) {
        const topicIdsToRemove = collectAllTopics(localTopics, topics);
        localEntries = localEntries.filter((item) => topicIdsToRemove.indexOf(item.topic) === -1);
        localTopics = localTopics.filter((item) => topicIdsToRemove.indexOf(item.id) === -1);
        const absentShares = [];
        const presentShares = [];
        staticShare.forEach((item) => {
          if (topicIdsToRemove.indexOf(item.id) !== -1) {
            absentShares.push(item.id);
          } else {
            presentShares.push(item);
          }
        });
        if (absentShares.length > 0) {
          yield call(deleteStaticShareRequest, absentShares);
        }
        if (presentShares.length > 0) {
          yield put(StudyCenterActions.fetchStaticShareSuccess(presentShares));
        }
      }
    }
    let bookId;
    if (entries.length) {
      const deleteEntries = yield call(deleteSCEntriesRequest, entries);
      if (deleteEntries) {
        const isEditorMode = isUserEditor();
        if (isEditorMode) {
          localTopics = yield call(fetchSCTopicsRequest);
        }
        let topicId;
        let removedCount = 0;

        //support remove only from one folder
        entries.forEach((entry) => {
          const index = localEntries.findIndex((item) => item.id === entry);
          if (index !== -1) {
            const entryToRemove = localEntries[index];
            bookId = entryToRemove.bookId;
            topicId = entryToRemove.topic;
            localEntries.splice(index, 1);
            removedCount++;
          }
        });

        if (topicId && removedCount > 0 && !isEditorMode) {
          const topic = localTopics.find((item) => item.id === topicId);
          if (topic) {
            topic.child_count = topic.child_count - removedCount;
          }
        }
      }
    }

    yield put(
      StudyCenterActions.updateStudyCenterDumpSuccess({
        entries: localEntries,
        topics: localTopics,
      }),
    );
    // When remove one scc element in reader, update reader view
    if (entries.length === 1 && bookId) {
      yield put(ContentActions.actionMakeRichContent(bookId));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* searchSCAsync(action) {
  try {
    const { query, startSearch } = action.data;
    const { searchResults } = yield select((state) => state.studyCenter);

    const results = yield call(fetchStudyCenterSearchRequest, action.data);

    if (results.next) {
      const page = getQueryByName("page", results.next);
      if (page) {
        results.nextPage = page;
      }
    }
    results.query = query;

    if (!startSearch && searchResults.query === query) {
      results.results.unshift(...searchResults.results);
    }
    yield put(StudyCenterActions.fetchStudyCenterSearchSuccess(results));
  } catch (error) {
    console.log(TAG + "searchSCAsync", error);
  }
}

export function* fetchSCPreviewAsync(action) {
  try {
    let { previewIds, start } = action.data;
    const { previewRequest } = yield select((state) => state.studyCenter);
    if (previewIds.length === 0 || !deepEqual(previewIds, previewRequest)) {
      yield put(StudyCenterActions.fetchStudyCenterPreviewSuccess());
      if (previewIds.length === 0) {
        return;
      }
    }
    const { previewParagraphs, previewPageIds } = yield select((state) => state.studyCenter);
    if (previewParagraphs[start]) {
      return;
    }

    const loader = StudyCenterConstants.FETCH_STUDY_CENTER_PREVIEW;

    yield put(actionLoading(loader));

    const results = yield call(
      fetchPreviewTopicsRequest,
      previewIds,
      start === "0" ? undefined : start,
    );

    let resultData;

    if (results && results.paragraphs) {
      const newPreviewParagraphs = { ...previewParagraphs };
      newPreviewParagraphs[start] = results.paragraphs;
      const newPreviewPageIds = [...previewPageIds];

      if (newPreviewPageIds.indexOf(start) === -1) {
        newPreviewPageIds.push(start);
      }
      if (results.next && newPreviewPageIds.indexOf(results.next) === -1) {
        newPreviewPageIds.push(results.next);
      }

      resultData = {
        previewRequest: [...previewIds],
        previewParagraphs: newPreviewParagraphs,
        previewPageIds: newPreviewPageIds,
      };
      if (results.entries) {
        const { entries } = yield select((state) => state.studyCenter);
        resultData.entries = joinObjArrays([entries, results.entries]);
      }
    }

    yield put(StudyCenterActions.fetchStudyCenterPreviewSuccess(resultData));
    yield put(actionLoading(loader, true));
  } catch (error) {
    console.log(TAG + "fetchSCPreviewAsync", error);
  }
}

//****************** STATIC SHARE **********************************

export function* createStaticShareAsync(action) {
  try {
    const createStatus = yield call(createStaticShareRequest, action.data);
    if (createStatus === 204) {
      const { staticShare } = yield select((state) => state.studyCenter);
      let shares = [...staticShare];
      shares.push(action.data);
      yield put(StudyCenterActions.fetchStaticShareSuccess(shares));
    }
  } catch (error) {
    let message = error.message || "can't create static share";
    yield put(actionAddMessage("Error: " + message, TOAST_TYPE.error));
    console.log(TAG + "createStaticShareAsync", error);
  }
}

export function* deleteStaticShareAsync(action) {
  try {
    const idsToRemove = action.data;
    const deleteStatus = yield call(deleteStaticShareRequest, idsToRemove);
    if (deleteStatus === 204) {
      const { staticShare } = yield select((state) => state.studyCenter);
      let shares = staticShare.filter((item) => idsToRemove.indexOf(item.id) === -1);
      yield put(StudyCenterActions.fetchStaticShareSuccess(shares));
    }
  } catch (error) {
    yield put(actionAddMessage("Error: can't delete static share", TOAST_TYPE.error));
    console.log(TAG + "deleteStaticShareAsync", error);
  }
}

export function* previewStaticShareAsync(action) {
  try {
    const { userId, shareId } = action.data;
    const preview = yield call(fetchPreviewStaticShareRequest, userId, shareId);
    yield put(StudyCenterActions.fetchPreviewStaticShareSuccess(preview));
  } catch (error) {
    yield put(actionAddMessage("Error: can't preview static share", TOAST_TYPE.error));
    console.log(TAG + "previewStaticShareAsync", error);
  }
}

export function* moveToTopicEditorWorker(action) {
  try {
    const { updatedTopics, updatedEntries, selectedEntries, targetFolder } = action.data;
    const { topics, entries } = yield select((state) => state.studyCenter);
    const moveStatus = yield call(moveToTopicRequest, {
      id: selectedEntries.map((entry) => entry.id),
      topic: targetFolder.title,
    });

    if (moveStatus === 204) {
      const updatedEntriesFormatted = updatedEntries.map((entry) => ({
        ...entry,
        note_topic: targetFolder.title,
      }));
      const newTopics = joinObjArrays([updatedTopics, topics], "id");
      const newEntries = joinObjArrays([updatedEntriesFormatted, entries], "id");
      const newTopicsFiltered = newTopics.filter(
        (topic) => !(topic.child_count === 0 && topic.topic),
      );

      yield put(
        StudyCenterActions.updateStudyCenterDumpSuccess({
          topics: newTopicsFiltered,
          entries: newEntries,
        }),
      );
    }
  } catch (error) {
    yield put(actionAddMessage("Error: can't move to topic", TOAST_TYPE.error));
    console.log(TAG + "moveToTopicEditorAsync", error);
  }
}

export function* createTestSCElementsMethod(action) {
  try {
    const paraId = action.data;

    const { colors } = yield select((state) => state.studyCenter);
    const { paragraphs } = yield select((store) => store.paragraphReducer);

    const bookId = getBookId(paraId);
    const bookContent = paragraphs[bookId];
    if (bookContent) {
      const paraData = bookContent.find((item) => item.id === paraId);
      if (paraData) {
        const elem = document.createElement("div");
        elem.innerHTML = paraData.content;
        let newHls = [];
        const textContent = elem.innerText;
        let lastIndex = 0;
        let count = "";

        while (lastIndex <= textContent.length) {
          let space = textContent.indexOf(" ", lastIndex);
          if (space === -1 || count > 200) {
            break;
          }
          count++;

          let color = colors[count % colors.length];
          newHls.push({
            id: uuid(),
            color_id: color.id,
            end_para: paraId,
            end_pos: space + 1,
            order: count,
            lu: Date.now(),
            start_para: paraId,
            start_pos: lastIndex,
            type: "highlight",
          });
          lastIndex = space + 1;
        }

        if (newHls.length) {
          yield put(
            StudyCenterActions.updateStudyCenterDump({
              entries: newHls,
              updateContentBook: bookId,
            }),
          );
        }
      }
    }
  } catch (error) {
    console.log("error", error);
  }
}

function* findEntryWorker(action) {
  const newSearchText = action.data;
  if (newSearchText === "") {
    yield put(
      StudyCenterActions.setSCSearchTextSuccess({
        searchText: newSearchText,
        validEntries: [],
      }),
    );

    return;
  }

  const searchText = yield select((state) => state.studyCenter.searchText);
  if (newSearchText !== searchText) {
    const results = yield call(fetchStudyCenterSearchRequest, { query: newSearchText });
    yield put(
      StudyCenterActions.setSCSearchTextSuccess({
        searchText: newSearchText,
        validEntries: results.results.map(({ id, topic }) => ({ id, topic })),
      }),
    );
  }
}

export default function* studyCenter() {
  yield all([
    takeLatest(StudyCenterConstants.UPDATE_STUDY_CENTER_DUMP, updateSCDumpAsync),
    takeLeading(StudyCenterConstants.FETCH_STUDY_CENTER_COLORS, fetchStudyCenterColorsAsync),
    takeLatest(StudyCenterConstants.CREATE_STUDY_CENTER_ENTRY, createSCEntryAsync),
    takeLatest(StudyCenterConstants.UPDATE_STUDY_CENTER_ENTRY, updateSCEntryAsync),
    takeLatest(StudyCenterConstants.CREATE_STUDY_CENTER_TOPIC, createSCTopicAsync),
    takeLatest(StudyCenterConstants.FETCH_STUDY_CENTER_TOPICS, fetchSCTopicsAsync),
    takeLatest(StudyCenterConstants.DELETE_STUDY_CENTER_ELEMENTS, deleteSCElementsAsync),
    takeLatest(StudyCenterConstants.DELETE_STUDY_CENTER_COLORS, deleteSCColorsAsync),
    takeLatest(StudyCenterConstants.CREATE_STUDY_CENTER_COLOR, createSCColorAsync),
    takeLatest(StudyCenterConstants.UPDATE_STUDY_CENTER_COLOR, updateSCColorAsync),
    takeLatest(StudyCenterConstants.UPDATE_STUDY_CENTER_TOPIC, updateSCTopicAsync),
    takeLatest(StudyCenterConstants.FETCH_STUDY_CENTER_SEARCH, searchSCAsync),
    takeLatest(StudyCenterConstants.FETCH_STUDY_CENTER_PREVIEW, fetchSCPreviewAsync),
    takeLatest(StudyCenterConstants.CREATE_STATIC_SHARE, createStaticShareAsync),
    takeLatest(StudyCenterConstants.DELETE_STATIC_SHARE, deleteStaticShareAsync),
    takeLatest(StudyCenterConstants.PREVIEW_STATIC_SHARE, previewStaticShareAsync),
    takeLatest(StudyCenterConstants.CREATE_TEST_SC_ELEMENTS, createTestSCElementsMethod),
    takeLatest(StudyCenterConstants.MOVE_TO_TOPIC_EDITOR_ACTION, moveToTopicEditorWorker),
    takeLatest(StudyCenterConstants.SET_SC_SEARCH_TEXT, findEntryWorker),
    fork(fetchStudyCenterWatcher),
  ]);
}
