import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import IconView from "src/shared/components/IconView";
import EgwWebFont from "src/assets/EgwWebFont";

const ColorEditPopup = ({ onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="context-menu-item" onClick={() => onEdit()}>
        <IconView icon={EgwWebFont.edit} />
        {t("edit")}
      </div>
      <div className="context-menu-item" onClick={() => onDelete()}>
        <IconView icon={EgwWebFont.delete} />
        {t("delete")}
      </div>
    </React.Fragment>
  );
};

ColorEditPopup.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default ColorEditPopup;
