import axios from "axios";
import { getBookId, getBookOrigin } from "src/shared/utils/content";
import { localStorageSafe } from "src/shared/utils/systemUtils";
import { ELLEN4ALL_TOKEN_KEY, ELLEN4ALL_URL, formatContent } from "./translate.utils";

const SEGMENT_BASE_URL = ELLEN4ALL_URL + "/api";

export default class Segment {
  constructor(options = {}) {
    this.options = options;
    this.options.baseUrl = SEGMENT_BASE_URL;
  }

  getTranslatedListWork(field) {
    return new Promise((resolve, reject) => {
      if (!field) {
        reject(Error({ statusCode: 401 }));
      }
      axios
        .get(`${this.options.baseUrl}/translations/${field}`, {
          headers: this.options.headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getSegments(translationId, position) {
    return new Promise((resolve, reject) => {
      if (!translationId) {
        reject(Error({ statusCode: 401 }));
      }
      if (!position) {
        reject(Error({ statusCode: 401 }));
      }
      axios
        .get(`${this.options.baseUrl}/translations/${translationId}/segments/${position}/`, {
          headers: this.options.headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  editSegment(translationId, position, content) {
    return new Promise((resolve, reject) => {
      if (!translationId) {
        reject(Error({ statusCode: 401 }));
      }
      if (!position) {
        reject(Error({ statusCode: 401 }));
      }
      axios
        .patch(
          `${this.options.baseUrl}/translations/${translationId}/segments/${position}/`,
          content,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.options.token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }
}

export const editSegment = async (translationId, position, content) => {
  let editContent;
  try {
    const response = await segmentFetch().editSegment(translationId, position, content);
    editContent = response.data.content;
  } catch (error) {
    console.log("ERROR", error);
  }
  return editContent;
};

export const getBooksInTranslations = async (language, offset = 0, limit = 15) => {
  const queryParts = [
    "is_authorized=false",
    "ordering=priority,-reviewed,-translated,-pretranslated,title",
  ];
  if (language) {
    queryParts.push(`language=${language}`);
  }
  if (offset !== undefined) {
    queryParts.push(`offset=${offset}`);
  }
  if (limit !== undefined) {
    queryParts.push(`limit=${limit}`);
  }
  const url = `${SEGMENT_BASE_URL}/translations/?${queryParts.join("&")}`;
  const response = await fetch(url);
  const resResponse = await response.json();
  return resResponse;
};

function segmentFetch() {
  const token = localStorageSafe.getItem(ELLEN4ALL_TOKEN_KEY);
  const segment = new Segment({
    headers: {
      Authorization: "Bearer " + token,
    },
    "Access-Control-Allow-Origin": "*",
    token,
  });
  return segment;
}

export const getSegmentsTranslationRequest = async (segmentID, bookId) => {
  let formatedContent = [];
  try {
    const response = await segmentFetch().getSegments(segmentID, `?key=${bookId}&limit=10`);
    const resResponse = await response.data.results;
    formatedContent = formatContent(resResponse);
  } catch (error) {
    console.log("ERROR", error);
  }
  return formatedContent;
};

export const getAvailableLanguagesRequest = async (bookID) => {
  const ID = getBookOrigin(bookID);
  const response = await segmentFetch().getTranslatedListWork(`?originalwork_key=${ID}`);
  const resResponse = await response.data.results;
  const availableLanguages = resResponse
    .map((el) => {
      return {
        ...el.language,
        id: el.id,
        bookId: getBookId(el.original.key),
        label: el.language.name,
      };
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  return availableLanguages;
};
