export const folderTreeActionConstants = {
  EXPAND_TREE: "ft/EXPAND_TREE",
  UPDATE_EXPANDED: "ft/UPDATE_EXPANDED",
  UPDATE_CHECKED: "ft/UPDATE_CHECKED",
  UPDATE_FILTERS: "ft/UPDATE_FILTERS",
  FETCH_PRESET_CHECKED_SUCCESS: "ft/FETCH_PRESET_CHECKED_SUCCESS",
  INVALIDATE_ITEM: "ft/INVALIDATE_ITEM",
};

export const folderTreeActions = {
  expandTree:(data, options = {}) => ({
    type: folderTreeActionConstants.EXPAND_TREE,
    data,
    options: { drop: false, ...options },
  }),
  invalidateItem:(data) => ({
    type: folderTreeActionConstants.INVALIDATE_ITEM,
    data,
  }),
  updateChecked: (data) => ({
    type: folderTreeActionConstants.UPDATE_CHECKED,
    data,
  }),
  // WARNING: "invalidateItem" dispatch necessary after updating expanded list!
  // To fetch expanded items children.
  updateExpanded: (data) => ({
    type: folderTreeActionConstants.UPDATE_EXPANDED,
    data,
  }),
  updateFilters: (data) => ({
    type: folderTreeActionConstants.UPDATE_FILTERS,
    data,
  }),
  fetchPresetCheckedSuccess: (data) => ({
    type: folderTreeActionConstants.FETCH_PRESET_CHECKED_SUCCESS,
    data,
  }),
};
