import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CheckYesIcon, CheckNoIcon, CheckHalfIcon } from "../../assets/CommonIcons";

import "./View.scss";

/**
 *
 * @param {string} title
 * @param {boolean} checked
 * @param {boolean} partCheck
 * @param {function} onChange - f(isChecked, event)
 * @param {string} className
 * @param {string} iconClassName
 */

const Checkbox = ({
  title,
  checked,
  partCheck,
  onChange,
  className,
  iconClassName = "",
  disabled,
}) => {
  const Icon = partCheck ? CheckHalfIcon : checked ? CheckYesIcon : CheckNoIcon;
  const [focus, setFocus] = useState(false);

  const onInputClick = (event) => {
    if (!disabled && onChange) {
      onChange(partCheck ? false : !checked, event);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div
      className={classNames("checkbox-item", className, { focused: focus, inactive: disabled })}
      onClick={onInputClick}>
      <input
        tabIndex={0}
        type="checkbox"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <Icon className={classNames("checkbox-icon", iconClassName)} />
      {title && <span className="controlLabel">{title}</span>}
    </div>
  );
};

Checkbox.propTypes = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  partCheck: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default Checkbox;
