import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { RadioBtnCheckedIcon, RadioBtnUncheckedIcon } from "../../assets/CommonIcons";
import { isEventKeys } from "../../utils/Utils";
import { KeyCodes } from "../../shared/utils/dom";

import "./View.scss";

/**
 *
 * @param options  array - [{id, label}]
 * @param value - id
 * @param onChange - f(key)
 * @param {string} className
 * @param {boolean} withTranslation - boolean - flag to translate label
 * @param {string} groupName
 */
const RadioGroup = ({ options, value, onChange, className, withTranslation, groupName }) => {
  const { t } = useTranslation();
  const keyboardUsedRef = useRef(false);
  const [current, setCurrent] = useState(value);
  const name = groupName || "radio-group";

  useEffect(() => {
    keyboardUsedRef.current = document?.querySelector("html[data-whatinput='keyboard']");
  }, []);

  useEffect(() => {
    setCurrent(value);
  }, [value]);

  const onClickHandle = (id) => {
    if (!keyboardUsedRef.current) {
      if (onChange) {
        onChange(id);
      }
      setCurrent(id);
    }
  };

  const keyDownHandler = (event, id) => {
    if (isEventKeys(event, [KeyCodes.enter, KeyCodes.space])) {
      if (onChange) {
        onChange(id);
      }
      setCurrent(id);
      event.preventDefault();
    }
  };

  return (
    <div role="radiogroup" className={classNames("RadioGroup", className)}>
      {(options || []).map((item, index) => {
        const { id, label, icon, style } = item;
        const isActive = id === current;
        const RadioIcon = icon || (isActive ? RadioBtnCheckedIcon : RadioBtnUncheckedIcon);

        return (
          <div
            key={index}        
            role="radio"
            className={classNames("radio-item", isActive ? "active" : "inactive")}
            onClick={() => onClickHandle(id)}>
            <input
              onKeyDown={(event) => keyDownHandler(event, id)}
              type="radio"
              name={name}
              aria-checked={isActive ? "true" : "false"}
            />
            <RadioIcon tabIndex={-1} />
            <span style={style} className="controlLabel">{withTranslation ? t(label) : label}</span>
          </div>
        );
      })}
    </div>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  withTranslation: PropTypes.bool,
  groupName: PropTypes.string
};

export default RadioGroup;
