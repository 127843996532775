import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { withResizeDetector } from "react-resize-detector/build/withPolyfill";

import { IconButton } from "../views";
import { useTooltipContext } from "../popup/TooltipContext";
import { useDialogContext } from "../dialog/DialogContext";
import { DropdownIcon } from "../dropDown/DropdownIcon";
import { Period, periodOptionCustom } from "../../utils/AdvSearchTypes";
import { getControlPanelIcons, getIconsSorted, renderIcons } from "../../utils/Panel.utils";
import { getBoundingClientRect, isFunction, Position } from "../../utils/Utils";
import { createContainer, createHistorySearchContainer } from "../../utils/PrintUtils";
import { copy } from "../../utils/CopyUtil";
import { useAuth, usePopupId, useViewMode } from "../../hooks";
import TextButton from "../views/TextButton";
import PrintShareDialog from "./PrintShareDialog";
import {
  getSearchLoaderId,
  isDisableOption,
  isDisableOptionRelated,
  makeSearchUrl,
} from "../../utils/SearchUtils";
import { useDefLang, useLoader } from "../../redux/selectors";
import { actionAddMessage, actionUpdateSetting } from "../../redux/actions";
import { getStoredValue, Settings } from "../../utils/Settings";
import { usePopupContext } from "../popup/PopupContext";
import SearchNavPanel from "../views/SearchNavPanel";
import {
  orderOptions, periodOptions, queryTypeOptions,
  RESTRICTION_SEARCH_VALUE,
  SearchType,
  snippetOptions
} from "../../shared/utils/search";
import classnames from "classnames";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { useNavigationContext } from "../NavigationContext";
import SearchLanguageSelector from "../languageSelector/SearchLanguageSelector";
import PopupWrap from "../popup/PopupWrap";

import EgwWebFont from "../../assets/EgwWebFont";
import { TreeMenuMode } from "src/utils/TreeUtils";
import { getBookId } from "src/shared/utils/content";
import { EnumPopupPositions } from "../popup/constants";
import { SearchPanelTabs } from "../rightPanel/constants";
import { SearchActions } from "./search.actions";
import { useSearchBookCode } from "./hooks";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { URLS } from "../../shared/utils/url";
import { translateSelectOptions } from "../../shared/utils/i18n";
import ButtonSwitchToTextSearch from "./ButtonSwitchToTextSearch";
import { useSearchContext } from "./SearchContext";

const MinimalIconPanelWidthRelated = 80;

const PRINT_DIALOG_ID = "search-print-dialog";
const SHARE_DIALOG_ID = "search-pshare-dialog";

const LANG_POPUP_ID = "languageSelector";

const SearchTopPanel = ({
  total,
  isLocal,
  start,
  loaded,
  onChangePosition,
  onClose,
  onChangeOrder,
  onChangeQueryType,
  onChangeSnippet,
  onChangePeriod,
  onChangeDictionaryPeriod,
  onChangeCode,
  isRightPanel,
  width,
  onChangeTargetBible,
  isHistorySearch,
}) => {
  const { t } = useTranslation();
  const { showTooltip } = useTooltipContext();
  const { showPopup } = usePopupContext();
  const isShownLangPopup = usePopupId(LANG_POPUP_ID);
  const { showDialog, checkDialogId } = useDialogContext();
  const history = useHistory();
  const { isMobile, textMode, zoom } = useViewMode();
  const remToPx = useRemToPx();
  const { isLogin } = useAuth();

  const { isCurrentQueryHasShortcuts, isShortcutsAvailableAndShouldBeShown } = useSearchContext();
  const { isRelatedSearch } = useNavigationContext();
  const dispatch = useDispatch();
  const bibles = useSelector((state) => state.mainTree.bibles);
  const dictionaries = useSelector((state) => state.mainTree.dictionaries);
  const baseBible = useSelector((state) => state.settings.baseBible);
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);
  const isTreeViewMode = useSelector(
    (state) => state.settings.treeMenuMode !== TreeMenuMode.CATEGORIES,
  ); 
  const dictionaryFilter = useSelector((state) => state.search.dictionaryFilter);
  const searchParams = useSelector((state) => state.search.searchParams);
  const localSearchParams = useSelector((state) => state.search.localSearchParams);
  const isShowPlanet = useSelector((state) => state.search.searchQueryLangs.length > 0);

  const defaultMlLang = useDefLang();

  const [show, setShow] = useState(false);
  const [popupOptions, setPopupOptions] = useState({});
  const [targetBible, setTargetBible] = useState();
  const searchIsLoading = useLoader(getSearchLoaderId(false, isRightPanel));

  const isRelSearch = searchParams?.extras?.type === "related";
  const threeDotsRef = useRef();

  const searchQuery = useSelector((state) => state.search.searchQuery);

  const params = isLocal ? localSearchParams : searchParams;

  const { period, snippet, order, queryType } = params.extras;

  const isBibleFilter = searchParams.extras?.period === Period.myBible;
  const isBibleType = searchParams.extras?.type === "bible";
  const isDictionaryFilter = searchParams.extras?.period === Period.dictionary;
  const isCompactView = width <= 500;
  const bookForSearch = useSearchBookCode(isLocal);

  const searchType = searchParams?.onlyText || !bookForSearch ? "text" : "book";

  const [bookCodeInput, setBookCodeInput] = useState(bookForSearch?.code || "");

  useEffect(() => {
    setBookCodeInput(bookForSearch?.code || "");
  }, [bookForSearch?.code]);

  const MinimalIconPanelWidth = useMemo(() => {
    const lockedTabs = getStoredValue(Settings.lockedTabs.id);

    if (lockedTabs?.length >= 2 && zoom >= 180) {
      return 0;
    }

    return !isMobile || isBibleFilter ? (!isMobile && isBibleFilter ? 180 : 160) : 140;
  }, [isBibleFilter, isMobile, zoom]);

  const bibleFilterOptions = useMemo(
    () =>
      bibles
        .filter((item) => item.lang === defaultMlLang)
        .map((item) => ({ key: item.id, label: item.label })),
    [bibles, defaultMlLang],
  );

  const advancedOrderOptions = useMemo(() => {
    return orderOptions.map((item) => ({
      ...item,
      disabled:
        isDisableOption(item.key, period) || (isRelSearch && isDisableOptionRelated(item.key, [])),
    }));
  }, [period, isRelSearch]);

  const queryTypeOptionsTranslated = useMemo(
    () => translateSelectOptions(queryTypeOptions, t), [t]
  );
  
  useEffect(() => {
    const baseBibleOption = bibleFilterOptions.find((item) => item.key === baseBible);
    const currentBible = getBookId(searchParams.extras.pubnr);
    const currentBibleOption = bibleFilterOptions.find((item) => item.key === currentBible);

    setTargetBible(currentBibleOption?.key || baseBibleOption?.key);
  }, [bibleFilterOptions, searchParams.extras.pubnr]);

  const onChangeBibleFilter = (key) => {
    setTargetBible(key);
    onChangeTargetBible(bibles.find((bible) => bible.id === key));
  };

  const onChangeDictionary = (key) => {
    dispatch(SearchActions.updateDictionaryFilter(key));
    onChangeDictionaryPeriod(key);
  };

  const handleOpenPrintShareDialog = (activeTab, dialogId = "") => () => {
    showDialog(<PrintShareDialog isLocal={isLocal} activeTab={activeTab} />, { dialogId });
    setShow(false);
  };

  const handleCopySearchResults = () => {
    let container;
    if (isHistorySearch) {
      container = createHistorySearchContainer(loaded);
    } else {
      container = createContainer(loaded);
    }

    copy(container.innerHTML, {
      format: "text/html",
      textPlain: container.innerText,
      onSuccess: () => dispatch(actionAddMessage(t("searchCopied", { count: loaded.length }))),
      onError: () => dispatch(actionAddMessage(t("searchNotCopied"))),
    });
  };

  const openSearchHandler = () => {
    if (isRelatedSearch) {
      dispatch(actionUpdateSetting(Settings.rightTab.id, SearchPanelTabs.RELATED));
    }
    history.push(makeSearchUrl({ ...searchParams, start: start || 1 }, collectionFilter));
  };

  const openRelatedSearchHandler = () => {
    if (searchParams?.query) {
      history.push(
        makeSearchUrl(
          {
            ...searchParams,
            tab: "related",
          },
          collectionFilter,
          true,
        ),
      );
    } else {
      history.push(URLS.relatedSearch + "?tab=related");
    }

    setShow(false);
  };

  const handleAddLanguageClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    showPopup(
      {
        left: rect.left,
        top: rect.bottom,
      },
      <SearchLanguageSelector />,
      {
        hideOnClick: false,
        targetRect: e.target,
        popupId: LANG_POPUP_ID,
        position: EnumPopupPositions.LEFT_BOTTOM,
      },
    );
  };

  const openSearchHistoryHandler = () => {
    history.push(URLS.historySearch);
  };

  const switchSearchListToShortcuts = useCallback(() => {
    dispatch(actionUpdateSetting(Settings.showShortcutsForShortcut.id, true));
  }, []);

  const isMobileSearch = isMobile && !isRightPanel;

  const codePanel = (
    <div className="book-code-panel">
      <div className="title">{t("book_code")}:</div>
      <input
        value={bookCodeInput}
        className="search-page-value"
        onMouseEnter={(e) => {
          showTooltip(t("bookCodeHint"), e);
        }}
        type="text"
        onChange={(event) => setBookCodeInput(event.target.value)}
        onKeyPress={(event) => {
          if (isEventKey(event, KeyCodes.enter) && onChangeCode) {
            onChangeCode(event.target.value);
            setShow(false);
          }
        }}
      />
    </div>
  );

  const navPanel = (
    <SearchNavPanel
      startValue={start}
      max={Math.min(total, RESTRICTION_SEARCH_VALUE)}
      isMobileSearch={isMobileSearch}
      isInteractionDisabled={searchIsLoading}
      onChangeStartPosition={onChangePosition}
      isHistorySearch={isHistorySearch}
    />
  );

  const handleChangeOrderAndQueryType = useCallback((values) => {
    onChangeOrder(values[0]);
    onChangeQueryType(values[1]);
  }, [onChangeOrder, onChangeQueryType]);

  const ddValueOrder = order || advancedOrderOptions[0]?.key;
  const ddValueQueryType = queryType || queryTypeOptionsTranslated[0].key;
  const ddValuePeriod = period || collectionFilter;

  const availableIcons = [
    {
      render: () => (
        <IconButton
          title={t("openMainSearch")}
          icon={EgwWebFont["search-book"]}
          onClick={openSearchHandler}
        />
      ),
      isShown: isRightPanel && !isMobile && !isHistorySearch,
      priority: isRightPanel ? 2 : 1,
      order: 9,
      hiddenOrder: 4,
      key: "SearchIcon",
    },
    {
      render: () => (
        <IconButton
          title={t("searchShortcut")}
          icon={EgwWebFont["search-shortcut"]}
          onClick={switchSearchListToShortcuts}
        />
      ),
      // {isShortcutsAvailableAndShouldBeShown} - bcz this panel and button can be shown
      // for shortcuts list.
      isShown: !isShortcutsAvailableAndShouldBeShown && isCurrentQueryHasShortcuts,
      priority: isRightPanel ? 2 : 1,
      order: 9,
      hiddenOrder: 4,
      key: "ShortcutIcon",
    },
    {
      render: () => (
        <ButtonSwitchToTextSearch rightPanel={isRightPanel} />
      ),
      isShown: isShortcutsAvailableAndShouldBeShown,
      priority: isRightPanel ? 2 : 1,
      order: 9,
      hiddenOrder: 4,
      key: "SearchTextIcon",
    },
    {
      render: () => (
        <IconButton
          title={t("openRelatedSearch")}
          icon={EgwWebFont["search-relation"]}
          onClick={openRelatedSearchHandler}
        />
      ),
      isShown: isRightPanel && !isMobile,
      priority: isRightPanel ? 2 : 1,
      order: 9,
      hiddenOrder: 4,
      key: "SearchRelatedIcon",
    },
    {
      render: () => (
        <IconButton
          title={t("openSearchHistory")}
          icon={EgwWebFont["search-history"]}
          onClick={openSearchHistoryHandler}
        />
      ),
      isShown: isLogin && isRightPanel && !isMobile,
      priority: isRightPanel ? 3 : 2,
      order: 10,
      hiddenOrder: 5,
      key: "SearchHistoryIcon",
    },
    {
      render: () => (
        <IconButton
          icon={EgwWebFont.copy}
          disabled={total <= 0}
          title={t("copySearch")}
          onClick={() => {
            setShow(false);
            handleCopySearchResults();
          }}
        />
      ),
      isShown: true,
      priority: isMobileSearch ? 1 : isRightPanel ? 3 : 1,
      order: 2,
      hiddenOrder: 2,
      key: "CopyIcon",
    },
    {
      render: () => (
        <IconButton
          active={checkDialogId(PRINT_DIALOG_ID)}
          icon={EgwWebFont.print}
          disabled={total <= 0}
          title={t("Print")}
          onClick={handleOpenPrintShareDialog("print", PRINT_DIALOG_ID)}
        />
      ),
      isShown: !isHistorySearch,
      priority: isRightPanel ? 3 : 2,
      order: isRightPanel ? 5 : 2,
      hiddenOrder: 2,
      key: "PrintIcon",
    },
    {
      render: () => (
        <IconButton
          active={checkDialogId(SHARE_DIALOG_ID)}
          icon={EgwWebFont.share}
          disabled={total <= 0}
          title={t("share")}
          onClick={handleOpenPrintShareDialog("share", SHARE_DIALOG_ID)}
        />
      ),
      isShown: !isHistorySearch,
      priority: isMobileSearch ? 4 : 3,
      order: 3,
      hiddenOrder: 3,
      key: "ShareIcon",
    },
    {
      render: () => {
        return (
          <DropdownIcon
            dropDownClassName="orderOptions"
            icon={EgwWebFont.sort}
            iconTitle={t("searchResultSorting")}
            options={[advancedOrderOptions, queryTypeOptionsTranslated]}
            value={[ddValueOrder, ddValueQueryType]}
            onChange={handleChangeOrderAndQueryType}
            tooltipPosition={Position.TOP}
            withRadio
            closeOnMouseLeave
            doNotOpenOnHover
          />
        );
      },
      isShown: !isHistorySearch && !isRelSearch && isFunction(onChangeOrder),
      priority: 5,
      order: isRightPanel ? 1 : 4,
      hiddenOrder: 2,
      key: "SortIcon",
    },
    {
      render: () => (
        <DropdownIcon
          containerClassName="search-result-length"
          icon={EgwWebFont["result-size"]}
          iconTitle={t("searchResultLength")}
          dropDownClassName="snippetOptions"
          options={snippetOptions}
          value={snippet}
          onChange={onChangeSnippet}
          tooltipPosition={Position.TOP}
          closeOnMouseLeave
          doNotOpenOnHover
        />
      ),
      isShown:
        !isHistorySearch && !isRelSearch && !isDictionaryFilter && isFunction(onChangeSnippet),
      priority: isRightPanel ? 3 : 7,
      order: isRightPanel ? 6 : 7,
      hiddenOrder: 1,
      key: "ResultSizeIcon",
    },
    {
      render: () => (
        <DropdownIcon
          containerClassName="search-period"
          icon={EgwWebFont.filter}
          iconTitle={t("searchPeriod")}
          dropDownClassName="searchOptions"
          options={ddValuePeriod === Period.custom
            ? [...periodOptions, periodOptionCustom]
            : periodOptions}
          tooltipPosition={Position.TOP}
          value={ddValuePeriod}
          onChange={onChangePeriod}
          closeOnMouseLeave
          doNotOpenOnHover
        />
      ),
      isShown:
        !isHistorySearch &&
        !isRelSearch &&
        !isLocal &&
        isTreeViewMode &&
        isFunction(onChangePeriod),
      priority: isMobileSearch ? 7 : isRightPanel ? 6 : 5,
      order: isRightPanel ? 3 : 5,
      hiddenOrder: 5,
      key: "FilterIcon",
    },
    {
      render: () => (
        <IconButton
          title={t("filterResultLanguages")}
          icon={EgwWebFont.planet}
          disabled={isShowPlanet}
          onClick={handleAddLanguageClick}
          active={isShownLangPopup}
        />
      ),
      isShown:
        !isHistorySearch && !isRelSearch && !isLocal && !isDictionaryFilter && isTreeViewMode,
      priority: isMobileSearch ? 7 : isRightPanel ? 4 : 5,
      order: isRightPanel ? 4 : 5,
      hiddenOrder: 5,
      key: "LanguageFilterIcon",
    },
    {
      render: () => (
        <DropdownIcon
          containerClassName="bible-filter"
          icon={EgwWebFont.bible}
          iconTitle={t("myBibleFilter")}
          dropDownClassName="periodOptions"
          options={bibleFilterOptions}
          tooltipPosition={Position.TOP}
          value={targetBible}
          onChange={onChangeBibleFilter}
          closeOnMouseLeave
          withRadio
          doNotOpenOnHover
        />
      ),
      isShown: !isHistorySearch && !isRelSearch && (isBibleFilter || isBibleType),
      priority: isRightPanel ? 8 : 6,
      order: isRightPanel ? 4 : 6,
      hiddenOrder: 7,
      key: "BibleIcon",
    },
    {
      render: () => (
        <DropdownIcon
          containerClassName="search-dictionary"
          icon={EgwWebFont.dictionary}
          iconTitle={t("dictionaryFilter")}
          dropDownClassName="dictionary-options"
          options={dictionaries}
          value={dictionaryFilter}
          onChange={onChangeDictionary}
          tooltipPosition={Position.TOP}
          closeOnMouseLeave
          doNotOpenOnHover
        />
      ),
      isShown: !isHistorySearch && !isRelSearch && isDictionaryFilter,
      priority: isRightPanel ? 8 : 6,
      order: isRightPanel ? 4 : 6,
      hiddenOrder: 7,
      key: "DictionaryIcon",
    },
    {
      render: () => navPanel,
      isShown: !textMode && isFunction(onChangePosition),
      priority: isRightPanel ? 1 : 8,
      order: 8,
      hiddenOrder: 8,
      width: remToPx(10),
      key: "NavPanel",
    },
    {
      render: () => codePanel,
      isShown: isFunction(onChangeCode) && !isRelSearch && !textMode && !isHistorySearch,
      priority: isMobileSearch ? 3 : isRightPanel ? 2 : 7,
      order: 9,
      hiddenOrder: 9,
      width: remToPx(8.3),
      key: "CodePanel",
    },
    {
      render: () => <IconButton icon={EgwWebFont.close} onClick={onClose} className="close-icon" />,
      isShown: isFunction(onClose),
      priority: isRightPanel ? 3 : 8,
      order: 10,
      hiddenOrder: 10,
      key: "CloseIcon",
    },
  ];

  const parentWidth = !isRightPanel
    ? width
    : isRelSearch
    ? MinimalIconPanelWidthRelated
    : MinimalIconPanelWidth;
  const { hiddenIcons, visibleIcons } = getControlPanelIcons(availableIcons, {
    isCollapsed: textMode,
    initialWidth: 38,
    parentWidth,
  });
  const needShowTreeDots = hiddenIcons.length > 0;

  const handleSearch = (type) => {
    setShow(false);
    if (type && type === searchType) {
      return;
    }

    dispatch(
      SearchActions.fetchSearch({
        query: searchQuery,
        extras: { ...searchParams.extras, type: SearchType.basic },
        start: 1,
        onlyText: type === "text",
      }),
    );
  };

  const openTreeDotsPopup = (e) => {
    const bodyRect = getBoundingClientRect(window.document.body);
    const targetRect = getBoundingClientRect(e.currentTarget);
    let left, top;

    if (isRightPanel) {
      left = targetRect.right;
      top = targetRect.bottom + 16;
      bodyRect.right = left; // Do max popup right as the target right.
    } else {
      left = targetRect.left - 8;
      top = targetRect.bottom + 8;
      bodyRect.left = left; // Do min popup left as the target left.
    }

    setPopupOptions({ left, top, bodyRect });
    setShow(true);
  };
  const renderRowContent = () => {
    if (isRightPanel) {
      return (
        <React.Fragment>
          {renderIcons(getIconsSorted(availableIcons))}
          {navPanel}
          {isFunction(onChangeCode) && codePanel}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {renderIcons(getIconsSorted(availableIcons))}
        {isMobile && navPanel}
        {isMobile && isFunction(onChangeCode) && codePanel}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (threeDotsRef.current) {
      if (!show) {
        threeDotsRef.current.hide();
        return;
      }

      threeDotsRef.current.showPopup(
        { left: popupOptions?.left, top: popupOptions?.top },
        <div className="search-panel__popup-tree-dots">
          {textMode ? renderRowContent() : renderIcons(hiddenIcons)}

          {searchParams?.booksExtras?.query && (
            <div
              className={classNames("search-text-label", {
                active: searchType === "book",
              })}
              onClick={() => handleSearch("book")}>
              {t("search_in")}&nbsp;{searchParams?.booksExtras?.bookCode}:&nbsp;
              {searchParams?.booksExtras?.query}
            </div>
          )}
          {searchQuery && (
            <div
              className={classNames("search-text-label", {
                active: searchType === "text",
              })}
              onClick={() => handleSearch("text")}>
              {t("searchText")}:&nbsp;{searchQuery}
            </div>
          )}
        </div>,
        {
          onShow: () => {
            setShow(true);
          },
          onHide: () => {
            setShow(false);
          },
          parentRect: popupOptions?.bodyRect,
          isScrollWrapperNeeded: false,
          hideOnBlur: false,
          useFocus: false,
        },
      );
    }
  }, [textMode, popupOptions, show, hiddenIcons]);

  const renderContent = () => {
    if (textMode) {
      return (
        <React.Fragment>
          {isRightPanel ? (
            <IconButton
              active={show}
              icon={EgwWebFont["dots-vertical"]}
              onClick={openTreeDotsPopup}
            />
          ) : (
            <TextButton
              className={classnames("show-popup-text-button", {
                active: show,
              })}
              onClick={openTreeDotsPopup}>
              {t("searchTools")}
            </TextButton>
          )}
          {!isRightPanel && !isMobile && (
            <div className="panel-base">
              {navPanel}
              {!isCompactView && isFunction(onChangeCode) && codePanel}
            </div>
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {isRightPanel && visibleIcons.length > 0 && (
          <div className="panel-base">{renderIcons(visibleIcons)}</div>
        )}
        {needShowTreeDots && (
          <IconButton
            active={show}
            icon={EgwWebFont["dots-vertical"]}
            onClick={openTreeDotsPopup}
          />
        )}
        {!isRightPanel && visibleIcons.length > 0 && (
          <div className="panel-base">{renderIcons(visibleIcons)}</div>
        )}
      </React.Fragment>
    );
  };

  if (isRightPanel) {
    return (
      <PopupWrap ref={threeDotsRef}>
        <div className="search-top-panel-wrapper">{renderContent()}</div>
      </PopupWrap>
    );
  }

  return (
    <div data-search-panel={true} className="searchPanel">
      <PopupWrap ref={threeDotsRef}>
        <div className="searchPanelLeft">{renderContent()}</div>
      </PopupWrap>
    </div>
  );
};

SearchTopPanel.defaultProps = {
  isRightPanel: false,
  width: window.innerWidth,
};

SearchTopPanel.propTypes = {
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.number,
  onChangePosition: PropTypes.func,
  onChangeOrder: PropTypes.func,
  onChangeQueryType: PropTypes.func,
  onChangeCode: PropTypes.func,
  onChangeSnippet: PropTypes.func,
  onChangePeriod: PropTypes.func,
  onClose: PropTypes.func,
  currentRow: PropTypes.number,
  order: PropTypes.string,
  loaded: PropTypes.array,
  orderOptions: PropTypes.array,
  snippet: PropTypes.string,
  snippetOptions: PropTypes.array,
  period: PropTypes.string,
  isRightPanel: PropTypes.bool,
  isLocal: PropTypes.bool,
  width: PropTypes.number,
  onChangeTargetBible: PropTypes.func,
  onChangeDictionaryPeriod: PropTypes.func,
  isHistorySearch: PropTypes.bool,
};

export default withResizeDetector(SearchTopPanel);
