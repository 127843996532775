import React from "react";
import Layout2 from "./Layout2";
import Layout3 from "./Layout3";
import { layoutIds } from "./LayoutUtils";
import ResizerWrap from "./ResizeWrap";
import PropTypes from "prop-types";

const Layout5 = ({ panelIds, id, onRender }) => {
  const [id0, id1, id2, id3, id4] = panelIds;
  switch (id) {
    case layoutIds.l5s122v:
      return (
        <ResizerWrap weight={[2, 1]}>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s12v} onRender={onRender} />
          <Layout2 panelIds={[id3, id4]} onRender={onRender} vertical />
        </ResizerWrap>
      );

    case layoutIds.l5s212:
      return (
        <ResizerWrap weight={[2, 1]} vertical>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s21} onRender={onRender} />
          <Layout2 panelIds={[id3, id4]} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l5s212v:
      return (
        <ResizerWrap weight={[2, 1]}>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s21v} onRender={onRender} />
          <Layout2 panelIds={[id3, id4]} onRender={onRender} vertical />
        </ResizerWrap>
      );

    case layoutIds.l5s221:
      return (
        <ResizerWrap weight={[1, 2]} vertical>
          <Layout2 panelIds={[id0, id1]} onRender={onRender} />
          <Layout3 panelIds={[id2, id3, id4]} id={layoutIds.l3s21} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l5s221v:
      return (
        <ResizerWrap weight={[1, 2]}>
          <Layout2 panelIds={[id0, id1]} onRender={onRender} vertical />
          <Layout3 panelIds={[id2, id3, id4]} id={layoutIds.l3s21v} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l5s2111:
      return (
        <ResizerWrap vertical>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s21} onRender={onRender} />
          <Layout2 panelIds={[id3, id4]} onRender={onRender} vertical />
        </ResizerWrap>
      );

    case layoutIds.l5s2111v:
      return (
        <ResizerWrap vertical={false}>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s21v} onRender={onRender} />
          <Layout2 panelIds={[id3, id4]} onRender={onRender} />
        </ResizerWrap>
      );

    case layoutIds.l5s122:
    default:
      return (
        <ResizerWrap weight={[2, 1]} vertical>
          <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3s12} onRender={onRender} />
          <Layout2 panelIds={[id3, id4]} onRender={onRender} />
        </ResizerWrap>
      );
  }
};

Layout5.propTypes = {
  id: PropTypes.string,
  onRender: PropTypes.func,
  panelIds: PropTypes.array,
};

export default Layout5;
