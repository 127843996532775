import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { MENU_STATE } from "../utils/MenuItems";
import { Settings } from "../utils/Settings";
import { actionUpdateSettings } from "../redux/actions";
import { URLS } from "../shared/utils/url";

/**
 * determine if we are in fullscreen mode
 * @param {object} el
 */
export function isFullScreenElement(el) {
  if (el && el.current) {
    return Boolean(
      document.fullscreenElement === el.current ||
      document.mozFullScreenElement === el.current ||
      document.webkitFullscreenElement === el.current ||
      document.msFullscreenElement === el.current
    );
  }

  return Boolean(
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement ||
    document.fullscreen ||
    document.mozFullScreen ||
    document.webkitIsFullScreen ||
    document.fullScreenMode
  );
}

const useFullScreen = (element) => {
  const initialState = isFullScreenElement(element);
  const [fullscreen, setFullScreen] = useState(initialState);
  const location = useLocation();
  const dispatch = useDispatch();

  // access various open fullscreen methods
  const openFullScreen = () => {
    const el = (element && element.current) || document.documentElement;

    if (el.requestFullscreen) {
      return el.requestFullscreen();
    }
    if (el.mozRequestFullScreen) {
      return el.mozRequestFullScreen();
    }
    if (el.webkitRequestFullscreen) {
      return el.webkitRequestFullscreen();
    }
    if (el.msRequestFullscreen) {
      return el.msRequestFullscreen();
    }

    return null;
  };

  // access various exit fullscreen methods
  const closeFullScreen = () => {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    }
    if (document.mozCancelFullScreen) {
      return document.mozCancelFullScreen();
    }
    if (document.webkitExitFullscreen) {
      return document.webkitExitFullscreen();
    }
    if (document.msExitFullscreen) {
      return document.msExitFullscreen();
    }

    return null;
  };

  const closePanels = () => {
    if (location.pathname === URLS.read) {
      dispatch(actionUpdateSettings({
        [Settings.isShowRightPanel.id]: false,
        [Settings.menuState.id]: MENU_STATE.COLLAPSE
      }));
    }
  };

  useEffect(() => {
    function handleChange() {
      setFullScreen(isFullScreenElement(element));
      closePanels();
    }

    document.addEventListener("webkitfullscreenchange", handleChange, false);
    document.addEventListener("mozfullscreenchange", handleChange, false);
    document.addEventListener("msfullscreenchange", handleChange, false);
    document.addEventListener("MSFullscreenChange", handleChange, false); // IE11
    document.addEventListener("fullscreenchange", handleChange, false);

    return () => {
      document.removeEventListener("webkitfullscreenchange", handleChange);
      document.removeEventListener("mozfullscreenchange", handleChange);
      document.removeEventListener("msfullscreenchange", handleChange);
      document.removeEventListener("MSFullscreenChange", handleChange);
      document.removeEventListener("fullscreenchange", handleChange);
    };
  }, [element]);

  return {
    fullscreen,
    open: openFullScreen,
    close: closeFullScreen,
    toggle: fullscreen ? closeFullScreen : openFullScreen
  };
};

export default useFullScreen;
