import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { fetchAllBooksData } from "../../redux/library.actions";
import { HistoryType } from "../../utils/Utils";
import { BookListType } from "../book/BooksUtils";
import { withLogin } from "../../utils/HOC";
import { makeNavigationUrl } from "../../utils/URLUtils";
import BookList from "../book/BookList";
import { LanguageFolders } from "../index";
import LibraryHint from "./LibraryHint";
import { main, RouteType } from "../../utils/RouterUtils";
import { menuItems } from "../../utils/MenuItems";
import { useNavigationData } from "../NavigationContext";
import { usePopupContext } from "../popup/PopupContext";
import { libraryBooksSelector } from "src/redux/selectors";

import "./MyLibraryPage.scss";

const MyLibraryPage = ({ match, location }) => {
  const { key } = location;
  const { lang } = match.params;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showPopup, hidePopup } = usePopupContext();
  const booksFromLibrarySorted = useSelector(libraryBooksSelector);
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const isLibraryFirstOpened = useSelector((state) => state.settings.libraryFirstOpen);
  // for check change route parameter withoit change of url EGWW-2762
  const mainTree = useSelector((state) => state.mainTree.mainTree);

  const currentLang = lang || libraryLanguages[0];
  const isOneLibraryLanguage = libraryLanguages.length === 1;

  useEffect(() => {
    dispatch(fetchAllBooksData([HistoryType.LIBRARY]));
  }, [key]); // fetch new data for sync with other devices

  useEffect(() => {
    if (isLibraryFirstOpened) {
      showPopup({ top: 0, left: 0 }, <LibraryHint />, {
        backStyle: {
          bottom: 0,
          right: 0,
        },
        popupProps: {
          className: "lib-hint-container__popup",
        },
      });
    }

    return () => {
      hidePopup();
    };
  }, [isLibraryFirstOpened]);

  const booksCountByLang = useMemo(() => {
    const booksByLang = {};
    booksFromLibrarySorted.forEach((item) => {
      if (!booksByLang[item.lang]) {
        booksByLang[item.lang] = 0;
      }
      booksByLang[item.lang]++;
    });
    return booksByLang;
  }, [booksFromLibrarySorted]);

  const navigationParams = useMemo(() => {
    const nodeList = [
      main,
      {
        ...menuItems.myLibrary,
        children: mainTree.filter((elem) => libraryLanguages.includes(elem.code)),
      },
    ];
    let routeType = RouteType.folders;
    if (lang) {
      const langNode = mainTree.find((item) => item.id === lang);
      if (langNode) {
        routeType = RouteType.books;
        nodeList.push(langNode);
      }
    } else if (libraryLanguages.length === 1) {
      routeType = RouteType.books;
    }

    return { nodeList, routeType };
  }, [lang, libraryLanguages, mainTree]);

  useNavigationData(navigationParams);

  if (lang || isOneLibraryLanguage) {
    const bookFromLibraryLangFiltered = booksFromLibrarySorted.filter((item) =>
      item.bookInfo ? item.bookInfo.lang === currentLang : false,
    );

    if (bookFromLibraryLangFiltered.length > 0) {
      return (
        <BookList
          isShowFilterAZ
          isShownFormatIcon={false}
          bookListType={BookListType.allWithHistory}
          books={bookFromLibraryLangFiltered}
        />
      );
    }

    return (
      <div className="loading">
        <p className="library-text">{t("noLibraryBooks")}</p>
        <Link
          className="library-link"
          to={makeNavigationUrl({ className: "language", id: currentLang })}>
          {t("addBooks")}
        </Link>
      </div>
    );
  }

  return (
    <LanguageFolders
      isLibrary
      firstOpen={isLibraryFirstOpened}
      booksCountByLang={booksCountByLang}
    />
  );
};

MyLibraryPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default withLogin(MyLibraryPage);
