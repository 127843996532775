export const formatContent = (data) => {
  let formatedContent = [];
  for (let index = 0; index < data.length; index++) {
    formatedContent.push({
      bookType: "book",
      className: "para",
      content: data[index].content,
      element_subtype: "",
      element_type: data[index].tag,
      id: data[index].key,
      id_next: index + 1 === data.length ? "" : data[index].key,
      id_prev: index === 0 ? null : data[index].key,
      label: data[index].reference,
      para_id: data[index].key,
      parent: data[0].key,
      puborder: data[index].position,
      original: data[index].original,
      refcode_short: data[index].reference,
      refcode_1: data[index].workAbbreviation,
      refcode_2: data[index].reference.replace(/[^\d.-]/g, ""),
      refcode_3: "",
      refcode_4: "",
      position: data[index].position,
      lastModified: data[index].lastModified
    });
  }
  return formatedContent;
};

export const makeTranslateBookLoader = (bookId) => "E4A_BOOK_LOAD_" + bookId;

// export const ELLEN4ALL_URL = "https://langify-langify-server-staging.code.communitybuildar.com";
export const ELLEN4ALL_URL = "https://api.ellen4all.org";
export const ELLEN4ALL_TOKEN_KEY = "ellen4AllToken";
