import React from "react";
import { useTranslation } from "react-i18next";

import "./NoDataTemplate.scss";

const NoDataTemplate = () => {
  const { t } = useTranslation();

  return (
    <div className="no-data-template">
      <h3 className="no-data-template_title">{t("noDataTitle")}</h3>
      <p className="no-data-template_description">{t("noDataDesc")}</p>
    </div>
  );
};

export default NoDataTemplate;
