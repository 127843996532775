import {
  ACTION_AUTH_USER,
  ACTION_INIT_APP_LOAD,
  ACTION_INIT_APP_LOAD_SUCCESS,
  ACTION_LOGIN,
  ACTION_LOGOUT,
  ACTION_LOGIN_SUCCESS,
  ACTION_CHECK_TREE,
  ACTION_CHANGE_LANG,
  FETCH_DIALOG_CONTENTS,
  FETCH_DIALOG_CONTENTS_SUCCESS,
  ACTION_SET_AUDIO_PLAYER_DATA,
  ACTION_FETCH_AUDIO_WAVE,
  ACTION_PUT_AUDIO_WAVE,
  ACTION_SET_TOP_PANEL_VISIBILITY,
  ACTION_UPDATE_DATA_ACQUISITION_STATUS,
  COLLAPSE_TREE_ELEMENT,
  FETCH_GEO_IP_DATA_SUCCESS,
  ACTION_LOADING,
  ACTION_SET_BROKEN_AUDIOS,
  ACTION_ADD_MESSAGE,
  ACTION_FETCH_CF,
  ACTION_FETCH_CF_SUCCESS,
  ACTION_SEND_CF,
  ACTION_SEND_CF_STATUS,
  ACTION_PUT_INFO,
  ACTION_COPY,
  ACTION_UPDATE_SETTING,
  ACTION_UPDATE_SETTINGS,
  ACTION_UPDATE_SETTING_SUCCESS,
  ACTION_NAVIGATE_BY_ID,
  ACTION_SET_CONTENT_ID,
  ACTION_UPDATE_THEME,
  FETCH_ABBREVIATIONS,
  FETCH_ABBREVIATIONS_SUCCESS,
  ACTION_SET_NEXT_ROUTE,
  ACTION_SET_SCREEN_TYPE,
  ACTION_TOGGLE_SHOW_CART,
  ACTION_SET_POPUP_ID,
  ACTION_CREATE_WORKSPACE,
  ACTION_DELETE_WORKSPACE,
  ACTION_UPDATE_WORKSPACE,
  FETCH_TRANSLATION_BOOK,
  FETCH_TRANSLATION_BOOK_SUCESS,
  FETCH_SEGMENTS_TRANSLATION,
  FETCH_SEGMENTS_TRANSLATION_SUCCESS,
  FETCH_AVAILABLE_LANGUAGES,
  FETCH_AVAILABLE_LANGUAGES_SUCCESS,
  ACTION_SET_ELLEN4ALL_TOKEN,
} from "./constants";
import { TOAST_TYPE } from "../components/popup/Toaster";

export const actionAuthUser = (data) => ({
  type: ACTION_AUTH_USER,
  data,
});

export const actionInitAppLoad = (data) => ({
  type: ACTION_INIT_APP_LOAD,
  data,
});

export const actionInitAppLoadSuccess = (data) => ({
  type: ACTION_INIT_APP_LOAD_SUCCESS,
  data,
});

export const actionSetEllen4AllToken = (data) => ({
  type: ACTION_SET_ELLEN4ALL_TOKEN,
  data,
});

export const actionGetSegments = (segmentID, paraID) => ({
  type: FETCH_SEGMENTS_TRANSLATION,
  data: { segmentID, paraID },
});

export const actionGetSegmentsTranslationSuccess = (data) => ({
  type: FETCH_SEGMENTS_TRANSLATION_SUCCESS,
  data,
});

export const actionGetAvailableLanguages = (bookId) => ({
  type: FETCH_AVAILABLE_LANGUAGES,
  data: bookId,
});

export const actionGetAvailableLanguagesSuccess = (bookId, content) => ({
  type: FETCH_AVAILABLE_LANGUAGES_SUCCESS,
  data: { bookId, content },
});

export const actionGetTranslations = (language) => ({
  type: FETCH_TRANSLATION_BOOK,
  data: language ,
});

export const actionGetTranslationsSuccess = (data) => ({
  type: FETCH_TRANSLATION_BOOK_SUCESS,
  data: data,
});

export const onLoginAction = (token, refreshToken) => ({
  type: ACTION_LOGIN,
  data: { token, refreshToken },
});

export const onLoginSuccess = (data) => ({
  type: ACTION_LOGIN_SUCCESS,
  data,
});

export const onLogout = () => ({
  type: ACTION_LOGOUT,
});

export const fetchDialogContents = (chapterIds, bookRealType, bookType) => ({
  type: FETCH_DIALOG_CONTENTS,
  data: { chapterIds, bookRealType, bookType },
});

export const fetchDialogContentsSuccess = (content) => ({
  type: FETCH_DIALOG_CONTENTS_SUCCESS,
  data: content,
});

export const actionCheckTree = (data, options = {}) => ({
  type: ACTION_CHECK_TREE,
  data,
  options: { drop: false, ...options },
});

export const actionChangeLang = (data) => ({
  type: ACTION_CHANGE_LANG,
  data,
});

export const collapseTreeElement = (data) => ({
  type: COLLAPSE_TREE_ELEMENT,
  data,
});

export const setPlayerParams = (data) => ({
  type: ACTION_SET_AUDIO_PLAYER_DATA,
  data,
});

export const fetchAudioTrack = (url, chapterId) => ({
  type: ACTION_FETCH_AUDIO_WAVE,
  data: {
    url,
    chapterId,
  },
});

export const putAudioTrack = (data) => ({
  type: ACTION_PUT_AUDIO_WAVE,
  data,
});

export const setBrokenAudios = (data) => ({
  type: ACTION_SET_BROKEN_AUDIOS,
  data,
});

export const changeTopPanelVisibility = (isVisible) => ({
  type: ACTION_SET_TOP_PANEL_VISIBILITY,
  data: isVisible,
});

export const actionSetScreenType = (screenType) => ({
  type: ACTION_SET_SCREEN_TYPE,
  data: screenType,
});

export const actionUpdateDataAcquisitionStatus = (data) => ({
  type: ACTION_UPDATE_DATA_ACQUISITION_STATUS,
  data,
});

export const actionFetchGeoIpDataSuccess = (data) => ({
  type: FETCH_GEO_IP_DATA_SUCCESS,
  data,
});

export const actionLoading = (loadingId, forceRemove = false) => ({
  type: ACTION_LOADING,
  data: { loadingId, forceRemove },
});

/**
 *
 * @param {*} message can start with @ and be translated
 * @param {*} type {@see TOAST_TYPE}
 */
export const actionAddMessage = (message, type = TOAST_TYPE.notify) => ({
  type: ACTION_ADD_MESSAGE,
  data: { message, type },
});

export const actionFetchCF = () => ({
  type: ACTION_FETCH_CF,
});

export const actionFetchCFSuccess = (data) => ({
  type: ACTION_FETCH_CF_SUCCESS,
  data,
});

export const actionSendCF = (data) => ({
  type: ACTION_SEND_CF,
  data,
});

export const actionSendCFStatus = (data) => ({
  type: ACTION_SEND_CF_STATUS,
  data,
});

export const actionPutInfo = (data) => ({
  type: ACTION_PUT_INFO,
  data,
});

export const actionCopy = (text, options) => ({
  type: ACTION_COPY,
  data: { text, options },
});

export const actionUpdateTheme = (data) => ({
  type: ACTION_UPDATE_THEME,
  data,
});

/**
 *
 * @param {*} key e.g. Settings.readerFontFamily.id
 * @param {*} value
 */
export const actionUpdateSetting = (key, value) => ({
  type: ACTION_UPDATE_SETTING,
  data: { [key]: value },
});

export const actionUpdateSettings = (data) => ({
  type: ACTION_UPDATE_SETTINGS,
  data,
});

export const actionUpdateSettingSuccess = (data) => ({
  type: ACTION_UPDATE_SETTING_SUCCESS,
  data,
});

export const actionSetContentId = (data) => ({
  type: ACTION_SET_CONTENT_ID,
  data,
});

export const actionNavigateById = (data) => ({
  type: ACTION_NAVIGATE_BY_ID,
  data,
});

export const fetchAbbreviations = () => ({
  type: FETCH_ABBREVIATIONS,
});

export const fetchAbbreviationsSuccess = (data) => ({
  type: FETCH_ABBREVIATIONS_SUCCESS,
  data,
});


export const actionSetNextRoute = (url, options = {}) => ({
  type: ACTION_SET_NEXT_ROUTE,
  data: { url, ...options },
});

export const toggleShowCart = () => ({
  type: ACTION_TOGGLE_SHOW_CART,
});

export const actionSetPopupId = (data) => ({
  type: ACTION_SET_POPUP_ID,
  data,
});

export const actionCreateWorkspace = (data) => ({
  type: ACTION_CREATE_WORKSPACE,
  data,
});

export const actionUpdateWorkspace = (data) => ({
  type: ACTION_UPDATE_WORKSPACE,
  data,
});

export const actionDeleteWorkspace = (data) => ({
  type: ACTION_DELETE_WORKSPACE,
  data,
});
