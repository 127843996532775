import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { usePopupId } from "src/hooks";
import { categoryTreeActions } from "src/redux/categoryTree/actions";
import { titlesActions } from "../../redux/titleTree/actions";
import { usePopupContext } from "../popup/PopupContext";
import NestedDropdown from "../views/NestedDropdown/NestedDropdown";
import { getObjectKeysNotNullable } from "../../utils/Utils";
import RadioGroup from "../views/RadioGroup";
import Checkbox from "../views/Checkbox";
import FiltersLanguageSelector from "../languageSelector/FiltersLanguageSelector";
import { useNavigationContext } from "../NavigationContext";
import { BooksFiltersItems, FilterTypes } from "../../utils/FilterBooksUtils";
import { folderTreeActions } from "../../redux/folderTree/actions";

import "./PopupBooksFilters.scss";

const PopupBooksFilters = ({
  selectorFilters, dispatchActionFnUpdateFilters, idsHiddenFilters, idsLanguagesDisabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(selectorFilters);

  const handleFilterChange = useCallback((key, payload) => {
    dispatch(dispatchActionFnUpdateFilters({ key, payload }));
  }, [dispatchActionFnUpdateFilters]);

  const itemsWithImplementedChildrenProperty = useMemo(() => {
    return (
      idsHiddenFilters
        ? BooksFiltersItems.filter((filterItem) => !idsHiddenFilters.includes(filterItem.id))
        : [...BooksFiltersItems]
    ).map((filterItem) => {
      const { filterType, id, filterItems } = filterItem;

      if (filterType === FilterTypes.languages) {
        filterItem.children = (
          <FiltersLanguageSelector
            itemsChecked={filters.languages}
            itemsDisabled={idsLanguagesDisabled}
            onClickItem={(e, item) => {
              const itemId = item.id;

              const languagesNew = [...filters.languages];
              const itemIndex = languagesNew.indexOf(itemId);

              if (itemIndex === -1) {
                languagesNew.push(itemId);
              } else {
                languagesNew.splice(itemIndex, 1);
              }

              handleFilterChange(id, languagesNew);
            }}
          />
        );
      }

      if (filterType === FilterTypes.radiobutton) {
        filterItem.children = (
          <RadioGroup
            withTranslation
            value={filters[id]}
            options={filterItems}
            onChange={(radioId) => handleFilterChange(id, radioId)}
          />
        );
      }

      if (filterType === FilterTypes.checkbox) {
        filterItem.children = (
          <>
            {
              filterItems.map((checkbox) => {
                const checkboxId = checkbox.id;
                const filterKey = id + "." + checkboxId;

                return (
                  <Checkbox
                    key={checkboxId}
                    id={checkboxId}
                    title={t(checkbox.label, checkbox.translationKeys)}
                    checked={!!filters[id][checkboxId]}
                    onChange={(stateNew) => handleFilterChange(filterKey, stateNew)}
                  />
                );
              })
            }
          </>
        );
      }

      return filterItem;
    });
  }, [t, filters, handleFilterChange, idsHiddenFilters, idsLanguagesDisabled]);

  return (
    <NestedDropdown
      dropDownClassName="categories-filter"
      items={itemsWithImplementedChildrenProperty}
    />
  );
};

PopupBooksFilters.propTypes = {
  selectorFilters: PropTypes.func,
  dispatchActionFnUpdateFilters: PropTypes.func,
  idsHiddenFilters: PropTypes.array,
  idsLanguagesDisabled: PropTypes.array,
};

const POPUP_ID = "books-filters";

const _usePopupBooksFilters = (selectorFilters, dispatchActionFnUpdateFilters) => {
  const { currentFolder } = useNavigationContext();

  const idsHiddenFilters = currentFolder?.idsHiddenFilters;
  const idsLanguagesDisabled = currentFolder?.idsLanguagesDisabled;
  const { showPopup, hidePopup } = usePopupContext();
  const isPopupShown = usePopupId(POPUP_ID);
  const filters = useSelector(selectorFilters);
  const isAnyFilterApplied = !!(
    getObjectKeysNotNullable(filters.years).length
    || filters.format
    || filters.sortingFilter
    || filters.languages.length
  );

  const openPopupFilters = useCallback(
    (e) => {
      if (isPopupShown) {
        hidePopup();
      } else {
        const targetRect = e.target.getBoundingClientRect();
        const target = e.target;

        showPopup(targetRect, (
          <PopupBooksFilters
            selectorFilters={selectorFilters}
            dispatchActionFnUpdateFilters={dispatchActionFnUpdateFilters}
            idsHiddenFilters={idsHiddenFilters}
            idsLanguagesDisabled={idsLanguagesDisabled}
          />
        ), {
          isScrollWrapperNeeded: false,
          hideOnBlur: false,
          fitPosition: true,
          popupId: POPUP_ID,
          targetElement: target,
        });
      }
    },
    [
      idsHiddenFilters, idsLanguagesDisabled, selectorFilters, dispatchActionFnUpdateFilters,
      isPopupShown, showPopup, hidePopup
    ],
  );

  return {
    openPopupFilters,
    isAnyFilterApplied,
  };
};

const selectorCategoryTreeFilters = (state) => state.categoryTree.filters;

export const usePopupCategoryTreeFilters = () => {
  return _usePopupBooksFilters(selectorCategoryTreeFilters, categoryTreeActions.updateFilters);
};

const selectorTitleTreeFilters = (state) => state.titleTree.filters;

export const usePopupTitleTreeFilters = () => {
  return _usePopupBooksFilters(selectorTitleTreeFilters, titlesActions.updateFilters);
};

const selectorAllCollectionAudioBooksFilters = (state) => state.folderTree.filters;

export const usePopupAllCollectionAudioBooksFilters = () => {
  return _usePopupBooksFilters(
    selectorAllCollectionAudioBooksFilters,
    folderTreeActions.updateFilters,
  );
};

