// This player was taken here: https://github.com/justinmc/react-audio-player/blob/master/src/index.jsx
// It is put here as source because of little changes needs to be made inside component

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AudioPlayer extends Component {

  componentDidMount() {
    const audio = this.audioEl;

    this.updateVolume(this.props.volume);

    audio.addEventListener('error', (e) => {
      this.props.onError(e);
    });

    // When enough of the file has downloaded to start playing
    audio.addEventListener('canplay', (e) => {
      this.props.onCanPlay(e);
    });

    // When enough of the file has downloaded to play the entire file
    audio.addEventListener('canplaythrough', (e) => {
      this.props.onCanPlayThrough(e);
    });

    // When audio play starts
    audio.addEventListener('play', (e) => {
      // this.setListenTrack();
      this.props.onPlay(e);
    });

    // When unloading the audio player (switching to another src)
    // audio.addEventListener('abort', (e) => {
    //   this.clearListenTrack();
    //   this.props.onAbort(e);
    // });

    // When the file has finished playing to the end
    audio.addEventListener('ended', (e) => {
      // this.clearListenTrack();
      this.props.onEnded(e);
    });

    // When the user pauses playback
    audio.addEventListener('pause', (e) => {
      // this.clearListenTrack();
      this.props.onPause(e);
    });

    // When the user drags the time indicator to a new time
    audio.addEventListener('seeked', (e) => {
      this.props.onSeeked(e);
    });

    audio.addEventListener('loadeddata', (e) => {
      this.props.onLoadedData(e);
    });

    audio.addEventListener('loadedmetadata', (e) => {
      this.props.onLoadedMetadata(e);
    });

    audio.addEventListener('volumechange', (e) => {
      this.props.onVolumeChanged(e);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateVolume(this.props.volume);
    }
  }

  /**
   * Set an interval to call props.onListen every props.listenInterval time period
   */
  // setListenTrack() {
  //   if (!this.listenTracker) {
  //     const listenInterval = this.props.listenInterval;
  //     this.listenTracker = setInterval(() => {
  //       if (!this.audioEl) return;
  //       this.props.onListen(this.audioEl.currentTime);
  //     }, listenInterval);
  //   }
  // }

  /**
   * Set the volume on the audio element from props
   * @param {Number} volume
   */
  updateVolume(volume) {
    if (typeof volume === 'number' && volume !== this.audioEl.volume) {
      this.audioEl.volume = volume;
    }
  }

  /**
   * Clear the onListen interval
   */
  // clearListenTrack() {
  //   if (this.listenTracker) {
  //     clearInterval(this.listenTracker);
  //     this.listenTracker = null;
  //   }
  // }

  render() {
    // Set controls to be true by default unless explicity stated otherwise
    const controls = !(this.props.controls === false);

    // Set lockscreen / process audio title on devices
    const title = this.props.title ? this.props.title : this.props.src;

    // Some props should only be added if specified
    const conditionalProps = {};
    if (this.props.controlsList) {
      conditionalProps.controlsList = this.props.controlsList;
    }

    return (
      <audio
        autoPlay={this.props.autoPlay}
        className={`react-audio-player ${this.props.className}`}
        controls={controls}
        crossOrigin={this.props.crossOrigin}
        id={this.props.id}
        loop={this.props.loop}
        muted={this.props.muted}
        onPlay={this.onPlay}
        preload={this.props.preload}
        ref={(ref) => {
          this.audioEl = ref;
        }}
        src={this.props.src}
        style={this.props.style}
        title={title}
        {...conditionalProps}
      />
    );
  }
}

AudioPlayer.defaultProps = {
  autoPlay: false,
  children: null,
  className: '',
  controls: false,
  controlsList: '',
  crossOrigin: null,
  id: '',
  listenInterval: 10000,
  loop: false,
  muted: false,
  onAbort: () => { },
  onCanPlay: () => { },
  onCanPlayThrough: () => { },
  onEnded: () => { },
  onError: () => { },
  onListen: () => { },
  onPause: () => { },
  onPlay: () => { },
  onSeeked: () => { },
  onVolumeChanged: () => { },
  onLoadedMetadata: () => { },
  onLoadedData: () => { },
  preload: 'metadata',
  src: null,
  style: {},
  title: '',
  volume: 1.0,
};

AudioPlayer.propTypes = {
  autoPlay: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string,
  controls: PropTypes.bool,
  controlsList: PropTypes.string,
  crossOrigin: PropTypes.string,
  id: PropTypes.string,
  listenInterval: PropTypes.number,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  onAbort: PropTypes.func,
  onCanPlay: PropTypes.func,
  onCanPlayThrough: PropTypes.func,
  onEnded: PropTypes.func,
  onError: PropTypes.func,
  onListen: PropTypes.func,
  onLoadedData: PropTypes.func,
  onLoadedMetadata: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onSeeked: PropTypes.func,
  onVolumeChanged: PropTypes.func,
  preload: PropTypes.oneOf(['', 'none', 'metadata', 'auto']),
  src: PropTypes.string, // Not required b/c can use <source>
  style: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  volume: PropTypes.number,
};

export default AudioPlayer;
