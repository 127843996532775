import { getDateLatest, getObjectKeysNotNullable } from "./Utils";
import { isAfter } from "date-fns";

export const FilterTypes = {
  checkbox: "check",
  radiobutton: "radio",
  languages: "languages"
};

export const BookFormats = {
  pdf: "pdf",
  mp3: "mp3",
  ePub: "epub",
  mobi: "mobi",
};

export const BookFilterFormatIds = {
  all: "",
  ...BookFormats,
};

const BookFilterFormat = [
  {
    id: BookFilterFormatIds.all,
    label: "all",
    translationKeys: null,
    type: "radio"
  },
  {
    id: BookFilterFormatIds.pdf,
    label: "pdf",
    translationKeys: null,
    type: "radio"
  },
  {
    id: BookFilterFormatIds.mp3,
    label: "mp3",
    translationKeys: null,
    type: "radio"
  },
  {
    id: BookFilterFormatIds.ePub,
    label: "ePub",
    translationKeys: null,
    type: "radio"
  },
  {
    id: BookFilterFormatIds.mobi,
    label: "kindle",
    translationKeys: null,
    type: "radio"
  }
];

export const FILTER_YEAR_LABEL_PRESENT = "PRESENT";

const BookFilterYear = [
  {
    id: "1845-1917",
    label: "lifetime",
    translationKeys: null,
    type: "check"
  },
  {
    id: `1918-${FILTER_YEAR_LABEL_PRESENT}`,
    label: "compilations",
    translationKeys: null,
    type: "check"
  }
];

const BookFilterSort = [
  {
    id: "asc",
    label: "sortAscending",
    translationKeys: null,
    type: "radio"
  },
  {
    id: "desc",
    label: "SortDescending",
    translationKeys: null,
    type: "radio"
  }
];

export const BooksFiltersItemsIds = {
  languages: "languages",
  format: "format",
  years: "years",
  sortingFilter: "sortingFilter",
};

export const BooksFiltersItems = [
  {
    id: BooksFiltersItemsIds.languages,
    title: "languages",
    children: null,

    filterItems: [],
    filterType: FilterTypes.languages
  },
  {
    id: BooksFiltersItemsIds.format,
    title: "format",
    children: null,

    filterItems: BookFilterFormat,
    filterType: FilterTypes.radiobutton
  },
  {
    id: BooksFiltersItemsIds.years,
    title: "categoryYear",
    children: null,

    filterItems: BookFilterYear,
    filterType: FilterTypes.checkbox
  },
  {
    id: BooksFiltersItemsIds.sortingFilter,
    title: "categorySorting",
    children: null,

    filterItems: BookFilterSort,
    filterType: FilterTypes.radiobutton
  }
];

export const BooksFiltersStoreDefault = {
  languages: [],
  years: {},
  format: "",
  sortingFilter: "",
};

// "Standardized" function to update books filters in the store.
export const updateBooksFiltersStore = (state, actionData) => {
  const {key, payload} = actionData;
  const [keyOfFilter, keyOfFilterInnerProperty] = key.split(".");

  const filtersNew = {...state.filters};

  if (keyOfFilterInnerProperty) {
    const filterPropertyStateOld = filtersNew[keyOfFilter][keyOfFilterInnerProperty];
    filtersNew[keyOfFilter][keyOfFilterInnerProperty] = !filterPropertyStateOld;
  } else {
    filtersNew[keyOfFilter] = payload;
  }

  return {
    ...state,
    filters: filtersNew,
  };
};

/*** FILTERS ***/
const getIsBookValidByFilterType = (book, bookTypeFilters) => {
  let isValid = true;
  if (bookTypeFilters.showReadingHistory && !bookTypeFilters.showListenHistory) {
    isValid = !book.is_audiobook;
  }
  if (!bookTypeFilters.showReadingHistory && bookTypeFilters.showListenHistory) {
    isValid = book.is_audiobook;
  }
  return isValid;
};

export const getIsBookValidByFilterLang = (book, libraryLanguages) => {
  if (!book) {
    return false;
  }

  if (libraryLanguages.length) {
    return libraryLanguages.includes(book.lang);
  }

  return true;
};

const getIsBookValidByFilterYears = (book, years) => {
  if (!years || !years.length) {
    return true;
  }

  return years.find((yearRange) => {
    const [from, to] = yearRange.split("-");

    return (
      book.pubYear >= parseInt(from)
      && (to === FILTER_YEAR_LABEL_PRESENT || book.pubYear <= parseInt(to))
    );
  });
};

const getIsBookValidByFilterFormat = (book, format) => format ? !!book.files[format] : true;

export const getIsBookValidByFilterChar = (book, char) => {
  return char ? (book && (book.bookInfo?.firstChar || book.firstChar) === char) : true;
};

export const createPredicateFilterBooks = (bookTypeFilters, {
  years,
  format,
  languages,
}) => {
  const filterYears = years ? getObjectKeysNotNullable(years) : undefined;

  return (book) => {
    return (
      (languages?.length ? getIsBookValidByFilterLang(book, languages) : true)
      && (bookTypeFilters ? getIsBookValidByFilterType(book, bookTypeFilters) : true)
      && (filterYears?.length ? getIsBookValidByFilterYears(book, filterYears) : true)
      && (format ? getIsBookValidByFilterFormat(book, format) : true)
    );
  };
};

/*** SORT ***/
const createPredicateSortCategoryBooks = (filterSortType) => {
  const sortOrder = filterSortType === "asc" ? 1 : -1;
  return (bookLeft, bookRight) => {
    const property = "title";
    const bookLeftProperty = bookLeft[property];
    const bookRightProperty = bookRight[property];

    if (bookLeftProperty < bookRightProperty) {
      return -1 * sortOrder;
    }

    if (bookLeftProperty > bookRightProperty) {
      return 1 * sortOrder;
    }

    return 0;
  };
};

export const sortBooksByDateMyLibrary = (books) => {
  const mapItemToHistoryDateLatest = new Map();

  const getBookHistoryDateLatest = (book) => {
    let bookHistoryDateLatest;

    if (mapItemToHistoryDateLatest.has(book)) {
      bookHistoryDateLatest = mapItemToHistoryDateLatest.get(book);
    } else {
      bookHistoryDateLatest = getDateLatest([
        book.mlLu, book.luLibrary, book.luListen, book.luRead,
      ]);
      mapItemToHistoryDateLatest.set(book, bookHistoryDateLatest);
    }

    return bookHistoryDateLatest;
  };

  books.sort((bookPrev, bookNext) => {
    return isAfter(
      getBookHistoryDateLatest(bookPrev), getBookHistoryDateLatest(bookNext)
    ) ? -1 : 1;
  });
};

export const sortBookByLabel = (books) => {
  books.sort((prev, next) => {
    const prevInfo = prev.bookInfo;
    const nextInfo = next.bookInfo;
    if (!prevInfo || !nextInfo) {
      return 0;
    }
    return prevInfo.label.localeCompare(nextInfo.label);
  });
};

export const sortBooks = (books, languageDefault, filterSortType) => {
  if (filterSortType) {
    return books.sort(createPredicateSortCategoryBooks(filterSortType));
  }
  return books;
};
