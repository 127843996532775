import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { actionAddMessage } from "../redux/actions";
import { isRestricted, makeSearchUrl, parseSearchUrl } from "../utils/SearchUtils";
import { RESTRICTION_SEARCH_VALUE } from "../shared/utils/search";
import { makeChapterUrl, makeLinkValue, PanelTypes} from "../utils/URLUtils";
import { useNavigationContext } from "../components/NavigationContext";
import { initialSearchState } from "../components/search/search.reducer";
import { StudyCenterActions } from "src/components/studyCenter/studyCenter.actions";
import { SearchActions } from "src/components/search/search.actions";
import { clutch } from "../utils/NumberUtils";
import { URLS } from "../shared/utils/url";

const useSearchList = ({ initPosition, onUpdatePosition, currentSearchParams, isRightPanel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    panelIds,
    activeId,
    activeIndex,
    openGlobalSearchResult,
    isReader,
  } = useNavigationContext();
  const currentLocation = history.location.pathname + history.location.search;
  const [position, setPosition] = useState(initPosition || 1);
  const [readerPosition, setReaderPosition] = useState(undefined);
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);
  
  const results = useSelector((state) => state.search.search.results);
  const localResults = useSelector((state) => state.search.localSearch.results);

  const searchList = (currentSearchParams.isLocal ? localResults : results) ?? [];

  useEffect(() => {
    if ((initPosition !== undefined && position === 1) || initPosition === 1) {
      setPosition(initPosition);
    }
  }, [initPosition]);

  // if reader page -> set active element in search list
  useEffect(() => {
    if (readerPosition !== undefined && isReader) {
      const currentItem = searchList?.find((item) => activeId === item.para_id);
      if (currentItem) {
        _updateUrl(currentItem);
      }
    }
  }, [_updateUrl, activeId, searchList]);

  useEffect(() => {
    if ((initPosition !== undefined && position === 1) || initPosition === 1) {
      setPosition(initPosition);
    }
    setReaderPosition();
    let timeout;
    // TODO check needed or not
    if (!isRightPanel && history.location.pathname === URLS.search && history.location.search) {
      const searchData = parseSearchUrl(history.location.search);
      if (searchData && searchList.length) {
        setPosition(searchData.start);
        timeout = setTimeout(() => {
          history.replace(makeSearchUrl({ ...searchData }, collectionFilter));
        }, 200);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const _updateIndex = (index, diff = 0) => clutch(index + diff, 0, RESTRICTION_SEARCH_VALUE);
 

  const onChangePosition = useCallback(
    (value) => {
      if (isRestricted(value)) {
        dispatch(
          actionAddMessage(t("restrictionSearch", { count: `${RESTRICTION_SEARCH_VALUE}+` })),
        );
        return;
      }

      setPosition(value);
      if (onUpdatePosition) {
        dispatch(onUpdatePosition(value));
      }

      dispatch(SearchActions.fetchSearch(currentSearchParams, value));
    },
    [onUpdatePosition, searchList, currentSearchParams],
  );

  const onNavWithKeyboard = useCallback(
    (diff, withoutOpen = false) => {
      const currentItem = searchList.find((item) => item.index === position - 1);

      if (currentItem) {
        const nextIndex = _updateIndex(currentItem.index, diff);
        const nextItem = searchList[nextIndex];

        if (onUpdatePosition) {
          dispatch(onUpdatePosition(nextIndex + 1));
        }

        if (!withoutOpen) {
          openGlobalSearchResult(nextItem.para_id, true);
        }
      }
    },
    [searchList, position, onUpdatePosition, openGlobalSearchResult],
  );

  const _updateUrl = useCallback(
    (nextItem) => {
      const newActiveId = makeLinkValue(PanelTypes.PARA.id, nextItem?.para_id);
      const clonedPanelIds = [...panelIds];
      clonedPanelIds[activeIndex] = newActiveId;
      const newUrl = makeChapterUrl(clonedPanelIds, activeIndex);
      // for avoid stackowerflow
      if (currentLocation !== newUrl) {
        history.push(newUrl);
        setReaderPosition(nextItem.index);
        setPosition(nextItem.index);
        if (onUpdatePosition) {
          dispatch(onUpdatePosition(nextItem.index + 1));
        }
      }
    },
    [activeIndex, panelIds, onUpdatePosition, currentLocation],
  );

  const onNavByUrl = useCallback(
    (diff) => {
      if (!searchList.length) {
        return;
      }
      // disable navigation by study center elements if user navigate via search elements.
      // only one type of navigaton can be in one time
      dispatch(StudyCenterActions.setCurrentSCElement());
      if (!readerPosition) {
        const currentItem = searchList?.find((item) => activeId === item.para_id);

        if (currentItem) {
          const nextIndex = _updateIndex(currentItem.index, diff);
          const nextItem = searchList[nextIndex];

          if (!nextItem) {
            return;
          }

          _updateUrl(nextItem);
        } else {
          // if we don't have current item -> start from first list item
          _updateUrl(searchList?.[0]);
        }
      } else {
        const nextIndex = _updateIndex(searchList[readerPosition].index, diff);
        const nextItem = searchList[nextIndex];
        if (!nextItem) {
          return;
        }
        _updateUrl(nextItem);
      }
    },
    [searchList, activeId, _updateUrl],
  );

  const onSaveUrlPosition = useCallback(
    (elIndex) => {
      history.replace(makeSearchUrl({ ...currentSearchParams, start: elIndex }, collectionFilter));
    },
    [currentSearchParams, collectionFilter],
  );

  const onSearchFromScratch = useCallback(
    (type = "basic") => {
      setPosition(1);

      if (onUpdatePosition) {
        dispatch(onUpdatePosition(1));
      }

      const params = {
        ...currentSearchParams,
        folders: [],
        bookCode: "",
        books: [],
        extras: { ...currentSearchParams.extras, type },
      };
      dispatch(SearchActions.fetchSearchSuccess(initialSearchState));
      dispatch(SearchActions.fetchSearch(params));
    },
    [dispatch, onUpdatePosition, currentSearchParams],
  );

  return {
    searchList,
    position,
    onChangePosition,
    onSearchFromScratch,
    onNavWithKeyboard,
    onNavByUrl,
    onSaveUrlPosition,
  };
};

export default useSearchList;
