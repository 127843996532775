import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "../../assets/CommonIcons";
import { useTooltipContext } from "../popup/TooltipContext";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { actionAddMessage } from "../../redux/actions";
import { IconButton } from "./index";
import { clutch } from "../../utils/NumberUtils";

const SearchNavPanel = ({
  startValue,
  isMobileSearch,
  onChangeStartPosition,
  max,
  isInteractionDisabled,
  isHistorySearch,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showTooltip } = useTooltipContext();
  const [startInputValue, setStartInputValue] = useState(startValue);

  useEffect(() => {
    if (startInputValue !== startValue) {
      setStartInputValue(startValue);
    }
  }, [startValue]);

  const navStartHandler = (shift) => {
    onChangeStartPosition(getValueClutched(startInputValue + shift));
  };

  const onStartInputKeyPress = (event) => {
    if (isEventKey(event, KeyCodes.enter)) {
      if (startInputValue > max) {
        dispatch(actionAddMessage(t("bigSearchValue", { total: max })));
        setStartInputValue(startValue);
      } else {
        onChangeStartPosition(getValueClutched(startInputValue));
      }
    }
  };

  const getValueClutched = (value) => {
    return clutch(Number(value), 1, max);
  };

  const onStartValueChange = (e) => {
    const valueParsed = Number.parseInt(e.target.value, 10);
    setStartInputValue(isNaN(valueParsed) ? "" : valueParsed);
  };

  const disabledPrev = startValue < 10;
  const disabledNext = Math.abs(max - startValue) < 11;

  return (
    <span className={classNames("search-nav-panel", { "mobile-search": isMobileSearch })}>
      <IconButton
        showIcon
        icon={ChevronDoubleLeftIcon}
        title={t("goToPreviousResults", { count: 100 })}
        onClick={() => navStartHandler(-100)}
        disabled={isInteractionDisabled || disabledPrev}
      />
      {!isHistorySearch && (
        <IconButton
          showIcon
          icon={ChevronLeftIcon}
          title={t("goToPreviousResults", { count: 10 })}
          onClick={() => navStartHandler(-10)}
          disabled={isInteractionDisabled || disabledPrev}
        />
      )}
      <input
        disabled={isInteractionDisabled}
        className="search-page-value"
        onChange={onStartValueChange}
        onMouseEnter={(e) => {
          showTooltip(t("resultNumber", { count: e.target?.value }), e);
        }}
        type="number"
        onKeyPress={onStartInputKeyPress}
        value={startInputValue}
      />
      {!isHistorySearch && (
        <IconButton
          showIcon
          icon={ChevronRightIcon}
          title={t("goToNextResults", { count: 10 })}
          onClick={() => navStartHandler(+10)}
          disabled={isInteractionDisabled || disabledNext}
        />
      )}
      <IconButton
        showIcon
        icon={ChevronDoubleRightIcon}
        title={t("goToNextResults", { count: 100 })}
        onClick={() => navStartHandler(+100)}
        disabled={isInteractionDisabled || disabledNext}
      />
    </span>
  );
};

SearchNavPanel.propTypes = {
  startValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.number,
  isMobileSearch: PropTypes.bool,
  isInteractionDisabled: PropTypes.bool,
  isHistorySearch: PropTypes.bool,
  onChangeStartPosition: PropTypes.func,
};

export default SearchNavPanel;
