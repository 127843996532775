import React, { Component } from "react";
import ReactResizeDetector from "react-resize-detector/build/withPolyfill";
import classNames from "classnames";
import PropTypes from "prop-types";
import { getEventPath } from "../../shared/utils/dom";
import {
  getBoundingClientRect,
  calculateMaxSizes,
  calculateFitPosition,
  MouseButtonsCodes
} from "../../utils/Utils";
import {
  commonPopupKeyDownEvent,
  focusElement,
  selectorFocusableElements
} from "../../utils/AccessibilityUtils";
import Scroll from "../views/Scroll";
import { Portal } from "../views";

const INITIAL_STATE = {
  main: false,
  isOpen: false,
  useFocus: true,
  popupId: null,
  component: null,
  deltaToTarget: 35,
  popupProps: {},
  innerProps: {},
  targetElement: null,
  withHoverZone: false,
  hideOnMouseLeave: false,
  hideOnBackMouseUp: true,
  hideOnClick: false,
  hideOnBlur: true,
  targetRect: {
    left: undefined,
    top: undefined
  },
  parentRect: undefined,
  noBack: false,
  fitPosition: false,
  fitSizes: false,
  backStyle: undefined,
  isScrollWrapperNeeded: true,
  onHide: () => {},
  onClick: () => {},
};

class PopupWrap extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;

    this.defaultBackgroundClassName = "popup-background";
    this.containerRef = React.createRef();
    this.defaultActiveElementRef = React.createRef();
    this.onWindowClick = this.onWindowClick.bind(this);
    this.onScrollEvent = this.onScrollEvent.bind(this);
    this.onMouseLeaveTarget = this.onMouseLeaveTarget.bind(this);
    this.onMouseLeaveContainer = this.onMouseLeaveContainer.bind(this);
    this.onPopupBackgroundKeyDown = this.onPopupBackgroundKeyDown.bind(this);
    this.onPopupBackgroundKeyDown = this.onPopupBackgroundKeyDown.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.focusOnOpen = this.focusOnOpen.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.hide = this.hide.bind(this);
  }

  onWindowClick(e) {
    const { noBack, isOpen } = this.state;

    /* Handle outside click. */
    if (noBack && isOpen) {
      const containerElement = this.containerRef?.current;
      const targetElement = this.state;
      const eventPath = getEventPath(e);

      if (
        containerElement && !eventPath.includes(containerElement)
        && (!targetElement || !eventPath.includes(targetElement))
      ) {
        this.hide();
      }
    }
    /* ==================== */
  }

  onScrollEvent() {
    const { isOpen, parent, deltaToTarget, notHideOnScroll } = this.state;
    if (isOpen && parent && this.containerRef.current && !notHideOnScroll) {
      const { top: parentTop } = getBoundingClientRect(parent);
      const { top: popupTop } = getBoundingClientRect(this.containerRef.current);

      if ((parentTop + deltaToTarget) < popupTop || parentTop > (popupTop + deltaToTarget)) {
        this.hide();
      }
    }
  }

  onPopupBackgroundKeyDown(event) {
    commonPopupKeyDownEvent(
      event,
      event.target.closest(`.${this.defaultBackgroundClassName}`),
      () => this.hide()
    );
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  attachEventListeners() {
    window.addEventListener("click", this.onWindowClick);
    document.addEventListener("touchmove", this.onScrollEvent);
    window.addEventListener("scroll", this.onScrollEvent);

    if (this.state.targetElement) {
      this.state.targetElement.addEventListener("mouseleave", this.onMouseLeaveTarget);
    }
  }

  removeEventListeners() {
    window.removeEventListener("click", this.onWindowClick);
    document.removeEventListener("touchmove", this.onScrollEvent);
    window.removeEventListener("scroll", this.onScrollEvent);

    if (this.state.targetElement) {
      this.state.targetElement.removeEventListener("mouseleave", this.onMouseLeaveTarget);
    }
  }

  focusOnOpen() {
    if (this.state.useFocus) {
      setTimeout(() => {
        const currentActiveElement = document.activeElement;
        if (
          currentActiveElement &&
          !currentActiveElement.closest(`.${this.defaultBackgroundClassName}`)
          && !this.defaultActiveElementRef.current
        ) {
          this.defaultActiveElementRef.current = currentActiveElement;
        }

        focusElement(this.containerRef.current?.querySelectorAll(selectorFocusableElements));
      }, 150);
    }
  }

  makePosition(targetRect, parentRect, fitPosition, fitSizes) {
    let style = {};
    const width = this.containerRef.current?.offsetWidth;
    const height = this.containerRef.current?.offsetHeight;

    if (parentRect === null || typeof parentRect !== "object") {
      parentRect = {
        top: 0,
        left: 0,
        bottom: window.innerHeight,
        right: window.innerWidth,
      };
    }

    if (fitPosition) {
      style = calculateFitPosition(parentRect, targetRect, width, height);
    } else {
      const parentLeft = parentRect.left;
      const parentTop = parentRect.top;
      const parentRight = parentRect.right;
      const parentBottom = parentRect.bottom;

      style.top = targetRect.top;
      style.left = targetRect.left;

      if (style.top + height > parentBottom) {
        style.top = Math.ceil(parentBottom - height);
      }

      if (style.top < parentTop) {
        style.top = parentTop;
      }

      if (style.left + width > parentRight) {
        style.left = parentRight - width;
      }

      if (style.left < parentLeft) {
        style.left = parentLeft;
      }

      if (targetRect?.right && width) {
        if (targetRect.right - width > parentLeft) {
          style.left = targetRect.right - width;
        }
      }
    }

    if (fitSizes) {
      const fitSizes = calculateMaxSizes(parentRect, targetRect);

      style.maxWidth = fitSizes.maxWidth;
      style.maxHeight = fitSizes.maxHeight;
    }

    if (!width || !height) {
      // Fix popup might be shown not in the final position, when sizes are not known yet.
      // Actual sizes are set after the first React Resize Detector "onResize" event.
      style.visibility = "hidden";
    }

    return style;
  }

  showPopup(targetRect, component, {onShow, ...restOptions} = {}) {
    const { hideOnClick } = restOptions;
    const isKeyboardAccessibility = document?.querySelector("html[data-whatinput='keyboard']");

    // block hideOnClick for using keyboard. Esc is used to close a popup
    if (isKeyboardAccessibility && hideOnClick) {
      restOptions.hideOnClick = false;
    }

    this.setState({
      ...INITIAL_STATE,
      isOpen: true,
      ...restOptions,
      component,
      targetRect
    }, () => {
      this.attachEventListeners();
      this.focusOnOpen();
    });

    if (typeof onShow === "function") {
      onShow();
    }
  }

  changeCoordinates(targetRect, ignoreParentRect = false) {
    this.setState({
      targetRect,
      parentRect: ignoreParentRect ? null : this.state.parentRect,
    });
  }

  hide() {
    const { isOpen, onHide } = this.state;

    if (isOpen) {
      this.setState(INITIAL_STATE, () => {
        this.removeEventListeners();

        if (onHide && typeof onHide === "function") {
          onHide(this.state);
        }
        if (this.props.onClose) {
          this.props.onClose();
        }

        setTimeout(() => {
          if (this.defaultActiveElementRef.current) {
            this.defaultActiveElementRef.current.focus();
            this.defaultActiveElementRef.current = null;
          }
        }, 0);
      });
    }
  }
 
  onMouseLeaveContainer(e) {
    const targetElement = this.state.targetElement;

    if (this.state.hideOnMouseLeave) {
      const toElement = e.nativeEvent.toElement;

      if (
        targetElement
        && targetElement !== toElement
        && !targetElement.contains(toElement)
      ) {
        this.hide();
      }
    }
  }

  onMouseLeaveTarget (e) {
    const containerElement = this.containerRef.current;

    if (this.state.hideOnMouseLeave) {
      const toElement = e.toElement || e.relatedTarget; // the second is for FF

      if (
        containerElement
        && containerElement !== toElement
        && !containerElement.contains(toElement)
      ) {
        this.hide();
      }
    }
  }

  onClick(e) {
    if (typeof this.state.onClick === "function") {
      this.state.onClick(e);
    }
    if (this.state.hideOnClick) {
      this.hide();
    }
  }

  onBlur() {
    if (this.state.hideOnBlur && this.state.isOpen) {
      const body = document.activeElement;
      let timer = setInterval(() => {
        clearTimeout(timer);
        const newFocused = document.activeElement;
        if (body.nodeName === newFocused.nodeName) {
          return;
        }
        if (!this.containerRef?.current?.contains(newFocused)){
          this.hide();
        }
      }, 150);
    }
  }

  render() {
    const {
      isOpen, component,
      targetRect , parentRect, fitPosition, fitSizes,
      noBack, isScrollWrapperNeeded,
      backStyle, innerProps, popupProps,
      withHoverZone,
    } = this.state;
    const {main} = this.props;
    const computedStyles = this.makePosition(targetRect, parentRect, fitPosition, fitSizes);

    const isShownAtBottom = computedStyles.top > targetRect?.top;
    const isShownAtRight = !(computedStyles.left < targetRect?.left);
    const targetRectSizesCssVars = targetRect ? {
      "--target_width": targetRect.width + "px",
      "--target_height": targetRect.height + "px",
    } : {};
    const { className: popupClassName, style: popupStyle = {}, ...popupPropsRest } = popupProps;
    const { className: innerClassName, ...innerPropsRest } = innerProps;

    let InnerWrapperTag = isScrollWrapperNeeded ? Scroll : "div";
    let innerWrapperProps = isScrollWrapperNeeded ? {
      noHidePopups: true, hasStaticPosition: true
    } : {};

    return (
      <React.Fragment>
        {this.props.children}
        <div className={classNames({
          "hide": !isOpen,
        })}>
          <Portal id="popup">
            <div
              {...popupPropsRest}
              tabIndex={-1}
              className={classNames(this.defaultBackgroundClassName, popupClassName, {
                "popup-main": main,
                "is-shown-at-bottom": isShownAtBottom,
                "is-shown-at-right": isShownAtRight,
                "has-hover-zone": withHoverZone,
              })}
              style={{
                ...computedStyles, ...targetRectSizesCssVars, ...backStyle, ...popupStyle
              }}
              onKeyDown={this.onPopupBackgroundKeyDown}
              onMouseLeave={this.onMouseLeaveContainer}
              ref={this.containerRef}
              onClick={this.onClick}
              onBlur={this.onBlur}
              onContextMenu={(e) => e.preventDefault()}>
              <InnerWrapperTag
                className={classNames("popup-inner", innerClassName)}
                {...innerPropsRest}
                {...innerWrapperProps}
              >
                <ReactResizeDetector
                  handleHeight
                  handleWidth
                  onResize={() => {
                    this.forceUpdate();
                  }}>{component}</ReactResizeDetector>
              </InnerWrapperTag>
            </div>
            {!noBack && isOpen && (
              <div
                className="modalBack"
                onClick={() => this.hide()}
                onMouseUp={(e) => {
                  if (this.state.hideOnBackMouseUp && e.button === MouseButtonsCodes.secondary) {
                    this.hide();
                    e.preventDefault();
                  }
                }}
              />
            )}
          </Portal>
        </div>
      </React.Fragment>
    );
  }
}

PopupWrap.propTypes = {
  children: PropTypes.node,
  onRenderPopup: PropTypes.func,
  onClose: PropTypes.func,
  main: PropTypes.bool
};

export default PopupWrap;
