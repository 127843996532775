import { withEtagCachingRequest } from "./BaseAPI";

export const getCategoriesRequest = (langs = []) => {
  const params = langs.map((item) => "lang=" + item);
  let url = "/content/export/categories";
  if (params.length > 0) {
    url += "?" + params.join("&");
  }
  const request = {
    url,
    type: "get",
  };

  return withEtagCachingRequest(request);
};
