import React from "react";
import { Link } from "react-router-dom";
import { ScrollerView } from "../components/views";
import UserAgreementPage from "src/shared/pages/UserAgreementPage";

import "./Pages.scss";

const UserAgreement = () => {
  return (
    <ScrollerView>
      <UserAgreementPage Link={Link} />
    </ScrollerView>
  );
};

export default UserAgreement;
