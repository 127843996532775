import {
  bookIdRegex,
  folderIdRegex,
  getBookId,
  getBookOrigin,
  langRegex,
} from "../shared/utils/content";
import {
  Period as PeriodShared,
  periodOptions as periodOptionsShared,
} from "../shared/utils/search";

export const resultsLimit = 100;

export const advSearchAutocompleteTypes = ["topical", "dictionary", "ltms"];
export const advSearchInfoLangTypes = ["book", "bible"];
export const typesCompatibleWithBookCode = [
  "basic",
  "book",
  "periodicals",
  "apl",
  "reference",
  "bible",
  "commentary",
];

export const extrasArrayFields = ["collections"];

export const emptyOption = [{ id: "", label: "any_option" }];

// "custom" option is absent in the Textapp. There is no Tree menu which this period related to.
export const Period = { ...PeriodShared, custom: "custom" };

export const periodOptionCustom = { key: Period.custom, label: "custom" };
export const periodOptions = [...periodOptionsShared, periodOptionCustom];

export const virtualPeriodValues = [Period.myBible, Period.apl, Period.custom, Period.dictionary];

export const getBiblePubnr = (baseBible) => {
  const defaultBible = 1965;
  if (!baseBible) {
    return defaultBible;
  }
  return getBookOrigin(baseBible);
};

const BookUrlRegex = /^\/book\/(b\d+)$/i;

export const getBookIdByLocation = (pathname, activeId) => {
  if (activeId) {
    return getBookId(activeId);
  } else if (pathname && BookUrlRegex.test(pathname)) {
    const [_, bookId] = pathname.match(BookUrlRegex);
    return bookId;
  }
  return undefined;
};

export const getFolderList = (mainTree, checked) => {
  if (!mainTree) {
    // if mainTree is not loaded yet then no actual folders
    return [];
  }
  // we need to send langauges in PARTIAL state to search
  // we need to send other folders in ALL state
  // we need not to send children of parent in ALL state

  const checkedSet = new Set(checked);
  // list of partial/all folders

  const { lang, folder, pubnr } = parseFolders(checked);
  const books = pubnr.map((value) => getBookId(value));

  const parents = [];
  // we need parent folders list to drop parents if

  const res = [];
  // ALL-checked parent folders will be stored here

  // each item from <list> will be excluded from <valueSet>
  const dropList = (valueSet, list) => {
    if (!list.length) {
      return;
    }
    list.forEach((item) => {
      valueSet.delete(item);
    });
  };

  // recursive func to exclude from fullChecked all PARTIAL-checked folders
  // if checked folder has non-checked child, it is PARTIAL-checked. not ALL-checked
  const _drop = (item) => {
    const id = item.id;
    if (!checkedSet.has(id)) {
      dropList(fullChecked, parents);
      return;
    }
    // else
    // included case
    // now book children can't be checked/unchecked separately ! =)
    // so a book is the lowest level to test
    if (item.children && !item.book_id) {
      parents.push(id);
      item.children.forEach((item) => {
        _drop(item);
      });
      parents.pop();
    }
  };

  // recursive func to copy to results only parent ALL-checked folders, not any of their children
  const _copy = (item) => {
    const id = item.id;
    if (fullChecked.has(id)) {
      res.push(id);
      // folder is ALL-checked, no need to process its children
      return;
    }
    // else
    // not ALL-checked case
    // so need to process its children
    // now book children can't be checked/unchecked separately ! =)
    // so a book is the lowest level to test
    if (item.children && !item.book_id) {
      item.children.forEach((item) => {
        _copy(item);
      });
    }
  };

  const fullChecked = new Set(folder.concat(books));
  // we need to exclude non-ALL folders and books, but not langauges
  // so languages will be stored (in <lang>)and added to results at the process end

  // exclude form fullChecked PARTIAL-checked folders
  mainTree.forEach((item) => {
    _drop(item);
  });

  // copy to res only parent ALL-checked folders
  mainTree.forEach((item) => {
    _copy(item);
  });
  const result = lang.concat(res);
  return result;
};

export const parseFolders = (folders) => {
  const lang = [];
  const folder = [];
  const pubnr = [];
  (folders || []).forEach((value) => {
    if (langRegex.test(value)) {
      // lang case
      lang.push(value);
    } else if (folderIdRegex.test(value)) {
      // folder value
      folder.push(value);
    } else if (bookIdRegex.test(value)) {
      // book case
      pubnr.push(value.slice(1));
    }
  });
  return { lang, folder, pubnr };
};

export const cutAreaFromExtras = (oldExtras) => {
  const extras = { ...oldExtras };
  if ("collection" in extras) {
    delete extras["collection"];
  }
  if ("collections" in extras) {
    delete extras["collections"];
  }
  return extras;
};

// TODO refactor
export const getActiveLangs = (checked = [], mainTree, bookLangMap = {}) => {
  // get book langs
  const checkedLangs = [];
  const bookLangs = [];
  const checkedFolders = [];
  const folderLangs = [];

  checked.forEach((item) => {
    if (bookIdRegex.test(item) && bookLangMap[item]) {
      bookLangs.push(bookLangMap[item]);
    }
    if (langRegex.test(item)) {
      checkedLangs.push(item);
    }
    if (Number.isInteger(item)) {
      checkedFolders.push(item);
    }
  });
  const unloadedCheckedLangs = mainTree
    .filter((item) => checkedLangs.includes(item.code) && item.children === undefined)
    .map((item) => item.code);

  const _process = (items) => {
    if (items === undefined) {
      return;
    }
    const limit = items.length;
    for (let i = 0; i < limit; i++) {
      const item = items[i];
      if (item.className === "language") {
        // language case
        _process(item.children);
        continue;
      }
      // else
      // folder case
      if (checkedFolders.includes(item.id)) {
        // lang is uses
        folderLangs.push(item.lang);
        continue;
      }
      // else
      // lang status in unknown
      if (item.nbooks !== 0) {
        // no subfolders
        continue;
      }
      // there are subfolders
      _process(item.children);
    }
  };
  if (mainTree) {
    _process(mainTree);
  }

  return [...new Set([...unloadedCheckedLangs, ...bookLangs, ...folderLangs])]; // result.unique()
};
