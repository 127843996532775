import React from "react";
import PleaseSignInPage from "../react-pages/PleaseSignInPage";
import { useAuth } from "../hooks";


export const withLogin = (Page) => {
  return (props) => {
    const { isLogin } = useAuth();

    return isLogin
      ? <Page {...props}/>
      : <PleaseSignInPage />;
  };
};
