import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAfter } from "date-fns";

import { actionUpdateSettings } from "src/redux/actions";
import { Settings } from "src/utils/Settings";

import { makeChapterUrl } from "../../../utils/URLUtils";
import { useDialogContext } from "../../dialog/DialogContext";
import { getLayoutByCountPane, layoutIds } from "../layouts/LayoutUtils";
import WorkspaceTools from "./WorkspaceTools";
import BookCodePane from "./BookCodePane";
import { fromNow } from "src/utils/Utils";
import { checkPrefix } from "./WorkspaceUtils";

import "./WorkspacesPanel.scss";

const WorkspacesGrid = ({
  currentLayout,
  layoutsData,
  showDateCreated = false,
  showShelf = true,
}) => {
  const dispatch = useDispatch();
  const workspaces = useSelector((state) => state.settings.workspaces);
  const { hideDialog } = useDialogContext();

  const handleSelectWS = (item) => {
    dispatch(
      actionUpdateSettings({
        [Settings.activeWorkspace.id]: item.id,
        [Settings.layout.id]: item.layoutId,
      }),
    );
    hideDialog();
  };

  const renderWorkspacesGrid = () => {
    return workspaces
      .sort((prev, next) => (isAfter(new Date(prev.created), new Date(next.created)) ? -1 : 1))
      .map((item) => {
        const panelIds = checkPrefix(item.ids).split(",");
        const countPanels = panelIds.length;
        const layoutData = layoutsData.find((elem) => elem.id === item.layoutId);
        const realType = layoutData.id;
        const layoutPanelIds = panelIds.map((panelId, index) => ({
          panelId,
          index,
        }));
        const Layout = getLayoutByCountPane(countPanels, onRenderWSPanel(layoutPanelIds[0]));
        const url = makeChapterUrl(panelIds);
        const title = item.title || "";

        return (
          <div className="ws-item" key={item.created}>
            <Link to={url} onClick={() => handleSelectWS(item)}>
              <div className="ws-item-layout">
                {countPanels === 1 && <div className="Pane">{Layout}</div>}
                {countPanels !== 1 && (
                  <Layout
                    onRender={onRenderWSPanel}
                    panelIds={layoutPanelIds}
                    id={realType}
                    vertical={realType === layoutIds.l2v}
                  />
                )}
              </div>
              {showDateCreated && <div className="ws-date">{fromNow(item.created)}</div>}
              {showShelf && (
                <div className="ws-title" title={title}>
                  {title}
                </div>
              )}
            </Link>
            {showShelf && <WorkspaceTools item={item} currentLayout={currentLayout} />}
          </div>
        );
      });
  };

  const onRenderWSPanel = ({ index, panelId }) => {
    return <BookCodePane key={index} panelId={panelId} />;
  };

  return renderWorkspacesGrid();
};

WorkspacesGrid.propTypes = {
  currentLayout: PropTypes.string,
  layoutsData: PropTypes.array,
  showDateCreated: PropTypes.bool,
  showShelf: PropTypes.bool,
};

export default WorkspacesGrid;
