import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import SubsBadge from "../../components/subscriptions/SubsBadge";
import { getMenuItemsAsList, MENU_STATE } from "../../utils/MenuItems";
import { HideIcon, IconButton, Scroll, ScrollerView } from "../views";
import { actionUpdateSetting, actionUpdateSettings } from "../../redux/actions";
import { useResizeDimensions, useViewMode, useAuth, useTheme, useDevMode } from "../../hooks";
import { useNavigationContext } from "../NavigationContext";
import {
  getBoundingClientRect,
  getExtendedClass,
  scrollToElement
} from "../../utils/Utils";
import {
  DotsVerticalIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevroneUpCircleIcon,
} from "../../assets/CommonIcons";
import {
  firstElementIsFocusable,
  lastElementIsFocusable,
  selectorFocusableElements,
} from "../../utils/AccessibilityUtils";
import { Settings } from "../../utils/Settings";
import { SCROLL_DIRECTIONS } from "../reader/constants";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { getGravatarImage } from "../../utils/Gravatar.utils";
import { MENU_ITEMS_LOCATIONS } from "../../shared/utils/menu";
import useScrollArrows  from "../../hooks/useScrollArrows";
import { removeBR } from "../../shared/utils/content";
import IconView from "src/shared/components/IconView";
import sassVariables from "../../styles/variables.module.scss";
import { makeOAuthRequest } from "src/shared/api/baseApi";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { URLS } from "../../shared/utils/url";
import { useWhatInputValueIsLastPointerMouse } from "../../what-input/useWhatInput";

const SideMenuList = () => {
  const history = useHistory();
  const { MIN_LEFT_WIDTH, COLLAPSE_MENU_WIDTH } = useResizeDimensions();
  const { openId, isReader } = useNavigationContext();
  const { isLogin, showLoginDialog, showLogoutDialog, isUserEditor } = useAuth();
  const [showHiddenMenu, setShowHiddenMenu] = useState(false);
  const [parentHeight, setParentHeight] = useState(1024);
  const userInfo = useSelector((state) => state.settings.userInfo);
  const menuState = useSelector((state) => state.settings.menuState);
  const isShowFooter = useSelector((state) => state.settings.isShowFooter);
  const scrollToFooter = useSelector((state) => state.settings.scrollToFooter);
  const isSmallPlayerActive = useSelector((state) => state.audioPlayer.isSmallPlayerActive);
  const { isMobile, isMobileOrTablet, textMode } = useViewMode();
  const remToPx = useRemToPx();
  const { isDevMode } = useDevMode();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listMenu = getMenuItemsAsList(MENU_ITEMS_LOCATIONS.MENU, false, isDevMode, isUserEditor);
  const { isDark } = useTheme();
  const timerHiddenMenuRef = useRef(0);
  const moreButtonRef = useRef(null);
  const [scrollElement, setScrollElement] = useState(null);
  const [hiddenMenuHeight, setHiddenMenuHeight] = useState(null);
  const [showPlaceHolder, setShowPlaceHolder] = useState(false);
  const [menuItemHovered, setMenuItemHovered] = useState(false);
  const itemHeight = remToPx(sassVariables.sideMenuItemHeight);
  const isLastPointerMouse = useWhatInputValueIsLastPointerMouse();

  const {
    showArrowOne: showArrowUp,
    showArrowTwo: showArrowDown,
    handleArrowClick,
    resetArrows,
    validateArrows,
  } = useScrollArrows(scrollElement, false);

  useEffect(() => {
    validateArrows();
  }, [validateArrows]);

  useEffect(() => {
    if (moreButtonRef.current) {
      const moreButtonRect = moreButtonRef.current.getBoundingClientRect();
      const innerHeight = window.innerHeight;
      const distanceToBottom = innerHeight - moreButtonRect.top;
      const hiddenMenuHeight = parentHeight - distanceToBottom;
      setHiddenMenuHeight(hiddenMenuHeight);
    }
    return () => {
      resetArrows();
    };
  }, [parentHeight, showHiddenMenu, resetArrows]);

  useEffect(() => {
    setShowPlaceHolder(false);
  }, [userInfo]);

  const { pathname } = history.location;

  useEffect(() => {
    dispatch(actionUpdateSetting(Settings.scrollToFooter.id, false));

    if (!isShowFooter || !scrollToFooter) {
      return;
    }

    setTimeout(() => {
      if (isMobile && isSmallPlayerActive) {
        // Scroll with the align to top as there is no enough scroll
        // when mobile player is shown, but that scroll type forbids smooth scrolling though.
        scrollToElement("footer", true);
      } else {
        // Scroll to footer with smooth scrolling.
        scrollToElement("footer");
      }
    }, 100);
  }, [isShowFooter, scrollToFooter, isMobile]);

  useEffect(() => {
    let intervalId = null;

    const handleResize = () => {
      if (!isMobileOrTablet) {
        if (intervalId) {
          clearInterval(intervalId);
        }

        intervalId = setInterval(() => {
          const parent = document.getElementById("mainApp");
          if (parent) {
            const { height } = getBoundingClientRect(parent);
            if (height) {
              setParentHeight(height);
              clearInterval(intervalId);
            }
          }
        }, 100);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(intervalId);
      if (timerHiddenMenuRef.current) {
        clearTimeout(timerHiddenMenuRef.current);
      }
    };
  }, [isMobileOrTablet]);

  let items = listMenu.filter(({ id, onlyMobileOrTablet, onlyMobile, onlyNoAuth }) => {
    let result = true;
    if (onlyMobileOrTablet && !isMobileOrTablet) {
      result = false;
    }

    if (onlyMobile && !isMobile) {
      result = false;
    }

    if (onlyNoAuth && isLogin) {
      result = false;
    }

    if (id === URLS.logout && !isLogin) {
      result = false;
    }

    return result;
  });

  const selected = items.findIndex((item) => pathname.indexOf(item.id) === 0);
  const isExpand = menuState === MENU_STATE.MENU;

  const onToggleFooter = () => {
    if (isMobile && isReader) {
      return;
    }

    dispatch(
      actionUpdateSettings({
        [Settings.scrollToFooter.id]: true,
        [Settings.isShowFooter.id]: !isShowFooter,
      }),
    );
  };

  const renderIconValue = (iconValue) => {
    return textMode ? null : iconValue;
  };

  const handleMouseEnterMenuItem = () => {
    setMenuItemHovered(true);
  };

  const hanldeMouseLeaveMenuItem = () => {
    setMenuItemHovered(false);
  };

  const renderLabel = (label, shortLabel) => {
    let labelValue = t(shortLabel || label);
    return removeBR(labelValue);
  };

  const listMenuItems = items.map(
    ({ icon: Icon, secondIcon: SecondIcon, id, label, shortLabel, url, onlyAuth }, i) => {
      let iconValue = <IconView icon={Icon} className="menu-item-icon" />;
      // ONES MORE WORKAROUND BECAUSE ABOUT HAS DIFFERENT ICONS
      if (id === URLS.aboutEW && isDark && SecondIcon) {
        iconValue = <IconView icon={SecondIcon} className="menu-item-icon" />;
      }

      if (id === URLS.logout && userInfo?.email && !showPlaceHolder) {
        iconValue = (
          <img
            alt="avatar"
            className="menu-item-icon icon-avatar"
            src={getGravatarImage(userInfo.email)}
            onError={() => {
              setShowPlaceHolder(true);
            }}
          />
        );
      }

      let loginClassMenu;
      if (onlyAuth && !isLogin) {
        loginClassMenu = "menu-disabled";
      }
      return (
        <div className="menuItemWrap" key={i}>
          {url ? (
            <a
              onMouseEnter={handleMouseEnterMenuItem}
              onMouseLeave={hanldeMouseLeaveMenuItem}
              className={classNames(
                "sideMenuItem",
                getExtendedClass(i, isExpand, selected),
                loginClassMenu,
              )}
              href={url}
              target="_blank"
              rel="noopener noreferrer">
              {renderIconValue(iconValue)}
              <span
                className="sideMenuItem-label"
                dangerouslySetInnerHTML={{ __html: renderLabel(label, shortLabel) }}
              />
            </a>
          ) : (
            <Link
              onMouseEnter={handleMouseEnterMenuItem}
              onMouseLeave={hanldeMouseLeaveMenuItem}
              className={classNames(
                "sideMenuItem",
                getExtendedClass(i, isExpand, selected),
                loginClassMenu,
              )}
              to={id}
              onClick={(e) => {
                setShowHiddenMenu(false);
                if (loginClassMenu) {
                  showLoginDialog();
                  e.preventDefault();
                  return;
                } else if (id === URLS.allCollection || id === URLS.myLibrary) {
                  openId(id);
                  e.preventDefault();
                } else if (id === URLS.login) {
                  if (!isLogin) {
                    makeOAuthRequest();
                  }
                  e.preventDefault();
                } else if (id === URLS.logout) {
                  if (isLogin) {
                    showLogoutDialog();
                  }
                  e.preventDefault();
                }

                if (isMobileOrTablet) {
                  dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
                  const { scrollY, scrollTo } = window;
                  if (scrollY > 0) {
                    scrollTo(0, 0);
                  }
                }
              }}>
              {renderIconValue(iconValue)}
              <span
                className="sideMenuItem-label"
                dangerouslySetInnerHTML={{ __html: renderLabel(label, shortLabel) }}
              />
              {id === URLS.subscription && (
                <SubsBadge
                  minMode={menuState === MENU_STATE.COLLAPSE || menuState === MENU_STATE.TREE}
                />
              )}
            </Link>
          )}
        </div>
      );
    },
  );

  const onMenuItemWrapKeyDown = (event) => {
    const target = event.target.closest(".menuItemWrap.item-more");

    if (target) {
      if (isEventKey(event, KeyCodes.esc)) {
        setShowHiddenMenu(false);
        target.focus();
      } else if (isEventKey(event, KeyCodes.enter) || isEventKey(event, KeyCodes.space)) {
        setShowHiddenMenu(true);

        // set focus on a first focusable element
        if (timerHiddenMenuRef.current) {
          clearTimeout(timerHiddenMenuRef.current);
        }

        timerHiddenMenuRef.current = setTimeout(() => {
          const elements = target.querySelectorAll(selectorFocusableElements);
          if (elements && elements.length) {
            elements[0].focus();
          }
        }, 0);
      }
    }
  };
  const hideIcon = (
    <div className="menuItemWrap">
      <HideIcon
        onClick={() => {
          dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
        }}
      />
    </div>
  );

  const onHiddenMenuKeyDown = (event) => {
    event.stopPropagation();
    const target = event.target.closest(".hidden-menu-items");
    const parentTarget = event.target.closest(".menuItemWrap.item-more");

    if (target && parentTarget) {
      if (isEventKey(event, KeyCodes.esc)) {
        setShowHiddenMenu(false);
        parentTarget.focus();
      } else if (target && isEventKey(event, KeyCodes.tab)) {
        if (!event.shiftKey && lastElementIsFocusable(target)) {
          event.preventDefault();
        } else if (event.shiftKey && firstElementIsFocusable(target)) {
          event.preventDefault();
        }
      }
    }
  };

  const renderListMenuItems = () => {
    const availableMenu = [];
    const hiddenMenu = [];
    // instant add one row for special button toggle footer
    let accumulateOfHeight = itemHeight;

    listMenuItems.forEach((menuItem) => {
      const menuItemHeight = itemHeight;
      accumulateOfHeight += menuItemHeight;

      if (accumulateOfHeight > parentHeight - menuItemHeight) {
        hiddenMenu.push(menuItem);
      } else {
        availableMenu.push(menuItem);
      }
    });

    const toggleFooterButton = (
      <div className="menuItemWrap">
        <div
          className={classNames("sideMenuItem", getExtendedClass(items.length, isExpand, selected))}
          onClick={onToggleFooter}
          tabIndex={0}>
          {renderIconValue(
            <ChevroneUpCircleIcon
              className={classNames("menu-item-icon", {
                "is-show-footer": isShowFooter,
              })}
            />,
          )}
          <span
            className="sideMenuItem-label"
            dangerouslySetInnerHTML={{ __html: renderLabel("footerToggle") }}
          />
        </div>
      </div>
    );

    const menuHidden = hiddenMenu.length > 0;
    let height = Math.floor(itemHeight * hiddenMenu.length);
    const scroll = height >= hiddenMenuHeight;
    // for shadow effect in last hiddenmenu item
    if (!scroll) {
      height = height + 10;
    }
    if (isMobileOrTablet) {
      if (isMobile) {
        return (
          <ScrollerView className="side-menu__scrollbars-wrapper">
            {listMenuItems}
            {!isReader && toggleFooterButton}
          </ScrollerView>
        );
      }

      return (
        <React.Fragment>
          {listMenuItems}
          {toggleFooterButton}
          {isExpand && hideIcon}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {availableMenu}
        {menuHidden && (
          <button
            ref={moreButtonRef}
            aria-label={t("more")}
            onKeyDown={onMenuItemWrapKeyDown}
            className="menuItemWrap item-more"
            onMouseEnter={isLastPointerMouse ? () => setShowHiddenMenu(true) : undefined}
            onMouseLeave={() => setShowHiddenMenu(false)}
            onClick={() => setShowHiddenMenu(true)}
          >
            <span className={classNames("sideMenuItem", { active: showHiddenMenu })}>
              <DotsVerticalIcon className={"menu-item-icon menu-icon-rotate"} />
              <span className="sideMenuItem-label">{isExpand && t("more")}</span>
              {menuHidden && showHiddenMenu && (
                <div
                  onKeyDown={onHiddenMenuKeyDown}
                  className={classNames("hidden-menu-items", {
                    "with-arrow-down": showArrowDown && scroll,
                    "with-arrow-up": showArrowUp && scroll,
                    "not-expanded": !isExpand,
                  })}>
                  {showArrowUp && (
                    <IconButton
                      className="hidden-menu-btn up"
                      id={SCROLL_DIRECTIONS.UP}
                      icon={ChevronUpIcon}
                      onClick={handleArrowClick}
                    />
                  )}
                  <Scroll
                    style={{
                      height: height,
                      maxHeight: hiddenMenuHeight,
                      width: isExpand ? "15rem" : menuItemHovered ? "14rem" : "100%",
                      marginBottom: !scroll ? "-10px" : "0px",
                    }}
                    onScroll={validateArrows}
                    ref={setScrollElement}
                    hasStaticPosition
                    onClick={() => setShowHiddenMenu(false)}
                  >
                    {hiddenMenu}
                  </Scroll>
                  {showArrowDown && scroll && (
                    <IconButton
                      className="hidden-menu-btn down"
                      id={SCROLL_DIRECTIONS.DOWN}
                      icon={ChevronDownIcon}
                      onClick={handleArrowClick}
                    />
                  )}
                </div>
              )}
            </span>
          </button>
        )}
        {toggleFooterButton}
        {isExpand && !menuHidden && hideIcon}
      </React.Fragment>
    );
  };

  let navStyles = {};
  if (!isMobile) {
    navStyles.width = isExpand ? MIN_LEFT_WIDTH : COLLAPSE_MENU_WIDTH;
  }

  return (
    <nav className={classNames("sideMenuMain", { expanded: isExpand })} style={navStyles}>
      {renderListMenuItems()}
    </nav>
  );
};

export default SideMenuList;
