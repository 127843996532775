import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";

import { HideIcon } from "../views";
import DialogButtons from "./DialogButtons"; 
import {
  commonPopupKeyDownEvent,
  focusElement,
  selectorFocusableElements,
} from "../../utils/AccessibilityUtils";
import { useDeviceDetect, useViewMode } from "../../hooks";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { useRemToPx } from "../../hooks/viewModeHooks";

import "./Dialog.scss";

const DialogContext = React.createContext();

const DialogProvider = ({ children }) => {
  const location = useLocation();
  const [dialogStack, setDialogStack] = useState([]);
  const { t } = useTranslation();
  const defaultActiveElementRef = useRef();
  const focusTimerRef = useRef(0);
  const focusAfterTimerRef = useRef(0);
  const { isMobile, zoom } = useViewMode();
  const remToPx = useRemToPx();
  const { isRealMobile } = useDeviceDetect();

  const defaultBackgroundClassName = "dialog-background";

  const showAlert = (title, message, options = {}) => {
    setDialogStack([
      ...dialogStack,
      {
        ...options,
        title,
        message,
        alert: true,
      },
    ]);
  };
  /**
   *
   * @param {*} title
   * @param {*} message
   * @param {*} onConfirm
   * @param {*} options
   */
  const showConfirm = useCallback((title, message, onConfirm, options = {}) => {
    setDialogStack((dialogStackOld) => [
      ...dialogStackOld,
      {
        ...options,
        confirm: true,
        title,
        message,
        onConfirm,
      },
    ]);
  }, []);

  const showDialog = useCallback((component, options = {}) => {
    setDialogStack((stackOld) => {
      return [...stackOld, { ...options, component }];
    });
  }, []);

  const handleClose = (isCancel, dialogIndex, onCancel, isHide) => {
    if (dialogIndex < 0 || dialogIndex >= dialogStack.length) {
      return;
    }
    const cancelFun = isHide ? dialogStack[dialogIndex].onCancel : onCancel;
    dialogStack.splice(dialogIndex, 1);
    setDialogStack([...dialogStack]);
    if (isCancel && cancelFun) {
      cancelFun();
    }

    focusAfterClosing();
  };

  const handleConfirm = (event, dialogIndex, onCancel, onConfirm) => {
    handleClose(false, dialogIndex, onCancel);
    if (onConfirm) {
      onConfirm(event);
    }
  };

  useEffect(() => {
    setDialogStack([]);
  }, [location.pathname]);

  const focusOnOpen = () => {
    if (dialogStack.length === 0) {
      return;
    }

    if (focusTimerRef.current) {
      clearTimeout(focusTimerRef.current);
    }

    focusTimerRef.current = setTimeout(() => {
      const currentActiveElement = document.activeElement;
      if (
        currentActiveElement &&
        !currentActiveElement.closest(`.${defaultBackgroundClassName}`) &&
        !defaultActiveElementRef.current
      ) {
        defaultActiveElementRef.current = currentActiveElement;
      }

      const popups = document.querySelectorAll(`.${defaultBackgroundClassName}`);

      for (let popup of popups) {
        popup.focus();
        focusElement(popup.querySelectorAll(selectorFocusableElements));
      }
    }, 300);
  };

  const focusAfterClosing = () => {
    if (focusAfterTimerRef.current) {
      clearTimeout(focusAfterTimerRef.current);
    }

    focusAfterTimerRef.current = setTimeout(() => {
      if (defaultActiveElementRef.current) {
        defaultActiveElementRef.current.focus();
        defaultActiveElementRef.current = null;
      }
    }, 0);
  };

  useEffect(() => {
    if (isMobile) {
      const body = document.querySelector("body");
      if (dialogStack.length > 0) {
        body.classList.add("no-scroll");
      } else {
        body.classList.remove("no-scroll");
      }
    }
    const handleKeyPress = (event) => {
      if (dialogStack.length === 0) {
        return;
      }

      const escButton = isEventKey(event, KeyCodes.esc);
      const enterButton = isEventKey(event, KeyCodes.enter);

      const { onConfirm, onCancel, onHide } = dialogStack[dialogStack.length - 1] || {};
      const keyboardUsed = document.querySelector("html[data-whatinput='keyboard']");

      if (escButton) {
        if (escButton && onCancel) {
          onCancel();
        }
        if (onHide) {
          onHide();
        }

        dialogStack.splice(dialogStack.length - 1, 1);
        setDialogStack([...dialogStack]);
        event.preventDefault();
        focusAfterClosing();
      } else if (!keyboardUsed && enterButton && onConfirm) {
        onConfirm();
        if (onHide) {
          onHide();
        }
        dialogStack.splice(dialogStack.length - 1, 1);
        setDialogStack([...dialogStack]);
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    if (!isRealMobile) {
      // [EGWW-2116]
      focusOnOpen();
    }

    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [dialogStack]);

  const onDialogBackgroundKeyDown = (event) => {
    commonPopupKeyDownEvent(event, event.target.closest(`.${defaultBackgroundClassName}`));
  };

  const renderDialogs = () => {
    return dialogStack.map((dialogState, index) => {
      const {
        component,
        className,
        onCancel,
        onConfirm,
        alert,
        confirm,
        message,
        negativeBtn,
        title,
        positiveBtn,
        noTranslation,
        noPadding,
        noMinWidth,
        noBorderRadius,
        noButtons,
        rootClassAdv,
        noCancelable,
      } = dialogState;

      const style = {};
      if (alert || confirm) {
        if (zoom < 300) {
          style.width = remToPx(25.5);
        }
      }
      if (noPadding) {
        style.padding = 0;
      }
      if (noMinWidth) {
        style.minWidth = 0;
      }
      if (noBorderRadius) {
        style.borderRadius = 0;
      }
 
      return (
        <div
          onKeyDown={onDialogBackgroundKeyDown}
          tabIndex={-1}
          key={index}
          className={defaultBackgroundClassName}>
          <div className={classNames("dialog-container", rootClassAdv)} style={{ ...style }}>
            <Scrollbars
              hideTracksWhenNotNeeded
              autoHide
              renderView={(restProps) => (
                <div
                  className={classNames("scrollbars-render-view", "has-static-position")}
                  {...restProps}
                />
              )}
              renderTrackVertical={(props) => <div {...props} className="scrollMainTrack" />}
              renderThumbVertical={(props) => <div {...props} className="scrollStyle" />}
              renderThumbHorizontal={(props) => <div {...props} className="scrollStyle" />}
              className="dialog-scroll-wrapper scrollbars-wrapper">
              {!noCancelable && (
                <div className="dialog-hide-icon">
                  <HideIcon onClick={() => handleClose(true, index, onCancel)} />
                </div>
              )}
              {title && <div className="dialog-title">{t(title)}</div>}
              {component ? (
                component
              ) : (
                <div className={className}>
                  {message && (
                    <div
                      className="dialog-message"
                      data-focus={true}
                      tabIndex={1}
                      dangerouslySetInnerHTML={{ __html: noTranslation ? message : t(message) }}
                    />
                  )}
                  {!noButtons && (
                    <DialogButtons
                      negativeText={alert ? "ok" : t(negativeBtn || "cancel")}
                      positiveText={t(positiveBtn || "ok")}
                      onClickPositive={
                        alert
                          ? undefined
                          : (event) => handleConfirm(event, index, onCancel, onConfirm)
                      }
                      onClickNegative={() => handleClose(true, index, onCancel)}
                    />
                  )}
                </div>
              )}
            </Scrollbars>
          </div>
          <div
            className="dialog-cancelable"
            onClick={noCancelable ? undefined : () => handleClose(true, index, onCancel)}
          />
        </div>
      );
    });
  };

  const checkDialogId = useCallback(
    (dialogId) => {
      return !!dialogStack.find((stack) => stack.dialogId && stack.dialogId === dialogId);
    },
    [dialogStack],
  );

  return (
    <DialogContext.Provider
      value={{
        checkDialogId,
        showAlert,
        showConfirm,
        showDialog,
        hideDialog: () => handleClose(true, dialogStack.length - 1, undefined, true),
      }}>
      {children}
      {renderDialogs()}
    </DialogContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.node,
};

export { DialogContext, DialogProvider };

/**
 * @returns {{
 *   checkDialogId: (dialogId: string) => boolean,
 *   showAlert: function,
 *   showConfirm: function,
 *   showDialog: function,
 *   hideDialog: function,
 * }}
 */
export const useDialogContext = () => useContext(DialogContext);
