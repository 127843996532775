export const CONTENT_CLASSES = {
  EGW_WRITINGS: "egwwritings",
  EGW_LINK: "egwlink",
  EGW_LINK_BOOK: "egwlink_book",
  EGW_LINK_BIBLE: "egwlink_bible",
  BOOKS: "books",
  BOOK: "book",
  READER_HEADER_TEXT: "readerHeaderText",
  PAGE_NUMBER: "readerPageNumber",
  REF_CODE: "refCode",
  DICTIONARY: "dictionary",
  REFERENCE: "reference",
  PAGE_BREAK: "page-break",
  PARAGRAPH: "para",
  CHAPTER: "chapter",
  LANGUAGE: "language",
  ICON_TRANSLATE: "reader-translate-icon",
  ICON_BIBLE: "egw-bible-icon",

  CATEGORIES_ROOT: "categories-root",
  CATEGORIES_CATEGORY: "categories-category",
  CATEGORIES_BOOK: "categories-book",
  CATEGORIES_LANGUAGE_FOLDER: "categories-language-folder",
  CATEGORIES_BOOK_TRANSLATION: "categories-book-translation",

  TITLES_ROOT: "titles-root",
  TITLES_CATEGORY: "titles-category",
  TITLES_BOOK: "titles-book",
  TITLES_LANGUAGE_FOLDER: "titles-language-folder",
  TITLES_BOOK_TRANSLATION: "titles-book-translation",

  TREE_ITEM: "tree-item",

  FOOT_NOTE: "footnote",
  CHAPTER_END_NOTE: "chapterendnote",
  BOOK_END_NOTE: "bookendnote",
  SC_NOTE: "sc-note",
  SC_MARK: "sc-mark",
  SC_BOOKMARK: "sc-bookmark",
};

export const SubsPreffix = "sub";

export const bookIdRegex = /b[0-9]*/;
export const folderIdRegex = /^[0-9]+$/;
export const langRegex = /^[a-z]+$/;

export const bookIdRegexStrict = new RegExp(`^${bookIdRegex.source}$`);
export const paraWithoutPrefixRegExp = /(\d+)\.(\d+)/;

export const DEFAULT_BIBLES = {
  en: "1965",
};

/**
 * @param {string | number} id
 * @return {string} e.g. "b127"
 */
export const getBookId = (id) => {
  if (!id) {
    return undefined;
  }
  if (bookIdRegexStrict.test(id)) {
    return id;
  }

  const value = Number.isInteger(id) ? id.toString() : id;
  const [book] = value.split(".");
  return "b" + book;
};

export const getParagraph = (paraId, paragraphs) => {
  if (paraId) {
    const book = getBookId(paraId);
    const bookContent = paragraphs[book];

    if (bookContent) {
      const paragraph = bookContent.find((item) => item.id === paraId);
      if (paragraph) {
        return paragraph;
      }
    }
  }
  return undefined;
};

export const getBookOrigin = (value) => {
  if (!value) {
    return undefined;
  }
  if (typeof value === "string") {
    let outValue = value.split(".")[0].replace(/[a-z]/, "");
    return parseInt(outValue);
  }
  return value;
};

/**
 * method checkes ids for one book. canc check paraId and bookId
 * @param {*} paraId  123.45 or b123
 * @param {*} paraId1 123.45 or b123
 */
export const isSameBook = (paraId, paraId1) => {
  if (!paraId || !paraId1) {
    return false;
  }
  return getBookId(paraId) === getBookId(paraId1);
};

export const renderYearAndName = ({ pub_year: pubYear, pub_name: pubName }) => {
  const year = Number(pubYear) !== 0 ? pubYear : "";
  return (year ? `${year} ${pubName}` : pubName) || "";
};

export const removeBR = (text, newValue = " ") => text.replace(/<br\s*[/]?>/gi, newValue);

const makeChapter = (chapter, bookType) => ({
  ...chapter,
  label: chapter.title || chapter.refcode_short || chapter.para_id,
  className: "chapter",
  type: bookType,
  id: chapter.para_id,
});

const parseTocInternal = (initialData, initialLevel, initialType) => {
  let tracklistIndex = 0;
  const toc = (data, level, type) => {
    if (!data.length) {
      return [];
    }
    const result = [];
    const accumulator = [];
    let lastChunk = null;
    for (let i = 0; i < data.length; i++) {
      const chap = makeChapter(data[i], type);
      if (chap.level === level) {
        if (lastChunk != null) {
          const resultItem = lastChunk;
          if (lastChunk.mp3) {
            resultItem.cursor = tracklistIndex;
            tracklistIndex++;
          }
          resultItem.children = toc(accumulator, level + 1, type);
          result.push(resultItem);
          accumulator.length = 0;
        }
        lastChunk = chap;
      } else {
        accumulator.push(chap);
      }
    }
    const resultItem = lastChunk;
    if (lastChunk.mp3) {
      resultItem.cursor = tracklistIndex;
      tracklistIndex++;
    }
    resultItem.children = toc(accumulator, level + 1, type);
    result.push(resultItem);
    return result;
  };
  return toc(initialData, initialLevel, initialType);
};

export const convertChapters = (content) => {
  let cursor = 0;
  return content.map((item) => {
    const newItem = {
      ...item,
      className: "chapter",
      id: item.para_id,
    };
    if (item.mp3) {
      newItem.cursor = cursor;
      cursor++;
    }
    return newItem;
  });
};

export const makeChapterTree = (book, content) => {
  const type = book.realType;
  const children = parseTocInternal(content, 0, type);
  let bookChildren = children;
  if (children[0].children.length > 1) {
    bookChildren = children[0].children;
  }
  const chapters = convertChapters(content);
  return {
    children: bookChildren,
    chapters,
  };
};

export const convertAbbreviation = (abbr) => {
  const chars = [];
  abbr.abbreviations.forEach((string) => {
    chars.push(string.trim().substr(0, 1));
  });
  return {
    ...abbr,
    firstChars: chars,
  };
};

export const convertAbbreviations = (abbreviations) => {
  return {
    egw: abbreviations.egw.map((abbr) => convertAbbreviation(abbr)),
    bible: abbreviations.bible.map((abbr) => convertAbbreviation(abbr)),
  };
};

export const bookPermission = {
  purchased: "purchased",
  public: "public",
};

export const cutOutHeaders = (dateContent) => {
  dateContent.forEach((item) => {
    item.content = item.content.filter((tag) => !tag.includes("</h1>"));
  });
  return dateContent;
};

export const normalizeSubsContent = (content) => {
  return content.map((item) => ({
    ...item,
    className: "subscription",
    // label: item?.book_title // text app crash build
    label: item ? item.book_title : undefined,
  }));
};

export const removeTags = (text = "", newValue = "") => {
  const re = /(<([^>]+)>)/g;

  if (re.test(text)) {
    return text.replace(/(<([^>]+)>)/g, newValue);
  }

  return text;
};

/**
 * Sort base array based on sort list
 * @param {array} baseList 
 * @param {array} sortList 
 * @param {string} key
 * @returns sorted array
 */
export const sortByArray = (baseList, sortList, key) => {
  const outList = [...baseList];
  const indexList = {};
  sortList.forEach((item, index) => (indexList[item] = index + 1));
  outList.sort((a, b) => {
    const valA = key ? a[key] : a;
    const valB = key ? b[key] : b;
    const orderA = indexList[valA] || outList.length;
    const orderB = indexList[valB] || outList.length;
    return orderA - orderB;
  });

  return outList;
};
