/* eslint-disable no-console */
import { put, call, select, takeLatest, delay, all } from "redux-saga/effects";

import {
  searchRequest,
  searchTopicsRequest,
  searchExternalSuggestionsRequest,
  getDetectSuggestionsRequest,
  getPosibleTranslateLangRequest,
  getTranslateRequest,
  searchDictionaryRequest,
  getAllShortcutsRequest,
  searchBookByNameRequest,
} from "../../api/SearchAPI";
import { actionRelatedType } from "../relevantSearch/relevantSearch.actions";
import {
  putHistoryRequest,
  getContentPreviewRequest,
  getFoldersInLangRequest,
} from "../../api/API";

import {
  clearSearchHistory,
  CorrectionsType,
  saveSearchHistory,
  sortByScoreAndCount,
} from "./search.utils";

import { actionLoading, actionAddMessage } from "../../redux/actions";
import { SearchActions, SearchConstants } from "./search.actions";
import {
  arraymove,
  arrayToObject,
  findNode,
  getLanguageDefault,
  getNodePathIds,
  HistoryType,
  isArraysContentsEquals,
  joinObjArrays,
  removeFromArrayWithArray,
} from "../../utils/Utils";
import { getSearchLoaderId, isEqualSearch, validateSearchQuery } from "../../utils/SearchUtils";
import { Period } from "../../utils/AdvSearchTypes";
import { TOAST_TYPE } from "../popup/Toaster";
import {
  aplFolders,
  getTopics,
  LOADER_SUGGESTION_ID,
  PeriodDefault,
} from "../../shared/utils/search";
import {
  bookIdRegex,
  folderIdRegex,
  getBookId,
  getBookOrigin,
  langRegex,
  sortByArray,
} from "../../shared/utils/content";
import { initialSearchState } from "./search.reducer";
import { parseTreeWithChecked, TreeMenuMode } from "src/utils/TreeUtils";
import { SearchPanelTabs } from "../rightPanel/constants";
import { featuredSearchActions } from "../featuredSearch/featuredSearch.actions";
import { RelatedTabType } from "../relevantSearch/RelevantSearch.utils";
import { currentTreeReducerSelector } from "../../redux/selectors";
import { ContentActions } from "src/redux/content.actions";

const TAG = "searchSaga_";

const AMOUNT_OF_RESULTS = 100;
const SEARCH_LIST_DELTA = 30;

function* _fetchTopicsMethod(query) {
  try {
    const { topicQuery } = yield select((state) => state.search);
    if (topicQuery !== query) {
      const { facets: result } = yield call(searchTopicsRequest, query);
      if (result) {
        const topics = getTopics(result);
        yield put(SearchActions.fetchTopicsSuccess(query, topics));
      }
    }
  } catch (error) {
    console.log("_fetchTopicsMethod error", error);
    yield put(actionAddMessage("Cant Load topics", TOAST_TYPE.error));
  }
}

/**
 * Get Checked folders from trees and compare old and new search params
 * call {@link _fetchSearchContent} for make search by updated parameters
 * @param {Object} searchQueryParams
 * @param {Number} index
 * @returns
 */
function* _makeSearch(searchQueryParams, index = 1) {
  const searchId = getSearchLoaderId(searchQueryParams.isLocal, searchQueryParams.rightPanel);
  try {
    const {
      localSearch: localSearchResults,
      search: searchResults,
      searchHistory,
      searchParams,
      localSearchParams,
    } = yield select((state) => state.search);

    // is it must be here?
    const defaultSearchQuery =
      !searchQueryParams.isLocal && searchHistory[0] ? searchHistory[0].query : "";

    if (!searchQueryParams.query && defaultSearchQuery) {
      searchQueryParams = { query: defaultSearchQuery, ...searchQueryParams };
    }

    if (!validateSearchQuery(searchQueryParams.query)) {
      return;
    }
    const { rightTab, isShowRightPanel } = yield select((state) => state.settings);
    // not needed just load it here, move to component call on demand only
    if (isShowRightPanel && rightTab === SearchPanelTabs.FEATURED) {
      yield put(
        featuredSearchActions.fetch({
          query: searchQueryParams.query,
          langs: searchParams.folders,
        }),
      );
    }

    let newSearchQueryParams = { ...searchQueryParams };

    if (!searchQueryParams.isLocal) {
      const { checked } = yield select(currentTreeReducerSelector);

      if (checked.length) {
        // Search by Tree checked
        const { folders, books, period, type } = yield call(_getCurrentTreeSearchParams);

        newSearchQueryParams = {
          ...searchQueryParams,
          folders,
          books,
          extras: {
            ...searchQueryParams.extras,
            period,
            type,
          },
        };
      } else {
        // If no selected languages filter, because it stores selected languages in {folders}.
        if (!searchQueryParams.folders?.length) {
          // Add languages from user library
          const folders = [];

          const { libraryLanguages } = yield select((state) => state.settings);
          if (libraryLanguages) {
            folders.push(...libraryLanguages);
          }

          const searchLang = yield select((state) => state.search.searchLang);
          if (searchLang) {
            if (folders.length === 0) {
              folders.push(searchLang);
            } else {
              const index = folders.indexOf(searchLang);
              if (index !== -1 && index !== 0) {
                arraymove(folders, index, 0);
              }
            }
            folders.push("all");
          }

          newSearchQueryParams = {
            ...searchQueryParams,
            folders,
          };
          // ==============================
        }
      }
    }

    const isEqual = isEqualSearch(newSearchQueryParams, searchParams, localSearchParams);

    const start = index || 1;
    const reminder = start % AMOUNT_OF_RESULTS;
    let position = start - reminder;

    const results = searchQueryParams.isLocal ? localSearchResults : searchResults;
    // TODO get only one portion of content
    const content = results.results;

    const resultIndex = content.findIndex((item) => item.index === start);
    // call special info only for change query, not needed to load it if change folder

    if (
      isEqual &&
      (resultIndex !== -1 || (content.length !== 0 && results.total === content.length))
    ) {
      // load by delta for avoid emptry result in list on edge of loaded parts
      const deltaPlusIndex = content.findIndex((item) => item.index === start + SEARCH_LIST_DELTA);
      const deltaMinusIndex = content.findIndex((item) => item.index === start - SEARCH_LIST_DELTA);
      if (deltaPlusIndex === -1) {
        position += AMOUNT_OF_RESULTS;
      } else if (deltaMinusIndex === -1) {
        position -= AMOUNT_OF_RESULTS;
      }

      if (position < 0) {
        position = 0;
      }

      if (
        (deltaPlusIndex !== -1 && deltaMinusIndex !== -1) ||
        (position === 0 && content[0]?.index === 0)
      ) {
        return;
      }
    }

    yield call(_fetchSearchContent, {
      searchId,
      searchQueryParams: newSearchQueryParams,
      start: position,
    });

    // dont save local search
    if (!searchQueryParams.isLocal && !searchQueryParams.isSuggest) {
      yield put(SearchActions.fetchSearchHistory(searchQueryParams.query));
    }
  } catch (error) {
    yield put(actionLoading(searchId));
    console.log("function*_makeSearch error ->", error);
  }
}

/**
 * Make search request(s) and stor it in store
 * @param {*} param0
 */
function* _fetchSearchContent({ searchId, searchQueryParams, start }) {
  try {
    yield put(actionLoading(searchId));

    const loadPosition = yield call(searchRequest, {
      ...searchQueryParams,
      start,
    });

    // BEGIN  MEREGE AND STORE SEARCH CONTENT
    const {
      searchParams,
      localSearchParams,
      search: searchResults,
      localSearch: localSearchResults,
      lastPosition,
      rightPanelPosition,
    } = yield select((state) => state.search);

    const isEqual = isEqualSearch(searchQueryParams, searchParams, localSearchParams);
    let newLastPosition = isEqual ? lastPosition : 1;
    let newRightPanelPosition = isEqual ? rightPanelPosition : 1;

    delete searchQueryParams.start;
    if (searchQueryParams.isLocal) {
      const localSearchContent = { ...loadPosition };
      if (isEqual) {
        const allResult = joinObjArrays(
          [localSearchResults.results, loadPosition.results],
          "index",
        );
        localSearchContent.results = allResult;
      } else {
        // clear filter show search result
        yield put(SearchActions.toogleSearchMark("all"));
      }

      yield put(
        SearchActions.fetchSearchSuccess({
          localSearchParams: searchQueryParams,
          localSearch: localSearchContent,
        }),
      );
    } else {
      const searchContent = { ...loadPosition };

      if (isEqual) {
        const allResult = joinObjArrays([searchResults.results, loadPosition.results], "index");
        searchContent.results = allResult;
      } else {
        // clear filter show search result
        yield put(SearchActions.toogleSearchMark("all"));
      }

      // FIXME maybe not best place for it
      const { searchNeedle } = yield select((state) => state.search);
      if (searchQueryParams.query !== searchParams.query || !searchNeedle) {
        yield put(SearchActions.setSearchNeedleChange(searchQueryParams.query));
      }

      yield put(
        SearchActions.fetchSearchSuccess({
          searchParams: { ...searchQueryParams, firstSearch: false },
          // prevent set an empty object when got 400
          search: searchContent?.total ? searchContent : { total: 0, results: [] },
          lastPosition: newLastPosition,
          rightPanelPosition: newRightPanelPosition,
        }),
      );
      if (isEqual) {
        yield call(_fetchTopicsMethod, searchQueryParams.query);
      }
      // loads data about books
      if (searchQueryParams.books?.length > 0) {
        yield put(ContentActions.fetchBooksByIds(searchQueryParams.books));
      }
    }
  } catch (error) {
    console.log("function* _fetchSearchContentMethod =>", error);
  } finally {
    yield put(actionLoading(searchId, true));
  }
}

/**
 *
 * @param {string[]} langList
 * @param {string} priorityLang
 * @param {Object} langScoreMap
 * @returns
 */
function* _makeLangSort(langList, priorityLang, langScoreMap = {}) {
  const { mainTree } = yield select((state) => state.mainTree);
  const langCountMap = {};
  mainTree.forEach((item) => {
    langCountMap[item.code] = item.book_count;
  });
  const { libraryLanguages } = yield select((state) => state.settings);
  const defLang = getLanguageDefault(libraryLanguages);
  let sortedList = [...langList];
  sortedList.sort((a, b) => {
    return sortByScoreAndCount(b, a, langScoreMap, langCountMap);
  });
  let reverseLangs = [...libraryLanguages];

  if (reverseLangs.length === 0) {
    reverseLangs.push(defLang);
  }
  if (priorityLang) {
    const indexPriority = reverseLangs.indexOf(priorityLang);
    if (indexPriority === -1) {
      reverseLangs.unshift(priorityLang);
    } else if (indexPriority !== -1 && indexPriority !== 0) {
      reverseLangs.splice(indexPriority, 1);
      reverseLangs.unshift(priorityLang);
    }
  }
  sortedList = sortByArray(sortedList, reverseLangs);

  return sortedList;
}

function* fetchPossibleTranslateMethod(action) {
  const { lang } = action.data;
  try {
    const translateResponse = yield call(getPosibleTranslateLangRequest, lang);
    const langs = yield _makeLangSort(translateResponse?.languages);
    yield put(SearchActions.fetchPossibleTranslateSuccess(langs));
  } catch (error) {
    yield put(SearchActions.fetchPossibleTranslateSuccess([]));
    console.log(TAG + "fetchPossibleTranslateMethod", error);
  }
}

function* fetchDetectWorker(action) {
  const { query, withDetect, currentLang } = action.data;
  try {
    const trimQuery = query.trim();

    // TODO if possible join in one API call
    const detectResponce = yield call(getDetectSuggestionsRequest, trimQuery);
    const detectLangs = detectResponce.map((item) => item.language);
    const langScore = arrayToObject(detectResponce, "language", "score");
    // use score of detect lang for better check order
    const detectedLangs = yield _makeLangSort(detectLangs, currentLang, langScore);

    let translatedLangList;
    // Instantly get translation langs for the first detected lang
    if (withDetect && detectedLangs[0]) {
      const [firstLang] = detectedLangs;
      const translate = yield call(getPosibleTranslateLangRequest, firstLang);
      translatedLangList = yield _makeLangSort(translate?.languages, currentLang);
    }
    yield put(SearchActions.fetchDetectSuccess(detectedLangs, translatedLangList));
  } catch (error) {
    console.log(TAG + "fetchDetectWorker", error);
  }
}

function* fetchTranslateMethod(action) {
  const { fromLang, toLang, query } = action.data;
  try {
    const translatedQuery = yield call(getTranslateRequest, { fromLang, toLang }, query);
    const newTranslateQuery = translatedQuery.translation;
    yield put(SearchActions.setSearchQuery(newTranslateQuery, toLang));
    yield put(SearchActions.fetchSuggestion(newTranslateQuery, toLang));
  } catch (error) {
    console.log(TAG + "fetchTranslateAsync", error);
  }
}

const _getSearchPeriodByTree = (tree, checked, baseBible) => {
  let period = Period.custom;

  const { idsWithCheckedAll, idsChildrenOfCheckedAll } = parseTreeWithChecked(tree, checked);
  const checksWithoutChildrenOfCheckedAll = [...checked];
  removeFromArrayWithArray(checksWithoutChildrenOfCheckedAll, idsChildrenOfCheckedAll);

  if (idsWithCheckedAll.includes(baseBible)) {
    const pathIds = getNodePathIds(tree, baseBible);

    if (isArraysContentsEquals(pathIds, checksWithoutChildrenOfCheckedAll)) {
      period = Period.myBible;
    }
  } else if (aplFolders.every((aplFolderId) => idsWithCheckedAll.includes(aplFolderId))) {
    const pathIdsAplFolders = [];
    for (let i = 0; i < aplFolders.length; i++) {
      pathIdsAplFolders.push(...getNodePathIds(tree, aplFolders[i]));
    }

    if (isArraysContentsEquals(pathIdsAplFolders, checksWithoutChildrenOfCheckedAll)) {
      period = Period.apl;
    }
  }

  return period;
};

function* _getCurrentTreeSearchParams() {
  const { treeMenuMode, baseBible } = yield select((state) => state.settings);

  const isTreeFolders = treeMenuMode === TreeMenuMode.FOLDERS;
  const currentTreeReducer = yield select(currentTreeReducerSelector);
  const { checked } = currentTreeReducer;
  const tree = isTreeFolders
    ? yield select((state) => state.mainTree.mainTree)
    : currentTreeReducer.tree;

  const langs = [],
    folders = [],
    books = [];

  let treeToParse;
  if (isTreeFolders) {
    const checkedLangsIds = checked.filter((checkedId) => langRegex.test(checkedId));
    const isCheckedSeveralLangs = checkedLangsIds.length > 1;

    if (isCheckedSeveralLangs) {
      treeToParse = [];

      let langNode, checkedLangId;
      for (let i = 0; i < checkedLangsIds.length; i++) {
        checkedLangId = checkedLangsIds[i];
        langNode = findNode(checkedLangId, tree);

        langs.push(checkedLangId);

        if (langNode.children) {
          treeToParse.push(...langNode.children);
        } else {
          const languageFolders = yield getFoldersInLangRequest(checkedLangId);
          folders.push(...languageFolders.map((folder) => folder.folder_id));
        }
      }
    } else {
      treeToParse = tree;
    }
  } else {
    treeToParse = tree;
  }

  const { idsWithCheckedAll } = parseTreeWithChecked(treeToParse, checked);

  let idCheckedAll;
  for (let i = 0; i < idsWithCheckedAll.length; i++) {
    idCheckedAll = idsWithCheckedAll[i];

    if (bookIdRegex.test(idCheckedAll)) {
      books.push(idCheckedAll);
    } else if (folderIdRegex.test(idCheckedAll)) {
      folders.push(idCheckedAll);
    } else if (langRegex.test(idCheckedAll)) {
      langs.push(idCheckedAll);
    }
  }

  return {
    // {langs} are spread to the {folders}, because of
    // {basicSearchRequest} does not support {langs} property for now.
    // {isTreeFolders} condition, because of only folders tree supports search by folders.
    folders: isTreeFolders ? [...folders, ...langs] : [],
    books,
    period: _getSearchPeriodByTree(tree, checked, baseBible),
    type: "basic",
  };
}

/**
 * Loads shortcut by query string,  check lang of query,
 * check is shortcut for bible book
 * @param {String} query
 * @returns {Object}
 */
function* _getShortcut(query = "") {
  const { shortcuts } = yield select((state) => state.search);
  const trimQuery = query.trim();
  let shortcut = shortcuts[trimQuery];
  if (trimQuery && shortcut === undefined) {
    try {
      const shortcutResponse = yield call(getAllShortcutsRequest, trimQuery, true);

      if (shortcutResponse) {
        let filterShortcuts = shortcutResponse;
        if (shortcutResponse?.[0]?.cutQuery) {
          const trimQuery = shortcutResponse?.[0]?.cutQuery.trim();
          const detectResponce = yield call(getDetectSuggestionsRequest, trimQuery);
          let detectMap = arrayToObject(detectResponce, "language", "score");
          filterShortcuts = shortcutResponse.filter(
            (item) => detectMap[item.lang] !== undefined && item.hits !== 0,
          );
        }
        // loads and cache all possible books for shortcuts
        yield put(ContentActions.fetchBooksByIds(shortcutResponse.map((item) => item.bookId)));
        shortcuts[trimQuery] = filterShortcuts;
      } else {
        shortcuts[trimQuery] = [];
      }
    } catch (error) {
      console.log("_getShortcut_error", error);
    }
    shortcut = shortcuts[trimQuery];
    yield put(SearchActions.fetchShortcutSuccess({ ...shortcuts }));
  }
  let isBible = false;
  if (shortcut) {
    const { baseBible } = yield select((state) => state.settings);
    const baseBibleIndex = shortcut.findIndex((item) => item.bookId === baseBible);
    if (baseBibleIndex !== -1) {
      isBible = true;
    }
    if (baseBibleIndex !== -1 && baseBibleIndex !== 0) {
      const [bibleShortCut] = shortcut.splice(baseBibleIndex, 1);
      shortcut.unshift(bibleShortCut);
    }
  }

  return { shortcuts: shortcut, isBible };
}

function* fetchSearchHistoryMethod(action) {
  const { isLogin } = yield select((state) => state.system);
  const query = action.data;

  if (!isLogin || !query) {
    return;
  }
  const { searchHistory } = yield select((state) => state.search);
  const [lastSearchValue] = searchHistory;
  // skip update history for same query value
  // TODO add check save last time
  if (lastSearchValue?.query === query) {
    return;
  }
  yield delay(1000);

  const savedSearchHistory = saveSearchHistory(query);
  if (savedSearchHistory.items) {
    //FIXME save fix luDate
    const luDate = yield call(putHistoryRequest, HistoryType.SEARCH, savedSearchHistory);
    //remove duplicate query from searchHistory
    let historyQuery = searchHistory.filter((data) => {
      if (data.query !== query) {
        return data;
      }
      return false;
    });
    // add last search query to search history
    historyQuery.unshift(savedSearchHistory.items[0]);
    if (historyQuery.length > 1000) {
      // remove oldest result from search history
      historyQuery.pop();
    }
    yield put(SearchActions.fetchSearchHistorySuccess(historyQuery));
  }
}
/**
 * fetchSearchMethod -> {@link _makeSearch} ->
 * @param {*} action
 * @returns
 */
function* fetchSearchWorker(action) {
  const { searchQueryParams, index = 1, delya: delayTime } = action.data;
  const { query, extras, externalList, context, onlyText } = searchQueryParams;
  yield delay(delayTime || 1000);
  const { type, lang } = extras;

  if (type === "related" && context === "suggestions" && lang) {
    const resultsSuggestions = yield call(searchExternalSuggestionsRequest, query, lang);

    let list = resultsSuggestions["suggestions"].map((data, key) => {
      return {
        group: "external",
        index: key,
        result: data,
      };
    });
    yield put(
      SearchActions.fetchSearchSuccess({
        searchParams: searchQueryParams,
        search: { total: list.length, results: list },
        topics: [],
      }),
    );
    return;
  }
  if (type === "related" && externalList) {
    let list = externalList.map((data, key) => {
      return {
        group: "external",
        index: key,
        refcode_short: data,
      };
    });
    yield put(
      SearchActions.fetchSearchSuccess({
        searchParams: searchQueryParams,
        search: { total: list.length, results: list },
        topics: [],
      }),
    );
    return;
  }
  if (type !== "basic") {
    yield call(_makeSearch, searchQueryParams, index);
    return;
  }

  if (searchQueryParams.isLocal) {
    // local search case doesn't need shortcut processing
    if (index === 1) {
      // make global & local search with local start==1 search
      const { searchParams } = yield select((state) => state.search);
      const params = { ...searchParams, query };
      yield call(_makeSearch, params, index);
    }
    yield call(_makeSearch, searchQueryParams, index);
    return;
  }
  // basic search case
  const { initialChecked } = yield select((state) => state.folderTree);

  // get possible shortcut by query
  let { shortcuts, isBible } = yield call(_getShortcut, query);

  let shortcut;
  let newPeriod;
  if (shortcuts && shortcuts.length > 0) {
    // This place for check and update period, because some default period "both"
    // breaks search in many books
    shortcut = shortcuts[0];
    const shorcutBookId = shortcut.bookId;

    if (shorcutBookId && shortcut.hits > 0) {
      const bookOriginId = getBookOrigin(shorcutBookId);
      const aplBook = initialChecked.apl.books.find((item) => item.id === bookOriginId);

      if (aplBook) {
        newPeriod = Period.custom;
      } else if (isBible) {
        newPeriod = Period.myBible;
      }
    }
  }

  let newSearchData = searchQueryParams;
  if (!shortcut || (shortcut && (shortcut.type !== "search" || shortcut.hits === 0))) {
    // delete searchQueryParams.chapters;
    newSearchData = searchQueryParams;
  } else if (!onlyText) {
    // else for search like "aa jesus"
    // current shortcut type is 'search'
    newSearchData = {
      query: shortcut.cutQuery,
      extras: { ...extras, period: newPeriod || Period.custom },
    };
    if (shortcut.paraId && shortcut.type === "search") {
      newSearchData.chapters = [shortcut.paraId];
    } else {
      newSearchData.books = [shortcut.bookId];
    }
    newSearchData.langs = [shortcut.lang];
  }

  if (!onlyText) {
    let newTabType = RelatedTabType.EGW;

    if (isBible || newSearchData.extras.period === Period.myBible) {
      newTabType = RelatedTabType.BIBLE;
    }

    yield put(actionRelatedType(newTabType));
  }

  yield call(_makeSearch, newSearchData, index);
}

function* fetchSearchByRange(action) {
  try {
    let response = yield call(searchRequest, action.data);
    const data = response.results.filter((item) => item.index <= action.data.end);
    yield put(SearchActions.fetchSearchByRangeSuccess(data));
  } catch (error) {
    yield put(SearchActions.fetchSearchByRangeError(error));
  }
}

function* deleteSearchHistory() {
  yield call(clearSearchHistory);
  yield put(SearchActions.fetchSearchHistorySuccess([]));
  yield put(SearchActions.fetchSearchSuccess(initialSearchState));
}

// get all data for search popup
function* fetchSuggestionWorker(action) {
  try {
    const { query, currentLang, withDetect } = action.data;
    if (query && query !== "") {
      const trimedQuery = query.trim();
      yield put(actionLoading(LOADER_SUGGESTION_ID));
      const { libraryLanguages } = yield select((state) => state.settings);
      const { searchedQuery, corrections } = yield select((state) => state.search.searchFilter);
      const defLang = getLanguageDefault(libraryLanguages);

      if (withDetect) {
        yield call(fetchDetectWorker, { data: { query: trimedQuery, withDetect, currentLang } });
      }

      // TODO split to separate block
      let { shortcuts } = yield call(_getShortcut, trimedQuery);
      let suggestionQuery = trimedQuery;
      if (shortcuts?.[0]?.cutQuery) {
        suggestionQuery = shortcuts?.[0]?.cutQuery;
      }

      let resultsCorrect = yield call(
        searchExternalSuggestionsRequest,
        suggestionQuery,
        currentLang ? currentLang : defLang,
      );
      let newCorrections = trimedQuery === searchedQuery?.trim() ? [...corrections] : [];

      try {
        const { searchQueryLangsCache, queryCorrectionsCache } = yield select(
          (state) => state.search,
        );
        // cache work only for get request now. keep now in redux
        let searchQueryLangs = searchQueryLangsCache[suggestionQuery];
        let correction = queryCorrectionsCache[suggestionQuery];
        if (!searchQueryLangs) {
          const result = yield call(searchRequest, {
            query: suggestionQuery,
            start: 0,
            limit: 1,
          });
          searchQueryLangs = result?.languageFacets;
          correction = result?.suggested_query;
        }

        if (correction) {
          newCorrections.push({ title: correction, type: CorrectionsType.CORRECTION });
        }
        yield put(
          SearchActions.fetchLangsForWordSuccess(searchQueryLangs, suggestionQuery, correction),
        );
      } catch (error) {
        console.log("function*asyncFetchLangsForWord -> error: ", error);
      }

      let suggestionResult;
      if (resultsCorrect) {
        if (shortcuts?.length > 0) {
          newCorrections = joinObjArrays(
            [
              newCorrections,
              shortcuts.map((item) => ({ ...item, type: CorrectionsType.SUGGESTION })),
            ],
            "title",
          );
        } else {
          if (
            resultsCorrect?.did_you_mean &&
            typeof resultsCorrect.did_you_mean === "string" &&
            resultsCorrect.did_you_mean !== trimedQuery
          ) {
            newCorrections.push({
              title: resultsCorrect.did_you_mean,
              type: CorrectionsType.SUGGESTION,
            });
          } else if (
            resultsCorrect?.did_you_mean &&
            typeof resultsCorrect.did_you_mean !== "string" &&
            resultsCorrect.did_you_mean[0] !== trimedQuery
          ) {
            newCorrections.push(
              ...resultsCorrect.did_you_mean.map((item) => ({
                title: item,
                type: CorrectionsType.SUGGESTION,
              })),
            );
          }
        }
        let autocomplete =
          resultsCorrect?.autocomplete.length > 0 ? resultsCorrect.autocomplete : [];
        if (autocomplete.length > 0) {
          autocomplete = autocomplete.filter((autocompleteItem) => {
            return autocompleteItem.trim() !== trimedQuery;
          });
        }
        newCorrections.sort((a, b) => b.type - a.type);

        suggestionResult = {
          searchedQuery: trimedQuery,
          shortcuts,
          corrections: newCorrections,
          suggestions: resultsCorrect.suggestions,
          synonyms: resultsCorrect.synonyms,
          autocomplete,
        };
      }
      yield put(SearchActions.fetchSuggestionSuccess(suggestionResult));
    }
  } catch (error) {
    console.log(TAG + "fetchSuggestionAsync", error);
  } finally {
    yield put(actionLoading(LOADER_SUGGESTION_ID, true));
  }
}

function* searchWithCurrentTree() {
  const searchParams = yield select((state) => state.search.searchParams);
  const { collectionFilter } = yield select((state) => state.settings);

  if (searchParams.query !== "") {
    yield call(_makeSearch, {
      ...searchParams,
      folders: [],
      books: [],
      extras: {
        ...searchParams.extras,
        period: collectionFilter || PeriodDefault,
        type: "basic",
      },
    });
  }
}

function* fetchSearchDictionaryWorker(action) {
  try {
    let result;
    const { value, key } = action.data;
    result = yield call(searchDictionaryRequest, { value, key });

    if (Array.isArray(result)) {
      yield put(SearchActions.searchDictionarySuccess(result));
    } else {
      yield put(SearchActions.searchDictionarySuccess([]));
    }
  } catch (error) {
    console.log(TAG + "function*fetchSearchDictionaryWorker -> error: ", error);
  }
}

/**
 * Loads translated paragraphs for shortcut
 * @param {*} action
 * @returns
 */
function* getShortcutContentMethod(action) {
  const { shortcut, paraId, queryAnalogue } = action.data;

  if (!queryAnalogue) {
    console.log(
      "%cPls specify {queryAnalogue}. It's needed for correct working of switching between" +
      "shortcut and text results via button.",
      "background: red; color: white;"
    );
  }

  const { shortcutContentCache } = yield select((state) => state.search);
  const { libraryLanguages, baseBible } = yield select((state) => state.settings);
  const defLang = getLanguageDefault(libraryLanguages);
  let sortArray = [...libraryLanguages];
  if (sortArray.length === 0) {
    sortArray.push(defLang);
  }

  let shortcutContent = [];
  const { bibles, books } = yield select((state) => state.mainTree);

  const paraIdForFetching = shortcut?.paraId || paraId;
  let bookId = getBookId(paraIdForFetching) || shortcut?.bookId;

  if (!bookId) {
    return;
  }
  let result;
  if (shortcutContentCache[paraIdForFetching]) {
    result = shortcutContentCache[paraIdForFetching].results;
  } else {
    // TODO add method for load and store book
    const originalBook = books.find((item) => item.id === bookId);

    let className = originalBook?.realType || "egwwritings";
    try {
      if (paraIdForFetching) {
        result = yield call(
          getContentPreviewRequest,
          paraIdForFetching,
          libraryLanguages,
          className,
        );
      }
      if (className.includes("bible") && result.length === 0) {
        result = bibles.map((item) => {
          return {
            className: "bible",
            refcode_long: item.label,
            content: item.description,
            paraId: item.first_para,
            lang: item.lang,
          };
        });
      }
    } catch (error) {
      console.log("getContentPreviewRequest", error);
    }
  }
  if (result) {
    shortcutContent = sortByArray(result, sortArray, "lang");
    const baseBibleIndex = shortcutContent.findIndex(
      (item) => getBookId(item.paraId) === baseBible,
    );
    if (baseBibleIndex !== -1 && baseBibleIndex !== 0) {
      arraymove(shortcutContent, baseBibleIndex, 0);
    }
  }

  yield put(
    SearchActions.fetchShotcutContentSuccess({
      paraId: paraIdForFetching,
      data: shortcutContent,
      queryAnalogue: shortcutContentCache[paraIdForFetching]?.queryAnalogue || queryAnalogue,
    }),
  );
}

function* searchBookByNameWorker(action) {
  const textQuery = action.data;
  let newSearchBookData = {
    total: 0,
    books: [],
    query: textQuery,
  };
  try {
    const { shortcuts, isBible } = yield call(_getShortcut, textQuery);
    const booksResponse = yield call(searchBookByNameRequest, textQuery);
    let books = [...booksResponse.results];
    let total = booksResponse.total;
    if (isBible && shortcuts?.[0]) {
      const { bibles } = yield select((state) => state.mainTree);
      const bible = bibles.find((item) => item.id === shortcuts?.[0].bookId);
      if (bible) {
        const existBook = books.find((item) => item.id === bible.id);
        if (!existBook) {
          books.unshift({
            ...bible,
            folder_type: "bible",
          });
          total++;
        }
      }
    } else {
      const { libraryLanguages } = yield select((state) => state.settings);
      const defLang = getLanguageDefault(libraryLanguages);
      let sortArray = [...libraryLanguages];
      if (sortArray.length === 0) {
        sortArray.push(defLang);
      }
      books = sortByArray(books, sortArray, "lang");
    }
    yield put(SearchActions.setSearchBookListSuppress(false));
    newSearchBookData = {
      total,
      books,
      query: textQuery,
    };
  } catch (error) {
    console.log(TAG + `searchBookByNameWorker`, error);
  }

  yield put(SearchActions.setSearchBookListSuppress(newSearchBookData.total === 0));
  yield put(SearchActions.searchBookByNameSuccess(newSearchBookData));
}

export default function* searchSaga() {
  yield all([
    takeLatest(SearchConstants.ACTION_SEARCH_HISTORY_DELETE, deleteSearchHistory),
    takeLatest(SearchConstants.ACTION_SEARCH_HISTORY, fetchSearchHistoryMethod),
    takeLatest(SearchConstants.ACTION_SEARCH, fetchSearchWorker),
    takeLatest(SearchConstants.ACTION_SEARCH_BY_RANGE, fetchSearchByRange),
    takeLatest(SearchConstants.SEARCH_WITH_CURRENT_TREE, searchWithCurrentTree),
    takeLatest(SearchConstants.FETCH_SUGGESTION, fetchSuggestionWorker),
    takeLatest(SearchConstants.FETCH_POSSIBLE_TRANSLATE, fetchPossibleTranslateMethod),
    takeLatest(SearchConstants.FETCH_TRANSLATE, fetchTranslateMethod),
    takeLatest(SearchConstants.FETCH_DETECT, fetchDetectWorker),
    takeLatest(SearchConstants.FETCH_SEARCH_DICTIONARY, fetchSearchDictionaryWorker),
    takeLatest(SearchConstants.ACTION_GET_SHORTCUT_CONTENT, getShortcutContentMethod),
    takeLatest(SearchConstants.SEARCH_BOOK_BY_NAME, searchBookByNameWorker),
  ]);
}
