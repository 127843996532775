import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Category, Loading } from "./index";
import { ScrollerView } from "./views";
import { findNode } from "../utils/Utils";
import { makeNavigationUrl } from "../utils/URLUtils";
import { useViewMode } from "../hooks";
import { RootFolder } from "./studyCenter/studyCenter.constants";
import { ContentActions } from "src/redux/content.actions";

const FolderList = ({
  lang,
  id,
  folderList,
  isAudio,
  isLibrary,
  showOrder,
  booksCountByLang,
  onOrderClick
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useViewMode();
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);

  useEffect(() => {
    if (lang) {
      dispatch(ContentActions.fetchFoldersRequest(lang));
    }
  }, [lang]);

  const targetId = id || lang || RootFolder.id;

  const folders = useMemo(() => {
    if (folderList) {
      return folderList;
    }
    if (targetId === RootFolder.id) {
      return mainTree;
    }
    const fold = findNode(targetId, mainTree);
    if (fold) {
      return fold.children || [];
    }
    return [];
  }, [targetId, mainTree, folderList]);

  const handleOrderClick = (e, lang) => {
    e.preventDefault();
    onOrderClick(lang);
  };

  if ((folders || []).length === 0) {
    return <Loading type={Loading.types.FOLDER_LIST} />;
  }

  const WrapperComponent = isMobile ? "div" : ScrollerView;

  return (
    <WrapperComponent className="folder-list">
      {(folders || []).map((item) => (
        <Category
          key={item.id}
          item={item}
          isDefault={libraryLanguages.indexOf(item.id) === 0}
          inLibrary={libraryLanguages.includes(item.id)}
          booksCountByLang={booksCountByLang}
          showOrder={showOrder}
          onOrderClick={handleOrderClick}
          route={makeNavigationUrl(item, { isAudio, isLibrary }).toString()}
          isAudio={isAudio}
        />
      ))}
    </WrapperComponent>
  );
};

FolderList.propTypes = {
  id: PropTypes.string,
  folderList: PropTypes.array,
  lang: PropTypes.string,
  isAudio: PropTypes.bool,
  showOrder: PropTypes.bool,
  booksCountByLang: PropTypes.object,
  firstOpen: PropTypes.bool,
  isLibrary: PropTypes.bool,
  onOrderClick: PropTypes.func
};

export default FolderList;
