import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Scroll } from "../views";
import { focusElement, selectorFocusableElements } from "../../utils/AccessibilityUtils";
import "./DropDown.scss";
import { KeyCodes } from "../../shared/utils/dom";
import { getBoundingClientRect } from "src/utils/Utils";
import { getOptionKey } from "./DropDown.utils";

export const DEFAULT_DROPDOWN_MAXHEIGHT = "7.1rem";

const DropDownOption = ({
  index, title, isActive, isCursor, item, handleSelect,
  withRadio, cursorOptionRef, optionsGroupIndex,
}) => {
  const itemId = getOptionKey(item);
  return (
    <button
      className={classNames("dd-option-container wide", {
        "dd-option-selected": isActive || isCursor,
        "dd-disabled-title": item.disabled,
      })}
      style={item.style}
      aria-checked={isActive ? "true" : "false"}
      onClick={() => handleSelect(item, index, optionsGroupIndex)}
      ref={isCursor ? cursorOptionRef : null}>
      {withRadio && (
        <React.Fragment>
          <input
            type="radio"
            id={itemId}
            className="dd-option-input"
            name="dd-option-radio"
          />
          <span className="dd-option-radio" />
        </React.Fragment>
      )}
      <label
        className="dd-option-default"
        htmlFor={itemId}
        style={item.style}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </button>
  );
};

DropDownOption.propTypes = {
  index: PropTypes.number,
  optionsGroupIndex: PropTypes.number,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  isCursor: PropTypes.bool,
  item: PropTypes.object,
  handleSelect: PropTypes.func,
  withRadio: PropTypes.bool,
  cursorOptionRef: PropTypes.object,
};

const handleCursorPrev = (groupIndex, optionIndex, groups) => {
  const lastGroupIndex = groups.length - 1;

  let result;
  if (optionIndex <= 0) {
    const newGroupIndex = groupIndex - 1;

    if (newGroupIndex < 0) {
      result = [lastGroupIndex, groups[lastGroupIndex].length - 1];
    } else {
      result = [newGroupIndex, groups[newGroupIndex].length - 1];
    }
  } else {
    result = [groupIndex, optionIndex - 1];
  }

  return result;
};

const handleCursorNext = (groupIndex, optionIndex, groups) => {
  const lastGroupIndex = groups.length - 1;
  const lastOptionIndex = groups[groupIndex].length - 1;

  let result;
  if (optionIndex >= lastOptionIndex) {
    const newGroupIndex = groupIndex + 1;

    if (newGroupIndex > lastGroupIndex) {
      result = [0, 0];
    } else {
      result = [newGroupIndex, 0];
    }
  } else {
    result = [groupIndex, optionIndex + 1];
  }

  return result;
};

const DropDown = React.forwardRef(
  (
    {
      autoHide,
      expanded,
      options,
      dropDownClassName,
      onDropDownClose,
      position,
      value,
      onGetTitle,
      onSelect,
      isPopup,
      maxHeight,
      innerClass,
      containerRef,
      isBackground = true,
      withRadio = false,
      focusOnExpand = true,
      focusFirstOption = false,
      slotOptionsAfter,
    },
    ref,
  ) => {
    const isValueArray = Array.isArray(value);
    const valueNormalized = useMemo(
      () => isValueArray ? value : [value],
      [isValueArray, value]
    );
    const optionsNormalized = useMemo(() => {
      return (!options.length || Array.isArray(options[0])) ? options : [options];
    }, [options]);
    const [selected, setSelected] = useState(valueNormalized);
    const [cursor, setCursor] = useState([]);
    const [dropDownHeight, setDropDownHeight] = useState(maxHeight);
    const dropDownRef = useRef();
    const customHeight = useRef(false);
    const defaultActiveElementRef = useRef();
    const cursorOptionRef = useRef();
    const activeIndexRef = useRef([]);

    useEffect(() => {
      if (containerRef && containerRef.current && dropDownRef.current) {
        const containerRect = getBoundingClientRect(containerRef.current);
        const dropDownRect = getBoundingClientRect(dropDownRef.current);
        const dropDownShadowY = 12;

        if (dropDownRect.bottom + dropDownShadowY > containerRect.bottom) {
          setDropDownHeight(containerRect.bottom - dropDownRect.top - dropDownShadowY);
          customHeight.current = true;
        } else {
          customHeight.current = false;
        }
      } else {
        customHeight.current = false;
      }
    }, [containerRef, dropDownRef]);

    useEffect(() => {
      setSelected(valueNormalized);
    }, [valueNormalized]);

    useEffect(() => {
      let timerId = 0;

      if (expanded && (focusOnExpand || focusFirstOption)) {
        if (timerId) {
          clearTimeout(timerId);
        }

        timerId = setTimeout(() => {
          const currentActiveElement = document.activeElement;
          if (currentActiveElement && !defaultActiveElementRef.current) {
            defaultActiveElementRef.current = currentActiveElement;
          }
          focusElement(dropDownRef?.current?.querySelectorAll(selectorFocusableElements));

          if (focusFirstOption) {
            setCursor([0, 0]);
          }
        }, 150);
      }

      return () => {
        if (timerId) {
          clearTimeout(timerId);
        }

        setTimeout(() => {
          if (defaultActiveElementRef.current) {
            defaultActiveElementRef.current.focus();
            defaultActiveElementRef.current = undefined;
          }
        }, 0);
      };
    }, [expanded, dropDownRef, focusOnExpand, focusFirstOption]);

    const handleSelect = (valueNew, optionIndex, optionsGroupIndex) => {
      const valueNormalizedNew = valueNormalized.map((value, index) => {
        if (index === optionsGroupIndex) {
          return optionsNormalized[optionsGroupIndex][optionIndex];
        }

        return optionsNormalized[index]
          .find((option) => getOptionKey(option) === getOptionKey(value));
      });
      setSelected(valueNormalizedNew);
      // if {value} was an array then return as an array of options.
      // if {value} was an object then return as an option.
      onSelect(
        isValueArray ? valueNormalizedNew : valueNormalizedNew[optionsGroupIndex],
        optionIndex
      );
    };

    const renderOptions = () => {
      const elements = [];

      optionsNormalized.forEach((optionsGroup, optionsGroupIndex) => {
        const selectedId = getOptionKey(selected[optionsGroupIndex]);

        optionsGroup.forEach((item, index) => {
          if (index === 0 && optionsGroupIndex > 0) {
            elements.push(<hr
              key={"divider" + optionsGroupIndex}
              className="drop-down_options-group-divider"
            />);
          }

          const itemId = getOptionKey(item);
          const isActive = itemId === selectedId;
          if (optionsGroupIndex === 0 && isActive) {
            activeIndexRef.current = [optionsGroupIndex, index];
          }
          const isCursor = optionsGroupIndex === cursor[0] && index === cursor[1];
          const title = onGetTitle ? onGetTitle(item) : item.label;

          if (title !== "") {
            elements.push(<DropDownOption
              key={optionsGroupIndex.toString() + itemId.toString()}
              index={index}
              isActive={isActive}
              isCursor={isCursor}
              title={title}
              handleSelect={handleSelect}
              withRadio={withRadio}
              item={item}
              cursorOptionRef={cursorOptionRef}
              optionsGroupIndex={optionsGroupIndex}
            />);
          }
        });
      });
      return elements;
    };
    useEffect(() => {
      if (cursorOptionRef.current) {
        cursorOptionRef.current.scrollIntoView({ block: "nearest" });
        cursorOptionRef.current.focus();
      }
    }, [cursor]);

    useEffect(() => {
      if (!expanded) {
        setCursor([]);
      }
    }, [expanded]);

    const onDefaultSelectKeyDown = (event) => {
      if (event.keyCode === KeyCodes.esc) {
        return;
      }

      let preventEvent = false;

      let optionsGroupIndexCursor = cursor[0] ?? activeIndexRef.current[0] ?? 0;
      let optionIndexCursor = cursor[1] ?? activeIndexRef.current[1] ?? -1;

      if (event.keyCode === KeyCodes.upArrow) {
        preventEvent = true;
        setCursor(handleCursorPrev(optionsGroupIndexCursor, optionIndexCursor, optionsNormalized));
      }
      if (event.keyCode === KeyCodes.downArrow || event.keyCode === KeyCodes.tab) {
        preventEvent = true;
        setCursor(handleCursorNext(optionsGroupIndexCursor, optionIndexCursor, optionsNormalized));
      }
      if (event.keyCode === KeyCodes.enter) {
        preventEvent = true;
        handleSelect(
          optionsNormalized[optionsGroupIndexCursor][optionIndexCursor],
          optionIndexCursor,
          optionsGroupIndexCursor,
        );
      }

      if (preventEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
      }
    };

    let dropDownShown;
    if (!isPopup) {
      dropDownShown = (expanded ? "showView" : "hideView") + " ddContainerAbsolute";
    }

    let scrollStyle = {};
    if (customHeight.current) {
      scrollStyle = {
        height: dropDownHeight,
      };
    }

    if (!slotOptionsAfter && !options.length) {
      return null;
    }

    return (
      <div
        ref={dropDownRef}
        className={classNames("ddContainer", dropDownClassName, dropDownShown)}
        style={position || {}}>
        <div
          ref={ref}
          onKeyDown={onDefaultSelectKeyDown}
          className={classNames("dropDown", innerClass)}>
          <Scroll
            autoHide={autoHide}
            universal
            noHidePopups
            autoHeight={!customHeight.current}
            style={scrollStyle}
            autoHeightMax={maxHeight ? maxHeight : DEFAULT_DROPDOWN_MAXHEIGHT}>
            {expanded && renderOptions()}
            {slotOptionsAfter}
          </Scroll>
        </div>
        {!isPopup && isBackground && <div className="ddBackground" onClick={onDropDownClose} />}
      </div>
    );
  },
);

DropDown.defaultProps = {
  options: [],
  autoHide: true, // This regulates scrollbar visibility. Probably, should be "false" by default.
};

DropDown.propTypes = {
  expanded: PropTypes.bool,
  options: PropTypes.array,
  children: PropTypes.node,
  dropDownClassName: PropTypes.string,
  onDropDownClose: PropTypes.func,
  position: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  onGetTitle: PropTypes.func,
  onSelect: PropTypes.func,
  isPopup: PropTypes.bool,
  autoHide: PropTypes.bool,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  emptyComponent: PropTypes.node,
  slotOptionsAfter: PropTypes.node,
  isBackground: PropTypes.bool,
  innerClass: PropTypes.string,
  withRadio: PropTypes.bool,
  focusOnExpand: PropTypes.bool,
  focusFirstOption: PropTypes.bool,
  containerRef: PropTypes.shape({
    current: PropTypes.any,
  }),
};

export default DropDown;
