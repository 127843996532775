import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { orderLanguages, getCurrentLang } from "../utils/Utils";

import { FolderList } from "./index";
import { libraryBooksSelector } from "../redux/selectors";
import { useAuth, useLangUpdate } from "../hooks";
import { setLibLangs } from "src/redux/library.actions";

const LanguageFolders = ({ lang, id, isLibrary, firstOpen, booksCountByLang }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const updateLang = useLangUpdate();
  const { isLogin } = useAuth();
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const languageUI = useSelector((state) => state.settings.languageUI);
  const inLibraryBooks = useSelector(libraryBooksSelector);

  useEffect(() => {
    if (libraryLanguages.length === 0 && inLibraryBooks.length > 0) {
      const langs = [getCurrentLang(i18n, languageUI)];

      inLibraryBooks.forEach((item) => {
        const bookLang = (item.bookInfo || {}).lang;
        if (!langs.includes(bookLang)) {
          langs.push(bookLang);
        }
      });
      dispatch(setLibLangs(langs));
    }
  }, [inLibraryBooks]);

  const langFolders = useMemo(() => {
    let clonedItems = [...mainTree];
    if (isLogin && !firstOpen) {
      clonedItems = orderLanguages(clonedItems, libraryLanguages);
    }
    return clonedItems || [];
  }, [mainTree, libraryLanguages, isLogin, firstOpen]);

  return (
    <FolderList
      lang={lang}
      id={id}
      folderList={lang ? undefined : langFolders}
      onOrderClick={updateLang}
      showOrder={isLogin ? lang === undefined : false}
      isLibrary={isLibrary}
      booksCountByLang={booksCountByLang}
    />
  );
};

LanguageFolders.propTypes = {
  id: PropTypes.string,
  lang: PropTypes.string,
  isLibrary: PropTypes.bool,
  firstOpen: PropTypes.bool,
  booksCountByLang: PropTypes.object
};

export default LanguageFolders;
