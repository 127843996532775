import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeResourceUrl } from "../../../shared/utils/url";
import { makeNavigationUrl } from "../../../utils/URLUtils";
import { IconButton } from "../../views";
import CommonPopup from "./commonPopup";

import "./commonPopup.scss";
import EgwWebFont from "src/assets/EgwWebFont";

class EpubPopup extends Component {
  constructor(props) {
    super();
    const { history, book } = props;
    this.popupRef = React.createRef();
    this.state = {
      book,
      history
    };
  }

  show = (newState, bounds) => {
    this.setState({ book: newState.book });
    this.popupRef.current.show(newState, bounds);
  };

  handleReaderClick = () => {
    const epubReaderUrl = makeNavigationUrl({
      className: "epub",
      id: this.state.book.id
    });
    this.state.history.push(epubReaderUrl);
  };

  render() {
    if (!this.state.book.children) {
      return null;
    }
    const downloadUrl = makeResourceUrl(this.state.book.files.epub);
    return (
      <CommonPopup ref={this.popupRef}>
        <IconButton icon={EgwWebFont.read} onClick={this.handleReaderClick} />
        <a href={downloadUrl}>
          <IconButton  icon={EgwWebFont.download} />
        </a>
      </CommonPopup>
    );
  }
}

EpubPopup.propTypes = {
  history: PropTypes.object,
  book: PropTypes.object
};

export default EpubPopup;
