import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useLangUpdate } from "../../hooks";
import { libraryBooksSelector } from "../../redux/selectors";
import UpdateLangPopup from "../popup/UpdateLangPopup";
import LanguageSelectorView  from "./LanguageSelectorView";
import { URLS } from "../../shared/utils/url";

const BaseLanguageSelector = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const updateLang = useLangUpdate();
  const refPopupWrap = useRef(null);
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const libraryBooks = useSelector(libraryBooksSelector);
  const [disabled, setDisabled] = useState(true);

  // Remove disabling if all library books have {bookInfo} (seems like implementation of loader).
  useEffect(() => {
    if (!libraryBooks.find((item) => item.bookInfo === undefined)) {
      setDisabled(false);
    }
  }, [libraryBooks]);

  const checkLocation = useCallback((lang) => {
    const url = window.location.href.split("/");
    if (url[url.length - 1] === lang) {
      history.replace(URLS.myLibrary);
    }
  }, [history]);

  const handleContextMenu = useCallback((e, langItem, { isDefault }) => {
    if (isDefault || !libraryLanguages.includes(langItem.id)) {
      return;
    }

    if (refPopupWrap.current) {
      refPopupWrap.current.showPopup({
        top: e.clientY,
        left: e.clientX,
      }, <UpdateLangPopup langItem={langItem} />, {
        hideOnClick: true,
      });
    }

    e.preventDefault();
  }, [libraryLanguages]);

  const handleChange = useCallback((event, item) => {
    updateLang(item.id, () => {
      checkLocation();
    });
  }, [updateLang, checkLocation]);

  const itemsDefault = useMemo(() => {
    return libraryLanguages.length ? [libraryLanguages[0]] : [];
  }, [libraryLanguages]);

  return (
    <LanguageSelectorView
      ref={refPopupWrap}
      title={t("addLanguage")}
      isDisabled={disabled}
      items={mainTree}
      itemsDefault={itemsDefault}
      itemsChecked={libraryLanguages}
      onClickItem={handleChange}
      onContextMenu={handleContextMenu}
    />
  );
};

export default BaseLanguageSelector;
