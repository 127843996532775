import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SplitPane from "react-split-pane";
import PropTypes from "prop-types";

import { makeNavigationUrl } from "../../../utils/URLUtils";
import { useSelector, useDispatch } from "react-redux";
import {  setPlayerParams } from "../../../redux/actions";
import { FullScreenIcon, CloseIcon } from "../../../assets/CommonIcons";
import TocSmall from "./TocSmall";
import { TocButton } from "../../views";
import { useAudioContext } from "../AudioContext";
import { useResizeDimensions, useViewMode } from "../../../hooks";
import { useBookSelect } from "../../../redux/selectors";
import classNames from "classnames";
import { PlayControls, ProgressControls } from "../PlayerUIKit/PlayerUIKit";
import IconButton from "../../views/IconButton";
import PlayerComponent from "../Player/PlayerComponent";
import BookTocBlock from "../../bookDetails/BookTocBlock";
import * as classnames from "classnames";
import { useNavigationContext } from "../../NavigationContext";
import { usePanels } from "../../../hooks/usePanels";
import { useAudioContextWithCurrentTime } from "../AudioContext.hooks";
import { dispatchWindowEventPlayAudioInReader } from "../../reader/ReaderUtils";
import { ContentActions } from "src/redux/content.actions";

import "./PlayerView.scss";

const PlayerSmall = ({onMinimizedViewHeightChange}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const containerRef = useRef();
  const { expandSmallPlayer } = usePanels();
  const { isReader } = useNavigationContext();
  const { zoom, isMobile, isMobileOrTablet } = useViewMode();
  const brokenAudios = useSelector((state) => state.audioPlayer.brokenAudios);
  const isTocVisible = useSelector((state) => state.audioPlayer.isTocVisible);
  const isSmallPlayerExpanded = useSelector((state) => state.audioPlayer.isSmallPlayerExpanded);
  const waveformsDictionary = useSelector((state) => state.audioPlayer.waveformsDictionary);
  const isSmallPlayerActive = useSelector((state) => state.audioPlayer.isSmallPlayerActive);
  const isShowRightPanel = useSelector((state) => state.settings.isShowRightPanel);
  const isSearchPanelCoversContent = isMobile && isShowRightPanel;
  const [isMobileProgressVisible, setIsProgressVisible] = useState(false);

  /* Inits audio context and its additional hook to get curr time, with optimized configuration. */
  const audioContext = useAudioContext();
  const { chapterId } = audioContext;
  const isPlayerExpandedAndCanBeShown = isMobileOrTablet ? isSmallPlayerExpanded : false;
  // We don't show a waveform on the mobile.
  const isWaveformShown = isMobile ? false : !!waveformsDictionary[chapterId];
  const audioContextWithCurrentTime = useAudioContextWithCurrentTime(
    audioContext, {
      enabled: isPlayerExpandedAndCanBeShown || isMobileProgressVisible,
      delayUpdateTime: isWaveformShown ? 65 : 1000,
    }
  );
  const {
    duration, isPlaying,
    timePosition, setPlayerPosition, toggleChapterPlaying,
    getPlayerPosition, seekPlay, skipPlay,
    stopPlayer, audioChapters
  } = audioContextWithCurrentTime;
  /* ======================== */

  const { PANE_WIDTH_BOOK_DETAILS  } = useResizeDimensions();
  const [hasSmallSizeUi, setHasSmallSizeUi] = useState(false);
  const [smallPlayerEl, setSmallPlayerEl] = useState(320);
  const smallPlayerWidth = smallPlayerEl?.offsetWidth;
  const smallPlayerHeight = smallPlayerEl?.offsetHeight;
  useEffect(() => {
    onMinimizedViewHeightChange(smallPlayerHeight);
  }, [smallPlayerHeight, onMinimizedViewHeightChange]);
  const book = useBookSelect(chapterId);
  const [selectedTrack, selectedChapterIndex,
    chaptersArray, fileDuration] = audioChapters;

  const trackDuration = fileDuration || duration;

  const handlePlayBtnClick = () => {
    if (isReader && !isSearchPanelCoversContent) {
      dispatchWindowEventPlayAudioInReader();
      return;
    }

    toggleChapterPlaying();
  };

  // Regulates MOBILE small player markup depend on the player width and the zoom.
  useEffect(() => {
    let newSmallSizeUiState;

    if (isMobile) {
      const SMALL_SIZE_UI_NEEDED_WIDTH = 480;

      // 393px, 130zoom - needed breakpoint.
      // (e.g. 393px device will not have small ui until 130+zoom).
      // 160zoom - max mobile zoom.
      // 2.9 = (SMALL_SIZE_UI_NEEDED_WIDTH - 393px) / (160zoom - 130zoom)
      const bonusZoomValue = Math.floor((SMALL_SIZE_UI_NEEDED_WIDTH - smallPlayerWidth) / 2.9);
      const smallSizeUiZoomBreakpoint = (160 - bonusZoomValue);

      newSmallSizeUiState = smallPlayerWidth <= SMALL_SIZE_UI_NEEDED_WIDTH
        && zoom > smallSizeUiZoomBreakpoint;
    } else {
      newSmallSizeUiState = false;
    }

    if (hasSmallSizeUi !== newSmallSizeUiState) {
      setHasSmallSizeUi(newSmallSizeUiState);
    }
  }, [isMobile, hasSmallSizeUi, smallPlayerWidth, zoom]);

  // Fetch book paragraphs if don't exist in the Redux yet to use as the label of the TOC button.
  useEffect(() => {
    if (chapterId) {
      dispatch(ContentActions.fetchParagraphs(chapterId));
    }
  }, [chapterId]);

  const handleExpand = () => {
    if (isMobileOrTablet) {
      expandSmallPlayer();
    } else {
      dispatch(setPlayerParams({ isTocVisible: false }));
      history.push(
        makeNavigationUrl({ ...selectedTrack, position: Math.ceil(getPlayerPosition()) },
          { isAudio: true })
      );
    }
  };
  const handleCollapse = () => {
    dispatch(setPlayerParams({ isSmallPlayerExpanded: false }));
  };
  const onTocToggleVisible = () => {
    dispatch(setPlayerParams({
      isTocVisible: !isTocVisible
    }));
  };
  const handleCloseIcon = () => {
    if (isMobile && isPlaying) {
      dispatch(setPlayerParams({ isSmallPlayerActive: false }));
    } else {
      stopPlayer();
    }
  };

  if (selectedTrack === undefined) {
    return null;
  }

  let content;

  if (isPlayerExpandedAndCanBeShown) {
    const clientWidth = containerRef?.current
      ? containerRef.current.clientWidth : window.innerWidth;
    const thereIsNoSpace = clientWidth <  PANE_WIDTH_BOOK_DETAILS * 2;
    const splitParams = { style: { position: "static" } };

    if (isTocVisible) {
      splitParams.primary = "second";
      splitParams.minSize = PANE_WIDTH_BOOK_DETAILS;
      splitParams.maxSize = clientWidth - PANE_WIDTH_BOOK_DETAILS;
    } else {
      splitParams.primary = "first";
      splitParams.minSize = clientWidth;
    }

    content = (
      <SplitPane
        className={classnames("small-player-expanded__split-wrapper", {
          "not-enough-split-place": thereIsNoSpace,
          "is-book-toc-visible": isTocVisible
        })}
        split="vertical"
        {...splitParams}
      >
        <PlayerComponent
          audioContextWithCurrentTime={audioContextWithCurrentTime}
          book={book}
          showBtnToc={false}
          showBtnClose={isSmallPlayerActive && isSmallPlayerExpanded}
          showBtnCollapse={isSmallPlayerExpanded}
          onClickBtnToc={onTocToggleVisible}
          onClickBtnCollapse={handleCollapse}
          onClickBtnClose={handleCloseIcon}
        />
        { (isTocVisible)
          ? (
            <div
              className="small-player-expanded__book-toc">
              <BookTocBlock
                book={book}
                onButtonHideClick={onTocToggleVisible}
              />
            </div>
          ) : <div/>
        }
      </SplitPane>
    );
  } else {
    content = (
      <>
        {isTocVisible && (
          <React.Fragment>
            <div
              className="dropDownMenuBack"
              aria-describedby={t("close")}
              onClick={() => dispatch(setPlayerParams({isTocVisible: false}))}
            />
            <div className="book-toc-dropdown">
              <TocSmall book={book} onToggleVisible={onTocToggleVisible} />
            </div>
          </React.Fragment>
        )}
        <div
          ref={setSmallPlayerEl}
          className={classnames("small-player__wrapper", {
          "has-small-size-ui": hasSmallSizeUi
        })}
        >
          {
            isMobile && (
              <button
                onClick={() => { setIsProgressVisible(!isMobileProgressVisible); }}
                className={classNames("small-player__btn-toggle-progress toggle-button", {
                  "is-active": isMobileProgressVisible
                })}>
                <span className="toggle-button__left" />
                <span className="toggle-button__right" />
              </button>
            )
          }
          {
            !hasSmallSizeUi && <TocButton
              titleFallback={selectedTrack?.refcode_short}
              isVisible={isTocVisible}
              onToggleVisible={onTocToggleVisible}
            />
          }
          <PlayControls
            showSkipButtons={!hasSmallSizeUi}
            className="small-player__play-controls"
            isAudioPlaying={isPlaying}
            isSeekBackDisabled={selectedChapterIndex === 0}
            isSeekForwardDisabled={selectedChapterIndex + 1 === chaptersArray.length}
            isTogglePlayDisabled={selectedTrack && brokenAudios.includes(selectedTrack.id)}
            onSeekPrevClick={() => { seekPlay(false); }}
            onSkipPrevClick={() => {
              skipPlay(-10);
            }}
            onTogglePlayClick={handlePlayBtnClick}
            onSkipNextClick={() => {
              skipPlay(10);
            }}
            onSeekNextClick={() => { seekPlay(true); }}
          />
          <div className="small-player__panel-actions">
            <IconButton
              showIcon
              className="small-player__btn-full-screen"
              icon={FullScreenIcon}
              onClick={handleExpand}
              title={t("fullScreen")}
            />
            <IconButton
              showIcon
              className="small-player__btn-close"
              icon={CloseIcon}
              onClick={handleCloseIcon}
              title={t("close")}
            />
          </div>
          {
            isMobileProgressVisible && (
              <ProgressControls
                className="small-player__progress-controls"
                uiChangesInTextMode={false}
                timePosition={timePosition}
                trackDuration={trackDuration}
                onChange={setPlayerPosition}
                onChangeCommitted={(value) => {
                  skipPlay(value - getPlayerPosition());
                }}
              />
            )
          }
        </div>
      </>
    );
  }

  return (
    <div className={classNames("small-player", {
      "toc-open": isTocVisible,
      "has-layout-with-progress-bar": isMobileProgressVisible && !isPlayerExpandedAndCanBeShown,
      "has-layout-expanded": isPlayerExpandedAndCanBeShown
    })} ref={containerRef}>{content}</div>
  );
};

PlayerSmall.defaultProps = {
  onMinimizedViewHeightChange: PropTypes.func,
};

export default PlayerSmall;
