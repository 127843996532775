import {
  convertFoldersToTree,
  convertBooks,
  convertBook,
  makeChapterContent,
  makeChapterTree,
  convertAbbreviations,
} from "../utils/ContentUtils";
import { arraymove, getCurrentLang, HistoryType } from "../utils/Utils";
import i18n from "../i18n/i18n";
import {
  CACHE_LANG,
  CACHE_SUBSCRIPTION_BOOKS,
  makeDumpCacheName,
  makeFolderCacheName,
  makeFolderCustomCacheName,
} from "./CacheHolder";
import { getCPanelUrl } from "../shared/utils/url";

import { getBookOrigin, getBookId } from "../shared/utils/content";
import { getTokens } from "src/shared/utils/systemUtils";
import { makeAggregateUrl, makeHistoryUrl, normilizeLangs } from "./api.utils";
import { getUserRole } from "src/components/studyCenter/EditorCenterUtils";
import { makeRequest } from "src/shared/api/baseApi";

const CONTENT_MEMORY_CACHE_TIME = 1000 * 60 * 60;

export const fetchBaseDataRequest = (lang) =>
  makeRequest({
    url: "/aggregate/anonymous/" + lang,
    storageCacheName: makeDumpCacheName(lang),
    parseResponse: (response) => {
      const outData = {};
      const allData = response.data;

      if (allData["dictionary-books"]) {
        let dictionaries = allData["dictionary-books"].results;
        if (dictionaries) {
          const defaultOption = { key: "all", label: "All Dictionaries" };
          dictionaries = dictionaries.map((item) => ({
            key: item.book_id,
            label: `${item.title} (${item.code})`,
          }));
          dictionaries.unshift(defaultOption);
          outData.dictionaries = dictionaries;
        }
      }

      if (allData["bible-books"]?.results) {
        outData.bibles = convertBooks(allData["bible-books"].results);
      }

      if (allData["subscription-books"]) {
        outData.subscriptions = allData["subscription-books"].map((item) =>
          getBookId(item.book_id),
        );
      }

      if (allData["search-periods"]) {
        let presets = allData["search-periods"];
        if (allData["search-apl"]) {
          presets.apl = {
            books: [
              ...(allData["search-apl"].books?.books || []),
              ...(allData["search-apl"].periodicals?.books || []),
              ...(allData["search-apl"].both?.books || []),
            ],
          };
        }
        outData.presets = presets;
      }

      if (allData["languages"]) {
        outData.langs = normilizeLangs(allData["languages"], lang);
      }

      if (allData["folders"]) {
        let folders = convertFoldersToTree(allData["folders"], lang).outList;
        outData.folders = folders;
      }
      if (allData.geoip) {
        outData.geoIpData = allData.geoip;
      }

      return outData;
    },
  });

export const fetchBaseUserDataRequest = () =>
  makeRequest({
    url: "/aggregate/authenticated",
    parseResponse: (response) => {
      const outData = {};
      const allData = response.data;

      if (allData["subscription-time"]) {
        outData.timeData = allData["subscription-time"];
      }
      if (allData["settings-library"]?.languages) {
        outData.languages = allData["settings-library"].languages;
      }
      if (allData["settings-custom"]) {
        outData.settings = allData["settings-custom"];
      }
      if (allData["subscription-unread"]?.unread) {
        outData.subsUnreadCount = allData["subscription-unread"].unread;
      }
      if (allData["history-search"]) {
        outData.searchHistory = allData["history-search"].items;
      }
      if (allData["history-read"]) {
        outData.reading = normalizeHistoryData(allData["history-read"].items);
      }
      if (allData["history-audio"]) {
        outData.listen = normalizeHistoryData(allData["history-audio"].items);
      }
      if (allData["history-download"]) {
        outData.library = normalizeHistoryData(allData["history-download"].items);
      }
      if (allData["history-library"]) {
        outData.ml = normalizeHistoryData(allData["history-library"].items);
      }

      return outData;
    },
  });

/**
 *
 * @param {Object} oUrls  object with keyname and url e.g { book1: "/books/11", book2: "/books/2" }
 * @returns {Object} with multiple data from urls
 */
export const getAgregateRequest = (oUrls) => {
  return makeRequest({
    url: makeAggregateUrl(oUrls),
    parseResponse: (response) => response.data,
  });
};

export const getBiblesRequest = () =>
  makeRequest({
    url: "/content/books?type=bible",
    storageCacheName: makeFolderCacheName("bibles"),
    parseResponse: (response) => convertBooks(response.data.results),
  });

export const getSubscriptionBooksRequest = () =>
  makeRequest({
    url: "/subscriptions/books/short",
    storageCacheName: CACHE_SUBSCRIPTION_BOOKS,
    parseResponse: (response) => response.data.map((item) => getBookId(item.book_id)),
  });

export const getLanguagesRequest = () => {
  return makeRequest({
    url: "/content/languages/",
    type: "get",
    storageCacheName: CACHE_LANG,
    parseResponse: (response) => normilizeLangs(response.data),
  }).then((languages) => {
    // TODO add this as parameter for request
    const lang = getCurrentLang(i18n);
    let index = languages.findIndex((item) => item.id === lang);
    if (index > -1) {
      arraymove(languages, index, 0);
    }
    return languages;
  });
};

export const getFoldersInLangRequest = (lang) =>
  makeRequest({
    url: `/content/languages/${lang}/folders`,
    type: "get",
    storageCacheName: makeFolderCustomCacheName(lang),
    parseResponse: (response) => response.data,
  });

export const getFoldersRequest = (lang) =>
  makeRequest({
    url: `/content/languages/${lang}/folders`,
    storageCacheName: makeFolderCacheName(lang),
    parseResponse: (response) => convertFoldersToTree(response.data, lang).outList,
  });

export const getBooksByFolderRequest = (id) =>
  makeRequest({
    url: `/content/books/by_folder/${id}`,
    storageCacheName: makeFolderCacheName(id),
    parseResponse: (response) => convertBooks(response.data),
  });

export const getBookByIdRequest = async (id) => {
  const realId = getBookOrigin(id);
  const bookData = await makeRequest({
    url: `content/books/${realId}`,
    memoryCache: true,
    memoryCacheTime: CONTENT_MEMORY_CACHE_TIME,
    parseResponse: (response) => convertBook(response.data),
  });

  if (bookData?.id !== id) {
    return undefined;
  }

  if (bookData) {
    const chapterData = await makeRequest({
      url: `content/books/${realId}/toc`,
      memoryCache: true,
      memoryCacheTime: CONTENT_MEMORY_CACHE_TIME,
      parseResponse: (response) => makeChapterTree(bookData, response.data),
    });
    if (chapterData) {
      bookData.children = chapterData.children;
      bookData.chapters = chapterData.chapters;
    }
  }
  return bookData;
};

export const getBookTocById = (book) => {
  return makeRequest({
    url: `content/books/${book.book_id}/toc`,
    memoryCache: true,
    memoryCacheTime: CONTENT_MEMORY_CACHE_TIME,
    parseResponse: (response) => makeChapterTree(book, response.data),
  });
};

export const getChapterContentRequest = (paraId, bookRealType, bookType) => {
  const [bookId, chapterId] = paraId.split(".");
  return makeRequest({
    url: `/content/books/${bookId}/chapter/${chapterId}?trans=all`,
    memoryCache: true,
    memoryCacheTime: CONTENT_MEMORY_CACHE_TIME,
    parseResponse: (response) => makeChapterContent(response.data, bookRealType, bookType),
  });
};

export const LoadDirection = {
  up: "up",
  down: "down",
  both: "both",
};

export const getParagraphsRequest = (
  paraId,
  limit,
  loadDirection = LoadDirection.both,
  highlight,
) => {
  const [bookId, chapterId] = paraId.split(".");
  let queries = ["trans=all"];

  if (limit) {
    queries.push("limit=" + limit);
  }
  if (loadDirection) {
    queries.push("direction=" + loadDirection);
  }
  if (highlight) {
    queries.push("highlight=" + highlight);
  }
  return makeRequest({
    url: `content/books/${bookId}/content/${chapterId}?` + queries.join("&"),
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

const normalizeHistoryData = (list) => {
  return list.map((item) => ({
    ...item,
    id: getBookId(item.book),
  }));
};

export const getHistoryRequest = (historyType, withDelete, start, end) => {
  return makeRequest({
    url: makeHistoryUrl(historyType, withDelete, start, end),
    type: "get",
    parseResponse: (response) => normalizeHistoryData(response.data.items),
  });
};

export const getAllHistoryRequest = async () => {
  const requests = {
    reading: makeHistoryUrl(HistoryType.READING, true),
    listen: makeHistoryUrl(HistoryType.LISTEN, true),
    ml: makeHistoryUrl(HistoryType.ML_HISTORY, true),
    library: makeHistoryUrl(HistoryType.LIBRARY, false),
  };
  const results = await getAgregateRequest(requests);
  let reading = [],
    listen = [],
    ml = [],
    library = [];
  if (results.reading?.data?.items) {
    reading = normalizeHistoryData(results.reading?.data?.items);
  }
  if (results.listen?.data?.items) {
    listen = normalizeHistoryData(results.listen?.data?.items);
  }
  if (results.ml?.data?.items) {
    ml = normalizeHistoryData(results.ml?.data?.items);
  }
  if (results.library?.data?.items) {
    library = normalizeHistoryData(results.library?.data?.items);
  }
  return { reading, listen, ml, library };
};

/**
 *
 * @param {*} historyType
 * @param {Object} params { items: [],  date: Date, deleted: [] }
 * @returns
 */
export const putHistoryRequest = (historyType, params) => {
  return makeRequest({
    url: `/settings/${historyType}/sync`,
    type: "put",
    headers: {
      "Content-Type": "application/json-patch+json",
      accept: "text/plain",
    },
    data: params,
    parseResponse: (response) => response.data.date,
  });
};

export const deleteBookToLibrary = (bookIds) => {
  return makeRequest({
    url: `/settings/${HistoryType.LIBRARY}/sync`,
    type: "delete",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "text/plain",
    },
    data: {
      books: bookIds,
    },
    parseResponse: (response) => response.data,
  });
};

export const deleteSearchHistory = () => {
  return makeRequest({
    url: "/settings/search_history/",
    type: "delete",
    parseResponse: (response) => response.data.date,
  });
};

export const getBooksRequest = async (ids) => {
  const args = ids.map((item) => "pubnr=" + item).join("&");
  return makeRequest({
    url: `content/books?${args}`,
    type: "get",
    memoryCache: true,
    memoryCacheTime: CONTENT_MEMORY_CACHE_TIME,
    parseResponse: (response) => convertBooks(response.data.results),
  });
};

/**
 * FIXME workaround for loading big amount of books.
 * need to rafactoring and implement pagination if have more time
 * @param {Array} bookIds
 */
export const getBooksInfoRequest = async (bookIds) => {
  let allList = [];
  let ids = [...bookIds];
  while (ids.length !== 0) {
    const partIds = ids.splice(0, 100);
    const books = await getBooksRequest(partIds);
    if (books) {
      allList.push(...books);
    }
  }
  return allList;
};

export const getUserInfo = async () => {
  const tokens = getTokens();
  if (tokens.refreshToken) {
    const userInfo = await makeRequest({
      // url: "user/info/",
      // impl more stable API call when cors will be resolved
      url: getCPanelUrl() + "/connect/legacy-userinfo",
      type: "get",
      parseResponse: (response) => response.data,
    });
    if (!userInfo) {
      return undefined;
    }
    // simple way for analitics
    window.userId = userInfo.id;
    userInfo.role = getUserRole();
    return userInfo;
  }
  return undefined;
};

export const fetchLibLangsRequest = () => {
  return makeRequest({
    url: "settings/library/settings",
    type: "get",
    parseResponse: (response) => {
      return response.data.languages;
    },
  });
};

export const setLibLangsRequest = (languages) => {
  const request = {
    headers: {
      "Content-Type": "application/json",
    },
    url: "settings/library/settings",
    type: "put",
    data: {
      languages,
      lu: new Date().toISOString(),
    },
    parseResponse: (response) => {
      return response.status;
    },
  };
  return makeRequest(request);
};

export const getWaveformRequest = (url, bookId) => {
  return fetch(url)
    .then((response) => response.json())
    .then(({ data, chapters }) => {
      return {
        waveform: data,
        paragraphsAudioPositions: chapters.map((chapter) => {
          chapter.id = `${bookId}.${chapter.paraId}`;
          delete chapter.paraId;
          return chapter;
        }),
      };
    });
};

export const getGeoIpData = () => {
  return makeRequest({
    url: "geoip",
    type: "get",
    parseResponse: (response) => {
      return response.data;
    },
  });
};

export const deleteMlItem = (id) => {
  return makeRequest({
    url: "settings/library",
    type: "delete",
    data: { id },
    parseResponse: (response) => {
      return response.status;
    },
  });
};

export const addBooksToLibraryRequest = async (books) => {
  const addToLibrary = await makeRequest({
    url: `/settings/${HistoryType.LIBRARY}`,
    type: "post",
    data: { books },
    parseResponse: (response) => response.status,
  });

  return addToLibrary;
};

export const deleteBooksFromLibraryRequest = async (books) => {
  const result = await makeRequest({
    url: `settings/${HistoryType.LIBRARY}`,
    type: "delete",
    data: { books },
    parseResponse: (response) => response.status,
  });
  await deleteMlItem(books);
  return result;
};

export const getBookAudioHistory = (bookId) => {
  return makeRequest({
    url: `settings/audio/${bookId}`,
    parseResponse: (response) => {
      return response.data;
    },
  });
};

export const getBookReadHistory = (bookId) => {
  return makeRequest({
    url: `settings/read/${bookId}`,
    parseResponse: (response) => {
      return response.data;
    },
  });
};

export const sendCFTypo = (data) => {
  return makeRequest({
    url: "cf/typo",
    type: "post",
    data,
    headers: {
      "Content-type": "multipart/form-data",
    },
    parseResponse: (response) => response,
    parseError: (error) => error.data,
  });
};

export const getContactUsRequest = () =>
  makeRequest({
    url: "cf",
    parseResponse: (response) => response,
  });

export const sendContact = (data) =>
  makeRequest({
    url: "cf",
    type: "post",
    data,
    "Content-type": "multipart/form-data",
    parseResponse: (response) => response,
  });

/**
 * @deprecated use {@link getContentPreviewRequest}
 * @param {String} bibleId
 * @param {*} targetBibles
 * @param {*} targetParagraphs
 * @returns
 */
export const getBibleContentsRequest = (bibleId, targetBibles, targetParagraphs) => {
  let url = `content/preview/bible/${getBookOrigin(bibleId)}?`;

  const pubnrs = targetBibles.map((item) => "pubnr=" + getBookOrigin(item));
  if (pubnrs.length) {
    url += pubnrs.join("&");
  }

  const paras = targetParagraphs.map((item) => {
    const para = item.indexOf(".") !== -1 ? item.split(".")[1] : item;
    return "para=" + para;
  });
  if (paras.length) {
    url += "&" + paras.join("&");
  }

  return makeRequest({
    url,
    memoryCache: true,
    parseResponse: (response) => {
      if (response.data.paragraphs) {
        const outContent = response.data.paragraphs.map((item) => {
          return {
            ...item,
            className: "bible",
            bookId: getBookId(item.para_id),
            paraId: item.para_id,
          };
        });
        return outContent;
      }
      return [];
    },
  });
};

export const getContentPreviewRequest = (paraId, langs, className) => {
  let url = `content/preview/${paraId}`;

  if (langs) {
    const langParams = langs.map((item) => "lang=" + item);
    url += "?" + langParams.join("&");
  }

  return makeRequest({
    url,
    memoryCache: true,
    parseResponse: (response) => {
      let outData = [];
      // if (response.data.original) {
      //   outData.push(response.data.original);
      // }
      if (response.data.translations) {
        outData.push(...response.data.translations);
      }
      return outData.map((item) => {
        return {
          ...item,
          className: className || "egwwritings",
          bookId: getBookId(item.para_id),
          paraId: item.para_id,
        };
      });
    },
  });
};

export const getSettingsRequest = () => {
  return makeRequest({
    url: "settings/custom",
    parseResponse: (response) => response.data,
  });
};

export const saveSettingsRequest = (data) => {
  return makeRequest({
    url: "settings/custom",
    type: "PATCH",
    data,
    parseResponse: (response) => response,
  });
};

export const getAbbreviationsRequest = () => {
  return makeRequest({
    url: "/content/books/abbreviations",
    parseResponse: (response) => convertAbbreviations(response.data),
  });
};
