import React, { Component } from "react";
import Loading from "./Loading";
import { Button } from "./views";

const asyncComponent = (importComponent, fallback) => {
  return class extends Component {
    state = {
      component: null,
      error: false,
    };

    componentDidMount() {
      this.loadComponent();
    }

    loadComponent() {
      importComponent()
        .then((component) => this.setState({ error: false, component: component.default }))
        .catch((err) => {
          this.setState({
            error: true,
          });
          console.error(err.message || err);
        });
    }

    render() {
      if (this.state.error) {
        return (
          <Button onClick={() => this.loadComponent()}>
            Refresh
          </Button>
        );
      }
      const Comp = this.state.component;
      return Comp ? (
        <Comp {...this.props} />
      ) : (
        fallback || <Loading sizeSmall align="flex-center" />
      );
    }
  };
};

export default asyncComponent;
