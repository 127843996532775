import React, { useRef, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { ListView } from "../views";
import { useNavigationContext } from "../NavigationContext";
import { getLinkValue } from "../../utils/URLUtils";
import Loading from "../Loading";
import componentsId from "../constants";
import { RESTRICTION_SEARCH_VALUE, searchGroups } from "../../shared/utils/search";
import { isEventKeys } from "../../utils/Utils";
import { isEventKey, isFocusTextInput, KeyCodes } from "../../shared/utils/dom";
import { getBookId, renderYearAndName } from "../../shared/utils/content";
import { useTranslation } from "react-i18next";
import { Period, periodOptions } from "src/utils/AdvSearchTypes";
import { SearchListType } from "./search.utils";
import { useHistory } from "react-router";
import { URLS } from "../../shared/utils/url";
import { isNumberBetween } from "../../shared/utils/number";

const WITH_REDUCE_HEIGHT = false;

const calcBaseLines = (baseLines, width) => {
  if (!WITH_REDUCE_HEIGHT) {
    return baseLines;
  }
  let lineCount = baseLines;
  if (width && width > 500) {
    const ratio = 500 / width;
    lineCount = Math.round(baseLines * ratio);
  }

  return lineCount;
};

const getRowHeight = (fontSize, listType, snippet, width) => {
  const TEXT_CONTENT_LINE_HEIGHT = fontSize * 1.7;

  if (listType === SearchListType.OMSK_SEARCH || listType === SearchListType.SUGGESTION_SEARCH) {
    return 1.5 * TEXT_CONTENT_LINE_HEIGHT;
  }
  if (listType === SearchListType.PHRASE_SEARCH) {
    return 4 * TEXT_CONTENT_LINE_HEIGHT;
  }

  //TODO can be implement dynamic row height for virtual list
  let height = 2 * fontSize;
  const textFontSize = 1.4 * fontSize;
  switch (snippet) {
    case "ref":
      // not needed to make bigger rowHeight for one line
      // height = 2 * TEXT_CONTENT_LINE_HEIGHT;
      break;
    case "short":
    default:
      height += textFontSize * calcBaseLines(2, width);
      break;
    case "long":
      height += textFontSize * calcBaseLines(5, width);
      break;
    case "full":
      height += textFontSize * calcBaseLines(6, width);
      break;
  }

  return Math.round(height);
};

const SearchList = ({
  width,
  height,
  searchList,
  total,
  start,
  style,
  snippet,
  listType,
  listClassName,
  isLoading,
  onItemClick,
  onChangePosition,
  onChangePeriod,
  onCorrectionClick,
  shortcutList,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { panelIds } = useNavigationContext();
  const searchFontSize = useSelector((state) => state.settings.searchFontSize);
  const zoom = useSelector((state) => state.settings.zoom);
  const searchParams = useSelector((state) => state.search.searchParams);
  const scroller = useRef();
  const { corrections } = useSelector((state) => state.search.searchFilter);

  const currentFilter = searchParams?.extras?.period
    ? periodOptions.find((item) => item.key === searchParams.extras.period)
    : Period.all;

  const fontSize = searchFontSize * (zoom / 100);

  const rowHeight = getRowHeight(fontSize, listType, snippet , width);

  const panelsArray = useMemo(() => {
    return panelIds.map((elem) => {
      const { value } = getLinkValue(elem);
      return value;
    });
  }, [panelIds]);

  const isLoadingExternal = useMemo(() => {
    if (searchList.length === 0 && total > 0) {
      return true;
    }
    if (searchList.length > 0) {
      const [firstItem] = searchList;
      const needRefCodeList = [SearchListType.PHRASE_SEARCH, SearchListType.DEFAULT];
      const needOnlyResultList = [SearchListType.OMSK_SEARCH, SearchListType.SUGGESTION_SEARCH];
      if (!firstItem.result && needOnlyResultList.includes(listType)) {
        return true;
      }
      if (!firstItem.refcode_short && needRefCodeList.includes(listType)) {
        return true;
      }
    }

    return false;
  }, [listType, searchList, total]);

  useEffect(() => {
    const keyHandler = (event) => {
      const list = document.querySelector(".search-controlled-list");
      if (isFocusTextInput()) {
        return;
      }
      // index from 0
      const currentIndex = start - 1;

      if (!event.altKey && !(event.ctrlKey || event.metaKey)) {
        if (isEventKeys(event, [KeyCodes.minus, KeyCodes.numMinus])) {
          const prevIndex = currentIndex - 1;
          if (prevIndex !== -1) {
            scroller.current.scrollTop(prevIndex * rowHeight);
          }
        }

        if (isEventKeys(event, [KeyCodes.plus, KeyCodes.numPlus])) {
          const nextIndex = currentIndex + 1;
          if (nextIndex <= start) {
            scroller.current.scrollTop(nextIndex * rowHeight);
          }
        }
      }

      if (list && list.getAttribute("data-control")) {
        if (isEventKey(event, KeyCodes.upArrow)) {
          const prevIndex = currentIndex - 1;
          if (prevIndex !== -1) {
            scroller.current.scrollTop(prevIndex * rowHeight);
          }
        }
        if (isEventKey(event, KeyCodes.downArrow)) {
          const nextIndex = currentIndex + 1;
          if (nextIndex <= start) {
            scroller.current.scrollTop(nextIndex * rowHeight);
          }
        }

        if (isEventKey(event, KeyCodes.enter)) {
          const currentElement = searchList.find((item) => item.index === currentIndex);
          if (currentElement) {
            handleItemClick(currentElement);
          }
        }
      }
    };

    window.addEventListener("keydown", keyHandler);

    return () => {
      window.removeEventListener("keydown", keyHandler);
    };
  }, [start, rowHeight, searchList]);

  const getFirstVisibleRowNumber = useCallback(() => {
    if (scroller.current) {
      const offset = scroller.current.getScrollTop();
      return Math.round(offset / rowHeight) + 1;
    }

    return undefined;
  }, [rowHeight]);

  useEffect(() => {
    if (start === getFirstVisibleRowNumber()) {
      return;
    }

    if (scroller.current) {
      const startIndex = start - 1;
      const listHeight = scroller.current.getClientHeight();
      const offset = scroller.current.getScrollTop();
      const offsetNew = startIndex * rowHeight;
      if (!isNumberBetween(offsetNew, offset, offset + listHeight - rowHeight)) {
        scroller.current.scrollTop(offsetNew);
      }
    }
  }, [start, rowHeight, getFirstVisibleRowNumber]);

  const handleItemClick = (item) => () => {
    // isForSale = isAvailable for search items only
    if (item.isForSale) {
      const bookId = getBookId(item.book_id || item.id || item.para_id);
      const bookDetailsUrl = URLS.book + "/" + bookId;
      if (location.pathname !== bookDetailsUrl) {
        history.push(bookDetailsUrl);
      }
      return;
    }
    if (onItemClick) {
      onItemClick(item);
    }
  };

  const handlePeriodClick = () => onChangePeriod(Period.all);

  const renderItem = (params) => {
    const { key, index, style } = params;
    const item = searchList.find((item) => item.index === index);
    const currentItem = index + 1;

    if (!item) {
      return (
        <button
          key={key}
          style={style}
          data-index={index}
          className={classNames("search-item-wrap", "placeholder", {
            "in-reader": inReader !== -1,
            "is-focused": start === currentItem,
          })}>
          <div className={classNames("search-item-title")}>
            <span className="search-item-title-index">{currentItem}</span>
            <span className="search-item-title-text">&nbsp;&nbsp;&nbsp;{t("loading")}</span>
          </div>
          <div className={classNames("search-item-snippet")}>{t("pleaseWait")}</div>
        </button>
      );
    }

    const inReader = panelsArray.indexOf(item.para_id);
    if (listType === SearchListType.BOOK_SEARCH) {
      return (
        <button
          key={key}
          style={style}
          data-index={item.index}
          className={classNames("search-item-wrap", "bookTitle", {
            "in-reader": inReader !== -1,
            "is-focused": start === currentItem,
          })}
          onClick={handleItemClick(item)}>
          <div
            className={"search-item-title bookTitle"}
            dangerouslySetInnerHTML={{ __html: currentItem + " " + item.title }}
          />
          <div
            className={" search-item-snippet"}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </button>
      );
    } else if (
      listType === SearchListType.OMSK_SEARCH ||
      listType === SearchListType.SUGGESTION_SEARCH
    ) {
      return (
        <button
          key={key}
          style={style}
          data-index={item.index}
          className={classNames("search-external-item", {
            "in-reader": inReader !== -1,
            "is-focused": start === currentItem,
          })}
          onClick={handleItemClick(item)}>
          <div className={classNames("search-external-item-inner", "egwwritings")}>
            <div className={classNames("search-item-title")}>
              <span className="search-item-title-index">{currentItem}</span>
              <span className="search-item-title-text">{item.result}</span>
            </div>
          </div>
        </button>
      );
    } else if (listType === SearchListType.PHRASE_SEARCH) {
      return (
        <button
          key={key}
          style={style}
          data-index={item.index}
          className={classNames("search-item-wrap", "egwwritings", {
            "in-reader": inReader !== -1,
            "is-focused": start === currentItem,
          })}
          onClick={handleItemClick(item)}>
          <div className={classNames("search-item-title", "egwwritings")}>
            <span className="search-item-title-index">{currentItem}</span>
            <span className="search-item-title-text">&nbsp;{item.refcode_short}</span>
          </div>
          <div
            className={classNames("search-item-snippet", "egwwritings")}
            dangerouslySetInnerHTML={{ __html: item.result }}
          />
        </button>
      );
    }

    if (item.id === componentsId.LOADER_ID) {
      return (
        <div key={key} style={style} data-index={item.index}>
          <div className="search-item-wrap">
            <span className="search-item-title">
              <Loading sizeSmall align="flex-center" />
            </span>
          </div>
        </div>
      );
    }
    const yearValue = renderYearAndName(item);
    const yearLabel = yearValue ? "  (" + yearValue + ")" : "";
    return (
      <button
        key={key}
        style={style}
        data-index={item.index}
        className={classNames("search-item-wrap", item.group, {
          "in-reader": inReader !== -1,
          "is-focused": start === currentItem,
        })}
        onClick={handleItemClick(item)}>
        <div className={classNames("search-item-title", item.group)}>
          <span className="search-item-title-index">{currentItem}</span>
          <span className="search-item-title-text">
            &nbsp;&nbsp;&nbsp;{searchGroups[item.group] || item.group}
            &nbsp;{item.refcode_short || ""}
            {yearLabel}
          </span>
        </div>
        {item.isForSale ? (
          <div className="search-item-badge-wrap">
            <div className="search-item-badge">{t("buyBook")}</div>
          </div>
        ) : (
          <div
            className={classNames("search-item-snippet", item.group)}
            dangerouslySetInnerHTML={{ __html: item.snippet }}
          />
        )}
      </button>
    );
  };

  const renderSuggestion = () => {
    if (!onCorrectionClick) {
      return null;
    }
    if (total === 0 && corrections.length > 0) {
      return (
        <div className="search-list-correction-wrap">
          {t("didyoumean")}:&nbsp;
          {corrections.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <span
                  onClick={() => {
                    onCorrectionClick(item);
                  }}
                  className="search-list-correction-value">
                  {item.title}
                </span>
                {index !== corrections.length - 1 && (
                  <span className="correction-delimiter">{t("or")}</span>
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
    if (shortcutList?.length > 0 && shortcutList[0]?.hits === 0) {
      return (
        <div className="search-list-suggestion-wrap">
          <div className="search-list-suggestion-item">
            {t("no_exact_match")}:&nbsp;
            <span className="search-list-suggestion-value">{shortcutList[0].title}</span>
          </div>
          <div className="search-list-suggestion-item">
            {t("show_result_for_text_match")}:&nbsp;
            <span className="search-list-suggestion-value">{shortcutList[0].query}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  if (isLoading || isLoadingExternal) {
    return <Loading align="flex-center" />;
  }

  return (
    <div
      className={classNames("search-list", listClassName)}
      style={{ ...style, fontSize: fontSize }}>
      {total === 0 && currentFilter.key !== Period.all && (
        <div className="empty-search-recommended-filter">
          <span
            dangerouslySetInnerHTML={{
              __html: t("emptySearchRecommendedFilterTo", {
                currentFilter: `<span class="current-filter"> ${t(currentFilter.label)} </span>`,
              }),
            }}
          />{" "}
          <span className="to-filter" onClick={handlePeriodClick}>
            {t("allCollection")}
          </span>
        </div>
      )}

      {renderSuggestion()}

      {searchList.length === 0 && total <= 0 && (
        <div className="search-list-empty-placeholder">{t("searchListPlaceHolder")}</div>
      )}

      <ListView
        width={width - 2 || 500} // -2 is for avoid horizontal scroll of borders
        height={height || 500}
        ref={scroller}
        stopDelay={500}
        listClassName="search-controlled-list"
        onScrollStop={(delta) => {
          if (delta !== 0) {
            onChangePosition(getFirstVisibleRowNumber());
          }
        }}
        rowCount={Math.min(total === -1 ? 0 : total, RESTRICTION_SEARCH_VALUE)}
        rowHeight={rowHeight}
        rowRenderer={renderItem}
      />
    </div>
  );
};

SearchList.propTypes = {
  searchList: PropTypes.array,
  shortcutList: PropTypes.array,
  total: PropTypes.number,
  start: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  snippet: PropTypes.string,
  isLoading: PropTypes.bool,
  listType: PropTypes.string,
  listClassName: PropTypes.string,
  onChangeCurrentRow: PropTypes.func,
  onItemClick: PropTypes.func,
  onChangePosition: PropTypes.func,
  onChangePeriod: PropTypes.func,
  onCorrectionClick: PropTypes.func,
};

export default SearchList;
