/* eslint-disable no-console */
import { useDispatch, useSelector } from "react-redux";

import { MENU_STATE } from "./MenuItems";
import { localStorageSafe } from "../shared/utils/systemUtils";
import { VIEW_MODE } from "./Utils";
import { SEARH_PANEL_TAB_ITEMS } from "../components/rightPanel/constants";
import { CACHE_SEARCH_HISTORY } from "../api/CacheHolder";
import { actionUpdateSetting } from "../redux/actions";
import {
  activeTheme,
  activeZoom,
  ScreenType,
  SearchFontSizes,
  THEME_MODE,
  TreeFontSizes,
  ZoomValues
} from "./ThemeUtils";
import {
  DEF_READER_FONT,
  DEF_READER_TEXT_ALIGN,
  READER_PLAYER_BEHAVIOURS
} from "../components/reader/constants";

import { TOGGLE_IN_LIBRARY_BEHAVIOURS } from "../components/myLibrary/constants";
import { ReaderFontSizes } from "../shared/utils/theme";
import { TreeMenuMode } from "./TreeUtils";
import { SAVE_WORKSPACE_BEHAVIOURS } from "src/components/reader/workspaces/WorkspaceUtils";
import { Order, PeriodDefault, QUERY_TYPE, SNIPPET } from "../shared/utils/search";
import { getSystemLang } from "../shared/utils/user-agent";
import { DEFAULT_LANGUAGE } from "../shared/utils/i18n";

export const SettingsLocations = {
  MAIN_APP: "MAIN_APP",
  NEXT_APP: "NEXT_APP"
};

export const SettingsType = { 
  string: "string",
  bool: "bool",
  number: "number",
  array: "array",
  object: "object",
};

//impl complex logic for all settings
export const Settings = {
  isDevMode: { // for show some features on demo servers after enable
    id: "isDevMode",
    default: false,
    type: SettingsType.bool,
    locations: [
      SettingsLocations.MAIN_APP,
      SettingsLocations.NEXT_APP
    ]
  },
  themeMode: {
    id: "themeMode",
    server: true,
    type: SettingsType.string,
    default: THEME_MODE.dy.id,
    action: (value) => activeTheme(value),
    saveOnLogout: true,
    locations: [
      SettingsLocations.MAIN_APP,
      SettingsLocations.NEXT_APP
    ]
  },
  textMode: {
    id: "textMode",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  languageUI: {
    id: "languageUI",
    server: true,
    type: SettingsType.string,
    default: getSystemLang(DEFAULT_LANGUAGE),
    locations: [
      SettingsLocations.MAIN_APP,
      SettingsLocations.NEXT_APP
    ]
  },
  isGrid: {
    id: "isGrid",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
      SettingsLocations.NEXT_APP
    ]
  },
  isShowAZ: {
    id: "isShowAZ",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  isShowRightPanel: {
    id: "isShowRightPanel",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  zoom: {
    id: "zoom",
    type: SettingsType.number,
    default: ZoomValues.desktop[ScreenType.DESKTOP],
    action: (value) => activeZoom(value),
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  treeFontSize: {
    id: "treeFontSize",
    type: SettingsType.number,
    default: TreeFontSizes.default,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  searchFontSize: {
    id: "searchFontSize",
    type: SettingsType.number,
    default: SearchFontSizes.default,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  studyCenter: {
    id: "studyCenter",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  lastActiveColor: {
    id: "lastActiveColor",
    server: true,
    type: SettingsType.string,
    default: undefined,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  editorTempEntry: {
    id: "editorTempEntry",
    server: true,
    type: SettingsType.object,
    default: {},
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showTranslations: {
    id: "showTranslations",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showRefCodes: {
    id: "showRefCodes",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showHeaders: {
    id: "showHeaders",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showPageNumbers: {
    id: "showPageNumbers",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showPageBreaks: {
    id: "showPageBreaks",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  syncReader: {
    id: "syncReader",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  syncReaderAndSearch: {
    id: "syncReaderAndSearch",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  syncPlayerAndReader: {
    id: "syncPlayerAndReader",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showShortcutsForShortcut: {
    id: "showShortcutsForShortcut",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  readerFontFamily: {
    id: "readerFontFamily",
    type: SettingsType.string,
    default: DEF_READER_FONT,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  readerFontSize: {
    id: "readerFontSize",
    type: SettingsType.number,
    default: ReaderFontSizes.default,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  textAlign: {
    id: "textAlign",
    type: SettingsType.number,
    default: DEF_READER_TEXT_ALIGN,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  readerMinWidth: {
    id: "readerMinWidth",
    type: SettingsType.number,
    default: 300,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  layout: {
    id: "layout",
    type: SettingsType.string,
    default: "",
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  isShowFooter: {
    id: "isShowFooter",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  isShowFooterContent: {
    id: "isShowFooterContent",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  scrollToFooter: {
    id: "scrollToFooter",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  viewMode: {
    id: "viewMode",
    type: SettingsType.string,
    default: VIEW_MODE.DESKTOP,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  rightTab: {
    id: "rightTab",
    type: SettingsType.string,
    default: SEARH_PANEL_TAB_ITEMS[0].id,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showRightPanel: {
    id: "showRightPanel",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  isRightPanelPinned: {
    id: "isRightPanelPinned",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showLeftPanel: {
    id: "showLeftPanel",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  isLeftPanelPinned: {
    id: "isLeftPanelPinned",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  openLastBook: {
    id: "openLastBook",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  libraryLanguages: {
    id: "libraryLanguages",
    type: SettingsType.array,
    default: [],
    locations: [
      SettingsLocations.MAIN_APP,
      SettingsLocations.NEXT_APP
    ]
  },
  filterLibraryLanguages: {
    id: "filterLibraryLanguages",
    type: SettingsType.array,
    default: [],
    locations: [
      SettingsLocations.MAIN_APP
    ]
  },
  sortByRecent: {
    id: "sortByRecent",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  playerState: {
    id: "playerState",
    type: SettingsType.number,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  baseBible: {
    id: "baseBible",
    type: SettingsType.string,
    server: true,
    default: "b1965",
    locations: [
      SettingsLocations.MAIN_APP,
      SettingsLocations.NEXT_APP
    ]
  },
  jtlBaseBible: {
    id: "jtlBaseBible",
    type: SettingsType.string,
    server: true,
    default: "b1965",
    locations: [
      SettingsLocations.MAIN_APP,
      SettingsLocations.NEXT_APP
    ]
  },  
  timeZoneAuto: {
    id: "timeZoneAuto",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  timeZone: {
    id: "timeZone",
    type: SettingsType.string,
    default: "America/New_York",
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  collectionFilter: {
    id: "collectionFilter",
    server: true,
    type: SettingsType.string,
    default: PeriodDefault,
    locations: [
      SettingsLocations.MAIN_APP,
      SettingsLocations.NEXT_APP
    ]
  },
  deliveryTime: {
    id: "deliveryTime",
    type: SettingsType.string,
    default: "00:00:00",
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  searchSnippet: {
    id: "searchSnippet",
    type: SettingsType.string,
    default: SNIPPET.LONG,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  searchSort: {
    id: "searchSort",
    type: SettingsType.string,
    default: Order.wrel,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  searchQueryType: {
    id: "searchQueryType",
    type: SettingsType.string,
    default: QUERY_TYPE.PREFER_EXACT,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showBookshelf: {
    id: "showBookshelf",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  lockedTabs: {
    id: "lockedTabs",
    type: SettingsType.array,
    default: [],
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  userInfo: {
    id: "userInfo",
    type: SettingsType.object,
    default: {},
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  menuState: {
    id: "menuState",
    type: SettingsType.string,
    default: MENU_STATE.COLLAPSE,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  hlListType: {
    id: "hlListType",
    type: SettingsType.string,
    default: "g_c",
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  scPanel: {
    id: "scPanel",
    type: SettingsType.string,
    default: "n",
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  libraryFirstOpen: {
    id: "libraryFirstOpen",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  paraChangePlayerBehaviour: {
    id: "paraChangePlayerBehaviour",
    type: SettingsType.number,
    default: READER_PLAYER_BEHAVIOURS.ASK,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  bookChangePlayerBehaviour: {
    id: "bookChangePlayerBehaviour",
    type: SettingsType.number,
    default: READER_PLAYER_BEHAVIOURS.ASK,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  saveWSOnReaderCloseBehaviour: {
    id: "saveWSOnReaderCloseBehaviour",
    type: SettingsType.number,
    default: SAVE_WORKSPACE_BEHAVIOURS.ASK,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  toggleInLibraryBehaviour: {
    id: "toggleInLibraryBehaviour",
    type: SettingsType.number,
    default: TOGGLE_IN_LIBRARY_BEHAVIOURS.ASK,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  copyChecks: {
    id: "copyChecks",
    type: SettingsType.object,
    default: { refs: true, headers: false, pageBreaks: false, format: false, embedUrlLink: false },
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  printChecks: {
    id: "printChecks",
    type: SettingsType.object,
    default: { refs: true, headers: false, pageBreaks: false, format: true, radio: "chap" },
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  workspaces: {
    id: "workspaces",
    server: true,
    type: SettingsType.array,
    default: [],
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  activeWorkspace: {
    id: "activeWorkspace",
    server: true,
    type: SettingsType.string,
    default: "",
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  lastEditWorkspace: {
    id: "lastEditWorkspace",
    server: true,
    type: SettingsType.string,
    default: "",
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  loadLanguageUI: {
    id: "loadLanguageUI",
    type: SettingsType.bool,
    default: false,
    locations: [
      SettingsLocations.NEXT_APP,
    ]
  },
  treeMenuMode: {
    id: "treeMenuMode",
    type: SettingsType.string,
    default: TreeMenuMode.FOLDERS,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showReadingHistory: {
    id: "showReadingHistory",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  showListenHistory: {
    id: "showListenHistory",
    type: SettingsType.bool,
    default: true,
    locations: [
      SettingsLocations.MAIN_APP,
    ]
  },
  geoIpData: {
    saveOnLogout: true,
    locations: [],
  },
};

export const getStoredValue = (id) => {
  let outValue;
  const setting = Settings[id];
  if (!setting) {
    return undefined;
  }
  if (setting.type === SettingsType.string) {
    outValue = localStorageSafe.getItem(setting.id) || setting.default;
  } else if (setting.type === SettingsType.number) {
    const intValue = localStorageSafe.getItem(setting.id);
    if (intValue !== undefined && intValue !== null) {
      outValue = parseInt(intValue, 10);
    } else if (setting.default) {
      outValue = setting.default;
    }
  } else if (setting.type === SettingsType.bool) {
    const value = localStorageSafe.getItem(setting.id);
    outValue = setting.default;
    if (value !== undefined && value !== null) {
      outValue = value === "1";
    }
  } else if (setting.type === SettingsType.array || setting.type === SettingsType.object) {
    outValue = getJsonValue(setting.id, setting.default);
  }
  return outValue;
};

const isValidType = (type, expectValue) => {
  return !(
    (type === SettingsType.string && typeof expectValue !== "string") ||
    (type === SettingsType.number && typeof expectValue !== "number") ||
    (type === SettingsType.bool && typeof expectValue !== "boolean") ||
    (type === SettingsType.array && !Array.isArray(expectValue))
  );
};

export const validateSettings = (loadedSettings, store) => {
  const settingsObj = {};

  Object.keys(loadedSettings).forEach((key) => {
    const setting = Settings[key];
    const serverSetting = loadedSettings[key];

    //TODO add type validation
    if (setting) {
      if (!isValidType(setting.type, serverSetting)) {
        console.warn(`Setting: ${setting.id} is not valid type - ${setting.type}`);
        return;
      }

      settingsObj[key] = serverSetting;
      if (store) {
        storeSetting(key, serverSetting);
      }
    }
  });

  return settingsObj;
};

/**
 * call action if it defined in settings, e.g. applyTheme
 * TODO extend this logic to call redux actions
 * @param {*} settings
 */
export const applySettings = (settings) => {
  let settingsObj = {};
  Object.keys(settings).forEach((key) => {
    const setting = Settings[key];
    if (setting && setting.action) {
      setting.action(settings[key]);
    }
  });

  return settingsObj;
};

export const getStoredSettings = (location = SettingsLocations.MAIN_APP) => {
  let settingsObj = {};
  Object.keys(Settings).forEach((key) => {
    if (Settings[key].locations.includes(location)) {
      settingsObj[key] = getStoredValue(key);
    }
  });
  return settingsObj;
};

export const getDefaultSettings = (location = SettingsLocations.MAIN_APP) => {
  return Object.entries(Settings).reduce((acc, [key, config]) => {
    if (config.locations.includes(location)) {
      acc[key] = config.default;
    } 

    return acc;
  }, {});
};

export const storeSetting = (id, value) => {
  const setting = Settings[id];
  if (setting) {
    if (value === undefined || value === null) {
      localStorageSafe.removeItem(setting.id);
    } else if (setting.type === SettingsType.string) {
      localStorageSafe.setItem(setting.id, value);
    } else if (setting.type === SettingsType.number) {
      localStorageSafe.setItem(setting.id, value);
    } else if (setting.type === SettingsType.bool) {
      localStorageSafe.setItem(setting.id, value ? "1" : "0");
    } else if (setting.type === SettingsType.array || setting.type === SettingsType.object) {
      setJsonValue(setting.id, value);
    }
    return setting;
  }
  console.warn("Setting " + id + " not defined");
  return undefined;
};

export const useSettings = (settingKey) => {
  const setting = useSelector((state) => state.settings[settingKey]);
  const dispatch = useDispatch();

  const setValue = (value) => {
    dispatch(actionUpdateSetting(settingKey, value));
  };

  return [setting, setValue];
};

export const getJsonValue = (name, defValue = {}) => {
  const stored = localStorageSafe.getItem(name);
  try {
    const parsed = JSON.parse(stored);
    return parsed || defValue;
  } catch (e) {
    return defValue;
  }
};

export const setJsonValue = (name, value) => {
  localStorageSafe.setItem(name, JSON.stringify(value));
};

/**
 * @deprecated
 * TODO impl way with load settings
 */
export const restoreSavedData = () => {
  return {
    searchHistory: getJsonValue(CACHE_SEARCH_HISTORY, {}).items || [],
    ...getStoredSettings()
  };
};

//check accordance of current time zone with the one on server
//if this option is turned on in settings
export const makeTimeZoneCheck = (timeData, autoCheck = true) => {
  const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();

  if ((autoCheck && !getStoredValue(Settings.timeZoneAuto.id)) || !resolvedOptions) {
    return undefined;
  }

  const currentTimeZone = resolvedOptions.timeZone;

  if (!currentTimeZone) {
    return undefined;
  }

  if (!timeData) {
    return undefined;
  }

  const { delivery_time: deliveryTime } = timeData;

  if (getStoredValue(Settings.timeZone.id) !== currentTimeZone) {
    return { timeZone: currentTimeZone, deliveryTime };
  }

  return undefined;
};
