export default {
  "ABC": 61697,
  "add-subscription": 61698,
  "adventist-beliefs": 61699,
  "adventist-library": 61700,
  "all-collections": 61701,
  "arrow-right": 61702,
  "audio-books": 61703,
  "AZ": 61704,
  "bible": 61705,
  "bibliography": 61706,
  "bold-text": 61707,
  "book-circle": 61708,
  "book-close": 61709,
  "book-open": 61710,
  "book-stack": 61711,
  "bookmark-outline": 61712,
  "bookmark": 61713,
  "books": 61714,
  "bookshelf": 61715,
  "bracers": 61716,
  "burger-menu": 61717,
  "calendar": 61718,
  "cart": 61719,
  "chevron-double-left": 61720,
  "chevron-double-right": 61721,
  "chevron-down": 61722,
  "chevron-left": 61723,
  "chevron-right": 61724,
  "chevron-up-circle": 61725,
  "chevron-up": 61726,
  "childrens-stories": 61727,
  "christ-s-life-and-ministry": 61728,
  "christian-lifestyle": 61729,
  "church-history": 61730,
  "church-life-and-ministry": 61731,
  "clip": 61732,
  "close": 61733,
  "commentaries": 61734,
  "concordances": 61735,
  "conflict-of-the-ages-series": 61736,
  "contact-us": 61737,
  "content": 61738,
  "copy-link": 61739,
  "copy": 61740,
  "delete": 61741,
  "devotional-readings": 61742,
  "devotionals": 61743,
  "dictionaries": 61744,
  "dictionary": 61745,
  "donate": 61746,
  "dots-vertical": 61747,
  "download": 61748,
  "edit": 61749,
  "education": 61750,
  "egw-biography": 61751,
  "ellen-dark": 61752,
  "ellen-light": 61753,
  "email-circle": 61754,
  "epub-file": 61755,
  "epub": 61756,
  "evangelism-and-witnessing": 61757,
  "extend": 61758,
  "facebook-circle": 61759,
  "facebook": 61760,
  "faq": 61761,
  "file": 61762,
  "filter": 61763,
  "folder-add": 61764,
  "folder-close": 61765,
  "folder-copy-to": 61766,
  "folder-import": 61767,
  "folder-move-to": 61768,
  "folder-open": 61769,
  "folder-shared": 61770,
  "folder-up": 61771,
  "font": 61772,
  "fullscreen-exit": 61773,
  "fullscreen": 61774,
  "go-to-full-app": 61775,
  "go-to-reader": 61776,
  "google-translate": 61777,
  "grid-2": 61778,
  "grid": 61779,
  "health-and-wellness": 61780,
  "heart-outline": 61781,
  "helping-guides": 61782,
  "highlight": 61783,
  "history": 61784,
  "home": 61785,
  "info-circle": 61786,
  "info": 61787,
  "information": 61788,
  "insta": 61789,
  "italic-text": 61790,
  "L2h": 61791,
  "L2v": 61792,
  "L3h": 61793,
  "L3s12": 61794,
  "L3s12v": 61795,
  "L3s21": 61796,
  "L3s21v": 61797,
  "L3v": 61798,
  "L4h": 61799,
  "L4s112": 61800,
  "L4s112v": 61801,
  "L4s121": 61802,
  "L4s121v": 61803,
  "L4s211": 61804,
  "L4s211v": 61805,
  "L4s22": 61806,
  "L4v": 61807,
  "L5s122": 61808,
  "L5s122v": 61809,
  "L5s2111": 61810,
  "L5s2111v": 61811,
  "L5s212": 61812,
  "L5s212v": 61813,
  "L5s221": 61814,
  "L5s221v": 61815,
  "L6h": 61816,
  "L6v": 61817,
  "L7s1222": 61818,
  "L7s1222v": 61819,
  "L7s2122": 61820,
  "L7s2122v": 61821,
  "L7s2212": 61822,
  "L7s2212v": 61823,
  "L7s2221": 61824,
  "L7s2221v": 61825,
  "L8h": 61826,
  "L8v": 61827,
  "languages": 61828,
  "last-day-events": 61829,
  "layouts": 61830,
  "leadership": 61831,
  "left-arrow": 61832,
  "lessons-from-the-bible": 61833,
  "lessons": 61834,
  "letters-manuscripts": 61835,
  "list": 61836,
  "logo-review-and-herald": 61837,
  "logo": 61838,
  "manuscript": 61839,
  "menu": 61840,
  "minus": 61841,
  "misc-collections": 61842,
  "misc-titles": 61843,
  "mobi-file": 61844,
  "modern-english": 61845,
  "mp-3-file": 61846,
  "my-library": 61847,
  "new-tab": 61848,
  "note": 61849,
  "old": 61850,
  "page-breaks": 61851,
  "page-numbers": 61852,
  "pamphlets": 61853,
  "paper-study-center": 61854,
  "parenting": 61855,
  "pause-circle": 61856,
  "pause": 61857,
  "pdf-file": 61858,
  "pdf": 61859,
  "periodicals": 61860,
  "pin": 61861,
  "pinterest": 61862,
  "planet": 61863,
  "play-circle": 61864,
  "play-header": 61865,
  "play": 61866,
  "playback-rate": 61867,
  "plus": 61868,
  "preview": 61869,
  "print-circle": 61870,
  "print": 61871,
  "profile": 61872,
  "publishing": 61873,
  "question": 61874,
  "read": 61875,
  "reader-arrow": 61876,
  "reading-history": 61877,
  "recent-authors": 61878,
  "reference-works": 61879,
  "reference": 61880,
  "relationships-and-marriage": 61881,
  "research-documents": 61882,
  "restart": 61883,
  "result-size": 61884,
  "save": 61885,
  "sda-scripture-Index": 61886,
  "search-book": 61887,
  "search-clear": 61888,
  "search-corrections": 61889,
  "search-featured": 61890,
  "search-header": 61891,
  "search-history": 61892,
  "search-phrase": 61893,
  "search-relation": 61894,
  "search-shortcut": 61895,
  "search-suggestions": 61896,
  "search-text": 61897,
  "search": 61898,
  "settings": 61899,
  "share": 61900,
  "show-headers": 61901,
  "sign-in": 61902,
  "skip-back": 61903,
  "skip-forward": 61904,
  "sort-by-recent": 61905,
  "sort": 61906,
  "star": 61907,
  "stop": 61908,
  "study-center-cap": 61909,
  "study-center-plus": 61910,
  "study-center": 61911,
  "study-guides": 61912,
  "subscription-outline": 61913,
  "subscription": 61914,
  "sync-with-feature-search": 61915,
  "sync-with-player": 61916,
  "sync": 61917,
  "telegram-circle": 61918,
  "testimonies-for-the-church": 61919,
  "text-center": 61920,
  "text-decrease": 61921,
  "text-increase": 61922,
  "text-justify": 61923,
  "text-left": 61924,
  "text-right": 61925,
  "theme-select": 61926,
  "timer-disabled": 61927,
  "timer": 61928,
  "titles": 61929,
  "topical-index": 61930,
  "translation": 61931,
  "tree": 61932,
  "twitter-circle": 61933,
  "twitter": 61934,
  "typo": 61935,
  "underline-text": 61936,
  "unshare": 61937,
  "view": 61938,
  "volume-muted": 61939,
  "volume": 61940,
  "whatsapp-circle": 61941,
  "youth-and-modern-english": 61942,
  "youtube-circle": 61943,
};
