import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { BookListType } from "./BooksUtils";
import { findNode, getLanguageDefault } from "../../utils/Utils";
import { Loading } from "../";
import BookList from "./BookList";
import { createPredicateFilterBooks, sortBooks } from "../../utils/FilterBooksUtils";
import { mapFilterFormatToBtnInteractType } from "./BookList.utils";
import { ContentActions } from "src/redux/content.actions";

import "./BookList.scss";

const Books = ({ folderId, isAudio }) => {
  const dispatch = useDispatch();
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const filters = useSelector((state) => state.folderTree.filters);
  const booksFolder = useMemo(() => findNode(folderId, mainTree), [folderId, mainTree]);
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const [charFilters, setCharFilters] = useState("");

  const booksFolderChildren = useMemo(() => {
    return booksFolder.children || [];
  }, [booksFolder.children]);

  useEffect(() => {
    if (booksFolderChildren.length === 0) {
      dispatch(ContentActions.fetchBooksRequest(folderId));
    }
  }, [folderId, booksFolderChildren]);

  const books = useMemo(() => {
    const bookListFiltered = booksFolderChildren.filter(
      createPredicateFilterBooks(
        {
          showReadingHistory: !isAudio,
          showListenHistory: true,
        },
        filters,
      ),
    );

    return sortBooks(bookListFiltered, getLanguageDefault(libraryLanguages), filters.sortingFilter);
  }, [booksFolderChildren, isAudio, filters, libraryLanguages]);

  if (!booksFolder.children) {
    return <Loading type={Loading.types.BOOKS_LIST} />;
  }

  return (
    <div className="page_books">
      <BookList
        isShownFormatIcon={!isAudio}
        bookInteractTypeCustom={
          isAudio ? undefined : mapFilterFormatToBtnInteractType[filters.format]
        }
        filterChar={charFilters}
        setFilterChar={setCharFilters}
        bookListType={isAudio ? BookListType.listen : BookListType.all}
        books={books}
      />
    </div>
  );
};

Books.propTypes = {
  folderId: PropTypes.number,
  isAudio: PropTypes.bool,
};

export default Books;
