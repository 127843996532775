import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import { STORAGE_KEY_LAST_SC_ACTIONS } from "../../components/studyCenter/studyCenter.constants";

const _emulatedItems = [];

export const _emulateStorage = {
  setItem: (name, value) => {
    _emulatedItems[name] = value;
  },
  getItem: (name) => {
    return _emulatedItems[name];
  },
  removeItem: (name) => {
    delete _emulatedItems[name];
  },
  clear: () => {
    _emulatedItems.length = 0;
  }
};

// Fixes the crash of a Darryl's friend with an Ipad IOS 10.
export const _getSessionStorageSafe = () => {
  try {
    if (typeof sessionStorage === "object") {
      sessionStorage.setItem("sessionStorage", 1);
      sessionStorage.removeItem("sessionStorage");
    }
    return sessionStorage;
  } catch (error) {
    return _emulateStorage;
  }
};

// Fixes the crash of a Darryl's friend with an Ipad IOS 10.
export const _getLocalStorageSafe = () => {
  try {
    if (typeof localStorage === "object") {
      localStorage.setItem("localStorage", 1);
      localStorage.removeItem("localStorage");
    }
    return localStorage;
  } catch (error) {
    return _emulateStorage;
  }
};

export const localStorageSafe = _getLocalStorageSafe();
export const sessionStorageSafe = _getSessionStorageSafe();

const localStorageIgnoredSubstrings = [STORAGE_KEY_LAST_SC_ACTIONS];
// Use this method to clear LS.
// By default, it preserves keys that don't need to be removed.
export const clearLocalStorage = (clearAllKeys) => {
  Object.keys(localStorageSafe).forEach((key) => {
    const removeKey = clearAllKeys
      || !localStorageIgnoredSubstrings.some((substring) => key.includes(substring));

    if (removeKey) {
      localStorageSafe.removeItem(key);
    }
  });
};

export const getCookie = (name) => Cookies.get(name);
export const deleteCookie = (name) => Cookies.remove(name);

/**
 * @name deleteAllCookies
 * @description clears all Cookies except ignoredCookies passed as an argument
 * @param {string[]} ignoredCookies
 */
export const deleteAllCookies = (ignoredCookies = []) => {
  Object.keys(Cookies.get()).forEach((name) => {
    if (!ignoredCookies.includes(name)) {
      deleteCookie(name);
    }
  });
};

export const getTokens = () => {
  const token = _getSessionStorageSafe().getItem("token");
  const refreshToken = getCookie("refresh_token", document.cookie);

  return { token, refreshToken };
};

export const setTokens = (token, refreshToken) => {
  if (token) {
    _getSessionStorageSafe().setItem("token", token);
  } else {
    _getSessionStorageSafe().removeItem("token");
  }
  if (refreshToken) {
    Cookies.set("refresh_token", refreshToken, { expires: 100000000 });
    Cookies.set("access_token", token);
  } else {
    deleteCookie("refresh_token");
    deleteCookie("access_token");
  }
};

/**
 * return token for current session
 * @returns uuid
 */
export const getSessionToken = () => {
  let token = sessionStorageSafe.getItem("session_token");
  if (!token) {
    token = uuid();
    sessionStorageSafe.setItem("session_token", token);
  }
  return token;
};

/**
 * return token for current browser
 * @returns uuid
 */
export const getStableToken = () => {
  let token = localStorageSafe.getItem("stable_token");
  if (!token) {
    token = uuid();
    localStorageSafe.setItem("stable_token", token);
  }

  return token;
};

