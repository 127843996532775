import { getJsonAsCssString } from './ThemeUtils';

const createStyles = () => {
  let content = `
      <style>
        body {
          font-size: 16px;
          padding: 1em;
          font-family: sans-serif;
        }

        .egwwritings {
          color: var(--category-egw);
          fill: var(--category-egw);
        }

        .egwwritings.mark {
          background: var(--category-egw);
          color: white;
        }

        .search-item-wrap {
          font-family: "Roboto Condensed", sans-serif;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid var(--icon-color);
          padding: 14px;
          box-sizing: border-box;
        }

        .search-item-title {
          font-family: "Roboto Condensed", sans-serif;
          font-style: normal;
          font-weight: bold;
          white-space: nowrap;
          height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          text-transform: uppercase;
          line-height: 32px;
        }

        .search-item-snippet {
          font-family: "Roboto Condensed", sans-serif;
          color: var(--text-default);
          overflow: hidden;
          text-overflow: ellipsis;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.25;
          max-height: calc(100% - 35px);
        }

        .search-item-wrap.egwwritings .search-item-snippet,
        .search-item-wrap.reference .search-item-snippet,
        .search-item-wrap.bible .search-item-snippet,
        .search-item-wrap.apl .search-item-snippet {
          color: var(--text-default);
        }
      </style>
    `;

  return content;
};


const _createDiv = () => {
  const div = document.createElement("div");
  div.classList.add("search-item-wrap", "egwwritings");
  return div;
};

const _createHistorySearchElement = (item, index) => {
  const div = _createDiv();
  div.innerHTML += `
    <div class="search-item-title egwwritings">${index + 1} ${item.query}</div>
  `;
  div.innerHTML += "\n";

  return div;
};

const _createSearchElement = (item) => {
  const div = _createDiv();
  div.innerHTML += `
    <div class="search-item-title egwwritings">${item.index + 1} ${item.group}(${item.refcode_long})</div>
    <div class="search-item-snippet egwwritings">${item.snippet}</div>
  `;
  div.innerHTML += "\n";

  return div;
};

const _appendToContainer = (data, fn) => {
  const container = document.createElement("div");
  return data.reduce((acc, item, index) => {
    acc.append(fn(item, index));
    return acc;
  }, container);
};

export const createHistorySearchContainer = (data) => {
  return _appendToContainer(data, _createHistorySearchElement);
};

export const createContainer = (data) => {
  return _appendToContainer(data, _createSearchElement);
};

export const createPrintWindow = (data) => {
  const win = window.open(undefined, "_blank");
  if (win && win.document) {
    const container = createContainer(data);
    win.document.write(`<title>Print</title>`);
    win.document.write(`<style>${getJsonAsCssString()}</style>`);
    win.document.write(createStyles());
    win.document.write(container.innerHTML);
    win.focus();
    win.print();
  } else {
    alert("Your browser prevented the window from opening.");
  }
};
