import React from "react";
import PropTypes from "prop-types";
import { useViewMode } from "../../hooks";

import "./View.scss";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";


const TextButton = ({ active, disabled, onClick, className,  ariaPressed = undefined, children, ...props }) => {
  const { textMode } = useViewMode();

  let styles = "text-button" + (className ? " " + className : "");

  if (textMode) {
    styles += " text-only";
  }

  styles += active ? " iconActive" : " iconInActive";
  styles += disabled ? " iconDisabled" : "";

  const onKeyDownHandle = (event) => {
    if (isEventKey(event, KeyCodes.enter) && onClick) {
      onClick(event);
    }
  };

  return (
    <span
      aria-pressed={active ?? ariaPressed}
      role="button"
      tabIndex={0}
      className={styles}
      onKeyDown={onKeyDownHandle}
      onClick={onClick}
      {...props}
    >
      {children}
    </span>
  );
};

TextButton.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default TextButton;
