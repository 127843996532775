import { makeResourceUrl } from "../../shared/utils/url";

export const getFileDuration = (mp3Url) => {
  if (!mp3Url) {
    return 0;
  }
  let fileDuration = 0;
  const durationReg = /duration=(.*)&/;
  const [, value] = mp3Url.match(durationReg);
  if (value) {
    fileDuration = parseInt(value, 10);
  }
  return fileDuration;
};

export const makeJsonUrl = (mp3URL) => {
  return makeResourceUrl(mp3URL.split("#")[0].replace(".mp3", ".json"));
};

const getAudioChapterIndexForChapter = (chapters, chapterId, duplicateId) => {
  return chapters.findIndex(
    (chapter) => (chapter.id === chapterId
      || chapter.dup === chapterId
      || chapter.id === duplicateId) && chapter.mp3
  );
};

export const getAudioChapterForChapter = (chapters, chapterId, duplicateId) => {
  const index = getAudioChapterIndexForChapter(chapters, chapterId, duplicateId);

  if (index !== -1) {
    return chapters[index];
  }
  return undefined;
};

export const getAudioChapters = (chapters, chapterId) => {
  if (chapters && chapterId) {
    const audioChaptersList = chapters.filter((item) => !!item.mp3);
    const index = getAudioChapterIndexForChapter(audioChaptersList, chapterId);
    let track, fileDuration = 0;

    if (index !== -1) {
      track = audioChaptersList[index];

      if (track && track.mp3) {
        fileDuration = getFileDuration(track.mp3);
      }
    }

    return [track, index, audioChaptersList, fileDuration];
  }
  return [];
};

export const findWaveformsDictionaryParaById = (waveformsDictionary, targetId, paraId) => {
  return waveformsDictionary[targetId]?.paragraphsAudioPositions.find((item) => {
    return item.id === paraId;
  });
};

export const findWaveformsDictionaryParaByPosition = (waveformsDictionary, targetId, position) => {
  return waveformsDictionary[targetId]?.paragraphsAudioPositions.find((item) => {
    return item.start <= position && item.end > position;
  });
};

