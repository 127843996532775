import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./Subscriptions.scss";

const SubsBadge = ({ minMode }) => {

  const subsCount = useSelector((state) => state.subscriptions.subsCount);

  if (subsCount > 0) {
    return <div className={"badge-container" + (minMode ? " min" : "")}>{subsCount}</div>;
  }
  return null;
};

SubsBadge.propTypes = {
  minMode: PropTypes.bool
};

export default SubsBadge;
