export const updateStatePropertyAndItsCache = (
  CACHE_LIMIT_ITEMS, state, stateKeyCache, stateKeyData, key, payload
) => {
  const stateCacheNew = { ...state[stateKeyCache], [key]: payload };

  // Clean a half of the cache if its size is bigger than {CACHE_LIMIT_ITEMS}
  const cacheKeys = Object.keys(state[stateKeyCache]);
  if (cacheKeys.length > CACHE_LIMIT_ITEMS) {
    for (let i = 0; i < CACHE_LIMIT_ITEMS / 2; i++) {
      delete stateCacheNew[cacheKeys[i]];
    }
  }

  return {
    ...state,
    [stateKeyCache]: stateCacheNew,
    [stateKeyData]: payload,
  };
};
