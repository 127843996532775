import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";
import FeaturedTab from "../rightPanel/FeaturedTab";
import Badge from "../Badge";
import { useAuth } from "../../hooks";
import { useSearchContext } from "../search/SearchContext";
import SearchAccordion from "../views/Accordion/SearchAccordion";
import { PeriodDefault } from "../../shared/utils/search";
import { URLS } from "../../shared/utils/url";

const RelatedEgwView = ({ lang, mobileView, schemeFiltered, rightPanel, onNavigate }) => {
  const { t } = useTranslation();
  const facets = useSelector((state) => state.relevantSearch.facets);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const searchParams = useSelector((state) => state.search.searchParams);
  const lastSearchQuery = useSelector((state) => state.relevantSearch.lastSearchQuery);
  const search = useSelector((state) => state.search.search);
  const searchHistory = useSelector((state) => state.search.searchHistory);
  const searchQueryLangs = useSelector((state) => state.search.searchQueryLangs);
  const mainTree = useSelector((state) => state.mainTree.mainTree);
  const { total: searchTotal } = search;
  const collectionFilter = useSelector((state) => state.settings.collectionFilter);
  const { isLogin } = useAuth();
  const { openSearchHistory } = useSearchContext();

  const langsFacets = useMemo(() => {
    const sortable = [];
    for (let langs in searchQueryLangs) {
      sortable.push({ lang: langs, count: searchQueryLangs[langs] });
    }
    sortable.sort((a, b) => b.count - a.count);
    const sorted = sortable.slice(0, 10).map((facet) => {
      const langName = mainTree.find((item) => item.id === facet.lang);
      if (langName) {
        return { name: langName.label, code: langName.code, count: facet.count };
      }
      return { name: facet.lang, code: facet.lang, count: facet.count };
    });

    return sorted || [];
  }, [searchQueryLangs, mainTree]);

  const renderRelevantForm = (position, placeholder = "") => {
    let data = schemeFiltered;
    if ((data === undefined || data.length === 0) && !rightPanel) {
      return <div className="filter-search-box-placeholder">{t(placeholder)}</div>;
    }
    let title =
      searchQuery !== "" ? t("searchWithQuery", { query: searchQuery }) : t("search") + ":";
    if (mobileView) {
      return (
        <React.Fragment>
          {position === "left" && (
            <div
              key="egw_search"
              className={classNames("relevant-search-block", {
                "mobile-view": mobileView,
              })}>
              <SearchAccordion title={title} className="folder" isOpenByDefault>
                {renderRelevantFormContentLeftFirst(data)}
              </SearchAccordion>
            </div>
          )}
          {renderSections(position)}
          {position === "left" && (
            <div
              key="egw_dictionary"
              className={classNames("relevant-search-block", {
                "mobile-view": mobileView,
              })}>
              <SearchAccordion title={t("dictionary")} className="folder">
                {renderRelevantFormContentLeftDictionary(data)}
              </SearchAccordion>
            </div>
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {position === "left" && (
          <div
            key="egw_search"
            className={classNames("relevant-search-block", {
              "mobile-view": mobileView,
            })}>
            {renderRelevantFormContentLeftFirst(data)}
          </div>
        )}
        {renderSections(position)}
        {position === "left" && lang === "en" && (
          <div
            key="egw_dictionary"
            className={classNames("relevant-search-block", {
              "mobile-view": mobileView,
            })}>
            <div className="title ellipsis">{t("dictionary")}</div>
            {renderRelevantFormContentLeftDictionary(data)}
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderSections = (position) => {
    return (schemeFiltered || []).map((item) => {
      let left = ["egw_phrase:000", "egw_topical_index"];
      let left2 = [
        "egw_topical_index",
        "egw_dictionary",
        "egw_dictionary:001",
        "egw_dictionary:002",
        "egw_search:001",
        "egw_search:002",
        "egw_search:003",
        "egw_search:004",
        "egw_phrase:000",
      ];
      if (item.code.indexOf("bible_") !== 0) {
        if (position === "left" && left.includes(item.code)) {
          return (
            <div
              key={item.code}
              className={classNames("relevant-search-block", {
                "mobile-view": mobileView,
              })}>
              {renderRelevantFormContentLeft(item)}
            </div>
          );
        } else if (position === "right" && !left2.includes(item.code)) {
          return (
            <div
              key={item.code}
              className={classNames("relevant-search-block", {
                "mobile-view": mobileView,
              })}>
              {renderRelevantFormContentRight(item)}
            </div>
          );
        }
        return false;
      }
      return false;
    });
  };

  const handleSearchByLang = (item) => {
    const query = lastSearchQuery || searchQuery || searchParams.query || ""; 
    const searchExtras = {
      ...searchParams.extras,
      period: searchParams.extras.period || collectionFilter || PeriodDefault,
    };   
    if (query !== "") {
      let start = 1;
      let context = item.name;
      let folders = [item.code];

      let extras = { ...searchExtras, type: "basic" };
      onNavigate({ query, start, context, extras, folders }, collectionFilter);
    }
  };

  const handleSearch = (item, section) => {
    let query = lastSearchQuery || searchQuery || searchParams.query;
    const searchExtras = {
      period: searchParams.extras.period || collectionFilter || PeriodDefault,
    };
   
    if (query !== "") {
      let start = 1;
      let extras = { ...searchExtras, section, type: "related", lang };
      let context = item.code;
      let folders = searchParams.folders;
      let omskType = ["synonyms", "antonyms", "definitions"];
      if (
        item.code.substring(0, 7) === "phrases" ||
        omskType.includes(item.code) ||
        item.code === "suggestions"
      ) {
        extras = { ...extras, snippet: [], order: [], folders: [] };
        folders = [];
      }
      if (item.code === "search_results") {
        extras = { ...searchExtras, section: "", type: "basic" };
        context = "";
      }

      if (item.code === "egw_search_not") {
        extras = { ...searchExtras, section: "", type: "basic", lang };
        context = "";
        query = query.replace(/[,.\s:;!?\t]/g, " ").replace(/ +/g, " !");
      }

      if (item.code === "egw_search_or") {
        extras = { ...searchExtras, section: "", type: "basic", lang };
        context = "";
        query = query.replace(/[,.\s:;!?\t]/g, "  ").replace(/  +/g, " | ");
      }

      onNavigate({ query, start, context, extras, folders }, collectionFilter);
    }
  };

  const renderRelevantFormContentLeftDictionary = (dataFiltered) => {
    return (dataFiltered || []).map((data) => {
      if (data.code.substring(0, 14) === "egw_dictionary") {
        return (data.endpoints || []).map((item) => {
          let count = facets?.[data.code]?.[item.code] || 0;
          return renderRelevantFormContentItem(item, count, data.code);
        });
      }
      return false;
    });
  };

  const renderRelevantFormContentLeftFirst = (dataFiltered) => {
    let left = ["egw_search:002", "egw_search:003", "egw_search:004"];
    let filtered = (dataFiltered || []).map((data) => {
      if (data.code.indexOf("egw_search:") === 0) {
        return (data.endpoints || []).map((item) => {
          if (data.code === "egw_search:001") {
            if (
              !searchQuery ||
              (searchQuery.trim() !== searchParams.query.trim() && !searchParams?.books)
            ) {
              return renderRelevantFormContentItem(item, 0, data.code);
            }
            // else: searchQuery is not empty and searchParams.query === searchQuery case
            const count = Math.max(0, searchTotal);
            return renderRelevantFormContentItem(item, count, data.code);
          } else if (left.includes(data.code)) {
            let count = facets?.[data.code]?.[item.code] || 0;
            if (count >= 1000) {
              count = "1000+";
            }
            return renderRelevantFormContentItem(item, count, data.code);
          }
          return false;
        });
      }
      return false;
    });
    if (facets?.["egw_search:009"]?.["suggestions"]) {
      const suggest = renderRelevantFormSuggestions(
        { title: "Suggestions", code: "suggestions" },
        facets["egw_search:009"]["suggestions"],
        "egw_search:009",
      );
      filtered = filtered.concat(suggest);
    }
    return (
      <React.Fragment>
        {!mobileView && <div className="title">{t("searchResults")}</div>}
        {filtered}
      </React.Fragment>
    );
  };

  const renderRelevantFormContentLeft = (data) => {
    let left = ["egw_search:003", "egw_dictionary:002"];
    if (data.endpoints.length > 1 && !left.includes(data.code)) {
      if (mobileView) {
        return (
          <SearchAccordion title={data.title} className="folder">
            {(data.endpoints || []).map((item) => {
              let count = facets?.[data.code]?.[item.code] || 0;
              return renderRelevantFormContentItem(item, count, data.code);
            })}
          </SearchAccordion>
        );
      }
      return (
        <React.Fragment>
          <div className="title">{data.title}</div>
          {(data.endpoints || []).map((item) => {
            let count = facets?.[data.code]?.[item.code] || 0;
            return renderRelevantFormContentItem(item, count, data.code);
          })}
        </React.Fragment>
      );
    }
    let count = facets?.[data.code]?.[data.code] || 0;
    return (
      <div
        className={classNames("title with-badge-wrap", count > 0 ? "clickable" : "")}
        onClick={() => (count > 0 ? handleSearch(data, data.code) : false)}>
        {data.title}
        <Badge number={count} />
      </div>
    );
  };

  const renderRelevantFormContentRight = (data) => {
    if (data.endpoints.length > 1) {
      if (mobileView) {
        return (
          <SearchAccordion title={data.title} className="folder">
            <div className="cols">
              {(data.endpoints || []).map((item) => {
                let count = facets?.[data.code]?.[item.code] || 0;
                return renderRelevantFormContentItem(item, count, data.code);
              })}
            </div>
          </SearchAccordion>
        );
      }
      return (
        <React.Fragment>
          <div className="title">{data.title}</div>
          <div className="cols">
            {(data.endpoints || []).map((item) => {
              let count = facets?.[data.code]?.[item.code] || 0;
              return renderRelevantFormContentItem(item, count, data.code);
            })}
          </div>
        </React.Fragment>
      );
    }
    let count = facets?.[data.code]?.[data.code] || 0;
    return (
      <div
        className={classNames("title", count > 0 ? "clickable" : "")}
        onClick={() => (count > 0 ? handleSearch(data, data.code) : false)}>
        {data.title}
        <Badge number={count} />
      </div>
    );
  };

  const renderRelevantFormContentItem = (item, count, section) => {
    return (
      <div
        key={item.code}
        className={classNames("relevant-search-content-item", count ? "clickable" : "")}
        onClick={() => (count ? handleSearch(item, section) : false)}>
        <div className="item-title">{item.title}</div>
        <Badge number={count} />
      </div>
    );
  };

  const renderRelevantFormSuggestions = (item, data, section) => {
    return (
      <div
        key={item.code}
        className={classNames("relevant-search-content-item", data.length > 0 ? "clickable" : "")}
        onClick={() => (data.length > 0 ? handleSearch(item, section) : false)}>
        <div className="item-title">{item.title}</div>
        <Badge number={data.length} />
      </div>
    );
  };

  const goToMainHistory = () => {
    onNavigate(URLS.historySearch);
  };

  const updateSearchHandler = (item) => () => {
    openSearchHistory(item.query);
  };

  const renderSearchHistoryContent = () => (
    <React.Fragment>
      {searchHistory.slice(0, 5).map((item, index) => {
        return (
          <div
            className="search-history-item relevant-search-content-item overflow-text clickable"
            key={index}
            onClick={updateSearchHandler(item)}>
            <div className="item-title">{item.query}</div>
          </div>
        );
      })}
      <div className="relevant-search-content-item clickable" onClick={goToMainHistory}>
        <button className="more-button">
          <span className="more-button-title with-chevron">{t("more")}</span>
        </button>
      </div>
    </React.Fragment>
  );

  const renderSearchHistory = () => {
    if (!isLogin || !searchHistory.length) {
      return null;
    }

    if (mobileView) {
      return (
        <div className="relevant-search-block mobile-view">
          <SearchAccordion title={t("searchHistoryTitle")} className="folder">
            {renderSearchHistoryContent()}
          </SearchAccordion>
        </div>
      );
    }

    return (
      <div className="relevant-search-block">
        <div className="title">{t("searchHistoryTitle")}</div>
        <div className="cols">{renderSearchHistoryContent()}</div>
      </div>
    );
  };

  const renderSearchLangContent = () =>
    langsFacets.map((item, index) => {
      return (
        <div
          className="relevant-search-content-item overflow-text clickable"
          key={index}
          onClick={() => {
            if (item.count) {
              handleSearchByLang(item);
            }
          }}>
          <div className="item-title">{item.name}</div>
          <Badge number={item.count} />
        </div>
      );
    });
  const renderSearchLang = () => {
    if (langsFacets.length > 0) {
      if (mobileView) {
        return (
          <div className="relevant-search-block-mobile">
            <SearchAccordion title={t("searchLangTitle")} className="folder">
              {renderSearchLangContent()}
            </SearchAccordion>
          </div>
        );
      }

      return (
        <div className="relevant-search-block">
          <div className="title">{t("searchLangTitle")}</div>
          <div className="cols">{renderSearchLangContent()}</div>
        </div>
      );
    }
    return false;
  };
  if (rightPanel) {
    return (
      <div className="relevant-search-box">
        <div className="relevant-search-box-left">{renderRelevantForm("left")}</div>
        <div className="relevant-search-box-right">{renderRelevantForm("right")}</div>
      </div>
    );
  }
  return (
    <div className="relevant-search-box">
      {schemeFiltered?.length !== 0 && (
        <div className="relevant-search-box-left">{renderRelevantForm("left", "noSearch")}</div>
      )}
      <div className="relevant-search-box-right">
        {renderRelevantForm("right", "reSearch")}
        {renderSearchHistory()}
        {renderSearchLang()}
        <FeaturedTab isInsideSearchPlus={false} type="egw" mobileView={mobileView} />
      </div>
    </div>
  );
};

RelatedEgwView.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  lang: PropTypes.string,
  mobileView: PropTypes.bool,
  schemeFiltered: PropTypes.array,
  rightPanel: PropTypes.bool,
};

export default RelatedEgwView;
