import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDeviceDetect } from "./index";

export const useScrollToTop = (scrollRef) => {
  const { pathname } = useLocation();
  const { isRealMobile } = useDeviceDetect();

  useEffect(() => {
    if (isRealMobile) {
      const { scrollY, scrollTo } = window;

      if (scrollY && scrollY > 0) {
        scrollTo(0, 0);
      }
    } else if (typeof scrollRef?.current?.scrollToTop === "function") {
      scrollRef.current.scrollToTop();
    }
  }, [scrollRef, pathname, isRealMobile]);
};
