import React from "react";
import PropTypes from "prop-types";
import { Books } from "../components/book";
import { FolderList, LanguageFolders, Loading } from "../components/index";
import { useNavigationContext } from "../components/NavigationContext";

const FolderPage = ({ match }) => {
  const { lang, id } = match.params;
  const { currentFolder, isAudio } = useNavigationContext();

  if (currentFolder) {
    const booksCount = isAudio ? currentFolder.nAudioBooks : currentFolder.nbooks;
    if (booksCount > 0) {
      return (
        <Books folderId={currentFolder.id} isAudio={isAudio} />
      );
    }
    if (isAudio) {
      return <FolderList lang={lang} id={id} isAudio={true} />;
    }
    return <LanguageFolders lang={lang} id={id} isLibrary={false} />;
  } else if (lang) {
    if (isAudio) {
      return <FolderList lang={lang} id={id} isAudio={true} />;
    }
    return <LanguageFolders lang={lang} id={id} isLibrary={false} />;
  }

  return <Loading type={Loading.types.CURRENT_FOLDER} />;
};

FolderPage.propTypes = {
  match: PropTypes.object
};

export default FolderPage;
