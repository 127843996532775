import deepEqual from "deep-equal";

import { extrasArrayFields, Period } from "./AdvSearchTypes";
import { findNode, getChildrenIds, int3DigitFormat } from "./Utils";
import componentsId from "../components/constants";
import {
  getMinExtras,
  Order,
  PeriodDefault,
  RESTRICTION_SEARCH_VALUE
} from "../shared/utils/search";
import { searchStrToReqParams } from "src/shared/utils/api";
import { URLS } from "../shared/utils/url";

export const isRestricted = (value) => value > RESTRICTION_SEARCH_VALUE;

export const findBiblePath = ({ defLang, mainTree, bibles, baseBible }) => {
  const path = [];

  const root = mainTree.find((item) => item.id === defLang);

  if (root && root.children) {
    const bibleRoot = root.children.find((item) => item.className === "bible");
    if (bibleRoot) {
      path.push(bibleRoot.id);
    } else {
      return [path];
    }
  }

  const myBible = bibles.find((item) => item.id === baseBible);
  const bibleNode = findNode(baseBible, mainTree);

  if (bibleNode) {
    const { ids, parents } = getChildrenIds(bibleNode.id, mainTree);
    path.push(...ids, ...parents);
  }
  if (myBible) {
    path.push(myBible.folder_id, myBible.id);
  }

  if (path.length) {
    path.unshift(defLang);
  }
  return [[...new Set(path)], myBible && myBible.id];
};

export const getSearchLoaderId = (isLocal, rightPanel) => {
  if (isLocal) {
    return componentsId.LOCAL_SEARCH_ID;
  }

  if (rightPanel) {
    return componentsId.RIGHT_PANEL_SEARCH_ID;
  }

  return componentsId.SEARCH_ID;
};

export const isDisableOption = (itemId, period) => {
  if (itemId === Order.wrel && (period === Period.myBible || period === Period.apl)) {
    return true;
  }

  return (
    itemId === Order.rel &&
    (period === Period.compilations || period === Period.lifetime || period === Period.apl)
  );
};

export const isDisableOptionRelated = (itemId, orders) => {
  return !orders.includes(itemId);
};

export const updateSearchPeriod = (period) => {
  const params = { period };

  if (period === Period.lifetime || period === Period.compilations) {
    params.order = Order.wrel;
  }

  if (period === Period.myBible || period === Period.apl) {
    params.order = Order.seq;
  }

  return params;
};

export const makeParamsString = (params, isApi = false) => {
  if (!Object.keys(params).length) {
    return "";
  }
  const aParams = [];
  for (const key in params) {
    if (!params[key]) {
      continue;
    }
    const item = params[key];

    if (Array.isArray(item)) {
      // array case
      if (isApi) {
        // array api format
        for (const value of item) {
          aParams.push(`${key}=${encodeURIComponent(value)}`);
        }
      } else {
        // array app format
        const value = item.join(",");
        aParams.push(`${key}=${value}`);
      }
    } else {
      // string case
      if (extrasArrayFields.includes(key)) {
        // array serialization case
        aParams.push(`${key}=${item}`);
      } else {
        aParams.push(`${key}=${encodeURIComponent(item)}`);
      }
    }
  }
  return aParams.join("&");
};
// exluded values e.g. reader params
const excludes = ["rightPanel", "firstSearch", "delay", "paraId", "panels", "index", "id"];

export const isExistValue = (value) => {
  if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
    return value;
  }
  if (Array.isArray(value)) {
    return value.length;
  }

  return false;
};

/**
 * @typedef {Object} SearchParams
 * @property {?string} query
 * @property {?string[]} folders
 * @property {?string[]} langs
 * @property {?string[]} chapters
 * @property {?string[]} books
 * @property {?string} period
 * @property {?string} order
 * @property {?string} section
 * @property {?string} snippet
 * @property {?string} type
 * @property {?string} tab
 * @property {?number} start
 * @property {?Object} extras
 */

/**
 *
 * @param {SearchParams} params
 * @param {string} collectionFilter - user's collection filter setting
 * @param {boolean?} isRelated
 * @returns
 */
export const makeSearchUrl = (params, collectionFilter, isRelated) => {
  const searchQuery = [];
  const entries = Object.entries(params);

  entries.forEach(([key, value]) => {
    if (key === "folders" &&  value &&  value.includes("all")) {
      return;
    } else if (key === "extras") {
      const extraEntries = Object.entries(value);
      for (let extraQ of extraEntries) {
        const [extraKey, extraValue] = extraQ;
        if (!excludes.includes(extraKey) && isExistValue(extraValue)) {
          searchQuery.push(`${extraKey}=${encodeURIComponent(extraValue)}`);
        }
      }
    } else {
      if (!excludes.includes(key) && isExistValue(value)) {
        searchQuery.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
  });

  if (!params.period && !params?.extras?.period) {
    searchQuery.push("period=" + (collectionFilter || PeriodDefault));
  }

  return (isRelated ? URLS.relatedSearch : URLS.search) + "?" + searchQuery.join("&");
};

/**
 *
 * @param {*} search
 * @param {*} isRelated
 * @returns {SearchParams}
 */
export const parseSearchUrl = (search, isRelated = false) => {
  if (search?.length < 1) {
    return {};
  }
  const params = searchStrToReqParams(search);

  if (Object.keys(params).length === 0) {
    return {};
  }

  if (params.langs) {
    params.langs = params.langs.split(",");
  }

  if (isRelated) {
    return params;
  }

  params.extras = getMinExtras(search);

  if (params.start) {
    params.start = parseInt(params.start);
  }

  if (params.folders) {
    params.folders = params.folders.split(",");
  }

  if (params.books) {
    params.books = params.books.split(",");
  }

  if (params.chapters) {
    params.chapters = params.chapters.split(",");
  }

  return params;
};

export const isEqualSearch = (searchQuery, searchParams, localSearchParams) => {
  const { query, extras, folders, books, isLocal, context, chapters } = searchQuery;
  if (isLocal) {
    return (
      localSearchParams.query === query &&
      deepEqual(extras, localSearchParams.extras) &&
      deepEqual(books, localSearchParams.books)
    );
  }
  return (
    !searchParams?.firstSearch &&
    searchParams.query === query &&
    deepEqual(extras, searchParams.extras) &&
    deepEqual(books, searchParams.books) &&
    deepEqual(chapters, searchParams.chapters) &&
    deepEqual(context, searchParams.context) &&
    deepEqual(folders, searchParams.folders)
  );
};

const QueryRegex = /\w*/u;
export const validateSearchQuery = (query) => {
  if (!query) {
    return false;
  }
  return QueryRegex.test(query);
};

export const getResultsCount = (count) => {
  if (isRestricted(count)) {
    return `${int3DigitFormat(RESTRICTION_SEARCH_VALUE)}+`;
  }
  return int3DigitFormat(count);
};
