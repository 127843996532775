import React, { useEffect, useState } from "react";
import { useDevMode } from "src/hooks";
import { Hotkeys, KeyCodes } from "src/shared/utils/dom";
import { isEventKeys } from "src/utils/Utils";

import "./HelpNotification.scss";

const HOTKEYS = [
  {
    value: "F1",
    // hotkey: Hotkeys.help.keyCode,
    label: "Open/hide menu",
  },
  {
    value: "ALT+SHIFT+(arrow top/bottom)",
    label: "Navigate on panels",
  },
  {
    value: "CTRL+SHIFT+F",
    hotkey: Hotkeys.focusSearch.keyCode,
    label: "Set focus on search field",
  },
  {
    value: "CTRL+ALT+T",
    hotkey: Hotkeys.toggleTree.keyCode,
    label: "Toggle tree",
  },
  {
    value: "Mouse Midle Click", 
    label: "Add a split from the tree",
  },
];

const hotkeyToLabel = (hotkey) => {
  let labelParts = [];
  if (hotkey.ctrl) {
    labelParts.push("CTRL");
  }

  if (hotkey.shift) {
    labelParts.push("SHIFT");
  }

  if (hotkey.alt) {
    labelParts.push("ALT");
  }
  labelParts.push(...hotkey.keys.map(item => String.fromCharCode(item)));

  return labelParts.join(" + ");
};

const HelpNotification = () => {
  const [showHelp, setShowHelp] = useState(false);
  const { isDevMode } = useDevMode();

  useEffect(() => {
    const onDocumentKeyDown = (event) => {
      if (!isDevMode) {
        return;
      }
      if (isEventKeys(event, [KeyCodes.f1])) {
        setShowHelp(!showHelp);
        event.preventDefault();
      } else if (showHelp) {
        setShowHelp(false);
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", onDocumentKeyDown);
    return () => {
      document.removeEventListener("keydown", onDocumentKeyDown);
    };
  }, [showHelp, isDevMode]);

  if (!showHelp) {
    return null;
  }

  return (
    <div className="help-back">
      <div className="help-wrap">
        <div className="help-title">Help</div>
        <div className="help-content">
          {HOTKEYS.map((item, index) => {
            return (
              <div className="help-item" key={index}>
                <span className="value">
                  {item.hotkey ? hotkeyToLabel(item.hotkey) : item.value}
                </span>
                {" - " + item.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HelpNotification;
