import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./Accordion.scss";

const Accordion = ({
  isCanBeToggled, children, title, className,
  headClassName, isChevronSecondary, bodyClassName,
  isOpenByDefault,
  isOpen: isOpenExternal,
  setIsOpen: setIsOpenExternal,
}) => {
  const [_isOpen, _setIsOpen] = useState(isOpenExternal ?? false);
  const isOpen = isOpenExternal ?? _isOpen;
  const setIsOpen = setIsOpenExternal ?? _setIsOpen;

  const toggleIsOpen = useCallback(() => {
    setIsOpen((expandedOld) => !expandedOld);
  }, [setIsOpen]);

  useEffect(() => {
    setIsOpen(isOpenByDefault);
  }, [isOpenByDefault, setIsOpen]);

  const elementChevron = isChevronSecondary ? (
    <span className={classNames({
      "expandItem": isCanBeToggled,
      "expandChevronRotate": isOpen,
    })} />
  ) : (
    <span className={classNames({
      "with-chevron": isCanBeToggled,
      "open": isOpen,
    })}/>
  );

  return (
    <div className={classNames("accordion", className, {
      "open": isOpen,
      "toggling-disabled": !isCanBeToggled,
    })}>
      <div
        className={classNames("accordion_head", headClassName)}
        onClick={isCanBeToggled ? toggleIsOpen : undefined}
      >
        {
          typeof title === "function" ? title(elementChevron) : (
            <div>
              {title}
              {elementChevron}
            </div>
          )
        }
      </div>
      <div className={classNames("accordion_body", bodyClassName)}>{isOpen && children}</div>
    </div>
  );
};

Accordion.defaultProps = {
  isCanBeToggled: true,
};

Accordion.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isOpen: PropTypes.bool,
  isOpenByDefault: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isCanBeToggled: PropTypes.bool,
  isChevronSecondary: PropTypes.bool,
  className: PropTypes.string,
  headClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
};

export default Accordion;
