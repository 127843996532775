import { getContrastTextColor } from "../../utils/ThemeUtils";
import { CONTENT_CLASSES } from "../../shared/utils/content";
import { ItemTypes } from "./studyCenter.constants";
import { defEditorHighLightColor } from "./EditorCenterUtils";

const rgbToHex = (r, g, b) => {
  const finalColor = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  return fixColor(finalColor);
};

const getAverage = (arr) => {
  var i,
    sum = 0;
  for (i = 0; i < arr.length; i++) {
    sum += parseInt(arr[i], 10);
  }
  return parseInt(sum / arr.length, 10);
};

const fixColor = (color) => {
  if (!color || color === "#aN" || color.length !== 7 || color.substr(0, 1) !== "#") {
    return "#000000";
  }
  return color;
};

const htmlToNodes = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.childNodes;
};

const replaceWithHTMl = (oldElement, html) => {
  const newNodes = htmlToNodes(html);
  oldElement.replaceWith(...newNodes);
};

export default class HighlightUtils {
  constructor(settings = {}) {
    this.settings = {
      wrappingElement: "span",
      wrapperElement: document.querySelector(".reader-page-wrap"),
      ignoreElements: ["path", "svg", "refCode", "bible-number"],
      dontHighlightElements: [
        ".highlight",
        "textarea",
        "input",
        `.${CONTENT_CLASSES.READER_HEADER_TEXT}`,
        "path",
        "h4",
        "h3",
        "h2",
        `.${CONTENT_CLASSES.PAGE_NUMBER}`
      ],
      textColor: "var(--text-default)",
      ...settings
    };
  }

  addHL(item) {
    this.createHighlight({
      hid: item.id,
      start_para: item.start_para,
      end_para: item.end_para,
      start_pos: item.start_pos,
      end_pos: item.end_pos,
      color: item.color,
      isActive: item.isActive 
    });
  }

  addMark(oElement) {
    //bookmark must be at start
    if (oElement.type === ItemTypes.bookmark) {
      const parentElement = this.settings.wrapperElement.querySelector(
        `[data-id='${oElement.start_para}']`
      );
      const { elm: anchor, offset } = this.findSpecificElement(
        parentElement,
        oElement.start_pos,
        "start"
      );
      if (!anchor) {
        return;
      }
      let text = anchor.textContent;
      let newOffset = offset;
      if (text[newOffset] !== " ") {
        newOffset = text.substr(0, offset).lastIndexOf(" ");
        if (newOffset === -1) {
          newOffset = 0;
        } else {
          newOffset++;
        }
      }

      const icon = `<i 
        class="sc-mark icon-bookmark ${CONTENT_CLASSES.SC_BOOKMARK}" 
        data-hid="${oElement.id}"
        id="${oElement.id}"
        data-tooltip="${(oElement.text || oElement.title)}"></i>`;
      text = text.substr(0, newOffset) + icon + text.substr(newOffset);
      replaceWithHTMl(anchor, text);
      //note must be at end
    } else if (oElement.type === ItemTypes.note) {
      const parentElement = this.settings.wrapperElement.querySelector(
        `[data-id='${oElement.end_para}']`
      );
      let { elm: anchor, offset } = this.findSpecificElement(
        parentElement,
        oElement.end_pos,
        "end"
      );
      if (!anchor) {
        // Some times note creates in special position out of bounds of paragraph
        let element = this.findSpecificElement(parentElement, oElement.start_pos, "end");
        if (element.elm) {
          anchor = element.elm;
          offset = element.offset;
        } else {
          return;
        }
      }
      let text = anchor.textContent;
      let newOffset = offset;
      if (text[newOffset] !== " ") {
        newOffset = text.indexOf(" ", offset - 1);
        if (newOffset === -1) {
          newOffset = text.length;
        }
      }
      const icon = `<i 
        class="sc-mark icon-note ${CONTENT_CLASSES.SC_NOTE}" 
        data-hid="${oElement.id}"
        id="${oElement.id}"
        data-tooltip="${(oElement.text || oElement.title)}"></i>`;

      text = text.substr(0, newOffset) + icon + text.substr(newOffset);
      replaceWithHTMl(anchor, text);
    }
  }

  findSpecificElement(elm, offset, arrow) {
    let returnElement = false,
      counter = 0,
      text;
    const queryDom = (parentElement) => {
      let children = parentElement.childNodes,
        n,
        m,
        query;
      for (n = 0; n < children.length; n++) {
        if (children[n].tagName !== undefined) {
          query = true;
          for (m = 0; m < this.settings.ignoreElements.length; m++) {
            if (children[n].matches(this.settings.ignoreElements[m])) {
              query = false;
              break;
            }
          }
          if (query) {
            queryDom(children[n]);
          }
        } else {
          text = children[n].textContent;
          text = text.replace(/\r+|\n+/g, "").replace(/\t+|( )+/g, " ");
          if (
            (arrow === "start" && counter + text.length > offset) ||
            (arrow === "end" && counter + text.length >= offset)
          ) {
            returnElement = {
              elm: children[n],
              offset: offset - counter
            };
          } else {
            counter += text.length;
          }
        }
        if (returnElement) {
          break;
        }
      }
    };

    if (elm) {
      queryDom(elm);
    }

    return returnElement;
  }

  mixColors() {
    this.settings.wrapperElement.querySelectorAll(".hh > .hh").forEach((element) => {
      let bgColor,
        elm = element;
      const red = [],
        green = [],
        blue = [];

      do {
        bgColor = elm.dataset.background_color;
        if (bgColor && bgColor.length == 7) {
          red.push(parseInt(bgColor.substr(1, 2), 16));
          green.push(parseInt(bgColor.substr(3, 2), 16));
          blue.push(parseInt(bgColor.substr(5, 2), 16));
        }
        if (!elm.parentElement.classList.contains("hh")) {
          break;
        }
        elm = elm.parentElement;
      } while (elm);
      const mixedColor = rgbToHex(getAverage(red), getAverage(green), getAverage(blue));

      element.dataset.mixed_color = mixedColor;
      element.style.backgroundColor = mixedColor;
      element.style.color = getContrastTextColor(mixedColor);
    });
  }

  removeHighlight(hid) {
    const { wrapperElement, wrappingElement } = this.settings;
    wrapperElement.querySelectorAll(wrappingElement + `.hh[data-hid="${hid}"]`).forEach((item) => {
      const childNode = item.childNodes[0];
      let elParentNode = childNode.parentNode;
      while (elParentNode.firstChild) {
        elParentNode.before(elParentNode.firstChild);
      }
      elParentNode.remove();
    });
    this.mixColors();
  }

  createHighlight(oEntry) {
    const that = this;
    let highlightStore = [],
      highlight,
      elementIsAllowed,
      findNextElement,
      findNextAllowedElement,
      startElement,
      startSpecificElement,
      endElement,
      endSpecificElement,
      highlightElement;

    const hlColor = fixColor(oEntry.color);
    const isBlack = hlColor.indexOf("#000000") != -1;
    const wrapper = this.settings.wrappingElement;
    this.addToHighlight = true;
    highlight = {
      commit: function () {
        let attributes = [];
        for (let n = 0; n < highlightStore.length; n++) {
          attributes = [
            `class="hh${oEntry.isActive ? " active" : ""}"`,
            `data-start_pos='${oEntry.start_pos}'`,
            `data-start_para="${oEntry.start_para}"`,
            `data-end_pos=${oEntry.end_pos}`,
            `data-end_para="${oEntry.end_para}"`,
            `data-hid="${oEntry.hid}"`
          ];

          if (isBlack) {
            const textColor = that.settings.textColor;
            attributes.push(`style="text-decoration:underline; color: ${textColor};"`);
          } else {
            attributes.push(
              ` style="background-color: ${hlColor}; color: ${getContrastTextColor(hlColor)};"`
            );
          }

          attributes.push(`data-background_color="${isBlack === true ? "transparent" : hlColor}"`);

          if (oEntry.attributes !== undefined) {
            for (let key in oEntry.attributes) {
              attributes.push(`${key}="${oEntry.attributes[key]}"`);
            }
          }

          let markup = highlightStore[n].element.textContent
            .replace(/\r+|\n+/g, "")
            .replace(/\t+|( )+/g, " ");
          if (highlightStore[n].endOffset) {
            markup =
              markup.substring(0, highlightStore[n].endOffset) +
              "</" +
              wrapper +
              ">" +
              markup.substring(highlightStore[n].endOffset);
          } else {
            markup += "</" + wrapper + ">";
          }
          const startWrap = "<" + wrapper + " " + attributes.join(" ") + ">";
          if (highlightStore[n].startOffset) {
            markup =
              markup.substring(0, highlightStore[n].startOffset) +
              startWrap +
              markup.substring(highlightStore[n].startOffset);
          } else {
            markup = startWrap + markup;
          }
          replaceWithHTMl(highlightStore[n].element, markup);
        }
        that.mixColors();
      },
      store: function (u) {
        if (u.element && u.element.nodeType === 3 && that.addToHighlight) {
          highlightStore.push(u);
        }
      }
    };
    elementIsAllowed = function (elm) {
      if (!elm || !elm.matches) {
        return true;
      }

      for (let m = 0; m < that.settings.dontHighlightElements.length; m++) {
        if (elm.matches(that.settings.dontHighlightElements[m])) {
          return false;
        }
      }
      return true;
    };
    findNextElement = function (e, allowDown) {
      var nextElm, parent;
      try {
        if (allowDown === undefined) {
          allowDown = true;
        }

        const children = e.childNodes;
        if (children.length > 0 && allowDown) {
          nextElm = children[0];
        } else if (e.nextSibling) {
          nextElm = e.nextSibling;
        } else {
          parent = e.parentElement;

          if (parent === that.settings.wrapperElement) {
            return false;
          }
          while (!parent.nextSibling) {
            parent = parent.parentElement;
          }
          nextElm = parent.nextSibling;
        }
      } catch (e) {
        console.log("hl.js error: " + e);
      }
      return nextElm;
    };
    findNextAllowedElement = function (e) {
      let elm = findNextElement(e, false);
      while (!elementIsAllowed(elm)) {
        elm = findNextElement(elm, false);
      }
      return elm;
    };

    startElement = this.settings.wrapperElement.querySelector(`[data-id='${oEntry.start_para}']`);
    startSpecificElement = this.findSpecificElement(startElement, oEntry.start_pos, "start");
    endElement = this.settings.wrapperElement.querySelector(`[data-id='${oEntry.end_para}']`);

    //TODO WORKAROUND BUT WORK
    let endOffset =
      endElement && oEntry.end_pos > endElement.textContent.length
        ? endElement.textContent.length
        : oEntry.end_pos;
    endSpecificElement = this.findSpecificElement(endElement, endOffset, "end");

    if (startElement && endElement) {
      if (startSpecificElement.elm === endSpecificElement.elm) {
        highlight.store({
          element: startSpecificElement.elm,
          startOffset: startSpecificElement.offset,
          endOffset: endSpecificElement.offset
        });
      } else {
        highlightElement = {
          element: startSpecificElement.elm,
          startOffset: startSpecificElement.offset,
          endOffset: false
        };
        while (highlightElement.element !== endSpecificElement.elm) {
          if (this.addToHighlight && !elementIsAllowed(highlightElement.element)) {
            this.addToHighlight = false;
            this.nextAllowedElement = findNextAllowedElement(highlightElement.element);
          }
          highlight.store(highlightElement);
          highlightElement = {
            element: findNextElement(highlightElement.element),
            startOffset: false,
            endOffset: false
          };
          if (!this.addToHighlight && highlightElement.element === this.nextAllowedElement) {
            this.addToHighlight = true;
          }
          if (!highlightElement.element) {
            break;
          }
        }
        if (highlightElement.element) {
          highlight.store({
            element: endSpecificElement.elm,
            startOffset: false,
            endOffset: endSpecificElement.offset
          });
        }
      }
      highlight.commit();
    }
  }
}
