import { useCallback, useEffect, useRef, useState } from "react";
import { SCROLL_DIRECTIONS } from "../components/reader/constants";
import { getFirstVisible } from "../utils/DOMUtils";
import { isDeltaEquals } from "../utils/NumberUtils";

const roundInaccuracy = 1;

// For now, supports with {Scrollbars} only.
const useScrollArrows = (scrollRef, isHorizontal) => {
  const [showArrowOne, setShowArrowOne] = useState(false);
  const [showArrowTwo, setShowArrowTwo] = useState(false);
  const isScrollable = showArrowOne || showArrowTwo;
  const isScrollableRef = useRef(isScrollable);

  useEffect(() => {
    isScrollableRef.current = isScrollable;
  }, [isScrollable]);

  const getScrollableView = useCallback(() => {
    return scrollRef?.container?.querySelector(".scrollbars-render-view");
  }, [scrollRef]);

  const getWrapStart = useCallback(() => {
    const scrollableView = getScrollableView();

    if (scrollableView) {
      const keyOffset = isHorizontal ? "scrollLeft" : "scrollTop";
      return scrollableView[keyOffset];
    }

    return undefined;
  }, [getScrollableView, isHorizontal]);

  const getWrapSize = useCallback(() => {
    const scrollableView = getScrollableView();

    if (scrollableView) {
      const keySize = isHorizontal ? "offsetWidth" : "offsetHeight";
      return scrollableView[keySize];
    }

    return undefined;
  }, [getScrollableView, isHorizontal]);

  const getWrapScrollSize = useCallback(() => {
    const scrollableView = getScrollableView();

    if (scrollableView) {
      const keySize = isHorizontal ? "scrollWidth" : "scrollHeight";
      return scrollableView[keySize];
    }

    return undefined;
  }, [getScrollableView, isHorizontal]);

  const getScrollTo = useCallback((isNext) => {
    const keySize = isHorizontal ? "offsetWidth" : "offsetHeight";
    const keyOffsetElem = isHorizontal ? "offsetLeft" : "offsetTop";

    const wrapStart = getWrapStart();
    const wrapEnd = wrapStart + getWrapSize();

    const scrollableView = getScrollableView();

    if (!scrollableView) {
      return undefined;
    }

    let scrollTarget = getFirstVisible(scrollableView, isNext, isHorizontal);

    if (!scrollTarget) {
      return undefined;
    }

    let scrollTargetStart = scrollTarget[keyOffsetElem];
    let scrollTargetEnd = scrollTargetStart + scrollTarget[keySize];

    // Skip scroll target if first visible element is exactly at the edge of the scroll view.
    if (isNext) {
      if (isDeltaEquals(scrollTargetEnd, wrapEnd, roundInaccuracy)) {
        scrollTarget = scrollTarget.nextSibling || scrollTarget;
        scrollTargetStart = scrollTarget[keyOffsetElem];
        scrollTargetEnd = scrollTargetStart + scrollTarget[keySize];
      }
    } else {
      if (isDeltaEquals(scrollTargetStart, wrapStart, roundInaccuracy)) {
        scrollTarget = scrollTarget.previousSibling || scrollTarget;
        scrollTargetStart = scrollTarget[keyOffsetElem];
        scrollTargetEnd = scrollTargetStart + scrollTarget[keySize];
      }
    }
    // ====================================

    return isNext
      ? (wrapStart + (scrollTargetEnd - wrapEnd))
      : (wrapStart - (wrapStart - scrollTargetStart));
  }, [getScrollableView, getWrapStart, getWrapSize, isHorizontal]);

  const validateArrows = useCallback(() => {
    const wrapStart = getWrapStart();
    const wrapSize = getWrapSize();
    const wrapScrollSize = getWrapScrollSize();

    if (wrapStart === undefined || wrapSize === undefined || wrapScrollSize === undefined) {
      setShowArrowOne(false);
      setShowArrowTwo(false);
    }

    setShowArrowOne(wrapStart > roundInaccuracy);
    setShowArrowTwo(wrapScrollSize - wrapSize - wrapStart > roundInaccuracy);
  }, [getWrapStart, getWrapSize, getWrapScrollSize]);

  const scrollTo = useCallback((value) => {
    const scrollableView = getScrollableView();

    if (scrollableView) {
      const scrollOptions = { behavior: "smooth" };
      if (isHorizontal) {
        scrollOptions.left = value;
      } else {
        scrollOptions.top = value;
      }
      scrollableView.scroll(scrollOptions);
    }
  }, [isHorizontal, getScrollableView]);

  const handleArrowClick = useCallback((e) => {
    const id = e.target.id;

    scrollTo(
      getScrollTo(id === SCROLL_DIRECTIONS.RIGHT || id === SCROLL_DIRECTIONS.DOWN)
    );
  }, [scrollTo, getScrollTo]);

  const resetArrows = useCallback(() => {
    scrollTo(0);
    validateArrows();
  }, [scrollTo, validateArrows]);

  return {
    isScrollable, showArrowOne, showArrowTwo,
    handleArrowClick,
    resetArrows, validateArrows,
  };
};

export default useScrollArrows;
