import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import { BookCircle } from "../../assets/CommonIcons";
import BookDesc from "./BookDesc";
import { useGridDimensions, useViewMode } from "../../hooks";
import sassVariables from "../../styles/variables.module.scss";
import { LongPressWrapper } from "../index";
import { KeyCodes } from "../../shared/utils/dom";
import BookCoverView from "src/shared/components/BookCoverView";
import { BookInteractTypes } from "./BookList.utils";
import { ButtonDownloadBook } from "./BookDownloadLinksView";
import { mapBtnInteractTypeToFormat } from "./BookItemImage.utils";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { useWhatInputIsLastPointerMouse } from "../../what-input/useWhatInput";

import "./BookItemImage.scss";

const CLASSNAME_HOVER_WRAPPER = "hover-wrapper";

const descParamsDefault = null;
const calculateDescriptionParams = ({
  gridPosition, remToPx, imageWidth, showBookshelf,
  GRID_BOOKSHELF_HEIGHT, GRID_OFFSET, GRID_HEIGHT,
}, isMobileOrTablet, textMode, isBookDescTranslationProgress) => {
  if (gridPosition) {
    const WIDTH_NOT_COMPACT_IN_ITEMS = 2;
    const { columnCount, columnIndex, rowCount, rowIndex, itemWidth, listWidth } = gridPosition;
    const thisColumnNumber = columnIndex + 1;
    const thisRowNumber = rowIndex + 1;
    const thisColumnIsNotTheFirst = thisColumnNumber > 1;
    const thisColumnIsNotTheLast = thisColumnNumber < columnCount;
    const isShownOnTop = rowCount > 1 && thisRowNumber === rowCount;

    let hasCompactView = false;
    let isShownOnTheLeftSide = false;
    let isShownOnBothSides = false;
    let canContainExpandedView;

    if (columnCount > WIDTH_NOT_COMPACT_IN_ITEMS) {
      // don't need both sides desc block for translation progress, there is no style yet.
      if (isMobileOrTablet && !isBookDescTranslationProgress) {
        if (thisColumnIsNotTheFirst && thisColumnIsNotTheLast) {
          isShownOnBothSides = true;
        } else if (!thisColumnIsNotTheLast) {
          isShownOnTheLeftSide = true;
        }
      } else if (thisColumnNumber > columnCount - WIDTH_NOT_COMPACT_IN_ITEMS) {
        isShownOnTheLeftSide = true;
      }

      if (isShownOnTheLeftSide) {
        canContainExpandedView = thisColumnNumber > WIDTH_NOT_COMPACT_IN_ITEMS;
      } else {
        canContainExpandedView = thisColumnNumber <= columnCount - WIDTH_NOT_COMPACT_IN_ITEMS;
      }
    } else {
      canContainExpandedView = false;
      isShownOnTheLeftSide = thisColumnIsNotTheFirst;
    }

    if (!canContainExpandedView && !isShownOnBothSides) {
      hasCompactView = true;
    }

    let widthInItems = hasCompactView ? 1 : WIDTH_NOT_COMPACT_IN_ITEMS;

    let style = {},
      sideWidth,
      paddingLeft,
      paddingRight;

    if (isShownOnTop && showBookshelf) {
      if (!isShownOnBothSides) {
        style.top = -(GRID_BOOKSHELF_HEIGHT - GRID_HEIGHT);
      } else {
        if (textMode) {
          style.top = -(GRID_BOOKSHELF_HEIGHT - GRID_HEIGHT);
        } else {
          style.top = -remToPx(0.8);
        }
      }
    }

    if (isShownOnBothSides) {
      const width = itemWidth * widthInItems + imageWidth;
      style.width = width;
      style.left = -itemWidth;
      const sidePaddings = remToPx(0.2) * 2;
      sideWidth = imageWidth - sidePaddings;
    } else if (isShownOnTheLeftSide) {
      style.width = itemWidth * widthInItems + (showBookshelf ? imageWidth : 0);
      style.left = -(itemWidth * widthInItems);
      paddingRight = showBookshelf ? itemWidth : undefined;
    } else {
      style.width = itemWidth * widthInItems + (showBookshelf ? imageWidth : 0);
      style.left = showBookshelf ? 0 : GRID_OFFSET;
      paddingLeft = showBookshelf ? itemWidth : undefined;
    }

    if (style.width > listWidth) {
      style.width = listWidth;
    }

    return {
      style,
      sideWidth,
      paddingLeft,
      paddingRight,
      hasCompactView,
      isShownOnBothSides,
      isShownOnTheLeftSide,
      isShownOnTop,
    };
  }

  return descParamsDefault;
};

const BookItemImage = ({
  isShownFormatIcon, isShowDescriptionCover, isBig, isGrid,
  book, mark, gridPosition,
  btnInteractType, showBookshelf,
  imageClassName = "", bookCoverClassName,
  renderBookRow, onClick,
  withTranslation
}) => {
  const { t } = useTranslation();
  const innerRef = useRef();
  const remToPx = useRemToPx();
  const { isMobileOrTablet, textMode, zoom } = useViewMode();
  const { GRID_OFFSET, GRID_BOOKSHELF_HEIGHT, GRID_HEIGHT } = useGridDimensions();
  const [hovered, setHovered] = useState(false);
  const [descParams, setDescParams] = useState(descParamsDefault);

  const descriptionCanBeShown = isGrid && !isBig && book && isShowDescriptionCover;
  const isLastPointerMouse = useWhatInputIsLastPointerMouse();
  const isBookDescTranslationProgress = btnInteractType === BookInteractTypes.translate;

  const imageWidth = isMobileOrTablet
    ? remToPx(sassVariables.bookListItemWidthMobileTablet)
    : remToPx(sassVariables.bookListItemWidthDesktop);

  const changeDescriptionParamsVisibility = useCallback(() => {
    setDescParams(
      calculateDescriptionParams({
        gridPosition, remToPx, imageWidth, showBookshelf,
        GRID_BOOKSHELF_HEIGHT, GRID_OFFSET, GRID_HEIGHT,
      }, isMobileOrTablet, textMode, isBookDescTranslationProgress)
    );
  }, [gridPosition, remToPx, imageWidth, showBookshelf,
    GRID_BOOKSHELF_HEIGHT, GRID_OFFSET, GRID_HEIGHT,
    isMobileOrTablet, textMode, isBookDescTranslationProgress]);

  useEffect(() => {
    let timer;

    if (descriptionCanBeShown && hovered) {
      timer = setTimeout(() => {
        changeDescriptionParamsVisibility();
      }, 450);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [descriptionCanBeShown, hovered, changeDescriptionParamsVisibility]);

  // Re-calc on {zoom} change.
  useEffect(() => {
    if (descParams) {
      changeDescriptionParamsVisibility();
    }
    // eslint-disable-next-line
  }, [zoom, changeDescriptionParamsVisibility]); // missing dependency intentionally

  const hideDesc = useCallback(() => {
    setDescParams(null);
    setHovered(descParamsDefault);
  }, []);

  const hideDescMobile = useCallback(() => {
    setTimeout(() => {
      if (document.activeElement !== innerRef.current) {
        hideDesc();
      }
    }, 500);
  }, [hideDesc]);

  const handleOpenBookDescription = useCallback(() => {
    if (!descriptionCanBeShown) {
      return;
    }

    setHovered(true);
  }, [descriptionCanBeShown]);

  const handleLongPress = useCallback(() => {
    handleOpenBookDescription();

    if (innerRef.current) {
      innerRef.current.focus();
    }
  }, [handleOpenBookDescription]);

  const handleBookCoverClick = useCallback(() => {
    if (!showBookshelf) {
      hideDesc();
    }
    if (onClick) {
      onClick();
    }
  }, [showBookshelf, hideDesc, onClick]);

  const handleBookImageWrapKeyPress = useCallback((e) => {
    if (Number(e.key) === KeyCodes.enter) {
      handleBookCoverClick();
    }
  }, [handleBookCoverClick]);

  const isDescParamsShown = !!descParams;
  const handleClickInfoIcon = useCallback((e) => {
    if (isDescParamsShown) {
      hideDesc();
    } else {
      const parent = e.target.closest("." + CLASSNAME_HOVER_WRAPPER);
      if (parent) {
        changeDescriptionParamsVisibility();
      }
    }
  }, [isDescParamsShown, changeDescriptionParamsVisibility, hideDesc]);

  const renderImage = () => {
    if (!book) {
      return null;
    }

    return (
      <BookCoverView
        book={book}
        onClick={handleBookCoverClick}
        className={bookCoverClassName}
        imageClassName={imageClassName}
      />
    );
  };

  const renderContent = () => {
    let classNameColor = "book-image-circle-blue";
    let bookCenterTitle = t("readStory");

    if (btnInteractType === BookInteractTypes.open) {
      bookCenterTitle = t("open");
    } if (btnInteractType === BookInteractTypes.translate) {
      bookCenterTitle = t("start");
      classNameColor = "book-image-circle-green";
    } else if (btnInteractType === BookInteractTypes.read) {
      bookCenterTitle = t("readStory");
    } else if (btnInteractType === BookInteractTypes.listen) {
      bookCenterTitle = t("playStory");
      classNameColor = "book-image-circle-orange";
    } else if (btnInteractType === BookInteractTypes.buy) {
      bookCenterTitle = t("buyBook");
      classNameColor = "book-image-circle-red";
    }

    return (
      <React.Fragment>
        <div
          onClick={handleBookCoverClick}
          className={classNames("book-image-tap-wrap", classNameColor,
            isGrid ? "book-image-tap-btn" : "book-image-tap-btn-small",
          )}>
          <span className={classNames("book-center-title")}>
            {bookCenterTitle}
          </span>
          <BookCircle />
        </div>

        {mark && (
          <span
            className={classNames("book-mark bm-date",
              isGrid ? "bm-vertical" : "bm-horizontal",
            )}
          >{mark}</span>
        )}

        {descriptionCanBeShown && (
          <i className="icon-info-circle bi-info-background" onClick={handleClickInfoIcon} />
        )}
        {withTranslation && (
          <i className="icon-translation bi-translate-background" onClick={handleClickInfoIcon} />
        )}
        {
          isShownFormatIcon && (
            <ButtonDownloadBook
              className="book-item-image_mark-btn-download"
              book={book}
              format={mapBtnInteractTypeToFormat[btnInteractType]}
            />
          )
        }
        {renderImage()}
      </React.Fragment>
    );
  };
  // TODO the LongPressWrapper is probably required for mobiles only
  return (
    <LongPressWrapper
      onLongPress={(descriptionCanBeShown && isLastPointerMouse) ? undefined : handleLongPress}
      onContextMenu={(descriptionCanBeShown && isLastPointerMouse) ? undefined : handleLongPress}
      timeout={500}>
      <div
        ref={innerRef}
        tabIndex={0}
        className={classNames("book-image-wrap", {
          enlarged: descParams,
        })}
        onMouseEnter={isLastPointerMouse ? handleOpenBookDescription : undefined}
        onMouseLeave={(descriptionCanBeShown && isLastPointerMouse) ? hideDesc : undefined}
        onBlur={(descriptionCanBeShown && isLastPointerMouse) ? undefined : hideDescMobile}
        onKeyPress={handleBookImageWrapKeyPress}>
        {descriptionCanBeShown ? (
          <div className="hover-container">
            {descParams && (
              <BookDesc
                bookInfo={book}
                isViewTranslationProgress={isBookDescTranslationProgress}
                descParams={descParams}
                renderBookRow={renderBookRow}
                showBookshelf={showBookshelf}
              />
            )}
            <div className={CLASSNAME_HOVER_WRAPPER}>{renderContent()}</div>
          </div>
        ) : (
          renderContent()
        )}
      </div>
    </LongPressWrapper>
  );
};

BookItemImage.defaultProps = {
  isShowDescriptionCover: true,
};

BookItemImage.propTypes = {
  book: PropTypes.object,
  isShownFormatIcon: PropTypes.bool,
  isGrid: PropTypes.bool,
  imageClassName: PropTypes.string,
  bookCoverClassName: PropTypes.string,
  onClick: PropTypes.func,
  isBig: PropTypes.bool,
  mark: PropTypes.string,
  gridPosition: PropTypes.object,
  isShowDescriptionCover: PropTypes.bool,
  btnInteractType: PropTypes.oneOf(Object.values(BookInteractTypes)).isRequired,
  renderBookRow: PropTypes.func,
  showBookshelf: PropTypes.bool,
  withTranslation: PropTypes.bool,
};

export default BookItemImage;
