import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { IconButton } from "./index";
import EgwWebFont from "src/assets/EgwWebFont";

const SearchField = ({
  isIconClearVisible,
  isIconSearchVisible,
  isIconSearchEnabled,
  placeholder,
  value,
  onSearch,
  onClear,
  onInputFocus,
  onInputClick,
  onInputBlur,
  onChange,
  className,
  isWithDebounce,
}) => {
  const [search, setSearch] = useState(value || "");
  const { t } = useTranslation();

  useEffect(() => {
    if (value !== search) {
      setSearch(value || "");
    }
  }, [value]);

  useEffect(() => {
    let timeout;

    if (isWithDebounce) {
      timeout = setTimeout(() => {
        onChange(search);
      }, 500);
    } else {
      onChange(search);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [search, isWithDebounce, onChange]);

  const handleSearch = (event) => {
    if (search.length && onSearch) {
      onSearch(search);
    }
    event.preventDefault();
  };

  const handleClear = (event) => {
    setSearch("");
    if (onClear) {
      onClear(event);
    }
  };

  return (
    <div className={className || ""}>
      <form role="search" className="search-wrap-base" onSubmit={handleSearch}>
        <input
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          className="searchField"
          onFocus={(e) => {
            e.target.select();
            if (onInputFocus) {
              onInputFocus();
            }
          }}
          onClick={(e) => {
            if (onInputClick) {
              onInputClick(e);
            }
          }}
          onBlur={() => {
            if (onInputBlur) {
              onInputBlur();
            }
          }}
          type="search"
          value={search}
          placeholder={placeholder}
        />
        <span className="search-button-wrap">
          {isIconClearVisible && (
            <IconButton
              icon={EgwWebFont.close}
              className={classNames(
                "searchButton",
                "search-icon",
                search ? "showView" : "hideView",
              )}
              title={t("clear")}
              onClick={handleClear}
            />
          )}
          {isIconSearchVisible && (
            <IconButton
              icon={EgwWebFont.search}
              className={classNames(
                "search-show-search-icon",
                "search-icon",
                isIconSearchEnabled ? "searchButton" : "",
              )}
              title={t("search")}
              onClick={(event) => {
                if (isIconSearchEnabled) {
                  handleSearch(event);
                }
              }}
            />
          )}
        </span>
      </form>
    </div>
  );
};

SearchField.defaultProps = {
  isIconClearVisible: true,
  isIconSearchVisible: true,
  isIconSearchEnabled: true,
  onChange: () => {},
};

SearchField.propTypes = {
  isWithDebounce: PropTypes.bool,
  isIconClearVisible: PropTypes.bool,
  isIconSearchVisible: PropTypes.bool,
  isIconSearchEnabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputClick: PropTypes.func,
  onInputBlur: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default SearchField;
