import { SubsPreffix } from "src/shared/utils/content";
import { TreeItemStateChecked } from "src/utils/TreeUtils";
import { CheckHalfIcon, CheckNoIcon, CheckYesIcon } from "src/assets/CommonIcons";

/**
 * //check for correct target is not /loading
 * @param {*} id
 * @returns boolean
 */
export const isCorrectTargetId = (id) => {
  if (!id) {
    return false;
  }
  if (Number.isInteger(id)) {
    return true;
  }
  if (typeof id === "string" && id.indexOf("/") !== 0 && id.indexOf(SubsPreffix) !== 0) {
    return true;
  }
  return false;
};


export const CheckIcons = {
  [TreeItemStateChecked.all]: CheckYesIcon,
  [TreeItemStateChecked.part]: CheckHalfIcon,
};

export const getCheckIcon = (checkType) => {
  let CheckIcon = CheckIcons[checkType];
  if (!CheckIcon) {
    CheckIcon = CheckNoIcon;
  }
  return CheckIcon;
};
