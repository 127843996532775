export const APIUrls = {
  getToken: "/api/getToken",
  status: "/api/status",
  authCallback: "/api/authCallback",
  fbCallback: "/api/callbackFB",
  getInstagramData: "/api/getInstagramData",
  oidcCallback: "/api/oidc/auth/cb",
  robots: "/robots.txt",
};

export const getParamsNames = (params) => {
  return Object.values(params).map(({ name }) => {
    return name;
  });
};

export const filterRequestParamsByParams = (reqParams, params) => {
  const out = {};
  const paramsNames = getParamsNames(params);

  Object.keys(reqParams).forEach((key) => {
    if (paramsNames.includes(key)) {
      out[key] = reqParams[key];
    }
  });

  return out;
};

export const reqParamsToString = (params) => {
  return Object.entries(params).reduce((acc, [name, value], index) => {
    if (value !== "" && value !== undefined) {
      const prefix = index === 0 ? "?" : "&";
      // "encodeURI" is needed to encode cyrillic letters for the Search API (e.g. "?query=бог").
      acc += prefix + name + "=" + encodeURIComponent(value);
    }

    return acc;
  }, "");
};

export const parseAcceptLanguageHeader = (str) => {
  if (!str) {
    return undefined;
  }
  const [langStr] = str.match(/((([a-zA-Z]+(-[a-zA-Z0-9]+){0,2})|\*)(;q=[0-1](\.[0-9]+)?)?)*/g);
  const [langShort] = langStr.split("-");
  return langShort;
};

export const searchStrToReqParams = (str) => {
  if (!str) {
    return {};
  }
  return str
    .replace("?", "")
    .split("&")
    .reduce(
      (acc, pair) => {
        const [name, value] = pair.split("=");

        if (name !== undefined && value !== undefined) {
          // Prevents "?=undefined".
          acc[name] = decodeURIComponent(value);
        }

        return acc;
      },
      {},
    );
};

export const decodeSearchStr = (str) => {
  return window.decodeURIComponent(window.decodeURI(str.replace(/\+/g, " ")));
};

export const parseProtocol = (req, host) => {
  if (
    req &&
    req.headers["x-forwarded-proto"] &&
    typeof req.headers["x-forwarded-proto"] === "string"
  ) {
    return `${req.headers["x-forwarded-proto"]}:`;
  }

  return /^localhost(:\d+)?$/.test(host) ? "http:" : "https:";
};

export const parseHost = (req) => {
  if (req) {
    if (req.headers.host) {
      return req.headers.host;
    }

    if (req.headers["x-forwarded-host"] && typeof req.headers["x-forwarded-host"] === "string") {
      return req.headers["x-forwarded-host"];
    }
  }

  return null;
};

export const initMiddleware = (middleware) => {
  return (req, res) =>
    new Promise((resolve, reject) => {
      middleware(req, res, (result) => {
        if (result instanceof Error) {
          return reject(result);
        }
        return resolve(result);
      });
    });
};

// TODO add params from env
const whiteList = ["localhost", "egwwritings.org"];

export const getCorsConfig = () => {
  return {
    methods: ["GET"],
    origin: (origin, callback) => {
      //TODO implement real check for cors and allows domains form `whiteList`
      console.log(`CORS origin`, origin, "whiteList", whiteList);
      callback(null, true);
    },
  };
};

/**
 *
 * @returns generated content for inject process.env parameters to window.env
 */
export const getWindowEnv = () => {
  return `window.env = {
      REACT_APP_RESOURCE_URL: "${process.env.REACT_APP_RESOURCE_URL}",
      REACT_APP_SERVER_URL: "${process.env.REACT_APP_SERVER_URL}",
      REACT_APP_CLIENT_ID_AUTHORIZED: "${process.env.REACT_APP_CLIENT_ID_AUTHORIZED}",
      REACT_APP_CPANEL: "${process.env.REACT_APP_CPANEL}",
      REACT_APP_TEXT_APP_HOST: "${process.env.REACT_APP_TEXT_APP_HOST}",
      REACT_APP_NEXT_APP_HOST: "${process.env.REACT_APP_NEXT_APP_HOST}",
      REACT_APP_BASE_HOST: "${process.env.REACT_APP_BASE_HOST}",
      REACT_APP_SEARCH_LOGS_URL: "${process.env.REACT_APP_SEARCH_LOGS_URL}",
      REACT_APP_SEARCH_LOGS_TOKEN: "${process.env.REACT_APP_SEARCH_LOGS_TOKEN}",
      REACT_APP_ENVIRONMENT: "${process.env.REACT_APP_ENVIRONMENT}",
    }`;
};
