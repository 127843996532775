import { useCallback } from "react";

import { cartActions } from "../redux/cart/actions";
import { actionAddMessage } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "./index";

const useBuyBookPackage = () => {
  const dispatch = useDispatch();
  const { isLogin } = useAuth();
  const shopPackages = useSelector((state) => state.shop.shopPackages);

  return useCallback((packageId) => {
    if (isLogin) {
      const shopPackage = shopPackages[packageId];
      if (shopPackage) {
        dispatch(cartActions.addToCart(shopPackage));
        dispatch(actionAddMessage("@addedToCart"));
      }
    } else {
      dispatch(actionAddMessage("@pleaseLogin"));
    }
  }, [isLogin, shopPackages]);
};

export default useBuyBookPackage;
