import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { BookInteractTypes } from "./BookList.utils";
import { makeNavigationUrl } from "../../utils/URLUtils";
import { makeResourceUrl } from "../../shared/utils/url";
import { useAudioContext } from "../bookPlayer/AudioContext";
import useDialogHelpTranslate from "./translate/useDialogHelpTranslate";
import { CONTENT_CLASSES, getBookId } from "../../shared/utils/content";
import useBuyBook from "../../hooks/useBuyBook";
import { useNavigationContext } from "../NavigationContext";

export const useHandleBookInteraction = () => {
  const history = useHistory();
  const { initPlayIfNotTheSameBook } = useAudioContext();
  const { openId } = useNavigationContext();
  const handleBookInteractionTranslate = useDialogHelpTranslate();
  const handleBuyBook = useBuyBook();

  const handleBookInteractionListenOrRead = useCallback((
    book, bookHistory, isInteractionTypeListen, withHistory
  ) => {
    if (isInteractionTypeListen) {
      // TODO: Simplify.
      let urlParam = { book };

      if (bookHistory && withHistory) {
        if (bookHistory.luListen) {
          const chapter = bookHistory.listenChapter;
          const position = bookHistory.position;
          urlParam = {
            className: CONTENT_CLASSES.CHAPTER,
            id: `${book.book_id}.${chapter || "1"}`,
            position,
          };
        } else {
          urlParam = {
            className: CONTENT_CLASSES.CHAPTER,
            id: book.first_para || `${book.book_id}.1`,
          };
        }
      } else {
        if (book.chapters) {
          const firstChapter = book.chapters.find((item) => !!item.mp3);
          if (firstChapter) {
            urlParam = { className: CONTENT_CLASSES.CHAPTER, id: firstChapter.id };
          }
        } else {
          const firstChapterId = book.first_para || book.children?.[0]?.id;
          const [paraFirst] = firstChapterId.split(".");
          urlParam = { className: CONTENT_CLASSES.CHAPTER, id: `${paraFirst}.1`, position: 0 };
        }
      }

      history.push(makeNavigationUrl(urlParam, { isAudio: true }));
      initPlayIfNotTheSameBook(urlParam.id, urlParam.position);
    } else {
      const urlParam = {
        className: CONTENT_CLASSES.CHAPTER,
        id: book.first_para || book.children?.[0]?.id,
      };

      if (withHistory && bookHistory?.paraId) {
        urlParam.id = bookHistory.paraId;
      }

      openId(urlParam.id, {
        className: CONTENT_CLASSES.CHAPTER,
        newWindow: true,
      });
    }
  }, [openId, history, initPlayIfNotTheSameBook]);

  const handleBookInteractionEpubOrPdf = useCallback((book, isInteractionTypeEpub) => {
    history.push(makeNavigationUrl({
      ...book,
      className: isInteractionTypeEpub ? BookInteractTypes.epub : BookInteractTypes.pdf,
      // possibly need to add another properties if no {first_para}
      chapterId: book.first_para,
    }));
  }, [history]);

  const handleBookInteractionMobi = useCallback((book) => {
    window.open(makeResourceUrl(book.files?.mobi), "_blank");
  }, []);

  const handleBookInteractionBookDetails = useCallback((book) => {
    history.push(makeNavigationUrl(book));
  }, [history]);

  const handleBookInteraction = useCallback((book, interactionType, {
    withHistory, // for "read", "listen" only
    bookHistory, // for "read", "listen" only
    isOpenBookDetailsOnBuy, // for "buy" only
  } = {}) => {
    switch (interactionType) {
      case BookInteractTypes.translate: {
        //FIXME maybe incorect logic
        handleBookInteractionTranslate(book.first_para);
        break;
      }
      case BookInteractTypes.buy: {
        if (isOpenBookDetailsOnBuy) {
          handleBookInteractionBookDetails(book);
        } else {
          handleBuyBook(book.id);
        }
        break;
      }
      case BookInteractTypes.mobi: {
        handleBookInteractionMobi(book);
        break;
      }
      case BookInteractTypes.listen:
      case BookInteractTypes.read: {
        handleBookInteractionListenOrRead(
          book,
          bookHistory,
          interactionType === BookInteractTypes.listen,
          withHistory,
        );
        break;
      }
      case BookInteractTypes.epub:
      case BookInteractTypes.pdf: {
        handleBookInteractionEpubOrPdf(book, interactionType === BookInteractTypes.epub);
        break;
      }
    }
  }, [
    handleBookInteractionTranslate, handleBuyBook, handleBookInteractionMobi,
    handleBookInteractionListenOrRead, handleBookInteractionEpubOrPdf,
    handleBookInteractionBookDetails,
  ]);

  return {
    handleBookInteraction,
    handleBookInteractionBuy: handleBuyBook,
    handleBookInteractionMobi,
    handleBookInteractionEpubOrPdf,
    handleBookInteractionTranslate,
    handleBookInteractionBookDetails,
  };
};
