export const normalizeData = (filteredData) => {
  const multiplier = Math.pow(Math.max(...filteredData), -1);
  return filteredData.map((n) => Math.abs(n) * multiplier);
};

export const draw = (normalizedData, colorActive, colorInactive, canvas, progress) => {
  const dpr = window.devicePixelRatio || 1;
  canvas.width = canvas.offsetWidth * dpr;
  canvas.height = canvas.offsetHeight * dpr;
  const ctx = canvas.getContext("2d");
  ctx.scale(dpr * dpr, dpr);
  ctx.translate(0, canvas.offsetHeight / 2); // set Y = 0 to be in the middle of the canvas

  // draw the line segments
  const width = canvas.offsetWidth / normalizedData.length;
  const adaptedProgress = progress ? progress / dpr : 0;
  for (let i = 0; i < normalizedData.length; i++) {
    let height = normalizedData[i] * (canvas.offsetHeight / 2);
    let x = (width * i) / dpr;
    if (x < 0) {
      x = 0;
    }
    const isEven = (i + 1) % 2 === 0;
    if (height < 1) {
      height = 1;
    }
    height = isEven ? height : -height;
    drawLineSegment(ctx, x, height, width, adaptedProgress, colorActive, colorInactive);
  }
};

export const drawLineSegment = (ctx, x, height, width, progress, colorActive, colorInactive) => {
  ctx.lineWidth = width > 1 ? width : 1;
  if (x > progress) {
    ctx.strokeStyle = colorInactive;
  } else {
    ctx.strokeStyle = colorActive;
  }
  ctx.beginPath();
  ctx.moveTo(x, 0);
  ctx.lineTo(x, height);
  ctx.stroke();
};
