import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useViewMode } from "src/hooks";
import IconView from "src/shared/components/IconView";
import { makeImageUrl } from "src/shared/utils/url";
import { HideIcon } from "../views";
import { Price } from "../views/Price";
import "./CartListItem.scss";

const CartListItem = React.memo(
  ({
    index,
    isImageBigSize,
    title,
    imgSrc,
    navUrl,
    price,
    fullPrice,
    discountPercent,
    bookCount,
    icon,
    onRemove,
  }) => {
    const { t } = useTranslation();
    const { isMobile } = useViewMode();

    return (
      <li className="cart-li">
        <span className="cart-li__index">{index}</span>
        {!isMobile && (
          <Image src={imgSrc} alt={title} title={title} isImageBigSize={isImageBigSize} />
        )}
        <div className="cart-li__details">
          <Link className="cart-li__title" to={navUrl}>
            {title}
          </Link>
          <span className="cart-li__price">
            <IconView icon={icon} className="cart-li__icon" />
            <Price discountPrice={price} fullPrice={fullPrice} />
          </span>
          {discountPercent && (
            <span className="cart-li__discount">
              {t("discountFor", { percent: discountPercent })}
            </span>
          )}
          {bookCount && <span className="cart-li__set">{bookCount + " " + t("booksInSet")}</span>}
        </div>
        <HideIcon onClick={onRemove} className="cart-li__close-btn" title={t("remove")} />
      </li>
    );
  },
);

CartListItem.propTypes = {
  index: PropTypes.number,
  isImageBigSize: PropTypes.bool,
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  navUrl: PropTypes.string,
  price: PropTypes.number,
  fullPrice: PropTypes.number,
  discountPercent: PropTypes.number,
  bookCount: PropTypes.number,
  icon: PropTypes.number,
  onRemove: PropTypes.func,
};

export default CartListItem;

const Image = ({ isImageBigSize, src, alt, title, onClick }) => {
  return (
    <div className="cart-li__image-container" onClick={onClick}>
      <img
        className="cart-li__image"
        alt={alt}
        effect="opacity"
        title={title}
        src={src}
        onError={(e) => {
          e.target.src = makeImageUrl(undefined, isImageBigSize);
        }}
      />
    </div>
  );
};

Image.propTypes = {
  isImageBigSize: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  discountPercent: PropTypes.number,
  onClick: PropTypes.func,
};
