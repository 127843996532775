export const COOKIE_KEY_DONATE_NEXT_SHOW = "donateNotifyNS";
export const COOKIE_KEY_DONATE_CLOSED_COUNT = "donateNotifyCC";
export const DONATE_TIMEOUT_ALREADY_DONATED = 31104000000; // 360 days.

const getDonateDelayWithClosedCount = (closedCount) => {
  if (closedCount === 0) {
    return 900000; // 15 min
  }
  if (closedCount === 1) {
    return 604800000; // 7 days.
  }
  return 2592000000; // 30 days.
};

export const getDonateTimestampNextShowWithClosedCount = (closedCount) => {
  return Date.now() + getDonateDelayWithClosedCount(closedCount);
};
