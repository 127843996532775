import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import ReactResizeDetector from "react-resize-detector/build/withPolyfill";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import BookListItem from "./BookListItem";
import AlphabetView from "./AlphabetView";
import { getBooksFirstChars } from "./BooksUtils";
import { GridView } from "../views";
import { useGridDimensions, useViewMode } from "../../hooks";
import useTogglePlayerOnChangeScrollDirection from "../../hooks/useTogglePlayerOnChangeScrollDirection";
import { useShopBooks } from "src/hooks/useShop";
import { BookInteractTypes } from "./BookList.utils";
import { getIsBookValidByFilterChar } from "../../utils/FilterBooksUtils";
import { useRemToPx } from "../../hooks/viewModeHooks";

import "./BookList.scss";

const BookList = ({
  isShowFilterAZ,
  isShowBookShelf,
  isShownFormatIcon,
  isListItemsFullWidthLong,
  isShowDescriptionCover,
  isListTypeWithInfo,
  bookInteractTypeCustom,
  handleBookInteractionCustom,
  bookListType,
  books,
  filterChar: filterCharOuter,
  setFilterChar: setFilterCharOuter,
}) => {
  const { t } = useTranslation();
  const updatePlayerVisibilityWithScrollDirection = useTogglePlayerOnChangeScrollDirection();
  const [_filterChar, _setFilterChar] = useState(null);
  const filterChar = filterCharOuter ?? _filterChar;
  const setFilterChar = useMemo(() => setFilterCharOuter ?? _setFilterChar, [setFilterCharOuter]);
  const [listSizes, setListSizes] = useState({ width: 100, height: undefined });
  const listWrapperRef = useRef();
  const gridRef = useRef();
  const isGrid = useSelector((state) => state.settings.isGrid);
  const isShowAZ = useSelector((state) => state.settings.isShowAZ);
  const settingShowBookshelf = useSelector((state) => state.settings.showBookshelf);
  const showBookshelf = isShowBookShelf ?? settingShowBookshelf;
  const { shopBooks } = useShopBooks(books);

  const {
    GRID_WIDTH,
    LIST_WIDTH,
    GRID_HEIGHT,
    LIST_HEIGHT,
    LIST_TEXT_MODE_HEIGHT,
    GRID_BOOKSHELF_HEIGHT,
    GRID_TEXT_MODE_BOOKSHELF_HEIGHT,
    GRID_TEXT_MODE_HEIGHT,
  } = useGridDimensions();
  const { textMode, isMobile } = useViewMode();
  const remToPx = useRemToPx();
  const showFilterAlphabetic = isShowFilterAZ ?? isShowAZ;

  let gridHeight;
  if (textMode) {
    gridHeight = showBookshelf ? GRID_TEXT_MODE_HEIGHT : GRID_TEXT_MODE_BOOKSHELF_HEIGHT;
  } else {
    gridHeight = showBookshelf ? GRID_HEIGHT : GRID_BOOKSHELF_HEIGHT;
  }

  const listHeight = textMode ? LIST_TEXT_MODE_HEIGHT : LIST_HEIGHT;
  let columnWidth = isGrid ? GRID_WIDTH : isListItemsFullWidthLong ? listSizes.width : LIST_WIDTH;

  const width = listSizes.width || listWrapperRef.current?.clientWidth || 0;
  const height = listSizes.height || listWrapperRef.current?.clientHeight || 0;

  let columnCount = Math.floor(width / columnWidth);
  // Prevent crash for zero width EGWW-1984
  if (columnCount <= 0) {
    columnCount = 1;
  }
  // make list for full width
  if (columnCount >= 2 && isMobile) {
    columnWidth = width / columnCount;
  }

  let rowHeight = isGrid ? gridHeight : listHeight;

  if ((isListTypeWithInfo && !isGrid) || (showBookshelf && textMode && !isGrid)) {
    rowHeight = remToPx(3.8);
  }
  const firstId = books[0]?.bookInfo?.id;

  const booksFilteredByChar = useMemo(() => {
    return books.filter((book) => getIsBookValidByFilterChar(book, filterChar));
  }, [books, filterChar]);

  const chars = useMemo(() => getBooksFirstChars(books), [books]);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollTop();
    }
    setFilterChar(undefined);
  }, [setFilterChar, firstId]);

  let rowCount = Math.ceil(
    columnCount ? booksFilteredByChar.length / columnCount : booksFilteredByChar.length,
  );

  if (rowCount < 0) {
    rowCount = 0;
  }

  if (rowCount === 1 && showBookshelf) {
    // Fix the book's hovering description is cropped in a single row case.
    rowHeight += textMode ? remToPx(4.8) : remToPx(4);
  }

  const cellRenderer = (params) => {
    const { columnIndex, key, rowIndex, style } = params;
    let position = columnCount * rowIndex + columnIndex;
    const book = booksFilteredByChar[position];
    if (!book) {
      return null;
    }

    const nBookId = (book.bookInfo || book).book_id;
    const shopBook = shopBooks[nBookId];

    return (
      <div className="book-list-item" role="listitem" style={style} key={nBookId || key}>
        <BookListItem
          isShownFormatIcon={isShownFormatIcon}
          isListTypeWithInfo={isListTypeWithInfo}
          isGrid={isGrid}
          isShowBookshelf={showBookshelf}
          isShowDescriptionCover={isShowDescriptionCover}
          book={book}
          shopBook={shopBook}
          bookListType={bookListType}
          bookInteractTypeCustom={bookInteractTypeCustom}
          handleBookInteractionCustom={handleBookInteractionCustom}
          gridPosition={{
            columnCount,
            columnIndex,
            itemWidth: style.width,
            rowCount,
            rowIndex,
            listWidth: width,
          }}
        />
      </div>
    );
  };

  return (
    <div className="bookList">
      {!!(showFilterAlphabetic && chars.length) && (
        <AlphabetView
          chars={chars}
          filter={filterChar}
          onFilter={setFilterChar}
          maxHeight={height}
        />
      )}
      <div className="book-list-grid-view-container" ref={listWrapperRef}>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={(width, height) => setListSizes({ width, height })}
          render={() => (
            <GridView
              scrollProps={{
                onScrollChangeDirection: updatePlayerVisibilityWithScrollDirection,
              }}
              width={width}
              height={height}
              role="list"
              ariaLabel={t("list_of_books")}
              ref={gridRef}
              cellRenderer={cellRenderer}
              columnCount={columnCount || booksFilteredByChar?.length}
              columnWidth={columnWidth}
              rowCount={rowCount}
              rowHeight={rowHeight}
            />
          )}
        />
      </div>
    </div>
  );
};

BookList.displayName = "BookList";

BookList.defaultProps = {
  isShownFormatIcon: true,
};

BookList.propTypes = {
  isShownFormatIcon: PropTypes.bool,
  isShowDescriptionCover: PropTypes.bool,
  isShowBookShelf: PropTypes.bool,
  isShowFilterAZ: PropTypes.bool,
  isListItemsFullWidthLong: PropTypes.bool,
  isListTypeWithInfo: PropTypes.bool,
  filterChar: PropTypes.string,
  setFilterChar: PropTypes.func,
  handleBookInteractionCustom: PropTypes.func,
  bookInteractTypeCustom: PropTypes.oneOf(Object.values(BookInteractTypes)),
  books: PropTypes.array.isRequired,
  bookListType: PropTypes.string.isRequired,
};

export default BookList;
