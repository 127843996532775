import React from "react";
import { Route, Switch } from "react-router-dom";
import BookDetailsPage from "./components/bookDetails/BookDetailsPage";
import ThemeMode from "./components/themeMode/ThemeMode";
import { 
  Loading,
  SearchPage,
} from "./components/index";
import {
  HomePage,
  CopyrightNoticeScreen,
  PrivacyPolicyPage,
  ContactScreen,
  PatchNotesPage,
  Page404,
  AboutScreen,
  UserAgreement,
  HistoryPage,
  StudyCenterPage,
  SettingsPage,
  RelatedSearchPage,
  HistorySearchPage,
  SubscriptionsPage,
  SubsReaderPage,
  StaticSharePage,
  FolderPage,
  BibliographyPage,
  ReaderPage,
  AdvSearchPage,
  SystemPage,
  WorkspacesPage,
  CategoriesPage,
  FeaturedSearchPage,
  TitlesPage,
} from "./react-pages";
import MyLibraryPage from "./components/myLibrary/MyLibraryPage";
import AddSubscriptionPage from "./components/subscriptions/AddSubscriptionPage";
import EditSubscriptionPage from "./components/subscriptions/edit/EditSubscriptionPage";
import { EpubReader } from "./components/epub";
import { PdfPage } from "./components/pdf";
import { bookIdRegex } from "./shared/utils/content";
import { URLS } from "./shared/utils/url";

/**
 * Encapsulate part with all routes for MainApp.js
 */
const Routes = React.memo(() => {
  return (
    <Switch>
      <Route path={URLS.home} exact component={HomePage} />
      <Route path={URLS.allCollection + "/:lang?/:id?"} component={FolderPage} />
      <Route path={URLS.categories + "/:categoryId?/:bookId?"} component={CategoriesPage} />
      <Route path={URLS.titles + "/:categoryId?/:bookId?"} component={TitlesPage} />
      <Route path={URLS.audioBooks + "/:lang?/:id?"} component={FolderPage} />
      <Route path={`${URLS.book}/:bookId(${bookIdRegex.source})`} component={BookDetailsPage} />
      <Route path={URLS.pdf + "/:bookId/:pageNum?"} component={PdfPage} />
      <Route path={URLS.epub + "/:bookId/:chapterId?"} component={EpubReader} />
      <Route path={URLS.read} component={ReaderPage} />
      <Route path={URLS.myLibrary + "/:lang?"} component={MyLibraryPage} />
      <Route path={URLS.history + "/:lang?"} component={HistoryPage} />
      <Route path={URLS.copirightNotice} component={CopyrightNoticeScreen} />
      <Route path={URLS.userAgreement} component={UserAgreement} />
      <Route path={URLS.privacyPolicy} component={PrivacyPolicyPage} />
      <Route path={URLS.contactus} component={ContactScreen} />
      <Route path={URLS.patchNotes} component={PatchNotesPage} />
      <Route path={URLS.search} component={SearchPage} />
      <Route path={URLS.studyCenter + "/:topicId?"} component={StudyCenterPage} />
      <Route path={URLS.editorCenter + "/:topicId?"} component={StudyCenterPage} />
      <Route path={URLS.staticShare + "/:userId/:shareId/:topicId?"} component={StaticSharePage} />
      <Route path={URLS.subscription + "/read/:date/:bookId?"} component={SubsReaderPage} />
      <Route path={URLS.subscription + "/:category?/:date?"} component={SubscriptionsPage} />
      <Route path={URLS.subscriptionAdd + "/:type?/:id?"} component={AddSubscriptionPage} />
      <Route path={URLS.subscriptionEdit + "/:type?/:id?"} component={EditSubscriptionPage} />
      <Route path={URLS.settings + "/:category?"} component={SettingsPage} />
      <Route path={URLS.relatedSearch} component={RelatedSearchPage} />
      <Route path={URLS.historySearch} component={HistorySearchPage} />
      <Route path={URLS.featuredSearch + "/:sectionCode?"} component={FeaturedSearchPage} />
      <Route path={URLS.about} component={AboutScreen} />
      <Route path={URLS.themeMode} component={ThemeMode} />
      <Route path={URLS.loading} component={Loading} />
      <Route path={URLS.bibliography} component={BibliographyPage} />
      <Route path={URLS.system} component={SystemPage} />
      <Route path={URLS.workspaces} component={WorkspacesPage} />
      <Route path={URLS.advSearch + "/:category?"} component={AdvSearchPage} />
      <Route component={Page404} />
    </Switch>
  );
});

export default Routes;
