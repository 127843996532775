import React, { useRef, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ShareWrap from "./ShareWrap";
import { copy } from "../../utils/CopyUtil";
import { useTranslation } from "react-i18next";
import { TOAST_TYPE } from "../popup/Toaster";
import { useViewMode } from "../../hooks";
import { useDispatch } from "react-redux";
import { actionAddMessage } from "../../redux/actions";
import { useLocation } from "react-router-dom";

const ShareContext = React.createContext({});

const ShareProvider = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { textMode } = useViewMode();
  const [sharerId, setSharerId] = useState();

  const shareRef = useRef();

  const onCopyToClipboard = (value) => {
    copy(value,
      {
        format: "text/plain",
        onSuccess: () => dispatch(actionAddMessage(t("linkCopied"))),
        onError: () => dispatch(actionAddMessage(t("linkNotCopied"), TOAST_TYPE.error))
      }
    );
  };

  useEffect(()=> {
    hideShare();
  }, [location.pathname, location.search]);

  /**
   *
   * @param {*} params JSON object with params
   * shareUrl: URL,
   * shareText: String,
   * onClose: Function,
   * closeOnBack: Boolean,
   * bounds: {} object of bound of open button,
   * parentBounds: object of bound of possible parent rect. Default rect is window
   */
  const showShare = (params) => {
    if (shareRef.current) {
      shareRef.current.show(params);
      setSharerId(params.sharerId);
    }
  };

  const hideShare = () => {
    if (shareRef.current) {
      shareRef.current.hide();
      setSharerId();
    }
  };

  useEffect(() => {
    if (shareRef.current) {
      shareRef.current.textMode = textMode;
    }
  }, [textMode]);

  return <ShareContext.Provider value={{ showShare, hideShare, sharerId }}>
    <ShareWrap
      ref={shareRef}
      onCopyToClipboard={(value) => onCopyToClipboard(value)}
      onClose={() => {
        setSharerId();
      }}
    />
    {children}
  </ShareContext.Provider>;
};

ShareProvider.propTypes = {
  children: PropTypes.node
};

export { ShareContext, ShareProvider };

/**
 * Has methods
 * @method showShare
 * @method hideShare
 * @string sharerId
 */
export const useShareContext = () => useContext(ShareContext);
