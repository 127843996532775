export const getSystemLang = (fallbackLang) => {
  if (typeof window !== "undefined") {
    const windowNavigator = window.navigator;

    let lang =
      (windowNavigator.languages && windowNavigator.languages[0]) || // Firefox
      windowNavigator.language || // All browsers
      fallbackLang; // Fallback language

    if (lang.indexOf("-") !== -1) {
      return lang.split("-")[0];
    }
    return lang;
  }

  return fallbackLang;
};
