import React, { forwardRef, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { getCoordinateX, whichEventKey } from "../../utils/Utils";
import { clutch } from "../../utils/NumberUtils";

import "./EGWSlider.scss";

export const EGWSlider = forwardRef(
  (
    {
      value,
      step = 1,
      isDraggableOnlyThumb,
      min = 0,
      max = 0,
      onChangeCommitted,
      thumbClassName,
      disabled,
      onChange,
      className,
      ...restProps
    },
    ref
  ) => {
    const sliderRef = useRef(null);
    const thumbRef = useRef(null);

    const getSliderRef = useCallback(() => {
      return ref || sliderRef;
    }, [ref, sliderRef]);

    const getSliderBoundingClientRect = useCallback(() => {
      const sliderRefCurrent = getSliderRef().current;

      if (sliderRefCurrent) {
        return sliderRefCurrent.getBoundingClientRect();
      }

      return undefined;
    }, [getSliderRef]);

    /**
     * @description Calculates slider progress for now based on coordinates from the given event.
     * @type {function(e: MouseEvent | TouchEvent ): (number)}
     */
    const getProgressWithEvent = useCallback(
      (e) => {
        const sliderBoundingClientRect = getSliderBoundingClientRect();

        if (sliderBoundingClientRect) {
          const moveCoordinate = getCoordinateX(e);
          let sliderRelativeCoordinate = moveCoordinate - sliderBoundingClientRect.left;

          if (sliderRelativeCoordinate <= 0) {
            return min;
          }

          const sliderWidth = sliderBoundingClientRect.width;

          if (sliderRelativeCoordinate >= sliderWidth) {
            return max;
          }

          const progressInPercents = Math.ceil((sliderRelativeCoordinate * 100) / sliderWidth);
          const newValue = ((max - min) * progressInPercents) / 100 + min;
          const extraPoints = newValue % step;

          return clutch(newValue - extraPoints, min, max);
        }

        return value;
      },
      [value, max, min, step, getSliderBoundingClientRect]
    );

    const addSliderMoveListeners = () => {
      document.addEventListener("mousemove", onThumbMove);
      document.addEventListener("touchmove", onThumbMove, { passive: false, cancelable: true });

      document.addEventListener("mouseup", onMouseReleased);
      document.addEventListener("touchend", onMouseReleased);
    };

    const removeSliderMoveListeners = () => {
      document.removeEventListener("mousemove", onThumbMove);
      document.removeEventListener("touchmove", onThumbMove);

      document.removeEventListener("mouseup", onMouseReleased);
      document.removeEventListener("touchend", onMouseReleased);
    };

    const preventEvent = (e) => {
      e.preventDefault();
    };

    const onThumbCapture = isDraggableOnlyThumb
      ? ({ nativeEvent }) => {
          if (onChangeCommitted) {
            onChangeCommitted(nativeEvent, getProgressWithEvent(nativeEvent));
          }
          addSliderMoveListeners();
        }
      : () => {};

    const onSliderCapture = isDraggableOnlyThumb
      ? () => {}
      : ({ nativeEvent }) => {
          // prev solution works wrong commiting updates on click and hold
          onChange(nativeEvent, getProgressWithEvent(nativeEvent));
          addSliderMoveListeners();
        };

    const onMouseReleased = (e) => {
      removeSliderMoveListeners();
      if (onChangeCommitted) {
        onChangeCommitted(e, getProgressWithEvent(e));
      }
    };

    const onThumbMove = (e) => {
      onChange(e, getProgressWithEvent(e));
    };

    const onThumbKeyDown = (e) => {
      const { leftArrow, rightArrow, upArrow, downArrow } = whichEventKey(e);

      if (leftArrow || downArrow) {
        const newValue = clutch(value - step, min, max);
        onChange(e, newValue);
        if (onChangeCommitted) {
          onChangeCommitted(e, newValue);
        }
      }

      if (rightArrow || upArrow) {
        const newValue = clutch(value + step, min, max);
        onChange(e, newValue);
        if (onChangeCommitted) {
          onChangeCommitted(e, newValue);
        }
      }

      return false;
    };

    useEffect(() => {
      const sliderRefCurrent = getSliderRef().current;
      const thumbRefCurrent = thumbRef.current;

      if (sliderRefCurrent) {
        sliderRefCurrent.addEventListener("mousedown", preventEvent, {
          passive: false,
          cancelable: true
        });
        sliderRefCurrent.addEventListener("touchstart", preventEvent, {
          passive: false,
          cancelable: true
        });
        sliderRefCurrent.addEventListener("touchmove", preventEvent, {
          passive: false,
          cancelable: true
        });
      }

      if (thumbRefCurrent) {
        thumbRefCurrent.addEventListener("mousedown", preventEvent, {
          passive: false,
          cancelable: true
        });
        thumbRefCurrent.addEventListener("touchstart", preventEvent, {
          passive: false,
          cancelable: true
        });
        thumbRefCurrent.addEventListener("touchmove", preventEvent, {
          passive: false,
          cancelable: true
        });
        thumbRefCurrent.addEventListener("keydown", preventEvent, {
          passive: false,
          cancelable: true
        });
      }

      return () => {
        if (sliderRefCurrent) {
          sliderRefCurrent.removeEventListener("mousedown", preventEvent);
          sliderRefCurrent.removeEventListener("touchstart", preventEvent);
          sliderRefCurrent.removeEventListener("touchmove", preventEvent);
        }
        if (thumbRefCurrent) {
          thumbRefCurrent.removeEventListener("mousedown", preventEvent);
          thumbRefCurrent.removeEventListener("touchstart", preventEvent);
          thumbRefCurrent.removeEventListener("touchmove", preventEvent);
          thumbRefCurrent.removeEventListener("keydown", preventEvent);
        }
      };
    }, [getSliderRef, onSliderCapture, onThumbCapture]);

    let currentProgressInPercents;

    const valueClutched = clutch(value, min, max);

    if (valueClutched === min) {
      currentProgressInPercents = 0;
    } else if (valueClutched === max) {
      currentProgressInPercents = 100;
    } else {
      currentProgressInPercents = ((valueClutched - min) * 100) / (max - min);
    }

    const currentProgressWithPercentSymbol = `${currentProgressInPercents}%`;
    const rootClassName = classnames("egw-slider", className, {
      "draggable-only-thumb": isDraggableOnlyThumb,
      disabled: disabled
    });

    return (
      <div
        {...restProps}
        className={rootClassName}
        ref={ref || sliderRef}
        onMouseDown={onSliderCapture}
        onTouchStart={onSliderCapture}>
        <div className="egw-slider-track">
          <div
            className="egw-slider-progress"
            style={{
              width: currentProgressWithPercentSymbol
            }}
          />
          <div
            ref={thumbRef}
            className={`egw-slider-thumb ${thumbClassName}`}
            style={{
              left: currentProgressWithPercentSymbol
            }}
            tabIndex="0"
            role="slider"
            aria-orientation="horizontal"
            aria-valuemax={max}
            aria-valuemin={min}
            aria-valuenow={value}
            onMouseDown={onThumbCapture}
            onTouchStart={onThumbCapture}
            onKeyDown={onThumbKeyDown}
          />
        </div>
      </div>
    );
  }
);

EGWSlider.defaultProps = {
  isDraggableOnlyThumb: false,
  thumbClassName : "",
};

EGWSlider.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
  thumbClassName: PropTypes.string,
  isDraggableOnlyThumb: PropTypes.bool,
  disabled: PropTypes.bool,
  onChangeCommitted: PropTypes.func,
  onChange: PropTypes.func.isRequired
};
