/* eslint-disable no-console */
import deselectCurrent from "toggle-selection";
import { getElementParagraphId } from "../components/reader/ReaderUtils";
import dy from "./dy.json";
import { changeElementTag } from "./Utils";
import { addSCEntries, removeAllEntries } from "../components/studyCenter/StudyCenterUtils";
import { containsUrl, makeSingleChapterUrl } from "./URLUtils";
import { CONTENT_CLASSES, getBookId } from "../shared/utils/content";

const clipboardToIE11Formatting = {
  "text/plain": "Text",
  "text/html": "Url",
  default: "Text",
};
let defaultMessage = "Copy to clipboard: #{key}, Enter";

const format = (message) => {
  const copyKey = (/mac os x/i.test(navigator.userAgent) ? "⌘" : "Ctrl") + "+C";
  return message.replace(/#{\s*key\s*}/g, copyKey);
};

export const COPY_FORMATS = {
  TEXT_PLAIN: "text/plain",
  TEXT_HTML: "text/html",
};

export const copyText = (text, options = {}) => {
  return new Promise((resolve) => {
    copy(text, {
      ...options,
      format: options.format || COPY_FORMATS.TEXT_PLAIN,
      onSuccess: () => resolve(true),
      onError: () => resolve(false),
    });
  });
};

export const copy = (text, options) => {
  let debug,
    message,
    reselectPrevious,
    range,
    selection,
    mark,
    success = false;
  if (!options) {
    options = {};
  }
  debug = options.debug || false;
  try {
    reselectPrevious = deselectCurrent();

    range = document.createRange();
    selection = document.getSelection();

    mark = document.createElement("span");
    mark.textContent = text;
    // reset user styles for span element
    mark.style.all = "unset";
    // prevents scrolling to the end of the page
    mark.style.position = "fixed";
    mark.style.top = 0;
    mark.style.clip = "rect(0, 0, 0, 0)";
    // used to preserve spaces and line breaks
    mark.style.whiteSpace = "pre";
    // do not inherit user-select (it may be `none`)
    mark.style.webkitUserSelect = "text";
    mark.style.MozUserSelect = "text";
    mark.style.msUserSelect = "text";
    mark.style.userSelect = "text";
    mark.addEventListener("copy", (e) => {
      e.stopPropagation();
      if (options.format) {
        e.preventDefault();
        if (typeof e.clipboardData === "undefined") {
          // IE 11
          if (debug) {
            console.warn("unable to use e.clipboardData");
          }
          window.clipboardData.clearData();
          let format =
            clipboardToIE11Formatting[options.format] || clipboardToIE11Formatting["default"];
          window.clipboardData.setData(format, text);
        } else {
          // all other browsers
          e.clipboardData.clearData();
          if (options.format === "text/html") {
            if (options.textPlain) {
              e.clipboardData.setData("text/plain", options.textPlain);
            }
            e.clipboardData.setData(options.format, text);
          } else {
            e.clipboardData.setData(options.format, text);
          }
          if (debug) {
            console.log("copy lib clipboardData", e.clipboardData);
          }
        }
      }
      if (options.onCopy) {
        e.preventDefault();
        options.onCopy(e.clipboardData);
      }
    });

    document.body.appendChild(mark);

    range.selectNodeContents(mark);
    selection.addRange(range);

    let successful = document.execCommand("copy");
    if (!successful) {
      let errorMessage = "copy command was unsuccessful";
      console.error(errorMessage);
    }
    success = true;
  } catch (err) {
    if (debug) {
      console.error("unable to copy using execCommand: ", err);
    }
    try {
      window.clipboardData.setData(options.format || "text", text);
      options.onCopy && options.onCopy(window.clipboardData);
      success = true;
    } catch (err) {
      if (debug) {
        console.error("unable to copy using clipboardData: ", err);
      }
      message = format("message" in options ? options.message : defaultMessage);
      window.prompt(message, text);
    }
  } finally {
    if (selection) {
      if (typeof selection.removeRange == "function") {
        selection.removeRange(range);
      } else {
        selection.removeAllRanges();
      }
    }

    if (mark) {
      document.body.removeChild(mark);
    }
    reselectPrevious();
  }
  if (success) {
    if (options.onSuccess) {
      options.onSuccess();
    }
  } else {
    if (options.onError) {
      options.onError();
    }
  }

  return success;
};

/**
 * @description Replaces page break elements in the "container" and replaces
 * their "innerHTML" to the newline symbols or paragraphs with page break styles
 * in depend of on the passed property named "isTextPlainFormat".
 *
 * @param {HTMLElement} container
 * @param {boolean} isTextPlainFormat
 */
const replacePageBreaks = (container, isTextPlainFormat) => {
  Array.from(container.getElementsByClassName(CONTENT_CLASSES.PAGE_BREAK)).forEach((pageBreak) => {
    pageBreak.innerHTML = isTextPlainFormat
      ? " <br><br> "
      : // eslint-disable-next-line max-len
        `<p style="page-break-after: always;">&nbsp;</p><p style="page-break-before: always;">&nbsp;</p>`;
  });
};

const removePageBreaks = (container) => {
  container.getElementsByClassName(CONTENT_CLASSES.PAGE_BREAK).forEach((node) => {
    node.remove();
  });
};

const createHeaderElement = (text) => {
  const element = document.createElement("DIV");
  element.innerHTML = `${text}<br> `;
  element.className = CONTENT_CLASSES.READER_HEADER_TEXT;
  return element;
};

const createRefElement = (text) => {
  const element = document.createElement("span");
  element.innerHTML = ` ${text}<br> `;
  element.className = CONTENT_CLASSES.REF_CODE;
  return element;
};

const applyEgwLinkStyles = (wrapper) => {
  Array.from(wrapper.getElementsByClassName(CONTENT_CLASSES.EGW_LINK)).forEach((element) => {
    element.style.color = dy["--text-link"];
  });
};

const applyEgwLinkBibleStyles = (wrapper) => {
  Array.from(wrapper.getElementsByClassName(CONTENT_CLASSES.EGW_LINK_BIBLE)).forEach((element) => {
    element.style.color = dy["--category-bible"];
  });
};

const applyBooksStyles = (wrapper) => {
  Array.from(wrapper.getElementsByClassName(CONTENT_CLASSES.BOOKS)).forEach((element) => {
    element.style.color = dy["--category-egw"];
  });
};

const applyHeadersStyles = (wrapper) => {
  Array.from(wrapper.getElementsByClassName(CONTENT_CLASSES.READER_HEADER_TEXT)).forEach(
    (element) => {
      element.style.color = dy["--text-paragraph"];
    },
  );
};

const applyRefCodesStyles = (wrapper) => {
  Array.from(wrapper.getElementsByClassName(CONTENT_CLASSES.REF_CODE)).forEach((element) => {
    element.style.color = dy["--secondary"];
  });
};

const applyStrongTagStyles = (wrapper) => {
  Array.from(wrapper.querySelectorAll("strong")).forEach((element) => {
    element.style.color = dy["--category-egw"];
  });
};

const applyDictionariesStyles = (wrapper) => {
  Array.from(wrapper.getElementsByClassName(CONTENT_CLASSES.DICTIONARY)).forEach((element) => {
    element.style.color = dy["--category-references"];
  });
};

const applyReferencesStyles = (wrapper) => {
  Array.from(wrapper.getElementsByClassName(CONTENT_CLASSES.REFERENCE)).forEach((element) => {
    element.style.color = dy["--category-references"];
  });
};

const applyNotesStyles = (wrapper) => {
  Array.from(
    wrapper.querySelectorAll(
      `sup.${CONTENT_CLASSES.FOOT_NOTE}` +
        `,sup.${CONTENT_CLASSES.BOOK_END_NOTE}` +
        `,sup.${CONTENT_CLASSES.CHAPTER_END_NOTE}`,
    ),
  ).forEach((element) => {
    element.style.color = dy["--text-link"];
    element.style.verticalAlign = "super";
  });
};

/**
 * @description Resolves the issue that some elements are not
 * styled after pasting in the "Microsoft Word".
 * @param {HTMLElement} wrapper
 */
const fixNotStylableElements = (wrapper) => {
  Array.from(
    wrapper.querySelectorAll(
      `sup.${CONTENT_CLASSES.FOOT_NOTE}` +
        `,sup.${CONTENT_CLASSES.BOOK_END_NOTE}` +
        `,sup.${CONTENT_CLASSES.CHAPTER_END_NOTE}`,
    ),
  ).forEach((element) => {
    element.parentNode.replaceChild(changeElementTag(element, "SPAN"), element);
  });
};

const applyReaderElementsStyles = (wrapper) => {
  fixNotStylableElements(wrapper);

  applyEgwLinkStyles(wrapper);
  applyEgwLinkBibleStyles(wrapper);
  applyBooksStyles(wrapper);
  applyHeadersStyles(wrapper);
  applyRefCodesStyles(wrapper);
  applyStrongTagStyles(wrapper);
  applyDictionariesStyles(wrapper);
  applyReferencesStyles(wrapper);
  applyNotesStyles(wrapper);
};

/**
 * @description Finds SC note elements in the "finalTextContainer",
 * transforms text links in them to the elements,
 * creates the wrapper for new notes and includes it to the end of the "finalTextContainer".
 * @param {HTMLElement} finalTextContainer
 */
const addSCNotes = (finalTextContainer) => {
  const notesElements = Array.from(
    finalTextContainer.querySelectorAll(`.${CONTENT_CLASSES.SC_NOTE}`),
  );

  if (notesElements.length) {
    const notesListContainerElement = document.createElement("DIV");
    notesListContainerElement.innerHTML = " <br><div><b>Notes:</b></div>";

    const notesListElement = document.createElement("UL");
    notesListElement.style.margin = "25px";

    notesListContainerElement.append(notesListElement);
    finalTextContainer.append(notesListContainerElement);

    notesElements.forEach((noteElement) => {
      const noteItemElement = document.createElement("LI");
      let noteContent = noteElement.dataset.tooltip;
      let noteContentTransformed;

      if (containsUrl(noteContent)) {
        noteContentTransformed = noteContent.split(/(https?:\/\/[^\s]+)/g);

        for (let i = 0; i < noteContentTransformed.length; i++) {
          if (noteContentTransformed[i].indexOf("http") > -1) {
            const url = noteContentTransformed[i];
            noteContentTransformed[i] = `<a href=${url} >${url}</a>`;
          }
        }
      }

      noteItemElement.innerHTML = noteContentTransformed
        ? noteContentTransformed.join("")
        : noteContent;
      notesListElement.append(noteItemElement);
    });
  }
};

/**
 * @description Generates "html" and "text" for paragraphs with passed options.
 *
 * @param {string} selectionHtml - innerHTML of Reader selected text.
 * @param {{
 *   format: boolean,
 *   refs: boolean,
 *   headers: boolean,
 *   pageBreaks: boolean,
 *   embedUrlLink: boolean,
 * }} options - the configuration object
 * @param {Object[]} paragraphs - the array of paragraphs to get ability
 * to pull the headers and references to the copy text.
 * @param {Object[]} studyCenterEntries - the object to apply Study Center
 * entries styles to the copy text.
 *
 * @returns {{
 *   html: string,
 *   text: string
 * }}
 */
export const transformSelectionWithOptions = (
  selectionHtml,
  options = {},
  paragraphs,
  studyCenterEntries,
) => {
  const unnecessaryElementsSelectors = [
    ".reader-translate-icon",
    ".readerTranslateContainer",
    ".readArrow",
    `.${CONTENT_CLASSES.READER_HEADER_TEXT}`,
    `.${CONTENT_CLASSES.PAGE_NUMBER}`,
    `.${CONTENT_CLASSES.REF_CODE}`,
    `.${CONTENT_CLASSES.FOOT_NOTE}`,
  ];

  const containerWithoutUnnecessaryElements = document.createElement("div");
  containerWithoutUnnecessaryElements.innerHTML = selectionHtml;

  containerWithoutUnnecessaryElements
    .querySelectorAll(unnecessaryElementsSelectors.join())
    .forEach((node) => {
      node.remove();
    });

  /* Entries should be added before the rest elements are (write the reason here, don't remember) */
  if (options.format) {
    addSCEntries(containerWithoutUnnecessaryElements, studyCenterEntries);
  } else {
    removeAllEntries(containerWithoutUnnecessaryElements);
  }

  const paragraphElementsArray = [
    ...containerWithoutUnnecessaryElements.getElementsByClassName(CONTENT_CLASSES.PARAGRAPH),
  ];
  const bookIdsSet = new Set();

  paragraphElementsArray.forEach((paraEl) => {
    bookIdsSet.add(getBookId(getElementParagraphId(paraEl)));
  });

  const finalTextContainer = document.createElement("DIV");
  let finalText, finalHTML, embedLinkParagraphId;

  if (paragraphElementsArray.length) {
    const allBooksParagraphs = [...bookIdsSet].reduce((acc, bookId) => {
      acc.push(...paragraphs[bookId]);
      return acc;
    }, []);

    // Goes through the paragraphs from the Redux and finds suitable paragraphs
    // for each paragraph in the copy range.
    const suitableParagraphs = paragraphElementsArray.map((paragraph, index) => {
      const paragraphId = getElementParagraphId(paragraph);

      if (index === 0) {
        embedLinkParagraphId = paragraphId;
      }

      return allBooksParagraphs.find(({ id }) => {
        return paragraphId === id;
      });
    });

    // Pulls refs and headers from the suitableParagraphs to the copy text.
    paragraphElementsArray.forEach((paragraphElement, index) => {
      const suitableParagraph = suitableParagraphs[index];

      if (options.headers) {
        paragraphElement.prepend(createHeaderElement(suitableParagraph.refcode_long));
      }

      if (options.refs) {
        paragraphElement.append(createRefElement(suitableParagraph.refcode_short));
      }

      finalTextContainer.append(paragraphElement.cloneNode(true));
    });
  } else {
    finalTextContainer.innerHTML = containerWithoutUnnecessaryElements.innerHTML;
  }

  if (options.pageBreaks) {
    replacePageBreaks(finalTextContainer, !options.format);
  } else {
    removePageBreaks(finalTextContainer);
  }

  if (options.embedUrlLink && embedLinkParagraphId) {
    const embedUrlLinkContainerElement = document.createElement("DIV");
    const url = window.location.origin + makeSingleChapterUrl(embedLinkParagraphId);
    embedUrlLinkContainerElement.innerHTML = `<br><div><b>EGW Writings: </b></div><div><a href="${url}">${url}</a></div>`;

    finalTextContainer.append(embedUrlLinkContainerElement);
  }

  if (options.format) {
    addSCNotes(finalTextContainer);
    applyReaderElementsStyles(finalTextContainer);
  }

  finalHTML = finalTextContainer.innerHTML;
  finalText = finalTextContainer.innerText || finalTextContainer.textContent;

  return {
    html: finalHTML,
    text: finalText,
  };
};
