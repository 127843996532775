import React  from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IconButton } from "../views";
import EgwWebFont from "../../assets/EgwWebFont";
import { SearchActions } from "./search.actions";
import { actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";

const ButtonSwitchToTextSearch = ({rightPanel}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchParams = useSelector((state) => state.search.searchParams);
  const searchLang = useSelector((state) => state.search.searchLang);
  const shortcutQueryAnalogue = useSelector((state) => state.search.shortcutContent.queryAnalogue);

  return (
    <IconButton
      disabled={!shortcutQueryAnalogue}
      icon={EgwWebFont["search-text"]}
      title={t("searchText")}
      onClick={() => {
        dispatch(SearchActions.fetchSuggestion(shortcutQueryAnalogue, searchLang, true));
        dispatch(SearchActions.fetchSearch({
          ...searchParams,
          query: shortcutQueryAnalogue,
          rightPanel,
          onlyText: true
        }));

        dispatch(actionUpdateSetting(Settings.showShortcutsForShortcut.id, false));
      }}
    />
  );
};

ButtonSwitchToTextSearch.propTypes = {
  rightPanel: PropTypes.bool,
};

export default ButtonSwitchToTextSearch;
