import React from "react";
import { URLS } from "../utils/url";

const UserAgreementPage = ({ Link }) => (
  <div className="long-content">
    <p>
      Your single-user license gives you the right to install EGW writings on any device you own.
      You may not copy the software. You may not copy and distribute the software by any method or
      means. You may only download and install this software from our website or official&nbsp;App
      stores.
    </p>
    <p>
      Applicable copyright laws also protect Ellen G. White’s writings within this app. If you use a
      quotation-sentences or paragraphs copied from a book, magazine article, or any other source on
      the app-you must acknowledge the source by listing the author (Ellen G. White) and the
      periodical title, or book and page number. Whether quoting directly or using ideas from these
      writings, it is important to accurately convey the context and complete thought of the passage
      in order to preserve its intended meaning and avoid misunderstanding or misapplication.
    </p>
    <p>
      <strong>Further Details</strong>
    </p>
    <p>
      <strong>1. License Grant</strong>
      <br />
      By acquiring the Software, you have obtained the non-exclusive right to the number of
      simultaneous &quot;Uses&quot; of the Software (&quot;Authorized Use(s)&quot;), indicated
      above, solely in accordance with this Agreement. You will ensure that the number of concurrent
      uses of the Software shall not exceed the number of Authorized Uses. Notwithstanding the
      foregoing, you are not licensed to use the Software to provide on-line or similar database
      services to third parties.
    </p>
    <p>
      <strong>2. Restrictions</strong>
      <br />
      You may not and you may not permit others to (a) disassemble, decompile or otherwise derive
      source code for the Software, (b) reverse engineer the Software, (c) modify or prepare
      derivative works of the Software, (d) rent or lease the Software, (e) use the Software in any
      manner that infringes the intellectual property or other rights of another party, or (f)
      transfer the Software or any copy thereof to another party, unless you transfer all media and
      written materials in this package and retain no copies of the Software (including prior
      versions of the Software) for your own use. You are required to notify the Ellen G. White
      Estate via fax, email or letter of the transfer of license to another user.
    </p>
    <p>
      <strong>3. Ownership</strong>
      <br />
      Ellen G. White Estate, Inc. owns the EGW Writings software and all trade secrets, copyrights,
      patents, trademarks, and other intellectual property rights therein, which you agree to
      preserve.
    </p>
    <p>
      <strong>4. Limited Warranty and Disclaimer</strong>
      <br />
      The Publisher does not offer any warranty for EGW Writings software. You install the software
      at your own risk. The Software is licensed to you on an &quot;AS IS&quot; basis without any
      warranty of any nature.
    </p>
    <p>
      The publisher and its licensors disclaim all other warranties, express or implied, including
      the implied warranties of merchantability and fitness for a particular purpose.
    </p>
    <p>
      <strong>5. Liability Limitations; Exclusive Remedies</strong>
      <br />
      The publisher and its licensors shall not be liable for any damage or loss, whether direct,
      indirect, special or consequential (including lost profits), arising out of or resulting from
      your possession or use of the software (including data loss or corruption), regardless of
      whether such liability is based in tort, contract or otherwise. If the foregoing limitation is
      held to be unenforceable, Ellen G. White Estate will not be held liable for any malfunction of
      your device resulting from installing this app. Some states do not allow the limitation or
      exclusion of implied warranties or liability for incidental or consequential damages, so the
      above limitations or exclusions may not apply to you.
    </p>
    <p>
      <strong>Privacy Policy</strong>
    </p>
    <p>
      See Ellen G. White Estate <Link to={URLS.privacyPolicy}>Privacy Policy</Link>&nbsp;for
      information gathered for EGW Writings
    </p>
    <p>
      <strong>Copyright Notice</strong>
    </p>
    <p>
      See Ellen G. White Estate <Link to={URLS.copirightNotice}>Copyright Notice</Link>&nbsp;for EGW
      Writings Apps and Ellen White Estate Websites
    </p>
    <p>&nbsp;</p>
  </div>
);

export default UserAgreementPage;
