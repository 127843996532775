import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import { MENU_STATE } from "../../utils/MenuItems";
import SideMenuList from "./SideMenuList";
import { Scroll } from "../views";
import { useResizeDimensions, useViewMode } from "../../hooks";
import { actionUpdateSetting } from "../../redux/actions";
import { Settings } from "../../utils/Settings";
import SideMenuTree from "./SideMenuTree";

import "./SideMenu.scss";

const SideMenu = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.settings.menuState);
  const isLeftPanelPinned = useSelector((state) => state.settings.isLeftPanelPinned);
  const { isTablet, isMobile, isMobileOrTablet, textMode } = useViewMode();
  const { MIN_LEFT_WIDTH, MIN_LEFT_TREE_WIDTH } = useResizeDimensions();

  const showTree = menuState === MENU_STATE.TREE;
  const showMenu = menuState === MENU_STATE.MENU;
  const showLeftPanel = (showTree && !textMode) || showMenu;
  const isShowLeftPinned = isLeftPanelPinned && showTree;

  const renderFolderTree = showTree ? <SideMenuTree /> : null;

  if (textMode && showTree) {
    return null;
  }

  const renderSideMenuList = () => {
    if (!isMobileOrTablet) {
      return <SideMenuList />;
    }

    if (!showTree) {
      if (isTablet) {
        return (
          <Scroll
            renderTrackVertical={(props) => <div {...props} className="scrollSideMenuTrack" />}
            className="side-menu__scrollbars-wrapper">
            <SideMenuList />
          </Scroll>
        );
      }

      return <SideMenuList />;
    }

    return null;
  };

  if (isTablet) {
    if (isShowLeftPinned) {
      return (
        <div className="menuWrap pinned" style={{ width: "100%", minWidth: MIN_LEFT_WIDTH }}>
          {renderFolderTree}
        </div>
      );
    }

    return (
      <React.Fragment>
        <div
          className={classNames("left-slide-menu", showLeftPanel ? "showView" : "hideView" )}
          onClick={(e) => {
            if (e.target.classList.contains("left-slide-menu")) {
              dispatch(actionUpdateSetting(Settings.menuState.id, MENU_STATE.COLLAPSE));
            }
          }}
        />
        <div
          className="left-slide-menu"
          style={{
            width: showTree ? MIN_LEFT_TREE_WIDTH : MIN_LEFT_WIDTH,
            transform: `translate(${showLeftPanel ? "0%" : "-110%"}, 0)`
          }}>
          <div
            className="menuWrap sliding">
            {renderSideMenuList()}
            {renderFolderTree}
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (isMobile) {
    return (
      <div
        className="menuWrap sliding"
        style={{ width: "100%" }}>
        {renderSideMenuList()}
        {renderFolderTree}
      </div>
    );
  }

  return (
    <div className="menuWrap">
      {renderSideMenuList()}
      {renderFolderTree}
    </div>
  );
};

export default SideMenu;
