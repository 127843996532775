import { useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { actionUpdateSetting, onLogout } from "../redux/actions";
import { Settings } from "../utils/Settings";
import { ScreenType, ZoomValues } from "../utils/ThemeUtils";
import { DEFAULT_LANGUAGE } from "src/shared/utils/i18n";
import { shopActions } from "src/redux/shop/actions";
import { cartActions } from "src/redux/cart/actions";
import { useNavigationContext } from "src/components/NavigationContext";
import { useDialogContext } from "src/components/dialog/DialogContext";
import { isUserEditor } from "src/components/studyCenter/EditorCenterUtils";
import { makeOAuthRequest } from "src/shared/api/baseApi";
import { SearchActions } from "src/components/search/search.actions";
import { getSystemLang } from "../shared/utils/user-agent";

const useAuth = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loginCallbackRef = useRef();
  const isLogin = useSelector((state) => state.system.isLogin);
  const userInfo = useSelector((state) => state.settings.userInfo);
  const { hasNotSavedWSChanges, setShowPopupSaveWSonLogOut } = useNavigationContext();
  const { showConfirm } = useDialogContext();

  useEffect(() => {
    if (isLogin && loginCallbackRef.current) {
      loginCallbackRef.current();
      loginCallbackRef.current = undefined;
    }
  }, [isLogin]);

  const logoutHandler = useCallback(
    (checkNotSavedWS = true) => {
      if (hasNotSavedWSChanges && checkNotSavedWS) {
        setShowPopupSaveWSonLogOut(true);
        return;
      }

      const systemLang = getSystemLang(DEFAULT_LANGUAGE);

      dispatch(onLogout());
      dispatch(actionUpdateSetting(Settings.zoom.id, ZoomValues.desktop[ScreenType.DESKTOP]));
      dispatch(SearchActions.clearSearch({ lang: systemLang })); // clear all search
      dispatch(shopActions.resetShop()); // clear shop info
      dispatch(cartActions.resetCart()); // clear cart
      i18n.changeLanguage(systemLang);
      history.push("/");
      window.userId = undefined;
      window.userLang = undefined;
    },
    [hasNotSavedWSChanges],
  );

  /**
   * Show login dialog and execute callback. If user logined - call callback instantly
   * Callback - implement function after login, can be undefined
   */
  const handleShowLoginDialog = useCallback(
    (callback) => {
      if (isLogin) {
        callback();
      } else {
        loginCallbackRef.current = callback;
        showConfirm(
          "pleaseSignIn",
          "enterToUse",
          () => {
            makeOAuthRequest();
          },
          {
            positiveText: "signIn",
            positiveBtn: "signIn",
            onCancel: () => {
              loginCallbackRef.current = undefined;
            },
          },
        );
      }
    },
    [showConfirm, isLogin],
  );

  const handleShowLogoutDialog = useCallback(() => {
    showConfirm("logout", "logout_message", () => logoutHandler(), {
      positiveBtn: "logout",
      negativeBtn: "cancel",
    });
  }, [showConfirm, logoutHandler]);

  return {
    isLogin,
    userInfo,
    isUserEditor: isUserEditor(userInfo?.role),
    onLogout: logoutHandler,
    showLoginDialog: handleShowLoginDialog,
    showLogoutDialog: handleShowLogoutDialog,
  };
};

 
export default useAuth;
