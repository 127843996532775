import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "../../views";
import { useDialogContext } from "../../dialog/DialogContext";
import { makeSCTree } from "../StudyCenterUtils";
import ScTreeViewFolders from "../ScTreeViewFolders";
import { StudyCenterActions } from "../studyCenter.actions";
import NewFolderDialog from "./NewFolderDialog";
import DialogButtons from "../../dialog/DialogButtons";
import { makeTreeFromList } from "../../../utils/Utils";
import { ItemTypes, RootFolder } from "../studyCenter.constants";
import EgwWebFont from "src/assets/EgwWebFont";

const ScFoldersDialog = ({ onChange, current, title, onlyBookFolder }) => {
  const { showDialog, showConfirm, hideDialog } = useDialogContext();
  const dispatch = useDispatch();
  const [selectedFolder, setSelectedFolder] = useState(current ? current : RootFolder.id);
  const [expanded, setExpanded] = useState([RootFolder.id]);
  const { t } = useTranslation();
  const topics = useSelector((state) => state.studyCenter.topics);

  const handleExpand = (item) => {
    const index = expanded.indexOf(item.id);
    if (index === -1) {
      expanded.push(item.id);
    } else {
      expanded.splice(index, 1);
    }
    setExpanded([...expanded]);
  };

  const [bookTopic, bookChildren] = useMemo(() => {
    if (onlyBookFolder) {
      const currentTopic = topics.find((topic) => topic.id === current);
      const bookTopic = topics.find((topic) => topic.id === currentTopic.topic);
      const bookChildren = topics.filter((topic) => topic.topic === currentTopic.topic);
      const bookChildrenFormatted = bookChildren.map((child) => ({ ...child, topic: null }));

      return [bookTopic, bookChildrenFormatted];
    }

    return [];
  }, [current, onlyBookFolder, topics]);

  const treeList = useMemo(() => {
    let rootTopics, tree;
    if (onlyBookFolder) {
      rootTopics = makeTreeFromList(bookChildren);
      tree = [
        {
          ...bookTopic,
          children: rootTopics,
        },
      ];
    } else {
      rootTopics = makeTreeFromList(topics);
      tree = [
        {
          ...RootFolder,
          children: rootTopics,
        },
      ];
    }

    return makeSCTree(tree, undefined, expanded, 0);
  }, [topics, expanded, onlyBookFolder]);

  const itemClickHandler = (item) => {
    if (item.type === ItemTypes.folder) {
      handleExpand(item);
    }

    setSelectedFolder(item.id);
  };

  useEffect(() => {
    if (onlyBookFolder && bookTopic) {
      setExpanded([bookTopic.id]);
    }
  }, [onlyBookFolder, bookTopic]);

  return (
    <div className="hl-folder-wrap">
      <div className="hl-color-title">
        <span className="sc-title">{title ? t(title) : t("folders")}</span>
        {onlyBookFolder && (
          <IconButton
            title={t("createFolder")}
            icon={EgwWebFont.plus}
            className="hl-control-btn"
            onClick={() => {
              showDialog(
                <NewFolderDialog
                  afterSubmit={(newTopic) => {
                    hideDialog();
                    onChange(null, {
                      id: `${bookTopic.id} / ${newTopic}`,
                      parent: bookTopic.id,
                      name: newTopic,
                      child_count: 0,
                      type: "folder",
                      label: newTopic,
                      title: newTopic,
                      topic: bookTopic.id,
                      children: [],
                    });
                  }}
                />,
              );
            }}
          />
        )}
        {!onlyBookFolder && (
          <>
            <IconButton
              icon={EgwWebFont.plus}
              className="hl-control-btn"
              onClick={() => {
                showDialog(
                  <NewFolderDialog
                    parent={selectedFolder === RootFolder.id ? undefined : selectedFolder}
                    onNewFolder={(folder, parent) => {
                      if (parent && !expanded.includes(parent)) {
                        setExpanded([...expanded, parent]);
                      }
                      setSelectedFolder(folder.id);
                    }}
                  />,
                );
              }}
            />
            <IconButton
              icon={EgwWebFont.minus}
              onClick={() => {
                const folder = topics.find((item) => item.id === selectedFolder);
                if (folder) {
                  showConfirm(
                    "delete",
                    t("deleteFolderMessage", { title: folder.title }),
                    () => {
                      dispatch(StudyCenterActions.deleteSCElements([], [selectedFolder]));
                    },
                    { positiveBtn: "delete", negativeBtn: "cancel" },
                  );
                }
              }}
            />
          </>
        )}
      </div>
      <ScTreeViewFolders
        treeList={treeList}
        current={selectedFolder}
        onItemClick={itemClickHandler}
      />
      <DialogButtons
        onClickNegative={hideDialog}
        onClickPositive={() => {
          if (selectedFolder) {
            hideDialog();
            const topic = topics.find((item) => item.id === selectedFolder);
            onChange(selectedFolder === RootFolder.id ? undefined : selectedFolder, topic);
          }
        }}
        negativeText={t("cancel")}
        positiveText={t("save")}
      />
    </div>
  );
};

ScFoldersDialog.propTypes = {
  current: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  topics: PropTypes.array,
  onlyBookFolder: PropTypes.bool,
};

export default ScFoldersDialog;
