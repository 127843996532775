import { FeaturedSearchActionsConstants } from "./featuredSearch.actions";
import { updateStatePropertyAndItsCache } from "../../utils/StoreUtils";

const SECTIONS_CACHE_LIMIT = 15;

const initialState = {
  cache: {},
  sections: [],
  isLoadingSection: [],
};

const featuredSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FeaturedSearchActionsConstants.FETCH_SUCCESS: {
      return updateStatePropertyAndItsCache(
        SECTIONS_CACHE_LIMIT, state,
        "cache", "sections",
        action.data.cacheKey, action.data.data
      );
    }
    default: {
      return state;
    }
  }
};

export default featuredSearchReducer;
