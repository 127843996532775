export const KeyCodes = {
  leftArrow: 37,
  rightArrow: 39,
  upArrow: 38,
  downArrow: 40,
  space: 32,
  enter: 13,
  esc: 27,
  tab: 9,
  f3: 114,
  f: 70,
  l: 76,
  p: 80,
  s: 83,
  c: 67,
  t: 84,
  zero: 48,
  keyZ: 90,
  plus: "Equal",
  numPlus: 107,
  minus: "Minus",
  numMinus: 109,
  period: 190,
  comma: 188,
  f11: 122,
  f1: 112,
};

export const Hotkeys = {
  focusSearch: {
    label: "Search",
    keyCode: {
      ctrl: true,
      shift: true,
      keys: [KeyCodes.f],
    },
  },
  toggleTree: {
    label: "Toogle Tree",
    keyCode: {
      ctrl: true,
      alt: true,
      keys: [KeyCodes.t],
    },
  },
  help: {
    label: "Help",
    keyCode: {
      keys: [KeyCodes.f1],
    },
  },
};

const textInputs = ["INPUT", "TEXTAREA"];

export const isFocusTextInput = () => {
  if (document.activeElement && document.activeElement.tagName) {
    return textInputs.includes(document.activeElement.tagName);
  }
  return false;
};

export const isEventKey = (e = {}, code = null) => {
  if (typeof code === "string") {
    return e.code === code;
  }

  return e.which === code || e.keyCode === code;
};

export const getEventPath = (e) => {
  return e.path || (e.composedPath && e.composedPath()) || [];
};

export const handleReactSlot = (slot, props = {}) => {
  switch (typeof slot) {
    case "function":
      return slot(props);
    default:
      return slot;
  }
};
