import React from "react";

import Accordion from "./Accordion";

import "./SearchAccordion.scss";

const SearchAccordion = (props) => {
  return (
    <Accordion
      headClassName="folder-item search-accordion_head"
      bodyClassName="list-wrap"
      {...props}
    />
  );
};

export default SearchAccordion;
