import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import { usePopupContext } from "../popup/PopupContext";
import { SCROLL_DIRECTIONS } from "../reader/constants";
import  classNames from "classnames";

import "./Scroll.scss";

const renderTrackVertical = (props) => <div {...props} className="scrollMainTrack" />;
const renderThumbVertical = (props) => <div {...props} className="scrollStyle" />;
const renderThumbHorizontal = (props) => <div {...props} className="scrollStyle" />;
const renderViewDefault = (props) => <div {...props} />;

/**
 * @description Scroll component with custom scrollbars and handling scroll stop with possible delay
 */
const Scroll = React.forwardRef(
  (
    {
      noHidePopups,
      children,
      onScroll,
      onScrollFrame,
      onScrollStart,
      onUpdate,
      onScrollChangeDirection,
      onScrollStop,
      onScrollStopInstance,
      stopDelay,
      className,
      renderViewClassName,
      renderViewStyle,
      fixScrollbarCoversContent,
      hasStaticPosition,
      renderView,
      ...props
    },
    ref,
  ) => {
    const { hidePopup } = usePopupContext();
    // keep start scroll position
    const scrollTopRef = useRef();
    // keep timeout for make delayed scroll
    const scrollStopRef = useRef();
    // keep time for last event of stop scroll for make debounce logic
    const [scrollStop, setScrollStop] = useState();

    const onScrollStartHandler = (e) => {
      if (!noHidePopups && hidePopup) {
        hidePopup();
      }
      if (ref && ref.current) {
        scrollTopRef.current = ref.current.view.scrollTop;
      }
      if (onScrollStart) {
        onScrollStart(e);
      }
      if (scrollStop && stopDelay !== 0) {
        setScrollStop(Date.now());
      }
    };

    useEffect(() => {
      if (scrollStop) {
        //use ref object allows reset event scroll stop when user scroll again
        clearTimeout(scrollStopRef.current);
        scrollStopRef.current = setTimeout(() => {
          if (scrollStop && onScrollStop) {
            let offset = 0;
            if (scrollTopRef.current && ref.current?.view?.scrollTop !== undefined) {
              offset = scrollTopRef.current - ref.current?.view?.scrollTop || 0;
            }
            onScrollStop(offset);
          }
          setScrollStop();
        }, stopDelay);
      }
      return () => {
        clearTimeout(scrollStopRef.current);
      };
    }, [scrollStop, stopDelay, onScrollStop, ref]);

    const handleScrollFrame = (event) => {
      const previousScrollStepTop = scrollTopRef.current;
      if (scrollStopRef.current !== undefined) {
        clearTimeout(scrollStopRef.current);
        scrollStopRef.current = undefined;
      }
      const { scrollTop } = event;
      if (onScrollChangeDirection) {
        let delta = 100;
        if (ref && ref.current && ref.current.view) {
          delta = ref.current.view.clientHeight / 6;
        }
        if (scrollTop < delta) {
          delta = scrollTop / 2;
        }
        const scrollDelta = scrollTop - previousScrollStepTop;
        if (scrollDelta > 0 && scrollDelta > delta) {
          onScrollChangeDirection(SCROLL_DIRECTIONS.DOWN);
        } else if (scrollDelta < 0 && scrollDelta < -delta) {
          onScrollChangeDirection(SCROLL_DIRECTIONS.UP);
        }
      }
    };

    const renderViewFinal = useCallback((props) => {
      const propsFulfilled = {...props};
      propsFulfilled.className = classNames(
        "scrollbars-render-view", renderViewClassName, propsFulfilled.className, {
          "has-static-position": hasStaticPosition,
          "gap-for-scrollbar": fixScrollbarCoversContent,
        });
      if (renderViewStyle) {
        propsFulfilled.style = { ...props.style, ...renderViewStyle };
      }

      return renderView ? renderView(propsFulfilled) : renderViewDefault(propsFulfilled);
    }, [renderView, renderViewStyle, renderViewClassName,
      hasStaticPosition, fixScrollbarCoversContent]);

    return (
      <Scrollbars
        hideTracksWhenNotNeeded
        className={classNames("scrollbars-wrapper", className)}
        ref={ref}
        autoHide
        onUpdate={onUpdate}
        onScrollStart={onScrollStartHandler}
        onScrollFrame={(event) => {
          handleScrollFrame(event);
          if (onScrollFrame) {
            onScrollFrame(event);
          }
        }}
        onScrollStop={() => {
          if (onScrollStopInstance) {
            onScrollStopInstance();
          }
          if (stopDelay > 0) {
            setScrollStop(Date.now());
          } else if (onScrollStop) {
            let offset = 0;
            if (scrollTopRef.current && ref.current?.view?.scrollTop !== undefined) {
              offset = scrollTopRef.current - ref.current?.view?.scrollTop || 0;
            }
            onScrollStop(offset);
          }
        }}
        onScroll={onScroll}
        renderView={renderViewFinal}
        renderTrackVertical={renderTrackVertical}
        renderThumbVertical={renderThumbVertical}
        renderThumbHorizontal={renderThumbHorizontal}
        {...props}>
        {children}
      </Scrollbars>
    );
  },
);

Scroll.defaultProps = {
  stopDelay: 0,
};

Scroll.propTypes = {
  className: PropTypes.string,
  renderViewClassName: PropTypes.string,
  noHidePopups: PropTypes.bool,
  fixScrollbarCoversContent: PropTypes.bool,
  renderViewStyle: PropTypes.object,
  hasStaticPosition: PropTypes.bool,
  children: PropTypes.node,
  onScroll: PropTypes.func,
  onScrollFrame: PropTypes.func,
  onScrollChangeDirection: PropTypes.func,
  onScrollStart: PropTypes.func,
  onUpdate: PropTypes.func,
  onScrollStop: PropTypes.func,
  onScrollStopInstance: PropTypes.func,
  renderView: PropTypes.func,
  stopDelay: PropTypes.number,
};

export default Scroll;
