import React, { forwardRef, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevroneUpCircleIcon } from "../../assets/CommonIcons";
import Scroll from "./Scroll";
import classnames from "classnames";

const ScrollerView = forwardRef(
  ({ children, className, style, noHidePopups, ...restProps }, ref) => {
    const scroller = useRef(null);
    const refFinal = ref || scroller;
    const [showToTop, setShowToTop] = useState(false);
    const [showToBottom, setShowToBottom] = useState(false);

    return (
      <React.Fragment>
        <Scroll
          className={classnames("scroller-view", className)}
          noHidePopups={noHidePopups}
          style={style || {}}
          ref={refFinal}
          onScrollFrame={({ scrollTop, clientHeight, scrollHeight }) => {
            const isNotOnTop = scrollTop !== 0;

            setShowToTop(isNotOnTop);
            setShowToBottom(
              scrollHeight > clientHeight && isNotOnTop && scrollTop < scrollHeight - clientHeight
            );
          }}
          {...restProps}>
          {children}
        </Scroll>
        <span className="scrollToWrapper">
          <ChevroneUpCircleIcon
            className={classNames("top-button", showToTop ? "showView" : "hideView")}
            onClick={() => {
              if (refFinal.current) {
                refFinal.current.scrollToTop();
              }
            }}
          />
          <ChevroneUpCircleIcon
            className={classNames("bottom-button", showToBottom ? "showView" : "hideView")}
            onClick={() => {
              if (refFinal.current) {
                refFinal.current.scrollToBottom();
              }
            }}
          />
        </span>
      </React.Fragment>
    );
  }
);

ScrollerView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  noHidePopups: PropTypes.bool
};

export default ScrollerView;
