import { isUserEditor } from "./EditorCenterUtils";
import { getBookId } from "../../shared/utils/content";
import { UnderlineColor } from "./studyCenter.constants";
import { makeRequest } from "src/shared/api/baseApi";

const EDITOR_CENTER = "sceditor";
const STUDY_CENTER = "studycenter";
const getAPIEntryHome = () => (isUserEditor() ? EDITOR_CENTER : STUDY_CENTER);

export const fetchSCEntriesRequest = (params = {}) => {
  const { bookId, chapter, topic, page } = params;
  let url = getAPIEntryHome() + "/entries";
  let addQuery = [];
  if (bookId) {
    addQuery.push("pubnr=" + bookId);
  }
  if (chapter) {
    addQuery.push("chapter=" + chapter);
  }
  if (topic) {
    addQuery.push("topic=" + encodeURIComponent(topic));
  }
  if (page) {
    addQuery.push("page=" + page);
  }

  if (addQuery.length) {
    url += "?" + addQuery.join("&");
  }
  //TODO implement reqursive load for many pages.
  const request = {
    url: url,
    type: "get",
    parseResponse: (response) => {
      const entries = normilizeEntryList(response.data.results);
      const { next: nextPage, count } = response.data;
      return { entries, nextPage, count };
    },
  };
  return makeRequest(request);
};

const normilizeEntryList = (entries) => {
  return entries
    .filter((item) => {
      //WORKAROUND for filter wrong elements. sc element cant be with 0 length
      return !(item.start_pos === item.end_pos && item.start_para === item.end_para);
    })
    .map(normalizeEntry);
};

const normalizeEntry = (entry) => {
  return {
    ...entry,
    lu: entry.lu * 1000,
    bookId: getBookId(entry.chapter),
    color: `#${entry.color || "000000"}`,
  };
};

const normalizeColor = (color) => {
  return {
    ...color,
    lu: color.lu ? color.lu * 1000 : Date.now(),
    color: `#${color.color || "000000"}`,
  };
};

const normalizeTopic = (item) => {
  return {
    ...item,
    type: "folder",
    label: item.name,
    title: item.name,
    topic: item.parent,
    lu: item.lu * 1000,
    children: [],
  };
};

export const fetchStudyCenterDumpRequest = () => {
  const request = {
    url: getAPIEntryHome() + "/dump",
    type: "get",
    parseResponse: (response) => {
      const { colors, topics, entries } = response.data;
      const normColors = colors.map((item) => {
        return {
          ...item,
          color: `#${item.color}`,
        };
      });

      const normEntries = entries.map(normalizeEntry);
      const normTopics = topics.map(normalizeTopic);
      return {
        colors: normColors,
        entries: normEntries,
        topics: normTopics,
      };
    },
  };
  return makeRequest(request);
};

export const fetchTopicsRequest = () => {
  const request = {
    url: getAPIEntryHome() + "/topics",
    type: "get",
    parseResponse: (response) => response.data.map(normalizeTopic),
  };
  return makeRequest(request);
};

export const fetchPreviewTopicsRequest = (topicIds, nextId) => {
  let url = getAPIEntryHome() + "/topics/preview";

  if (nextId) {
    url += "?start=" + nextId;
  }

  const request = {
    url,
    type: "put",
    data: { guids: topicIds },
    parseResponse: (response) => {
      const { paragraphs, entries, next } = response.data;
      return {
        next,
        entries: normilizeEntryList(entries),
        paragraphs: paragraphs.map((item) => ({ ...item, id: item.para_id })),
      };
    },
  };
  return makeRequest(request);
};

export const fetchColorsRequest = () => {
  const request = {
    url: getAPIEntryHome() + "/colors",
    type: "get",
    parseResponse: (response) => {
      let colors = response.data.map(normalizeColor);
      const index = colors.findIndex((item) => item.id === UnderlineColor);
      if (index !== -1) {
        const underline = colors.splice(index, 1);
        colors.push(...underline);
      }
      return colors;
    },
  };
  return makeRequest(request);
};

export const createEntryRequest = (data) => {
  const request = {
    url: getAPIEntryHome() + "/entries",
    type: "post",
    data,
    parseResponse: (response) => {
      return normalizeEntry(response.data);
    },
  };
  return makeRequest(request);
};

export const updateEntryRequest = (data) => {
  const request = {
    url: getAPIEntryHome() + `/entries/${data.id}`,
    type: "put",
    data,
    parseResponse: (response) => {
      return normalizeEntry(response.data);
    },
  };
  return makeRequest(request);
};

export const updateStudyCenterDumpRequest = (data) => {
  const request = {
    url: getAPIEntryHome() + "/dump",
    type: "put",
    data,
    parseResponse: (response) => {
      return response;
    },
  };
  return makeRequest(request);
};

export const moveToTopicRequest = (data) => {
  const request = {
    url: getAPIEntryHome() + "/entries/move",
    type: "post",
    data,
    parseResponse: (response) => {
      return response.data || response.status;
    },
  };
  return makeRequest(request);
};

export const createColorRequest = (name, color) => {
  const request = {
    url: getAPIEntryHome() + "/colors",
    type: "post",
    data: { name, color },
    parseResponse: (response) => normalizeColor(response.data),
  };
  return makeRequest(request);
};

export const updateColorRequest = (updateColor) => {
  const { id, color, name } = updateColor;
  const request = {
    url: getAPIEntryHome() + `/colors/${id}`,
    type: "put",
    data: { name, color },
    parseResponse: (response) => normalizeColor(response.data),
  };
  return makeRequest(request);
};

/**
 *
 * @param {array} color ids
 */
export const deleteColorsRequest = (colors) => {
  const data = {
    id: colors,
  };
  const request = {
    url: getAPIEntryHome() + "/colors",
    type: "delete",
    data,
    parseResponse: (response) => response.data,
  };
  return makeRequest(request);
};

export const createTopicRequest = (data) => {
  const request = {
    url: getAPIEntryHome() + "/topics",
    type: "post",
    data,
    headers: {
      "Content-type": "application/json",
    },
    parseResponse: (response) => normalizeTopic(response.data),
  };
  return makeRequest(request);
};

export const updateTopicRequest = (data) => {
  return isUserEditor() ? updateEditorTopicRequest(data) : updateStudyTopicRequest(data);
};

export const updateStudyTopicRequest = (data) => {
  const request = {
    url: getAPIEntryHome() + `/topics/${data.id}`,
    type: "put",
    data,
    parseResponse: (response) => {
      return normalizeTopic(response.data);
    },
  };
  return makeRequest(request);
};

export const updateEditorTopicRequest = (data) => {
  const editorData = {
    topic_id: data.id,
    new_name: data.name,
  };
  const request = {
    url: `${EDITOR_CENTER}/entries/rename`,
    type: "POST",
    data: editorData,
    parseResponse: (response) => {
      if (response.status === 204) {
        return normalizeTopic(data);
      }

      return normalizeTopic(response.data);
    },
  };
  return makeRequest(request);
};

export const fetchStudyCenterSearchRequest = (data) => {
  const { query, page } = data;
  let url = getAPIEntryHome() + "/search";
  let queries = [];
  if (query) {
    queries.push("query=" + encodeURIComponent(query));
  }
  if (page) {
    queries.push("page=" + page);
  }

  if (queries.length) {
    url += "?" + queries.join("&");
  }

  return makeRequest({
    url,
    type: "get",
    parseResponse: (response) => {
      response.data.results = response.data.results.map((item) => ({
        ...item,
        lu: item.lu * 1000,
        color: "#" + item.color,
      }));
      return response.data;
    },
  });
};

export const deleteSCEntriesRequest = (elements) => {
  const data = {
    id: elements,
  };
  const request = {
    url: getAPIEntryHome() + "/entries",
    type: "delete",
    data,
    parseResponse: (response) => response.data.deleted,
  };
  return makeRequest(request);
};

export const deleteSCTopicsRequest = (elements) => {
  const data = {
    id: elements,
  };
  const request = {
    url: getAPIEntryHome() + "/topics/delete/recursive",
    type: "put",
    data,
    parseResponse: (response) => response.data || response.status,
  };
  return makeRequest(request);
};

export const fetchStaticShareRequest = () => {
  const request = {
    url: getAPIEntryHome() + "/static-shares",
    type: "get",
    parseResponse: (response) => response.data,
  };
  return makeRequest(request);
};

export const createStaticShareRequest = (data) => {
  const request = {
    url: getAPIEntryHome() + "/static-shares",
    type: "post",
    data,
    parseResponse: (response) => response.data || response.status,
    parseError: (response) => {
      throw Error(response?.data?.title + " " + response?.data?.detail || "Parse error");
    },
  };
  return makeRequest(request);
};

export const deleteStaticShareRequest = (ids) => {
  const request = {
    url: getAPIEntryHome() + "/static-shares/delete",
    type: "post",
    data: ids,
    parseResponse: (response) => response.data || response.status,
    parseError: (response) => {
      throw Error(response?.data?.title || "Parse error");
    },
  };
  return makeRequest(request);
};

export const fetchPreviewStaticShareRequest = async (userId, shareId) => {
  const shareData = await makeRequest({
    url: getAPIEntryHome() + `/static-shares/${userId}/${shareId}`,
    type: "get",
    parseResponse: (response) => response.data,
  });

  const previewRequest = await makeRequest({
    url: getAPIEntryHome() + `/static-shares/${userId}/${shareId}/preview`,
    type: "get",
    parseResponse: (response) => response.data,
  });

  return {
    ...shareData,
    colors: shareData.colors.map(normalizeColor),
    entries: normilizeEntryList(shareData.entry),
    topics: shareData.topics.map(normalizeTopic),
    paragraphs: previewRequest.paragraphs.map((item) => ({ ...item, id: item.para_id })),
  };
};
