import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { THEME_MODE } from "../utils/ThemeUtils";
import { makePublicUrl } from "../shared/utils/url";

const bookDY = makePublicUrl("/images/DY-Book.png");
const bookDM = makePublicUrl("/images/DM-Book.png");
const bookDW = makePublicUrl("/images/DW-Book.png");
const bookDK = makePublicUrl("/images/DK-Book.png");
const book = makePublicUrl("/images/Book.png");

const showOneBook = false;

const WaterMark = ({ title }) => {
  const themeMode = useSelector((state) => state.settings.themeMode);
  let image;

  if (themeMode === THEME_MODE.dy.id) {
    image = bookDY;
  } else if (themeMode === THEME_MODE.dm.id) {
    image = bookDM;
  } else if (themeMode === THEME_MODE.dk.id) {
    image = bookDK;
  } else if (themeMode === THEME_MODE.dw.id) {
    image = bookDW;
  }

  let textStyle;

  if (title.length > 15) {
    let size = 60 + (15 / title.length) * 60;
    textStyle = { fontSize: size };
  }

  const watermarkImgs = [
    {
      src: bookDY,
      active: image === bookDY,
    },
    {
      src: bookDM,
      active: image === bookDM,
    },
    {
      src: bookDK,
      active: image === bookDK,
    },
    {
      src: bookDW,
      active: image === bookDW,
    },
  ];

  return (
    <div role="presentation" className="watermark-wrap">
      <span className="watermark-text" style={textStyle}>
        {title}
      </span>
      {showOneBook ? (
        <img src={book} alt="background" className={"active"} loading="lazy" />
      ) : (
        watermarkImgs.map((item, index) => (
          <img
            loading="lazy"
            key={index}
            src={item.src}
            alt="background"
            className={item.active ? "active" : undefined}
          />
        ))
      )}
    </div>
  );
};

WaterMark.propTypes = {
  title: PropTypes.string,
};

export default WaterMark;
