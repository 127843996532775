/* eslint-disable no-console */
/* eslint-disable max-len */
import axios from "axios";

import { getAPIUrl, getBaseHost, getClientId, getCPanelUrl } from "../utils/url";
import { getTokens, setTokens, localStorageSafe } from "../utils/systemUtils";
import { APIUrls } from "../utils/api";
import { logNetworkEvent } from "./analitics";
import { isDevelopment } from "../utils/assembly";

const MEMORY_CACHE_TIME = 1000 * 60 * 15; // 15 minutes
const STORAGE_CACHE_TIME = 1000 * 60 * 60 * 24 * 7; //cache for one week

const isLogNetwork = () => isDevelopment();

const TAG_NETWORK = "NetworkLog_";

function storeCache(name, data) {
  if (window && localStorageSafe) {
    let json = {
      timestamp: Date.now(),
      data,
    };
    localStorageSafe.setItem(name, JSON.stringify(json));
  }
}

export function makeOAuthRequest() {
  const authParams = encodeURIComponent(
    "/connect/authorize?response_type=code" +
      "&client_id=" +
      getClientId() +
      "&redirect_uri=" +
      encodeURIComponent(getBaseHost() + "/api/authCallback") +
      "&scope=" +
      encodeURIComponent(
        "user_info writings search " + "studycenter subscriptions offline_access",
      ) +
      "&grant_type=authorization_code" +
      "&state=test",
  );

  const loginURL = `${getCPanelUrl()}/accounts/login/?next=${authParams}`;
  window.open(loginURL);
}

export function fetchToken(refresh) {
  let tokenLink = getBaseHost() + APIUrls.getToken;
  const { token, refreshToken } = getTokens();
  if (token && !refresh) {
    return Promise.resolve(token);
  }
  if (refreshToken) {
    tokenLink += "?token=" + refreshToken;
  }
  return axios
    .get(tokenLink)
    .then((response) => {
      let { access_token, refresh_token } = response.data;
      let refToken = refreshToken || refresh_token;
      setTokens(access_token, refToken);
      return access_token;
    })
    .catch((response) => {
      if (isLogNetwork()) {
        console.log(TAG_NETWORK + "error", response);
      }
      return undefined;
    });
}

/**
 * [url] :{
 * timestamp,
 * data,
 * }
 */
const MemoryCacheHolder = {};
if (typeof window !== "undefined") {
  window.MemoryCacheHolder = MemoryCacheHolder;
}

/**
 * @typedef {Object} NetworkParams
 * @property {!string} url - url, if start without http - adds base API server
 * @property {?string} type=get - get, post, delete, put.
 * @property {?object} data - data for post request
 * @property {?object} headers - additional headers
 * @property {?boolean} skipToken - skip use api token if request for external request
 * @property {?function} parseError - parse error of request
 * @property {?function} parseResponse - parse result of request
 * @property {?boolean} memoryCache - cache get request in memory by url
 * @property {?string} memoryCacheName - cache get request in memory by special name
 * @property {number} memoryCacheTime={@link MEMORY_CACHE_TIME} - set time for cache
 * @property {?string} storageCacheName - Store data in localStorage by name
 * @property {?number} storageCacheTime={@link STORAGE_CACHE_TIME} - set time for cache
 */

/**
 * base network request for client side
 * @param {NetworkParams} request
 * @param {number} refreshCount
 * @returns
 */
export function makeRequest(request, refreshCount) {
  return fetchToken(refreshCount).then((token) => {
    if (!token || !request.url) {
      return undefined;
    }
    const requestMethod = request.type || "get";
    let url;
    if (request.url.indexOf("http") === 0) {
      url = request.url;
    } else if (request.url.indexOf("/") === 0) {
      url = getAPIUrl() + request.url;
    } else {
      url = getAPIUrl() + "/" + request.url;
    }
    if (window && localStorageSafe && request.storageCacheName) {
      let cache = localStorageSafe.getItem(request.storageCacheName);
      if (cache) {
        try {
          const storageTime = request.storageCacheTime || STORAGE_CACHE_TIME;
          let jsonCache = JSON.parse(cache);
          if (jsonCache.data && Date.now() - jsonCache.timestamp < storageTime) {
            return jsonCache.data;
          }
        } catch (e) {
          if (isLogNetwork()) {
            console.log("makeRequest error", e);
          }
        }
      }
    }

    const timeStart = Date.now();
    if (request.memoryCache) {
      const time = request.memoryCacheTime || MEMORY_CACHE_TIME;
      if (request.memoryCacheName) {
        const cachedData = MemoryCacheHolder[request.memoryCacheName];
        if (cachedData && cachedData.data && Date.now() - cachedData.timestamp < time) {
          return cachedData.data;
        }
      } else if (requestMethod === "get") {
        const cachedData = MemoryCacheHolder[url];
        if (cachedData && cachedData.data && Date.now() - cachedData.timestamp < time) {
          return cachedData.data;
        }
      }
    }

    return axios({
      method: requestMethod,
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        ...request.headers,
      },
      data: request.data,
    })
      .then((response) => {
        const requestTime = Date.now() - timeStart;
        if (requestTime < 1000000) {
          logNetworkEvent({
            url,
            method: requestMethod,
            status: "success",
            time: Date.now() - timeStart,
            code: response.status,
            post_data: request.data,
          });
        }
        let finalResponse = response;
        if (typeof request.parseResponse === "function") {
          finalResponse = request.parseResponse(response);
        }

        if (request.storageCacheName) {
          storeCache(request.storageCacheName, finalResponse);
        }
        if (request.memoryCache && requestMethod === "get") {
          MemoryCacheHolder[url] = {
            timestamp: Date.now(),
            data: finalResponse,
          };
        }
        return finalResponse;
      })
      .catch((response) => {
        if (response.response) {
          if (response.response.status !== 304) {
            console.log(`${TAG_NETWORK}makeRequest error`, url, response);
          }
          logNetworkEvent({
            url,
            time: Date.now() - timeStart,
            status: "error",
            code: response.response.status,
          });
          if (response.response.status === 401) {
            const callNumber = refreshCount ? refreshCount + 1 : 1;
            if (callNumber > 5) {
              return undefined;
            }
            return makeRequest(request, callNumber);
          }

          if (request.parseError) {
            return request.parseError(response.response);
          }
        }
        console.log(`${TAG_NETWORK}makeRequest not request error`, url, response);
        return undefined;
      })
      .finally(() => {
        // if (isLogNetwork()) {
        //   console.log(`${TAG_NETWORK}finally`, url, result);
        // }
      });
  });
}
