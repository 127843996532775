import { useCallback, useEffect, useRef } from "react";

const useSetTimeoutWithPause = ({
  isSetIntervalBehaviour,
  timeout,
  handler,
}) => {
  const timerIdRef = useRef();

  const timeoutStartRef = useRef();
  const timestampStartRef = useRef();

  const timeoutRemainAfterPauseRef = useRef();

  const getTimeoutRemain = useCallback(() => {
    const timestampDelta = Date.now() - timestampStartRef.current;

    return timeoutStartRef.current - timestampDelta;
  }, []);

  const clearTimer = useCallback(() => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
  }, []);

  const startOrResumeTimer = useCallback(() => {
    timeoutStartRef.current = timeoutRemainAfterPauseRef.current ?? timeout;
    timestampStartRef.current = Date.now();

    timerIdRef.current = setTimeout(() => {
      handler();
      timeoutRemainAfterPauseRef.current = undefined;

      if (isSetIntervalBehaviour) {
        startOrResumeTimer();
      }
    }, timeoutStartRef.current);
  }, [handler, isSetIntervalBehaviour, timeout]);

  const pauseTimer = useCallback(() => {
    clearTimer();

    timeoutRemainAfterPauseRef.current = getTimeoutRemain();
  }, [clearTimer, getTimeoutRemain]);

  const restartTimer = useCallback(() => {
    clearTimer();
    startOrResumeTimer();
  }, [clearTimer, startOrResumeTimer]);

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, [clearTimer]);

  return {
    startOrResumeTimer,
    pauseTimer,
    restartTimer,
    clearTimer,
    getTimeoutRemain,
    timestampStartRef,
  };
};

export default useSetTimeoutWithPause;
