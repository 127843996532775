import EgwIcons from "../../../assets/EgwWebFont";
import {
  PanelTypes,
  getLinkValue,
  NEW_PANEL_ID,
  findBookIndex,
  makeLinkValue,
} from "../../../utils/URLUtils";
import { contains } from "src/utils/Utils";

import Layout2 from "../layouts/Layout2";
import Layout3 from "../layouts/Layout3";
import Layout4 from "../layouts/Layout4";
import Layout5 from "../layouts/Layout5";
import Layout6 from "../layouts/Layout6";
import Layout7 from "../layouts/Layout7";
import Layout8 from "../layouts/Layout8";

export const MAX_PANELS_COUNT = 8;
export const MAX_MOBILE_COUNT = 2;

export const layoutIds = {
  l1: "l1",

  l2v: "l2v",
  l2h: "l2h",

  l3s12: "l3s12",
  l3s12v: "l3s12v",
  l3s21: "l3s21",
  l3s21v: "l3s21v",
  l3v: "l3v",
  l3h: "l3h",

  l4s22: "l4s22",
  l4v: "l4v",
  l4h: "l4h",
  l4s112: "L4s112",
  l4s112v: "L4s112v",
  l4s211: "l4s211",
  l4s211v: "l4s211v",
  l4s121: "l4s121",
  l4s121v: "l4s121v",

  l5s122: "l5s122",
  l5s122v: "l5s122v",
  l5s212: "l5s212",
  l5s212v: "l5s212v",
  l5s221v: "l5221v",
  l5s221: "l5221",
  l5s2111: "l5s2111",
  l5s2111v: "l5s2111v",

  l6v: "l6v",
  l6h: "l6h",

  l7s1222: "l7s1222",
  l7s1222v: "l7s1222v",
  l7s2122: "l7s2122",
  l7s2122v: "l7s2122v",
  l7s2212: "l7s2212",
  l7s2212v: "l7s2212v",
  l7s2221: "l7s2221",
  l7s2221v: "l7s2221v",

  l8h: "l8h",
  l8v: "l8v",
};

const layouts = {
  2: Layout2,
  3: Layout3,
  4: Layout4,
  5: Layout5,
  6: Layout6,
  7: Layout7,
  8: Layout8,
};

export const getLayoutByCountPane = (count, onePane = null) => {
  if (count < 1) {
    return null;
  }

  if (count === 1) {
    return onePane || null;
  }

  if (count > 7) {
    return layouts[8];
  }

  return layouts[count];
};

export const getLayoutsData = ({ MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT }) => [
  {
    id: layoutIds.l1,
    count: 1,
    icon: EgwIcons.stop,
    minWidth: MIN_PANEL_WIDTH,
    minHeight: MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l2v,
    count: 2,
    icon: EgwIcons.L2v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l2h,
    count: 2,
    icon: EgwIcons.L2h,
    minWidth: MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l3s12,
    count: 3,
    icon: EgwIcons.L3s12,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l3s21,
    count: 3,
    icon: EgwIcons.L3s21,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l3s12v,
    count: 3,
    icon: EgwIcons.L3s12v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l3s21v,
    count: 3,
    icon: EgwIcons.L3s21v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l3v,
    count: 3,
    icon: EgwIcons.L3v,
    minWidth: 3 * MIN_PANEL_WIDTH,
    minHeight: MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l3h,
    count: 3,
    icon: EgwIcons.L3h,
    minWidth: MIN_PANEL_WIDTH,
    minHeight: 3 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4s22,
    count: 4,
    icon: EgwIcons.L4s22,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4h,
    count: 4,
    icon: EgwIcons.L4h,
    minWidth: MIN_PANEL_WIDTH,
    minHeight: 4 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4v,
    count: 4,
    icon: EgwIcons.L4v,
    minWidth: 4 * MIN_PANEL_WIDTH,
    minHeight: MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4s112,
    count: 4,
    icon: EgwIcons.L4s112,
    minWidth: 3 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4s112v,
    count: 4,
    icon: EgwIcons.L4s112v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 3 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4s211,
    count: 4,
    icon: EgwIcons.L4s211,
    minWidth: 3 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4s211v,
    count: 4,
    icon: EgwIcons.L4s211v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 3 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4s121,
    count: 4,
    icon: EgwIcons.L4s121,
    minWidth: 3 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l4s121v,
    count: 4,
    icon: EgwIcons.L4s121v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 3 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l5s122,
    count: 5,
    icon: EgwIcons.L5s122,
    minWidth: 3 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l5s122v,
    count: 5,
    icon: EgwIcons.L5s122v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 3 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l5s212,
    count: 5,
    icon: EgwIcons.L5s212,
    minWidth: 3 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l5s212v,
    count: 5,
    icon: EgwIcons.L5s212v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 3 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l5s221,
    count: 5,
    icon: EgwIcons.L5s221,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l5s221v,
    count: 5,
    icon: EgwIcons.L5s221v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 3 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l5s2111,
    count: 5,
    icon: EgwIcons.L5s2111,
    minWidth: 4 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l5s2111v,
    count: 5,
    icon: EgwIcons.L5s2111v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 4 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l6h,
    count: 6,
    icon: EgwIcons.L6h,
    minWidth: 3 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l6v,
    count: 6,
    icon: EgwIcons.L6v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 3 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l7s1222,
    count: 7,
    icon: EgwIcons.L7s1222,
    minWidth: 4 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l7s1222v,
    count: 7,
    icon: EgwIcons.L7s1222v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 4 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l7s2122,
    count: 7,
    icon: EgwIcons.L7s2122,
    minWidth: 4 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l7s2122v,
    count: 7,
    icon: EgwIcons.L7s2122v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 4 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l7s2212,
    count: 7,
    icon: EgwIcons.L7s2212,
    minWidth: 4 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l7s2212v,
    count: 7,
    icon: EgwIcons.L7s2212v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 4 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l7s2221,
    count: 7,
    icon: EgwIcons.L7s2221,
    minWidth: 4 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l7s2221v,
    count: 7,
    icon: EgwIcons.L7s2221v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 4 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l8h,
    count: 8,
    icon: EgwIcons.L8h,
    minWidth: 4 * MIN_PANEL_WIDTH,
    minHeight: 2 * MIN_PANEL_HEIGHT,
  },
  {
    id: layoutIds.l8v,
    count: 8,
    icon: EgwIcons.L8v,
    minWidth: 2 * MIN_PANEL_WIDTH,
    minHeight: 4 * MIN_PANEL_HEIGHT,
  },
];

export const getLayouts = ({ MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT }, count, width, height) => {
  return getLayoutsData({ MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT }).filter((item) => {
    return item.count === count && width >= item.minWidth && height >= item.minHeight;
  });
};

export const getLayout = ({ MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT }, layout) => {
  return (
    getLayoutsData({ MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT }).find((item) => item.id === layout) || {}
  );
};

export const getCurrentLayout = (
  { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT },
  current,
  count,
  width,
  height,
) => {
  const inCount = count > MAX_PANELS_COUNT ? MAX_PANELS_COUNT : count;
  const possible = getLayouts({ MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT }, inCount, width, height);
  if (!possible.length) {
    return "l1";
  }
  if (!current) {
    return possible[0].id;
  }
  let curr = possible.find((item) => item.id === current);
  if (!curr) {
    return possible[0].id;
  }
  return current;
};

export const getMinWidthLayouts = (layouts, defaultValue = 258) => {
  let minValue = 0;
  layouts.forEach(({ minWidth }) => {
    if (minValue === 0) {
      minValue = minWidth;
    } else {
      if (minWidth < minValue) {
        minValue = minWidth;
      }
    }
  });

  return minValue > 0 ? minValue : defaultValue;
};

export const convertPanelIds = (panelIds) => {
  let contentIndex = -1;
  const list = panelIds.map((item, index) => {
    const { type, value } = getLinkValue(item);
    if (type === PanelTypes.CONTENT.id) {
      contentIndex = index;
    }
    return {
      id: item,
      index,
      type,
      value,
    };
  });
  return [list, contentIndex];
};

// go through the list of panelIds to identify if it's possible to display
// all panels based on current both height and width values
export const getPossiblePanelIds = (dimms) => (panelIds) => {
  const { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT, parentWidth, parentHeight, height } = dimms;
  const possibleLayouts =
    getLayouts({ MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT }, panelIds.length, parentWidth, parentHeight)
      .length > 0;
  if (!possibleLayouts) {
    const possiblePanelIds = [...panelIds];
    for (let i = 0; i < panelIds.length; i++) {
      possiblePanelIds.pop();
      const possibleLayouts =
        getLayouts(
          { MIN_PANEL_WIDTH, MIN_PANEL_HEIGHT },
          possiblePanelIds.length,
          parentWidth,
          height,
        ).length > 0;
      if (possibleLayouts && possiblePanelIds.length > 1) {
        return possiblePanelIds;
      }
    }
  }
  return panelIds;
};

const getAllowedPanelIds = (panelIds, isLogin, isUserEditor) => {
  return panelIds.filter((value) => {
    const type = value.substring(0, 1);
    if (type === PanelTypes.EDITOR.id) {
      return isLogin && isUserEditor;
    }

    return true;
  });
};

export const getVisiblePanels = (
  isAddingPanel,
  panelIds,
  componentDimms,
  isLogin,
  isUserEditor,
) => {
  const possiblePanelIds = getPossiblePanelIds(componentDimms);
  const allowedPanelIds = getAllowedPanelIds(panelIds, isLogin, isUserEditor);
  if (isAddingPanel) {
    const withNewPanel = [NEW_PANEL_ID, ...allowedPanelIds];
    return convertPanelIds(possiblePanelIds(withNewPanel));
  }
  return convertPanelIds(possiblePanelIds(allowedPanelIds));
};

export const getOverWhatPanleId = (x, y) => {
  const panels = document.getElementsByClassName("drop-field");
  for (let i = 0; i < panels.length; i++) {
    const rect = panels[i].getBoundingClientRect();
    if (contains(rect, x, y)) {
      const overId = panels[i].getAttribute("data-panel-id");
      const overTitle = panels[i].getAttribute("data-panel-title");
      return { overId, overTitle };
    }
  }
  return {};
};

export const getCanAddPanel = (layoutWrap, panelIds, dimms) => {
  if (layoutWrap) {
    const layoutsCount = getLayouts(
      dimms,
      panelIds.length + 1,
      layoutWrap.offsetWidth,
      layoutWrap.offsetHeight,
    ).length;
    return layoutsCount > 0;
  }
  return true;
};

export const getLinkId = (panelIds, elementId) => {
  const { type, value } = getLinkValue(elementId);
  if (!type) {
    const linkType =
      findBookIndex(panelIds, elementId) !== -1 ? PanelTypes.READ.id : PanelTypes.PARA.id;
    return makeLinkValue(linkType, elementId);
  } else if (type === PanelTypes.BOOK.id) {
    const paraId = value + ".1";
    const linkType =
      findBookIndex(panelIds, paraId) !== -1 ? PanelTypes.READ.id : PanelTypes.PARA.id;
    return makeLinkValue(linkType, paraId);
  }
  return null;
};
