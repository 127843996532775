import {
  ACTION_SET_AUDIO_PLAYER_DATA,
  ACTION_PUT_AUDIO_WAVE,
  ACTION_SET_BROKEN_AUDIOS
} from "./constants";

export const audioPlayerInitialState = {
  isSmallPlayerActive: false,
  isSmallPlayerExpanded: false,
  waveformsDictionary: {},
  brokenAudios: [],
};

const audioReducer = (state = audioPlayerInitialState, action) => {
  switch (action.type) {
    case ACTION_SET_AUDIO_PLAYER_DATA: {
      return {
        ...state,
        ...action.data
      };
    }
    case ACTION_PUT_AUDIO_WAVE: {
      const { waveform, paragraphsAudioPositions, chapterId } = action.data;

      if (!waveform && !paragraphsAudioPositions) {
        return state;
      }

      return {
        ...state,
        waveformsDictionary: {
          ...state.waveformsDictionary,
          [chapterId]: {
            waveform,
            paragraphsAudioPositions
          }
        }
      };
    }
    case ACTION_SET_BROKEN_AUDIOS: {
      return { ...state, brokenAudios: action.data };
    }
  }
  return state;
};

export default audioReducer;
