import React from "react";
import BookToc, { BOOK_TOC_ITEM_ACTIONS } from "../../bookToc/BookToc";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useViewMode } from "../../../hooks";
import { useAudioContext } from "../AudioContext";

const TocSmall = ({ book }) => {
  const { isMobile } = useViewMode();
  const { t } = useTranslation();
  const { initPlay, toggleChapterPlaying, isPlaying } = useAudioContext();
  const contentStr = isMobile ? t("contents") : `- ${t("contents")}`;

  return <div className="toc-small">
    <div className="bookInfoTitleContainer">
      <span className="bookInfoContentTitle">{book.title}</span>
      <span className="bookInfoGrayText">&nbsp;{contentStr}</span>
    </div>
    {book.children && book.children.length !== 0 &&
      <BookToc onItemTitleClick={(actions, item, itemAudioChapter) => {
        if (actions.includes(BOOK_TOC_ITEM_ACTIONS.OPEN_IN_READER) && itemAudioChapter.mp3) {
          if (isPlaying) {
            // pause before play another chapter to not stop Player and Reader sync.
            toggleChapterPlaying();
          }

          initPlay(itemAudioChapter.id);
        }
      }} bookId={book.id} />}
  </div>;
};

TocSmall.propTypes = {
  book: PropTypes.object,
  onToggleVisible: PropTypes.func
};

export default TocSmall;
