import { getJsonValue } from "src/utils/Settings";
import { localStorageSafe } from "../../shared/utils/systemUtils";
import { parseISO } from "date-fns";
import { deleteSearchHistory } from "src/api/API";

export const HASH_S_PLUS_OPENED = "s-plus";
export const HASH_S_PLUS_OPENED_AND_SEARCHED = "s-plus-and-searched";

export const CACHE_SEARCH_HISTORY = "searchHistory";

export const SearchListType = {
  OMSK_SEARCH: "omsk_search",
  BOOK_SEARCH: "book_search",
  PHRASE_SEARCH: "phrase_search",
  SUGGESTION_SEARCH: "suggestion_search",
  DEFAULT: "default",
};

const defaultHistoryObject = {
  date: "",
  items: [],
  deleted: []
};

export const CorrectionsType = {
  SHORTCUT: 0,
  SUGGESTION: 1,
  CORRECTION: 2,
};

export const saveSearchHistory = (query) => {
  const cache = CACHE_SEARCH_HISTORY;
  const searchHistory = getJsonValue(cache, defaultHistoryObject);

  let isExist = false;
  const date = new Date();
  searchHistory.items.forEach((item) => {
    if (!item.luTime && item.lu) {
      item.luTime = parseISO(item.lu).getTime();
    }
    if (item.query === query) {
      isExist = true;
      item.lu = date.toISOString();
      item.luTime = date.getTime();
    }
  });
  if (!isExist) {
    searchHistory.items.unshift({
      lu: date.toISOString(),
      luTime: date.getTime(),
      query
    });
  }

  searchHistory.items.sort((a, b) => b.luTime - a.luTime).filter((item) => !!item.query);

  localStorageSafe.setItem(cache, JSON.stringify(searchHistory));
  return searchHistory;
};


export const clearSearchHistory = async () => {
  await deleteSearchHistory();
  const cache = CACHE_SEARCH_HISTORY;
  let record = {};
  record.date = new Date().toISOString();
  record.items = [];
  localStorageSafe.setItem(cache, JSON.stringify(record));
};

export const sortByScoreAndCount = (langA, langB, langScore, langCountMap) => {
  if (langScore[langA] && langScore[langB]) {
    if (langScore[langA] > langScore[langB]) {
      return 1;
    }
    if (langScore[langA] < langScore[langB]) {
      return -1;
    }
  }
  if (langCountMap[langA] && langCountMap[langB]) {
    if (langCountMap[langA] > langCountMap[langB]) {
      return 1;
    }
    if (langCountMap[langA] < langCountMap[langB]) {
      return -1;
    }
  }

  return 0;
};
