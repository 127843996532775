import React from "react";
import Layout3 from "./Layout3";
import { layoutIds } from "./LayoutUtils";
import PropTypes from "prop-types";
import ResizerWrap from "./ResizeWrap";

const Layout6 = ({ panelIds, id, onRender }) => {
  const [id0, id1, id2, id3, id4, id5] = panelIds;

  if (id === layoutIds.l6h) {
    return (
      <ResizerWrap vertical>
        <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3h} onRender={onRender} />
        <Layout3 panelIds={[id3, id4, id5]} id={layoutIds.l3h} onRender={onRender} />
      </ResizerWrap>
    );
  }

  //layoutIds.l6h
  return (
    <ResizerWrap>
      <Layout3 panelIds={[id0, id1, id2]} id={layoutIds.l3v} onRender={onRender} />
      <Layout3 panelIds={[id3, id4, id5]} id={layoutIds.l3v} onRender={onRender} />
    </ResizerWrap>
  );
};

Layout6.propTypes = {
  id: PropTypes.string,
  onRender: PropTypes.func,
  panelIds: PropTypes.array,
};

export default Layout6;
