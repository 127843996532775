import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { PLAYER_EVENTS, useAudioContext } from "./AudioContext";
import { findWaveformsDictionaryParaByPosition } from "./PlayerUtils";

// Expands AudioContext hook to get dynamic current time position of the playing audio.
// Note: Each {time} update invokes a render.
export const useAudioContextWithCurrentTime = (audioContext, {
  // To not listen time (e.g. in small player) when progress isn't visible.
  enabled = true,
  // To not listen too often when progress line is a slider one.
  delayUpdateTime,
}) => {
  const timeoutRef = useRef(-1);
  const {
    isPlaying,
    getPlayerPosition,
    chapterId,
    subscribePlayerEvents,
    unsubscribePlayerEvents,
  } = audioContext;
  const [time, setTime] = useState(getPlayerPosition());

  // @UX - is needed to much faster time sync.
  useEffect(() => {
    const listener = ({event, position}) => {
      if (event === PLAYER_EVENTS.CAN_PLAY_THROUGH) {
        setTime(getPlayerPosition());
      }

      if (event === PLAYER_EVENTS.CALL_INIT_PLAY) {
        setTime(position);
      }
    };

    subscribePlayerEvents(listener);

    return () => {
      unsubscribePlayerEvents(listener);
    };
  }, [subscribePlayerEvents, unsubscribePlayerEvents, getPlayerPosition]);

  // Update {time} every {delayUpdateTime} if {enabled} and {isPlaying}.
  useEffect(() => {
    const updateCurrentTime = (setNewTimeout) => {
      setTime(getPlayerPosition());

      if (setNewTimeout) {
        timeoutRef.current = setTimeout(() => updateCurrentTime(setNewTimeout), delayUpdateTime);
      }
    };

    updateCurrentTime(enabled && isPlaying);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [enabled, delayUpdateTime, isPlaying, chapterId, getPlayerPosition]);

  const withInstantlyTimeUpdate = (method) => {
    return (...args) => {
      method(...args);
      setTime(getPlayerPosition());
    };
  };

  return {
    ...audioContext,
    timePosition: time,
    seekPlay: withInstantlyTimeUpdate(audioContext.seekPlay),
    skipPlay: withInstantlyTimeUpdate(audioContext.skipPlay),
    setPlayerPosition: withInstantlyTimeUpdate(audioContext.setPlayerPosition),
  };
};

const DELAY_CODE_EXECUTIONS = 500;

/**
 * Returns immediately found current para and waits its remain time with {setTimeout}
 * to find a next para then.
 * @return {{
 *   id: string,
 *   start: number,
 *   end: number,
 * } | undefined}
 */
export const useGetCurrentPlayingPara = () => {
  const timerIdRef = useRef();
  const {
    chapterId,
    isPlaying,
    getPlayerPosition,
    playbackRate,
    subscribePlayerEvents,
    unsubscribePlayerEvents,
  } = useAudioContext();

  const waveformsDictionary = useSelector((state) => state.audioPlayer.waveformsDictionary);

  const [triggerRecomputeCurrentPara, dispatchTriggerRecomputeCurrentPara] = useState(null);

  // Find current paragraph and set timeout to search paragraph anew after its remain seconds.
  const currentPara = useMemo(() => {
    clearTimeout(timerIdRef.current);

    const currentPara = findWaveformsDictionaryParaByPosition(
      waveformsDictionary, chapterId, getPlayerPosition()
    );

    if (currentPara && isPlaying) {
      const secondsRemain = ((currentPara.end - getPlayerPosition()) / playbackRate).toFixed(2);

      timerIdRef.current = setTimeout(() => {
        dispatchTriggerRecomputeCurrentPara(currentPara.id + secondsRemain);
      }, secondsRemain * 1000 + DELAY_CODE_EXECUTIONS);
    }

    return currentPara;
  }, [
    triggerRecomputeCurrentPara, waveformsDictionary,
    isPlaying, chapterId, getPlayerPosition, playbackRate
  ]);

  // Clean up timer on unmount.
  useEffect(() => {
    return () => {
      clearTimeout(timerIdRef.current);
    };
  }, []);

  // Additional cases to search paragraph anew.
  useEffect(() => {
    const listener = ({event}) => {
      if (event === PLAYER_EVENTS.CALL_INIT_PLAY
      || event === PLAYER_EVENTS.CAN_PLAY_THROUGH
      || event === PLAYER_EVENTS.POSITION_SET) {
        dispatchTriggerRecomputeCurrentPara(Math.random());
      }
    };

    subscribePlayerEvents(listener);

    return () => {
      unsubscribePlayerEvents(listener);
    };
  }, [subscribePlayerEvents, unsubscribePlayerEvents]);

  return currentPara;
};
