import React from "react";
import PropTypes from "prop-types";
import { getTheme } from "../../shared/utils/theme";
import { useCookies } from "react-cookie";
import { useTheme } from "../../hooks";
import { makePublicUrl } from "../../shared/utils/url";

const PreloadIcon = React.memo(({ size }) => {
  const [cookie] = useCookies(["theme"]);
  const { themeMode } = useTheme(); 

  let fileName = getTheme(cookie.theme || themeMode).gifLoader; 
  const path = makePublicUrl("/images/" + fileName);

  return (
    <img  src={path} style={{width: size, height: size * 1.1}}/>
  );
});

PreloadIcon.defaultProps = {
  size: 200
};

PreloadIcon.propTypes = {
  size: PropTypes.number
};

export default PreloadIcon;
