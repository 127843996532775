import React, { useContext, useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import PopupWrap from "./PopupWrap";
import { getBoundingClientRect } from "../../utils/Utils";

import "./Popup.scss";
import { useDispatch } from "react-redux";
import { actionSetPopupId } from "src/redux/actions";
import { EnumPopupPositions } from "./constants";

const PopupContext = React.createContext({});

const PopupProvider = ({ children }) => {
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const targetRectRef = useRef(null);
  const location = useLocation();
  const [position, setPosition] = useState(EnumPopupPositions.LEFT_TOP);

  useEffect(() => {
    let timer;
    const onWindowResizeFinished = () => {
      const { x, y, bottom } = getBoundingClientRect(targetRectRef.current);
      if (popupRef.current) {
        if (x && y && bottom) {
          if (position === EnumPopupPositions.LEFT_TOP) {
            popupRef.current.changeCoordinates({ left: x, top: y });
          } else if (position === EnumPopupPositions.LEFT_BOTTOM) {
            popupRef.current.changeCoordinates({ left: x, top: bottom });
          } else if (position === EnumPopupPositions.RIGHT_TOP) {
            popupRef.current.changeCoordinates({ right: x, top: y });
          } else if (position === EnumPopupPositions.RIGHT_BOTTOM) {
            popupRef.current.changeCoordinates({ right: x, top: bottom });
          }
        } else {
          hide();
        }
      }
    };

    const onWindowResize = (event) => {
      if (!targetRectRef.current) {
        return;
      }

      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(onWindowResizeFinished, 100, event);
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
      clearTimeout(timer);
    };
  }, [position]);

  useEffect(() => {
    hide();
  }, [location.pathname, location.search]);

  useEffect(() => {
    window.addEventListener("popstate", hide);

    return () => window.removeEventListener("popstate", hide);
  }, []);

  const showPopup = useCallback((positions, component, options = {}) => {
    if (popupRef.current) {
      popupRef.current.showPopup(positions, component, options);
      const { targetRect, position } = options;
      if (targetRect) {
        targetRectRef.current = targetRect;
      }
      if (position) {
        setPosition(position);
      }
      dispatch(actionSetPopupId(options.popupId));
    }
  }, []);

  const hide = useCallback(() => {
    if (popupRef.current) {
      popupRef.current.hide();
      dispatch(actionSetPopupId());
    }
  }, []);

  const changeCoordinates = useCallback((coordinates) => {
    if (popupRef.current) {
      popupRef.current.changeCoordinates(coordinates);
    }
  }, []);

  // split context for popupId and functions for avoid obsolete rerenders
  return (
    <PopupContext.Provider value={{ showPopup, hidePopup: hide, changeCoordinates }}>
      <PopupWrap
        main
        ref={popupRef}
        onClose={() => {
          dispatch(actionSetPopupId());
        }}>
        {children}
      </PopupWrap>
    </PopupContext.Provider>
  );
};

PopupProvider.propTypes = {
  children: PropTypes.node
};

export { PopupContext, PopupProvider };

/**
 * The interface can be deprecated, in case please @see PopupWrap.js for the information.
 * @return {{
 * isShow: function,
 * popupId: string,
 * showPopup: function(targetRect: {}, component: any, options: {
 *    popupId?: string,
 *    innerProps?: string,
 *    popupProps?: string,
 *    withHoverZone?: boolean,
 *    hideOnMouseLeave?: boolean,
 *    hideOnClick?: boolean,
 *    hideOnBlur?: boolean,
 *    parentRect?: {},
 *    noBack?: boolean,
 *    hideOnOutsideClick?: boolean,
 *    hideOnBackMouseUp?: boolean,
 *    fitPosition?: boolean,
 *    fitSizes?: boolean,
 *    backStyle?: {},
 *    targetElement?: HTMLElement,
 *    isScrollWrapperNeeded?: boolean,
 *    onShow?: function,
 *    onHide?: function,
 *    position?: string,
 * }),
 * hidePopup: function,
 * changeCoordinates: function,
 * popupId: string
 * }}
 */
export const usePopupContext = () => useContext(PopupContext);
