import React from "react";
import { SortDirection } from "react-virtualized";
import classNames from "classnames";
import PropTypes from "prop-types";
  
import { ChevronDownIcon, ChevronUpIcon } from "src/assets/CommonIcons";

const icons = [
  { icon: ChevronUpIcon, direction: SortDirection.ASC },
  { icon: ChevronDownIcon, direction: SortDirection.DESC }
];

const SortIcons = ({ activeType, sortDirection }) => (
  <div className="sort-icons-container">
    {icons.map((item) => {
      const { icon: Icon, direction } = item;
      const isActive = direction === sortDirection && activeType;

      return (
        <div className="sort-icons" key={direction}>
          <Icon className={classNames("sort-icon", { "is-active": isActive })}/>       
        </div>
      );
    })}
  </div>
);

SortIcons.propTypes = {
  activeType: PropTypes.bool,
  sortDirection: PropTypes.string,
  toggleSort: PropTypes.func
};

export default SortIcons;
