import axios from "axios";
import {  getBaseHost } from "../shared/utils/url";
import { localStorageSafe } from "src/shared/utils/systemUtils";
import { APIUrls } from "../shared/utils/api";
import { withEtagSaving } from "./api.utils";
import { makeRequest } from "src/shared/api/baseApi";
import { isDevelopment } from "../shared/utils/assembly";

export const getVersionRequest = async () => {
  let url = getBaseHost() + APIUrls.status;
  try {
    const res = await axios.get(url);
    return res?.data?.version;
  } catch (e) {
    return undefined;
  }
};

export const initFetchToken = (refreshToken) => {
  let tokenLink = getBaseHost() + APIUrls.getToken;
  if (refreshToken) {
    tokenLink += "?token=" + refreshToken;
  }
  return axios
    .get(tokenLink)
    .then((response) => {
      let { access_token, refresh_token } = response.data;
      let refToken = refreshToken || refresh_token;

      return { token: access_token, refreshToken: refToken };
    })
    .catch((response) => {
      if (isDevelopment()) {
        console.log("error", response);
      }
      if (refreshToken) {
        return initFetchToken().then((token) => {
          return { ...token };
        });
      }
      return {};
    });
};

export const withEtagCachingRequest = async (requestOptions) => {
  const TAG = "/etag";
  const eTagCacheName = requestOptions.url + TAG;
  const dataCacheName = requestOptions.url;
  const storedETag = localStorageSafe.getItem(eTagCacheName);
  const parseResponseAndError = withEtagSaving(requestOptions.parseResponse, eTagCacheName);
  const request = {
    ...requestOptions,
    headers: {
      ...requestOptions.headers,
      "if-none-match": storedETag,
    },
    parseResponse: parseResponseAndError,
    parseError: parseResponseAndError,
  };
  const data = await makeRequest(request);

  if (data.status === 304) {
    const dataFromCache = localStorageSafe.getItem(dataCacheName);
    if (dataFromCache) {
      return JSON.parse(dataFromCache);
    }
  }
  localStorageSafe.setItem(dataCacheName, JSON.stringify(data.value));
  return data.value;
};
