import React from "react";
import { Loading } from "../components";

const LoadingPage = () => {
  return (
    <div className="fl-container">
      <div className="fl-content">
        <Loading type={Loading.types.INIT} />
        <span className="fl-quote">
          &quot;My writings will constantly speak, and their work will go forward as long as time
          shall last.&quot;
        </span>
        <span className="fl-author">- Ellen G. White</span>
      </div>
    </div>
  );
};

export default LoadingPage;
