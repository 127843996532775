import React from "react";
import { Link } from "react-router-dom";
import { ScrollerView } from "../components/views";

import "./Pages.scss";
import { useViewMode } from "../hooks";
import CopyrightNoticePage from "src/shared/pages/CopyrightNoticePage";

const CopyrightNoticeScreen = () => {
  const { isMobile } = useViewMode();

  const WrapperComponent = isMobile ? "div" : ScrollerView;

  return (
    <WrapperComponent className="long-content">
      <CopyrightNoticePage Link={Link} />
    </WrapperComponent>
  );
};

export default CopyrightNoticeScreen;
