import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { makeResourceUrl, URLS } from "../../shared/utils/url";
import { makeNavigationUrl } from "../../utils/URLUtils";
import { useShareContext } from "../shareHolder/ShareHolder";
import { Button, LinkButton } from "../views";
import BookItemImage from "../book/BookItemImage";
import EpubPopup from "./popups/epubPopup";
import PdfPopup from "./popups/pdfPopup";
import useSubscription from "src/hooks/useSubscription";
import { BookInteractTypes } from "../book/BookList.utils";
import { useHandleBookInteraction } from "../book/BookList.hooks";
import useBuyBookPackage from "../../hooks/useBuyBookPackage";
import { useBookTranslationsBasedOnTitleTree } from "./BookDetails.hooks";
import { useBookHistory } from "src/hooks";
import { useBookTransation } from "./hooks";
import BookTranslateStat from "../book/translate/BookTranslateStat";

export const ImageBlock = ({ book, withHistory, shopBook, shopPackage, isAvailable }) => {
  const history = useHistory();
  const { showShare } = useShareContext();
  const bookHistory = useBookHistory(book.id);
  const { handleBookInteraction } = useHandleBookInteraction(book);
  const handleBuyBookPackage = useBuyBookPackage();
  const { isCanSubscribe, isSubscribed, onClickSubscribe } = useSubscription(book.id);
  const nBookId = book.book_id;
  const { titleTreeBookOriginal, bookTranslations } = useBookTranslationsBasedOnTitleTree(nBookId);

  const { t } = useTranslation();
  const { is_audiobook: isAudio } = book;
  const { epub, mobi, mp3, pdf } = book.files;
  const { buy_link } = book;

  const epubRef = useRef();
  const pdfRef = useRef();
  const translationBook = useBookTransation(book.id);

  const isLabelRead = !!book.chapters?.length;
  // Block resource links for pay books.
  // There is no files, because of some troubles with the security.
  const blockResourceLinks = !!shopBook;

  let btnInteractType;

  if (!isAvailable) {
    btnInteractType = BookInteractTypes.buy;
  } else if (isLabelRead) {
    btnInteractType = BookInteractTypes.read;
  } else {
    btnInteractType = BookInteractTypes.listen;
  }

  const handleOpen = useCallback(
    (interactionType = btnInteractType) => {
      handleBookInteraction(book, interactionType, {
        withHistory,
        bookHistory,
        isOpenReaderOnRead: true,
      });
    },
    [handleBookInteraction, book, withHistory, bookHistory, btnInteractType],
  );

  const renderBookActions = (isAvailable) => {
    if (!isAvailable) {
      return (
        <>
          <Button
            type="tertiary"
            onClick={() => {
              handleBookInteraction(book, BookInteractTypes.buy);
            }}>
            {t("buyBook")}
          </Button>
          {shopPackage && (
            <Button
              type="tertiary"
              outline
              onClick={() => {
                handleBuyBookPackage(shopPackage.id);
              }}>
              {t("buySet")}
            </Button>
          )}
        </>
      );
    }
    return (
      <>
        <Button
          className="s-btn_read"
          disabled={(book.children || []).length === 0}
          onClick={() => handleOpen(BookInteractTypes.read)}>
          {t("read")}
        </Button>
        <Button
          type="secondary"
          disabled={!isAudio}
          onClick={() => handleOpen(BookInteractTypes.listen)}>
          {t("listen")}
        </Button>
      </>
    );
  };

  const handleClickAllLanguages = useCallback(() => {
    history.push(
      [URLS.titles, titleTreeBookOriginal.categoryId, titleTreeBookOriginal.book_id].join("/"),
    );
  }, [titleTreeBookOriginal?.book_id, titleTreeBookOriginal?.categoryId, history]);

  return (
    <div className="bookImageContainer">
      <BookItemImage
        book={book}
        lastChapter={bookHistory ? bookHistory.refcode_short : null}
        imageClassName="bookCover"
        bookCoverClassName="image-block_book-cover"
        isGrid={true}
        btnInteractType={btnInteractType}
        onClick={() => handleOpen()}
        isBig={true}
      />
      <div className="bookBtnGridContainer">
        <div className="book-actions-container">{renderBookActions(isAvailable)}</div>
        <div className="book-actions-container">
          <Button
            disabled={!bookTranslations.length}
            outline
            type="secondary"
            className="height-middle"
            onClick={handleClickAllLanguages}>
            {t("allLanguages")}
          </Button>
        </div>
        <div className="book-actions-container actions-download">
          <Button
            className="height-tiny"
            outline
            type="secondary"
            onClick={(e) => pdfRef.current.show({ book }, e.target.getBoundingClientRect())}
            disabled={blockResourceLinks || !pdf}>
            {t("pdf")}
          </Button>
          <Button
            className="height-tiny epub-btn"
            outline
            type="secondary"
            onClick={(e) => epubRef.current.show({ book }, e.target.getBoundingClientRect())}
            disabled={blockResourceLinks || !epub}>
            {t("epub")}
          </Button>
          <LinkButton
            tabIndex={-1}
            className="height-tiny"
            external
            outline
            type="secondary"
            link={makeResourceUrl(mobi)}
            label={t("mobi")}
            disabled={blockResourceLinks || !mobi}
          />
          <LinkButton
            tabIndex={-1}
            className="height-tiny"
            external
            outline
            type="secondary"
            link={makeResourceUrl(mp3)}
            label={t("mp3")}
            disabled={blockResourceLinks || !mp3}
          />
        </div>
        <div className="book-actions-container">
          <LinkButton
            tabIndex={-1}
            className="height-small"
            external
            outline
            type="secondary"
            link={buy_link || ""}
            label={t("buyPrintedBook")}
            disabled={!buy_link}
          />
          <Button
            className="height-small"
            outline
            type="secondary"
            onClick={(e) => {
              showShare({
                shareData: {
                  url: makeNavigationUrl(book),
                  title: book.title,
                  refcode: book.code,
                },
                bounds: e.target.getBoundingClientRect(),
              });
            }}>
            {t("share")}
          </Button>
        </div>
        <div className="book-actions-container">
          <Button
            outline
            type="secondary"
            className="height-middle"
            disabled={!isCanSubscribe}
            onClick={onClickSubscribe}>
            {isSubscribed ? t("subscribed") : t("subscribe")}
          </Button>
        </div>
      </div>
      {translationBook && (
        <BookTranslateStat statistics={translationBook.statistics} paraId={book.first_id} big />
      )}
      {!!epub && <EpubPopup history={history} ref={epubRef} book={book} />}
      {!!pdf && <PdfPopup history={history} ref={pdfRef} book={book} />}
    </div>
  );
};

ImageBlock.propTypes = {
  book: PropTypes.object,
  withHistory: PropTypes.bool,
  shopBook: PropTypes.object,
  shopPackage: PropTypes.object,
  isAvailable: PropTypes.bool,
};

export default ImageBlock;
