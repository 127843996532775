import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeviceDetect } from "../hooks";
import { useOrientationChange } from "./index";
import { getScreenOrientation, SCREEN_ORIENTATION } from "../utils/Utils";
import { useDialogContext } from "../components/dialog/DialogContext";

/**
 * @name useOrientationCheck (hook)
 * @description show warning if the device is in landsacpe mode
 * @return {undefined}
 */
 const useOrientationCheck = () => {
  const { t } = useTranslation();
  const [isLandscape, setIsLandscape] = useState(getScreenOrientation() === SCREEN_ORIENTATION.LANDSCAPE);
  const [isRightAngle, setIsRightAngle] = useState(false);
  const { showConfirm, hideDialog } = useDialogContext();
  const { addOrientationChangeListener, removeOrientationChangeListener } = useOrientationChange();
  const { isRealMobileOnly } = useDeviceDetect();

  useEffect(() => {
    // do not show warning if it is phone's keyboard squeezes the app
    if (isLandscape && isRealMobileOnly && isRightAngle) {
      showConfirm(t("rotateYourDevice"), t("rotateForBetterExp"), null, {
        noButtons: true
      });
    } else {
      hideDialog();
    }
  }, [isLandscape, isRealMobileOnly, isRightAngle]);

  useEffect(() => {
    let onOrientationChange;
    if (isRealMobileOnly) {
      onOrientationChange = ({ newOrientation }) => {
        setIsLandscape(newOrientation === SCREEN_ORIENTATION.LANDSCAPE);
      };
      addOrientationChangeListener(onOrientationChange);
    }
    return () => removeOrientationChangeListener(onOrientationChange);
  }, [isRealMobileOnly, addOrientationChangeListener, removeOrientationChangeListener]);

  useEffect(() => {
    // EGWW-2537 a workaround for sony xperia
    const onOrientationChange = (e) => {
      // set to true and return if not supported
      if (!e.target.screen?.orientation) {
        if (!isRightAngle) {
          setIsRightAngle(true);
        }
        return;
      }
      const angle = e.target.screen.orientation.angle;
      setIsRightAngle(angle !== 0);
    };
    window.addEventListener("orientationchange", onOrientationChange);
    return () => window.removeEventListener("orientationchange", onOrientationChange);
  },[isRightAngle]);
};

export default useOrientationCheck;
