import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useViewMode } from "../../hooks";
import { Scroll } from "../views";

import "./BookDesc.scss";
import BookTranslateStat from "./translate/BookTranslateStat";

const BookDesc = ({
  bookInfo, descParams, renderBookRow, showBookshelf, isViewTranslationProgress
}) => {
  const { t } = useTranslation();
  const { textMode } = useViewMode();
  const { title, author, npages, lang, description } = bookInfo;
  const {
    hasCompactView,
    style,
    sideWidth,
    paddingRight,
    paddingLeft,
    isShownOnBothSides,
    isShownOnTheLeftSide,
    isShownOnTop,
  } = descParams;

  const elementTitle = (
    <div className="title-wrapper">
      <div className="title">{title}</div>
      {author && <div className="author">{`By ${author}`}</div>}
    </div>
  );

  const elementDescription = (
    <Scroll className="scroll-wrapper-description">
      <div className="desc-block" dangerouslySetInnerHTML={{ __html: description }} />
    </Scroll>
  );

  let contentPartLeft;

  if (isViewTranslationProgress) {
    contentPartLeft = (
      <>
        <div className="translate-title">
          {elementTitle}         
        </div>
        <BookTranslateStat statistics={bookInfo?.statistics} paraId={bookInfo.first_para} /> 
      </>
    );
  } else {
    contentPartLeft = (
      <>
        <div className="book-desc-info-main">
          {elementTitle}
          <div className="pagesNum-wrapper">
            <span>{t("pagesNum", { pages: npages })}</span>
            <span className="lang">{lang}</span>
          </div>
        </div>
        {isShownOnBothSides ? renderBookRow({
          listView: true,
          withStaticDownload: showBookshelf && !textMode,
          showBookCode: showBookshelf,
          showActionButtons: false,
          showBookCodeLabel: false,
          showBookSeries: bookInfo.isForSale,
        }) : elementDescription}
      </>
    );
  }

  let contentBottom;

  if (!isViewTranslationProgress) {
    contentBottom = (
      <>
        {
          (showBookshelf || isShownOnBothSides)
            ? (
              <div className="book-cover-part-right" style={{ width: sideWidth }}>
                { (isShownOnBothSides) ? elementDescription : "" }
                {
                  (textMode && isShownOnBothSides)
                    ? ""
                    : renderBookRow({
                      listView: true,
                      withStaticDownload: !isShownOnBothSides,
                      showBookCode: !isShownOnBothSides,
                      showActionButtons: (isShownOnBothSides) ? showBookshelf : true,
                      showDownloadButton: false,
                      showBookSeries: bookInfo.isForSale && !isShownOnBothSides
                    })
                }
              </div>
            )
            : ""
        }
        {
          (textMode && isShownOnBothSides && showBookshelf)
            ? (
              <div className="book-desc-bottom-part">
                {
                  renderBookRow({
                    listView: true,
                    withStaticDownload: true,
                    showBookCode: false,
                    showActionButtons: true,
                    showBookCodeLabel: false
                  })
                }
              </div>
            )
            : ""
        }
      </>
    );
  } else {
    contentBottom = (
      <div className="book-cover-part-right" style={{ width: sideWidth }}>
        {
          renderBookRow({
            listView: true,
            withStaticDownload: false,
            showBookCode: false,
            showActionButtons: true,
            showBookCodeLabel: false,
            showBookSeries: bookInfo.isForSale,
          })
        }
      </div>
    );
  }

  return (
    <div
      className={classNames("book-description-cover", {
        "show-bookshelf": showBookshelf || isViewTranslationProgress,
        "has-compact-view": hasCompactView,
        "is-shown-on-both-sides": isShownOnBothSides,
        "is-shown-on-right-side": !isShownOnTheLeftSide && !isShownOnBothSides,
        "is-shown-on-left-side": isShownOnTheLeftSide,
        "is-shown-on-top": isShownOnTop,
        "is-translation-cover": isViewTranslationProgress,
      })}
      style={style}>
      <div className="book-cover-part-left" style={{ paddingRight, paddingLeft, width: sideWidth }}>
        {contentPartLeft}
      </div>
      {contentBottom}
    </div>
  );
};

BookDesc.propTypes = {
  isViewTranslationProgress: PropTypes.bool,
  showBookshelf: PropTypes.bool,
  bookInfo: PropTypes.object,
  descParams: PropTypes.object,
  renderBookRow: PropTypes.func,
};

export default BookDesc;
