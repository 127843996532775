import { useMemo} from "react";
import {  useSelector } from "react-redux";
// import { useAuth } from "src/hooks";
import { getBookId } from "src/shared/utils/content";
import { SearchPanelTabs } from "../rightPanel/constants";
import { useSearchContext } from "./SearchContext";

export const useSearchBookCode = (isLocal) => {
  const searchParams = useSelector((state) => state.search.searchParams);
  const localSearchParams = useSelector((state) => state.search.localSearchParams);
  const params = isLocal ? localSearchParams : searchParams;
  const { books, chapters } = params;
  const allBooks = useSelector((state) => state.mainTree.books);

  const bookCode = useMemo(() => {
    let bookId;
    if (chapters?.length === 1) {
      bookId = getBookId(chapters[0]);
    }
    if (books?.length === 1) {
      bookId = books[0];
    }
    if (bookId) {
      const book = allBooks.find((item) => item.id === bookId);
      if (book) {
        return book;
      }
    }
    return undefined;
  }, [books, chapters, allBooks]);

  return bookCode;
};

/**
 * Moved to method for avoid bug with auto open search result panels
 * 
 * @returns {func} function for call start search of last search result
 */
export const useMakeLastSearch = () => {
  // const { isLogin } = useAuth();
  const isLogin = useSelector((state) => state.system.isLogin);
  const { openSearchHistory } = useSearchContext();
  const searchHistory = useSelector((state) => state.search.searchHistory);
  const shortcutContent = useSelector((state) => state.search.shortcutContent.results);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const rightTab = useSelector((state) => state.settings.rightTab);

  const openLastSearch = () => {
    // impl start loading only on create component
    const [lastSearch] = searchHistory;
    if (
      isLogin &&
      !searchQuery &&
      rightTab === SearchPanelTabs.RESULTS &&
      lastSearch &&
      shortcutContent.length === 0
    ) {
      openSearchHistory(lastSearch.query);
    }
  };

  return openLastSearch;
};
