import { SCROLL_DIRECTIONS } from "../components/reader/constants";
import { setPlayerParams } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useViewMode } from "./viewModeHooks";
import { useCallback } from "react";
import { useAudioContext } from "../components/bookPlayer/AudioContext";

const useTogglePlayerOnChangeScrollDirection = () => {
  const dispatch = useDispatch();
  const isSmallPlayerActive = useSelector((state) => state.audioPlayer.isSmallPlayerActive);
  const { chapterId } = useAudioContext();
  const { isMobile } = useViewMode();

  const updatePlayerVisibilityWithScrollDirection = useCallback((scrollDirection) => {
    if (isMobile && chapterId) {
      let isSmallPlayerActiveNew;

      if (scrollDirection === SCROLL_DIRECTIONS.DOWN) {
        isSmallPlayerActiveNew = false;
      } else if (scrollDirection === SCROLL_DIRECTIONS.UP) {
        isSmallPlayerActiveNew = true;
      }

      if (isSmallPlayerActiveNew === undefined) {
        return;
      }

      if (isSmallPlayerActiveNew !== isSmallPlayerActive) {
        dispatch(setPlayerParams({
          isSmallPlayerActive: isSmallPlayerActiveNew,
        }));
      }
    }
  }, [isMobile, chapterId, isSmallPlayerActive]);

  return updatePlayerVisibilityWithScrollDirection;
};

export default useTogglePlayerOnChangeScrollDirection;
