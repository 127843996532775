import React, { useRef } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

import { useNavigationContext } from "../NavigationContext";
import { IconButton } from "../views";
import EgwWebFont from "src/assets/EgwWebFont";
import { useViewMode } from "src/hooks";
import MinNavPanel from "../rightPanel/MinNavPanel";
import { URLS } from "../../shared/utils/url";
import ButtonSwitchToTextSearch from "./ButtonSwitchToTextSearch";
import { useSearchContext } from "./SearchContext";

const ShortcutPanelButtons = ({rightPanel}) => {
  const history = useHistory();
  const timerLoadRef = useRef();
  const { isReader } = useNavigationContext();
  const { isMobile } = useViewMode();
  const { syncReaderParaAndSearchShortcuts } = useSearchContext();

  return (
    <div className="shortcut-content-top-panel">
      <ButtonSwitchToTextSearch rightPanel={rightPanel} />
      <MinNavPanel hideBtnFeaturedSearch />
      {!isMobile && (
        <IconButton
          icon={EgwWebFont["search-book"]}
          onClick={() => {
            history.push(URLS.search);
          }}
        />
      )}
      {isReader && (
        <IconButton
          icon={EgwWebFont.restart}
          onClick={() => {
            clearTimeout(timerLoadRef.current);
            syncReaderParaAndSearchShortcuts();
          }}
        />
      )}
    </div>
  );
};

ShortcutPanelButtons.propTypes = {
  rightPanel: PropTypes.bool,
};

export default ShortcutPanelButtons;
