import React, { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useDialogContext } from "src/components/dialog/DialogContext";
import EditWorkspaceDialog from "src/components/reader/workspaces/EditWorkspaceDialog";
import NewWorkspaceDialog from "src/components/reader/workspaces/NewWorkspaceDialog";
import {
  checkPrefix,
  SAVE_WORKSPACE_BEHAVIOURS,
} from "src/components/reader/workspaces/WorkspaceUtils";
import {
  actionCreateWorkspace,
  actionDeleteWorkspace,
  actionUpdateWorkspace,
} from "src/redux/actions";

const useReaderWorkspaces = ({ currentLayout, panelIds }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showDialog, showConfirm } = useDialogContext();

  const isSavedWorkSpace = useCallback(
    (ws) => !!ws && panelIds.join(",") === checkPrefix(ws.ids) && currentLayout === ws.layoutId,
    [currentLayout, panelIds],
  );

  const createWorkspace = useCallback(
    (title) => {
      dispatch(
        actionCreateWorkspace({
          id: uuid(),
          title,
          ids: panelIds.join(","),
          layoutId: currentLayout,
        }),
      );
    },
    [currentLayout, panelIds],
  );

  const updateWorkspace = useCallback(
    (item) => {
      dispatch(
        actionUpdateWorkspace({
          ...item,
          ids: panelIds.join(","),
          layoutId: currentLayout,
        }),
      );
    },
    [currentLayout, panelIds],
  );

  const renameWorkspace = useCallback((item, title) => {
    dispatch(
      actionUpdateWorkspace({
        ...item,
        title,
      }),
    );
  }, []);

  const deleteWorkspace = useCallback((item) => {
    dispatch(actionDeleteWorkspace(item.id));
  }, []);

  const showSaveWorkspaceDialog = useCallback(
    (item) => {
      showConfirm(
        "saveWorkspaceTitle",
        t("saveWorkspaceMessage", { title: item.title }),
        () => {
          updateWorkspace(item);
        },
        { positiveBtn: "save", negativeBtn: "cancel" },
      );
    },
    [showConfirm, updateWorkspace, t],
  );

  const showRenameWorkspaceDialog = useCallback(
    (item) => {
      showDialog(<EditWorkspaceDialog item={item} />);
    },
    [showDialog],
  );

  const showCreateWorkspaceDialog = useCallback(
    (handleConfirmCreateWS = null) => {
      showDialog(
        <NewWorkspaceDialog
          panelIds={panelIds}
          currentLayout={currentLayout}
          onConfirmCreateWS={handleConfirmCreateWS}
        />,
      );
    },
    [showDialog, currentLayout, panelIds],
  );

  const showDeleteWorkspaceDialog = useCallback(
    (item) => {
      showConfirm(
        "deleteWorkspaceTitle",
        t("deleteWorkspaceMessage", { title: item.title }),
        () => {
          deleteWorkspace(item);
        },
        { positiveBtn: "delete", negativeBtn: "cancel" },
      );
    },
    [showConfirm, deleteWorkspace, t],
  );

  const handleDoNotAsc = useCallback(
    (behaviour, data) => {
      switch (behaviour) {
        case SAVE_WORKSPACE_BEHAVIOURS.CREATE_NEW:
          showCreateWorkspaceDialog(data.handleDialogClosed);
          return false;

        case SAVE_WORKSPACE_BEHAVIOURS.SAVE:
          updateWorkspace(data.activeWS);
          break;

        default:
          break;
      }

      return true;
    },
    [showCreateWorkspaceDialog, updateWorkspace],
  );

  return {
    createWorkspace,
    updateWorkspace,
    renameWorkspace,
    deleteWorkspace,

    showCreateWorkspaceDialog,
    showSaveWorkspaceDialog,
    showRenameWorkspaceDialog,
    showDeleteWorkspaceDialog,

    handleDoNotAsc,
    isSavedWorkSpace,
  };
};

export default useReaderWorkspaces;
