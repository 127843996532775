import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { DotsVerticalIcon } from "../../assets/CommonIcons";
import {
  getControlPanelIcons,
  getIconsSorted,
  renderColumnIcons,
  renderIcons
} from "../../utils/Panel.utils";
import { IconButton } from "../views";
import { useViewMode } from "../../hooks";
import TextButton from "../views/TextButton";
import { commonPopupKeyDownEvent, selectorFocusableElements } from "../../utils/AccessibilityUtils";


// TODO need to reimplemnt this comonent
const ThreeDotsView = ({
  icons = [],
  closeOnAnyChange,
  isCollapsed,
  parentWidth = 0,
  initialWidth,
  title,
  threeDotsClassName = "",
  ...props
}) => {
  const { t } = useTranslation();
  const { isMobile, zoom, textMode } = useViewMode();
  const popupRef = useRef(null);
  const defaultActiveElementRef = useRef(null);
  const [show, setShow] = useState(false);

  const { hiddenIcons, visibleIcons, showHiddenIcons, showVisibleIcons } = getControlPanelIcons(
    icons,
    {
      isCollapsed: isCollapsed ?? (textMode || isMobile),
      parentWidth,
      initialWidth,
      zoom
    }
  );

  const onKeyDownHandler = (event) => {
    commonPopupKeyDownEvent(
      event,
      event.target.closest(".three-dots-view_popup"),
      () => {
        setShow(false);
        focusAfterClosing();
      }
    );
  };

  const focusAfterClosing = () => {
    setTimeout(() => {
      if (defaultActiveElementRef.current) {
        defaultActiveElementRef.current.focus();
        defaultActiveElementRef.current = null;
      }
    }, 0);

    if (popupRef.current) {
      popupRef.current.removeEventListener("keydown", onKeyDownHandler);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const currentActiveElement = document.activeElement;
        if (currentActiveElement
          && !currentActiveElement.closest(".three-dots-view_popup")
          && !defaultActiveElementRef.current
        ) {
          defaultActiveElementRef.current = currentActiveElement;
        }

        if (popupRef.current) {
          const firstFocusableElement = popupRef.current.querySelector(selectorFocusableElements);
          popupRef.current.addEventListener("keydown", onKeyDownHandler);

          if (firstFocusableElement) {
            firstFocusableElement.focus();
          }
        }
      }, 150);
    }

    return () => focusAfterClosing();
  }, [show]);

  useEffect(() => {
    focusAfterClosing();
    setShow(false);
  }, [closeOnAnyChange]);


  const threeDotsButton = <IconButton
    className={classNames(threeDotsClassName)}
    active={show}
    icon={DotsVerticalIcon}
    onClick={() => setShow(!show)} />;

  const threeDotsTextButton = <TextButton
    className={classNames("show-popup-text-button", threeDotsClassName, { active: show })}
    onClick={() => setShow(!show)}
  >
    {title || t("reader_tools")}
  </TextButton>;

  const readerConditionButton = (condition = false) => {
    if (!showHiddenIcons) {
      return null;
    }

    return condition ? threeDotsButton : threeDotsTextButton;
  };

  const readerTextButton = () => {
    if (!showHiddenIcons) {
      return null;
    }

    return threeDotsTextButton;
  };

  const renderButton = () => {
    if (!showHiddenIcons) {
      return null;
    }

    return threeDotsButton;
  };

  const hiddenClassName = classNames("three-dots-view_popup", (show ? "showView" : "hideView"));

  return (
    <React.Fragment>
      {show && <div
        className="dropDownMenuBack"
        aria-describedby={t("close")}
        onClick={() => setShow(false)}
      />}
      {props?.render(
        visibleIcons,
        hiddenIcons,
        {
          setShow,
          readerConditionButton: (condition) => readerConditionButton(condition),
          renderButton: () => renderButton(),
          readerTextButton: () => readerTextButton(),
          showHiddenIcons,
          showVisibleIcons,
          hiddenClassName,
          renderColumnIcons,
          getIconsSorted,
          renderIcons,
          popupRef
        }
      )}
    </React.Fragment>
  );
};

ThreeDotsView.propTypes = {
  render: PropTypes.func.isRequired,
  parentWidth: PropTypes.number.isRequired,
  icons: PropTypes.array.isRequired,
  initialWidth: PropTypes.number,
  isCollapsed: PropTypes.bool,
  closeOnAnyChange: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  threeDotsClassName: PropTypes.string
};

export default ThreeDotsView;
