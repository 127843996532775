import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  ThemeDayIcon,
  ThemeDawnIcon,
  ThemeDuskIcon,
  ThemeDarkIcon,
  ThemeHumanWhiteIcon,
  ThemeHumanBlackIcon
} from "../../assets/CommonIcons";
import { activeTheme, THEME_MODE } from "../../utils/ThemeUtils";
import { useDispatch, useSelector } from "react-redux";
import { useViewMode } from "../../hooks";
import ViewMode from "../views/ViewMode";
import { actionUpdateTheme } from "../../redux/actions";
import "./ThemeMenuView.scss";
import { IconButton } from "../views";

const ThemeMenuView = ({ className, compactLayout }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile, textMode } = useViewMode();
  const themeMode = useSelector(({ settings }) => settings.themeMode);

  const handleThemeClick = (theme) => {
    dispatch(
      actionUpdateTheme({
        theme,
        isMobile
      })
    );
    activeTheme(theme);
  };

  const makeIcon = (icon, theme) => {
    return (
      <button
        onClick={() => handleThemeClick(theme.id)}
        className={classNames("theme-menu-button", { "theme-active": themeMode === theme.id })}>
        {!textMode && (
          <IconButton
            icon={icon}
            className={`theme-${theme.id}`}
            ariaLabel={t(theme.id)}
            title={t(theme.label)}
          />
        )}
        <span className="theme-title">{t(theme.label)}</span>
      </button>
    );
  };

  if (compactLayout) {
    return (
      <div className={classNames("themeMenu", className)}>
        <div className="themeMenu__row">
          <div className={classNames("themeMenu__column", className)}>
            {makeIcon(ThemeDayIcon, THEME_MODE.dy)}
            {makeIcon(ThemeDawnIcon, THEME_MODE.dw)}
          </div>
          <div className={classNames("themeMenu__column", className)}>
            {makeIcon(ThemeDuskIcon, THEME_MODE.dk)}
            {makeIcon(ThemeDarkIcon, THEME_MODE.dm)}
          </div>
        </div>
        {makeIcon(ThemeHumanBlackIcon, THEME_MODE.adm)}
        {makeIcon(ThemeHumanWhiteIcon, THEME_MODE.ady)}
        <div className="theme-menu__view-mode-items">
          <ViewMode />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("themeMenu", className)}>
      {makeIcon(ThemeDayIcon, THEME_MODE.dy)}
      {makeIcon(ThemeDawnIcon, THEME_MODE.dw)}
      {makeIcon(ThemeDuskIcon, THEME_MODE.dk)}
      {makeIcon(ThemeDarkIcon, THEME_MODE.dm)}
      {makeIcon(ThemeHumanBlackIcon, THEME_MODE.adm)}
      {makeIcon(ThemeHumanWhiteIcon, THEME_MODE.ady)}
      <div className="theme-menu__view-mode-items">
        <ViewMode />
      </div>
    </div>
  );
};

ThemeMenuView.propTypes = {
  className: PropTypes.string,
  compactLayout: PropTypes.bool
};

export default ThemeMenuView;
