import { CONTENT_CLASSES } from "src/shared/utils/content";
import EgwWebFont from "./EgwWebFont";
import { BIBLE_TYPE_CONCORDANCES } from "../utils/ContentUtils";

export const getImageByClass = (className, darkTheme = false) => {
  // As ussualy name of classname is same as icon name
  let iconValue = EgwWebFont[className];

  if (!iconValue) {
    switch (className) {
      case "manuscript-releases":
        return EgwWebFont.manuscript;
      case "EGW":
      case "biography":
        return darkTheme ? EgwWebFont["ellen-dark"] : EgwWebFont["ellen-light"];
      case "apl":
        return EgwWebFont["adventist-library"];
      case "bible":
      case "bibleCommentary":
      case "bible-versions":
        return EgwWebFont.bible;
      case BIBLE_TYPE_CONCORDANCES:
        return EgwWebFont.concordances;
      case "bible-commentaries":
        return EgwWebFont.commentaries;
      case "bible-dictionaries":
        return EgwWebFont.dictionaries;
      case "bible-sdasi":
        return EgwWebFont["sda-scripture-Index"];
      case "devotionals":
      case "devotional":
        return EgwWebFont.devotionals;
      case "egwwritings":
      case "modern-english":
        return EgwWebFont["modern-english"];
      case "chapter":
      case "para":
        return EgwWebFont.content;
      case "books":
        return EgwWebFont.books;
      case "book":
      case CONTENT_CLASSES.TITLES_BOOK:
      case CONTENT_CLASSES.CATEGORIES_BOOK:
        return EgwWebFont["book-open"];
      case "info":
        return EgwWebFont.information;
      case "donate":
        return EgwWebFont.donate;
      case "all-collections":
        return EgwWebFont["all-collections"];
      case "study-center":
        return EgwWebFont["study-center"];
      case "subscription":
        return EgwWebFont.subscription;
      case "faq":
        return EgwWebFont.faq;
      case "contact-us":
        return EgwWebFont["contact-us"];
      case "settings":
        return EgwWebFont.settings;
      case "category":
        return null;
      case CONTENT_CLASSES.CATEGORIES_ROOT:
        return EgwWebFont.tree;
      case CONTENT_CLASSES.TITLES_ROOT:
        return EgwWebFont.titles;
      default:
        return EgwWebFont["folder-close"];
    }
  }
  return iconValue || EgwWebFont["folder-close"];
};
