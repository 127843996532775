import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getBoundingClientRect } from "../../utils/Utils";
import { containsUrl } from "../../utils/URLUtils";
import { commonPopupKeyDownEvent, selectorFocusableElements } from "../../utils/AccessibilityUtils";
import { makeMessageText, normilizeUrl, ShareActions } from "../../shared/utils/share";
import { IconButton } from "../views";

const initState = {
  isOpen: false,
  position: { top: -1000, left: -1000 },
  shareUrl: "",
  shareText: "",
  onClose: () => {},
  onShareButtonClick: () => {},
  closeOnBack: true,
  bounds: {},
  parentBounds: undefined,
  hideOnLeave: false,
  shareData: {
    url: undefined
  }
};

const SHARE_VIA_HTML_ATTRS_CN = "socialsBtn";

class ShareWrap extends React.Component {
  constructor(props) {
    super();
    this.popupRef = React.createRef();
    this.state = { ...initState, ...props };
    this.textMode = null;
    this.defaultActiveElementRef = React.createRef();
    this.focusOnOpen = this.focusOnOpen.bind(this);
    this.onPopupContainerKeyDown = this.onPopupContainerKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  calculateOptimalPosition = (newState) => {
    const { width: areaWidth, height: areaHeight } = getBoundingClientRect(this.popupRef.current);
    const { innerWidth, innerHeight } = window;
    const { bounds, parentBounds } = newState;

    const parentRight = parentBounds ? parentBounds.right : innerWidth;
    const parentBottom = parentBounds ? parentBounds.bottom : innerHeight;

    let position = {
      top: bounds.bottom + 10,
      left: bounds.left
    };

    if (position.left + areaWidth > parentRight) {
      let left = parentRight - areaWidth;
      if (left < 0) {
        left = 0;
      }
      position.left = left;
    }

    if (position.top + areaHeight > parentBottom) {
      let top = parentBottom - areaHeight;
      if (top < 0) {
        top = 0;
      }
      position.top = top;
    }

    return position;
  };

  show = (newState) => {
    const position = this.calculateOptimalPosition(newState);
    this.setState({ ...initState, isOpen: true, position, ...newState }, () => {
      this.focusOnOpen();
    });   
  };

  hide = (back) => {
    const { onClose, closeOnBack } = this.state;
    if ((onClose && !back) || (onClose && closeOnBack)) {
      onClose(false);
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({ ...initState }, () => {
      if (this.defaultActiveElementRef.current) {
        this.defaultActiveElementRef.current.focus();
        this.defaultActiveElementRef.current = null;
      }
    });
  };

  focusOnOpen() {
    setTimeout(() => {
      const currentActiveElement = document.activeElement;
      if (
        currentActiveElement &&
        !currentActiveElement.closest(".share-popup-container") &&
        !this.defaultActiveElementRef.current
      ) {
        this.defaultActiveElementRef.current = currentActiveElement;
      }

      const firstFocusableElement = this.popupRef.current.querySelector(selectorFocusableElements);

      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }, 150);
  }

  onPopupContainerKeyDown(event) {
    commonPopupKeyDownEvent(event, event.target.closest(".share-popup-container"), () =>
      this.hide()
    );
  }

  render() {
    const { isOpen, position, hideOnLeave, shareData, onShareButtonClick } = this.state;

    const popupClassNames = classNames("popup-container", isOpen ? "showView" : "hideView");

    let { url, refcode, imgUrl, text, copyText } = shareData;

    if (!url) {
      url = normilizeUrl(window.location.pathname + window.location.search);
    }

    if (!containsUrl(url)) {
      url = normilizeUrl(url);
    }

    shareData.url = url;
    const shareText = makeMessageText(300 - url.length, "", refcode, text);

    return (
      <React.Fragment>
        {this.props.children}
        <div
          onKeyDown={this.onPopupContainerKeyDown}
          ref={this.popupRef}
          className={popupClassNames}
          style={{ ...position }}
          onMouseLeave={() => {
            if (hideOnLeave) {
              this.hide();
            }
          }}>
          <div className="share-popup-container">
            {ShareActions.map((item, index) => {
              const buttonProps = {
                title: item.title,
                className: item.shareViaHtmlAttrs ? SHARE_VIA_HTML_ATTRS_CN : "",
                "data-media": imgUrl || undefined,
                "data-service": item.service,
                "data-url": url,
                "data-title": shareText,
                tabIndex: 0,
                onClick: () => {
                  if (item.justCopy) {
                    if (copyText) {
                      this.props.onCopyToClipboard(decodeURIComponent(shareText));
                    } else {
                      this.props.onCopyToClipboard(url);
                    }
                  } else if (item.action) {
                    onShareButtonClick();
                    item.action(shareData);
                    this.hide();
                  }
                }
              };
              return <IconButton key={index} icon={item.icon} {...buttonProps} />;
            })}
          </div>
          <div
            className={"dropDownMenuBack" + (isOpen ? " show" : " hide")}
            onClick={() => this.hide(true)}
          />
        </div>
      </React.Fragment>
    );
  }
}

ShareWrap.propTypes = {
  children: PropTypes.node,
  onCopyToClipboard: PropTypes.func,
  onClose: PropTypes.func
};

export default ShareWrap;
