import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import "./Badge.scss";

const Badge = ({ badgeContent, className, children }) => {
  if (!badgeContent) {
    return children;
  }
  return (
    <div className="badge">
      {children}
      <BadgeBase badgeContent={badgeContent} className={className} />
    </div>
  );
};

const BadgeBase = ({ badgeContent, className }) => {
  if (typeof badgeContent === "number") {
    const label = badgeContent > 99 ? "99+" : badgeContent;
    return <span className={classNames("badge__label bottom right", className)}>{label}</span>;
  }
  return <span className={classNames("badge__label bottom right", className)}>{badgeContent}</span>;
};

Badge.propTypes = {
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  children: PropTypes.node,
};

BadgeBase.propTypes = {
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default Badge;
