import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { useBooksSelect } from "src/redux/selectors";
import { Accordion } from "../views";
import { PriceDescription } from "../views/Price";
import BookListWithArrows from "./BookListWithArrows";
import BlockProvidedBy from "./BlockProvidedBy";
import EgwWebFont from "src/assets/EgwWebFont";
import IconView from "../../shared/components/IconView";

import "./ShopBookPackage.scss";

const ShopBookPackage = ({ book, shopBooks, shopPackage, packageShopBookIds }) => {
  const { t } = useTranslation();
  const booksFromPackage = useBooksSelect(packageShopBookIds);
  const shopBook = shopBooks[book.book_id];

  if (packageShopBookIds.length < 2) {
    return (
      <div className="book-package-container">
        <div className="book-package_head is-single-book">
          <PriceDescription
            discountPrice={shopBook.price}
            fullPrice={shopBook.full_price}>
            <IconView icon={EgwWebFont["book-close"]} />
          </PriceDescription>
          <BlockProvidedBy publisher={book.publisher} />
        </div>
      </div>
    );
  }

  const priceBookInSet = parseFloat(shopPackage.price / packageShopBookIds.length).toFixed(2);

  const renderHead = (elementChevron) => {
    if (shopBook.is_purchased) {
      return (
        <>
          <BlockProvidedBy publisher={book.publisher} />
          <div className="book-package_price-with-arrow">
            <span>{booksFromPackage.length + " " + t("booksInSet")}</span>
            {elementChevron}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="book-package_head_top">
          <div className="book-package_prices">
            <PriceDescription
              description={t("buyBookCount", { count: 1 })}
              discountPrice={shopBook.price}
              fullPrice={shopBook.full_price}>
              <IconView icon={EgwWebFont["book-close"]} />
            </PriceDescription>
            <div className="book-package_price-with-arrow">
              <PriceDescription
                description={t("buyBooksCount", { count: booksFromPackage.length })}
                discountPrice={shopPackage.price}
                fullPrice={shopPackage.full_price}>
                <IconView icon={EgwWebFont["book-stack"]} />
              </PriceDescription>
              {elementChevron}
            </div>
          </div>
          <BlockProvidedBy publisher={book.publisher} />
        </div>
        <PriceDescription
          className="price-for-one-book"
          description={t("priceForOneBookInSet")}
          discountPrice={priceBookInSet}
          fullPrice={shopBook.full_price}
        />
      </>
    );
  };

  return (
    <Accordion
      isChevronSecondary
      className="book-package-container"
      headClassName={classnames("book-package_head", {
        "purchased": shopBook.is_purchased,
      })}
      title={renderHead}
    >
      <BookListWithArrows books={booksFromPackage} />
    </Accordion>
  );
};

ShopBookPackage.propTypes = {
  book: PropTypes.object,
  shopBooks: PropTypes.object,
  shopPackage: PropTypes.object,
  packageShopBookIds: PropTypes.array
};

export default ShopBookPackage;
