import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withResizeDetector } from "react-resize-detector/build/withPolyfill";

import { SearchExampleView } from "./SearchExampleView";
import { usePopupContext } from "../popup/PopupContext";
import { validateSearchQuery } from "../../utils/SearchUtils";
import { useNavigationContext } from "../NavigationContext";
import { actionAddMessage, setPlayerParams } from "../../redux/actions";
import { useHotKey, useOutsideEvent, usePopupId, useViewMode } from "../../hooks";
import { SearchActions } from "../search/search.actions";
import SearchFilterPopup from "./SearchFilterPopup";
import { Hotkeys, isEventKey, KeyCodes } from "../../shared/utils/dom";
import { clearSelection, getBoundingClientRect } from "../../utils/Utils";
import { useDefLang, useLoader } from "../../redux/selectors";
import { useAdvSearch } from "../../hooks/navigation.hooks"; 
import { IconButton } from "../views";
import { LOADER_SUGGESTION_ID, SearchItemType } from "src/shared/utils/search";
import EgwWebFont from "src/assets/EgwWebFont";
import { logSearchEvent, SearchLogEvents } from "src/shared/api/analitics";
import { useRemToPx } from "../../hooks/viewModeHooks";
import { useSearchContext } from "../search/SearchContext";
import sassVariables from "./Header.variables.module.scss";
import { URLS } from "../../shared/utils/url";

import "./SearchHeader.scss";

const EXAMPLE_SEARCH_POPUP_ID = "exampleSearchPopup";
const POPUP_ID = "advSearchLang";

const SearchHeader = ({ width, headerHeight }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAdvSearch, toggleAdvSearch } = useAdvSearch();
  const { isReader } = useNavigationContext();
  const { t } = useTranslation();
  const { isMobile } = useViewMode();
  const remToPx = useRemToPx();
  const isShowPopup = usePopupId(POPUP_ID);
  const searchWrapRef = useRef();
  const searchInput = useRef();
  const searchStartRef = useRef();
  const searchSuggestionTimerRef = useRef();
  const { showPopup, hidePopup } = usePopupContext();
  const isSuggestLoads = useLoader(LOADER_SUGGESTION_ID);

  const detect = useSelector((state) => state.search.searchFilter.detect);
  const searchLang = useSelector((state) => state.search.searchLang);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const rightPanelPosition = useSelector((state) => state.search.rightPanelPosition);
  const isShowFilterSearch = useSelector((state) => state.search.isShowFilterSearch);
  const isShowHeaderSearch = useSelector((state) => state.search.isShowHeaderSearch);

  const isShowExampleSearchPopup = usePopupId(EXAMPLE_SEARCH_POPUP_ID);

  const defLang = useDefLang();

  // inner state of search value
  const [searchInnerValue, setSearchInnerValue] = useState(searchQuery || "");
  const [autoSelectSearch, setAutoSelectSearch] = useState(false);
  const [inputFocused, setInputFocused] = useState(true);
  const [lastPosition, setLastPosition] = useState(0);

  const currentSearchLang = React.useMemo(() => {
    if (detect.length > 0) {
      if (detect.includes(searchLang)) {
        return searchLang;
      }
      return detect[0];
    }
    return defLang;
  }, [detect, searchLang, defLang]);

  useEffect(() => {
    // clear selection when component is unmount
    return () => {
      clearSelection();
    };
  }, []);

  const { makeSearch, updateSearchURL, openSearchHistory } = useSearchContext();

  useHotKey(Hotkeys.focusSearch.keyCode, () => {
    focusChanged(true);
    return true;
  });

  useEffect(() => {
    // start search on mobile 
    if (!isShowHeaderSearch && isMobile && !isReader && searchInnerValue) {
      makeSearch({ query: searchInnerValue });
    }
  }, [isShowHeaderSearch]);

  useEffect(() => {
    if (searchQuery && searchQuery !== searchInnerValue) {
      setSearchInnerValue(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    // start search after end loading of suggestions
    if (!isSuggestLoads && searchStartRef.current) {
      let newLang = detect[0];
      if (detect.includes(defLang)) {
        newLang = defLang;
      }
      onFilterSearch(searchStartRef.current, false, newLang);
      searchStartRef.current = undefined;
    }
  }, [isSuggestLoads]);

  useEffect(() => {
    // make search in suggestions on change search value
    clearTimeout(searchSuggestionTimerRef.current);
    searchSuggestionTimerRef.current = setTimeout(() => {
      searchSuggestionTimerRef.current = undefined;
      if (searchInnerValue) {
        dispatch(SearchActions.fetchSuggestion(searchInnerValue, currentSearchLang, true));
      } else {
        dispatch(SearchActions.changeRelatedLang(defLang));
        dispatch(SearchActions.fetchSuggestionSuccess());
      }
    }, 250);
    return () => {
      clearTimeout(searchSuggestionTimerRef.current);
    };    
  }, [searchInnerValue, currentSearchLang]);

  useOutsideEvent({
    eventType: "mousedown",
    ref: searchWrapRef,
    specialCondition: !isShowPopup,
    callback: () => {
      hideFilter();
    },
  });

  useOutsideEvent({
    eventType: "wheel",
    ref: searchWrapRef,
    specialCondition: !isShowPopup,
    callback: () => {
      hideFilter();
    },
  });
  
  const showFilter = () => {
    setLastPosition(rightPanelPosition);
    dispatch(SearchActions.setShowFilterSearch(true));
  };

  const hideFilter = () => {
    if (isShowFilterSearch) {
      dispatch(SearchActions.setShowFilterSearch(false));
    }
  };

  const onFilterSearch = (search, type, newLang) => {
    if (search && type === SearchItemType.RECENT) {
      openSearchHistory(search);
      return;
    }

    if (search) {
      let isSuggest =
        type === SearchItemType.SUGGESTION ||
        type === SearchItemType.CORRECTION ||
        type === SearchItemType.RECENT ||
        type === "translate";

      dispatch(setPlayerParams({ isSmallPlayerExpanded: false }));
      if (type && type !== "translate") {
        focusChanged(true);
      }
      dispatch(SearchActions.setSearchQuery(search));
      const searchData = {
        query: search,
        isSuggest,
        newLang,
      };
      if (type === "translate") {
        searchData.type = "translate";
      } else if (type === "onlyText") {
        searchData.type = "onlyText";
        setSearchInnerValue(search);
      } else if (type !== "init") {
        setSearchInnerValue(search);
      }
      makeSearch(searchData);
    }
  };

  useEffect(() => {
    if (isMobile && lastPosition > 0 && rightPanelPosition !== lastPosition) {
      setLastPosition(rightPanelPosition);
    }
  }, [rightPanelPosition]);

  const handleChangeSearch = (event) => {
    let value = event.target.value;
    setSearchInnerValue(value);
    if (value && !isShowFilterSearch) {
      dispatch(SearchActions.setShowFilterSearch(true));
    }
  };

  const handleInputFocus = (event) => {
    if (searchInnerValue !== "") {
      setAutoSelectSearch(true);
      if (isMobile) {
        focusChanged(true);
      }
    } else {
      setAutoSelectSearch(false);
    }
    showFilter();
  };

  const focusChanged = (focused) => {
    if (focused) {
      const searchInputElement = searchInput.current;
      if (searchInputElement) {
        dispatch(SearchActions.setShowFilterSearch(true));
        if (autoSelectSearch || isMobile) {
          searchInputElement.select();
          setAutoSelectSearch(false);
        } else if (!autoSelectSearch) {
          searchInputElement.selectionStart = searchInputElement.value.length;
          searchInputElement.selectionEnd = searchInputElement.value.length;
          searchInputElement.focus();
        }
      }
    } else {
      searchInput.current?.blur();
    }
  };

  const handleQuestionClick = (event) => {
    const targetRect = getBoundingClientRect(event.target);
    const bottom = remToPx(
      isMobile ? sassVariables.headerMobileHeight : sassVariables.headerTabletHeight,
    );
    // TODO change to show simple component without popup
    showPopup(
      {
        left: isMobile ? 0 : targetRect.left,
        right: isMobile ? window.innerWidth : targetRect.right,
        top: bottom,
        bottom: bottom,
      },
      <SearchExampleView
        onClose={() => {
          hidePopup();
        }}
      />,
      {
        popupId: EXAMPLE_SEARCH_POPUP_ID,
        popupProps: {
          className: "search-example-view__popup",
        },
        fitSizes: true,
      },
    );
  };

  const handleClear = () => {
    if (!isReader) {
      history.replace(URLS.home);
    }
    setSearchInnerValue("");

    dispatch(SearchActions.setSearchQuery(""));
    dispatch(SearchActions.fetchSuggestionSuccess());
    dispatch(SearchActions.setSearchNeedleChange(""));
    // Uncoment for close search on mobile on clear click if needed
    // if (isMobile) {
    //  dispatch(SearchActions.setShowFilterSearch(false));
    // }
  };

  const executeSearch = () => {
    if (searchSuggestionTimerRef.current) {
      searchStartRef.current = searchInnerValue;
      clearTimeout(searchSuggestionTimerRef.current);
      searchSuggestionTimerRef.current = undefined;
      if (searchInnerValue) {
        dispatch(SearchActions.fetchSuggestion(searchInnerValue, currentSearchLang, true));
      }
      focusChanged(false);
      hideFilter();
      return;
    } else if (isSuggestLoads) {
      clearTimeout(searchSuggestionTimerRef.current);
      searchStartRef.current = searchInnerValue;
      focusChanged(false);
      hideFilter();
      return;
    }

    if (!searchInnerValue.length) {
      dispatch(actionAddMessage(t("please_enter_search_value")));
    } else if (!validateSearchQuery(searchInnerValue)) {
      dispatch(actionAddMessage(t("wrong_search_syntax")));
    } else {
      let newLang = detect[0];
      if (detect.includes(defLang)) {
        newLang = defLang;
      }
      onFilterSearch(searchInnerValue, false, newLang);
      focusChanged(false);
    }
    hideFilter();
  };

  const onInputKeyDownHandler = (event) => {
    if (isEventKey(event, KeyCodes.esc)) {
      focusChanged(false);
      hideFilter();
    }
    if (isEventKey(event, KeyCodes.enter)) {
      executeSearch();
      if (event) {
        event.preventDefault();
      }
    }
    if (!(event.ctrlKey || event.metaKey) && isEventKey(event, KeyCodes.downArrow)) {
      handleSetInputFocused(false);
      event.preventDefault();
    }
  };

  const handleChangeDetectedLang = (lang) => {
    logSearchEvent(SearchLogEvents.SEARCH_CHANGE_LANG, {
      lang,
    });

    dispatch(SearchActions.setSearchQuery(searchInnerValue, lang));
    dispatch(SearchActions.fetchPossibleTranslate(lang));
    makeSearch({ query: searchInnerValue, newLang: lang });
  };

  const handleChangeLang = (lang) => {
    dispatch(SearchActions.setSearchQuery(searchInnerValue, lang)); 
    updateSearchURL({ lang: lang });
    makeSearch({ type: "translate", query: searchInnerValue, newLang: lang });
  };

  const handleSetInputFocused = (focus) => {
    if (searchInput.current) {
      if (focus) {
        searchInput.current.focus();
        setInputFocused(true);
      } else {
        searchInput.current.blur();
        setInputFocused(false);
      }
    }
  };

  return (
    <div className="header-search-wrap" ref={searchWrapRef}>
      <div role="search" className={classNames("search-wrap-base", "search-wrap-base-header")}>
        {!isMobile && (
          <IconButton
            icon={EgwWebFont.filter}
            tabIndex={0}
            active={isAdvSearch}
            title={t("filter")}
            className="search-icon search-filter-icon"
            onClick={toggleAdvSearch}
          />
        )}
        <input
          ref={searchInput}
          value={searchInnerValue}
          onChange={handleChangeSearch}
          className="searchField searchLong"
          onFocus={(event) => {
            handleInputFocus(event);
          }}
          onClick={(event) => {
            if (!isShowFilterSearch) {
              handleInputFocus(event);
            }
          }}
          onBlur={() => {
            document.activeElement?.blur();
          }}
          type="search"
          onKeyDown={onInputKeyDownHandler}
          placeholder={t("search")}
        />
        <span className={"search-button-wrap"}>
          {searchInnerValue && (
            <IconButton
              icon={EgwWebFont.close}
              className="searchButton search-icon"
              title={t("clear")}
              onClick={handleClear}
            />
          )}
          <IconButton
            disabled={isSuggestLoads}
            icon={EgwWebFont.search}
            className="searchButton search-icon search-show-search-icon"
            title={t("search")}
            onClick={() => {
              executeSearch();
            }}
          />

          {!isMobile && (
            <IconButton
              icon={EgwWebFont.question}
              title={t("search_templates")}
              active={isShowExampleSearchPopup}
              className={"search-icon search-guide-icon"}
              onClick={handleQuestionClick}
            />
          )}
        </span>
      </div>
      {isShowFilterSearch && (
        <SearchFilterPopup
          headerHeight={headerHeight}
          parentWidth={width}
          hideFilter={hideFilter}
          setInputFocused={handleSetInputFocused}
          setSearchInputValue={(data) => {
            if (data && data !== "" && searchInput.current) {
              searchInput.current.value = data;
            }
          }}
          onFilterSearch={(value, type, lang) => {
            onFilterSearch(value || searchInnerValue, type, lang);
          }}
          inputFocused={inputFocused}
          search={searchInnerValue}
          detectedLang={currentSearchLang}
          onChangeDetectedLang={handleChangeDetectedLang}
          onChangeLang={handleChangeLang}
        />
      )}
    </div>
  );
};

SearchHeader.propTypes = {
  width: PropTypes.number,
  headerHeight: PropTypes.number,
};

export default withResizeDetector(SearchHeader);
