/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { libraryBooksSelector } from "../redux/selectors";
import { actionDeleteLibraryLanguage } from "../redux/params.actions";
import { setLibLangs } from "../redux/library.actions";
import { useDialogContext } from "../components/dialog/DialogContext";
import {
  isMobile as isRealMobile,
  isTablet as isRealTablet,
  isMobileOnly as isRealMobileOnly,
} from "react-device-detect";
import { getBookId } from "../shared/utils/content";
import { uiLanguages } from "../shared/utils/i18n";
import { actionUpdateSetting } from "src/redux/actions";
import { Settings } from "src/utils/Settings";
import { sessionStorageSafe, localStorageSafe } from "../shared/utils/systemUtils";
import { isDevelopment } from "../shared/utils/assembly";

export { default as useOrientationCheck } from "./useOrientationCheck";
export { useSafariCheck } from "./useSafariCheck";
export { useDebouncedEffect } from "./useDebouncedEffect";
export { default as useOrientationChange } from "./useOrientationChange";
export { default as useTextEditor } from "./useTextEditor";
export { default as useSortList } from "./useSortList";
export { default as useKeyboard, useHotKey } from "./useKeyboard";
export { default as useSearchList } from "./useSearchList";
export { default as usePortal } from "./usePortal";
export { default as useAuth } from "./useAuth";
export { default as useOutsideEvent } from "./useOutsideEvent";
export { default as useFullScreen } from "./useFullScreen";
export { useSubscriptionDialogs } from "./navigation.hooks";

export {
  useViewMode,
  useSetScreenType,
  useGridDimensions,
  useResizeDimensions,
  useSetViewMode,
  useTheme,
} from "./viewModeHooks";

/**
 * @see {@link https://usehooks.com/useWhyDidYouUpdate/}
 * @param {*} name of component for debug
 * @param {*} props all props what need to check
 */
export function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length && isDevelopment()) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

/**
 * @description Translates labels of "uiLanguages" from "Utils.js"
 * from base language to the user's current one.
 * @returns {[{
 *   id: number,
 *   key: string,
 *   label: string,
 * }]}
 * id - language id,
 * key - language string,
 * label - language string translated.
 */
export const useUiLanguagesTranslated = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return uiLanguages.map((language) => {
      return {
        ...language,
        label: t(language.key),
      };
    });
  }, [t]);
};

export const useDeviceDetect = () => {
  return {
    isRealMobile,
    isRealTablet,
    isRealMobileOnly,
    isRealMobileOrTablet: isRealMobile || isRealTablet,
  };
};

export const useLangUpdate = () => {
  const books = useSelector(libraryBooksSelector);
  const { showConfirm } = useDialogContext();
  const dispatch = useDispatch();
  const libraryLanguages = useSelector((state) => state.settings.libraryLanguages);
  const { t } = useTranslation();

  const updateLang = useCallback(
    (lang, callback) => {
      const foundLang = libraryLanguages.find((item) => item === lang);
      if (foundLang) {
        const booksToDelete = books.filter((item) => item.lang === lang);
        if (booksToDelete.length > 0) {
          showConfirm(
            t("warning"),
            t("removeFromCloud", { amount: booksToDelete ? booksToDelete.length : 0 }),
            () => {
              libraryLanguages.splice(libraryLanguages.indexOf(booksToDelete[0].bookInfo.lang), 1);
              // dispatchLangFun(libraryLanguages);
              dispatch(setLibLangs(libraryLanguages));
              const removeBookList = booksToDelete.map((item) => {
                const book = item.bookInfo.book_id;
                return {
                  id: getBookId(book),
                  book,
                };
              });
              dispatch(actionDeleteLibraryLanguage(removeBookList));
              if (callback) {
                callback();
              }
            },
            { positiveBtn: "delete", negativeBtn: "cancel" },
          );
          return;
        }
        libraryLanguages.splice(libraryLanguages.indexOf(lang), 1);
      } else {
        libraryLanguages.push(lang);
      }
      dispatch(setLibLangs(libraryLanguages));
      if (callback) {
        callback();
      }
    },
    [books, libraryLanguages, t, showConfirm],
  );

  return updateLang;
};

// TODO optimize with selector
export const usePopupId = (popupId) => {
  const isShownPopupId = useSelector((state) => state.system.popupId === popupId);
  return isShownPopupId;
};

/**
 * Hook allow to use dev features on test server
 * @returns {{
 *   isDevMode: boolean,
 *   toggleDevMode: function
 * }}
 */
export const useDevMode = () => {
  const dispatch = useDispatch();
  const isDevMode = useSelector((state) => state.settings.isDevMode);

  const toggleDevMode = () => {
    dispatch(actionUpdateSetting(Settings.isDevMode.id, !isDevMode));
  };

  return { isDevMode, toggleDevMode };
};

// Hook for localStorage
export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorageSafe.getItem(key);
    try {
      // Get from local storage by key
      // Parse stored json or if none return initialValue

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      if (isDevelopment()) {
        console.log(error);
      }
      return item ?? initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Save state
        setStoredValue((valueOld) => {
          const valueToStore = value instanceof Function ? value(valueOld) : value;

          if (valueToStore) {
            localStorageSafe.setItem(key, JSON.stringify(valueToStore));
          } else {
            localStorageSafe.removeItem(key);
          }

          return valueToStore;
        });
        // Save to local storage
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key],
  );
  return [storedValue, setValue];
};

/**
 * @see {@link https://github.com/streamich/react-use/blob/master/docs/useSessionStorage.md}
 * @param {*} key sessionStorage key to manage.
 * @param {*} initialValue initial value to set, if value in sessionStorage is empty
 * @param {boolean} raw if set to true, hook will not attempt to JSON serialize stored values.
 */
export const useSessionStorage = (key, initialValue, raw = false) => {
  const [state, setState] = useState(() => {
    try {
      const sessionStorageValue = sessionStorageSafe.getItem(key);
      if (typeof sessionStorageValue !== "string") {
        sessionStorageSafe.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue));
        return initialValue;
      }

      return raw ? sessionStorageValue : JSON.parse(sessionStorageValue || "null");
    } catch (error) {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const serializedState = raw ? String(value) : JSON.stringify(value);
      setState(value);
      sessionStorageSafe.setItem(key, serializedState);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [state, setValue];
};

export const useBookHistory = (bookId) => {
  const allHistory = useSelector((state) => state.history.allHistory);
  return useMemo(() => allHistory.find((bookDataItem) => bookDataItem.id === bookId), [
    bookId,
    allHistory,
  ]);
};
