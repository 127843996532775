import {
  ACTION_RESIZE,
  ACTION_FULLSCREEN_BOOK,
  ACTION_LOGIN,
  ACTION_LOGOUT,
  ACTION_UPDATE_DATA_ACQUISITION_STATUS,
  ACTION_LOADING,
  ACTION_ADD_MESSAGE,
  ACTION_PUT_INFO,
  ACTION_INIT_APP_LOAD_SUCCESS,
  ACTION_SET_CONTENT_ID,
  ACTION_SET_NEXT_ROUTE,
  ACTION_SET_POPUP_ID,
  ACTION_LOGIN_SUCCESS,
} from "./constants";
import { setTokens } from "../shared/utils/systemUtils";
import { AppState } from "src/shared/constants";
import { ContentConstants } from "./content.actions";

const initialState = {
  lastLoad: undefined,
  fullscreenId: -1,
  contentId: undefined,
  resize: false,
  isLogin: false,
  isLoginSuccess: false,
  isLoginByUserAction: false,
  dataAcquisitionStatus: undefined,
  errorBookLoad: [],
  loaders: [],
  messages: [],
  info: {},
  appState: AppState.loading,
  nextRoute: undefined,
  popupId: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_RESIZE:
      return { ...state, resize: action.data };
    case ACTION_FULLSCREEN_BOOK: {
      return { ...state, fullscreenId: action.data === undefined ? -1 : action.data };
    }
    case ACTION_LOGIN: {
      const { token, refreshToken } = action.data;
      setTokens(token, refreshToken);
      return { ...state, isLogin: !!refreshToken };
    }
    case ACTION_LOGIN_SUCCESS: {
      return { ...state, isLoginSuccess: true, isLoginByUserAction: !action.data.isFetchInitLoad };
    }
    case ACTION_LOGOUT: {
      return { ...state, isLogin: false, isLoginSuccess: false, isLoginByUserAction: false };
    }
    case ACTION_UPDATE_DATA_ACQUISITION_STATUS: {
      return { ...state, dataAcquisitionStatus: action.data };
    }
    case ContentConstants.ACTION_ERROR_BOOK_LOAD: {
      return { ...state, errorBookLoad: [...state.errorBookLoad, action.data] };
    }
    case ACTION_LOADING: {
      const { loadingId, forceRemove } = action.data;
      const loaders = [...state.loaders];
      const index = loaders.findIndex((item) => item === loadingId);
      if (index === -1 && !forceRemove) {
        loaders.push(loadingId);
      } else {
        loaders.splice(index, 1);
      }
      return { ...state, loaders };
    }
    case ACTION_ADD_MESSAGE: {
      return { ...state, messages: [action.data, ...state.messages] };
    }
    case ACTION_PUT_INFO: {
      return { ...state, info: action.data };
    }
    case ACTION_SET_POPUP_ID: {
      if ((!state.popupId && !action.data) || state.popupId === action.data) {
        return state;
      }

      return { ...state, popupId: action.data };
    }
    case ACTION_INIT_APP_LOAD_SUCCESS: {
      return { ...state, appState: action.data };
    }
    case ACTION_SET_NEXT_ROUTE: {
      return { ...state, nextRoute: action.data };
    }
    case ACTION_SET_CONTENT_ID: {
      return { ...state, contentId: state.contentId !== action.data ? action.data : undefined };
    }
    default:
      return state;
  }
};
