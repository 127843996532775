import React, { useLayoutEffect, useRef, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withResizeDetector } from "react-resize-detector/build/withPolyfill";
import { useTranslation } from "react-i18next";

import { ListView, GridView } from "../views";
import { isEventKey, KeyCodes } from "../../shared/utils/dom";
import { useSearchContext } from "./SearchContext";
import { useRemToPx } from "../../hooks/viewModeHooks";

export const getColumnWidth = (width) => {
  if (width <= 768) {
    return width * 0.5;
  }
  if (width <= 1440) {
    return width * 0.33;
  }
  if (width <= 2560) {
    return width * 0.25;
  }
  if (width <= 5120) {
    return width * 0.12;
  }

  return width * 0.05;
};

const SearchHistoryList = ({
  isScroll,
  activeIndex,
  className,
  onScrollList,
  isList = false,
  width = 500,
  height = 500,
}) => {
  const remToPx = useRemToPx();
  const scroller = useRef();
  const { t } = useTranslation();
  const searchHistory = useSelector((state) => state.search.searchHistory);
  const rowHeight = remToPx(2.2);

  const {  openSearchHistory } = useSearchContext();


  const { columnWidth, columnCount, rowCount } = useMemo(() => {
    const columnWidth = getColumnWidth(width);
    const columnCount = Math.floor(width / columnWidth);
    const rowCount = Math.ceil(searchHistory.length / columnCount);

    return { columnWidth, columnCount, rowCount };
  }, [width, searchHistory.length]);

  useLayoutEffect(() => {
    if (scroller.current && searchHistory.length && activeIndex) {
      if (isScroll) {
        const elementIndex = searchHistory.findIndex((item, index) => index === activeIndex - 1);
        const activeRow = Math.floor(elementIndex / columnCount) * rowHeight;
        scroller.current.scrollTop(activeRow);
      }
    }
  }, [searchHistory.length, columnCount, isScroll, activeIndex]);

  const onRowRenderer = (params) => {
    const { index } = params;

    const item = searchHistory[index];
    const itemIndex = index + 1;

    if (!item) {
      return null;
    }

    return onItemRender(item, itemIndex, params);
  };

  const onCellRenderer = (params) => {
    const { columnIndex, rowIndex } = params;
    const position = columnCount * rowIndex + columnIndex;
    const item = searchHistory[position];
    const itemIndex = position + 1;

    if (!item) {
      return null;
    }

    return onItemRender(item, itemIndex, params);
  };

  const onItemRender = (item, itemIndex, params) => {
    const activeItem = itemIndex === activeIndex;

    return (
      <button
        tabIndex={0}
        className={classNames("search-history-item", { "is-focused": activeItem })}
        key={params.key}
        style={params.style}
        onClick={() => onClickHandler(item)}
        onKeyDown={(event) => onKeyDownHandler(event, item)}>
        <div className="search-history-item-inner">
          <div className="search-item-title egwwritings">
            <span className="search-item-title-index">{itemIndex}</span>
            <span className="search-item-title-text">{item.query}</span>
          </div>
        </div>
      </button>
    );
  };

  const onKeyDownHandler = (event, item) => {
    if (isEventKey(event, KeyCodes.enter)) {
      onClickHandler(item);
    }
  };

  const onClickHandler = (item) => {
    openSearchHistory(item.query);
  };

  const onScrollStop = () => {
    const offset = scroller.current.getScrollTop();
    const row = Math.round(offset / rowHeight) + 1;
    if (onScrollList) {
      onScrollList(row);
    }
  };

  return (
    <div className={classNames("search-history-list right-panel-search-list", className)}>
      {isList ? (
        <ListView
          width={width}
          height={height}
          ref={scroller}
          rowCount={searchHistory.length}
          rowHeight={rowHeight}
          rowRenderer={onRowRenderer}
          onScrollStop={onScrollStop}
        />
      ) : (
        <GridView
          width={width}
          height={height}
          isHideScrollTo
          role="list"
          ariaLabel={t("gridOfSearch")}
          ref={scroller}
          cellRenderer={onCellRenderer}
          columnCount={columnCount || searchHistory?.length}
          columnWidth={columnWidth}
          rowCount={rowCount}
          rowHeight={rowHeight}
        />
      )}
    </div>
  );
};

SearchHistoryList.propTypes = {
  activeIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  isScroll: PropTypes.bool,
  onScrollList: PropTypes.func,
  isList: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default withResizeDetector(SearchHistoryList);
