import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useViewMode } from "../../hooks";
import { useTooltipContext } from "../popup/TooltipContext";

import "./View.scss";

const DOUBLE_CLICK_INTERVAL = 300;

const TabBar = ({ options, current, onChange, onDoubleClick, className }) => {
  const { t } = useTranslation();
  const { isMobileOrTablet, textMode } = useViewMode();
  const { showTooltip } = useTooltipContext();
  const lastClickRef = useRef(0);
  const clickTimeoutRef = useRef(undefined);

  const lockedTabs = useMemo(() => options.filter((item) => item.isLocked).map((item) => item.id), [
    options,
  ]);

  const checkDoubleClick = () => {
    if (!onDoubleClick || isMobileOrTablet) {
      return false;
    }
    if (lastClickRef.current === 0) {
      lastClickRef.current = Date.now();
    }
    const currentClickTime = Date.now();
    const lastClickTime = lastClickRef.current;
    lastClickRef.current = currentClickTime;
    const timeOffset = currentClickTime - lastClickTime;
    return timeOffset > 0 && timeOffset <= DOUBLE_CLICK_INTERVAL;
  };

  const handleSingleClick = (item) => {
    if (item.id !== current) {
      onChange(item.id);
    }
  };

  const handleClick = (item) => {
    if (onDoubleClick) {
      if (!clickTimeoutRef.current) {
        clickTimeoutRef.current = setTimeout(() => {
          handleSingleClick(item);
          clickTimeoutRef.current = undefined;
        }, DOUBLE_CLICK_INTERVAL);
      }
      if (checkDoubleClick()) {
        clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = undefined;
        onDoubleClick(item.id);
      }
    } else {
      handleSingleClick(item);
    }
  };

  return (
    <div role="tablist" className={classNames(className, "tab-wrap")}>
      {options.map((item, index) => {
        let isCurrent =
          (lockedTabs.includes(current) && lockedTabs.includes(item.id)) || item.id === current;

        const { isLocked, isActive } = item;
        const tabActive = isCurrent && !isLocked;

        return (
          <button
            aria-label={t("tabLabel", { label: item.label })}
            aria-controls="tab-group-id"
            tabIndex={0}
            aria-selected={tabActive ? "true" : "false"}
            onClick={() => handleClick(item)}
            onMouseEnter={(e) => {
              if (!onDoubleClick || textMode) {
                return;
              }
              const hint = lockedTabs.includes(item.id) ? "unlockColumnHint" : "lockColumnHint";
              showTooltip(t(hint), e);
            }}
            className={classNames("tab-default", {
              "tab-active": tabActive,
              "tab-inactive": !isCurrent && !isLocked,
              "tab-locked-active": isCurrent && isLocked && isActive,
              "tab-locked-inactive":
                (isCurrent && isLocked && !isActive) || (!isCurrent && isLocked),
            })}
            key={index}>
            {t(item.label)}
          </button>
        );
      })}
    </div>
  );
};

TabBar.propTypes = {
  current: PropTypes.string,
  onChange: PropTypes.func,
  onDoubleClick: PropTypes.func,
  options: PropTypes.array,
  className: PropTypes.string,
};

export default TabBar;
