import { makeExternalCacheRequest, makeExternalRequest } from "../../api/SearchExternalAPI";
import {
  CACHE_RELEVANT_FORMS,
  CACHE_RELEVANT_OMSK_FORMS,
} from "../../api/CacheHolder";
import { makeRequest } from "src/shared/api/baseApi";
import { normalizeFeaturedSearchSections } from "./RelevantSearch.utils";
import { langListShort } from "src/shared/utils/langs";

export const relevantSearchSchemeRequest = (lang) => {
  let url = "/related/form/";
  let storageCacheName = CACHE_RELEVANT_FORMS;
  if (lang) {
    url += "?lang=" + langListShort;
    storageCacheName += "_" + lang;
  }

  return makeRequest({
    url: url,
    storageCacheName,
    parseResponse: (response) => response.data,
  });
};

export const relevantSearchOmskScheme = () => {
  //TODO add url when omsk api finish
  const request = {
    url: "/related/form/",
    type: "get",
    parseResponse: (response) => response.data,
  };
  return makeExternalCacheRequest(request, CACHE_RELEVANT_OMSK_FORMS);
};

export const relevantSearchFacetAggregateRequest = (data) => {
  const { lang, query } = data;
  return makeRequest({
    url: `/related/aggregate/?lang=${lang}&query=${encodeURIComponent(query)}`,
    type: "get",
    memoryCache: true,
    parseResponse: (response) => response.data,
  });
};

export const fetchFeaturedSearchRequest = (query, langs = []) => {
  return makeRequest({
    url: `/search/featured/search?query=${encodeURIComponent(query)}&lang=${langs}`,
    type: "get",
    memoryCache: true,
    parseResponse: (response) => {
      if (response.data.sections) {
        return normalizeFeaturedSearchSections(response.data.sections);
      }

      return [];
    },
  });
};

export const fetchFeaturedForParagraph = (paraId /* e.g. "127.5" - id without a char prefix */) => {
  return makeRequest({
    url: `/search/featured/${paraId}`,
    type: "get",
    parseResponse: (response) => {
      if (response.data.sections) {
        return normalizeFeaturedSearchSections(response.data.sections);
      }

      return [];
    },
  });
};

export const relevantSearchOmsk = (url) => {
  return makeExternalRequest({
    url: url,
    type: "get",
    parseResponse: (response) => response.data,
  });
};
