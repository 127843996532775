import { CONTENT_CLASSES } from "../shared/utils/content";
import { getSelectionAnchorClosest, isNoteElement } from "./Utils";

/* Fixes the FF range count more than 1 in case couple paragraphs are selected.
 Only single range can be handled by current app logic. */
const setRangeEndAsLastRange = (selection = {}, range) => {
  if (selection.rangeCount > 1) {
    const { endContainer, endOffset } = selection.getRangeAt(selection.rangeCount - 1);
    range.setEnd(endContainer, endOffset);
  }
};

export const getSelection = () => {
  return window.getSelection
    ? window.getSelection()
    : document.getSelection
      ? document.getSelection()
      : null;
};

// FYI: Might be trouble of an empty selection on the mobile.
// Previous fix (to return the last range cached into the variable) had been removed
// because of [EGWW-1849].
export const getSelectionRange = (numberOfRange = 0) => {
  const selection = getSelection();
  const isEmpty = selection.toString().trim().length === 0;
  let range;

  if (!isEmpty) {
    range = selection ? selection.rangeCount > 0 && selection.getRangeAt(numberOfRange) : null;
    setRangeEndAsLastRange(selection, range);
  }

  return {
    selection,
    range,
    isEmpty
  };
};

/**
 * @description Expands the selection to include footnote's content if selection was end on it.
 * @param {Object} range
 */
const expandSelectionToIncludeFootnoteContent = (range) => {
  if (range?.endContainer?.nextSibling && isNoteElement(range.endContainer.parentNode)) {
    range.setEnd(range.endContainer.nextSibling, 1);
  }
};

/**
 * @description Fixes the issue when selection has none paragraph wrapper elements with
 * a paragraph id. It is strict needed in the "getCopyPrintTextWithOptions" implementation, to
 * get missing "refs", "headers" from Redux.
 * @param {Object} selection
 * @param {Object} range
 * @returns {HTMLDivElement}
 */
const fixSelectionHasNoneParaWrappers = (selection, range) => {
  let selectionNodes = document.createElement("div");
  selectionNodes.appendChild(range.cloneContents());

  const paras = selectionNodes.querySelectorAll(`.${CONTENT_CLASSES.PARAGRAPH}[data-id]`);

  // To find related paragraph wrapper with "data-id" if it's missing for the selected text.
  if (!paras || !paras.length) {
    const para = getSelectionAnchorClosest(selection, `.${CONTENT_CLASSES.PARAGRAPH}[data-id]`);

    if (para) {
      const clonedPara = para.cloneNode(true);
      const fixedPara = clonedPara.querySelector(`span:not(.${CONTENT_CLASSES.REF_CODE})`);

      if (fixedPara) {
        fixedPara.innerHTML = selectionNodes.innerHTML;
      }

      selectionNodes = clonedPara;
    }
  }

  return selectionNodes;
};

export const getSelectionNode = () => {
  const { selection, range, isEmpty } = getSelectionRange();
  if (isEmpty) {
    return null;
  }
  expandSelectionToIncludeFootnoteContent(range);
  return fixSelectionHasNoneParaWrappers(selection, range);
};
