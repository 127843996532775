const componentsId = {
  CF_TYPO_ID: "CF_TYPO_ID",
  SEARCH_ID: "SEARCH_ID",
  LOCAL_SEARCH_ID: "LOCAL_SEARCH_ID",
  RIGHT_PANEL_SEARCH_ID: "RIGHT_PANEL_SEARCH_ID",
  LOADER_ID: "LOADER_ID",
  CF_SEND_ID: "CF_SEND_ID"
};

export default componentsId;
