import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import { useNavigationContext } from "../NavigationContext";
import { Scroll } from "../views";
import NoDataTemplate from "../NoDataTemplate/NoDataTemplate";

const ShortcutListResult = () => {
  const { isReader, openGlobalSearchResult } = useNavigationContext();
  const shortcutContent = useSelector((state) => state.search.shortcutContent.results);
  const syncReaderAndSearch = useSelector((state) => state.settings.syncReaderAndSearch);
  const isShortcutListPossiblyShouldHasData = isReader && syncReaderAndSearch;

  return (
    <Scroll>
      {shortcutContent.length ? (
        shortcutContent.map((item, index) => {
          return (
            <div
              className={classnames("shortcut-item-wrap", item.className)}
              key={index}
              onClick={() => {
                openGlobalSearchResult(item.paraId);
              }}>
              <h4
                className={"shortcut-title"}
                dangerouslySetInnerHTML={{ __html: item.refcode_long || item.refcode_short }}
              />
              {/* {(item.refcode_long || item.refcode_short) + `(${item.lang})`} */}
              {/* {(item.refcode_long || item.refcode_short)} */}

              <div
                className= "shortcut-content"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
          );
        })
      ) : isShortcutListPossiblyShouldHasData ? (
        <NoDataTemplate />
      ) : (
        ""
      )}
    </Scroll>
  );
};

ShortcutListResult.propTypes = {
  shortcutContent: PropTypes.array,
};

export default ShortcutListResult;
