import "react-app-polyfill/stable";
import "core-js/features/promise";
import "core-js/features/string/repeat";
import "core-js/features/object/values";
import "core-js/features/object/assign";
import "core-js/features/array/includes";
import React from "react";
import ReactDOM from "react-dom";
import cssVars from "css-vars-ponyfill";
import App from "./App";
import * as Sentry from "@sentry/browser";
import reportWebVitals from "./reportWebVitals";
import {
  flushStoredLogsByData,
  getStoredLogs, isCanLogAnythingToAWS,
  logTypes,
  makeTechLogsEndpoint
} from "./shared/api/analitics";
import { getEnvironment, isDevelopment } from "./shared/utils/assembly";

// import * as serviceWorker from "./serviceWorker";

import "./styles/index.scss";

// serviceWorker.registerVersionSync();

// prevent sentry send errors from dev process
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://b10629fc49f440f1a209994d69f3fe04@sentry.egwwritings.org/11",
    environment: getEnvironment(),
    ignoreErrors: ["ResizeObserver loop limit exceeded"], // this error occures exiting epub reader https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
  });
}

cssVars();

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();

// Send analytics on unload logic.
if ("sendBeacon" in navigator) {
  window.addEventListener("unload", function() {
    // "sendBeacon" works for EGW Tech only currently.
    if (!isCanLogAnythingToAWS()) {
      let logType, logDataStored;
      for (let i = 0; i < logTypes.length; i++) {
        logType = logTypes[i];
        logDataStored = getStoredLogs(logType);

        if (logDataStored.length) {
          const blob = new Blob([JSON.stringify(logDataStored)], {
            type : "application/json",
          });
          const isSendShouldBeOK = navigator.sendBeacon(makeTechLogsEndpoint(logType), blob);

          if (isSendShouldBeOK) {
            flushStoredLogsByData(logType, logDataStored);
          }
        }
      }
    }
  });
}
// =============================

// TODO test PWA
let deferredPrompt;

window.addEventListener("beforeinstallprompt", (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI notify the user they can install the PWA
  // showInstallPromotion();
  // alert("aaa");
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
