import {
  ACTION_SCROLL_DIRECTION_UPDATE,
  ACTION_SET_TOP_PANEL_VISIBILITY,
  ACTION_UPDATE_SHOW_TEXT_MODE_POPUP,
  RESTORE_DATA,
  READER_SEARCH_FOCUS,
  READER_LAYOUT_WRAP_HEIGHT,
  FETCH_GEO_IP_DATA_SUCCESS,
  ACTION_UPDATE_SETTING_SUCCESS,
  ACTION_LOGOUT,
  ACTION_SET_SCREEN_TYPE,
  ACTION_TOGGLE_SHOW_CART,
} from "./constants";
import { SCROLL_DIRECTIONS } from "../components/reader/constants";
import { getDefaultSettings, Settings } from "../utils/Settings";
import { ScreenType } from "../utils/ThemeUtils";

const initialState = {
  ...getDefaultSettings(),
  isTopPanelVisible: true,
  showReading: true,
  showListen: true,
  scrollDirection: SCROLL_DIRECTIONS.UNTOUCHED,
  showTextModePopup: false,
  readerSearchFocus: false,
  readerLayoutWrapHeight: 0,
  geoIpData: {},
  swiping: false,
  screenType: ScreenType.DESKTOP,
  showCartPanel: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_UPDATE_SETTING_SUCCESS: {
      return { ...state, ...action.data };
    }
    case RESTORE_DATA: {
      return { ...state, ...action.data };
    }
    case READER_SEARCH_FOCUS: {
      return { ...state, readerSearchFocus: action.data };
    }
    case READER_LAYOUT_WRAP_HEIGHT: {
      return { ...state, readerLayoutWrapHeight: action.data };
    }
    case ACTION_SCROLL_DIRECTION_UPDATE: {
      return { ...state, scrollDirection: action.data };
    }
    case ACTION_SET_TOP_PANEL_VISIBILITY: {
      return { ...state, isTopPanelVisible: action.data };
    }
    case ACTION_UPDATE_SHOW_TEXT_MODE_POPUP: {
      return { ...state, showTextModePopup: action.data };
    }
    case FETCH_GEO_IP_DATA_SUCCESS: {
      return { ...state, geoIpData: action.data };
    }
    case ACTION_SET_SCREEN_TYPE: {
      return { ...state, screenType: action.data };
    }
    case ACTION_LOGOUT: {
      const newInitState = { ...initialState };
      const settingsKeys = Object.keys(Settings);


      settingsKeys.forEach((settingName) => {
        const { saveOnLogout } = Settings[settingName];

        if (Object.hasOwnProperty.call(initialState, settingName) && saveOnLogout) {
          newInitState[settingName] = state[settingName];
        }
      });

      return newInitState;
    }
    case ACTION_TOGGLE_SHOW_CART: 
      return { ...state, showCartPanel: !state.showCartPanel };
    default:
      return state;
  }
};
