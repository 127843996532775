import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import {
  COOKIE_KEY_DONATE_CLOSED_COUNT,
  COOKIE_KEY_DONATE_NEXT_SHOW, DONATE_TIMEOUT_ALREADY_DONATED,
  getDonateTimestampNextShowWithClosedCount
} from "../utils/donate";

export const useDonateNotification = ({isReader, pathname}) => {
  const [show, setShow] = useState(false);
  const [cookies, setCookies] = useCookies([COOKIE_KEY_DONATE_NEXT_SHOW]);
  const timestampNextShow = parseInt(cookies[COOKIE_KEY_DONATE_NEXT_SHOW]);
  let closedCount = parseInt(cookies[COOKIE_KEY_DONATE_CLOSED_COUNT]);
  closedCount = isNaN(closedCount) ? 0 : closedCount;
  const isTimestampNextShowValid = !isNaN(timestampNextShow);

  useEffect(() => {
    if (isReader) {
      if (!isTimestampNextShowValid) {
        setCookies(
          COOKIE_KEY_DONATE_NEXT_SHOW,
          getDonateTimestampNextShowWithClosedCount(closedCount),
        );
      }
    }
  }, [isReader, isTimestampNextShowValid, closedCount, setCookies]);

  useEffect(() => {
    // === Return cases ===
    if (show || !isTimestampNextShowValid) {
      return;
    }
    // ===================

    if (timestampNextShow <= Date.now()) {
      setShow(true);
    }
  }, [isTimestampNextShowValid, show, timestampNextShow, pathname]);

  const onDonateClick = useCallback(() => {
    setCookies(COOKIE_KEY_DONATE_NEXT_SHOW, Date.now() + DONATE_TIMEOUT_ALREADY_DONATED);

    setShow(false);
  }, [setCookies]);

  const onCloseClick = useCallback(() => {
    const closeCountNew = closedCount + 1;

    setCookies(COOKIE_KEY_DONATE_CLOSED_COUNT, closeCountNew);
    setCookies(
      COOKIE_KEY_DONATE_NEXT_SHOW,
      getDonateTimestampNextShowWithClosedCount(closeCountNew)
    );

    setShow(false);
  }, [setCookies, closedCount]);

  return { show, onDonateClick, onCloseClick, };
};
