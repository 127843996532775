import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { detectIfLastRead, convertToTime, fromNow } from "../../utils/Utils";
import { makeNavigationUrl } from "../../utils/URLUtils";
import { useShareContext } from "../shareHolder/ShareHolder";
import { useViewMode, useAuth, useBookHistory } from "../../hooks";
import BookItemImage from "./BookItemImage";
import DownloadButton from "./BookDownloadView";
import BookDownloadLinksView from "./BookDownloadLinksView";
import { BookListType, getIsBookListTypeListen, getIsBookListTypeWithHistory } from "./BooksUtils";
import { IconButton } from "../views";
import useToggleInLibrary from "../../hooks/useToggleInLibrary";
import { getBookCover } from "../../shared/utils/url";
import { cartActions } from "src/redux/cart/actions";
import { actionAddMessage } from "src/redux/actions";
import useSubscription from "src/hooks/useSubscription";
import EgwWebFont from "src/assets/EgwWebFont";
import { BookInteractTypes } from "./BookList.utils";
import { useHandleBookInteraction } from "./BookList.hooks";

import "./BookIconsRow.scss";
import "./BookListItem.scss";
import "../views/View.scss";
import { useBookTransation } from "../bookDetails/hooks";
import BookTranslateStat from "./translate/BookTranslateStat";

const BookListItem = ({
  isShownFormatIcon,
  isShowDescriptionCover,
  handleBookInteractionCustom,
  bookInteractTypeCustom,
  book,
  shopBook,
  isGrid,
  gridPosition,
  isShowBookshelf,
  bookListType,
  isListTypeWithInfo,
}) => {
  const { isLogin } = useAuth();
  const { t } = useTranslation();
  const { showShare, sharerId } = useShareContext();
  const dispatch = useDispatch();
  const { isMobileOrTablet, textMode, isMobile } = useViewMode();
  const bookData = book.bookInfo || book;
  const bookId = bookData.id;
  const { toggleInLibrary } = useToggleInLibrary();
  const code = bookData?.code || book?.code || "";
  const isForSaleAndPurchased = bookData.isForSale && shopBook?.is_purchased;
  const isAvailable = !bookData.isForSale || isForSaleAndPurchased;
  const { isCanSubscribe, isSubscribed, onClickSubscribe } = useSubscription(bookId);
  const bookDataHistory = useBookHistory(bookId);
  const isBookInLibrary = bookDataHistory?.inLibrary || false;
  const bookHistory = useMemo(() => {
    if (bookDataHistory?.luRead || bookDataHistory?.luListen || bookDataHistory?.luLibrary) {
      return { ...bookDataHistory, ...book };
    }

    return undefined;
  }, [bookDataHistory, book]);

  const isBookListTypeWithHistory = getIsBookListTypeWithHistory(bookListType);
  const isBookListTypeListen = getIsBookListTypeListen(bookListType);
  const translationBook = useBookTransation(bookId);
  const isViewTypeTranslation = translationBook !== undefined;

  const { handleBookInteraction: handleBookInteractionHookFn } = useHandleBookInteraction();

  const sharerBookId = "book-list-share-" + bookId;

  const handleShare = (e) => {
    showShare({
      sharerId: sharerBookId,
      shareData: {
        url: makeNavigationUrl(bookData),
        imgUrl: getBookCover(bookData, true),
        title: bookData.label,
        refcode: bookData.code,
      },
      bounds: e.target.getBoundingClientRect(),
    });
  };

  const handleLike = useCallback(() => {
    if (isAvailable) {
      toggleInLibrary(isBookInLibrary, bookId);
    } else {
      dispatch(actionAddMessage("@pleaseBuyThisBook"));
    }
  }, [toggleInLibrary, isAvailable, isBookInLibrary, bookId]);

  const handleCartClick = () => {
    if (isLogin) {
      if (shopBook) {
        dispatch(cartActions.addToCart(shopBook));
        dispatch(actionAddMessage("@addedToCart"));
      }
    } else {
      dispatch(actionAddMessage("@pleaseLogin"));
    }
  };

  const subsribeActive = bookData && isCanSubscribe && isSubscribed;

  const shareBtn = (
    <IconButton
      icon={EgwWebFont.share}
      title={t("share")}
      className={"booklist-fill-icon" + (sharerBookId === sharerId ? " active" : "")}
      onClick={handleShare}
    />
  );
  const subscribeBtn = (
    <IconButton
      icon={EgwWebFont["subscription-outline"]}
      title={isSubscribed ? t("subscribed") : t("subscribe")}
      className={classNames(
        "book-row-button-subscribe",
        "booklist-fill-icon",
        { active: subsribeActive },
        { disabled: !(bookData && isCanSubscribe) },
      )}
      onClick={onClickSubscribe}
    />
  );
  const btnRead = (
    <IconButton
      icon={EgwWebFont.read}
      onClick={() => {
        handleBookInteraction(bookData, BookInteractTypes.read);
      }}
      className="booklist-fill-icon"
      title={t("read")}
    />
  );
  const btnListen = (
    <IconButton
      disabled={!bookData.is_audiobook}
      icon={EgwWebFont["play-circle"]}
      onClick={() => {
        handleBookInteraction(bookData, BookInteractTypes.listen);
      }}
      className={bookData.is_audiobook ? "book-play" : "book-play-disabled"}
      title={t("listen")}
    />
  );
  const btnBuy = (
    <IconButton
      icon={EgwWebFont.cart}
      onClick={handleCartClick}
      className="booklist-fill-icon"
      title={t("addToCart")}
    />
  );
  const likeBtn = (
    <IconButton
      icon={EgwWebFont["heart-outline"]}
      trottle={500}
      onClick={handleLike}
      className={classNames("booklist-fill-icon", "book-list_btn-like", {
        active: isBookInLibrary,
        disabled: !isAvailable,
      })}
      title={isBookInLibrary ? t("removeFromLibrary") : t("addToLibrary")}
    />
  );

  let labelBookCode;
  let isBookListTypeRead = !isBookListTypeListen;
  let isReadIconOnCoverImg = isBookListTypeRead;
  let btnInteractType;

  if (bookInteractTypeCustom) {
    btnInteractType = bookInteractTypeCustom;
  } else {
    if (isBookListTypeWithHistory && bookHistory && bookListType !== BookListType.readWithHistory) {
      isBookListTypeRead = detectIfLastRead(bookHistory.luRead, bookHistory.luListen);
      isReadIconOnCoverImg = isBookListTypeRead && !isBookListTypeListen;
    }

    if (!isAvailable) {
      btnInteractType = BookInteractTypes.buy;
    } else if (isReadIconOnCoverImg) {
      btnInteractType = BookInteractTypes.read;
    } else {
      btnInteractType = BookInteractTypes.listen;
    }
  }

  // There is no space on mobile, so leave it empty.
  let labelValue = isMobile ? "" : (isReadIconOnCoverImg ? t("reading") : t("listening")) + ": ";

  if (bookData && bookData.npages > 0) {
    labelBookCode = code + " " + bookData.npages;
  }

  let interactionDate;
  if (isBookListTypeWithHistory && bookHistory) {
    if (isReadIconOnCoverImg && bookHistory.luRead) {
      interactionDate = fromNow(bookHistory.luRead);
      labelBookCode = bookHistory.refcode_short;
    } else if (bookHistory.luListen) {
      interactionDate = fromNow(bookHistory.luListen);
      labelBookCode = code + " " + convertToTime(bookHistory.position, false);
    }
  }

  if (!labelBookCode) {
    labelBookCode = code;
  }

  const downloadBtn = bookData.isForSale ? null : <DownloadButton book={bookData} />;

  let bookRowBtnOpen;

  if (btnInteractType === BookInteractTypes.read) {
    bookRowBtnOpen = btnListen;
  } else if (btnInteractType === BookInteractTypes.buy) {
    bookRowBtnOpen = btnBuy;
  } else {
    bookRowBtnOpen = btnRead;
  }

  const handleBookInteraction = useCallback(
    (book, interactionType) => {
      const interactionTypeFinal = bookInteractTypeCustom || interactionType || btnInteractType;

      if (handleBookInteractionCustom) {
        handleBookInteractionCustom(book, interactionTypeFinal);
      } else {
        handleBookInteractionHookFn(book, interactionTypeFinal, {
          isOpenBookDetailsOnBuy: true,
          withHistory: isBookListTypeWithHistory,
          bookHistory,
        });
      }
    },
    [
      bookInteractTypeCustom,
      btnInteractType,
      handleBookInteractionCustom,
      handleBookInteractionHookFn,
      isBookListTypeWithHistory,
      bookHistory,
    ],
  );

  /**
   * Renders book's action buttons.
   * @param {{
   *   listView: boolean,
   *   withStaticDownload: boolean,
   *   showBookCode: boolean,
   *   showActionButtons: boolean,
   *   showDownloadButton: boolean
   * }} config - configuration object.
   * @returns {JSX.Element|null}
   */
  const renderBookRow = (config = {}) => {
    let {
      listView = false,
      withStaticDownload = false,
      showBookCode = true,
      showActionButtons = true,
      showDownloadButton = true,
      showBookSeries = false,
    } = config;

    if (!isAvailable) {
      withStaticDownload = false;
      showBookCode = false;
    }

    const bookCodeLabel = <span className="bookItemText bookItemLabel">{labelValue}</span>;
    const bookCode = (
      <span className="bookItemText bookItemCode" title={labelBookCode}>
        {isBookListTypeWithHistory && bookCodeLabel}
        {labelBookCode}
      </span>
    );

    const translatBookCode = (
      <span className="bookItemText bookItemCode" title={bookData.abbreviation}>
        {bookData.abbreviation}
      </span>
    );

    if (listView) {
      return (
        <div
          className={classNames("book-row listRow", {
            "with-static-download": withStaticDownload,
          })}
          onClick={(e) => e.stopPropagation()}>
          {showBookCode && bookCode}
          <span className="actions-wrapper">
            {withStaticDownload && <BookDownloadLinksView book={bookData} />}
            {showBookSeries && (
              <span className="bookItemSetLabel">
                {bookData?.book_series && bookData.book_series.length + " " + t("booksInSet")}
              </span>
            )}
            {showActionButtons && (
              <span className="action-buttons">
                {shareBtn}
                {subscribeBtn}
                {likeBtn}
                {withStaticDownload ? null : showDownloadButton && downloadBtn}
                {bookRowBtnOpen}
              </span>
            )}
            {isViewTypeTranslation && (
              <BookTranslateStat
                statistics={translationBook.statistics}
                paraId={bookData.first_para}
              />
            )}
          </span>
        </div>
      );
    }

    let content = (
      <React.Fragment>
        {showActionButtons ? (
          <span className="book-row">
            {shareBtn}
            {subscribeBtn}
            {likeBtn}
            {bookRowBtnOpen}
          </span>
        ) : (
          ""
        )}
        <span className="book-row">
          {showBookCode && bookCode}
          {showDownloadButton && downloadBtn}
        </span>
      </React.Fragment>
    );

    if (textMode) {
      content = (
        <React.Fragment>
          <span className="book-row">{bookCode}</span>
          <span className="book-row">{downloadBtn}</span>
          <span className="book-row">
            {shareBtn}
            {subscribeBtn}
          </span>
          <span className="book-row">
            {likeBtn}
            {bookRowBtnOpen}
          </span>
        </React.Fragment>
      );

      if (isMobileOrTablet) {
        content = (
          <React.Fragment>
            <span className="book-row">{bookCode}</span>
            <span className="book-row">{downloadBtn}</span>
            <span className="book-row">{shareBtn}</span>
            <span className="book-row">{subscribeBtn}</span>
            <span className="book-row">{likeBtn}</span>
            <span className="book-row">{bookRowBtnOpen}</span>
          </React.Fragment>
        );
      }
    }

    return content;
  };

  if (isGrid) {
    return (
      <div
        className={classNames("bookItemWrap", {
          "bookshelf-active": isShowBookshelf,
        })}>
        <BookItemImage
          book={bookData}
          isShownFormatIcon={isShownFormatIcon}
          btnInteractType={btnInteractType}
          isShowDescriptionCover={isShowDescriptionCover}
          withTranslation={isViewTypeTranslation}
          isGrid={isGrid}
          gridPosition={gridPosition}
          renderBookRow={renderBookRow}
          showBookshelf={isShowBookshelf}
          mark={interactionDate}
          onClick={() => {
            handleBookInteraction(bookData);
          }}
        />
        <div className="book-item-bookshelf">{renderBookRow()}</div>
      </div>
    );
  }

  if (isListTypeWithInfo) {
    return (
      <BookListItemWithInfo
        bookData={bookData}
        btnInteractType={btnInteractType}
        isMobile={isMobile}
        onClick={() => {
          handleBookInteraction(bookData);
        }}
      />
    );
  }

  return (
    <div
      className={classNames("bookItemWrapHor", {
        "bookshelf-active": isShowBookshelf,
      })}>
      {!textMode && (
        <BookItemImage
          book={bookData}
          withTranslation={isViewTypeTranslation}
          isShownFormatIcon={isShownFormatIcon}
          btnInteractType={btnInteractType}
          onClick={() => {
            handleBookInteraction(bookData);
          }}
        />
      )}
      <div className="bookItemContentHor">
        <div className="book-item-hor__head-wrapper">
          <span
            className={classNames("bookItemText bookItemTitle", {
              bookItemHorText: !!bookHistory,
            })}
            onClick={() => {
              handleBookInteraction(bookData);
            }}>
            {bookData.title}
          </span>
          {interactionDate ? <span className="label-horizontal">{interactionDate}</span> : null}
        </div>

        {isShowBookshelf ? null : renderBookRow({ listView: true })}
      </div>
    </div>
  );
};

BookListItem.propTypes = {
  isShownFormatIcon: PropTypes.bool,
  isShowBookshelf: PropTypes.bool,
  isGrid: PropTypes.bool,
  isListTypeWithInfo: PropTypes.bool,
  isShowDescriptionCover: PropTypes.bool,
  bookInteractTypeCustom: PropTypes.oneOf(Object.values(BookInteractTypes)),
  handleBookInteractionCustom: PropTypes.func,
  bookListType: PropTypes.string,
  gridPosition: PropTypes.object,
  shopBook: PropTypes.shape({
    is_purchased: PropTypes.any,
  }),
  book: PropTypes.shape({
    abbreviation: PropTypes.string, // optional, only if {isViewTypeTranslation=true}
    statistics: PropTypes.object, // optional, only if {isViewTypeTranslation=true}
    bookInfo: PropTypes.shape({
      book_series: PropTypes.array,
      code: PropTypes.any,
      id: PropTypes.any,
      isForSale: PropTypes.any,
      is_audiobook: PropTypes.any,
      label: PropTypes.any,
      npages: PropTypes.number,
      title: PropTypes.any,
      languageName: PropTypes.string, // optional, only if {isListTypeWithInfo=true}
      code_en: PropTypes.string, // optional, only if {isListTypeWithInfo=true}
    }),
    luLibrary: PropTypes.any,
    luListen: PropTypes.any,
    luRead: PropTypes.any,
  }),
};

export default BookListItem;

const BookListItemWithInfo = ({
  isShownFormatIcon,
  bookData,
  btnInteractType,
  isMobile,
  onClick,
}) => {
  const { t } = useTranslation();

  const language = isMobile ? bookData.lang : bookData.languageName || bookData.lang;

  return (
    <div className="category-book" onClick={onClick}>
      {!isMobile && (
        <div className="category-book__img">
          <BookItemImage
            isShownFormatIcon={isShownFormatIcon}
            book={bookData}
            btnInteractType={btnInteractType}
          />
        </div>
      )}
      <div className="category-book__description">
        <div className="category-book__row">
          <CategoryListRow title={t("title")} subtitle={bookData.title} className="pub-name" />
          {!isMobile && (
            <CategoryListRow title={t("code")} subtitle={bookData.code} className="pub-code" />
          )}
          <CategoryListRow
            title={isMobile ? bookData.code : t("engCode")}
            subtitle={bookData.code_en || (!isMobile && bookData.code)}
            className="eng-pub-code"
          />
          <CategoryListRow title={t("language")} subtitle={language} className="lang" />
          <CategoryListRow
            title={t("publication")}
            subtitle={bookData.pub_year}
            className="pub-year"
          />
        </div>
        <hr />
      </div>
    </div>
  );
};

BookListItemWithInfo.propTypes = {
  bookData: PropTypes.object,
  isMobile: PropTypes.bool,
  isShownFormatIcon: PropTypes.bool,
  btnInteractType: PropTypes.oneOf(Object.values(BookInteractTypes)),
  onClick: PropTypes.func,
};

const CategoryListRow = ({ title, subtitle, className }) => {
  return (
    <div className={classNames("category-book__row", className)}>
      <div className="category-book__column">
        <span className="category-book__title">
          {title}
          <span>:</span>
        </span>
        <span className="category-book__subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

CategoryListRow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};
