import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useViewMode } from "../../hooks";
import TextButton from "./TextButton";
import { usePanels } from "../../hooks/usePanels";
import IconView from "src/shared/components/IconView";
import EgwWebFont from "src/assets/EgwWebFont";
import { useMakeLastSearch } from "../search/hooks";

const SearchPlusButton = () => {
  const { setIsRightPanelVisible } = usePanels();
  const { t } = useTranslation();
  const { textMode } = useViewMode();
  const isShowRightPanel = useSelector((state) => state.settings.isShowRightPanel);
  const makeLastSearch = useMakeLastSearch();

  const onToggleSearch = () => {
    setIsRightPanelVisible(!isShowRightPanel);
    if (!isShowRightPanel) {
      makeLastSearch();
    }
  };

  const content = textMode ? (
    t("search+")
  ) : (
    <React.Fragment>
      <IconView icon={EgwWebFont["search-book"]} className="hsFirst" />
      {t("search")}
      <IconView icon={EgwWebFont.plus} className="hsLast" />
    </React.Fragment>
  );

  return (
    <TextButton
      className="homeSearchIcon homeSearchTopPane"
      onClick={onToggleSearch}
      active={isShowRightPanel}>
      {content}
    </TextButton>
  );
};

export default SearchPlusButton;
